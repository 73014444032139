import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import GeofencesPageContainer from './containers/GeofencesPageContainer'

// Sync route definition
export default store => ({
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        cb(
          null,
          UserIsAuthenticated(UserHasValidPassword(UserHasScope(['ra'], GeofencesPageContainer, false, studyID))),
        )
      },
      'geofences',
    )
  },
})
