/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { combineReducers } from 'redux'
import { actions as notyActions } from 'layouts/ErrorBox'
import { EditorState } from 'draft-js'
import { HTMLToEditorState, EditorStateToHTML, formatLinkEntities } from 'utils/draft'
import { addUniqToArr } from 'utils/misc'
import request, { generateNotyMessage } from 'utils/request'
import { getClinroErrors, getFormulaErrors } from 'utils/instrumentValidation'
import {
  addMediaToFileMap,
  deleteMediaFromInstrumentJson,
  removeValuesAndLabels,
  removeChoices,
  getRandomizedArray,
} from 'utils/instrument'
import {
  INSTRUMENT_PATH_MAP,
  MEDIA_TYPE_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  MODAL_CONTENT_MAP,
  QUESTION_TYPE_MAP,
  QUESTION_TYPES_WITH_AUDIO,
  QUESTION_TYPES_WITH_CHOICE_IMAGES,
  QUESTION_TYPES_WITH_CHOICES,
  QUESTION_TYPES_WITH_IMAGE,
  QUESTION_TYPES_WITH_SCORING,
  QUESTION_TYPES_WITH_VIDEO,
  QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS,
  SELECT_QUESTION_TYPES,
} from 'utils/constants'
import { stateToHTML } from 'draft-js-export-html'
import { modalActions } from 'store/modal'
import { loadingActions } from 'store/loader'
import { getDomainIsUtilized } from '../../Scoring/utils/domainUtils'
import { _getDefaultMetadata, cleanUploadedJSON } from '../../../../../utils/PropertyFields'
import { validateAndFixQuestionAndChoiceIdMismatches } from '../../EditSurvey/modules/Survey'
import { replaceTargetsWithVal } from '../../Scoring/utils/scoring'
import { getRandomQuestionId } from '../../../../../../../utils/getRandomQuestionId'

//
// Constants
//

// Clinro
export const INITIALIZE_CLINRO_EDIT = 'INITIALIZE_CLINRO_EDIT'
const ADD_CLINRO_CHOICE = 'ADD_CLINRO_CHOICE'
const ADD_CLINRO_CHOICE_LABEL_IMAGE = 'ADD_CLINRO_CHOICE_LABEL_IMAGE'
const ADD_CLINRO_CHOICE_ON_PASTE = 'ADD_CLINRO_CHOICE_ON_PASTE'
const ADD_CLINRO_ITEM = 'ADD_CLINRO_ITEM'
const ADD_CLINRO_ITEM_IMAGE = 'ADD_CLINRO_ITEM_IMAGE'
const ADD_CLINRO_LOGIC_CONDITION = 'ADD_CLINRO_LOGIC_CONDITION'
const ADD_CLINRO_LOGIC_GROUP = 'ADD_CLINRO_LOGIC_GROUP'
const ADD_CLINRO_MATRIX_QUESTION = 'ADD_CLINRO_MATRIX_QUESTION'
const ADD_CLINRO_OTHER_CHOICE = 'ADD_CLINRO_OTHER_CHOICE'
const ADD_CLINRO_SCORE = 'ADD_CLINRO_SCORE'
const ADD_CLINRO_SIBLING_ITEM = 'ADD_CLINRO_SIBLING_ITEM'
const ADD_NEW_CLINRO_DOMAIN = 'ADD_NEW_CLINRO_DOMAIN'
const ADD_CLINRO_PREVIEW_IMAGE = 'ADD_CLINRO_PREVIEW_IMAGE'
const CHANGE_CLINRO_ITEM_TYPE = 'CHANGE_CLINRO_ITEM_TYPE'
const CHANGE_CLINRO_LOGIC_OPERATOR = 'CHANGE_CLINRO_LOGIC_OPERATOR'
const DELETE_ALL_CLINRO_LOGIC = 'DELETE_ALL_CLINRO_LOGIC'
const DELETE_CLINRO_CHOICE = 'DELETE_CLINRO_CHOICE'
const DELETE_CLINRO_CHOICE_LABEL_IMAGE = 'DELETE_CLINRO_CHOICE_LABEL_IMAGE'
const DELETE_CLINRO_ITEM = 'DELETE_CLINRO_ITEM'
const DELETE_CLINRO_ITEM_IMAGE = 'DELETE_CLINRO_ITEM_IMAGE'
const DELETE_CLINRO_LOGIC_CONDITION = 'DELETE_CLINRO_LOGIC_CONDITION'
const DELETE_CLINRO_MATRIX_QUESTION = 'DELETE_CLINRO_MATRIX_QUESTION'
const DELETE_CLINRO_OTHER_CHOICE = 'DELETE_CLINRO_OTHER_CHOICE'
const DELETE_CLINRO_SCORE = 'DELETE_CLINRO_SCORE'
const DELETE_INVALID_CLINRO_LOGIC = 'DELETE_INVALID_CLINRO_LOGIC'
const DELETE_CLINRO_PREVIEW_IMAGE = 'DELETE_CLINRO_PREVIEW_IMAGE'
const DUPLICATE_CLINRO_ITEM = 'DUPLICATE_CLINRO_ITEM'
const INITIALIZE_BLANK_CLINRO = 'INITIALIZE_BLANK_CLINRO'
const MOVE_CLINRO_CHOICE = 'MOVE_CLINRO_CHOICE'
const MOVE_CLINRO_ITEM = 'MOVE_CLINRO_ITEM'
const RANDOMIZE_CLINRO_QUESTIONS = 'RANDOMIZE_CLINRO_QUESTIONS'
const SET_CLINRO_SCORES_EDITED = 'SET_CLINRO_SCORES_EDITED'
const TOGGLE_CLINRO_EDIT = 'TOGGLE_CLINRO_EDIT'
const TOGGLE_CLINRO_INPUT_VALIDATION = 'TOGGLE_CLINRO_INPUT_VALIDATION'
const TOGGLE_CLINRO_MATRIX_QUESTION_ALLOW_MULTIPLE_ANSWERS = 'TOGGLE_CLINRO_MATRIX_QUESTION_ALLOW_MULTIPLE_ANSWERS'
const TOGGLE_CLINRO_QUESTION_DOMAIN = 'TOGGLE_CLINRO_QUESTION_DOMAIN'
const TOGGLE_CLINRO_QUESTION_HAS_SCORING = 'TOGGLE_CLINRO_QUESTION_HAS_SCORING'
const TOGGLE_CLINRO_REQUIRED_QUESTION = 'TOGGLE_CLINRO_REQUIRED_QUESTION'
const UPDATE_CLINRO_CHOICE_KEY = 'UPDATE_CLINRO_CHOICE_KEY'
const UPDATE_CLINRO_CHOICE_LABEL = 'UPDATE_CLINRO_CHOICE_LABEL'
const UPDATE_CLINRO_CHOICE_SCORE_VALUE = 'UPDATE_CLINRO_CHOICE_SCORE_VALUE'
const UPDATE_CLINRO_CHOICE_VALUE = 'UPDATE_CLINRO_CHOICE_VALUE'
const UPDATE_CLINRO_DOMAIN_FORMULA = 'UPDATE_CLINRO_DOMAIN_FORMULA'
const UPDATE_CLINRO_ITEM = 'UPDATE_CLINRO_ITEM'
const UPDATE_CLINRO_ITEM_FORMULA = 'UPDATE_CLINRO_ITEM_FORMULA'
const UPDATE_CLINRO_ITEM_LABEL = 'UPDATE_CLINRO_ITEM_LABEL'
const UPDATE_CLINRO_LOGIC_CONDITION = 'UPDATE_CLINRO_LOGIC_CONDITION'
const UPDATE_CLINRO_MATRIX_QUESTION_LABEL = 'UPDATE_CLINRO_MATRIX_QUESTION_LABEL'
const UPDATE_CLINRO_OTHER_CHOICE = 'UPDATE_CLINRO_OTHER_CHOICE'
const UPDATE_CLINRO_SCORE = 'UPDATE_CLINRO_SCORE'
const UPDATE_CLINRO_TITLE = 'UPDATE_CLINRO_TITLE'
const UPDATE_CLINRO_UNIT_TYPE = 'UPDATE_CLINRO_UNIT_TYPE'
const UPDATE_ORIGINAL_CLINRO = 'UPDATE_ORIGINAL_CLINRO'
const SET_CLINROS_SCORE = 'SET_CLINROS_SCORE'

// Errors
const ADD_CLINRO_ERRORS = 'ADD_CLINRO_ERRORS'
const CLEAR_CLINRO_ERROR = 'CLEAR_CLINRO_ERROR'
const CLEAR_CLINRO_ERRORS = 'CLEAR_CLINRO_ERRORS'
const CLEAR_CLINRO_QUESTION_ERROR = 'CLEAR_CLINRO_QUESTION_ERROR'

//
// Action Creators
//

// Clinician Instrument Actions
function initializeEdit(json, editorStates) {
  return {
    type: INITIALIZE_CLINRO_EDIT,
    json,
    editorStates,
  }
}

function initializeBlankClinro(title = '', displayName = '') {
  return {
    type: INITIALIZE_BLANK_CLINRO,
    title,
    displayName,
  }
}

export function updateTitle(title) {
  return {
    type: UPDATE_CLINRO_TITLE,
    title,
  }
}

// Item Actions

function toggleEdit(itemId) {
  return {
    type: TOGGLE_CLINRO_EDIT,
    itemId,
  }
}

function addItem({ itemType, index, newQId }) {
  return {
    type: ADD_CLINRO_ITEM,
    itemType,
    newQId,
    index,
  }
}

function addSiblingClinroItem({ itemType, index, newQId, siblingId, siblingQId }) {
  return {
    type: ADD_CLINRO_SIBLING_ITEM,
    index,
    itemType,
    newQId,
    siblingId,
    siblingQId, // question ID of the sibling item to which this new sibling question is a sibling
  }
}

function deleteItem(itemId) {
  return {
    type: DELETE_CLINRO_ITEM,
    itemId,
  }
}

function addItemImage({ itemId, imageId, imageSrc, size }) {
  return {
    type: ADD_CLINRO_ITEM_IMAGE,
    itemId,
    imageId,
    imageSrc,
    size,
  }
}

function deleteItemImage(itemId, imageId) {
  return {
    type: DELETE_CLINRO_ITEM_IMAGE,
    itemId,
    imageId,
  }
}

function addPreviewImage({ itemId, imageId, imageOrder, src: imageSrc, size }) {
  return {
    type: ADD_CLINRO_PREVIEW_IMAGE,
    itemId,
    imageId,
    imageOrder,
    imageSrc,
    size,
  }
}

function deletePreviewImage(itemId, imageId, imageOrder) {
  return {
    type: DELETE_CLINRO_PREVIEW_IMAGE,
    itemId,
    imageId,
    imageOrder,
  }
}

function addChoice(itemId, choicesLength, insertIdx, itemType) {
  return {
    type: ADD_CLINRO_CHOICE,
    itemId,
    insertIdx,
    choicesLength,
    itemType,
  }
}

function deleteChoice(itemId, choiceId) {
  return {
    type: DELETE_CLINRO_CHOICE,
    itemId,
    choiceId,
  }
}

function addMatrixQuestion(itemId, insertIdx) {
  const newQuestionId = `${itemId}_${getRandomQuestionId()}`
  return {
    type: ADD_CLINRO_MATRIX_QUESTION,
    itemId,
    insertIdx,
    newQuestionId,
  }
}

function deleteMatrixQuestion(itemId, subQuestionId) {
  return {
    type: DELETE_CLINRO_MATRIX_QUESTION,
    itemId,
    subQuestionId,
  }
}

function addChoiceOnPaste(itemId, pasteText) {
  return {
    type: ADD_CLINRO_CHOICE_ON_PASTE,
    itemId,
    pasteText,
  }
}

function addOtherChoice(itemId) {
  return {
    type: ADD_CLINRO_OTHER_CHOICE,
    itemId,
  }
}

function deleteOtherChoice(itemId) {
  return {
    type: DELETE_CLINRO_OTHER_CHOICE,
    itemId,
  }
}

function updateOtherChoice(item, itemId, otherValue) {
  return {
    type: UPDATE_CLINRO_OTHER_CHOICE,
    item,
    itemId,
    otherValue,
  }
}

function updateChoiceLabel(item, itemId, choiceId, newLabel) {
  return {
    type: UPDATE_CLINRO_CHOICE_LABEL,
    item,
    itemId,
    choiceId,
    newLabel,
  }
}

function updateChoiceValue(item, itemId, choiceId, newValue) {
  return {
    type: UPDATE_CLINRO_CHOICE_VALUE,
    item,
    itemId,
    choiceId,
    newValue,
  }
}

function updateChoiceScoreValue(item, itemId, choiceId, newValue) {
  return {
    type: UPDATE_CLINRO_CHOICE_SCORE_VALUE,
    item,
    itemId,
    choiceId,
    newValue,
  }
}

function updateChoiceKey(item, itemId, choiceId, key, newValue) {
  return {
    type: UPDATE_CLINRO_CHOICE_KEY,
    item,
    itemId,
    choiceId,
    key,
    newValue,
  }
}

function addChoiceLabelImage({ item, itemId, choiceId, imageId, imageSrc, size }) {
  return {
    type: ADD_CLINRO_CHOICE_LABEL_IMAGE,
    item,
    itemId,
    choiceId,
    imageId,
    imageSrc,
    size,
  }
}

function deleteChoiceLabelImage(item, itemId, choiceId, imageId) {
  return {
    type: DELETE_CLINRO_CHOICE_LABEL_IMAGE,
    item,
    itemId,
    choiceId,
    imageId,
  }
}

function moveChoice(itemId, startIdx, endIdx) {
  return {
    type: MOVE_CLINRO_CHOICE,
    itemId,
    startIdx,
    endIdx,
  }
}

function changeItemType(itemId, prevType, nextType, _clinro) {
  const newQuestionId = `${itemId}_${getRandomQuestionId()}`
  const item = _clinro.questions[itemId]
  return {
    type: CHANGE_CLINRO_ITEM_TYPE,
    item,
    itemId,
    prevType,
    nextType,
    newQuestionId,
  }
}

function toggleRequiredQuestion(itemId) {
  return {
    type: TOGGLE_CLINRO_REQUIRED_QUESTION,
    itemId,
  }
}

function toggleQuestionScoring(itemId, domains) {
  return {
    type: TOGGLE_CLINRO_QUESTION_HAS_SCORING,
    itemId,
    domains,
  }
}

function setQuestionScoresEdited(itemId) {
  return {
    type: SET_CLINRO_SCORES_EDITED,
    itemId,
  }
}

function toggleInputValidation(itemId) {
  return {
    type: TOGGLE_CLINRO_INPUT_VALIDATION,
    itemId,
  }
}

function toggleQuestionDomain(itemId, domainId) {
  return {
    type: TOGGLE_CLINRO_QUESTION_DOMAIN,
    itemId,
    domainId,
  }
}

function createNewDomainAndAddToQuestion(itemId, label) {
  const newDomainId = getRandomQuestionId()
  return {
    type: ADD_NEW_CLINRO_DOMAIN,
    itemId,
    label,
    newDomainId,
  }
}

function updateClinroDomainFormula(domainId, formula) {
  return {
    type: UPDATE_CLINRO_DOMAIN_FORMULA,
    domainId,
    formula,
  }
}

function updateClinroScore(scoreId, key, value) {
  return {
    type: UPDATE_CLINRO_SCORE,
    scoreId,
    key,
    value,
  }
}

function addClinroScore(scoreId) {
  return {
    type: ADD_CLINRO_SCORE,
    scoreId,
  }
}

function deleteClinroScore(scoreId) {
  return {
    type: DELETE_CLINRO_SCORE,
    scoreId,
  }
}

function moveItem(_clinro, startIdx, endIdx, selectedItemArr) {
  return {
    type: MOVE_CLINRO_ITEM,
    clinro: _clinro,
    endIdx,
    selectedItemArr,
    startIdx,
  }
}

function updateClinroItemFormula(itemId, formula) {
  return {
    type: UPDATE_CLINRO_ITEM_FORMULA,
    itemId,
    formula,
  }
}

function toggleClinroMatrixAllowMulti(itemId) {
  return {
    type: TOGGLE_CLINRO_MATRIX_QUESTION_ALLOW_MULTIPLE_ANSWERS,
    itemId,
  }
}

export function duplicateItem(itemId, editorState, choiceQProps, matrixProps) {
  const newQId = getRandomQuestionId()
  if (choiceQProps) {
    /**
     * we will keep choices editor states in here for the future when there is
     * rich text implemented for the clinro choices
     *  */
    const { choicesOrder, choicesEditorStates } = choiceQProps
    const newChoicesIdMap = {}
    choicesOrder.forEach(choiceId => {
      const newChoiceId = `${newQId}_${getRandomQuestionId()}`
      newChoicesIdMap[choiceId] = newChoiceId
    })
    const action = {
      type: DUPLICATE_CLINRO_ITEM,
      choicesEditorStates,
      choicesOrder,
      editorState,
      hasChoices: true,
      itemId,
      newChoicesIdMap,
      newQId,
    }

    if (!matrixProps) return action

    // if there are matrixProps we add more key-values to the action
    const { questionsOrder, matrixQuestionEditorStates } = matrixProps
    const newQuestionIdMap = {}
    questionsOrder.forEach(questionId => {
      const newMatrixQuestionId = `${newQId}_${getRandomQuestionId()}`
      newQuestionIdMap[questionId] = newMatrixQuestionId
    })
    action.isMatrix = true
    action.matrixQuestionEditorStates = matrixQuestionEditorStates
    action.newQuestionIdMap = newQuestionIdMap
    action.questionsOrder = questionsOrder

    return action
  }
  // We'll keep this return below for when we add question types without choices.
  return {
    type: DUPLICATE_CLINRO_ITEM,
    itemId,
    newQId,
    editorState,
  }
}

function updateUnitType(itemId, unit) {
  return {
    type: UPDATE_CLINRO_UNIT_TYPE,
    itemId,
    unit,
  }
}

function randomizeQuestions() {
  return {
    type: RANDOMIZE_CLINRO_QUESTIONS,
  }
}

function setClinrosScore(instrumentId, scores) {
  return {
    type: SET_CLINROS_SCORE,
    instrumentId,
    scores,
  }
}

// Error actions

function addClinroErrors(errors) {
  return {
    type: ADD_CLINRO_ERRORS,
    errors,
  }
}

function clearClinroErrors() {
  return {
    type: CLEAR_CLINRO_ERRORS,
  }
}

function checkLogicAndToggleRequired(clinro, itemId) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnToggleRequired(clinro.questions, clinro.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(toggleRequiredQuestion(itemId))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.actionInvalidate,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(toggleRequiredQuestion(itemId))
    }
  }
}

function checkLogicAndDeleteItem(clinro, itemId) {
  return dispatch => {
    let onConfirm = () => {
      dispatch(deleteItem(itemId))
    }
    let modalBody = {
      content: MODAL_CONTENT_MAP.deleteItem,
      confirmButton: MODAL_BUTTONS_MAP.yes,
      cancelButton: MODAL_BUTTONS_MAP.no,
    }
    const invalidConditions = findInvalidLogicOnDelete(clinro.questions, clinro.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) {
      onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(deleteItem(itemId))
      }
      modalBody = {
        content: MODAL_CONTENT_MAP.logicInvalidatedByMove,
        confirmButton: MODAL_BUTTONS_MAP.proceed,
        cancelButton: MODAL_BUTTONS_MAP.cancel,
      }
    }
    dispatch(
      modalActions.openModal({
        ...modalBody,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  }
}

function checkLogicAndMoveItem(_clinro, startIdx, endIdx, selectedItemArr) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnMove(_clinro, startIdx, endIdx, selectedItemArr)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(moveItem(_clinro, startIdx, endIdx, selectedItemArr))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByMove,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(moveItem(_clinro, startIdx, endIdx, selectedItemArr))
    }
  }
}

function checkLogicAndDeleteChoice(_clinro, itemId, choiceId) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnChoiceDelete(_clinro.questions, _clinro.order[0], itemId, choiceId)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(deleteChoice(itemId, choiceId))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByDeletion,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(deleteChoice(itemId, choiceId))
    }
  }
}

const checkLogicAndDeleteMatrixQuestion = (_clinro, itemId, subQuestionId) => async dispatch => {
  const invalidConditions = await findInvalidLogicOnDeleteMatrixQuestion(
    _clinro.questions,
    _clinro.order[0],
    subQuestionId,
  )
  if (Object.keys(invalidConditions).length > 0) {
    const onConfirm = () => {
      dispatch(deleteInvalidLogic(invalidConditions))
      dispatch(deleteMatrixQuestion(itemId, subQuestionId))
    }
    dispatch(
      modalActions.openModal({
        content: MODAL_CONTENT_MAP.logicInvalidatedByDeletion,
        confirmButton: MODAL_BUTTONS_MAP.proceed,
        cancelButton: MODAL_BUTTONS_MAP.cancel,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  } else {
    dispatch(deleteMatrixQuestion(itemId, subQuestionId))
  }
}

function checkLogicAndChangeItemType(_clinro, itemId, prevType, nextType) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnChangeItemType(
      _clinro.questions,
      _clinro.order[0],
      itemId,
      prevType,
      nextType,
    )
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(changeItemType(itemId, prevType, nextType, _clinro))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByType,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(changeItemType(itemId, prevType, nextType, _clinro))
    }
  }
}

function logicForEach(terms, callback, path = []) {
  if (!terms) return
  terms.forEach((term, index) => {
    if (term.comparator === 'boolean_combine') {
      logicForEach(term.terms, callback, path.slice().concat(index))
    } else {
      callback(term, path.slice().concat(index))
    }
  })
}

/**
 * This util function helps determine the correct endIndex to place an individual
 * or group of sibling questions
 */
function returnEndIdx({ clinro: _clinro, endIdx, selectedItemArr, startIdx }) {
  const { order, questions, siblings } = _clinro
  const isMoveDown = endIdx > startIdx
  const origOrder = [...order[0]]

  let _order = [...order[0]]
  let _endIdx = endIdx
  let qIdAtEndIdx = null

  if (selectedItemArr) {
    _order = _order.filter(questionId => {
      return selectedItemArr[0] === questionId || !selectedItemArr.includes(questionId)
    })
    qIdAtEndIdx = _order[_endIdx]
  } else {
    qIdAtEndIdx = origOrder[_endIdx]
  }

  const sibling_id = questions[qIdAtEndIdx]?.sibling_id

  if (sibling_id) {
    /**
     * The code reaches this block if the question at the index to where we
     * we are moving is a sibling. If it is a sibling, we need to change the end index
     * either that of the first sibling of the question at the index (if we are moving up),
     * or that of the last sibling of the question at the index (if we are moving down).
     * This prevents the question(s) we are moving from being sorted into the middle of
     * sibling group.
     *  */
    const siblingArr = siblings[sibling_id]
    if (isMoveDown) {
      const lastSiblingQId = siblingArr[siblingArr.length - 1]
      _endIdx = _order.indexOf(lastSiblingQId)
    } else {
      const firstSiblingQId = siblingArr[0]
      _endIdx = _order.indexOf(firstSiblingQId)
    }
  }
  return _endIdx
}

export function findInvalidLogicOnMove(_clinro, startIdx, endIdx, selectedItemArr) {
  const { questions, order: _order, siblings } = _clinro
  const order = _order[0]
  const itemId = order[startIdx]
  const question = questions[itemId]
  const { sibling_id } = question
  const currentSiblings = sibling_id ? siblings[sibling_id] : null
  const _endIdx = returnEndIdx({ clinro: { order: _order, questions, siblings }, endIdx, selectedItemArr, startIdx })
  const siblingsWithLogic = []

  const invalidConditions = {}

  if (currentSiblings) {
    currentSiblings.forEach(siblingQId => {
      if (questions[siblingQId].logic.show_if) siblingsWithLogic.push(siblingQId)
    })
    // If none of the siblings have logic terms return empty invalidConditions
    if (siblingsWithLogic.length === 0) return invalidConditions
  }

  if (startIdx > _endIdx) {
    if (currentSiblings) {
      // If it's sibling questions moving up

      siblingsWithLogic.forEach(siblingQId => {
        const currentItemRefs = {}
        logicForEach(questions[siblingQId].logic.show_if.terms, (term, path) => {
          currentItemRefs[term.question_id] = currentItemRefs[term.question_id] || []
          currentItemRefs[term.question_id].push(path)
        })
        for (let i = _endIdx; i < startIdx; i++) {
          if (order[i] in currentItemRefs) {
            invalidConditions[siblingQId] = invalidConditions[siblingQId] || []
            invalidConditions[siblingQId] = invalidConditions[siblingQId].concat(currentItemRefs[order[i]])
          }
        }
      })
    } else if (!question.logic.show_if) {
      // If it's a single question that doesn't have any logic
      return invalidConditions
    } else {
      // If it's a single question moving up
      const currentItemRefs = {}
      logicForEach(question.logic.show_if.terms, (term, path) => {
        currentItemRefs[term.question_id] = currentItemRefs[term.question_id] || []
        currentItemRefs[term.question_id].push(path)
      })
      for (let i = _endIdx; i < startIdx; i++) {
        if (order[i] in currentItemRefs) {
          invalidConditions[itemId] = invalidConditions[itemId] || []
          invalidConditions[itemId] = invalidConditions[itemId].concat(currentItemRefs[order[i]])
        }
      }
    }
  } else if (currentSiblings) {
    // If it's sibling questions moving down
    currentSiblings.forEach(siblingQId => {
      const orderIndexOfSibling = order.indexOf(siblingQId)
      const endIdxToCheck = _endIdx + currentSiblings.length - 1

      for (let i = orderIndexOfSibling + 1; i <= endIdxToCheck; i++) {
        if (!questions[order[i]].logic.show_if) continue
        logicForEach(questions[order[i]].logic.show_if.terms, (term, path) => {
          if (term.question_id === siblingQId) {
            invalidConditions[order[i]] = invalidConditions[order[i]] || []
            invalidConditions[order[i]].push(path)
          }
        })
      }
    })
  } else {
    // If it's single questions moving down in order
    for (let i = startIdx + 1; i <= _endIdx; i++) {
      if (!questions[order[i]].logic.show_if) continue
      logicForEach(questions[order[i]].logic.show_if.terms, (term, path) => {
        if (term.question_id === order[startIdx]) {
          invalidConditions[order[i]] = invalidConditions[order[i]] || []
          invalidConditions[order[i]].push(path)
        }
      })
    }
  }
  return invalidConditions
}

export function findInvalidLogicOnDelete(questions, order, deleteId) {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (deleteId === term.question_id) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnToggleRequired(questions, order, toggleId) {
  const invalidConditions = {}
  if (questions[toggleId].attributes.required) return invalidConditions
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (toggleId === term.question_id && term.comparator.includes('answered')) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnChoiceDelete(questions, order, itemId, choiceId) {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (term.value === choiceId) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

/**
 * Find if any invalid conditions for subquestions in Matrix
 * @param {Object} questions
 * @param {Array} order
 * @param {Number} subQuestionId
 */
export const findInvalidLogicOnDeleteMatrixQuestion = async (questions, order, subQuestionId) => {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (term.sub_question_id === subQuestionId) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnChangeItemType(questions, order, itemId, prevType, nextType) {
  if (prevType === nextType) return {}
  const invalidConditions = {}
  if (prevType !== nextType) {
    order.forEach(id => {
      if (!questions[id].logic.show_if) return
      logicForEach(questions[id].logic.show_if.terms, (term, path) => {
        if (!term.comparator.includes('answered') && term.question_id === itemId) {
          invalidConditions[id] = invalidConditions[id] || []
          invalidConditions[id].push(path)
        }
      })
    })
  }
  return invalidConditions
}

function deleteInvalidLogic(invalidConditions) {
  return {
    type: DELETE_INVALID_CLINRO_LOGIC,
    invalidConditions,
  }
}

function updateItemLabel(itemId, oldEditorState, newEditorState) {
  return {
    type: UPDATE_CLINRO_ITEM_LABEL,
    itemId,
    oldEditorState,
    newEditorState,
  }
}
function updateClinroMatrixQuestionLabel(itemId, questionId, oldEditorState, newEditorState) {
  return {
    type: UPDATE_CLINRO_MATRIX_QUESTION_LABEL,
    itemId,
    questionId,
    oldEditorState,
    newEditorState,
  }
}

function updateItem(itemId, item) {
  return {
    type: UPDATE_CLINRO_ITEM,
    itemId,
    item,
  }
}

// Logic Actions
function updateLogicCondition({ itemId, logic, conditionPath, field, value, field2, value2 }) {
  return {
    type: UPDATE_CLINRO_LOGIC_CONDITION,
    itemId,
    conditionPath,
    field,
    value,
    logic,
    field2,
    value2,
  }
}

function addLogicCondition(itemId, groupPath) {
  return {
    type: ADD_CLINRO_LOGIC_CONDITION,
    itemId,
    groupPath,
  }
}

function addLogicGroup(itemId) {
  return {
    type: ADD_CLINRO_LOGIC_GROUP,
    itemId,
  }
}

function deleteLogicCondition(itemId, conditionPath) {
  return {
    type: DELETE_CLINRO_LOGIC_CONDITION,
    itemId,
    conditionPath,
  }
}

function deleteAllLogic(itemId) {
  return {
    type: DELETE_ALL_CLINRO_LOGIC,
    itemId,
  }
}

function changeLogicOperator(itemId, groupPath, value) {
  return {
    type: CHANGE_CLINRO_LOGIC_OPERATOR,
    itemId,
    groupPath,
    value,
  }
}

// Clinro API Functions

export const uploadImage = formData => {
  const success = payload => {
    const { full_path: src, image_id: imageId } = payload
    return {
      src,
      imageId,
    }
  }
  return dispatch => {
    return dispatch(
      request({
        method: 'POST',
        url: '/control/survey_image',
        contentType: 'multipart/form-data',
        body: formData,
        success,
        hasLoader: true,
        loadingKey: 'mediaUpload',
        successMessage: 'Image successfully uploaded.',
        catchMessage: 'There was a problem uploading the image. Please try again later.',
      }),
    )
  }
}

export function fetchClinro(studyID, instrumentId, version, useLoader) {
  return (dispatch, getState) => {
    const versionText = version ? `?version=${version}` : ''
    function success(json) {
      prepareClinro(json)
        .then(result => {
          const { formattedJSON, editorStates } = result
          calcQuestionNumbers(formattedJSON.order, formattedJSON.questions)
          return Promise.resolve({ formattedJSON, editorStates })
        })
        .then(result => {
          const { formattedJSON: _formattedJSON, editorStates: _editorStates } = result
          dispatch(initializeEdit(_formattedJSON, _editorStates))
          let errors = getClinroErrors(_formattedJSON, _editorStates)
          if (_formattedJSON?.scores?.length) {
            errors = {
              ...errors,
              ...getFormulaErrors(_formattedJSON),
            }
          }
          dispatch(setClinrosScore(instrumentId, _formattedJSON?.scores))
          dispatch(addClinroErrors(errors))

          const { location } = getState()
          const path = location.pathname.match(/([^/]+$)/)[0]
          const isScoringPage = path === INSTRUMENT_PATH_MAP.scoring

          // Render a noty to alert user there are score errors if there are
          if (isScoringPage && !!errors.scores) {
            dispatch(
              notyActions.showError({
                text: generateNotyMessage('Please check errors before proceeding.', false),
              }),
            )
          }

          return Promise.resolve()
        })
        .then(() => {
          dispatch(loadingActions.stopLoader(true))
        })
    }

    function fail(res) {
      dispatch(loadingActions.stopLoader(true))
      throw new Error(`${res.status} ${res.statusText} when fetching instrument`)
    }
    if (!useLoader) dispatch(loadingActions.startLoader(true))
    return dispatch(
      request({
        method: 'GET',
        url: `/control/admin/studies/${studyID}/instruments/${instrumentId}/clinro.json${versionText}`,
        success,
        fail,
      }),
    )
  }
}

export function validateClinroAndSave({ studyID, clinroEditor, onRedirect, redirect, path }) {
  return dispatch => {
    let errors = getClinroErrors(clinroEditor.clinro, clinroEditor.editorStates)

    /**
     * This boolean is created to allow users to save the instrument even if there are formula
     * errors if they are not navigating to or from the scoring page.
     */
    const isScoringPath = [redirect, path].includes(INSTRUMENT_PATH_MAP.scoring)

    if (clinroEditor.clinro?.scores?.length && isScoringPath) {
      errors = {
        ...errors,
        ...getFormulaErrors(clinroEditor.clinro),
      }
    }
    const redirectIsScoring = redirect === 'scoring'
    const hasScoringErrors = !!errors.scores && Object.keys(errors.scores).length > 0
    const hasQuestionErrors = Object.keys(errors.questions).length > 0
    const hasAnyError = Object.keys(errors).length > 1
    const valid = !(hasScoringErrors || hasQuestionErrors || hasAnyError)
    if (valid) {
      // remove [] used as marker for end of domain ID
      clinroEditor.clinro.scores = clinroEditor.clinro.scores?.map(score => {
        return {
          ...score,
          formula: replaceTargetsWithVal(score.formula, ['[]'], ''),
        }
      })
    }
    if (hasScoringErrors && redirectIsScoring && !hasQuestionErrors) {
      dispatch(saveClinroToDatabase({ studyID, ...clinroEditor }))
        .then(onRedirect)
        .then(() => {
          dispatch(addClinroErrors(errors))
        })
    } else if (valid) {
      dispatch(clearClinroErrors())
      dispatch(saveClinroToDatabase({ studyID, ...clinroEditor })).then(onRedirect)
    } else {
      dispatch(addClinroErrors(errors))
      dispatch(
        notyActions.showError({
          text: generateNotyMessage('Please check errors before proceeding.', false),
        }),
      )
    }
  }
}

export function _saveClinro(studyID, body, clinroID, isClinro = true) {
  let reqBody = body
  if (clinroID) {
    const { data } = body
    reqBody = data
  }
  return dispatch => {
    const url = `/control/studies/${studyID}/instruments${clinroID === undefined ? '' : `/${clinroID}/clinro.json`}`
    return dispatch(
      request({
        url,
        body: isClinro ? body : JSON.stringify(reqBody),
        method: clinroID ? 'PUT' : 'POST',
        successMessage: 'Instrument saved successfully',
        hasLoader: true,
        failMessage: 'Failed to save instrument to database.',
        success: json => json,
      }),
    )
  }
}

function cleanUpClinro(_clinro) {
  // Remove unnecessary fields
  delete _clinro.activeItemId
  for (const qId in _clinro.questions) {
    removeValuesAndLabels(_clinro.questions[qId])
    removeChoices(_clinro.questions[qId])
    delete _clinro.questions[qId].images
    delete _clinro.questions[qId].label_images
    delete _clinro.questions[qId].attributes.canHaveLogic
    delete _clinro.questions[qId].attributes.questionNumber
  }
  if (Object.keys(_clinro.questionsWithScoring).length === 0) {
    _clinro.scores = []
  }
}

export function saveClinroToDatabase({ clinro: _clinro, editorStates: _editorStates, studyID }) {
  return dispatch => {
    const clinroJSON = JSON.parse(JSON.stringify(_clinro))
    cleanUpClinro(clinroJSON)

    /**
     * This function will resolve any mismatches between a question's ID and
     * its choices' IDs.
     *  */
    validateAndFixQuestionAndChoiceIdMismatches(clinroJSON)

    clinroJSON.metadata.display_name = clinroJSON.metadata.display_name || clinroJSON.title
    let imageList = []
    const requiredPermissions = []
    for (const qId in clinroJSON.questions) {
      const question = clinroJSON.questions[qId]
      if (question.type === QUESTION_TYPE_MAP.audioRecording) {
        if (!requiredPermissions.includes(QUESTION_TYPE_MAP.audioRecording))
          requiredPermissions.push(QUESTION_TYPE_MAP.audioRecording)
      }
      if (question.type === QUESTION_TYPE_MAP.imageCapture) {
        if (question.preview_images)
          question.preview_images = question.preview_images.filter(imageId => imageId !== null)
      }

      const { questionsWithScoring } = clinroJSON
      const { domains } = question
      const { hasScoring } = question.attributes

      if (question.type !== QUESTION_TYPE_MAP.imageCapture) {
        delete question.preview_images
        delete question.previewImagesSrc
        delete question.camera_tip
      }

      // logic to handle domains and scoring
      if (!QUESTION_TYPES_WITH_SCORING.includes(question.type)) {
        if (hasScoring) {
          delete question.attributes.hasScoring
          delete question.formula
          delete questionsWithScoring[qId]
          if (domains) {
            domains.forEach(domainId => {
              const domainIsNotUsedByInstrument = !getDomainIsUtilized({ questionsWithScoring, domainId })
              if (domainIsNotUsedByInstrument) {
                delete clinroJSON.domains[domainId]
              }
            })
          }
          delete question.domains
          delete question.formula
        }
      } else if (!hasScoring) {
        delete question.attributes.hasScoring
        delete questionsWithScoring[qId]
        if (domains) {
          domains.forEach(domainId => {
            const domainIsNotUsedByInstrument = !getDomainIsUtilized({ questionsWithScoring, domainId })
            if (domainIsNotUsedByInstrument) {
              delete clinroJSON.domains[domainId]
            }
          })
        }
        delete question.domains // if a question doesn't have scoring, we'll cleanup and delete the domains
        delete question.formula
      } else if (![QUESTION_TYPE_MAP.selectMultiple, QUESTION_TYPE_MAP.matrix].includes(question.type)) {
        delete question.formula
      } else if (question.type === QUESTION_TYPE_MAP.matrix && !question.attributes.allowMultipleAnswers) {
        delete question.formula
      }

      if (question.image) imageList = [...imageList, question.image]
      for (const choiceId in question.choices) {
        const choice = question.choices[choiceId]
        if (!hasScoring) {
          delete choice.score_value
        } else {
          const { score_value } = choice
          if (score_value || score_value === 0) choice.score_value = Number(choice.score_value)
          else choice.score_value = ''
        }
        if (question.type === QUESTION_TYPE_MAP.multipleField) {
          if (!question.attributes.hasCharLimit) {
            // deletes a choice's min and max key if the question does not have input validation
            delete choice.max
            delete choice.min
          } else if (
            (choice.max === '' || choice.max === undefined) &&
            (choice.min === '' || choice.min === undefined)
          ) {
            delete choice.max
            delete choice.min
          } else if ((choice.max === '' || choice.max === undefined) && choice.min !== '') {
            choice.max = 999999999
          } else if ((choice.min === '' || choice.min === undefined) && choice.max !== '') {
            choice.min = 0
          }
          if (choice.type === QUESTION_TYPE_MAP.text) delete choice.min
          const choiceLabel = choice.label
          choice.choice_label = choiceLabel
          delete choice.label
        }
        if (choice.image) {
          imageList = [...imageList, choice.image]
          choice.label = `${choice.label}<img src="${choice.image}"/>`
        }
      }
    }

    if (imageList.length) {
      const uniqImageList = [...new Set(imageList)]
      clinroJSON.image_list = uniqImageList
    }

    clinroJSON.required_permissions = requiredPermissions

    // this removes duplicate image_ids
    clinroJSON.image_list = clinroJSON.image_list.filter((item, pos) => {
      return clinroJSON.image_list.indexOf(item) === pos
    })

    convertLabelsToHTML(clinroJSON.questions, _editorStates)

    function success(resJSON) {
      dispatch({
        type: UPDATE_ORIGINAL_CLINRO,
        json: _clinro,
      })
      return Promise.resolve(resJSON)
    }

    const body = JSON.stringify(_clinro.id ? clinroJSON : { data: clinroJSON, format: 'json' })
    return dispatch(_saveClinro(studyID, body, _clinro.id)).then(success)
  }
}

export function saveClinroUploadToDatabase(studyID, uploadedClinro) {
  return (dispatch, getState) => {
    let data = JSON.parse(uploadedClinro.data)
    const { participants, user } = getState()
    data = cleanUploadedJSON(data, participants, user, studyID)

    const body = {
      format: uploadedClinro.format,
      data,
    }
    return dispatch(_saveClinro(studyID, body, uploadedClinro.id, null))
  }
}

//
// State Generators
//

function generateBlankClinro(initialTitle, displayName) {
  return {
    title: initialTitle || '',
    type: 'CLINRO',
    metadata: _getDefaultMetadata('CLINRO', displayName),
    order: [[]],
    questions: {},
    questionsWithScoring: {},
    image_list: [],
    scores: [],
  }
}

//
// Util Functions
//

// this creates image elements that will render in the clinician instrument editor

const imageRegex = /<img src(.*)\/>/g
const pOpen = /<p>/g
const pClose = /<\/p>/g

const removeImageElementFromLabel = label => {
  let newLabel = label.slice()
  newLabel = newLabel.replace(imageRegex, '')
  newLabel = newLabel.replace(pOpen, '')
  newLabel = newLabel.replace(pClose, '')
  return newLabel
}

export function prepareClinro(json) {
  const _editorStates = {}
  for (const qId in json.questions) {
    // convert note to introduction
    const question = json.questions[qId]
    _editorStates[qId] = formatLinkEntities(HTMLToEditorState(question.label))
    const label = stateToHTML(_editorStates[qId].getCurrentContent())
    question.label = removeImageElementFromLabel(label)
    if (question.type === 'note') {
      question.type = QUESTION_TYPE_MAP.introduction
    } else if (
      question.type === QUESTION_TYPE_MAP.selectOne &&
      question.attributes.appearance === QUESTION_TYPE_MAP.likert
    ) {
      question.type = QUESTION_TYPE_MAP.likert
    } else if (question.type === QUESTION_TYPE_MAP.matrix) {
      question.questions_order.forEach(matrixQuestionId => {
        _editorStates[matrixQuestionId] = formatLinkEntities(
          HTMLToEditorState(question.questions[matrixQuestionId].label),
        )
      })
    }
    if (question.attributes.hasScoring && !question.formula) {
      question.formula = 'MEAN'
    }
    delete question.attributes.appearance
    // encapsulate logic conditions if they aren't in a boolean combine
    if (Object.keys(question.logic).length > 0 && question.logic.show_if.comparator !== 'boolean_combine') {
      question.logic.show_if = {
        comparator: 'boolean_combine',
        operator: 'and',
        terms: [question.logic.show_if],
      }
    }
    const { choices_order, image, resources } = question
    if (image) {
      addMediaToFileMap({ _instrument: json, fileId: image, itemId: qId })
    } else if (resources) {
      const { audio, video } = resources
      addMediaToFileMap({ _instrument: json, fileId: audio || video, itemId: qId })
    }
    if (choices_order) {
      choices_order.forEach(choiceId => {
        const choice = question.choices[choiceId]
        const { score_value } = choice
        if (choice.image) {
          choice.label = removeImageElementFromLabel(choice.label)
          addMediaToFileMap({ _instrument: json, fileId: choice.image, itemId: qId })
        }
        if (score_value || score_value === 0) choice.score_value = score_value.toString()
        if (SELECT_QUESTION_TYPES.includes(question.type)) {
          _editorStates[choiceId] = HTMLToEditorState(choice.label)
        }
      })
    }
    delete json.required_permissions
  }

  if (!json.questionsWithScoring) {
    json.questionsWithScoring = {}
  }
  if (!json.scores) {
    json.scores = []
  }
  return Promise.resolve({
    formattedJSON: json,
    editorStates: _editorStates,
  })
}

function convertLabelsToHTML(questions, _editorStates) {
  for (const key in questions) {
    questions[key].label = EditorStateToHTML(_editorStates[key])
    const question = questions[key]
    if (question.image) {
      question.label = removeImageElementFromLabel(question.label)
      question.label = `${question.label}<img src="${question.image}"/>`
    }
  }
}

function countWords(string) {
  if (string === '') return 0
  return string.trim().split(' ').length
}

function countWordsInClinro(clinro) {
  return clinro.order[0].reduce((sum, itemId) => {
    return sum + countWords(clinro.questions[itemId].label)
  }, 0)
}

export const calcQuestionNumbers = (order, questions) => {
  let count = 0
  order[0].forEach(id => {
    if (!QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS.includes(questions[id]?.type)) {
      count++
      questions[id].attributes.questionNumber = count
      questions[id].attributes.canHaveLogic = count > 1
    } else {
      questions[id].attributes.canHaveLogic = count >= 1
    }
  })
}

function getRandomizedOrder(order = [], siblingsObj = {}) {
  const createSiblingMap = siblings => {
    const map = {}
    Object.values(siblings).forEach(siblingGroup => {
      const firstSibling = siblingGroup[0]
      map[firstSibling] = siblingGroup
    })
    return map
  }

  let updatedOrder = order
  const siblingIds = Object.keys(siblingsObj)
  if (siblingIds.length) {
    // Prevent changing order of siblings
    const siblingMap = createSiblingMap(siblingsObj)
    const orderWithGroupedSiblings = []
    for (let i = 0; i < updatedOrder.length; i++) {
      const currentOrderId = updatedOrder[i]
      const siblingGroup = siblingMap[currentOrderId]
      if (siblingGroup) {
        orderWithGroupedSiblings.push(siblingGroup)
        i += siblingGroup.length - 1
      } else {
        orderWithGroupedSiblings.push(currentOrderId)
      }
    }
    updatedOrder = orderWithGroupedSiblings
  }
  return getRandomizedArray(updatedOrder).flat()
}

function dupStateAndItem(state, itemId) {
  const newState = { ...state }
  newState.questions = { ...newState.questions }
  newState.questions[itemId] = { ...newState.questions[itemId] }
  return newState
}

function getCondition(terms, conditionPath) {
  if (conditionPath.length === 1) {
    return terms[conditionPath[0]]
  }
  return getCondition(terms[conditionPath[0]].terms, conditionPath.slice(1))
}

function setCondition(terms, conditionPath, newValue) {
  if (conditionPath.length === 1) {
    terms[conditionPath[0]] = newValue
  } else {
    setCondition(terms[conditionPath[0]].terms, conditionPath.slice(1), newValue)
  }
}

function addCondition(terms, groupPath) {
  if (groupPath.length === 1) {
    terms[groupPath[0]].terms.push({
      question_type: '',
      question_id: '',
      value: '',
      comparator: '',
    })
  } else {
    addCondition(terms[groupPath[0]].terms, groupPath.slice(1))
  }
}

function deleteCondition(showIf, conditionPath) {
  if (conditionPath.length === 1) {
    showIf.terms.splice(conditionPath[0], 1)
  } else {
    deleteCondition(showIf.terms[conditionPath[0]], conditionPath.slice(1))
    if (showIf.terms[conditionPath[0]].terms.length === 0) {
      showIf.terms.splice(conditionPath[0], 1)
    }
  }
}

function removeLoneGroup(logic) {
  if (logic.show_if.terms.length < 1) {
    delete logic.show_if
  } else if (logic.show_if.terms[0].comparator === 'boolean_combine' && logic.show_if.terms.length === 1) {
    logic.show_if.terms = logic.show_if.terms[0].terms
  }
}

function setOperator(logic, groupPath, value) {
  if (groupPath.length === 0) {
    logic.operator = value
  } else {
    setOperator(logic.terms[groupPath[0]], groupPath.slice(1), value)
  }
}

function generateNewScoreObject(id = getRandomQuestionId()) {
  return {
    id,
    name: '',
    description: '',
    formula: '',
    tagFormula: '',
  }
}

//
// Reducers
//

export function clinro(state = generateBlankClinro(), action) {
  const defaultItem = {
    type: action.itemType,
    label: '',
    choices: {},
    choices_order: [],
    attributes: { required: true, hasCharLimit: false, hasScoring: false },
    logic: {},
    comments: '',
  }
  switch (action.type) {
    case INITIALIZE_BLANK_CLINRO: {
      return generateBlankClinro(action.title, action.displayName)
    }
    case INITIALIZE_CLINRO_EDIT: {
      return action.json
    }
    case UPDATE_CLINRO_TITLE: {
      return { ...state, title: action.title }
    }
    case TOGGLE_CLINRO_EDIT: {
      const newState = { ...state }
      newState.activeItemId = action.itemId
      return newState
    }
    case UPDATE_CLINRO_ITEM: {
      const newState = dupStateAndItem(state, action.itemId)
      Object.assign(newState.questions[action.itemId], action.item)
      return newState
    }
    case UPDATE_CLINRO_ITEM_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].label = stateToHTML(action.newEditorState.getCurrentContent())
      return newState
    }
    case UPDATE_CLINRO_MATRIX_QUESTION_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].questions[action.questionId].label = stateToHTML(
        action.newEditorState.getCurrentContent(),
      )
      return newState
    }

    case CHANGE_CLINRO_ITEM_TYPE: {
      const newState = dupStateAndItem(state, action.itemId)
      const question = newState.questions[action.itemId]
      question.type = action.nextType

      if (action.prevType === action.nextType) return newState // no change if type is the same

      if (!QUESTION_TYPES_WITH_SCORING.includes(action.nextType)) {
        const { domains } = question
        const { hasScoring } = question.attributes
        if (hasScoring) {
          delete question.attributes.hasScoring
          delete question.formula
          delete newState.questionsWithScoring[action.itemId]
          if (domains) {
            domains.forEach(domainId => {
              const domainIsNotUsedByInstrument = !getDomainIsUtilized({
                questionsWithScoring: newState.questionsWithScoring,
                domainId,
              })
              if (domainIsNotUsedByInstrument) {
                delete newState.domains[domainId]
              }
            })
          }
          delete question.domains
          delete question.formula
        }
      }

      if (SELECT_QUESTION_TYPES.includes(action.nextType)) {
        delete question.attributes.other
        while (question.choices_order.length < 2) {
          // when a question type is changed to select type, the question is given a minimum of two choices
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
        question.choices_order.forEach(choiceId => {
          delete question.choices[choiceId].value
        })
      }

      if (action.nextType === QUESTION_TYPE_MAP.likert) {
        delete question.attributes.other
        while (question.choices_order.length < 5) {
          // when a question type is changed to likert, the question is given a minimum of five choices
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
        if (question.choices_order.length > 7) {
          question.choices_order.length = 7
        } else if (question.choices_order.length === 6) {
          const newChoiceId = action.itemId + getRandomQuestionId()
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
      }

      if (action.nextType === QUESTION_TYPE_MAP.numericRatingScale) {
        delete question.attributes.other

        /*
         * this prevents the uncontolled input error that occurs when multipleField questions
         * don't have value (the value inside the button)
         */
        if (
          [
            QUESTION_TYPE_MAP.selectOne,
            QUESTION_TYPE_MAP.selectMultiple,
            QUESTION_TYPE_MAP.likert,
            QUESTION_TYPE_MAP.numericRatingScale,
          ].includes(action.prevType)
        ) {
          question.choices_order.forEach(choiceId => {
            question.choices[choiceId].value = ''
          })
        }

        while (question.choices_order.length < 2) {
          // when a question type is changed to numeric rating scale type, the question is given a minimum of two choices
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
        if (question.choices_order.length > 11) {
          question.choices_order.length = 11
        }
      }
      if (action.nextType === QUESTION_TYPE_MAP.multipleField) {
        question.choices = {} // deletes all prevous choices, and creates a new choice with a blank label and default type of QUESTION_TYPE_MAP.text
        question.choices_order = []
        delete question.attributes.other
        while (question.choices_order.length < 1) {
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '', type: QUESTION_TYPE_MAP.text }
          question.choices_order.push(newChoiceId)
        }
        question.attributes.inputWidth = 'medium'
      }

      if (action.nextType === QUESTION_TYPE_MAP.vaScale) {
        question.scale_labels = {
          top: '',
          bottom: '',
        }
      }

      if (action.nextType === QUESTION_TYPE_MAP.vasHorizontal) {
        question.scale_labels = {
          top_value: '100',
          top: '',
          mid_value: '50',
          mid: '',
          bottom_value: '0',
          bottom: '',
        }
        question.hint = ''
      }

      if (action.nextType === QUESTION_TYPE_MAP.audioRecording) {
        question.attributes.min = ''
        question.attributes.required = true
      }

      if (!QUESTION_TYPES_WITH_CHOICES.includes(question.type)) {
        question.choices = {}
        question.choices_order = []
      }

      if (
        [QUESTION_TYPE_MAP.integer, QUESTION_TYPE_MAP.decimal].includes(action.prevType) &&
        ![QUESTION_TYPE_MAP.integer, QUESTION_TYPE_MAP.decimal].includes(action.nextType)
      ) {
        delete question.attributes.unit
      }

      if (action.nextType === QUESTION_TYPE_MAP.matrix) {
        while (question.choices_order.length < 2) {
          // when a question type is changed to numeric rating scale type, the question is given a minimum of two choices
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
        if (question.choices_order.length > 7) {
          question.choices_order.length = 7
        }
        if (!question.questions) {
          question.questions = {
            [action.newQuestionId]: {
              label: '',
            },
          }
          question.questions_order = [action.newQuestionId]
        }
        question.attributes.allowMultipleAnswers = false
      }
      if (action.prevType === QUESTION_TYPE_MAP.matrix) {
        delete question.questions
        delete question.questions_order
        delete question.attributes.allowMultipleAnswers
      }

      if (action.nextType === QUESTION_TYPE_MAP.imageCapture) {
        question.choices = {}
        question.choices_order = []
      } else {
        delete question.preview_images
        delete question.previewImagesSrc
        delete question.camera_tip
      }

      // Remove 'type' key from non-multiple_field questions
      if (
        action.prevType === QUESTION_TYPE_MAP.multipleField &&
        QUESTION_TYPES_WITH_CHOICES.includes(action.nextType)
      ) {
        question.choices_order.forEach(choiceId => {
          delete question.choices[choiceId].type
        })
      }

      /**
       * Removing media if the new item type does not suppport media
       *  */

      // Removing images
      if (
        QUESTION_TYPES_WITH_IMAGE.includes(action.prevType) &&
        action?.item?.image &&
        !QUESTION_TYPES_WITH_IMAGE.includes(action.nextType)
      ) {
        const fileName = newState.questions[action.itemId].image
        delete newState.questions[action.itemId].image
        delete newState.questions[action.itemId].imageSrc

        deleteMediaFromInstrumentJson({ _instrument: newState, fileId: fileName, itemId: action.itemId })
      }
      // Removing video or audio
      if (
        (QUESTION_TYPES_WITH_VIDEO.includes(action.prevType) &&
          action?.item?.resources?.video &&
          !QUESTION_TYPES_WITH_VIDEO.includes(action.nextType)) ||
        (QUESTION_TYPES_WITH_AUDIO.includes(action.prevType) &&
          action?.item?.resources?.audio &&
          !QUESTION_TYPES_WITH_AUDIO.includes(action.nextType))
      ) {
        const { audio } = action.item.resources
        const fileName = newState.questions[action.itemId].resources[audio ? 'audio' : 'video']

        deleteMediaFromInstrumentJson({
          _instrument: newState,
          fileId: fileName,
          itemId: action.itemId,
          isImage: false,
        })

        delete newState.questions[action.itemId].resources
        delete newState.questions[action.itemId].mediaSrc
      }
      // Removing images from choices
      if (!QUESTION_TYPES_WITH_CHOICE_IMAGES.includes(action.nextType) && action?.item?.choices_order) {
        action.item.choices_order.forEach(choiceId => {
          const fileName = action.item.choices[choiceId].image
          if (fileName) {
            deleteMediaFromInstrumentJson({ _instrument: newState, fileId: fileName, itemId: action.itemId })

            if (newState.questions[action.itemId].choices[choiceId]?.image) {
              delete newState.questions[action.itemId].choices[choiceId].image
              delete newState.questions[action.itemId].choices[choiceId].imageSrc
            }
          }
        })
      }

      return newState
    }
    case UPDATE_CLINRO_UNIT_TYPE: {
      const newState = dupStateAndItem(state, action.itemId)
      if (!action.unit) {
        delete newState.questions[action.itemId].attributes.unit
      } else {
        newState.questions[action.itemId].attributes.unit = action.unit
      }
      return newState
    }
    case TOGGLE_CLINRO_REQUIRED_QUESTION: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.required = !newState.questions[action.itemId].attributes.required
      return newState
    }
    case TOGGLE_CLINRO_QUESTION_HAS_SCORING: {
      const newState = dupStateAndItem(state, action.itemId)
      const nextHasScoringState = !newState.questions[action.itemId].attributes.hasScoring
      newState.questions[action.itemId].attributes.hasScoring = nextHasScoringState
      newState.questions[action.itemId].formula = 'MEAN'

      if (nextHasScoringState) {
        newState.questionsWithScoring = {
          ...newState.questionsWithScoring,
          [action.itemId]: action.domains,
        }
      } else {
        delete newState.questionsWithScoring[action.itemId]
      }
      return newState
    }
    case SET_CLINRO_SCORES_EDITED: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.nonDefaultScoring = true
      return newState
    }
    case TOGGLE_CLINRO_INPUT_VALIDATION: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.hasCharLimit = !newState.questions[action.itemId].attributes
        .hasCharLimit
      return newState
    }
    case ADD_CLINRO_ITEM: {
      const newState = { ...state }
      if (action.index === undefined) {
        newState.order[0].push(action.newQId)
      } else {
        newState.order[0].splice(action.index + 1, 0, action.newQId)
      }
      newState.questions[action.newQId] = defaultItem

      if (action.itemType === QUESTION_TYPE_MAP.selectOne) {
        const question = newState.questions[action.newQId]
        delete question.attributes.other
        while (question.choices_order.length < 2) {
          const newChoiceId = `${action.newQId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '', score_value: '' }
          question.choices_order.push(newChoiceId)
        }
      }
      calcQuestionNumbers(newState.order, newState.questions)
      newState.activeItemId = action.newQId
      return newState
    }
    case ADD_CLINRO_SIBLING_ITEM: {
      const newState = { ...state }

      if (action.index === undefined) {
        newState.order[0].push(action.newQId)
      } else {
        newState.order[0].splice(action.index + 1, 0, action.newQId)
      }
      newState.questions[action.siblingQId].sibling_id = action.siblingId
      newState.questions[action.newQId] = defaultItem
      newState.questions[action.newQId].sibling_id = action.siblingId

      if (action.itemType === QUESTION_TYPE_MAP.selectOne) {
        const question = newState.questions[action.newQId]
        delete question.attributes.other
        while (question.choices_order.length < 2) {
          const newChoiceId = `${action.newQId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '', score_value: '' }
          question.choices_order.push(newChoiceId)
        }
      }
      calcQuestionNumbers(newState.order, newState.questions)
      newState.activeItemId = action.newQId
      const { siblings } = newState
      if (siblings) {
        if (siblings[action.siblingId]) {
          const siblingIndex = siblings[action.siblingId].indexOf(action.siblingQId)
          newState.siblings[action.siblingId].splice(siblingIndex + 1, 0, action.newQId)
        } else {
          newState.siblings[action.siblingId] = [action.siblingQId, action.newQId]
        }
      } else {
        newState.siblings = {}
        newState.siblings[action.siblingId] = [action.siblingQId, action.newQId]
      }
      return newState
    }
    case DELETE_CLINRO_ITEM: {
      const newState = { ...state }
      const { questionsWithScoring } = newState
      const question = newState.questions[action.itemId]
      const { sibling_id, domains } = question
      if (sibling_id) {
        const siblingsArr = newState.siblings[sibling_id]
        newState.siblings[sibling_id] = siblingsArr.filter(itemId => itemId !== action.itemId)
        if (newState.siblings[sibling_id].length === 1) {
          const lastSiblingQId = newState.siblings[sibling_id][0]
          delete newState.questions[lastSiblingQId].sibling_id
          delete newState.siblings[sibling_id]
        }
      }
      delete newState.questionsWithScoring[action.itemId]
      if (domains?.length) {
        domains.forEach(domainId => {
          const domainIsNotUsedByInstrument = !getDomainIsUtilized({ questionsWithScoring, domainId })
          if (domainIsNotUsedByInstrument) delete newState.domains[domainId]
        })
        const { domains: surveyDomains } = newState
        // If there are no domains, delete all the scores
        if (Object.keys(surveyDomains).length === 0) newState.scores = []
      }

      // Delete question's media data from survey if not being used in another question
      const { image, resources, preview_images } = question
      if (image) {
        deleteMediaFromInstrumentJson({
          _instrument: newState,
          fileId: image,
          itemId: action.itemId,
        })
      } else if (resources) {
        const { audio } = resources
        const fileName = newState.questions[action.itemId].resources[audio ? 'audio' : 'video']
        deleteMediaFromInstrumentJson({
          _instrument: newState,
          fileId: fileName,
          itemId: action.itemId,
          isImage: false,
        })
      }
      if (preview_images) {
        preview_images.forEach(imageId => {
          deleteMediaFromInstrumentJson({
            _instrument: newState,
            fileId: imageId,
            itemId: action.itemId,
          })
        })
      }

      if (QUESTION_TYPES_WITH_CHOICE_IMAGES.includes(question.type)) {
        question.choices_order.forEach(choiceId => {
          const choice = question.choices[choiceId]
          const fileName = choice?.image
          if (fileName) {
            deleteMediaFromInstrumentJson({ _instrument: newState, fileId: fileName, itemId: action.itemId })
          }
        })
      }

      delete newState.questions[action.itemId]
      newState.order[0].splice(newState.order[0].indexOf(action.itemId), 1)
      calcQuestionNumbers(newState.order, newState.questions)
      if (action.itemId === newState.activeItemId) {
        newState.activeItemId = null
      }
      return newState
    }
    case ADD_CLINRO_ITEM_IMAGE: {
      const newState = { ...state }
      if (!action.imageId) return newState
      const { resource_size_list } = newState
      newState.image_list = newState.image_list ? addUniqToArr(newState.image_list, action.imageId) : [action.imageId]
      newState.questions[action.itemId].image = action.imageId
      newState.questions[action.itemId].imageSrc = action.imageSrc

      const fileResourceObject = {
        file_name: action.imageId,
        size: action.size,
        type: MEDIA_TYPE_MAP.image,
      }
      if (resource_size_list) {
        resource_size_list.push(fileResourceObject)
      } else newState.resource_size_list = [fileResourceObject]

      addMediaToFileMap({ _instrument: newState, fileId: action.imageId, itemId: action.itemId })
      return newState
    }
    case DELETE_CLINRO_ITEM_IMAGE: {
      const newState = { ...state }
      delete newState.questions[action.itemId].image
      delete newState.questions[action.itemId].imageSrc
      deleteMediaFromInstrumentJson({
        _instrument: newState,
        fileId: action.imageId,
        itemId: action.itemId,
      })
      return newState
    }

    case ADD_CLINRO_PREVIEW_IMAGE: {
      const newState = { ...state }
      if (!action.imageId) return newState
      const { resource_size_list } = newState
      const question = newState.questions[action.itemId]
      newState.image_list = newState.image_list ? addUniqToArr(newState.image_list, action.imageId) : [action.imageId]
      if (!question.preview_images) question.preview_images = []
      if (!question.previewImagesSrc) question.previewImagesSrc = {}
      question.preview_images[action.imageOrder] = action.imageId
      question.previewImagesSrc[action.imageId] = action.imageSrc

      const fileResourceObject = {
        file_name: action.imageId,
        size: action.size,
        type: MEDIA_TYPE_MAP.image,
      }
      if (resource_size_list) {
        resource_size_list.push(fileResourceObject)
      } else newState.resource_size_list = [fileResourceObject]

      addMediaToFileMap({ _instrument: newState, fileId: action.imageId, itemId: action.itemId })
      return newState
    }
    case DELETE_CLINRO_PREVIEW_IMAGE: {
      const newState = { ...state }
      const question = newState.questions[action.itemId]
      question.preview_images[action.imageOrder] = null
      delete question.previewImagesSrc[action.imageId]
      if (question.preview_images.length === 0 || Object.keys(question.previewImagesSrc).length === 0) {
        delete question.preview_images
        delete question.previewImagesSrc
      }
      deleteMediaFromInstrumentJson({
        _instrument: newState,
        fileId: action.imageId,
        itemId: action.itemId,
      })

      return newState
    }

    case MOVE_CLINRO_ITEM: {
      const newState = { ...state }
      newState.order = newState.order.slice()
      newState.questions = { ...newState.questions }
      const { clinro: _clinro, endIdx, selectedItemArr, startIdx } = action
      const _endIdx = returnEndIdx({ clinro: _clinro, endIdx, selectedItemArr, startIdx })

      if (selectedItemArr) {
        const order = newState.order.slice()[0].filter(questionId => !selectedItemArr.includes(questionId))
        newState.order[0] = [...order.slice(0, _endIdx), ...selectedItemArr, ...order.slice(_endIdx, order.length)]
      } else {
        newState.order[0].splice(_endIdx, 0, newState.order[0].splice(startIdx, 1)[0])
      }
      calcQuestionNumbers(newState.order, newState.questions)
      return newState
    }
    case DUPLICATE_CLINRO_ITEM: {
      const newState = { ...state }
      newState.order = newState.order.slice()
      newState.questions = { ...newState.questions }
      newState.questions[action.newQId] = JSON.parse(JSON.stringify(newState.questions[action.itemId]))
      const newIndex = newState.order[0].indexOf(action.itemId) + 1
      newState.order[0].splice(newIndex, 0, action.newQId)
      calcQuestionNumbers(newState.order, newState.questions)
      const question = newState.questions[action.itemId]
      const { hasScoring } = question.attributes
      if (hasScoring) newState.questionsWithScoring[action.newQId] = question.domains
      if (action.hasChoices) {
        const duplicateQuestion = newState.questions[action.newQId]
        const newChoicesOrder = action.choicesOrder.map(choiceId => action.newChoicesIdMap[choiceId])
        duplicateQuestion.choices_order = newChoicesOrder
        action.choicesOrder.forEach(choiceId => {
          const newChoiceId = action.newChoicesIdMap[choiceId]
          duplicateQuestion.choices[newChoiceId] = JSON.parse(JSON.stringify(duplicateQuestion.choices[choiceId]))
          delete duplicateQuestion.choices[choiceId]
        })
      }
      if (action.isMatrix) {
        const duplicateQuestion = newState.questions[action.newQId]
        const newQuestionsOrder = action.questionsOrder.map(
          matrixQuestionId => action.newQuestionIdMap[matrixQuestionId],
        )
        duplicateQuestion.questions_order = newQuestionsOrder
        action.questionsOrder.forEach(matrixQuestionId => {
          const newQuestionId = action.newQuestionIdMap[matrixQuestionId]
          duplicateQuestion.questions[newQuestionId] = JSON.parse(
            JSON.stringify(duplicateQuestion.questions[matrixQuestionId]),
          )
          delete duplicateQuestion.questions[matrixQuestionId]
        })
      }

      /**
       * If the duplicated question has any media, we need to add the new question ID of
       * the duplicated question item to the file_map
       */
      const { type } = question
      if (QUESTION_TYPES_WITH_IMAGE.includes(type)) {
        const { image, preview_images } = question
        if (newState.file_map) {
          if (image && newState.file_map[image]) {
            addMediaToFileMap({ _instrument: newState, fileId: image, itemId: action.newQId })
          }
          if (QUESTION_TYPE_MAP.imageCapture === type && preview_images) {
            preview_images.forEach(imageId => {
              if (image !== null && newState.file_map[imageId]) {
                addMediaToFileMap({ _instrument: newState, fileId: imageId, itemId: action.newQId })
              }
            })
          }
          if (QUESTION_TYPES_WITH_CHOICE_IMAGES.includes(newState.questions[action.itemId].type)) {
            newState.questions[action.itemId].choices_order.forEach(choiceId => {
              const { image: choiceImage } = newState.questions[action.itemId].choices[choiceId]
              if (choiceImage && newState.file_map[choiceImage]) {
                addMediaToFileMap({ _instrument: newState, fileId: choiceImage, itemId: action.newQId })
              }
            })
          }
        }
      }
      if (QUESTION_TYPES_WITH_AUDIO.includes(type) || QUESTION_TYPES_WITH_VIDEO.includes(type)) {
        const { resources } = question
        if (resources) {
          const { audio, video } = resources
          addMediaToFileMap({ _instrument: newState, fileId: audio || video, itemId: action.newQId })
        }
      }
      return newState
    }
    case ADD_CLINRO_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
      newState.questions[action.itemId].choices[newChoiceId] = { label: '', score_value: '' }
      if (action.itemType === QUESTION_TYPE_MAP.numericRatingScale) {
        newState.questions[action.itemId].choices[newChoiceId].value = ''
      }
      if (action.itemType === QUESTION_TYPE_MAP.multipleField)
        newState.questions[action.itemId].choices[newChoiceId].type = QUESTION_TYPE_MAP.text
      if (action.insertIdx) {
        newState.questions[action.itemId].choices_order.splice(action.insertIdx, 0, newChoiceId)
      } else {
        newState.questions[action.itemId].choices_order.push(newChoiceId)
      }
      return newState
    }
    case ADD_CLINRO_CHOICE_ON_PASTE: {
      if (action.pasteText === '') return state
      const newState = dupStateAndItem(state, action.itemId)
      action.pasteText.split('\n').forEach(line => {
        const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
        newState.questions[action.itemId].choices[newChoiceId] = { label: line }
        newState.questions[action.itemId].choices_order.push(newChoiceId)
      })
      return newState
    }
    case ADD_CLINRO_OTHER_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.other = 'Other'
      return newState
    }
    case DELETE_CLINRO_OTHER_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].attributes.other
      return newState
    }
    case UPDATE_CLINRO_OTHER_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.other = action.otherValue
      return newState
    }
    case DELETE_CLINRO_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].choices[action.choiceId]
      newState.questions[action.itemId].choices_order.splice(
        newState.questions[action.itemId].choices_order.indexOf(action.choiceId),
        1,
      )
      return newState
    }
    case UPDATE_CLINRO_CHOICE_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId].label = action.newLabel
      return newState
    }
    case UPDATE_CLINRO_CHOICE_VALUE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId].value = action.newValue
      return newState
    }
    case UPDATE_CLINRO_CHOICE_SCORE_VALUE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId].score_value = action.newValue
      return newState
    }
    case UPDATE_CLINRO_CHOICE_KEY: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId][action.key] = action.newValue
      if (action.key === 'type' && action.newValue === QUESTION_TYPE_MAP.text) {
        // deletes a choice's min key if, the type is changed to text, this is important
        // for the text's max value because the input is made so that the max value cannot
        // be less than the min value
        delete newState.questions[action.itemId].choices[action.choiceId].min
      }
      return newState
    }
    case ADD_CLINRO_CHOICE_LABEL_IMAGE: {
      const newState = dupStateAndItem(state, action.itemId)
      if (!action.imageId) return newState
      const { image_list, resource_size_list } = newState
      newState.image_list = image_list ? addUniqToArr(newState.image_list, action.imageId) : [action.imageId]
      newState.questions[action.itemId].choices[action.choiceId].image = action.imageId
      newState.questions[action.itemId].choices[action.choiceId].imageSrc = action.imageSrc
      const fileResourceObject = {
        file_name: action.imageId,
        size: action.size,
        type: MEDIA_TYPE_MAP.image,
      }
      if (resource_size_list) resource_size_list.push(fileResourceObject)
      else newState.resource_size_list = [fileResourceObject]
      addMediaToFileMap({ _instrument: newState, fileId: action.imageId, itemId: action.itemId })
      return newState
    }
    case DELETE_CLINRO_CHOICE_LABEL_IMAGE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].choices[action.choiceId].image
      delete newState.questions[action.itemId].choices[action.choiceId].imageSrc
      deleteMediaFromInstrumentJson({
        _instrument: newState,
        fileId: action.imageId,
        itemId: action.itemId,
      })
      return newState
    }
    case MOVE_CLINRO_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices_order.splice(
        action.endIdx,
        0,
        newState.questions[action.itemId].choices_order.splice(action.startIdx, 1)[0],
      )
      return newState
    }
    case UPDATE_CLINRO_LOGIC_CONDITION: {
      const newState = dupStateAndItem(state, action.itemId)
      const newCondition = Object.assign(
        getCondition(newState.questions[action.itemId].logic.show_if.terms, action.conditionPath),
        { [action.field]: action.value },
      )
      if (action.field === 'question_id') {
        if (action.value2) {
          /**
           * This block is for when a questionId is that of a nested question from matrix
           *  */
          const questionId = action.value.split('_')[0]
          const question = newState.questions[questionId]
          const { allowMultipleAnswers } = question.attributes
          newCondition.question_type = question.type
          newCondition.comparator = ''
          newCondition.value = ''
          newCondition.sub_question_id = action.value2
          newCondition.allow_multiple = allowMultipleAnswers
        } else {
          newCondition.question_type = newState.questions[action.value].type
          newCondition.comparator = ''
          newCondition.value = ''
          delete newCondition.sub_question_id
          delete newCondition.allow_multiple
        }
      } else if (action.field === 'comparator' && action.value.includes('answered')) {
        newCondition.value = ''
      }
      setCondition(newState.questions[action.itemId].logic.show_if.terms, action.conditionPath, newCondition)
      return newState
    }
    case ADD_CLINRO_LOGIC_CONDITION: {
      const newState = dupStateAndItem(state, action.itemId)
      const blankCondition = {
        question_type: '',
        question_id: '',
        value: '',
        comparator: '',
      }
      if (action.groupPath) {
        addCondition(newState.questions[action.itemId].logic.show_if.terms, action.groupPath)
        return newState
      }
      if (newState.questions[action.itemId].logic.show_if) {
        newState.questions[action.itemId].logic.show_if.terms.push(blankCondition)
      } else {
        newState.questions[action.itemId].logic.show_if = {
          comparator: 'boolean_combine',
          operator: 'and',
          terms: [blankCondition],
        }
      }
      return newState
    }
    case ADD_CLINRO_LOGIC_GROUP: {
      const newState = dupStateAndItem(state, action.itemId)
      const blankGroup = {
        comparator: 'boolean_combine',
        operator: 'and',
        terms: [
          {
            question_type: '',
            question_id: '',
            value: '',
            comparator: '',
          },
        ],
      }
      if (newState.questions[action.itemId].logic.show_if.terms[0].comparator !== 'boolean_combine') {
        newState.questions[action.itemId].logic.show_if.terms = [
          {
            comparator: 'boolean_combine',
            operator: 'and',
            terms: newState.questions[action.itemId].logic.show_if.terms,
          },
          blankGroup,
        ]
      } else {
        newState.questions[action.itemId].logic.show_if.terms.push(blankGroup)
      }
      return newState
    }
    case DELETE_CLINRO_LOGIC_CONDITION: {
      const nextState = dupStateAndItem(state, action.itemId)
      deleteCondition(nextState.questions[action.itemId].logic.show_if, action.conditionPath)
      removeLoneGroup(nextState.questions[action.itemId].logic)
      return nextState
    }
    case DELETE_ALL_CLINRO_LOGIC: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].logic.show_if
      return newState
    }
    case CHANGE_CLINRO_LOGIC_OPERATOR: {
      const newState = dupStateAndItem(state, action.itemId)
      setOperator(newState.questions[action.itemId].logic.show_if, action.groupPath, action.value)
      return newState
    }
    case DELETE_INVALID_CLINRO_LOGIC: {
      const nextState = { ...state }
      for (const itemId in action.invalidConditions) {
        nextState.questions[itemId] = { ...nextState.questions[itemId] }
        action.invalidConditions[itemId].sort((a, b) => {
          return b[0] - a[0] || b[1] - a[1]
        })
        action.invalidConditions[itemId].forEach(path => {
          deleteCondition(nextState.questions[itemId].logic.show_if, path)
        })
        removeLoneGroup(nextState.questions[itemId].logic)
      }
      return nextState
    }
    case RANDOMIZE_CLINRO_QUESTIONS: {
      const newState = { ...state }
      newState.order[0] = getRandomizedOrder(newState.order[0], newState.siblings)
      Object.keys(newState.questions).forEach(qId => {
        newState.questions[qId].logic = {}
      })
      calcQuestionNumbers(newState.order, newState.questions)
      return newState
    }

    case ADD_NEW_CLINRO_DOMAIN: {
      const nextState = { ...state }
      const { domains } = nextState
      const { newDomainId } = action

      if (!domains) nextState.domains = {}
      nextState.domains[newDomainId] = {
        label: action.label,
        formula: 'SUM',
      }

      const question = nextState.questions[action.itemId]
      const { domains: questionDomains } = question

      if (questionDomains) {
        question.domains.push(newDomainId)
      } else {
        question.domains = [newDomainId]
      }

      nextState.questionsWithScoring[action.itemId] = question.domains
      return nextState
    }
    case TOGGLE_CLINRO_QUESTION_DOMAIN: {
      const nextState = { ...state }
      const { questionsWithScoring } = nextState
      const question = nextState.questions[action.itemId]
      const { domains = [] } = question
      let newDomains = []

      if (domains.includes(action.domainId)) {
        newDomains = domains.filter(_domainId => _domainId !== action.domainId)
      } else {
        newDomains = [...domains, action.domainId]
      }
      question.domains = newDomains
      nextState.questionsWithScoring[action.itemId] = newDomains // update questionsWithScoring with new domain list

      /**
       * If the admin toggles off a domain from a question, we do a check to see if the domain
       * is assigned to any other question. If it is not, we delete the domain.
       */
      const domainIsNotUsedByInstrument = !getDomainIsUtilized({ questionsWithScoring, domainId: action.domainId })
      if (domainIsNotUsedByInstrument) {
        delete nextState.domains[action.domainId]
      }
      const { domains: clinroDomains } = nextState
      // If there are no domains, delete all the scores
      if (Object.keys(clinroDomains).length === 0) nextState.scores = []
      return nextState
    }
    case UPDATE_CLINRO_ITEM_FORMULA: {
      const nextState = { ...state }
      nextState.questions[action.itemId].formula = action.formula
      return nextState
    }
    case UPDATE_CLINRO_DOMAIN_FORMULA: {
      const nextState = { ...state }
      nextState.domains[action.domainId] = {
        ...nextState.domains[action.domainId],
        formula: action.formula,
      }
      return nextState
    }
    case UPDATE_CLINRO_SCORE: {
      const nextState = { ...state }
      const { key, scoreId, value } = action
      const scoreIdx = nextState.scores.findIndex(score => score.id === scoreId)
      nextState.scores[scoreIdx] = {
        ...nextState.scores[scoreIdx],
        [key]: value,
      }
      return nextState
    }
    case ADD_CLINRO_SCORE: {
      const nextState = { ...state }
      if (!nextState.scores) {
        nextState.scores = []
      }
      nextState.scores = [...nextState.scores, generateNewScoreObject(action.scoreId)]
      return nextState
    }
    case DELETE_CLINRO_SCORE: {
      const nextState = { ...state }
      nextState.scores = nextState.scores.filter(score => score.id !== action.scoreId)
      return nextState
    }
    // Matrix questions
    case ADD_CLINRO_MATRIX_QUESTION: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].questions[action.newQuestionId] = { label: '' }
      if (action.insertIdx) {
        newState.questions[action.itemId].questions_order.splice(action.insertIdx, 0, action.newQuestionId)
      } else {
        newState.questions[action.itemId].questions_order.push(action.newQuestionId)
      }
      return newState
    }
    case DELETE_CLINRO_MATRIX_QUESTION: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].questions[action.subQuestionId]
      newState.questions[action.itemId].questions_order.splice(
        newState.questions[action.itemId].questions_order.indexOf(action.subQuestionId),
        1,
      )
      return newState
    }
    case TOGGLE_CLINRO_MATRIX_QUESTION_ALLOW_MULTIPLE_ANSWERS: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.allowMultipleAnswers = !newState.questions[action.itemId].attributes
        .allowMultipleAnswers
      return newState
    }
    default: {
      return state
    }
  }
}

export function clinroErrors(state = { questions: {} }, action) {
  let newState
  switch (action.type) {
    case INITIALIZE_CLINRO_EDIT:
    case INITIALIZE_BLANK_CLINRO:
    case CLEAR_CLINRO_ERRORS:
      return { questions: {} }
    case ADD_CLINRO_ERRORS:
      return action.errors
    case CLEAR_CLINRO_ERROR:
      newState = { ...state }
      delete newState[action.key]
      return newState
    case CLEAR_CLINRO_QUESTION_ERROR:
    case CHANGE_CLINRO_ITEM_TYPE:
      newState = { ...state }
      newState.questions = { ...newState.questions }
      delete newState.questions[action.itemId]
      return newState
    default:
      return state
  }
}

export function editorStates(state = {}, action) {
  switch (action.type) {
    case INITIALIZE_CLINRO_EDIT: {
      return action.editorStates
    }
    case ADD_CLINRO_ITEM: {
      const newState = { ...state }
      newState[action.newQId] = EditorState.createEmpty()
      return newState
    }
    case ADD_CLINRO_SIBLING_ITEM: {
      const newState = { ...state }
      newState[action.newQId] = EditorState.createEmpty()
      return newState
    }
    case ADD_CLINRO_MATRIX_QUESTION: {
      const newState = { ...state }
      newState[action.newQuestionId] = EditorState.createEmpty()
      return newState
    }
    case DUPLICATE_CLINRO_ITEM: {
      const newState = { ...state }
      newState[action.newQId] = EditorState.createWithContent(action.editorState.getCurrentContent())
      if (action.isMatrix) {
        action.questionsOrder.forEach(questionId => {
          const newMatrixQuestionId = action.newQuestionIdMap[questionId]
          newState[newMatrixQuestionId] = EditorState.createWithContent(
            action.matrixQuestionEditorStates[questionId].getCurrentContent(),
          )
        })
      }
      return newState
    }
    case UPDATE_CLINRO_ITEM_LABEL: {
      const newState = { ...state }
      newState[action.itemId] = action.newEditorState
      return newState
    }
    case UPDATE_CLINRO_MATRIX_QUESTION_LABEL: {
      const newState = { ...state }
      newState[action.questionId] = action.newEditorState
      return newState
    }
    case CHANGE_CLINRO_ITEM_TYPE: {
      const newState = { ...state }
      if (action.nextType === QUESTION_TYPE_MAP.matrix) {
        newState[action.newQuestionId] = EditorState.createEmpty()
      }
      return newState
    }
    default: {
      return state
    }
  }
}

function originalClinro(state = {}, action) {
  if (action.type === INITIALIZE_CLINRO_EDIT || action.type === UPDATE_ORIGINAL_CLINRO) {
    return JSON.parse(JSON.stringify(action.json))
  }
  return state
}

function wordCount(state = 0, action) {
  switch (action.type) {
    case INITIALIZE_BLANK_CLINRO: {
      return 0
    }
    case INITIALIZE_CLINRO_EDIT: {
      return countWordsInClinro(action.json)
    }
    case UPDATE_CLINRO_ITEM_LABEL: {
      return (
        state +
        (countWords(action.newEditorState.getCurrentContent().getPlainText()) -
          countWords(action.oldEditorState.getCurrentContent().getPlainText()))
      )
    }
    default: {
      return state
    }
  }
}

function clinrosScore(state = {}, action) {
  switch (action.type) {
    case SET_CLINROS_SCORE: {
      return {
        ...state,
        [action.instrumentId]: action.scores,
      }
    }
    default: {
      return state
    }
  }
}

const domainCachereducer = (state, action) => {
  state.domains = state.domains || {}
  if (state.domains[action.domainId] && action.type === TOGGLE_CLINRO_QUESTION_DOMAIN) {
    const formulas = state.scores.map(s => s.tagFormula)
    state.deletedDomains = { ...state.deletedDomains, [action.domainId]: state.domains[action.domainId] }

    state.deletedDomains = Object.keys(state.deletedDomains)
      .filter(tagId => formulas.map(f => f.includes(tagId)).some(b => b))
      .reduce((acc, k) => ({ ...acc, [k]: state.deletedDomains[k] }), {})
  }
  return state
}

export default combineReducers({
  clinro(state = generateBlankClinro(), action) {
    return clinro(domainCachereducer(state, action), action)
  },
  editorStates,
  clinroErrors,
  wordCount,
  clinrosScore,
  originalClinro,
})

export const clinroActions = {
  addItem,
  addItemImage,
  addPreviewImage,
  closeModal: modalActions.closeModal,
  deleteChoice: checkLogicAndDeleteChoice,
  deleteItem: checkLogicAndDeleteItem,
  deleteItemImage,
  deletePreviewImage,
  fetchClinro,
  initializeBlankClinro,
  moveItem: checkLogicAndMoveItem,
  openModal: modalActions.openModal,
  randomizeQuestions,
  toggleEdit,
  updateTitle,
}

export const itemActions = {
  addChoiceOnPaste,
  addItem,
  duplicateItem,
  updateItem,
  updateItemLabel,
}

export const itemEditorActions = {
  addChoice,
  addMatrixQuestion,
  addOtherChoice,
  addSiblingClinroItem,
  changeItemType: checkLogicAndChangeItemType,
  createNewDomainAndAddToQuestion,
  deleteChoice: checkLogicAndDeleteChoice,
  deleteMatrixQuestion: checkLogicAndDeleteMatrixQuestion,
  moveItem: checkLogicAndMoveItem,
  toggleClinroMatrixAllowMulti,
  toggleInputValidation,
  toggleQuestionDomain,
  toggleQuestionScoring,
  toggleRequiredQuestion: checkLogicAndToggleRequired,
  updateClinroItemFormula,
  updateItem,
  updateUnitType,
}

export const selectViewActions = {
  addChoice,
  addChoiceLabelImage,
  deleteChoice: checkLogicAndDeleteChoice,
  deleteChoiceLabelImage,
  deleteOtherChoice,
  moveChoice,
  setQuestionScoresEdited,
  updateClinroMatrixQuestionLabel,
  updateChoiceKey,
  updateChoiceLabel,
  updateChoiceValue,
  updateChoiceScoreValue,
  updateOtherChoice,
}

export const logicActions = {
  addLogicCondition,
  addLogicGroup,
  changeLogicOperator,
  deleteAllLogic,
  deleteLogicCondition,
  updateLogicCondition,
}

export const imageActions = {
  uploadImage,
}

export const scoringActions = {
  updateClinroDomainFormula,
  updateClinroScore,
  addClinroScore,
  deleteClinroScore,
}
