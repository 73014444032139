import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Checkbox } from 'components/UIElements'
import LogicPreview from './LogicPreview'

const recordingType = {
  fixed: 'Fixed',
  range: 'Range',
}

const AudioCaptureView = props => {
  const { clearQuestionError, item, itemId, errors, updateItem, preview } = props
  const { attributes } = item
  const { min, max, show_timer } = attributes
  const [recordingTimeType, setRecordingTimeType ] = useState(max ? recordingType.range : recordingType.fixed)
  const [fixedState, setFixedState] = useState({ min: '', show_timer: true })
  const [rangeState, setRangeState] = useState({ min: '', max: '', show_timer: true })
  

  const updateItemAttribute = (value) => {
    const newItem = {
      ...item,
      attributes: { 
        ...item.attributes, 
        ...value,
      },
    }
    const itemMin = newItem.attributes.min
    newItem.attributes.min = itemMin ? parseInt(itemMin, 10) : '' 
    if (recordingTimeType === recordingType.fixed) {
      delete newItem.attributes.max
    }
    if (recordingTimeType === recordingType.range) {
      const itemMax = newItem.attributes.max
      newItem.attributes.max = itemMax ? parseInt(itemMax, 10) : ''
    }
    updateItem(itemId, newItem)
  }

  const onSelectType = (type) => {
    if (recordingTimeType === recordingType.fixed) {
      setFixedState({ min, show_timer })
    } 
    if (recordingTimeType === recordingType.range) {
      setRangeState({ min, max, show_timer })
    }

    setRecordingTimeType(type)
    const defaultState = {
      ...item,
      attributes: { 
        ...item.attributes, 
      },
    }
    if (type === recordingType.fixed) {
      defaultState.attributes.min = fixedState.min
      defaultState.attributes.show_timer = fixedState.show_timer
      delete defaultState.attributes.max
    } 

    if (type === recordingType.range) {
      defaultState.attributes.min = rangeState.min
      defaultState.attributes.max = rangeState.max
      defaultState.attributes.show_timer = rangeState.show_timer
    }
    clearQuestionError(itemId)
    updateItem(itemId, defaultState)
  }

  const renderErrors = () => {
    let errorMessages
    if (errors.minDurationErr && (min <= 0 && min !== '' || max <= 0 && max !== '')) {
      errorMessages = errors.minDurationErr
    } else if (errors.maxDurationErr && (min > 900 || max > 900)) {
      errorMessages = errors.maxDurationErr
    } else if (errors.blankDurationErr) {
      errorMessages = errors.blankDurationErr
    } else if (errors.minExceedMaxErr) {
      errorMessages = errors.minExceedMaxErr
    }
    return (
      <div>
        <p className='survey-error'>{errorMessages}</p>
      </div> 
    )
  }

  const recordingTypeInput = recordingTimeType === recordingType.fixed ? (
    <Input
      disabled={preview}
      hasError={
        errors
          ? (!!errors.minDurationErr && !!min && min <= 0) ||
            (!!errors.minDurationErr && min === 0) ||
            (!!errors.blankDurationErr && !min && min !== 0) ||
            (!!errors.maxDurationErr && min > 900) // audio_recording needs to be validated to be less than 15 minutes (900 seconds)
          : false
      }
      type='number'
      min={1}
      className='flexed'
      posttext='seconds'
      value={min}
      onChange={value => updateItemAttribute({ min: value })}
    />
  ) : (
    <div>
      <Input 
        disabled={preview}
        hasError={
          errors
            ? (!!errors.minDurationErr && !!min && min <= 0) ||
              (!!errors.minDurationErr && min === 0) ||
              (!!errors.blankDurationErr && !min && min !== 0) ||
              (!!errors.maxDurationErr && min > 900) || 
              (!!errors.minExceedMaxErr && !!max && min >= max)
            : false
        }
        type='number'
        min={1}
        className='min flexed'
        pretext='Minimum'
        posttext='seconds'
        value={min}
        onChange={value => updateItemAttribute({ min: value })}
      />
      <Input 
        disabled={preview}
        hasError={
          errors
            ? (!!errors.minDurationErr && !!max && max <= 0) ||
              (!!errors.minDurationErr && max === 0) ||
              (!!errors.blankDurationErr && !max && max !== 0) ||
              (!!errors.maxDurationErr && max > 900) ||
              (!!errors.minExceedMaxErr && !!min && min >= max)
            : false
        }
        type='number'
        min={1}
        className='max flexed'
        pretext='Maximum'
        posttext='seconds'
        value={max}
        onChange={value => updateItemAttribute({ max: value })}
      />
    </div>
  )
 
  return (
    <div>
      {item.type === 'audio_recording' && (
        <div className='non-select-preview flexed column start-aligned audio border-radius five'>
          <div className='audio-section flexed space-between-justified start-aligned'>
            <div className='audio-section-header flexed baseline-aligned'>
              <i key='fa-microphone' className='fas fa-microphone' />
              <div>Recording Time</div>
            </div>
            <div className='recording-time flexed column start-aligned'>
              <div>
                <Button 
                  noStyling={recordingTimeType !== recordingType.fixed}
                  content='Fixed' 
                  onClick={() => onSelectType(recordingType.fixed)} 
                />
                <Button 
                  noStyling={recordingTimeType !== recordingType.range}
                  content='Range' 
                  onClick={() => onSelectType(recordingType.range)} 
                />
              </div>
              {recordingTypeInput}
              {errors && renderErrors()}
            </div>
          </div>
          <div className='audio-section flexed space-between-justified start-aligned'>
            <div className='audio-section-header flexed baseline-aligned'>
              <i className='fas fa-eye' />
              <div>Timer Visibility</div>
            </div>
            <div className='timer-visibility header'>
              <Checkbox 
                label='Show timer' 
                checked={show_timer}
                onClick={() => {updateItemAttribute({ show_timer: !show_timer })}}
              />
            </div>
          </div>
        </div>
      )}
      <LogicPreview errors={errors} item={item} />
    </div>
  )
}

export default AudioCaptureView

AudioCaptureView.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.string,
  clearQuestionError: PropTypes.func,
  errors: PropTypes.shape({
    blankDurationErr: PropTypes.string,
    minDurationErr: PropTypes.string,
    maxDurationErr: PropTypes.string,
    minExceedMaxErr: PropTypes.string,
  }),
  preview: PropTypes.bool,
  updateItem: PropTypes.func,
}
