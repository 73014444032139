import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Container, AutocompleteSearch, Radio, PhoneNumberInput, Checkbox } from 'components/UIElements'
import { icons } from 'assets/assets'
import { browserHistory } from 'react-router'
import Dropdown from 'components/Dropdown'
import { buttonContentOnSendState } from 'utils/helpers'
import { sortFunction, getUserRoleIdByStudy } from 'utils/misc'
import {
  USER_ROLE_IDS_THAT_CAN_BE_CREATED_BY_SITE_ADMIN,
  CLINICIAN_ROLES_ID_LIST,
  HIDE_PERMISSION_TABLE_ROLES_LIST,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  MODAL_CONTENT_MAP,
  USER_ROLE_ID_MAP,
  USER_ROLE_IDS_WITH_LIMITED_WRITE_USER_PERMISSIONS,
  USER_ROLES_MAP,
  SITE_USER_MANAGEMENT_ADMIN_DELETION_FORBIDDEN,
  ROLE_NAME_ID_MAP,
} from 'utils/constants'
import ChangePasswordForm from './ChangePasswordForm'
import PermissionTable from './PermissionsTable'
import UserRadios from './UserRadios'
import '../stylesheets/user.scss'

const HEADER_MAP = {
  create: 'Create User',
  edit: 'Edit User',
  profile: 'My Profile',
}

const PAGE_TYPE_MAP = {
  create: 'create',
  edit: 'edit',
  profile: 'profile',
}

const PHONE_ENTRY_STRINGS = {
  caseMan:
    'SMS case alerts are available for this study. If enabled, SMS alerts regarding new and updated cases will be sent to the user at the mobile phone number entered below.',
  caseManProfile:
    'By enabling SMS and entering your mobile phone number below, you agree that SMS alerts regarding new and updated cases will be sent to this mobile phone number.',
  caseManSMS:
    'By entering the phone number above, you verify that this phone number is a company-associated mobile phone number that can receive SMS messages.',
  clinicianPart1: 'If enabled, this clinician will receive an SMS if an ',
  clinicianPart2: 'immediate virtual visit',
  clinicianPart3:
    ' is scheduled for a participant. Immediate virtual visits are triggered by the score of an instrument and can be configured when scheduling the instrument.',
  clinicianSMS:
    'In order to comply with the GDPR, you verify that this cell phone number number is a company-associated cell phone number that is able to receive SMS messages by entering that phone number.',
}

const renderPhoneCopy = (roleId, isProfile) => {
  if (roleId === USER_ROLE_ID_MAP.caseManager && isProfile) {
    return <p>{PHONE_ENTRY_STRINGS.caseManProfile}</p>
  }
  if (roleId === USER_ROLE_ID_MAP.caseManager) {
    return <p>{PHONE_ENTRY_STRINGS.caseMan}</p>
  }
  return (
    <p>
      <span>{PHONE_ENTRY_STRINGS.clinicianPart1}</span>
      <strong>{PHONE_ENTRY_STRINGS.clinicianPart2}</strong>
      <span>{PHONE_ENTRY_STRINGS.clinicianPart3}</span>
    </p>
  )
}

class CreateUserPage extends React.Component {
  constructor(props) {
    super(props)
    const { user } = this.props
    const { phone } = user
    this.state = {
      fileName: null,
      selectedStudy: {},
      clearPhoneOnCountry: false,
      smsEnabled: !!phone,
      selectedRoleId: null,
      selectedRoleName: null,
    }
    this.studyNameList = []
    this.phoneNumber = createRef()
    this.fileUpload = createRef()
  }

  componentDidMount() {
    const {
      fetchUserSites,
      setNavBarProps,
      params,
      pageType,
      studies,
      subroute,
      clearUserSites,
      updateField,
      user,
    } = this.props
    const { studyID } = params
    const isProfile = pageType === PAGE_TYPE_MAP.profile
    if (subroute === 'me') clearUserSites()
    setNavBarProps({
      back: isProfile ? undefined : `/studies/${studyID}/users`,
      close: isProfile ? undefined : `/studies/${studyID}/users`,
      backText: isProfile ? 'Back' : 'Users',
    })

    this.studyNameList = studies.map(study => {
      return {
        key: study.id,
        text: study.platform_name,
        value: study.id,
      }
    })

    const _studyIndex = this.studyNameList.findIndex(el => el.key === Number(studyID))
    const selectedStudyIndex = _studyIndex === -1 ? 0 : _studyIndex
    this.setState({
      selectedStudy: this.studyNameList[selectedStudyIndex],
    })

    if (isProfile) {
      if (studyID) {
        fetchUserSites({ studyId: studyID, checkSitesOnSuccess: true })
      } else if (this.studyNameList.length !== 0) {
        const selectedStudyId = this.studyNameList[selectedStudyIndex]?.key
        fetchUserSites({ studyId: selectedStudyId, checkSitesOnSuccess: true })
      }
    }

    const { smsEnabled } = this.state
    const { role_id } = user
    updateField('study_id', parseInt(studyID, 10))
    if (role_id === USER_ROLE_ID_MAP.investigator) this.onToggleOnAll()
    if (role_id === USER_ROLE_ID_MAP.caseManager && !smsEnabled) {
      this.scrollToPhoneNumber()
    }
    this.setState({ selectedRoleId: role_id })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedStudy, smsEnabled } = this.state
    const { studies, fetchUserSites } = this.props

    if (studies.length !== prevProps.studies.length) {
      this.studyNameList = studies.map(study => {
        return {
          key: study.id,
          text: study.platform_name,
          value: study.id,
        }
      })
      const defaultStudy = this.studyNameList[0]
      fetchUserSites({ studyId: selectedStudy?.key || defaultStudy?.key, checkSitesOnSuccess: true })
      this.setState({ selectedStudy: selectedStudy || defaultStudy })
    }
    if (prevState.selectedStudy !== selectedStudy) {
      const { pageType, user } = this.props
      let currentRoleName
      const isProfile = pageType === PAGE_TYPE_MAP.profile
      if (isProfile) {
        if (selectedStudy?.key && user.permissions && user.permissions[selectedStudy.key]) {
          currentRoleName = user.permissions[selectedStudy.key].name
          this.onUpdate()
        }
        const isCaseManager = currentRoleName === USER_ROLES_MAP.caseManager
        if (isCaseManager && !smsEnabled) this.scrollToPhoneNumber()
      }
    }
  }

  componentWillUnmount() {
    const { resetUserPage, setNavBarProps, resetCheckedSites } = this.props
    resetUserPage()
    resetCheckedSites()
    setNavBarProps(null)
  }

  onUpdate = () => {
    const { user } = this.props
    const { selectedStudy } = this.state
    const { name: selectedRoleName, role_id: selectedRoleId } = user.permissions[selectedStudy.key]
    this.setState({ selectedRoleName, selectedRoleId })
  }

  handleSave = e => {
    const {
      changePassword,
      createNewUser,
      newPasswordActive,
      pageType,
      profilePic,
      saveSelfToDatabase,
      user,
      checkedSites,
      params,
    } = this.props

    e.preventDefault()
    const { smsEnabled, selectedStudy, selectedRoleId, selectedRoleName } = this.state
    const { studyID } = params

    if (pageType === PAGE_TYPE_MAP.create) {
      createNewUser(user, checkedSites, studyID, false)
    }
    if (pageType === PAGE_TYPE_MAP.profile) {
      const userCopy = { ...user }
      userCopy.study_id = selectedStudy?.key || Number(studyID)
      userCopy.role_id = selectedRoleId
      userCopy.role_name = selectedRoleName
      userCopy.smsEnabled = smsEnabled
      saveSelfToDatabase(userCopy, newPasswordActive, changePassword, profilePic)
    }
    if (pageType === PAGE_TYPE_MAP.edit) {
      createNewUser(user, checkedSites, studyID, true)
    }
  }

  renderRadio = item => {
    const { pageType, resetCheckedSites, sendDisabled, updateField, user } = this.props
    const rolesWithPhoneNumber = [USER_ROLE_ID_MAP.caseManager, ...CLINICIAN_ROLES_ID_LIST]
    return (
      <Radio
        key={`role-item-${item.key}`}
        id={`role-${item.key}`}
        disabled={pageType !== PAGE_TYPE_MAP.create || sendDisabled}
        selected={user.role_id === item.role_id}
        onClick={() => {
          updateField('role_id', item.role_id)
          if (item.role_id === USER_ROLE_ID_MAP.investigator) {
            this.onToggleOnAll()
          } else {
            resetCheckedSites()
          }
          if (!rolesWithPhoneNumber.includes(item.role_id)) {
            this.setState({ smsEnabled: false })
            updateField('smsEnabled', false)
          }
        }}
        content={item.text}
      />
    )
  }

  onToggleOnAll = () => {
    const { siteSelectionList, toggleOnSite } = this.props
    siteSelectionList.forEach(siteRow => {
      toggleOnSite(siteRow[0].value, siteRow[3].value)
    })
  }

  onToggleSite = (siteID, pathName, treeLevel) => {
    const { siteSelectionList, toggleSite, toggleOnSite, toggleOffSite, checkedSites } = this.props

    if (checkedSites[siteID]) {
      siteSelectionList.forEach(siteRow => {
        if (
          siteRow[3].value.includes(`${pathName}.`) ||
          (pathName.includes(siteRow[3].value) && treeLevel !== siteRow[2].value)
        ) {
          toggleOffSite(siteRow[0].value)
        }
      })
    } else {
      siteSelectionList.forEach(siteRow => {
        if (siteRow[3].value.includes(`${pathName}.`)) {
          toggleOnSite(siteRow[0].value, siteRow[3].value)
        }
      })
    }
    toggleSite(siteID, pathName)
  }

  onTogglePasswordForm = () => {
    const { newPasswordActive, showChangePassword, hideChangePassword } = this.props
    if (newPasswordActive) {
      hideChangePassword()
    } else {
      showChangePassword()
    }
  }

  onPasswordReset = () => {
    const { closeModal, openModal, forgotPassword, user } = this.props
    openModal({
      content: MODAL_CONTENT_MAP.userNewPassword,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        forgotPassword(user.username)
        closeModal()
      },
    })
  }

  formatSiteList = list => {
    return list.map(row => {
      return {
        key: row[0].value,
        text: row[1].value,
        treeLevel: row[2].value,
        path: row[3].value,
      }
    })
  }

  getSiteList = () => {
    const { pageType, params, siteSelectionList, user, userSites } = this.props
    const { studyID } = params
    const { selectedStudy } = this.state

    const isProfile = pageType === PAGE_TYPE_MAP.profile
    const _studyID = studyID ? Number(studyID) : null
    if (isProfile) {
      const selectedStudyId = selectedStudy?.key || _studyID
      if (!user.permissions || !user.permissions[selectedStudyId]) return []
      const siteList = Object.keys(userSites).map(siteId => {
        const currentSite = userSites[siteId]
        const name = currentSite.level === 1 ? 'All Sites' : currentSite.site_label
        return [
          { key: 'id', value: currentSite.site_id, sortValue: currentSite.site_id },
          { key: 'name', value: name, sortValue: name },
          { key: 'treeLevel', value: currentSite.level, sortValue: currentSite.level },
          { key: 'path', value: currentSite.site_path, sortValue: currentSite.site_path },
        ]
      })
      const sortedList = siteList.sort((row1, row2) => sortFunction(row1[3].sortValue, row2[3].sortValue))
      return this.formatSiteList(sortedList)
    }
    return siteSelectionList ? this.formatSiteList(siteSelectionList) : []
  }

  toggleSMS = () => {
    const { updateField } = this.props
    const { smsEnabled } = this.state

    if (smsEnabled) {
      updateField('smsEnabled', false)
      this.setState({ smsEnabled: false })
    } else {
      updateField('smsEnabled', true)
      this.setState({ smsEnabled: true })
    }
  }

  scrollToPhoneNumber = () => {
    this.phoneNumber?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  onSelectStudy = studyItem => {
    const { fetchUserSites } = this.props
    const { key: studyID } = studyItem
    fetchUserSites({ studyId: studyID, checkSitesOnSuccess: true })
    this.setState({ selectedStudy: studyItem })
  }

  render() {
    const {
      canWrite,
      checkedSites,
      currentRoleId,
      currentStudy,
      deleteUser,
      newPasswordActive,
      pageType,
      params,
      profilePic,
      requestSending,
      requestSent,
      sendDisabled,
      studies,
      studyLock,
      updateField,
      uploadProfilePic,
      user,
      userSites,
      userSitesLoading,
      userErrors,
    } = this.props

    const { emailErr, nameErr, phoneErr, siteErr } = userErrors
    const { fileName, selectedStudy, clearPhoneOnCountry, smsEnabled } = this.state

    const isProfile = pageType === PAGE_TYPE_MAP.profile
    let roleName = user.role_name || ''
    const roleId = isProfile ? getUserRoleIdByStudy(user, selectedStudy?.value) : user.role_id

    /* create a special boolean for site coordinators and case managers because they can write users,
     * but only enrollers and site coordinators
     */

    const isCurrentUserWithLimitedWriteAccess = USER_ROLE_IDS_WITH_LIMITED_WRITE_USER_PERMISSIONS.includes(
      currentRoleId,
    )

    const isCurrentUserSiteUserManagementAdmin = currentRoleId === ROLE_NAME_ID_MAP.Site_User_Management_Admin

    const limitedUserCanWrite = isCurrentUserWithLimitedWriteAccess
      ? USER_ROLE_IDS_THAT_CAN_BE_CREATED_BY_SITE_ADMIN.includes(roleId)
      : true
    const isClinRoRole = CLINICIAN_ROLES_ID_LIST.includes(roleId)
    const hidePermissionTable = HIDE_PERMISSION_TABLE_ROLES_LIST.includes(roleId)
    const { studyID } = params

    const isRoot = user.id === user.created_by
    const allowEditPermissions = !isRoot && pageType !== 'profile' && canWrite && limitedUserCanWrite
    const studyName = currentStudy.platform_name
    const list = this.getSiteList()
    const isCreate = pageType === PAGE_TYPE_MAP.create
    const isEdit = pageType === PAGE_TYPE_MAP.edit

    const { config } = currentStudy
    let isSmsEnabled = isProfile ? true : !config

    if (config && !isProfile) {
      const { communication } = config
      const isClinicianUser = CLINICIAN_ROLES_ID_LIST.includes(roleId)

      // If clinician role is selected look for clinro else for admin
      isSmsEnabled = communication?.sms[isClinicianUser ? 'clinro' : 'admin'].enabled ?? true
    }

    if (isProfile) {
      const _studyId = studyID ? Number(studyID) : null
      const selectedStudyId = selectedStudy?.key || _studyId
      if (selectedStudyId && user.permissions && user.permissions[selectedStudyId]) {
        roleName = user.permissions[selectedStudyId].name
      }
      // Disable phone if the study has sms disabled on Profile view
      studies
        .filter(study => study.id === selectedStudyId)
        .map(study => {
          if (study.config) {
            const { communication } = study.config
            isSmsEnabled = communication?.sms.admin.enabled ?? true
          }
        })
    }

    // Case manager logic
    const isCaseManager = isProfile
      ? roleName === USER_ROLES_MAP.caseManager
      : user.role_id === USER_ROLE_ID_MAP.caseManager
    const isCaseManagerProfile = isProfile && isCaseManager

    // Clinical coordinator logic
    const isClinCoord = isProfile
      ? roleName === USER_ROLES_MAP.clinicalCoordinator
      : user.role_id === USER_ROLE_ID_MAP.clinicalCoordinator
    const isClinCoordProfile = isProfile && isClinCoord

    const showSitesList = isProfile || (list.length > 0 && studyID)
    const canDelete = roleId !== USER_ROLE_ID_MAP.root && roleId !== USER_ROLE_ID_MAP.dchAdmin

    const canDeleteCertainUsers = isCurrentUserSiteUserManagementAdmin
      ? !SITE_USER_MANAGEMENT_ADMIN_DELETION_FORBIDDEN.includes(roleId)
      : true

    const hasSites = Object.keys(isProfile ? userSites : checkedSites).length > 0

    const addUser = {
      content: 'Add User',
      sendingContent: 'Adding',
      sentContent: '...Added!',
    }
    const saveUser = {
      content: 'Save',
      sendingContent: 'Saving',
      sentContent: '...Saved!',
    }
    const sendStateClassName = sendDisabled ? 'send-state' : '' // for cursor wait during sendState

    // Phone entry-related bools
    const hasSmsAlert = isProfile && !smsEnabled && !isClinCoordProfile
    const phoneEntryDisabled = !isCreate && !(isCaseManagerProfile || isClinCoordProfile)

    return (
      <div className={`create-user page ${sendStateClassName}`}>
        <h2>{!canWrite && isEdit ? 'User' : HEADER_MAP[pageType]}</h2>
        <Container column>
          <div className='user-profile'>
            <div className='flexed user-details'>
              <div>
                <div className='flexed column'>
                  <div className='profile-pic'>
                    {profilePic ? <img src={profilePic} alt='Profile' /> : icons.myProfile}
                  </div>
                  {isProfile && (
                    <a onClick={() => this.fileUpload.click()}>
                      <i className='fas fa-camera' />
                      {fileName || 'Upload Image'}
                    </a>
                  )}
                  <input
                    aria-label='picture-upload-input'
                    className='file-input'
                    type='file'
                    id='user-profile-pic'
                    ref={el => {
                      this.fileUpload = el
                    }}
                    accept='.jpg,.jpeg,.png,.svg'
                    onChange={() => {
                      this.setState({ fileName: this.fileUpload.files[0].name })
                      uploadProfilePic(this.fileUpload.files[0])
                    }}
                    disabled={sendDisabled}
                  />
                </div>
              </div>
              <div>
                {isCreate || isProfile ? (
                  <Input
                    hasError={!user.nickname && nameErr}
                    errorText={nameErr}
                    id='user-nickname'
                    disabled={isEdit || sendDisabled}
                    onChange={value => updateField('nickname', value)}
                    label='Name'
                    value={user.nickname}
                    placeholder='Name (required)'
                  />
                ) : (
                  <p className='role-name'>{user.nickname}</p>
                )}
                {isCreate ? (
                  <Input
                    hasError={!!emailErr}
                    errorText={emailErr}
                    id='user-email'
                    disabled={!isCreate || sendDisabled}
                    onChange={value => updateField('username', value.trim().toLowerCase())}
                    label='Email'
                    value={user.username}
                    placeholder='Email (required)'
                  />
                ) : (
                  <a href={`mailto:${user.username}`} className='email'>
                    {user.username}
                  </a>
                )}
              </div>
            </div>
            {isProfile && !newPasswordActive && (
              <Button
                className='change-pw'
                link
                onClick={() => this.onTogglePasswordForm()}
                content='Change Password'
                disabled={sendDisabled}
              />
            )}
            {newPasswordActive && (
              <ChangePasswordForm
                {...this.props}
                togglePasswordForm={this.onTogglePasswordForm}
                saveUser={this.handleSave}
              />
            )}
            {studyName && !isProfile && (
              <div>
                <h6>STUDY</h6>
                <h5 className='study-name word-clamp'>{studyName}</h5>
              </div>
            )}
            {isProfile && !!studies.length && !studyID && (
              <div>
                <h6>ASSIGNED STUDY</h6>
                <Dropdown
                  selected={selectedStudy?.key || this.studyNameList[0]?.key}
                  options={this.studyNameList}
                  onSelect={this.onSelectStudy}
                />
              </div>
            )}
            <h6>ROLE</h6>
            {isCreate ? <UserRadios renderRadio={this.renderRadio} /> : <p className='role-name'>{roleName}</p>}
            {!hidePermissionTable && <PermissionTable role={roleId} />}
          </div>
          {(isCaseManager || isClinRoRole) && isSmsEnabled && (
            <div className={`user-phone${hasSmsAlert ? ' alerted' : ''}`} ref={this.phoneNumber}>
              {hasSmsAlert && (
                <span className='alert-text'>
                  In order to receive SMS alerts regarding new cases, please enable "Receive SMS Alerts".
                </span>
              )}
              <h6>PHONE NUMBER</h6>
              {renderPhoneCopy(roleId, isProfile)}
              <Checkbox
                toggle
                id='receive-sms-alert'
                label='Receive SMS alerts'
                checked={smsEnabled}
                onClick={this.toggleSMS}
                disabled={phoneEntryDisabled || sendDisabled || studyLock}
              />
              {smsEnabled && (
                <>
                  <PhoneNumberInput
                    className={`${phoneEntryDisabled ? 'disabled' : ''}`}
                    onChange={value => {
                      if (clearPhoneOnCountry) updateField('phone', value)
                      else {
                        this.setState({ clearPhoneOnCountry: true }, () => updateField('phone', value))
                      }
                    }}
                    onCountryChange={() => {
                      if (clearPhoneOnCountry) updateField('phone', '')
                    }}
                    hasError={!!phoneErr}
                    errorText={phoneErr}
                    value={user.phone}
                    disabled={phoneEntryDisabled || sendDisabled}
                    id='phone-number-input'
                    placeholder='Mobile phone number (required)'
                  />
                  {isClinRoRole && <p>{PHONE_ENTRY_STRINGS.clinicianSMS}</p>}
                  {isCaseManager && <p>{PHONE_ENTRY_STRINGS.caseManSMS}</p>}
                </>
              )}
            </div>
          )}
          {showSitesList && (
            <div>
              <h6>SITES</h6>
              <AutocompleteSearch
                id='search-sites'
                allSitesOnly={roleId === 4}
                checked={isProfile ? userSites : checkedSites}
                /**
                 * If the list length is one, that means there is only 'All Sites'.
                 * If it is an edit of an user and there is only one site, we disable it,
                 * because a user must be in a site.
                 *  */

                disabled={isProfile || !canWrite || (isEdit && list.length === 1) || sendDisabled || studyLock}
                errorText={siteErr}
                hasError={!!siteErr && !hasSites}
                list={list}
                loading={userSitesLoading}
                toggleItem={this.onToggleSite}
              />
            </div>
          )}
          {!isCreate && allowEditPermissions && !studyLock && (
            <a id='reset-password' onClick={this.onPasswordReset}>
              Reset Password
            </a>
          )}
        </Container>

        {(isProfile || allowEditPermissions) && (
          <div className='button-list'>
            <Button grey onClick={browserHistory.goBack} id='cancel' content='Cancel' disabled={sendDisabled} />
            {isEdit && canDelete && canDeleteCertainUsers && (
              <Button
                id='delete-user'
                className='delete-button'
                disabled={studyLock || sendDisabled}
                content='Delete User'
                onClick={() => deleteUser(user.id, studyID, checkedSites, roleId)}
              />
            )}
            {isCreate && (
              <Button
                id='add-user'
                content={buttonContentOnSendState(addUser, requestSending, requestSent)}
                loading={requestSending}
                disabled={sendDisabled}
                onClick={this.handleSave}
              />
            )}
            {isEdit && (
              <Button
                id='save-user'
                content={buttonContentOnSendState(saveUser, requestSending, requestSent)}
                loading={requestSending}
                disabled={studyLock || sendDisabled}
                onClick={this.handleSave}
              />
            )}
            {isProfile && (
              <Button
                content={buttonContentOnSendState(saveUser, requestSending, requestSent)}
                loading={requestSending}
                disabled={sendDisabled}
                onClick={this.handleSave}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

CreateUserPage.propTypes = {
  canWrite: PropTypes.bool,
  changePassword: PropTypes.objectOf(PropTypes.string),
  checkedSites: PropTypes.objectOf(PropTypes.string),
  clearUserSites: PropTypes.func,
  closeModal: PropTypes.func,
  createNewUser: PropTypes.func,
  currentRoleId: PropTypes.number,
  currentStudy: PropTypes.shape({
    config: PropTypes.shape({
      communication: PropTypes.object,
    }),
    platform_name: PropTypes.string,
  }),
  deleteUser: PropTypes.func,
  fetchUserSites: PropTypes.func,
  forgotPassword: PropTypes.func,
  hasEditUserPermissions: PropTypes.bool,
  hideChangePassword: PropTypes.func,
  insights: PropTypes.shape({ enabled: PropTypes.bool }),
  loading: PropTypes.bool,
  newPasswordActive: PropTypes.bool,
  openModal: PropTypes.func,
  pageType: PropTypes.string,
  params: PropTypes.shape({
    siteID: PropTypes.string,
    studyID: PropTypes.string,
  }),
  profilePic: PropTypes.string,
  requestSending: PropTypes.bool,
  requestSent: PropTypes.bool,
  resetCheckedSites: PropTypes.func,
  resetUserPage: PropTypes.func,
  saveSelfToDatabase: PropTypes.func,
  sendDisabled: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  showChangePassword: PropTypes.func,
  siteSelectionList: PropTypes.arrayOf(PropTypes.array),
  studies: PropTypes.arrayOf(PropTypes.object),
  studyLock: PropTypes.bool,
  subroute: PropTypes.string,
  toggleOffSite: PropTypes.func,
  toggleOnSite: PropTypes.func,
  toggleSite: PropTypes.func,
  updateField: PropTypes.func,
  updateNewPassword: PropTypes.func,
  uploadProfilePic: PropTypes.func,
  user: PropTypes.shape({
    created_by: PropTypes.number,
    id: PropTypes.number,
    nickname: PropTypes.string,
    permissions: PropTypes.objectOf(PropTypes.object),
    phone: PropTypes.string,
    role_id: PropTypes.number,
    role_name: PropTypes.string,
    username: PropTypes.string,
  }),
  userErrors: PropTypes.objectOf(PropTypes.string),
  userSites: PropTypes.objectOf(PropTypes.object),
  userSitesLoading: PropTypes.bool,
}

export default CreateUserPage
