import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/dropdown.scss'
import { formatStringForID } from 'utils/misc'
import { Notes } from './UIElements'

/**
 * props: options - array of options { key: 'key', value: 'key', text: 'text' }
 */

const Dropdown = props => {
  const { options = [] } = props
  const [open, setOpen] = useState(false)
  const [selectedText, setSelectedText] = useState('')
  const [textMap, setTextMap] = useState(new Map())
  const dropdownRef = useRef(null)

  const handleClose = () => {
    const { onClose } = props
    if (onClose) onClose()
    setOpen(false)
  }

  const handleOpen = e => {
    const { disabled, onOpen } = props
    if (disabled) return
    if (onOpen) onOpen()
    if (!open) setOpen(true)
  }
  const clickListener = e => {
    const { disabled } = props
    if (!disabled) {
      if (!dropdownRef?.current?.contains(e.target)) handleClose()
    }
  }

  const updateTextMap = () => {
    if (options) {
      const _textMap = new Map()
      options.forEach(item => {
        _textMap.set(item.key, item.text)
      })
      setTextMap(_textMap)
    }
  }

  useEffect(() => {
    const { onSelect, required, selected } = props
    if (required && !selected && options.length > 0) {
      onSelect(options[0])
    }
    updateTextMap()
    document.addEventListener('click', clickListener)
    // dropdownRef?.current?.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('click', clickListener)
      // dropdownRef?.current?.removeEventListener('click', clickListener)
    }
  }, [])

  useEffect(() => {
    updateTextMap()
  }, [options])

  const handleCloseTriggers = e => {
    if (e.target.dataset.closetrigger === 'true') handleClose()
  }

  const onSelectItem = item => {
    const { disabled, onSelect, preventCloseOnSelect } = props
    if (disabled) return
    return e => {
      onSelect(item, e)
      if (!preventCloseOnSelect) setOpen(false)
      setSelectedText(item.text)
    }
  }

  const renderOptions = () => {
    const { children, selected, selectedIdx, renderFunction, sortingOrder, sortingBy } = props
    const _options = [...options]
    if (sortingBy) {
      _options.sort((a, d) => {
        if (sortingOrder === 'asc') {
          return a[sortingBy].localeCompare(d[sortingBy])
        }
        return d[sortingBy].localeCompare(a[sortingBy])
      })
    }
    return (
      <div onClick={handleCloseTriggers} className='menu'>
        {children ||
          _options.map((item, idx) => {
            const isSelected = selectedIdx ? selectedIdx === idx : selected === item.value
            return (
              <div
                key={item.key || `item_${idx}`}
                onClick={onSelectItem(item)}
                className={`dropdown-item clickable${isSelected ? ' selected' : ''}`}
                id={formatStringForID(item.text)}>
                {renderFunction ? renderFunction(item, props) : item.text}
              </div>
            )
          })}
      </div>
    )
  }

  const {
    children,
    className,
    disabled,
    errorTextClassName = '',
    errorText,
    hasError,
    hideCaret,
    id,
    main,
    notes,
    placeholder,
    selected,
  } = props

  const hasDropdown = children || (options && options.length > 0)
  let _className = `d3-dropdown${className ? ` ${className}` : ''}`
  _className += disabled ? ' disabled' : ''
  _className += hasDropdown && open ? ' open' : ''
  _className += hasError ? ' has-error' : ''

  return (
    <div tabIndex='0' ref={dropdownRef} className={_className} id={id}>
      <div onClick={open ? handleClose : handleOpen} className='dropbar'>
        <div className='dropbar-content'>{main || textMap.get(selected) || placeholder}</div>
        {hasDropdown && !hideCaret && <i className={`fas fa-caret-${open ? 'up' : 'down'}`} />}
      </div>
      {hasDropdown && renderOptions()}
      {hasError && errorText && <p className={`error-text ${errorTextClassName}`}>{errorText}</p>}
      {notes && <Notes {...notes} />}
    </div>
  )
}

Dropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  errorTextClassName: PropTypes.string,
  hasError: PropTypes.bool,
  hideCaret: PropTypes.bool,
  id: PropTypes.string,
  notes: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  placeholder: PropTypes.string,
  preventCloseOnSelect: PropTypes.bool,
  renderFunction: PropTypes.func,
  required: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedIdx: PropTypes.number,
  sortingOrder: PropTypes.oneOf(['asc', 'dsc']),
  sortingBy: PropTypes.string,
}

export default Dropdown
