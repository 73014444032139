export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const TURN_OFF_MODAL_ON_ITEM = 'TURN_OFF_MODAL_ON_ITEM'

function openModal(modalProps) {
  return {
    type: OPEN_MODAL,
    modalProps,
  }
}

function closeModal() {
  return {
    type: CLOSE_MODAL,
  }
}

function turnOffModalOnItem(itemId, off) {
  return {
    type: TURN_OFF_MODAL_ON_ITEM,
    itemId,
    off,
  }
}

export default function modalReducer(state = { open: false }, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      const defaults = {
        confirmButton: 'Confirm',
        cancelButton: 'Cancel',
      }
      return Object.assign(defaults, { open: true, className: 'universal-modal' }, action.modalProps)
    }
    case CLOSE_MODAL:
      return { open: false }
    default:
      return state
  }
}

export const modalActions = {
  openModal,
  closeModal,
  turnOffModalOnItem,
}
