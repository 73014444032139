// Types
const ADD_DOCUMENT = 'ADD_DOCUMENT'
const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
const RESET_DOCUMENTS = 'RESET_DOCUMENTS'

// Actions
export const addDocument = (rowId, document) => {
  return {
    type: ADD_DOCUMENT,
    rowId,
    document,
  }
}

export const deleteDocument = (rowId, documentIdx) => {
  return {
    type: DELETE_DOCUMENT,
    rowId,
    documentIdx,
  }
}

export const resetDocs = () => {
  return {
    type: RESET_DOCUMENTS,
  }
}

//
// Reducers
//
export default (state = {}, action) => {
  let newState
  switch (action.type) {
    case ADD_DOCUMENT: {
      newState = { ...state }
      const _rowId = action.rowId.toString()
      if (action.rowId in newState) newState[_rowId].push(action.document)
      else newState[_rowId] = [action.document]
      return newState
    }
    case DELETE_DOCUMENT:
      newState = { ...state }
      if (newState[action.rowId].length > 1) newState[action.rowId].splice(action.documentIdx, 1)
      else delete newState[action.rowId]
      return newState
    case RESET_DOCUMENTS:
      return {}
    default:
      return state
  }
}

export const documentsActions = {
  addDocument,
  deleteDocument,
  resetDocs,
}
