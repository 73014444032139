import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserHasFeatures } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import announcementReducer, { announcementActions } from '../EditAnnouncement/modules/Announcement'
import ptpsReducer, { fetchCohorts } from '../../../Participants/routes/ParticipantsPage/modules/Participants'
import ptpReducer from '../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import AnnouncementView from '../EditAnnouncement/containers/AnnouncementContainer'

export default store => ({
  path: 'createsms',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        // inject announcement specific reducers
        injectReducers(store, {
          announcementReducer,
          participants: ptpsReducer,
          participantReducer: ptpReducer,
        })

        const { params } = nextState
        const { studyID, announcementID } = params
        const { currentStudy } = store.getState().study
        const { config } = currentStudy
        let isSmsEnabled = !config
        const smsPlatformKey = 'admin'
        if (config) {
          const { communication } = config
          isSmsEnabled = communication?.sms.admin.enabled
        }
        if (isSmsEnabled) {
          setPageTitle(`Create SMS | Study ${studyID} | ${currentStudy.platform_name}`)
          store.dispatch(fetchCohorts(studyID))
          store.dispatch(
            announcementActions.initializeAnnouncementPage({
              studyID,
              communicationId: announcementID,
              isAnnouncement: false,
            }),
          )
        }

        // initilize or reset the announcement reducer
        store.dispatch(announcementActions.resetAnnouncement())

        // UserIsAuthenticated wrapper redirects user to the login page if credentials are invalid
        // UserHasScope wrapper redirects user to the permission denied page if user does not have the correct permisions
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'communication',
              )(
                UserHasScope(
                  ['xp'],
                  UserHasFeatures(
                    AnnouncementView,
                    [
                      {
                        name: 'communication',
                        props: [
                          {
                            name: 'sms',
                            props: [
                              {
                                name: smsPlatformKey,
                                props: ['enabled'],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    'communication',
                    true,
                  ),
                  false,
                  studyID,
                ),
              ),
            ),
          ),
        )
      },
      'communication/createsms',
    )
  },
})
