import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Popup, Button } from 'components/UIElements'
import { actions } from 'store/tableFilters'
import { DATE_FORMAT_MAP } from 'utils/constants'
import { STRING_MATCH_MAP } from './StringFilter'

const getDateRangeContent = ({ start, end }) => {
  if (start === '') return `Before ${end.format(DATE_FORMAT_MAP.main)}`
  if (end === '') return `After ${start.format(DATE_FORMAT_MAP.main)}`
  return `${start.format(DATE_FORMAT_MAP.main)} - ${end.format(DATE_FORMAT_MAP.main)}`
}

const getNumRangeContent = ({ min, max }) => {
  if (min === '') return `Max ${max}`
  if (max === '') return `Min ${min}`
  return `${min} - ${max}`
}

const getCheckboxContent = ({ set }) => set.size + ' Total'

const getRadioContent = ({ selected }) => selected

const getStringContent = ({ string, type }) => `${STRING_MATCH_MAP[type]} ${string}`

const contentMap = {
  dateRange: getDateRangeContent,
  numRange: getNumRangeContent,
  radio: getRadioContent,
  checkbox: getCheckboxContent,
  string: getStringContent,
}

const KEY_NAME_MAP = {
  checkbox: null,
  id: 'ID',
  subjectId: 'Subject ID',
  name: 'Name',
  lastName: 'Last Name',
  firstName: 'Preferred Name',
  email: 'Email',
  siteId: 'Site ID',
  age: 'Age',
  sex: 'Sex',
  tracks: 'Tracks',
  cohorts: 'Cohorts',
  firstLogin: 'First Login',
  eConsent: 'eConsent',
  lastInstrument: 'Last Instrument',
  lastPassiveData: 'Last Device Data',
  lastConnection: 'Last Connection',
  compliancePercent: 'Compliance',
  scheduledDate: 'Scheduled Date',
  completionDate: 'Completion Date',
  instrumentsCompleted: 'Instruments Completed',
}

const FilterTag = ({ title, content, onClear }) => (
  <Button
    noStyling
    className='filter-tag clickable'
    onClick={onClear}
    iconAfter='fas fa-times'
    content={`${title}${content ? `: ${content}` : ''}`}
  />
)

const FilterTags = ({ filterKeys, criteria, customTags, onClearKey }) => (
  <>
    {filterKeys.map(key => {
      const criterion = criteria[key]
      if (customTags && customTags[key]) {
        const { title = '', content = '' } = customTags[key]
        return (
          <FilterTag
            onClear={() => {
              onClearKey(key)
            }}
            title={title}
            key={key}
            content={content}
          />
        )
      }
      return criterion ? (
        <FilterTag
          onClear={() => {
            onClearKey(key)
          }}
          title={KEY_NAME_MAP[key]}
          key={key}
          content={contentMap[criterion.filterType](criterion)}
        />
      ) : null
    })}
  </>
)

const FilterTagsPopup = props => {
  const { filters, clearFilters, removeFilter, limit = 2 } = props
  const [open, setOpen] = useState(false)
  const filterKeys = Object.keys(filters)
  const pluralFilters = filterKeys.length > 1 ? 's' : ''

  const onClearKey = key => {
    if (filterKeys.length === 1) setOpen(false)
    removeFilter(key)
  }

  if (!filterKeys.length) return null

  return (
    <div
      className='filters-tags-container'
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <FilterTags {...props} filterKeys={[...filterKeys.slice(-limit)]} onClearKey={onClearKey} />
      <Popup
        open={open}
        position='bottom'
        align='right'
        trigger={
          <Button
            noStyling
            className='filters-tags-button'
            iconBefore='fas fa-filter'
            content={`${filterKeys.length || 0} filter${pluralFilters} applied`}
            onClick={() => setOpen(true)}
          />
        }>
        <div className='filters-tags-popup' onMouseLeave={() => setOpen(false)}>
          <span className='label-small'>Edit Filters</span>
          <div className='filters-tags-list'>
            <FilterTags {...props} filterKeys={filterKeys} onClearKey={onClearKey} />
          </div>
          <div className='filters-tags-footer'>
            <Button className='close-trigger' link content='Cancel' onClick={() => setOpen(false)} />
            <Button
              grey
              content={`Clear filter${pluralFilters}`}
              onClick={() => {
                setOpen(false)
                clearFilters()
              }}
            />
          </div>
        </div>
      </Popup>
    </div>
  )
}

export default connect(state => state.tableFilters, actions)(FilterTagsPopup)
