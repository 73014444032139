import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { setPageTitle } from 'utils/misc'
import { onFetchInstrument } from '../../modules/Instrument'
import { fetchArticle } from '../EditSurvey/modules/Survey'
import SurveyEditView from '../EditSurvey/containers/SurveyContainer'
// Sync route definition
export default store => ({
  path: ':instrumentID/editarticle',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */
        const { studyID, instrumentID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Edit Article | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(onFetchInstrument(studyID, instrumentID))
        store.dispatch(fetchArticle(studyID, instrumentID))
        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['wa'], SurveyEditView, false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      '/instruments/editarticle/:id',
    )
  },
})
