import React, { useEffect } from 'react'
import { Dropdown, Button } from 'components/UIElements'
import STRINGS from 'utils/strings'
import AddConditions from '../AddConditions'

const TriggersListItem = props => {
  const {
    allOptions,
    chain_deployment_info,
    clearErr,
    errors,
    getInstrumentScore,
    idx,
    invalidTriggers,
    isDeleteBtnShown,
    options,
    removeTrigger,
    selectTrigger,
    throwPrevalidatedErr,
    trigger,
    updateChainDeployInfo,
    updateChainDeployInfoField,
  } = props

  const instrumentScore = getInstrumentScore(trigger)
  const triggerInvalid = invalidTriggers[trigger]

  useEffect(() => {
    if (triggerInvalid) {
      throwPrevalidatedErr()
      return clearErr
    }
  }, [triggerInvalid])

  return (
    <div className='trigger-list-item'>
      <Dropdown
        className='inst-list-dropdown'
        hasError={triggerInvalid}
        errorText={STRINGS.instToInstTriggerError}
        options={options}
        onSelect={item => {
          selectTrigger(idx, item.key, item)
        }}
        required
        selected={trigger}
        main={allOptions.find(option => option.key === trigger)?.text}
      />
      {!triggerInvalid && (
        <AddConditions
          chain_deployment_info={chain_deployment_info}
          errors={errors}
          updateChainDeployInfo={updateChainDeployInfo}
          updateChainDeployInfoField={updateChainDeployInfoField}
          instrumentScore={instrumentScore}
          instrumentId={trigger}
        />
      )}
      {isDeleteBtnShown ? (
        <Button
          className='delete-trigger-btn'
          icon='fa-regular fa-trash-can'
          noStyling
          onClick={() => {
            removeTrigger(idx)
          }}
        />
      ) : null}
    </div>
  )
}

export default TriggersListItem
