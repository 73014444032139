import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { giftCardIcon, donationIcon } from 'assets/assets'
import { STUDY_CONFIG_MAP } from 'utils/constants'
import { getRandomId, getFloor } from 'utils/misc'
import { RichButton, Dropdown, Input } from 'components/UIElements'
import { calculateCycles } from '../utils/studyUtils'
import AddTreasureChestGiftCardAccountComponent from './TreasureChestRewards/AddTreasureChestGiftCards'
import AddDonationOrgComponent from './TreasureChestRewards/AddTreasureChestDonation'

const CURRENCY_OPTIONS = [{ key: 'USD', value: 'USD', text: 'USD ($)' }]

const NO_MIDTERM_REWARD_ERROR_MSG = 'Select a mid-term reward category'

const defaultTreasureChestGCObj = cycles => ({
  max: '',
  cycles,
  currency: 'USD',
  gift_card_type_list: [],
})

const defaultGiftCardObj = {
  auto_approve: { status: false, payout_type: '' },
  payout_types: [],
  notification_type: 'email',
}

const defaultDonationObj = cycles => [
  {
    id: getRandomId(),
    goal: cycles * 3,
    summary: '',
    detailed_description: '',
    asset_download_link: '',
    currency: 'USD',
    value: '',
  },
]

const RewardField = ({ subHeaderText, bodyText, children }) => {
  return (
    <div className='reward-field'>
      <div className='flexed start-aligned'>
        <div className='flexed column'>
          <p className='sub-heading'>{subHeaderText}</p>
          <p className='body-text'>{bodyText}</p>
        </div>
        <div className='flexed right-info'>{children}</div>
      </div>
    </div>
  )
}

const MoneyFields = ({ disabled, isGiftcard = false, config, props }) => {
  const { saveAttempted, updateStudyConfig } = props
  const { super_gems = {} } = config
  const { treasure_chest_gift_card_config = {}, donations = [] } = super_gems
  const key = isGiftcard ? 'max' : '0'
  const parentKey = isGiftcard ? STUDY_CONFIG_MAP.treasureChestGiftCardConfig : STUDY_CONFIG_MAP.donations
  const maxValue = treasure_chest_gift_card_config?.max

  const [maxAmount, setMaxAmount] = useState(maxValue || '')

  const maxFieldVal = isGiftcard ? maxAmount : donations[key].value

  const getUpdateSuperGems = value => {
    const parsedVal = value === '' ? '' : parseInt(value, 10)
    const realValue = isGiftcard
      ? parsedVal
      : {
          ...donations[key],
          value: parsedVal,
        }
    if (parentKey === STUDY_CONFIG_MAP.donations) {
      updateStudyConfig({
        [STUDY_CONFIG_MAP.superGems]: {
          ...super_gems,
          donations: [
            {
              ...super_gems.donations[0],
              value: parsedVal,
            },
          ],
        },
      })
    } else {
      updateStudyConfig({
        [STUDY_CONFIG_MAP.superGems]: {
          ...super_gems,
          [parentKey]: {
            ...super_gems[parentKey],
            [key]: realValue,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (isGiftcard) {
      const delayDebounceFn = setTimeout(() => {
        getUpdateSuperGems(maxAmount)
      }, 750)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [maxAmount])

  return (
    <div className='flexed column'>
      <div className='flexed start-aligned'>
        <Dropdown
          className='drop-down'
          disabled={disabled}
          options={CURRENCY_OPTIONS}
          onSelect={item => {}}
          selected='USD'
        />
        <Input
          className='max-amount text-align-right number'
          errorText='Enter a non-negative amount'
          hasError={saveAttempted && (maxFieldVal === '' || maxFieldVal === 0)}
          disabled={disabled}
          value={maxFieldVal}
          type='number'
          step={1}
          onChange={val => {
            const realValue = val === '' ? '' : getFloor(val)
            return isGiftcard ? setMaxAmount(realValue) : getUpdateSuperGems(realValue)
          }}
          placeholder='Max amount per person'
          preventInput={num => num < 0}
        />
      </div>
      {isGiftcard ? <AddTreasureChestGiftCardAccountComponent {...props} /> : <AddDonationOrgComponent {...props} />}
    </div>
  )
}

const NewRewardsConfig = props => {
  const { updateStudyConfig, saveAttempted, study, disabled } = props
  const config = study.config || {}
  const giftCardConfig = config[STUDY_CONFIG_MAP.treasureChestStoreGiftCard]
  const giftCardSelected = !!giftCardConfig
  const superGems = config[STUDY_CONFIG_MAP.superGems]
  const donationConfig = superGems ? superGems[STUDY_CONFIG_MAP.donations] : null
  const donationSelected = !!donationConfig
  const { cycles, has_dynamic_cycles } = config || {}
  const cyclesNum = has_dynamic_cycles ? cycles.length : calculateCycles(cycles[0])

  const hasNoMidtermRewardSelected = saveAttempted && superGems && !(giftCardConfig || donationConfig)

  useEffect(() => {
    if (!disabled) {
      if (donationSelected) {
        updateStudyConfig({
          [STUDY_CONFIG_MAP.superGems]: {
            ...superGems,
            [STUDY_CONFIG_MAP.donations]: donationConfig || defaultDonationObj(cyclesNum),
            [STUDY_CONFIG_MAP.treasureChestGiftCardConfig]: {
              ...config.super_gems.treasure_chest_gift_card_config,
              cycles: cyclesNum,
            },
          },
        })
      }
      if (giftCardSelected) {
        updateStudyConfig({
          [STUDY_CONFIG_MAP.superGems]: {
            ...superGems,
            [STUDY_CONFIG_MAP.treasureChestGiftCardConfig]: {
              ...config.super_gems.treasure_chest_gift_card_config,
              cycles: cyclesNum,
            },
          },
        })
      }
    }
  }, [cycles])

  return (
    <div className='reward-eligibility'>
      <p>
        Select the type of mid-term reward this study offers, then select each reward that participants will be able to
        choose from. Participants are progressively offered more rewards as they complete each chapter, and their
        ability to redeem rewards depends on their compliance.
      </p>
      <RewardField subHeaderText='What type of mid-term rewards would you like to offer?'>
        <div className='flexed column'>
          <div className='flexed'>
            <RichButton
              className='reward-type'
              disabled={disabled}
              graphicElement={giftCardIcon('#1C2358')}
              hasError={hasNoMidtermRewardSelected}
              label='Gift cards'
              selected={giftCardSelected}
              onClick={() => {
                updateStudyConfig({
                  [STUDY_CONFIG_MAP.treasureChestStoreGiftCard]: giftCardSelected ? undefined : defaultGiftCardObj,
                  [STUDY_CONFIG_MAP.superGems]: {
                    ...superGems,
                    [STUDY_CONFIG_MAP.treasureChestGiftCardConfig]: giftCardSelected
                      ? undefined
                      : defaultTreasureChestGCObj(cyclesNum),
                    [STUDY_CONFIG_MAP.giftCards]: undefined,
                  },
                })
              }}
            />
            <RichButton
              className='reward-type'
              disabled={disabled}
              graphicElement={donationIcon}
              hasError={hasNoMidtermRewardSelected}
              label='Donations'
              selected={donationSelected}
              onClick={() => {
                updateStudyConfig({
                  [STUDY_CONFIG_MAP.superGems]: {
                    ...superGems,
                    [STUDY_CONFIG_MAP.donations]: donationSelected ? undefined : defaultDonationObj(cyclesNum),
                  },
                })
              }}
            />
          </div>
          {hasNoMidtermRewardSelected && <span className='error-text'>{NO_MIDTERM_REWARD_ERROR_MSG}</span>}
        </div>
      </RewardField>
      {giftCardSelected && (
        <RewardField
          subHeaderText='What is the maximum gift card payout for each participant?'
          bodyText='You will break this value into separate gift card amounts in the rewards selector below. Changing this value will reset the existing selections.'>
          <MoneyFields isGiftcard disabled={disabled} props={props} config={config} />
        </RewardField>
      )}
      {donationSelected && (
        <RewardField subHeaderText='What is the maximum donation payout for each participant? '>
          <MoneyFields disabled={disabled} props={props} config={config} />
        </RewardField>
      )}
    </div>
  )
}

export default NewRewardsConfig

NewRewardsConfig.propTypes = {
  updateStudyConfig: PropTypes.func,
  saveAttempted: PropTypes.bool,
  study: PropTypes.shape({
    config: PropTypes.shape({
      cycles: PropTypes.array,
    }),
  }),
  disabled: PropTypes.bool,
}

MoneyFields.propTypes = {
  contentBtn: PropTypes.string,
  disabled: PropTypes.bool,
  isGiftcard: PropTypes.bool,
  props: PropTypes.shape({
    disabled: PropTypes.bool,
  }),
}

RewardField.propTypes = {
  subHeaderText: PropTypes.string,
  bodyText: PropTypes.string,
  children: PropTypes.node,
}
