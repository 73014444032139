import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import generatePendingPaymentsMap from '../../utils/paymentUtils'

const PaymentApprovalBar = props => {
  const {
    approveAllPayments,
    config = {},
    reload,
    hasTreasureChest,
    noPendingPayments,
    pendingPayments,
    pendingPaymentsLoading,
    studyLock,
  } = props
  const payoutsConfig = hasTreasureChest ? config.super_gem_store_payouts : config.advanced_payouts
  const hasAutoApprove = payoutsConfig?.auto_approve.status

  return (
    <div className='payment-approval flexed end-justified'>
      <div>
        <Button
          disabled={hasAutoApprove || noPendingPayments}
          grey
          iconBefore='fas fa-redo'
          content='Reload All'
          onClick={() => {
            reload()
          }}
        />
        <Button
          content='Approve All'
          disabled={noPendingPayments || pendingPaymentsLoading || studyLock}
          onClick={() => approveAllPayments(generatePendingPaymentsMap(pendingPayments))}
        />
      </div>
    </div>
  )
}

PaymentApprovalBar.propTypes = {
  approveAllPayments: PropTypes.func,
  config: PropTypes.shape({
    advanced_payouts: PropTypes.shape({
      auto_approve: PropTypes.shape({
        status: PropTypes.bool,
      }),
    }),
  }),
  hasTreasureChest: PropTypes.bool,
  noPendingPayments: PropTypes.bool,
  pendingPayments: PropTypes.arrayOf(PropTypes.array),
  pendingPaymentsLoading: PropTypes.bool,
  reload: PropTypes.func,
  studyLock: PropTypes.bool,
}

export default PaymentApprovalBar
