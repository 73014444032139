import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import STRINGS from 'utils/strings'
import { Button, Container, Input, UploadInput } from 'components/UIElements'
import { MEDIA_TYPE_MAP, MEDIA_UPLOAD_MAP, TRAINING_MATERIAL_TYPE } from 'utils/constants'
import { buttonContentOnSendState } from 'utils/helpers'
import '../stylesheets/uploadtraining.scss'

const UploadTrainingPage = props => {
  const { disabled, mediaLoading, sending, studyID, studyLock, thumbnailLoading, trainingMaterialType } = props
  useEffect(() => {
    const { setNavBarProps } = props
    setNavBarProps({
      back: `/studies/${studyID}/training`,
      backText: STRINGS.back,
    })
    return () => {
      setNavBarProps(null)
    }
  }, [])

  const [file, setFile] = useState({})
  const [thumbnail, setThumbnail] = useState({})
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileUrl, setFileUrl] = useState('')
  const [isExternalUrl, setIsExternalUrl] = useState(false)

  const uploadFile = async (_file, isThumbnail = false) => {
    const { uploadStudyAsset } = props
    const formData = new FormData()
    formData.set('file_name', _file.name)
    formData.set('upload_file', _file)
    const { filename } = await uploadStudyAsset({
      formData,
      studyID,
      loadingKey: isThumbnail ? 'thumbnail' : 'mediaUpload',
    })
    if (filename) {
      if (isThumbnail) setThumbnailUrl(filename)
      else setFileUrl(filename)
    }
  }

  const onFileSelection = _file => {
    if (_file.name) {
      setFile(_file)
      uploadFile(_file)
      if (isExternalUrl) setIsExternalUrl(false)
    } else {
      setFile(_file)
      setFileUrl('')
    }
  }

  const onThumbnailSelection = _file => {
    if (_file.name) {
      setThumbnail(_file)
      uploadFile(_file, true)
    } else {
      setThumbnail(_file)
      setThumbnailUrl('')
    }
  }

  const onUploadTraining = () => {
    const { uploadTrainingMaterial } = props

    const trainingMaterial = {
      name: fileName,
      url: fileUrl,
      type: trainingMaterialType,
      thumb_url: thumbnailUrl,
      metadata: {},
    }
    if (isExternalUrl) trainingMaterial.metadata.is_external = true

    uploadTrainingMaterial({ trainingMaterial, studyID })
  }

  const onUpdateUrl = val => {
    setFileUrl(val)
    if (!isExternalUrl) setIsExternalUrl(true)
  }

  const { sendingState, sentState } = sending
  const uploadingDocument = trainingMaterialType === TRAINING_MATERIAL_TYPE.document

  const renderTrainingUploadInput = useMemo(
    () => (
      <div className='material-upload-container'>
        <label className='label-small' htmlFor='training-material-upload'>
          {uploadingDocument ? STRINGS.uploadDocument : STRINGS.uploadVideo}
        </label>
        <UploadInput
          id='training-material-upload'
          autoFocus={false}
          type={uploadingDocument ? MEDIA_TYPE_MAP.pdf : TRAINING_MATERIAL_TYPE.video}
          accept={MEDIA_UPLOAD_MAP[trainingMaterialType].acceptedTypes}
          onUpdateFile={onFileSelection}
          onRemoveFile={() => onFileSelection({})}
          name=''
          file={file}
          disabled={disabled}
          loading={mediaLoading}
          sayPreparing
        />
        {uploadingDocument && !file.name && (
          <Input
            placeholder={STRINGS.orAttach}
            disabled={!!file.name || disabled || studyLock}
            onChange={onUpdateUrl}
          />
        )}
      </div>
    ),
    [file, mediaLoading],
  )

  const renderThumbnailUploadInput = useMemo(
    () => (
      <div className='thumbnail-upload-container'>
        <label className='label-small' htmlFor='thumbnail-upload'>
          {uploadingDocument ? STRINGS.trainingDocumentThumbnail : STRINGS.trainingVideoThumbnail}
        </label>
        <UploadInput
          id='thumbnail-upload'
          type={MEDIA_TYPE_MAP.image}
          accept={MEDIA_UPLOAD_MAP.image.acceptedTypes}
          autoFocus={false}
          onUpdateFile={onThumbnailSelection}
          onRemoveFile={() => onThumbnailSelection({})}
          name=''
          file={thumbnail}
          disabled={disabled}
          loading={thumbnailLoading}
          sayPreparing
        />
      </div>
    ),
    [thumbnail, thumbnailLoading],
  )

  const uploadTrainingButtonContent = {
    content: 'Upload',
    sendingContent: 'Uploading',
    sentContent: '...Uploaded!',
  }

  return (
    <div className='upload-training page'>
      <h3>{STRINGS[`uploadNewTraining${trainingMaterialType}`]}</h3>
      <Container>
        <Input
          id='training-material-name-input'
          charLimit={100}
          className='pos rel'
          placeholder={STRINGS.enterHere}
          value={fileName}
          onChange={setFileName}
          label={uploadingDocument ? STRINGS.documentName : STRINGS.videoName}
        />
        {renderTrainingUploadInput}
        {renderThumbnailUploadInput}
      </Container>
      <div className='button-list'>
        <Button
          onClick={onUploadTraining}
          id='uploading-training'
          content={buttonContentOnSendState(uploadTrainingButtonContent, sendingState, sentState)}
          disabled={!fileUrl || sendingState || sentState || studyLock || !fileName || thumbnailLoading}
          loading={sendingState}
        />
      </div>
    </div>
  )
}

UploadTrainingPage.propTypes = {
  disabled: PropTypes.bool,
  mediaLoading: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  sending: PropTypes.shape({
    sendingState: PropTypes.bool,
    sentState: PropTypes.bool,
  }),
  studyID: PropTypes.string,
  studyLock: PropTypes.bool,
  thumbnailLoading: PropTypes.bool,
  trainingMaterialType: PropTypes.string,
  uploadStudyAsset: PropTypes.func,
  uploadTrainingMaterial: PropTypes.func,
}

export default UploadTrainingPage
