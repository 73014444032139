import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import TimePicker from 'rc-time-picker'
import { Dropdown } from 'components/UIElements'
import 'react-datepicker/dist/react-datepicker.css'
import { datePickerConfig } from 'utils/time'
import 'rc-time-picker/assets/index.css'
import { isNumeric } from 'utils/misc'
import { QUESTION_TYPE_MAP, DATE_FORMAT_MAP } from 'utils/constants'

const comparatorsForSelectOne = [
  { key: 'is_selected', text: 'is the following answer', value: 'is_selected' },
  { key: 'is_not_selected', text: 'is not the following answer', value: 'is_not_selected' },
  { key: 'is_answered', text: 'is answered', value: 'is_answered' },
  { key: 'is_not_answered', text: 'is not answered', value: 'is_not_answered' },
]

const comparatorsForSelectMulti = [
  { key: 'is_selected', text: 'includes one of the following answers', value: 'is_selected' },
  { key: 'is_not_selected', text: 'does not include one of the following answers', value: 'is_not_selected' },
  { key: 'is_answered', text: 'is answered', value: 'is_answered' },
  { key: 'not_answered', text: 'is not answered', value: 'is_not_answered' },
]

const comparatorsForNumber = [
  { key: '=', text: 'is exactly equal to', value: '=' },
  { key: '!=', text: 'is not exactly equal to', value: '!=' },
  { key: '>', text: '> (greater than)', value: '>' },
  { key: '>=', text: '>= (greater than or equal to)', value: '>=' },
  { key: '<', text: '< (less than)', value: '<' },
  { key: '<=', text: '<= (less than or equal to)', value: '<=' },
  { key: 'is_answered', text: 'is answered', value: 'is_answered' },
  { key: 'is_not_answered', text: 'is not answered', value: 'is_not_answered' },
]

const comparatorsForOther = [
  { key: '=', text: 'is exactly equal to', value: '=' },
  { key: '!=', text: 'is not exactly equal to', value: '!=' },
  { key: 'is_answered', text: 'is answered', value: 'is_answered' },
  { key: 'is_not_answered', text: 'is not answered', value: 'is_not_answered' },
]

const SelectDropdown = props => {
  const { conditionPath, disabled, itemId, logic, questions, term, updateLogicCondition } = props
  const choiceOptions = questions[term.question_id].choices_order.map(choiceId => {
    let text = questions[term.question_id].choices[choiceId].label
    if (text.length > 30) text = text.slice(0, 30) + '...'
    return {
      key: choiceId,
      text,
      value: choiceId,
    }
  })
  return (
    <Dropdown
      className='value-dropdown'
      onSelect={item => updateLogicCondition({ itemId, logic, conditionPath, field: 'value', value: item.value })}
      disabled={disabled}
      options={choiceOptions}
      selected={term.value}
    />
  )
}

const InputField = props => {
  const { conditionPath, itemId, logic, term, updateLogicCondition } = props
  const isDecimal = term.question_type === QUESTION_TYPE_MAP.decimal
  const isInteger = term.question_type === QUESTION_TYPE_MAP.integer || term.question_type === QUESTION_TYPE_MAP.vaScale
  const isNumber = isDecimal || isInteger
  const updateCondition = value => {
    updateLogicCondition({ itemId, logic, conditionPath, field: 'value', value })
  }
  const onBlur = e => {
    const val = e.target.value
    if (val === '' || val === '-') {
      updateCondition('')
    } else {
      if (isDecimal) {
        updateCondition(parseFloat(val))
      } else if (isInteger) {
        updateCondition(parseInt(val))
      }
    }
  }
  const onChange = e => {
    let value = e.target.value
    if (!isNumber || value === '' || value === '-' || (isDecimal && isNumeric(value))) {
      updateCondition(value)
    } else if (isInteger && isNumeric(value)) {
      updateCondition(parseInt(value))
    }
  }
  return (
    <input
      onBlur={onBlur}
      disabled={props.disabled}
      className='logic-input'
      placeholder={`${props.term.question_type}`}
      onChange={onChange}
      value={props.term.value}
    />
  )
}

const DateField = props => {
  const { conditionPath, disabled, itemId, logic, term, updateLogicCondition } = props
  return (
    <div className='date-picker'>
      <DatePicker
        locale={datePickerConfig.locale}
        disabled={disabled}
        selected={term.value ? moment(term.value.slice(0, term.value.length - 1)) : null}
        onChange={mom =>
          updateLogicCondition({
            itemId,
            logic,
            conditionPath,
            field: 'value',
            valiue: mom.format('YYYY-MM-DDT00:00:00.000') + 'Z',
          })
        }
        dropdownMode='select'
        dateFormat={datePickerConfig.format}
        placeholderText={datePickerConfig.placeholder}
      />
    </div>
  )
}

const TimeField = props => {
  const { conditionPath, itemId, logic, term, updateLogicCondition } = props
  const formattedTime = '0000-01-01' + term.value.slice(10, props.term.value.length - 1)
  return (
    <TimePicker
      allowEmpty={false}
      className='time-picker'
      format='H:mm'
      placeholder='hh:mm'
      showSecond={false}
      value={term.value ? moment(formattedTime) : null}
      onChange={mom => {
        updateLogicCondition({
          itemId,
          logic,
          conditionPath,
          field: 'value',
          value: mom.format('0000-00-00THH:mm:00.000') + 'Z',
        })
      }}
    />
  )
}

const DateTimeField = props => {
  const { conditionPath, disabled, itemId, logic, term, updateLogicCondition } = props
  const date = term.value ? term.value.split('T')[0] : ''
  const time = term.value ? term.value.split('T')[1] : ''
  return (
    <div className='logic-datetime-picker'>
      <div className='date-picker stacked'>
        <DatePicker
          locale={datePickerConfig.locale}
          disabled={disabled}
          selected={date ? moment(term.value.slice(0, term.value.length - 1)) : null}
          onChange={mom =>
            updateLogicCondition({
              itemId,
              logic,
              conditionPath,
              field: 'value',
              value: mom.format(DATE_FORMAT_MAP.datePicker) + 'T' + (time || '00:00:00.000Z'),
            })
          }
          dropdownMode='select'
          dateFormat={datePickerConfig.format}
          placeholderText={datePickerConfig.placeholder}
        />
      </div>
      <TimePicker
        allowEmpty={false}
        disabled={disabled}
        className='time-picker stacked'
        format='H:mm'
        placeholder='hh:mm'
        showSecond={false}
        value={time ? moment(time, 'H:mm') : null}
        onChange={mom =>
          updateLogicCondition({
            itemId,
            logic,
            conditionPath,
            field: 'value',
            value: (date || moment().format(DATE_FORMAT_MAP.datePicker)) + 'T' + mom.format('HH:mm:00.000') + 'Z',
          })
        }
      />
    </div>
  )
}

const ClinroLogicCondition = props => {
  const {
    conditionPath,
    deleteLogicCondition,
    itemId,
    logic,
    questionOptions,
    questionRequired,
    term,
    updateLogicCondition,
  } = props

  let comparatorOptions
  const disabled = term.comparator.includes('answered')
  let valueField = <InputField disabled={disabled} {...props} />
  switch (term.question_type) {
    case 'likert':
    case 'select_one':
    case 'numeric_rating_scale':
      comparatorOptions = comparatorsForSelectOne
      valueField = <SelectDropdown disabled={disabled} {...props} />
      break
    case 'matrix':
      comparatorOptions = comparatorsForSelectMulti
      if (!term.allow_multiple) {
        comparatorOptions = comparatorsForSelectOne
      }
      valueField = <SelectDropdown disabled={disabled} {...props} />
      break
    case 'select_multiple':
      comparatorOptions = comparatorsForSelectMulti
      valueField = <SelectDropdown disabled={disabled} {...props} />
      break
    case 'integer':
    case 'decimal':
    case 'vas_horizontal':
    case 'va_scale':
      comparatorOptions = comparatorsForNumber
      break
    case 'date':
      comparatorOptions = comparatorsForNumber
      valueField = <DateField disabled={disabled} {...props} />
      break
    case 'time': {
      comparatorOptions = comparatorsForNumber
      valueField = <TimeField disabled={disabled} {...props} />
      break
    }
    case 'datetime': {
      comparatorOptions = comparatorsForNumber
      valueField = <DateTimeField disabled={disabled} {...props} />
      break
    }
    default:
      comparatorOptions = comparatorsForOther
  }
  if (questionRequired) {
    comparatorOptions = comparatorOptions.slice(0, comparatorOptions.length - 2)
  }

  return (
    <div className='logic-condition'>
      <Dropdown
        className='question-dropdown'
        onSelect={item =>
          updateLogicCondition({
            itemId,
            logic,
            conditionPath,
            field: 'question_id',
            value: item.value,
            field2: 'sub_question_id',
            value2: item.value2,
          })
        }
        options={questionOptions}
        required
        selected={term.sub_question_id || term.question_id}
      />
      <Dropdown
        className='comparator-dropdown'
        onSelect={item =>
          updateLogicCondition({ itemId, logic, conditionPath, field: 'comparator', value: item.value })
        }
        options={comparatorOptions}
        selected={term.comparator}
      />
      {valueField}
      <i onClick={() => deleteLogicCondition(itemId, conditionPath)} className='fas fa-trash clickable' />
    </div>
  )
}

ClinroLogicCondition.propTypes = {
  itemId: PropTypes.string,
  term: PropTypes.object,
  questionRequired: PropTypes.bool,
  questionOptions: PropTypes.array,
  logic: PropTypes.object,
  conditionPath: PropTypes.array,
  updateLogicCondition: PropTypes.func,
  deleteLogicCondition: PropTypes.func,
}

SelectDropdown.propTypes = ClinroLogicCondition.propTypes
InputField.propTypes = ClinroLogicCondition.propTypes
DateField.propTypes = ClinroLogicCondition.propTypes
TimeField.propTypes = ClinroLogicCondition.propTypes
DateTimeField.propTypes = ClinroLogicCondition.propTypes

export default ClinroLogicCondition
