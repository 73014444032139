import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Popup } from 'components/UIElements'

const ptpProperties = {
  ptpGemCount: {
    text: 'Participant Gem Count',
  },
  superGemCount: {
    text: 'Super Gem Count',
    disabledText: 'The selected study the participants are moving to does not support Super Gem. ',
  },
  avatarPurchases: {
    text: 'Avatar Purchases',
  },
  avatarSettings: {
    text: 'Avatar Settings',
  },
}

const ParticipantProperties = props => {
  const {
    canEditRewardProps,
    nextStudyID,
    ptpProperties: properties,
    setDefaultProperties,
    setPtpProperty,
    studyHasSuperGems,
  } = props

  useEffect(() => {
    setDefaultProperties()
    if (!studyHasSuperGems && nextStudyID) setPtpProperty('superGemCount')
  }, [nextStudyID, studyHasSuperGems])

  const getPtpProperties = () => {
    ptpProperties.superGemCount.disabled = !studyHasSuperGems && !!nextStudyID

    return Object.keys(ptpProperties).map(property => {
      const isNotSuperGems = !!ptpProperties[property].disabled
      return (
        <div key={property} className='properties'>
          <Checkbox
            checked={properties[property]?.toggle}
            disabled={ptpProperties[property]?.disabled || !canEditRewardProps}
            label={ptpProperties[property].text}
            onClick={() => {
              setPtpProperty(property)
            }}
          />
          {isNotSuperGems && (
            <Popup hover noPointer position='bottom-right' dark trigger={<i className='fa fa-info-circle' />}>
              <div className='hover-text'>{ptpProperties[property].disabledText}</div>
            </Popup>
          )}
        </div>
      )
    })
  }

  return (
    <div className='ptp-properties'>
      <h6>{'Select properties that will move over with the participant to the new study.'.toUpperCase()}</h6>
      {getPtpProperties()}
    </div>
  )
}

ParticipantProperties.propTypes = {
  canEditRewardProps: PropTypes.bool,
  nextStudyID: PropTypes.number,
  ptpProperties: PropTypes.object,
  setDefaultProperties: PropTypes.func,
  setPtpProperty: PropTypes.func,
  studyHasSuperGems: PropTypes.bool,
}

export default ParticipantProperties
