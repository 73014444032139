import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio, WarningText, AdvancedOptions } from 'components/UIElements'
import { GROUP_TYPE_MAP } from 'utils/constants'
import contentConnect from '../containers/ContentContainer'
import CohortManagementContainer from '../../../../../../Participants/routes/CreateParticipant/containers/CohortManagementContainer'
import DeploymentSiteSelection from './DeploymentSiteSelection'

const AGE_RANGE = {
  min: 0,
  max: 100,
}

const ParticipantManagement = props => {
  const {
    advancedOptions,
    ageDisabled,
    checkedCohorts,
    checkedSites,
    cohort,
    disableTrackCreation,
    errors,
    initializeCheckedSites,
    leafSites,
    noTracksFeature,
    resetAdvancedOptions,
    setAdvancedOptions,
    setAdvancedOptionsToStore,
    setShowAdvancedOption,
    showAdvancedOption,
    sitesList,
    toggleSite,
    updateCohort,
    updateCohortField,
  } = props
  const getCohort = Array.isArray(cohort) || showAdvancedOption ? {} : cohort
  const { filter = {}, type = '' } = getCohort
  return (
    <div className='ptp-management'>
      <p>
        Who should receive this instrument?
        {errors['schedule.cohort.filter.error'] && (
          <WarningText text={errors['schedule.cohort.filter.error']} disabled />
        )}
      </p>
      <Radio
        disabled={showAdvancedOption}
        selected={type === GROUP_TYPE_MAP.all}
        onClick={() => {
          updateCohort({
            type: GROUP_TYPE_MAP.all,
            filter: {},
          })
        }}
        id='all-participants'
        content='All Participants'
      />
      {!ageDisabled && (
        <Radio
          id='age-range'
          disabled={showAdvancedOption}
          selected={type === GROUP_TYPE_MAP.age}
          onClick={() => {
            updateCohort({
              type: GROUP_TYPE_MAP.age,
              filter: {
                min_age: AGE_RANGE.min,
                max_age: AGE_RANGE.max,
              },
            })
          }}
          content={<AgeRangeInput {...filter} updateCohortField={updateCohortField} errors={errors} type={type} />}
        />
      )}
      {!noTracksFeature && (
        <Radio
          id='track'
          disabled={showAdvancedOption}
          className='cohort-radio-content'
          selected={type === GROUP_TYPE_MAP.cohort}
          onClick={() => {
            updateCohort({
              type: GROUP_TYPE_MAP.cohort,
              filter: {
                include: Object.keys(checkedCohorts).map(id => parseInt(id, 10)),
              },
            })
          }}
          content={
            <CohortInput
              checkedCohorts={checkedCohorts}
              errorText={errors['schedule.cohort.filter.include']}
              hasError={errors.hasOwnProperty('schedule.cohort.filter.include')}
              type={type}
              updateCohortField={updateCohortField}
            />
          }
        />
      )}
      <Radio
        id='site'
        disabled={showAdvancedOption}
        className='site-radio-content'
        selected={type === GROUP_TYPE_MAP.sites}
        onClick={() => {
          updateCohort({
            type: GROUP_TYPE_MAP.sites,
            filter: {
              site_ids: Object.keys(checkedSites).map(id => parseInt(id, 10)),
            },
          })
        }}
        content={
          <SiteInput
            checkedSites={checkedSites}
            errors={errors}
            hasError={errors.hasOwnProperty('schedule.cohort.filter.site_ids')}
            initializeCheckedSites={initializeCheckedSites}
            leafSites={leafSites}
            siteIds={filter?.site_ids}
            sitesList={sitesList}
            toggleSite={toggleSite}
            type={type}
            updateCohortField={updateCohortField}
          />
        }
      />
      <AdvancedOptions
        advancedOptions={advancedOptions}
        cohort={cohort}
        disableTrackCreation={disableTrackCreation}
        errors={errors}
        exclude={{ age: ageDisabled, [GROUP_TYPE_MAP.cohort]: noTracksFeature }}
        selected={type}
        resetAdvancedOptions={resetAdvancedOptions}
        setAdvancedOptions={setAdvancedOptions}
        setShowAdvancedOption={setShowAdvancedOption}
        showAdvancedOption={showAdvancedOption}
        setConfig={{
          study_group: {
            type: GROUP_TYPE_MAP.cohort,
            label: 'Track',
            countableText: ['track', 'tracks'],
          },
        }}
        setAdvancedOptionsToStore={setAdvancedOptionsToStore}
      />
    </div>
  )
}

const AgeRangeInput = ({ min_age, max_age, updateCohortField, errors, type }) => {
  const minAge = min_age || min_age === AGE_RANGE.min ? min_age : ''
  const maxAge = max_age || max_age === AGE_RANGE.min ? max_age : ''
  const hasError = !!(errors['schedule.cohort.filter.min_age'] || errors['schedule.cohort.filter.min_age'])
  const errorText = 'Minimum and maximum age must be set'
  const onUpdateCohortField = field => value => {
    const otherField = field === 'max_age' ? 'min_age' : 'max_age'
    const otherValue = field === 'max_age' ? min_age : max_age
    if (value < AGE_RANGE.min || value > AGE_RANGE.max) return
    updateCohortField(
      'filter',
      { [field]: parseInt(value, 10), [otherField]: parseInt(otherValue, 10) },
      `schedule.cohort.filter${field}`,
    )
  }

  return (
    <span>
      Age Range
      {type === GROUP_TYPE_MAP.age && (
        <>
          <Input
            hasError={errors.hasOwnProperty('schedule.cohort.filter.min_age')}
            onBlur={() => {
              if (min_age > max_age) {
                onUpdateCohortField('max_age')(min_age)
              }
            }}
            className='age-input'
            id='min-age'
            placeholder='Min Age'
            type='number'
            value={minAge}
            onChange={onUpdateCohortField('min_age')}
          />
          <span>to</span>
          <Input
            hasError={errors.hasOwnProperty('schedule.cohort.filter.max_age')}
            onBlur={() => {
              if (max_age < min_age) {
                onUpdateCohortField('min_age')(max_age)
              }
            }}
            className='age-input'
            id='max-age'
            placeholder='Max Age'
            type='number'
            value={maxAge}
            onChange={onUpdateCohortField('max_age')}
            max={AGE_RANGE.max}
          />
          {hasError && <WarningText text={errorText} />}
        </>
      )}
    </span>
  )
}

export const CohortInput = ({ checkedCohorts, errorText, updateCohortField, hasError, type }) => {
  return (
    <div className='cohort-radio-column'>
      <p className='recipient-label'>Track</p>
      {type === GROUP_TYPE_MAP.cohort && (
        <CohortManagementContainer
          className={hasError ? 'has-error' : ''}
          errorText={errorText}
          hasError={hasError}
          onToggleCohort={id => {
            const newCohortData = { ...checkedCohorts }
            if (id in checkedCohorts) {
              delete newCohortData[id]
            } else {
              newCohortData[id] = null
            }
            updateCohortField(
              'filter',
              { include: Object.keys(newCohortData).map(_id => parseInt(_id, 10)) },
              'schedule.cohort.filter.include',
            )
          }}
        />
      )}
    </div>
  )
}

export const SiteInput = ({
  checkedSites,
  errors,
  hasError,
  initializeCheckedSites,
  leafSites,
  siteIds,
  sitesList,
  toggleSite,
  type,
  updateCohortField,
}) => {
  const errorText = errors['schedule.cohort.filter.site_ids']
  return (
    <>
      <p className='recipient-label'>Site</p>
      {type === GROUP_TYPE_MAP.sites && (
        <DeploymentSiteSelection
          checkedSites={checkedSites}
          errorText={errorText}
          hasError={hasError}
          leafSites={leafSites}
          siteIds={siteIds}
          sitesList={sitesList}
          toggleSite={toggleSite}
          updateCohortField={updateCohortField}
          initializeCheckedSites={initializeCheckedSites}
        />
      )}
    </>
  )
}

AgeRangeInput.propTypes = {
  errors: PropTypes.object,
  max_age: PropTypes.number,
  min_age: PropTypes.number,
  type: PropTypes.string,
  updateCohortField: PropTypes.func,
}

CohortInput.propTypes = {
  checkedCohorts: PropTypes.objectOf(PropTypes.number),
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  type: PropTypes.string,
  updateCohortField: PropTypes.func,
}

SiteInput.propTypes = {
  checkedSites: PropTypes.objectOf(PropTypes.string),
  errors: PropTypes.object,
  hasError: PropTypes.bool,
  initializeCheckedSites: PropTypes.func,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  siteIds: PropTypes.arrayOf(PropTypes.number),
  sitesList: PropTypes.arrayOf(PropTypes.array),
  toggleSite: PropTypes.func,
  type: PropTypes.string,
  updateCohortField: PropTypes.func,
}

ParticipantManagement.propTypes = {
  ageDisabled: PropTypes.bool,
  checkedCohorts: PropTypes.objectOf(PropTypes.number),
  checkedSites: PropTypes.objectOf(PropTypes.string),
  cohort: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  disableTrackCreation: PropTypes.bool,
  errors: PropTypes.object,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  noTracksFeature: PropTypes.bool,
  initializeCheckedSites: PropTypes.func,
  sitesList: PropTypes.arrayOf(PropTypes.array),
  toggleSite: PropTypes.func,
  updateCohort: PropTypes.func,
  updateCohortField: PropTypes.func,
  advancedOptions: PropTypes.object,
  setAdvancedOptions: PropTypes.func,
  showAdvancedOption: PropTypes.bool,
  resetAdvancedOptions: PropTypes.func,
  setShowAdvancedOption: PropTypes.func,
  setAdvancedOptionsToStore: PropTypes.func,
}

export default contentConnect(ParticipantManagement)
