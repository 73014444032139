import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'

import newConsentReducer, { fetchNewConsent } from '../NewConsentBuilder/modules/NewConsent'

import instrumentReducer from '../../../../../Instruments/routes/Instrument/modules/Instrument'

import ConsentFormatting from './container/ConsentFormattingContainer'

export const injectConsentReducers = store => {
  injectReducers(store, {
    instrumentReducer,
    newConsentReducer,
  })
}

export default store => {
  return {
    path: ':consentId/:consentVersion/formatting',
    getComponent(nextState, cb) {
      store.dispatch(setSubroute('formatting'))
      require.ensure(
        [],
        () => {
          injectConsentReducers(store)
          const { studyID, consentId, consentVersion } = nextState.params
          const { currentStudy } = store.getState().study
          setPageTitle(`Consent Page Formatting | Study ${studyID} | ${currentStudy.platform_name}`)
          store.dispatch(fetchNewConsent({ studyId: studyID, consentId, version: consentVersion }))

          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(UserHasScope(['xc'], ConsentFormatting, false, studyID, null, currentStudy)),
            ),
          )
        },
        '/consents/formatting',
      )
    },
  }
}
