import {
  UserIsAuthenticated,
  UserHasValidPassword,
  UserDoesNotHaveRole,
  UserHasFeatures,
} from 'containers/authContainers'
import { STUDY_CONFIG_MAP, USER_ROLES_WITH_BLOCKED_VIRTUAL_VISIT_ACCESS } from 'utils/constants'
import VisitsPageContainer from './containers/VisitsPageContainer'
import { actions } from './modules/Visits'

// Sync route definition
export default store => ({
  exact: true,
  getComponent(nextState, cb) {
    const { params } = nextState
    const { studyID } = params
    const { study } = store.getState()
    const { base_site_ids: baseSitesArr } = study?.currentStudy

    const siteID = baseSitesArr?.[0]

    require.ensure(
      [],
      () => {
        store.dispatch(actions.fetchVisits(studyID, siteID))
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserDoesNotHaveRole(
                USER_ROLES_WITH_BLOCKED_VIRTUAL_VISIT_ACCESS,
                UserHasFeatures(VisitsPageContainer, [STUDY_CONFIG_MAP.virtualVisits], 'compliance', false, false),
              ),
            ),
          ),
        )
      },
      'virtualvisits',
    )
  },
})
