import React from 'react'
import { Checkbox } from 'components/UIElements'

const FeatureFilterOption = ({ option, selectedOptions, selectOption }) => {
  return (
    <div className='feature-filter-option'>
      <Checkbox
        checked={!!selectedOptions.find(item => item.value === option.value)}
        onClick={() => {
          selectOption(option)
        }}
        label={option.text}
      />
    </div>
  )
}

export default FeatureFilterOption
