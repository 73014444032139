import React from 'react'
import PropTypes from 'prop-types'
import { PromptBar, Popup } from 'components/UIElements'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from 'utils/constants'

const LocalTimePrompt = ({ disabled = false, study, userId, isDiary }) => {
  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const isShown = hasInstrumentUnification ? true : !isDiary

  return isShown ? (
    <PromptBar className={`flexed${disabled ? ' disabled' : ''}`} status='medium'>
      <p>Note: All of the schedule times will adhere to each participant&apos;s local time zones.</p>
      <Popup hover noPointer dark position='bottom' trigger={<i className='fas fa-info-circle' />}>
        <p>
          <span>
            In the particular case in which a participant is in a different time zone from the time the instrument is
            scheduled, they will receive the instrument according to the time zone they were in
          </span>
          <strong> when this instrument was scheduled</strong>
          <span>.</span>
        </p>
      </Popup>
    </PromptBar>
  ) : null
}

LocalTimePrompt.propTypes = {
  disabled: PropTypes.bool,
}

export default LocalTimePrompt
