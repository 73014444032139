import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Radio, Checkbox } from 'components/UIElements'

const FORMAT_OPTIONS = ['Optimized for data analysis|(Long Format)', 'Optimized for response review|(Wide Format)']
const TYPE_OPTIONS = ['CSV'] // 'PDF' type to be add in future

const FileFormat = ({ isLongFormat, setIsLongFormat }) => {
  const updateSelection = _isLongFormat => {
    setIsLongFormat(_isLongFormat)
  }

  return FORMAT_OPTIONS.map((format, idx) => {
    const _isLongFormatOption = idx === 0
    const textArr = format.split('|')
    return (
      <div key={`ptp-diary-dl-format-${format}`}>
        <Radio
          selected={_isLongFormatOption ? isLongFormat : !isLongFormat}
          onClick={() => updateSelection(_isLongFormatOption)}
          content={textArr[0]}
        />
        <p>{textArr[1]}</p>
      </div>
    )
  })
}

const FileType = props => {
  const [isCheckedArr, setIsCheckedArr] = useState([true, true])

  const updateCheck = (idx, value) => {
    const newArr = [...isCheckedArr]
    newArr[idx] = value
    setIsCheckedArr(newArr)
  }

  return TYPE_OPTIONS.map((type, idx) => (
    <Checkbox
      key={`ptp-diary-dl-file-${type}`}
      disabled
      label={type}
      onClick={() => updateCheck(idx, !isCheckedArr[idx])}
      checked={isCheckedArr[idx]}
    />
  ))
}

const FileFormatType = props => {
  const { isFormat } = props

  return isFormat ? <FileFormat {...props} /> : <FileType />
}

FileFormatType.propTypes = {
  isFormat: PropTypes.bool,
}

export default FileFormatType
