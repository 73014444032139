import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'components/UIElements'
import { loadFrame } from '../../utils/useSisense'

const INSIGHTS_KEYS = {
  studyHeading: 'study_heading',
  siteHeading: 'site_heading',
}

const InsightsFrame = ({
  baseUrl,
  frameKey,
  sisenseLink,
  insightsModule,
  isStudyAdmin,
  sisenseLoaded,
  sisenseLoadingErr,
  studyID,
}) => {
  const [frameLoading, setFrameLoading] = useState(true)
  useEffect(() => {
    if (sisenseLoaded) {
      loadFrame({ frameKey, url: baseUrl, insightsModule, isStudyAdmin, setFrameLoading, studyID })
    }
  }, [sisenseLoaded])

  const headingKey = isStudyAdmin ? INSIGHTS_KEYS.studyHeading : INSIGHTS_KEYS.siteHeading

  return (
    <div className='insights-container insights-frame-wrapper med-height' id={`insights-frame-wrapper-${frameKey}`}>
      {sisenseLink}
      <h5>{insightsModule[headingKey]}</h5>
      {sisenseLoadingErr ? (
        <div>{sisenseLoadingErr}</div>
      ) : (
        <>
          {frameLoading && <Loader className='insights-frame-loader' inContainer />}
          <iframe
            className={`${frameLoading ? 'loading' : ''}`}
            id={`insights-iframe-${frameKey}`}
            title={`insights-iframe-${frameKey}`}
          />
        </>
      )}
    </div>
  )
}

InsightsFrame.propTypes = {
  baseUrl: PropTypes.string,
  frameKey: PropTypes.string,
  insightsModule: PropTypes.shape({}),
  isStudyAdmin: PropTypes.bool,
  sisenseLink: PropTypes.node,
  sisenseLoaded: PropTypes.bool,
  sisenseLoadingErr: PropTypes.string,
  studyID: PropTypes.string,
}

export default InsightsFrame
