import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { INPUT_VALIDATION_TYPE_MAP, DATE_FORMAT_MAP } from 'utils/constants'
import { pluralize } from 'utils/misc'
import moment from 'moment'
import STRINGS from '../../../../../../../../../../../../utils/strings'
import InstrumentDeploymentRange from './InstrumentDeploymentRange'
import SpecificDateRange from './SpecificDateRange'
import AllDatesRange from './AllDatesRange'
import TimePickerOverlay from './TimePickerOverlay'
import DatePickerOverlay from './DatePickerOverlay'

const PERIOD_IDS = {
  instrumentDeployed: 'instrumentDeployed',
  specificDate: 'specificDate',
  allDates: 'allDates',
}
const MOMENT_FORMAT = 'HH:mm'

const DateInputRange = props => {
  const { clearQuestionError, errors = {}, isDateTime, item, itemId, updateItem, preview } = props

  const getFromPeriodOptionId = () => {
    if (
      item?.input_validation?.relative?.preceding_interval ||
      item?.input_validation?.relative?.preceding_interval_type
    ) {
      return PERIOD_IDS.instrumentDeployed
    }

    if (
      item?.input_validation?.absolute_date?.has_from_participant_current_date ||
      item?.input_validation?.absolute_date?.min ||
      item?.input_validation?.absolute_time?.min
    ) {
      return PERIOD_IDS.specificDate
    }

    return PERIOD_IDS.allDates
  }

  const getToPeriodOptionId = () => {
    if (
      item?.input_validation?.relative?.succeeding_interval ||
      item?.input_validation?.relative?.succeeding_interval_type
    ) {
      return PERIOD_IDS.instrumentDeployed
    }

    if (
      item?.input_validation?.absolute_date?.has_to_participant_current_date ||
      item?.input_validation?.absolute_date?.max ||
      item?.input_validation?.absolute_time?.max
    ) {
      return PERIOD_IDS.specificDate
    }

    return PERIOD_IDS.allDates
  }

  const [fromPeriodOptionId, setFromPeriodOptionId] = useState(getFromPeriodOptionId())
  const [toPeriodOptionId, setToPeriodOptionId] = useState(getToPeriodOptionId())
  const [fromWindowOptions, setFromWindowOptions] = useState(null)
  const [toWindowOptions, setToWindowOptions] = useState(null)

  const [fromInputRef, setFromInputRef] = useState(undefined)
  const [toInputRef, setToInputRef] = useState(undefined)

  const hasError = errors.numericInputRangeError
  const hasTimePickerError = errors.timeInputRangeError

  const itemWithDefaults = {
    ...item,
    input_validation: {
      absolute_date: !isDateTime
        ? {
            has_from_participant_current_date: false,
            has_to_participant_current_date: false,
            ...item.input_validation?.absolute_date,
          }
        : undefined,
      absolute_time: isDateTime
        ? {
            has_from_participant_current_time: false,
            has_to_participant_current_time: false,
            ...item.input_validation?.absolute_time,
          }
        : undefined,
      accept_future_values: false,
      ...item.input_validation,
    },
  }

  useEffect(() => {
    // Set default input_validation object
    if (updateItem) {
      updateItem(itemId, itemWithDefaults)
    }
  }, [])

  useEffect(() => {
    if (item.input_validation?.relative?.preceding_interval) {
      setFromWindowOptions({
        type: item.input_validation?.relative?.preceding_interval_type,
        value: item.input_validation?.relative?.preceding_interval,
      })
    }

    if (item.input_validation?.relative?.succeeding_interval) {
      setToWindowOptions({
        type: item.input_validation?.relative?.succeeding_interval_type,
        value: item.input_validation?.relative?.succeeding_interval,
      })
    }
  }, [])

  const updateInput = (key, val, acceptFutureValues, reset, fieldToRemove) => {
    const prevInputValidation = itemWithDefaults.input_validation

    if (hasError || hasTimePickerError) {
      clearQuestionError(itemId)
    }

    if (key === 'relative') {
      delete prevInputValidation.absolute_date
    }

    if (key === 'absolute_date') {
      delete prevInputValidation.relative
    }

    if (isDateTime && fieldToRemove) {
      delete prevInputValidation[fieldToRemove]
    }

    if (isDateTime && reset) {
      prevInputValidation.absolute_time = {
        has_from_participant_current_time: false,
        has_to_participant_current_time: false,
        min: '',
        max: '',
      }
    }

    const newItem = {
      ...item,
      input_validation: {
        ...prevInputValidation,
        [key]: val,
        accept_future_values: acceptFutureValues ?? prevInputValidation.accept_future_values,
      },
    }

    if (
      newItem?.input_validation?.absolute_date?.min ||
      newItem?.input_validation?.absolute_date?.max ||
      newItem?.input_validation?.absolute_time?.min ||
      newItem?.input_validation?.absolute_time?.max
    ) {
      newItem.input_validation.accept_future_values = true
    }

    updateItem(itemId, newItem)
  }

  const addProps = (Component, checked, disabled, onClick) => {
    const WrappedComponent = props => {
      return <Component {...props} checked={checked} disabled={disabled || preview} onClick={onClick} />
    }
    return WrappedComponent
  }

  const getDatePeriodOptions = field => {
    return [
      {
        key: 'years',
        value: 'years',
        text: pluralize(field, 'Year', 'Years', false),
      },
      {
        key: 'months',
        value: 'months',
        text: pluralize(field, 'Month', 'Months', false),
      },
      {
        key: 'weeks',
        value: 'weeks',
        text: pluralize(field, 'Week', 'Weeks', false),
      },
      {
        key: 'days',
        value: 'days',
        text: pluralize(field, 'Day', 'Days', false),
      },
    ]
  }

  const setFromRef = itemRef => {
    setFromInputRef(itemRef)
  }

  const setToRef = itemRef => {
    setToInputRef(itemRef)
  }

  const hasFromPtpSpecificCurrentDate = !!itemWithDefaults.input_validation?.absolute_date
    ?.has_from_participant_current_date
  const hasToPtpSpecificCurrentDate = !!itemWithDefaults.input_validation?.absolute_date
    ?.has_to_participant_current_date

  const hasFromPtpCurrentTime = !!itemWithDefaults.input_validation?.absolute_time?.has_from_participant_current_time
  const hasToPtpCurrentTime = !!itemWithDefaults.input_validation?.absolute_time?.has_to_participant_current_time

  const getToDisabledDays = () => {
    if (hasToPtpSpecificCurrentDate) {
      return { before: new Date(), after: new Date() }
    }
    if (itemWithDefaults.input_validation.absolute_date?.min) {
      const date = new Date(itemWithDefaults.input_validation.absolute_date?.min)

      const dateNextDay = new Date(date)
      dateNextDay.setDate(date.getDate() + 1)
      return { before: dateNextDay }
    }
  }

  const fromPeriodOptions = [
    {
      id: PERIOD_IDS.instrumentDeployed,
      component: (
        <InstrumentDeploymentRange
          disabled={preview}
          id={PERIOD_IDS.instrumentDeployed}
          onClick={() => {
            setFromPeriodOptionId(PERIOD_IDS.instrumentDeployed)
            setToWindowOptions(null)

            setToPeriodOptionId(PERIOD_IDS.allDates)
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                ...itemWithDefaults.input_validation?.relative,
                preceding_interval: 0,
                preceding_interval_type: 'days',
                reference: 'deployment_day',
                has_from_participant_current_date: false,
                has_to_participant_current_date: false,
              },
              false,
              true,
            )
          }}
          selected={fromPeriodOptionId === PERIOD_IDS.instrumentDeployed}
          windowOptions={fromWindowOptions}
          isDateTime={isDateTime}
          hasError={hasTimePickerError}
          value={
            itemWithDefaults.input_validation.absolute_time?.min
              ? moment(itemWithDefaults.input_validation.absolute_time?.min, MOMENT_FORMAT)
              : ''
          }
          onChange={val => {
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.absTime,
              {
                ...itemWithDefaults.input_validation.absolute_time,
                min: val ? moment(val).format(MOMENT_FORMAT) : '',
                has_from_participant_current_time: val
                  ? false
                  : itemWithDefaults.input_validation.absolute_time?.has_from_participant_current_time,
                has_to_participant_current_time: val
                  ? false
                  : itemWithDefaults.input_validation.absolute_time?.has_to_participant_current_time,
              },
              true,
              false,
              'absolute_date',
            )
          }}
          addon={() => (
            <TimePickerOverlay
              checked={!!itemWithDefaults.input_validation.absolute_time?.has_from_participant_current_time}
              disabled={preview}
              onClick={() => {
                updateInput(
                  INPUT_VALIDATION_TYPE_MAP.absTime,
                  {
                    ...itemWithDefaults.input_validation.absolute_time,
                    has_from_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                      .has_from_participant_current_time,
                    has_to_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                      .has_from_participant_current_time,
                    min: '',
                    max: '',
                  },
                  true,
                )

                setToPeriodOptionId(PERIOD_IDS.instrumentDeployed)
              }}
              itemWithDefaults={itemWithDefaults}
            />
          )}
          onAddButtonClick={() => {
            setFromWindowOptions({ type: 'days', value: 1 })
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                ...itemWithDefaults.input_validation.relative,
                preceding_interval_type: 'days',
                preceding_interval: 1,
              },
              true,
            )
          }}
          inputValue={itemWithDefaults.input_validation.relative?.preceding_interval}
          onInputChange={val => {
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                ...itemWithDefaults.input_validation.relative,
                preceding_interval: val,
              },
              true,
            )
          }}
          selectedDropdownItem={itemWithDefaults.input_validation.relative?.preceding_interval_type}
          dropdownOptions={getDatePeriodOptions(itemWithDefaults.input_validation.relative?.preceding_interval)}
          onDropdownSelect={item => {
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                ...itemWithDefaults.input_validation.relative,
                preceding_interval_type: item.value,
              },
              true,
            )
          }}
          subtitle={STRINGS.beforeInstrumentDeployment}
          onRemoveButtonClick={() => {
            setFromWindowOptions(null)
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                preceding_interval: 0,
                preceding_interval_type: 'days',
                succeeding_interval: 0,
                succeeding_interval_type: 'days',
                reference: 'deployment_day',
              },
              true,
            )
          }}
          from
          timePickerPlaceholder={hasFromPtpCurrentTime ? STRINGS.participantCurrentTime : ''}
        />
      ),
    },
    {
      id: PERIOD_IDS.specificDate,
      component: (
        <SpecificDateRange
          disabled={preview}
          id={PERIOD_IDS.specificDate}
          onClick={() => {
            setFromPeriodOptionId(PERIOD_IDS.specificDate)
            setFromWindowOptions(null)
            setToWindowOptions(null)

            setToPeriodOptionId(PERIOD_IDS.allDates)
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.absDate,
              {
                ...itemWithDefaults.input_validation?.absolute_date,
                min: '',
                has_from_participant_current_date: false,
                has_to_participant_current_date: false,
              },
              false,
              true,
            )
          }}
          selected={fromPeriodOptionId === PERIOD_IDS.specificDate}
          onDayChange={val => {
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.absDate,
              {
                ...itemWithDefaults.input_validation?.absolute_date,
                min: val ? moment(val).format(DATE_FORMAT_MAP.datePicker) : '',
                has_from_participant_current_date: val
                  ? false
                  : itemWithDefaults.input_validation?.absolute_date?.has_from_participant_current_date,
                has_to_participant_current_date: val
                  ? false
                  : itemWithDefaults.input_validation?.absolute_date?.has_to_participant_current_date,
              },
              !!val,
            )
          }}
          date={
            itemWithDefaults.input_validation.absolute_date?.min
              ? moment(itemWithDefaults.input_validation.absolute_date?.min, DATE_FORMAT_MAP.datePicker)
              : ''
          }
          placeholder={hasFromPtpSpecificCurrentDate ? STRINGS.participantCurrentDate : STRINGS.selectDate}
          isDateTime={isDateTime}
          inputClassName={hasFromPtpSpecificCurrentDate ? 'long' : ''}
          setRef={fromPeriodOptionId === PERIOD_IDS.specificDate ? setFromRef : undefined}
          _ref={fromPeriodOptionId === PERIOD_IDS.specificDate ? fromInputRef : undefined}
          overlayComponent={addProps(
            DatePickerOverlay,
            !!itemWithDefaults.input_validation?.absolute_date?.has_from_participant_current_date,
            false,
            () => {
              if (!itemWithDefaults.input_validation?.absolute_date?.has_from_participant_current_date) {
                setToPeriodOptionId(PERIOD_IDS.specificDate)
              }
              updateInput(
                INPUT_VALIDATION_TYPE_MAP.absDate,
                {
                  ...itemWithDefaults.input_validation?.absolute_date,
                  has_from_participant_current_date: !itemWithDefaults.input_validation?.absolute_date
                    ?.has_from_participant_current_date,
                  has_to_participant_current_date: !itemWithDefaults.input_validation?.absolute_date
                    ?.has_from_participant_current_date
                    ? true
                    : itemWithDefaults.input_validation?.absolute_date?.has_to_participant_current_date,
                  min: '',
                  max: '',
                },
                itemWithDefaults.input_validation?.absolute_date?.has_from_participant_current_date,
              )

              fromInputRef?.current?.focus()
            },
          )}
          onDateContainerClick={() => {
            fromInputRef?.current?.focus()
          }}
          hasError={hasTimePickerError}
          value={
            itemWithDefaults.input_validation.absolute_time?.min
              ? moment(itemWithDefaults.input_validation.absolute_time?.min, MOMENT_FORMAT)
              : ''
          }
          onChange={val => {
            updateInput(INPUT_VALIDATION_TYPE_MAP.absTime, {
              ...itemWithDefaults.input_validation.absolute_time,
              min: val ? moment(val).format(MOMENT_FORMAT) : '',
              has_from_participant_current_time: val
                ? false
                : itemWithDefaults.input_validation.absolute_time?.has_from_participant_current_time,
              has_to_participant_current_time: val
                ? false
                : itemWithDefaults.input_validation.absolute_time?.has_to_participant_current_time,
            })
          }}
          addon={() => (
            <TimePickerOverlay
              checked={!!itemWithDefaults.input_validation?.absolute_time?.has_from_participant_current_time}
              disabled={preview}
              onClick={() => {
                updateInput(INPUT_VALIDATION_TYPE_MAP.absTime, {
                  ...itemWithDefaults.input_validation?.absolute_time,
                  has_from_participant_current_time: !itemWithDefaults.input_validation?.absolute_time
                    ?.has_from_participant_current_time,
                  has_to_participant_current_time: !itemWithDefaults.input_validation?.absolute_time
                    ?.has_from_participant_current_time,
                  min: '',
                  max: '',
                })
              }}
              itemWithDefaults={itemWithDefaults}
            />
          )}
          timePickerPlaceholder={hasFromPtpCurrentTime ? STRINGS.participantCurrentTime : ''}
        />
      ),
    },
    {
      id: PERIOD_IDS.allDates,
      component: (
        <AllDatesRange
          id={PERIOD_IDS.allDates}
          disabled={preview}
          onClick={() => {
            setFromPeriodOptionId(PERIOD_IDS.allDates)
            setFromWindowOptions(null)

            setToPeriodOptionId(PERIOD_IDS.allDates)

            updateInput(
              INPUT_VALIDATION_TYPE_MAP.absDate,
              {
                has_from_participant_current_date: false,
                has_to_participant_current_date: false,
                min: '',
                max: '',
              },
              false,
              true,
            )
          }}
          selected={fromPeriodOptionId === PERIOD_IDS.allDates}
          itemWithDefaults={itemWithDefaults}
          updateInput={updateInput}
          isDateTime={isDateTime}
          from
        />
      ),
    },
  ]

  const toPeriodOptions = [
    {
      id: PERIOD_IDS.instrumentDeployed,
      component: (
        <InstrumentDeploymentRange
          disabled={fromPeriodOptionId === PERIOD_IDS.specificDate || hasFromPtpSpecificCurrentDate || preview}
          id={PERIOD_IDS.instrumentDeployed}
          onClick={() => {
            setToPeriodOptionId(PERIOD_IDS.instrumentDeployed)

            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                ...itemWithDefaults.input_validation?.relative,
                succeeding_interval: 0,
                succeeding_interval_type: 'days',
                reference: 'deployment_day',
                has_from_participant_current_date: false,
                has_to_participant_current_date: false,
              },
              true,
            )
          }}
          selected={toPeriodOptionId === PERIOD_IDS.instrumentDeployed}
          windowOptions={toWindowOptions}
          isDateTime={isDateTime}
          hasError={hasTimePickerError}
          value={
            itemWithDefaults.input_validation.absolute_time?.max
              ? moment(itemWithDefaults.input_validation.absolute_time?.max, MOMENT_FORMAT)
              : ''
          }
          onChange={val => {
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.absTime,
              {
                ...itemWithDefaults.input_validation.absolute_time,
                max: val ? moment(val).format(MOMENT_FORMAT) : '',
                has_from_participant_current_time: val
                  ? false
                  : itemWithDefaults.input_validation.absolute_time?.has_from_participant_current_time,
                has_to_participant_current_time: val
                  ? false
                  : itemWithDefaults.input_validation.absolute_time?.has_to_participant_current_time,
              },
              true,
              false,
              'absolute_date',
            )
          }}
          addon={() => (
            <TimePickerOverlay
              checked={!!itemWithDefaults.input_validation.absolute_time?.has_to_participant_current_time}
              disabled={preview}
              onClick={() => {
                updateInput(
                  INPUT_VALIDATION_TYPE_MAP.absTime,
                  {
                    ...itemWithDefaults.input_validation?.absolute_time,
                    has_to_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                      .has_to_participant_current_time,
                    has_from_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                      .has_to_participant_current_time,
                    min: '',
                    max: '',
                  },
                  true,
                )
              }}
              itemWithDefaults={itemWithDefaults}
            />
          )}
          onAddButtonClick={() => {
            setToWindowOptions({ type: 'days', value: 1 })
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                ...itemWithDefaults.input_validation.relative,
                succeeding_interval_type: 'days',
                succeeding_interval: 1,
              },
              true,
            )
          }}
          inputValue={itemWithDefaults.input_validation.relative?.succeeding_interval}
          onInputChange={val => {
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                ...itemWithDefaults.input_validation.relative,
                succeeding_interval: val,
              },
              true,
            )
          }}
          selectedDropdownItem={itemWithDefaults.input_validation.relative?.succeeding_interval_type}
          dropdownOptions={getDatePeriodOptions(itemWithDefaults.input_validation.relative?.succeeding_interval)}
          onDropdownSelect={item => {
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                ...itemWithDefaults.input_validation.relative,
                succeeding_interval_type: item.value,
              },
              true,
            )
          }}
          subtitle={STRINGS.afterInstrumentDeployment}
          onRemoveButtonClick={() => {
            setToWindowOptions(null)
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.relative,
              {
                preceding_interval: 0,
                preceding_interval_type: 'days',
                succeeding_interval: 0,
                succeeding_interval_type: 'days',
                reference: 'deployment_day',
              },
              true,
            )
          }}
          toggleDisabled={
            !!(fromPeriodOptionId === PERIOD_IDS.specificDate || itemWithDefaults.input_validation.absolute_time?.min)
          }
          toggleChecked={!!itemWithDefaults.input_validation.accept_future_values}
          onToggleClick={() => {
            updateInput(
              INPUT_VALIDATION_TYPE_MAP.acceptFutureValues,
              !itemWithDefaults.input_validation.accept_future_values,
              !itemWithDefaults.input_validation.accept_future_values,
            )
          }}
          timePickerPlaceholder={hasToPtpCurrentTime ? STRINGS.participantCurrentTime : ''}
        />
      ),
    },
    {
      id: PERIOD_IDS.specificDate,
      component: (
        <SpecificDateRange
          disabled={fromPeriodOptionId === PERIOD_IDS.instrumentDeployed || preview}
          id={PERIOD_IDS.specificDate}
          onClick={() => {
            setToPeriodOptionId(PERIOD_IDS.specificDate)
            setToWindowOptions(null)

            updateInput(
              INPUT_VALIDATION_TYPE_MAP.absDate,
              {
                ...itemWithDefaults.input_validation?.absolute_date,
              },
              true,
            )
          }}
          selected={toPeriodOptionId === PERIOD_IDS.specificDate}
          onDayChange={val => {
            updateInput(INPUT_VALIDATION_TYPE_MAP.absDate, {
              ...itemWithDefaults.input_validation?.absolute_date,
              max: val ? moment(val).format(DATE_FORMAT_MAP.datePicker) : '',
            })
          }}
          date={
            itemWithDefaults.input_validation.absolute_date?.max
              ? moment(itemWithDefaults.input_validation.absolute_date?.max, DATE_FORMAT_MAP.datePicker)
              : ''
          }
          placeholder={hasToPtpSpecificCurrentDate ? STRINGS.participantCurrentDate : STRINGS.selectDate}
          disabledDays={getToDisabledDays()}
          inputClassName={hasToPtpSpecificCurrentDate ? 'long' : ''}
          isDateTime={isDateTime}
          setRef={toPeriodOptionId === PERIOD_IDS.specificDate ? setToRef : undefined}
          _ref={toPeriodOptionId === PERIOD_IDS.specificDate ? toInputRef : undefined}
          overlayComponent={addProps(
            DatePickerOverlay,
            !!itemWithDefaults.input_validation?.absolute_date?.has_to_participant_current_date,
            !!itemWithDefaults.input_validation?.absolute_date?.has_from_participant_current_date ||
              !!itemWithDefaults.input_validation?.absolute_date?.min,
            () => {
              updateInput(
                INPUT_VALIDATION_TYPE_MAP.absDate,
                {
                  ...itemWithDefaults.input_validation?.absolute_date,
                  has_to_participant_current_date: !itemWithDefaults.input_validation?.absolute_date
                    ?.has_to_participant_current_date,
                  max: '',
                },
                itemWithDefaults.input_validation?.absolute_date?.has_to_participant_current_date,
              )
              toInputRef?.current?.focus()
            },
          )}
          onDateContainerClick={() => {
            toInputRef?.current?.focus()
          }}
          hasError={hasTimePickerError}
          value={
            itemWithDefaults.input_validation.absolute_time?.max
              ? moment(itemWithDefaults.input_validation.absolute_time?.max, MOMENT_FORMAT)
              : ''
          }
          onChange={val => {
            updateInput(INPUT_VALIDATION_TYPE_MAP.absTime, {
              ...itemWithDefaults.input_validation.absolute_time,
              max: val ? moment(val).format(MOMENT_FORMAT) : '',
              has_from_participant_current_time: val
                ? false
                : itemWithDefaults.input_validation.absolute_time?.has_from_participant_current_time,
              has_to_participant_current_time: val
                ? false
                : itemWithDefaults.input_validation.absolute_time?.has_to_participant_current_time,
            })
          }}
          addon={() => (
            <TimePickerOverlay
              checked={!!itemWithDefaults.input_validation?.absolute_time?.has_to_participant_current_time}
              disabled={preview}
              onClick={() => {
                updateInput(INPUT_VALIDATION_TYPE_MAP.absTime, {
                  ...itemWithDefaults.input_validation?.absolute_time,
                  has_to_participant_current_time: !itemWithDefaults.input_validation?.absolute_time
                    .has_to_participant_current_time,
                  has_from_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                    .has_to_participant_current_time,
                  min: '',
                  max: '',
                })
              }}
              itemWithDefaults={itemWithDefaults}
            />
          )}
          timePickerPlaceholder={hasToPtpCurrentTime ? STRINGS.participantCurrentTime : ''}
        />
      ),
    },
    {
      id: PERIOD_IDS.allDates,
      component: (
        <AllDatesRange
          id={PERIOD_IDS.allDates}
          disabled={hasFromPtpSpecificCurrentDate || preview}
          toggleDisabled={!!itemWithDefaults.input_validation.absolute_date?.min}
          onClick={() => {
            setToPeriodOptionId(PERIOD_IDS.allDates)
            setToWindowOptions(null)

            if (fromPeriodOptionId !== PERIOD_IDS.instrumentDeployed) {
              updateInput(INPUT_VALIDATION_TYPE_MAP.absDate, {
                ...itemWithDefaults.input_validation?.absolute_date,
                max: '',
              })
            } else {
              updateInput(INPUT_VALIDATION_TYPE_MAP.relative, {
                ...itemWithDefaults.input_validation?.relative,
                succeeding_interval: undefined,
                succeeding_interval_type: undefined,
              })
            }
          }}
          selected={toPeriodOptionId === PERIOD_IDS.allDates}
          itemWithDefaults={itemWithDefaults}
          updateInput={updateInput}
          isDateTime={isDateTime}
        />
      ),
    },
  ]

  return (
    <div className={`non-select-preview date${isDateTime ? 'time' : ''}-input-range date-period-range`}>
      <p className='heading'>{isDateTime ? STRINGS.selectDateTimeRange : STRINGS.selectDateRange}</p>
      <p className='subheading'>{STRINGS.acceptFrom}</p>
      <div className={`radio-group ${fromWindowOptions ? 'expanded' : ''}`}>
        {fromPeriodOptions.map(option => (
          <Fragment key={option.id}>{option.component}</Fragment>
        ))}
      </div>

      <p className='subheading'>{STRINGS.acceptTo}</p>
      <div className={`radio-group ${toWindowOptions || toPeriodOptionId === PERIOD_IDS.allDates ? 'expanded' : ''}`}>
        {toPeriodOptions.map(option => (
          <Fragment key={option.id}>{option.component}</Fragment>
        ))}
      </div>
    </div>
  )
}

DateInputRange.propTypes = {
  clearQuestionError: PropTypes.func,
  dateDisplayComponent: PropTypes.node,
  displayComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errors: PropTypes.object,
  isLastQuestion: PropTypes.bool,
  isDateTime: PropTypes.bool,
  isDecimal: PropTypes.bool,
  item: PropTypes.object,
  itemId: PropTypes.string,
  preview: PropTypes.bool,
  timeDisplayComponent: PropTypes.node,
  updateItem: PropTypes.func,
}

export default DateInputRange
