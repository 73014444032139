import { setSubroute } from 'store/navbar'
import AnnouncementsRoute from './routes/AnnouncementsPage'
import CreateAnnouncementRoute from './routes/CreateAnnouncement'
import CreateSMSRoute from './routes/CreateSMS'
import EditAnnouncementRoute from './routes/EditAnnouncement'
import EditSMSRoute from './routes/EditSMS'

// Sync route definition for Announcements
export default store => ({
  path: 'communication',
  // routed at when navigating to 'studies/:studyID/communication'
  indexRoute: AnnouncementsRoute(store),
  // any route that is nested below `studies/:studyID/communication` will render one of the following child routes
  getChildRoutes(location, cb) {
    store.dispatch(setSubroute('communication'))
    cb(null, [CreateAnnouncementRoute(store), CreateSMSRoute(store), EditAnnouncementRoute(store), EditSMSRoute(store)])
  },
})
