import React from 'react'
import PropTypes from 'prop-types'
import { Container, Dropdown } from 'components/UIElements'
import CONSENT_DEPLOYMENT_TYPES from '../../../utils/consentConstants'

const RELATIVE_DEPLOYMENT_COPY = 'Deployment timing relative to participant enrollment status?'
const CLINRO_REROUTE_COPY = 'Please note: The consent form will be rerouted to ClinRO after the participant signs.'

const DROPDOWN_OPTIONS = [
  {
    key: CONSENT_DEPLOYMENT_TYPES.all.key,
    value: CONSENT_DEPLOYMENT_TYPES.all.key,
    text: CONSENT_DEPLOYMENT_TYPES.all.text,
  },
  {
    key: CONSENT_DEPLOYMENT_TYPES.existing.key,
    value: CONSENT_DEPLOYMENT_TYPES.existing.key,
    text: CONSENT_DEPLOYMENT_TYPES.existing.text,
  },
  {
    key: CONSENT_DEPLOYMENT_TYPES.new.key,
    value: CONSENT_DEPLOYMENT_TYPES.new.key,
    text: CONSENT_DEPLOYMENT_TYPES.new.text,
  },
]

const ConsentDeploymentTiming = props => {
  const { consent } = props
  const { metadata } = consent
  const { schedule } = metadata
  const { cohort } = schedule

  const getSelected = () => {
    if (cohort?.[CONSENT_DEPLOYMENT_TYPES.existing.key]) return CONSENT_DEPLOYMENT_TYPES.existing.key
    if (cohort?.[CONSENT_DEPLOYMENT_TYPES.new.key]) return CONSENT_DEPLOYMENT_TYPES.new.key
    return CONSENT_DEPLOYMENT_TYPES.all.key
  }

  const onSelect = item => {
    const { updateCohort } = props
    const newCohort = { ...cohort }
    switch (item.key) {
      case CONSENT_DEPLOYMENT_TYPES.existing.key:
        delete newCohort[CONSENT_DEPLOYMENT_TYPES.new.key]
        newCohort[CONSENT_DEPLOYMENT_TYPES.existing.key] = true
        break
      case CONSENT_DEPLOYMENT_TYPES.new.key:
        delete newCohort[CONSENT_DEPLOYMENT_TYPES.existing.key]
        newCohort[CONSENT_DEPLOYMENT_TYPES.new.key] = true
        break
      default:
        delete newCohort[CONSENT_DEPLOYMENT_TYPES.new.key]
        delete newCohort[CONSENT_DEPLOYMENT_TYPES.existing.key]
    }
    updateCohort(newCohort)
  }

  return (
    <Container>
      <div className='consent-deployment-time'>
        <p>{RELATIVE_DEPLOYMENT_COPY}</p>
        <Dropdown
          id='consent-deployment-time'
          onSelect={onSelect}
          options={DROPDOWN_OPTIONS}
          placeholder='Select'
          selected={getSelected()}
        />
        <p>{CLINRO_REROUTE_COPY}</p>
      </div>
    </Container>
  )
}

ConsentDeploymentTiming.propTypes = {
  consent: PropTypes.object,
  updateCohort: PropTypes.func,
}

export default ConsentDeploymentTiming
