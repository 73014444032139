import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import moment from 'moment'
import request, { generateNotyMessage } from 'utils/request'
import { CONDITION_ACTION_TYPE, CONDITION_RESOURCE_TYPE, CONDITION_RESOURCE_FUNCTION_TYPE } from 'utils/constants'
import { deleteObjectKey } from 'utils/object'
import { loadingActions } from 'store/loader'
import { actions as notyActions } from 'layouts/ErrorBox'
import { CONDITIONAL_NOTIFICATION_TYPE_MAP } from '../../../NotificationsPage/components/NotificationTypes'

//
// Actions
//

const ADD_NOTIFICATION_CONDITION = 'ADD_NOTIFICATION_CONDITION'
const DELETE_NOTIFICATION_CONDITION = 'DELETE_NOTIFICATION_CONDITION'
const SET_MED_ADH_NOTIFICIATIONS = 'SET_MED_ADH_NOTIFICATIONS'
const UPDATE_MED_ADH_NOTIFICATION_ACTION = 'UPDATE_MED_ADH_NOTIFICATION_ACTION'
const UPDATE_MED_ADH_NOTIFICATION_SCHEDULE_TIME = 'UPDATE_NOTIFICATION_SCHEDULE_TIME'
const UPDATE_MED_ADH_NOTIFICATION_TRIGGER = 'UPDATE_MED_ADH_NOTIFICATION_TRIGGER'
const CLEAR_MED_ADH_NOTIFICATIONS = 'CLEAR_MED_ADH_NOTIFICATIONS'
const SET_INSTRUMENT_TRIGGERS = 'SET_INSTRUMENT_TRIGGERS'

//
// Action Creators
//

const setMedicationNotifications = medNotifications => {
  let resMedicationNotifications = medNotifications
  const noConditions = medNotifications.conditions?.length === 0
  if (noConditions) resMedicationNotifications = _getDefaultMedicationAdherenceNotifications()
  return {
    type: SET_MED_ADH_NOTIFICIATIONS,
    medNotifications: resMedicationNotifications,
    enabled: !noConditions,
  }
}
const clearMedicationNotifications = () => {
  return {
    type: CLEAR_MED_ADH_NOTIFICATIONS,
  }
}

const deleteMedNotificationCondition = index => {
  return {
    type: DELETE_NOTIFICATION_CONDITION,
    index,
  }
}

const setInstrumentTriggers = triggers => {
  return {
    type: SET_INSTRUMENT_TRIGGERS,
    triggers,
  }
}

const updateMedNotificationScheduleTime = timeString => {
  return {
    type: UPDATE_MED_ADH_NOTIFICATION_SCHEDULE_TIME,
    timeString,
  }
}
const updateMedNotificationTrigger = ({ key, value, key2, value2 }, index) => {
  return {
    type: UPDATE_MED_ADH_NOTIFICATION_TRIGGER,
    key,
    value,
    key2,
    value2,
    index,
  }
}
const updateMedNotificationAction = ({ key, value, key2, value2 }, index) => {
  return {
    type: UPDATE_MED_ADH_NOTIFICATION_ACTION,
    key,
    value,
    key2,
    value2,
    index,
  }
}

const addMedNotificationConditionItem = () => {
  return {
    type: ADD_NOTIFICATION_CONDITION,
  }
}

//
// API Actions
//

const fetchConditions = (studyId, type) => dispatch => {
  const success = json => {
    const resultJson = { ...json }
    delete resultJson.message
    const medicationNotificationsConditionsObj = _filterForMedNotificationConditions(resultJson)
    dispatch(setMedicationNotifications(medicationNotificationsConditionsObj))
  }
  if (type === CONDITIONAL_NOTIFICATION_TYPE_MAP.medicationAdherence) {
    return dispatch(
      request({
        url: `/control/studies/${studyId}/conditions`,
        hasLoader: true,
        success,
      }),
    )
  }
}

const _fetchCondition = (studyId, conditionId) => dispatch => {
  const success = _json => {
    const resultJson = { ..._json }
    const { condition: _condition } = resultJson
    delete _condition.id
    _condition.condition_id = conditionId
    return Promise.resolve(_condition)
  }
  return dispatch(
    request({
      url: `/control/studies/${studyId}/conditions/${conditionId}`,
      hasLoader: true,
      success,
    }),
  )
}

export const fetchInstrumentConditions = (studyId, conditionsArr = []) => dispatch => {
  if (conditionsArr.length === 0) {
    return Promise.resolve()
  }
  const fetchConditionRequests = conditionsArr.map(conditionId => {
    return dispatch(_fetchCondition(studyId, conditionId))
  })
  dispatch(loadingActions.startLoader(true, 'trigger'))
  return Promise.all(fetchConditionRequests).then(results => {
    dispatch(setInstrumentTriggers(results))
    dispatch(loadingActions.stopLoader(true, 'trigger'))
  })
}

const _createConditions = (studyId, conditions, redirect, individual, isInstrumentTrigger) => dispatch => {
  const success = payload => {
    if (individual && !isInstrumentTrigger) return Promise.resolve()
    if (individual && isInstrumentTrigger) return Promise.resolve(payload.conditions[0])
    if (isInstrumentTrigger) {
      return Promise.resolve(payload.conditions)
    }
    browserHistory.push(redirect)
  }
  return dispatch(
    request({
      url: `/control/studies/${studyId}/conditions`,
      body: JSON.stringify(conditions),
      method: 'POST',
      successMessage: individual ? null : 'Conditions saved successfully',
      hasLoader: individual ? false : true,
      failMessage: 'Failed to save conditions to database.',
      success,
    }),
  )
}

const _updateCondition = ({ studyId, conditionId, condition }) => dispatch => {
  return dispatch(
    request({
      url: `/control/studies/${studyId}/conditions/${conditionId}`,
      body: JSON.stringify(condition),
      method: 'PATCH',
      hasLoader: false,
      failMessage: 'Failed to update condition to database.',
      success: payload => Promise.resolve({ oldConditionId: conditionId, newConditionId: payload.conditions[0] }),
    }),
  )
}
const _deleteCondition = (studyId, conditionId) => dispatch => {
  return dispatch(
    request({
      url: `/control/studies/${studyId}/conditions/${conditionId}`,
      method: 'DELETE',
      hasLoader: false,
      failMessage: 'Failed to delete condition from database.',
      success: () => Promise.resolve(),
    }),
  )
}

const _updateConditions = (
  studyId,
  _conditions,
  redirect,
  toDeleteArr,
  medNotyDisabled,
  isInstrumentTrigger,
) => dispatch => {
  let updateRequests
  if (medNotyDisabled) {
    // If med adherence notifications are turned off, we delete each noty condition
    updateRequests = _conditions.conditions.map(condition => {
      const { condition_id } = condition
      if (condition_id) return dispatch(_deleteCondition(studyId, condition_id))
    })
  } else {
    updateRequests = _conditions.conditions.map(condition => {
      const { condition_id } = condition
      const conditionBody = { condition }
      if (condition_id) {
        return dispatch(_updateCondition({ studyId, conditionId: condition_id, condition: conditionBody }))
      }
      return dispatch(_createConditions(studyId, { conditions: [condition] }, redirect, true, isInstrumentTrigger))
    })
    if (toDeleteArr.length > 0) {
      toDeleteArr.forEach(conditionId => {
        updateRequests.push(dispatch(_deleteCondition(studyId, conditionId)))
      })
    }
  }
  dispatch(loadingActions.startLoader(true))
  return Promise.all(updateRequests).then(payload => {
    dispatch(loadingActions.stopLoader(true))
    dispatch(notyActions.showSuccess({ text: generateNotyMessage('Conditions updated successfully') }))
    if (isInstrumentTrigger) return Promise.resolve(payload)
    browserHistory.push(redirect)
  })
}

export const saveConditions = ({
  studyId,
  conditions,
  redirect,
  toDeleteArr = [],
  medNotyDisabled,
  isInstrumentTrigger,
}) => dispatch => {
  const isEdit = conditions.conditions.some(condition => !!condition.condition_id) || toDeleteArr.length > 0
  if (isEdit) {
    return dispatch(_updateConditions(studyId, conditions, redirect, toDeleteArr, medNotyDisabled, isInstrumentTrigger))
  }
  return dispatch(_createConditions(studyId, conditions, redirect, false, isInstrumentTrigger))
}

//
// Utils
//

const _getDefaultMedicationAdherenceNotifications = () => {
  const defaultTimeStampMoment = moment.utc().set({ h: 9, m: 0, second: 0, millisecond: 0 })
  return {
    conditions: [
      {
        triggers: [
          {
            resource_type: CONDITION_RESOURCE_TYPE.medicationAdherence,
            resource_function_name: CONDITION_RESOURCE_FUNCTION_TYPE.getMedicationCount,
            resource_function_params: {
              medication_count: 0,
              window: 1440, // Lookback timeframe in minutes
              logical_operator: '==',
            },
          },
        ],
        action: {
          type: CONDITION_ACTION_TYPE.sendPushNotification,
          action_params: {
            body: '',
            title: 'Linkt Reminder',
          },
        },
        schedule: {
          start_date: defaultTimeStampMoment.toISOString(),
          recurrence_rule: {
            frequency: 'daily',
            interval: 1,
            by_hour: [9],
            by_minute: 0,
          },
        },
      },
      {
        triggers: [
          {
            resource_type: CONDITION_RESOURCE_TYPE.medicationAdherence,
            resource_function_name: CONDITION_RESOURCE_FUNCTION_TYPE.getMedicationCount,
            resource_function_params: {
              medication_count: 1,
              window: 1440,
              logical_operator: '==',
            },
          },
        ],
        action: {
          type: CONDITION_ACTION_TYPE.sendPushNotification,
          action_params: {
            body: '',
            title: 'Linkt Reminder',
          },
        },
        schedule: {
          start_date: defaultTimeStampMoment.toISOString(),
          recurrence_rule: {
            frequency: 'daily',
            interval: 1,
            by_hour: [9],
            by_minute: 0,
          },
        },
      },
      {
        triggers: [
          {
            resource_type: CONDITION_RESOURCE_TYPE.medicationAdherence,
            resource_function_name: CONDITION_RESOURCE_FUNCTION_TYPE.getMedicationCount,
            resource_function_params: {
              medication_count: 2,
              window: 1440,
              logical_operator: '>=',
            },
          },
        ],
        action: {
          type: CONDITION_ACTION_TYPE.sendPushNotification,
          action_params: {
            body: '',
            title: 'Linkt Reminder',
          },
        },
        schedule: {
          start_date: defaultTimeStampMoment.toISOString(),
          recurrence_rule: {
            frequency: 'daily',
            interval: 1,
            by_hour: [9],
            by_minute: 0,
          },
        },
      },
    ],
  }
}

const _filterForMedNotificationConditions = conditionsPayload => {
  const { conditions } = conditionsPayload
  const _medNofifications = conditions.filter(condition => {
    const resourceFuncName = condition.triggers[0].resource_function_name
    const resourceType = condition.triggers[0].resource_type
    return (
      resourceType === CONDITION_RESOURCE_TYPE.medicationAdherence &&
      resourceFuncName === CONDITION_RESOURCE_FUNCTION_TYPE.getMedicationCount
    )
  })
  return { conditions: _medNofifications }
}

const changeScheduleTime = (json, timeString) => {
  const [hour, minute] = timeString.split(':')
  const resultArr = json.conditions.map(condition => {
    const newCondition = { ...condition }
    const resHour = Number(hour)
    const resMin = Number(minute)
    newCondition.schedule.recurrence_rule.by_hour = [resHour]
    newCondition.schedule.recurrence_rule.by_minute = resMin
    newCondition.schedule.start_date = moment
      .utc(newCondition.schedule.start_date)
      .set({ h: resHour, m: resMin, second: 0, millisecond: 0 })
    return newCondition
  })
  const resultJson = { ...json }
  resultJson.conditions = resultArr
  return resultJson
}

export const changeTrigger = (json, { key, value, key2, value2 }, index, isRootTriggerKey) => {
  const resultJson = { ...json }
  if (isRootTriggerKey && (index || index === 0)) {
    const condition = resultJson.conditions[index]
    condition.triggers[0][key] = value
  } else if (index || index === 0) {
    const condition = resultJson.conditions[index]
    condition.triggers[0].resource_function_params[key] = value
    if (key2 && value2) condition.triggers[0].resource_function_params[key2] = value2
  } else {
    const resultArr = json.conditions.map(condition => {
      const newCondition = { ...condition }
      newCondition.triggers[0].resource_function_params[key] = value
      if (key2 && value2) {
        newCondition.triggers[0].resource_function_params[key2] = value2
      }
      return newCondition
    })
    resultJson.conditions = resultArr
  }
  return resultJson
}

export const changeAction = (json, { key, value, key2, value2, score }, index) => {
  const resultJson = { ...json }
  const condition = resultJson.conditions[index]
  if (key === 'type') {
    condition.action.type = value
    if (key2 && value2) {
      condition.action.action_params[key2] = value2
      condition.schedule = changeSchedule(true)
      condition.triggers[0].resource_function_name = 'get_instrument_score_by_name'
      condition.triggers[0].resource_function_params.role_ids = [8]
      condition.triggers[0].resource_function_params.score_id = score.key
      condition.triggers[0].resource_function_params.score_name = score.text
    } else {
      condition.action.action_params = {}
      condition.schedule = changeSchedule(false)
      condition.triggers[0].resource_function_name = 'get_instrument_scores'
      condition.triggers[0].resource_function_params = deleteObjectKey(condition.triggers[0].resource_function_params, [
        'score_id',
        'score_name',
        'role_ids',
      ])
    }
  } else {
    condition.action.action_params[key] = value
    if (key2 && value2) condition.action.action_params[key2] = value2
  }
  return resultJson
}

const changeSchedule = isEmail =>
  isEmail
    ? { is_manual: true }
    : {
        start_date: moment.utc().toISOString(),
        recurrence_rule: {
          frequency: 'minutely',
          interval: 2,
        },
      }

const _getDefaultConditionItem = condition => {
  const { schedule, triggers } = condition
  const { resource_function_params } = triggers[0]
  const { medication_count, window } = resource_function_params
  const medCountAtMax = medication_count === 2
  const newMedCount = medCountAtMax ? medication_count : medication_count + 1
  return {
    triggers: [
      {
        resource_type: CONDITION_RESOURCE_TYPE.medicationAdherence,
        resource_function_name: CONDITION_RESOURCE_FUNCTION_TYPE.getMedicationCount,
        resource_function_params: {
          medication_count: newMedCount,
          window,
          logical_operator: newMedCount === 2 ? '>=' : '==',
        },
      },
    ],
    action: {
      type: CONDITION_ACTION_TYPE.sendPushNotification,
      action_params: {
        body: '',
        title: 'Linkt Reminder',
      },
    },
    schedule,
  }
}

const medicationAdherence = (state = _getDefaultMedicationAdherenceNotifications(), action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_MED_ADH_NOTIFICIATIONS:
      if (!action.medNotifications) return newState
      return { ...action.medNotifications, enabled: action.enabled }
    case UPDATE_MED_ADH_NOTIFICATION_SCHEDULE_TIME:
      return changeScheduleTime(newState, action.timeString)
    case UPDATE_MED_ADH_NOTIFICATION_TRIGGER:
      return changeTrigger(
        newState,
        { key: action.key, value: action.value, key2: action.key2, value2: action.value2 },
        action.index,
      )
    case UPDATE_MED_ADH_NOTIFICATION_ACTION:
      return changeAction(
        newState,
        { key: action.key, value: action.value, key2: action.key2, value2: action.value2 },
        action.index,
      )
    case ADD_NOTIFICATION_CONDITION: {
      const { conditions } = newState
      const condition = conditions[conditions.length - 1]
      const newCondition = _getDefaultConditionItem(condition)
      newState.conditions.push(newCondition)
      return newState
    }
    case DELETE_NOTIFICATION_CONDITION: {
      const { conditions } = newState
      const newConditionsArr = [...conditions]
      newConditionsArr.splice(action.index, 1)
      newState.conditions = newConditionsArr
      return newState
    }
    case CLEAR_MED_ADH_NOTIFICATIONS:
      return {}
    default:
      return newState
  }
}

export const notificationActions = {
  addMedNotificationConditionItem,
  clearMedicationNotifications,
  deleteMedNotificationCondition,
  fetchConditions,
  fetchInstrumentConditions,
  saveConditions,
  updateMedNotificationAction,
  updateMedNotificationScheduleTime,
  updateMedNotificationTrigger,
}

export default combineReducers({ medicationAdherence })
