import { connect } from 'react-redux'
import { authActions } from 'store/user'
import { SITE_ROLE_IDS } from 'utils/constants'
import { fetchSisenseUrl } from './modules/SideBarRequests'
import SideBar from './SideBar'

const navText = {
  studies: 'All Studies',
  audit: 'Audit Report',
}

const mapDispatchToProps = {
  onLogout: authActions.logout,
  onFetchSisenseUrl: fetchSisenseUrl,
}

const mapStateToProps = state => {
  const {
    user,
    userReducer,
    study,
    subroute,
    location,
    sending,
    studies,
    modal,
    navBar,
    loading,
    paymentsReducer,
    studyLock,
    sideBarReducer,
    visitSchedules = {},
    sites = {},
  } = state
  const studyID = study.currentStudy.id
  const selectedUser = userReducer ? userReducer.user.nickname : undefined
  const dropdownText =
    studies.length === 0 && navText[subroute] === 'studies'
      ? 'Create Study'
      : navText[subroute] || selectedUser || study.currentStudy.platform_name

  const { orphanedParticipants } = study
  const { sitesList = [] } = sites
  const { visitSchedulesPreviousList = [] } = visitSchedules
  const disabled = sending.sendingState || sending.sentState

  return {
    studyLock,
    loading: loading.general,
    sisenseLoading: loading.sisense,
    selectedUser,
    user,
    location,
    disabled,
    studies,
    modal,
    subroute,
    dropdownText,
    study: study.currentStudy,
    orphanedParticipants,
    showNavLinks: subroute !== 'studies' && subroute !== 'me' && subroute !== 'createStudy',
    navProps: navBar,
    hasPayments: ['cycles', 'reward', 'reward_goal'].some(
      key => key in study.currentStudy && study.currentStudy[key] !== null,
    ),
    role: user.permissions && user.permissions[studyID] ? user.permissions[studyID].name : '',
    roleId: user.permissions?.[studyID]?.role_id,
    showPendingPaymentsNotification: paymentsReducer.pendingPaymentsNotification,
    loadingStudy: loading.studyConfig,
    sideBar: sideBarReducer,
    passedVisitsCount: visitSchedulesPreviousList?.filter(
      item => item.filter(rowData => rowData.key === 'status').map(obj => obj.value)?.[0] === 'confirm-occurence',
    )?.length,
    isPassedVisitsCountVisible: SITE_ROLE_IDS.includes(user?.permissions?.[studyID]?.role_id),
    sitesExist: !!sitesList?.length,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
