import React from 'react'
import { Dropdown, Button } from 'components/UIElements'
import PropTypes from 'prop-types'
import DiaryLogicCondition from './DiaryLogicCondition'
import DiaryLogicGroup from './DiaryLogicGroup'

const renderLogicGroups = (logic, props, path = []) => {
  if (!logic) return null
  const groups = []
  logic.terms.forEach((term, idx) => {
    const grouped = term.comparator === 'boolean_combine'
    let operatorDropdown = null
    if (idx < logic.terms.length - 1) {
      operatorDropdown = (
        <OperatorDropdown
          disabled={idx !== 0}
          key={`comparator_${idx}`}
          groupPath={path}
          operator={logic.operator}
          {...props}
        />
      )
    }
    if (grouped) {
      groups.push(
        <DiaryLogicGroup
          key={`group_${idx}`}
          groupPath={path.slice().concat(idx)}
          operator={term.operator}
          group={renderLogicGroups(term, props, path.slice().concat(idx))}
          operatorDropdown={operatorDropdown}
          {...props}
        />,
      )
      groups.push(operatorDropdown)
    } else {
      groups.push(
        <DiaryLogicCondition
          key={`condition_${path.slice().concat(idx)}`}
          conditionPath={path.slice().concat(idx)}
          term={term}
          questionRequired={term.question_id ? props.questions[term.question_id].attributes.required : false}
          operatorDropdown={operatorDropdown}
          {...props}
        />,
      )
      groups.push(operatorDropdown)
    }
  })
  return groups
}

const LogicPanel = props => {
  const { logic, questionOptions, deleteAllLogic, itemId, addLogicCondition, addLogicGroup } = props
  const logicGroups = logic ? renderLogicGroups(logic, props) : null
  if (questionOptions.length > 0) {
    return (
      <div>
        <h5 className='panel-header label-small med-font'>Logic</h5>
        <div className='logic panel'>
          <div className='header section'>
            <p> Only show this question based on answers to previous questions or other logic conditions </p>
          </div>
          <div className='main section'>
            {logicGroups && <Button link onClick={() => deleteAllLogic(itemId)} content='Remove All Logic' />}
            {logicGroups}
            <div className='add-logic-operator'>
              {(!logicGroups || logicGroups[0].key.includes('condition')) && (
                <Button
                  link
                  id='add-logic-condition'
                  icon='fas fa-plus'
                  onClick={() => addLogicCondition(itemId)}
                  content='Add Logic Condition'
                />
              )}
              {logicGroups && logicGroups.length > 0 && (
                <Button link icon='fas fa-plus' onClick={() => addLogicGroup(itemId)} content='Add Logic Group' />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return null
}

const OperatorDropdown = props => {
  const { disabled, operator, itemId, groupPath, changeLogicOperator } = props
  const options = [
    { key: 'and', text: 'and', value: 'and' },
    { key: 'or', text: 'or', value: 'or' },
  ]
  return (
    <div className='flexed center-justified'>
      {!disabled ? (
        <Dropdown
          className='operator-dropdown'
          selected={operator}
          onSelect={item => changeLogicOperator(itemId, groupPath, item.value)}
          options={options}
        />
      ) : (
        <div>{operator}</div>
      )}
    </div>
  )
}

LogicPanel.propTypes = {
  logic: PropTypes.object,
  itemId: PropTypes.string,
  addLogicCondition: PropTypes.func,
  addLogicGroup: PropTypes.func,
  deleteAllLogic: PropTypes.func,
}

OperatorDropdown.propTypes = LogicPanel.propTypes

export default LogicPanel
