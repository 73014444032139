import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'components/UIElements'
import { USER_SCOPES, ROLE_PERMISSIONS, USER_ROLE_ID_MAP } from 'utils/constants'

const PERMISSIONS_MAP = {
  participants: [
    {
      name: 'Write and interact',
      value: [
        { code: 'wxp', keys: [0] },
        { code: 'wp', keys: [0] },
        { code: 'xp', keys: [0, 3, 4] },
        { code: 'rpe', keys: [0, 1], onlyFor: [USER_ROLE_ID_MAP.supportAgent] },
      ],
    },
  ],
  data: [
    {
      name: 'Read',
      value: [{ code: 'rd', keys: [0] }],
    },
  ],
  instruments: [
    {
      name: 'Read',
      value: [
        { code: 'ra', keys: [0] },
        { code: 'rpc', keys: [0], onlyFor: [USER_ROLE_ID_MAP.supportAgent] },
        { code: 'roa', keys: [0], onlyFor: [USER_ROLE_ID_MAP.supportAgent] },
      ],
    },
  ],
  Consent: [
    {
      name: 'Write and deploy',
      value: [{ code: 'xc' }],
    },
    {
      name: 'Route and Sign',
      value: [{ code: 'roa', keys: [1], onlyFor: [USER_ROLE_ID_MAP.impartialWitness] }],
    },
  ],
  visits: [
    {
      name: 'Write',
      value: [
        { code: 'wpv', keys: [0] },
        { code: 'wppv', keys: [0] },
      ],
    },
    {
      name: 'Read',
      value: [
        { code: 'rpv', keys: [0] },
        { code: 'rppv', keys: [0] },
        { code: 'rppv-no-pii', keys: [0] },
      ],
    },
  ],
  users: [
    {
      name: 'Write',
      value: [{ code: 'wu', keys: [0] }],
    },
  ],
}

const InsightsRolePermissions = props => {
  const { checkboxList, studyLock } = props
  const [target, setTaeget] = useState(checkboxList[0].key)
  const rolePermissions = ROLE_PERMISSIONS[target]
  const map = PERMISSIONS_MAP

  const renderRow = rowType => {
    const row = map[rowType]
    const permissionsMap = Object.keys(JSON.parse(JSON.stringify(row))).reduce((acc, key) => {
      const keys = row[key].value.filter(({ code, onlyFor }) => {
        if (onlyFor && onlyFor.length && !onlyFor.includes(target)) return false
        return rolePermissions.allowed.includes(code)
      })
      if (keys.length)
        acc.push({
          name: row[key].name,
          value: keys,
        })
      return acc
    }, [])

    if (!permissionsMap.length) return null

    return (
      <tr key={`${rowType}`}>
        <td className='permissions-description'>
          {permissionsMap.map(({ name, value }) => (
            <div key={name}>
              <p className='permissions-type'>
                <b>{rowType.toUpperCase()}</b>
              </p>
              <p className='permissions-text'>
                <b>{name}</b>
              </p>
              <ul>
                {value.map(({ code, keys }, index) => {
                  if (keys?.length) {
                    return keys.map(number => {
                      return USER_SCOPES[code]?.text && USER_SCOPES[code]?.text[number] ? (
                        <li key={number} className='permissions-text'>
                          {USER_SCOPES[code]?.text[number]}
                        </li>
                      ) : null
                    })
                  }
                  return (
                    <li key={code || index} className='permissions-text'>
                      {code ? USER_SCOPES[code]?.note : null}
                    </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </td>
      </tr>
    )
  }

  return (
    <div className='permissions-table'>
      <h5 className='permissions-title'>User Role and Permissions</h5>
      <p className='label-small dropdown-label'>user role</p>
      <Dropdown
        selected={target}
        options={checkboxList}
        disabled={studyLock}
        value={target}
        onSelect={item => {
          setTaeget(item.key)
        }}
      />
      <p className='label-small'>PERMISSIONS</p>
      <p className='permissions-note'>(Limited to this Study)</p>
      <table className='permission-list'>
        <tbody>{Object.keys(PERMISSIONS_MAP).map(key => renderRow(key))}</tbody>
      </table>
    </div>
  )
}

InsightsRolePermissions.propTypes = {
  checkboxList: PropTypes.arrayOf(PropTypes.object),
  studyLock: PropTypes.bool,
}

export default InsightsRolePermissions
