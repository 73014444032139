import request from 'utils/request'

/**
 * Actions
 */
const FETCH_LINKT_URL = 'FETCH_LINKT_URL'

/**
 * Action Creators
 */
const _fetchLinkUrl = state => dispatch => {
  dispatch({
    type: FETCH_LINKT_URL,
    payload: state,
  })
}

const openTab = url => window.open(url)
export const fetchSisenseUrl = ({ dashboardId = '', studyID }) => dispatch => {
  dispatch(_fetchLinkUrl(true))
  return dispatch(
    request({
      url: `/control/studies/${studyID}/insights/redirect${dashboardId ? `/dashboards/${dashboardId}` : ''}`,
      errorMessage: 'error fetching sisense url',
      success: textResponse => openTab(textResponse),
      hasLoader: true,
      loadingKey: 'sisense',
      resType: 'text',
    }),
  ).then(() => dispatch(_fetchLinkUrl(false)))
}

/**
 * Reducers
 */
const sideBar = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LINKT_URL:
      return { fetchingSisenseUrl: action.payload }
    default:
      return state
  }
}

/**
 * Function
 */
export default sideBar
