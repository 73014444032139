import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import { CHINA_DEPLOY } from 'utils/constants'
import { Button, Checkbox, Input, Container, Popup, ParticipantSupportTooltip } from 'components/UIElements'
import PropTypes from 'prop-types'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { buttonContentOnSendState } from 'utils/helpers'
import EnforcedSiteLanguagesConfig from './EnforcedSiteLanguagesConfig'
import SiteConsentConfig from './SiteConsent/SiteConsentConfig'
import { getDefaultDigitalSignatures } from '../modules/Sites'

const SITE_NAME_ERROR = 'These special characters are not allowed: _@~`!#$%^&*+=[];|":?<>{}\\'
const SITE_PHONE_ERROR = 'Please enter a valid phone'

// TODO: move to shared state
const INITIAL_SITE_STATE = {
  siteName: '',
  country: '',
  region: '',
  description: '',
  isVirtual: false,
  config: {
    help_and_support: {
      site_phone: '',
      support_phone: '',
      site_email: '',
    },
  },
}

const CreateSite = ({
  createSites,
  editMode,
  hasConsent,
  isEmailless,
  isRootOrDchAdmin,
  params,
  requestSending,
  requestSent,
  sendDisabled,
  setNavBarProps,
  sites,
  sitesInfo,
  study,
  studyID,
  studyLock,
  toggleSiteLanguage,
  updateSite,
  updateSiteConfig,
}) => {
  const { enforced_language } = study.config || {}
  const [site, setSite] = useState(INITIAL_SITE_STATE)
  const [siteNameError, setSiteNameError] = useState('')
  const [sitePhoneError, setSitePhoneError] = useState('')
  const [supportPhoneError, setSupportPhoneError] = useState('')
  const { editSiteID } = params
  const { siteName, country, region, description, isVirtual, config: siteConfig = {} } = site
  const { help_and_support = {} } = siteConfig
  const { site_phone, support_phone, site_email } = help_and_support
  const { currentSite, sitesList, currentSiteHasErrors } = sites
  const { config = {} } = currentSite || {}

  const studyEnforcedLanguage = enforced_language && { ...enforced_language }

  const noSites = sitesList.length === 0

  useEffect(() => {
    if (editMode) {
      setSite({
        ...site,
        config: {
          ...config,
          ...site?.config,
          help_and_support: {
            ...INITIAL_SITE_STATE.config.help_and_support,
            ...site?.config?.help_and_support,
            ...currentSite?.config?.help_and_support,
          },
        },
      })
    }
  }, [editMode, currentSite])

  useEffect(() => {
    if (setNavBarProps) {
      setNavBarProps({
        back: `/studies/${studyID}/sites`,
        close: `/studies/${studyID}/sites`,
        backText: 'Sites',
        closeText: 'Close',
      })
    }

    const initConfig = {
      enforced_language: editMode ? config.enforced_language : studyEnforcedLanguage,
    }

    if (hasConsent) {
      if (!editMode) {
        initConfig.consent_2 = {
          digital_signatures: getDefaultDigitalSignatures(),
        }
      }
    }

    updateSiteConfig(initConfig)
    return () => {
      if (setNavBarProps) {
        setNavBarProps(null)
      }
    }
  }, [])

  useEffect(() => {
    if (editMode && !country && sitesList.length) {
      const editedSite = sitesList.filter(s => s[0].value === Number(editSiteID))[0]
      if (!editedSite) return
      setSite({
        siteName: editedSite[2].value,
        country: editedSite[3].value,
        region: editedSite[4].value,
        description: editedSite[2].description,
        isVirtual: !!editedSite[2].isVirtual,
      })
      updateSiteConfig({ ...editedSite[2].config })
    }
  }, [sitesList.length])

  const onChange = (key, value) => {
    setSite({
      ...site,
      [key]: value,
    })
  }

  // If the country is changed, we need to reset the region
  useEffect(() => {
    if (!editMode) onChange('region', '')
  }, [country, editMode])

  const onSupportConfigChange = (key, value) => {
    setSite({
      ...site,
      config: {
        ...site.config,
        help_and_support: {
          ...site.config.help_and_support,
          [key]: value,
        },
      },
    })
  }

  const toggleVirtual = () => {
    setSite({
      ...site,
      country: !site.isVirtual ? '' : site.country,
      region: !site.isVirtual ? '' : site.region,
      isVirtual: !site.isVirtual,
    })
  }

  const onButtonPress = (onSubmit = false) => {
    if (
      (onCheckSitePhoneError(true) && onCheckSupportPhoneError(true)) ||
      onCheckSitePhoneError(true) ||
      onCheckSupportPhoneError(true)
    ) {
      return
    }
    if (editMode) {
      const updatedSite = {
        label: siteName,
        description,
        config: {
          help_and_support: {
            ...siteConfig.help_and_support,
          },
        },
      }
      const link = `/studies/${studyID}/sites`
      if (onSubmit) {
        updateSite(studyID, editSiteID, updatedSite, link)
      } else {
        browserHistory.push(link)
      }
    } else {
      const link = `/studies/${studyID}/sites`
      // // only un-render add site component if site creation is successful
      if (onSubmit) {
        createSites({
          studyID,
          site: {
            ...site,
            config: {
              ...siteConfig,
              ...config,
            },
          },
          sitesInfo,
          link,
          skipContactValidation: !isEmailless,
        })
      } else {
        browserHistory.push(link)
      }
    }
  }

  const onCheckSiteNameError = () => {
    const hasSpecialChar = /[_@~`!#$%^&*+=[\];|\\":?<>{}]/g.test(siteName)
    if (hasSpecialChar) setSiteNameError(SITE_NAME_ERROR)
    else setSiteNameError('')
  }

  const onCheckSitePhoneError = (isSubmit = false) => {
    if (!isEmailless) {
      setSitePhoneError('')
      return false
    }

    if (isSubmit && support_phone) {
      return false
    }

    if (!site_phone && !isSubmit) {
      setSitePhoneError('')
      return false
    }

    if (!site_phone) {
      setSitePhoneError(SITE_PHONE_ERROR)
      return true
    }
    setSitePhoneError('')
    return false
  }

  const onCheckSupportPhoneError = (isSubmit = false) => {
    if (!isEmailless) {
      setSupportPhoneError('')
      return false
    }
    if (isSubmit && site_phone) {
      return false
    }

    if (!support_phone && !isSubmit) {
      setSupportPhoneError('')
      return false
    }

    if (!support_phone) {
      setSupportPhoneError(SITE_PHONE_ERROR)
      return true
    }
    setSupportPhoneError('')
    return false
  }

  const checkRequiredFieldsFilled = () => country && region && siteName

  const checkHasLanguageError = () =>
    currentSiteHasErrors && config.enforced_language && config.enforced_language.default_language === ''

  const checkHasInvalidSiteName = () => !!siteNameError

  const checkHasConsentRequirements = () => {
    const { consent_2 } = config
    if (!consent_2) return true

    const { paper_only } = consent_2
    if (paper_only) return true

    const { read_only } = consent_2
    if (read_only) return true

    const { digital_signatures } = consent_2
    return Object.values(digital_signatures).some(toggled => toggled)
  }

  const addThisSite = {
    content: 'Create Site',
    sendingContent: 'Adding',
    sentContent: '...Added!',
  }
  const submitChanges = {
    content: 'Confirm Edits',
    sendingContent: 'Submitting',
    sentContent: '...Submitted!',
  }

  return (
    <div className='create-site-page'>
      <h2>{editMode ? 'Edit Site' : 'Create New Site'}</h2>
      <Container className={`site-details ${sendDisabled ? 'send-state' : ''}`}>
        <h4>
          <strong>Site Details</strong>
        </h4>
        <div className='site-form'>
          <Input
            charLimit={120}
            className='site-name pos rel'
            disabled={sendDisabled || studyLock}
            errorText={siteNameError}
            hasError={!!siteNameError}
            label='SITE NAME'
            onBlur={onCheckSiteNameError}
            onChange={val => {
              if (siteNameError) setSiteNameError('')
              onChange('siteName', val)
            }}
            id='site-name-input'
            placeholder='Enter a site name'
            value={siteName}
          />
          {!CHINA_DEPLOY && (
            <>
              <Checkbox
                checked={isVirtual}
                disabled={editMode || sendDisabled || studyLock}
                label='This is a virtual trial'
                onClick={() => {
                  toggleVirtual()
                }}
              />
            </>
          )}
          <Input
            id={`${isVirtual ? 'description' : 'address'}-input`}
            className='description'
            disabled={sendDisabled || studyLock}
            label={isVirtual ? 'DESCRIPTION' : 'ADDRESS'}
            placeholder={`Enter ${isVirtual ? 'description' : 'address'} (optional)`}
            defaultOptionLabel='Please Select'
            onChange={val => onChange('description', val)}
            value={description}
          />
          {!isVirtual && (
            <div className='flexed dropdowns-row'>
              <div className='dropdown-label'>
                <p>COUNTRY</p>
                <CountryDropdown
                  defaultOptionLabel={editMode ? country : 'Please Select'}
                  disabled={editMode || isVirtual || sendDisabled || studyLock}
                  classes='dropdown'
                  id='country-dropdown'
                  value={country}
                  onChange={val => onChange('country', val)}
                />
              </div>
              <div className='dropdown-label'>
                <p>REGION / STATE</p>
                <RegionDropdown
                  classes='dropdown'
                  country={country}
                  id='region-dropdown'
                  defaultOptionLabel={editMode ? region : 'Please Select'}
                  disabled={editMode || isVirtual || country === '' || sendDisabled || studyLock}
                  onChange={val => onChange('region', val)}
                  value={region}
                />
              </div>
            </div>
          )}
          {(isRootOrDchAdmin || editMode) && studyEnforcedLanguage && (
            <>
              <p className='label-small'>Languages</p>
              <EnforcedSiteLanguagesConfig
                config={currentSite.config.enforced_language ? currentSite.config : study.config}
                locales={studyEnforcedLanguage.languages}
                resetSiteConfig={() => updateSiteConfig({ ...config, enforced_language: studyEnforcedLanguage })}
                toggleSiteLanguage={toggleSiteLanguage}
                updateSiteConfig={updateSiteConfig}
                hasError={checkHasLanguageError()}
                disabled={editMode || sendDisabled || studyLock}
              />
            </>
          )}
          {hasConsent && (
            <SiteConsentConfig
              disabled={editMode || sendDisabled || studyLock}
              editMode={editMode}
              updateSiteConfig={updateSiteConfig}
              config={config}
            />
          )}
        </div>
      </Container>

      <Container className={`site-details participant-support ${sendDisabled ? 'send-state' : ''}`}>
        <div className='heading-wrapper'>
          <h4>
            <strong>Participant Support Contact</strong>
          </h4>
          <Popup
            className='box-popup'
            align='left'
            position='bottom'
            hover
            dark
            trigger={<i className='fas fa-info-circle' />}>
            <ParticipantSupportTooltip />
          </Popup>
        </div>
        <h6>
          Please add contact information for at least one of the fields below. Participants should be able to connect
          with this local support when assistance with logging in is needed.
        </h6>
        {sitePhoneError && supportPhoneError ? (
          <h6 className='site-details-error'>
            Please note: At least one contact number should be available for a site.
          </h6>
        ) : null}
        <div className='site-form'>
          <Input
            id='primary-site-contact'
            className='site-contact'
            disabled={sendDisabled || studyLock}
            label='Primary site contact Phone number'
            placeholder='Ex: 123-456-7890'
            defaultOptionLabel='Please Select'
            onChange={val => {
              if (sitePhoneError) setSitePhoneError('')
              if (supportPhoneError) setSupportPhoneError('')
              onSupportConfigChange('site_phone', val)
            }}
            value={site_phone}
            onBlur={() => onCheckSitePhoneError()}
            errorText={sitePhoneError}
            hasError={!!sitePhoneError}
          />
          <Input
            id='primary-support-contact'
            className='site-contact'
            disabled={sendDisabled || studyLock}
            label='primary support Contact Phone number'
            placeholder='Ex: 123-456-7890'
            defaultOptionLabel='Please Select'
            onChange={val => {
              if (sitePhoneError) setSitePhoneError('')
              if (supportPhoneError) setSupportPhoneError('')
              onSupportConfigChange('support_phone', val)
            }}
            value={support_phone}
            onBlur={() => onCheckSupportPhoneError()}
            errorText={supportPhoneError}
            hasError={!!supportPhoneError}
          />
          <Input
            id='primary-support-email'
            className='support-email'
            disabled={sendDisabled || studyLock}
            label='Primary support email (optional)'
            placeholder='info@email.com'
            defaultOptionLabel='Please Select'
            onChange={val => onSupportConfigChange('site_email', val)}
            value={site_email}
          />
        </div>
      </Container>
      <div className='button-list'>
        <Button
          grey
          id='cancel'
          disabled={noSites || sendDisabled || studyLock}
          content='Cancel'
          onClick={() => onButtonPress(false)}
        />
        <Button
          id='add-this-site'
          content={
            editMode
              ? buttonContentOnSendState(submitChanges, requestSending, requestSent)
              : buttonContentOnSendState(addThisSite, requestSending, requestSent)
          }
          loading={requestSending}
          disabled={
            checkHasInvalidSiteName() ||
            (isVirtual ? !siteName : !checkRequiredFieldsFilled()) ||
            !checkHasConsentRequirements() ||
            sendDisabled ||
            studyLock
          }
          onClick={() => onButtonPress(true)}
        />
      </div>
    </div>
  )
}

CreateSite.propTypes = {
  createSites: PropTypes.func,
  editMode: PropTypes.bool,
  hasConsent: PropTypes.bool,
  isEmailless: PropTypes.bool,
  isRootOrDchAdmin: PropTypes.bool,
  params: PropTypes.shape({
    siteID: PropTypes.string,
    editSiteID: PropTypes.string,
  }),
  requestSending: PropTypes.bool,
  requestSent: PropTypes.bool,
  sendDisabled: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  studyID: PropTypes.number,
  studyLock: PropTypes.bool,
  toggleSiteLanguage: PropTypes.func,
  updateSite: PropTypes.func,
  updateSiteConfig: PropTypes.func,
}

export default CreateSite
