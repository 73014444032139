import React from 'react'
import PropTypes from 'prop-types'
import '../../stylesheets/ItemDomains.scss'

const DomainLabels = ({ domains, instrumentDomains }) => {
  const domainsList = domains.map(domainId => {
    return (
      <div className='domain-label' key={`${domainId}-item-label`}>
        <span>{instrumentDomains[domainId].label}</span>
      </div>
    )
  })
  return <div className='flexed column start-aligned'>{domainsList}</div>
}

const ItemDomains = ({ domains, hasScoring, instrumentDomains, isActive }) => {
  const hasDomains = !!domains && !!domains.length > 0
  return hasScoring && hasDomains ? (
    <div className={`item-domains${isActive ? ' active' : ''}`}>
      {isActive && hasDomains && (
        <>
          <strong>DOMAINS</strong>
        </>
      )}
      {hasDomains && <DomainLabels domains={domains} instrumentDomains={instrumentDomains} />}
    </div>
  ) : (
    <div />
  )
}

DomainLabels.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.string),
  instrumentDomains: PropTypes.objectOf(PropTypes.object),
}

ItemDomains.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.string),
  hasScoring: PropTypes.bool,
  instrumentDomains: PropTypes.objectOf(PropTypes.object),
  isActive: PropTypes.bool,
}

export default ItemDomains
