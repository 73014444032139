import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import participantReducer, { actions as createParticipantActions } from '../CreateParticipant/modules/CreateParticipant'
import paymentsReducer from '../../../Payments/modules/Payments'
import consentReducer from '../../../Consent/routes/ConsentEditor/modules/Consent'
import userReducer, { actions as userActions } from '../../../Users/routes/CreateUser/modules/CreateUser'
import ParticipantView from '../CreateParticipant/containers/ParticipantContainer'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'
import { fetchCohorts } from '../ParticipantsPage/modules/Participants'
import { fetchStudies } from '../../../../../../StudiesPage/modules/Studies'

const { fetchUserSites } = userActions

// Sync route definition
export default store => ({
  path: ':participantId/ptpSite/:siteID',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducers(store, {
          participantReducer,
          paymentsReducer,
          consentReducer,
          sites: sitesReducer,
          userReducer,
        })
        const { studies, study } = store.getState()
        if (studies.length === 0) {
          store.dispatch(fetchStudies(false))
        }

        const { params } = nextState
        const { studyID, siteID, participantId } = params
        const hasTreasureChest = !!study.currentStudy.config?.super_gems
        const {
          study: { currentStudy },
          user,
        } = store.getState()
        setPageTitle(`Edit Participant | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(fetchUserSites({ studyId: studyID, userId: user.id }))
        store.dispatch(fetchSites(studyID)).then(() => {
          store
            .dispatch(createParticipantActions.initializeEditPage(studyID, siteID, participantId, hasTreasureChest))
            .then(() => {
              /*  Return getComponent   */
              cb(
                null,
                UserIsAuthenticated(
                  UserHasValidPassword(
                    LockRedirect(studyID, 'participants')(UserHasScope(['rp', 'mp'], ParticipantView, false, studyID)),
                  ),
                ),
              )
            })
        })
        store.dispatch(fetchCohorts(studyID, false, true))
      },
      '/participants/:participantId',
    )
  },
})
