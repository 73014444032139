import STRINGS from 'utils/strings'

const HEADER_NAMES = {
  create: {
    ['participant-visit']: STRINGS.visitScheduleCreateNewAdhoc,
    ['visit-templates']: STRINGS.visitScheduleCreateNewVisitSchedule,
    visits: STRINGS.visitScheduleCreateNewVisit,
  },
  edit: {
    ['participant-visit']: STRINGS.visitScheduleEditAdhoc,
    ['visit-templates']: STRINGS.visitScheduleEditVisitSchedule,
    visits: STRINGS.visitScheduleEditVisit,
  },
}

export const NAV_HEADER_NAMES = {
  ['participant-visit']: 'participant visit',
  ['visit-templates']: 'visit schedule',
  visits: 'visit',
}

export const getSelectedTab = path => {
  if (HEADER_NAMES.hasOwnProperty(path)) {
    return 'main'
  }
  return path
}
export const getHeaderName = (route, subroute) =>
  subroute && typeof HEADER_NAMES[route] === 'object' ? HEADER_NAMES[route][subroute] : HEADER_NAMES[route]
