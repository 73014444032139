import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/popup.scss'

class Popup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.open ?? false,
      trigger: {
        top: null,
        left: null,
        height: null,
        width: null,
      },
      menu: {
        top: null,
        left: null,
        height: null,
        width: null,
      },
    }
  }

  componentDidMount() {
    this.listener = e => {
      if (this.component && !this.component.contains(e.target)) {
        this.onTrigger(false)
      }
    }
    this.getAndSetDimensionAndPosition(this.component)
    document.addEventListener('click', this.listener)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listener)
  }

  onTrigger = (open = !this.state.open) => {
    const { disabled, onClose, onOpen } = this.props
    if (disabled) return
    if (onOpen && open) {
      onOpen()
    }
    if (onClose && !open) {
      onClose()
    }
    this.setState({ open }, () => this.getAndSetDimensionAndPosition(this.popup))
  }

  onClickContent = e => {
    if (!e.target.classList.contains('close-trigger')) {
      e.stopPropagation()
    }
  }

  getAndSetDimensionAndPosition = element => {
    const { overrideOverflow } = this.props
    let height
    let width
    let posTop
    let posLeft
    if (overrideOverflow && element) {
      posTop = element.offsetTop
      posLeft = element.offsetLeft
      width = element.offsetWidth
      height = element.offsetHeight
    }
    const key = element === this.component ? 'trigger' : 'menu'
    if (posTop && posLeft && width && height) {
      this.setState({
        [key]: {
          ...this.state[key],
          top: posTop,
          left: posLeft,
          width,
          height,
        },
      })
    }
  }

  generateStyle = () => {
    const { trigger, menu } = this.state
    const style = {}
    style.top = `${trigger.top - menu.height - 5}px`
    style.left = `${trigger.width + trigger.left - menu.width + 5}px`
    return style
  }

  render() {
    const {
      align,
      isButtonList = false,
      children,
      className = '',
      dark = false,
      disabled,
      hover,
      noPointer,
      open = this.state.open,
      overrideOverflow = false,
      position = 'bottom',
      trigger,
      useOnMousedown = false,
    } = this.props
    let popupClass = `popup ${position}${isButtonList ? ' popup-button-list' : ''}`
    let containerClass = `popup-container${className ? ` ${className}` : ''}`
    containerClass += open ? ' open' : ''
    containerClass += disabled ? ' disabled' : ''
    containerClass += ` ${position}`
    containerClass += noPointer ? ' no-pointer' : ''
    containerClass += overrideOverflow ? ' override' : ''
    popupClass += open ? '' : ' hidden'
    popupClass += noPointer ? ' no-pointer' : ''
    popupClass += `${align === 'left' ? ' align left-align' : ''}${align === 'right' ? ' align right-align' : ''}`
    popupClass += dark ? ' dark' : ''
    popupClass += overrideOverflow ? ' override' : ''

    return (
      <div
        ref={el => {
          this.component = el
        }}
        onMouseEnter={hover ? () => this.onTrigger(true) : null}
        onMouseLeave={hover ? () => this.onTrigger(false) : null}
        onClick={
          hover || useOnMousedown
            ? null
            : e => {
                e.stopPropagation()
                this.onTrigger()
              }
        }
        onMouseDown={
          useOnMousedown
            ? e => {
                e.preventDefault()
                this.onTrigger()
              }
            : null
        }
        className={containerClass}>
        {trigger}
        {!disabled && children ? (
          <div
            ref={el => {
              this.popup = el
            }}
            onClick={this.onClickContent}
            className={popupClass}
            style={overrideOverflow ? this.generateStyle() : {}}>
            {children}
          </div>
        ) : null}
      </div>
    )
  }
}

Popup.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  hover: PropTypes.bool,
  isButtonList: PropTypes.bool,
  noPointer: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  overrideOverflow: PropTypes.bool,
  position: PropTypes.string,
  trigger: PropTypes.node,
  useOnMousedown: PropTypes.bool,
}

export default Popup
