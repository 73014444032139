import React from 'react'
import { Button } from 'components/UIElements'
import PropTypes from 'prop-types'
import superGem from 'assets/super-gem.png'
import { closeIcon } from 'assets/assets'

const configBodyTextMap = {
  lowTier: {
    type: 'Low Tier',
    body: 'A low tier reward is the lowest value reward that you offer. This should be the most common reward.',
  },
  midTier: {
    type: 'Mid tier',
    body: 'A mid tier is more valuable than a lower tier reward. This should be offered every few weeks.',
  },
  highTier: {
    type: 'High tier',
    body: 'A high tier reward is the most valuable reward that you offer. This should only be offered 1-2 times in a study.',
  },
}

const TierExplanation = ({ closeTierInfo }) => {
  
  const superGemImgArr = (num) => {
    const imgArr = [];
    for (let i = 0; i < num; i++) {
      imgArr.push(<img key={`superGemImg${i+1}`} src={superGem} alt='super-gem'/>)
    }
    return imgArr
  }

  const tierBoxes = Object.keys(configBodyTextMap).map((tier, idx) => {
    const superGemNum = idx + 1
    return (
      <div key={`tierBox-${tier}`} className='tier-info'>
        <div className='tier-header flexed start-justified center-aligned'>
          <div className='tier-box flexed center-justified border-radius'>
            {superGemImgArr(superGemNum)}
          </div>
          <span>{configBodyTextMap[tier].type}</span>
        </div>
        <span>{configBodyTextMap[tier].body}</span>
      </div>
    )
  })

  return (
    <div className='gift-cards-config tier-explanation flexed space-between-justified'>
      <Button noStyling className='close-x' onClick={() => closeTierInfo(true)} content={closeIcon()} />
      {tierBoxes}
    </div>
  )
}

TierExplanation.propTypes = {
  closeTierInfo: PropTypes.func,
}
export default TierExplanation