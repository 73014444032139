import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Textarea } from 'components/UIElements'
import ImportExportChoices from './ImportExportChoices'
import UploadTranslationInput from '../../../../../InstrumentsPage/components/UploadTranslationInput'

const LongListSelectOneTextarea = props => {
  const {
    activeItemId,
    errors,
    downloadChoicesCSV,
    updateChoiceLabel,
    isActive,
    item,
    itemId,
    preview,
    survey,
    updateItem,
    updateLongListItem,
    toggleUndoItem,
    toggleWarningBoxOnItem
  } = props
  const [choices, setChoices] = useState(item.choices_text)
  const [toggleWarningBox, setToggleWarningBox] = useState(false)
  const choicesOrder = item?.choices_order
  const lineNumbersWithLogic = []
  const choiceNumbers = choices.split('\n').map((choice, idx) => {
    const choiceId = choicesOrder[idx]
    const choiceNumber = idx + 1
    let className = ''
    if (toggleWarningBox && item.subsequentLogic && item.subsequentLogic[choiceId]) {
      lineNumbersWithLogic.push(choiceNumber)
      className = 'logic-applied'
    }
    const key = choiceId || `${choice}-${idx}`
    return <div key={key} className={className}>{choiceNumber}.</div>
  })

  const handleOnChange = e => {
    setChoices(e)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (choices !== item.choices_text) updateLongListItem(survey, itemId, item, choices)
    }, 300)
    return () => clearTimeout(timeoutId)
  }, [choices])

  useEffect(() => {
    if (choices !== item.choices_text) setChoices(item.choices_text)
  }, [item.choices_text])

  useEffect(() => {
    if (item.undo) {
      setChoices(item.choices_text)
      toggleUndoItem(itemId, item, false)
    }
  })

  useEffect(() => {
    if (item.showWarning) {
      setToggleWarningBox(true)
      toggleWarningBoxOnItem(itemId, false)
    }
  })
  const isChoicesFilled = choices.split('\n').every(choice => choice.length !== 0)

  const onOpenUploadModal = () => {
    const { closeModal, openModal, uploadChoicesCSV } = props
    openModal({
      noButtons: true,
      closeOnBackgroundClick: true,
      children: (
        <UploadTranslationInput
          forLongList
          itemId={itemId}
          closeModal={closeModal}
          uploadTranslation={uploadChoicesCSV}
          subsequentLogic={item.subsequentLogic}
          survey={survey}
        />
      ),
    })
  }

  const stringifiedlineNumbersWithLogic = () => {
    let string = '#'
    if (lineNumbersWithLogic.length === 1) {
      string += lineNumbersWithLogic.join('')
    } 
    if (lineNumbersWithLogic.length === 2) {
      string += lineNumbersWithLogic.join(' and #')
    } 
    if (lineNumbersWithLogic.length > 2) {
      string += lineNumbersWithLogic.slice(0, -1).join(', #') + ' and #' + lineNumbersWithLogic.slice(-1).join('')
    }
    return string
  }

  return (
    <div className='long-list'>
      <div className='choices-container'>
        <div className='choice-numbers'>{choiceNumbers}</div>
        <Textarea
          disabled={preview}
          hasError={errors ? !!errors.blankChoicesErr && !isChoicesFilled : false}
          value={choices}
          placeholder='Type or Paste your choices'
          onChange={handleOnChange}
        />
        {!preview && (
          <div className='import-export-choices flexed column'>
            <ImportExportChoices downloadChoicesCSV={downloadChoicesCSV} item={item} itemId={itemId} />
            <ImportExportChoices className='extra-margin-top' isImport={true} onOpenUploadModal={onOpenUploadModal} />
          </div>
        )}
      </div>
      {item.choices_order.length < 10 && (
        <div className='warning-box'>
          <p>
            <i className='fas fa-exclamation-triangle' />
            Select One question type is recommended for questions with fewer than 10 answer choices
          </p>
        </div>
      )}
      {toggleWarningBox && lineNumbersWithLogic.length > 0 &&
        <div className='warning-box'>
          <p>
            <i className='fas fa-exclamation-triangle' />
            {`Please check the logic assigned to the choices ${stringifiedlineNumbersWithLogic()} before proceeding.`}
          </p>
        </div>
      }
      {errors && errors.blankChoicesErr && !isChoicesFilled && (
        <div>
          <p className='survey-error long-list'>{errors.blankChoicesErr}</p>
        </div>
      )}
    </div>
  )
}

export default LongListSelectOneTextarea

LongListSelectOneTextarea.propTypes = {
  errors: PropTypes.shape({
    blankChoicesErr: PropTypes.string,
  }),
  downloadChoicesCSV: PropTypes.func,
  isActive: PropTypes.bool,
  item: PropTypes.shape({
    choices_order: PropTypes.array,
    choices_text: PropTypes.string,
    subsequentLogic: PropTypes.object,
    undo: PropTypes.bool,
    showWarning: PropTypes.bool,
  }),
  itemId: PropTypes.string,
  preview: PropTypes.bool,
  updateLongListItem: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  uploadChoicesCSV: PropTypes.func,
}
