import { MINUTES_IN_DAY } from 'utils/constants'

export const REVIEW_TYPES = {
  all: 'all',
  partial: 'partial',
}

export const DEFAULT_SCREEN_REVIEW_VALUES = {
  review: {
    type: REVIEW_TYPES.all,
    value: MINUTES_IN_DAY,
  },
  edit: {
    value: 1,
  },
}
