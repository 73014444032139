import React, { Component } from 'react'
import { Container, SortableTable, Button, Loader } from 'components/UIElements'
import { MODAL_CLASSES_MAP, MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, BULK_DATA_TYPES } from 'utils/constants'
import handleComponentStateLoading from 'utils/handleComponentStateLoading'
import PropTypes from 'prop-types'
import '../stylesheets/participant.scss'
import STRINGS from '../../../../../../../../../utils/strings'

const KEY_NAME_MAP = {
  type: 'Type',
  fileName: 'File Name',
  category: 'Category',
  action: 'Action',
}

const FILE_ICON_MAP = {
  docx: 'far fa-file-word',
  doc: 'far fa-file-word',
  pdf: 'far fa-file-pdf',
  jpeg: 'fas fa-image',
  png: 'fas fa-image',
  jpg: 'fas fa-image',
  wav: 'fas fa-file-audio',
}

class FilesManagement extends Component {
  constructor(props) {
    super(props)
    this.headerOptions = Object.keys(KEY_NAME_MAP).map(key => {
      return { key, sortable: false, component: KEY_NAME_MAP[key] }
    })
    this.state = { refreshLink: false, fileLoading: false }
    this.renderFunctions = {
      type: this.renderTypeCell,
      action: this.renderActionCell,
    }
    this.count = 0
    this.refreshTimeout = null
  }

  componentDidMount() {
    this.onFetchFiles()
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout)
  }

  onFetchFiles = () => {
    const { fetchFiles, ptpId, ptpSiteID, studyID } = this.props
    if (ptpId) {
      handleComponentStateLoading.call(this, fetchFiles.bind(this, studyID, ptpSiteID, ptpId), 'fileLoading')
    }
    this.refreshTimeout = setTimeout(() => this.setState({ refreshLink: true }), 60000) // this 60sec is in regards to the s3 bucket download link being valid for 60sec after files fetch
  }

  onRefreshDownloadlink = () => {
    this.setState({ refreshLink: false })
    this.onFetchFiles()
  }

  renderActionCell = ({ value, className }) => {
    const { refreshLink } = this.state
    return (
      <td className={className} key={`download-file-${value}`}>
        <a href={value} className={`${refreshLink ? 'disabled' : ''}`}>
          <i className='fas fa-download' />
        </a>
      </td>
    )
  }

  renderTypeCell = cellProps => {
    const { value, className } = cellProps
    this.count++
    return (
      <td key={`value-${this.count}`} className={className}>
        <i className={`${FILE_ICON_MAP[value]}`} />
      </td>
    )
  }

  onOpenAudioModal = () => {
    const { downloadBulkData, studyID, openModal, ptpId } = this.props

    openModal({
      heading: MODAL_CONTENT_MAP.downloadAllAudioFiles,
      content: (
        <div className='audio-modal-content'>
          <div>{MODAL_CONTENT_MAP.downloadAllAudioFilesSubtitle}</div>
          <div className='caption'>{MODAL_CONTENT_MAP.downloadAllAudioFilesCaption}</div>
        </div>
      ),
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        downloadBulkData({ studyID, type: BULK_DATA_TYPES.audioFiles, ptpId })
      },
    })
  }

  render() {
    const { files, ptpName } = this.props
    const { refreshLink, fileLoading } = this.state
    return (
      <Container centered flex column>
        <div className='title'>Files</div>
        <div className='files-management'>
          <div className='flexed end-justified'>
            <Button
              content={STRINGS.downloadAllAudioFiles}
              iconBefore='fas fa-file-archive-o'
              onClick={this.onOpenAudioModal}
            />
          </div>
          <div className='flexed'>
            <h5>{`Files Uploaded by ${ptpName}`}</h5>
            {refreshLink && (
              <Button
                link
                content='Refresh Download Links'
                onClick={() => {
                  this.onRefreshDownloadlink()
                }}
              />
            )}
          </div>
          {fileLoading ? (
            <Loader inContainer size={25} />
          ) : (
            <SortableTable
              headerOptions={this.headerOptions}
              renderHeader={this.renderHeader}
              rowList={files || []}
              renderFunctions={this.renderFunctions}
              viewMoreWithTablePages={{ minRows: 10, maxRows: 10 }}
              emptyText={`${ptpName} does not have any uploaded files.`}
            />
          )}
        </div>
      </Container>
    )
  }
}

FilesManagement.propTypes = {
  fetchFiles: PropTypes.func,
  studyID: PropTypes.number,
  ptpId: PropTypes.number,
  ptpName: PropTypes.string,
  ptpSiteID: PropTypes.number,
  files: PropTypes.arrayOf(PropTypes.array),
}

export default FilesManagement
