import React from 'react'

class StatusCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = { errorOpen: false }
  }

  onToggleError = (errorOpen = !this.state.errorOpen) => {
    this.setState({ errorOpen })
  }

  render() {
    const { timestamp, details, value, hasError } = this.props
    let className = 'tag'
    if (hasError) className += ' error' //clickable'
    return (
      <td key='status-cell' className='status-cell'>
        <span /*onClick={value === 'Error' ? () => this.onToggleError() : null}*/ className={className}>{value}</span>
        {timestamp && <span className='timestamp'>{timestamp}</span>}
        {hasError && details && <span className='error'>{details.name}</span>}
        {hasError && this.state.errorOpen && (
          <div className='error-details'>
            <span>
              {details.content} If error persists, please contact{' '}
              <a href='mailto:help@datacubed.com'>help@datacubed.com</a>
            </span>
          </div>
        )}
      </td>
    )
  }
}

export default StatusCell
