import { connect } from 'react-redux'
import UploadSurveyView from '../components/UploadSurveyPage'
import { actions } from '../modules/UploadSurvey'
import { instrumentActions } from '../../../modules/Instrument'

const mapStateToProps = state => {
  return {
    uploadedSurvey: state.upload.uploadedSurvey,
    fileName: state.upload.fileName,
  }
}

const mapDispatchToProps = { ...actions, ...instrumentActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadSurveyView)
