import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'components/UIElements'
import { STUDY_CONFIG_MAP, REWARD_TYPE_MAP } from 'utils/constants'
import { changeRewardType } from '../utils/studyUtils'
import AdvancedPaymentOptions from './AdvancedPaymentOptions/AdvancedPaymentOptions'
// import BasicRewardConfig from './BasicRewardConfig'

const _getDefaultFixedCycles = () => [
  {
    target: 2,
    reward: 100,
    cycleDuration: 1,
    studyDuration: 1,
    durationType: 'days',
    rewardType: 'cash',
    currency: 'USD',
  },
]

const _getDefaultDynamicCycles = () => [
  {
    target: 2,
    reward: 100,
    cycleDuration: 1,
    durationType: 'days',
    rewardType: 'cash',
    currency: 'USD',
  },
  {
    target: 2,
    reward: 100,
    cycleDuration: 1,
    durationType: 'days',
    rewardType: 'cash',
    currency: 'USD',
  },
]

const defaultAdvancedPayoutConfig = {
  auto_approve: {
    status: false,
    payout_type: '',
  },
  payout_types: [],
  notification_type: 'email',
}

const updateAdvancedPayouts = advancedPayouts => {
  if (advancedPayouts) return advancedPayouts
  return defaultAdvancedPayoutConfig
}

const RewardConfig = props => {
  const { configObj, deleteConfigKey, updateRewards, updateConfig, disabled } = props
  const { cycles, advanced_payouts } = configObj
  const hasDynamicCycles = configObj.has_dynamic_cycles
  const { rewardType } = cycles[0]
  const isCash = rewardType === REWARD_TYPE_MAP.cash
  const superGemsExist = configObj[STUDY_CONFIG_MAP.superGems]

  const initializeCashPayment = () => {
    deleteConfigKey(STUDY_CONFIG_MAP.superGems)
    deleteConfigKey(STUDY_CONFIG_MAP.treasureChestStoreGiftCard)
    updateConfig({
      cycles: changeRewardType(cycles, REWARD_TYPE_MAP.cash),
      advanced_payouts: updateAdvancedPayouts(defaultAdvancedPayoutConfig),
    })
  }

  return (
    <div className='chapter-setting'>
      <h5>Chapter Setting</h5>
      <p>
        1. Organizing study instruments in small chunks, or &quot;Chapters&quot; keeps participants engaged and
        motivated. Participants can receive incentives at the end of each Chapter. Would you like Chapters to be fixed
        (same length)?
      </p>
      <Radio
        id='chapter-setting-radio-yes'
        disabled={disabled}
        selected={!hasDynamicCycles}
        onClick={() => {
          updateRewards(_getDefaultFixedCycles(), null, null)
          updateConfig({ has_dynamic_cycles: false, advanced_payouts: updateAdvancedPayouts(advanced_payouts) })
        }}
        content='Yes, intervals between each chapter are fixed and the same length'
      />
      <Radio
        id='chapter-setting-radio-no'
        disabled={disabled}
        selected={hasDynamicCycles}
        onClick={() => {
          updateRewards(_getDefaultDynamicCycles(), null, null)
          updateConfig({ has_dynamic_cycles: true, advanced_payouts: updateAdvancedPayouts(advanced_payouts) })
        }}
        content='No, every interval has different length'
      />
      <p>2. How would you like to reward your participants?</p>
      <Radio
        id='digital-gems-radio'
        disabled={disabled}
        selected={[REWARD_TYPE_MAP.gems, REWARD_TYPE_MAP.superGems].includes(rewardType)}
        onClick={() => {
          updateConfig({
            cycles: changeRewardType(cycles, superGemsExist ? REWARD_TYPE_MAP.superGems : REWARD_TYPE_MAP.gems),
          })
        }}
        content='Digital gems'
      />
      <Radio id='payment-radio' disabled={disabled} selected={isCash} onClick={initializeCashPayment} content='Payment' />
      {isCash && (
        <AdvancedPaymentOptions
          {...props}
          changeRewardType={changeRewardType}
          cycles={cycles}
          initializeCashPayment={initializeCashPayment}
          updateAdvancedPayouts={updateAdvancedPayouts}
        />
      )}
    </div>
  )
}

export const studyPropTypes = {
  configObj: PropTypes.object,
  cycles: PropTypes.object,
  cycleType: PropTypes.oneOf(['fixed', 'dynamic']),
  deleteConfigKey: PropTypes.func,
  disabled: PropTypes.bool,
  reward_duration: PropTypes.number,
  reward_goal: PropTypes.number,
  setCycleType: PropTypes.func,
  study: PropTypes.object,
  toggleCycleType: PropTypes.func,
  updateConfig: PropTypes.func,
  updateRewards: PropTypes.func,
}

RewardConfig.propTypes = studyPropTypes

export default RewardConfig
