import React, { useState } from 'react'
import { Container, Input, Button } from 'components/UIElements'
import PropTypes from 'prop-types'
import { PW_REQUIREMENTS } from 'utils/misc'
import { check } from 'assets/assets'
import '../../CreateUser/stylesheets/user.scss'

const ActivateUserPage = props => {
  const {
    changePasswordState,
    changePwErrors,
    setNewPassword,
    updateNewPassword,
    token,
    userToken,
    temporary_token,
    expiration_message,
  } = props

  const [isEightChar, setIsEightChar] = useState(false)
  const [hasUppercase, setHasUppercase] = useState(false)
  const [hasLowercase, setHasLowercase] = useState(false)
  const [hasNum, setHasNum] = useState(false)
  const [hasSpecialChar, setHasSpecialChar] = useState(false)
  const [hasSupportedSpecialChar, setHasSupportedSpecialChar] = useState(null)
  const [requestIsPending, setRequestIsPending] = useState(false)

  const validate = password => {
    const isEightCharRegex = /^.{8,}$/g
    const hasUppercaseRegex = /^(.*[A-Z].*)$/g
    const hasLowercaseRegex = /^(.*[a-z].*)$/g
    const hasNumberRegex = /^(.*\d.*)$/g
    const hasSpecialCharRegex = /^(.*[~!@#$%^&*?].*)$/g
    const hasSupportedSpecialCharRegex = /^(.*[^~!@#$%^&*?].*)$/g
    const getSpecialCharacters = password.replace(/[a-z, \d]/gi, '')
    const hasSupportedSpecialCharError = hasSupportedSpecialCharRegex.test(getSpecialCharacters)

    setIsEightChar(isEightCharRegex.test(password))
    setHasUppercase(hasUppercaseRegex.test(password))
    setHasLowercase(hasLowercaseRegex.test(password))
    setHasNum(hasNumberRegex.test(password))
    setHasSupportedSpecialChar(!hasSupportedSpecialCharError)
    setHasSpecialChar(hasSpecialCharRegex.test(password) && !hasSupportedSpecialCharError)
  }

  const getPWReq = reqKey => {
    switch (reqKey) {
      case 'isEightChar':
        return isEightChar
      case 'hasUppercase':
        return hasUppercase
      case 'hasLowercase':
        return hasLowercase
      case 'hasNum':
        return hasNum
      case 'hasSpecialChar':
        return hasSpecialChar
      default:
        return false
    }
  }

  const renderError = _loginError => {
    return <div className='error'>{_loginError}</div>
  }

  const renderPrompt = msg => <div className='prompt'>{msg}</div>

  const { pwSecurityError } = changePwErrors
  const hasSupportedSpecialCharError = typeof hasSupportedSpecialChar === 'boolean' && !hasSupportedSpecialChar

  return (
    <div className='create-user password page'>
      {!userToken && expiration_message && renderPrompt(expiration_message)}
      <Container>
        {pwSecurityError && renderError(pwSecurityError)}
        <h3>Create a secure password</h3>
        <p>Make sure to include</p>
        <ul className='requirements'>
          {PW_REQUIREMENTS.map((req, idx) => {
            return (
              <li key={idx}>
                {check(getPWReq(req.key) ? 'checked' : '')}
                {req.text}
              </li>
            )
          })}
        </ul>
        <label htmlFor='user-password'>New Password</label>
        <form
          onSubmit={e => {
            e.preventDefault()
            setRequestIsPending(true)
            setNewPassword(changePasswordState, token || userToken, !!token, temporary_token, () =>
              setRequestIsPending(false),
            )
          }}>
          <Input
            id='user-password'
            hasError={!!pwSecurityError || hasSupportedSpecialCharError}
            placeholder='enter password'
            type='password'
            value={changePasswordState.password}
            onChange={value => {
              validate(value)
              updateNewPassword('password', value)
            }}
            errorText={hasSupportedSpecialCharError ? 'Please choose a supported special character' : null}
          />
          <label htmlFor='user-password'>Retype New Password</label>
          <Input
            id='user-password-confirmation'
            hasError={!!pwSecurityError}
            placeholder='re-enter password'
            type='password'
            value={changePasswordState.password_confirmation}
            onChange={value => updateNewPassword('password_confirmation', value)}
          />
          <Button
            type='submit'
            content='Set Password'
            disabled={
              !isEightChar ||
              !hasUppercase ||
              !hasLowercase ||
              !hasNum ||
              !hasSpecialChar ||
              !changePasswordState.password_confirmation.length > 0 ||
              requestIsPending
            }
          />
        </form>
      </Container>
    </div>
  )
}

export default ActivateUserPage

ActivateUserPage.propTypes = {
  changePasswordState: PropTypes.shape({
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }),
  changePwErrors: PropTypes.objectOf(PropTypes.string),
  expiration_message: PropTypes.string,
  setNewPassword: PropTypes.func,
  updateNewPassword: PropTypes.func,
  token: PropTypes.string,
  temporary_token: PropTypes.string,
  userToken: PropTypes.string,
}
