import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import { actions as notyActions } from 'layouts/ErrorBox'
import { getPasswordError, addErrorClass } from 'utils/misc'
import request, { generateNotyMessage } from 'utils/request'
import { SET_USER } from 'store/user'

const USER_ACTIVATED = 'USER_ACTIVATED'
const UPDATE_NEW_PASSWORD = 'UPDATE_NEW_PASSWORD'
const ADD_CHANGE_PW_ERRORS = 'ADD_CHANGE_PW_ERRORS'
const CLEAR_CHANGE_PW_ERRORS = 'CLEAR_CHANGE_PW_ERRORS'

export const addChangePwErrors = errors => {
  return {
    type: ADD_CHANGE_PW_ERRORS,
    errors,
  }
}
export const clearChangePwErrors = () => {
  return {
    type: CLEAR_CHANGE_PW_ERRORS,
  }
}

function activateUser(token, resetPw = false) {
  return dispatch => {
    const success = jsonBody => {
      const payload = Object.assign(JSON.parse(atob(jsonBody.token.split('.')[1])), jsonBody)
      if (!payload.username || !payload.id) {
        browserHistory.push('/unauthorized')
        throw new Error('Unable to authenticate user ')
      }
      if (jsonBody.hasPassword && !resetPw) {
        browserHistory.push('/users/me')
        throw new Error('You have already set your password')
      }
      dispatch({
        type: USER_ACTIVATED,
        token: jsonBody.token,
      })
    }
    return dispatch(
      request({
        method: 'POST',
        url: '/control/users/verify_token',
        body: JSON.stringify({ token }),
        failMessage: 'Unable to authenticate user.',
        success,
        authRequired: false,
        headerOptions: {
          Authorization: token,
        },
      }),
    )
  }
}

function setNewPassword(passwordState, token, isChangePw, temporary_token, reactivateSubmit) {
  return dispatch => {
    const passwordError = getPasswordError(passwordState.password, passwordState.password_confirmation, dispatch)
    if (!passwordError) {
      const success = () => {
        dispatch(clearChangePwErrors())
        if (isChangePw) {
          const newLocalUserData = { ...JSON.parse(localStorage.getItem('user')) }
          newLocalUserData.expiration_message = ''
          localStorage.setItem('user', JSON.stringify(newLocalUserData))
          dispatch({
            type: SET_USER,
            payload: newLocalUserData,
          })
          browserHistory.push('/studies')
        } else browserHistory.push('/login')
        reactivateSubmit()
      }
      const fail = (res, content) => {
        const pwErrorMessage = 'Please check errors before proceeding'
        dispatch(notyActions.showError({ text: generateNotyMessage(pwErrorMessage, false) }))
        if (res.status === 401) {
          dispatch(addChangePwErrors({ pwSecurityError: content.message }))
        }
        reactivateSubmit()
      }
      return dispatch(
        request({
          method: 'POST',
          url: `/control/users/setup_password`,
          body: JSON.stringify({ ...passwordState, token, temporary_token }),
          fail,
          successMessage: 'Password set successfully',
          success,
          authRequired: false,
          headerOptions: {
            Authorization: token,
          },
        }),
      )
    }
    reactivateSubmit()
    addErrorClass(['user-password', 'user-password-confirmation'])
  }
}

function updateNewPassword(field, value) {
  return {
    type: UPDATE_NEW_PASSWORD,
    field,
    value,
  }
}

// reducers

function changePasswordState(state = { password: '', password_confirmation: '' }, action) {
  switch (action.type) {
    case UPDATE_NEW_PASSWORD: {
      return { ...state, ...{ [action.field]: action.value } }
    }
    default: {
      return state
    }
  }
}

function userToken(state = null, action) {
  switch (action.type) {
    case USER_ACTIVATED:
      return action.token
    default:
      return state
  }
}

function changePwErrors(state = {}, action) {
  switch (action.type) {
    case ADD_CHANGE_PW_ERRORS:
      return { ...state, ...action.errors }
    case CLEAR_CHANGE_PW_ERRORS:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  changePasswordState,
  changePwErrors,
  userToken,
})

export const actions = {
  activateUser,
  updateNewPassword,
  setNewPassword,
}
