import React from 'react'
import AdvancedOptionsHOC from 'utils/hoc/AdvancedOptionsHOC'
import { Button, Container } from 'components/UIElements'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { DEPLOYMENT_KEYS, GEOFENCEABLE_TASKS, GROUP_TYPE_MAP } from 'utils/constants'
import DeploymentSchedule from './DeploymentSchedule'
import DeploymentOptions from './DeploymentOptions'
import TimelineDisplay from './TimelineDisplay'
import GeofenceCheckbox from './GeofenceCheckbox'
import LocalTimePrompt from './LocalTimePrompt'
import 'react-datepicker/dist/react-datepicker.css'
import '../stylesheets/Scheduler.scss'

class SchedulerPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSendButtonDisabled: false,
    }
  }

  componentDidMount() {
    const { instrument, initializeCheckedCohorts, initializeCheckedSites } = this.props
    if (instrument.metadata.schedule.cohort.type === GROUP_TYPE_MAP.cohort) {
      initializeCheckedCohorts(instrument.metadata.schedule.cohort.filter.include)
    }
    if (instrument.metadata.schedule.cohort.type === GROUP_TYPE_MAP.sites) {
      initializeCheckedSites(instrument.metadata.schedule.cohort.filter.site_ids)
    }
  }

  componentDidUpdate(prevProps) {
    const { studyID, scoreErrors, instrument } = this.props
    const { id } = instrument
    if (prevProps.scoreErrors !== scoreErrors) {
      if (scoreErrors) browserHistory.push(`/studies/${studyID}/instruments/${id}/scoring`)
    }
    return null
  }

  componentWillUnmount() {
    const { resetCheckedSites } = this.props
    resetCheckedSites()
  }

  toggleDisabledState = () => {
    this.setState(state => ({
      isSendButtonDisabled: !state.isSendButtonDisabled,
    }))
  }

  onSend = () => {
    const { deployInstrument, advancedOptions, showAdvancedOption } = this.props
    this.toggleDisabledState()
    deployInstrument({ onFail: this.toggleDisabledState }, showAdvancedOption ? advancedOptions : null)
  }

  render() {
    const {
      canSetDeploymentByVisit,
      canSetGeofencedInstruments,
      clearInstrumentTriggers,
      deleteMetadataField,
      hasVisitSupport,
      instrument,
      instruments,
      instrumentForm,
      isClinro,
      isDiary,
      isGeofenceStudy,
      removeChainDeploymentInfo,
      studyID,
      resetSchedule,
      updateChainDeployInfo,
      updateCohort,
      updateDeploy,
      updateExpire,
      updateGeofenceEnabled,
      updateInstrumentRoles,
      updateMetadataField,
      updateRecurring,
      study,
      userId,
    } = this.props

    const { isSendButtonDisabled } = this.state

    const { config } = study
    const _config = config || {}
    const { custom_geofence_behavior } = _config
    const hasCustomGeofenceBehavior = !!custom_geofence_behavior
    const isGeofencingChecked = !!instrument.is_geofencing_enabled
    const hasForcedPriorityEnabled = study?.config?.forced_instrument_priority || study.forced_instrument_priority
    const { metadata } = instrument
    const { schedule, visibility, module } = metadata
    const { chain_deployment_info, deploy } = schedule
    const isImmediateDeployment = 'now' in deploy
    const isDeployedByVisit = chain_deployment_info?.[DEPLOYMENT_KEYS.chainDeployByVisit]

    // Only the covid and ecoli chatbots can be geofenced
    const canBeGeofenced = GEOFENCEABLE_TASKS.includes(module)
    const shouldRenderGeofenceCheckbox =
      canBeGeofenced && isGeofenceStudy && canSetGeofencedInstruments && hasCustomGeofenceBehavior

    return (
      <div className='scheduler page'>
        <h3>Deployment Schedule</h3>
        <h4 className='instrument-title'>{instrument.title}</h4>
        <LocalTimePrompt disabled={isGeofencingChecked} study={study} userId={userId} isDiary={isDiary} />
        {shouldRenderGeofenceCheckbox && (
          <GeofenceCheckbox
            isChecked={isGeofencingChecked}
            onClick={nextState => {
              resetSchedule()
              if (nextState) {
                updateRecurring({ scheme: 'persistent' })
              }
              updateGeofenceEnabled(nextState)
            }}
          />
        )}
        <Container>
          <>
            <DeploymentSchedule
              canSetDeploymentByVisit={canSetDeploymentByVisit}
              clearInstrumentTriggers={clearInstrumentTriggers}
              deleteMetadataField={deleteMetadataField}
              disabled={isGeofencingChecked}
              hasVisitSupport={hasVisitSupport}
              instrumentId={instrument.id}
              instruments={instruments}
              instrumentForm={instrumentForm}
              instrumentRoles={instrument.instrument_roles}
              isClinro={isClinro}
              isDiary={isDiary}
              removeChainDeploymentInfo={removeChainDeploymentInfo}
              schedule={schedule}
              updateChainDeployInfo={updateChainDeployInfo}
              updateCohort={updateCohort}
              updateDeploy={updateDeploy}
              updateExpire={updateExpire}
              updateInstrumentRoles={updateInstrumentRoles}
              updateRecurring={updateRecurring}
              updateMetadataField={updateMetadataField}
              config={_config}
              userId={userId}
              study={study}
            />
          </>
          <DeploymentOptions
            disabled={isGeofencingChecked}
            hasForcedPriorityEnabled={hasForcedPriorityEnabled}
            schedule={schedule}
            visibility={visibility}
            userId={userId}
            study={study}
            {...this.props}
          />
        </Container>
        {/* TODO: Implement timeline for triggered by visit */}
        <TimelineDisplay
          disabled={isGeofencingChecked}
          schedule={schedule}
          userId={userId}
          study={study}
          isDiary={isDiary}
          isDeployedByVisit={isDeployedByVisit}
        />
        <div className='button-list'>
          <Button
            grey
            id='cancel'
            content='Cancel'
            onClick={() => browserHistory.push(`/studies/${studyID}/instruments`)}
          />
          <Button
            id='send'
            className='deploy-button'
            content={isImmediateDeployment ? 'Send' : 'Schedule'}
            onClick={this.onSend}
            disabled={isSendButtonDisabled}
          />
        </div>
      </div>
    )
  }
}

SchedulerPage.propTypes = {
  advancedOptions: PropTypes.shape(),
  canSetDeploymentByVisit: PropTypes.bool,
  canSetGeofencedInstruments: PropTypes.bool,
  clearInstrumentTriggers: PropTypes.func,
  deployInstrument: PropTypes.func,
  hasVisitSupport: PropTypes.bool,
  initializeCheckedCohorts: PropTypes.func,
  initializeCheckedSites: PropTypes.func,
  instrument: PropTypes.shape({
    id: PropTypes.string,
    is_geofencing_enabled: PropTypes.bool,
    instrument_roles: PropTypes.arrayOf(PropTypes.number),
    title: PropTypes.string,
    metadata: PropTypes.shape({ schedule: PropTypes.object, deploy: PropTypes.object }),
  }),
  instrumentForm: PropTypes.shape({
    metadata: PropTypes.shape({ schedule: PropTypes.object, deploy: PropTypes.object }),
  }),
  instruments: PropTypes.arrayOf(PropTypes.array),
  isClinro: PropTypes.bool,
  isDiary: PropTypes.bool,
  isGeofenceStudy: PropTypes.bool,
  removeChainDeploymentInfo: PropTypes.func,
  resetCheckedSites: PropTypes.func,
  resetSchedule: PropTypes.func,
  scoreErrors: PropTypes.objectOf(PropTypes.object),
  siteID: PropTypes.number,
  study: PropTypes.shape({
    name: PropTypes.string,
    config: PropTypes.oneOfType([PropTypes.object]),
    forced_instrument_priority: PropTypes.bool,
  }),
  studyID: PropTypes.number,
  triggersLoading: PropTypes.bool,
  updateChainDeployInfo: PropTypes.func,
  updateCohort: PropTypes.func,
  updateDeploy: PropTypes.func,
  updateExpire: PropTypes.func,
  updateGeofenceEnabled: PropTypes.func,
  updateInstrumentRoles: PropTypes.func,
  updateRecurring: PropTypes.func,
  showAdvancedOption: PropTypes.bool,
}

export default AdvancedOptionsHOC(SchedulerPage)
