import CreateStudyPage from './containers/CreateStudyContainer'
import CreateStudyTypeRoute from './routes/CreateStudyTypeRoute'
import CreateStudySettingRoute from './routes/CreateStudySettingRoute'
import CreateStudyScheduleRoute from './routes/CreateStudyScheduleRoute'
import CreateStudyReviewRoute from './routes/CreateStudyReviewRoute'

export default store => ({
  component: CreateStudyPage,
  getChildRoutes(location, cb) {
    require.ensure(
      [],
      require => {
        /*  Return getComponent   */
        cb(null, [
          CreateStudyTypeRoute(store),
          CreateStudySettingRoute(store),
          CreateStudyScheduleRoute(store),
          CreateStudyReviewRoute(store),
        ])

        /* Webpack named bundle   */
      },
      'create',
    )
  },
})
