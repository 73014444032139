import React from 'react'
import { Filter, Dropdown, Input } from 'components/UIElements'
import { _getMapState, mapActions } from 'components/Filter'
import { connect } from 'react-redux'
import { filterGetters } from './Utils'

export const STRING_MATCH_MAP = {
  contains: 'Contains',
  doesNotContain: 'Does Not Contain',
  startsWith: 'Starts With',
  endsWith: 'Ends WIth',
}

class StringFilter extends Filter {
  constructor(props) {
    super(props)
    this.defaults = { string: '', type: 'contains', filterType: 'string' }
    this.state = { ...this.defaults }
    this.key = props.dataKey
    this.header = props.header
    this.align = 'left'
    this.dropdownOptions = Object.keys(STRING_MATCH_MAP).map(key => {
      return { key, value: key, text: STRING_MATCH_MAP[key] }
    })
  }

  onChangeFilterType = ({ value }, e) => {
    e.stopPropagation()
    this.setState({ type: value, _edited: true })
  }

  onChange = string => {
    this.setState({ string, _edited: true })
  }

  getFilter(criteria) {
    return filterGetters.getStringMatcher(this.state.type, `${this.state.string}`)
  }

  render() {
    return super.render(
      <div className='select-type-filter'>
        <p className='label-small'>{`PARTICIPANT ${this.header}`}</p>
        <Dropdown options={this.dropdownOptions} onSelect={this.onChangeFilterType} selected={this.state.type} />
        <Input inline placeholder='Value' value={this.state.value ?? this.state.string} onChange={this.onChange} />
      </div>,
    )
  }
}

export default config =>
  connect(
    _getMapState(config.dataKey),
    mapActions,
  )(props => {
    return <StringFilter {...config} {...props} />
  })
