import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { setSubroute } from 'store/navbar'
import { setPageTitle, getUserScopeByStudy } from 'utils/misc'
import NotificationsPage from './containers/NotificationsContainer'
import { notificationActions } from '../routes/EditConditionalNotification/modules/ConditionalNotification'
import { CONDITIONAL_NOTIFICATION_TYPE_MAP } from './components/NotificationTypes'
// Sync route definition
export default store => {
  return {
    getComponent(nextState, cb) {
      require.ensure(
        [],
        () => {
          const { params } = nextState
          const { studyID } = params
          const { study, user } = store.getState()
          const { currentStudy } = study
          const medicationAdherence = currentStudy?.config?.medication_adherence
          setPageTitle(`Notifications | Study ${studyID} | ${study.currentStudy.platform_name}`)
          if (!medicationAdherence) store.dispatch(notificationActions.clearMedicationNotifications())
          else {
            const canViewConditionalNotifications = getUserScopeByStudy(user, study.currentStudy).includes('wa')
            if (canViewConditionalNotifications) {
              store.dispatch(
                notificationActions.fetchConditions(studyID, CONDITIONAL_NOTIFICATION_TYPE_MAP.medicationAdherence),
              )
            }
          }
          store.dispatch(setSubroute('notifications'))
          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(UserHasScope(['rsn'], NotificationsPage, false, studyID, null, currentStudy)),
            ),
          )
        },
        'notifications',
      )
    },
  }
}
