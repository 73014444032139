import { connect } from 'react-redux'
import { formatLinkEntities } from 'utils/draft'
import SurveyItem from '../components/SurveyItem'
import { itemActions, mediaActions } from '../modules/Survey'

const mapDispatchToProps = { ...itemActions, ...mediaActions }

const mapStateToProps = (state, ownProps) => {
  const { activeItemId, itemId, instrumentDomains } = ownProps
  const { itemIndex } = ownProps
  const item = state.surveyEditor.survey.questions[itemId]
  const { editorStates, survey } = state.surveyEditor
  const { currentStudy } = state.study
  const { resource_size_list: resourceSizeList } = survey
  return {
    editorState: formatLinkEntities(editorStates[itemId]),
    editorStates,
    errors: state.surveyEditor.surveyErrors.questions[itemId] || {},
    instrumentDomains,
    isActive: activeItemId === itemId,
    item,
    itemId,
    itemIndex,
    preview: state.location.pathname.includes('preview'),
    questionNumber: state.surveyEditor.survey.questions[itemId].attributes.questionNumber,
    resourceSizeList,
    caregiverEnabled: true, // !!currentStudy.caregiver_enabled, // TODO: uncomment once study configuration setting for `caregiver_enabled` will be presented
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyItem)
