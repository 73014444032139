import React from 'react'
import PropTypes from 'prop-types'
import './NavBar.scss'
import { Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import { LockBanner } from './MainNavBar'

const AltNavBar = props => {
  const { disabled, navProps, subroute, studyLock } = props
  const { closeText, close, back, backText, component } = navProps
  if (component) return component
  const onBackClick = back ? () => browserHistory.push(back) : browserHistory.goBack
  const onCloseClick = close ? () => browserHistory.push(close) : browserHistory.goBack
  const disabledBackClassName = disabled ? ' disabled' : ''

  return (
    <div className={`navbar${subroute === 'editConsent' ? ' consent-nav' : ''}${disabledBackClassName}`}>
      <div className='main alt'>
        <div>
          {back && (
            <Button noStyling disabled={disabled} className='back-link' onClick={onBackClick}>
              <i className='fas fa-arrow-left' />
              {backText}
            </Button>
          )}
        </div>
        {closeText && <Button grey content={closeText} onClick={onCloseClick} disabled={disabled} />}
        {studyLock && <LockBanner />}
      </div>
    </div>
  )
}

AltNavBar.propTypes = {
  disabled: PropTypes.bool,
  navProps: PropTypes.shape({
    back: PropTypes.string,
    backText: PropTypes.string,
    close: PropTypes.string,
    closeText: PropTypes.string,
  }),
  subroute: PropTypes.string,
  studyLock: PropTypes.bool,
}

export default AltNavBar
