import { Button } from 'components/UIElements'
import React from 'react'
import PropTypes from 'prop-types'
import UploadSurveyInput from './UploadSurveyInput'

const UploadSurveyPage = props => {
  const { onDiscardUpload, onSave, params, uploadedSurvey, onSurveyUpload } = props
  const { studyID, siteID } = params
  return (
    <div className='upload-survey page flexed column center-aligned start-justified'>
      <UploadSurveyInput onSurveyUpload={onSurveyUpload} params={params} onDiscardUpload={onDiscardUpload} />
      <div className='button-list'>
        <Button grey content='Discard' onClick={() => onDiscardUpload(studyID, siteID)} />
        <Button
          content='Go To Properties'
          disabled={uploadedSurvey === null}
          onClick={() => onSave('properties')}
          isThrottled
        />
      </div>
    </div>
  )
}

export default UploadSurveyPage

UploadSurveyPage.propTypes = {
  onDiscardUpload: PropTypes.func,
  onSave: PropTypes.func,
  onSaveSurvey: PropTypes.func,
  onSurveyUpload: PropTypes.func,
  params: PropTypes.object,
  uploadedSurvey: PropTypes.object,
}
