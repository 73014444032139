import moment from 'moment'

/*
 * This function validates that the batched meetings during visit
 * creation do not overlap and have conflicts with each other.
 */
export const getOverlappingVisits = visits => {
  const overlappingTimes = []
  visits.forEach((visit, idx) => {
    const { visit_duration, visit_time } = visit
    delete visit.isNew
    const visitStart = moment(visit_time)
    const visitEnd = moment(visitStart).add(visit_duration, 'minutes')
    const otherVisits = [...visits]
    otherVisits.splice(idx, 1)

    otherVisits.forEach(_visit => {
      const _visitStart = moment(_visit.visit_time)
      const _visitEnd = moment(_visitStart).add(_visit.visit_duration, 'minutes')

      const latestStart = visitStart.isAfter(_visitStart) ? visitStart : _visitStart
      const earliestEnd = visitEnd.isBefore(_visitEnd) ? visitEnd : _visitEnd

      if (
        latestStart.isBefore(earliestEnd) &&
        !overlappingTimes.includes(visit_time)
      )
        overlappingTimes.push(visit_time)
    })
  })
  return overlappingTimes
}

const getVisitsInPast = visits => {
  const visitsInThePast = []
  visits.forEach(visit => {
    const { visit_time } = visit
    delete visit.isNew
    const momentTime = moment(visit_time)
    if (momentTime.isBefore(new Date())) visitsInThePast.push(visit_time)
  })
  return visitsInThePast
}

const getVisitErrors = currentVisits => {
  const { visits, participant_id, user_id } = currentVisits
  const errors = {}
  const overlappingVisits = getOverlappingVisits(visits)
  const visitsInPast = getVisitsInPast(visits)
  if (overlappingVisits.length > 0) {
    errors.overlappingVisits = overlappingVisits
  }
  if (visitsInPast.length > 0) {
    errors.visitsInPast = visitsInPast
  }
  if (!participant_id) errors.noPtp = 'Please select a participant'
  if (!user_id) errors.noUser = 'Please select a user'
  return errors
}

export default getVisitErrors
