import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/loader.scss'

const D3Loader = props => {
  const {
    className,
    size = 50,
    inContainer,
    containerStyle,
    loaderColor = '#107bd5',
    fullCircleLoader,
    noBackground = false,
    noAnimate = false,
    noLoader = false,
  } = props
  const style = {
    height: size,
    width: size,
    border: `${size * 0.1}px solid ${noBackground ? 'transparent' : '#f3f3f3'}`,
    borderTop: `${size * 0.1}px solid ${noLoader ? 'transparent' : loaderColor}`,
  }

  if (fullCircleLoader) {
    style.borderBottom = `${size * 0.1}px solid ${loaderColor}`
    style.borderRight = `${size * 0.1}px solid ${loaderColor}`
  }

  return (
    <div
      style={containerStyle}
      className={`d3-loader-container${inContainer ? '' : ' page'}${className ? ` ${className}` : ''}`}>
      <div style={style} className={`loader ${noAnimate ? '' : 'spin'}`} />
    </div>
  )
}

D3Loader.propTypes = {
  className: PropTypes.string,
  containerStyle: PropTypes.object,
  fullCircleLoader: PropTypes.bool,
  inContainer: PropTypes.bool,
  loaderColor: PropTypes.string,
  size: PropTypes.number,
  noBackground: PropTypes.bool,
  noAnimate: PropTypes.bool,
  noLoader: PropTypes.bool,
}

export default D3Loader
