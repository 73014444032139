import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'

const ActionCell = props => {
  const { confirmPayment, cycleNo, reload, paymentId, hasError, hasTreasureChest, studyId, studyLock } = props
  return (
    <td key='action-cell' className='action-cell'>
      {hasError ? (
        <Button
          className='reload-button'
          grey
          content='Reload'
          iconBefore='fas fa-redo'
          onClick={() => {
            reload(studyId, false)
          }}
        />
      ) : (
        <div className='flexed row start-justified'>
          <Button
            content='Approve'
            disabled={studyLock}
            onClick={() => {
              confirmPayment(studyId, paymentId, cycleNo, true, false /* approve or decline */, hasTreasureChest)
            }}
          />
          <Button
            redSecondary
            content='Decline'
            disabled={studyLock}
            onClick={() => {
              confirmPayment(studyId, paymentId, cycleNo, false, false, hasTreasureChest)
            }}
          />
        </div>
      )}
    </td>
  )
}

ActionCell.propTypes = {
  confirmPayment: PropTypes.func,
  cycleNo: PropTypes.number,
  reload: PropTypes.func,
  hasError: PropTypes.bool,
  hasTreasureChest: PropTypes.bool,
  paymentId: PropTypes.string,
  studyId: PropTypes.number,
}

export default ActionCell
