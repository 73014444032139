import React from 'react'
import 'styles/d3/fixedtooltip.scss'

export default ({ x, y, triggerHeight, triggerWidth, visible, direction, closeTrigger, content, noGuard }) => {
  const style = {
    left: x,
  }
  if (direction === 'up') style.bottom = window.innerHeight - y + 20
  else style.top = y + 20
  return visible ? (
    <div onMouseLeave={closeTrigger} className={`fixed-tooltip${direction ? ` ${direction}` : ''}`} style={style}>
      <div className='tooltip-content'>{content}</div>
      {!noGuard && (
        <div
          style={{
            height: triggerHeight * 2,
            width: triggerWidth,
            left: `calc(50% - ${triggerWidth / 2}px)`,
          }}
          className='hover-guard'
        />
      )}
    </div>
  ) : null
}
