import React from 'react'
import { TickBar } from './VisibilityDisplay'

const VisibilityRangesDisplay = ({ min, max, visibleHours, startTickValue, endTickValue }) => {
  return (
    <div className='vis-ranges-display'>
      {visibleHours.map((isVisible, idx) => {
        let className = 'vis-box'
        if (isVisible) className += ' filled'
        // if (isVisible === 'partial') className += ' filled-partial'
        // if (isVisible === 'reverse-partial') className += ' reverse-filled-partial'
        // if (isVisible === 'complete') className += ' filled'
        className += idx === min ? ' has-bar left up' : ''
        className += idx === max ? ' has-bar right up' : ''
        return (
          <div key={`vis-box-${idx}`} className={className}>
            {min !== max && idx === min && idx !== 0 && (
              <TickBar content={startTickValue} tickPosition='top left' textPosition='top left' />
            )}
            {min !== max && idx === max - 1 && idx !== 23 && (
              <TickBar content={endTickValue} tickPosition='top right' textPosition='top right' />
            )}
            {idx === 0 && <TickBar content='0:00' tickPosition='bottom left' textPosition='bottom right' />}
            {idx === 23 && <TickBar content='23:59' tickPosition='bottom right' textPosition='bottom left' />}
          </div>
        )
      })}
    </div>
  )
}

export default VisibilityRangesDisplay
