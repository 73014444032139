import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button } from 'components/UIElements'
import '../../stylesheets/consentDrawer.scss'
import StatusContainer from './StatusContainer'
import DetailsContainer from './DetailsContainer'
import VersionContainer from './VersionContainer'

const ConsentDrawer = props => {
  const { consentId, consentVersion, draft, studyId } = props
  useEffect(() => {
    const { fetchNewConsent } = props
    fetchNewConsent({ studyId, consentId, version: consentVersion, getJson: false, loadingKey: 'consentPtps' })
  }, [])
  const { canEdit, consent, studyLock } = props
  const { consent_title, consent_version, response_rate } = consent
  const version = draft ? draft.version : consent_version
  return (
    <div className='consent-drawer page'>
      <div className='preview-header'>
        <div className='flexed-header response'>
          <span className='response-rate'>{`${response_rate}% Responded`}</span>
        </div>
        <div className='flexed-header'>
          <div className='title'>
            <h2>{consent_title}</h2>
          </div>
          {canEdit && !studyLock && (
            <Button
              content='Update Consent'
              disabled={studyLock}
              onClick={() => browserHistory.push(`/studies/${studyId}/consents/${consentId}/${version}/properties`)}
            />
          )}
        </div>
      </div>
      <StatusContainer {...props} />
      <DetailsContainer {...props} />
      <VersionContainer {...props} />
    </div>
  )
}

ConsentDrawer.propTypes = {
  canEdit: PropTypes.bool,
  consent: PropTypes.shape({
    consent_title: PropTypes.string,
    consent_version: PropTypes.number,
    response_rate: PropTypes.number,
  }),
  consentId: PropTypes.string,
  consentVersion: PropTypes.number,
  draft: PropTypes.shape({
    version: PropTypes.number,
  }),
  fetchNewConsent: PropTypes.func,
  studyId: PropTypes.number,
  studyLock: PropTypes.bool,
}

export default ConsentDrawer
