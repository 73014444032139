import { injectReducer } from 'store/reducers'
import { authActions } from 'store/user'
import { setPageTitle } from 'utils/misc'
import reducer, { actions } from '../Studies/routes/Study/routes/Users/routes/ActivateUser/modules/ActivateUser'
import ResetPasswordContainer from '../Studies/routes/Study/routes/Users/routes/ResetPassword/containers/ResetPasswordContainer'

// Sync route definition
export default store => ({
  path: '/users/reset',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        setPageTitle(`Reset Password | Admin Panel`)
        injectReducer(store, {
          key: 'resetPassword',
          reducer,
        })
        const { location, user } = store.getState()
        const urlToken = location.search.split('?token=')[1]
        if (urlToken) {
          store.dispatch(authActions.logout())
          store.dispatch(actions.activateUser(urlToken, true)).then(() => {
            cb(null, ResetPasswordContainer)
          })
        } else if (user?.token) {
          cb(null, ResetPasswordContainer)
        }
      },
      'reset',
    )
  },
})
