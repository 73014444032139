import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import ConsentItemListView from '../../../components/ConsentItemListView'

require('../../../../../../../../Instruments/routes/Instrument/routes/InstrumentPreview/stylesheets/InstrumentPreview.scss')
require('../../../../../../../../Instruments/routes/Instrument/routes/EditSurvey/stylesheets/Survey.scss')

const ConsentPreviewPage = props => {
  const { editorStates, consent, params } = props
  const { studyID } = params
  return (
    <div className='preview page'>
      <Button
        onClick={() => browserHistory.push(`/studies/${studyID}/e-consent/consent`)}
        className='preview-close-btn'
        content='Close Preview'
      />
      <h2>{consent.title}</h2>
      <ConsentItemListView editorStates={editorStates} questions={consent.questions} order={consent.order[0]} />
    </div>
  )
}

ConsentPreviewPage.propTypes = {
  consent: PropTypes.object,
  editorStates: PropTypes.object,
  params: PropTypes.object,
}

export default ConsentPreviewPage
