import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AutocompleteSearch, Button } from 'components/UIElements'
import { pluralize } from 'utils/misc'

const ParticipantFilter = props => {
  const {
    checkedPtps,
    isLongFormat,
    hasPtpCounter = true,
    numTotalPtps,
    ptpSelectionListLoading,
    participantSelectionList,
    placeholder,
    resetCheckedPtps,
    togglePtp,
    togglePtpOn,
    togglePtpOff,
  } = props

  useEffect(() => {
    if (isLongFormat) {
      participantSelectionList.forEach(ptpItem => {
        const { key, path, treeLevel: ptpTreeLevel } = ptpItem
        togglePtpOn(key, { id: key, path, treeLevel: ptpTreeLevel })
      })
    }
  }, [isLongFormat])

  const [numPtpsSelected, setNumPtpsSelected] = useState(0)
  const [participantSelectionText, setPtpSelectionText] = useState('All participants')

  useEffect(() => {
    setNumPtpsSelected(numTotalPtps)
  }, [numTotalPtps, ptpSelectionListLoading])

  useEffect(() => {
    const _numPtpSelected = participantSelectionList.filter(ptpListItem => {
      return checkedPtps[ptpListItem.key] && ptpListItem.treeLevel === 3
    }).length
    setNumPtpsSelected(_numPtpSelected)
  }, [checkedPtps])

  useEffect(() => {
    if (numPtpsSelected === numTotalPtps) {
      setPtpSelectionText(`All participants selected (${numPtpsSelected})`)
    } else {
      setPtpSelectionText(`${pluralize(numPtpsSelected, 'participant', 'participants')} selected`)
    }
  }, [numPtpsSelected])

  const _togglePtp = (id, pathName, treeLevel) => {
    if (checkedPtps[id]) {
      participantSelectionList.forEach(ptpItem => {
        if (
          ptpItem.path.includes(`${pathName}.`) ||
          (pathName.includes(ptpItem.path) && treeLevel !== ptpItem.treeLevel)
        ) {
          togglePtpOff(ptpItem.key)
        }
      })
    } else {
      participantSelectionList.forEach(ptpItem => {
        const { key, path, treeLevel: ptpTreeLevel } = ptpItem
        if (ptpItem.path.includes(`${pathName}.`)) {
          togglePtpOn(key, { id: key, path, treeLevel: ptpTreeLevel })
        }
      })
    }
    togglePtp(id, { id, path: pathName, treeLevel })
  }

  return (
    <div className='flexed'>
      <div>
        <AutocompleteSearch
          absolutePositionedList
          checked={checkedPtps}
          disabled={ptpSelectionListLoading || isLongFormat}
          list={participantSelectionList}
          hideSearchIcon
          placeholder={placeholder || 'Search by Participant ID or Site'}
          toggleItem={_togglePtp}
        />
        {hasPtpCounter && <span className='pos abs microcopy'>{participantSelectionText}</span>}
      </div>
      <Button
        disabled={ptpSelectionListLoading || isLongFormat}
        link
        content='Clear All'
        onClick={() => {
          resetCheckedPtps()
        }}
      />
    </div>
  )
}

ParticipantFilter.propTypes = {
  checkedPtps: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.object), PropTypes.objectOf(PropTypes.number)]),
  hasPtpCounter: PropTypes.bool,
  isLongFormat: PropTypes.bool,
  numTotalPtps: PropTypes.number,
  participantSelectionList: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  ptpSelectionListLoading: PropTypes.bool,
  resetCheckedPtps: PropTypes.func,
  togglePtp: PropTypes.func,
  togglePtpOn: PropTypes.func,
  togglePtpOff: PropTypes.func,
}

export default ParticipantFilter
