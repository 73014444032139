import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Radio, Button, FormattedContent } from 'components/UIElements'
import { DEPLOYMENT_KEYS, INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { pluralize } from 'utils/misc'
import STRINGS from 'utils/strings'
import TriggersListItem from './TriggersListItem'

const CHAIN_DEPLOY_OFFSET = DEPLOYMENT_KEYS.chainDeployOffset

const OPERATOR_OPTIONS = {
  OR: 'OR',
  AND: 'AND',
}

const InstrumentToInstrumentStart = props => {
  const {
    chain_deployment_info,
    clearPrevalidatedError,
    clinrosScore,
    deploy,
    diariesScore,
    disabled,
    errors,
    fetchClinro,
    fetchDiary,
    fetchSurvey,
    otherInstruments,
    study,
    surveysScore,
    updateChainDeployInfo,
    updateChainDeployInfoField,
    updateDeployField,
    updateExpire,
    updatePrevalidatedErrors,
  } = props

  const chainedInstrumentId = chain_deployment_info?.prev_instrument_id
  const [triggers, setTriggers] = useState([])
  const [operator, setOperator] = useState(OPERATOR_OPTIONS.AND)

  const instrumentDropdownOptions = otherInstruments

  const filteredOptions = instrumentDropdownOptions.filter(option => {
    return !triggers.includes(option.key)
  })

  useEffect(() => {
    if (Array.isArray(chainedInstrumentId)) {
      const newTriggers = [...triggers, ...chainedInstrumentId]
      setTriggers(newTriggers)

      instrumentDropdownOptions.forEach(option => {
        if (newTriggers.includes(option.key)) {
          fetchSurveyOrClinro(option)
        }
      })
    } else {
      const newItem = {
        ...chain_deployment_info,
        prev_instrument_id: [...triggers, filteredOptions?.[0]?.key],
        deployment_condition: {
          ...chain_deployment_info.deployment_condition,
          operator: chain_deployment_info.deployment_condition?.operator || operator,
          terms: [
            {
              instrument_id: filteredOptions?.[0]?.key,
            },
          ],
        },
      }

      setTriggers([...triggers, filteredOptions?.[0]?.key])
      updateChainDeployInfo(newItem)
      fetchSurveyOrClinro(newItem)
    }
  }, [])

  useEffect(() => {
    if (chain_deployment_info.deployment_condition?.operator) {
      setOperator(chain_deployment_info.deployment_condition?.operator)
    }
  }, [])

  const [daysAfter, setDaysAfter] = useState(deploy.chain_deploy_offset || 1)
  const [chainedItemType, setChainedItemType] = useState('')
  const getInstrumentScore = instrumentId => {
    return surveysScore[instrumentId] || clinrosScore[instrumentId] || diariesScore[instrumentId] || []
  }

  const generateInvalidTriggers = () => {
    const result = {}
    if (Array.isArray(chainedInstrumentId)) {
      chainedInstrumentId.forEach(trigger => {
        const notIncluded =
          otherInstruments.findIndex(el => {
            return el.key === trigger
          }) === -1
        if (notIncluded) {
          result[trigger] = true
        }
      })
    }
    return result
  }

  const throwPrevalidatedErr = () => {
    updateDeployField(CHAIN_DEPLOY_OFFSET, 0)
    updateExpire({ never: null })
    setDaysAfter(1)
    updatePrevalidatedErrors({ invalidInstrumentTrigger: '' })
  }
  const clearErr = () => {
    clearPrevalidatedError('invalidInstrumentTrigger')
  }

  const invalidTriggers = generateInvalidTriggers()

  const fetchSurveyOrClinro = item => {
    if (item.type === INSTRUMENT_TYPE_MAP.survey) {
      fetchSurvey(study.id, item.key, null, true).then(fetched => {
        if (fetched) setChainedItemType(item.type)
      })
    } else if (item.type === INSTRUMENT_TYPE_MAP.clinro) {
      fetchClinro(study.id, item.key, null, true).then(fetched => {
        if (fetched) setChainedItemType(item.type)
      })
    } else if (item.type === INSTRUMENT_TYPE_MAP.diary) {
      fetchDiary(study.id, item.key, null, true).then(fetched => {
        if (fetched) setChainedItemType(item.type)
      })
    } else {
      setChainedItemType(item.type)
    }
  }

  const daysAfterInput = (
    <div className='flexed'>
      <span>After</span>
      <Input
        value={daysAfter}
        min={1}
        onChange={val => {
          updateExpire({ never: null })
          // prevents user from entering negative number
          if (Number(val) < 0) {
            setDaysAfter(1)
            updateDeployField(CHAIN_DEPLOY_OFFSET, 1)
          } else {
            const isNum = val || val === '0'
            setDaysAfter(isNum ? Number(val) : val)
            updateDeployField(CHAIN_DEPLOY_OFFSET, isNum ? Number(val) : val)
          }
        }}
        onBlur={e => {
          // This ensures there is no empty value if the input is left blank
          if (e.target.value === '') {
            setDaysAfter(1)
            updateDeployField(CHAIN_DEPLOY_OFFSET, 1)
          }
        }}
        type='number'
      />
      <span>{pluralize(daysAfter, 'day', 'days', false)}</span>
    </div>
  )

  const updateOperatorField = value => {
    const newItem = {
      ...chain_deployment_info,
      deployment_condition: {
        ...chain_deployment_info.deployment_condition,
        operator: value,
      },
    }
    delete newItem.cohort
    updateChainDeployInfo(newItem)
    fetchSurveyOrClinro(newItem)
  }

  const updateChainDeploymentInfo = (triggersList, item, updatedTerms) => {
    const newItem = {
      ...chain_deployment_info,
      prev_instrument_id: triggersList,
      deployment_condition: {
        ...chain_deployment_info.deployment_condition,
        terms: updatedTerms,
      },
    }
    delete newItem.cohort
    updateChainDeployInfo(newItem)
    fetchSurveyOrClinro(item)
  }

  const selectTrigger = (triggerIdx, newId, item) => {
    const newTriggers = triggers.map((trigger, idx) => {
      if (idx === triggerIdx) {
        return newId
      }
      return trigger
    })

    const oldTerms = chain_deployment_info.deployment_condition.terms || []
    const newTerms = newTriggers.map(id => ({ instrument_id: id }))

    const terms = oldTerms.length ? oldTerms : newTerms

    // Update existing term
    let updatedTerms = terms.map((term, idx) => {
      if (idx === triggerIdx) {
        return {
          ...term,
          instrument_id: newId,
        }
      }
      return term
    })

    // Add new term
    const newIdTerm = !!updatedTerms.find(updatedTerm => updatedTerm?.instrument_id === newId)
    if (!newIdTerm) {
      updatedTerms = [
        ...updatedTerms,
        {
          instrument_id: newId,
        },
      ]
    }

    setTriggers(newTriggers)

    updateChainDeploymentInfo(newTriggers, item, updatedTerms)
  }

  const addTrigger = () => {
    const item = filteredOptions?.[0]
    const newId = item?.key
    const triggerIdx = triggers.length // add as a new item
    const newTriggers = [...triggers, newId]

    const oldTerms = chain_deployment_info.deployment_condition.terms || []
    const newTerms = newTriggers.map(id => ({ instrument_id: id }))

    const terms = oldTerms.length ? oldTerms : newTerms

    // Update existing term
    let updatedTerms = terms.map((term, idx) => {
      if (idx === triggerIdx) {
        return {
          ...term,
          instrument_id: newId,
        }
      }
      return term
    })

    // Add new term
    const newIdTerm = !!updatedTerms.find(updatedTerm => updatedTerm?.instrument_id === newId)
    if (!newIdTerm) {
      updatedTerms = [
        ...updatedTerms,
        {
          instrument_id: newId,
        },
      ]
    }

    setTriggers(newTriggers)

    updateChainDeploymentInfo(newTriggers, item, updatedTerms)
  }

  const removeTrigger = triggerIdx => {
    const newTriggers = triggers.filter((trigger, idx) => idx !== triggerIdx)

    const oldTerms = chain_deployment_info.deployment_condition.terms || []
    const newTerms = newTriggers.map(id => ({ instrument_id: id }))

    const terms = oldTerms.length ? oldTerms : newTerms

    const updatedTerms = terms.filter((term, idx) => idx !== triggerIdx)

    setTriggers(newTriggers)

    updateChainDeploymentInfo(newTriggers, {}, updatedTerms)
  }

  return (
    <div className={`triggered-by-menu${disabled ? ' disabled' : ''}`}>
      <div className='trigger-inst-box border-radius eight'>
        <p>
          <span className={`${disabled ? 'disabled' : ''}`}>
            Which instrument should trigger this instrument&apos;s deployment?
          </span>
        </p>
        {triggers.map((trigger, idx) => (
          <TriggersListItem
            key={`${trigger}-${idx}`}
            invalidTriggers={invalidTriggers}
            trigger={trigger}
            chain_deployment_info={chain_deployment_info}
            errors={errors}
            options={filteredOptions}
            updateChainDeployInfo={updateChainDeployInfo}
            updateChainDeployInfoField={updateChainDeployInfoField}
            selectTrigger={selectTrigger}
            removeTrigger={removeTrigger}
            isDeleteBtnShown={triggers.length > 1}
            getInstrumentScore={getInstrumentScore}
            idx={idx}
            allOptions={instrumentDropdownOptions}
            throwPrevalidatedErr={throwPrevalidatedErr}
            clearErr={clearErr}
          />
        ))}
        <div className='add-new-trigger-box'>
          <Button
            className='add-new-trigger-btn'
            iconBefore='fas fa-plus'
            noStyling
            inline
            disabled={!filteredOptions.length}
            onClick={() => {
              addTrigger()
            }}>
            <span>{STRINGS.addNewTrigger}</span>
          </Button>
        </div>
        <div className='trigger-options-box'>
          <Radio
            disabled={disabled}
            selected={operator === OPERATOR_OPTIONS.OR}
            onClick={() => {
              setOperator(OPERATOR_OPTIONS.OR)
              updateOperatorField(OPERATOR_OPTIONS.OR)
            }}
            content={<FormattedContent content={STRINGS.atLeastOneInstrumentOption} />}
          />
          <Radio
            disabled={disabled}
            selected={operator === OPERATOR_OPTIONS.AND}
            onClick={() => {
              setOperator(OPERATOR_OPTIONS.AND)
              updateOperatorField(OPERATOR_OPTIONS.AND)
            }}
            content={<FormattedContent content={STRINGS.allInstrumentsOption} />}
          />
        </div>
      </div>
      <p>{STRINGS.instToInstHowLongAfter}</p>
      <Radio
        content='Immediately'
        onClick={() => {
          updateDeployField(CHAIN_DEPLOY_OFFSET, 0)
          updateExpire({ never: null })
        }}
        selected={deploy.chain_deploy_offset === 0}
      />
      <Radio
        onClick={() => {
          if (deploy.chain_deploy_offset === 0) {
            updateDeployField(CHAIN_DEPLOY_OFFSET, daysAfter)
            updateExpire({ never: null })
          }
        }}
        selected={deploy.chain_deploy_offset !== 0}
        content={daysAfterInput}
      />
    </div>
  )
}

InstrumentToInstrumentStart.propTypes = {
  chain_deployment_info: PropTypes.shape({
    has_chain_deployment: PropTypes.bool,
  }),
  clearPrevalidatedError: PropTypes.func,
  deploy: PropTypes.shape({
    chain_deploy_offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  clinroScore: PropTypes.array,
  disabled: PropTypes.bool,
  fetchClinro: PropTypes.func,
  fetchSurvey: PropTypes.func,
  otherInstruments: PropTypes.arrayOf(PropTypes.object),
  surveyScore: PropTypes.array,
  study: PropTypes.shape({
    id: PropTypes.number,
  }),
  updateChainDeployInfo: PropTypes.func,
  updateChainDeployInfoField: PropTypes.func,
  updateDeployField: PropTypes.func,
  updateExpire: PropTypes.func,
  updatePrevalidatedErrors: PropTypes.func,
}

export default InstrumentToInstrumentStart
