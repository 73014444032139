import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Divider } from 'components/UIElements'
import { QUESTION_TYPE_MAP, QUESTION_TYPES_WITH_CHOICES, QUESTION_TYPES_WITH_SCORING } from 'utils/constants'
import { scrollTo } from 'utils/misc'
import ScoringPanel from './Scoring/ClinroScoringPanel'
import CommentsPanel from './Comments/CommentsPanel'
import SiblingsPanel from './Siblings/SiblingsPanel'
import ClinroQuestionPanel from './ClinroQuestionPanel'
import ValidationPanel from './ValidationPanel'
import ClinroLogicPanelContainer from '../containers/ClinroLogicPanelContainer'
import { itemEditorActions } from '../modules/Clinro'

const introPic = require('../../EditSurvey/assets/intro_with_greeting.png')

class ClinroItemEditor extends React.Component {
  onChangeItemType = item => {
    const { clinro, activeItemId, changeItemType } = this.props
    changeItemType(clinro, activeItemId, clinro.questions[activeItemId].type, item.value)
  }

  onUpdateItemInputWidth = item => {
    const { updateItem, clinro, activeItemId } = this.props
    const { questions } = clinro
    const question = questions[activeItemId]
    const newItem = { ...question, attributes: { ...question.attributes, inputWidth: item.value } }
    updateItem(activeItemId, newItem)
  }

  onUpdateItemComments = val => {
    const { updateItem, clinro, activeItemId } = this.props
    const { questions } = clinro
    const question = questions[activeItemId]
    const newItem = { ...question, comments: val }
    updateItem(activeItemId, newItem)
  }

  onAddChoice = () => {
    const { activeItemId, clinro, addChoice } = this.props
    const { questions } = clinro
    const { type } = questions[activeItemId]
    addChoice(activeItemId, questions[activeItemId], null, type)
  }

  onDeleteChoice = choiceId => {
    const { activeItemId, clinro, deleteChoice } = this.props
    return () => {
      deleteChoice(clinro, activeItemId, choiceId)
    }
  }

  onAddQuestion = () => {
    const { activeItemId, addMatrixQuestion } = this.props
    addMatrixQuestion(activeItemId, null)
  }

  onDeleteQuestion = subQuestionId => {
    const { activeItemId, deleteMatrixQuestion, clinro } = this.props
    deleteMatrixQuestion(clinro, activeItemId, subQuestionId)
  }

  onScrollToItem = ({ isIntro, e }) => {
    e.preventDefault()
    const { activeItemId } = this.props
    const element = document.getElementById(`question-${activeItemId}`)
    let offset = 120
    if (isIntro) offset += 16
    if (element) {
      scrollTo({ element, offset })
    }
  }

  onUpdateUnitType = e => {
    const { activeItemId, updateUnitType } = this.props
    updateUnitType(activeItemId, e.target.value)
  }

  onAddOtherChoice = () => {
    const { activeItemId, addOtherChoice } = this.props
    addOtherChoice(activeItemId)
  }

  onMoveItem = direction => {
    const { clinro, activeItemId, moveItem } = this.props
    return () => {
      const startIdx = clinro.order[0].indexOf(activeItemId)
      let endIdx
      if (direction === 'up') {
        endIdx = startIdx - 1
      } else {
        endIdx = startIdx + 1
      }
      const currentQuestionId = clinro.order[0][startIdx]
      const { sibling_id } = clinro.questions[currentQuestionId]
      const selectedItemsArr = sibling_id ? clinro.siblings[sibling_id] : null
      moveItem(clinro, startIdx, endIdx, selectedItemsArr)
    }
  }

  onDuplicateItem = () => {
    const { activeItemId, editorStates, duplicateItem, question, setActiveItemId } = this.props
    const questionLabelEditorState = editorStates[activeItemId]
    if (QUESTION_TYPES_WITH_CHOICES.includes(question.type)) {
      const { choices_order } = question
      const choiceQProps = { choicesOrder: choices_order }
      duplicateItem(activeItemId, questionLabelEditorState, choiceQProps)
    } else if (question.type === QUESTION_TYPE_MAP.matrix) {
      const { choices_order } = question
      const choiceQProps = { choicesOrder: choices_order }
      const matrixQuestionEditorStates = {}
      const { questions_order } = question
      const matrixProps = { questionsOrder: questions_order }
      questions_order.forEach(questionId => {
        matrixQuestionEditorStates[questionId] = editorStates[questionId]
      })
      matrixProps.matrixQuestionEditorStates = matrixQuestionEditorStates
      duplicateItem(activeItemId, questionLabelEditorState, choiceQProps, matrixProps)
    } else {
      duplicateItem(activeItemId, questionLabelEditorState)
    }
  }

  onToggleRequiredQuestion = () => {
    const { toggleRequiredQuestion, clinro, activeItemId } = this.props
    toggleRequiredQuestion(clinro, activeItemId)
  }

  onToggleInputValidation = () => {
    const { toggleInputValidation, activeItemId } = this.props
    toggleInputValidation(activeItemId)
  }

  onToggleScoring = () => {
    const { clinro, toggleQuestionScoring, activeItemId } = this.props
    const item = clinro.questions[activeItemId]
    const { domains = [] } = item
    toggleQuestionScoring(activeItemId, domains)
  }

  onUpdateItemFormula = formula => {
    const { activeItemId, updateClinroItemFormula } = this.props
    updateClinroItemFormula(activeItemId, formula)
  }

  onCreateNewDomain = label => {
    const { activeItemId, createNewDomainAndAddToQuestion } = this.props
    createNewDomainAndAddToQuestion(activeItemId, label)
  }

  onToggleDomain = domainId => {
    const { activeItemId, toggleQuestionDomain } = this.props
    toggleQuestionDomain(activeItemId, domainId)
  }

  render() {
    const { activeItemId, addSiblingClinroItem, clinro, deleteItem, setActiveItemId } = this.props
    const item = clinro.questions[activeItemId]
    const { attributes, comments, formula, sibling_id } = item
    const { canHaveLogic, hasCharLimit, hasScoring, questionNumber } = attributes
    const isIntro = item.type === QUESTION_TYPE_MAP.introduction
    const isMultiField = item.type === QUESTION_TYPE_MAP.multipleField
    const isSelectMultiple = item.type === QUESTION_TYPE_MAP.selectMultiple
    const isImageCapture = item.type === QUESTION_TYPE_MAP.imageCapture
    const isQuestion = ![QUESTION_TYPE_MAP.introduction, QUESTION_TYPE_MAP.prompt].includes(item.type)
    const itemText = questionNumber ? `Q${questionNumber}` : isIntro ? 'Introduction Text' : 'Text'
    const { domains } = clinro
    const questionDomains = item.domains
    const isFirstSibling = sibling_id ? clinro.siblings[sibling_id].indexOf(activeItemId) === 0 : false
    const isSubsequentSibling = !!sibling_id && !isFirstSibling
    const canHaveScoring = QUESTION_TYPES_WITH_SCORING.includes(item.type)

    // Bool representing whether a matrix question type allows for multiple answers
    const isMatrixMulti = item.type === QUESTION_TYPE_MAP.matrix && item.attributes.allowMultipleAnswers

    const disabledRequired = [
      QUESTION_TYPE_MAP.introduction,
      QUESTION_TYPE_MAP.audioRecording,
      QUESTION_TYPE_MAP.prompt,
    ].includes(item.type)

    return (
      <div className='item-editor' onClick={e => this.onScrollToItem({ isIntro, e })}>
        <div className='editor-toolbar flexed'>
          <div className='flexed align-center'>
            <div className='question-number'>
              <h4>{itemText}</h4>
            </div>
            {!isIntro && (
              <>
                <Divider height='15px' width='1px' color='#cacaca' />
                <ValidationPanel
                  hasCharLimit={hasCharLimit}
                  disabledRequired={disabledRequired}
                  isMultiField={isMultiField}
                  onToggleInputValidation={this.onToggleInputValidation}
                  onToggleRequiredQuestion={this.onToggleRequiredQuestion}
                  required={item.attributes.required}
                />
              </>
            )}
          </div>
          {!isIntro && (
            <div className='toolbar-buttons button-list'>
              <Button
                className='far fa-clone hoverable-1'
                disabled={!!sibling_id}
                noStyling
                onClick={this.onDuplicateItem}
              />
              <Button className='fas fa-trash-alt hoverable-red' onClick={deleteItem(activeItemId)} noStyling />
              <i
                className={`clickable fas fa-arrow-up${
                  clinro.order[0][1] === activeItemId || clinro.order[0][0] === activeItemId || isSubsequentSibling
                    ? ' disabled'
                    : ''
                }`}
                onClick={this.onMoveItem('up')}
              />
              <i
                className={`clickable fas fa-arrow-down${
                  clinro.order[0][clinro.order[0].length - 1] === activeItemId ||
                  item.type === 'introduction' ||
                  isSubsequentSibling
                    ? ' disabled'
                    : ''
                }`}
                onClick={this.onMoveItem('down')}
              />
            </div>
          )}
        </div>
        {item.type === QUESTION_TYPE_MAP.introduction && (
          <div>
            <div className='panel'>Introduction text will be given in a speech bubble by Humi.</div>
            <img src={introPic} alt='intro-sample' />
          </div>
        )}
        {isQuestion && (
          <>
            <ClinroQuestionPanel
              item={item}
              itemId={activeItemId}
              onAddOtherChoice={this.onAddOtherChoice}
              onChangeItemType={this.onChangeItemType}
              onDeleteChoice={this.onDeleteChoice}
              onAddChoice={this.onAddChoice}
              onUpdateUnitType={this.onUpdateUnitType}
              onUpdateItemInputWidth={this.onUpdateItemInputWidth}
              onAddQuestion={this.onAddQuestion}
              onDeleteQuestion={this.onDeleteQuestion}
              {...this.props}
            />
            {canHaveScoring && (
              <ScoringPanel
                createNewDomain={this.onCreateNewDomain}
                domains={domains}
                hasCalcDropdown={isSelectMultiple || isMatrixMulti}
                hasScoring={hasScoring}
                onToggleDomain={this.onToggleDomain}
                onToggleScoring={this.onToggleScoring}
                onUpdateItemFormula={this.onUpdateItemFormula}
                questionDomains={questionDomains}
                formula={formula}
              />
            )}
            {!sibling_id && !isImageCapture && (
              <CommentsPanel comments={comments} onChange={this.onUpdateItemComments} />
            )}
          </>
        )}

        {canHaveLogic && <ClinroLogicPanelContainer itemId={activeItemId} />}
        {isQuestion && !isImageCapture && (
          <>
            <Divider color='#979797' margin='16px 20px' />
            <SiblingsPanel
              activeItemId={activeItemId}
              addSiblingClinroItem={addSiblingClinroItem}
              clinro={clinro}
              item={item}
              setActiveItemId={setActiveItemId}
            />
          </>
        )}
      </div>
    )
  }
}

ClinroItemEditor.propTypes = {
  activeItemId: PropTypes.string,
  addChoice: PropTypes.func,
  addSiblingClinroItem: PropTypes.func,
  addMatrixQuestion: PropTypes.func,
  addOtherChoice: PropTypes.func,
  changeItemType: PropTypes.func,
  clinro: PropTypes.shape({
    domains: PropTypes.objectOf(PropTypes.object),
    order: PropTypes.arrayOf(PropTypes.array),
    questions: PropTypes.objectOf(PropTypes.object),
    siblings: PropTypes.objectOf(PropTypes.array),
  }),
  createNewDomainAndAddToQuestion: PropTypes.func,
  deleteChoice: PropTypes.func,
  deleteItem: PropTypes.func,
  deleteMatrixQuestion: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorStates: PropTypes.object,
  itemId: PropTypes.string,
  moveItem: PropTypes.func,
  question: PropTypes.shape({
    choices_order: PropTypes.array,
    questions_order: PropTypes.array,
    type: PropTypes.string,
  }),
  setActiveItemId: PropTypes.func,
  toggleInputValidation: PropTypes.func,
  toggleQuestionDomain: PropTypes.func,
  toggleQuestionScoring: PropTypes.func,
  toggleRequiredQuestion: PropTypes.func,
  updateClinroItemFormula: PropTypes.func,
  updateItem: PropTypes.func,
  updateUnitType: PropTypes.func,
}

export default connect(null, itemEditorActions)(ClinroItemEditor)
