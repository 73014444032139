import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { RichTextEditor } from 'components/UIElements'

const HEADERS = {
  header1: 'left_header',
  header2: 'center_header',
  header3: 'right_header',
}

const FOOTERS = {
  footer1: 'left_footer',
  footer2: 'center_footer',
}
function HeaderFooterFields(props) {
  const { editorState, disabled, updateConsentElement } = props

  const [elementState, setElementState] = useState(
    Object.values(HEADERS)
      .concat(Object.values(FOOTERS))
      .reduce((obj, key) => ({ ...obj, [key]: editorState[key] || EditorState.createEmpty() }), {}),
  )

  useEffect(() => {
    if (editorState) {
      const { left_header, center_header, right_header, left_footer, center_footer } = editorState
      setElementState({ left_header, center_header, right_header, left_footer, center_footer })
    }
  }, [editorState])

  const onChangeTextValue = (newEditorState, elementKey) => {
    updateConsentElement({ elementKey, newEditorState })
  }

  return (
    <div className='header-footer-fields'>
      <h5>Header</h5>
      {Object.values(HEADERS).map((elementKey, idx) => {
        return (
          <div key={elementKey} id={`element_${elementKey}`}>
            <p className='label-small'>{`Header ${idx + 1}`}</p>
            <RichTextEditor
              readOnly={disabled}
              showToolbar
              isChoice
              editorState={elementState[elementKey]}
              onChange={newEditorState => onChangeTextValue(newEditorState, elementKey)}
              autofocus
              placeholder='Type here'
              spellCheck
              charLimit={100}
            />
          </div>
        )
      })}
      <h5>Footer</h5>
      {Object.values(FOOTERS).map((elementKey, idx) => {
        return (
          <div key={elementKey} id={`element_${elementKey}`}>
            <p className='label-small'>{`Footer ${idx + 1}`}</p>
            <RichTextEditor
              readOnly={disabled}
              showToolbar
              isChoice
              editorState={elementState[elementKey]}
              onChange={newEditorState => onChangeTextValue(newEditorState, elementKey)}
              autofocus
              placeholder='Type here'
              spellCheck
              charLimit={100}
            />
          </div>
        )
      })}
      <h5>Page number</h5>
      <p className='label-small'>page numbers will be available at the lower right corner of this document.</p>
    </div>
  )
}

HeaderFooterFields.propTypes = {
  consent: PropTypes.object,
  disabled: PropTypes.bool,
  updateConsentElement: PropTypes.func,
}

export default HeaderFooterFields
