import React from 'react'
import PropTypes from 'prop-types'
import { QUESTION_TYPE_MAP } from 'utils/constants'
import DiaryLogicPreview from './DiaryLogicPreview'
import DiarySelectView from './DiarySelectView'
import DiaryNumericInputRange from './InputRange/DiaryNumericInputRange'
import DiaryDateInputRange from './InputRange/DiaryDateInputRange'
import DiaryTimeInputRange from './InputRange/DiaryTimeInputRange'

class DiaryQuestionView extends React.Component {
  constructor(props) {
    super(props)
    this.DISPLAY_PROPS = {
      integer: '123',
      decimal: '123',
      text: 'abc',
      date: <i key='fa-calendar-preview' className='far fa-calendar-alt' />,
      time: <i key='fa-clock-preview' className='fa fa-clock-o' />,
      datetime: (
        <span className='flexed'>
          <i key='fa-calendar-preview' className='far fa-calendar-alt' />
          <i key='fa-clock-preview' className='fa fa-clock-o' />
        </span>
      ),
    }
  }

  getIsLastQuestion = () => {
    const { itemId, diary } = this.props
    if (!diary) return false
    const { order } = diary
    return order[0][order[0].length - 1] === itemId
  }

  renderDefaultQuestion = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <div>
        {item.type !== 'introduction' ? (
          <div className='non-select-preview'>
            <div style={{ fontFamily: 'AmericanTypeWriter' }}>{displayComponent}</div>
          </div>
        ) : null}
        <DiaryLogicPreview item={item} errors={errors} />
      </div>
    )
  }

  renderDiaryNumericInputRange = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <>
        <DiaryNumericInputRange
          {...this.props}
          displayComponent={displayComponent}
          isDecimal={item.type === QUESTION_TYPE_MAP.decimal}
        />
        <DiaryLogicPreview item={item} errors={errors} />
      </>
    )
  }

  renderDateQuestion = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <>
        <DiaryDateInputRange
          {...this.props}
          isLastQuestion={this.getIsLastQuestion()}
          displayComponent={displayComponent}
          timeDisplayComponent={this.DISPLAY_PROPS.time}
          dateDisplayComponent={this.DISPLAY_PROPS.date}
          isDateTime={item.type === QUESTION_TYPE_MAP.datetime}
        />
        <DiaryLogicPreview item={item} errors={errors} />
      </>
    )
  }

  renderDiaryTimeInputRange = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <>
        <DiaryTimeInputRange
          {...this.props}
          displayComponent={displayComponent}
          timeDisplayComponent={this.DISPLAY_PROPS.time}
        />
        <DiaryLogicPreview item={item} errors={errors} />
      </>
    )
  }

  renderSelectQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <DiarySelectView {...this.props} />
        <DiaryLogicPreview errors={errors} item={item} />
      </div>
    )
  }

  render() {
    const { item } = this.props
    switch (item.type) {
      case QUESTION_TYPE_MAP.numericRatingScale:
      case QUESTION_TYPE_MAP.longListSelectOne:
      case QUESTION_TYPE_MAP.selectOne:
      case QUESTION_TYPE_MAP.selectMultiple:
      case QUESTION_TYPE_MAP.multipleField:
        return this.renderSelectQuestion()
      case QUESTION_TYPE_MAP.decimal:
      case QUESTION_TYPE_MAP.integer:
        return this.renderDiaryNumericInputRange()
      case QUESTION_TYPE_MAP.date:
      case QUESTION_TYPE_MAP.datetime:
        return this.renderDateQuestion()
      case QUESTION_TYPE_MAP.time:
        return this.renderDiaryTimeInputRange()
      default:
        return this.renderDefaultQuestion()
    }
  }
}

export default DiaryQuestionView

DiaryQuestionView.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.string,
  onDeleteChoice: PropTypes.func,
  updateItem: PropTypes.func,
  isActive: PropTypes.bool,
  errors: PropTypes.object,
  preview: PropTypes.bool,
  diary: PropTypes.object,
}
