import React from 'react'
import PropTypes from 'prop-types'
import { AutocompleteSearch, Button, Dropdown } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { convertLanguagesToCheckedObject } from '../../../../../../CreateStudy/utils/studyUtils'

const LanguageTags = ({ disabled, tags, toggleSiteLanguage }) => {
  return (
    <div className='language-tags'>
      {tags.map(tag => {
        const { id, text } = tag
        return (
          <span key={id}>
            {text}
            {!disabled && <i onClick={() => toggleSiteLanguage(tag)} className='fas fa-times' />}
          </span>
        )
      })}
    </div>
  )
}

const PLACEHOLDER = { key: 'placeholder', text: 'Select a default language' }

const EnforcedSiteLanguagesConfig = ({
  config = {},
  disabled,
  hasError,
  locales,
  resetSiteConfig,
  toggleSiteLanguage,
  updateSiteConfig,
}) => {
  const { enforced_language } = config
  const tags = enforced_language ? enforced_language.languages : []
  const tagKeys = tags.map(tag => tag.key)

  const allowedLanguagesText = tags.length ? (
    <p>
      <strong>{`${tags.length}`}</strong>
      {` ${pluralize(tags.length, 'language', 'languages', false)} allowed`}
    </p>
  ) : (
    <p>No languages selected</p>
  )

  return (
    <div className='enforced-site-languages-config'>
      {!disabled && (
        <div className='flexed baseline-aligned'>
          <AutocompleteSearch
            checked={convertLanguagesToCheckedObject(tags || [])}
            disabled={disabled}
            toggleItem={item => toggleSiteLanguage(item)}
            placeholder='Search languages to add'
            shouldReturnItem
            list={locales.filter(locale => !tagKeys.includes(locale.key))}
          />

          <Button className='default-language-btn' link onClick={() => resetSiteConfig()}>
            Default to study settings
          </Button>
        </div>
      )}
      <div className={`default-language-section flexed column start-aligned ${hasError ? ' has-error' : ''}`}>
        {allowedLanguagesText}
        {!disabled && (
          <Button
            className='clear-languages-btn'
            link
            onClick={() => updateSiteConfig({ ...config, enforced_language: { languages: [], default_language: '' } })}>
            Clear all
          </Button>
        )}
        {tags.length > 0 && <LanguageTags disabled={disabled} toggleSiteLanguage={toggleSiteLanguage} tags={tags} />}
        <div className='flexed baseline-aligned start-justified'>
          <p className='label-medium'>Default Language</p>
          <Dropdown
            hasError={hasError}
            disabled={disabled}
            selected={
              enforced_language && enforced_language.default_language
                ? enforced_language.default_language
                : PLACEHOLDER.key
            }
            options={[PLACEHOLDER, ...tags]}
            onSelect={newLang => {
              updateSiteConfig({
                ...config,
                enforced_language: {
                  default_language: newLang.key === PLACEHOLDER.key ? '' : newLang.key,
                  languages: [...tags],
                },
              })
            }}
          />
        </div>
      </div>
      {hasError && <p className='error-text'>Select language(s) or "Default to study settings"</p>}
    </div>
  )
}

const localeType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
  }),
)

LanguageTags.propTypes = {
  disabled: PropTypes.bool,
  tags: localeType,
  toggleSiteLanguage: PropTypes.func,
}

EnforcedSiteLanguagesConfig.propTypes = {
  config: PropTypes.object,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  locales: localeType,
  resetEnforcedLanguage: PropTypes.func,
  resetSiteConfig: PropTypes.func,
  study: PropTypes.object,
  toggleSiteLanguage: PropTypes.func,
  updateSiteConfig: PropTypes.func,
}

export default EnforcedSiteLanguagesConfig
