const KEYS = {
  users: ['username', 'nickname'],
  participants: ['fname', 'lname', 'id'],
  instruments: ['title'],
}

const replaceLiterals = text => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

export const getRegexFromTerm = term => {
  const searchStr = term
    .trim()
    .replace(' ', '|')
    .replace(/[-[\]{}()*+?.,\\^$#\s]/g, '\\$&')
  return new RegExp(searchStr, 'i')
}

/**
 * This stricter regex doesn't allow for strings to match where they share words.
 * E.g., if a list had "Domain 1" and "Domain 2", and my search term was "Domain 1",
 * only "Domain 1" will match, the getRegexFromTerm makes "Domain 1" and
 * "Domain 2" matches.
 */
export const getStrictRegexFromTerm = term => {
  const searchStr = term.trim().replace(/[-[\]{}()*+?.,\\^$#\s]/g, '\\$&')
  return new RegExp(searchStr, 'i')
}

export const searchFilter = (list, searchTerm, type) => {
  const listKeys = KEYS[type]
  const regex = new RegExp(replaceLiterals(searchTerm.trim().replace(' ', '|')), 'i')
  const filteredList = Array.isArray(list) ? [] : {}
  for (const idx in list) {
    const obj = list[idx]
    let matchValue = ''
    listKeys.forEach(key => {
      matchValue += obj[key]
    })
    if (type === 'instruments') {
      const acronym = obj.metadata.acronym || ''
      matchValue += acronym
    }
    if (matchValue.match(regex)) {
      if (type !== 'instruments') {
        filteredList.push(list[idx])
      } else {
        filteredList[idx] = list[idx]
      }
    }
  }
  return filteredList
}
