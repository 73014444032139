const SET_NAV_PROPS = 'SET_NAV_PROPS'
const SET_SUBROUTE = 'SET_SUBROUTE'

export const setNavBarProps = props => {
  return {
    type: SET_NAV_PROPS,
    props,
  }
}

export const setSubroute = subroute => {
  return {
    type: SET_SUBROUTE,
    subroute,
  }
}

const navProps = (state = null, action) => {
  if (action.type === SET_NAV_PROPS) {
    return action.props
  } 
    return state
  
}

const subroute = (state = '', action) => {
  if (action.type === SET_SUBROUTE) {
    return action.subroute
  } 
    return state
  
}

export default { navProps, subroute }
