import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button } from 'components/UIElements'
import D3Logo from 'assets/DCH-Logo.svg'
import { onOpenCancelModal } from '../utils/pariticipantUtils'

import '../stylesheets/createparticipantnav.scss'

const CreateParticipantNav = props => {
  const {
    studyID,
    isEmailless,
    setNavBarProps,
    location: { pathname },
    disabled,
  } = props
  return (
    <div className='create-participant-nav flexed-header'>
      <div className='flexed-header'>
        <div className='d3-logo-container'>
          <img className='d3-logo' src={D3Logo} alt='d3-logo' />
        </div>
        <Button
          className='default'
          selected={pathname.includes('/participants/create')}
          disabled={disabled}
          link
          content={
            <span>
              1. Participant Details
              {isEmailless && <i className='fas fa-arrow-right' />}
            </span>
          }
        />
        {isEmailless && (
          <Button
            className='default'
            selected={pathname.includes('/participants/account-settings')}
            disabled={disabled}
            link
            content={<span>2. Account Settings</span>}
          />
        )}
      </div>
      <Button
        grey
        content={pathname.includes('/participants/account-settings') ? 'Close' : 'Cancel & Close'}
        onClick={() => {
          setNavBarProps(null)
          if (pathname.includes('/participants/account-settings'))
            browserHistory.push(`/studies/${studyID}/participants`)
          else onOpenCancelModal(props)
        }}
      />
    </div>
  )
}

CreateParticipantNav.propTypes = {
  isEmailless: PropTypes.bool,
  studyID: PropTypes.number,
  setNavBarProps: PropTypes.func,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  disabled: PropTypes.bool,
}

export default CreateParticipantNav
