import React from 'react'
import { connect } from 'react-redux'
import { modalActions } from 'store/modal'
import { getUserRoleIdByStudy, getUserScope } from 'utils/misc'
import { insightsPermissionsActions } from '../modules/EditInsights'
import EditInsights from '../components/EditInsights'

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps
  const { loading, study, studyLock, user } = state
  const { currentStudy } = study
  const studyID = Number(params.studyID)
  const currentRoleId = studyID ? getUserRoleIdByStudy(user, studyID) : 7
  const userScope = new Set(getUserScope(user, studyID))
  const hasWpi = userScope.has('wpi')
  const hasRpi = userScope.has('rpi')

  return {
    studyLock,
    currentStudy: study.currentStudy,
    loading: loading.general,
    studyID,
    insights: currentStudy.config?.insights,
    currentRoleId,
    readOnly: hasRpi && !hasWpi,
  }
}

const mapDispatchToProps = { ...modalActions, ...insightsPermissionsActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return <EditInsights {...props} />
})
