import React, { useEffect, useState } from 'react'
import { Popup, Button } from 'components/UIElements'
import { STUDY_FEATURE_FILTER_OPTIONS } from 'utils/constants'
import { pluralize } from 'utils/misc'
import STRINGS from 'utils/strings'
import FeatureFilterOption from './FeatureFilterOption'

const StudyFeatureFilter = ({ selectedFilterFeatures, setSelectedFilterFeatures, clearSelectedFilterFeatures }) => {
  const [options, setOptions] = useState([])

  const getFilterName = () => {
    if (selectedFilterFeatures.length) {
      return pluralize(selectedFilterFeatures.length, STRINGS.filterApplied, STRINGS.filtersApplied)
    }
    return STRINGS.filterBy
  }

  useEffect(() => {
    if (Array.isArray(selectedFilterFeatures)) {
      setOptions(selectedFilterFeatures)
    }
  }, [selectedFilterFeatures])

  const addOptions = feature => {
    if (options.find(option => option.value === feature.value)) {
      setOptions(options.filter(option => option.value !== feature.value))
    } else {
      setOptions([...options, feature])
    }
  }

  const handleApplyFeatures = () => {
    setSelectedFilterFeatures(options)
  }

  return (
    <div className='filter-box'>
      <Popup
        align='right'
        className='d3-filter-box'
        trigger={
          <div className={`feature-filter-trigger ${selectedFilterFeatures.length ? 'active' : ''}`}>
            <i className='fas fa-filter' />
            <div className='feature-filter-name'>{getFilterName()}</div>
          </div>
        }>
        <div className='flexed space-between'>
          <span className='label-small'>{STRINGS.filter}</span>
        </div>
        <div className='flexed space-between feature-filter-title-wrapper'>
          <p className='feature-filter-title'>{STRINGS.studyCapitalized}</p>
          {options.length ? (
            <div
              className='cancel-btn'
              onClick={() => {
                clearSelectedFilterFeatures()
                setOptions([])
              }}>
              <i className='fas fa-times' />
              {STRINGS.clearAll}
            </div>
          ) : null}
        </div>
        <div>
          <div style={{ width: 336 }} className='checkbox-list'>
            {STUDY_FEATURE_FILTER_OPTIONS.map(option => (
              <FeatureFilterOption
                key={option.key}
                option={option}
                selectedOptions={options}
                selectOption={addOptions}
              />
            ))}
          </div>
        </div>
        <div className='flexed end-justified'>
          <Button className='close-trigger' link content={STRINGS.cancel} />
          <Button grey className='close-trigger' onClick={handleApplyFeatures} content={STRINGS.apply} />
        </div>
      </Popup>
    </div>
  )
}

export default StudyFeatureFilter
