import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { setPageTitle } from 'utils/misc'
import TaskEditView from '../SelectTask/containers/SelectTaskContainer'
import { actions } from '../SelectTask/modules/SelectTask'
import { instrumentActions } from '../../modules/Instrument'
// Sync route definition
export default store => ({
  path: ':instrumentID/edittask',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */

        const { studyID, instrumentID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Edit Task | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(instrumentActions.onFetchInstrument(studyID, instrumentID))
        store.dispatch(actions.fetchTasks())
        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['wa'], TaskEditView, false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      '/instruments/edittask/:id',
    )
  },
})
