import React from 'react'
import { useOffsets } from 'utils/misc'
import CasesTable from './CasesTable'

const CasesPage = props => {
  const offsets = useOffsets()

  return (
    <div className='cases page'>
      <CasesTable {...props} offsets={offsets ? [offsets[0]] : 0} />
    </div>
  )
}

export default CasesPage
