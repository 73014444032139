import React from 'react'
import PropTypes from 'prop-types'
import './NavBar.scss'
import MainNavBar from './MainNavBar'
import AltNavBar from './AltNavBar'

const NavBar = props => {
  const { location, user, view, loadingStudy } = props
  const { token } = location.query
  const newToken = token || user.token
  if (newToken !== user.token) return null
  return (
    <div className={`navbar-wrapper${view ? '' : ' full'} ${loadingStudy ? 'hide' : ''}`}>
      <div className='navbar-placeholder' />
      {props.navProps ? <AltNavBar {...props} /> : <MainNavBar {...props} />}
    </div>
  )
}

export default NavBar

NavBar.propTypes = {
  study: PropTypes.object,
  studies: PropTypes.array,
  location: PropTypes.object,
  modalProps: PropTypes.object,
  user: PropTypes.object,
  onLogout: PropTypes.func,
  view: PropTypes.bool,
}
