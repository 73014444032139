import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/UIElements'

const ValidationPanel = ({
  disabledRequired,
  hasCharLimit,
  isMultiField,
  onToggleInputValidation,
  onToggleRequiredQuestion,
  required,
}) => {
  return (
    <div className='validation panel flexed'>
      <Checkbox
        toggle
        id='required-toggle'
        disabled={disabledRequired}
        label='Required'
        checked={required}
        onClick={onToggleRequiredQuestion}
      />
      {/* {isMultiField && (
          <Checkbox
            toggle
            label='Participant input field validation'
            checked={hasCharLimit}
            onClick={onToggleInputValidation}
          />
        )} */}
    </div>
  )
}

ValidationPanel.propTypes = {
  disabledRequired: PropTypes.bool,
  required: PropTypes.bool,
  hasCharLimit: PropTypes.bool,
  isMultiField: PropTypes.bool,
  isAudio: PropTypes.bool,
  isIntro: PropTypes.bool,
  onToggleRequiredQuestion: PropTypes.func,
  onToggleInputValidation: PropTypes.func,
}

export default ValidationPanel
