import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { resetInstrument } from '../../modules/Instrument'
import { surveyActions } from '../EditSurvey/modules/Survey'
import CreateSurveyView from '../EditSurvey/containers/SurveyContainer'
import { fetchCohorts } from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'

// Sync route definition
export default store => ({
  path: 'createinstrument',
  exact: true,
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        /*  Webpack - use require callback to define
       dependencies for bundling   */
        const { currentStudy } = store.getState().study
        setPageTitle(`Create Participant Instrument | Study ${params.studyID} | ${currentStudy.platform_name}`)
        const title = store.getState().instruments ? store.getState().instruments.wizardTitle : ''
        const displayName = store.getState().instruments ? store.getState().instruments.wizardDisplayName : ''
        store.dispatch(surveyActions.initializeBlankSurvey(title, displayName))
        store.dispatch(resetInstrument('SURVEY'))
        store.dispatch(fetchCohorts(nextState.params.studyID))

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['wa'], CreateSurveyView, false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'createinstrument',
    )
  },
})
