import React from 'react'
import { Radio, Checkbox, Popup } from 'components/UIElements'
import { INPUT_VALIDATION_TYPE_MAP } from 'utils/constants'
import STRINGS from '../../../../../../../../../../../../utils/strings'

const AllDatesRange = ({
  id,
  onClick,
  selected,
  isDateTime,
  from,
  itemWithDefaults,
  updateInput,
  disabled,
  toggleDisabled,
}) => {
  return (
    <Radio
      className='has-future-toggle'
      id={id}
      onClick={onClick}
      selected={selected}
      disabled={disabled}
      content={
        from ? (
          <div className='option-wrapper'>
            <span className={disabled ? 'disabled' : ''}>
              {isDateTime ? STRINGS.allPastDatesTime : STRINGS.allPastDates}
            </span>
          </div>
        ) : (
          <div className='option-wrapper column'>
            <div className='option-inner'>
              <span className={disabled ? 'disabled' : ''}>
                {isDateTime ? STRINGS.allFutureDatesTime : STRINGS.allFutureDates}
              </span>
            </div>
            {selected ? (
              <div className='future-dates-toggle'>
                <Checkbox
                  toggle
                  disabled={toggleDisabled}
                  checked={!!itemWithDefaults.input_validation.accept_future_values}
                  onClick={() => {
                    updateInput(
                      INPUT_VALIDATION_TYPE_MAP.acceptFutureValues,
                      !itemWithDefaults.input_validation.accept_future_values,
                      !itemWithDefaults.input_validation.accept_future_values,
                    )
                  }}
                />
                <span className={disabled ? 'disabled' : ''}>
                  {isDateTime ? STRINGS.acceptFutureDatesTime : STRINGS.acceptFutureDates}
                </span>
                <Popup
                  className='future-dates-popup'
                  align='left'
                  position='bottom'
                  hover
                  noPointer
                  dark
                  trigger={<i className='fas fa-info-circle' />}>
                  <p>{isDateTime ? STRINGS.acceptFutureDatesTimeTooltip : STRINGS.acceptFutureDatesTooltip}</p>
                </Popup>
              </div>
            ) : null}
          </div>
        )
      }
    />
  )
}

export default AllDatesRange
