import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { closeIcon } from 'assets/assets'

const AutocompleteTags = ({ tags, toggleItem, inputEl, inputRef }) => {
  const onToggleItem = id => {
    toggleItem(id)
    inputRef.focus()
  }
  const tagList = tags.map(tag => {
    const { id, text } = tag
    return (
      <div key={`${id}-${text}`} className='flexed center-aligned'>
        <span>{text}</span>
        <Button className='tag-x' content={closeIcon()} noStyling onClick={() => onToggleItem(id)} />
      </div>
    )
  })
  tagList.push(inputEl)
  return <div className='pre-input-content autocomplete-tags'>{tagList}</div>
}

AutocompleteTags.propTypes = {
  inputEl: PropTypes.element,
  tags: PropTypes.arrayOf(PropTypes.object),
  toggleItem: PropTypes.func,
  inputRef: PropTypes.shape({
    focus: PropTypes.func,
  }),
}

export default AutocompleteTags
