import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { DocumentList, Loader, PdfViewer, SearchInput, VideoPlayer } from 'components/UIElements'
import { getStrictRegexFromTerm } from 'utils/search'
import STRINGS from 'utils/strings'

const SEARCHABLE_KEYS = ['name']

const TrainingPage = props => {
  const { loading, trainingMaterial = {}, canWrite, resetTraining, studyId } = props
  const { training_documents: documents = [], training_videos: videos = [] } = trainingMaterial

  useEffect(() => {
    return resetTraining
  }, [])

  const getFilteredList = list => {
    const { searchTerm } = props
    const searchKeys = SEARCHABLE_KEYS
    if (!searchTerm) return list
    const regex = getStrictRegexFromTerm(searchTerm)
    return list.filter(item => {
      for (let i = 0; i < searchKeys.length; i++) {
        const key = searchKeys[i]
        const value = item[key]
        if (value.match(regex)) {
          return true
        }
      }
      return false
    })
  }

  const onDeleteTrainingMaterial = ({ id }) => {
    const { deleteTrainingMaterial } = props
    deleteTrainingMaterial({ id, studyId })
  }

  const onDownloadTrainingMaterial = ({ url, metadata }) => {
    if (metadata?.is_external) window.open(url)
    else window.location.href = url
  }

  const exitPip = () => {
    if (document.pictureInPictureElement) document.exitPictureInPicture()
  }

  const onOpenVideo = ({ name, url, type, metadata }) => {
    const { openOverlay, minimizeOverlay } = props
    const nameProps = {
      type,
      name,
    }
    exitPip()
    openOverlay({
      content: <VideoPlayer src={url} onEnterPip={minimizeOverlay} />,
      nameProps,
      onDownload: () => {
        onDownloadTrainingMaterial({ url, metadata })
      },
      onMaximize: exitPip,
    })
  }
  const onOpenPdf = ({ name, url, type, metadata }) => {
    if (metadata?.is_external) {
      window.open(url)
    } else {
      const { openOverlay } = props
      const nameProps = {
        type,
        name,
      }
      exitPip()
      openOverlay({
        content: <PdfViewer url={url} />,
        nameProps,
        onDownload: () => {
          onDownloadTrainingMaterial({ url })
        },
        onMaximize: exitPip,
      })
    }
  }

  return (
    <div className='training page'>
      <div className='flexed-header end-justified'>
        <SearchInput placeholder={STRINGS.searchEllipsis} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h4 className='bold'>{STRINGS.trainingDocuments}</h4>
          <DocumentList
            list={getFilteredList(documents)}
            canWrite={canWrite}
            addLink={`/studies/${studyId}/training/upload/document`}
            onDelete={onDeleteTrainingMaterial}
            onDownload={onDownloadTrainingMaterial}
            onOpen={onOpenPdf}
            noList={!documents.length}
            emptyText={STRINGS.noTrainingDocument}
          />
          <h4 className='bold'>{STRINGS.trainingVideos}</h4>
          <DocumentList
            list={getFilteredList(videos)}
            canWrite={canWrite}
            addLink={`/studies/${studyId}/training/upload/video`}
            onDelete={onDeleteTrainingMaterial}
            onDownload={onDownloadTrainingMaterial}
            onOpen={onOpenVideo}
            noList={!videos.length}
            emptyText={STRINGS.noTrainingVideo}
          />
        </>
      )}
    </div>
  )
}

TrainingPage.propTypes = {
  canWrite: PropTypes.bool,
  deleteTrainingMaterial: PropTypes.func,
  loading: PropTypes.bool,
  minimizeOverlay: PropTypes.func,
  openOverlay: PropTypes.func,
  resetTraining: PropTypes.func,
  searchTerm: PropTypes.string,
  studyId: PropTypes.number,
  trainingMaterial: PropTypes.shape({ training_documents: PropTypes.array, training_videos: PropTypes.array }),
}

export default TrainingPage
