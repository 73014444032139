import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import STRINGS from 'utils/strings'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { getSigneeRequiredCount } from 'utils/instrumentValidation'
import Signee from './ConsentSignee'
import '../../stylesheets/NewConsentEditor.scss'

const SortableSignee = SortableElement(props => {
  return (
    <li>
      <Signee {...props} />
    </li>
  )
})

const SigneeList = SortableContainer(props => {
  const { item } = props
  const { attributes } = item || {}
  const { signees, signeeOrder } = attributes || {}
  return (
    <ol>
      {signeeOrder?.map((signeeId, index) => {
        const signee = signees[signeeId]
        return (
          <SortableSignee
            key={signeeId}
            index={index}
            {...props}
            signee={signee}
            signeeId={signeeId}
            canDelete={signeeOrder.length > 1}
          />
        )
      })}
    </ol>
  )
})

const Signature = props => {
  const { addSignee, moveSignee, isActive, item, itemId, signeeReqErr } = props
  const showSigneeRequiredErr = getSigneeRequiredCount(item.attributes.signees) < 1 && signeeReqErr

  // If the signature time does not have signees in the we need to add a signee to the item.
  useEffect(() => {
    const { attributes } = item
    const { signees } = attributes
    if (!signees) addSignee(itemId)
  }, [])

  const onMoveSignee = ({ oldIndex, newIndex }) => {
    moveSignee({ itemId, oldIndex, newIndex })
  }
  const shouldCancelStart = e => {
    return !isActive || !e.target.className.includes('signee-grab-icon')
  }

  return (
    <div className='signature'>
      {showSigneeRequiredErr && <p className='error-text'>{signeeReqErr}</p>}
      <ol>
        <li className='signature-steps'>
          <p className='signee-instructions'>{STRINGS.signatureItemDescription}</p>
          <SigneeList
            {...props}
            helperClass='sortableHelper sortable-signees'
            lockAxis='y'
            lockToContainerEdges
            onSortEnd={onMoveSignee}
            shouldCancelStart={shouldCancelStart}
            signeeReqErr={showSigneeRequiredErr}
          />
          <Button
            className='add-signature-button'
            link
            id='add-signature'
            icon='fas fa-plus'
            onClick={() => {
              addSignee(itemId)
            }}>
            Add signature
          </Button>
        </li>
      </ol>
    </div>
  )
}

Signature.propTypes = {
  addSignee: PropTypes.func,
  isActive: PropTypes.bool,
  item: PropTypes.shape({
    attributes: PropTypes.shape({
      signees: PropTypes.object,
      signeeOrder: PropTypes.array,
    }),
  }),
  itemId: PropTypes.string,
  moveSignee: PropTypes.func,
  signeeReqErr: PropTypes.string,
  invalidSigneesError: PropTypes.string,
}

export default Signature
