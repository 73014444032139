import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { Container, SortableTable, Loader, Button, Popup } from 'components/UIElements'
import { STATUS_OPTIONS } from 'utils/constants'
import handleComponentStateLoading from 'utils/handleComponentStateLoading'
import PropTypes from 'prop-types'
import STRINGS from 'utils/strings'
import '../stylesheets/participant.scss'
import UpdateStatusSection from '../../../../VisitSchedules/routes/VisitSchedulesPage/components/UpdateStatusSection'

const headerOptions = [
  { key: 'visitName', sortable: true, component: 'Visit Name' },
  { key: 'scheduledDate', sortable: true, component: 'Scheduled Date' },
  { key: 'actualDate', sortable: true, component: 'Actual Date' },
  { key: 'status', sortable: true, component: 'Status' },
  { key: 'action', component: 'Action', className: 'text-align-center' },
]

class ParticipantVisits extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUpdateStatusRowExpanded: {},
    }
  }

  componentDidMount() {
    this.onFetchPtpVisits()
  }

  onFetchPtpVisits = () => {
    const { fetchParticipantVisits, ptpId, studyID } = this.props
    if (ptpId)
      handleComponentStateLoading.call(
        this,
        fetchParticipantVisits.bind(this, studyID, ptpId),
        'participantVisitsLoading',
      )
  }

  renderActionsCell = ({ idx, row, className, isUpdateAllowed }) => {
    const { canViewVisitSchedule, disabled, isSiteCoord, ptpId, studyID } = this.props
    const { visitId } = row[0]
    const status = row.filter(rowData => rowData.key === 'status').map(ptpIDObj => ptpIDObj.value)?.[0]

    const actionable = status !== STATUS_OPTIONS.Confirmed || isUpdateAllowed

    return isSiteCoord ? (
      <td key={`col_${idx}`} className={className}>
        {canViewVisitSchedule && actionable ? (
          <div className='flexed center-justified'>
            <Popup
              dark
              noPointer={false}
              position='bottom'
              className='edit'
              disabled={disabled}
              trigger={<Button link icon='fas fa-pencil-alt' />}>
              <>
                {status !== STATUS_OPTIONS.Confirmed ? (
                  <Button
                    content={STRINGS.visitScheduleReschedule}
                    onClick={() => {
                      browserHistory.push(
                        `/studies/${studyID}/visit-schedules/participant-visit/${ptpId}-${visitId}/edit`,
                      )
                    }}
                  />
                ) : null}
                {isUpdateAllowed ? (
                  <Button
                    content={STRINGS.visitScheduleUpdateStatus}
                    onClick={() => {
                      this.setState({
                        isUpdateStatusRowExpanded: {
                          ...this.state.isUpdateStatusRowExpanded,
                          [visitId]: true,
                        },
                      })
                    }}
                  />
                ) : null}
              </>
            </Popup>
          </div>
        ) : null}
      </td>
    ) : null
  }

  renderStatusCell = ({ idx, value, className }) => {
    return (
      <td key={`col_${idx}`} className={className}>
        {value !== STATUS_OPTIONS.Passed ? <div className={`visit-status-cell ${value}`}>{value}</div> : null}
      </td>
    )
  }

  getHeaderOptions = () => {
    const { isSiteCoord } = this.props
    if (!isSiteCoord) {
      return headerOptions.slice(0, -1)
    }
    return headerOptions
  }

  expandedRow = row => {
    const { ptpId, studyID, updatePreviousScheduleVisit } = this.props
    const { visitId } = row[0]
    const scheduledDate = row.filter(el => el.key === 'scheduledDate')[0]
    const isScheduleDateFuture = new Date(scheduledDate.value) > new Date()
    const status = row.filter(rowData => rowData.key === 'status').map(ptpIDObj => ptpIDObj.value)?.[0]
    return (
      <UpdateStatusSection
        status={status}
        updatePreviousScheduleVisit={updatePreviousScheduleVisit}
        onClose={() => {
          this.setState({
            isUpdateStatusRowExpanded: {
              ...this.state.isUpdateStatusRowExpanded,
              [visitId]: false,
            },
          })
        }}
        studyID={studyID}
        ssvId={visitId}
        ptpID={ptpId}
        heading={isScheduleDateFuture ? STRINGS.visitHappenning : STRINGS.didVisitHappen}
        scheduledDate={scheduledDate}
        isScheduleDateFuture={isScheduleDateFuture}
      />
    )
  }

  render() {
    const { participantVisits } = this.props
    const { participantVisitsLoading, isUpdateStatusRowExpanded } = this.state
    const renderFunctions = {
      action: this.renderActionsCell,
      status: this.renderStatusCell,
    }
    return (
      <Container centered flex column>
        <div className='title'>Visits</div>
        <div>
          {participantVisitsLoading ? (
            <Loader inContainer size={25} />
          ) : (
            <div>
              <SortableTable
                id='participant-visits-table'
                className='participant-visits-table'
                headerOptions={this.getHeaderOptions()}
                rowList={participantVisits}
                renderFunctions={renderFunctions}
                viewMoreWithTablePages={{ minRows: 10, maxRows: 10 }}
                emptyText='No Matching Participant Visits'
                sortingBy='scheduledDate'
                initialOrder='ascending'
                rowExpandOptions={{
                  isRowExpanded: row => {
                    const { visitId } = row[0]
                    return isUpdateStatusRowExpanded[visitId]
                  },
                  expandedRowClassName: 'expanded',
                }}
                expandedRow={this.expandedRow}
              />
            </div>
          )}
        </div>
      </Container>
    )
  }
}

ParticipantVisits.propTypes = {
  canViewVisitSchedule: PropTypes.bool,
  disabled: PropTypes.bool,
  fetchParticipantVisits: PropTypes.func,
  isSiteCoord: PropTypes.bool,
  participantVisits: PropTypes.arrayOf(PropTypes.array),
  ptpId: PropTypes.number,
  studyID: PropTypes.number,
}

export default ParticipantVisits
