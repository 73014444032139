import React from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-number-input'
import SmartInput from 'react-phone-number-input/smart-input'
import flags from 'react-phone-number-input/flags'
import 'react-phone-number-input/style.css'
import 'styles/d3/phonenumberinput.scss'

const PhoneNumberInput = props => {
  const {
    className,
    disabled = false,
    errorText,
    hasError,
    onChange,
    onCountryChange,
    placeholder = '',
    tabIndex,
    value,
    id,
  } = props

  const _className = `${className ? ` ${className}` : ''}${hasError ? ' has-error' : ''}${disabled ? ' disabled' : ''}`

  const defaultCountry = navigator.language.split('-')[1]

  return (
    <div className={`phone-number-input${_className}`}>
      <PhoneInput
        disabled={disabled}
        displayInitialValueAsLocalNumber
        international={false}
        tabIndex={tabIndex || 0}
        inputComponent={SmartInput}
        countryOptions={['US', 'IT', 'JP']}
        onChange={onChange}
        limitMaxLength
        value={value}
        flags={flags}
        country={defaultCountry || 'US'}
        onCountryChange={onCountryChange}
        placeholder={placeholder}
        id={id}
      />
      {hasError && errorText && <p className='error-text'>{errorText}</p>}
    </div>
  )
}

PhoneNumberInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  onCountryChange: PropTypes.func,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  id: PropTypes.string,
}

export default PhoneNumberInput
