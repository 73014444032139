import React from 'react'
import PropTypes from 'prop-types'
import { RichButton } from 'components/UIElements'
import { STUDY_CONFIG_MAP, REWARD_TYPE_MAP } from 'utils/constants'
import { changeRewardType } from '../utils/studyUtils'

const BinaryConfig = props => {
  const {
    isRewardType = false,
    bodyText,
    buttonIcons = {},
    buttonLabels = {},
    configKey,
    defaultIsFalse,
    disabled,
    heading,
    subheading,
    study,
    updateStudyConfig,
    deleteConfigKey,
  } = props
  const { left, right } = buttonLabels
  const { leftIcon, rightIcon } = buttonIcons
  const config = study.config || {}

  return (
    <div className='binary-config'>
      <h5 className='heading'>{heading}</h5>
      <div className='flexed start-aligned'>
        <div>
          <p className='sub-heading'>{subheading}</p>
          <p className='body-text'>{bodyText}</p>
        </div>
        <div className='flexed start-aligned'>
          <RichButton
            disabled={disabled}
            graphicElement={leftIcon}
            isRadio
            label={left}
            className={isRewardType ? 'square-shape' : ''}
            additionalLabel={isRewardType ? ' Recommended' : ''}
            selected={defaultIsFalse ? !!config[configKey] : !config[configKey]}
            onClick={() => {
              if (isRewardType) {
                updateStudyConfig({
                  [configKey]: {},
                  cycles: changeRewardType(config.cycles, REWARD_TYPE_MAP.superGems),
                })
              } else {
                updateStudyConfig({ [configKey]: defaultIsFalse })
              }
            }}
          />
          <RichButton
            disabled={disabled}
            graphicElement={rightIcon}
            isRadio
            label={right}
            className={isRewardType ? 'square-shape' : ''}
            selected={defaultIsFalse ? !config[configKey] : !!config[configKey]}
            onClick={() => {
              if (isRewardType) {
                deleteConfigKey(configKey)
                if (!config[configKey] && config[STUDY_CONFIG_MAP.treasureChestStoreGiftCard]) {
                  deleteConfigKey(STUDY_CONFIG_MAP.treasureChestStoreGiftCard)
                }
                updateStudyConfig({
                  cycles: changeRewardType(config.cycles, REWARD_TYPE_MAP.gems),
                })
              } else {
                updateStudyConfig({ [configKey]: !defaultIsFalse })
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

BinaryConfig.propTypes = {
  isRewardType: PropTypes.bool,
  bodyText: PropTypes.string,
  buttonLabels: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  buttonIcons: PropTypes.shape({
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
  }),
  configKey: PropTypes.string,
  defaultIsFalse: PropTypes.bool,
  disabled: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  study: PropTypes.shape({
    config: PropTypes.shape({ data_sync_ui_enabled: PropTypes.bool }),
  }),
  updateStudyConfig: PropTypes.func,
  deleteConfigKey: PropTypes.func,
}

export default BinaryConfig
