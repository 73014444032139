import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'components/UIElements'
import STRINGS from 'utils/strings'
import useDidMountEffect from 'utils/hooks/didMountEffect'
import DurationInput from '../DurationInput'
import {
  MINS_MULTIPLIER_MAP,
  UNIFICATION_REPEAT_TIME_UNIT_OPTION,
  getInitialValues,
} from '../../constants/schedulerConstants'

const SaveReviewContent = props => {
  const { disabled, updateTimeoutWindow, schedule, updateTimeoutWindowField } = props

  const { timeout_window } = schedule
  const { draft } = timeout_window || {}
  const { value: draftValue } = draft || {}

  const { key, val } = getInitialValues(draftValue, 1)
  const [timeValue, setTimeValue] = useState(val)
  const [timeUnit, setTimeUnit] = useState(key)

  useDidMountEffect(() => {
    if (draft) {
      const minsMultiplier = MINS_MULTIPLIER_MAP[timeUnit]
      const newTimeVal = minsMultiplier * timeValue
      onSaveResume(newTimeVal)
    }
  }, [timeValue, timeUnit])

  const onNoSaveReview = () => {
    setTimeValue(1)
    setTimeUnit('n-minutes')
    const prevTimeout = { ...timeout_window }
    delete prevTimeout.draft
    updateTimeoutWindow(prevTimeout)
  }

  const onSaveResume = value => {
    updateTimeoutWindowField('draft', { value }, 'schedule.timeout_window.draft')
  }

  const onBlur = e => {
    if (e.target.value === '') {
      setTimeValue(1)
    }
  }

  const onTimeUnitDropdownSelect = item => {
    setTimeUnit(item.key)
  }

  return (
    <div className={`save-review-menu${disabled ? ' disabled' : ''}`}>
      <p className='subtitle'>{STRINGS.saveResumeSubtitle}</p>
      <p>
        <span>{STRINGS.saveResumeQuestion}</span>
      </p>
      <div>
        <Radio
          id='save-review'
          selected={!draft}
          onClick={() => {
            onNoSaveReview()
          }}
          disabled={disabled}
          content={STRINGS.saveResumeOption1}
        />
        <Radio
          id='no-save-review'
          selected={!!draft}
          onClick={() => {
            onSaveResume(1)
          }}
          disabled={disabled}
          content={
            <DurationInput
              disabled={disabled}
              isSelected={!!draft}
              onBlur={onBlur}
              onChange={val => {
                setTimeValue(val)
              }}
              inputValue={String(timeValue)}
              unitValue={timeUnit}
              onDropdownSelect={onTimeUnitDropdownSelect}
              preText={STRINGS.saveResumeOption2}
              min={1}
              newTimeOpts={UNIFICATION_REPEAT_TIME_UNIT_OPTION}
              useSimpleInput={true}
            />
          }
        />
      </div>
    </div>
  )
}

SaveReviewContent.propTypes = {
  disabled: PropTypes.bool,
  deleteMetadataKey: PropTypes.func,
  schedule: PropTypes.object,
  updateScheduleKey: PropTypes.func,
}

export default SaveReviewContent
