import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import useQueryString from 'utils/hooks/useQueryString'
import { useOffsets } from 'utils/misc'
import VisitSchedulesUpcoming from './VisitSchedulesUpcoming'
import VisitSchedulesPrevious from './VisitSchedulesPrevious'
import '../stylesheets/VisitSchedules.scss'
import VisitTemplates from './VisitTemplates'

const VISITS_TABS = {
  upcoming: {
    content: 'Upcoming Visits',
    query: 'upcoming',
  },
  previous: {
    content: 'Previous Visits',
    query: 'previous',
  },
  templates: {
    content: 'Visit Templates',
    query: 'templates',
  },
}

const VisitsSchedulePage = props => {
  const {
    canEditVisits,
    loading,
    studyID,
    visitSchedules,
    fetchScheduleVisitsUpcoming,
    fetchScheduleVisitsPrevious,
    fetchVisitSchedules,
    studyLock,
    isUpcomingTabVisible,
    isPreviousTabVisible,
    searchTerm,
    isSiteCoord,
    location,
  } = props
  const { visitSchedulesPreviousList = [] } = visitSchedules
  const [visitSchedulesLoading, setVisitSchedulesLoading] = useState(false)
  const defaultQueryTab = isUpcomingTabVisible ? VISITS_TABS.upcoming.query : VISITS_TABS.templates.query
  const queryTabs = [
    ...new Set([
      defaultQueryTab,
      VISITS_TABS.previous.query,
      VISITS_TABS.templates.query /* VISITS_TABS.history.query*/,
    ]),
  ]
  const {
    query: { tab = defaultQueryTab },
    querySet,
  } = useQueryString({
    location,
    tabKeys: {
      tab: queryTabs,
    },
    initValues: {
      tab: defaultQueryTab,
    },
  })
  const offsets = useOffsets('sticky', tab)

  useEffect(() => {
    let onChangeTab = null
    switch (tab) {
      case VISITS_TABS.upcoming.query:
        onChangeTab = () => fetchScheduleVisitsUpcoming(studyID)
        break
      case VISITS_TABS.previous.query:
        onChangeTab = () => fetchScheduleVisitsPrevious(studyID)
        break
      case VISITS_TABS.templates.query:
        onChangeTab = () => fetchVisitSchedules(studyID)
        break
      default:
        break
    }

    if (typeof onChangeTab !== 'function') return
    setVisitSchedulesLoading(true)
    onChangeTab().finally(() => setVisitSchedulesLoading(false))
  }, [tab])

  const passedVisitsCount = visitSchedulesPreviousList.filter(
    item => item.filter(rowData => rowData.key === 'status').map(obj => obj.value)?.[0] === 'confirm-occurence',
  ).length

  return (
    <div className='visits page'>
      <div className='selected-button-list'>
        {isUpcomingTabVisible && (
          <Button
            id='visits-tab'
            selected={tab === VISITS_TABS.upcoming.query}
            content={VISITS_TABS.upcoming.content}
            onClick={() => {
              querySet({ tab: VISITS_TABS.upcoming.query })
            }}
          />
        )}
        {isPreviousTabVisible && (
          <span className='previous-visits-tab'>
            <Button
              id='previous-visits-tab'
              selected={tab === VISITS_TABS.previous.query}
              className='flexed inline'
              content={
                <>
                  {VISITS_TABS.previous.content}
                  {passedVisitsCount ? <span className='previous-visits-tab-count'>{passedVisitsCount}</span> : null}
                </>
              }
              onClick={() => {
                querySet({ tab: VISITS_TABS.previous.query })
              }}
            />
          </span>
        )}
        <Button
          id='templates-tab'
          selected={tab === VISITS_TABS.templates.query}
          content={VISITS_TABS.templates.content}
          onClick={() => {
            querySet({ tab: VISITS_TABS.templates.query })
          }}
        />
      </div>
      {tab === VISITS_TABS.upcoming.query && (
        <VisitSchedulesUpcoming
          {...props}
          offset={offsets ? offsets[0] : 0}
          studyID={studyID}
          isSiteCoord={isSiteCoord}
          visitSchedulesLoading={visitSchedulesLoading}
          visitSchedules={visitSchedules}
        />
      )}
      {tab === VISITS_TABS.previous.query && (
        <VisitSchedulesPrevious
          {...props}
          offset={offsets ? offsets[0] : 0}
          studyID={studyID}
          isSiteCoord={isSiteCoord}
          visitSchedulesLoading={visitSchedulesLoading}
          visitSchedules={visitSchedules}
        />
      )}
      {tab === VISITS_TABS.templates.query && (
        <VisitTemplates
          {...props}
          location={location}
          offset={offsets ? offsets[1] : 0}
          canEditVisits={canEditVisits}
          loading={loading}
          studyID={studyID}
          studyLock={studyLock}
          selectedTab={tab}
          searchTerm={searchTerm}
          isSiteCoord={isSiteCoord}
          visitSchedulesLoading={visitSchedulesLoading}
          visitSchedules={visitSchedules}
        />
      )}
    </div>
  )
}

VisitsSchedulePage.propTypes = {
  canEditVisits: PropTypes.bool,
  isUpcomingTabVisible: PropTypes.bool,
  isPreviousTabVisible: PropTypes.bool,
  loading: PropTypes.shape({
    visitsHistory: PropTypes.bool,
  }),
  siteID: PropTypes.number,
  studyID: PropTypes.string,
  searchTerm: PropTypes.string,
  fetchScheduleVisitsUpcoming: PropTypes.func,
  fetchScheduleVisitsPrevious: PropTypes.func,
  fetchVisitSchedules: PropTypes.func,
  location: PropTypes.object,
  studyLock: PropTypes.bool,
  isSiteCoord: PropTypes.bool,
}

export default VisitsSchedulePage
