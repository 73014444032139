import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Input } from 'components/UIElements'
import { SECURITY_KEY_MAP as CONSTANTS } from 'utils/constants'
import { pluralize, isObject } from 'utils/misc'
import { exclamationIcon } from 'assets/assets'

const minMaxValidate = (min, max) => v => v < min || v > max

const validators = {
  attempt_num: { v: minMaxValidate(5, 10), default: 6 },
  lockout_time: { v: minMaxValidate(1, 600), default: 30 },
  expiration: { v: minMaxValidate(30, 365), default: 90 },
  reuse_history: { v: minMaxValidate(1, 15), default: 6 },
  session_timeout: { v: () => {} },
}

const NumberOfLoginAttemptsInput = ({ attempt, lockout }) => {
  const { hasError: hasAttemptError } = attempt
  const { hasError: hasLockoutError } = lockout

  const hasError = hasAttemptError || hasLockoutError

  return (
    <div className={`security-checkbox-body${hasError ? ' has-error' : ''}`}>
      <span>Allow maximum of</span>
      <Input id='max-attempt-input' placeholder='5-10' {...attempt} />
      <span>{`${pluralize(
        attempt.value,
        'attempt',
        'attempts',
        false,
      )} to log in. After that, lock users from logging in for`}</span>
      <Input id='user-lock-time-input' placeholder='1-600' {...lockout} />
      <span>min.</span>
    </div>
  )
}

const PasswordExpirationInput = ({ onChange, value, hasError, disabled }) => (
  <div className={`security-checkbox-body${hasError ? ' has-error' : ''}`}>
    <span>Password will expire</span>
    <Input
      id='expiration-input'
      placeholder='30-365'
      hasError={hasError}
      disabled={disabled}
      onChange={onChange}
      type='number'
      value={value}
    />
    <span>{`${pluralize(value, 'day', 'days', false)} after creation.`}</span>
  </div>
)

const PasswordReuseInput = ({ onChange, value, hasError, disabled }) => (
  <div className={`security-checkbox-body ${hasError ? ' has-error' : ''}`}>
    <span>Prevent users from reusing any of the previous</span>
    <Input
      id='restrict-reuse-password-input'
      placeholder='1-15'
      hasError={hasError}
      disabled={disabled}
      onChange={onChange}
      type='number'
      value={value}
    />
    <span>passwords.</span>
  </div>
)

const onToggleMenu = (inputType, currentSettings, updateStudySecurityConfig) => {
  const conf = {
    ...currentSettings,
    [inputType]: { ...currentSettings[inputType], isToggled: !currentSettings[inputType]?.isToggled },
  }
  if (inputType === CONSTANTS.attempt_num)
    conf.lockout_time = { ...currentSettings.lockout_time, isToggled: !currentSettings.lockout_time.isToggled }
  updateStudySecurityConfig(conf)
}

const initState = ({ admin_security = {} }, val, saveAttempted) => {
  return Object.keys(val).reduce((acc, v) => {
    if (!val[v]?.default) return acc
    const adminSecurityField = admin_security[v]
    return {
      ...acc,
      [v]:
        typeof adminSecurityField == 'number'
          ? adminSecurityField
          : isObject(adminSecurityField)
          ? {
              ...adminSecurityField,
              showError: adminSecurityField.isToggled && saveAttempted && val[v].v(adminSecurityField.value),
            }
          : { isToggled: !!adminSecurityField, value: adminSecurityField || val[v].default },
    }
  }, {})
}

const ErrorMessage = ({ message, activated }) =>
  activated ? (
    <div className='visit-error flexed start-justified'>
      {exclamationIcon()}
      <div>{message}</div>
    </div>
  ) : null

const updateInputValue = (updateStudy, key, conf) => value => {
  const isSessionTimeout = key === CONSTANTS.session_timeout
  const _value = isSessionTimeout ? Math.round(Number(value)) : Number(value)
  const newVal = isSessionTimeout ? _value : { ...conf[key], value: _value || '' }
  return updateStudy({
    ...conf,
    [key]: newVal,
  })
}

const sessionTimeout = (timeoutInput, updateValue, updateStudySecurityConfig, currentSettings) => {
  const sessionTimeoutConfig = {
    updateValue,
    updateStudySecurityConfig,
    key: CONSTANTS.session_timeout,
    currentSettings,
  }
  return (
    <div className='session-timeout'>
      <div className='divider' />
      <h5>Session Timeout</h5>
      {timeoutInput(sessionTimeoutConfig)}
      <p>
        <span>Please note:</span> Session timeout configuration will affect both the Admin Panel and ClinRO
      </p>
    </div>
  )
}

const studySecurityConfig = props => {
  const { configObj = {}, disabled, updateStudySecurityConfig, saveAttempted, renderTimeoutInput } = props
  const currentSettings = initState(configObj, validators, saveAttempted)
  const { attempt_num = {}, lockout_time = {}, expiration = {}, reuse_history = {} } = currentSettings

  return (
    <div className='security'>
      <p>What kinds of password restrictions would you like to set for the admin users of this study?</p>
      <div className='security-settings'>
        <ErrorMessage message='Password expiry must be between 30 and 365 days' activated={expiration.showError} />
        <Checkbox
          id='expire-passwords'
          checked={isObject(expiration) ? expiration.isToggled : typeof expiration === 'number'}
          disabled={disabled}
          label='Expire passwords'
          onClick={() => onToggleMenu(CONSTANTS.expiration, currentSettings, updateStudySecurityConfig)}
          tabbable
        />
        {(isObject(expiration) ? expiration.isToggled : expiration) && (
          <PasswordExpirationInput
            onChange={updateInputValue(updateStudySecurityConfig, CONSTANTS.expiration, currentSettings)}
            value={isObject(expiration) ? expiration.value : expiration}
            disabled={disabled}
            hasError={expiration.showError}
          />
        )}
        <ErrorMessage
          message={`${attempt_num.showError ? 'Login attempts must be between 5 and 10 attempts' : ''}${
            lockout_time.showError && attempt_num.showError ? '. ' : ''
          }${lockout_time.showError ? 'Lockout time must be between 1 and 600 minutes' : ''}`}
          activated={lockout_time.showError || attempt_num.showError}
        />
        <Checkbox
          id='max-login-attempts'
          checked={isObject(attempt_num) ? attempt_num.isToggled : typeof attempt_num === 'number'}
          disabled={disabled}
          label='Set maximum number of login attempts'
          onClick={() => onToggleMenu(CONSTANTS.attempt_num, currentSettings, updateStudySecurityConfig)}
          tabbable
        />
        {(isObject(attempt_num) ? attempt_num.isToggled : attempt_num) && (
          <NumberOfLoginAttemptsInput
            attempt={{
              onChange: updateInputValue(updateStudySecurityConfig, CONSTANTS.attempt_num, currentSettings),
              value: isObject(attempt_num) ? attempt_num.value : attempt_num,
              disabled,
              hasError: attempt_num.showError,
            }}
            lockout={{
              onChange: updateInputValue(updateStudySecurityConfig, CONSTANTS.lockout_time, currentSettings),
              value: isObject(lockout_time) ? lockout_time.value : lockout_time,
              disabled,
              hasError: lockout_time.showError,
            }}
          />
        )}
        <ErrorMessage message='Number should be between 1 and 15' activated={reuse_history.showError} />
        <Checkbox
          checked={isObject(reuse_history) ? reuse_history.isToggled : typeof reuse_history === 'number'}
          disabled={disabled}
          id='no-password-reuse'
          label='Do not allow users to reuse passwords'
          onClick={() => onToggleMenu(CONSTANTS.reuse_history, currentSettings, updateStudySecurityConfig)}
          tabbable
        />
        {(isObject(reuse_history) ? reuse_history.isToggled : reuse_history) && (
          <PasswordReuseInput
            onChange={updateInputValue(updateStudySecurityConfig, CONSTANTS.reuse_history, currentSettings)}
            value={isObject(reuse_history) ? reuse_history.value : reuse_history}
            disabled={disabled}
            hasError={reuse_history.showError}
          />
        )}
      </div>
      {sessionTimeout(renderTimeoutInput, updateInputValue, updateStudySecurityConfig, currentSettings)}
    </div>
  )
}

const getValues = conf => Object.keys(conf).reduce((acc, k) => ({ ...acc, [k]: conf[k].value || conf[k] }), {})

const filterConfig = (conf = {}) => {
  const _conf = { ...conf }
  const toIgnore = Object.keys(conf).filter(k => k !== CONSTANTS.session_timeout && !conf[k].isToggled)
  toIgnore.forEach(k => delete _conf[k])
  return _conf
}

export const beforeCreate = {
  hasErrors: ({ config: { admin_security } = {} }) => {
    if (!admin_security) {
      return false
    }
    const activeInputs = filterConfig(admin_security)
    return Object.keys(activeInputs)
      .map(k => validators[k].v(activeInputs[k].value))
      .some(v => v)
  },
  cb: ({ config: study, ...rest }) => {
    return { ...rest, config: { ...study, admin_security: getValues(filterConfig(study.admin_security)) } }
  },
}

studySecurityConfig.propTypes = {
  configObj: PropTypes.shape({
    admin_security: PropTypes.object,
  }),
  disabled: PropTypes.bool,
  renderTimeoutInput: PropTypes.func,
  updateStudySecurityConfig: PropTypes.func,
  saveAttempted: PropTypes.bool,
}

export default studySecurityConfig
