import React from 'react'
import { Container, Input } from 'components/UIElements'
import PropTypes from 'prop-types'

const CohortForm = ({ canWriteCohort, updateDescription, description, group_name, updateName }) => {
  const canEdit = canWriteCohort
  return (
    <Container className='cohort-form' centered flex column>
      <Input
        disabled={!canEdit}
        id='track-name'
        placeholder='Track Name'
        onChange={updateName}
        value={group_name}
        label='Track Name'
      />
      <Input
        disabled={!canEdit}
        id='track-description'
        placeholder='Track Description'
        onChange={updateDescription}
        value={description}
        label='Description'
      />
    </Container>
  )
}

CohortForm.propTypes = {
  canWriteCohort: PropTypes.bool,
  canAddPtp: PropTypes.bool,
  description: PropTypes.string,
  group_name: PropTypes.string,
  isCreate: PropTypes.bool,
  updateDescription: PropTypes.func,
  updateName: PropTypes.func,
}

export default CohortForm
