import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'components/UIElements'
import { setNavBarProps } from 'store/navbar'
import { modalActions } from 'store/modal'
import { USER_SCOPES } from 'utils/constants'
import { disableScope, getUserScopeByStudy } from 'utils/misc'
import { announcementActions } from '../modules/Announcement'
import { setCommunicationType } from '../../AnnouncementsPage/modules/Announcements'
import AnnouncementView from '../components/EditAnnouncement'

const mapState = (state, ownProps) => {
  const { params } = ownProps
  const { announcementReducer, loading, sites = {}, study, studyLock, user } = state
  const { announcement, errors } = announcementReducer
  const studyID = Number(params.studyID)
  const { isDeploy, sendingState, sentState } = state.sending
  const sendDisabled = sendingState || sentState
  const { currentStudy, locales } = study
  const enforcedLanguage = currentStudy?.config?.enforced_language
  const {
    content,
    deployed,
    disabledLangs,
    num_read,
    num_received,
    num_sent,
    schedule,
    title,
    translations,
  } = announcement
  const { siteSelectionList, leafSites, sitesList } = sites
  const userScope = getUserScopeByStudy(user, currentStudy)
  const canWriteCohort = userScope.includes(USER_SCOPES.wxc.code)
  const noTracksFeature = currentStudy.config?.tracks?.disabled === true

  return {
    checkedSites: schedule.cohort.type === 'sites' ? schedule.cohort.filter.site_ids : [],
    content,
    currentStudy: study.currentStudy,
    deployed,
    deploySending: isDeploy && sendingState,
    deploySent: isDeploy && sentState,
    disabledLangs,
    disableTrackCreation: disableScope({ study, user, scope: USER_SCOPES.wxc.code }) || !canWriteCohort,
    enforcedLanguage,
    errors,
    isCreate: !announcement.hasOwnProperty('id'),
    leafSites,
    loading: loading.general,
    locales,
    noTracksFeature,
    num_read,
    num_received,
    num_sent,
    readOnly: deployed !== null,
    saveSending: !isDeploy && sendingState,
    saveSent: !isDeploy && sentState,
    schedule,
    sendDisabled,
    siteSelectionList,
    sitesList,
    studyID,
    studyLock,
    title,
    translations,
  }
}

const mapDispatch = {
  ...announcementActions,
  ...modalActions,
  setNavBarProps,
  setCommunicationType,
}

export default connect(
  mapState,
  mapDispatch,
)(props => {
  if (props.loading) return <Loader />
  return <AnnouncementView {...props} />
})
