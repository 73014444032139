import { combineReducers } from 'redux'
import { loadingActions } from 'store/loader'
import request, { generateNotyMessage } from 'utils/request'
import { actions as notyActions } from 'layouts/ErrorBox'
import STRINGS from 'utils/strings'
import { redirectVisit, onRedirectVisit } from '../utils/visitRedirection'
import { getCreateVisitTemplateErrors } from '../utils/visitTemplateValidation'
import { setScheduleVisitTemplates } from '../../../../VisitSchedulesPage/modules/VisitSchedules'

//
// Actions
//
const INIT_VISIT_TEMPLATE = 'INIT_VISIT_TEMPLATE'
export const UPDATE_VISIT_TEMPLATE_NAME = 'UPDATE_VISIT_TEMPLATE_NAME'
const UPDATE_ORIGINAL_VISIT_TEMPLATE = 'UPDATE_ORIGINAL_VISIT_TEMPLATE'
export const SET_VISIT_TEMPLATE = 'SET_VISIT_TEMPLATE'

// ERRORS
const ADD_VISIT_TEMPLATE_ERRORS = 'ADD_VISIT_TEMPLATE_ERRORS'
const CLEAR_VISIT_TEMPLATE_ERRORS = 'CLEAR_VISIT_TEMPLATE_ERRORS'
//
// Action Creators
//

const initPage = () => dispatch => {
  return dispatch({
    type: INIT_VISIT_TEMPLATE,
  })
}

const setScheduleVisitTemplate = ({ study_visit_schedule }, visitTemplateId, templateVisitId) => {
  return {
    type: SET_VISIT_TEMPLATE,
    visitTemplate: returnSpecificVisitTemplate(study_visit_schedule, visitTemplateId),
    templateVisitId,
  }
}

const updateVisitName = text => dispatch => dispatch({ type: UPDATE_VISIT_TEMPLATE_NAME, text })

const addVisitTemplateErrors = errors => dispatch => dispatch({ type: ADD_VISIT_TEMPLATE_ERRORS, errors })

const clearVisitTemplateErrors = () => dispatch => dispatch({ type: CLEAR_VISIT_TEMPLATE_ERRORS })
//
// Utils
//
const defaultVisitsTemplateState = {
  template_name: '',
  visits: [],
}

//
// API functions
//
const saveVisitTemplateRequest = ({ studyID, body, visitTemplateID }) => dispatch => {
  const url = `/control/studies/${studyID}/study_schedules${visitTemplateID ? `/${visitTemplateID}` : ''}`
  return dispatch(
    request({
      url,
      body,
      method: visitTemplateID ? 'PATCH' : 'POST',
      successMessage: STRINGS.visitScheduleSavedSuccess,
      hasLoader: true,
      success: json => json,
    }),
  )
}

const fetchVisitTemplate = (studyID, visitTemplateId, templateVisitId) => dispatch => {
  const url = `/control/studies/${studyID}/study_schedules`
  const success = payload => {
    dispatch(setScheduleVisitTemplate(payload, visitTemplateId, templateVisitId))
    dispatch(setScheduleVisitTemplates(payload))
  }
  return dispatch(
    request({
      url,
      hasLoader: true,
      success,
    }),
  )
}

//
// Util Functions
//
const saveVisitTemplate = ({ studyID, visitTemplate, isUpdate, visitTemplateID }) => dispatch => {
  const { template_name } = visitTemplate
  const defaultVisitTemplateJSON = {
    study_visit_schedule: {
      template_name,
      metadata: {
        blocking_window: false,
        disable_future_status_update: false,
      },
      visits: [],
    },
  }

  const success = resJSON => {
    dispatch({
      type: UPDATE_ORIGINAL_VISIT_TEMPLATE,
      json: visitTemplate,
    })
    return Promise.resolve(resJSON)
  }

  const body = JSON.stringify(isUpdate ? { study_visit_schedule: visitTemplate } : defaultVisitTemplateJSON)

  return dispatch(saveVisitTemplateRequest({ studyID, body, visitTemplateID })).then(success)
}

const onSaveVisitTemplate = ({ studyID, redirectPath, queryParams, isUpdate, visitTemplateID }) => (
  dispatch,
  getState,
) => {
  const { visitTemplateReducer, subroute } = getState()
  const { visitTemplate } = visitTemplateReducer
  const errors = getCreateVisitTemplateErrors(visitTemplate)
  const valid = !(Object.values(errors).filter(val => val).length >= 1)
  if (valid) {
    dispatch(clearVisitTemplateErrors())
    dispatch(saveVisitTemplate({ studyID, ...visitTemplateReducer, isUpdate, visitTemplateID })).then(resJSON => {
      dispatch(onRedirectVisit({ resJSON, redirectPath, studyID, queryParams, visitScheduleSubroute: subroute }))
    })
  } else {
    dispatch(addVisitTemplateErrors(errors))
    dispatch(
      notyActions.showError({
        text: generateNotyMessage(STRINGS.pleaseCheckErr, false),
      }),
    )
  }
}

const returnSpecificVisitTemplate = (visitSchedules, visitTemplateId) => {
  for (let i = 0; i < visitSchedules.length; i++) {
    const visitSchedule = visitSchedules[i]
    if (visitSchedule.id === visitTemplateId) return visitSchedule
  }
}

//
// Reducers
//

const visitTemplate = (state = defaultVisitsTemplateState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case INIT_VISIT_TEMPLATE:
      return defaultVisitsTemplateState
    case UPDATE_VISIT_TEMPLATE_NAME:
      return { ...newState, template_name: action.text }
    case SET_VISIT_TEMPLATE: {
      return action.visitTemplate
    }
    default:
      return state
  }
}

const visitTemplateErrors = (state = {}, action) => {
  switch (action.type) {
    case INIT_VISIT_TEMPLATE:
    case CLEAR_VISIT_TEMPLATE_ERRORS:
      return {}
    case ADD_VISIT_TEMPLATE_ERRORS:
      return action.errors
    default:
      return state
  }
}

const originalVisitTemplate = (state = {}, action) => {
  if (action.type === INIT_VISIT_TEMPLATE || action.type === UPDATE_ORIGINAL_VISIT_TEMPLATE) {
    return JSON.parse(JSON.stringify(action.json || defaultVisitsTemplateState))
  }
  return state
}

export default combineReducers({ visitTemplate, visitTemplateErrors, originalVisitTemplate })

export const visitTemplateActions = {
  fetchVisitTemplate,
  initPage,
  onSaveVisitTemplate,
  updateVisitName,
}
