import request from 'utils/request'
import moment from 'moment'
import { Parser } from 'json2csv'
import { loadingActions } from 'store/loader'
import { actions as notyActions } from 'layouts/ErrorBox'
import { isArray, downloadBlob } from 'utils/misc'

//
// Actions
//

const FETCHED_AUDIT_REPORT = 'FETCHED_AUDIT_REPORT'
const RESET_AUDIT_REPORT = 'RESET_AUDIT_REPORT'

//
// API Actions
//

export const fetchAuditReport = ({ urlString = '', callback, errCallback, redirect, _fail }) => {
  return dispatch => {
    const success = () => {
      callback()
    }
    const fail = (response, content) => {
      if (_fail) _fail()
      const errMessage = content.message || `${response.status} Error: ${response.statusText}.`
      if (errCallback) errCallback(errMessage)
    }

    dispatch(loadingActions.startLoader())
    return dispatch(
      request({
        url: `/control/audit_events?${urlString}`,
        errorMessage: 'error fetching report',
        success,
        fail,
      }),
    ).then(() => dispatch(loadingActions.stopLoader()))
  }
}

export const resetAuditReport = () => {
  return {
    type: RESET_AUDIT_REPORT,
  }
}

/**
 * Download the Audit Report
 * @param {Array} auditReportArray
 */
export const downloadAuditReport = auditReportArray => (dispatch, getState) => {
  const auditReportCSV = getAuditCSV(transformAuditReport(auditReportArray), dispatch)
  if (!auditReportCSV) return

  const { location, study } = getState()
  const splitPath = location.pathname.split('/report-')
  const pathArray = splitPath[1].split('&')
  const from = pathArray[getIndex(pathArray, 'from')]?.slice(5)
  const to = pathArray[getIndex(pathArray, 'to')]?.slice(3)
  const studyID = `study_id-${study.currentStudy.id}`
  // Generate and Download the file
  const blob = new Blob([auditReportCSV], { type: 'text/csv' })
  downloadBlob(
    blob,
    `audit_report_${from ? `${getFormatDate(from)}-` : ''}${to ? `to-${getFormatDate(to)}-` : ''}${studyID}.csv`,
  )
}
const getFormatDate = dateStr => moment(dateStr).format('DD-MMM-YYYY')
const getIndex = (arr, strValue) => arr.findIndex(el => el.includes(strValue))
//
// Utils
//

export const action = {
  Create: 'Created',
  Update: 'Updated',
  Delete: 'Deleted',
}

/**
 * Convert an object into csv Synchronous
 * @param {Object} auditReportData
 */
const getAuditCSV = (auditReportData, dispatch) => {
  const fields = [
    {
      label: 'action',
      value: 'action',
    },
    {
      label: 'date',
      value: 'date',
    },
    {
      label: 'object',
      value: 'object',
    },
    {
      label: 'object id',
      value: 'object_id',
    },
    {
      label: 'author',
      value: 'author',
    },
    {
      label: 'original value',
      value: 'original_value',
    },
    {
      label: 'updated value',
      value: 'updated_value',
    },
  ]
  const json2csvParser = new Parser({ fields })
  let csv = null
  try {
    csv = json2csvParser.parse(auditReportData)
  } catch (error) {
    dispatch(notyActions.showError({ text: error }))
  }
  return csv
}

/**
 * Transform the audit_report double array into a single one
 * @param {Array} auditReportArray
 */
const transformAuditReport = auditReportArray => {
  const newReportArray = auditReportArray.map(rowArray =>
    rowArray
      .map(({ key, value }) => {
        const dataValue = Array.isArray(value)
          ? value.length > 0
            ? value.map(el => (Array.isArray(el) ? el.join(':') : el)).join('\r\n')
            : ''
          : value
        return { [key]: dataValue }
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  )
  return newReportArray
}

//  A method for rendering nest objects properly to be added
// const parseNestedObjects = (data) => {
// }

const parseAuditReportList = auditReportItems => {
  return auditReportItems.map(auditReportItem => {
    const updated = auditReportItem.action_type === 'Update'
    const created = auditReportItem.action_type === 'Create'
    const changedFields = auditReportItem.changed_fields
    const changedFieldKeys = changedFields ? Object.keys(changedFields) : ''
    // const updated = !!changedFields
    const date = moment(auditReportItem.timestamp)
    const dateText = date.format('MMMM Do YYYY, h:mm:ssa')
    const dateData = date.valueOf()
    let originalValues = ''
    let updatedValues = ''
    let originalSortValue = ''
    let updatedSortValue = ''
    let newOrDeletedValues = ''
    // if change was an updated, value returned is an array of strings of changed fields
    if (updated) {
      const originalValuesArray = changedFieldKeys.map(changedFieldKey => {
        if (isArray(auditReportItem.row_data[changedFieldKey])) {
          return { [changedFieldKey]: auditReportItem.row_data[changedFieldKey] }
        }
        return `${changedFieldKey} = ${auditReportItem.row_data[changedFieldKey]}`
      })
      originalValues = originalValuesArray
      originalSortValue = originalValues[0]

      const updatedValuesArray = changedFieldKeys.map(changedFieldKey => {
        if (isArray(changedFields[changedFieldKey])) {
          return { [changedFieldKey]: changedFields[changedFieldKey] }
        }
        return `${changedFieldKey} = ${changedFields[changedFieldKey]}`
      })
      updatedValues = updatedValuesArray
      updatedSortValue = updatedValues[0]
      // if change was a create or delete action, value returned is a 2-d array to be parsed into jsx elements
    } else {
      newOrDeletedValues = Object.keys(auditReportItem.row_data).map(key => {
        return [key, auditReportItem.row_data[key] === null ? 'null' : auditReportItem.row_data[key]]
      })
      originalValues = created ? [] : newOrDeletedValues
      originalSortValue = created ? '' : newOrDeletedValues[0][1]
      updatedValues = created ? newOrDeletedValues : []
      updatedSortValue = created ? newOrDeletedValues[0][1] : ''
    }

    const row = [
      {
        key: 'action',
        value: auditReportItem.action_type,
        sortValue: auditReportItem.action_type,
        className: `action ${action[auditReportItem.action_type].toLowerCase()}`,
      },
      {
        key: 'date',
        value: dateText,
        sortValue: dateData,
        className: 'audit-date',
      },
      {
        key: 'object',
        value: auditReportItem.object_type,
        sortValue: auditReportItem.object_type,
        className: 'audit-object',
      },
      { key: 'object_id', value: auditReportItem.object_id, sortValue: auditReportItem.object_id },
      {
        key: 'author',
        value:
          auditReportItem.author_type === 'System'
            ? 'System'
            : `${auditReportItem.author_type} ID: ${auditReportItem.author_id}`,
        sortValue: auditReportItem.author_id,
        className: 'audit-author',
      },
      {
        key: 'original_value',
        value: originalValues,
        action: auditReportItem.action_type,
        objectType: auditReportItem.object_type,
        objectId: auditReportItem.object_id,
        sortValue: originalSortValue,
      },
      {
        key: 'updated_value',
        value: updatedValues,
        action: auditReportItem.action_type,
        objectType: auditReportItem.object_type,
        objectId: auditReportItem.object_id,
        sortValue: updatedSortValue,
      },
    ]
    return row
  })
}

//
// Reducers
//

const auditReport = (state = [], action) => {
  switch (action.type) {
    case FETCHED_AUDIT_REPORT:
      return action.payload
    case RESET_AUDIT_REPORT:
      return []
    default:
      return state
  }
}

// function

export default auditReport
