import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'components/UIElements'
import { setNavBarProps } from 'store/navbar'
import { modalActions } from 'store/modal'
import { notificationActions } from '../modules/ConditionalNotification'
import EditConditionalNotification from '../components/EditConditionalNotificationPage'

const getMapState = notificationType => state => {
  const { conditionalNotifications, loading, studyLock } = state
  const { enabled } = conditionalNotifications.medicationAdherence
  return {
    conditionalNotifications,
    loading: loading.general,
    notificationType,
    settings: { enabled },
    studyLock,
  }
}

export default notificationType =>
  connect(getMapState(notificationType), { ...notificationActions, setNavBarProps, ...modalActions })(props => {
    if (props.loading) return <Loader />
    return <EditConditionalNotification {...props} />
  })
