import { injectReducer } from 'store/reducers'
import {
  UserIsAuthenticated,
  UserHasValidPassword,
  UserHasScope,
} from '../../../../../../../../containers/authContainers'
import users from './modules/Users'

// Sync route definition
export default store => ({
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      require => {
        const { params } = nextState
        const { studyID } = params

        // Add Reducer
        injectReducer(store, {
          key: 'users',
          reducer: users,
        })

        store.dispatch(require('./modules/Users').actions.fetchUsers(Number(studyID)))
        // Return Component
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(UserHasScope(['ru'], require('./containers/UserContainer').default, false, studyID)),
          ),
        )
      },
      'dashboard',
    )
  },
})
