export const buttonContentOnSendState = (
  { 
    content = 'Save', 
    sendingContent = 'Saving', 
    sentContent = '...Saved!', 
  }, 
  sending = false, 
  sent = false,
) => {
  if (sending) {
    return sendingContent
  }
  if (sent) {
    return sentContent
  }
  return content
}

export default buttonContentOnSendState