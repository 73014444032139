import React from 'react'
import PropTypes from 'prop-types'
import { Button, Loader, ImageCropper } from 'components/UIElements'
import { uploadIcon } from 'assets/assets'
import { convertFileTypeArrToText } from 'utils/misc'
import { MEDIA_TYPE_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import 'styles/d3/uploadinput.scss'
import { FILE_MIME_TYPE_MAP } from '../utils/constants'

class UploadInput extends React.Component {
  constructor(props) {
    super(props)
    this._getDefaultState = () => ({
      draggedOver: false,
      error: false,
      progress: null,
      validDraggedFile: true,
    })
    this.state = this._getDefaultState()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.errorMsg) {
      const { errorMsg } = this.props
      if (errorMsg) this.setState({ error: errorMsg })
    }
  }

  onDragOver = e => {
    e.preventDefault()
  }

  onDragEnter = e => {
    this.setState({ draggedOver: true })
    const fileType = e.dataTransfer.items[0].type
    this.handleAccepted(fileType)
  }

  onDragLeave = () => {
    this.setState({ draggedOver: false, validDraggedFile: true })
  }

  onDrop = e => {
    e.preventDefault()
    if (e.dataTransfer.items && e.dataTransfer.items[0].kind === 'file') {
      this.handleFileLoad(e.dataTransfer.files[0])
    }
  }

  handleAccepted = fileType => {
    const { accept } = this.props
    if (accept) {
      const fileTypeArr = accept.map(type => FILE_MIME_TYPE_MAP[type])
      if (!fileTypeArr.includes(fileType)) {
        this.setState({ validDraggedFile: false })
      }
    }
  }

  handleFileLoad = fileNode => {
    const { accept, onUpdateFile, isMedia } = this.props
    const extension = `.${fileNode?.name
      .split('.')
      .pop()
      .toLowerCase()}`

    // If the file type loaded is not one of the accepted types
    if (accept && !accept.includes(extension) && !isMedia) {
      onUpdateFile({})
      this.setState({
        ...this._getDefaultState(),
        error: `Please upload a file with the correct extension (${accept.join(', ')})`,
      })
      onUpdateFile({})
    } else {
      onUpdateFile({})
      onUpdateFile(fileNode)
      this.setState(this._getDefaultState())
    }
  }

  onClickToUpload = e => {
    e?.preventDefault()
    this.fileInput.click()
  }

  onInputChange = e => {
    this.handleFileLoad(this.fileInput.files[0])
  }

  onProgress = e => {
    this.setState({ progress: Math.round((100 * e.loaded) / e.total) })
  }

  renderLoading = () => {
    const { sayPreparing = false, type } = this.props
    const { draggedOver } = this.state
    return (
      <div style={{ pointerEvents: draggedOver ? 'none' : '' }} className='input-container'>
        <Loader inContainer size={25} active loaderColor='#313d98' fullCircleLoader noBackground />
        <div>{`${sayPreparing ? STRINGS.preparing : STRINGS.uploading}${type ? ` ${type}` : ''}`}</div>
      </div>
    )
  }

  renderDraggedFileMessage = () => {
    const { validDraggedFile } = this.state
    return (
      <div className='dragged-prompt'>
        {validDraggedFile ? (
          <>
            {uploadIcon}
            <p>Drop that file here</p>
          </>
        ) : (
          <p>Hmm, that file type isn’t supported</p>
        )}
      </div>
    )
  }

  renderInputContainer = () => {
    const { error, draggedOver, validDraggedFile } = this.state
    const {
      accept,
      autoFocus = true,
      changeImageMode = false,
      children,
      disabled,
      file,
      onUpdateFile,
      onRemoveFile,
      restrictionText,
      type,
      isCrop = false,
      onSubmit = false,
      setCroppedImage,
      previmSrc,
    } = this.props

    const acceptedFileText = accept ? convertFileTypeArrToText(accept) : ''
    const hasAdditionalText = accept || restrictionText
    const draggedOverInvalid = draggedOver && !validDraggedFile

    return (
      <div className={`input-container ${changeImageMode && !file?.name && previmSrc ? 'image' : ''}`}>
        {file.name === undefined ? (
          <Button
            autoFocus={autoFocus}
            link
            className='clickable'
            disabled={disabled}
            onClick={this.onClickToUpload}
            type='button'>
            <div>
              {accept && draggedOver ? (
                this.renderDraggedFileMessage()
              ) : (
                <div className='upload-prompt'>
                  {changeImageMode ? (
                    <div className={`preview-wrap ${previmSrc ? 'preview-image' : ''}`}>
                      <div className='change-text'>Change image</div>
                      {previmSrc && <img src={previmSrc} alt='preview' />}
                    </div>
                  ) : (
                    <>
                      <p>
                        {!error && !file.name && uploadIcon}
                        {error || file.name || 'Upload'}
                      </p>
                      <p>or drag the file here</p>
                    </>
                  )}
                </div>
              )}
              {!changeImageMode && hasAdditionalText && (!draggedOver || draggedOverInvalid) && (
                <div className='file-type-restrictions'>
                  {acceptedFileText && <p>{acceptedFileText}</p>}
                  {restrictionText && <p>{restrictionText}</p>}
                </div>
              )}
            </div>
          </Button>
        ) : (
          <div className='file flexed column'>
            <div className='flexed'>
              <span className='file-name'>{file.name}</span>
              <Button
                onClick={() => {
                  if (onRemoveFile) onRemoveFile()
                  onUpdateFile({})
                }}
                className='fas fa-times clickable'
                disabled={disabled}
              />
            </div>
            {file &&
              (type === MEDIA_TYPE_MAP.image || type === MEDIA_TYPE_MAP.white_labeling) &&
              (isCrop ? (
                <ImageCropper
                  img={window.URL.createObjectURL(file)}
                  onSubmit={onSubmit}
                  setCroppedImage={setCroppedImage}
                />
              ) : (
                <img src={`${window.URL.createObjectURL(file)}`} alt='uploading' />
              ))}
            {file && type === MEDIA_TYPE_MAP.audio && <audio src={`${window.URL.createObjectURL(file)}`} controls />}
            {file && type === MEDIA_TYPE_MAP.video && <video src={`${window.URL.createObjectURL(file)}`} controls />}

            {file && (type === 'CSV' || type === 'json' || type === 'pdf') && <i className='far fa-file-alt' />}
          </div>
        )}
        {children}
      </div>
    )
  }

  render() {
    const { accept, className, loading, name, file, disabled } = this.props
    const { draggedOver, error, validDraggedFile } = this.state
    let _className = 'upload-input'
    const fileToUpload = file || {}
    const hasFile = this.props.file.name !== undefined
    _className += draggedOver ? ' dragged-over' : ''
    _className += hasFile ? ' loaded' : ''
    _className += error ? ' has-error' : ''
    _className += loading ? ' loading' : ''
    _className += draggedOver && validDraggedFile ? ' valid-drag' : ''
    _className += className ? ` ${className}` : ''

    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        className={_className}>
        <input
          aria-label='file-input'
          name={name || ''}
          type='file'
          onClick={e => (e.target.value = null)}
          onChange={this.onInputChange}
          style={{ display: 'none' }}
          ref={el => {
            this.fileInput = el
          }}
          accept={accept instanceof Array ? accept.join(',') : undefined}
          disabled={disabled}
        />
        {loading ? this.renderLoading() : this.renderInputContainer()}
      </div>
    )
  }
}

UploadInput.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  autoFocus: PropTypes.bool,
  changeImageMode: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isMedia: PropTypes.bool,
  onUpdateFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
  loading: PropTypes.bool,
  name: PropTypes.string,
  restrictionText: PropTypes.string,
  sayPreparing: PropTypes.bool,
  type: PropTypes.string,
}

export default UploadInput
