import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, DatePicker, TimePicker, Input, Button } from 'components/UIElements'
import { exclamationIcon } from 'assets/assets'
import { pluralize } from 'utils/misc'
import { isToday } from 'utils/time'
import moment from 'moment'

const meetingTimeDescription = (
  <div className='meeting-description'>
    <h5>Meeting times</h5>
    <p>
      <span>Participant will receive an email and push notification </span>
      <strong>24 hours</strong>
      <span> and </span>
      <strong>30 minutes prior</strong>
      <span> to the scheduled visit</span>
      <br />
      <span>Clinicians will be notified </span>
      <strong>24 hours</strong>
      <span> and </span>
      <strong>10 minutes prior</strong>
      <span> to the scheduled visit</span>
    </p>
  </div>
)

/**
 * Only one error message ever renders to the right of meetings times.
 * All errors render mutually-exclusively. Error with existing meetings
 * in the system, error with current overlapping batched times, and error
 * with past meeting times will never render together.
 *
 * The overlapping current visit error takes precedence over any past time
 * error. This means that if there are any times that overlap, the
 * overlapping error will render even though there may be batched meeting
 * times that are in the past.
 * */
const generateErrorMessage = ({
  anyOverlappingErrors,
  hasErrorWithCurrentVisits,
  hasErrorWithExistingVisits,
  hasErrorWithPastVisits,
  overlappingExistingVisits,
  visitsInPast,
}) => {
  let errorMessage = ''
  if (hasErrorWithExistingVisits)
    errorMessage = `The selected participant and/or clinician has a scheduled meeting at ${pluralize(
      overlappingExistingVisits.length,
      'that time',
      'those times',
      false,
    )}.`
  if (anyOverlappingErrors) {
    if (hasErrorWithCurrentVisits || hasErrorWithPastVisits) errorMessage = 'The meeting times you selected overlap.'
  } else if (hasErrorWithPastVisits) {
    errorMessage = `The meeting ${pluralize(
      visitsInPast.length,
      'time you selected is',
      'times you selected are',
      false,
    )} in the past.`
  }

  return errorMessage
}

const MeetingRow = ({
  canDelete,
  clearVisitTimeErrors,
  deleteMeeting,
  disabled,
  idx,
  recordingDisabled,
  updateMeetingField,
  visit,
  visitErrors,
}) => {
  const { visit_duration, visit_time, isNew, is_recorded } = visit
  const { overlappingVisits, overlappingExistingVisits, visitsInPast } = visitErrors

  /**
   * Error with overlaps in the batched visits currently being created
   * */
  const hasErrorWithCurrentVisits = overlappingVisits?.includes(visit_time)

  /**
   * Error with overlaps between batched visists and existing visits in the system
   * */
  const hasErrorWithExistingVisits = overlappingExistingVisits?.includes(visit_time)

  /**
   * Error with batched visits that are set up not in the future
   * */
  const hasErrorWithPastVisits = visitsInPast?.includes(visit_time)

  const anyOverlappingErrors = overlappingVisits?.length > 0

  const hasError = (hasErrorWithCurrentVisits || hasErrorWithExistingVisits || hasErrorWithPastVisits) && !isNew
  const visitMoment = moment(visit_time)

  const errorClasses = `${hasErrorWithCurrentVisits || hasErrorWithPastVisits ? ' current-error' : ''}${
    hasErrorWithExistingVisits ? ' existing-error' : ''
  }`

  return (
    <div className={`meeting-container${hasError ? ' has-error' : ''}${errorClasses}`}>
      {hasError && (
        <div className='visit-error flexed start-justified'>
          {exclamationIcon()}
          <div>
            {generateErrorMessage({
              anyOverlappingErrors,
              hasErrorWithCurrentVisits,
              hasErrorWithExistingVisits,
              hasErrorWithPastVisits,
              overlappingExistingVisits,
              visitsInPast,
            })}
          </div>
        </div>
      )}
      {idx === 0 && (
        <div className='flexed start-justified meeting-header'>
          <div className='label-small date'>Start Date</div>
          <div className='label-small time'>Time</div>
          <div className='label-small duration'>Duration</div>
        </div>
      )}
      <div className='flexed start-justified meeting-row'>
        {canDelete && (
          <i
            className='fas fa-trash'
            id='meeting-delete'
            onClick={e => {
              e.stopPropagation()
              deleteMeeting(idx)
            }}
          />
        )}
        <DatePicker
          disabled={disabled}
          id='start-date'
          disabledDays={{ before: new Date() }}
          initialDate={visitMoment}
          onDayChange={date => {
            clearVisitTimeErrors()
            if (date) updateMeetingField(idx, 'visit_time', date.format())
          }}
        />
        <TimePicker
          allowEmpty={false}
          disabled={disabled}
          id='start-time'
          preventPastTimes={isToday(visit_time)}
          hasError={hasError}
          value={visitMoment}
          onChange={mom => {
            clearVisitTimeErrors()
            updateMeetingField(idx, 'visit_time', mom.format())
          }}
        />
        <Input
          disabled={disabled}
          hasError={hasError}
          type='number'
          onChange={val => {
            clearVisitTimeErrors()
            updateMeetingField(idx, 'visit_duration', Number(val))
          }}
          className='duration-input'
          id='meeting-duartion'
          max={1440}
          min={1}
          posttext='min'
          value={visit_duration.toString()}
        />
        {!recordingDisabled && (
          <Checkbox
            disabled={disabled}
            label='Record visit'
            id='record-visit-checkbox'
            checked={is_recorded}
            onClick={() => {
              updateMeetingField(idx, 'is_recorded', !is_recorded)
            }}
          />
        )}
      </div>
    </div>
  )
}

const MeetingTimeSelection = props => {
  const {
    clearVisitTimeErrors,
    currentVisits,
    deleteMeeting,
    disabled,
    firstErrorIndex,
    recordingDisabled,
    updateMeetingField,
    visitErrors,
  } = props
  const { visits } = currentVisits

  const canDelete = visits.length > 1

  const meetingList = visits.map((visit, idx) => {
    return (
      <MeetingRow
        canDelete={canDelete}
        clearVisitTimeErrors={clearVisitTimeErrors}
        deleteMeeting={deleteMeeting}
        disabled={disabled}
        firstErrorIndex={firstErrorIndex}
        idx={idx}
        key={`meeting-row-${idx}`}
        recordingDisabled={recordingDisabled}
        updateMeetingField={updateMeetingField}
        visit={visit}
        visitErrors={visitErrors}
      />
    )
  })
  return meetingList
}

const RecordMeetingDescription = () => {
  return (
    <div className='recording-section'>
      <h5>Record meetings</h5>
      <p>
        The recording will start when the meeting begins and end when the meeting ends. Video files of the virtual
        visits will be available in the ‘History’ tab
      </p>
    </div>
  )
}

const VisitTimeSelection = props => {
  const { addAdditionalVisit, disabled, recordingDisabled } = props

  return (
    <div className='visit-time-selection'>
      <div className='flexed start-aligned'>
        <div className='meeting-section'>
          {meetingTimeDescription}
          <MeetingTimeSelection {...props} />
        </div>
        {!recordingDisabled && <RecordMeetingDescription {...props} />}
      </div>
      <Button disabled={disabled} grey onClick={() => addAdditionalVisit()} id='new-meeting' content='New Meeting' icon='fas fa-plus' />
    </div>
  )
}

MeetingRow.propTypes = {
  canDelete: PropTypes.bool,
  clearVisitTimeErrors: PropTypes.func,
  deleteMeeting: PropTypes.func,
  disabled: PropTypes.bool,
  idx: PropTypes.number,
  recordingDisabled: PropTypes.bool,
  updateMeetingField: PropTypes.func,
  visit: PropTypes.shape({
    isNew: PropTypes.bool,
    visit_duration: PropTypes.number,
    visit_time: PropTypes.string,
    is_recorded: PropTypes.bool,
  }),
  visitErrors: PropTypes.shape({
    overlappingVisits: PropTypes.arrayOf(PropTypes.string),
    overlappingExistingVisits: PropTypes.arrayOf(PropTypes.string),
    visitsInPast: PropTypes.arrayOf(PropTypes.string),
  }),
}

MeetingTimeSelection.propTypes = {
  clearVisitTimeErrors: PropTypes.func,
  currentVisits: PropTypes.shape({
    visits: PropTypes.arrayOf(PropTypes.object),
  }),
  deleteMeeting: PropTypes.func,
  disabled: PropTypes.bool,
  firstErrorIndex: PropTypes.number,
  updateMeetingField: PropTypes.func,
  visitErrors: PropTypes.shape({
    overlappingVisits: PropTypes.arrayOf(PropTypes.string),
  }),
}

VisitTimeSelection.propTypes = {
  addAdditionalVisit: PropTypes.func,
  disabled: PropTypes.bool,
  recordingDisabled: PropTypes.bool,
}

export default VisitTimeSelection
