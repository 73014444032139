import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { modalActions } from 'store/modal'
import { setNavBarProps } from 'store/navbar'
import { actions as geofencesActions } from '../modules/Geofences'
import CreateGeofence from '../components/CreateGeofence'

const mapStateToProps = state => {
  const { participants, geofences, study, loading } = state
  return {
    participants,
    geofences,
    studyID: study.currentStudy.id,
    loading: loading.general,
  }
}

const mapDispatchToProps = { ...modalActions, ...geofencesActions, setNavBarProps }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.loading) {
    return <Loader />
  }
  return <CreateGeofence {...props} editMode />
})
