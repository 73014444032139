import React from 'react'
import { Checkbox, Popup } from 'components/UIElements'
import STRINGS from '../../../../../../../../../../../../utils/strings'

const DatePickerOverlay = ({ classNames, children, checked, disabled, onClick, ...props }) => {
  return (
    <div className={'DayPickerInput-OverlayWrapper'} {...props}>
      <div className={`DayPickerInput-Overlay past-overlay`}>
        {children}
        <div className='flexed column' style={{ marginBottom: '20px' }}>
          <div className='future-dates-toggle'>
            <Checkbox disabled={disabled} toggle checked={checked} onClick={onClick} />
            <span>{STRINGS.participantCurrentDate}</span>
            <Popup
              className='future-dates-popup'
              align={'left'}
              position='bottom'
              hover
              noPointer
              dark
              trigger={<i className='fas fa-info-circle' />}>
              {<p>{STRINGS.participantCurrentDate}</p>}
            </Popup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DatePickerOverlay
