import request from 'utils/request'
import { browserHistory } from 'react-router'
import { loadingActions } from 'store/loader'
import { fetchCohorts } from '../../ParticipantsPage/modules/Participants'
import { toggleCohort } from '../../CreateParticipant/modules/CreateParticipant'
import { combineReducers } from 'redux'

const FETCHED_COHORT = 'FETCHED_COHORT'
const UPDATE_COHORT = 'UPDATE_COHORT'
const RESET_COHORT = 'RESET_COHORT'
const TOGGLE_PARTICIPANT = 'TOGGLE_PARTICIPANT'

const _getDefaultCohort = () => {
  return {
    description: '',
    group_name: '',
  }
}

const updateCohort = field => value => {
  return {
    type: UPDATE_COHORT,
    field,
    value,
  }
}

const resetCohort = (initialPtps = []) => {
  return {
    type: RESET_COHORT,
    initialPtps,
  }
}

const deleteCohort = (studyID, siteID, groupID) => {
  return dispatch => {
    const success = () => {
      browserHistory.push(`/studies/${studyID}/participants?tracks`)
    }
    return dispatch(
      request({
        method: 'DELETE',
        url: `/control/study_groups/${groupID}`,
        success,
        successMessage: 'Track successfully deleted',
      }),
    )
  }
}

const fetchCohort = (studyID, cohortID) => {
  return dispatch => {
    const success = jsonBody => {
      dispatch({
        type: FETCHED_COHORT,
        payload: jsonBody,
      })
      return Promise.resolve(jsonBody)
    }
    return dispatch(
      request({
        url: `/control/studies/${studyID}/study_groups/${cohortID}`,
        success,
        hasLoader: true,
      }),
    )
  }
}

const saveCohortToDatabase = ({ studyID, cohort, isPatch, checkedPtps }) => {
  return dispatch => {
    const body = isPatch
      ? {
          group_name: cohort.group_name,
          description: cohort.description,
        }
      : {
          ...cohort,
          study_id: studyID,
          participants: Object.keys(checkedPtps),
        }
    return dispatch(
      request({
        method: isPatch ? 'PATCH' : 'POST',
        url: `/control/study_groups${isPatch ? `/${cohort.id}` : ''}`,
        body: JSON.stringify(body),
        success: res => Promise.resolve(res),
        successMessage: 'Track was saved successfully',
      }),
    )
  }
}

const saveCohort = ({ studyID, cohort, checkedPtps, canWriteCohort = false }) => {
  return dispatch => {
    dispatch(loadingActions.startLoader(true))
    const isPatch = 'id' in cohort
    const save = () => {
      if (canWriteCohort) {
        return dispatch(saveCohortToDatabase({ studyID, cohort, isPatch, checkedPtps })).then(() => {
          browserHistory.push(`/studies/${studyID}/participants?tracks`)
          dispatch(loadingActions.stopLoader(true))
        })
      }
      dispatch(loadingActions.stopLoader(true))
      browserHistory.push(`/studies/${studyID}/participants?tracks`)
    }

    if (isPatch) {
      return dispatch(updateCohortList(cohort, checkedPtps)).then(save)
    }
    return save()
  }
}

const saveCohortInline = (studyID, cohort, checkedPtps, onToggleCohort, toggleAfter = true) => {
  return dispatch => {
    return dispatch(saveCohortToDatabase({ studyID, cohort, isPatch: false, checkedPtps })).then(({ id }) => {
      dispatch(fetchCohorts(studyID, false))
      onToggleCohort(id)
      if (toggleAfter) dispatch(toggleCohort(id))
    })
  }
}

const updateCohortInDB = (cohortID, ptpID, isAdd) => {
  return dispatch => {
    return dispatch(
      request({
        method: isAdd ? 'PUT' : 'DELETE',
        url: `/control/study_groups/${cohortID}/participants/${ptpID}`,
        failMessage: `Failed to ${isAdd ? 'add' : 'remove'} participant ${ptpID} ${isAdd ? 'to' : 'from'} track.`,
        successMessage: `Successfully ${isAdd ? 'added' : 'removed'} participant ${ptpID} ${
          isAdd ? 'to' : 'from'
        } track`,
      }),
    )
  }
}

const updateCohortList = (cohort, checkedPtps) => {
  return dispatch => {
    // const newObj = Object.assign({}, checkedPtps)
    const newObj = { ...checkedPtps }
    const updateList = []
    cohort.participants.forEach(id => {
      if (!(id in newObj)) {
        updateList.push({ id, isAdd: false })
      } else {
        delete newObj[id]
      }
    })
    Object.keys(newObj).forEach(id => {
      updateList.push({ id, isAdd: true })
    })
    return new Promise((resolve, reject) => {
      if (updateList.length === 0) {
        resolve()
      } else {
        updateList.forEach((ptp, idx) => {
          dispatch(updateCohortInDB(cohort.id, ptp.id, ptp.isAdd)).then(() => {
            if (idx === updateList.length - 1) {
              resolve('SUCCESS')
            }
          })
        })
      }
    })
  }
}

const cohort = (state = _getDefaultCohort(), action) => {
  switch (action.type) {
    case RESET_COHORT:
      return _getDefaultCohort()
    case FETCHED_COHORT:
      return action.payload
    case UPDATE_COHORT:
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

const listTracker = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCHED_COHORT:
      newState = {}
      action.payload.participants.forEach(id => {
        newState[id] = null
      })
      return newState
    case TOGGLE_PARTICIPANT:
      newState = { ...state }
      newState[action.id] = null
      return newState
    case RESET_COHORT:
      newState = {}
      action.initialPtps.forEach(id => {
        newState[id] = null
      })
      return newState
    default:
      return state
  }
}

export const actions = {
  updateDescription: updateCohort('description'),
  updateName: updateCohort('group_name'),
  saveCohort,
  saveCohortInline,
  resetCohort,
  deleteCohort,
  fetchCohort,
}

export default combineReducers({
  cohort,
  listTracker,
})
