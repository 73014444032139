import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'

import DomainList from './DomainList'
import OverallScores from './OverallScores'

import '../stylesheets/scoring.scss'

const ScoringPage = ({
  addClinroScore,
  addSurveyScore,
  addDiaryScore,
  clinro,
  clinroErrors,
  deleteClinroScore,
  deleteSurveyScore,
  deleteDiaryScore,
  diary,
  diaryErrors,
  isClinro,
  isDiary,
  onSave,
  survey,
  surveyErrors,
  updateClinroDomainFormula,
  updateDiaryDomainFormula,
  updateSurveyDomainFormula,
  updateClinroScore,
  updateDiaryScore,
  updateSurveyScore,
}) => {
  const instrumentErrors = isClinro ? clinroErrors : isDiary ? diaryErrors : surveyErrors

  // TODO: Leave onUnload behavior commented until confirmed we want to implement this window alert.
  // useEffect(() => {
  //   const { scores: scoreErrors } = instrumentErrors
  //   const handlePageClose = e => {
  //     if (scoreErrors) {
  //       // this prevents the user from closing or refreshing without fixing any formula errors
  //       onSave('instruments')
  //       e.preventDefault()
  //       e.returnValue = ''
  //       return ''
  //     }
  //   }
  //   window.addEventListener('beforeunload', handlePageClose)
  //   return () => window.removeEventListener('beforeunload', handlePageClose)
  // }, [instrumentErrors])

  return (
    <div className='scoring page'>
      <div className='flexed baseline-aligned'>
        <DomainList
          instrument={isClinro ? clinro : isDiary ? diary : survey}
          isClinro={isClinro}
          isDiary={isDiary}
          updateDomainFormula={isClinro ? updateClinroDomainFormula : isDiary ? updateDiaryDomainFormula : updateSurveyDomainFormula}
        />
        <OverallScores
          addScore={isClinro ? addClinroScore : isDiary ? addDiaryScore : addSurveyScore}
          instrumentErrors={instrumentErrors}
          deleteScore={isClinro ? deleteClinroScore : isDiary ? deleteDiaryScore : deleteSurveyScore}
          instrument={isClinro ? clinro : isDiary ? diary : survey}
          isClinro={isClinro}
          isDiary={isDiary}
          updateScore={isClinro ? updateClinroScore : isDiary ? updateDiaryScore : updateSurveyScore}
        />
      </div>
      <div className='button-list'>
        <Button onClick={() => onSave('properties')} content='Next' />
      </div>
    </div>
  )
}

export default ScoringPage

ScoringPage.propTypes = {
  addClinroScore: PropTypes.func,
  addDiaryScore: PropTypes.func,
  addSurveyScore: PropTypes.func,
  diary: PropTypes.objectOf(PropTypes.object),
  diaryErrors: PropTypes.objectOf(PropTypes.object),
  clinro: PropTypes.objectOf(PropTypes.object),
  clinroErrors: PropTypes.objectOf(PropTypes.object),
  deleteClinroScore: PropTypes.func,
  deleteDiaryScore: PropTypes.func,
  deleteSurveyScore: PropTypes.func,
  isClinro: PropTypes.bool,
  isDiary: PropTypes.bool,
  onSave: PropTypes.func,
  survey: PropTypes.objectOf(PropTypes.object),
  surveyErrors: PropTypes.objectOf(PropTypes.object),
  updateClinroDomainFormula: PropTypes.func,
  updateDiaryDomainFormula: PropTypes.func,
  updateSurveyDomainFormula: PropTypes.func,
  updateClinroScore: PropTypes.func,
  updateDiaryScore: PropTypes.func,
  updateSurveyScore: PropTypes.func,
}
