import React, { useCallback, forwardRef } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import 'styles/d3/button.scss'
import { Loader } from 'components/UIElements'
import { throttle } from 'utils/misc'

const customProps = [
  'active',
  'circle',
  'customIcon',
  'diameter',
  'disabled',
  'errorText',
  'grey',
  'hasError',
  'iconAfter',
  'iconBefore',
  'inline',
  'isThrottled',
  'light',
  'link',
  'loading',
  'no-margin',
  'no-padding',
  'onClick',
  'red',
  'redSecondary',
  'secondary',
  'selected',
  'square',
  'style',
  'throttleTimeout',
  /**
   * This strips the button of all styling in cases where we want
   * our custom button components, but none of the style.
   */
  'noStyling',
]

const Button = forwardRef((props, ref) => {
  const _props = { ...props }
  delete _props.loading
  delete _props.customIcon
  delete _props.hasError
  delete _props.noStyling
  delete _props.grey
  delete _props.red
  customProps.forEach(prop => {
    if (_props[prop]) delete _props[prop]
  })
  const {
    className,
    children,
    content,
    customIcon,
    disabled,
    errorText,
    hasError,
    isThrottled,
    throttleTimeout = 500,
    diameter,
    form,
    onClick,
    icon,
    iconAfter,
    iconBefore,
    id,
    loading,
    type = 'submit',
    to = '',
  } = props

  const onThrottledClick = useCallback(throttle(onClick, throttleTimeout), [])

  let style = {}
  if (diameter) {
    style = { height: `${diameter}px`, minWidth: `${diameter}px`, borderRadius: `${diameter}px` }
  }
  let btnClassName = 'custom-button'
  btnClassName = `${btnClassName} ${customProps.filter(name => props[name] && name !== 'onClick').join(' ')}`
  btnClassName += className ? ` ${className}` : ''
  btnClassName += to ? ' is-link' : ''

  return (
    <>
      {to ? (
        <Link id={id} className={btnClassName} to={to}>
          {content || children}
        </Link>
      ) : (
        <button
          ref={ref}
          {..._props}
          id={id}
          className={btnClassName}
          onClick={isThrottled ? onThrottledClick : onClick}
          style={style}
          type={type}
          disabled={!!disabled}
          form={form}>
          {loading && <Loader inContainer size={15} />}
          {iconBefore && <i className={iconBefore} />}
          {!loading && !iconAfter && icon && <i className={icon} />}
          {!loading && !!customIcon && customIcon}
          {content || children}
          {iconAfter && <i className={`${iconAfter} icon-after`} />}
        </button>
      )}

      {hasError && errorText && <span className='error-text nowrap button-error'>{errorText}</span>}
    </>
  )
})

Button.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array]),
  className: PropTypes.string,
  content: PropTypes.node,
  customIcon: PropTypes.element,
  diameter: PropTypes.number,
  disabled: PropTypes.bool,
  debounceTimeout: PropTypes.number,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  icon: PropTypes.string,
  iconAfter: PropTypes.string,
  iconBefore: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  isThrottled: PropTypes.bool,
  throttleTimeout: PropTypes.number,
  type: PropTypes.string,
}

export default Button
