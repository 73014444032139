import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/D3Loader'
import './SideBar.scss'
import MainSideBar from './MainSideBar'

const Sidebar = props => {
  const { location, user, loadingStudy } = props
  const { token } = location.query
  const newToken = token || user.token
  if (newToken !== user.token) return null
  return <div className='sidebar-placeholder'>{loadingStudy ? <Loader /> : <MainSideBar {...props} />}</div>
}

export default Sidebar

Sidebar.propTypes = {
  study: PropTypes.object,
  studies: PropTypes.array,
  location: PropTypes.object,
  modalProps: PropTypes.object,
  user: PropTypes.object,
  onLogout: PropTypes.func,
}
