import { browserHistory } from 'react-router'
import { injectReducers } from 'store/reducers'
import { USER_ROLES_MAP } from 'utils/constants'
import { setPageTitle, getUserRoleByStudy } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { actions as createUserActions } from '../CreateUser/modules/CreateUser'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'
// Sync route definition
export default store => ({
  path: ':id',
  getComponent(nextState, cb) {
    const { study, user } = store.getState()
    const { currentStudy } = study
    const { id, config } = currentStudy
    const { root, dchAdmin, dchPii } = USER_ROLES_MAP
    const isRootOrDCH = [root, dchAdmin, dchPii].includes(getUserRoleByStudy(user, id))
    if (!isRootOrDCH) {
      const canEditUsers = config ? config?.users?.can_edit_users ?? true : true
      if (!canEditUsers) {
        browserHistory.push(`/studies/${id}/users`)
        return
      }
    }
    require.ensure(
      [],
      require => {
        const UserView = require('../CreateUser/containers/UserContainer').default
        const reducer = require('../CreateUser/modules/CreateUser').default
        const { params } = nextState
        const { studyID } = params
        injectReducers(store, {
          userReducer: reducer,
          sites: sitesReducer,
        })
        store.dispatch(fetchSites(studyID, true, false, false)) // params: studyID, hasLoader, includeLang, clearCheckedSites
        store.dispatch(createUserActions.resetUserPage())
        store.dispatch(createUserActions.fetchUser(nextState.params.id))
        setPageTitle(`Edit User | Study ${studyID} | ${currentStudy.platform_name}`)
        /*  Return getComponent   */
        cb(null, UserIsAuthenticated(UserHasValidPassword(UserHasScope(['ru'], UserView, false, studyID))))
      },
      ':id',
    )
  },
})
