import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button, Loader } from 'components/UIElements'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import PropertiesView from './PropertiesView'
import InstrumentStatusFunnelChart from './InstrumentStatusFunnelChart'
import ItemListView from './ItemListView'
import '../stylesheets/InstrumentPreview.scss'
import '../../EditSurvey/stylesheets/Survey.scss'

class InstrumentPreviewPage extends React.Component {
  componentDidMount() {
    const { onFetchInstrument, onFetchInstrumentEvents, studyID, instrumentID, inDrawer } = this.props
    if (!!studyID && !!instrumentID) {
      onFetchInstrument(studyID, instrumentID, null, inDrawer)
      onFetchInstrumentEvents(studyID, instrumentID, inDrawer)
    }
    this.page.scrollIntoView(true)
  }

  componentDidUpdate(prevProps) {
    const {
      onFetchSurvey,
      onFetchArticle,
      onFetchClinro,
      onFetchDiary,
      instrument,
      inDrawer,
      instrumentID,
      studyID,
      isSurvey,
      isArticle,
      isClinro,
      isDiary,
    } = this.props
    if (prevProps.instrument.id !== instrument.id && !!instrument.id) {
      if (instrument.type === INSTRUMENT_TYPE_MAP.survey || isSurvey) {
        onFetchSurvey(studyID, instrumentID, null, inDrawer)
      } else if (instrument.type === INSTRUMENT_TYPE_MAP.article || isArticle) {
        onFetchArticle(studyID, instrumentID, null, inDrawer)
      } else if (instrument.type === INSTRUMENT_TYPE_MAP.clinro || isClinro) {
        onFetchClinro(studyID, instrumentID, null, inDrawer)
      } else if (instrument.type === INSTRUMENT_TYPE_MAP.diary || isDiary) {
        onFetchDiary(studyID, instrumentID, null, inDrawer)
      }
    }
  }

  render() {
    const {
      article,
      buttonContent,
      canWrite,
      clinro,
      diary,
      closeDrawer,
      editorStates,
      inDrawer,
      instrument,
      isArticle,
      isClinro,
      isSurvey,
      isDiary,
      link,
      numSent,
      responseRate,
      survey,
      studyLock,
      cohortMap,
      siteSelectionList,
      caregiverEnabled,
      study,
      userId,
    } = this.props

    const isConditionalDeployment = Array.isArray(
      instrument?.metadata?.schedule?.chain_deployment_info?.prev_instrument_id,
    )

    const { type, deployed } = instrument
    const instrumentIsSurvey = isSurvey || type === INSTRUMENT_TYPE_MAP.survey
    const instrumentIsClinro = isClinro || type === INSTRUMENT_TYPE_MAP.clinro
    const instrumentIsArticle = isArticle || type === INSTRUMENT_TYPE_MAP.article
    const sent = !!numSent
    const instrumentIsDiary = isDiary || type === INSTRUMENT_TYPE_MAP.diary

    const instrumentEditorState = instrumentIsSurvey
      ? editorStates
      : instrumentIsClinro
      ? clinro.editorStates
      : diary.editorStates
    const instrumentQuestions = instrumentIsSurvey
      ? survey.questions
      : instrumentIsClinro
      ? clinro.clinro.questions
      : diary.diary.questions
    const instrumentQuestionOrder = instrumentIsSurvey
      ? survey.order[0]
      : instrumentIsClinro
      ? clinro.clinro.order[0]
      : diary.diary.order[0]
    return (
      <div
        className='preview page'
        ref={el => {
          this.page = el
        }}>
        {instrument.title ? (
          <>
            <div className='preview-header'>
              {inDrawer && (
                <div className='flexed-header response'>
                  {sent && <span className='response-rate'>{`${responseRate}% Responded`}</span>}
                </div>
              )}
              <div className='flexed-header'>
                <div className='title'>
                  <h2>{instrument.title}</h2>
                  {isConditionalDeployment ? (
                    <span className='tag deployed conditional-deployment'>
                      <i className='fas fa-folder-tree' />
                      Conditional Deployment
                    </span>
                  ) : deployed ? (
                    <span className='tag deployed'>Deployed</span>
                  ) : (
                    <span className='tag draft'>Draft</span>
                  )}
                </div>
                {link && canWrite && (
                  <Button
                    id='edit-survey'
                    content={`Edit ${buttonContent}`}
                    disabled={studyLock}
                    onClick={() => {
                      if (!studyLock) {
                        closeDrawer()
                        browserHistory.push(link)
                      }
                    }}
                  />
                )}
              </div>
              {instrumentIsArticle && (
                <div className='article-link'>
                  <h5>Article link</h5>
                  <a href={article.url} target='_blank' rel='noopener noreferrer'>
                    {article.url}
                  </a>
                </div>
              )}
            </div>
            {inDrawer && <InstrumentStatusFunnelChart {...this.props} />}
            <PropertiesView
              instrument={instrument}
              inDrawer={inDrawer}
              cohortMap={cohortMap}
              siteSelectionList={siteSelectionList}
            />
            {(instrumentIsSurvey || instrumentIsClinro || instrumentIsDiary) && (
              <ItemListView
                isClinro={instrumentIsClinro}
                isDiary={instrumentIsDiary}
                isSurvey={instrumentIsSurvey}
                instrumentType={instrument.type}
                editorStates={instrumentEditorState}
                questions={instrumentQuestions}
                order={instrumentQuestionOrder}
                caregiverEnabled={caregiverEnabled}
                study={study}
                userId={userId}
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}

InstrumentPreviewPage.propTypes = {
  fetchSurvey: PropTypes.func,
  onFetchInstrument: PropTypes.func,
  onFetchInstrumentEvents: PropTypes.func,
  onFetchSurvey: PropTypes.func,
  onFetchClinro: PropTypes.func,
  onFetchArticle: PropTypes.func,
  onFetchDiary: PropTypes.func,
  inDrawer: PropTypes.bool,
  studyID: PropTypes.number,
  instrumentID: PropTypes.string,
  buttonContent: PropTypes.string,
  survey: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    questions: PropTypes.object,
    order: PropTypes.array,
  }),
  article: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }),
  instrument: PropTypes.object,
  editorStates: PropTypes.object,
  clinro: PropTypes.object,
  diary: PropTypes.object,
  cohortMap: PropTypes.object,
  closeDrawer: PropTypes.func,
  link: PropTypes.string,
  isSurvey: PropTypes.bool,
  isClinro: PropTypes.bool,
  isArticle: PropTypes.bool,
  isDiary: PropTypes.bool,
  responseRate: PropTypes.number,
  numSent: PropTypes.number,
  canWrite: PropTypes.bool,
  studyLock: PropTypes.bool,
  caregiverEnabled: PropTypes.bool,
}

export default InstrumentPreviewPage
