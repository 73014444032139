import { setSubroute } from 'store/navbar'
import { UserIsAuthenticated, UserHasValidPassword, UserHasRole, UserHasFeatures } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import STRINGS from 'utils/strings'
import { USER_ROLES_WITH_SCHEDULE_ADHOC_VISIT_PERMISSION } from 'utils/constants'
import participantReducer from '../../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import participantVisitReducer, {
  fetchVisitSelectionPtps,
  participantVisitActions,
} from '../ParticipantVisitPage/modules/ParticipantVisit'
import ParticipantVisitContainer from '../ParticipantVisitPage/container/ParticipantVisitContainer'

export const injectVisitReducers = store => {
  injectReducers(store, {
    participantVisitReducer,
    participantReducer,
  })
}

const disableFeatures = [
  {
    name: 'visit_support',
    props: ['disable_adhoc_visit'],
  },
]

// Sync route definition
export default store => ({
  path: 'participant-visit/create',
  exact: true,
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        injectVisitReducers(store)
        store.dispatch(setSubroute('participant-visit'))
        store.dispatch(participantVisitActions.initPage())
        /*  Webpack - use require callback to define
         dependencies for bundling   */

        const { studyID } = nextState.params
        const { study } = store.getState()
        const { currentStudy } = study
        setPageTitle(`${STRINGS.visitScheduleCreateNewAdhoc} | Study ${studyID} | ${currentStudy.platform_name}`)
        // Fetch visit selection participants
        store.dispatch(fetchVisitSelectionPtps(studyID))

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'visit-schedules',
              )(
                UserHasRole(
                  USER_ROLES_WITH_SCHEDULE_ADHOC_VISIT_PERMISSION,
                  studyID,
                  /**
                   * Last argument for `UserHasFeatures` is to prevent navigation to the this route if the
                   * feature config key passed as the 2nd argument (`disableFeatures`) is enabled.
                   * For this route, if the `config.visit_support.disable_adhoc_visit` is true,
                   * we will redirect the user to the visit schedules page.
                   *  */

                  UserHasFeatures(
                    ParticipantVisitContainer,
                    disableFeatures,
                    'visit-schedules', // redirectSubroute,
                    false, // isNull
                    true, // every
                    true, // redirectIfEnabled
                  ),
                ),
              ),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'participant-visit',
    )
  },
})
