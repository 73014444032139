import React from 'react'
import { connect } from 'react-redux'
import { actions } from '../modules/Visits'
import VisitorCell from '../components/VisitorCell'

const mapStateToProps = (state, ownProps) => {
  const { loading, visits } = state
  const { handleResize, visitId } = ownProps
  const { visitsInfo, expandedVisits } = visits
  const isExpanded = expandedVisits[visitId]

  return {
    handleResize,
    isExpanded,
    visitsLoading: loading.visit,
    visitsInfo,
    ...ownProps,
  }
}

const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return <VisitorCell {...props} />
})
