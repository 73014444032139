import React from 'react'
import PropTypes from 'prop-types'
import { Container, Input, Loader } from 'components/UIElements'
import STRINGS from 'utils/strings'
import Subheader from '../../../../VisitScheduleNav/components/Subheader'
function VisitFields(props) {
  const { children, errors, disabled, participantVisit, updatePtpVisitName, updatePtpDisplayName, extraProps } = props
  const { isUpdate, ptpVisitsLoading, visitorParticipantLoader } = extraProps
  const { visitName, displayName, isAdhoc } = participantVisit
  const { visitName: nameErr, displayName: displayNameErr } = errors || {}

  const fieldsDisabled = disabled || (!isAdhoc && isUpdate)

  return (
    <Container>
      <div className='visit-fields'>
        <Subheader subheaderText={`What is the${isAdhoc ? ' adhoc' : ''} visit's name?`} />
        {isUpdate && (ptpVisitsLoading || visitorParticipantLoader) ? (
          <Loader inContainer size={24} />
        ) : (
          <div className='names'>
            <Input
              label={STRINGS.visitName}
              disabled={fieldsDisabled}
              id='visit-name-input'
              className='visit-name-input'
              placeholder={STRINGS.visitName}
              value={visitName || ''}
              onChange={value => updatePtpVisitName(value)}
              spellCheck
              charLimit={100}
              hasError={nameErr && !visitName}
              errorText={nameErr}
            />
            <Input
              label='Visit display name for participant '
              disabled={fieldsDisabled}
              id='display-name-input'
              className='visit-name-input'
              placeholder={STRINGS.visitDisplayNameInApp}
              value={displayName || ''}
              onChange={value => updatePtpDisplayName(value)}
              spellCheck
              charLimit={100}
              hasError={displayNameErr && !displayName}
              errorText={displayNameErr}
            />
          </div>
        )}
        {children}
      </div>
    </Container>
  )
}

VisitFields.propTypes = {
  disabled: PropTypes.bool,
  visitTemplate: PropTypes.shape({ template_name: PropTypes.string }),
  errors: PropTypes.object,
  updateVisitName: PropTypes.func,
  template_name: PropTypes.string,
}

export default VisitFields
