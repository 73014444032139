import React, { useState, useMemo } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, DataBox, Loader, SearchInput, StickyHeader, Dropdown } from 'components/UIElements'
import { DATE_FORMAT_MAP } from 'utils/constants'
import { useOffsets } from 'utils/misc'
import STRINGS from 'utils/strings'
import VisitSchedulesPreviousTable from './VisitSchedulesPreviousTable'
import FilterTagsPopup from '../../../../Participants/routes/ParticipantsPage/components/Filters/FilterTagsPopup'

const dataBoxList = [
  {
    header: 'Confirm Occurence',
    status: 'confirm-occurence',
    content: (
      <div>
        <p>
          <strong className='bold-text'>Did this visit happen?</strong>
        </p>
        <p>
          If <strong className='semi-bold'>yes</strong>, please provide the date when it occurred and click confirm. If{' '}
          <strong className='semi-bold'>no</strong>, please mark that the participant missed the visit, and our system
          will record this visit as "missed". You may correct the status after making this change until this
          participant's next visit schedule.
        </p>
      </div>
    ),
    color: 'orange',
  },
  // {
  //   header: 'Reschedule request',
  //   status: 'Passed',
  //   subtitle: 'Reschedule request',
  //   color: 'orange',
  // },
]

const VISITS_PERIOD_OPTIONS = [
  { key: 'all', text: 'All visits', window: 'all' },
  { key: 'days', text: 'day', window: 'days' },
  { key: '3days', text: '3 days', num: 3, window: 'days' },
  { key: 'weeks', text: 'week', window: 'weeks' },
  { key: 'months', text: 'month', window: 'months' },
  { key: 'years', text: 'year', window: 'years' },
]

const VisitSchedulesPrevious = props => {
  const {
    adhocVisitsDisabled,
    studyID,
    selectedTab,
    canEditVisits,
    studyLock,
    visitSchedules,
    isSiteCoord,
    visitSchedulesLoading,
  } = props
  const { visitSchedulesPreviousList = [] } = visitSchedules
  const [schedulePreviousCount, setSchedulePreviousCount] = useState(visitSchedulesPreviousList.length)
  const [selectedDataBox, setSelectedDataBox] = useState(null)
  const [dataBoxCount, setDataBoxCount] = useState({})
  const [selectedVisitsPeriod, setSelectedVisitsPeriod] = useState(VISITS_PERIOD_OPTIONS[0])
  const offsets = useOffsets('sticky', selectedTab)

  const onSelectDataBox = nextSelected => {
    if (nextSelected === selectedDataBox) {
      setSelectedDataBox(null)
    } else {
      setSelectedDataBox(nextSelected)
    }
  }

  const getVisitByPeriod = (visitList, { window, num }) =>
    window === 'all'
      ? visitList
      : visitList.filter(visit => {
          const date = visit.find(({ key }) => key === 'date')
          if (!date) return true
          const today = moment()
          const visitDate = moment.utc(date.value, DATE_FORMAT_MAP.main)
          const filterDate = today.subtract(num || 1, window)
          return visitDate.isSameOrAfter(filterDate)
        })

  const filteredVisitSchedulesPreviousList = useMemo(
    () => getVisitByPeriod(visitSchedulesPreviousList, selectedVisitsPeriod),
    [visitSchedulesPreviousList, selectedVisitsPeriod],
  )

  return (
    <>
      <StickyHeader offset={offsets ? offsets[0] : 0} className='column'>
        <div className='full-width-flex flex-end align-center'>
          <div className='flex'>
            <div className='periods-filter'>
              <h6>Showing visits for past</h6>
              <Dropdown
                className='periods-dropdown'
                options={VISITS_PERIOD_OPTIONS}
                onSelect={item => {
                  setSelectedVisitsPeriod(item)
                }}
                selected={selectedVisitsPeriod.key}
              />
            </div>

            {dataBoxList.map(box =>
              dataBoxCount[box.status] ? (
                <DataBox
                  key={box.status}
                  type={box.status}
                  header={box.header}
                  value={dataBoxCount[box.status] ?? 0}
                  total={schedulePreviousCount ?? 0}
                  subtitle={box.subtitle ?? ''}
                  content={box.content ?? ''}
                  onSelect={onSelectDataBox}
                  selected={selectedDataBox}
                  className={box.status ? `${box.status}-request` : ''}
                />
              ) : null,
            )}
          </div>
          {isSiteCoord ? (
            !adhocVisitsDisabled && (
              <Button
                id='create-new-adhoc-visit'
                disabled={studyLock}
                content={STRINGS.visitScheduleCreateNewAdhoc}
                onClick={() => {
                  if (!studyLock) {
                    browserHistory.push(`/studies/${studyID}/visit-schedules/participant-visit/create`)
                  }
                }}
              />
            )
          ) : (
            <>
              {canEditVisits && (
                <Button
                  id='schedule-new-visit'
                  disabled={studyLock}
                  content={STRINGS.visitScheduleScheduleNewVisit}
                  onClick={() => !studyLock && browserHistory.push(`/studies/${studyID}/virtualvisits/create`)}
                />
              )}
              <Button
                id='create-new-visit'
                disabled={studyLock}
                content={STRINGS.visitScheduleCreateNewVisit}
                onClick={() => {
                  if (!studyLock) {
                    browserHistory.push(`/studies/${studyID}/visit-schedules/template-visits/create`)
                  }
                }}
              />
            </>
          )}
        </div>
        <div className='full-width-flex space-between'>
          <div />
          <SearchInput />
        </div>
      </StickyHeader>
      {visitSchedulesLoading ? (
        <Loader inContainer className='visits-history-loader' />
      ) : (
        <>
          <FilterTagsPopup
            clearStateFilters={() => {
              setSelectedDataBox(null)
            }}
            customTags={{
              checkbox: {
                title: 'Visit status',
              },
            }}
          />
          <VisitSchedulesPreviousTable
            {...props}
            setSchedulePreviousCount={setSchedulePreviousCount}
            setDataBoxCount={setDataBoxCount}
            selectedDataBox={selectedDataBox}
            visitSchedulesPreviousList={filteredVisitSchedulesPreviousList}
            getVisitByPeriod={getVisitByPeriod}
            selectedVisitsPeriod={selectedVisitsPeriod}
            offset={offsets ? offsets[1] : 0}
          />
          {visitSchedulesPreviousList.length === 0 && (
            <div className='flexed column no-visits'>
              <h5>There are no visits</h5>
              <p>...yet!</p>
            </div>
          )}
        </>
      )}
    </>
  )
}

VisitSchedulesPrevious.propTypes = {
  adhocVisitsDisabled: PropTypes.bool,
  canEditVisits: PropTypes.bool,
  loading: PropTypes.shape({
    virtualVisits: PropTypes.bool,
  }),
  studyID: PropTypes.string,
  selectedTab: PropTypes.string,
  visitSchedules: PropTypes.shape({
    visitsSchedulePreviousList: PropTypes.arrayOf(PropTypes.array),
  }),
  visitSchedulesLoading: PropTypes.bool,
  studyLock: PropTypes.bool,
  isSiteCoord: PropTypes.bool,
}

export default VisitSchedulesPrevious
