import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { modalActions } from 'store/modal'
import { drawerActions } from 'store/drawer'
import { getUserRoleByStudy, getUserScopeByStudy } from 'utils/misc'
import SadCloud from 'components/SadCloud'
import { USER_ROLES_MAP } from 'utils/constants'
import GeofencesPage from '../components/GeofencesPage'
import { actions as geofenceActions } from '../modules/Geofences'

const mapStateToProps = state => {
  const { geofences, participants, study, loading, user, studyLock } = state
  const studyID = study.currentStudy.id
  const userRole = getUserRoleByStudy(user, studyID)
  const userScope = getUserScopeByStudy(user, study.currentStudy)

  return {
    ...participants,
    ...{
      geofences,
      studyID,
      loading: loading.general,
      geofencesLoading: loading.geofences,
      canCreate: userScope.includes('wa') && userRole !== USER_ROLES_MAP.siteCoordinator,
      canEdit: userScope.includes('wa'),
      studyLock,
    },
  }
}

const mapDispatchToProps = {
  ...modalActions,
  ...drawerActions,
  ...geofenceActions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { canCreate, loading, geofences, geofencesLoading } = props
  if (loading || geofencesLoading) {
    return <Loader />
  }
  if (!canCreate && geofences.geofencesList.length === 0) {
    return <SadCloud text='There are 0 geofences. Please check back later.' />
  }
  return <GeofencesPage {...props} />
})
