import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from './UIElements'

class CollapseMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    const { open } = this.props
    if (open) this.onToggleCollapse()
  }

  onToggleCollapse = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen })
    }
  }

  render() {
    const { className, titleContent, subtitleContent, menuContent, hasError, useErrorIcon, loading } = this.props
    const { open } = this.state
    let _className = `collapse-menu${className ? ` ${className}` : ''}${open ? ' open' : ''}`
    _className += hasError ? ' has-error' : ''

    return (
      <div className={_className}>
        <div className={`title-bar clickable${loading ? ' disabled' : ''}`} onClick={this.onToggleCollapse}>
          {loading ? (
            <div className='loading-title'>
              <div className='loader'>
                <Loader inContainer size={16} />
              </div>
              <div className='simple-title'>{titleContent}</div>
            </div>
          ) : (
            <p className='title'>{titleContent}</p>
          )}
          <p className='sub-title'>{subtitleContent}</p>
          {hasError && useErrorIcon && !open && (
            <div className='icon-wrapper'>
              <i className='fas fa-circle fa-stack-1x' />
              <i className='fas fa-exclamation-circle fa-stack-1x' />
            </div>
          )}
        </div>
        <div className='main-menu'>{menuContent}</div>
      </div>
    )
  }
}

CollapseMenu.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  loading: PropTypes.bool,
  menuContent: PropTypes.node,
  open: PropTypes.bool,
  subtitleContent: PropTypes.string,
  titleContent: PropTypes.string,
  useErrorIcon: PropTypes.bool,
}

export default CollapseMenu
