import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getUserRoleByStudy } from 'utils/misc'
import { USER_ROLES_WITH_SCHEDULE_VIRTUAL_VISIT_PERMISSION } from 'utils/constants'
import VisitsPage from '../components/VisitsPage'
import { actions } from '../modules/Visits'

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps
  const { studyID } = params
  const { loading, searchTerm, study, user, visits, studyLock } = state
  const { base_site_ids: baseSitesArr } = study.currentStudy

  const siteID = baseSitesArr[0]

  const userRole = getUserRoleByStudy(user, studyID)
  return {
    canEditVisits: USER_ROLES_WITH_SCHEDULE_VIRTUAL_VISIT_PERMISSION.includes(userRole),
    loading,
    searchTerm,
    siteID,
    studyID,
    visits,
    studyLock,
  }
}

const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { loading } = props
  if (loading.general) {
    return <Loader />
  }
  return <VisitsPage {...props} />
})
