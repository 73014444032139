import React from 'react'
import PropTypes from 'prop-types'

function DetailsPage(props) {
  return <div>DetailsPage</div>
}

DetailsPage.propTypes = {}

export default DetailsPage
