import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import ConsentFields from './ConsentFields'
import ConsentFormat from './ConsentFormat'
import '../stylesheets/consentProperties.scss'
import STRINGS from 'utils/strings'
import { consentBuilderIcon, pdfUploadIcon } from 'assets/assets'
import { CONSENT_OPTIONS_IDS, QUESTION_TYPE_MAP, CONSENT_STATUSES } from 'utils/constants'

const NewConsentProperties = props => {
  const { consent, onSave, updateConsentPdfUpload } = props
  const {
    consent_id: consentId,
    consent_status: consentStatus,
    questions,
    consent_version: consentVersion,
    pdf_upload: pdfUpload,
  } = consent

  const isFormatSelectionDisabled = useMemo(() => {
    const questionValues = Object.values(questions)
    const pdfQuestion = questionValues.find(question => question.type === QUESTION_TYPE_MAP.pdf)
    const isConsentScheduled = consentId && consentStatus === CONSENT_STATUSES.scheduled

    // Format selection buttons ( Consent Builder or PDF upload ) will be disabled if:
    // - PDF upload option selected and pdf question is added to consent OR
    // - Consent version is > 1 OR
    // - Consent is already created and deployed/scheduled
    return !!pdfQuestion || consentVersion > 1 || isConsentScheduled
  }, [questions, consentVersion, consentStatus, consentId])

  const options = [
    {
      id: CONSENT_OPTIONS_IDS.consentBuilder,
      heading: STRINGS.consent_builder_title,
      bodyText: STRINGS.consent_builder_description,
      icon: consentBuilderIcon({ className: '' }),
    },
    {
      id: CONSENT_OPTIONS_IDS.pdfUpload,
      heading: STRINGS.pdf_upload_title,
      bodyText: STRINGS.pdf_upload_description,
      icon: pdfUploadIcon({ className: '' }),
    },
  ]
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [saveAttempted, setSaveAttempted] = useState(false)

  const isConsentPdf = !!Object.values(questions).find(item => item.type === QUESTION_TYPE_MAP.pdf)

  useEffect(() => {
    if (isConsentPdf || pdfUpload) {
      // Set Pdf Upload option as selected if consent contains question with pdf type or if it is Draft PDF Upload consent
      setSelectedOption(options[1])
    }
  }, [isConsentPdf, pdfUpload])

  const onSaveConsent = (asDraft = true) => {
    const notScheduled = consentStatus !== 'scheduled'
    setSaveAttempted(true)
    const redirectPath = asDraft ? 'consents' : 'schedule'
    onSave(
      redirectPath,
      false,
      {
        isProperties: true,
        isUpdate: notScheduled && !!consentId,
        isJsonSave: true,
        isPdfUpload: selectedOption.id === CONSENT_OPTIONS_IDS.pdfUpload,
      },
      {
        query: asDraft ? '?tab=drafts' : '',
      },
    )
  }

  return (
    <div className='consent-properties page'>
      <div className='flexed'>
        <h4>Create new</h4>
        <Button id='top-save-as-draft' grey content='Save and close' onClick={onSaveConsent} />
      </div>
      <ConsentFields {...props} saveAttempted={saveAttempted} />
      <h4>File format</h4>
      <ConsentFormat
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        disabled={isFormatSelectionDisabled}
        updateConsentPdfUpload={updateConsentPdfUpload}
      />
      <div className='button-list'>
        <Button grey id='bottom-save-as-draft' content='Save and close' onClick={onSaveConsent} />
        <Button id='next' className='deploy-button' content='Save and continue' onClick={() => onSaveConsent(false)} />
      </div>
    </div>
  )
}

NewConsentProperties.propTypes = {
  consent: PropTypes.shape({
    consent_id: PropTypes.string,
    consent_status: PropTypes.string,
  }),
  onSave: PropTypes.func,
}

export default NewConsentProperties
