import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { injectReducer } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { fetchSurvey, fetchArticle } from '../EditSurvey/modules/Survey'
import { fetchClinro } from '../EditClinro/modules/Clinro'
import { fetchDiary } from '../EditDiary/modules/Diary'
import { onFetchInstrument, onFetchInstrumentEvents } from '../../modules/Instrument'
import participantsReducer, {
  fetchCohorts,
} from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'

import SurveyPreviewView from './containers/PreviewContainer'

// Sync route definition
export default store => ({
  path: ':instrumentID/preview',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */

        injectReducer(store, {
          key: 'participants',
          participantsReducer,
        })

        const { studyID, instrumentID } = nextState.params
        const { version } = nextState.location.query
        const { currentStudy } = store.getState().study
        setPageTitle(`Preview | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(fetchCohorts(studyID))
        store.dispatch(onFetchInstrument(studyID, instrumentID, version)).then(({ type }) => {
          switch (type) {
            case INSTRUMENT_TYPE_MAP.article:
              store.dispatch(fetchArticle(studyID, instrumentID))
              break
            case INSTRUMENT_TYPE_MAP.clinro:
              store.dispatch(fetchClinro(studyID, instrumentID, version))
              break
            case INSTRUMENT_TYPE_MAP.diary:
              store.dispatch(fetchDiary(studyID, instrumentID, version))
              break
            case INSTRUMENT_TYPE_MAP.survey:
            default:
              store.dispatch(fetchSurvey(studyID, instrumentID, version))
          }
        })
        /**
         * TODO: now that onFetchInstrument returns we can conditionally only fetch the
         * the instrument type that is returned so we don't make the other requests
         */
        store.dispatch(onFetchInstrumentEvents(studyID, instrumentID))

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['ra'], SurveyPreviewView, false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      '/instruments/:instrumentID/preview',
    )
  },
})
