import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from './UIElements'
import 'styles/d3/databox.scss'

const DataBox = props => {
  const { type, header, value, total, subtitle, onSelect, selected, className, content, noPointer = true } = props
  return (
    <div
      onClick={() => onSelect(type)}
      className={`data-sent-box left ${type}${selected === type ? ' box-active' : ''} ${className ?? ''}`}>
      <div className='box-header'>
        <span className='box-title'>{header}</span>
        <Popup
          className='box-popup'
          align={!!content ? 'bottom' : 'left'}
          position='bottom'
          hover
          noPointer={noPointer}
          dark
          trigger={<i className='fas fa-info-circle' />}>
          {content || <p>{subtitle}</p>}
        </Popup>
      </div>
      <div className='box-number'>
        {value}
        <span>{`/${total}`}</span>
      </div>
    </div>
  )
}

DataBox.propTypes = {
  type: PropTypes.string,
  header: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.number,
  subtitle: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.string,
  className: PropTypes.string,
}

export default DataBox
