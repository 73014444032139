import { combineReducers } from 'redux'
import { loadingActions } from 'store/loader'
import request from 'utils/request'
import { generateDateStringWithTimeZone } from 'utils/time'
import { CASE_HIERARCHY_LIST, CASE_STATUS_MAP } from '../utils/statusMaps'

//
// Actions
//

const SET_CASES = 'SET_CASES'
const SET_CASE = 'SET_CASE'

//
// Action Creators
//

const setCases = geofences => {
  return {
    type: SET_CASES,
    payload: geofences,
  }
}

const setCase = _case => {
  return {
    type: SET_CASE,
    payload: _case,
  }
}

//
// Utils
//

export const STATUS_MAP = {
  open: 'Open',
  open_no_symptoms: 'Open - No symptoms',
  reported: 'Symptoms reported',
  closed: 'Closed',
  suspected: 'Infection suspected',
  completed: 'Infection confirmed',
  confirmed: 'Infection confirmed',
}

export const ACTION_MAP = {
  open: 'Confirm symptoms reported',
  open_no_symptoms: 'Close case',
  reported: 'Confirm sample collected',
  closed: '',
  suspected: 'Confirm lab results',
  completed: 'Close case',
  confirmed: 'Confirm follow-up',
}

const parseCase = _case => {
  const progressMap = {
    suspected: false,
    confirmed: false,
  }
  const caseCopy = { ..._case }
  const { case_progress, participant_reports } = caseCopy
  let statusNo = null
  let reportedNo = false
  let reported = false
  let ptpReportYes = false
  let open = false
  let ptpReportedAfterOpen = true

  case_progress.sort((prog1, prog2) => (prog1.timestamp > prog2.timestamp ? 1 : -1))
  case_progress.forEach(progress => {
    const { status, confirmation, timestamp } = progress
    if (status === CASE_STATUS_MAP.open) {
      open = timestamp
    }

    if (status === CASE_STATUS_MAP.reported) {
      reported = true
      if (confirmation === false) reportedNo = true
      if (timestamp === open) ptpReportedAfterOpen = false
    }
    if (confirmation === false && status !== CASE_STATUS_MAP.open_no_symptoms) {
      delete progress.timestamp
      statusNo = status
    }
    progressMap[status] = true
  })

  participant_reports.forEach(report => {
    if (report.has_symptom) ptpReportYes = true
  })

  if (reported) {
    if (!progressMap.suspected) case_progress.push({ status: 'suspected', confirmation: null })
    if (!progressMap.confirmed) case_progress.push({ status: 'confirmed', confirmation: null })
  }

  if (statusNo) {
    const prevStatusIndex = CASE_HIERARCHY_LIST.indexOf(statusNo) - 1
    const newStatus = CASE_HIERARCHY_LIST[prevStatusIndex]
    if (caseCopy.status !== CASE_STATUS_MAP.closed && caseCopy.status !== CASE_STATUS_MAP.open_no_symptoms) {
      caseCopy.status = newStatus
    }
  }

  caseCopy.info = {
    reportedNo,
    ptpReportYes,
    ptpReportedAfterOpen,
  }

  delete caseCopy.message

  return caseCopy
}

const parseCasesList = ({ cases }) => {
  const list = cases.map(_case => {
    const { case_id, case_sequence_num, participant_id, status, created, has_new_edits, status_response } = _case
    const dateString = generateDateStringWithTimeZone(created)

    let newStatus = status
    let newActionStatus = status

    if (status_response === false && status !== CASE_STATUS_MAP.open_no_symptoms) {
      newActionStatus = 'open_no_symptoms'
      const prevStatusIndex = CASE_HIERARCHY_LIST.indexOf(status) - 1
      const prevStatus = CASE_HIERARCHY_LIST[prevStatusIndex]
      newStatus = prevStatus
    }

    return [
      { key: 'hasNewEdits', value: has_new_edits ? 'a' : 'z', sortValue: has_new_edits ? 'a' : 'z' },
      { key: 'id', value: case_sequence_num, sortValue: case_sequence_num, case_id },
      { key: 'participantId', value: participant_id, sortValue: participant_id },
      { key: 'status', value: newStatus, sortValue: STATUS_MAP[newStatus] },
      { key: 'actionNeeded', value: newActionStatus, sortValue: ACTION_MAP[newActionStatus] },
      { key: 'created', value: dateString, sortValue: created.valueOf() },
    ]
  })

  return list
}

// Fetch functions

const fetchCases = studyId => dispatch => {
  const success = payload => dispatch(setCases(payload))
  return dispatch(
    request({
      method: 'GET',
      url: `/control/studies/${studyId}/cases`,
      success,
      hasLoader: true,
      fail: () => {},
    }),
  )
}

const fetchCase = (studyId, caseId) => dispatch => {
  const success = payload => {
    dispatch(setCase(payload))
  }
  dispatch(loadingActions.startLoader())
  return dispatch(
    request({
      method: 'GET',
      url: `/control/studies/${studyId}/cases/${caseId}`,
      failMessage: 'Error fetching case.',
      success,
    }),
  ).then(() => dispatch(loadingActions.stopLoader()))
}

const postNote = (note, studyId, caseId) => dispatch => {
  const body = JSON.stringify({ note })
  const success = () => {
    dispatch(fetchCase(studyId, caseId))
  }
  dispatch(loadingActions.startLoader())
  return dispatch(
    request({
      method: 'POST',
      url: `/control/studies/${studyId}/cases/${caseId}/notes`,
      success,
      body,
    }),
  )
}

const updateCaseProgress = (status, confirmationBool, studyId, caseId) => dispatch => {
  const success = () => {
    dispatch(fetchCase(studyId, caseId))
  }
  const body = JSON.stringify({ confirmation: confirmationBool })
  dispatch(loadingActions.startLoader())
  return dispatch(
    request({
      method: 'PATCH',
      url: `/control/studies/${studyId}/cases/${caseId}/${status}`,
      success,
      body,
    }),
  )
}

//
// Reducers
//

const casesList = (state = [], action) => {
  switch (action.type) {
    case SET_CASES:
      return parseCasesList(action.payload)
    default:
      return state
  }
}

const currentCase = (state = {}, action) => {
  switch (action.type) {
    case SET_CASE:
      return parseCase(action.payload)
    default:
      return state
  }
}

export default combineReducers({
  casesList,
  currentCase,
})

export const actions = {
  fetchCases,
  fetchCase,
  updateCaseProgress,
  postNote,
}
