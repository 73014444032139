import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { injectReducers } from 'store/reducers'
import announcementReducer, { announcementActions } from '../EditAnnouncement/modules/Announcement'
import ptpsReducer, { fetchCohorts } from '../../../Participants/routes/ParticipantsPage/modules/Participants'
import ptpReducer, { fetchLocales } from '../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import AnnouncementView from '../EditAnnouncement/containers/AnnouncementContainer'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'

export default store => ({
  path: 'createannouncement',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        // inject announcement specific reducers
        injectReducers(store, {
          announcementReducer,
          participants: ptpsReducer,
          participantReducer: ptpReducer,
          sites: sitesReducer,
        })

        const { params } = nextState
        const { studyID, announcementID } = params
        store.dispatch(fetchCohorts(studyID))
        store.dispatch(announcementActions.initializeAnnouncementPage({ studyID, communicationId: announcementID }))

        // initilize or reset the announcement reducer
        store.dispatch(announcementActions.resetAnnouncement())

        Promise.all([store.dispatch(fetchSites(studyID, false, true)), store.dispatch(fetchLocales())])
        // UserIsAuthenticated wrapper redirects user to the login page if credentials are invalid
        // UserHasScope wrapper redirects user to the permission denied page if user does not have the correct permisions
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'communication')(UserHasScope(['xp'], AnnouncementView, false, studyID)),
            ),
          ),
        )
      },
      'communication/createannouncement',
    )
  },
})
