import React from 'react'
import { Radio, DatePicker, TimePicker } from 'components/UIElements'
import STRINGS from '../../../../../../../../../../../../utils/strings'

const SpecificDateRange = ({
  id,
  onClick,
  disabled,
  selected,
  isDateTime,
  hasError,
  onDayChange,
  date,
  overlayComponent,
  value,
  onChange,
  addon,
  setRef,
  _ref,
  placeholder,
  disabledDays,
  inputClassName = '',
  timePickerPlaceholder = '',
  disablePriorToTime,
  onDateContainerClick,
}) => {
  return (
    <Radio
      disabled={disabled}
      id={id}
      onClick={onClick}
      selected={selected}
      content={
        <div className='option-wrapper'>
          {isDateTime ? (
            <div className='option-inner'>
              {placeholder ? (
                <DatePicker
                  disabledDays={disabledDays}
                  disabled={disabled}
                  initialDate={undefined}
                  onDayChange={onDayChange}
                  date={date}
                  placeholder={placeholder}
                  id='specific-date-picker'
                  overlayComponent={overlayComponent}
                  setRef={setRef}
                  _ref={_ref}
                  inputClassName={inputClassName}
                  onContainerClick={onDateContainerClick}
                />
              ) : null}
              <span className={disabled ? 'disabled' : ''}>{STRINGS.at}</span>
              <TimePicker
                allowEmpty
                use12Hours
                disabled={disabled}
                hasError={!!hasError && selected}
                value={value}
                onChange={onChange}
                addon={addon}
                popupClassName='timepicker-overlay'
                placeholder={timePickerPlaceholder}
                className={timePickerPlaceholder ? 'long' : ''}
                disablePriorToTime={disablePriorToTime}
              />
              <span className={disabled ? 'disabled' : ''}>{STRINGS.ofRecipientLocalTime}</span>
            </div>
          ) : (
            <>
              <span className={disabled ? 'disabled' : ''}>{STRINGS.specificDate}</span>
              {placeholder ? (
                <DatePicker
                  disabledDays={disabledDays}
                  disabled={disabled}
                  initialDate={undefined}
                  onDayChange={onDayChange}
                  date={date}
                  placeholder={placeholder}
                  id='specific-date-picker'
                  overlayComponent={overlayComponent}
                  setRef={setRef}
                  _ref={_ref}
                  inputClassName={inputClassName}
                  onContainerClick={onDateContainerClick}
                />
              ) : null}
            </>
          )}
        </div>
      }
    />
  )
}

export default SpecificDateRange
