/**
 * For having loading state and change it inside the class component
 * @param {Func} func action that needs to bind and action should return promise
 * @param {String} field loading state field
 * How to use it: For example with this 'state' and action:
 * this.state = { loadingField: false }
 * actionFunc(arg1, arg2)
 * We should use call and put there binded function if it has arguments
 * and field name from state
 * handleComponentStateLoading.call(actionFunc.bind(this, arg1, arg2), 'loadingField')
 * As a result: when we need to call action we change loading state field bedore and after request
 */

function handleComponentStateLoading(func, field) {
  this.setState({ [field]: true })
  func().finally(() => {
    this.setState({ [field]: false })
  })
}

export default handleComponentStateLoading
