import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SortableTable } from 'components/UIElements'
import STRINGS from 'utils/strings'

const KEY_NAME_MAP = {
  visitName: 'Visit Name',
  visitDay: 'Visit Day',
  scheduledDate: 'Scheduled Date',
  actualDate: 'Actual Date',
  status: 'Status',
}

function ParticipantVisitTable(props) {
  const {
    fetchParticipantVisits,
    isUpdate,
    participantVisitList,
    participantVisit,
    ptpVisitsLoading,
    studyID,
    ssvpID,
  } = props
  const { checkedPtp, isAdhoc } = participantVisit
  const isPtpChecked = Object.keys(checkedPtp).length > 0

  const [lastPtpId, setLastPtpId] = useState(null)

  useEffect(() => {
    const checkedPtpKeyArr = Object.keys(checkedPtp)
    if (checkedPtpKeyArr.length > 0 && lastPtpId !== checkedPtpKeyArr[0]) {
      setLastPtpId(checkedPtpKeyArr[0])
      /**
       * The third parameter in fetchParticipantVisits is whether the current visit being edited or created
       * is an adhoc visit. At the time of creation, adhoc visits aren't designate as adhoc as of yet, so
       * if the current operation is not an update of an existing visit, the user would be creating an adhoc
       * visits, as these are the only type of participant visits that can be created. Non-adhoc participant visits
       * are generated from study visit schedule templates.
       */
      fetchParticipantVisits(studyID, checkedPtpKeyArr[0], isAdhoc || !isUpdate, isUpdate, ssvpID)
    }
  }, [checkedPtp, lastPtpId])

  const headerOptionsSeed = Object.keys(KEY_NAME_MAP).map(key => ({
    key,
    sortable: false,
    component: KEY_NAME_MAP[key],
  }))

  const getHeaderOptions = () => {
    const newHeaderOptions = [...headerOptionsSeed]
    return newHeaderOptions
  }

  return (
    isPtpChecked && (
      <div className='ptp-visit-table'>
        <p className='label-small'>{STRINGS.visitScheduleVisitsInSchedule}</p>
        <SortableTable
          emptyText='No Matching Participant Visits'
          headerOptions={getHeaderOptions()}
          id='participant-visit-table'
          initialOrder='descending'
          rowList={participantVisitList}
          sortingBy='scheduledDate'
          viewMoreWithTablePages={{ minRows: 10, maxRows: 10 }}
          loadingBody={ptpVisitsLoading}
        />
      </div>
    )
  )
}

ParticipantVisitTable.propTypes = {
  fetchParticipantVisits: PropTypes.func,
  isUpdate: PropTypes.bool,
  participantVisitList: PropTypes.arrayOf(PropTypes.array),
  participantVisit: PropTypes.object,
  ptpVisitsLoading: PropTypes.bool,
  ssvpID: PropTypes.string,
  studyID: PropTypes.number,
}

export default ParticipantVisitTable
