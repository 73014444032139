import { browserHistory } from 'react-router'

export const redirectVisit = ({ visitScheduleSubroute, redirectPath, studyID, visitID, queryParams }) => {
  if (redirectPath) {
    if (redirectPath === 'visit-schedules' || !visitID) {
      browserHistory.push(`/studies/${studyID}/visit-schedules${queryParams || ''}`)
    } else if (visitScheduleSubroute === 'visit-templates' && redirectPath === 'details' && visitID) {
      browserHistory.push(
        `/studies/${studyID}/visit-schedules/${visitScheduleSubroute}/${visitID}/${redirectPath}${queryParams || ''}`,
      )
    }
  } else {
    browserHistory.push(`/studies/${studyID}/visit-schedules${queryParams || ''}`)
  }
}

export const onRedirectVisit = ({ resJSON, redirectPath, studyID, queryParams, visitScheduleSubroute }) => (
  dispatch,
  getState,
) => {
  if (resJSON && !resJSON.ok && resJSON.status === 412) return
  const { location } = getState()
  const path = location.pathname.match(/([^/]+$)/)[0]

  // if !study_visit_template_id use the id from the pathname
  const visitID = resJSON?.study_visit_template_id || location.pathname.replace(`/${path}`, '').match(/([^/]+$)/)[0]
  redirectVisit({ redirectPath, studyID, visitID, queryParams, visitScheduleSubroute })
}
