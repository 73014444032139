import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/UIElements'
import { setObjectProperty } from 'utils/object'

const DashboardItem = props => {
  const {
    dashboardName,
    insight,
    dashboardRoles,
    setDashboardRoles,
    checkboxListData,
    rolesList = [],
    loading,
    studyLock,
  } = props
  const toggleRole = key => {
    setDashboardRoles(prev => {
      const prevState = JSON.parse(JSON.stringify(prev))
      const dashboard = {
        dashboard_name: dashboardName,
        hidden_role_ids: {},
        metadata: { group_id: insight.group_id || '' },
      }
      if (!prevState[dashboardName]) prevState[dashboardName] = { ...dashboard }
      if (
        prevState[dashboardName] &&
        prevState[dashboardName].hidden_role_ids &&
        prevState[dashboardName].hidden_role_ids[key]
      )
        delete prevState[dashboardName].hidden_role_ids[key]
      else setObjectProperty(prevState, `${dashboardName}.hidden_role_ids.${key}`, true)
      return prevState
    })
  }

  return (
    <div>
      <div className='flexed start-justified start-aligned'>
        <span>Description of dashboard here</span>
      </div>
      <div className='dashboard-permissions'>
        <span className='dashboard-permissions-col-title'>selected user role</span>
        <span className='dashboard-permissions-col-title'>dashboard visibility</span>
        {!rolesList.filter(({ key }) => checkboxListData[key]).length && (
          <span className='no-roles'>No user selected to have access to Insights Dashboard</span>
        )}
        {rolesList
          .filter(({ key }) => checkboxListData[key])
          .map(({ key, text }) => {
            const checked = !(
              dashboardRoles[dashboardName] &&
              dashboardRoles[dashboardName].hidden_role_ids &&
              dashboardRoles[dashboardName].hidden_role_ids[key]
            )
            return (
              <React.Fragment key={key}>
                <div className='dashboard-permissions-item'>
                  <span className='setting-text'>{text}</span>
                </div>
                <div className='dashboard-permissions-item'>
                  <Checkbox toggle disabled={loading || studyLock} checked={checked} onClick={() => toggleRole(key)} />
                  <span className='setting-text'>{checked ? 'Shown' : 'Hidden'}</span>
                </div>
              </React.Fragment>
            )
          })}
      </div>
    </div>
  )
}

DashboardItem.propTypes = {
  dashboardName: PropTypes.string,
  setDashboardRoles: PropTypes.func,
  rolesList: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  studyLock: PropTypes.bool,
  insight: PropTypes.object,
  dashboardRoles: PropTypes.object,
  checkboxListData: PropTypes.object,
}

export default DashboardItem
