import request, { generateNotyMessage } from 'utils/request'
import { actions as notyActions } from 'layouts/ErrorBox'
import { loadingActions } from 'store/loader'

//
// Actions
//

const INITIALIZE_DATA_CORRECTION_FORM = 'INITIALIZE_DATA_CORRECTION_FORM'
const SET_DATA_CORRECTION_FORM = 'SET_DATA_CORRECTION_FORM'
const SET_DATA_CORRECTION_FORM_SENT = 'SET_DATA_CORRECTION_FORM_SENT'

//
// Action Creators
//

const initializeDataCorrectionForm = () => {
  return {
    type: INITIALIZE_DATA_CORRECTION_FORM,
  }
}

const setDataCorrectionForm = path => {
  return {
    type: SET_DATA_CORRECTION_FORM,
    payload: path,
  }
}

const setDataCorrectionFormSent = payload => {
  return {
    type: SET_DATA_CORRECTION_FORM_SENT,
    payload,
  }
}

//
// Fetch Functions
//

const getDataCorrectionForm = studyID => {
  return dispatch => {
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/datacorrection`,
        errorMessage: `error fetching form`,
        success: payload => dispatch(setDataCorrectionForm(payload.dcf_form_path)),
        hasLoader: true,
      }),
    )
  }
}

const uploadDataCorrectionForm = (formData, catchMessage) => {
  return dispatch => {
    const success = () => {
      dispatch(setDataCorrectionFormSent(true))
    }
    const fail = () => {
      dispatch(
        notyActions.showError({
          text: generateNotyMessage('There was an error sending the DCF form. Please try submitting again.', false),
        }),
      )
    }

    dispatch(loadingActions.startLoader())
    return dispatch(
      request({
        method: 'POST',
        url: '/control/studies/datacorrection',
        contentType: 'multipart/form-data',
        body: formData,
        hasLoader: true,
        success,
        fail,
        catchMessage,
      }),
    )
  }
}

//
// Reducers
//

const _defaultState = {
  path: '',
  isFormSent: false,
}

const DataCorrection = (state = _defaultState, action) => {
  switch (action.type) {
    case SET_DATA_CORRECTION_FORM:
      return {
        path: action.payload,
      }
    case SET_DATA_CORRECTION_FORM_SENT: {
      return {
        ...state,
        isFormSent: action.payload,
      }
    }
    case INITIALIZE_DATA_CORRECTION_FORM:
      return _defaultState
    default:
      return state
  }
}

export default DataCorrection

export const actions = {
  initializeDataCorrectionForm,
  setDataCorrectionForm,
  setDataCorrectionFormSent,
  getDataCorrectionForm,
  uploadDataCorrectionForm,
}
