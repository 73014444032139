import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { browserHistory } from 'react-router'
import LockRedirect from 'containers/lockContainers'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { setPageTitle } from 'utils/misc'
import { instrumentActions, setInstrument } from '../../modules/Instrument'
import MetadataBuilderView from './containers/MetadataBuilderContainer'
import hasInstrumentFeatures from '../../../../utils/hasInstrumentFeatures'
import { fetchSurvey } from '../EditSurvey/modules/Survey'
import { fetchClinro } from '../EditClinro/modules/Clinro'
import { fetchDiary } from '../EditDiary/modules/Diary'
// Sync route definition
export default store => ({
  path: ':instrumentID/properties',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */
        const { studyID, instrumentID } = nextState.params
        const { study } = store.getState()
        setPageTitle(`Properties | Study ${studyID} | ${study.currentStudy.platform_name}`)
        store.dispatch(instrumentActions.onFetchInstrument(studyID, instrumentID)).then(({ type }) => {
          const {
            currentStudy: { config },
          } = store.getState().study
          if (!hasInstrumentFeatures(config, type)) browserHistory.push(`/studies/${studyID}/instruments`)

          switch (type) {
            case INSTRUMENT_TYPE_MAP.clinro:
              store.dispatch(fetchClinro(studyID, instrumentID))
              break
            case INSTRUMENT_TYPE_MAP.diary:
              store.dispatch(fetchDiary(studyID, instrumentID))
              break
            case INSTRUMENT_TYPE_MAP.survey:
              store.dispatch(fetchSurvey(studyID, instrumentID))
              break
            default:
              break
          }

          const { instrumentReducer } = store.getState()
          const { instrument } = instrumentReducer
          if (instrument.metadata.display_name === '') {
            const _instrument = instrument
            _instrument.metadata.display_name = instrument.title
            store.dispatch(setInstrument(_instrument))
          }
        })

        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['wa'], MetadataBuilderView, false, studyID)),
            ),
          ),
        )
        /* Webpack named bundle   */
      },
      'instruments/metadata',
    )
  },
})
