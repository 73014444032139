import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { STUDY_CONFIG_MAP, STUDY_SUBCONFIG_MAP } from 'utils/constants'
import { getBaseSiteIdFromStudy } from 'utils/misc'
import { modalActions } from 'store/modal'
import { setNavBarProps } from 'store/navbar'
import { actions as ptpsActions } from '../../ParticipantsPage/modules/Participants'
import MoveParticipant from '../components/MoveParticipant'

const mapStateToProps = state => {
  const { loading, location, studies, study, participants, sites } = state
  const { single, singlePtpId } = location?.state || {}
  const { id: studyID, platform_name: studyName, config = {} } = study.currentStudy

  const canEditRewardProps = !config?.[STUDY_CONFIG_MAP.movePtps]?.[STUDY_SUBCONFIG_MAP.rewardPropsEditingDisabled]

  return {
    canEditRewardProps,
    ...participants,
    participantList: Object.values(participants.participants),
    participantSelectionList: participants.movePtpsList,
    numTotalPtps: Object.keys(participants.participants).length,
    filterProps: state.tableFilters,
    loading,
    single,
    singlePtpId,
    siteID: getBaseSiteIdFromStudy(study),
    studies: studies.filter(_study => _study.id !== studyID),
    sitesAndTracksByStudies: sites.sitesByStudies,
    studyID,
    studyName,
  }
}

const mapDispatchToProps = {
  ...modalActions,
  ...ptpsActions,
  setNavBarProps,
  togglePtp: ptpsActions.toggleFilterParticipant,
  togglePtpOn: ptpsActions.toggleFilterParticipantOn,
  togglePtpOff: ptpsActions.toggleFilterParticipantOff,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => (props.loading.general ? <Loader /> : <MoveParticipant {...props} />))
