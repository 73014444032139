import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { SortableTable, PercentMeter, Button, ExpandableList } from 'components/UIElements'
import generateLocalesMap from 'utils/locales'
import '../stylesheets/announcements.scss'

class AnnouncementsPage extends React.Component {
  constructor(props) {
    super(props)
    const { currentCommunicationType } = this.props
    this.state = {
      selectedFilter: 'outbox',
      selectedSMSFilter: 'outbox',
      selectedPage: currentCommunicationType,
      toolTipX: null,
      toolTipY: null,
      languageMap: {},
    }
    this.filters = {
      outbox: this.outboxFilter.bind(this),
      draft: this.draftFilter.bind(this),
    }
  }

  componentDidMount() {
    const { locales } = this.props
    this.setState({ languageMap: generateLocalesMap(locales) })
  }

  onSelectFilter = (selectedFilter, isAnnouncements = true) => {
    if (isAnnouncements) {
      this.setState({ selectedFilter })
    } else {
      this.setState({ selectedSMSFilter: selectedFilter })
    }
  }

  onSelectPage = selectedPage => {
    const { setCommunicationType } = this.props
    setCommunicationType(selectedPage)
    this.setState({ selectedPage })
  }

  _isSelected = (filterName, isAnnouncements = true) => {
    const { selectedFilter, selectedSMSFilter } = this.state
    if (isAnnouncements) return selectedFilter === filterName
    return selectedSMSFilter === filterName
  }

  _isSelectedPage = filterName => {
    const { selectedPage } = this.state
    return selectedPage === filterName
  }

  outboxFilter = props => {
    return props?.deployed
  }

  renderReadStatus = rowProps => {
    return (
      <td key='read-percent-cell'>
        <PercentMeter ratio={rowProps.value} />
      </td>
    )
  }

  renderTitle = (rowProps, idx) => {
    const { isAnnouncement, translations } = rowProps
    const { languageMap, toolTipX, toolTipY } = this.state
    let langArr = ['en_US']
    if (translations) langArr = Object.keys(translations)

    const languageTags = langArr.map(languageId => {
      return (
        <React.Fragment key={`${languageId}-announcement-tag`}>
          <div className='tag' onMouseMove={e => this.setState({ toolTipX: e.clientX, toolTipY: e.clientY })}>
            {languageId.toUpperCase()}
          </div>
          <div
            className='language-name-tooltip'
            style={{
              top: `${toolTipY + 10}px`,
              left: `${toolTipX + 10}px`,
            }}>
            {languageMap[languageId]}
          </div>
        </React.Fragment>
      )
    })

    return (
      <td className='title-column' key={`row_${idx}`}>
        <div className='flexed start-justified start-aligned'>
          <div>
            <div className='name'>{rowProps.value}</div>
            {isAnnouncement && <div className='languages flexed start-justified'>{languageTags}</div>}
          </div>
        </div>
      </td>
    )
  }

  renderRecipients = ({ value, idx }) => (
    <td key={`row-${idx}`}>
      {value.map((item, index) => {
        if (!item?.label)
          return (
            <div className='recipients' key={item.label || index}>
              {item}
            </div>
          )
        return <ExpandableList className='recipients' key={index.toString()} {...item} />
      })}
    </td>
  )

  onRowClick = rowData => {
    const { selectedPage } = this.state
    const isAnnouncements = selectedPage === 'announcements'
    const { params } = this.props
    const { studyID } = params
    const announcementID = rowData[0].id
    browserHistory.push(
      `/studies/${studyID}/communication/${isAnnouncements ? 'announcement' : 'sms'}/${announcementID}`,
    )
  }

  draftFilter = props => {
    return !props.deployed
  }

  renderPage = (isAnnouncements = false) => {
    const { announcementsList, smsList, params, studyLock } = this.props
    const { studyID } = params
    const { selectedFilter, selectedSMSFilter } = this.state
    let headerOptions = [
      { key: 'title', sortable: true, component: isAnnouncements ? 'Subject' : 'Label' },
      { key: 'recipients', sortable: false, component: 'Recipients' },
      { key: 'lastEdited', sortable: true, component: 'Last Edited' },
      { key: 'scheduled', sortable: true, component: 'Date Scheduled' },
      { key: 'readStatus', sortable: true, component: 'Read Status' },
    ]

    const currentSelectedFilter = isAnnouncements ? selectedFilter : selectedSMSFilter

    if (selectedFilter === 'draft' || !isAnnouncements) {
      headerOptions = headerOptions.slice(0, headerOptions.length - 1)
    }

    const renderFunctions = {
      readStatus: this.renderReadStatus,
      title: this.renderTitle,
      recipients: this.renderRecipients,
    }

    return (
      <div>
        <div className='flexed-header'>
          <div />
          <Button
            disabled={studyLock}
            id={isAnnouncements ? 'create-announcement' : 'new-message'}
            content={isAnnouncements ? 'Create Announcement' : 'New Message'}
            onClick={() =>
              !studyLock &&
              browserHistory.push(`/studies/${studyID}/communication/create${isAnnouncements ? 'announcement' : 'sms'}`)
            }
          />
        </div>
        <div className='selected-button-list sub'>
          <Button
            id='outbox-tab'
            selected={this._isSelected('outbox', isAnnouncements)}
            onClick={() => {
              this.onSelectFilter('outbox', isAnnouncements)
            }}
            content='Outbox'
          />
          <Button
            id='draft-tab'
            selected={this._isSelected('draft', isAnnouncements)}
            onClick={() => {
              this.onSelectFilter('draft', isAnnouncements)
            }}
            content='Drafts'
          />
        </div>
        <SortableTable
          id={isAnnouncements ? 'announcement-table' : 'sms-table'}
          emptyText={currentSelectedFilter === 'outbox' ? 'Your outbox is empty.' : 'You have no outstanding drafts.'}
          onRowClick={this.onRowClick}
          filters={{ scheduled: this.filters[currentSelectedFilter] }}
          sortingBy='lastEdited'
          initialOrder='descending'
          renderFunctions={renderFunctions}
          headerOptions={headerOptions}
          rowList={isAnnouncements ? announcementsList : smsList}
        />
      </div>
    )
  }

  render() {
    const { selectedPage } = this.state
    const { communication, isClinro } = this.props
    const isSmsDisabled = communication?.sms[isClinro ? 'clinro' : 'admin'].enabled ?? true

    const isAnnouncements = selectedPage === 'announcements'

    return (
      <div className='announcements page'>
        <div className='selected-button-list '>
          <Button
            id='announcements-tab'
            selected={this._isSelectedPage('announcements')}
            onClick={() => {
              this.onSelectPage('announcements')
            }}
            content='Announcements'
          />
          {isSmsDisabled && (
            <Button
              id='sms-tab'
              selected={this._isSelectedPage('sms')}
              onClick={() => {
                this.onSelectPage('sms')
              }}
              content='SMS'
            />
          )}
        </div>
        {isAnnouncements ? this.renderPage(isAnnouncements) : this.renderPage()}
      </div>
    )
  }
}

AnnouncementsPage.propTypes = {
  announcementsList: PropTypes.arrayOf(PropTypes.array),
  currentCommunicationType: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.shape({
    studyID: PropTypes.string,
  }),
  studyLock: PropTypes.bool,
  setCommunicationType: PropTypes.func,
  smsList: PropTypes.arrayOf(PropTypes.array),
}

export default AnnouncementsPage
