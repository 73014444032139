import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'components/UIElements'
import '../styles/d3/checkbox.scss'

const Checkbox = props => {
  const { checked, className, disabled, label, loading, onClick, stopPropagation, tabbable, toggle, id } = props
  const _className = `${toggle ? 'toggle' : 'checkbox'}${disabled ? ' disabled' : ''}`
  const _onClick = e => {
    if (stopPropagation) e.stopPropagation()
    onClick()
  }
  const disabledClass = disabled ? ' disabled' : ''
  return (
    <div
      id={id}
      tabIndex={tabbable ? 0 : -1}
      onClick={disabled || !onClick ? null : _onClick}
      className={`${_className}${checked ? ' checked' : ''}${className ? ` ${className}` : ''}`}>
      <div className={`check${checked ? ' checked' : ''}${disabledClass}`}>
        <div id='round-slider' className='round-slider'>
          <Loader
            noLoader={!loading}
            noBackground={!loading}
            noAnimate={!loading}
            className={`circle${loading ? '' : ' default'}`}
            inContainer
            size={20}
          />
        </div>
      </div>
      <span className='label'>{label}</span>
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  stopPropagation: PropTypes.func,
  tabbable: PropTypes.bool,
  toggle: PropTypes.bool,
}

export default Checkbox
