import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { PromptBar, Radio } from 'components/UIElements'
import { DEPLOYMENT_KEYS, DEPLOYMENT_VALUES, INSTRUMENT_TYPE_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import { pluralize } from 'utils/misc'
import DurationInput from '../DurationInput'
import { DAY_MULTIPLIER_MAP } from '../../constants/schedulerConstants'
import { ARTICLE_TYPE } from '../../../../../../utils/instrumentConstants'

const DAYS_OFFSET = DEPLOYMENT_KEYS.daysOffset

const VisitExpireContent = props => {
  const {
    deploy,
    disabled,
    isVisitSummary,
    expire,
    instrument,
    selected,
    updateRecurring,
    updateExpire,
    updateExpireField,
  } = props
  const { metadata, type } = instrument
  const isArticle = type === INSTRUMENT_TYPE_MAP.article
  const isMissedVisitArticle = metadata.article_type === ARTICLE_TYPE.missed_visit_follow_up.key
  const isVisitSummaryArticle = metadata.article_type === ARTICLE_TYPE.visit_summary.key
  const { completion_of_visit, receipt_of_article } = selected
  const [offsetTimeUnit, setOffsetTimeUnit] = useState('days')
  const [offsetDays, setOffsetDays] = useState(expire.days_offset || 0)

  const { days_offset: deployDaysOffset } = deploy
  const deployDaysOffsetIsGreaterThanZero = deployDaysOffset > 0

  useEffect(() => {
    if (receipt_of_article || completion_of_visit) {
      const dayMultiplier = DAY_MULTIPLIER_MAP[offsetTimeUnit]
      const newOffsetVal = offsetDays * dayMultiplier
      updateExpireField(DAYS_OFFSET, newOffsetVal)
    }
  }, [offsetDays, offsetTimeUnit, completion_of_visit, receipt_of_article])

  const renderDaysInput = (inputProps = {}) => {
    const { isAfterVisit = false, isSelected = false } = inputProps

    const onSelect = val => {
      // prevents user from entering negative number
      if (Number(val) < 0) {
        setOffsetDays({ offsetDays: 1 })
      } else {
        const isNum = val || val === '0' || val === 0
        if (isAfterVisit && deployDaysOffset && deployDaysOffset >= 0 && Number(val) < deployDaysOffset) {
          setOffsetDays(deployDaysOffset)
        } else {
          setOffsetDays(isNum ? Number(val) : val)
        }
      }
    }

    const onChange = val => {
      // prevents user from entering negative number
      if (Number(val) < 0) {
        setOffsetDays({ offsetDays: 1 })
      } else {
        const isNum = val || val === '0' || val === 0
        setOffsetDays(isNum ? Number(val) : val)
      }
    }
    const onBlur = e => {
      if (e.target.value === '') {
        setOffsetDays(1)
      }
      if (isAfterVisit) {
        const { days_offset: expireDaysOffset } = expire
        if (deployDaysOffset && deployDaysOffset >= 0 && expireDaysOffset < deployDaysOffset) {
          setOffsetDays(deployDaysOffset)
          setOffsetTimeUnit('days')
        }
      }
    }
    const onDropdownSelect = item => {
      setOffsetTimeUnit(item.key)
    }

    const preText = STRINGS.expire
    const postText = (
      <>
        <span>{isAfterVisit ? STRINGS.afterCompletionOfVisit : STRINGS.afterReceiptOfArticle}</span>
      </>
    )
    const getMin = () => {
      if (isAfterVisit) {
        if (deployDaysOffset && deployDaysOffset >= 0) return deployDaysOffset
        return 0
      }
      return 1
    }

    return (
      <DurationInput
        disabled={disabled}
        isSelected={isSelected}
        onBlur={onBlur}
        onChange={onChange}
        onSelect={onSelect}
        inputValue={String(offsetDays)}
        unitValue={offsetTimeUnit}
        onDropdownSelect={onDropdownSelect}
        preText={preText}
        postText={postText}
        min={getMin()}
      />
    )
  }

  // TODO: On click of radio option for expire after visit, we need to prefill the days offset
  // if the days offset for deploy is non-negative

  const onClickOfAfterVisitOption = () => {
    updateRecurring(null)
    updateExpire({ expire_type: DEPLOYMENT_VALUES.completionOfVisit })
    if (deployDaysOffsetIsGreaterThanZero) {
      setOffsetDays(deployDaysOffset)
      setOffsetTimeUnit('days')
    } else {
      setOffsetDays(offsetDays || 1)
    }
  }

  return (
    <>
      <Radio
        id='exp-rec-after-article'
        selected={selected.receipt_of_article}
        disabled={isVisitSummary}
        onClick={() => {
          updateRecurring(null)
          setOffsetDays(1)
          updateExpire({ expire_type: DEPLOYMENT_VALUES.receiptOfArticle })
        }}
        content={renderDaysInput({ isAfterVisit: false, isSelected: selected.receipt_of_article })}
      />
      {!isMissedVisitArticle && (
        <>
          <Radio
            id='exp-days-after-visit'
            selected={selected.completion_of_visit}
            disabled={isVisitSummary}
            onClick={onClickOfAfterVisitOption}
            content={renderDaysInput({ isAfterVisit: true, isSelected: selected.completion_of_visit })}
          />
          {deployDaysOffsetIsGreaterThanZero && selected.completion_of_visit && (
            <PromptBar>
              <strong>Please note:</strong>
              <span>{' This item deploys '}</span> <strong>{`${pluralize(deployDaysOffset, 'day', 'days')}`}</strong>{' '}
              <span>
                {' after a visit is completed. Please set the item expiration date after the item deployment date.'}
              </span>
            </PromptBar>
          )}
        </>
      )}
      {isArticle && isVisitSummaryArticle ? (
        <Radio
          id='never-expire-radio'
          selected={selected.never}
          onClick={() => {
            updateRecurring(null)
            updateExpire({ never: true })
          }}
          content={`${STRINGS.neverExpire}`}
        />
      ) : null}
    </>
  )
}

VisitExpireContent.propTypes = {
  deploy: PropTypes.shape({ days_offset: PropTypes.number }),
  disabled: PropTypes.bool,
  expire: PropTypes.shape({
    days_offset: PropTypes.number,
  }),
  selected: PropTypes.shape({
    completion_of_visit: PropTypes.bool,
    receipt_of_article: PropTypes.bool,
  }),
  updateExpire: PropTypes.func,
  updateExpireField: PropTypes.func,
  updateRecurring: PropTypes.func,
}

export default VisitExpireContent
