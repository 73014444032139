import { injectReducers } from 'store/reducers'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserHasFeatures } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import cohortReducer, { actions as cohortActions } from '../CreateCohort/modules/cohort'
import { actions as ptpActions } from '../ParticipantsPage/modules/Participants'
import CohortContainer from '../CreateCohort/containers/CohortContainer'
import sitesReducer from '../../../Sites/routes/SitesPage/modules/Sites'

const disabledFeatures = [{ name: 'tracks', props: ['disabled'] }]

// Sync route definition
export default store => ({
  path: 'tracks/:cohortID',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducers(store, {
          sites: sitesReducer,
          cohort: cohortReducer,
        })
        const { params } = nextState
        const { studyID, cohortID } = params

        store.dispatch(ptpActions.fetchPtpsAndCohorts(studyID))
        store.dispatch(cohortActions.fetchCohort(studyID, cohortID))

        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'participants',
              )(
                UserHasScope(
                  ['rp', 'rp-no-pii'],
                  UserHasFeatures(
                    CohortContainer,
                    disabledFeatures,
                    'participants',
                    false, // isNull
                    true, // every
                    true, // redirectIfEnabled
                    true, // nullMeansEnabled),
                  ),
                  false,
                  studyID,
                ),
              ),
            ),
          ),
        )
        // Return Component
      },
      '/participants/tracks/:cohortID',
    )
  },
})
