import React from 'react'
import PropTypes from 'prop-types'
import { SIGNATURE_TIPS } from 'utils/constants'

const phoneImg = require('assets/signature_phone.png')
const tabletImg = require('assets/tablet.png')

const CAMERA_TIP = 'Mobile photo tip example:'
const CLINRO_CAMERA_TIP = 'ClinRO photo tip example:'

const SignatureCameraTip = ({ item }) => {
  const { attributes } = item || {}
  const { signees, signeeOrder } = attributes || {}

  const hasClinroSignee = signeeOrder?.some(signeeId => signees[signeeId].type === 'clinro')
  const hasMobileSignee = signeeOrder?.some(
    signeeId => signees[signeeId].type === 'caregiver' || signees[signeeId].type === 'participant',
  )

  return (
    <div className='camera-tips'>
      {hasMobileSignee && (
        <div className='camera-tip flexed'>
          <img className='phone signature-sample' src={phoneImg} alt='signature-phone-img' />
          <div className='tip-labels flexed column'>
            <span className='tip-title'>{CAMERA_TIP}</span>
            <span>{SIGNATURE_TIPS.camera_tip}</span>
          </div>
        </div>
      )}
      {hasClinroSignee && (
        <div className='camera-tip flexed'>
          <img className='tablet signature-sample' src={tabletImg} alt='tablet-img' />
          <div className='tip-labels flexed column'>
            <span className='tip-title'>{CLINRO_CAMERA_TIP}</span>
            <span>{SIGNATURE_TIPS.clinro_camera_tip}</span>
          </div>
        </div>
      )}
    </div>
  )
}

SignatureCameraTip.propTypes = {
  item: PropTypes.shape({
    camera_tip: PropTypes.string,
    attributes: PropTypes.shape({
      signees: PropTypes.object,
      signeeOrder: PropTypes.array,
    }),
  }),
}

export default SignatureCameraTip
