import React from 'react'
import PropTypes from 'prop-types'
import { QUESTION_TYPE_MAP } from 'utils/constants'
import { Button } from 'components/UIElements'
import { getRandomQuestionId } from '../../../../../../../../utils/getRandomQuestionId'

const MAX_SIBLING_COUNT = 3

const SiblingsPanel = props => {
  const { activeItemId, addSiblingClinroItem, clinro, item, setActiveItemId } = props
  const { siblings, order } = clinro
  const { sibling_id } = item
  const isAtMaxSiblings = sibling_id ? siblings[sibling_id].length === MAX_SIBLING_COUNT : false
  const index = order[0].indexOf(activeItemId)

  return (
    <div className='siblings panel'>
      <Button
        disabled={isAtMaxSiblings}
        onClick={() => {
          const newQId = getRandomQuestionId()
          const siblingId = sibling_id || getRandomQuestionId()
          addSiblingClinroItem({
            itemType: QUESTION_TYPE_MAP.selectOne,
            newQId,
            siblingQId: activeItemId,
            siblingId,
            index,
          })
          setActiveItemId(newQId)
        }}
        link
        no-margin
        content='Add a question on the same page'
        iconBefore='fas fa-plus'
      />
      {isAtMaxSiblings ? (
        <p>You can only display up to 3 questions on a page </p>
      ) : (
        <p>
          Questions displayed on the same page function as other questions would. Scoring and logic will work the same.
        </p>
      )}
    </div>
  )
}

SiblingsPanel.propTypes = {
  activeItemId: PropTypes.string,
  addSiblingClinroItem: PropTypes.func,
  clinro: PropTypes.shape({
    order: PropTypes.arrayOf(PropTypes.array),
    siblings: PropTypes.objectOf(PropTypes.array),
  }),
  item: PropTypes.shape({
    sibling_id: PropTypes.string,
  }),
  setActiveItemId: PropTypes.func,
}

export default SiblingsPanel
