import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button, Container } from 'components/UIElements'
import { isPtpInUncompletedConsentBlockList } from '../../../utils/participantValidation'

const ParticipantStudySettings = props => {
  const { ptpId, study, studyID, ptpSiteID, toggleParticipant, unCompleteBlockedConsents } = props
  const { platform_name } = study

  const openMovePtpModal = () => {
    toggleParticipant(ptpId, ptpSiteID)
    browserHistory.push(`/studies/${studyID}/participants/move`)
  }
  return (
    <Container centered flex column className='ptp-study-settings'>
      <div className='title'>Study Settings</div>
      <div className='flexed'>
        <div className='flexed column start-aligned'>
          <p className='label-small'>This study</p>
          <p className='study-name'>{platform_name}</p>
        </div>
        {!isPtpInUncompletedConsentBlockList([`${ptpId}`], Object.values(unCompleteBlockedConsents)) ? (
          <Button link content='Move to another study' onClick={openMovePtpModal} />
        ) : null}
      </div>
    </Container>
  )
}

ParticipantStudySettings.propTypes = {
  openModal: PropTypes.func,
  ptpId: PropTypes.number,
  ptpMoveLoading: PropTypes.bool,
  study: PropTypes.shape({
    platform_name: PropTypes.string,
  }),
}

export default ParticipantStudySettings
