import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Radio, Popup, AdvancedOptions, WarningText } from 'components/UIElements'
import { GROUP_TYPE_MAP } from 'utils/constants'
import {
  CohortInput,
  SiteInput,
} from '../../../../../../Instruments/routes/Instrument/routes/Scheduler/components/ParticipantManagement'

const blockingConsentPopup = (
  <Popup hover noPointer position='bottom-right' dark trigger={<i className='fas fa-info-circle' />}>
    <div className='pop-content'>
      Check “Yes” if a participant <strong>should not have</strong> access to any study activities until all required
      signatures for this consent are collected. Check “No” if a participant <strong>should have</strong> access to some
      study activities even when the required signatures for this consent are not collected.
    </div>
  </Popup>
)

const ConsentRecipients = props => {
  const {
    canSeeSites,
    checkedCohorts,
    checkedSites,
    consent,
    disableTrackCreation,
    errors,
    initializeCheckedSites,
    initializeCheckedCohorts,
    leafSites,
    noTracksFeature,
    siteSelectionList,
    toggleSite,
    updateCohort,
    updateCohortField,
    updateConsentKey,
    advancedOptions,
    setAdvancedOptions,
    showAdvancedOption,
    setShowAdvancedOption,
    setAdvancedOptionsToStore,
    resetAdvancedOptions,
  } = props
  const { is_blocking, metadata } = consent
  const { schedule } = metadata
  const { cohort } = schedule
  const getCohort = Array.isArray(cohort) || showAdvancedOption ? {} : cohort
  const { filter = {}, type = '' } = getCohort

  useEffect(() => {
    if (type === GROUP_TYPE_MAP.sites) {
      initializeCheckedSites(filter.site_ids)
    } else if (type === GROUP_TYPE_MAP.cohort) {
      initializeCheckedCohorts(filter.include)
    }
  }, [type])

  const updateIsBlocking = (isBlocking = true) => {
    updateConsentKey({ key: 'is_blocking', value: isBlocking })
  }

  const renderSiteInput = () => {
    const noSitesSelected = !filter?.site_ids?.length
    return (
      <SiteInput
        checkedSites={checkedSites}
        errors={errors}
        hasError={noSitesSelected && errors.hasOwnProperty('schedule.cohort.filter.site_ids')}
        initializeCheckedSites={initializeCheckedSites}
        leafSites={leafSites}
        siteIds={filter?.site_ids}
        sitesList={siteSelectionList}
        toggleSite={toggleSite}
        type={type}
        updateCohortField={updateCohortField}
      />
    )
  }

  const renderTrackInput = () => {
    const noTracksSelected = !filter?.include?.length
    return (
      <CohortInput
        checkedCohorts={checkedCohorts}
        errorText={errors['schedule.cohort.filter.include']}
        hasError={noTracksSelected && errors.hasOwnProperty('schedule.cohort.filter.include')}
        type={type}
        updateCohortField={updateCohortField}
      />
    )
  }
  return (
    <Container>
      <ol>
        <li>
          <p>
            Select who will receive this consent form.
            {errors['schedule.cohort.filter.error'] && (
              <WarningText text={errors['schedule.cohort.filter.error']} disabled />
            )}
          </p>
          <Radio
            onClick={() => {
              updateCohort({
                type: GROUP_TYPE_MAP.all,
                filter: {},
              })
            }}
            id='all-participants-radio'
            content='All Participants'
            selected={type === GROUP_TYPE_MAP.all}
            disabled={showAdvancedOption}
          />
          {canSeeSites && (
            <Radio
              onClick={() => {
                updateCohort({
                  type: GROUP_TYPE_MAP.sites,
                  filter: {
                    site_ids: Object.keys(checkedSites).map(id => parseInt(id, 10)),
                  },
                })
              }}
              id='sites-radio'
              content={renderSiteInput()}
              selected={type === GROUP_TYPE_MAP.sites}
              disabled={showAdvancedOption}
            />
          )}
          {!noTracksFeature && (
            <Radio
              onClick={() => {
                updateCohort({
                  type: GROUP_TYPE_MAP.cohort,
                  filter: {
                    include: Object.keys(checkedCohorts).map(id => parseInt(id, 10)),
                  },
                })
              }}
              id='track-radio'
              content={renderTrackInput()}
              selected={type === GROUP_TYPE_MAP.cohort}
              disabled={showAdvancedOption}
            />
          )}
          <AdvancedOptions
            advancedOptions={advancedOptions}
            disableTrackCreation={disableTrackCreation}
            setAdvancedOptions={setAdvancedOptions}
            showAdvancedOption={showAdvancedOption}
            setShowAdvancedOption={setShowAdvancedOption}
            resetAdvancedOptions={resetAdvancedOptions}
            setAdvancedOptionsToStore={setAdvancedOptionsToStore}
            cohort={cohort}
            selected={type}
            errors={errors}
            exclude={{
              [GROUP_TYPE_MAP.age]: true,
              [GROUP_TYPE_MAP.sites]: !canSeeSites,
              [GROUP_TYPE_MAP.cohort]: noTracksFeature,
            }}
            hideWithOne
            setConfig={{
              study_group: {
                type: GROUP_TYPE_MAP.cohort,
                label: 'Track',
                countableText: ['track', 'tracks'],
              },
            }}
          />
        </li>
        <li>
          <div className='flexed start-justified'>
            <span>Is this a blocking consent?</span>
            {blockingConsentPopup}
          </div>
          <Radio
            onClick={() => {
              updateIsBlocking()
            }}
            id='blocking-consent-yes'
            content='Yes'
            selected={is_blocking}
          />
          <Radio
            onClick={() => {
              updateIsBlocking(false)
            }}
            id='blocking-consent-no'
            content='No'
            selected={!is_blocking}
          />
        </li>
      </ol>
    </Container>
  )
}

ConsentRecipients.propTypes = {
  canSeeSites: PropTypes.bool,
  checkedCohorts: PropTypes.objectOf(PropTypes.number),
  checkedSites: PropTypes.objectOf(PropTypes.string),
  consent: PropTypes.shape({
    is_blocking: PropTypes.bool,
    metadata: PropTypes.shape({
      schedule: PropTypes.shape({
        cohort: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
      }),
    }),
  }),
  initializeCheckedCohorts: PropTypes.func,
  initializeCheckedSites: PropTypes.func,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  noTracksFeature: PropTypes.bool,
  siteSelectionList: PropTypes.arrayOf(PropTypes.array),
  toggleSite: PropTypes.func,
  updateCohort: PropTypes.func,
  updateCohortField: PropTypes.func,
  updateConsentKey: PropTypes.func,
}

export default ConsentRecipients
