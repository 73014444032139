import React from 'react'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import moment from 'moment'
import { formatDate, parseDate } from 'react-day-picker/moment'
import 'styles/d3/datepicker.scss'
import { dayPickerConfig } from 'utils/time'

class DatePicker extends React.Component {
  constructor(props) {
    super(props)
    const { initialDate } = props
    this.state = {
      valid: true,
      initialValue: initialDate ? initialDate.toDate() : '',
    }
    this.inputRef = React.createRef()
  }

  handleBlur = () => {
    const { valid } = this.state
    const { initialDate } = this.props
    if (valid) {
      this.setState({
        initialValue: initialDate ? initialDate.toDate() : '',
      })
    }
  }

  removeError = () => this.inputRef.current.classList.remove('has-error')

  onContainerClick = () => {
    const { onContainerClick } = this.props
    if (onContainerClick) onContainerClick()
    else this.inputRef?.current?.focus()
  }

  render() {
    const {
      date, // date is a Moment date object type
      disabled,
      disabledDays,
      errorText,
      hasError,
      helpText,
      id,
      inputClassName,
      onDayChange,
      placeholder = dayPickerConfig.placeholder,
      overlayComponent,
      setRef,
      _ref,
    } = this.props
    const { valid, initialValue } = this.state

    if (setRef && !_ref?.current) {
      setRef(this.inputRef)
    }

    return (
      <div className='day-picker-wrapper' onClick={this.onContainerClick}>
        <DayPickerInput
          inputProps={{
            id,
            disabled,
            className: `${inputClassName || ''} ${valid ? '' : 'invalid-date'}${hasError ? ' has-error' : ''}`,
            onBlur: this.handleBlur,
            ref: this.inputRef,
          }}
          dayPickerProps={{
            disabledDays,
            locale: dayPickerConfig.locale,
            localeUtils: dayPickerConfig.localeUtils,
          }}
          formatDate={formatDate}
          format={dayPickerConfig.format}
          parseDate={(str, format, locale) => {
            const _date = parseDate(str, format, locale)
            if (_date && !valid) this.setState({ valid: true })
            else if (!_date && valid) this.setState({ valid: false })
            return _date
          }}
          onDayChange={(_date, _, options) => {
            if (_date) onDayChange(moment(_date), options)
            else onDayChange(_date, options)
          }}
          value={date ? date.toDate() : initialValue}
          placeholder={placeholder}
          overlayComponent={overlayComponent}
        />
        <i className={`far fa-calendar-alt${hasError ? ' has-error' : ''}`} />
        {hasError && errorText && <p className='error-text'>{errorText}</p>}
        {!hasError && helpText && <p className='help-text'>{helpText}</p>}
      </div>
    )
  }
}

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  disabledDays: PropTypes.shape({
    after: PropTypes.instanceOf(Date),
    before: PropTypes.instanceOf(Date),
  }),
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  helpText: PropTypes.string,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  onDayChange: PropTypes.func,
  onContainerClick: PropTypes.func,
  _ref: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
}

export default DatePicker
