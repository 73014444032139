import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import { actions as notyActions } from 'layouts/ErrorBox'
import { SET_USER, authActions, fetchUserImage } from 'store/user'
import { modalActions } from 'store/modal'
import request, { generateNotyMessage } from 'utils/request'
import { getPasswordError, sortFunction } from 'utils/misc'
import {
  ROLE_NAME_ID_MAP,
  USER_ROLE_ID_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  MODAL_CONTENT_MAP,
} from 'utils/constants'
import { loadingActions } from 'store/loader'
import { sendingActions } from 'store/sender'
import addExistingUserModalContent from './userFunctionalComponents'
import { getEmailError, getPhoneError, getSiteError } from '../../../../Participants/utils/participantValidation'

const ADD_USER_ERRORS = 'ADD_USER_ERRORS'
const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS'
const CLEAR_USER_PIC = 'CLEAR_USER_PIC'
const FETCHED_USER = 'FETCHED_USER'
const HIDE_CHANGE_PASSWORD = 'HIDE_CHANGE_PASSWORD'
const RESET_PW_STATE = 'RESET_PW_STATE'
const RESET_USER_PAGE = 'RESET_USER_PAGE'
const SAVED_USER = 'SAVED_USER'
const SAVING_USER = 'SAVING_USER'
const SET_CLINRO_ROLES = 'SET_CLINRO_ROLES'
const SHOW_CHANGE_PASSWORD = 'SHOW_CHANGE_PASSWORD'
const UPDATE_FIELD = 'UPDATE_FIELD'
const UPDATE_NEW_PASSWORD = 'UPDATE_NEW_PASSWORD'
const UPLOADED_IMAGE_PREVIEW = 'UPLOADED_IMAGE_PREVIEW'
const CHECK_USER_SITES = 'CHECK_USER_SITES'
const SET_USER_SITES = 'SET_USER_SITES'

//
// Action Creators
//

function updateField(field, value) {
  return {
    type: UPDATE_FIELD,
    field,
    value,
  }
}

function updateNewPassword(field, value) {
  return {
    type: UPDATE_NEW_PASSWORD,
    field,
    value,
  }
}

function showChangePassword() {
  return {
    type: SHOW_CHANGE_PASSWORD,
  }
}

function hideChangePassword() {
  return {
    type: HIDE_CHANGE_PASSWORD,
  }
}

function resetUserPage() {
  return {
    type: RESET_USER_PAGE,
  }
}

function addUserErrors(errors) {
  return {
    type: ADD_USER_ERRORS,
    errors,
  }
}
function clearUserErrors() {
  return {
    type: CLEAR_USER_ERRORS,
  }
}

//
// API Util Functions
//

const fetchUserSites = ({ studyId, userId, checkSitesOnSuccess = false, onResolve = false }) => (
  dispatch,
  getState,
) => {
  const { user: currentUser } = getState()

  const success = json => {
    const { sites } = json
    if (onResolve) {
      return Promise.resolve(sites)
    }
    if (checkSitesOnSuccess) dispatch({ type: CHECK_USER_SITES, sites })
    dispatch({ type: SET_USER_SITES, sites })
  }
  return dispatch(
    request({
      url: `/control/studies/${studyId}/users/${userId || currentUser.id}/site_permissions`,
      success,
      hasLoader: true,
      loadingKey: 'userSites',
    }),
  )
}

// Fetch individual user by ID
function fetchUser(id) {
  return (dispatch, getState) => {
    const { study, subroute } = getState()
    const studyID = study.currentStudy.id
    const isProfilePage = subroute === 'me'

    dispatch(loadingActions.startLoader(true))
    let _user
    dispatch({
      type: CLEAR_USER_PIC,
    })
    const success = jsonBody => {
      _user = jsonBody
      return dispatch(fetchProfilePic(_user.id))
    }
    return dispatch(
      request({
        url: `/control/users/${id}`,
        success,
        hasLoader: true,
      }),
    )
      .then(() => {
        if (!isProfilePage) dispatch(fetchUserSites({ studyId: studyID, userId: id, checkSitesOnSuccess: true }))
        const { permissions } = _user
        let roleName
        let roleId
        if (studyID) {
          const study = permissions[studyID]
          roleName = study.name
          roleId = study.role_id
        } else {
          const firstStudy = permissions[Object.keys(permissions)[0]]
          roleName = firstStudy.name
          roleId = firstStudy.role_id
        }
        const _roleName = roleName.replace(/ /g, '_')
        const _roleId = ROLE_NAME_ID_MAP[_roleName] || roleId
        _user.role_id = _roleId
        _user.role_name = roleName
        dispatch({
          type: FETCHED_USER,
          _user,
          studyID,
        })
        dispatch(loadingActions.stopLoader(true))
      })
      .catch(err => {
        dispatch(notyActions.showError({ text: generateNotyMessage(err.message, false) }))
      })
  }
}

export function fetchProfilePic(id) {
  return dispatch => {
    const success = jsonBody => {
      dispatch({
        type: UPLOADED_IMAGE_PREVIEW,
        image: jsonBody.asset,
      })
    }
    return dispatch(
      request({
        url: `/control/assets/profile_pics/${id}`,
        success,
        fail: () => {
          if (__DEV__) console.log('user has no profile picture')
        },
      }),
    )
  }
}

export const fetchClinroRoles = () => {
  return dispatch => {
    const success = payload => {
      const { roles } = payload
      dispatch({
        type: SET_CLINRO_ROLES,
        roles,
      })
    }
    return dispatch(
      request({
        url: `/control/clinro_roles`,
        success,
      }),
    )
  }
}

function updateUserPermissions(userID, studyID, siteID, roleID, method) {
  return dispatch => {
    const userParams = {
      user_id: userID,
      role_id: roleID,
      study_id: studyID,
      site_id: siteID,
    }
    const success = () => {
      return Promise.resolve()
    }
    return dispatch(
      request({
        method: method ? 'PATCH' : 'POST',
        url: '/control/permissions',
        success,
        body: JSON.stringify(userParams),
      }),
    )
  }
}

function validateUser(params, dispatch, sitesArr, pwErrors) {
  const hasPwErrors = !!pwErrors
  const errors = hasPwErrors ? { ...pwErrors } : {}
  const { phone, role_id, smsEnabled, username } = params
  let errorMessage = ''
  const emailError = getEmailError({ val: username })
  const phoneError = getPhoneError({ val: phone, required: smsEnabled })
  const siteError = sitesArr ? getSiteError({ val: sitesArr, type: 'user' }) : null
  const roleIdsWithPhone = [
    USER_ROLE_ID_MAP.caseManager,
    USER_ROLE_ID_MAP.clinRoI,
    USER_ROLE_ID_MAP.clinRoII,
    USER_ROLE_ID_MAP.clinRoIII,
    USER_ROLE_ID_MAP.impartialWitness,
  ]
  if (emailError) {
    errors.emailErr = emailError
    errorMessage += `${emailError}<br>`
  }
  if (!params.nickname) {
    errors.nameErr = 'Name cannot be blank'
    errorMessage += 'Name cannot be blank<br>'
  }

  if (phoneError && roleIdsWithPhone.includes(role_id)) {
    errors.phoneErr = phoneError
    errorMessage += `${phoneError}<br>` // only check for phone error for case manager and clinician roles}
  }
  if (siteError) {
    errors.siteErr = siteError
    errorMessage += `${siteError}<br>`
  }
  if (errorMessage !== '' || hasPwErrors) {
    const userErrMessage = 'Please check errors before proceeding'
    dispatch(notyActions.showError({ text: generateNotyMessage(userErrMessage, false) }))
    dispatch({
      type: ADD_USER_ERRORS,
      errors,
    })
    return null
  }
  dispatch({ type: CLEAR_USER_ERRORS })

  return errorMessage === ''
}

/**
 *
 * @param {string} pathString
 * @returns number that represents the tree level of a site.
 *
 * This number will be used to handle the scenario when a site ('place a') at the same level
 * as another site ('place') includes the pathname of the other site.
 *
 * 'place a'.includes('place') => true
 *
 * Pathname inclusivity is used to bypass site creation of lower sites when a parent site is created.
 * This function helps us add addiitonal logic to not bypass site creation if a pathname is inclusive
 * of another site's pathname, but is at the same level.
 */
function countLevelsInPath(pathString) {
  return pathString.split('.').length - 1
}

function continueSiteCreation(currentSitePathName, currentPath) {
  const currentSiteLevel = countLevelsInPath(currentSitePathName)
  const currentPathLevel = countLevelsInPath(currentPath)

  const sameLevel = currentSiteLevel === currentPathLevel
  const pathNameIncluded = currentSitePathName.includes(currentPath)
  return !pathNameIncluded || (sameLevel && pathNameIncluded)
}

function createNewUser(userBody, sites, studyID, edit = false) {
  let currentPath
  let currentSite
  const sitesArr = Object.keys(sites).map(idString => {
    return parseInt(idString, 10)
  })
  const _sitesArr = [...sitesArr]
  sitesArr.sort((el1, el2) => sortFunction(sites[el1], sites[el2]))

  return dispatch => {
    currentSite = sitesArr.shift()
    const { username, nickname, role_id, study_id, phone, smsEnabled } = userBody
    const site_id = currentSite
    const userParams = {
      username,
      nickname,
      role_id,
      study_id: study_id || parseInt(studyID, 10),
      site_id,
      phone,
    }

    if (!smsEnabled) userParams.phone = ''

    if (!validateUser(userBody, dispatch, _sitesArr)) return null

    const success = async payload => {
      const userID = payload.id
      const roleID = role_id
      const _studyID = parseInt(studyID, 10)
      currentPath = sites[currentSite]

      const promiseArr = []
      while (sitesArr.length > 0) {
        currentSite = sitesArr.shift()
        if (continueSiteCreation(sites[currentSite], currentPath)) {
          promiseArr.push(dispatch(updateUserPermissions(userID, _studyID, currentSite, roleID, edit)))
          currentPath = sites[currentSite]
        }
      }
      await Promise.allSettled(promiseArr)
      dispatch(sendingActions.stopSender())
      setTimeout(() => {
        browserHistory.push(`/studies/${studyID}/users`)
        dispatch(sendingActions.resetSender())
      }, 500)
    }

    const fail = (payload, content) => {
      if (payload.status === 412) {
        const { message } = content
        const _studyID = parseInt(studyID, 10)
        const userIdString = message.slice(message.indexOf(':') + 1)
        const userID = parseInt(userIdString, 10)
        currentPath = sites[currentSite]

        const updateUser = async () => {
          dispatch(sendingActions.startSender())

          await dispatch(updateUserPermissions(userID, _studyID, currentSite, role_id, edit))

          const promiseArr = []
          while (sitesArr.length > 0) {
            currentSite = sitesArr.shift()
            if (continueSiteCreation(sites[currentSite], currentPath)) {
              promiseArr.push(dispatch(updateUserPermissions(userID, _studyID, currentSite, role_id, false)))
              currentPath = sites[currentSite]
            }
          }

          await Promise.allSettled(promiseArr)
          dispatch(sendingActions.stopSender())
          dispatch(
            notyActions.showSuccess({
              text: generateNotyMessage('Permissions successfully updated!', true),
            }),
          )
          setTimeout(() => {
            browserHistory.push(`/studies/${studyID}/users`)
            dispatch(sendingActions.resetSender())
          }, 500)
        }

        // Don't render existing user prompt for edits
        if (edit) updateUser()
        else {
          dispatch(loadingActions.stopLoader(true, 'request'))
          dispatch(sendingActions.resetSender())
          dispatch(
            modalActions.openModal({
              content: addExistingUserModalContent,
              confirmButton: MODAL_BUTTONS_MAP.addExistingUser,
              cancelButton: MODAL_BUTTONS_MAP.cancel,
              className: MODAL_CLASSES_MAP.existingUserPrompt,
              onConfirm: updateUser,
              closeOnBackgroundClick: false,
            }),
          )
        }
      }
      if (payload.status === 400) {
        return dispatch(
          notyActions.showError({ text: generateNotyMessage('Please assign the user to a site.', false) }),
        )
      }
    }

    /**
     * This starts the request loader which is linked to the button to initiate this request.
     * The slice won't stop till all necessary requests to update or create the user are made.
     * This loading slice will prevent the button from being click multiple times.
     * */
    dispatch(sendingActions.startSender())
    return dispatch(
      request({
        method: 'POST',
        url: '/control/users',
        success,
        successMessage: 'User successfully created!',
        body: JSON.stringify(userParams),
        fail,
        hasLoader: true,
        hasSender: true,
        loadingKey: 'request',
      }),
    )
  }
}

function saveSelfToDatabase(self, _newPasswordActive, changePasswordState, _profilePic = null) {
  const { smsEnabled } = self

  return dispatch => {
    const updatedSelf = { ...self }
    if (!updatedSelf.phone) updatedSelf.phone = ''
    let pwErrors = null
    if (_newPasswordActive) {
      pwErrors = {}
      if (!changePasswordState.oldPassword) {
        pwErrors.noOldPasswordErr = 'Please enter your old password'
      }
      const passwordErrors = getPasswordError(
        changePasswordState.newPassword1,
        changePasswordState.newPassword2,
        dispatch,
      )
      if (passwordErrors) {
        pwErrors = { ...pwErrors, ...passwordErrors }
      }
      const hasErrors = Object.keys(pwErrors).length > 0
      if (!hasErrors) {
        pwErrors = null
      }
      updatedSelf.old_password = changePasswordState.oldPassword
      updatedSelf.new_password = changePasswordState.newPassword1
    }
    if (!validateUser(updatedSelf, dispatch, null, pwErrors)) return null
    dispatch({
      type: SAVING_USER,
    })
    const success = () => {
      dispatch(sendingActions.stopSender())
      if (_profilePic) dispatch(saveProfilePicToAssets(self.id, _profilePic))
      const _user = JSON.parse(localStorage.getItem('user'))
      _user.nickname = self.nickname
      _user.phone = self.phone
      localStorage.setItem('user', JSON.stringify(_user))
      dispatch(clearUserErrors())
      dispatch(authActions.updateUsername(self.nickname))
      dispatch({ type: SAVED_USER })
      dispatch({
        type: SET_USER,
        payload: _user,
      })
      setTimeout(() => {
        dispatch(sendingActions.resetSender())
      }, 500)
    }
    const fail = (res, content) => {
      const pwErrorMessage = 'Please check errors before proceeding'
      dispatch(sendingActions.resetSender())
      dispatch(notyActions.showError({ text: generateNotyMessage(pwErrorMessage, false) }))
      if (res.status === 401) {
        dispatch(addUserErrors({ pwSecurityError: content.message }))
      } else {
        dispatch(addUserErrors({ wrongPasswordError: content.message }))
      }
    }

    if (!smsEnabled) updatedSelf.phone = ''
    delete updatedSelf.smsEnabled

    dispatch(sendingActions.startSender())
    return dispatch(
      request({
        method: 'PATCH',
        url: '/control/users/me',
        successMessage: 'User info updated.',
        success,
        hasLoader: true,
        hasSender: true,
        loadingKey: 'request',
        fail,
        body: JSON.stringify(updatedSelf),
      }),
    )
  }
}

function saveProfilePicToAssets(userId, _profilePic) {
  return dispatch => {
    const success = () => {
      return dispatch(fetchUserImage(userId))
    }
    return dispatch(
      request({
        method: 'PUT',
        url: `/control/assets/profile_pics/${userId}`,
        body: _profilePic,
        success,
      }),
    )
  }
}

function deleteUser(userID, studyID, sites, roleID) {
  return dispatch => {
    const onConfirm = () => {
      dispatch(_deleteUsers(userID, studyID, sites, roleID))
    }
    return dispatch(
      modalActions.openModal({
        content: MODAL_CONTENT_MAP.deleteUser,
        confirmButton: MODAL_BUTTONS_MAP.yes,
        cancelButton: MODAL_BUTTONS_MAP.no,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  }
}

function _deleteUser({ userID, studyID, siteID, onSuccess, successMessage }) {
  return dispatch => {
    dispatch(sendingActions.startSender())
    return dispatch(
      request({
        method: 'DELETE',
        url: `/control/studies/${studyID}/sites/${siteID}/users/${userID}`,
        failMessage: 'There was a problem deleting this user. Please try again.',
        successMessage,
        successIcon: 'fas fa-trash-alt',
        success: onSuccess,
        hasLoader: true,
        hasSender: true,
        loadingKey: 'request',
      }),
    )
  }
}

// Delete set of flagged user IDs
function _deleteUsers(userID, studyID, sites, roleID) {
  // To check if user has all sites assigned to him or not
  const isAllSites = !!Object.values(sites).find(site => Number(site) === Number(studyID))
  // To make default path name unique and not equal to any of actual site names
  let path = 'someDefaultSitePathName'
  // To convert all site ids to numbers and sort this array as numbers
  const deletionArr = Object.keys(sites)
    .map(idString => parseInt(idString, 10))
    .sort((a, b) => a - b)
    .filter(key => {
      if (!sites[key]?.includes(path)) {
        path = sites[key]
        return true
      }
    })

  return dispatch => {
    // User can't delete root or DCH Admins
    if (roleID === 1)
      return dispatch(notyActions.showError({ text: generateNotyMessage('You cannot delete Root users.', false) }))
    if (roleID === 2)
      return dispatch(
        notyActions.showError({
          text: generateNotyMessage('You cannot delete Datacubed Health Administrators.', false),
        }),
      )

    const onLastSuccess = () => {
      dispatch(sendingActions.resetSender())
      return browserHistory.push(`/studies/${studyID}/users`)
    }

    const onDeleteUserSuccess = () => {
      const siteID = deletionArr.shift()
      return dispatch(
        _deleteUser({
          userID,
          studyID,
          siteID,
          onSuccess: !deletionArr.length ? onLastSuccess : onDeleteUserSuccess,
          successMessage: !deletionArr.length
            ? 'User successfully deleted'
            : `User successfully unassigned from site ${siteID}`,
        }),
      )
    }

    const siteId = deletionArr.shift()
    const isLastDeletion = isAllSites || !deletionArr.length
    return dispatch(
      _deleteUser({
        userID,
        studyID,
        siteID: siteId,
        onSuccess: isLastDeletion ? onLastSuccess : onDeleteUserSuccess,
        successMessage: isLastDeletion
          ? 'User successfully deleted'
          : `User successfully unassigned from site ${siteId}`,
      }),
    )
  }
}

function uploadProfilePic(imageFile) {
  return dispatch => {
    try {
      readImageFile(imageFile, imageUrl => {
        dispatch({
          type: UPLOADED_IMAGE_PREVIEW,
          image: imageUrl,
        })
      })
    } catch (err) {
      dispatch(notyActions.showError({ text: generateNotyMessage(err.message, false) }))
    }
  }
}

function readImageFile(file, callback) {
  const reader = new FileReader()
  reader.onloadend = () => {
    callback(reader.result)
  }
  reader.readAsDataURL(file)
}

//
// Util Functions
//

export function generateBlankUser() {
  return {
    username: '',
    nickname: '',
    role_id: 7,
    study_id: 0,
    site_id: 0,
  }
}

function generateBlankPWState() {
  return {
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
  }
}

//
// Reducers
//

export function user(state = generateBlankUser(), action) {
  switch (action.type) {
    case FETCHED_USER: {
      return action._user
    }
    case UPDATE_FIELD: {
      const newState = { ...state }
      newState[action.field] = action.value
      return newState
    }
    case RESET_USER_PAGE:
      return generateBlankUser()
    default: {
      return state
    }
  }
}

function changePassword(state = generateBlankPWState(), action) {
  switch (action.type) {
    case RESET_PW_STATE: {
      return generateBlankPWState()
    }
    case UPDATE_NEW_PASSWORD: {
      return { ...state, [action.field]: action.value }
    }
    case SAVED_USER:
    case RESET_USER_PAGE:
      return generateBlankPWState()
    default: {
      return state
    }
  }
}

function newPasswordActive(state = false, action) {
  switch (action.type) {
    case SHOW_CHANGE_PASSWORD:
      return true
    case HIDE_CHANGE_PASSWORD:
    case SAVED_USER:
    case RESET_USER_PAGE:
      return false
    default:
      return state
  }
}

function profilePic(state = null, action) {
  switch (action.type) {
    case UPLOADED_IMAGE_PREVIEW:
      return action.image
    case RESET_USER_PAGE:
    case CLEAR_USER_PIC:
      return null
    default:
      return state
  }
}

const userErrors = (state = {}, action) => {
  switch (action.type) {
    case ADD_USER_ERRORS: {
      const { errors } = action
      return errors
    }
    case RESET_USER_PAGE:
    case CLEAR_USER_ERRORS:
      return {}
    default:
      return state
  }
}

const userSites = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_SITES: {
      return action.sites
    }
    default:
      return state
  }
}

export default combineReducers({
  changePassword,
  newPasswordActive,
  profilePic,
  user,
  userErrors,
  userSites,
})

export const actions = {
  createNewUser,
  deleteUser,
  fetchUser,
  fetchUserSites,
  hideChangePassword,
  resetUserPage,
  saveSelfToDatabase,
  showChangePassword,
  updateField,
  updateNewPassword,
  updateUserPermissions,
  uploadProfilePic,
}
