import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducer } from 'store/reducers'
import { fetchUser } from 'store/user'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import Dashboard from './containers/StudyDashboardContainer'
import dashboardReducer from './modules/StudyDashboard'
import {
  fetchStudy,
  fetchStudyInsightsStatus,
  resetInsightsLowFunctionalityMode,
} from '../../../CreateStudy/modules/CreateStudy'
import EditInsightsRoute from './routes/EditInsights'
import EmbeddedInsightsRoute from './routes/EmbeddedInsights'

// Sync route definition
export default store => ({
  path: 'compliance',
  exact: true,
  getIndexRoute(location, cb) {
    store.dispatch(setSubroute('studyDashboard'))
    cb(null, {
      getComponent(nextState, _cb) {
        require.ensure(
          [],
          () => {
            const { params } = nextState
            const { studyID } = params
            const { currentStudy } = store.getState().study
            const { config } = currentStudy
            store.dispatch(fetchStudy(studyID))
            store.dispatch(resetInsightsLowFunctionalityMode())
            store.dispatch(fetchStudyInsightsStatus(studyID))
            store.dispatch(setSubroute(config?.insights ? 'insights' : 'compliance'))
            store.dispatch(fetchUser())
            setPageTitle(`Compliance | Study ${studyID} | ${currentStudy.platform_name}`)
            // Return Component
            injectReducer(store, {
              key: 'studyDashboard',
              reducer: dashboardReducer,
            })
            _cb(
              null,
              UserIsAuthenticated(UserHasValidPassword(UserHasScope(['ra', 'rp', 'rd'], Dashboard, false, studyID))),
            )
          },
          'compliance',
        )
      },
    })
  },
  getChildRoutes(location, cb) {
    cb(null, [EditInsightsRoute(store), EmbeddedInsightsRoute(store)])
  },
})
