import React from 'react'
import { browserHistory } from 'react-router'
import { Button } from 'components/UIElements'
import PropTypes from 'prop-types'

const ParticipantInfo = ({ participant, params, isNoPiiUser, canWrite, studyLock }) => {
  const { participant_id, site_id, name, phone, email } = participant
  const { studyID } = params

  return (
    <div className='participant-info flexed column'>
      <div className='flexed'>
        <div className='flexed start-justified'>
          <span>{`Participant ${participant_id}`}</span>
          <Button
            link
            disabled={studyLock}
            icon='fas fa-angle-right'
            onClick={() =>
              canWrite
                ? browserHistory.push(`/studies/${studyID}/participants/${participant_id}/${site_id}`)
                : // ? browserHistory.push(`/studies/${studyID}/sites/${site_id}/participants/${participant_id}`)
                  browserHistory.push(`/studies/${studyID}/participants/`)
            }
          />
        </div>
        {!isNoPiiUser && (phone ? <a href={`tel:${phone}`}>{phone}</a> : <span>Phone number not provided</span>)}
      </div>
      {!isNoPiiUser && (
        <div className='flexed'>
          <div>{name}</div>
          <a href={`mailto:${email}`} className='participant-email'>
            {email}
          </a>
        </div>
      )}
    </div>
  )
}

ParticipantInfo.propTypes = {
  participant: PropTypes.shape({
    participant_id: PropTypes.number,
    site_id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  params: PropTypes.shape({
    studyID: PropTypes.string,
    siteID: PropTypes.string,
  }),
  isNoPiiUser: PropTypes.bool,
  canWrite: PropTypes.bool,
  studyLock: PropTypes.bool,
}

export default ParticipantInfo
