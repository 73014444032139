import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/participant.scss'

const SCROLL_VALUE = 200
const MAX_TABS_WITHOUT_SCROLLING = 7
/**
 * This padding is here to ensure that the scrolling arrows on the left
 * and right side of the tab container are hidden and not blocking the
 * right and left-most tabs once the scroll enters this threshold.
 */
const HORIZONTAL_PADDING_THRESHOLD = 20

const DrawerTabs = ({ tabs, selectedTab = {}, selectTab }) => {
  const ref = useRef(null)

  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true)
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false)
  const [sliderWidth, setSliderWidth] = useState(0)

  useEffect(() => {
    if (tabs.length <= MAX_TABS_WITHOUT_SCROLLING) {
      setIsLeftArrowHidden(true)
      setIsRightArrowHidden(true)
    }
  }, [tabs])

  useEffect(() => {
    setSliderWidth(ref?.current?.scrollWidth - ref?.current?.offsetWidth)
  }, [ref?.current])

  const handleScroll = scrollOffset => {
    ref.current.scrollLeft += scrollOffset
  }

  const onScroll = () => {
    setIsLeftArrowHidden(ref?.current?.scrollLeft < HORIZONTAL_PADDING_THRESHOLD)
    setIsRightArrowHidden(ref?.current?.scrollLeft > sliderWidth - HORIZONTAL_PADDING_THRESHOLD)
  }

  return (
    <div className='drawer-tabs-wrapper'>
      {!isLeftArrowHidden ? (
        <div className='drawer-tabs-arrow left' onClick={() => handleScroll(-SCROLL_VALUE)}>
          <i className='fas fa-chevron-left' />
        </div>
      ) : null}

      <div className='drawer-tabs' ref={ref} onScroll={onScroll}>
        {tabs.map(tab => {
          return (
            <div
              className={`drawer-tab ${selectedTab?.id === tab.id ? 'selected' : ''}`}
              key={tab.id}
              onClick={() => {
                selectTab(tab)
              }}>
              {tab.title}
            </div>
          )
        })}
      </div>

      {!isRightArrowHidden ? (
        <div className='drawer-tabs-arrow right' onClick={() => handleScroll(SCROLL_VALUE)}>
          <i className='fas fa-chevron-right' />
        </div>
      ) : null}
    </div>
  )
}

DrawerTabs.propTypes = {
  selectTab: PropTypes.func,
  selectedTab: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.object),
}

export default DrawerTabs
