import { combineReducers } from 'redux'
import locationReducer from './location'
import modalReducer from './modal'
import overlayReducer from './overlay'
import drawerReducer from './drawer'
import userReducer from './user'
import loadingReducer from './loader'
import sendingReducer from './sender'
import lockingReducer from './studyLock'
import navBarReducer from './navbar'
import tableFilterReducer from './tableFilters'
import searchTermReducer from './searchTerm'
import viewReducer from './view'
import studiesReducer from '../routes/Studies/StudiesPage/modules/Studies'
import studyReducer from '../routes/Studies/routes/CreateStudy/modules/CreateStudy'
import paymentsReducer from '../routes/Studies/routes/Study/routes/Payments/modules/Payments'
import visitSchedulesReducer from '../routes/Studies/routes/Study/routes/VisitSchedules/routes/VisitSchedulesPage/modules/VisitSchedules'
import sideBarReducer from '../layouts/SideBar/modules/SideBarRequests'
import { errorReducer } from '../layouts/ErrorBox'

export const makeRootReducer = asyncReducers => {
  const combinedReducer = combineReducers({
    view: viewReducer,
    location: locationReducer,
    user: userReducer,
    errorBox: errorReducer,
    modal: modalReducer,
    overlay: overlayReducer,
    drawer: drawerReducer,
    searchTerm: searchTermReducer,
    loading: loadingReducer,
    sending: sendingReducer,
    studyLock: lockingReducer,
    studies: studiesReducer,
    study: studyReducer,
    navBar: navBarReducer.navProps,
    subroute: navBarReducer.subroute,
    tableFilters: tableFilterReducer,
    visitSchedules: visitSchedulesReducer,
    paymentsReducer,
    sideBarReducer,
    ...asyncReducers,
  })

  const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGGED_OUT') {
      // return initial state for all reducers
      state = undefined
    }

    return combinedReducer(state, action)
  }

  return rootReducer
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export const injectReducers = (store, reducers) => {
  Object.keys(reducers).forEach(key => {
    injectReducer(store, { key, reducer: reducers[key] })
  })
}

export default makeRootReducer
