import React from 'react'
import { Container, AutocompleteSearch } from 'components/UIElements'
import PropTypes from 'prop-types'
import ParticipantTable from '../../ParticipantsPage/components/ParticipantTable'

const renderFooter = filteredList => {
  if (filteredList.length > 0) return null
  return (
    <tr>
      <td className='checkbox-row' />
      <td className='no-ptp-added'>No Participants Added</td>
    </tr>
  )
}

class ParticipantManagement extends React.Component {
  getPartialList = () => {
    if (this.props.list === null) return []
    return Object.keys(this.props.listTracker).map(id => this.props.ptpMap[id])
  }

  onToggleAll = () => {
    if (Object.keys(this.props.checked).length > 0) {
      this.props.toggleCheckAll(this.props.ptpMap)
    } else {
      this.props.toggleCheckAll(this.props.ptpMap, Object.keys(this.props.listTracker))
    }
  }

  render() {
    const {
      list,
      checked,
      participantDataFields,
      ptpMap,
      toggleParticipant,
      canAddPtp,
      canWriteCohort,
      hasPiiRole,
      trackId,
      enforceSingleTrackAssignment,
    } = this.props

    const checkedIds = Object.keys(checked || {})

    // if hasPiiRole the searchList take both firstName and lastName, else only the name
    const searchList = list
      ? list.map(row => ({
          key: row[1].value,
          text: `${row[3].value} ${hasPiiRole ? row[4].value : ''}`,
          tracks: row[10]?.cohorts,
        }))
      : []
    const disabled = !(canAddPtp || canWriteCohort)

    const filteredList = enforceSingleTrackAssignment
      ? searchList.filter(item => {
          if (!item?.tracks?.length) {
            return true
          }
          if (checkedIds.includes(String(item.key))) {
            return true
          }
          if (item?.tracks?.includes(String(trackId))) {
            return true
          }
          return false
        })
      : searchList

    return (
      <Container centered flex column>
        <AutocompleteSearch
          id='add-participant-track'
          disabled={disabled}
          checked={checked}
          toggleItem={toggleParticipant}
          placeholder='Search participants to add to this track'
          list={filteredList}
        />
        <p>
          <b>{`${Object.keys(checked).length} participant(s) added`}</b>
        </p>
        <ParticipantTable
          participantDataFields={participantDataFields}
          ptpMap={ptpMap}
          toggleCheckAll={this.onToggleAll}
          toggleParticipant={toggleParticipant}
          checkedPtps={checked}
          disableRowClick
          participantList={this.getPartialList()}
          renderFooter={renderFooter}
          hasPiiRole={hasPiiRole}
          hasOverFlow
        />
      </Container>
    )
  }
}

ParticipantManagement.propTypes = {
  canWriteCohort: PropTypes.bool,
  canAddPtp: PropTypes.bool,
  checked: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.array),
  participantDataFields: PropTypes.objectOf(PropTypes.array),
  ptpMap: PropTypes.object,
  toggleCheckAll: PropTypes.func,
  toggleParticipant: PropTypes.func,
  hasPiiRole: PropTypes.bool,
}

export default ParticipantManagement
