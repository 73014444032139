import React from 'react'
import PropTypes from 'prop-types'
import { Radio, TimePicker, NumberInputDropdown, Button, WarningText, Popup } from 'components/UIElements'
import { connect } from 'react-redux'
import moment from 'moment'
import { pluralize } from 'utils/misc'
import _dayOptions from '../constants/schedulerConstants'
import VisibilityDisplay from './VisibilityDisplay'
import { schedulerActions } from '../../../modules/Instrument'
import VisibilityRangesDisplay from './VisibilityRangesDisplay'

class VisibilityContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showWarning: false }
  }

  onToggleWarning = _showWarning => {
    const { showWarning } = this.state
    return () => {
      if (_showWarning === undefined) {
        this.setState({ showWarning: !showWarning })
      } else {
        this.setState({ showWarning: _showWarning })
      }
    }
  }

  onUpdateVisibleDays = (num, errorPath) => {
    const { recurring, updateRecurringField } = this.props
    const { showWarning } = this.state
    if (num > recurring.interval) {
      if (!showWarning) this.onToggleWarning()()
    } else {
      updateRecurringField('visible_days', num, 'schedule.recurring.visible_days')
      if (showWarning) this.onToggleWarning()()
    }
  }

  render() {
    const { recurring } = this.props
    const { showWarning } = this.state
    const showVisibleDays = recurring && recurring.scheme === 'n-days'
    return (
      <div className='visibility-menu'>
        {showVisibleDays && (
          <VisibleDays
            onToggleWarning={this.onToggleWarning}
            onUpdateVisibleDays={this.onUpdateVisibleDays}
            showWarning={showWarning}
            {...this.props}
          />
        )}
        <Visibility {...this.props} />
      </div>
    )
  }
}

const VisibleDays = props => {
  const { recurring, onUpdateVisibleDays, onToggleWarning, errors } = props
  const dayOptions = _dayOptions.filter(option => option.key <= recurring.interval)
  return (
    <div className='visible-days'>
      <p>1. How many days should the instrument be visible for?</p>
      <NumberInputDropdown
        onOpen={onToggleWarning(false)}
        value={recurring.visible_days || ''}
        options={dayOptions}
        onChange={num => onUpdateVisibleDays(num, 'schedule.recurring.visible_days')}
      />
      {` ${pluralize(recurring.visible_days, 'day', 'days', false)}`}
      <WarningText text={errors['schedule.recurring.visible_days']} />
      <p className='repeat-cycle-length'>
        {`Repeat Cycle Length: ${
          recurring.interval
            ? `${recurring.interval} ${pluralize(recurring.interval, 'day', 'days', false)}`
            : 'Repeat Interval Not Set'
        }`}
      </p>
      <VisibilityDisplay filled={recurring.visible_days} total={recurring.interval} />
    </div>
  )
}

const Visibility = props => {
  const { updateVisibility, addVisibilityRange, updateVisibilityRange, metadata, recurring } = props
  const { visibility, visibilityRanges } = metadata
  return (
    <div className='visibility'>
      <div className='flexed start-justified'>
        <p>
          {`${
            recurring && recurring.scheme === 'n-days' ? '2. ' : ''
          }What time of the day should this instrument be visible? `}
        </p>
        <Popup hover noPointer dark position='bottom' trigger={<i className='fas fa-info-circle' />}>
          <p>
            Within the days that this instrument is available, participants will only be able to complete it during
            these hours.
          </p>
        </Popup>
      </div>
      <Radio
        selected={visibility === 'all_day'}
        onClick={() => {
          updateVisibility('visibility', 'all_day')
          updateVisibility('visibilityRanges', [{ start: null, end: null }])
        }}
        id='all-day'
        content='All Day'
      />
      <Radio
        selected={visibility === 'specific_time'}
        onClick={() => {
          updateVisibility('visibility', 'specific_time')
          updateVisibility('visibilityRanges', [])
          addVisibilityRange()
        }}
        id='specific-time'
        content='Specific Time(s)'
      />
      {visibility === 'specific_time' && (
        <VisibilityRanges
          updateVisibility={updateVisibility}
          addVisibilityRange={addVisibilityRange}
          ranges={visibilityRanges}
          updateVisibilityRange={updateVisibilityRange}
        />
      )}
    </div>
  )
}

const VisibilityRanges = ({ ranges, updateVisibilityRange, updateVisibility, addVisibilityRange }) => {
  const visData = _getVisibilityData(ranges)
  const onDelete = idx => () => {
    const newRanges = ranges.slice()
    newRanges.splice(idx, 1)
    updateVisibility('visibilityRanges', newRanges)
  }
  return (
    <div className='visibility-ranges'>
      {ranges.map((range, idx) => {
        const { disabledStart, disabledEnd } = _getDisabledTime(range)
        const isLast = idx === ranges.length - 1
        return (
          <div key={`visibility-range-${idx}`} className='range-item'>
            <TimePicker
              id='from-time'
              disablePriorFromTime={moment.utc(range.end)}
              value={moment.utc(range.start)}
              onChange={mom => updateVisibilityRange(idx, 'start', mom)}
            />
            <span className='range-hyphen'>-</span>
            <TimePicker
              id='to-time'
              disablePriorToTime={moment.utc(range.start)}
              value={moment.utc(range.end)}
              onChange={mom => updateVisibilityRange(idx, 'end', mom)}
            />
            {isLast && (
              <Button className='grey' id='add-another-time' content='Add Another Time' onClick={addVisibilityRange} />
            )}
            {!isLast && ranges.length > 1 && <i onClick={onDelete(idx)} className='clickable fas fa-times' />}
          </div>
        )
      })}
      <VisibilityRangesDisplay {...visData} />
    </div>
  )
}

const _getDisabledTime = ({ start, end }) => {
  const disabledStart = { hour: [], min: [] }
  const disabledEnd = { hour: [], min: [] }
  const startHour = moment.utc(start).hour()
  const startMin = moment.utc(start).minute()
  const endHour = moment.utc(end).hour()
  const endMin = moment.utc(end).minute()
  for (let h = 0; h < 24; h++) {
    if (h > endHour) disabledStart.hour.push(h)
    if (h < startHour) disabledEnd.hour.push(h)
  }
  if (startHour !== endHour) return { disabledStart, disabledEnd }
  for (let m = 0; m < 60; m++) {
    if (m > endMin) disabledStart.min.push(m)
    if (m < startMin) disabledEnd.min.push(m)
  }
  return { disabledStart, disabledEnd }
}

const _getHourRanges = ranges => {
  return ranges.map(range => {
    const startTime = moment.utc(range.start)
    const startHour = startTime.hour()
    const startMinute = startTime.minute()
    const endTime = moment.utc(range.end)
    const endHour = endTime.hour()
    const endMinute = endTime.minute()
    return { start: startHour, startMinute, end: endHour, endMinute, startTime, endTime }
  })
}

const _getMinMax = hourRanges => {
  return {
    min: Math.min(...hourRanges.map(range => range.start)),
    max: Math.max(...hourRanges.map(range => (range.end && range.endMinute ? range.end + 1 : range.end))),
  }
}

const _getVisibilityData = ranges => {
  const hourRanges = _getHourRanges(ranges)
  const hourRangesSortedByStart = [...hourRanges].sort((a, b) =>
    moment(a.startTime).isAfter(b.startTime, 'minute') ? 1 : -1,
  )
  const hourRangesSortedByEnd = [...hourRanges].sort((a, b) =>
    moment(a.endTime).isAfter(b.endTime, 'minute') ? 1 : -1,
  )

  const { min, max } = _getMinMax(hourRanges)
  const visibleHours = new Array(24).fill(false)
  hourRanges.forEach(range => {
    for (let i = range.start; i < range.end; i++) {
      if (i === range.start && range.startMinute) {
        visibleHours[i] = 'partial'
      } else if (i === range.end - 1) {
        visibleHours[i] = 'complete'
        visibleHours[i + 1] = range.endMinute ? 'reverse-partial' : false
      } else {
        visibleHours[i] = 'complete'
      }
    }
  })

  const startTickValue = hourRangesSortedByStart[0].startTime.format('H:mm')
  const endTickValue = hourRangesSortedByEnd[hourRangesSortedByEnd.length - 1].endTime.format('H:mm')

  return {
    min,
    max,
    visibleHours,
    hourRanges,
    startTickValue,
    endTickValue,
  }
}

VisibilityContent.propTypes = {
  recurring: PropTypes.shape({
    interval: PropTypes.number,
    scheme: PropTypes.string,
  }),
  updateRecurringField: PropTypes.func,
}

VisibleDays.propTypes = {
  errors: PropTypes.shape({
    'schedule.recurring.visible_days': PropTypes.string,
  }),
  onToggleWarning: PropTypes.func,
  onUpdateVisibleDays: PropTypes.func,
  recurring: PropTypes.shape({
    interval: PropTypes.number,
    scheme: PropTypes.string,
    visible_days: PropTypes.number,
  }),
}

Visibility.propTypes = {
  addVisibilityRange: PropTypes.func,
  metadata: PropTypes.shape({
    visibility: PropTypes.string,
    visibilityRanges: PropTypes.arrayOf(PropTypes.object),
  }),
  recurring: PropTypes.shape({
    scheme: PropTypes.string,
  }),
  updateVisibility: PropTypes.func,
  updateVisibilityRange: PropTypes.func,
}

VisibilityRanges.propTypes = {
  addVisibilityRange: PropTypes.func,
  ranges: PropTypes.arrayOf(PropTypes.object),
  updateVisibility: PropTypes.func,
  updateVisibilityRange: PropTypes.func,
}

export default connect(({ instrumentReducer }) => {
  const { metadata } = instrumentReducer.instrument
  return {
    metadata,
    errors: instrumentReducer.errors,
  }
}, schedulerActions)(VisibilityContent)
