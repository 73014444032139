import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio } from 'components/UIElements'
import { MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP, MODAL_CONTENT_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import Subheader from '../../../components/Subheader'
import ParticipantSelection from './ParticipantSelection'
import ParticipantVisitFields from './ParticipantVisitFields'
import ParticipantVisitTable from './ParticipantVisitTable'
import '../stylesheets/participantVisit.scss'
import AdhocVisitDate from './AdhocVisitDate'
import ParticipantVisitDate from './ParticipantVisitDate'
import { DEFAULT_REMINDER } from '../../VisitPage/components/VisitPage'
import VisitReminders from '../../VisitTemplatePage/components/VisitReminders'
import { REMINDER_PERIOD_OPTIONS } from '../../VisitTemplatePage/components/VisitReminder'
import { HOURS_IN_DAY } from '../../../../../../../../../../../utils/constants'

function ParticipantVisitPage(props) {
  const {
    disabled,
    disableSubsequentVisitAdjustment,
    initPage,
    isUpdate,
    onDeleteAdhocVisit,
    onSaveParticipantVisit,
    openModal,
    participantVisit,
    ptpID,
    ptpVisitsLoading,
    ssvpID,
    studyID,
    subtitleName,
    updatePtpVisitMetadataVal,
    visitorParticipantLoader,
  } = props
  const { checkedPtp, visit_metadata = { reminders: {} }, id, isAdhoc, visitDay } = participantVisit
  const isPtpChecked = Object.keys(checkedPtp).length > 0
  const [daysBeforeVisitPassed, setDaysBeforeVisitPassed] = useState(false)
  const [reminders, setReminders] = useState([DEFAULT_REMINDER])
  const [remindersEnabled, setRemindersEnabled] = useState(false)
  const [reminderNotificationsEnabled, setReminderNotificationsEnabled] = useState(true)
  const [pushSubsequent, setPushSubsequent] = useState(null)
  const [dayChanged, setDayChanged] = useState(false)

  const { original_visit_day } = visit_metadata
  const isDayOneVisit = original_visit_day === 1
  const disableSaveForUpdate = !isDayOneVisit && !isAdhoc && isUpdate && pushSubsequent === null

  useEffect(() => {
    if (disableSubsequentVisitAdjustment) {
      setPushSubsequent(false)
    }
    return initPage
  }, [])

  useEffect(() => {
    const { new_visit_day } = visit_metadata
    setDayChanged(visitDay !== new_visit_day)
    return () => {
      setDayChanged(false)
    }
  }, [visit_metadata.new_visit_day])

  const _onSaveParticipantVisit = () => {
    onSaveParticipantVisit({
      studyID,
      isUpdate,
      ssvpID,
      pushSubsequent,
    })
  }

  const onOpenDeleteModal = () => {
    openModal({
      onConfirm: () => {
        onDeleteAdhocVisit({ studyID, ssvpID, ptpID })
      },
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.spacious,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      content: STRINGS.deleteAdhocVisitConfirm,
      heading: MODAL_CONTENT_MAP.pleaseConfirm,
      isUrgent: true,
    })
  }

  const onOpenUpdateVisitScheduleModal = () => {
    openModal({
      onConfirm: _onSaveParticipantVisit,
      className: MODAL_CLASSES_MAP.spacious,
      heading: STRINGS.visitScheduleUpdatingHeading,
      content: STRINGS.visitScheduleUpdatingBody,
    })
  }

  useEffect(() => {
    const { reminders: visitReminders } = visit_metadata
    if (visitReminders && isUpdate) {
      const formattedReminders = Object.keys(visitReminders).map(key => ({
        id: key,
        value: visit_metadata.reminders[key] / HOURS_IN_DAY,
        period: REMINDER_PERIOD_OPTIONS[0].key,
      }))
      if (formattedReminders.length) {
        setRemindersEnabled(true)
        setReminders(formattedReminders)
      }
    }
  }, [visit_metadata, isUpdate])

  return (
    <div className='participant-visit-page page'>
      <div className='flexed'>
        <h4>{isUpdate && !isAdhoc ? STRINGS.visitScheduleRescheduleAVisit : subtitleName}</h4>
      </div>
      <ParticipantVisitFields {...props} extraProps={{ isUpdate, ptpVisitsLoading, visitorParticipantLoader }}>
        {isAdhoc || !isUpdate ? (
          <>
            <Subheader subheaderText={STRINGS.visitScheduleWhoWillBeReq} />
            <div className='recipient-panel'>
              <Radio id='send-to-all-participants' disabled={disabled} selected content={STRINGS.participant} />
              <ParticipantSelection {...props} />
              <ParticipantVisitTable {...props} />
            </div>
          </>
        ) : null}
      </ParticipantVisitFields>
      {isAdhoc || !isUpdate ? (
        <AdhocVisitDate
          {...props}
          daysBeforeVisitPassed={daysBeforeVisitPassed}
          setDaysBeforeVisitPassed={setDaysBeforeVisitPassed}
        />
      ) : null}
      {!isAdhoc && isUpdate ? (
        <ParticipantVisitDate
          {...props}
          daysBeforeVisitPassed={daysBeforeVisitPassed}
          setDaysBeforeVisitPassed={setDaysBeforeVisitPassed}
          pushSubsequent={pushSubsequent}
          setPushSubsequent={setPushSubsequent}
          dayChanged={dayChanged}
          isDayOneVisit={isDayOneVisit}
        />
      ) : null}
      <VisitReminders
        {...props}
        reminders={reminders}
        setReminders={setReminders}
        remindersEnabled={remindersEnabled}
        setRemindersEnabled={setRemindersEnabled}
        reminderNotificationsEnabled={reminderNotificationsEnabled}
        setReminderNotificationsEnabled={setReminderNotificationsEnabled}
        updateVisitMetadata={updatePtpVisitMetadataVal}
        disabled={!!id || disabled}
      />
      <div className='button-list'>
        <Button
          id='cancel'
          grey
          className='visit-cancel-button'
          content={STRINGS.cancel}
          to={`/studies/${studyID}/visit-schedules`}
        />
        <Button
          disabled={!isPtpChecked || daysBeforeVisitPassed || disabled || disableSaveForUpdate}
          id='next'
          className='deploy-button'
          content={STRINGS.visitScheduleSaveVisit}
          onClick={() => {
            if (isUpdate && !isAdhoc && dayChanged) {
              onOpenUpdateVisitScheduleModal()
            } else _onSaveParticipantVisit()
          }}
        />
        {isUpdate && isAdhoc && (
          <Button
            red
            disabled={disabled}
            id='delete-adhoc-visit'
            isThrottled
            content={STRINGS.deleteAdhocVisit}
            onClick={onOpenDeleteModal}
          />
        )}
      </div>
    </div>
  )
}

ParticipantVisitPage.propTypes = {
  disabled: PropTypes.bool,
  disableSubsequentVisitAdjustment: PropTypes.bool,
  subtitleName: PropTypes.string,
  participantVisit: PropTypes.shape({ visit_metadata: PropTypes.shape({ reminders: PropTypes.object }) }),
  onDeleteAdhocVisit: PropTypes.func,
  onSaveParticipantVisit: PropTypes.func,
  studyID: PropTypes.number,
  initPage: PropTypes.func,
  isUpdate: PropTypes.bool,
  openModal: PropTypes.func,
  ptpID: PropTypes.string,
  ptpVisitsLoading: PropTypes.bool,
  STRINGS: PropTypes.objectOf(PropTypes.string),
  ssvpID: PropTypes.string,
  updatePtpVisitMetadataVal: PropTypes.func,
  visitorParticipantLoader: PropTypes.bool,
}

export default ParticipantVisitPage
