import React from 'react'
import '../stylesheets/createStudy.scss'
import PropTypes from 'prop-types'
import STRINGS from 'utils/strings'
import CreateStudyReview from './CreateStudyReview'

class CreateStudyConfigurationPage extends React.Component {
  componentDidMount() {
    const { disabled, isWizard, resetStudyData, setNavBarProps, fetchLocales } = this.props
    if (!disabled || isWizard) {
      resetStudyData()
    }
    if (!disabled && !isWizard) {
      setNavBarProps({
        back: '/studies',
        backText: STRINGS.studies,
        close: '/studies',
      })
    }
    fetchLocales()
  }

  componentWillUnmount() {
    const { setNavBarProps } = this.props
    setNavBarProps(null)
  }

  render() {
    return (
      <div className='create-study page'>
        <CreateStudyReview {...this.props} />
      </div>
    )
  }
}

CreateStudyConfigurationPage.propTypes = {
  createStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  disabled: PropTypes.bool,
  fetchLocales: PropTypes.func,
  hasDeleteStudyPermissions: PropTypes.bool,
  isRootOrDchAdmin: PropTypes.bool,
  isWizard: PropTypes.bool,
  resetStudy: PropTypes.func,
  resetStudyData: PropTypes.func,
  saveAttempted: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  study: PropTypes.shape({
    self_sign_code: PropTypes.string,
    name: PropTypes.string,
    platform_name: PropTypes.string,
    id: PropTypes.number,
  }),
  updateConfig: PropTypes.func,
  updateStudy: PropTypes.func,
}

export default CreateStudyConfigurationPage
