import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/UIElements'
import { CHINA_DEPLOY } from 'utils/constants'

const toggleMap = {
  geofencing: 'Geofencing',
  battery: 'Battery',
  location: 'Location',
  stepcounter: 'Step Counter',
  appsinstalled: 'Apps Installed',
  screentime: 'Screen Time',
  // phonecontacts: 'Phone Contacts (Android Only)',
  // calls: 'Calls',
  // bluetooth: 'Bluetooth',
  // sms: 'SMS',
  // gmail: 'Gmail',
  // googlecontacts: 'Google Contacts (iOS only)',
  // gmailaccounts: 'Gmail Accounts (Android Only)',
  // facebook: 'Facebook',
  // linkedin: 'LinkedIn',
  // twitter: 'Twitter',
  // instagram: 'Instagram',
}

const AppPushConfig = props => {
  const { configObj, disabled, toggleModule, isRootOrDchAdmin, id } = props
  const columns = [[], []]
  let currentCol = 0
  let toggles = Object.keys(toggleMap)

  // This disables and un-renders the geofencing toggle for the China build 
  // or for users who are not Root or DCH Admin
  if (CHINA_DEPLOY || !isRootOrDchAdmin) {
    toggles = toggles.filter(toggle => toggle !== 'geofencing')
  }

  toggles.forEach((key, idx) => {
    if (columns[currentCol].length >= Math.ceil(toggles.length / 2)) {
      currentCol++
    }
    columns[currentCol].push(
      <div className='toggle-input' key={`${key}_toggle`}>
        <p className='setting-text'>{`${idx + 1}.  ${toggleMap[key]}`}</p>
        <Checkbox id={`${key}-toggle`} toggle disabled={disabled} checked={configObj.modules[key]} onClick={() => toggleModule(key)} />
      </div>,
    )
  })
  return (
    <div>
      <h5>Capture Mobile Data</h5>
      <p>
        Capture additional data about your participant's mobile phone every 15 minutes or 24 hours, which can be
        downloaded at any time. Participants will be able to opt in.
      </p>
      <div className='toggle-columns'>
        {columns.map((content, idx) => {
          return <div key={`col_${idx}`}>{content}</div>
        })}
      </div>
    </div>
  )
}

AppPushConfig.propTypes = {
  configObj: PropTypes.shape({
    modules: PropTypes.objectOf(PropTypes.bool),
  }),
  disabled: PropTypes.bool,
  isRootOrDchAdmin: PropTypes.bool,
  toggleModule: PropTypes.func,
}

export default AppPushConfig
