import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserHasFeatures } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { setPageTitle } from 'utils/misc'
import { onFetchInstrument } from '../../modules/Instrument'
import { fetchClinro } from './modules/Clinro'
import EditClinroView from './containers/ClinroContainer'
// Sync route definition
export default store => ({
  path: ':instrumentID/editclinro',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */
        const { studyID, instrumentID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Edit Clinician Instrument | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(onFetchInstrument(studyID, instrumentID))
        store.dispatch(fetchClinro(studyID, instrumentID))
        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'instruments',
              )(UserHasScope(['wa'], UserHasFeatures(EditClinroView, ['clinro'], 'instruments'), false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      '/instruments/editclinro/:id',
    )
  },
})
