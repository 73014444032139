import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Tags from '@yaireo/tagify/dist/react.tagify'
import 'styles/d3/tags.scss'

//
// ref: https://github.com/yairEO/tagify#mixed-content
//
const settings = {
  mode: 'mix',
  pattern: /[#]/, // use '#' to initiate tagging
  dropdown: {
    classname: 'tag-dropdown',
    enabled: 0, // 0 value sets the dropdown to be visible on focus
    highlightFirst: true,
    position: 'all', // positions the dropdown right below the textarea
  },
  duplicates: true,
  editTags: false, // do not allow double tap on tag to edit tag
  enforceWhitelist: true, // can only tag things in the list, no adding new items
}

const TagsComponent = props => {
  const { list = [], value, placeholder = 'Enter value', onChange } = props

  const _onChange = useCallback(e => {
    e.persist()
    onChange(e.target.value.trim())
  }, [])

  settings.whitelist = list

  return (
    <div className='d3-tags'>
      <Tags mode='textarea' placeholder={placeholder} settings={settings} className='d3-tags' onChange={_onChange}>
        {value}
      </Tags>
    </div>
  )
}

TagsComponent.propTypes = {
  id: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}
export default TagsComponent
