import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import CasesPageRoute from './routes/CasesPage'
import EditCaseRoute from './routes/EditCase'
import { UserIsAuthenticated, UserHasValidPassword } from '../../../../../../containers/authContainers'
import casesReducer, { actions as caseActions } from './routes/CasesPage/modules/Cases'

export default store => {
  return {
    path: 'cases',
    exact: true,
    getIndexRoute(location, cb) {
      const { params } = location
      const { studyID } = params
      store.dispatch(setSubroute('cases'))
      injectReducers(store, {
        cases: casesReducer,
      })
      store.dispatch(caseActions.fetchCases(studyID))
      const { currentStudy } = store.getState().study
      setPageTitle(`Cases | Study ${studyID} | ${currentStudy.platform_name}`)
      // TODO: add user scope checks
      cb(null, UserIsAuthenticated(UserHasValidPassword(CasesPageRoute(store))))
    },
    getChildRoutes(location, cb) {
      injectReducers(store, {
        cases: casesReducer,
      })
      cb(null, [EditCaseRoute(store)])
    },
  }
}
