import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect'
import { browserHistory } from 'react-router'
import { getUserScope, getUserScopeByStudy, getHighestUser, getUserRoleByStudy, checkConfigFeatures } from 'utils/misc'

export const UserHasFeatures = (
  Component,
  features,
  redirectSubroute,
  isNull = false,
  every = true,
  redirectIfEnabled = false,
  nullMeansEnabled = false,
) =>
  connectedReduxRedirect({
    authenticatedSelector: state => {
      const studyConfig = state.study.currentStudy.config
      if (isNull && studyConfig === null) return true
      const func = every ? 'every' : 'some'
      return features[func](feature => checkConfigFeatures(studyConfig, [feature], redirectIfEnabled, nullMeansEnabled))
    },
    redirectPath: state => {
      if (!Object.keys(state.user).length) return '/login'
      const currentStudy = state.study.currentStudy.id
      return `/studies/${currentStudy}/${redirectSubroute}`
    },
    redirectAction: newLoc => () => {
      browserHistory.replace(newLoc)
    },
    wrapperDisplayName: 'UserStudyHasFeature',
    allowRedirectBack: false,
  })(Component)

export const UserHasScopes = (scopeArray, Component, every = true) =>
  connectedReduxRedirect({
    authenticatedSelector: state => {
      const func = every ? 'every' : 'some'
      if (!state.user) return false
      return scopeArray[func](scope => getHighestUser({ user: state.user }).includes(scope))
    },
    redirectPath: state => (Object.keys(state.user).length ? '/unauthorized' : '/login'),
    redirectAction: newLoc => () => {
      browserHistory.replace(newLoc)
    },
    wrapperDisplayName: 'UserHasScopes',
    allowRedirectBack: false,
  })(Component)

export const UserHasScope = (scopeArray, Component, every = true, studyID, redirectRoute, study) =>
  connectedReduxRedirect({
    authenticatedSelector: state => {
      const func = every ? 'every' : 'some'
      if (!state.user) return false
      if (study) {
        return scopeArray[func](scope => getUserScopeByStudy(state.user, study).includes(scope))
      }
      return scopeArray[func](scope => getUserScope(state.user, studyID).includes(scope))
    },
    redirectPath: state => {
      if (redirectRoute) return redirectRoute
      return Object.keys(state.user).length ? '/unauthorized' : '/login'
    },
    redirectAction: newLoc => () => {
      browserHistory.replace(newLoc)
    },
    wrapperDisplayName: 'UserHasScope',
    allowRedirectBack: false,
  })(Component)

export const UserDoesNotHaveRole = (disallowedRoleArr, Component) =>
  connectedReduxRedirect({
    authenticatedSelector: state => {
      if (state.study.currentStudy.id) {
        return state.user && !disallowedRoleArr.includes(getUserRoleByStudy(state.user, state.study.currentStudy.id))
      }
      return state.user && !disallowedRoleArr.includes(getHighestUser({ user: state.user, getRoleName: true }))
    },
    redirectPath: state => (Object.keys(state.user).length ? '/unauthorized' : '/login'),
    redirectAction: newLoc => () => {
      browserHistory.replace(newLoc)
    },
    wrapperDisplayName: 'UserDoesNotHaveRole',
    allowRedirectBack: false,
  })(Component)

export const UserHasRole = (allowedRoles, studyId, Component, redirectSubroute = '') =>
  connectedReduxRedirect({
    authenticatedSelector: state => state.user && allowedRoles.includes(getUserRoleByStudy(state.user, studyId)),
    redirectPath: state => {
      if (redirectSubroute) return redirectSubroute
      return Object.keys(state.user).length ? '/unauthorized' : '/login'
    },
    redirectAction: newLoc => () => {
      browserHistory.replace(newLoc)
    },
    wrapperDisplayName: 'UserHasRole',
    allowRedirectBack: false,
  })(Component)

export const UserHasValidPassword = connectedReduxRedirect({
  authenticatedSelector: state => {
    return state.user && !state.user.expiration_message
  },
  redirectAction: newLoc => dispatch => {
    browserHistory.replace(newLoc)
  },
  redirectPath: '/users/reset',
  wrapperDisplayName: 'UserHasValidPW',
  allowRedirectBack: false,
})

export const UserIsAuthenticated = connectedReduxRedirect({
  authenticatedSelector: state => !!Object.keys(state.user).length,
  wrapperDisplayName: 'UserIsAuthenticated',
  redirectPath: '/login',
  redirectAction: newLoc => dispatch => {
    browserHistory.replace(newLoc)
  },
  allowRedirectBack: nextState => (nextState.route.path === 'unauthorized' ? false : true),
})

export const UserIsNotAuthenticated = connectedReduxRedirect({
  authenticatedSelector: state => !Object.keys(state.user || {}).length,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  redirectPath: (state, ownProps) => ownProps.location.query.redirect || '/',
  redirectAction: newLoc => dispatch => {
    browserHistory.replace(newLoc)
  },
  allowRedirectBack: false,
})
