import request from 'utils/request'
import { browserHistory } from 'react-router'
import { setUserLinktFlag } from 'utils/userUtils'

// ------------------------------------
// Constants
// ------------------------------------

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const UPDATE_USERNAME = 'UPDATE_USERNAME'
export const SET_USER_IMAGE = 'SET_USER_IMAGE'
export const SET_USER = 'SET_USER'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'

const setLoginError = error => {
  return {
    type: SET_LOGIN_ERROR,
    error,
  }
}

export function fetchInsightsSetup() {
  return dispatch => {
    return dispatch(
      request({
        url: `/control/insights/setup`,
        success: textResponse => {
          return textResponse
        },
        hasLoader: true,
        loadingKey: 'sisenseSetup',
        resType: 'text',
      }),
    ).catch(e => {
      throw new Error(e)
    })
  }
}

export const fetchUser = (userData = {}, hasLoader = true, redirectToResetPW) => {
  return dispatch => {
    const localUserData = JSON.parse(localStorage.getItem('user'))
    const userDataWithAuth = userData.token ? userData : localUserData
    const success = jsonBody => {
      const payload = { ...userDataWithAuth, ...jsonBody }
      localStorage.setItem('user', JSON.stringify(payload))
      dispatch({
        type: SET_USER,
        payload,
      })
      if (redirectToResetPW) browserHistory.push('/users/reset')
      return Promise.resolve()
    }
    return dispatch(
      request({
        method: 'GET',
        url: '/control/users/me',
        hasLoader,
        success,
        userData: userDataWithAuth,
      }),
    )
  }
}

function login(username, password) {
  return dispatch => {
    const success = jsonBody => {
      const { expiration_message } = jsonBody
      const payload = Object.assign(JSON.parse(atob(jsonBody.token.split('.')[1])), jsonBody)
      dispatch(setLoginError(''))
      dispatch(fetchUser(payload, true, !!expiration_message)).then(() => dispatch(fetchInsightsSetup()))
    }

    const fail = (res, content) => {
      dispatch(setLoginError(content.message))
    }
    return dispatch(
      request({
        method: 'POST',
        url: '/control/admin/login',
        body: JSON.stringify({ username, password }),
        catchMessage: 'There was a problem logging in, please try again later.',
        authRequired: false,
        success,
        fail,
      }),
    )
  }
}

function logout(user = {}) {
  const userId = (JSON.parse(localStorage.getItem('user')) || user).id
  localStorage.setItem('lastUser', userId)
  setUserLinktFlag(userId)
  localStorage.removeItem('user')
  return dispatch => {
    dispatch({
      type: USER_LOGGED_OUT,
    })
    return Promise.resolve()
  }
}

function updateUsername(username) {
  return {
    type: UPDATE_USERNAME,
    payload: username,
  }
}

export function fetchUserImage(userID) {
  return dispatch => {
    const errorMessage = 'Could not fetch user image'
    return dispatch(
      request({
        method: 'GET',
        url: `/control/assets/profile_pics/${userID}`,
        headerOptions: { 'Content-Type': 'image' },
        errorMessage,
        success: json => {
          dispatch({
            type: SET_USER_IMAGE,
            payload: json.asset,
          })
        },
        fail: () => {
          if (__DEV__) console.log('user does not have an image')
        },
      }),
    )
  }
}

export default function userUpdate(state = JSON.parse(localStorage.getItem('user')) || {}, { type, payload }) {
  switch (type) {
    case UPDATE_USERNAME:
      return { ...state, name: payload }
    case USER_LOGGED_OUT:
      return {}
    case SET_USER: {
      return { ...state, ...payload }
    }
    case SET_USER_IMAGE:
      return { ...state, userImage: payload }
    default:
      return state
  }
}

export const authActions = {
  login,
  logout,
  updateUsername,
}
