export const objForEach = (obj, callback) => {
  Object.keys(obj).forEach((key, idx) => {
    callback(obj[key], key, idx)
  })
}

export const objMapToObj = (obj, callback) => {
  let newObj = {}
  Object.keys(obj).map(key => {
    newObj[key] = callback(obj[key], key)
  })
  return newObj
}

export const objMapToList = (obj, callback) => {
  return Object.keys(obj).map(key => {
    return callback(obj[key], key)
  })
}

export const _isObject = item => {
  return item !== null && typeof item === 'object'
}

export const isObjEmpty = obj => {
  return Object.entries(obj).length === 0
}

const _checkKeys = (keys1, keys2) => {
  if (keys1.length !== keys2.length) return false
  const map = {}
  keys1.forEach(key => {
    map[key] = null
  })
  return keys2.every(key => map.hasOwnProperty(key))
}

export const compareJSON = (json1, json2) => {
  if (!_isObject(json1)) {
    if (!_isObject(json2)) return json1 === json2
    return false
  }
  if (!_isObject(json2)) return false

  const keys1 = Object.keys(json1)
  const keys2 = Object.keys(json2)
  if (!_checkKeys(keys1, keys2)) return false
  for (let i = 0; i < keys1.length; i++) {
    if (!compareJSON(json1[keys1[i]], json2[keys1[i]])) return false
  }
  return true
}

export const deepCompareJSON = (json1, json2) => {
  if (!_isObject(json1)) {
    if (!_isObject(json2)) return json1 === json2
    return false
  }
  if (JSON.stringify(json1) !== JSON.stringify(json2)) return false
  return true
}

export const deleteObjectKey = (obj, keys) =>
  Object.keys(obj).reduce((newObj, key) => {
    if (!keys.includes(key)) {
      newObj[key] = obj[key]
    }
    return newObj
  }, {})

/**
 * Filter an object by key
 * @param {Object} obj
 * @param {Function} predicate
 */

export const filterObjectByKey = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(key))
    .reduce((res, key) => ((res[key] = obj[key]), res), {})

export const deepCompareObj = (obj1, obj2) => {
  if (obj1 === obj2) return true
  if (!(obj1 instanceof Object) || !(obj2 instanceof Object) || obj1.constructor !== obj1.constructor) return false
  for (let p in obj1) {
    if (!obj1.hasOwnProperty(p) || obj1[p] === obj2[p]) continue
    if (!obj2.hasOwnProperty(p) || !deepCompareObj(obj1[p], obj2[p]) || typeof obj1[p] !== 'object') return false
  }
  for (let p in obj2) if (obj2.hasOwnProperty(p) && !obj1.hasOwnProperty(p)) return false
  return true
}

export const setObjectProperty = (obj, path, value) => {
  if (Object(obj) !== obj) return obj // When obj is not an object
  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || []
  path.slice(0, -1).reduce(
    (
      a,
      c,
      i, // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] = {}), // assign a new plain object
    obj,
  )[path[path.length - 1]] = value // Finally assign the value to the last key
  return obj // Return the top-level object to allow chaining
}
