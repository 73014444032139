import React from 'react'
import { Container, Checkbox, Button } from 'components/UIElements'
import { compareJSON } from 'utils/object'
import { browserHistory } from 'react-router'
import { TYPE_MAP } from '../../../NotificationsPage/components/NotificationTypes'

class EditAutoNotification extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...this.props.settings }
    this.backURL = `/studies/${props.params.studyID}/notifications`
  }

  componentDidMount() {
    const { setNavBarProps } = this.props
    setNavBarProps({
      back: this.backURL,
      close: this.backURL,
      backText: 'Notifications',
    })
  }

  onSave = () => {
    this.props.saveNotification(this.props.params.studyID, this.props.notificationType, this.state, this.backURL)
  }

  onToggle = () => {
    this.onUpdateNotification('enabled', !this.state.enabled)
  }

  onUpdateNotification = (key, value) => {
    this.setState({ [key]: value })
  }

  componentWillUnmount() {
    this.props.setNavBarProps(null)
  }

  renderTrigger = () => {
    return (
      <div className='trigger-section flexed start-justified'>
        <div>Trigger</div>
        {!!TYPE_MAP[this.props.notificationType].infoText ? (
          <>
            <i className='fas fa-info-circle' />
            <div className='text-bubble'>{TYPE_MAP[this.props.notificationType].infoText}</div>
          </>
        ) : null}
      </div>
    )
  }

  render() {
    const { notificationType, saveNotification, studyLock } = this.props
    const typeInfo = TYPE_MAP[notificationType]
    const saveDisabled = compareJSON(this.props.settings, this.state)
    return (
      <div className='edit-auto-notification page'>
        <h3>{`Edit ${typeInfo.text} Notification`}</h3>
        <Container>
          <div className='flexed-header'>
            <h5>{`${typeInfo.text} Notification`}</h5>
            <Checkbox
              onClick={() => !studyLock && this.onToggle()}
              toggle
              disabled={studyLock}
              checked={this.state.enabled}
              label={this.state.enabled ? 'ON' : 'OFF'}
            />
          </div>
          <div className={this.state.enabled ? '' : 'disabled'}>
            <h5>Notification Message</h5>
            {typeInfo.label}
            {this.renderTrigger()}
            <p>{typeInfo.inputText}</p>
            {typeInfo.input({
              ...this.state,
              onUpdateNotification: this.onUpdateNotification,
            })}
          </div>
        </Container>
        <div className='button-list'>
          <Button grey id='cancel' content='Cancel' onClick={() => browserHistory.push(this.backURL)} />
          <Button disabled={saveDisabled || studyLock} id='save-changes' content='Save Changes' onClick={this.onSave} />
        </div>
      </div>
    )
  }
}

export default EditAutoNotification
