import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import VisitSchedulesPage from './routes/VisitSchedulesPage'
import VisitScheduleNav from './routes/VisitScheduleNav'
import VisitSchedules from './routes/VisitSchedulesPage/modules/VisitSchedules'

export default store => {
  return {
    path: 'visit-schedules',
    exact: true,
    getIndexRoute(location, cb) {
      store.dispatch(setSubroute('visitSchedules'))
      const { currentStudy } = store.getState().study
      setPageTitle(`Visits Schedule | Study ${currentStudy.id} | ${currentStudy.platform_name}`)
      injectReducers(store, { visitSchedules: VisitSchedules })
      cb(null, UserIsAuthenticated(UserHasValidPassword(VisitSchedulesPage(store))))
    },
    getChildRoutes(location, cb) {
      cb(null, [VisitScheduleNav(store)])
    },
  }
}
