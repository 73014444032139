import React, { useState } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { useOffsets } from 'utils/misc'
import StickyHeader from '../../../../../../../../../components/StickyHeader'
import SitesTable from './SitesTable'
import '../stylesheets/Sites.scss'

const SitesPage = props => {
  const {
    canEdit,
    canCreate,
    canDelete,
    deleteLeafSites,
    params,
    sites,
    sitesInfo,
    studyLock,
    downloadSiteData,
    canDownloadSiteList = false,
  } = props
  const { studyID, siteID } = params
  const { checkedSites, sitesList } = sites
  const [showHeaderCreateSite, setHeaderCreateSite] = useState(false)

  const offsets = useOffsets()
  const subsequentOffsets = offsets ? offsets.slice(1) : []
  const anyCheckedSites = Object.keys(checkedSites).length > 0
  const showSitemanagementButtons = !showHeaderCreateSite
  const noSites = sitesList.length === 0
  const checkedSitesArr = Object.keys(checkedSites)
  const plural = checkedSitesArr.length > 1

  return (
    <div className='sites page'>
      <StickyHeader offset={offsets ? offsets[0] : 0}>
        <span />
        <div>
          {!noSites && canEdit && canDelete && showSitemanagementButtons && anyCheckedSites && (
            <Button
              grey
              content={`Delete Site${plural ? 's' : ''}`}
              onClick={() => deleteLeafSites(studyID, checkedSitesArr, siteID, sitesInfo, plural)}
              id='delete-site-button'
            />
          )}
          {!noSites && canCreate && showSitemanagementButtons && (
            <Button
              disabled={showHeaderCreateSite || studyLock}
              content='Add Site'
              onClick={() => {
                if (!studyLock) {
                  browserHistory.push(`/studies/${studyID}/sites/create`)
                }
              }}
            />
          )}
        </div>
      </StickyHeader>
      {!noSites && canDownloadSiteList ? (
        <Button
          id='download-site-list'
          className='data-download'
          content='Sites List'
          icon='fas fa-cloud-download-alt'
          isThrottled
          link
          onClick={() => downloadSiteData(studyID)}
        />
      ) : null}
      <SitesTable
        offsets={subsequentOffsets}
        {...props}
        showHeaderCreateSite={showHeaderCreateSite}
        setHeaderCreateSite={setHeaderCreateSite}
      />
    </div>
  )
}

SitesPage.propTypes = {
  canCreate: PropTypes.bool,
  canEdit: PropTypes.bool,
  deleteLeafSites: PropTypes.func,
  onSetOrphanedParticipants: PropTypes.func,
  params: PropTypes.shape({ studyID: PropTypes.string, siteID: PropTypes.string }),
  sites: PropTypes.shape({
    checkedSites: PropTypes.object,
    sitesList: PropTypes.arrayOf(PropTypes.array),
  }),
  sitesInfo: PropTypes.objectOf(PropTypes.object),
  studyLock: PropTypes.bool,
}

export default SitesPage
