import React from 'react'
import PropTypes from 'prop-types'
import { QUESTION_TYPE_MAP } from 'utils/constants'
import ClinroLogicPreview from './ClinroLogicPreview'
import MatrixView from './MatrixQuestion/MatrixView'
import SelectView from './SelectView'
import ImageCaptureView from '../../EditSurvey/components/ImageCaptureView'

class QuestionView extends React.Component {
  constructor(props) {
    super(props)
    this.DISPLAY_PROPS = {
      integer: '123',
      decimal: '123',
      text: 'abc',
      date: <i key='fa-calendar-preview' className='far fa-calendar-alt' />,
      time: <i key='fa-clock-preview' className='far fa-clock' />,
      datetime: (
        <span>
          <i key='fa-calendar-preview' className='far fa-calendar-alt' />
          <i key='fa-clock-preview' className='far fa-clock' />
        </span>
      ),
    }
  }

  renderDefaultQuestion = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <div>
        {item.type !== 'introduction' ? (
          <div className='non-select-preview'>
            <div style={{ fontFamily: 'AmericanTypeWriter' }}>{displayComponent}</div>
          </div>
        ) : null}
        <ClinroLogicPreview item={item} errors={errors} />
      </div>
    )
  }

  renderSelectQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <SelectView {...this.props} />
        <ClinroLogicPreview errors={errors} item={item} />
      </div>
    )
  }

  renderMatrixQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <MatrixView {...this.props} />
        <ClinroLogicPreview errors={errors} item={item} />
      </div>
    )
  }

  renderScribbleQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <div className='non-select-preview flexed column center-justified scribble'>
          <i key='fa-pencil-alt' className='fas fa-pencil-alt' />
          <p>Participant drawing will be collected</p>
        </div>
        <ClinroLogicPreview errors={errors} item={item} />
      </div>
    )
  }

  renderImageCaptureQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <ImageCaptureView isClinro {...this.props} />
        <ClinroLogicPreview error={errors} item={item} />
      </div>
    )
  }

  render() {
    const { item } = this.props
    switch (item.type) {
      case QUESTION_TYPE_MAP.numericRatingScale:
      case QUESTION_TYPE_MAP.likert:
      case QUESTION_TYPE_MAP.selectOne:
      case QUESTION_TYPE_MAP.selectMultiple:
      case QUESTION_TYPE_MAP.multipleField:
        return this.renderSelectQuestion()
      case QUESTION_TYPE_MAP.matrix:
        return this.renderMatrixQuestion()
      case QUESTION_TYPE_MAP.scribble:
        return this.renderScribbleQuestion()
      case QUESTION_TYPE_MAP.imageCapture:
        return this.renderImageCaptureQuestion()
      default:
        return this.renderDefaultQuestion()
    }
  }
}

export default QuestionView

QuestionView.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.string,
  onDeleteChoice: PropTypes.func,
  updateItem: PropTypes.func,
  isActive: PropTypes.bool,
}
