import { setSubroute } from 'store/navbar'
import { UserIsAuthenticated, UserHasValidPassword, UserHasRole } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import { USER_ROLES_WITH_SCHEDULE_ADHOC_VISIT_PERMISSION } from 'utils/constants'
import participantReducer from '../../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import participantVisitReducer, { fetchVisitSelectionPtps } from '../ParticipantVisitPage/modules/ParticipantVisit'
import ParticipantVisitContainer from '../ParticipantVisitPage/container/ParticipantVisitContainer'

export const injectVisitReducers = store => {
  injectReducers(store, {
    participantVisitReducer,
    participantReducer,
  })
}

// Sync route definition
export default store => ({
  path: 'participant-visit/:ptpID-:ssvpID/edit',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectVisitReducers(store)
        store.dispatch(setSubroute('participant-visit'))

        const { studyID, ptpID, ssvpID } = nextState.params
        const { study, user } = store.getState()
        const { currentStudy } = study
        setPageTitle(`Edit participant visit | Study ${studyID} | ${currentStudy.platform_name}`)
        // Fetch visit selection participants
        store.dispatch(fetchVisitSelectionPtps(studyID, true, ptpID))

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'visit-schedules',
              )(
                UserHasRole(
                  USER_ROLES_WITH_SCHEDULE_ADHOC_VISIT_PERMISSION,
                  studyID,
                  ParticipantVisitContainer,
                  `/studies/${studyID}/visit-schedules`,
                ),
              ),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'participant-visit',
    )
  },
})
