import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import EnforcedSiteLanguagesConfig from './EnforcedSiteLanguagesConfig'

const FIELDS = ['country', 'region/state', 'address', 'date added']
const VIRTUAL_FIELDS = ['description', 'date added']
const SHARED_FIELDS = ['no of participants', 'compliance']
const SIGN_UP_CODE = 'sign up code'
const LANGUAGES = 'languages'

const SitePreviewPage = ({ canEdit, closeDrawer, link, siteInfo, studyLock }) => {
  const siteName = siteInfo[2].value
  const country = siteInfo[3].value
  const region = siteInfo[4].value
  const address = siteInfo[2].description || '---'
  const signUpCode = siteInfo[8].value
  const description = address
  const numParticipants = siteInfo[5].value
  const dateAdded = siteInfo[6].value
  const compliance = siteInfo[7].value * 100
  const { config } = siteInfo[2]
  const enforcedLanguages = config ? siteInfo[2].config.enforced_language : {}

  const fieldValues = [country, region, address, dateAdded, signUpCode]
  const virtualFieldValues = [description, dateAdded, signUpCode]
  const sharedFields = [numParticipants, compliance]

  const isVirtual = siteInfo[2].isVirtual || false
  let fieldsToUse = isVirtual ? VIRTUAL_FIELDS : FIELDS
  if (signUpCode) {
    fieldsToUse = [...fieldsToUse, SIGN_UP_CODE]
  }
  return (
    <div className='preview page'>
      <div className='preview-header'>
        <div className='flexed-header'>
          <div className='title'>
            <h2>{siteName}</h2>
          </div>
          {canEdit && link && (
            <Button
              content='Edit This Site'
              disabled={studyLock}
              onClick={() => {
                if (!studyLock) {
                  closeDrawer()
                  browserHistory.push(link)
                }
              }}
            />
          )}
        </div>
      </div>
      <div>
        <table className='avoid-break'>
          <tbody>
            {fieldsToUse.map((field, idx) => {
              return (
                <tr key={field}>
                  <td className='first-column'>{field}</td>
                  <td>{isVirtual ? virtualFieldValues[idx] : fieldValues[idx]}</td>
                </tr>
              )
            })}
            <tr>
              <td className='spacer' />
            </tr>
            {SHARED_FIELDS.map((field, idx) => {
              const label = field === 'no of participants' ? ' Participants' : ' %'
              return (
                <tr key={field}>
                  <td className='first-column'>{field}</td>
                  <td>
                    {sharedFields[idx]}
                    <span className='field-label'>{label}</span>
                  </td>
                </tr>
              )
            })}
            {config && enforcedLanguages && (
              <tr key={LANGUAGES}>
                <td className='first-column'>{LANGUAGES}</td>
                <td>
                  <EnforcedSiteLanguagesConfig config={config} disabled />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

SitePreviewPage.propTypes = {
  canEdit: PropTypes.bool,
  closeDrawer: PropTypes.func,
  link: PropTypes.string,
  siteInfo: PropTypes.arrayOf(PropTypes.object),
  studyLock: PropTypes.bool,
}

export default SitePreviewPage
