import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import 'styles/d3/drawer.scss'
import { drawerActions } from 'store/drawer'
import SideBar from './SideBar'

class Drawer extends React.Component {
  componentDidUpdate(prevProps) {
    const { location, closeDrawer } = this.props
    if (prevProps.location.pathname !== location.pathname) closeDrawer()
  }

  render() {
    return <SideBar {...this.props} />
  }
}

Drawer.propTypes = {
  closeDrawer: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default connect(null, drawerActions)(Drawer)
