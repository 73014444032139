import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Input, Checkbox, Popup } from 'components/UIElements'
import STRINGS from 'utils/strings'
import {
  TIMER_DEFAULT_DATE,
  TIMER_TIME_FORMAT,
  TIMER_TIME_OPTIONS,
  MINUTES_IN_HOUR,
  SECONDS_IN_MINUTE,
} from 'utils/constants'

const TimerView = ({ item, itemId, updateItem, preview, errors, clearQuestionError }) => {
  useEffect(() => {
    if (updateItem && !item.attributes.timer_details) {
      updateItem(itemId, {
        ...item,
        attributes: {
          ...item.attributes,
          timer_details: {
            time_string: '',
            time: 0,
            countdown: false,
            audio_alert: false,
            disable_pause_resume: false,
          },
        },
      })
    }
  }, [updateItem, item])

  const updateTimerDetails = (key, value) => {
    if (updateItem) {
      updateItem(itemId, {
        ...item,
        attributes: {
          ...item.attributes,
          timer_details: {
            ...item.attributes.timer_details,
            [key]: value,
          },
        },
      })
    }
  }

  const { minutes, seconds } = useMemo(() => {
    const intervals = item?.attributes?.timer_details?.time_string
      ? item?.attributes?.timer_details?.time_string.split(':')
      : ['', '']
    return {
      minutes: intervals[0],
      seconds: intervals[1],
    }
  }, [item])

  const updateTime = (type, value) => {
    const newDate = moment(TIMER_DEFAULT_DATE)

    if (type === TIMER_TIME_OPTIONS.second && value >= SECONDS_IN_MINUTE) {
      newDate.set(TIMER_TIME_OPTIONS.minute, parseInt(value / SECONDS_IN_MINUTE))
      newDate.set(TIMER_TIME_OPTIONS.second, value % SECONDS_IN_MINUTE)
    } else {
      newDate.set(type, value)
      newDate.set(
        type === TIMER_TIME_OPTIONS.minute ? TIMER_TIME_OPTIONS.second : TIMER_TIME_OPTIONS.minute,
        type === TIMER_TIME_OPTIONS.minute ? seconds : minutes,
      )
    }

    if (updateItem) {
      updateItem(itemId, {
        ...item,
        attributes: {
          ...item.attributes,
          timer_details: {
            ...item.attributes.timer_details,
            time_string: moment(newDate).format(TIMER_TIME_FORMAT),
            time: Number(moment(newDate).seconds()) + Number(moment(newDate).minutes()) * SECONDS_IN_MINUTE,
          },
        },
      })
    }
    clearQuestionError(itemId)
  }

  return (
    <div className='non-select-preview timer'>
      <div className=''>
        <p className='timer-subtitle'>{STRINGS.pleaseConfirmAmountOfTime}</p>
        <div className='timer-details'>
          <div className='time-settings'>
            <p className='time-settings-label'>{STRINGS.count}</p>
            <Input
              placeholder='00'
              disabled={preview}
              className='time-settings-input'
              value={minutes}
              onChange={value => updateTime(TIMER_TIME_OPTIONS.minute, value)}
              type='number'
              max={MINUTES_IN_HOUR}
              min={0}
              hasError={!!errors?.zeroMinutesAndSeconds}
            />
            <p className='time-settings-label'>{STRINGS.minutes}</p>
            <Input
              placeholder='00'
              disabled={preview}
              className='time-settings-input'
              value={seconds}
              onChange={value => updateTime(TIMER_TIME_OPTIONS.second, value)}
              type='number'
              max={SECONDS_IN_MINUTE}
              min={0}
              hasError={!!errors?.zeroMinutesAndSeconds}
            />
            <p className='time-settings-label'>{STRINGS.seconds}</p>
          </div>
          <div className='toggles-settings'>
            <div className='toggle-box'>
              <Checkbox
                checked={item?.attributes?.timer_details?.countdown}
                disabled={preview}
                onClick={() => updateTimerDetails('countdown', !item?.attributes?.timer_details?.countdown)}
                label={
                  <div className='toggle-label-wrapper'>
                    <p className='toggle-label'>{STRINGS.timer_countdown}</p>
                    <Popup
                      className='box-popup'
                      align='left'
                      position='bottom'
                      hover
                      dark
                      trigger={<i className='fas fa-info-circle' />}>
                      <div className='tooltip-text'>{STRINGS.timer_countdown_tooltip}</div>
                    </Popup>
                  </div>
                }
                toggle
              />
            </div>

            <div className='toggle-box'>
              <Checkbox
                checked={item?.attributes?.timer_details?.audio_alert}
                disabled={preview}
                onClick={() => updateTimerDetails('audio_alert', !item?.attributes?.timer_details?.audio_alert)}
                label={
                  <div className='toggle-label-wrapper'>
                    <p className='toggle-label'>{STRINGS.timer_audible_alert}</p>
                  </div>
                }
                toggle
              />
            </div>

            <div className='toggle-box'>
              <Checkbox
                checked={item?.attributes?.timer_details?.disable_pause_resume}
                disabled={preview}
                onClick={() =>
                  updateTimerDetails('disable_pause_resume', !item?.attributes?.timer_details?.disable_pause_resume)
                }
                label={
                  <div className='toggle-label-wrapper'>
                    <p className='toggle-label'>{STRINGS.timer_disable_pause}</p>
                    <Popup
                      className='box-popup'
                      align='left'
                      position='bottom'
                      hover
                      dark
                      trigger={<i className='fas fa-info-circle' />}>
                      <div className='tooltip-text'>{STRINGS.timer_disable_pause_tooltip}</div>
                    </Popup>
                  </div>
                }
                toggle
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TimerView.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.string,
  updateItem: PropTypes.func,
  errors: PropTypes.object,
  preview: PropTypes.bool,
}

export default TimerView
