import request, { generateNotyMessage } from 'utils/request'
import { actions as notyActions } from 'layouts/ErrorBox'
import '../../../../../../../../../styles/d3/dataInbox.scss'
import moment from 'moment'

const SET_DATA_INBOX = 'SET_DATA_INBOX'
const RESET_DATA_INBOX = 'RESET_DATA_INBOX'

const SET_DATA_INBOX_URL = 'SET_DATA_INBOX_URL'
const RESET_DATA_INBOX_URL = 'RESET_DATA_INBOX_URL'

const setDataInbox = payload => {
  return {
    type: SET_DATA_INBOX,
    payload,
  }
}

const resetDataInbox = () => {
  return {
    type: RESET_DATA_INBOX,
  }
}

const setDataInboxUrl = payload => {
  return {
    type: SET_DATA_INBOX_URL,
    payload,
  }
}

const resetDataInboxUrl = () => {
  return {
    type: RESET_DATA_INBOX_URL,
  }
}

const fetchDataInbox = ({ studyId, instrumentType = 'ediary', siteId, from, to }) => {
  return (dispatch, getState) => {
    const { study } = getState()
    const { currentStudy } = study
    const { base_site_ids: siteIds } = currentStudy

    const siteID = siteId || siteIds?.[0]

    const formattedFrom = moment(from).format('YYYYMMDD')
    const formattedTo = moment(to).format('YYYYMMDD')

    const getQueryParams = () => {
      let params = ''
      if (siteID) {
        params += `?site_id=${siteID}`
      }

      if (params) {
        params += `&from=${formattedFrom}&to=${formattedTo}`
      } else {
        params += `?from=${formattedFrom}&to=${formattedTo}`
      }

      return params
    }

    const success = payload => {
      dispatch(setDataInbox(payload?.requests))
    }
    return dispatch(
      request({
        url: `/control/studies/${studyId}/instrument-report-requests/${instrumentType}${getQueryParams()}`,
        hasLoader: true,
        success,
      }),
    )
  }
}

const fetchDataInboxUrl = id => {
  return dispatch => {
    const success = payload => {
      dispatch(setDataInboxUrl(payload))
      window.open(payload?.url, '_blank')
    }

    const fail = (_, content) => {
      dispatch(
        notyActions.showError({
          text: generateNotyMessage(
            content?.message || 'There was an error during denerating the report. Please try again later.',
            false,
          ),
        }),
      )
    }
    return dispatch(
      request({
        url: `/control/instrument-report/${id}`,
        success,
        fail,
      }),
    )
  }
}

const DEFAULT_DATA_INBOX_STATE = {
  data_inbox: [],
  dataInboxUrl: null,
}

const dataInboxMaterial = (state = DEFAULT_DATA_INBOX_STATE, action) => {
  switch (action.type) {
    case SET_DATA_INBOX: {
      return {
        ...state,
        data_inbox: action.payload,
      }
    }
    case RESET_DATA_INBOX: {
      return {
        ...state,
        data_inbox: [],
      }
    }
    case SET_DATA_INBOX_URL:
      return {
        ...state,
        dataInboxUrl: action.payload,
      }
    case RESET_DATA_INBOX_URL:
      return {
        ...state,
        dataInboxUrl: null,
      }
    default:
      return state
  }
}

export const actions = {
  fetchDataInbox,
  fetchDataInboxUrl,
  resetDataInbox,
  resetDataInboxUrl,
}

export default dataInboxMaterial
