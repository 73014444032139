import React from 'react'

const Icon = ({ x = 10, y = 10, content, children, type }) => {
  return (
    <svg x={x - 10} y={y - 10} className={`data-gap-icon ${type}`} width='20px' height='20px'>
      {children}
    </svg>
  )
}

const CompletedIcon = (props = {}) => {
  return (
    <Icon {...props}>
      <rect
        onMouseEnter={props.onMouseEnter}
        strokeWidth='1'
        fill='#8BD5B8'
        x='0'
        y='0'
        width='20'
        height='20'
        rx='4'
      />
    </Icon>
  )
}

const ExpiredIcon = (props = {}) => {
  return (
    <Icon {...props}>
      <rect onMouseEnter={props.onMouseEnter} fill='#767676' x='0' y='0' width='20' height='20' />
    </Icon>
  )
}

const IncompleteIcon = (props = {}) => {
  return (
    <Icon {...props}>
      <rect
        onMouseEnter={props.onMouseEnter}
        fill='none'
        x='1'
        y='1'
        stroke='#F27B21'
        strokeWidth='2'
        width='18'
        height='18'
        rx='4'
      />
    </Icon>
  )
}

const AnnouncementIcon = (props = {}) => {
  return (
    <Icon {...props}>
      <polygon onMouseEnter={props.onMouseEnter} fill='#F9E3B0' fillRule='nonzero' points='10 2 18 18 2 18' />
    </Icon>
  )
}

const OpenedAppIcon = (props = {}) => {
  return (
    <Icon {...props}>
      <rect
        onMouseEnter={props.onMouseEnter}
        fill='none'
        stroke='#B0CBE2'
        transform='translate(10.000000, 10.000000) rotate(45.000000) translate(-10.000000, -10.000000) '
        x='5.5'
        y='5.5'
        width='9'
        height='9'
      />
    </Icon>
  )
}

export const Legend = () => {
  return (
    <div className='legend-box'>
      {Object.keys(ICONS).map(type => {
        const icon = ICONS[type]()
        const text = LEGEND_TEXT[type]
        return (
          <div key={type} className='item'>
            {icon}
            <div>{text}</div>
          </div>
        )
      })}
    </div>
  )
}

let LEGEND_TEXT = {
  ANNOUNCEMENT_SENT: `Announcement Received`,
  APP_OPENED: `Opened App`,
  COMPLETED: `Completed Instrument`,
  EXPIRED: `Expired Instrument`,
  SENT: `Incomplete Instrument (Received)`,
}

const COLORS = {
  SENT: { default: '#8bd5b8', hover: '#3db988' },
  ANNOUNCEMENT_SENT: { default: '#f9e3b0', hover: '#efb331' },
  COMPLETED: { default: '#8bd5b8', hover: '#3db988' },
  APP_OPENED: { default: '#bacede', hover: '#5b91be' },
  EXPIRED: { default: '#767676', hover: '#595959' },
}

const ICONS = {
  SENT: IncompleteIcon,
  EXPIRED: ExpiredIcon,
  APP_OPENED: OpenedAppIcon,
  COMPLETED: CompletedIcon,
  ANNOUNCEMENT_SENT: AnnouncementIcon,
}

export default ICONS
