import React from 'react'
import { connect } from 'react-redux'
import { _getMapState, mapActions } from 'components/Filter'
import { Filter } from 'components/UIElements'
import { renderers } from './Utils'

const customMapState = state => {
  return {
    ..._getMapState('tracks')(state),
    allCohorts: state.participants.cohortList.map(row => {
      return { id: row[0].value, label: row[1].value }
    }),
  }
}

class TracksFilter extends Filter {
  constructor(props) {
    super(props)
    this.defaults = { set: new Set(), filterType: 'checkbox' }
    this.state = { ...this.defaults }
    this.key = 'tracks'
    this.header = 'Tracks'
  }

  onCheckCohort = id => {
    const { set } = this.state
    return () => {
      const newSet = new Set(set)
      if (newSet.has(id)) newSet.delete(id)
      else newSet.add(id)
      this.setState({ set: newSet, _edited: true })
    }
  }

  getFilter(criteria) {
    const { set } = criteria
    let criteriaArr = Array.from(criteria.set)
    return data => {
      let cohortSet = new Set(data.cohorts)
      return set.size === 0 || criteriaArr.every(id => cohortSet.has(id + ''))
    }
  }

  render() {
    return super.render(
      <div style={{ width: 300 }} className='checkbox-list'>
        {renderers.renderCheckboxList(this.state, this.props.allCohorts, this.onCheckCohort)}
      </div>,
    )
  }
}

export default connect(customMapState, mapActions)(TracksFilter)
