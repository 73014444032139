import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Container, DatePicker, Dropdown, Input, Radio } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { DATE_FORMAT_MAP } from 'utils/constants'
import Subheader from '../../../components/Subheader'

const getDateErrors = (date, options) => {
  const inputDateValue = options?.props?.inputProps?.ref?.current?.value
  if (inputDateValue === '') return false
  return !date || typeof date === 'undefined' || moment().isAfter(date, 'day')
}

function AdhocVisitDate(props) {
  const {
    disabled,
    errors,
    participantVisit,
    participantVisitList,
    updateAdhocScheduleSelection,
    updateAdhocSchedule,
    daysBeforeVisitPassed,
    setDaysBeforeVisitPassed,
  } = props
  const { specificDate: specificDateErr, visitSelection: visitSelectionErr } = errors
  const { adhocVisitSchedule, checkedPtp } = participantVisit
  const isPtpChecked = Object.keys(checkedPtp).length > 0
  const isPtpVisitListEmpty = participantVisitList.length === 0

  const [startVisitDateError, setStartVisitDateError] = useState(false)
  const [visitDays, setVisitDays] = useState(1)
  const [visitDate, setVisitDate] = useState('')

  const resetDaysBefore = () => {
    setDaysBeforeVisitPassed(false)
    setVisitDays(1)
    setVisitDate('')
  }

  useEffect(() => {
    resetDaysBefore()
  }, [checkedPtp])

  useEffect(() => {
    const { isSelected } = adhocVisitSchedule.daysBeforeVisit
    if (isSelected) {
      if (visitDays && visitDate !== '') {
        const substractDays = moment(visitDate)
          .subtract(visitDays, 'days')
          .format(DATE_FORMAT_MAP.datePickerWithFullTime)
        updateAdhocSchedule({
          key: 'daysBeforeVisit',
          value: substractDays,
        })
        if (moment().isAfter(substractDays, 'day')) setDaysBeforeVisitPassed(true)
        else setDaysBeforeVisitPassed(false)
      }
    } else {
      resetDaysBefore()
    }
  }, [adhocVisitSchedule, visitDays, visitDate])

  const ptpVisitsDropdownOptions = participantVisitList
    .map(ptpVisitArr => {
      const { value, visitId } = ptpVisitArr[0]
      const ptpVisitStatus = ptpVisitArr.filter(({ key }) => key === 'status')[0]
      const ptpVisitDatetime = ptpVisitArr.filter(({ key }) => key === 'scheduledDate')[0]
      if (ptpVisitStatus?.value.toLowerCase() === 'scheduled') {
        return {
          key: visitId,
          value: visitId,
          text: value,
          visitDatetime: ptpVisitDatetime.sortValue,
        }
      }
    })
    .filter(val => val)
  const isPtPVisitsDropdownOptEmpty = ptpVisitsDropdownOptions.length == 0

  const specificDate = (adhocScheduleKey, isDisabled) => (
    <div className='specific-date flexed'>
      <span>On a specific date.</span>
      <DatePicker
        disabled={isDisabled}
        disabledDays={{ before: new Date() }}
        hasError={startVisitDateError || (specificDateErr && !adhocVisitSchedule[adhocScheduleKey].value)}
        date={
          adhocVisitSchedule[adhocScheduleKey].value
            ? moment(adhocVisitSchedule[adhocScheduleKey].value, DATE_FORMAT_MAP.datePickerWithFullTime)
            : adhocVisitSchedule[adhocScheduleKey].value
        }
        onDayChange={(date, options) => {
          setStartVisitDateError(getDateErrors(date, options))
          if (date)
            updateAdhocSchedule({ key: adhocScheduleKey, value: date.format(DATE_FORMAT_MAP.datePickerWithFullTime) })
          else updateAdhocSchedule({ key: adhocScheduleKey, value: date })
        }}
      />
    </div>
  )

  const daysBeforeVisit = (adhocScheduleKey, isDisabled) => {
    const updateInput = value => updateAdhocSchedule({ key: adhocScheduleKey, value, subkey: 'days' })
    const getVisitDropDownErr = () => {
      if (visitSelectionErr && adhocVisitSchedule[adhocScheduleKey].visit.key === '') {
        return visitSelectionErr
      }
    }
    const getDayBeforeVisitErr = () => {
      if (daysBeforeVisitPassed) return 'Visit cannot be set in the past'
    }
    return (
      <div className='days-before-visit flexed'>
        <Input
          disabled={isDisabled}
          value={adhocVisitSchedule[adhocScheduleKey].days}
          min={1}
          onChange={val => {
            // prevents user from entering negative number
            if (Number(val) < 0) {
              updateInput(1)
              setVisitDays(1)
            } else {
              const isNum = val || val === '0'
              updateInput(isNum ? Number(val) : val)
              setVisitDays(isNum ? Number(val) : val)
            }
          }}
          onBlur={e => {
            // This ensures there is no empty value if the input is left blank
            if (e.target.value === '' || e.target.value === '0') {
              updateInput(1)
              setVisitDays(1)
            }
          }}
          type='number'
          className='days-input'
          hasError={daysBeforeVisitPassed}
          errorText={getDayBeforeVisitErr()}
        />
        <span>{pluralize(adhocVisitSchedule[adhocScheduleKey].days, 'day', 'days', false)} before</span>
        <Dropdown
          id='currency-dropdown'
          disabled={isDisabled}
          options={ptpVisitsDropdownOptions}
          onSelect={item => {
            const value = {
              key: item.key,
              text: item.text,
              visitDatetime: item.visitDatetime,
            }
            updateAdhocSchedule({ key: adhocScheduleKey, value, subkey: 'visit' })
            setVisitDate(item.visitDatetime)
          }}
          selected={adhocVisitSchedule[adhocScheduleKey].visit.key}
          errorText={getVisitDropDownErr()}
          hasError={visitSelectionErr && adhocVisitSchedule[adhocScheduleKey].visit.key === ''}
        />
      </div>
    )
  }

  const ADHOC_SELECTORS = [
    { key: 'specificDate', id: 'specific-date', content: specificDate },
    { key: 'daysBeforeVisit', id: 'days-before-visit', content: daysBeforeVisit },
  ]

  return (
    isPtpChecked && (
      <Container>
        <div className='adhoc-visit-date'>
          <Subheader subheaderText='When will this adhoc visit happen? ' />
          {ADHOC_SELECTORS.map(({ key, id, content }) => {
            const isDisabled =
              (key === 'daysBeforeVisit' && (isPtpVisitListEmpty || isPtPVisitsDropdownOptEmpty)) || disabled
            return (
              <Radio
                id={`radio-${id}`}
                key={id}
                selected={adhocVisitSchedule[key].isSelected}
                content={content(key, isDisabled)}
                onClick={() => {
                  updateAdhocScheduleSelection(key)
                }}
                disabled={isDisabled}
              />
            )
          })}
        </div>
      </Container>
    )
  )
}

AdhocVisitDate.propTypes = {
  disabled: PropTypes.bool,
  participantVisit: PropTypes.object,
  participantVisitList: PropTypes.arrayOf(PropTypes.array),
  updateAdhocScheduleSelection: PropTypes.func,
  updateAdhocSchedule: PropTypes.func,
}

export default AdhocVisitDate
