import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducer } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import reducer, { fetchConsent } from '../../modules/NewConsent'
import ConsentPreview from './containers/ConsentPreviewContainer'
// Sync route definition
export default store => ({
  path: 'consent/preview',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        injectReducer(store, {
          key: 'consentReducer',
          reducer,
        })
        /*  Webpack - use require callback to define
       dependencies for bundling   */

        const { studyID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Preview Consent | Study ${studyID} | ${currentStudy.platform_name}`)
        // store.dispatch(fetchConsent(studyID))
        /*  Return getComponent   */
        cb(null, UserIsAuthenticated(UserHasValidPassword(UserHasScope(['ra'], ConsentPreview, false, studyID))))

        /* Webpack named bundle   */
      },
      '/e-consent/consent/preview',
    )
  },
})
