import { connect } from 'react-redux'
import Noty from 'noty'
import ErrorBox from './ErrorBoxComponent'
import './stylesheets/ErrorBox.scss'

//
//  Actions
//

export const ERROR = 'ERROR'
const SHOW_NOTY = 'SHOW_NOTY'
const CLOSE_NOTY = 'CLOSE_NOTY'

//
// Action Creators
//

const showNoty = type => {
  return options => {
    return {
      type: SHOW_NOTY,
      options: Object.assign({}, options, { type }),
    }
  }
}

const closeNoty = () => {
  return {
    type: CLOSE_NOTY,
  }
}

export const actions = {
  showError: showNoty('error'),
  showSuccess: showNoty('success'),
  closeNoty,
}

//
//  Reducers
//

const _getDefaults = () => {
  return {
    options: {
      theme: 'semanticui',
      timeout: 3000,
      type: '',
      layout: 'bottomRight',
    },
    noty: null,
  }
}

export const errorReducer = (state = _getDefaults(), action) => {
  switch (action.type) {
    case SHOW_NOTY:
      const newState = Object.assign({}, state)
      newState.options = Object.assign(state.options, action.options)
      newState.noty = new Noty(newState.options)
      return newState
    case CLOSE_NOTY:
      return _getDefaults()
    default:
      return state
  }
}

//
//  Container
//

export default connect(
  state => state.errorBox,
  { closeNoty },
)(ErrorBox)
