import request from 'utils/request'
import { MODAL_CONTENT_MAP } from 'utils/constants'
import { modalActions } from 'store/modal'

const SET_TRAINING = 'SET_TRAINING'
const RESET_TRAINING = 'RESET_TRAINING'

const setTraining = payload => {
  return {
    type: SET_TRAINING,
    payload,
  }
}

const resetTraining = () => {
  return {
    type: RESET_TRAINING,
  }
}

const fetchTraining = studyId => {
  return dispatch => {
    const success = payload => {
      dispatch(setTraining(payload))
    }
    return dispatch(
      request({
        url: `/control/studies/${studyId}/training`,
        hasLoader: true,
        success,
      }),
    )
  }
}

const deleteTrainingMaterial = ({ id, studyId }) => {
  return dispatch => {
    const onConfirm = () => {
      const success = () => {
        dispatch(fetchTraining(studyId))
      }
      return dispatch(
        request({
          method: 'DELETE',
          url: `/control/studies/${studyId}/training/${id}`,
          successMessage: 'Training successfully deleted',
          successIcon: 'fas fa-trash-alt',
          hasLoader: true,
          success,
        }),
      )
    }
    dispatch(
      modalActions.openModal({
        onConfirm,
        content: MODAL_CONTENT_MAP.deleteTrainingMaterial,
      }),
    )
  }
}

const DEFAULT_TRAINING_STATE = {
  training_documents: [],
  training_videos: [],
}

const parseTraining = payload => {
  const result = { training_documents: [], training_videos: [] }
  payload.training_documents.forEach(doc => {
    const resultDoc = { ...doc }
    resultDoc.type = 'document'
    result.training_documents.push(resultDoc)
  })
  payload.training_videos.forEach(video => {
    const resultVideo = { ...video }
    resultVideo.type = 'video'
    result.training_videos.push(resultVideo)
  })

  return result
}

const trainingMaterial = (state = DEFAULT_TRAINING_STATE, action) => {
  switch (action.type) {
    case SET_TRAINING: {
      return parseTraining(action.payload)
    }
    case RESET_TRAINING: {
      return DEFAULT_TRAINING_STATE
    }
    default:
      return state
  }
}

export const actions = {
  deleteTrainingMaterial,
  fetchTraining,
  resetTraining,
}

export default trainingMaterial
