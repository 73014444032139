import React from 'react'
import { Checkbox, Popup } from 'components/UIElements'
import STRINGS from '../../../../../../../../../../../../utils/strings'

const TimePickerOverlay = ({ checked, disabled, onClick }) => {
  return (
    <div className='timepicker-overlay-toggle'>
      <Checkbox disabled={disabled} toggle checked={checked} onClick={onClick} />
      <span>{STRINGS.participantCurrentTime}</span>
      <Popup
        className='future-dates-popup'
        align={'left'}
        position='bottom'
        hover
        noPointer
        dark
        trigger={<i className='fas fa-info-circle' />}>
        {<p>{STRINGS.participantCurrentTime}</p>}
      </Popup>
    </div>
  )
}
export default TimePickerOverlay
