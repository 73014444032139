import React from 'react'
import { Textarea, Input } from 'components/UIElements'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { QUESTION_TYPE_MAP } from 'utils/constants'

const renderScoreValueLabel = () => <div className='label-small score-value'>(Value)</div>

const MatrixChoicesList = SortableContainer(props => {
  const { preview, item } = props
  const { nonDefaultScoring } = item.attributes

  const icon = item.attributes.allowMultipleAnswers ? 'fa fa-square-o' : 'fa fa-circle-thin'
  const numChoices = item.choices_order.length + (item.attributes.other ? 1 : 0)
  const isMatrix = item.type === QUESTION_TYPE_MAP.matrix
  const isNRS = item.type === QUESTION_TYPE_MAP.numeric_rating_scale

  return (
    <div className='flexed'>
      {props.errors && numChoices < 2 && props.errors.minChoicesErr && (
        <p className='survey-error'>{props.errors.minChoicesErr}</p>
      )}
      {item.choices_order.map((choiceId, index) => {
        const placeholder = `Option ${index + 1}`
        return (
          <Choice
            choice={item.choices[choiceId]}
            choiceId={choiceId}
            cIndex={index}
            disabled={preview}
            icon={icon}
            index={index}
            isMatrix={isMatrix}
            isNRS={isNRS}
            key={choiceId}
            nonDefaultScoring={nonDefaultScoring}
            numChoices={item.choices_order.length}
            placeholder={placeholder}
            {...props}
          />
        )
      })}
    </div>
  )
})

const allowAddChoice = props => {
  if (props.item.type === QUESTION_TYPE_MAP.numericRatingScale && props.item.choices_order.length >= 11) return false
  if (props.item.type === QUESTION_TYPE_MAP.matrix && props.item.choices_order.length >= 7) return false
  return true
}

const Choice = SortableElement(props => {
  const errors = props.errors ? props.errors : {}
  const {
    choice,
    choiceId,
    cIndex,
    draggable,
    icon,
    isActive,
    isMatrix,
    item,
    itemId,
    nonDefaultScoring,
    onAddChoice,
    placeholder,
    preview,
    setQuestionScoresEdited,
    updateChoiceLabel,
    updateChoiceScoreValue,
  } = props

  const { hasScoring } = item.attributes

  const inputDefaultScoreValues = () => {
    /**
     * Adds a default value for the score value in select question choices. If the score values
     * have been edited at anytime, a default score value of the choice's index is not added.
     *  */
    const value = isMatrix ? cIndex + 1 : cIndex
    if (!choice.score_value && hasScoring && !nonDefaultScoring && isMatrix) {
      updateChoiceScoreValue(item, itemId, choiceId, value.toString())
      // If the question is NRS, the default values start at 1 not 0
    }
  }

  const onUpdateChoiceScoreValue = val => {
    setQuestionScoresEdited(itemId)
    return updateChoiceScoreValue(item, itemId, choiceId, val || val === 0 ? parseInt(val, 10).toString() : '') // parsing as integer first to remove prefixed '0's
  }

  const renderChoiceLabel = () => {
    return (
      <Textarea
        hasError={choice.label === '' && errors.blankChoicesErr}
        autoFocus={choice.label === ''}
        disabled={preview}
        className={`choice-label-input${isMatrix ? ' matrix no-image' : ''}`}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            if (allowAddChoice(props)) {
              e.preventDefault()
              onAddChoice(cIndex + 1)()
            } else {
              e.preventDefault()
            }
          }
        }}
        onChange={value => (updateChoiceLabel ? updateChoiceLabel(item, itemId, choiceId, value) : null)}
        placeholder={placeholder}
        value={choice.label}
      />
    )
  }

  const renderScoreValueInput = () => {
    return (
      <Input
        disabled={preview}
        hasError={choice.score_value === '' && errors.blankScoreErr}
        className='score-val-input'
        min={0}
        mountFunc={() => inputDefaultScoreValues()}
        type='number'
        value={choice.score_value}
        onChange={val => (updateChoiceScoreValue ? onUpdateChoiceScoreValue(val) : '')}
      />
    )
  }

  return (
    <div className={`select-option flexed column labeled${isMatrix ? ' matrix' : ''}`}>
      {draggable && <div className='choice-grab-icon' />}
      <i className={icon} />
      {renderChoiceLabel()}
      {hasScoring && renderScoreValueInput()}
      {hasScoring && cIndex === 0 && isActive && <div>{renderScoreValueLabel()}</div>}
    </div>
  )
})

export default MatrixChoicesList
