import React from 'react'
import PropTypes from 'prop-types'
import '../../stylesheets/charitypreview.scss'

const NUMBER_DISCLAIMER = '*numbers are placeholder values'
const topPlaceholderImg = require('../../assets/display mask top.png')
const middlePlaceholderImg = require('../../assets/donation_middle.png')
const superGemImg = require('../../../../../../../../assets/super-gem.png')

const CharityInfoPreviewContent = props => {
  const { charityDescription, charityName, hideDisclaimer = false, imgUrl } = props
  return (
    <div className='charity-preview-content'>
      <h4>Donation information</h4>
      <div className='mobile-viewport flexed column start-justified'>
        <div className='mobile-header flexed center-justified'>
          <img src={topPlaceholderImg} alt='mobile-os-header' />
        </div>
        <div className='content-header flexed space-between'>
          <i className='fas fa-chevron-left' />
          <div className='flexed'>
            <img src={superGemImg} alt='super-gem-icon' />
            <strong>10</strong>
          </div>
        </div>
        {imgUrl ? (
          <img src={imgUrl} alt='charity-preview-img' />
        ) : (
          <div className='preview-image-placeholder'>
            <strong>No image</strong>
          </div>
        )}
        <h3 className='charity-name'>{charityName || 'No Charity Selected'}</h3>
        <div className='mobile-progress-bar flexed center-justified'>
          <img src={middlePlaceholderImg} alt='mobile-mid-section-placeholder' />
        </div>
        <div className='desc-body'>
          <h5>About</h5>
          <p>{charityDescription || 'No description'}</p>
        </div>
      </div>
      {!hideDisclaimer && <p className='number-disclaimer'>{NUMBER_DISCLAIMER}</p>}
    </div>
  )
}

CharityInfoPreviewContent.propTypes = {
  charityDescription: PropTypes.string,
  charityName: PropTypes.string,
  hideDisclaimer: PropTypes.bool,
  imgUrl: PropTypes.string,
}

export default CharityInfoPreviewContent
