import { injectReducer } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import { UserIsNotAuthenticated } from '../../containers/authContainers'

// Sync route definition
export default store => ({
  path: 'login',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      require => {
        setPageTitle('Login | Admin Panel')
        const LoginPage = require('./containers/LoginContainer').default
        const reducer = require('./modules/Login').default
        injectReducer(store, { key: 'login', reducer })
        cb(null, UserIsNotAuthenticated(LoginPage))
      },
      'login',
    )
  },
})
