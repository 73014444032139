import React from 'react'
import { Container, Checkbox } from 'components/UIElements'
import '../stylesheets/createStudy.scss'
import { FLAGSMITH_FEATURES, DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION } from 'utils/constants'
import STRINGS from 'utils/strings'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'

const FlagsmithConfigurationSection = ({ userId, study }) => {
  const flagsmithFeaturesArchitectureVersion = useFlagsmithFeature({
    featureFlags: {
      [FLAGSMITH_FEATURES.architectureVersion]: { enabled: true, value: 1 },
    },
    userId,
    studyId: study?.id,
  })

  const flagsmithFeaturesAdvancedEvents = useFlagsmithFeature({
    featureFlags: {
      [FLAGSMITH_FEATURES.advancedEvents]: { enabled: true },
    },
    userId,
    studyId: study?.id,
  })

  const flagsmithFeaturesInstrumentUnification = useFlagsmithFeature({
    featureFlags: {
      [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true },
    },
    userId,
    studyId: study?.id,
  })

  const flagsmithFeaturesFoodDiary = useFlagsmithFeature({
    featureFlags: {
      [FLAGSMITH_FEATURES.foodDiary]: { enabled: true },
    },
    userId,
    studyId: study?.id,
  })

  const flagsmithFeaturesStudyPromotion = useFlagsmithFeature({
    featureFlags: {
      [FLAGSMITH_FEATURES.studyPromotion]: { enabled: true },
    },
    userId,
    studyId: study?.id,
  })

  const flagsmithFeaturesMaintenanceTool = useFlagsmithFeature({
    featureFlags: {
      [FLAGSMITH_FEATURES.maintenanceTool]: { enabled: true },
    },
    userId,
    studyId: study?.id,
  })

  console.log('flagsmithFeaturesArchitectureVersion', flagsmithFeaturesArchitectureVersion)
  console.log('flagsmithFeaturesAdvancedEvents', flagsmithFeaturesAdvancedEvents)
  console.log('flagsmithFeaturesInstrumentUnification', flagsmithFeaturesInstrumentUnification)
  console.log('flagsmithFeaturesFoodDiary', flagsmithFeaturesFoodDiary)
  console.log('flagsmithFeaturesStudyPromotion', flagsmithFeaturesStudyPromotion)
  console.log('flagsmithFeaturesMaintenanceTool', flagsmithFeaturesMaintenanceTool)

  const instrumentArchitectureVersion =
    study?.config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  return (
    <Container>
      <h3>{STRINGS.flagsmithConfiguration}</h3>

      <h5>{STRINGS.flags}</h5>
      <div className='flagsmith-options'>
        <div className='config-option'>
          <p className='setting-text'>
            <code>{FLAGSMITH_FEATURES.instrumentUnification}</code>
          </p>
          <Checkbox
            toggle
            disabled
            checked={flagsmithFeaturesInstrumentUnification[FLAGSMITH_FEATURES.instrumentUnification]?.enabled}
          />
        </div>

        <div className='config-option'>
          <p className='setting-text'>
            <code>{FLAGSMITH_FEATURES.foodDiary}</code>
          </p>
          <Checkbox toggle disabled checked={flagsmithFeaturesFoodDiary[FLAGSMITH_FEATURES.foodDiary]?.enabled} />
        </div>

        <div className='config-option'>
          <p className='setting-text'>
            <code>{FLAGSMITH_FEATURES.studyPromotion}</code>
          </p>
          <Checkbox
            toggle
            disabled
            checked={flagsmithFeaturesStudyPromotion[FLAGSMITH_FEATURES.studyPromotion]?.enabled}
          />
        </div>

        <div className='config-option'>
          <p className='setting-text'>
            <code>{FLAGSMITH_FEATURES.maintenanceTool}</code>
          </p>
          <Checkbox
            toggle
            disabled
            checked={flagsmithFeaturesMaintenanceTool[FLAGSMITH_FEATURES.maintenanceTool]?.enabled}
          />
        </div>

        <div className='config-option'>
          <p className='setting-text'>
            <code>{FLAGSMITH_FEATURES.advancedEvents}</code>
          </p>
          <Checkbox
            toggle
            disabled
            checked={flagsmithFeaturesAdvancedEvents[FLAGSMITH_FEATURES.advancedEvents]?.enabled}
          />
        </div>

        <div className='config-option margin-right'>
          <p className='setting-text'>
            <code>{FLAGSMITH_FEATURES.architectureVersion}</code>
          </p>
          <strong>{flagsmithFeaturesArchitectureVersion[FLAGSMITH_FEATURES.architectureVersion]?.value}</strong>
        </div>
      </div>

      <h5>{STRINGS.studyConfig}</h5>
      <div className='config-option margin-right'>
        <p className='setting-text'>
          <code>{'instrument_architecture_version'}</code>
        </p>
        <strong>{instrumentArchitectureVersion}</strong>
      </div>
    </Container>
  )
}

export default FlagsmithConfigurationSection
