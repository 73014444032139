import React from 'react'
import PropTypes from 'prop-types'
import { CollapseMenu } from 'components/UIElements'
import { generateDateStringWithTimeZone } from 'utils/time'
import { CASE_STATUS_STRINGS, CASE_STATUS_MAP, CASE_HIERARCHY_LIST } from '../../CasesPage/utils/statusMaps'
import MenuContent from './CaseProgressMenuContent'

const generateTitle = (stat, ts, nextStatusTrue) => {
  const icon = nextStatusTrue ? 'fa-check' : 'fa-arrow-right'
  return (
    <div className='flexed'>
      {!!ts && <i className={`fas ${icon}`} />}
      <span className='status'>{CASE_STATUS_STRINGS[stat]}</span>
      {!!ts && <span>{`on ${generateDateStringWithTimeZone(ts)}`}</span>}
    </div>
  )
}

const CaseProgress = props => {
  const { caseProgress, status, caseClosed, participantReports, updateCaseProgress, info, canWrite, studyLock } = props

  const { reportedNo } = info

  let list = caseProgress.filter(
    progress => progress.status !== CASE_STATUS_MAP.closed && progress.status !== CASE_STATUS_MAP.completed,
  )

  const indexOfReportedStatus = caseProgress.findIndex(progress => progress.status === CASE_STATUS_MAP.reported)

  if (reportedNo) {
    if (indexOfReportedStatus !== 0) list = list.slice(0, indexOfReportedStatus)
    else list = list.slice(1)
  }

  const progressList = list.map(progress => {
    const { timestamp, confirmation } = progress

    const _status = progress.status

    const nextStatusHierarchyIndex = CASE_HIERARCHY_LIST.indexOf(_status)

    const hierarchyNextStatus = CASE_HIERARCHY_LIST[nextStatusHierarchyIndex + 1]

    const nextStatus = caseProgress[caseProgress.findIndex(_progress => _progress.status === hierarchyNextStatus)]

    const nextStatusConfirmed = nextStatus ? nextStatus.confirmation !== null : false

    const nextStatusTrue = nextStatus ? nextStatus.confirmation === true : false

    return (
      <div key={`${_status}-${timestamp}`} className='menu-wrapper'>
        <CollapseMenu
          className={!timestamp ? 'disabled' : ''}
          titleContent={generateTitle(_status, timestamp, nextStatusTrue)}
          open={
            status === _status ||
            (status === CASE_STATUS_MAP.completed && _status === CASE_STATUS_MAP.confirmed) ||
            (status === CASE_STATUS_MAP.reported && _status === CASE_STATUS_MAP.open && reportedNo)
          }
          menuContent={
            <MenuContent
              info={info}
              caseClosed={caseClosed}
              status={_status}
              confirmation={confirmation}
              participantReports={participantReports}
              updateCaseProgress={updateCaseProgress}
              nextStatus={nextStatus}
              nextStatusTrue={nextStatusTrue}
              statusToConfirm={hierarchyNextStatus}
              nextStatusConfirmed={nextStatusConfirmed}
              timestamp={timestamp}
              canWrite={canWrite}
              studyLock={studyLock}
            />
          }
        />
      </div>
    )
  })

  return (
    <div className='case-progress'>
      <div className='header'>CASE PROGRESS</div>
      {progressList}
    </div>
  )
}

CaseProgress.propTypes = {
  caseProgress: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.string,
  caseClosed: PropTypes.bool,
  canWrite: PropTypes.bool,
  participantReports: PropTypes.arrayOf(PropTypes.object),
  updateCaseProgress: PropTypes.func,
  reportedNo: PropTypes.bool,
  info: PropTypes.shape({
    reportedNo: PropTypes.bool,
  }),
  studyLock: PropTypes.bool,
}

export default CaseProgress
