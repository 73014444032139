import { connect } from 'react-redux'
import { getBaseSiteIdFromStudy } from 'utils/misc'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { instrumentActions, resetInstrument } from '../modules/Instrument'
import InstrumentView from '../components/InstrumentView'
import { clinroActions } from '../routes/EditClinro/modules/Clinro'

export const UNIFICATION_HEADER_NAMES = {
  edittask: 'View Task',
  editinstrument: 'Edit Participant Instrument',
  createinstrument: 'Create Participant Instrument',
  editclinro: 'Edit Clinician Instrument',
  createclinro: 'Create Clinician Instrument',
  editdiary: 'Edit Participant Instrument',
  creatediary: 'Create Participant Instrument',
  selecttask: 'Task',
  uploadinstrument: 'Upload Participant Instrument',
  createarticle: 'Article',
  editarticle: 'Article',
}

export const HEADER_NAMES = {
  edittask: 'View Task',
  editinstrument: 'Edit Survey',
  createinstrument: 'Create Survey',
  editclinro: 'Edit Clinician Instrument',
  createclinro: 'Create Clinician Instrument',
  editdiary: 'Edit Diary',
  creatediary: 'Create Diary',
  selecttask: 'Task',
  uploadinstrument: 'Upload Survey',
  createarticle: 'Article',
  editarticle: 'Article',
}

const _getSelectedTab = path => {
  if (HEADER_NAMES.hasOwnProperty(path)) {
    return 'main'
  }
  return path
}

const mapState = state => {
  const { clinroEditor, surveyEditor, diaryEditor, instrumentReducer, loading, location, study, user } = state
  const path = location.pathname.match(/([^/]+$)/)[0]
  const { type } = instrumentReducer.instrument
  const isClinro = type === INSTRUMENT_TYPE_MAP.clinro
  const isDiary = type === INSTRUMENT_TYPE_MAP.diary
  const isSurvey = type === INSTRUMENT_TYPE_MAP.survey
  const { domains: clinroDomains = {}, questionsWithScoring: clinroQuestionsWithScoring } = clinroEditor?.clinro
  const { domains: surveyDomains = {}, questionsWithScoring: surveyQuestionsWithScoring } = surveyEditor?.survey
  const { domains: diaryDomains = {}, questionsWithScoring: diaryQuestionsWithScoring } = diaryEditor?.diary
  const disableScoring = isClinro
    ? Object.keys(clinroQuestionsWithScoring).length === 0 || Object.keys(clinroDomains).length === 0
    : isDiary
    ? Object.keys(diaryQuestionsWithScoring).length === 0 || Object.keys(diaryDomains).length === 0
    : Object.keys(surveyQuestionsWithScoring).length === 0 || Object.keys(surveyDomains).length === 0

  const getFirstLink = () => {
    if (isDiary) {
      return 'editdiary'
    }
    if (isSurvey) {
      return 'editinstrument'
    }
    return `edit${type?.toLowerCase()}`
  }

  return {
    disableNavButtons: !instrumentReducer.instrument.title && type === 'TASK',
    firstLink: getFirstLink(),
    instrumentID: instrumentReducer.instrument.id,
    loading: loading.general,
    preview: path === 'preview',
    selectedTab: _getSelectedTab(path),
    disableScoring,
    studyID: study.currentStudy.id,
    siteID: getBaseSiteIdFromStudy(study),
    type,
    path,
    study: study?.currentStudy,
    userId: user?.id,
    instrumentUnificationEnabled: instrumentReducer.instrumentUnificationEnabled,
  }
}

const mapDispatch = {
  ...instrumentActions,
  ...clinroActions,
  resetInstrument,
}

export default connect(mapState, mapDispatch)(InstrumentView)
