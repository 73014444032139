import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateSearchTerm } from 'store/searchTerm'
import { debounce } from 'utils/misc'

import 'styles/d3/searchinput.scss'

class SearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isFocused: false }
    this.debounceUpdateSearch = debounce(this.onChange)
  }

  componentDidMount() {
    const { updateSearch } = this.props
    updateSearch('')
  }

  handleClear = () => {
    const { updateSearch } = this.props
    updateSearch('')
    this.inputRef.value = ''
    this.inputRef.focus()
  }

  onChange = val => {
    const { updateSearch, performExternalUpdate } = this.props
    updateSearch(val)

    if (performExternalUpdate) {
      performExternalUpdate()
    }
  }

  render() {
    const { placeholder = '', leftJustified, searchTerm, useDebounce, id } = this.props
    const { isFocused } = this.state
    return (
      <div
        className={`search-input${isFocused ? ' focused' : ''}${searchTerm ? ' has-search-val' : ''}${
          leftJustified ? ' left-justified' : ''
        }`}>
        <input
          ref={el => {
            this.inputRef = el
          }}
          id={id}
          aria-label='search-input'
          onChange={e => {
            if (useDebounce) {
              e.persist()
              this.debounceUpdateSearch(e.target.value)
            } else this.onChange(e.target.value)
          }}
          className={searchTerm ? '' : 'empty'}
          onBlur={() => this.setState({ isFocused: false })}
          onFocus={() => this.setState({ isFocused: true })}
          placeholder={placeholder}
          type='search'
        />
        {searchTerm ? (
          <i onClick={this.handleClear} className='fas fa-times' />
        ) : (
          <i
            id='search-icon'
            onClick={() => {
              this.inputRef.focus()
            }}
            className='fas fa-search'
          />
        )}
      </div>
    )
  }
}

const mapProps = ({ searchTerm }) => ({ searchTerm })

SearchInput.propTypes = {
  leftJustified: PropTypes.bool,
  placeholder: PropTypes.string,
  searchTerm: PropTypes.string,
  updateSearch: PropTypes.func,
  useDebounce: PropTypes.bool,
  performExternalUpdate: PropTypes.func,
}

export default connect(mapProps, { updateSearch: updateSearchTerm })(SearchInput)
