import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { INSTRUMENT_TYPE_MAP, DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from 'utils/constants'
import D3Logo from 'assets/DCH-Logo.svg'
import { HEADER_NAMES, UNIFICATION_HEADER_NAMES } from '../containers/InstrumentViewContainer'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import { capitalize } from 'utils/misc'
import STRINGS from 'utils/strings'

const InstrumentNav = props => {
  const {
    disableNavButtons,
    disableScoring,
    firstLink,
    loading,
    onSave,
    preview,
    selectedTab,
    type,
    study,
    userId,
    path,
    setInstrumentUnificationEnabled,
    instrumentUnificationEnabled,
  } = props
  const isClinro = type === INSTRUMENT_TYPE_MAP.clinro
  const isSurvey = type === INSTRUMENT_TYPE_MAP.survey
  const isDiary = type === INSTRUMENT_TYPE_MAP.diary
  const canHaveScoring = isClinro || isSurvey || isDiary

  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  useEffect(() => {
    if (flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled && !instrumentUnificationEnabled) {
      setInstrumentUnificationEnabled(true)
    }
  }, [flagsmithFeatures, instrumentUnificationEnabled])

  const getHeaderName = (route, type) => {
    const getInstrumentType = () => {
      if (isClinro) {
        return STRINGS.clinicianInstrument
      }
      if (isSurvey || isDiary) {
        if (hasInstrumentUnification) {
          return STRINGS.participantInstrument
        }
        if (isSurvey) {
          return STRINGS.survey
        }
        if (isDiary) {
          return STRINGS.diary
        }
      }
      return type?.toLowerCase()
    }

    const headerNames = hasInstrumentUnification ? UNIFICATION_HEADER_NAMES : HEADER_NAMES

    return headerNames[route] || (type === 'TASK' ? 'View Task' : `Edit ${capitalize(getInstrumentType())}`)
  }

  const headerName = getHeaderName(path, type)

  return preview ? (
    <Button onClick={() => window.close()} className='preview-close-btn' content='Close Preview' />
  ) : (
    <div className='instrument-nav flexed-header'>
      <div className='flexed-header'>
        <div className='d3-logo-container'>
          <img className='d3-logo' src={D3Logo} alt='d3-logo' />
        </div>
        <Button
          selected={selectedTab === 'main'}
          disabled={selectedTab === 'main' || loading}
          link
          onClick={() => onSave(firstLink)}
          content={
            <span>
              {`1. ${headerName}`}
              <i className='fas fa-arrow-right' />
            </span>
          }
        />
        {canHaveScoring ? (
          <Button
            selected={selectedTab === 'scoring'}
            disabled={selectedTab === 'scoring' || loading || disableScoring}
            link
            onClick={() => onSave('scoring')}
            content={
              <span>
                2. Scoring
                <i className='fas fa-arrow-right' />
              </span>
            }
          />
        ) : null}
        <Button
          selected={selectedTab === 'properties'}
          disabled={selectedTab === 'properties' || loading || disableNavButtons}
          link
          onClick={() => onSave('properties')}
          content={
            <span>
              {`${canHaveScoring ? '3' : '2'}. Properties`}
              <i className='fas fa-arrow-right' />
            </span>
          }
        />
        <Button
          selected={selectedTab === 'schedule'}
          disabled={selectedTab === 'schedule' || loading || disableNavButtons}
          link
          onClick={() => onSave('schedule')}
          content={`${canHaveScoring ? '4' : '3'}. Schedule`}
        />
      </div>
      <Button grey content='Close' onClick={() => onSave('instruments', selectedTab !== 'scoring')} />
    </div>
  )
}

InstrumentNav.propTypes = {
  disableNavButtons: PropTypes.bool,
  disableScoring: PropTypes.bool,
  firstLink: PropTypes.string,
  headerName: PropTypes.string,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  params: PropTypes.shape({
    studyID: PropTypes.string,
    instrumentID: PropTypes.string,
    siteID: PropTypes.string,
  }),
  preview: PropTypes.bool,
  selectedTab: PropTypes.string,
  type: PropTypes.string,
}

export default InstrumentNav
