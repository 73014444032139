import React from 'react'
import PropTypes from 'prop-types'
import { MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import DiaryItemList from './DiaryItemList'
import DiaryToolbar from './DiaryToolbar'
import DiaryItemEditor from './DiaryItemEditor'
import { getRandomQuestionId } from '../../../../../../../utils/getRandomQuestionId'

export default class DiaryView extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { activeItemId: null }
  }

  componentDidMount() {
    const { diary, addItem } = this.props
    if (diary.order[0].length === 0) {
      const newQId = getRandomQuestionId()
      addItem({
        itemType: 'introduction',
        index: 0,
        newQId,
      })
      this.setState({ activeItemId: newQId })
    } else {
      this.setState({ activeItemId: diary.order[0][0] })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.setState({ activeItemId: this.props.diary.order[0][0] })
    }
  }

  onMoveItem = ({ oldIndex, newIndex }) => {
    if (newIndex !== 0) {
      this.props.moveItem(this.props.diary, oldIndex, newIndex)
    }
  }

  onDeleteItem = itemId => {
    return () => {
      this.props.deleteItem(this.props.diary, itemId)
    }
  }

  onChangeItemType = (itemId, prevType, nextType) => {
    this.props.changeItemType(this.props.diary, itemId, prevType, nextType)
  }

  onDeleteChoice = (itemId, choiceId) => {
    return () => {
      this.props.deleteChoice(this.props.diary, itemId, choiceId)
    }
  }

  onAddItem = (itemType, index) => {
    const { addItem } = this.props
    return e => {
      const newQId = getRandomQuestionId()
      addItem({
        itemType,
        index,
        newQId,
      })
      this.setState({ activeItemId: newQId })
    }
  }

  onToggleEdit = itemId => {
    if (this.props.activeItemId !== itemId) {
      this.setState({ activeItemId: itemId })
    }
  }

  onRandomize = () => {
    const { openModal, randomizeQuestions } = this.props
    openModal({
      content: MODAL_CONTENT_MAP.shuffleQuestionOrder,
      confirmButton: MODAL_BUTTONS_MAP.proceed,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: randomizeQuestions,
    })
  }

  shouldCancelStart(e) {
    return e.target.className !== 'grab-icon'
  }

  render() {
    const {
      downloadInstrument,
      duplicateItem,
      editorStates,
      errors,
      instrumentTitle,
      isLoading,
      onSave,
      studyID,
      diary,
      type,
      updateTitle,
      version,
      wordCount,
      isCreateDiary,
      userId,
    } = this.props
    const { activeItemId } = this.state
    const { title, id, questions } = diary
    const diaryItemLabelEditorState = editorStates[activeItemId]

    return (
      <div className='survey-builder'>
        <DiaryToolbar
          studyID={studyID}
          id={id}
          version={version}
          onRandomize={this.onRandomize}
          wordCount={wordCount}
          type={type}
          isLoading={isLoading}
          updateTitle={updateTitle}
          title={title}
          errors={errors}
          download={downloadInstrument}
          onSave={onSave}
          questions={questions}
          isCreateDiary={isCreateDiary}
          userId={userId}
        />
        <div className='builder-content'>
          <div>
            <div className='main-editor-section'>
              <DiaryItemList
                {...this.props}
                activeItemId={activeItemId}
                onSortEnd={this.onMoveItem}
                shouldCancelStart={this.shouldCancelStart}
                lockToContainerEdges
                helperClass='sortableHelper'
                lockAxis='y'
                onToggleEdit={this.onToggleEdit}
                onDeleteItem={this.onDeleteItem}
                onChangeItemType={this.onChangeItemType}
                onDeleteChoice={this.onDeleteChoice}
                onAddItem={this.onAddItem}
              />
            </div>
            <div className={`right-section${activeItemId ? ' active' : ''}`}>
              {diary.questions[activeItemId] && (
                <DiaryItemEditor
                  diary={diary}
                  question={diary.questions[activeItemId]}
                  activeItemId={activeItemId}
                  changeItemType={this.onChangeItemType}
                  duplicateItem={duplicateItem}
                  onDeleteItem={this.onDeleteItem}
                  diaryItemLabelEditorState={diaryItemLabelEditorState}
                  editorStates={editorStates}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DiaryView.propTypes = {
  activeItemId: PropTypes.string,
  addChoiceOnPaste: PropTypes.func,
  addItem: PropTypes.func,
  changeItemType: PropTypes.func,
  deleteChoice: PropTypes.func,
  deleteItem: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorStates: PropTypes.object,
  initializeBlankDiary: PropTypes.func,
  instrumentTitle: PropTypes.string,
  moveItem: PropTypes.func,
  noQuestionsErr: PropTypes.string,
  openModal: PropTypes.func,
  randomizeQuestions: PropTypes.func,
  studyID: PropTypes.number,
  diary: PropTypes.object,
  titleErr: PropTypes.string,
  toggleEdit: PropTypes.func,
  updateInstrumentTitle: PropTypes.func,
  updateIntrumentTitle: PropTypes.func,
  updateTitle: PropTypes.func,
  url: PropTypes.string,
  version: PropTypes.number,
  versionOptions: PropTypes.array,
  wordCount: PropTypes.number,
}
