import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { SortableTable, Checkbox, Divider, Popup, Button } from 'components/UIElements'
import '../stylesheets/VisitTable.scss'
import { DEFAULT_LANG, MODAL_BUTTONS_MAP, MODAL_CONTENT_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import UploadTranslationInput from '../../../../Instruments/routes/InstrumentsPage/components/UploadTranslationInput'

const headerOptionsSeed = [
  { key: 'visitName', sortable: true, component: 'Visit Name', className: 'visit-name' },
  { key: 'visitDay', sortable: true, component: 'Study Day' },
  { key: 'action', component: 'Action', className: 'visits-template-actions' },
]

const VisitTemplatesTable = props => {
  const {
    visitSchedules,
    filteredVisits,
    location,
    searchTerm,
    offset,
    checkedVisitsArr = [],
    toggleVisitTemplate,
    canEditVisits,
    resetCheckedVisitTemplates,
    isSiteCoord,
    studyLock,
    downloadScheduleTranslationTemplate,
    uploadScheduleTranslations,
    closeModal,
    openModal,
    translations,
    studyId,
    enforcedLanguage,
  } = props
  const { checkedVisitTemplates } = visitSchedules
  const [headerOptions, setHeaderOptions] = useState([])
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [currentLang, setCurrentLang] = useState(DEFAULT_LANG)

  const renderCheckboxCell = ({ value, idx, checkedVisits }) => {
    if (studyLock || isSiteCoord) return <td />
    return (
      <td
        className='checkbox-cell'
        onClick={e => {
          e.stopPropagation()
          resetCheckedVisitTemplates()
          if (!checkedVisits[value]) {
            toggleVisitTemplate(value)
          }
        }}
        key={`col_${idx}`}>
        <Checkbox checked={value in checkedVisits} />
      </td>
    )
  }

  const renderActionsCell = ({ idx, row }) => {
    if (studyLock || isSiteCoord) return null
    const { id, parentId } = row
    return (
      <td key={`col_${idx}`}>
        <div className='flexed center-justified'>
          {row?.isDraft ? (
            <Button disabled={!canEditVisits} grey content='Edit Draft' />
          ) : (
            <Popup
              dark
              position='bottom'
              align='right'
              className='edit'
              noPointer
              disabled={!canEditVisits}
              trigger={<Button link icon='fas fa-pencil-alt' />}>
              <Button
                content='Edit visit'
                onClick={() => browserHistory.push(`${location.pathname}/visits/${id}/${parentId}/edit`)}
              />
            </Popup>
          )}
        </div>
      </td>
    )
  }

  const onOpenUploadModal = visitScheduleId => {
    const successCb = fileName => {
      if (fileName) setUploadedFileName(fileName)
      setCurrentLang(DEFAULT_LANG)
      openModal({
        closeOnBackgroundClick: true,
        content: MODAL_CONTENT_MAP.translationsUploadPrompt,
        confirmButton: MODAL_BUTTONS_MAP.okay,
        className: MODAL_CLASSES_MAP.confirmation,
        hideCancelButton: true,
        onConfirm: closeModal,
      })
    }
    openModal({
      noButtons: true,
      closeOnBackgroundClick: true,
      children: (
        <UploadTranslationInput
          forVisitSchedule
          closeModal={closeModal}
          uploadTranslation={file =>
            uploadScheduleTranslations(file, () => successCb(file.name), enforcedLanguage, studyId, visitScheduleId)
          }
        />
      ),
    })
  }

  const renderVisitScheduleEditable = (visitScheduleId, { fullPath }) => {
    if (studyLock) return null
    return (
      <Popup
        dark
        noPointer
        position='bottom'
        align='right'
        className='edit-visit-schedule-btn'
        disabled={!canEditVisits}
        trigger={<Button link icon='fas fa-pencil-alt' />}>
        <div>
          <Button
            content='Edit visit template'
            onClick={() => browserHistory.push(`${fullPath}/visit-templates/${visitScheduleId}/edit`)}
          />
          <Divider margin='0 10px' />
          <Button
            iconBefore='fas fa-cloud-download-alt'
            content='Export CSV template'
            onClick={() => {
              downloadScheduleTranslationTemplate(translations, studyId, visitScheduleId)
            }}
          />
          <Button
            iconBefore='fas fa-cloud-upload-alt'
            content='Import CSV'
            onClick={() => onOpenUploadModal(visitScheduleId)}
          />
        </div>
      </Popup>
    )
  }

  const renderFunctions = {
    checkbox: renderCheckboxCell,
    action: renderActionsCell,
  }
  useEffect(() => {
    const newHeaderOptions = [...headerOptionsSeed].filter(item => {
      if ((studyLock || isSiteCoord) && item.key === 'action') return false
      return true
    })
    setHeaderOptions(newHeaderOptions)
  }, [checkedVisitsArr])

  return (
    <SortableTable
      id='visits-table'
      className='visits-table'
      headerOptions={headerOptions}
      offset={offset}
      sortingBy='templateName'
      renderFunctions={renderFunctions}
      rowList={filteredVisits}
      optionalCellProps={{ checkedVisits: checkedVisitTemplates, fullPath: location.pathname }}
      accordion={{
        field: 'templateName',
        editable: !isSiteCoord ? renderVisitScheduleEditable : undefined,
      }}
      searchKeys={['visitName', 'visitDay']}
      searchTerm={searchTerm}
      rowSelectOptions={{
        isRowSelected: row => row[0]?.value in checkedVisitTemplates,
      }}
      emptyText='No Matching Schedule Visit Templates'
    />
  )
}

VisitTemplatesTable.propTypes = {
  filteredVisits: PropTypes.arrayOf(PropTypes.array),
  offset: PropTypes.number,
  visitSchedules: PropTypes.shape({
    checkedVisits: PropTypes.objectOf(PropTypes.bool),
  }),
  searchTerm: PropTypes.string,
  setVisits: PropTypes.func,
  canEditVisits: PropTypes.bool,
  location: PropTypes.object,
  studyLock: PropTypes.bool,
}

export default VisitTemplatesTable
