import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { setPageTitle } from 'utils/misc'
import { onFetchInstrument } from '../../modules/Instrument'
import { fetchClinro, scoringActions as clinroScoringActions } from '../EditClinro/modules/Clinro'
import { fetchSurvey, scoringActions as surveyScoringActions } from '../EditSurvey/modules/Survey'
import { fetchDiary, scoringActions as diaryScoringActions } from '../EditDiary/modules/Diary'
import ScoringView from './containers/ScoringContainer'
// Sync route definition
export default store => ({
  path: ':instrumentID/scoring',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      require => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */
        const { studyID, instrumentID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Scoring | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(onFetchInstrument(studyID, instrumentID)).then(({ type }) => {
          if (type === INSTRUMENT_TYPE_MAP.clinro) {
            store.dispatch(fetchClinro(studyID, instrumentID)).then(() => {
              const state = store.getState()
              const { scores } = state.clinroEditor.clinro
              if (scores.length === 0) {
                store.dispatch(clinroScoringActions.addClinroScore())
              }
            })
          } else if (type === INSTRUMENT_TYPE_MAP.diary) {
            store.dispatch(fetchDiary(studyID, instrumentID)).then(() => {
              const state = store.getState()
              const { scores } = state.diaryEditor.diary
              if (scores.length === 0) {
                store.dispatch(diaryScoringActions.addDiaryScore())
              }
            })
          } else {
            store.dispatch(fetchSurvey(studyID, instrumentID)).then(() => {
              const state = store.getState()
              const { scores } = state.surveyEditor.survey
              if (scores.length === 0) {
                store.dispatch(surveyScoringActions.addSurveyScore())
              }
            })
          }
        })
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['wa'], ScoringView, false, studyID)),
            ),
          ),
        )
        /* Webpack named bundle   */
      },
      'instruments/scoring',
    )
  },
})
