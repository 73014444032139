import React from 'react'
import { connect } from 'react-redux'
import { notificationActions as actions } from '../modules/AutoNotification'
import EditAutoNotification from '../components/EditAutoNotificationPage'
import { Loader } from 'components/UIElements'
import { setNavBarProps } from 'store/navbar'

const getMapState = notificationType => state => {
  return {
    notificationType,
    loading: state.loading.general,
    settings: state.study.currentStudy.auto_notifications[notificationType],
    studyLock: state.studyLock,
  }
}

export default notificationType =>
  connect(getMapState(notificationType), { ...actions, setNavBarProps })(props => {
    if (props.loading) return <Loader />
    return <EditAutoNotification {...props} />
  })
