import React from 'react'
import participantSupportFirstStep from '../assets/participant-support1.png'
import participantSupportSecondStep from '../assets/participant-support2.png'
import 'styles/d3/participantSupportTooltip.scss'

const ParticipantSupportTooltip = () => {
  return (
    <div className='tooltip-content'>
      <h4 className='heading'>When will the participant see support contact information? </h4>
      <p className='description'>
        Participants will have access to support information when they encounter a problem with the mobile app or need
        help with retrieving their login credentials.
      </p>
      <div className='images'>
        <img src={participantSupportFirstStep} />
        <img src={participantSupportSecondStep} />
      </div>
    </div>
  )
}

ParticipantSupportTooltip.propTypes = {}

export default ParticipantSupportTooltip
