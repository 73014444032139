import React from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Dropdown, Textarea } from 'components/UIElements'
import { MEDICATION_ADHERENCE_CONDITION_OPTIONS, WINDOW_OPTIONS } from '../utils/conditionalNotificationUtils'
import '../stylesheets/conditionItem.scss'

const ConditionItem = props => {
  const {
    action,
    addToDeletionList,
    canDelete,
    condition_id: conditionId,
    deleteMedNotificationCondition,
    index,
    triggers,
    updateMedNotificationAction,
    updateMedNotificationTrigger,
  } = props
  const { resource_function_params: triggerParams } = triggers[0]
  const { action_params: actionParams } = action
  const { body } = actionParams
  return (
    <>
      <div className='condition-item'>
        <div className='flexed title-header'>
          <p className='condition-title'>{`Condition ${index + 1}`}</p>
          {canDelete && (
            <Button
              id='delete-condition'
              className='far fa-trash-alt'
              noStyling
              onClick={() => {
                addToDeletionList(conditionId)
                deleteMedNotificationCondition(index)
              }}
            />
          )}
        </div>
        <p className='label-small'>If this condition is met</p>
        <div className='flexed'>
          <Dropdown
            id='condition-met-dropdown'
            className='condition-to-meet'
            options={MEDICATION_ADHERENCE_CONDITION_OPTIONS}
            selected={`cond-${triggerParams.medication_count}`}
            onSelect={item =>
              updateMedNotificationTrigger(
                {
                  key: 'medication_count',
                  value: item.value.count,
                  key2: 'logical_operator',
                  value2: item.value.operator,
                },
                index,
              )
            }
          />
          <span>in the past</span>
          <Dropdown id='past-condition-hour' disabled options={WINDOW_OPTIONS} selected={triggerParams.window.toString()} hideCaret />
        </div>
        <p className='label-small'>Send this notification</p>
        <Textarea
          id='notification-message'
          onChange={value => updateMedNotificationAction({ key: 'body', value }, index)}
          placeholder='Write a message...'
          value={body}
        />
      </div>
      <Divider color='#eceff0' margin='27px -30px' />
    </>
  )
}

ConditionItem.propTypes = {
  action: PropTypes.shape({
    action_params: PropTypes.shape({
      body: PropTypes.string,
    }),
  }),
  addToDeletionList: PropTypes.func,
  canDelete: PropTypes.bool,
  condition_id: PropTypes.string,
  deleteMedNotificationCondition: PropTypes.func,
  index: PropTypes.number,
  triggers: PropTypes.arrayOf(PropTypes.object),
  updateMedNotificationAction: PropTypes.func,
  updateMedNotificationTrigger: PropTypes.func,
}

export default ConditionItem
