import { injectReducers } from 'store/reducers'
import { loadingActions } from 'store/loader'
import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import ParticipantView from './containers/ParticipantContainer'
import consentReducer from '../../../Consent/routes/ConsentEditor/modules/Consent'
import consentsReducer, { consentActions } from '../../../Consents/routes/ConsentsDashboard/modules/Consents'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'
import { actions as createPtpActions } from './modules/CreateParticipant'
import { fetchCohorts } from '../ParticipantsPage/modules/Participants'
import { generateDefaultPtpValMap } from './utils/pariticipantUtils'
// Sync route definition
export default store => ({
  path: 'create',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducers(store, {
          consentReducer,
          sites: sitesReducer,
          consentsReducer,
        })

        const state = store.getState()
        const { params } = nextState
        const { studyID } = params
        const { currentStudy } = store.getState().study
        setPageTitle(`Create Participant | Study ${studyID} | ${currentStudy.platform_name}`)

        const defaultPtpValMap = generateDefaultPtpValMap(currentStudy)
        const { fname, lname } =
          state.participants && Object.keys(state.participants.participants).length === 0
            ? store.getState().participants.wizardName
            : { fname: '', lname: '' }

        store.dispatch(loadingActions.startLoader(true))
        store.dispatch(createPtpActions.initializePage({ fname, lname, defaultPtpValMap }))
        Promise.all([
          store.dispatch(fetchSites(studyID, false)),
          store.dispatch(fetchCohorts(studyID, false, true)),
          store.dispatch(createPtpActions.fetchLocales()),
          store.dispatch(consentActions.fetchBlockedConsents(studyID)),
        ]).then(() => {
          store.dispatch(loadingActions.stopLoader(true))
        })

        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'participants')(UserHasScope(['wp', 'wxp'], ParticipantView, false, studyID)),
            ),
          ),
        )
      },
      '/participants/create',
    )
  },
})
