import React from 'react'
import PropTypes from 'prop-types'

const Notes = ({ strongNote, noteText }) => (
  <p className='notes'>
    {strongNote && <strong>{strongNote}</strong>}
    <span>{noteText}</span>
  </p>
)

Notes.propTypes = {
  strongNote: PropTypes.string,
  noteText: PropTypes.string,
}

export default Notes
