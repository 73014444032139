import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Textarea, MediaUpload } from 'components/UIElements'
import { MEDIA_TYPE_MAP } from 'utils/constants'
import CharityInfoPreviewContent from '../../../Study/routes/Payments/components/pendingPayments/CharityInfoPreviewContent'

const DonationInfoForm = props => {
  const { closeModal, study, updateConfig, uploadImage } = props
  const { super_gems } = study.config
  const donationsInfo = super_gems.donations[0]

  const [stagedDonation, setStagedDonation] = useState(donationsInfo)

  const { detailed_description, asset_download_link, summary } = stagedDonation
  const [showMediaUploader, setShowMediaUploader] = useState(!asset_download_link)

  const updateDonationKey = (val, donationKey) => {
    const newDonationsInfo = { ...stagedDonation, [donationKey]: val }
    setStagedDonation(newDonationsInfo)
  }

  const updateDonationImage = imageUploadResponse => {
    const { src } = imageUploadResponse
    if (src) {
      updateDonationKey(src, 'asset_download_link')
      setShowMediaUploader(false)
    }
  }

  const submitDisabled = !detailed_description || !asset_download_link || !summary

  return (
    <div>
      <div className='donation-info-form flexed'>
        <CharityInfoPreviewContent
          charityDescription={detailed_description}
          charityName={summary}
          hideDisclaimer
          imgUrl={asset_download_link}
        />
        <div className='donation-fields'>
          {showMediaUploader ? (
            <MediaUpload
              autoUploadAfterFileSelect
              callback={updateDonationImage}
              className='no-padding'
              isGeneralImageUpload
              mediaTypes={[MEDIA_TYPE_MAP.image]}
              noHeading
              noAltTextReq
              noTabs
              restrictionText='Max 400 KB'
              uploadImage={uploadImage}
            />
          ) : (
            <div tabIndex={0} className='donation-img-upload-toggler'>
              <MediaUpload
                autoUploadAfterFileSelect
                callback={updateDonationImage}
                changeImageMode
                className='no-padding'
                isGeneralImageUpload
                mediaTypes={[MEDIA_TYPE_MAP.image]}
                noHeading
                noAltTextReq
                noTabs
                restrictionText='Max 400 KB'
                uploadImage={uploadImage}
              />
              <div className='donation-img-wrapper'>
                <p>Current image</p>
                <img src={asset_download_link} alt='current-donation-logo-selection' />
              </div>
            </div>
          )}
          <Input
            label='Summary'
            placeholder='Enter summary...'
            value={summary}
            onChange={val => updateDonationKey(val, 'summary')}
          />
          <Textarea
            label='About this donation'
            id='donation-desc-textarea'
            placeholder='Tell participants about this cause...'
            value={detailed_description}
            onChange={val => updateDonationKey(val, 'detailed_description')}
          />
        </div>
      </div>
      <div className='bottom flexed'>
        <Button
          grey
          id='modal-cancel'
          content='Cancel'
          onClick={() => {
            closeModal()
          }}
        />
        <Button
          id='modal-confirm'
          className='confirm'
          content='Submit'
          disabled={submitDisabled}
          onClick={() => {
            updateConfig({
              super_gems: {
                ...super_gems,
                donations: [stagedDonation],
              },
            })
            closeModal()
          }}
        />
      </div>
    </div>
  )
}
DonationInfoForm.propTypes = {
  closeModal: PropTypes.func,
  study: PropTypes.shape({
    config: PropTypes.object,
  }),
  updateConfig: PropTypes.func,
  uploadImage: PropTypes.func,
}

export default DonationInfoForm
