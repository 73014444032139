import React from 'react'
import PropTypes from 'prop-types'
import { RichTextEditor } from 'components/UIElements'
import { QUESTION_TYPE_MAP, QUESTION_TYPES_WITH_IMAGE, QUESTION_TYPES_WITH_SCORING } from 'utils/constants'
import QuestionView from './QuestionView'
import HoverBar from './HoverBar'
import ItemDomains from './Scoring/ItemDomains'
import 'draft-js/dist/Draft.css'

class ClinroItem extends React.Component {
  componentDidMount() {
    const { isActive } = this.props
    if (isActive) {
      this.draftEditorRef.draftEditor.focus()
    }
  }

  onDuplicateItem = itemId => {
    const { duplicateItem, editorState } = this.props
    return () => duplicateItem(itemId, editorState)
  }

  onLabelChange = newEditorState => {
    const { updateItemLabel, itemId, editorState } = this.props
    updateItemLabel(itemId, editorState, newEditorState)
  }

  onClick = e => {
    const { isActive, onToggleEdit, itemId } = this.props
    e.stopPropagation()
    if (!isActive) {
      onToggleEdit(itemId, this.item)
    }
  }

  getErrorContent = () => {
    const { errors } = this.props
    return Object.values(errors).map((error, idx) => {
      return <p key={`error_${idx}`}>{`${idx + 1}. ${error}`}</p>
    })
  }

  onPaste = e => {
    const { isActive, item, addChoiceOnPaste, activeItemId } = this.props
    if (
      isActive &&
      document.activeElement.tagName === 'BODY' &&
      ['select_one', 'select_multiple', 'likert'].includes(item.type)
    ) {
      addChoiceOnPaste(activeItemId, e.clipboardData.getData('text'))
    }
  }

  render() {
    const {
      editorState,
      errors,
      firstSiblingQIdArr,
      instrumentDomains,
      isActive,
      isFirstSibling,
      item,
      itemId,
      itemIndex,
      onAddItem,
      questionNumber,
      siblings,
      sortingItemsArr,
      sortingItemKey,
    } = this.props

    const isIntro = item.type === QUESTION_TYPE_MAP.introduction
    const canHaveDomains = QUESTION_TYPES_WITH_SCORING.includes(item.type)
    const { attributes, domains, sibling_id } = item
    const { hasScoring } = attributes
    const isSiblingAndNotFirst = sibling_id && !isFirstSibling

    const isBeingSorted = sortingItemsArr?.includes(itemId) || sortingItemKey === itemId
    const isStackedSibling = firstSiblingQIdArr?.includes(itemId)
    const questionSiblings = siblings ? siblings[sibling_id] : null
    const siblingCount = questionSiblings?.length

    const _className = `${sibling_id ? 'sibling' : ''}${isFirstSibling ? ' first' : ''}${
      isBeingSorted ? ' sorting' : ''
    }${isBeingSorted && isStackedSibling ? ' piled' : ''}${isBeingSorted && siblingCount === 3 ? ' more-piled' : ''}`

    return (
      <div onPaste={this.onPaste} id={`question-${itemId}`} className={_className}>
        {!isIntro && !isSiblingAndNotFirst && (
          <HoverBar onAddItem={onAddItem} itemIndex={itemIndex - 1} isBeingSorted={isBeingSorted} />
        )}
        <div
          ref={el => {
            this.item = el
          }}
          onClick={this.onClick}
          onFocus={this.onClick}
          className={`survey-item-container clinro ${isActive ? 'active hasEditor' : ''}${
            isIntro ? ' introduction' : ''
          }${hasScoring ? ' has-scoring' : ''}`}>
          {sibling_id && isBeingSorted && siblingCount === 3 && (
            <div className='sorting-siblings-placeholder two'>
              <span>{`${questionNumber + 2}`}</span>
            </div>
          )}

          {sibling_id && isBeingSorted && (
            <div className='sorting-siblings-placeholder'>
              <span>{`${questionNumber + 1}`}</span>
            </div>
          )}

          <div className='label-header flexed start-justified start-aligned'>
            {isIntro && <h6>Introduction Text</h6>}
            {!isIntro && !isSiblingAndNotFirst && <div className='grab-icon' />}
            <div className='flexed start-aligned stretch-aligned'>
              <div>
                <div className='flexed start-justified start-aligned'>
                  <span className={questionNumber && 'has-question'}>{questionNumber}</span>
                  <RichTextEditor
                    ref={el => {
                      this.draftEditorRef = el
                    }}
                    canAddImage={QUESTION_TYPES_WITH_IMAGE.includes(item.type)}
                    editorState={editorState}
                    hasError={errors.blankLabelErr && item.label === '<p><br></p>'}
                    hasLinks
                    onChange={this.onLabelChange}
                    placeholder={isIntro ? 'Type your introduction here.' : 'Type text'}
                    showToolbar={isActive}
                    spellCheck
                    {...this.props}
                  />
                </div>
                <QuestionView {...this.props} />
              </div>
              {canHaveDomains && (
                <ItemDomains
                  isActive={isActive}
                  domains={domains}
                  hasScoring={hasScoring}
                  instrumentDomains={instrumentDomains}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinroItem

ClinroItem.propTypes = {
  activeItemId: PropTypes.string,
  addChoiceOnPaste: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorState: PropTypes.object,
  errors: PropTypes.object,
  firstSiblingQIdArr: PropTypes.arrayOf(PropTypes.string),
  isActive: PropTypes.bool,
  isFirstSibling: PropTypes.bool,
  item: PropTypes.object,
  itemId: PropTypes.string,
  itemIndex: PropTypes.number,
  instrumentDomains: PropTypes.objectOf(PropTypes.object),
  onAddItem: PropTypes.func,
  onDeleteChoice: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onToggleEdit: PropTypes.func,
  questionNumber: PropTypes.number,
  siblings: PropTypes.objectOf(PropTypes.array),
  sortingItemsArr: PropTypes.arrayOf(PropTypes.string),
  sortingItemKey: PropTypes.string,
  updateItemLabel: PropTypes.func,
}
