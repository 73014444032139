import { connect } from 'react-redux'
import { getUserScopeByStudy, getUserRoleByStudy, isSiteAdmin } from 'utils/misc'
import { modalActions } from 'store/modal'
import { drawerActions } from 'store/drawer'
import { consentActions } from '../modules/Consents'
import ConsentsDashboardPage from '../components/ConsentsDashboardPage'
import { setConsentParticipantsStatus } from '../../ConsentView/routes/NewConsentBuilder/modules/NewConsent'

const mapStateToProps = state => {
  const { consents, participants, user, study, studyLock, location } = state
  const { consentList, documents } = consents
  const { currentStudy, locales } = study
  const userScope = getUserScopeByStudy(user, currentStudy)
  const userRole = getUserRoleByStudy(user, currentStudy.id)
  const participantList = Object.values(participants.participants)
  const noTracksFeature = currentStudy.config?.tracks?.disabled === true

  return {
    location,
    consentList,
    ...state.consentReducer,
    consentStartDate: state.study.currentStudy.created_on,
    participants: participantList,
    isSiteAdmin: isSiteAdmin(userRole),
    loading: state.loading.general,
    noTracksFeature,
    canEdit: userScope.includes('xc'),
    studyLock,
    locales,
    studyId: currentStudy.id,
    documents,
  }
}

const mapDispatchToProps = { ...consentActions, ...modalActions, ...drawerActions, setConsentParticipantsStatus }

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsDashboardPage)
