import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserHasFeatures } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { resetInstrument } from '../../modules/Instrument'
import { diaryActions } from '../EditDiary/modules/Diary'
import CreateDiaryView from '../EditDiary/containers/DiaryContainer'
import { fetchCohorts } from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'

export default store => ({
  path: 'creatediary',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        const { currentStudy } = store.getState().study
        setPageTitle(`Create Participant Instrument | Study ${studyID} | ${currentStudy.platform_name}`)
        const title = store.getState().instruments ? store.getState().instruments.wizardTitle : ''
        const displayName = store.getState().instruments ? store.getState().instruments.wizardDisplayName : ''
        store.dispatch(diaryActions.initializeBlankDiary(title, displayName))
        store.dispatch(resetInstrument('EDIARY'))
        store.dispatch(fetchCohorts(nextState.params.studyID))

        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'instruments',
              )(UserHasScope(['wa'], UserHasFeatures(CreateDiaryView, ['ediary'], 'instruments'), false, studyID)),
            ),
          ),
        )
      },
      'creatediary',
    )
  },
})
