import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button, Container, Input, Checkbox, Popup, SubMenu, Divider, Radio } from 'components/UIElements'
import '../stylesheets/createStudy.scss'
import {
  CHINA_DEPLOY,
  STUDY_CONFIG_MAP,
  REWARD_TYPE_MAP,
  REWARD_NAME_MAP,
  DURATION_TYPE_MAP,
  DAYS_IN_WEEK,
} from 'utils/constants'
import STRINGS from 'utils/strings'
import { pluralize } from 'utils/misc'
import { checkIsEmailless } from 'utils/studyConfigUtils'
import { confettiIcon, supriseBox, gem, virtualVisitsIcon, visitSupportIcon } from 'assets/assets'
import { CREATE_STUDY_FULL_URLS, generatePClassName } from '../utils/studyUtils'
import AppPushConfig from './AppPushConfig'
import CycleRewardConfig from './CyclesRewardConfig'
import BinaryConfig from './BinaryConfig'
import DynamicCycleRewardConfig from './DynamicCycleRewardConfig'
import EnforcedLanguagesConfig from './EnforcedLanguagesConfig'
import RewardConfig from './RewardConfig'
import SecurityConfig from './SecurityConfig'
import StudyWizardHeader from './StudyWizardHeader'
import NewRewardsConfig from './NewRewardsConfig'
import GiftCardConfig from './GiftCardConfig'
import RewardSelectionConfig from './RewardSelectionConfig'
import CreateStudyFooter from './CreateStudyFooter'
import ParticipantOfflineDuration from './ParticipantOfflineDuration'
import { EmaillessConfig } from './CreateStudyType'
import FlagsmithConfigurationSection from './FlagsmithConfigurationSection'

const richButtonIconMap = {
  ban: <i className='fas fa-ban' />,
  squareCheck: <i className='fas fa-check-square' />,
  dataSync: (
    <div className='data-sync-icon flexed column'>
      <div />
    </div>
  ),
  confetti: confettiIcon,
  iconContainer: icon => {
    return (
      <div className='mid-term-reward-icon flexed column end-justified'>
        <div>{icon()}</div>
      </div>
    )
  },
}

const CreateStudyReview = props => {
  const {
    disabled,
    isRootOrDchAdmin,
    isWizard,
    saveAttempted,
    updateStudy,
    study,
    updateConfig,
    isFlagsmithConfigurationSectionShown = false,
    userId,
  } = props

  const configObj = study.config || study

  const updateStudyConfig = newConfig => {
    const config = study.config || {}
    const _config = config || {}
    updateConfig({
      ..._config,
      ...{ ...newConfig },
    })
  }

  const deleteConfigKey = key => {
    const config = study.config || {}
    if (!config) return
    const newConfig = config
    delete newConfig[key]
    updateConfig(newConfig)
  }

  const getHoursAndMins = mins => {
    const hours = Math.floor(mins / 60)
    const leftMins = mins % 60
    return [hours, leftMins]
  }

  const { self_sign_code } = study
  const {
    admin_security,
    consent_required,
    cycles,
    data_sync_ui_enabled,
    enforced_language,
    forced_instrument_priority,
    has_dynamic_cycles,
    hasEnforcedLanguage,
    neutral_reward_enabled,
    session_timeout_seconds,
    training,
    visit_support,
  } = configObj

  const isEmailless = checkIsEmailless(study)

  const configProps = {
    ...props,
    configObj,
    deleteConfigKey,
    updateConfig: updateStudyConfig,
  }
  const giftCardSelected = configObj[STUDY_CONFIG_MAP.treasureChestStoreGiftCard]

  const hasLanguageError =
    saveAttempted && hasEnforcedLanguage && !!enforced_language && enforced_language.default_language === ''

  const hasGemsRewards = [REWARD_TYPE_MAP.gems, REWARD_TYPE_MAP.superGems].includes(configObj?.cycles?.[0].rewardType)

  const getFormattedCycles = () => {
    let _cycles = cycles
    if (!cycles) _cycles = study.cycles
    if (has_dynamic_cycles) {
      return _cycles
    }
    if (!_cycles) return []

    const cycle = _cycles[0]
    const duration = Math.ceil(
      (cycle?.durationType === DURATION_TYPE_MAP.weeks ? cycle?.studyDuration * DAYS_IN_WEEK : cycle?.studyDuration) /
        cycle?.cycleDuration,
    )
    const newArr = Array.from({ length: duration }, () => ({}))
    return newArr.map((_, idx) => ({
      ...cycle,
      cycleDuration:
        idx + 1 === duration && cycle?.studyDuration % cycle?.cycleDuration
          ? cycle?.studyDuration % cycle?.cycleDuration
          : cycle?.cycleDuration,
    }))
  }

  const studyCycles = getFormattedCycles()

  const renderStudyNameInput = () => {
    return (
      <Input
        className='name'
        hasError={saveAttempted && study.platform_name === ''}
        errorText={STRINGS.cannotBeEmpty}
        label={STRINGS.studyName}
        disabled={disabled}
        value={study.platform_name}
        onChange={value => updateStudy('platform_name', value)}
        id='study-name'
        placeholder={STRINGS.studyName}
      />
    )
  }

  const renderAppNameInput = () => {
    return (
      <Input
        charLimit={60}
        className='name pos rel'
        hasError={saveAttempted && study.name === ''}
        errorText={STRINGS.cannotBeEmpty}
        label='Name displayed to participants in the app'
        disabled={disabled}
        value={study.name}
        onChange={value => updateStudy('name', value)}
        id='app-display-name'
        placeholder='App Display Name'
      />
    )
  }

  const renderTimeoutInput = sessionTimeoutConfig => {
    const { updateValue, updateStudySecurityConfig, key, currentSettings } = sessionTimeoutConfig || {}
    const onChange = sessionTimeoutConfig
      ? updateValue(updateStudySecurityConfig, key, currentSettings)
      : val => updateStudyConfig({ session_timeout_seconds: val * 60 })
    const sessionValue = sessionTimeoutConfig
      ? disabled && !admin_security?.[key]
        ? '720'
        : admin_security?.[key] || ''
      : session_timeout_seconds
      ? session_timeout_seconds / 60
      : ''
    const [hours, mins] = getHoursAndMins(sessionValue)
    const exampleTime =
      hours > 0 ? ` ( ${pluralize(hours, 'hour', 'hours')} ${mins > 0 ? pluralize(mins, 'min ', 'mins ') : ''}) ` : ' '
    return (
      <Input
        className='user-session-timeout'
        disabled={disabled}
        inline
        onChange={onChange}
        pretext='Timeout after'
        posttext={`minutes${exampleTime}`}
        preventInput={num => num < 0}
        id='session-timeout'
        type='number'
        value={sessionValue}
      />
    )
  }

  const configCheckbox = ({ id, label, coupledKeyString, keyString, toggle = false, val = {} }) => {
    return (
      <Checkbox
        id={id}
        toggle={toggle}
        label={label}
        checked={!!configObj[keyString]}
        disabled={disabled}
        onClick={() => {
          const enabled = configObj[keyString]
          if (!enabled) {
            updateStudyConfig({ [keyString]: val })
            if (coupledKeyString) updateStudyConfig({ [coupledKeyString]: val })
          } else {
            deleteConfigKey(keyString)
            if (coupledKeyString) deleteConfigKey(coupledKeyString)
          }
        }}
      />
    )
  }

  const NameSection = (
    <Container>
      {isWizard ? <StudyWizardHeader /> : <h3>{disabled ? STRINGS.studyInfoViewOnly : null}</h3>}
      {self_sign_code && (
        <div className='flexed start-justified start-aligned'>
          <p>{`Study Sign-up Code: ${self_sign_code}`}</p>
          <Popup hover noPointer dark position='right' trigger={<i className='fas fa-info-circle' />}>
            <p>{STRINGS.selfSignUpCode}</p>
          </Popup>
        </div>
      )}
      {renderStudyNameInput()}
      <Checkbox
        id='mark-as-test-study'
        label='Mark as Test Study'
        checked={configObj.is_test}
        disabled={disabled}
        onClick={() => updateStudyConfig({ is_test: !configObj.is_test })}
      />
      {renderAppNameInput()}
    </Container>
  )

  const orderedInstrumentContent = (isOrdered = true) => {
    return isOrdered ? (
      <div id='ordered-instruments'>
        <p>{STRINGS.orderedInstrumentsHeading}</p>
        <p>{STRINGS.orderedInstrumentsBody}</p>
      </div>
    ) : (
      <div id='unordered-instruments'>
        <p>{STRINGS.unorderedInstrumentsHeading}</p>
        <p>{STRINGS.unorderedInstrumentsBody}</p>
      </div>
    )
  }

  const MainFeaturesSection = (
    <Container>
      <h3>{STRINGS.studyConfigurations}</h3>
      {disabled && <EmaillessConfig disabled={disabled} isEmailless={isEmailless} />}
      <h5>{STRINGS.features}</h5>
      <div className='config-checkboxes'>
        <div className='checkboxes'>
          {!CHINA_DEPLOY && (
            <>
              <Checkbox
                id='econsent'
                label={STRINGS.eConsent}
                checked={configObj.consent_required}
                disabled={disabled}
                onClick={() => updateStudyConfig({ consent_required: !configObj.consent_required })}
              />
              <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.consentRequired, disabled)}>
                {STRINGS.eConsentConfigBody}
              </p>
            </>
          )}
          <Checkbox
            id='training'
            label={STRINGS.training}
            checked={configObj.training?.enabled}
            disabled={disabled}
            onClick={() => updateStudyConfig({ training: { enabled: !configObj.training?.enabled } })}
          />
          <p className={generatePClassName(configObj, [STUDY_CONFIG_MAP.training, 'enabled'], disabled)}>
            {STRINGS.trainingConfigBody}
          </p>
          {isRootOrDchAdmin && (
            <>
              {configCheckbox({
                id: 'clinician-reported-outcome',
                label: STRINGS.clinicianReportedOutcomes,
                keyString: STUDY_CONFIG_MAP.clinro,
                coupledKeyString: STUDY_CONFIG_MAP.virtualVisits,
              })}
              <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.clinro, disabled)}>
                {STRINGS.clinroConfigBody}
              </p>
              {configCheckbox({
                id: 'medication-adherance',
                label: STRINGS.medicationAdherence,
                keyString: STUDY_CONFIG_MAP.medicationAdherence,
              })}
              <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.medicationAdherence, disabled)}>
                {STRINGS.medicationAdherenceConfigBody}
              </p>
              <div className='config-toggles flexed column start-aligned'>
                {configCheckbox({
                  id: 'advanced-features',
                  label: STRINGS.advancedFeatures,
                  keyString: STUDY_CONFIG_MAP.hasAdvancedFeatures,
                  toggle: true,
                  val: true,
                })}
                {configObj.hasAdvancedFeatures && (
                  <SubMenu>
                    {configCheckbox({
                      id: 'custom-onboarding',
                      label: STRINGS.customOnboarding,
                      keyString: STUDY_CONFIG_MAP.customOnboarding,
                    })}
                    {configCheckbox({
                      id: 'case-management',
                      label: STRINGS.caseManagement,
                      keyString: STUDY_CONFIG_MAP.caseManagement,
                    })}
                    {!CHINA_DEPLOY &&
                      configCheckbox({
                        id: 'geofence-triggered',
                        label: STRINGS.geofenceInstruments,
                        keyString: STUDY_CONFIG_MAP.customGeofenceBehavior,
                      })}
                    {configCheckbox({
                      id: 'custom-report-outcome',
                      label: STRINGS.customReportButton,
                      keyString: STUDY_CONFIG_MAP.customMapButton,
                    })}
                  </SubMenu>
                )}
              </div>
            </>
          )}
          <h5>{STRINGS.visitConfiguration}</h5>
          <div className='flexed checkbox-with-graphic'>
            <div>
              {configCheckbox({
                id: 'visit-support-checkbox',
                label: STRINGS.visitSupport,
                keyString: STUDY_CONFIG_MAP.visitSupport,
              })}
              <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.clinro, disabled)}>
                {STRINGS.visitSupportConfigBody}
              </p>
            </div>
            {visitSupportIcon({ className: 'checkbox-graphic' })}
          </div>
          {configObj[STUDY_CONFIG_MAP.clinro] && isRootOrDchAdmin && (
            <div className='flexed checkbox-with-graphic'>
              <div>
                {configCheckbox({
                  id: 'virtual-visit-checkbox',
                  label: STRINGS.virtualVisitClinro,
                  keyString: STUDY_CONFIG_MAP.virtualVisits,
                })}
                <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.clinro, disabled)}>
                  {STRINGS.virtualVisitConfigBody}
                </p>
              </div>
              {virtualVisitsIcon({ className: 'checkbox-graphic' })}
            </div>
          )}
          <Divider color='#dfe1f1' />
        </div>
        <h5>{STRINGS.instrumentCompletionOrder}</h5>
        <div className='flexed instrument-priority'>
          <Radio
            disabled={disabled}
            selected={configObj.forced_instrument_priority}
            onClick={() => updateStudyConfig({ [STUDY_CONFIG_MAP.forcedInstrumentPriority]: true })}
            content={orderedInstrumentContent(true)}
          />
          <Radio
            disabled={disabled}
            selected={!configObj.forced_instrument_priority}
            onClick={() => updateStudyConfig({ [STUDY_CONFIG_MAP.forcedInstrumentPriority]: false })}
            content={orderedInstrumentContent(false)}
          />
        </div>
        <Divider color='#dfe1f1' />
        <AppPushConfig {...configProps} />
      </div>
    </Container>
  )

  const MainFeaturesReadOnlySection = (
    <Container className='read-only-section'>
      <div className='heading-row'>
        <p>Study Configuration</p>
        <Button
          link
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.type)}
          id='edit-button'
          icon='fas fa-pencil-alt'
          content='Edit'
        />
      </div>
      <div className='content-row'>
        <p>{STRINGS.ptpContactInformationCollection}</p>
        <p>{!isEmailless ? 'Enabled' : 'Disabled'}</p>
      </div>
      <div className='content-row'>
        <p>{STRINGS.eConsent}</p>
        <p>{consent_required ? STRINGS.yes : STRINGS.no}</p>
      </div>
      <div className='content-row'>
        <p>{STRINGS.training}</p>
        <p>{training?.enabled ? STRINGS.yes : STRINGS.no}</p>
      </div>
      <div className='content-row'>
        <p>{STRINGS.visitSupport}</p>
        <p>{visit_support ? STRINGS.yes : STRINGS.no}</p>
      </div>
      <div className='content-row last'>
        <p>{STRINGS.instrumentOrder}</p>
        <p>{forced_instrument_priority ? STRINGS.Ordered : STRINGS.unordered}</p>
      </div>
    </Container>
  )

  const renderParticipantReadOnlySection = () => {
    const sessionTimeOutSeconds = session_timeout_seconds
    const sessionTimeoutMin = Math.round(sessionTimeOutSeconds / 60) || admin_security?.session_timeout
    return (
      <Container className='read-only-section'>
        <div className='heading-row'>
          <p>Participant App Settings</p>
          <Button
            link
            onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.setting)}
            id='edit-button'
            icon='fas fa-pencil-alt'
            content='Edit'
          />
        </div>
        <div className='content-row'>
          <p>Timeout minutes</p>
          <p>{sessionTimeoutMin ? `${pluralize(sessionTimeoutMin, 'minute', 'minutes')}` : 'None'}</p>
        </div>
        <div className='content-row'>
          <p>Tone of voice</p>
          <p>{neutral_reward_enabled ? 'Neutral' : 'Celebratory'}</p>
        </div>
        <div className='content-row'>
          <p>Data Sync Indicator</p>
          <p>{data_sync_ui_enabled ? 'Show' : 'Hide'}</p>
        </div>
        <div className='content-row last'>
          <p>Expire admin and clinician passwords</p>
          <p>{admin_security?.expiration.isToggled ? 'Expire passwords' : 'Unexpire passowrds'}</p>
        </div>
      </Container>
    )
  }

  const ScheduleAndRewardsReadOnlySection = (
    <Container className='read-only-section'>
      <div className='heading-row'>
        <p>Schedule & Rewards</p>
        <Button
          link
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.schedule)}
          id='edit-button'
          icon='fas fa-pencil-alt'
          content='Edit'
        />
      </div>
      <div className='subheading-row'>
        <p>Timeline</p>
      </div>
      {studyCycles?.map((cycle, idx) => (
        <div className={`content-row ${idx + 1 === studyCycles?.length ? 'last' : ''}`} key={idx}>
          <p>{`Chapter ${idx + 1}`}</p>
          <p>{`${cycle?.cycleDuration} ${cycle?.cycleDuration === 1 ? 'day' : 'days'}, ${
            cycle?.target
          } required instruments`}</p>
        </div>
      ))}
      <div className='subheading-row'>
        <p>Rewards</p>
      </div>
      {studyCycles?.map((cycle, idx) => (
        <div className={`content-row ${idx + 1 === studyCycles?.length ? 'last' : ''}`} key={idx}>
          <p>{`Chapter ${idx + 1}`}</p>
          <p>{`${cycle?.rewardType === REWARD_TYPE_MAP.cash ? cycle?.reward / 100 : cycle?.reward} ${
            REWARD_NAME_MAP[cycle?.rewardType]
          }`}</p>
        </div>
      ))}
    </Container>
  )

  const subjectIdConfig = (
    <div>
      <h5>{STRINGS.ptpSubjectId}</h5>
      <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.showParticipantUniqueIdentifier, disabled)}>
        {STRINGS.subjectId}
      </p>
      {configCheckbox({
        id: 'subject-id',
        label: STRINGS.showPtpSubjectId,
        keyString: STUDY_CONFIG_MAP.showParticipantUniqueIdentifier,
        toggle: false,
        val: {
          type: 'subject_id?subject_id:participant_id',
        },
      })}
    </div>
  )

  const endOfStudyConfig = (
    <div>
      <h5>{STRINGS.endOfStudySummary}</h5>
      <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.studyEnd, disabled)}>
        {STRINGS.endOfStudyConfigBody}
      </p>
      {configCheckbox({
        id: 'end-of-summary',
        label: STRINGS.displayEndOfStudy,
        keyString: STUDY_CONFIG_MAP.studyEnd,
      })}
    </div>
  )

  const ParticipantSection = (
    <Container>
      <h3>{STRINGS.ptpAppSettings}</h3>
      {isRootOrDchAdmin && (
        <>
          <h5>{STRINGS.languages}</h5>
          <EnforcedLanguagesConfig
            {...props}
            resetEnforcedLanguage={() => updateStudyConfig({ hasEnforcedLanguage: false })}
            updateConfig={updateStudyConfig}
            hasError={hasLanguageError}
          />
          <Divider color='#dfe1f1' />
          {subjectIdConfig}
          <Divider color='#dfe1f1' />
          {endOfStudyConfig}
          <Divider color='#dfe1f1' />
        </>
      )}
      <h5>Session Time Out</h5>
      {renderTimeoutInput()}
    </Container>
  )

  const ScheduleAndRewardsSection = (
    <Container>
      <h3>Schedule & Rewards</h3>
      <RewardConfig {...configProps} />
      <div className='reward-eligibility'>
        {has_dynamic_cycles ? <DynamicCycleRewardConfig {...configProps} /> : <CycleRewardConfig {...configProps} />}
        {hasGemsRewards ? (
          <BinaryConfig
            {...props}
            bodyText={STRINGS.midtermRewardsBody}
            buttonIcons={{
              leftIcon: richButtonIconMap.iconContainer(supriseBox),
              rightIcon: richButtonIconMap.iconContainer(gem),
            }}
            buttonLabels={{ left: STRINGS.specialRewards, right: STRINGS.avatarItems }}
            configKey={STUDY_CONFIG_MAP.superGems}
            defaultIsFalse
            heading={STRINGS.midtermRewards}
            subheading={STRINGS.midtermRewardsSubheading}
            updateStudyConfig={updateStudyConfig}
            deleteConfigKey={deleteConfigKey}
            isRewardType
          />
        ) : null}
      </div>
    </Container>
  )
  const GiftCardSection = (
    <Container>
      <h3>{STRINGS.giftcardConfig}</h3>
      <GiftCardConfig {...configProps} hasDynamicCycles={has_dynamic_cycles} />
    </Container>
  )

  const RewardsSection = (
    <Container>
      <h3>{STRINGS.rewards}</h3>
      <NewRewardsConfig {...configProps} updateStudyConfig={updateStudyConfig} configObj={configObj} />
    </Container>
  )

  const RewardSelectionSection = (
    <Container>
      <h3>{STRINGS.rewardSelection}</h3>
      <RewardSelectionConfig {...configProps} />
    </Container>
  )

  const SecuritySection = (
    <Container>
      <h3>{STRINGS.security}</h3>
      <SecurityConfig
        {...configProps}
        updateStudySecurityConfig={newConfig => updateStudyConfig({ admin_security: { ...newConfig } })}
        renderTimeoutInput={renderTimeoutInput}
      />
    </Container>
  )

  const ToneSection = (
    <Container>
      <BinaryConfig
        {...props}
        bodyText={STRINGS.toneOfVoiceBody}
        buttonIcons={{ leftIcon: richButtonIconMap.squareCheck, rightIcon: richButtonIconMap.confetti }}
        buttonLabels={{ left: STRINGS.neutral, right: STRINGS.celebratory }}
        configKey={STUDY_CONFIG_MAP.toneOfVoice}
        defaultIsFalse
        heading={STRINGS.toneOfVoice}
        updateStudyConfig={updateStudyConfig}
      />
    </Container>
  )

  const DataSyncSection = (
    <Container>
      <BinaryConfig
        {...props}
        bodyText={STRINGS.dataSyncConfigBody}
        buttonIcons={{ leftIcon: richButtonIconMap.ban, rightIcon: richButtonIconMap.dataSync }}
        buttonLabels={{ left: STRINGS.hide, right: STRINGS.show }}
        configKey={STUDY_CONFIG_MAP.dataSyncUI}
        heading={STRINGS.dataSync}
        updateStudyConfig={updateStudyConfig}
      />
      <ParticipantOfflineDuration study={study} disabled={disabled} />
    </Container>
  )

  return (
    <>
      {!disabled ? (
        <div className='review-section'>
          <h3 className='main-heading'>Review your study</h3>
          {NameSection}
          {MainFeaturesReadOnlySection}
          {renderParticipantReadOnlySection()}
          {ScheduleAndRewardsReadOnlySection}
        </div>
      ) : (
        <>
          {NameSection}
          {isFlagsmithConfigurationSectionShown && <FlagsmithConfigurationSection userId={userId} study={study} />}
          {MainFeaturesSection}
          {ParticipantSection}
          {ToneSection}
          {DataSyncSection}
          {SecuritySection}
          {configObj.cycles && ScheduleAndRewardsSection}
          {hasGemsRewards && configObj.super_gems && RewardsSection}
          {hasGemsRewards &&
            !disabled &&
            configObj.super_gems &&
            giftCardSelected &&
            !configObj.super_gems.gift_cards &&
            GiftCardSection}
          {configObj.super_gems && giftCardSelected && configObj.super_gems.gift_cards && RewardSelectionSection}
        </>
      )}
      <CreateStudyFooter {...props} />
    </>
  )
}

CreateStudyReview.propTypes = {
  createStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  disabled: PropTypes.bool,
  fetchLocales: PropTypes.func,
  hasDeleteStudyPermissions: PropTypes.bool,
  isRootOrDchAdmin: PropTypes.bool,
  isWizard: PropTypes.bool,
  saveAttempted: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  study: PropTypes.shape({
    self_sign_code: PropTypes.string,
    name: PropTypes.string,
    platform_name: PropTypes.string,
    id: PropTypes.number,
  }),
  updateConfig: PropTypes.func,
  updateStudy: PropTypes.func,
  userId: PropTypes.number,
}

export default CreateStudyReview
