import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, DatePicker, Input, Checkbox, Container, Dropdown, Loader } from 'components/UIElements'
import { hasValue } from 'utils/misc'
import '../stylesheets/AuditFilter.scss'
import { formatStringForID } from 'utils/misc'

const AUDIT_REPORT_MSG = 'Note: Audit reports will be sent to the email address associated with your account'
const AUDIT_REPORT_SUCCESS_MSG =
  'We are generating your report now and will send it to your email once complete. This may take up to 60 min depending on the file size.'

const INITIAL_FILTER_STATE = {
  dateFrom: '',
  dateTo: '',
  actionTypes: [],
  objectType: null,
  objectId: null,
  authorType: null,
  authorId: null,
  noResults: false,
  showSuccess: false,
  errorMessage: '',
  dateFromError: '',
}
const BACKEND_PARAMS = {
  dateFrom: 'from',
  dateTo: 'to',
  actionTypes: 'action_types',
  objectType: 'object_type',
  objectId: 'object_ids',
  authorType: 'author_type',
  authorId: 'author_id',
}
const MESSAGE_VALUES = {
  dateFrom: 'from date',
  dateTo: 'to date',
  actionTypes: 'action type',
  objectType: 'object type',
  objectId: 'object ID',
  authorType: 'author type',
  authorId: 'author ID',
}
const OBJECT_TYPES = [
  'Participant',
  'User',
  'Instrument',
  'Instrument Item',
  'Announcement',
  'Instrument Event Item',
  'Participant File',
  'User Permission',
  'Site',
  'Geofence',
  'Track',
  'Track Assignment',
  'All Object Types',
]
const ACTION_TYPES = ['Create', 'Update', 'Delete']
const AUTHOR_TYPES = [
  'Participant',
  'User',
  // 'System',
  'All Author Types',
]

const DATE_RANGE_HELP_TEXT = 'Note: Maximum range is 31 days'

class AuditFilterPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = INITIAL_FILTER_STATE
  }

  componentDidMount() {
    const { resetAuditReport } = this.props
    resetAuditReport()
  }

  onDateChange = (key, date) => {
    this.toggleOffResultsErrorMessage()
    this.setState({ [key]: date })
  }

  onChangeInput = (key, event) => {
    this.toggleOffResultsErrorMessage()
    this.setState({ [key]: event })
  }

  onToggleActionTypeToFilter = (key, actionType) => {
    this.toggleOffResultsErrorMessage()
    let filterArr = [...this.state.actionTypes]
    if (this.state.actionTypes.includes(actionType)) {
      filterArr = filterArr.filter(val => {
        return val !== actionType
      })
    } else {
      filterArr.push(actionType)
    }
    this.setState({ [key]: filterArr })
  }

  onSelectExclusiveParamToFilter = ({ filterType, value }) => {
    this.toggleOffResultsErrorMessage()
    if (value === 'All Object Types') {
      this.setState({ [filterType]: null })
    } else if (value === 'All Author Types' || value === 'System') {
      this.setState({ [filterType]: value, authorId: null })
    } else {
      this.setState({ [filterType]: value })
    }
  }

  resetFilter = () => {
    const { resetAuditReport } = this.props
    resetAuditReport()
    this.setState({ ...INITIAL_FILTER_STATE })
  }

  generateSearchParams = () => {
    const filterParams = Object.keys(this.state).filter(
      stateKey => !['noResults', 'dateFromError', 'showSuccess', 'errorMessage'].includes(stateKey),
    )
    return filterParams.filter(key => {
      return hasValue(this.state[key])
    })
  }

  generateUrlString = () => {
    const { params } = this.props
    const { studyID } = params
    let urlSubStrings
    let nestedSearchParamArr
    const searchParams = this.generateSearchParams()
    if (searchParams.length > 0) {
      urlSubStrings = searchParams.map(searchParam => {
        const iterUrlValue = this.state[searchParam]
        if (Array.isArray(iterUrlValue)) {
          nestedSearchParamArr = iterUrlValue.map(nestedFilterValue => {
            return `${BACKEND_PARAMS[searchParam]}=${nestedFilterValue}`
          })
          return nestedSearchParamArr.join('&')
        }
        return `${BACKEND_PARAMS[searchParam]}=${this.state[searchParam]}`
      })
    }
    const urlString = `${urlSubStrings ? urlSubStrings.join('&') : ''}&study_id=${studyID}`
    return urlString
  }

  onGoToAuditReport = () => {
    const { fetchAuditReport } = this.props
    const urlString = this.generateUrlString()
    fetchAuditReport({
      urlString,
      callback: () => this.setState({ showSuccess: true, errorMessage: '' }),
      errCallback: errorMessage => this.setState({ errorMessage }),
    })
  }

  _changeFilter(key, value) {
    this.toggleOffResultsErrorMessage()
    const filter = { ...this.state.filter }
    filter[key] = value
    this.setState({ filter })
  }

  toggleOffResultsErrorMessage() {
    this.setState({ noResults: false })
  }

  // render functions for the different filter params
  renderActionTypeFilter = () => {
    const { actionTypes } = this.state
    const actionTypeCheckboxes = ACTION_TYPES.map(actionType => {
      return (
        <Checkbox
          id={formatStringForID(actionType)}
          key={actionType}
          label={actionType}
          checked={actionTypes.includes(actionType)}
          onClick={() => this.onToggleActionTypeToFilter('actionTypes', `${actionType}`)}
        />
      )
    })
    return (
      <div className='filter-action-type'>
        <label className='label-small'>Action</label>
        {actionTypeCheckboxes}
      </div>
    )
  }

  renderObjectTypeFilter = () => {
    const { noTracksFeature } = this.props
    const { objectType } = this.state
    let objectTypeDropdownOptions = OBJECT_TYPES.map(_objectType => {
      return {
        key: _objectType,
        value: _objectType,
        text: _objectType,
        filterType: 'objectType',
      }
    })

    if (noTracksFeature) {
      objectTypeDropdownOptions = objectTypeDropdownOptions.filter(_objectType => {
        return _objectType.key !== 'Track' && _objectType.key !== 'Track Assignment'
      })
    }

    return (
      <div>
        <label className='label-small' htmlFor='audit-object-type-dropdown'>
          Object
        </label>
        <Dropdown
          id='audit-object-type-dropdown'
          options={objectTypeDropdownOptions}
          selected={objectType === null ? 'All Object Types' : objectType}
          onSelect={this.onSelectExclusiveParamToFilter}
        />
      </div>
    )
  }

  renderAuthorTypeFilter = () => {
    const { authorType } = this.state
    const authorTypeDropdownOptions = AUTHOR_TYPES.map(_authorType => {
      return {
        key: _authorType,
        value: _authorType === 'All Author Types' ? null : _authorType,
        text: _authorType,
        filterType: 'authorType',
      }
    })
    return (
      <Dropdown
        id='author-type-filer-dropdown'
        options={authorTypeDropdownOptions}
        selected={authorType === null ? 'All Author Types' : authorType}
        onSelect={this.onSelectExclusiveParamToFilter}
      />
    )
  }

  renderAuthorIdField = () => {
    const { authorType, authorId } = this.state

    if (authorType === 'User' || authorType === 'Participant') {
      return (
        <Input
          id={formatStringForID('author-id-' + authorType)}
          label={`Author ${authorType} ID`}
          placeholder='Ex., 3'
          value={authorId || ''}
          onChange={e => this.onChangeInput('authorId', e)}
        />
      )
    }
  }

  checkErrorsAndSetErrors = () => {
    const { dateFrom, dateTo } = this.state
    if (!dateFrom) {
      this.setState({ dateFromError: 'Add from date' })
      return true
    }
    if (dateFrom && dateTo && new Date(dateFrom) > new Date(dateTo)) {
      this.setState({ dateFromError: 'Invalid date range' })
      return true
    }

    return false
  }

  setToDateToPresent = () => {
    const present = moment()
      .startOf('day')
      .format()

    this.setState({ dateTo: present })
  }

  checkErrorsAndRunReport = () => {
    const { dateTo } = this.state
    {
      const hasErrors = this.checkErrorsAndSetErrors()
      if (!hasErrors) {
        if (!dateTo) this.setToDateToPresent()
        this.onGoToAuditReport()
      }
    }
  }

  renderButtonList = () => {
    const { loading } = this.props
    return (
      <div className='button-list-wrapper'>
        <p>{AUDIT_REPORT_MSG}</p>
        <div className='button-list'>
          {loading && (
            <div className='audit-loader'>
              <Loader inContainer size={25} />
            </div>
          )}
          <Button
            disabled={loading}
            grey
            id='clear-all-button'
            content='Clear All'
            onClick={() => this.resetFilter()}
          />
          <Button
            disabled={loading}
            id='run-report-button'
            content='Run Report'
            onClick={() => this.checkErrorsAndRunReport()}
            isThrottled
          />
        </div>
      </div>
    )
  }

  renderNoResultsMessage = () => {
    const { noResults } = this.state
    const searchParams = this.generateSearchParams()
    const paramsCount = searchParams.length
    let message = ''
    if (paramsCount === 0) {
      message = 'There are no records.'
    } else if (paramsCount === 1) {
      message = `There are no records found for your filter value in ${MESSAGE_VALUES[searchParams[0]]}.`
    } else {
      const middleMessageValues = searchParams.slice(0, paramsCount - 1).map(key => MESSAGE_VALUES[key])
      message = `There are no records for your filter values in ${middleMessageValues.join(', ')} and ${
        MESSAGE_VALUES[searchParams[paramsCount - 1]]
      }.`
    }
    return <p className={noResults ? 'no-results' : 'results'}>{message}</p>
  }

  renderSuccessMessage = () => {
    const { showSuccess } = this.state
    if (showSuccess)
      return (
        <Button
          noStyling
          className='text-align left noty_bar audit-success-mgs'
          onClick={() => this.setState({ showSuccess: false })}>
          <div className='noty_body'>
            <div className='flexed centered-aligned'>
              <i className='first far fa-check-circle' />
              <span className='message-text'>{AUDIT_REPORT_SUCCESS_MSG}</span>
              <i className='far fa-times' />
            </div>
          </div>
        </Button>
      )
    return null
  }

  renderErrorMessage = () => {
    const { errorMessage } = this.state
    if (errorMessage)
      return (
        <Button
          noStyling
          className='text align left noty_bar noty_type__error'
          onClick={() => {
            this.setState({ errorMessage: '' })
          }}>
          <div className='noty_body '>
            <div className='flexed centered-aligned'>
              <i className='first far fa-times-circle' />
              <span className='message-text'>{errorMessage}</span>
              <i className='far fa-times' />
            </div>
          </div>
        </Button>
      )
    return null
  }

  render() {
    const { dateFrom, dateTo, dateFromError, objectId } = this.state
    const parsedDateFromDayAfter = new Date(dateFrom)
    parsedDateFromDayAfter.setDate(parsedDateFromDayAfter.getDate() + 1)
    const parsedDateFrom = dateFrom ? moment(dateFrom) : ''
    const parsedDateTo = dateTo ? moment(dateTo) : ''
    const present = new Date()

    /**
     * Audit reports restricts date range to a max of 31 days, so if there is a from or
     * to date chosen, the days beyond 31 days the from or to date will be disabled.
     */

    let disabledFromBeforeDates = null
    let disabledFromAfterDates = present
    let disabledToAfterDates = present
    let disabledToBeforeDates = null
    if (dateTo) {
      disabledFromBeforeDates = new Date(dateTo)
      disabledFromBeforeDates.setDate(disabledFromBeforeDates.getDate() - 31)

      const toDate = new Date(dateTo)
      /**
       * If there is a "To" date selected, we will prevent selection of dates after
       * that date in "From" date calendar
       */
      if (present > toDate) disabledFromAfterDates = toDate
    }
    if (dateFrom) {
      disabledToAfterDates = new Date(dateFrom)
      disabledToAfterDates.setDate(disabledToAfterDates.getDate() + 31)

      // If day after "from date" is in the future, we just disable days prior to present
      disabledToBeforeDates = parsedDateFromDayAfter < present ? parsedDateFromDayAfter : present

      // If 31 days from "from date" is beyond the present, we disable days after present
      disabledToAfterDates = disabledToAfterDates > present ? present : disabledToAfterDates
    }

    return (
      <div className='audit-reporting page'>
        <div className='audit-filter'>
          <div className='flexed-header'>
            <h2>Audit Report</h2>
          </div>
          <Container>
            <h4>Filter</h4>
            <div className='flexed start-aligned'>
              <div className='filter-left'>
                <div className='filter-date-range'>
                  <label className='label-small' htmlFor='audit-date-range'>
                    Date Range
                  </label>
                  <div className='date-range-picker' id='audit-date-range'>
                    <DatePicker
                      id='audit-date-from'
                      date={parsedDateFrom}
                      disabledDays={{
                        after: disabledFromAfterDates,
                        before: disabledFromBeforeDates,
                      }}
                      hasError={!!dateFromError}
                      helpText={DATE_RANGE_HELP_TEXT}
                      errorText={dateFromError}
                      onDayChange={date => {
                        if (dateFromError) this.setState({ dateFromError: '' })
                        this.onDateChange(
                          'dateFrom',
                          date
                            ? moment(date)
                                .startOf('day')
                                .format()
                            : '',
                        )
                      }}
                      value={dateFrom}
                    />
                    <p className='hyphen'>-</p>
                    <DatePicker
                      id='audit-date-to'
                      date={parsedDateTo}
                      disabledDays={{
                        after: disabledToAfterDates,
                        before: disabledToBeforeDates,
                      }}
                      onDayChange={date => {
                        if (dateFromError) this.setState({ dateFromError: '' })
                        this.onDateChange(
                          'dateTo',
                          date
                            ? moment(date)
                                .startOf('day')
                                .format()
                            : '',
                        )
                      }}
                      value={dateTo}
                    />
                  </div>
                </div>
                {this.renderActionTypeFilter()}
                {this.renderObjectTypeFilter()}
              </div>

              <div className='filter-right'>
                <label htmlFor='object-id-input' className='label-small'>
                  Object ID
                </label>
                <Input
                  id='object-id-input'
                  placeholder='Ex., 1101'
                  value={objectId || ''}
                  onChange={e => this.onChangeInput('objectId', e)}
                />
                <label className='label-small' htmlFor='author-type-filer-dropdown'>
                  Author Type
                </label>
                {this.renderAuthorTypeFilter()}
                {this.renderAuthorIdField()}
              </div>
            </div>
            {this.renderButtonList()}
          </Container>
          {this.renderNoResultsMessage()}
          {this.renderSuccessMessage()}
          {this.renderErrorMessage()}
        </div>
      </div>
    )
  }
}

AuditFilterPage.propTypes = {
  auditReport: PropTypes.arrayOf(PropTypes.array),
  params: PropTypes.shape({ studyID: PropTypes.string }),
  fetchAuditReport: PropTypes.func,
  loading: PropTypes.bool,
  resetAuditReport: PropTypes.func,
  noTracksFeature: PropTypes.bool,
}

export default AuditFilterPage
