import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from 'react-google-autocomplete'

const MapAutocompleteSearch = props => {
  const { className, onPlaceSelected } = props
  return <Autocomplete className={className} onPlaceSelected={onPlaceSelected} types={[]} />
}

MapAutocompleteSearch.propTypes = {
  className: PropTypes.string,
  onPlaceSelected: PropTypes.func,
}

export default MapAutocompleteSearch
