import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button, Loader, SearchInput, StickyHeader } from 'components/UIElements'
import { useOffsets } from 'utils/misc'
import STRINGS from 'utils/strings'
import VisitTemplatesTable from './VisitTemplatesTable'
import '../stylesheets/VisitSchedules.scss'

const VisitTemplatesPage = props => {
  const {
    adhocVisitsDisabled,
    canEditVisits,
    studyID,
    studyLock,
    selectedTab,
    isSiteCoord,
    visitSchedules,
    visitSchedulesLoading,
  } = props
  const offsets = useOffsets('sticky', selectedTab)

  const { checkedVisits = {}, visitSchedulesTemplatesList = [] } = visitSchedules

  const filteredVisits = visitSchedulesTemplatesList
  const checkedVisitsArr = Object.keys(checkedVisits)

  const noVisitTemplates = visitSchedulesTemplatesList.length === 0

  const isCreateNewVisitScheduleButtonDisplayed = !isSiteCoord && canEditVisits && noVisitTemplates
  const isCreateNewVisitButtonDisplayed = (canEditVisits && !noVisitTemplates) || isSiteCoord

  return (
    <>
      <StickyHeader offset={offsets ? offsets[0] : 0} className='column'>
        <div className='full-width-flex flex-end'>
          {/* TODO: change logic for Create Template button when we allow more than one visit schedules in a study */}
          {isCreateNewVisitScheduleButtonDisplayed && (
            <Button
              id='create-new-visit-schedule'
              disabled={studyLock}
              content={STRINGS.visitScheduleCreateNewVisitSchedule}
              onClick={() =>
                !studyLock && browserHistory.push(`/studies/${studyID}/visit-schedules/visit-templates/create`)
              }
            />
          )}
          {isCreateNewVisitButtonDisplayed &&
            (isSiteCoord ? (
              !adhocVisitsDisabled && (
                <Button
                  id='create-new-adhoc-visit'
                  disabled={studyLock}
                  content={STRINGS.visitScheduleCreateNewAdhoc}
                  onClick={() => {
                    if (!studyLock) {
                      browserHistory.push(`/studies/${studyID}/visit-schedules/participant-visit/create`)
                    }
                  }}
                />
              )
            ) : (
              <Button
                id='create-new-visit'
                disabled={studyLock}
                content={STRINGS.visitScheduleCreateNewVisit}
                onClick={() => {
                  if (!studyLock) {
                    browserHistory.push(`/studies/${studyID}/visit-schedules/visits/create`)
                  }
                }}
              />
            ))}
        </div>
        <div className='full-width-flex space-between'>
          {/* Next <div /> is a placeholder that will be replaced with Serhii's Filter component */}
          <div />
          <SearchInput />
        </div>
      </StickyHeader>
      {visitSchedulesLoading ? (
        <Loader inContainer className='visits-history-loader' />
      ) : (
        <>
          <VisitTemplatesTable
            offset={offsets ? offsets[1] : 0}
            checkedVisitsArr={checkedVisitsArr}
            visitSchedules={visitSchedules}
            {...props}
            filteredVisits={filteredVisits}
            emptyText='Schedule a new visit using the button above'
            studyId={studyID}
          />
          {visitSchedulesTemplatesList.length === 0 && (
            <div className='flexed column no-visits'>
              <h5>There are no visit templates</h5>
              {canEditVisits && <p>Create a new visit template using the button above</p>}
            </div>
          )}
        </>
      )}
    </>
  )
}

VisitTemplatesPage.propTypes = {
  adhocVisitsDisabled: PropTypes.bool,
  canEditVisits: PropTypes.bool,
  loading: PropTypes.shape({
    visitsSchedule: PropTypes.bool,
    virtualVisits: PropTypes.bool,
  }),
  studyID: PropTypes.string,
  selectedTab: PropTypes.string,
  visitSchedules: PropTypes.shape({
    expandedVisits: PropTypes.objectOf(PropTypes.bool),
    visitsHistoryList: PropTypes.arrayOf(PropTypes.array),
    visitsInfo: PropTypes.objectOf(PropTypes.object),
    visitsList: PropTypes.arrayOf(PropTypes.array),
    visitSchedulesTemplatesList: PropTypes.arrayOf(PropTypes.array),
    checkedVisits: PropTypes.objectOf(PropTypes.bool),
  }),
  visitSchedulesLoading: PropTypes.bool,
  studyLock: PropTypes.bool,
  isSiteCoord: PropTypes.bool,
}

export default VisitTemplatesPage
