import { setPageTitle } from 'utils/misc'
import { injectReducer } from 'store/reducers'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { setSubroute } from 'store/navbar'
import geofencesReducer, { actions as geofenceActions } from '../GeofencesPage/modules/Geofences'
import EditGeofenceContainer from '../GeofencesPage/containers/EditGeofenceContainer'

export default store => ({
  path: 'edit/:editGeofenceID',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      require => {
        injectReducer(store, {
          key: 'geofences',
          geofencesReducer,
        })
        const { studyID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Edit Geofence | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(setSubroute('geofences'))
        store.dispatch(geofenceActions.fetchGeofences(studyID))
        cb(null, UserIsAuthenticated(UserHasValidPassword(UserHasScope(['wu'], EditGeofenceContainer, false, studyID))))
      },
      'editgeofence',
    )
  },
})
