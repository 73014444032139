import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import STRINGS from 'utils/strings'
import '../stylesheets/visitTemplatePage.scss'
import VisitsFields from './VisitFields'

function VisitTemplatePage(props) {
  const {
    isVisitTemplateEditor,
    subtitleName,
    visitTemplate,
    visitTemplateID,
    onSaveVisitTemplate,
    studyID,
    studyLock,
  } = props
  const { template_name: visitName } = visitTemplate || {}

  return (
    <div className='visits-template-page page'>
      <div className='flexed'>
        <h4>{subtitleName}</h4>
        {/* <Button id='top-save-as-draft' grey content='Save as Draft' onClick={() => {}} /> */}
      </div>
      <VisitsFields {...props} />
      <div className='button-list'>
        {/* <Button grey id='bottom-save-as-draft' content='Save as Draft' onClick={() => {}} /> */}
        <Button
          disabled={!visitName || studyLock}
          id='next'
          className='deploy-button'
          content={STRINGS.saveVisitSchedule}
          onClick={() => onSaveVisitTemplate({ studyID, isUpdate: isVisitTemplateEditor, visitTemplateID })}
        />
      </div>
    </div>
  )
}

VisitTemplatePage.propTypes = {
  onSaveVisitTemplate: PropTypes.func,
  studyID: PropTypes.number,
  visitTemplateID: PropTypes.string,
}

export default VisitTemplatePage
