import React from 'react'
import PropTypes from 'prop-types'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { CollapseMenu } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import RewardGiftCardItem from './RewardGiftCardItem'

const SortableItem = SortableElement(props => {
  return <RewardGiftCardItem {...props} />
})

const SortableGiftCardList = SortableContainer(props => {
  const {
    cycle,
    days = 3,
    disabled,
    instruments = 1,
    items,
    isDragging,
    isExpand,
    updateGiftCard,
    updateGiftCardForUIObj,
    cycleArr,
  } = props

  return (
    <CollapseMenu
      titleContent={`Chapter ${cycle}`}
      subtitleContent={`${pluralize(days, 'day', 'days')}, ${pluralize(
        instruments,
        'instrument',
        'instruments',
      )} required`}
      useErrorIcon
      isOpen={isExpand}
      menuContent={items.map((item, index) => (
        <SortableItem
          collection={cycle}
          cycleNum={cycle}
          giftCardIndex={index}
          index={index}
          isDragging={isDragging}
          item={item}
          key={item.id}
          readOnly={disabled}
          updateGiftCard={updateGiftCard}
          updateGiftCardForUIObj={updateGiftCardForUIObj}
          cycleArr={cycleArr}
        />
      ))}
    />
  )
})

export default SortableGiftCardList

SortableGiftCardList.propTypes = {
  cycle: PropTypes.string,
  days: PropTypes.number,
  disabled: PropTypes.bool,
  instruments: PropTypes.number,
  items: PropTypes.array,
  isDragging: PropTypes.bool,
  isExpand: PropTypes.bool,
  updateGiftCard: PropTypes.func,
  updateGiftCardForUIObj: PropTypes.func,
  cycleArr: PropTypes.array,
}
