import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio, Checkbox } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { dayDifference } from 'utils/time'
import { CASE_STATUS_MAP } from '../../CasesPage/utils/statusMaps'

const MenuContent = ({
  caseClosed,
  status,
  nextStatusConfirmed,
  nextStatusTrue,
  participantReports,
  updateCaseProgress,
  nextStatus,
  statusToConfirm,
  info,
  timestamp,
  canWrite,
  studyLock,
}) => {
  const { ptpReportYes, ptpReportedAfterOpen } = info
  const noPtpReports = participantReports.length === 0

  const className = `${status} ${nextStatusConfirmed || caseClosed || !canWrite ? 'disabled' : ''}`

  const confirmation = nextStatus ? nextStatus.confirmation : null

  const [_confirmation, setConfirmation] = useState(nextStatusConfirmed ? confirmation : null)

  const nextStatusFalse = nextStatus ? nextStatus.confirmation === false : false

  const onSelect = bool => {
    setConfirmation(bool)
  }

  switch (status) {
    case CASE_STATUS_MAP.open:
      if (noPtpReports) {
        return (
          <div className={className}>
            <p>Participant has been in a medical facility for 6+ hours but has not responded</p>
            <p className='action'>
              Follow up with the participant or medical facility to see if symptoms for E.coli were reported
            </p>
            <p>
              <strong>Were E.coli symptoms reported?</strong>
            </p>
            <div className='radios flexed start-justified'>
              <Radio
                disabled={studyLock}
                selected={_confirmation === true}
                content='Yes'
                onClick={() => onSelect(true)}
              />
              <Radio
                disabled={studyLock}
                selected={_confirmation === false}
                content='No'
                onClick={() => onSelect(false)}
              />
            </div>
            {!nextStatusConfirmed && (
              <div className='flexed button-wrapper start-justified'>
                <Button
                  disabled={_confirmation === null || studyLock}
                  content='Submit'
                  onClick={() => updateCaseProgress(statusToConfirm, _confirmation)}
                />
                {_confirmation === true && <span>Case will be updated to "Symptoms reported"</span>}
              </div>
            )}
            {nextStatusFalse && !caseClosed && <div className='close-case'>Close the case above</div>}
          </div>
        )
      }
      if (ptpReportYes && !ptpReportedAfterOpen) {
        return (
          <div>
            <p>
              <span>The case was opened because the participant has reported</span>
              <br />
              <strong>experiencing E.coli symptoms</strong>
            </p>
          </div>
        )
      }
      return (
        <div>
          <p>
            <span>Since the case was opened, the participant has responded that they</span>
            <br />
            <strong> have experienced E.coli symptoms</strong>
          </p>
        </div>
      )
    case CASE_STATUS_MAP.reported:
      return (
        <div className={className}>
          <p>Were lab samples collected?</p>
          <div className='radios flexed start-justified'>
            <Radio
              id='sample-collected-yes'
              disabled={studyLock}
              selected={_confirmation === true}
              content='Yes'
              onClick={() => onSelect(true)}
            />
            <Radio
              id='sample-collected-no'
              disabled={studyLock}
              selected={_confirmation === false}
              content='No'
              onClick={() => onSelect(false)}
            />
          </div>
          {!nextStatusConfirmed && (
            <div className='flexed button-wrapper start-justified'>
              <Button
                id='submit'
                disabled={_confirmation === null || studyLock}
                content='Submit'
                onClick={() => updateCaseProgress(statusToConfirm, _confirmation)}
              />
              {_confirmation === true && <span>Case will be updated to "Infection suspected"</span>}
            </div>
          )}
          {nextStatusFalse && !caseClosed && <div className='close-case'>Close the case above</div>}
        </div>
      )
    case CASE_STATUS_MAP.suspected:
      return (
        <div className={className}>
          <p>Is E.coli infection confirmed?</p>
          <p className='action'>
            Check with relevant hospital departments and within the Medical Records system that the results confirm an
            E.coli infection
          </p>
          <div className='radios flexed start-justified'>
            <Radio
              disabled={studyLock}
              selected={_confirmation === true}
              content='Yes'
              onClick={() => onSelect(true)}
            />
            <Radio
              disabled={studyLock}
              selected={_confirmation === false}
              content='No'
              onClick={() => onSelect(false)}
            />
          </div>
          {!nextStatusConfirmed && (
            <div className='flexed button-wrapper start-justified'>
              <Button
                disabled={_confirmation === null || studyLock}
                content='Submit'
                onClick={() => updateCaseProgress(statusToConfirm, _confirmation)}
              />
              {_confirmation === true && <span>Case will be updated to "Infection confirmed"</span>}
            </div>
          )}
          {nextStatusFalse && !caseClosed && <div className='close-case'>Close the case above</div>}
        </div>
      )
    case CASE_STATUS_MAP.confirmed:
      const daysPast = dayDifference(timestamp, new Date())
      const withinTwentyEightDays = daysPast < 28
      const confirmedToday = daysPast === 0
      const daysPastString = confirmedToday
        ? 'Infection was confirmed today'
        : `Only ${pluralize(daysPast, 'day has', 'days have')} passed since infection was confirmed`
      return (
        <div className={className}>
          {!nextStatusConfirmed && withinTwentyEightDays && (
            <div className='prompt-bar days-passed'>{daysPastString}</div>
          )}
          <Checkbox
            disabled={studyLock}
            onClick={() => onSelect(!_confirmation)}
            checked={_confirmation === true}
            label='28-day follow-ups were completed in the EDC system'
          />
          {!nextStatusConfirmed && (
            <Button
              disabled={_confirmation === null || _confirmation === false || studyLock}
              content='Submit'
              onClick={() => updateCaseProgress(statusToConfirm, _confirmation)}
            />
          )}
          {nextStatusTrue && !caseClosed && (
            <div className='close-case'>Your follow-up is complete. You may now close this case.</div>
          )}
        </div>
      )
    case CASE_STATUS_MAP.open_no_symptoms:
    default:
      return (
        <div>
          <p>Since the case was opened, participant has responded that they have not experienced E.coli symptoms</p>
          <p>Close the case above</p>
        </div>
      )
  }
}

MenuContent.propTypes = {
  caseClosed: PropTypes.bool,
  status: PropTypes.string,
  timestamp: PropTypes.string,
  statusToConfirm: PropTypes.string,
  nextStatus: PropTypes.shape({
    confirmation: PropTypes.bool,
  }),
  canWrite: PropTypes.bool,
  nextStatusTrue: PropTypes.bool,
  nextStatusConfirmed: PropTypes.bool,
  participantReports: PropTypes.arrayOf(PropTypes.object),
  updateCaseProgress: PropTypes.func,
  info: PropTypes.shape({
    ptpReportYes: PropTypes.bool,
    ptpReportedAfterOpen: PropTypes.bool,
  }),
  studyLock: PropTypes.bool,
}

export default MenuContent
