import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button, SearchInput } from 'components/UIElements'
import { getUserRoleByStudy } from 'utils/misc'
import { getStudyLength } from 'utils/time'
import {
  USER_ROLES_MAP,
  TOOLTIP_MENU,
  TOOLTIP_MENU_MAP,
  MAX_STUDIES_ON_PAGE,
  STUDY_FEATURE_MAP,
  STUDY_SORTING_OPTIONS,
} from 'utils/constants'
import STRINGS from 'utils/strings'
import { getRegexFromTerm } from 'utils/search'
import { checkIsEmailless, persistStudyPaginationSettings } from 'utils/studyConfigUtils'
import '../stylesheets/Studies.scss'
import { CREATE_STUDY_FULL_URLS } from '../../routes/CreateStudy/utils/studyUtils'
import StudiesPaginationPanel from './StudiesPaginationPanel'
import StudyFeatureFilter from './StudyFeatureFilter'
import StudySorting from './StudySorting'
import StudyCard from './StudyCard'

const searchImg = require('../assets/search.png')

const studyKeys = ['id', 'name', 'platform_name']

const StudiesList = ({
  user,
  studies,
  searchTerm,
  allowCreateStudy,
  openModal,
  uploadLogo,
  deleteLogo,
  addPreviewLogo,
  resetStudyData,
  studyPaginationSettings,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedFilterFeatures, setSelectedFilterFeatures] = useState([])
  const [sortingOption, setSortingOption] = useState(STUDY_SORTING_OPTIONS.studyCreateDate)
  const [ascendingSortingOrder, setAscendingSortingOrder] = useState(true)

  useEffect(() => {
    if (studyPaginationSettings?.currentPage) {
      setCurrentPage(studyPaginationSettings?.currentPage)
    }
    if (studyPaginationSettings?.selectedFilterFeatures) {
      setSelectedFilterFeatures(studyPaginationSettings?.selectedFilterFeatures)
    }
    if (studyPaginationSettings?.sortingOption) {
      setSortingOption(studyPaginationSettings?.sortingOption)
    }
    if (studyPaginationSettings?.ascendingSortingOrder || studyPaginationSettings?.ascendingSortingOrder === false) {
      setAscendingSortingOrder(studyPaginationSettings?.ascendingSortingOrder)
    }
  }, [])

  const checkIsEnrollerOrSupport = studyID => {
    const userRole = getUserRoleByStudy(user, studyID)
    return [USER_ROLES_MAP.enroller, USER_ROLES_MAP.supportAgent].includes(userRole)
  }

  const checkIsSiteUserManagementAdmin = studyID => {
    const userRole = getUserRoleByStudy(user, studyID)
    return userRole === USER_ROLES_MAP.siteUserManagementAdmin
  }

  const getMainStudyPage = studyID => {
    const isEnrollerOrSupport = checkIsEnrollerOrSupport(studyID)
    const isSiteUserManagementAdmin = checkIsSiteUserManagementAdmin(studyID)

    if (isEnrollerOrSupport) {
      return 'participants'
    }

    if (isSiteUserManagementAdmin) {
      return 'users'
    }

    return 'compliance'
  }

  const renderIcons = (studyID, { icon, text, key, extra, path }) => {
    const userRole = getUserRoleByStudy(user, studyID)
    const isSponsorOrResearcher = userRole === USER_ROLES_MAP.sponsor || userRole === USER_ROLES_MAP.researcher
    const isAuditor = userRole === USER_ROLES_MAP.auditor
    const isSupport = userRole === USER_ROLES_MAP.supportAgent
    const isEnrollerOrSupport = checkIsEnrollerOrSupport(studyID)
    const mainStudyPage = getMainStudyPage(studyID)
    let historyPath = path || mainStudyPage

    switch (key) {
      case TOOLTIP_MENU_MAP.medicationAdherence:
        if (isSponsorOrResearcher || isAuditor || isSupport) historyPath = mainStudyPage
        break
      case TOOLTIP_MENU_MAP.clinro:
        if (isEnrollerOrSupport) historyPath = mainStudyPage
        break
      default:
        historyPath = path || mainStudyPage
    }

    return (
      <Fragment key={key}>
        <div
          className='icon-name'
          onClick={event => {
            event.stopPropagation()
            browserHistory.push(`studies/${studyID}/${historyPath}`)
          }}>
          <i className={icon} />
          <div className='hover-text'>{`${extra || ''} ${text}`}</div>
        </div>
      </Fragment>
    )
  }

  const checkIsFeatureSelected = feature => {
    return !!selectedFilterFeatures.find(item => item.value === feature)
  }

  const getSearchedList = () => {
    if (!searchTerm) return studies
    const regex = getRegexFromTerm(searchTerm)
    return studies.filter(row => {
      let keyMatches = false
      studyKeys.forEach(studyKey => {
        const value = `${row[studyKey]}`
        if (value.match(regex)) {
          keyMatches = true
        }
      })
      return keyMatches
    })
  }

  const getFilteredList = list => {
    if (selectedFilterFeatures.length) {
      const filteredList = list.filter(study => {
        let found = false
        if (checkIsFeatureSelected(STUDY_FEATURE_MAP.caseManagement)) {
          found = found || !!study?.config?.case_management
        }
        if (checkIsFeatureSelected(STUDY_FEATURE_MAP.geofencing)) {
          found = found || study?.modules.includes('geofencing')
        }
        if (checkIsFeatureSelected(STUDY_FEATURE_MAP.clinro)) {
          found = found || !!study?.config?.clinro
        }
        if (checkIsFeatureSelected(STUDY_FEATURE_MAP.medicationAdherence)) {
          found = found || !!study?.config?.medication_adherence
        }
        if (checkIsFeatureSelected(STUDY_FEATURE_MAP.superGems)) {
          found = found || !!study?.config?.super_gems
        }
        if (checkIsFeatureSelected(STUDY_FEATURE_MAP.testStudy)) {
          found = found || !!study?.is_test
        }
        if (checkIsFeatureSelected(STUDY_FEATURE_MAP.emailless)) {
          found = found || checkIsEmailless(study)
        }
        if (checkIsFeatureSelected(STUDY_FEATURE_MAP.econsent)) {
          found = found || !!study?.consent_required
        }
        return found
      })
      return filteredList
    }
    return list
  }

  const getSortedList = list => {
    const sortedList = [...list].sort((a, b) => {
      if (sortingOption === STUDY_SORTING_OPTIONS.studyCreateDate) {
        const firstDate = new Date(a.created_on)
        const secondDate = new Date(b.created_on)

        if (firstDate < secondDate) {
          return ascendingSortingOrder ? -1 : 1
        }
        if (firstDate > secondDate) {
          return ascendingSortingOrder ? 1 : -1
        }
        return 0
      }
      if (sortingOption === STUDY_SORTING_OPTIONS.studyName) {
        return ascendingSortingOrder ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
      }
    })
    return sortedList
  }

  const getProcessedList = () => {
    const searchedList = getSearchedList()
    const filteredList = getFilteredList(searchedList)
    const sortedList = getSortedList(filteredList)

    return sortedList
  }

  const getPaginatedList = () => {
    const processedList = getProcessedList()

    if (currentPage === 1) {
      return processedList.slice(0, MAX_STUDIES_ON_PAGE)
    }

    return processedList.slice(MAX_STUDIES_ON_PAGE * (currentPage - 1), MAX_STUDIES_ON_PAGE * currentPage)
  }

  const getTotalPages = () => {
    const processedList = getProcessedList()
    const fullyFilledPages = parseInt(processedList.length / MAX_STUDIES_ON_PAGE)
    const totalPages = processedList.length % MAX_STUDIES_ON_PAGE === 0 ? fullyFilledPages : fullyFilledPages + 1
    return totalPages
  }

  const updatePersistedPaginationSettings = ({ page, features, option, order }) => {
    persistStudyPaginationSettings(user?.id, {
      currentPage: page || currentPage,
      selectedFilterFeatures: features || selectedFilterFeatures,
      sortingOption: option || sortingOption,
      ascendingSortingOrder: order || ascendingSortingOrder,
    })
  }

  const updateCurrentPage = page => {
    setCurrentPage(page)
    updatePersistedPaginationSettings({ page })
  }

  const updateSelectedFilterFeatures = features => {
    setSelectedFilterFeatures(features)

    updateCurrentPage(1)

    updatePersistedPaginationSettings({ features })
  }

  const clearSelectedFilterFeatures = () => {
    setSelectedFilterFeatures([])
    updatePersistedPaginationSettings({ features: [] })
  }

  const removeSelectedFilterFeature = feature => {
    const filteredFeatures = selectedFilterFeatures.filter(item => item.value !== feature.value)
    updateSelectedFilterFeatures(filteredFeatures)
    updatePersistedPaginationSettings({ features: filteredFeatures })
  }

  const updateSortingOption = option => {
    setSortingOption(option)
    updatePersistedPaginationSettings({ option })
  }

  const updateAscendingSortingOrder = order => {
    setAscendingSortingOrder(order)
    updatePersistedPaginationSettings({ order })
  }

  const displayingList = getPaginatedList()

  return (
    <div className='studies page'>
      <div className='flexed-header'>
        <h3>All Studies</h3>
        {allowCreateStudy && (
          <Button
            id='add-new-study-top'
            content='Add Study'
            onClick={() => {
              resetStudyData()
              browserHistory.push(CREATE_STUDY_FULL_URLS.type)
            }}
          />
        )}
      </div>
      <div className='studies-list-wrapper'>
        <div className='flexed center-aligned space-between-justified'>
          <SearchInput id='search-study-input' useDebounce performExternalUpdate={() => updateCurrentPage(1)} />
          <div className='flexed sorting-filter-section'>
            <StudySorting
              sortingOption={sortingOption}
              updateAscendingSortingOrder={updateAscendingSortingOrder}
              ascendingSortingOrder={ascendingSortingOrder}
              updateSortingOption={updateSortingOption}
            />

            <StudyFeatureFilter
              selectedFilterFeatures={selectedFilterFeatures}
              setSelectedFilterFeatures={updateSelectedFilterFeatures}
              clearSelectedFilterFeatures={clearSelectedFilterFeatures}
            />
          </div>
        </div>
        {selectedFilterFeatures.length ? (
          <div className='filter-feature-list'>
            {selectedFilterFeatures.map(feature => (
              <div
                key={feature.key}
                className='filter-feature-item'
                onClick={() => removeSelectedFilterFeature(feature)}>
                {feature.text}
                <i className='fas fa-times' />
              </div>
            ))}
          </div>
        ) : null}
        <div className='studies-list'>
          {!!displayingList.length ? (
            displayingList.map(study => {
              const { id, config, study_duration, modules } = study
              const permissions = {
                1: 'Root',
                2: 'DCH Admin',
                14: 'DCH PII',
              }
              const userRoleId = user?.permissions[id]?.role_id || null
              const hasWhiteLabelingEditPermissions = userRoleId && permissions[userRoleId]
              const tooltipMenuOptions = {
                caseManagement: !!config?.case_management,
                geofencing: modules.includes('geofencing'),
                clinro: !!config?.clinro,
                medicationAdherence: !!config?.medication_adherence,
                superGems: !!config?.super_gems,
              }
              const tooltips = TOOLTIP_MENU.filter(tooltip => !!tooltipMenuOptions[tooltip.key])
              tooltips.map(tooltip => {
                if (tooltip.extra) tooltip.extra = tooltipMenuOptions[tooltip.key]
              })
              const mainStudyPage = getMainStudyPage(id)
              const dateObj = getStudyLength(study_duration)
              const dateToString = Object.keys(dateObj)
                .reduce((accVal, currVal) => accVal.concat(`${dateObj[currVal]} ${currVal}, `), '')
                .slice(0, -2)

              const onUpload = payload => {
                addPreviewLogo({
                  ...payload,
                  studyId: id,
                })
              }

              return (
                <StudyCard
                  key={id}
                  study={study}
                  mainStudyPage={mainStudyPage}
                  hasWhiteLabelingEditPermissions={hasWhiteLabelingEditPermissions}
                  onUpload={onUpload}
                  uploadLogo={uploadLogo}
                  openModal={openModal}
                  deleteLogo={deleteLogo}
                  dateToString={dateToString}
                  tooltips={tooltips}
                  renderIcons={renderIcons}
                />
              )
            })
          ) : (
            <div className='no-results-section'>
              <img src={searchImg} />
              <div className='no-results-title'>{STRINGS.noResults}</div>
              <div className='no-results-subtitle'>{STRINGS.checkTheSpelling}</div>
            </div>
          )}
        </div>
        <StudiesPaginationPanel
          currentPage={currentPage}
          setCurrentPage={updateCurrentPage}
          totalPages={getTotalPages()}
        />
      </div>
    </div>
  )
}

StudiesList.propTypes = {
  studies: PropTypes.array,
  resetStudy: PropTypes.func,
  resetStudyData: PropTypes.func,
  deleteStudy: PropTypes.func,
  allowCreateStudy: PropTypes.bool,
  user: PropTypes.object,
  searchTerm: PropTypes.string,
}

export default StudiesList
