import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'

import ConsentBuilderContainer from '../NewConsentBuilder/containers/ConsentBuilderContainer'
import newConsentReducer, { fetchNewConsent } from '../NewConsentBuilder/modules/NewConsent'
import consents, { fetchConsents } from '../../../ConsentsDashboard/modules/Consents'

import instrumentReducer from '../../../../../Instruments/routes/Instrument/modules/Instrument'
import ptpsReducer from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'
import { fetchLocales } from '../../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'

export const injectConsentReducers = store => {
  injectReducers(store, {
    consents,
    instrumentReducer,
    newConsentReducer,
    participants: ptpsReducer,
  })
}

export default store => {
  return {
    path: ':consentId/:consentVersion/editconsent',
    getComponent(nextState, cb) {
      store.dispatch(setSubroute('editConsent'))
      require.ensure(
        [],
        () => {
          injectConsentReducers(store)
          const { studyID, consentId, consentVersion } = nextState.params
          const { currentStudy } = store.getState().study
          setPageTitle(`Edit Consent | Study ${studyID} | ${currentStudy.platform_name}`)
          store.dispatch(fetchConsents(studyID))
          store.dispatch(fetchNewConsent({ studyId: studyID, consentId, version: consentVersion }))
          store.dispatch(fetchLocales())
          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(UserHasScope(['xc'], ConsentBuilderContainer, false, studyID, null, currentStudy)),
            ),
          )
        },
        '/consents/editconsent',
      )
    },
  }
}
