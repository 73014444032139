import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import InstrumentNav from './InstrumentNav'
import '../stylesheets/instrumentView.scss'

const InstrumentView = props => {
  const { children, fetchClinro, params, type } = props

  useEffect(() => {
    const { studyID, instrumentID } = params
    if (type === INSTRUMENT_TYPE_MAP.clinro && !!instrumentID) {
      fetchClinro(studyID, instrumentID)
    }
  }, [type])
  return (
    <div className='instrument-view page'>
      <InstrumentNav {...props} />
      {children}
    </div>
  )
}

InstrumentView.propTypes = {
  children: PropTypes.node,
  fetchClinro: PropTypes.func,
  params: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
}

export default InstrumentView
