import { connect } from 'react-redux'
import { getUserScopeByStudy, getUserRoleIdByStudy } from 'utils/misc'
import { checkIsStudyAdmin } from 'utils/userUtils'
import { USER_ROLE_ID_MAP } from 'utils/constants'

import StudyDashboardPage from '../components/StudyDashboardPage'
import { actions } from '../modules/StudyDashboard'

const mapState = state => {
  const { study, studyDashboard, loading, user } = state
  const { currentStudy, insightsLowFunctionalityMode } = study
  const studyId = study.currentStudy.id
  const studyRoleId = getUserRoleIdByStudy(user, studyId)
  const userScope = new Set(getUserScopeByStudy(user, currentStudy))
  return {
    ...studyDashboard,
    insights: currentStudy.config?.insights,
    loading: loading.general,
    loadSisenseDashboards: loading.sisense,
    hasReadParticipantPermission: userScope.has('rp'),
    hasEditInsightsPermission: userScope.has('wpi') || userScope.has('rpi'),
    insightsLowFunctionalityMode,
    hasReadInstrumentsPermission: userScope.has('ra'),
    isEnroller: studyRoleId === USER_ROLE_ID_MAP.enroller,
    isStudyAdmin: checkIsStudyAdmin(studyRoleId),
    studyRoleId,
  }
}

export default connect(mapState, actions)(StudyDashboardPage)
