import { injectReducer } from 'store/reducers'
import { authActions } from 'store/user'
import ActivateUserContainer from './containers/ActivateUserContainer'
import activateUserReducer, { actions } from './modules/ActivateUser'

// Sync route definition
export default store => ({
  path: 'activate',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducer(store, {
          key: 'activateUser',
          activateUserReducer,
        })
        store.dispatch(authActions.logout())
        const token = store.getState().location.search.split('?token=')[1]
        store.dispatch(actions.activateUser(token)).then(() => {
          cb(null, ActivateUserContainer)
        })
      },
      'activate',
    )
  },
})
