import React from 'react'
import { DATE_FORMAT_MAP, DEPLOYMENT_KEYS, INSTRUMENT_TYPE_MAP, LOGICAL_OPERATOR_CHART } from 'utils/constants'
import moment from 'moment'
import { fromUTCMidnightSeconds, toSecondsFromTimeString } from 'utils/time'
import { pluralize } from 'utils/misc'

// Property Fields

export const PROPERTIES = {
  display_name: {
    text: 'Display Name',
    type: 'input',
    description: 'The lead-in, or text, display to participants before they begin the participant instrument.',
    required: true,
  },
  display_icon: {
    text: 'Display Icon',
    description: 'The icon that will be displayed when instrument is selected.',
    required: true,
  },
  display_location: {
    text: 'Display Location',
    description: 'The location where instrument will be displayed.',
    required: true,
  },
  gamification_points: {
    text: 'Gamification Points',
    type: 'number',
    description: 'How many in-app points will this instrument be worth to the participant upon completion.',
    required: true,
  },
  est_duration: {
    text: 'Duration',
    description: 'The estimated length of the participant instrument.',
    type: 'radio',
    options: [
      {
        key: 'short',
        value: 'short',
        text: 'Short',
      },
      {
        key: 'medium',
        value: 'medium',
        text: 'Medium',
      },
      {
        key: 'long',
        value: 'long',
        text: 'Long',
      },
    ],
    required: true,
  },
  is_optional: {
    text: 'Instrument Type',
    type: 'radio',
    options: [
      {
        key: 'regular',
        value: false,
        text: (
          <>
            <p>Regular Instrument</p>
            <p>
              These instruments allow participants to advance on the map upon completion, and so are usually essential
              for chapter completion.
            </p>
          </>
        ),
        weight: 1.0,
      },
      {
        key: 'bonus',
        value: true,
        text: (
          <>
            <p>Bonus Instrument</p>
            <p>
              These instruments only grant participants gems (gamification points) upon completion, so they are
              non-essential for chapter completion.
            </p>
          </>
        ),
        weight: 0.0,
      },
    ],
    required: true,
    noTooltip: true,
  },
  priority: {
    text: 'Priority (For high priority, use large number)',
    type: 'number',
    description:
      'How important is this instrument for our data set? ' +
      'High priority instruments may also be associated with high levels of gamification points.',
    required: true,
  },
  attribution_text: {
    text: 'Text for Attribution',
    type: 'textarea',
    description: `When we acquire a license there is a good chance that they will want us to give a
      citation of the source, or their organization, somewhere in our data. This would be where we put that.`,
  },
  is_urgent: {
    text: 'Data Collection Behavior',
    type: 'checkbox',
    label: 'Urgent data upload',
    bodyText:
      'Participants must have internet connection to submit this instrument, so that data can be sent right away.  Recommended only for instruments that require immediate action, e.g. prompts an action upon instrument completion such as a virtual visit.',
    noTooltip: true,
  },
  start: {
    text: 'Start',
  },
  finish: {
    text: 'Finish',
  },
  repeat: {
    text: 'Repeat',
  },
  visible: {
    text: 'Visible',
  },
  participants: {
    text: 'Sent to',
  },
}

export const ICONS = [
  {
    key: 'Emotion',
    text: 'Emotion',
    icon: require('./assets/instrument_icons/Emotion.png'),
  },
  {
    key: 'Health',
    text: 'Health',
    icon: require('./assets/instrument_icons/Health.png'),
  },
  {
    key: 'Sleep',
    text: 'Sleep',
    icon: require('./assets/instrument_icons/Sleep.png'),
  },
  {
    key: 'Celebrate',
    text: 'Celebrate',
    icon: require('./assets/instrument_icons/Celebrate.png'),
  },
  {
    key: 'Social',
    text: 'Social',
    icon: require('./assets/instrument_icons/Social.png'),
  },
  {
    key: 'Personality',
    text: 'Personality',
    icon: require('./assets/instrument_icons/Personality.png'),
  },
  {
    key: 'General',
    text: 'General',
    icon: require('./assets/instrument_icons/General.png'),
  },
  {
    key: 'Clinical Card',
    text: 'Clinical',
    icon: require('./assets/instrument_icons/Clinical.png'),
  },
  {
    key: 'Cognitive',
    text: 'Cognitive',
    icon: require('./assets/instrument_icons/Cognitive.png'),
  },
  {
    key: 'Environment',
    text: 'Environment',
    icon: require('./assets/instrument_icons/Environment.png'),
  },
  {
    key: 'Community',
    text: 'Community',
    icon: require('./assets/instrument_icons/Community.png'),
  },
  {
    key: 'Development',
    text: 'Development',
    icon: require('./assets/instrument_icons/Development.png'),
  },
  {
    key: 'Financial',
    text: 'Financial',
    icon: require('./assets/instrument_icons/Financial.png'),
  },
  {
    key: 'Psychiatry',
    text: 'Psychiatry',
    icon: require('./assets/instrument_icons/Psychiatry.png'),
  },
  {
    key: 'Decision Making',
    text: 'Decision Making',
    icon: require('./assets/instrument_icons/Decision Making.png'),
  },
  {
    key: 'Motivation',
    text: 'Motivation',
    icon: require('./assets/instrument_icons/Motivation.png'),
  },
  {
    key: 'Behavior',
    text: 'Behavior',
    icon: require('./assets/instrument_icons/Behavior.png'),
  },
  {
    key: 'Life History',
    text: 'Life History',
    icon: require('./assets/instrument_icons/Life History.png'),
  },
]

export const _getDefaultSchedule = () => {
  return {
    cohort: {
      type: 'all_users',
    },
    expire: {
      never: null,
    },
    deploy: {
      now: null,
    },
  }
}

const INSTRUMENT_TYPES_WITH_DEFAULT_DISPLAY = [
  INSTRUMENT_TYPE_MAP.survey,
  INSTRUMENT_TYPE_MAP.clinro,
  INSTRUMENT_TYPE_MAP.diary,
]

export const _getDefaultMetadata = (instrumentType, displayName) => {
  const isDiary = instrumentType === INSTRUMENT_TYPE_MAP.diary
  const isConsent = instrumentType === INSTRUMENT_TYPE_MAP.consent
  const schedule = _getDefaultSchedule()
  /**
   * For now we are hard-coding the schedule of diaries to be deployed a day
   * after participant first login and be persistent. This will be made configurable
   * in the future.
   */
  if (isDiary) {
    schedule.recurring = {
      interval: 1,
      interval_type: 'days',
      recurrences: 2,
      scheme: 'n-days',
      visible_days: 1,
    }
    schedule.deploy = {
      first_login: {
        interval: 1,
        interval_type: 'days',
      },
      time_of_day: '09:00',
    }
  }

  const metadata = {
    schedule,
    gamification_points: 10,
    visibility: 'all_day',
    visibilityRanges: [{ start: null, end: null }],
    priority: 5,
    est_duration: 'medium',
    is_urgent: isConsent,
    is_optional: false,
  }
  if (INSTRUMENT_TYPES_WITH_DEFAULT_DISPLAY.includes(instrumentType)) {
    metadata.display_icon = 'General'
    metadata.display_name = displayName
  } else if (instrumentType === INSTRUMENT_TYPE_MAP.article) {
    metadata.display_icon = 'Behavior'
    metadata.display_name = displayName
  }
  return metadata
}

// Clear old deployment instructions based on cohorts/sites to prevent invalid
// settings when importing survey or clinro JSONs across studies
export const cleanUploadedJSON = (data, participants, user, studyId) => {
  const { metadata } = data
  const { schedule, conditions } = metadata
  const { cohort } = schedule
  const dataClone = { ...data }

  const COHORT_TYPES_TO_REMOVE = ['sites', 'study_group']
  if (COHORT_TYPES_TO_REMOVE.includes(schedule.cohort.type)) {
    let resetCohorts = false
    if (cohort.type === 'sites') {
      const sitesInStudy = user.permissions[studyId]?.sites || {}
      const sitesArr = Object.keys(sitesInStudy).map(id => Number(id))
      const deploymentSites = cohort?.filter?.site_ids || []
      const filteredDeploymentSites = deploymentSites.filter(id => sitesArr.includes(id))
      if (filteredDeploymentSites.length === 0) {
        resetCohorts = true
      } else {
        dataClone.metadata.schedule.cohort.filter.site_ids = filteredDeploymentSites
      }
    }
    if (cohort.type === 'study_group') {
      const cohortsInStudy = participants.cohortMap || {}
      const cohortsArr = Object.keys(cohortsInStudy).map(id => Number(id))
      const deploymentCohorts = cohort?.filter?.include || []
      const filteredDeploymentCohorts = deploymentCohorts.filter(id => cohortsArr.includes(id))
      if (filteredDeploymentCohorts.length === 0) {
        resetCohorts = true
      } else {
        dataClone.metadata.schedule.cohort.filter.include = filteredDeploymentCohorts
      }
    }
    if (resetCohorts) {
      dataClone.metadata.schedule.cohort = _getDefaultSchedule().cohort
    }
  }

  // Remove condition IDs
  if (conditions) {
    dataClone.metadata.conditions = []
  }

  return dataClone
}

function _standardizeFirstLogin(firstLogin) {
  if (firstLogin.interval_type === 'weeks') {
    return firstLogin.interval * 7
  }
  return firstLogin.interval
}

export const getScheduleText = (
  { article_type: articleType, visibilityRanges, schedule, deploy_on_confirm: deployOnConfirm },
  deployed,
) => {
  let text
  let sortValue
  if (!schedule) return { text: 'Not Yet Scheduled', sortValue: -1 }
  const { deploy } = schedule
  if ('now' in deploy) {
    const date = deployed ? moment(deployed) : null
    text = date ? date.format(DATE_FORMAT_MAP.mainWithDateTime) : 'Immediately after deployment'
    sortValue = deployed ? date.valueOf() : 0
  } else if ('first_login' in deploy) {
    _standardizeFirstLogin(deploy.first_login)
    text = `${pluralize(deploy.first_login.interval, 'day', 'days')} after login`
    sortValue = deploy.first_login.interval
  } else if ('absolute' in deploy) {
    const date = fromUTCMidnightSeconds(deploy.absolute)
    if (visibilityRanges[0].start !== null) {
      date.add(
        moment(visibilityRanges[0].start)
          .utc()
          .hours(),
        'hours',
      )
    }
    text = date.format(DATE_FORMAT_MAP.mainWithDateTime)
    sortValue = deploy.absolute

    if ('time_of_day' in deploy) {
      const timeAsMoment = moment(deploy.time_of_day, 'HH:mm')
      const timeString = timeAsMoment.format('h:mm A')
      text = `${date.format(DATE_FORMAT_MAP.main)} ${timeString}`
      sortValue = deploy.absolute + toSecondsFromTimeString(timeAsMoment)
    }
  } else if ('chain_deployment_info' in schedule) {
    const { chain_deployment_info } = schedule
    if (DEPLOYMENT_KEYS.chainDeployByVisit in chain_deployment_info) {
      const isMissedArticleType = articleType === 'missed_visit_follow_up'
      const visitType = isMissedArticleType ? 'missed visit' : `${deployOnConfirm ? 'confirmed ' : ''}visit`
      const { schedule_visit_id, visit_id } = chain_deployment_info
      const { days_offset } = deploy

      if (!days_offset) {
        // text = 'On day of visit'
        text = `On day of ${visitType}`
      } else if (days_offset) {
        text = `${pluralize(Math.abs(days_offset), 'day', 'days')} ${days_offset > 0 ? 'after' : 'before'} ${visitType}`
      }
      return { text, sortValue: -1, visit_id, schedule_visit_id }
    }

    const { cohort } = chain_deployment_info
    const { chain_deploy_offset } = deploy
    const scheduledTime = chain_deploy_offset === 0 ? 'Immediately' : pluralize(chain_deploy_offset, 'day', 'days')
    text = `${scheduledTime} after first instrument is complete`
    if (cohort) {
      const { filter } = cohort
      const { instrument_score, logical_operator, score_name } = filter
      text = `${scheduledTime} after first inst is complete and its score (${score_name}) is ${LOGICAL_OPERATOR_CHART[logical_operator]} ${instrument_score}`
    }
    sortValue = -1
  } else {
    text = '---'
    sortValue = -1
  }
  return { text, sortValue }
}
