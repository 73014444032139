import React from 'react'
import PropTypes from 'prop-types'
import { setPageTitle } from 'utils/misc'
import { browserHistory } from 'react-router'
import { Button } from 'components/UIElements'
import AdvancedOptionsHOC from 'utils/hoc/AdvancedOptionsHOC'
import { buttonContentOnSendState } from 'utils/helpers'
import '../stylesheets/announcement.scss'
import DeliverySection from './DeliverySection'
import MessageSection from './MessageSection'
import RecipientSection from './RecipientSection'
import ReadStatus from './ReadStatus'

class AnnouncementPage extends React.Component {
  constructor(props) {
    super(props)
    const { is_sms, route } = this.props
    this.state = {
      isSMS: is_sms || route.path.includes('sms'),
    }
  }

  componentDidMount() {
    const { studyID, setNavBarProps, setCommunicationType } = this.props
    const { isSMS } = this.state
    setCommunicationType(isSMS ? 'sms' : 'announcements')
    setNavBarProps({
      close: `/studies/${studyID}/communication`,
      closeText: 'Close',
    })
  }

  componentWillUnmount() {
    const { setNavBarProps, resetAnnouncement } = this.props
    resetAnnouncement()
    setNavBarProps(null)
  }

  onCancel = () => {
    const { params } = this.props
    const { studyID } = params
    browserHistory.push(`/studies/${studyID}/communication`)
  }

  render() {
    const {
      currentStudy,
      deploySending,
      deploySent,
      isCreate,
      readOnly,
      saveAndDeploy,
      saveAnnouncement,
      saveSending,
      saveSent,
      sendDisabled,
      studyLock,
      translations,
      advancedOptions,
      showAdvancedOption,
    } = this.props

    const { isSMS } = this.state
    const communicationType = isSMS ? 'SMS' : 'Announcement'
    const sendStateClassName = sendDisabled ? 'send-state' : '' // for cursor wait during sendState

    // This variable allows more translations to be added and saved to an announcement.
    let disabledEdit = readOnly
    if (!isSMS && translations) disabledEdit = !translations

    const sendButtonContent = {
      content: 'Send',
      sendingContent: 'Sending',
      sentContent: '...Sent!',
    }
    const saveButtonContent = {
      content: 'Save',
      sendingContent: 'Saving',
      sentContent: '...Saved!',
    }

    const pageTitle = isCreate ? `New` : readOnly ? `View` : `Edit`

    setPageTitle(`${pageTitle} ${communicationType} | Study ${currentStudy.id} | ${currentStudy.name}`)

    return (
      <div className={`announcement page ${sendStateClassName}`}>
        <h4 className='navbar-header'>
          {isCreate ? `New ${communicationType}` : readOnly ? `View ${communicationType}` : `Edit ${communicationType}`}
        </h4>
        {readOnly && !isSMS && <ReadStatus {...this.props} />}
        <RecipientSection {...this.props} isSMS={isSMS} disabled={readOnly || sendDisabled || studyLock} />
        <MessageSection {...this.props} isSMS={isSMS} disabled={readOnly || sendDisabled || studyLock} />
        <h4>Delivery Details</h4>
        <DeliverySection {...this.props} isSMS={isSMS} disabled={readOnly || sendDisabled || studyLock} />
        {!disabledEdit && (
          <div className='button-list'>
            <Button
              onClick={() => !studyLock && this.onCancel()}
              grey
              id='cancel'
              content='Cancel'
              disabled={sendDisabled || studyLock}
            />
            {!disabledEdit && (
              <Button
                id='save'
                onClick={() =>
                  !studyLock && saveAnnouncement(null, !isSMS, showAdvancedOption ? advancedOptions : null)
                }
                grey={!disabledEdit && !readOnly}
                content={buttonContentOnSendState(saveButtonContent, saveSending, saveSent)}
                loading={saveSending}
                disabled={sendDisabled || studyLock}
              />
            )}
            {!readOnly && (
              <Button
                id='send'
                className='deploy-btn'
                onClick={() => !studyLock && saveAndDeploy(!isSMS, showAdvancedOption ? advancedOptions : null)}
                content={buttonContentOnSendState(sendButtonContent, deploySending, deploySent)}
                loading={deploySending}
                disabled={sendDisabled || studyLock}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

AnnouncementPage.propTypes = {
  advancedOptions: PropTypes.objectOf(PropTypes.object),
  baseSiteID: PropTypes.number,
  currentStudy: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  deploySending: PropTypes.bool,
  deploySent: PropTypes.bool,
  studyID: PropTypes.number,
  route: PropTypes.shape({
    path: PropTypes.string,
  }),
  params: PropTypes.shape({
    siteID: PropTypes.string,
    studyID: PropTypes.string,
  }),
  isCreate: PropTypes.bool,
  readOnly: PropTypes.bool,
  saveAnnouncement: PropTypes.func,
  saveAndDeploy: PropTypes.func,
  saveSending: PropTypes.bool,
  saveSent: PropTypes.bool,
  sendDisabled: PropTypes.bool,
  setCommunicationType: PropTypes.func,
  setNavBarProps: PropTypes.func,
  showAdvancedOption: PropTypes.bool,
  studyLock: PropTypes.bool,
  is_sms: PropTypes.bool,
  resetAnnouncement: PropTypes.func,
  translations: PropTypes.objectOf(PropTypes.object),
}

export default AdvancedOptionsHOC(AnnouncementPage)
