import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { pluralize } from 'utils/misc'
import { DATE_FORMAT_MAP } from 'utils/constants'

const FirstLogin = ({ daysElapsed, firstLogin }) => {
  const today = moment().format(DATE_FORMAT_MAP.main)
  const isToday = firstLogin ? firstLogin.format(DATE_FORMAT_MAP.main) === today : false
  const firstLoginString = firstLogin ? firstLogin.format(DATE_FORMAT_MAP.main) : 'Never Logged In'
  return (
    <div className='duration-labels first-login'>
      <p>
        <span>FIRST LOGIN</span>
        {firstLogin && isToday && (
          <span>
            <b>Today</b>
            {` at ${firstLogin.format('h:mma')}`}
          </span>
        )}
        {firstLogin && !isToday && (
          <span className='days'>
            <b>{daysElapsed}</b>
            {pluralize(daysElapsed, ' day ago', ' days ago', false)}
          </span>
        )}
      </p>
      <p>{firstLoginString}</p>
    </div>
  )
}

FirstLogin.propTypes = {
  daysElapsed: PropTypes.number,
}

export default FirstLogin
