import { setSubroute } from 'store/navbar'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import templateVisitReducer, { visitActions } from '../VisitPage/modules/TemplateVisit'
import { actions as visitScheduleActions } from '../../../VisitSchedulesPage/modules/VisitSchedules'

import VisitContainer from '../VisitPage/container/VisitContainer'

export const injectVisitReducers = store => {
  injectReducers(store, {
    templateVisitReducer,
  })
}

// Sync route definition
export default store => ({
  path: 'visits/create',
  exact: true,
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        const { studyID } = nextState.params
        injectVisitReducers(store)
        store.dispatch(setSubroute('visits'))
        store.dispatch(visitActions.initPage())
        store.dispatch(visitScheduleActions.fetchVisitSchedules(studyID))
        /*  Webpack - use require callback to define
        dependencies for bundling   */

        const { study } = store.getState()
        const { currentStudy } = study
        setPageTitle(`Create New Visit | Study ${studyID} | ${currentStudy.platform_name}`)

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'visit-schedules')(UserHasScope(['wpv'], VisitContainer, true, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'visit-templates',
    )
  },
})
