import React from 'react'
import { Checkbox, Input, Popup, Radio, Textarea } from 'components/UIElements'
import { formatStringForID } from 'utils/misc'

const _getInputType = (error, fieldInfo, isClinro, onUpdate, value) => {
  const { text } = fieldInfo
  const id = formatStringForID(text)
  switch (fieldInfo.type) {
    case 'input':
    case 'number':
      return (
        <Input
          id={id}
          className={`${fieldInfo.type === 'number' ? 'num-input' : ''}${error ? ' has-error' : ''}`}
          value={value}
          type={fieldInfo.type === 'number' ? 'number' : undefined}
          onChange={onUpdate}
          min={0}
          step={1}
        />
      )
    case 'radio':
      return fieldInfo.options.map(option => {
        return (
          <Radio
            id={`${id}-${option.key}`}
            content={option.text}
            onClick={() => onUpdate(option.value)}
            key={option.key}
            selected={value === option.value}
          />
        )
      })
    case 'textarea':
      return <Textarea id={id} value={value} onChange={onUpdate} />
    case 'checkbox': {
      return (
        <div className='checkbox-wrapper'>
          <Checkbox
            id={id}
            label={fieldInfo.label}
            checked={value}
            onClick={() => {
              onUpdate(!value)
            }}
          />
          <p>{fieldInfo.bodyText}</p>
        </div>
      )
    }
    default:
      return null
  }
}
const PropertyField = ({ error, fieldInfo, metadataKey, isClinro, onUpdate, value }) => {
  return (
    <div className='property-field'>
      <TooltipLabel {...fieldInfo} error={error} />
      {_getInputType(error, fieldInfo, isClinro, onUpdate, value)}
    </div>
  )
}

export const TooltipLabel = ({ error, text, description, type, noTooltip }) => {
  return (
    <div className={`flexed-header${type === 'number' ? ' num' : ''}`}>
      <span className='label-small' style={{ color: error ? 'red' : undefined }}>
        {text}
      </span>
      {!noTooltip && (
        <Popup position='right' trigger={<i className='fas fa-info-circle' />}>
          <p>{description}</p>
        </Popup>
      )}
    </div>
  )
}

export default PropertyField
