import React from 'react'
import getUserRolesList from 'utils/hooks/useRolesList'

const UserRadios = ({ renderRadio }) => {
  const availableUserRoles = getUserRolesList()
  return (
    <div
      className='role-radios'
      style={{ gridTemplateRows: `repeat(${Math.ceil(availableUserRoles.length / 2)}, 20px)` }}>
      {availableUserRoles.map(item => renderRadio(item))}
    </div>
  )
}

export default UserRadios
