import React, { useState } from 'react'
import { NumberInputDropdown, Dropdown } from 'components/UIElements'

export const REMINDER_PERIOD_OPTIONS = [
  { key: 'days', text: 'day(s)' },
  { key: 'weeks', text: 'week(s)' },
]

function VisitReminder(props) {
  const { reminder, updateReminder, disabled } = props

  const [selectedVisitReminderPeriod, setSelectedVisitReminderPeriod] = useState(REMINDER_PERIOD_OPTIONS[0].key)

  return (
    <div className='reminder-row'>
      <div className={`inputs ${disabled ? 'disabled' : ''}`}>
        <NumberInputDropdown
          value={reminder.value}
          options={[]}
          onChange={item => updateReminder(reminder.id, item, reminder.period)}
        />
        <Dropdown
          options={REMINDER_PERIOD_OPTIONS}
          onSelect={item => {
            setSelectedVisitReminderPeriod(item.key)
            updateReminder(reminder.id, reminder.value, item.key)
          }}
          selected={selectedVisitReminderPeriod}
        />
      </div>
      <span>before the scheduled visit day.</span>
    </div>
  )
}

VisitReminder.propTypes = {}

export default VisitReminder
