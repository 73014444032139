import React from 'react'
import { connect } from 'react-redux'
import PayoutForm from '../components/AdvancedPaymentOptions/PayoutForm'
import { actions as studyActions } from '../modules/CreateStudy'

const mapState = (state, ownProps) => {
  const { loading } = state
  const { credentials: credentialsLoading } = loading

  return {
    ...ownProps,
    credentialsLoading,
  }
}

const mapDispatch = { ...studyActions }

export default connect(mapState, mapDispatch)(props => <PayoutForm {...props} />)
