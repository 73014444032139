import { connect } from 'react-redux'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { getBaseSiteIdFromStudy } from 'utils/misc'
import Loader from 'components/D3Loader'
import { metadataActions } from '../../../modules/Instrument'
import ScoringPage from '../components/ScoringPage'
import { scoringActions as clinroScoringActions } from '../../EditClinro/modules/Clinro'
import { scoringActions as surveyScoringActions } from '../../EditSurvey/modules/Survey'
import { scoringActions as diaryScoringActions } from '../../EditDiary/modules/Diary'

const mapStateToProps = ({ clinroEditor, diaryEditor, instrumentReducer, study, surveyEditor, loading, user }) => {
  const { clinro, clinroErrors } = clinroEditor
  const { survey, surveyErrors } = surveyEditor
  const { diary, diaryErrors } = diaryEditor
  const { type } = instrumentReducer.instrument
  const isClinro = type === INSTRUMENT_TYPE_MAP.clinro
  const isDiary = type === INSTRUMENT_TYPE_MAP.diary
  return {
    clinro,
    clinroErrors,
    diary, 
    diaryErrors,
    errors: instrumentReducer.errors,
    isClinro,
    isDiary,
    studyID: study.currentStudy.id,
    siteID: getBaseSiteIdFromStudy(study),
    survey,
    surveyErrors,
    loading: loading.general,
  }
}

const mapDispatchToProps = {
  ...clinroScoringActions,
  ...metadataActions,
  ...surveyScoringActions,
  ...diaryScoringActions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.loading) {
    return Loader(props)
  }
  return ScoringPage(props)
})
