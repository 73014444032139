import React from 'react'
import PropTypes from 'prop-types'
import { MODAL_CLASSES_MAP } from 'utils/constants'
import { Button } from 'components/UIElements'
import DonationInfoForm from '../../containers/DonationFormContainer'

const ConfirmedDonation = ({ detailed_description, summary, asset_download_link }) => (
  <div className='flexed space-between start-aligned column'>
    <div className='donation-pic-summary flexed start-justified start-aligned'>
      <img src={asset_download_link} alt='confirmed-donation-logo' />
      <h6 className='fw-weight'>{summary}</h6>
    </div>
    <p>{detailed_description}</p>
  </div>
)

const AddDonationOrgComponent = props => {
  const { disabled, openModal, saveAttempted, study } = props
  const { config = {} } = study
  const { super_gems = {} } = config
  const { donations = [] } = super_gems
  const donationInfo = donations[0]
  const { detailed_description, summary, asset_download_link } = donationInfo
  const hasConfirmedDetails = !!(detailed_description && summary && asset_download_link)

  return (
    <>
      {hasConfirmedDetails ? (
        <Button
          className='selected-donation'
          disabled={disabled}
          noStyling
          onClick={() => {
            openModal({
              className: MODAL_CLASSES_MAP.donation,
              content: <DonationInfoForm />,
              noButtons: true,
            })
          }}>
          <ConfirmedDonation {...donationInfo} />
        </Button>
      ) : (
        <Button
          className='payment-donation add-account'
          content='Add Donation Organization or Campaign'
          disabled={disabled}
          errorText='Add donation information'
          hasError={saveAttempted && !hasConfirmedDetails}
          iconBefore='fas fa-plus'
          noStyling
          onClick={() => {
            openModal({
              className: MODAL_CLASSES_MAP.donation,
              content: <DonationInfoForm />,
              noButtons: true,
            })
          }}
        />
      )}
    </>
  )
}

ConfirmedDonation.propTypes = {
  asset_download_link: PropTypes.string,
  detailed_description: PropTypes.string,
  summary: PropTypes.string,
}
AddDonationOrgComponent.propTypes = {
  disabled: PropTypes.bool,
  saveAttempted: PropTypes.bool,
  openModal: PropTypes.func,
  study: PropTypes.shape({
    config: PropTypes.object,
  }),
}

export default AddDonationOrgComponent
