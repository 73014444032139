import React, { useEffect } from 'react'
import { Button } from 'components/UIElements'
import STRINGS from 'utils/strings'
import {
  MAX_PHOTO_LIMIT_MIN_VALUE,
  MAX_PHOTO_LIMIT_MAX_VALUE,
  FLAGSMITH_FEATURES,
  DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION,
} from 'utils/constants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'

const MaxPhotoLimitPanel = ({ maxPhotoLimit, onPlusClick, onMinusClick, onSetDefaultValue, study, userId }) => {
  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  useEffect(() => {
    if (!maxPhotoLimit) {
      onSetDefaultValue()
    }
  }, [maxPhotoLimit])

  return hasInstrumentUnification ? (
    <div className='max-photo-limit attribute panel'>
      <h5 className='label-small med-font'>{STRINGS.maximumPhotoLimit}</h5>
      <div className='max-photo-limit-choices'>
        <Button
          className='inline flexed'
          onClick={onMinusClick}
          circle
          disabled={maxPhotoLimit <= MAX_PHOTO_LIMIT_MIN_VALUE}
          id='minus-options'
          icon='fas fa-minus'
        />
        <p className='inline flexed'>{maxPhotoLimit}</p>
        <Button
          className='inline flexed'
          onClick={onPlusClick}
          circle
          disabled={maxPhotoLimit >= MAX_PHOTO_LIMIT_MAX_VALUE}
          id='plus-options'
          icon='fas fa-plus'
        />
      </div>
    </div>
  ) : null
}

export default MaxPhotoLimitPanel
