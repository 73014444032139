import React from 'react'
import PropTypes from 'prop-types'
import { Container, Checkbox, Button } from 'components/UIElements'
import { deepCompareJSON } from 'utils/object'
import { browserHistory } from 'react-router'
import { MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import ScheduleSection from './NotificationSchedule'
import ConditionsSection from './NotificationConditions'
import {
  CONDITIONAL_TYPE_MAP,
  CONDITIONAL_NOTIFICATION_TYPE_MAP,
} from '../../../NotificationsPage/components/NotificationTypes'
import '../stylesheets/conditionalNotification.scss'

class EditConditionalNotification extends React.Component {
  constructor(props) {
    super(props)
    const { params, settings } = this.props
    this.state = {
      ...settings,
      oldConditionalNotifications: {},
      conditionsToDeleteArr: [],
      enableToggled: false,
    }
    this.backURL = `/studies/${params.studyID}/notifications`
  }

  componentDidMount() {
    const { setNavBarProps, conditionalNotifications } = this.props
    setNavBarProps({
      back: this.backURL,
      close: this.backURL,
      backText: 'Notifications',
    })
    const oldNotySettings = JSON.parse(JSON.stringify(conditionalNotifications))
    this.setState({ oldConditionalNotifications: Object.freeze(oldNotySettings) })
  }

  componentDidUpdate(prevProps, prevState) {
    const { enabled } = this.state
    if (prevState.enabled !== enabled) this.setState({ enableToggled: true })
  }

  componentWillUnmount() {
    const { setNavBarProps } = this.props
    setNavBarProps(null)
  }

  addToDeletionList = conditionId => {
    const { conditionsToDeleteArr } = this.state
    if (conditionId) {
      const newConditionsToDelete = [...conditionsToDeleteArr, conditionId]
      this.setState({ conditionsToDeleteArr: newConditionsToDelete })
    }
  }

  onSave = () => {
    const { conditionalNotifications, params, saveConditions } = this.props
    const { enabled } = this.state
    const { conditionsToDeleteArr } = this.state
    const { medicationAdherence } = conditionalNotifications
    saveConditions({
      conditions: medicationAdherence,
      medNotyDisabled: !enabled,
      redirect: this.backURL,
      studyId: params.studyID,
      toDeleteArr: conditionsToDeleteArr,
    })
  }

  onToggle = () => {
    const { enabled } = this.state
    this.onUpdateNotification('enabled', !enabled)
  }

  onUpdateNotification = (key, value) => {
    this.setState({ [key]: value })
  }

  renderTrigger = () => {
    const { notificationType } = this.props
    return (
      <div className='trigger-section flexed start-justified'>
        <div>Trigger</div>
        {!!CONDITIONAL_TYPE_MAP[notificationType].infoText ? (
          <>
            <i className='fas fa-info-circle' />
            <div className='text-bubble'>{CONDITIONAL_TYPE_MAP[notificationType].infoText}</div>
          </>
        ) : null}
      </div>
    )
  }

  onCancelClick = noChanges => {
    const { openModal } = this.props
    if (noChanges) return browserHistory.push(this.backURL)
    openModal({
      content: MODAL_CONTENT_MAP.cancelChanges,
      confirmButton: MODAL_BUTTONS_MAP.yes,
      cancelButton: MODAL_BUTTONS_MAP.no,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        browserHistory.push(this.backURL)
      },
    })
  }

  render() {
    const {
      addMedNotificationConditionItem,
      deleteMedNotificationCondition,
      conditionalNotifications,
      notificationType,
      updateMedNotificationAction,
      updateMedNotificationScheduleTime,
      updateMedNotificationTrigger,
      studyLock,
    } = this.props
    const { enabled, enableToggled, oldConditionalNotifications } = this.state
    const typeInfo = CONDITIONAL_TYPE_MAP[notificationType]
    const isMedicalAdherence = notificationType === CONDITIONAL_NOTIFICATION_TYPE_MAP.medicationAdherence
    const scheduleProps = conditionalNotifications.medicationAdherence.conditions[0].schedule.recurrence_rule
    const saveDisabled = deepCompareJSON(conditionalNotifications, oldConditionalNotifications)
    const hasChanges = saveDisabled && !enableToggled
    return (
      <div className='edit-conditional-notification page'>
        <Container row className='flexed'>
          <h5>{`${typeInfo.text} Notification`}</h5>
          <Checkbox
            onClick={this.onToggle}
            toggle
            checked={enabled}
            label={enabled ? 'ON' : 'OFF'}
            disabled={studyLock}
          />
        </Container>
        {isMedicalAdherence && (
          <div className={`${enabled ? '' : 'disabled'}`}>
            <ScheduleSection scheduleProps={scheduleProps} updateScheduleTime={updateMedNotificationScheduleTime} />
            <ConditionsSection
              enabled={enabled}
              conditionalNotifications={conditionalNotifications}
              addToDeletionList={this.addToDeletionList}
              deleteMedNotificationCondition={deleteMedNotificationCondition}
              addMedNotificationConditionItem={addMedNotificationConditionItem}
              updateMedNotificationAction={updateMedNotificationAction}
              updateMedNotificationTrigger={updateMedNotificationTrigger}
            />
          </div>
        )}
        <div className='button-list'>
          <Button grey id='cancel' content='Cancel' onClick={() => this.onCancelClick(hasChanges)} />
          <Button disabled={hasChanges} id='save-changes' content='Save Changes' onClick={this.onSave} />
        </div>
      </div>
    )
  }
}

EditConditionalNotification.propTypes = {
  addMedNotificationConditionItem: PropTypes.func,
  conditionalNotifications: PropTypes.shape({
    medicationAdherence: PropTypes.shape({
      conditions: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
  deleteMedNotificationCondition: PropTypes.func,
  notificationType: PropTypes.string,
  openModal: PropTypes.func,
  params: PropTypes.shape({
    studyID: PropTypes.string,
    siteID: PropTypes.string,
  }),
  saveConditions: PropTypes.func,
  setNavBarProps: PropTypes.func,
  settings: PropTypes.shape({ enabled: PropTypes.bool }),
  updateMedNotificationAction: PropTypes.func,
  updateMedNotificationScheduleTime: PropTypes.func,
  updateMedNotificationTrigger: PropTypes.func,
  studyLock: PropTypes.bool,
}

export default EditConditionalNotification
