import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Input } from 'components/UIElements'
import { closeIcon } from 'assets/assets'
import { pluralize } from 'utils/misc'

const RELATIVE_TYPE_MAP = {
  succeeding: 'succeeding',
  preceding: 'preceding',
}

const RelativeDateRangeRow = props => {
  const { type = RELATIVE_TYPE_MAP.succeeding, item, itemId, preview, updateItem } = props
  const [expanded, setExpanded] = useState(false)
  const isSucceeding = type === RELATIVE_TYPE_MAP.succeeding

  const intervalKey = `${isSucceeding ? 'succeeding' : 'preceding'}_interval`
  const intervalTypeKey = `${isSucceeding ? 'succeeding' : 'preceding'}_interval_type`

  const itemWithDefaults = {
    ...item,
    input_validation: {
      relative: {
        preceding_interval: 1,
        preceding_interval_type: 'weeks',
        succeeding_interval: 1,
        succeeding_interval_type: 'weeks',
        reference: 'deployment_day',
        ...item.input_validation?.relative,
      },
      ...item.input_validation,
    },
  }

  const updateInput = (key, val, isNumber = false) => {
    const prevInputValidation = itemWithDefaults.input_validation
    const prevRelative = itemWithDefaults.input_validation.relative
    let newVal = isNumber ? Number(Math.round(val)) : val

    // Allow empty strings
    if (isNumber && val === '') {
      newVal = ''
    } else if (isNumber && val !== '' && newVal < 0) {
      // We will change any negative number into 0
      newVal = 0
    }

    const newItem = {
      ...item,
      input_validation: {
        ...prevInputValidation,
        relative: {
          ...prevRelative,
          [key]: newVal,
        },
      },
    }
    updateItem(itemId, newItem)
  }

  useEffect(() => {
    if (
      !!itemWithDefaults.input_validation.relative[intervalKey] ||
      itemWithDefaults.input_validation.relative[intervalKey] === 0
    ) {
      setExpanded(true)
      if (!preview) updateInput()
    }
  }, [])

  const resetRelativeValidation = (_type = RELATIVE_TYPE_MAP.succeeding) => {
    const newItem = {
      ...itemWithDefaults,
    }
    newItem.input_validation.relative[`${_type}_interval`] = ''
    newItem.input_validation.relative[`${_type}_interval_type`] = 'days'
    updateItem(itemId, newItem)
  }

  const generateIntervalOptions = () => {
    const num = itemWithDefaults.input_validation.relative[intervalKey]
    return [
      { key: 'days', text: pluralize(num, 'Day', 'Days', false) },
      { key: 'weeks', text: pluralize(num, 'Week', 'Weeks', false) },
      { key: 'months', text: pluralize(num, 'Month', 'Months', false) },
      { key: 'years', text: pluralize(num, 'Year', 'Years', false) },
    ]
  }

  return (
    <>
      {!expanded && preview ? null : (
        <div className='flexed relative-range-row'>
          {expanded ? (
            <>
              <span className='plus-minus'>{isSucceeding ? '+' : '-'}</span>
              <Input
                disabled={preview}
                type='number'
                min={0}
                value={itemWithDefaults.input_validation.relative[intervalKey]}
                onChange={val => updateInput(intervalKey, val, true)}
              />
              <Dropdown
                disabled={preview}
                onSelect={_intervalType => {
                  updateInput(intervalTypeKey, _intervalType.key)
                }}
                options={generateIntervalOptions()}
                selected={itemWithDefaults.input_validation.relative[intervalTypeKey]}
              />
              <span className='post-text'>from deployment</span>
              {!preview && (
                <Button
                  link
                  onClick={() => {
                    setExpanded(false)
                    resetRelativeValidation(isSucceeding ? RELATIVE_TYPE_MAP.succeeding : RELATIVE_TYPE_MAP.preceding)
                  }}>
                  {closeIcon()}
                </Button>
              )}
            </>
          ) : (
            <Button
              className={`expand-link${isSucceeding ? ' two' : ' one'}`}
              link
              content={`Restrict time ${isSucceeding ? 'after' : 'prior to'} deployment`}
              onClick={() => {
                setExpanded(true)
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

const RelativeDateRange = props => {
  return (
    <div className='flexed column start-aligned'>
      <RelativeDateRangeRow type={RELATIVE_TYPE_MAP.preceding} {...props} />
      <RelativeDateRangeRow type={RELATIVE_TYPE_MAP.succeeding} {...props} />
    </div>
  )
}

RelativeDateRangeRow.propTypes = {
  item: PropTypes.shape({
    input_validation: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.object])),
  }),
  itemId: PropTypes.string,
  preview: PropTypes.bool,
  type: PropTypes.string,
  updateItem: PropTypes.func,
}

export default RelativeDateRange
