import React from 'react'

const HEADER_DATA_MAP = {
  avgCompliance: {
    header: 'Compliance',
    subtitle: 'Compliant',
    posttext: '%',
    style: {
      borderColor: '#909d41',
    },
  },
  avgTime: {
    header: 'Average Time',
    subtitle: 'Received to Completion',
    posttext: 'days avg',
    style: {
      borderColor: '#909d41',
    },
  },
  lowestCompliance: {
    header: 'Lowest\nCompletion Rate',
    subtitle: 'Received to Completion',
    posttext: '%',
    style: {
      borderColor: '#a88bbe',
    },
  },
  notStarted: {
    header: 'Not Started',
    subtitle: 'Participants',
    style: {
      borderColor: '#99e3ec',
    },
  },
  paymentError: {
    header: 'Payment Error',
    subtitle: 'Outstanding',
    style: {
      borderColor: '#c49d8b',
    },
  },
  uninstalls: {
    header: 'Uninstalls',
    subtitle: 'Participants',
    style: {
      borderColor: '#dedede',
    },
  },
}

const LEFT_HEADER_KEYS = ['avgCompliance', 'avgTime', 'lowestCompliance', 'notStarted']
const RIGHT_HEADER_KEYS = ['paymentError', 'uninstalls']

const DataBox = ({ dataKey, content }) => {
  const info = HEADER_DATA_MAP[dataKey]
  return (
    <div style={info.style} className='data-box'>
      <h4>{info.header}</h4>
      <div>
        <b>{content.value}</b>
        {info.posttext && <span>{info.posttext}</span>}
      </div>
      <p>{content.subtitle || info.subtitle}</p>
    </div>
  )
}

const StudyDBHeader = props => {
  return (
    <div className='study-db-header'>
      <div className='flexed start-justified'>
        {LEFT_HEADER_KEYS.map(key => {
          return props[key] !== null ? <DataBox key={key} dataKey={key} content={props[key]} /> : null
        })}
      </div>
    </div>
  )
}

export default StudyDBHeader
