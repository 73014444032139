import { connect } from 'react-redux'
import { QUESTION_TYPE_MAP } from 'utils/constants'
import LogicPanel from '../../../../../../Instruments/routes/Instrument/routes/EditSurvey/components/ItemEditor/LogicPanel'
import { logicActions } from '../modules/NewConsent'

const QUESTION_TYPES_IGNORE_NUMBERING = [QUESTION_TYPE_MAP.introduction, QUESTION_TYPE_MAP.prompt]
const QUESTION_TYPES_SKIP_NUMBERING = [QUESTION_TYPE_MAP.multipleField, QUESTION_TYPE_MAP.audioRecording]
const QUESTION_TYPES_ALLOWED_FOR_CONSENT = [
  QUESTION_TYPE_MAP.selectOne,
  QUESTION_TYPE_MAP.selectMultiple,
  QUESTION_TYPE_MAP.text,
]

const mapDispatchToProps = logicActions

const mapStateToProps = (state, ownProps) => {
  const { isConsent, itemId } = ownProps
  const { newConsentReducer } = state
  const { questions } = newConsentReducer.consent
  const order = newConsentReducer.consent.order[0]
  const currentIndex = order.indexOf(itemId)

  const questionOptions = []
  let questionNumber = 0
  for (let i = 0; i < currentIndex; i++) {
    const questionType = questions[order[i]].type
    const ignoreNumbering =
      QUESTION_TYPES_IGNORE_NUMBERING.includes(questionType) ||
      (isConsent && !QUESTION_TYPES_ALLOWED_FOR_CONSENT.includes(questionType))
    const skipNumbering = QUESTION_TYPES_SKIP_NUMBERING.includes(questionType)
    if (ignoreNumbering) continue
    if (skipNumbering) {
      questionNumber++
      continue
    }

    const plainText = newConsentReducer.editorStates[order[i]].getCurrentContent().getPlainText()
    questionNumber++
    let text = `Q${questionNumber}: ${plainText}`
    if (text.length > 40) text = `${text.slice(0, 40)}...`
    questionOptions.push({
      key: order[i],
      text,
      value: order[i],
    })
  }

  return {
    logic: newConsentReducer.consent.questions[itemId].logic.show_if,
    questionOptions,
    questions,
    logicErr: newConsentReducer.consentErrors.questions[itemId],
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogicPanel)
