import { connect } from 'react-redux'
import ParticipantManagement from '../components/ParticipantManagement'
import { actions as ptpActions } from '../../ParticipantsPage/modules/Participants'
import { ROLE_NAME_ID_MAP } from 'utils/constants'
import { getUserRoleIdByStudy } from 'utils/misc'

const mapState = (state, ownProps) => {
  const { study, participants, cohort, user } = state
  const { currentStudy } = study
  const { params } = ownProps
  const userRoleId = getUserRoleIdByStudy(user, currentStudy?.id)
  const isSiteCoordinator = userRoleId === ROLE_NAME_ID_MAP.Site_Coordinator
  return {
    studyID: study.currentStudy.id,
    list: Object.values(participants.participants),
    ptpMap: participants.ptpMap,
    checked: participants.checkedPtps,
    listTracker: cohort.listTracker,
    participantDataFields: currentStudy.config?.participant_data_fields,
    trackId: params?.cohortID,
    enforceSingleTrackAssignment: isSiteCoordinator && currentStudy?.config?.single_track_assignment,
  }
}

const mapActions = ptpActions

export default connect(mapState, mapActions)(ParticipantManagement)
