import React from 'react'

const OPEN_BOLD = '<bold>'
const CLOSE_BOLD = '</bold>'

const Bold = ({ children }) => <strong>{children}</strong>

const NonBold = ({ children }) => <span>{children}</span>

const FormattedContent = ({ content }) => {
  let combinedContent = []
  const splitByBoldTag = content.split(OPEN_BOLD)
  splitByBoldTag.forEach(string => {
    if (string === '') return
    if (string.includes(CLOSE_BOLD)) {
      const [boldSection, nonBoldSection] = string.split(CLOSE_BOLD)
      combinedContent = [
        ...combinedContent,
        <Bold key={boldSection}>{boldSection}</Bold>,
        <NonBold key={nonBoldSection}>{nonBoldSection}</NonBold>,
      ]
    } else {
      combinedContent = [...combinedContent, <NonBold key={string}>{string}</NonBold>]
    }
  })
  return combinedContent
}

export default FormattedContent
