import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Dropdown, Menu, MenuItem } from 'components/UIElements'
import './NavBar.scss'
import { getUserScope } from 'utils/misc'
import lockImage from 'assets/Icons/lock-shape3x.png'

const NAVBAR_STRING_MAP = {
  audit: 'Audit Report',
  createStudy: 'Create Study',
  dataCorrection: 'Data Correction Form',
  dataInbox: 'Data Inbox',
  insights: 'Datacubed Insights',
  payments: 'Incentives',
}

const LINKS = [
  {
    to: '/dashboard',
    text: 'Dashboard',
    key: 'dashboard',
    scopes: ['ra', 'rp'],
  },
  {
    to: '/instruments',
    text: 'Instruments',
    key: 'instruments',
    scopes: ['ra'],
  },
  {
    to: '/e-consent',
    text: 'eConsent',
    key: 'e-consent',
    scopes: ['wu'],
    conditional: props => props.study.consent_required,
  },
  {
    to: '/users',
    text: 'Users',
    key: 'users',
    scopes: ['ru'],
  },
  {
    to: '/participants',
    text: 'Participants',
    key: 'participants',
    scopes: ['rp'],
  },
  {
    to: '/sites',
    text: 'Sites',
    key: 'sites',
    scopes: ['rp'], // TODO: fix
  },
  {
    to: '/geofences',
    text: 'Geofences',
    key: 'geofences',
    scopes: ['rs'],
  },
  {
    to: '/audit-reporting',
    text: 'Audit Report',
    key: 'audit-reporting',
    scopes: ['ru'], // TODO: fix
  },
  {
    to: '/payments',
    text: 'Payments',
    key: 'payments',
    scopes: ['rp'],
    conditional: props => props.hasPayments,
  },
]

export function LockBanner({ className }) {
  return (
    <div className={`lock-banner flexed ${className}`}>
      <img src={lockImage} className='lock-image' />
      <div className='lock-text'>This study is locked. Functionality is limited.</div>
    </div>
  )
}

class MainNavBar extends React.PureComponent {
  constructor(props) {
    super(props)
    this.userScope = new Set(getUserScope(props.user))
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props
    if (prevProps.user.permissions !== user.permissions) {
      this.userScope = new Set(getUserScope(user))
    }
  }

  filterLinks = ({ scopes, conditional }) => {
    return (
      (!scopes || scopes.some(scope => this.userScope.has(scope))) && (conditional ? conditional(this.props) : true)
    )
  }

  renderNavItem = link => {
    const { study, subroute } = this.props
    const underlined = subroute === link.key
    return (
      <div
        key={link.key}
        className={`navbar-link clickable${underlined ? ' underlined' : ''}`}
        onClick={() => browserHistory.push(`/studies/${study.id}${link.to}`)}
        id={`navbar-link-${link.key}`}>
        {link.text}
      </div>
    )
  }

  renderLinks = () => {
    const { showNavLinks } = this.props
    if (showNavLinks) {
      const links = LINKS.filter(this.filterLinks).map(link => {
        return this.renderNavItem(link)
      })
      links.push(this.getCommunicationDropdown())
      links.push(this.renderNavItem({ to: '', key: 'about', text: 'About' }))
      return links
    }
    return <div />
  }

  getCommunicationDropdown = () => {
    const { study, subroute } = this.props
    const hasNotifications = this.userScope.has('wu')
    const hasAnnouncements = this.userScope.has('ra') && study.cycles !== null
    const underlined = ['notifications', 'announcements'].includes(subroute)
    if (!hasAnnouncements && !hasNotifications) return null
    return (
      <Dropdown
        key='comm-dropdown'
        className={`navbar-dropdown${underlined ? ' underlined' : ''}`}
        hideCaret
        main='Communication'>
        <Menu>
          {hasNotifications && (
            <MenuItem
              selected={subroute === 'notifications'}
              onClick={() => browserHistory.push(`/studies/${study.id}/notifications`)}
              content='Notifications'
            />
          )}
          {hasAnnouncements && (
            <MenuItem
              selected={subroute === 'announcements'}
              onClick={() => browserHistory.push(`/studies/${study.id}/communication`)}
              content='Announcements'
            />
          )}
        </Menu>
      </Dropdown>
    )
  }

  render() {
    const { user, subroute, studyLock } = this.props
    if (!user.token) return null
    const hiddenRouteTitles = ['participants', 'payments', 'communication']
    return (
      <div className='navbar'>
        {!hiddenRouteTitles.includes(subroute) ? <h4>{NAVBAR_STRING_MAP[subroute] || subroute}</h4> : <div />}
        {studyLock && subroute !== 'studies' && <LockBanner className={subroute} />}
      </div>
    )
  }
}

MainNavBar.propTypes = {
  study: PropTypes.object,
  studies: PropTypes.array,
  user: PropTypes.object,
  onLogout: PropTypes.func,
  selectedUser: PropTypes.string,
  dropdownText: PropTypes.string,
  subroute: PropTypes.string,
  showNavLinks: PropTypes.bool,
}

export default MainNavBar
