import React from 'react'
import { Input, TimePicker } from 'components/UIElements'
import { handleNaN } from 'utils/misc'
import moment from 'moment'

const HourIntervalInput = ({ interval_hours, onUpdateNotification }) => {
  return (
    <div className='type-input-container'>
      <Input
        onChange={num => {
          onUpdateNotification('interval_hours', num)
        }}
        onBlur={e => handleNaN(e)(() => onUpdateNotification('interval_hours', 1))}
        preventInput={num => num < 1}
        type='number'
        id='trigger-hours'
        isPositive
        onlyInteger
        value={interval_hours}
      />
      <span> hours </span>
    </div>
  )
}
const IntervalAndTimeInput = ({ interval_days, time_of_day, onUpdateNotification }) => {
  const time = moment(time_of_day, 'HH:mm')
  return (
    <div className='type-input-container'>
      <Input
        onChange={num => {
          onUpdateNotification('interval_days', num)
        }}
        onBlur={e => handleNaN(e)(() => onUpdateNotification('interval_days', 1))}
        type='number'
        isPositive
        onlyInteger
        value={interval_days}
      />
      <span>day at</span>
      <TimePicker
        onChange={mom => {
          onUpdateNotification('time_of_day', mom.format('HH:mm'))
        }}
        id='trigger-time'
        value={time}
      />
    </div>
  )
}

export const CONDITIONAL_NOTIFICATION_TYPE_MAP = {
  medicationAdherence: 'medication_adherence',
}

export const CONDITIONAL_TYPE_MAP = {
  medication_adherence: {
    text: 'Medication Adherence',
    getTriggerText: () => `Every day medication is taken`,
    label: (
      <div className='grey-box-text'>
        <p>"Don't forget to take your shots!"</p>
      </div>
    ),
    inputText: 'Send every',
    input: HourIntervalInput,
  },
}

export const TYPE_MAP = {
  incomplete_instrument: {
    text: 'Incomplete Instrument',
    getTriggerText: value => `${value} hours old`,
    label: (
      <div id='notification-message' className='grey-box-text'>
        <p>"You have </p>
        <span>activities remaining</span>
        <p> activity(ies) left to complete."</p>
      </div>
    ),
    infoText: `This Notification will only be sent to participants who haven't completed the instrument`,
    inputText: 'Time since instrument appeared',
    input: HourIntervalInput,
  },
  expiring_instrument: {
    text: 'Expiring Instrument',
    getTriggerText: value => `${value} hours to expire`,
    label: (
      <div className='grey-box-text'>
        <p>"Don't forget to complete your activities"</p>
      </div>
    ),
    infoText: `This notification will only be sent to participants who haven't completed the expiring Instrument.`,
    inputText: 'Send every',
    input: HourIntervalInput,
  },
  cycle_end: {
    text: 'Chapter End',
    getTriggerText: value => `${value} hours to chapter end`,
    label: (
      <div className='grey-box-text'>
        <p>"You have </p>
        <span>days remaining</span>
        <p> day(s) left to complete your activities this chapter."</p>
      </div>
    ),
    infoText: `This notification will be sent to all participants in the chapter.`,
    inputText: 'Chapter is scheduled to end in',
    input: HourIntervalInput,
  },
  periodic: {
    text: 'Periodic Reminder',
    getTriggerText: value => {
      if (value === 1) {
        return 'Everyday'
      }
      return `Every ${value} days`
    },
    label: (
      <div className='grey-box-text'>
        <p>"You only have </p>
        <span>activities remaining</span>
        <p> activity(ies) left in your carousel."</p>
      </div>
    ),
    inputText: 'Send every',
    input: IntervalAndTimeInput,
  },
  new_instrument: {
    text: 'New Instrument',
    getTriggerText: () => 'At the time of new instrument',
  },
  new_cycle: {
    text: 'New Chapter',
    getTriggerText: () => 'At the time of new chapter',
  },
  new_announcement: {
    text: 'New Announcement',
    getTriggerText: () => 'At the time of new announcement',
  },
  data_sync: {
    text: 'Data Sync',
    getTriggerText: () => 'Every 24 hours since Last Passive Data Receipt',
  },
}
