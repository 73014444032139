export const SET_FULL_WIDTH = 'SET_FULL_WIDTH'
export const SET_NORMAL_WIDTH = 'SET_NORMAL_WIDTH'

export const setFullWidth = () => {
  return {
    type: SET_FULL_WIDTH,
  }
}

export const setNormalWidth = () => {
  return {
    type: SET_NORMAL_WIDTH,
  }
}

const viewReducer = (state = true, action) => {
  switch (action.type) {
    case SET_FULL_WIDTH:
      return false
    case SET_NORMAL_WIDTH:
      return true
    default:
      return state
  }
}

export const viewActions = {
  setFullWidth,
  setNormalWidth,
}

export default viewReducer
