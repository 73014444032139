import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SELECT_QUESTION_TYPES } from 'utils/constants'
import DiaryChoicesList from './DiaryChoicesList'
import DiaryLongListSelectOneTextarea from './LongList/DiaryLongListSelectOneTextarea'
import { selectViewActions } from '../modules/Diary'

class SelectView extends React.Component {
  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (prevProps.item.type !== item.type) {
      if (!SELECT_QUESTION_TYPES.includes(item.type)) {
        this.el.querySelector('textarea').focus()
      }
    }
  }

  shouldCancelStart = e => {
    const { isActive } = this.props
    return !isActive || !e.target.className.includes('choice-grab-icon')
  }

  onAddChoice = idx => {
    const { item, addChoice, itemId } = this.props
    return () => {
      addChoice(itemId, item.choices_order.length, idx, item.type)
    }
  }

  render() {
    const { itemId, moveChoice, item, errors } = this.props
    const isLongList = item.type === 'long_list_select_one'
    return (
      <div
        ref={el => {
          this.el = el
        }}
        className='multi-choice-container'>
        {errors && !!errors.valuesErr && <p className='survey-error nrs-value'>{errors.valuesErr}</p>}
        {errors && !!errors.blankValuesErr && <p className='survey-error nrs-value'>{errors.blankValuesErr}</p>}
        {isLongList ? (
          <DiaryLongListSelectOneTextarea {...this.props} />
        ) : (
          <DiaryChoicesList
            onSortEnd={({ oldIndex, newIndex }) => moveChoice(itemId, oldIndex, newIndex)}
            lockAxis={item.type === 'likert' || item.type === 'numeric_rating_scale' ? 'x' : 'y'}
            axis={item.type === 'likert' || item.type === 'numeric_rating_scale' ? 'x' : 'y'}
            lockToContainerEdges
            helperClass='sortableHelper choices'
            shouldCancelStart={this.shouldCancelStart}
            onAddChoice={this.onAddChoice}
            {...this.props}
          />
        )}
      </div>
    )
  }
}

export default connect(null, selectViewActions)(SelectView)

SelectView.propTypes = {
  item: PropTypes.object,
  errors: PropTypes.object,
  itemId: PropTypes.string,
  moveChoice: PropTypes.func,
  addChoice: PropTypes.func,
  isActive: PropTypes.bool,
  preview: PropTypes.bool,
}
