import React from 'react'
import { CURRENCY_MAP } from 'utils/constants'
import { pluralize, handleNaN } from 'utils/misc'
import { Button, Dropdown, Input } from 'components/UIElements'
import { studyPropTypes } from './RewardConfig'
import { getIsMissingRequiredDynamicCycleFields } from '../modules/CreateStudy'

const TARGET_OPTIONS = [
  { key: 2, value: 2, text: '2' },
  { key: 3, value: 3, text: '3' },
  { key: 4, value: 4, text: '4' },
  { key: 5, value: 5, text: '5' },
  { key: 6, value: 6, text: '6' },
  { key: 7, value: 7, text: '7' },
  { key: 8, value: 8, text: '8' },
  { key: 9, value: 9, text: '9' },
  { key: 10, value: 10, text: '10' },
  { key: 11, value: 11, text: '11' },
  { key: 12, value: 12, text: '12' },
  { key: 13, value: 13, text: '13' },
  { key: 14, value: 14, text: '14' },
]

const DynamicCycleRewardConfig = ({ deleteCycle, configObj, updateCycle, disabled, addCycle, saveAttempted }) => {
  const { cycles } = configObj
  const { currency } = cycles[0]
  const usesCashRewards = cycles[0].rewardType === 'cash'
  const showError = saveAttempted && getIsMissingRequiredDynamicCycleFields(cycles)

  return (
    <div className='dynamic-cycles-config'>
      <p className='label-small'>Chapters and Rewards</p>
      {showError && <span className='error-text nowrap'>Fields marked in red cannot be empty</span>}
      <table className='dynamic-cycle-table'>
        <thead>
          <tr>
            <th className='milestone'>Chapter</th>
            <th>Length</th>
            <th>Requirement</th>
            <th>{`Rewards${usesCashRewards ? ` (Max ${CURRENCY_MAP[currency]}100)` : ''}`}</th>
          </tr>
        </thead>
        <tbody>
          {cycles.map((cycle, idx) => {
            return (
              <tr key={`tr_${idx}`}>
                <td className='milestone cell'>
                  {!disabled && cycles.length > 1 && (
                    <i
                      className='fas fa-trash-alt'
                      onClick={e => {
                        e.stopPropagation()
                        deleteCycle(idx)
                      }}
                    />
                  )}
                  {idx + 1}
                </td>
                <td>
                  <div className='input-cell length-cell'>
                    <Input
                      id='day-length-input'
                      disabled={disabled}
                      inline
                      onChange={days => updateCycle(idx, 'cycleDuration', days)}
                      posttext={`${pluralize(cycle.cycleDuration, 'day', 'days', false)}`}
                      hasError={showError && !cycle.cycleDuration}
                      type='number'
                      isPositive
                      onlyInteger
                      onBlur={e => handleNaN(e)(() => updateCycle(idx, 'cycleDuration', 1))}
                      value={cycle.cycleDuration}
                    />
                  </div>
                </td>
                <td>
                  <div className='input-cell'>
                    <Dropdown
                      id='requirement-input'
                      disabled={disabled}
                      onSelect={item => updateCycle(idx, 'target', item.value)}
                      options={TARGET_OPTIONS}
                      selected={cycle.target}
                      type='number'
                      value={cycle.target}
                    />
                    instruments
                  </div>
                </td>
                <td>
                  <div className='input-cell'>
                    <Input
                      id='reward-input'
                      className='reward-amount'
                      disabled={disabled}
                      inline
                      onChange={reward =>
                        updateCycle(idx, 'reward', parseInt(usesCashRewards ? reward * 100 : reward, 10))
                      }
                      posttext={usesCashRewards ? '' : 'gems'}
                      pretext={usesCashRewards ? CURRENCY_MAP[currency] : ''}
                      preventInput={num => {
                        if (usesCashRewards) {
                          return num <= 0 || num > 100
                        }
                        return num <= 0 || num > 9999
                      }}
                      hasError={showError && !cycle.reward}
                      step='1'
                      type='number'
                      value={usesCashRewards ? Math.round(cycle.reward) / 100 : cycle.reward}
                    />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {!disabled && <Button id='add-chapter' content='Add Chapter' grey onClick={() => addCycle()} />}
    </div>
  )
}

DynamicCycleRewardConfig.propTypes = studyPropTypes

export default DynamicCycleRewardConfig
