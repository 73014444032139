import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../../stylesheets/DiaryDownload.scss'
import { Button } from 'components/UIElements'
import DateFilter from './DateFilter'
import ParticipantFilter from './ParticipantFilter'
import FileFormatType from './FileFormatType'

const ParameterSelection = props => {
  return (
    <div className='diary-param-selection'>
      <section className='diary-filter-sec'>
        <h4 className='label-large'>Filter By</h4>
        <ParticipantFilter {...props} />
      </section>
      <section className='diary-date-sec'>
        <h4 className='label-large'>Time Range</h4>
        <span className='label-small'>SENT BETWEEN DATE</span>
        <DateFilter {...props} />
      </section>
    </div>
  )
}

const FormatSelection = props => {
  return (
    <div className='diary-format-selection'>
      <section className='diary-format-sec'>
        <h4 className='label-large'>File Format</h4>
        <FileFormatType isFormat {...props} />
      </section>
      <section className='diary-type-sec'>
        <h4 className='label-large'>File Type</h4>
        <FileFormatType />
      </section>
    </div>
  )
}

const formatCheckedPtpsIntoArr = (checkedPtps = {}) => {
  const idArr = Object.keys(checkedPtps)
  const ptpRequestArr = []
  if (idArr.length === 0) return ptpRequestArr
  for (let i = 0; i < idArr.length; i++) {
    const checkedItem = checkedPtps[idArr[i]]

    /**
     * If the tree level of the checked item is 1, that means the study ID is still selected,
     * therefore we can make the data download request call with just the study ID without
     * passing any participant id params.
     */
    if (checkedItem.treeLevel === 1) break
    /**
     * When the tree level of the checked item is 3, this signifies a participant ID
     * as opposed to a site or study ID. We then push these IDs into the array with
     * which we will make the data download request.
     */ else if (checkedItem.treeLevel === 3) {
      ptpRequestArr.push(idArr[i])
    }
  }
  return ptpRequestArr
}

const DiaryDataDownload = props => {
  const {
    checkedPtps,
    closeModal,
    diaryDownloadLoading,
    downloadParticipantData,
    fetchSelectionPtps,
    siteId,
    studyId,
  } = props
  const [date, setDate] = useState({ from: '', to: '', allDate: true })
  const [isLongFormat, setIsLongFormat] = useState(false)
  const [dateError, setDateError] = useState({
    from: {
      value: true,
      error: '',
    },
    to: {
      value: true,
      error: '',
    },
  })

  useEffect(() => {
    fetchSelectionPtps(studyId)
  }, [])

  const onSetDate = _date => {
    setDate(_date)
  }

  return (
    <div className='diary-download'>
      <h3 className='headline'>Download diary responses</h3>
      <div className='flexed start-aligned'>
        <ParameterSelection
          dateError={dateError}
          setDateError={setDateError}
          date={date}
          onSetDate={onSetDate}
          isLongFormat={isLongFormat}
          {...props}
        />
        <FormatSelection isLongFormat={isLongFormat} setIsLongFormat={setIsLongFormat} />
      </div>
      <div className='buttons flexed end-justified'>
        <Button grey no-margin content='Close' onClick={() => closeModal()} />
        <Button
          no-margin
          content='Download Responses'
          disabled={
            diaryDownloadLoading ||
            (!date.allDate && (dateError.from.value || dateError.to.value || !date.from || !date.to))
          }
          loading={diaryDownloadLoading}
          onClick={() => {
            if (isLongFormat) {
              downloadParticipantData({
                type: 'diary',
                studyId,
                siteId,
                scheduleDate: date,
                formatType: 'long',
                resType: 'text',
              })
            } else {
              downloadParticipantData({
                type: 'diary',
                studyId,
                siteId,
                scheduleDate: date,
                ptpIdArr: formatCheckedPtpsIntoArr(checkedPtps),
                formatType: 'wide',
                resType: 'text',
              })
            }
          }}
        />
      </div>
    </div>
  )
}

DiaryDataDownload.propTypes = {
  checkedPtps: PropTypes.objectOf(PropTypes.object),
  closeModal: PropTypes.func,
  diaryDownloadLoading: PropTypes.bool,
  downloadParticipantData: PropTypes.func,
  fetchSelectionPtps: PropTypes.func,
  participantSelectionList: PropTypes.arrayOf(PropTypes.object),
  siteId: PropTypes.number,
  studyId: PropTypes.number,
}

export default DiaryDataDownload
