import React from 'react'
import PropTypes from 'prop-types'
import STRINGS from 'utils/strings'
import '../../../stylesheets/consentPdfPreview.scss'
import { formatAndSanitizeHTML } from 'utils/draft'

const ConsentPdfPreview = ({ consentElements = {} }) => {
  const { left_header, center_header, right_header, left_footer, center_footer } = consentElements

  const renderContent = (content, labelText) => {
    return content === '<p><br></p>' ? (
      <p className='header-footer-p'>{labelText}</p>
    ) : (
      <p className='header-footer-p' dangerouslySetInnerHTML={{ __html: formatAndSanitizeHTML(content) }} />
    )
  }

  return (
    <div className='consent-pdf-preview'>
      <h5>{STRINGS.consentPdfFormatPreview}</h5>
      <div className='consent-sample flexed'>
        <header className='flexed start-aligned'>
          {renderContent(left_header, 'Header 1')}
          {renderContent(center_header, 'Header 2')}
          {renderContent(right_header, 'Header 3')}
        </header>
        <div className='consent-content-placeholder flexed column'>
          <div className='circle-wrapper flexed center-justified'>
            <i className='far fa-edit' />
          </div>
          <p className='consent-content-text'>{STRINGS.consentPdfPageConsentContent}</p>
        </div>
        <footer className='flexed end-aligned'>
          {renderContent(left_footer, 'Footer 1')}
          {renderContent(center_footer, 'Footer 2')}
          {renderContent(STRINGS.consentPdfPlaceholderPageNumber)}
        </footer>
      </div>
    </div>
  )
}

ConsentPdfPreview.propTypes = {
  consentElements: PropTypes.shape({
    left_header: PropTypes.string,
    center_header: PropTypes.string,
    right_header: PropTypes.string,
    left_footer: PropTypes.string,
    center_footer: PropTypes.string,
  }),
}

export default ConsentPdfPreview
