import React from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Container, Loader, SortableTable } from 'components/UIElements'
import { NOT_APPLICABLE_PLACEHOLDER } from 'utils/constants'
import STRINGS from 'utils/strings'
import StatusHistorySection from './StatusHistorySection'
import StatusTimestampTooltip from './StatusTimestampTooltip'

const noInstrumentsString = 'There are no pending instruments for this participant.'

class CompletedInstruments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentCycle: null,
      instrumentsToComplete: {},
      isStatusRowExpanded: {},
    }
  }

  headerOptions = () => {
    const { hasAdvancedEventTimelines, hasMarkInstrumentComplete, site, mainSite } = this.props
    const headers = hasAdvancedEventTimelines
      ? [
          { key: 'name', sortable: true, component: 'Instrument Name' },
          { key: 'status', sortable: true, component: 'Status' },
          {
            key: 'statusTimestamp',
            sortable: true,
            component: 'Status Timestamp',
            iconClassName: 'fas fa-info-circle',
            className: 'status-timestamp-cell',
            popupComponent: <StatusTimestampTooltip site={site} mainSite={mainSite} />,
          },
          { key: 'action', sortable: false, component: '' },
        ]
      : [
          { key: 'name', sortable: true, component: 'Instrument Name' },
          { key: 'cycleNo', sortable: true, component: 'Chapter Number' },
          {
            key: 'completionDate',
            sortable: true,
            component: 'Completion',
            iconClassName: 'fas fa-info-circle',
            popupText: "Participant's local time",
          },
          { key: 'action', sortable: false, component: '' },
        ]
    if (!hasMarkInstrumentComplete) return headers.filter(col => col.key !== `action`)
    return headers
  }

  clearInstrumentsToComplete = () => {
    this.setState({ instrumentsToComplete: {} })
  }

  renderMarkCompleteButton = () => {
    const { markInstrumentComplete, params, ptpInstrumentsLoading, ptpId: ptpID, ptpSiteID } = this.props
    const { studyID } = params
    const { instrumentsToComplete } = this.state
    const noInstrumentsToComplete = Object.keys(instrumentsToComplete).length === 0
    return (
      <Button
        className={`mark-complete flexed center-aligned${ptpInstrumentsLoading ? ' loading' : ''}`}
        content='Mark completed'
        disabled={noInstrumentsToComplete}
        loading={ptpInstrumentsLoading}
        icon='fas fa-check'
        // Second argument below is the success callback to the instrumentsToComplete state
        onClick={() =>
          markInstrumentComplete(
            { instrumentsToComplete, ptpID, studyID, siteID: ptpSiteID },
            this.clearInstrumentsToComplete,
          )
        }
      />
    )
  }

  toggleInstrumentToComplete = idqId => {
    const { instrumentsToComplete } = this.state
    const newInstrumentsToComplete = { ...instrumentsToComplete }
    if (instrumentsToComplete[idqId]) {
      delete newInstrumentsToComplete[idqId]
    } else {
      newInstrumentsToComplete[idqId] = true
    }
    this.setState({ instrumentsToComplete: newInstrumentsToComplete })
  }

  renderCompleteInstrumentCell = (row, idx) => {
    const { hasMarkInstrumentComplete } = this.props
    const { idqId, completed, value } = row
    const { instrumentsToComplete } = this.state
    const isMarkedComplete = value === 'Marked complete'
    if (!hasMarkInstrumentComplete) return null
    return (
      <td className='complete-cell' key={`complete-${row}-${idx}`}>
        {completed ? (
          <>{isMarkedComplete ? value : ''}</>
        ) : (
          <Checkbox
            label='Completed'
            checked={instrumentsToComplete[idqId]}
            onClick={() => this.toggleInstrumentToComplete(idqId)}
          />
        )}
      </td>
    )
  }

  renderCompletionCell = (row, idx) => {
    const { completed, value } = row
    return (
      <td className='completion-cell' key={`completion-date-${row}-${idx}`}>
        {completed ? (
          <>
            <i className='fas fa-check' /> <span>{value}</span>
          </>
        ) : (
          <i className='fas fa-times' />
        )}
      </td>
    )
  }

  renderStatusCell = (row, idx) => {
    const { value } = row
    return (
      <td className='completion-cell' key={`completion-date-${row}-${idx}`}>
        <span>{value}</span>
      </td>
    )
  }

  selectCycle = num => {
    return () => {
      this.setState({ currentCycle: num })
    }
  }

  getFilteredByCycle = () => {
    const { instrumentStatus } = this.props
    const { currentCycle } = this.state

    const inCycle = currentCycle !== NOT_APPLICABLE_PLACEHOLDER

    if (currentCycle === null) return instrumentStatus

    return instrumentStatus.filter(data => {
      return inCycle ? parseInt(data[1].value, 10) === currentCycle : data[1].value === currentCycle
    })
  }

  getCycleNums = () => {
    const { hasRewards, instrumentStatus } = this.props
    if (!hasRewards) return null
    const set = {}
    instrumentStatus.forEach(datum => {
      const hasCycleNum = datum[1].value !== 'Post-study' && datum[1].value !== ''
      if (hasCycleNum) set[datum[1].value] = null
    })

    return Object.keys(set)
  }

  renderCycleTabs = () => {
    const { hasRewards } = this.props
    const { currentCycle } = this.state
    const cycleNums = this.getCycleNums()
    if (!hasRewards) return null
    return (
      <div className='cycle-tabs'>
        <span onClick={this.selectCycle(null)} className={`clickable${currentCycle === null ? ' selected' : ''}`}>
          All Chapters
        </span>
        {cycleNums &&
          cycleNums.map(_num => {
            const inCycle = _num !== NOT_APPLICABLE_PLACEHOLDER
            const num = inCycle ? parseInt(_num, 10) : NOT_APPLICABLE_PLACEHOLDER
            return (
              <span
                key={`tab_cycle_${num}`}
                className={`clickable${currentCycle === num ? ' selected' : ''}`}
                onClick={this.selectCycle(inCycle ? parseInt(num, 10) : NOT_APPLICABLE_PLACEHOLDER)}>
                {inCycle ? `Chapter ${num}` : NOT_APPLICABLE_PLACEHOLDER}
              </span>
            )
          })}
      </div>
    )
  }

  expandedRow = row => {
    const {
      instrumentTimeline,
      fetchInstrumentTimeline,
      resetInstrumentTimeline,
      instrumentTimelineLoading,
      site,
      mainSite,
    } = this.props
    const { idqId } = row[row.length - 1]
    return (
      <StatusHistorySection
        instrumentTimeline={instrumentTimeline}
        instrumentId={idqId}
        fetchInstrumentTimeline={fetchInstrumentTimeline}
        instrumentTimelineLoading={instrumentTimelineLoading}
        onClose={() => {
          this.setState({
            isStatusRowExpanded: {},
          })
          resetInstrumentTimeline()
        }}
        noStatusMessage={STRINGS.noInstrumentStatus}
        site={site}
        mainSite={mainSite}
        withUtcOffset
      />
    )
  }

  getTableRenderFunctions = () => {
    const { hasMarkInstrumentComplete, hasAdvancedEventTimelines } = this.props
    const returnNull = () => null
    return {
      action: hasMarkInstrumentComplete ? this.renderCompleteInstrumentCell : returnNull,
      completionDate: hasAdvancedEventTimelines ? returnNull : this.renderCompletionCell,
      cycleNo: hasAdvancedEventTimelines ? returnNull : row => <td>{row.value}</td>,
      status: hasAdvancedEventTimelines ? this.renderStatusCell : returnNull,
      name: ({ value }) => (
        <td key={`instrument-${value}`} className='instrument-name'>
          {value}
        </td>
      ),
      statusTimestamp: hasAdvancedEventTimelines
        ? ({ value }) => <td key={`instrument-${value}`}>{value || NOT_APPLICABLE_PLACEHOLDER}</td>
        : returnNull,
    }
  }

  render() {
    const { hasMarkInstrumentComplete, ptpInstrumentsLoading, hasAdvancedEventTimelines } = this.props
    const { isStatusRowExpanded } = this.state
    const list = this.getFilteredByCycle()
    const hasInstruments = list.length > 0
    const renderFunctions = this.getTableRenderFunctions()
    return (
      <div>
        <Container className={`ptp-instruments ${hasInstruments ? 'fixed-min-height' : ''}`}>
          <div className='title'>Instruments</div>
          {ptpInstrumentsLoading ? (
            <Loader inContainer />
          ) : (
            <>
              {this.renderCycleTabs()}
              {hasMarkInstrumentComplete && hasInstruments && this.renderMarkCompleteButton()}
              {hasInstruments ? (
                <SortableTable
                  sortingBy='cycleNo'
                  headerOptions={this.headerOptions()}
                  rowList={list}
                  renderFunctions={renderFunctions}
                  onRowClick={
                    hasAdvancedEventTimelines
                      ? row => {
                          const { idqId } = row[row.length - 1]
                          this.setState({
                            isStatusRowExpanded: {
                              [idqId]: !isStatusRowExpanded[idqId],
                            },
                          })
                        }
                      : null
                  }
                  rowExpandOptions={
                    hasAdvancedEventTimelines
                      ? {
                          isRowExpanded: row => {
                            const { idqId } = row[row.length - 1]
                            return isStatusRowExpanded[idqId]
                          },
                          expandedRowClassName: 'expanded',
                        }
                      : null
                  }
                  expandedRow={this.expandedRow}
                />
              ) : (
                <p>{noInstrumentsString}</p>
              )}
            </>
          )}
        </Container>
      </div>
    )
  }
}

CompletedInstruments.propTypes = {
  hasAdvancedEventTimelines: PropTypes.bool,
  hasMarkInstrumentComplete: PropTypes.bool,
  hasRewards: PropTypes.bool,
  instrumentStatus: PropTypes.arrayOf(PropTypes.array),
  markInstrumentComplete: PropTypes.func,
  params: PropTypes.shape({
    siteID: PropTypes.string,
    studyID: PropTypes.string,
  }),
  ptpId: PropTypes.number,
  ptpInstrumentsLoading: PropTypes.bool,
  ptpSiteID: PropTypes.number,
  participant: PropTypes.shape({
    id: PropTypes.number,
  }),
}

export default CompletedInstruments
