import React from 'react'
import PropTypes from 'prop-types'
import { RichTextEditor } from 'components/UIElements'
import {
  QUESTION_TYPES_WITH_SCORING,
  QUESTION_TYPES_WITH_IMAGE,
  QUESTION_TYPES_WITH_AUDIO,
  QUESTION_TYPES_WITH_VIDEO,
} from 'utils/constants'
import QuestionView from './QuestionView'
import HoverBar from './HoverBar'
import ItemDomains from './Scoring/ItemDomains'
import { getCurrentResourcesSizeCount } from '../utils/surveyUtils'
import 'draft-js/dist/Draft.css'

const WRAPPED_LABEL_MIN_LENGTH = 90

class SurveyItem extends React.Component {
  componentDidMount() {
    const { isActive } = this.props
    if (isActive) {
      this.draftEditorRef.draftEditor.focus()
    }
  }

  onDuplicateItem = itemId => {
    const { duplicateItem, editorState } = this.props
    return () => duplicateItem(itemId, editorState)
  }

  onLabelChange = newEditorState => {
    const { updateItemLabel, itemId, editorState } = this.props
    updateItemLabel(itemId, editorState, newEditorState)
  }

  onClick = e => {
    const { isActive, onToggleEdit, itemId } = this.props
    e.stopPropagation()
    if (!isActive) {
      onToggleEdit(itemId, this.item)
    }
  }

  getErrorContent = () => {
    const { errors } = this.props
    return Object.values(errors).map((error, idx) => {
      return <p key={`error_${idx}`}>{`${idx + 1}. ${error}`}</p>
    })
  }

  onPaste = e => {
    const { isActive, item, addChoiceOnPaste, activeItemId } = this.props
    if (
      isActive &&
      document.activeElement.tagName === 'BODY' &&
      ['select_one', 'select_multiple', 'likert'].includes(item.type)
    ) {
      addChoiceOnPaste(activeItemId, e.clipboardData.getData('text'))
    }
  }

  render() {
    const {
      editorState,
      errors,
      isActive,
      item,
      itemId,
      itemIndex,
      instrumentDomains,
      onAddItem,
      resourceSizeList = [],
      questionNumber,
    } = this.props
    const notIntro = item.type !== 'introduction'
    const canHaveDomains = QUESTION_TYPES_WITH_SCORING.includes(item.type)
    const currentResourcesSizeCount = getCurrentResourcesSizeCount(resourceSizeList)
    const { domains, attributes } = item
    const { hasScoring } = attributes
    const isDateTime = item.type === 'datetime'

    return (
      <div onPaste={this.onPaste} id={`question-${itemId}`}>
        {notIntro && <HoverBar onAddItem={onAddItem} itemIndex={itemIndex - 1} />}
        <div
          ref={el => {
            this.item = el
          }}
          onClick={this.onClick}
          onFocus={this.onClick}
          className={`survey-item-container ${isActive ? 'active hasEditor' : ''} ${!notIntro ? 'introduction' : ''}${
            hasScoring ? ' has-scoring' : ''
          }`}>
          <div
            className={`label-header flexed start-justified start-aligned ${
              isDateTime ? 'datetime-label-header' : ''
            }`}>
            {notIntro ? <div className='grab-icon' /> : <h6>Introduction Text</h6>}
            <div className='flexed start-aligned stretch-aligned'>
              <div>
                <div
                  className={`flexed start-justified start-aligned ${
                    item.label.length < WRAPPED_LABEL_MIN_LENGTH ? 'wrapped' : ''
                  }`}>
                  <span className={questionNumber && 'has-question'}>{questionNumber}</span>
                  <RichTextEditor
                    ref={el => {
                      this.draftEditorRef = el
                    }}
                    autoFocus
                    canAddAudio={QUESTION_TYPES_WITH_AUDIO.includes(item.type)}
                    canAddImage={QUESTION_TYPES_WITH_IMAGE.includes(item.type)}
                    canAddVideo={QUESTION_TYPES_WITH_VIDEO.includes(item.type)}
                    currentResourcesSizeCount={currentResourcesSizeCount}
                    editorState={editorState}
                    hasBlockStyles
                    hasError={errors.blankLabelErr && item.label === '<p><br></p>'}
                    hasLinks
                    hasAlignmentControls
                    onChange={this.onLabelChange}
                    placeholder={!notIntro ? 'Type your introduction here.' : 'Type text'}
                    showToolbar={isActive}
                    spellCheck
                    {...this.props}
                  />
                </div>
                <QuestionView {...this.props} currentResourcesSizeCount={currentResourcesSizeCount} />
              </div>
              {canHaveDomains && (
                <ItemDomains
                  isActive={isActive}
                  domains={domains}
                  hasScoring={hasScoring}
                  instrumentDomains={instrumentDomains}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SurveyItem

SurveyItem.propTypes = {
  activeItemId: PropTypes.string,
  addChoiceOnPaste: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorState: PropTypes.object,
  errors: PropTypes.object,
  instrumentDomains: PropTypes.objectOf(PropTypes.object),
  isActive: PropTypes.bool,
  item: PropTypes.object,
  itemId: PropTypes.string,
  itemIndex: PropTypes.number,
  onAddItem: PropTypes.func,
  onDeleteChoice: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onToggleEdit: PropTypes.func,
  questionNumber: PropTypes.number,
  resourceSizeList: PropTypes.arrayOf(PropTypes.object),
  updateItemLabel: PropTypes.func,
}
