import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserHasFeatures } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { setPageTitle } from 'utils/misc'
import { resetInstrument } from '../../modules/Instrument'
import { clinroActions } from '../EditClinro/modules/Clinro'
import CreateClinroView from '../EditClinro/containers/ClinroContainer'
import { fetchCohorts } from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'
// Sync route definition
export default store => ({
  path: 'createclinro',
  exact: true,
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      require => {
        const { params } = nextState
        const { studyID } = params
        const { currentStudy } = store.getState().study
        setPageTitle(`Create Clinician Instrument | Study ${studyID} | ${currentStudy.platform_name}`)
        /*  Webpack - use require callback to define
       dependencies for bundling   */

        const title = store.getState().instruments ? store.getState().instruments.wizardTitle : ''
        const displayName = store.getState().instruments ? store.getState().instruments.wizardDisplayName : ''
        store.dispatch(clinroActions.initializeBlankClinro(title, displayName))
        store.dispatch(resetInstrument('CLINRO'))
        store.dispatch(fetchCohorts(nextState.params.studyID))

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'instruments',
              )(UserHasScope(['wa'], UserHasFeatures(CreateClinroView, ['clinro'], 'instruments'), false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'createclinro',
    )
  },
})
