import { connect } from 'react-redux'
import PerformanceChart from '../components/PerformanceChart'
import { actions } from '../modules/StudyDashboard'

const mapState = state => {
  return {
    ...state.studyDashboard.performance,
  }
}

export default connect(mapState, actions)(PerformanceChart)
