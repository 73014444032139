import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { arrsAreEqual } from 'utils/misc'

// this returns a bool for whether a question choice has a label
const hasLabel = (question, index, numChoices) => {
  const { type } = question
  if (type === 'select_one' || type === 'select_multiple') {
    return true
  }
  if (type === 'likert') {
    if (numChoices === 5) {
      return true
    }
    if ([0, 6].includes(index)) {
      return true
    }
  }
  if (type === 'numeric_rating_scale') {
    if (numChoices <= 5) {
      return true
    }
    if (
      /* these booleans check the number of choices in a numeric rating scale question,
       * and gives labels according to the specific index of the choices
       */
      (numChoices === 6 && [0, 2, 3, 5].includes(index)) ||
      (numChoices === 7 && [0, 3, 6].includes(index)) ||
      (numChoices === 8 && [0, 2, 5, 7].includes(index)) ||
      (numChoices === 9 && [0, 2, 4, 6, 8].includes(index)) ||
      (numChoices === 10 && [0, 3, 6, 9].includes(index)) ||
      (numChoices === 11 && [0, 3, 5, 7, 10].includes(index))
    ) {
      return true
    }
  }
  return false
}

/* this function removes numeric rating scale question labels saved in the
 * editorState for labels that don't show depending on the number of choices
 */
export const removeValuesAndLabels = question => {
  const { type, choices_order } = question
  const numChoices = choices_order?.length
  if (['numeric_rating_scale', 'select_one', 'select_multiple', 'likert'].includes(type)) {
    if (choices_order) {
      choices_order.forEach((choiceId, index) => {
        if (type === 'likert') delete question.choices[choiceId].value
        if (!hasLabel(question, index, numChoices)) {
          question.choices[choiceId].label = ''
        }
      })
    }
  }
}

export const removeChoices = question => {
  const { type, choices, choices_order } = question
  const choicesArr = choices ? Object.keys(choices) : []
  if (['numeric_rating_scale', 'select_one', 'select_multiple', 'likert'].includes(type)) {
    choicesArr.forEach(choiceId => {
      if (!choices_order.includes(choiceId)) delete question.choices[choiceId]
    })
  }
}

export const getScoreErrors = (instrument, type = INSTRUMENT_TYPE_MAP.survey) => {
  if (type === INSTRUMENT_TYPE_MAP.survey) {
    const { surveyErrors } = instrument
    const { scores } = surveyErrors
    return scores
  }
  if (type === INSTRUMENT_TYPE_MAP.clinro) {
    const { clinroErrors } = instrument
    const { scores } = clinroErrors
    return scores
  }
  return null
}

export const getIdxOfData = (dataArr, dataKey) => {
  for (let i = 0; i < dataArr.length; i++) {
    const data = dataArr[i]
    if (data.key === dataKey) return i
  }
  return null
}

export function deleteMediaFromInstrumentJson({ _instrument, fileId, itemId, isImage = true }) {
  const { image_list, resource_list, resource_size_list } = _instrument
  const questionsWithCurrentFileMap = _instrument.file_map?.[fileId]
  if (questionsWithCurrentFileMap) {
    if (Object.keys(questionsWithCurrentFileMap).length === 1) {
      if (isImage) {
        _instrument.image_list = image_list ? _instrument.image_list.filter(el => el !== fileId) : []
      } else {
        _instrument.resource_list = resource_list ? _instrument.resource_list.filter(el => el !== fileId) : []
      }
      _instrument.resource_size_list = resource_size_list
        ? _instrument.resource_size_list.filter(el => el.file_name !== fileId)
        : []
      delete _instrument.file_map[fileId]
    } else {
      delete _instrument.file_map[fileId][itemId]
    }
  }
}

export function addMediaToFileMap({ _instrument, fileId, itemId }) {
  const { file_map } = _instrument
  if (file_map) {
    if (file_map[fileId]) {
      file_map[fileId][itemId] = {}
    } else {
      file_map[fileId] = { [itemId]: {} }
    }
  } else {
    _instrument.file_map = {
      [fileId]: { [itemId]: {} },
    }
  }
}

export function getRandomizedArray(arr = []) {
  const origArr = [...arr]
  const intro = arr.shift()
  const indices = Object.keys(arr)
  const newArr = []
  newArr.push(intro) // Prevent changing order of intro question

  // Since intro is always staying on the first place,
  // we need to return original array if we have only 1 question or just intro in survey
  if (arr.length <= 1) {
    return origArr
  }

  while (indices.length > 0) {
    const randomIndex = indices.splice(Math.floor(indices.length * Math.random()), 1)[0]
    newArr.push(arr[randomIndex])
  }

  if (arrsAreEqual(origArr, newArr)) return getRandomizedArray(origArr)

  return newArr
}
