import { pluralize } from 'utils/misc'
import { QUESTION_TYPE_MAP } from 'utils/constants'

/**
 * Transform the Double array into a single array
 * @param {Array} doubleArray [[],[], ...]
 * @returns []
 */
export const convert2DArrayToArray = doubleArray => {
  const singleArray = doubleArray.map(rowArray =>
    rowArray
      .map(({ key, value, consentId, consent_type, deployed, consent_title }) => {
        const newValue = key === 'action' ? { consentId, consent_type, deployed, consent_title } : value
        return { [key]: newValue }
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  )
  return singleArray
}

const generateText = (cohortArr, cohortMap, countableTexts) => {
  const [singular, plural] = countableTexts
  const list = cohortArr.map(trackId => cohortMap[trackId])
  const label = pluralize(list.length, singular, plural, false)
  return {
    label,
    list,
  }
}

const generateRecipientString = (sitesMap, cohortMap, recipient, textList) => {
  const { filter, type } = recipient
  switch (type) {
    case 'sites': {
      const { site_ids } = filter
      textList.push(generateText(site_ids, sitesMap, ['Site', 'Sites']))
      break
    }
    case 'study_group': {
      const { include } = filter
      textList.push(generateText(include, cohortMap, ['Track', 'Tracks']))
      break
    }
    default:
      textList.push('All participants')
  }
}

export const generateRecipientsString = ({ sitesMap, cohortMap, recipients }) => {
  const textList = []
  if (Array.isArray(recipients)) {
    recipients.forEach(recipient => generateRecipientString(sitesMap, cohortMap, recipient, textList))
  } else {
    generateRecipientString(sitesMap, cohortMap, recipients, textList)
  }
  if (textList.length) return [...new Set(textList)]
  return ['All participants']
}

export const generateLanguagesString = ({ otherLanguages, localesMap }) => {
  if (!otherLanguages) return 'English'
  const textArr = otherLanguages.map(langCode => localesMap[langCode]?.text)
  return `English, ${textArr.join(', ')}`
}

export const checkHasPdfIfRequired = consent => {
  const { pdf_upload, questions, order } = consent
  if (!pdf_upload) return true
  const questionOrder = order[0]
  for (let i = 0; i < questionOrder.length; i++) {
    const question = questions[questionOrder[i]]
    if (question.type === QUESTION_TYPE_MAP.pdf) {
      if (!question.url) return false
    }
  }
  return true
}
