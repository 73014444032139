import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Overlay, Drawer } from 'components/UIElements'
import NavBarContainer from '../NavBar'
import SideBarContainer from '../SideBar'
import ErrorBox from '../ErrorBox'
import './CoreLayout.scss'

const CoreLayout = ({ children, drawer, modal, overlay, location, view }) => {
  const { pathname } = location
  const isViewFalse = !['/unauthorized', '/studies', '/users/me'].includes(pathname) && view === false
  const isFull =
    pathname === '/login' ||
    pathname === '/users/reset' ||
    pathname === '/users/activate' ||
    pathname.includes('/insights-permissions') ||
    pathname.includes('/studies/create') ||
    isViewFalse
  const isAllStudiesPath = pathname === '/studies' || pathname === '/studies/'
  return (
    <div className='layout-container'>
      <Modal {...modal} location={location} />
      <Overlay {...overlay} />
      <Drawer {...drawer} location={location} />
      {pathname !== '/login' &&
      !pathname.includes('preview') &&
      !pathname.includes('insights-permissions') &&
      !pathname.includes('/participants/create') &&
      !pathname.includes('/participants/account-settings') ? (
        <SideBarContainer />
      ) : null}
      {pathname !== '/login' &&
      !pathname.includes('preview') &&
      !pathname.includes('insights-permissions') &&
      !pathname.includes('/participants/create') &&
      !pathname.includes('/participants/account-settings') &&
      !isAllStudiesPath ? (
        <NavBarContainer />
      ) : null}
      <div className={isFull ? 'core-layout__viewport full' : 'core-layout__viewport'}>
        <ErrorBox />
        {children}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loading: state.loading.general,
  view: state.view,
  modal: state.modal,
  overlay: state.overlay,
  drawer: state.drawer,
  permissions: state.user.permissions,
})

export default connect(mapStateToProps, null)(CoreLayout)

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    general: PropTypes.bool,
  }),
  modal: PropTypes.shape({
    open: PropTypes.bool,
  }),
}
