import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/drawer.scss'
import { closeIcon } from 'assets/assets'

const delay = 150

class SideBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { closing: false }
  }

  onCloseDrawer = func => {
    this.setState({ closing: true })
    setTimeout(() => {
      if (func) func()
      this.setState({ closing: false })
    }, delay)
  }

  render() {
    const {
      open,
      className,
      closeOnBackgroundClick,
      onCancel,
      closeDrawer,
      children,
      content,
      width,
      zIndex,
      borderRadius,
      boxShadow,
      hideCloseButton = false,
      rollDown = false,
      setRollDown,
      showArrowButton = false,
    } = this.props
    const { closing } = this.state
    const _className = `d3-drawer drawer-${open || rollDown ? 'open' : 'closed'}${className ? ` ${className}` : ''}`
    return (
      <div
        className={_className}
        onClick={e => {
          e.stopPropagation()
          this.onCloseDrawer(closeOnBackgroundClick ? onCancel || closeDrawer : null)
        }}
        style={{
          width: width === 'auto' ? 'auto' : '100vw',
          zIndex: zIndex || 200,
          borderRadius: borderRadius || 10,
          boxShadow: boxShadow || '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        }}>
        <div
          onClick={e => e.stopPropagation()}
          className={`content${closing || rollDown ? ' closing' : ''} ${rollDown ? 'drawer-rolldown' : ''}`}>
          {showArrowButton && (
            <span
              className='arrow-button'
              onClick={e => {
                e.stopPropagation()
                this.onCloseDrawer(onCancel || closeDrawer)
                setRollDown(prev => !prev)
              }}>
              <i className='fas fa-chevron-right' />
            </span>
          )}
          <div className='content-scroll'>
            {!hideCloseButton && (
              <button
                id='close-drawer'
                className='close-drawer clickable'
                onClick={e => {
                  e.stopPropagation()
                  this.onCloseDrawer(onCancel || closeDrawer)
                }}
                type='button'>
                {closeIcon()}
              </button>
            )}
            {children || content}
          </div>
        </div>
      </div>
    )
  }
}

SideBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeDrawer: PropTypes.func,
  closeOnBackgroundClick: PropTypes.bool,
  content: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  width: PropTypes.string,
  zIndex: PropTypes.number,
  borderRadius: PropTypes.number,
  boxShadow: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  rollDown: PropTypes.bool,
  setRollDown: PropTypes.func,
  showArrowButton: PropTypes.bool,
}

export default SideBar
