import React from 'react'
import PropTypes from 'prop-types'
import { Button, SortableTable } from 'components/UIElements'
import { Link } from 'react-router'
import { convertToLocalizedNumber } from 'utils/misc'
import '../../stylesheets/paymentstable.scss'

export const headerOptions = [
  { key: 'ptpName', sortable: true, component: 'Name' },
  { key: 'ptpId', sortable: true, component: 'Part ID' },
  { key: 'subjectId', sortable: true, component: 'SubJ ID' },
  { key: 'details', sortable: true, component: 'Details' },
  { key: 'date', sortable: true, component: 'Date' },
  { key: 'action', sortable: false, component: 'Action' },
  { key: 'amount', sortable: true, component: 'Amount' },
]

const totalsMap = {
  paid: 'Paid',
  scheduled: 'Scheduled',
  pending: 'Pending',
}

export const renderFunctions = {
  paymentType: ({ value }) => <td key='payment-type-cell'>{value}</td>,
  details: ({ value }) => <td key='details-cell'>{value}</td>,
}

export const getTotals = (payments, totals, language) => {
  if (payments.length === 0) return null
  return Object.keys(totals).map(key => {
    const total = totals[key]
    const text = totalsMap[key]
    if (total === 0) return null
    const localizedTotal = convertToLocalizedNumber(total, language, true)
    return (
      <div className='payment-totals' key={key}>
        <span>{text}</span>
        <span>{localizedTotal}</span>
      </div>
    )
  })
}

const PendingDonationsTable = ({
  canEditPayments,
  canWrite,
  confirmPayment,
  // fetchPendingDonations,
  language,
  offsets = [],
  pendingDonations,
  searchTerm,
  study,
  studyLock,
}) => {
  const _pending = pendingDonations
  let paymentsData = _pending
  let finalHeaderOptions = headerOptions
  if (!canEditPayments && _pending.length) {
    paymentsData = paymentsData.map((paymentRow, i) => {
      return _pending[i].filter(col => col.key !== 'action')
    })
    finalHeaderOptions = headerOptions.filter(col => col.key !== 'action')
  }

  renderFunctions.ptpName = ({ value, hasError, ptpID, ptpSiteID }) => {
    return (
      <td key='ptpName-cell'>
        {canWrite ? (
          studyLock ? (
            value
          ) : (
            <Link to={`/studies/${study.id}/participants/${ptpID}/ptpSite/${ptpSiteID}`}>{value}</Link>
          )
        ) : (
          `Participant ${ptpID}`
        )}
        {hasError && <i className='fas fa-exclamation-circle' />}
      </td>
    )
  }
  renderFunctions.amount = ({ value }) => {
    const localizedPayment = convertToLocalizedNumber(value, language, true)
    return (
      <td key={`payment-${value}`} className='amount'>
        {localizedPayment}
      </td>
    )
  }
  renderFunctions.action = ({ cycleNo, paymentId }) => {
    const studyId = study.id
    return (
      <td key='action-cell' className='action-cell'>
        <Button
          className='donation-button'
          content='Mark as donated'
          grey
          disabled={studyLock}
          onClick={() => {
            confirmPayment(
              studyId,
              paymentId,
              cycleNo,
              true,
              false /* approve or decline */,
              false /* isGiftcard */,
              true,
            )
          }}
        />
      </td>
    )
  }

  return (
    <div className='payments-table'>
      <SortableTable
        offset={offsets[1] || 183} // catch for bug where 2nd offset does not calculate on refresh
        sortingBy='completionDate'
        rowList={paymentsData}
        headerOptions={finalHeaderOptions}
        renderFunctions={renderFunctions}
        searchKeys={['ptpName']}
        searchTerm={searchTerm}
      />
    </div>
  )
}

PendingDonationsTable.propTypes = {
  canEditPayments: PropTypes.bool,
  canWrite: PropTypes.bool,
  confirmPayment: PropTypes.func,
  fetchPendingDonations: PropTypes.func,
  language: PropTypes.string,
  offsets: PropTypes.arrayOf(PropTypes.number),
  pendingDonations: PropTypes.arrayOf(PropTypes.array),
  searchTerm: PropTypes.string,
  study: PropTypes.shape({
    id: PropTypes.number,
  }),
  studyLock: PropTypes.bool,
}

export default PendingDonationsTable
