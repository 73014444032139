export const _getPercentIncrease = (prev, current) => {
  let countChange
  const direction = ''
  if (prev !== 0 && current !== 0) {
    countChange = Math.floor((100 * (current - prev)) / prev)
    if (Math.floor(countChange) === 0) {
      countChange = undefined
    }
  }
  return countChange
}

export const _getDirection = countChange => {
  if (countChange === undefined) return ''
  return countChange > 0 ? 'up' : 'down'
}

export const INSIGHTS_VENDORS = {
  holistics: 'holistics',
  sisense: 'sisense',
}
