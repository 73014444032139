import { injectReducer } from 'store/reducers'
import { authActions } from 'store/user'
import { setPageTitle } from 'utils/misc'
import { actions } from '../ActivateUser/modules/ActivateUser'

// Sync route definition
export default store => ({
  path: 'reset',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      require => {
        const reducer = require('../ActivateUser/modules/ActivateUser').default
        injectReducer(store, {
          key: 'resetPassword',
          reducer,
        })
        store.dispatch(authActions.logout())
        setPageTitle(`Reset Password | Admin Panel`)
        const token = store.getState().location.search.split('?token=')[1]
        store.dispatch(actions.activateUser(token, true)).then(() => {
          cb(null, require('./containers/ResetPasswordContainer').default)
        })
      },
      'reset',
    )
  },
})
