import React from 'react'
import { Filter, Dropdown } from 'components/UIElements'
import { _getMapState, mapActions } from 'components/Filter'
import { renderers, filterGetters } from './Utils'
import { connect } from 'react-redux'

class FirstLoginFilter extends Filter {
  constructor(props) {
    super(props)
    this.defaults = { start: '', end: '', min: '', max: '', selected: 'dateRange', filterType: 'dateRange' }
    this.state = { ...this.defaults }
    this.key = 'firstLogin'
    this.header = 'First Login'
    this.renderFunctions = {
      dateRange: renderers.renderDateRange,
      numRange: renderers.renderNumRange
    }
    this.dropdownOptions = [
      { key: 'dateRange', value: 'dateRange', text: 'Date of First Login' },
      { key: 'numRange', value: 'numRange', text: '# Days Since First Login' }
    ]
  }

  onChangeFilterType = ({ value }) => {
    this.setState({ selected: value, _edited: true, filterType: value })
  }

  onChange = key => {
    return value => {
      this.setState({ [key]: value, _edited: true })
    }
  }

  getFilter(criteria) {
    return this.state.selected === 'dateRange'
      ? filterGetters.getDateRangeFilter(criteria.start, criteria.end)
      : filterGetters.getNumRangeFilter(criteria.min, criteria.max, 'daysSinceFirstLogin')
  }

  render() {
    const selected = this.state.selected
    return super.render(
      <div className='select-type-filter'>
        <p className='label-small'>First Login</p>
        <Dropdown options={this.dropdownOptions} onSelect={this.onChangeFilterType} selected={this.state.selected} />
        {this.renderFunctions[selected](this.state, this.onChange, { min: 'Value', max: 'Value' })}
      </div>
    )
  }
}

export default connect(
  _getMapState('firstLogin'),
  mapActions,
)(FirstLoginFilter)
