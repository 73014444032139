import React, { useEffect, useRef, useState } from 'react'
import { parse, stringify } from 'query-string'
import { deepCompareObj } from 'utils/object'

/**
 * Returns new object with keys from params
 * @param {Object} obj an object that should be filtered
 * @param {Object} tabKeys an object where a prop is the name of the query,
 * the value of prop is a string or an array of strings
 */
const pick = (obj, tabKeys) => {
  const keys = Object.keys(tabKeys)
  if (!Array.isArray(keys) || !keys.length) return obj
  return keys.reduce((acc, key) => {
    if (tabKeys[key]?.length && obj[key]) {
      if (Array.isArray(obj[key]) && obj[key]?.length) {
        for (let i = 0; i < obj[key].length; i++) {
          if (tabKeys[key].indexOf(obj[key][i]) !== -1) {
            acc[key] = obj[key][i]
            break
          }
        }
      } else if (tabKeys[key].indexOf(obj[key]) !== -1) {
        acc[key] = obj[key]
      }
    }
    return acc
  }, {})
}

/**
 * Returns a new object without the key param
 * @param {String} key that sould be removed
 * @param {Object} obj an object that should be filtered
 */
const omitSingle = (key = '', { [key]: _, ...obj }) => obj

/**
 * Returns a new object without keys param
 * @param {Array} keys that sould be removed
 * @param {Object} obj an object that should be filtered
 */
const omitMultiple = (keys = [], obj) => Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)))

const update = path => {
  window.history.pushState(null, document.title, path)
}

const initParseOptions = {
  parseNumbers: true,
  parseBooleans: true,
}

/**
 * @param {Object} location from props of component
 * @param {Array} tabKeys an object with queries
 * @param {Object} parseOptions an object with pars options
 * @param {Object} initValues an object with initial values of queries
 * @returns {Array} [query, querySet, queryRemove]
 * Hook for functional component returns a cortege
 * @param {Object} query an object with queries in url
 * @param {Function} querySet a function to change queries
 * @param {Function} queryRemove a function to remove queries
 */
const useQueryString = ({ location, tabKeys = null, parseOptions = initParseOptions, initValues = null }) => {
  const isFirst = useRef(true)
  const [query, setQuery] = useState(parse(location.search, parseOptions))
  const { pathname } = location

  useEffect(() => {
    if (!tabKeys) {
      setQuery(query)
      return
    }
    const state = pick(query, tabKeys)
    if (initValues && deepCompareObj(state, initValues)) setQuery({})
    else setQuery(state)
  }, [])

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false
    } else {
      const queryStr = stringify(query)
      update(`${pathname}${queryStr ? '?' : ''}${queryStr}`)
    }
  }, [query])

  const queryRemove = keys => {
    setQuery(prev => {
      if (keys === null) return {} // short form to clear all queries
      if (Array.isArray(keys) && keys.length) return omitMultiple(keys, prev)
      if (typeof keys === 'string' && keys) return omitSingle(keys, prev)
      return prev
    })
  }

  const querySet = values => {
    const nextState = tabKeys ? pick(values, tabKeys) : values
    if (deepCompareObj(nextState, initValues)) queryRemove(null)
    else setQuery(prev => ({ ...prev, ...nextState }))
  }

  return { query, querySet, queryRemove }
}

/**
 * HOC for using useQueryString inside class components
 * @param {Component} Component
 * @param {useQueryString params} settings
 * How to use: need to cover a component and put settings
 * Example: export default withQueryStringHook(<Component />, settings)
 * After it you can get a query cortege from component props
 */
export const withQueryStringHook = (Component, settings) => props => {
  const { location } = props
  const query = useQueryString({ ...settings, location })
  return <Component {...props} query={query} />
}

export default useQueryString
