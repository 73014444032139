import React from 'react'
import PropTypes from 'prop-types'

export const Divider = props => {
  const { className, color, margin, height, isVertical = false, width } = props
  const style = isVertical
    ? {
        display: 'flex',
        margin: margin || '0px 20px',
        width: width || 'inherit',
        background: color || '#4a4a4a',
        alignSelf: 'stretch',
      }
    : {
        margin: margin || '20px 0px',
        background: color || '#4a4a4a',
        height: height || '1px',
        width: width || 'inherit',
      }
  return <div className={`d3-divider${className ? ` ${className}` : ''}`} style={style} />
}

Divider.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
  height: PropTypes.string,
  isVertical: PropTypes.bool,
  width: PropTypes.string,
}

export default Divider
