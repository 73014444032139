import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import { getFormattedDateString } from 'utils/time'
import { Button, Container, Divider, Popup, AccountSetupTooltip, Checkbox } from 'components/UIElements'
import TemporaryPassword from './TemporaryPassword'

const AccountSettings = ({
  baseSiteID,
  canResendInviteOrResetPw,
  canWrite,
  checkedCohorts,
  checkedSites,
  consentRequired,
  generateTempPassword,
  isEmailless,
  noPII,
  openModal,
  participant,
  participantDataFields,
  ptpSiteID,
  requestSending,
  saveToDatabase,
  study,
  studyID,
  submitCancelDeactivation,
  tempPwdLoading,
  fetchPtp,
}) => {
  const {
    opt_out,
    username,
    fname,
    lname,
    id,
    deactivation_request_timestamp,
    registration_timestamp,
    last_updated,
    manual_consent: manualConsent,
  } = participant
  const isPtpDisable = opt_out || false
  const name = noPII ? username : `${fname} ${lname}`

  const ModalContent = ({ isDisable }) => {
    const modalText = isDisable ? STRINGS.enableAccountDesc : STRINGS.disableAccountDesc
    return (
      <div className='flexed column'>
        {!isDisable && <i className='fas fa-exclamation-circle fa-4x' />}
        <h4>
          {isDisable ? STRINGS.enable : STRINGS.disable} {name}
        </h4>
        <p>{modalText}</p>
      </div>
    )
  }

  const onToggle = () => {
    openModal({
      content: <ModalContent isDisable={isPtpDisable} noPII={noPII} participant={participant} />,
      className: MODAL_CLASSES_MAP.accountSettings,
      confirmBtnClassName: isPtpDisable ? '' : 'orange',
      confirmButton: isPtpDisable ? MODAL_BUTTONS_MAP.enable : MODAL_BUTTONS_MAP.disable,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      onConfirm: () => {
        saveToDatabase({
          studyID: study.id,
          siteID: baseSiteID,
          participant: {
            ...participant,
            opt_out: !isPtpDisable,
          },
          checkedCohorts,
          participantID: participant.id,
          consentRequired,
          sites: checkedSites,
          pDataFields: participantDataFields,
          isNotLeaving: true,
          ptpField: 'opt_out',
        })
      },
    })
  }

  const onSubmitManualConsent = async () => {
    await saveToDatabase({
      studyID: study.id,
      siteID: baseSiteID,
      participant: {
        ...participant,
        manual_consent: !manualConsent,
      },
      checkedCohorts,
      participantID: participant.id,
      consentRequired,
      sites: checkedSites,
      pDataFields: participantDataFields,
      isNotLeaving: true,
    })
    await fetchPtp(study.id, ptpSiteID, participant.id)
  }

  const onRejectAccount = () => {
    openModal({
      content: `Reject account deletion request from ${name}`,
      className: MODAL_CLASSES_MAP.accountDeletion,
      confirmButton: STRINGS.confirmAndRejectRequest,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      onConfirm: () => {
        submitCancelDeactivation({ ptpId: id, studyID, siteID: ptpSiteID })
      },
    })
  }

  return (
    <>
      {canResendInviteOrResetPw ? (
        <>
          <div className='flexed'>
            <div className='flexed end-justified'>
              <div className='title account-title no-margin' style={{ fontWeight: 'bold' }}>
                {STRINGS.accountSettingsCap}
              </div>
              {isEmailless && (
                <Popup
                  className='box-popup account-setup-info'
                  align='left'
                  position='bottom'
                  hover
                  dark
                  trigger={<i className='fas fa-info-circle' />}>
                  <AccountSetupTooltip
                    url={study.config?.complete_account_setup_url}
                    fileName='complete_account_setup_url.pdf'
                  />
                </Popup>
              )}
            </div>
            <span>
              {STRINGS.profileCreated}: {getFormattedDateString(registration_timestamp)}
            </span>
          </div>
          <TemporaryPassword
            lastUpdated={last_updated}
            participant={participant}
            studyID={studyID}
            generateTempPassword={generateTempPassword}
            tempPwdLoading={tempPwdLoading}
            isEmailless={isEmailless}
            study={study}
          />
        </>
      ) : null}
      {canWrite ? (
        <Container className='account-settings'>
          <div className='account-content flexed'>
            <div className='account-body'>
              {isPtpDisable && <i className='fas fa-lock' />}
              <div className='account-text'>
                <h6>{isPtpDisable ? STRINGS.accountDisabled : STRINGS.accountEnabled}</h6>
                <p className='account-text-description'>{STRINGS.accountDisabledDesc}</p>
              </div>
            </div>
            <div className='cta'>
              <Button
                grey
                content={isPtpDisable ? STRINGS.enableAccount : STRINGS.disableAccount}
                onClick={onToggle}
                loading={requestSending}
                disabled={requestSending}
              />
            </div>
          </div>
          {deactivation_request_timestamp && (
            <>
              <Divider height='2px' color='#e5e3e3' />
              <div className='account-content flexed'>
                <div className='account-body'>
                  <div className='account-text'>
                    <h6>{STRINGS.accountDeletion}</h6>
                    <p>{STRINGS.accountDeletionDesc}</p>
                  </div>
                </div>
                <div className='cta'>
                  <Button
                    onClick={() => browserHistory.push(`/studies/${studyID}/data-correction`)}
                    grey
                    id='submit-dcf'
                    content={STRINGS.submitADcf}
                  />
                  <Button onClick={onRejectAccount} className='reject' grey content='Reject Account Deletion' />
                </div>
              </div>
            </>
          )}
        </Container>
      ) : null}
      <Container className='account-settings'>
        <Checkbox
          id='manual-consent'
          label={STRINGS.manualConsentLabel}
          checked={!!manualConsent}
          disabled={isPtpDisable}
          onClick={onSubmitManualConsent}
        />
      </Container>
    </>
  )
}

AccountSettings.propTypes = {
  baseSiteID: PropTypes.number,
  checkedCohorts: PropTypes.object,
  checkedSites: PropTypes.object,
  closeModal: PropTypes.func,
  canResendInviteOrResetPw: PropTypes.bool,
  canWrite: PropTypes.bool,
  consentRequired: PropTypes.bool,
  generateTempPassword: PropTypes.func,
  isEmailless: PropTypes.bool,
  noPII: PropTypes.bool,
  openModal: PropTypes.func,
  participant: PropTypes.object,
  participantDataFields: PropTypes.object,
  ptpSiteID: PropTypes.number,
  requestSending: PropTypes.bool,
  saveToDatabase: PropTypes.func,
  study: PropTypes.object,
  studyID: PropTypes.number,
  submitCancelDeactivation: PropTypes.func,
  tempPwdLoading: PropTypes.bool,
  updateField: PropTypes.func,
}

export default AccountSettings
