export const RECURRING_AFTER_TIME_OPTION_DEFAULT = {
  'n-days': { interval: 1, interval_type: 'days', scheme: 'n-days', visible_days: 1 },
  'n-hours': { interval: 1, interval_type: 'hours', scheme: 'n-hours', visible: 1 },
  'n-minutes': { interval: 1, interval_type: 'minutes', scheme: 'n-minutes', visible: 1 },
}

/**
 * Get the keys of the time option recurring object without scheme field
 * @param {String} key
 * @returns array
 */
export const validateRecurringFields = key =>
  Object.keys(RECURRING_AFTER_TIME_OPTION_DEFAULT[key]).filter(key => key !== 'scheme')

export const UNIFICATION_REPEAT_TIME_UNIT_OPTION = [
  { key: 'n-days', text: 'days' },
  { key: 'n-hours', text: 'hours' },
  { key: 'n-minutes', text: 'minutes' },
]

export const MINS_MULTIPLIER_MAP = {
  'n-minutes': 1,
  'n-hours': 60,
  'n-days': 60 * 24,
}

export const REPEAT_TIME_UNIT_OPTION = [{ key: 'n-days', text: 'days' }]

export const isRepeatTimeUnitOpt = scheme => REPEAT_TIME_UNIT_OPTION.some(opt => opt.key === scheme)
export const isUnificationRepeatTimeUnitOpt = scheme =>
  UNIFICATION_REPEAT_TIME_UNIT_OPTION.some(opt => opt.key === scheme)
export const isHours = scheme => scheme === 'n-hours'

export const TIME_UNIT_OPTIONS = [
  {
    key: 'days',
    text: 'days',
  },
  {
    key: 'weeks',
    text: 'weeks',
  },
]

export const DAY_MULTIPLIER_MAP = {
  days: 1,
  weeks: 7,
  months: 30,
}

export const getInitialValues = (mins, minInitVal = 0) => {
  const initialValObj = { key: 'n-minutes', val: minInitVal }
  if (!mins) return initialValObj
  if (mins < 60 || mins % 60 !== 0) {
    initialValObj.val = mins
  } else if (mins / 60 >= 24) {
    initialValObj.key = 'n-days'
    initialValObj.val = mins / (60 * 24)
  } else {
    initialValObj.key = 'n-hours'
    initialValObj.val = mins / 60
  }
  return initialValObj
}

export const TIMEOUT_WINDOW = {
  draft: { text: 'Draft', value: null },
  review: { text: 'Review', value: null },
  edit: { text: 'Edit', value: null },
}

const dayOptions = [
  { key: 1, value: 1, text: '1' },
  { key: 7, value: 7, text: '7' },
  { key: 14, value: 14, text: '14' },
  { key: 30, value: 30, text: '30' },
]

export default dayOptions
