import React from 'react'
import PropTypes from 'prop-types'
import { AutocompleteSearch, Button, Checkbox, Dropdown } from 'components/UIElements'
import {
  formatDomainsToList,
  formatQuestionDomainsToCheckedObject,
  formatQuestionDomainsToTagList,
  SELECT_MULTIPLE_CALC_TYPE_ARR,
} from '../../../Scoring/utils/domainUtils'
import '../../../EditClinro/stylesheets/ScoringPanel.scss'

/**
 * This is the last item in the autocomplete search dropdown list that will either
 * be the prompt to the user to type in a new domain or be the button that adds a
 * new domain.
 *  */
const renderBottomItem = (list, createNewDomain) => {
  return (searchTerm, clearSearchTerm, domainEntryInputRef) => {
    // Trim the search term to prevent creating tags with empty strings
    if (searchTerm.trim() === '') {
      return <li className='domain-placeholder'>TYPE TO CREATE A NEW DOMAIN CLASS</li>
    }
    if (list.find(item => item.text === searchTerm) === undefined) {
      return (
        <Button
          noStyling
          type='button'
          className='bottom-item flexed start-justified'
          onClick={() => {
            createNewDomain(searchTerm)
            // Callbacks to clear search and focus back to input to begin new search
            if (clearSearchTerm) clearSearchTerm()
            if (domainEntryInputRef) domainEntryInputRef.focus()
          }}>
          <strong className='black'>Create</strong>
          <div>{searchTerm}</div>
        </Button>
      )
    }
  }
}

const toggleLabel = (
  <>
    <strong>SCORING</strong>
    <div>Assign numeric values to answers</div>
  </>
)

const DiaryScoringPanel = props => {
  const {
    createNewDomain,
    domains = {},
    formula,
    hasScoring,
    hasCalcDropdown,
    onToggleScoring,
    onUpdateItemFormula,
    questionDomains,
    onToggleDomain,
  } = props

  // Instrument domains list
  const domainList = formatDomainsToList(domains)

  // Domains assigned to question
  const checked = formatQuestionDomainsToCheckedObject(questionDomains)

  // List used for question domain tags
  const assignedDomains = formatQuestionDomainsToTagList(questionDomains, domains)

  // These props will be used in the AutocompleteTags element in the AutocompleteSearch
  const tagsProps = {
    toggleItem: onToggleDomain,
    tags: assignedDomains,
  }

  return (
    <div className={`scoring panel${hasScoring ? ' on' : ''}`}>
      <Checkbox
        checked={hasScoring}
        className='flexed center-aligned'
        label={toggleLabel}
        onClick={onToggleScoring}
        toggle
      />
      {hasScoring && (
        <>
          <label className='label-small' htmlFor='domain-autocomplete'>
            Assign Domain(s)
          </label>
          <AutocompleteSearch
            addItemOnEnter
            checked={checked}
            createItem={createNewDomain}
            deleteItemOnBackspace
            id='domain-autocomplete'
            list={domainList}
            renderBottomItem={renderBottomItem(domainList, createNewDomain)}
            tagsProps={tagsProps}
            toggleItem={onToggleDomain}
            useCaretIcon
            focusOnToggle
          />
        </>
      )}
      {hasScoring && hasCalcDropdown && (
        <>
          <label className='label-small' htmlFor='domain-autocomplete'>
            Calculation of Values within Question
          </label>
          <Dropdown
            options={SELECT_MULTIPLE_CALC_TYPE_ARR}
            onSelect={item => onUpdateItemFormula(item.value)}
            selected={formula}
          />
        </>
      )}
    </div>
  )
}

DiaryScoringPanel.propTypes = {
  createNewDomain: PropTypes.func,
  domains: PropTypes.objectOf(PropTypes.object),
  hasCalcDropdown: PropTypes.bool,
  hasScoring: PropTypes.bool,
  onToggleDomain: PropTypes.func,
  onToggleScoring: PropTypes.func,
  onUpdateItemFormula: PropTypes.func,
  questionDomains: PropTypes.arrayOf(PropTypes.string),
}

export default DiaryScoringPanel
