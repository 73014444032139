import React from 'react'
import { pluralize } from 'utils/misc'
import { Button, Popup } from 'components/UIElements'
import Icons from './Icons'

class SortTypeSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = { sortBy: this.props.sortBy }
    this.sections = [
      {
        title: 'Sort By Instrument Inactivity',
        types: [
          {
            key: 'num_received',
            text: 'Incomplete Instrument (Received)',
            icon: Icons.SENT(),
          },
          {
            key: 'num_expired',
            text: 'Expired Instrument',
            icon: Icons.EXPIRED(),
          },
        ],
      },
      {
        title: 'Sort By The Largest Gap In',
        types: [
          {
            key: 'largest_completion_gap',
            text: 'Completed Instrument',
            icon: Icons.COMPLETED(),
          },
          {
            key: 'largest_open_app_gap',
            text: 'Opened App',
            icon: Icons.APP_OPENED(),
          },
          {
            key: 'largest_announcement_gap',
            text: 'Announcement Received',
            icon: Icons.ANNOUNCEMENT_SENT(),
          },
        ],
      },
    ]
  }

  onSelectType = sortBy => {
    this.setState({ sortBy })
  }

  renderButton = info => {
    return (
      <div
        onClick={() => this.onSelectType(info.key)}
        key={info.key}
        className={`clickable sort-type-button${this.state.sortBy === info.key ? ' selected' : ''}`}>
        {info.icon}
        {info.text}
      </div>
    )
  }

  onCancel = () => {
    this.setState({ sortBy: this.props.sortBy })
  }

  onApply = () => {
    this.props.applySortBy(this.state.sortBy)
  }

  render() {
    const dayText = pluralize(this.state.range, 'day', 'days', false)
    return (
      <Popup
        className='data-gap-popup'
        trigger={
          <div className='data-gap-popup-button'>
            Sort By <i className='fas fa-sort' />
          </div>
        }>
        {this.sections.map(section => {
          return (
            <div key={section.title}>
              <h5>{section.title}</h5>
              {section.types.map(info => {
                return this.renderButton(info)
              })}
            </div>
          )
        })}
        <div className='button-list flex-end selector'>
          <Button className='close-trigger' onClick={this.onCancel} grey content='Cancel' />
          <Button className='close-trigger' onClick={this.onApply} content='Apply' />
        </div>
      </Popup>
    )
  }
}

export default SortTypeSelector
