import React from 'react'
import PropTypes from 'prop-types'
import ParticipantTable from './ParticipantTable'

class ParticipantList extends React.Component {
  componentWillUnmount() {
    const { updateSearchTerm } = this.props
    updateSearchTerm('')
  }

  render() {
    return <ParticipantTable {...this.props} />
  }
}

ParticipantList.propTypes = {
  participantList: PropTypes.arrayOf(PropTypes.array),
  updateSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
  value: PropTypes.number,
  studyID: PropTypes.number,
  siteID: PropTypes.number,
  downloadData: PropTypes.func,
}

export default ParticipantList
