import { useEffect, useRef } from 'react'

/**
 * React please run me if 'deps' changes, but not on initial render
 * @param {Function} func function inside the useEffect
 * @param {Any} deps dependencies
 */
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}

export default useDidMountEffect
