import { setPageTitle } from 'utils/misc'
import { injectReducer } from 'store/reducers'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { setSubroute } from 'store/navbar'
import sitesReducer, { actions as siteActions } from '../SitesPage/modules/Sites'
import EditSiteContainer from '../SitesPage/containers/EditSiteContainer'

export default store => ({
  path: 'edit/:editSiteID',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducer(store, {
          key: 'sites',
          sitesReducer,
        })
        const { studyID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Edit Site | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(setSubroute('sites'))
        store.dispatch(siteActions.fetchSites(studyID))
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'sites',
              )(UserHasScope(['wu'], EditSiteContainer, false, studyID, null, currentStudy)),
            ),
          ),
        )
      },
      'editsite',
    )
  },
})
