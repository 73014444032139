import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { Button, Container, Input, Popup, RichTextEditor } from 'components/UIElements'
import { DEFAULT_LANG, MODAL_BUTTONS_MAP, MODAL_CONTENT_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import generateLocalesMap from 'utils/locales'
import { compareValues } from 'utils/misc'
import UploadTranslationInput from '../../../../Instruments/routes/InstrumentsPage/components/UploadTranslationInput'

const DEFAULT_LIST = [DEFAULT_LANG]

const LanguageButton = ({ isLatest, onClick, text }) => {
  const ref = useRef()

  useEffect(() => {
    if (isLatest) ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [isLatest])
  return (
    <Button ref={isLatest ? ref : null} className='lang-btn' onClick={onClick}>
      {text}
    </Button>
  )
}

const MessageSection = ({
  addAnnouncementTranslation,
  clearAnnouncementErrors,
  closeModal,
  content,
  deleteAnnouncementTranslation,
  disabled,
  disabledLangs,
  downloadAnnouncementTranslationTemplate,
  enforcedLanguage,
  errors,
  isSMS,
  locales,
  openModal,
  toggleAnnouncementTranslations,
  translations,
  title,
  updateAnnouncementField,
  updateAnnouncementTranslation,
  uploadAnnouncementTranslations,
  checkedSites,
  sitesList,
}) => {
  const headingRef = useRef(null)

  useEffect(() => {
    return clearAnnouncementErrors
  }, [])

  useEffect(() => {
    if (errors.translations || errors.title) {
      headingRef.current.scrollIntoView()
    }
  }, [errors])

  const localesMap = generateLocalesMap(locales)
  const translationsEnabled = !!translations
  const [currentLang, setCurrentLang] = useState(DEFAULT_LANG)
  const translationsList = translations ? Object.keys(translations) : DEFAULT_LIST
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [deleting, setDeleting] = useState()

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [subject, setSubject] = useState('')
  const fieldsDisabled = disabledLangs ? disabledLangs.includes(currentLang) : disabled

  useEffect(() => {
    if (translations) {
      setEditorState(translations[currentLang].content)
      setSubject(translations[currentLang].title)
    } else {
      setEditorState(content)
      setSubject(title)
    }
  }, [translations, currentLang, deleting])

  const selectedLanguageList = translations
    ? translationsList.map((lang, i) => {
        const selected = lang === currentLang
        const hasError = errors.translations && errors.translations[lang] && translations[lang].title === ''
        const isDisabledLang = disabledLangs?.includes(lang)
        const isDefaultLang = lang === DEFAULT_LANG
        return (
          <div
            key={`${localesMap[lang]}-lang-btn-${lang}`}
            className={`lang-btn-wrapper flexed center-aligned${selected ? ' selected' : ''}${
              isDisabledLang ? ' disabled' : ''
            }${isDefaultLang ? ' is-default' : ''}`}>
            {translationsList.length > 1 && !isDefaultLang && !isDisabledLang && (
              <i
                className='fas fa-trash'
                onClick={() => {
                  setCurrentLang(DEFAULT_LANG)
                  setDeleting(!deleting)
                  deleteAnnouncementTranslation(lang)
                }}
              />
            )}
            {selected ? <i className='fas fa-caret-right' /> : <div />}

            <LanguageButton
              className='lang-btn'
              onClick={() => setCurrentLang(lang)}
              text={localesMap[lang]}
              isLatest={translationsList.length - 1 === i}
            />
            {hasError && <i className='fas fa-exclamation-triangle' />}
          </div>
        )
      })
    : DEFAULT_LIST

  const _toggleAnnouncementTranslations = () => {
    clearAnnouncementErrors()
    setUploadedFileName('')
    if (translationsEnabled) {
      toggleAnnouncementTranslations()
      setCurrentLang(DEFAULT_LANG)
    } else {
      toggleAnnouncementTranslations(DEFAULT_LANG)
    }
  }

  const languageSelectionPopup = () => {
    const currentLanguages = translations ? Object.keys(translations) : []
    const useSitesLanguages = enforcedLanguage && !!checkedSites[0]
    // filter the sitesList by the checkedSites and return an array of the site languages without duplicates
    const filterSites = useSitesLanguages
      ? [
          ...new Set(
            sitesList
              .filter(site => checkedSites.includes(site[0].value))
              .map(siteArr =>
                siteArr
                  .filter(siteOption => siteOption.key === 'enforcedLanguage')
                  .map(option => option.value)
                  .flat(),
              )
              .flat(),
          ),
        ]
      : null
    const languageList = useSitesLanguages
      ? enforcedLanguage.languages.filter(language => filterSites.includes(language.key))
      : enforcedLanguage
      ? enforcedLanguage.languages
      : locales
    const filteredLanguageList = languageList.filter(locale => !currentLanguages.includes(locale.id))
    const languageOptionList = filteredLanguageList.map(({ id, text }) => {
      return (
        <Button
          key={id}
          className='popup-list-btn'
          content={text}
          onClick={() => {
            addAnnouncementTranslation(id)
            setCurrentLang(id)
          }}
        />
      )
    })
    return (
      <Popup
        disabled={languageOptionList.length === 0}
        isButtonList
        noPointer
        position='right'
        trigger={<Button iconBefore='fas fa-plus' id='add-language' content='Add language...' />}>
        {languageOptionList}
      </Popup>
    )
  }

  const _onEditorStateChange = newEditorState => {
    setEditorState(newEditorState)
    if (!translations) {
      updateAnnouncementField('content', newEditorState)
    } else {
      updateAnnouncementTranslation({ key: 'content', localeId: currentLang, value: newEditorState })
    }
  }

  const _updateAnnouncementTitle = value => {
    setSubject(value)
    if (!translations) {
      updateAnnouncementField('title', value, 'title')
    } else {
      updateAnnouncementTranslation({ key: 'title', localeId: currentLang, value })
    }
  }

  const onOpenUploadModal = () => {
    const successCb = fileName => {
      if (fileName) setUploadedFileName(fileName)
      setCurrentLang(DEFAULT_LANG)
      openModal({
        closeOnBackgroundClick: true,
        content: MODAL_CONTENT_MAP.translationsUploadPrompt,
        confirmButton: MODAL_BUTTONS_MAP.okay,
        className: MODAL_CLASSES_MAP.confirmation,
        hideCancelButton: true,
        onConfirm: closeModal,
      })
    }
    openModal({
      noButtons: true,
      closeOnBackgroundClick: true,
      children: (
        <UploadTranslationInput
          forAnnouncement
          closeModal={closeModal}
          uploadTranslation={file => uploadAnnouncementTranslations(file, () => successCb(file.name), enforcedLanguage)}
        />
      ),
    })
  }

  if (disabled) selectedLanguageList.sort(compareValues('key'))

  return (
    <>
      <div className='flexed'>
        <h4 className='message-heading' ref={headingRef}>
          Message
        </h4>
        {isSMS || !translationsEnabled ? (
          <div />
        ) : (
          <div className='flexed'>
            <Button
              light
              iconBefore='fas fa-cloud-download-alt'
              id='download-csv-template'
              content='CSV template'
              onClick={() => {
                downloadAnnouncementTranslationTemplate(translations)
              }}
            />
            <Button
              disabled={disabled}
              light
              iconBefore='fas fa-cloud-upload-alt'
              id='import-csv'
              content='Import CSV...'
              onClick={onOpenUploadModal}
            />
          </div>
        )}
      </div>
      <Container>
        {!isSMS ? (
          <div className='flexed announcement-translations-section start-aligned'>
            {translationsEnabled && (
              <div className='flexed column language-sidebar side-button-list'>
                <div className='lang-list'>{selectedLanguageList}</div>
                {languageSelectionPopup()}
              </div>
            )}
            <div className='message-content'>
              <Input 
                id='annoucement-subject'
                charLimit={100}
                className='announcement-subject'
                hasError={
                  errors.translations
                    ? translations[currentLang].title === '' && errors.translations[currentLang]
                    : errors.title
                }
                errorText={errors.translations ? errors.translations[currentLang] : errors.title}
                disabled={fieldsDisabled}
                onChange={_updateAnnouncementTitle}
                value={subject}
                label='Subject'
              />
              <p className='label-small'>{isSMS ? 'SMS Text' : 'Announcement Content'}</p>
              <RichTextEditor
                readOnly={fieldsDisabled}
                showToolbar
                onChange={_onEditorStateChange}
                editorState={editorState}
                hasLinks
                currentLang={currentLang}
              />
              {translationsEnabled && uploadedFileName && (
                <div className='uploaded-filename flexed end-justified center-aligned'>
                  <i className='fas fa-check-circle' />
                  <span>{` Imported from ${uploadedFileName}`}</span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <p className='label-small'>{isSMS ? 'SMS Text' : 'Announcement Content'}</p>
            <RichTextEditor
              readOnly={disabled}
              showToolbar={!isSMS}
              stripPastedStyles={isSMS}
              onChange={newEditorState => updateAnnouncementField('content', newEditorState)}
              editorState={content}
              noStyling={isSMS}
            />
            <Input
              charLimit={30}
              id='message-label-input'
              className='sms-label'
              hasError={errors.title}
              errorText={errors.title}
              disabled={disabled}
              onChange={value => updateAnnouncementField('title', value, 'title')}
              value={title}
              label='Message Label'
              subTitle='Participants will not see this'
            />
          </>
        )}
        {!disabled && !isSMS && (
          <Button
            link
            id={`${translationsEnabled ? 'clear-all' : 'include'}-translations`}
            className='translation-toggle'
            content={`${translationsEnabled ? 'Clear all' : 'Include'} translations`}
            onClick={_toggleAnnouncementTranslations}
          />
        )}
      </Container>
    </>
  )
}

LanguageButton.propTypes = {
  isLatest: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

MessageSection.propTypes = {
  addAnnouncementTranslation: PropTypes.func,
  clearAnnouncementErrors: PropTypes.func,
  closeModal: PropTypes.func,
  content: PropTypes.object,
  deleteAnnouncementTranslation: PropTypes.func,
  disabled: PropTypes.bool,
  disabledLangs: PropTypes.arrayOf(PropTypes.string),
  downloadAnnouncementTranslationTemplate: PropTypes.func,
  enforcedLanguage: PropTypes.shape({
    default_language: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.shape({ title: PropTypes.string, translations: PropTypes.object }),
  isSMS: PropTypes.bool,
  locales: PropTypes.arrayOf(PropTypes.object),
  openModal: PropTypes.func,
  title: PropTypes.string,
  toggleAnnouncementTranslations: PropTypes.func,
  translations: PropTypes.objectOf(PropTypes.object),
  updateAnnouncementField: PropTypes.func,
  updateAnnouncementTranslation: PropTypes.func,
  uploadAnnouncementTranslations: PropTypes.func,
  checkedSites: PropTypes.array,
  sitesList: PropTypes.array,
}

export default MessageSection
