import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Textarea } from 'components/UIElements'
import { MEDIA_TYPE_MAP, MODAL_CLASSES_MAP, MAX_PHOTO_LIMIT_FIELD_DEFAULT_VALUE } from 'utils/constants'
import MediaUpload from 'containers/mediaUploadContainer'

const phoneImg = require('../../../../../../../../../../../assets/phone.png')
const tabletImg = require('../../../../../../../../../../../assets/tablet.png')

const ImageCaptureView = props => {
  const {
    addPreviewImage,
    closeModal,
    deletePreviewImage,
    isClinro,
    item,
    itemId,
    openModal,
    preview,
    studyID,
    uploadImage,
    updateItem,
  } = props
  const { preview_images, previewImagesSrc, attributes = {} } = item
  const { max_photo_limit: maxPhotoLimit = MAX_PHOTO_LIMIT_FIELD_DEFAULT_VALUE } = attributes
  const [hover, setHover] = useState({ isHover: false, hoveredImage: null })
  const { isHover, hoveredImage } = hover

  const onChangeItem = (value, key) => {
    const newItem = { ...item }
    newItem[key] = value
    updateItem(itemId, newItem)
  }

  const renderSampleImages = () => {
    const imageButtons = []
    for (let imageOrder = 0; imageOrder <= 2; imageOrder++) {
      if (!preview_images || preview_images.length === 0 || !preview_images[imageOrder]) {
        const onUpload = payload => {
          addPreviewImage({ ...payload, imageOrder, itemId })
        }
        const imageUploadButton = (
          <Button
            noStyling
            className='flexed column center-justified'
            content='Upload'
            disabled={preview}
            icon='far fa-image'
            key={`image-upload-button-${imageOrder}`}
            onClick={() => {
              openModal({
                content: (
                  <MediaUpload
                    className='photo-submission'
                    confirmButton='Submit'
                    mediaTypes={[MEDIA_TYPE_MAP.image]}
                    noTabs
                    heading='Provide example images'
                    subheading='upload image'
                    onClose={closeModal}
                    noAltTextReq
                    callback={onUpload}
                    uploadImage={uploadImage}
                    studyID={studyID}
                    showButtons
                    isCrop
                  />
                ),
                className: MODAL_CLASSES_MAP.photoSubmissionUploader,
                closeOnBackgroundClick: false,
                noButtons: true,
              })
            }}
          />
        )
        imageButtons.push(imageUploadButton)
      } else {
        const renderedImage = (
          <div
            className='rendered-preview'
            key={preview_images[imageOrder]}
            onMouseEnter={() => setHover({ isHover: true, hoveredImage: preview_images[imageOrder] })}
            onMouseLeave={() => setHover({ isHover: false, hoveredImage: null })}>
            {isHover && hoveredImage === preview_images[imageOrder] && (
              <Button
                className='trash-icon'
                disabled={preview}
                noStyling
                icon='fas fa-trash-alt'
                onClick={() => deletePreviewImage(itemId, preview_images[imageOrder], imageOrder)}
              />
            )}
            <img src={previewImagesSrc[preview_images[imageOrder]]} alt={`sample-${imageOrder}`} />
          </div>
        )
        imageButtons.push(renderedImage)
      }
    }
    return <div className='samples flexed start-justified'>{imageButtons}</div>
  }

  return (
    <div>
      <div className='non-select-preview flexed column center-justified image-capture placeholder'>
        <div className='corner-frame-top'>
          <div className='path left-top' />
          <div className='path right-top' />
        </div>
        <i className='far fa-images' />
        <div className='corner-frame-bottom'>
          <div className='path left-bottom' />
          <div className='path right-bottom' />
        </div>
        <p>
          {isClinro
            ? `Clinicians can collect up to ${maxPhotoLimit} photos per session.`
            : `Participant photos will be collected (up to ${maxPhotoLimit})`}
        </p>
      </div>
      <div className='non-select-preview flexed column stretch-aligned image-capture example-images'>
        <div className='label flexed'>
          <span>example images</span>
          <span>(optional)</span>
        </div>
        {renderSampleImages()}
      </div>
      {isClinro && (
        <div className='non-select-preview flexed column stretch-aligned image-capture instructions'>
          <div className='label flexed'>
            <span>instructions/comments</span>
            <span>(optional)</span>
          </div>
          <div className='textarea-container'>
            <Textarea
              charlimit={150}
              disabled={preview}
              placeholder='No comment'
              value={item?.comments || ''}
              onChange={value => onChangeItem(value, 'comments')}
            />
          </div>
        </div>
      )}
      <div className='non-select-preview flexed column stretch-aligned image-capture camera-tip'>
        <div className='label flexed'>
          <span>camera tip</span>
          <span>(optional)</span>
        </div>
        <div className='flexed'>
          <div className='textarea-container'>
            <Textarea
              charlimit={150}
              disabled={preview}
              placeholder='Try to fit the image within the frame above and ensure the center of the photo is aligned with the crosshair.'
              value={item?.camera_tip || ''}
              onChange={value => onChangeItem(value, 'camera_tip')}
            />
          </div>
          <img className={isClinro ? 'tablet' : 'phone'} src={isClinro ? tabletImg : phoneImg} alt='phone-img' />
        </div>
      </div>
    </div>
  )
}

export default ImageCaptureView

ImageCaptureView.propTypes = {
  addPreviewImage: PropTypes.func,
  closeModal: PropTypes.func,
  deletePreviewImage: PropTypes.func,
  isClinro: PropTypes.bool,
  item: PropTypes.shape({
    preview_images: PropTypes.arrayOf(PropTypes.string),
    previewImagesSrc: PropTypes.object,
  }),
  itemId: PropTypes.string,
  preview: PropTypes.bool,
  studyID: PropTypes.number,
  uploadImage: PropTypes.func,
  updateItem: PropTypes.func,
  openModal: PropTypes.func,
}
