import { connect } from 'react-redux'
import { schedulerActions } from '../../../modules/Instrument'

const mapState = state => {
  return {
    ...state.instrumentReducer.instrument.metadata.schedule,
    errors: state.instrumentReducer.errors,
  }
}

export default component => connect(mapState, schedulerActions)(component)
