import { fromUTCMidnightSeconds, currentDayStartInUTC } from 'utils/time'
import { PROPERTIES } from 'routes/Studies/routes/Study/routes/Instruments/utils/PropertyFields'
import moment from 'moment'
import { _isObject } from 'utils/object'
import {
  CONSENT_SIGNEE_MAP,
  DEPLOYMENT_KEYS,
  GROUP_TYPE_MAP,
  INSTRUMENT_ERROR_LIST,
  QUESTION_TYPE_MAP,
  TRIGGER_TYPE_MAP,
  urlRegex,
} from 'utils/constants'
import STRINGS from 'utils/strings'

import {
  getIsFormulaIncomplete,
  getDoesFormulaContainInvalidChars,
} from '../routes/Studies/routes/Study/routes/Instruments/routes/Instrument/routes/Scoring/utils/scoring'

import {
  isRepeatTimeUnitOpt,
  validateRecurringFields,
} from '../routes/Studies/routes/Study/routes/Instruments/routes/Instrument/routes/Scheduler/constants/schedulerConstants'

const NEVER = DEPLOYMENT_KEYS.never
const NOW = DEPLOYMENT_KEYS.now
const RELATIVE_TO_FIRST_LOGIN = DEPLOYMENT_KEYS.firstLogin
const RELATIVE_TO_SEND_IDEAL = DEPLOYMENT_KEYS.relativeToSendIdeal

export const checkIsInvalidSignee = (signeeType, siteRolesStatus) => {
  const { witness_status: witnessStatus, clinician_status: clinicianStatus } = siteRolesStatus
  let hasError = false
  if (signeeType === CONSENT_SIGNEE_MAP.witness && !witnessStatus) {
    hasError = true
  }
  if (signeeType === CONSENT_SIGNEE_MAP.clinro && !clinicianStatus) {
    hasError = true
  }
  return hasError
}

//
// Question Item Errors
//
export function getQuestionErrors({
  question,
  editorState,
  editorStates,
  isSurveyOrDiary,
  siteRolesStatus,
  isEditConsent,
}) {
  // check label
  const errors = {}

  if (question.attributes?.hasScoring) {
    if (question.choices_order.some(id => question.choices[id].score_value === ''))
      errors.blankScoreErr = 'Score values cannot be blank'
  }
  if (
    question.type === QUESTION_TYPE_MAP.numericRatingScale &&
    question.attributes?.hasOptOut &&
    !question.attributes?.opt_out
  ) {
    errors.blankOptOutErr = 'Opt out value cannot be blank'
  }
  if (question.type === QUESTION_TYPE_MAP.foodEntry && question.food_data_review) {
    const foodDataFieldsValues = Object.values(question.food_data_fields)
    const selectedValues = foodDataFieldsValues.filter(value => value)
    if (!selectedValues.length) {
      errors.blankFoodDataFields = 'Food data fields cannot be blank'
    }
  }
  if (question.type !== QUESTION_TYPE_MAP.signature && editorState.getCurrentContent().getPlainText() === '') {
    errors.blankLabelErr = 'Question labels cannot be blank'
  }
  if (question.type === QUESTION_TYPE_MAP.signature) {
    if (getSigneeRequiredCount(question.attributes.signees) < 1) {
      errors.atLeastOneSigneeRequiredErr = STRINGS.requiredSigneeErr
    }
    if (isEditConsent) {
      const signeeArr = Object.values(question.attributes.signees)
      for (let i = 0; i < signeeArr.length; i++) {
        const signee = signeeArr[i]
        const hasInvalidSignee = checkIsInvalidSignee(signee.type, siteRolesStatus)
        if (hasInvalidSignee) {
          errors.invalidSigneesError = STRINGS.invalidSigneePrompt
          break
        }
      }
    }
  }
  if (question.type === QUESTION_TYPE_MAP.pdf && isEditConsent) {
    if (!question.url) {
      errors.noPdfUpload = STRINGS.questionErrorNoPdf
    }
  }

  if ([QUESTION_TYPE_MAP.selectOne, QUESTION_TYPE_MAP.selectMultiple].includes(question.type)) {
    // check blank choices
    if (question.choices_order.some(id => !question.choices[id].label) || question.attributes.other === '') {
      errors.blankChoicesErr = 'Choices cannot be blank'
    }

    if (isSurveyOrDiary) {
      if ([QUESTION_TYPE_MAP.selectOne, QUESTION_TYPE_MAP.selectMultiple].includes(question.type)) {
        if (question.choices_order.some(id => editorStates[id].getCurrentContent().getPlainText() === '')) {
          errors.blankChoicesErr = 'Choices cannot be blank'
        }
      } else if (question.choices_order.some(id => !question.choices[id].label) || question.attributes.other === '') {
        errors.blankChoicesErr = 'Choices cannot be blank'
      }
    }

    // check number of choices
    const numChoices = question.choices_order.length + (question.attributes.other ? 1 : 0)

    if (numChoices < 2) {
      errors.minChoicesErr = 'Must have at least two choices.'
    }
  }

  if (question.type === QUESTION_TYPE_MAP.longListSelectOne) {
    if (question.choices_order.some(id => !question.choices[id].label)) {
      errors.blankChoicesErr = 'Choices cannot be blank'
    }
    if (question.choices_order.length < 10) {
      errors.minChoices = 'Select One question type is recommended for questions with fewer than 10 answer choices'
    }
  }

  if ([QUESTION_TYPE_MAP.likert].includes(question.type)) {
    if (question.choices_order.length === 5) {
      if (question.choices_order.some(id => !question.choices[id].label) || question.attributes.other === '') {
        errors.blankChoicesErr = 'Choices cannot be blank'
      }
    } else {
      const [choiceOne, choiceSeven] = [question.choices_order[0], question.choices_order[6]]
      if (!question.choices[choiceOne].label || !question.choices[choiceSeven].label) {
        errors.blankChoicesErr = 'Choices cannot be blank'
      }
    }
  }

  if ([QUESTION_TYPE_MAP.numericRatingScale].includes(question.type)) {
    if (question.choices_order.some(id => !question.choices[id].value && question.choices[id].value !== 0)) {
      errors.blankValuesErr = 'Values cannot be blank'
    }
    if (question.choices_order.some(id => question.choices[id].value < 0 || question.choices[id].value >= 100)) {
      errors.valuesErr = 'Values cannot be less than 0 or greater than 99'
    }
  }

  if ([QUESTION_TYPE_MAP.matrix].includes(question.type)) {
    if (question.choices_order.some(id => !question.choices[id].label)) {
      errors.blankChoicesErr = 'Choices cannot be blank'
    }
    if (question.questions_order.some(id => editorStates[id].getCurrentContent().getPlainText() === '')) {
      errors.blankQuestionsErr = 'Questions cannot be blank'
    }
  }

  if (question.type === QUESTION_TYPE_MAP.audioRecording) {
    const { attributes } = question
    const { min, max } = attributes

    if ((!min && min !== 0) || (max === '' && max !== 0)) errors.blankDurationErr = 'Enter a valid number'
    if ((!!min && min < 0) || min === 0 || (!!max && max < 0) || max === 0)
      errors.minDurationErr = 'Cannot be less than 1 second'
    if (min > 900 || max > 900) errors.maxDurationErr = 'Cannot exceed 900 seconds (15 minutes)'
    if (min >= max) errors.minExceedMaxErr = 'Maximum must be larger than minimum'
  }

  if (question.type === QUESTION_TYPE_MAP.timer) {
    const { attributes } = question
    const { timer_details = {} } = attributes
    const { time_string } = timer_details

    if (!time_string || time_string === '00:00') {
      errors.zeroMinutesAndSeconds = 'Time cannot be blank'
    }
  }

  if (question.type === QUESTION_TYPE_MAP.multipleField) {
    if (
      question.choices_order.some(id => {
        const { min, max } = question.choices[id]
        return !!max && !!min && min >= max
      })
    ) {
      errors.minMax = 'Minimum value must not exceed maximum value.'
    }
    if (
      question.choices_order.some(id => {
        const { min, max } = question.choices[id]
        return max < 0 || min < 0
      })
    ) {
      errors.negVal = 'Values cannot be negative.'
    }
  }

  // check for logic errors
  const logicError = getLogicErrors(question.logic.show_if)

  if (logicError.length > 0) {
    errors.logicErr = logicError
  }

  if (question.type === QUESTION_TYPE_MAP.vaScale || question.type === QUESTION_TYPE_MAP.vaScale2021) {
    if (question.scale_labels?.top === '' || question.scale_labels?.bottom === '' || !question.scale_labels) {
      errors.VASLabelError = 'Scale labels cannot be blank'
    }
  }

  // check for input range errors
  if (question.input_validation) {
    const { max, min, absolute_time } = question.input_validation
    const keysExist = max !== '' && min !== ''
    if (keysExist && min > max) {
      errors.numericInputRangeError = 'First field cannot be greater than the second'
    }

    if (absolute_time) {
      const { max: _max, min: _min } = absolute_time
      const format = 'HH:mm'
      if (!!_max && !!_min) {
        const maxMom = moment(_max, format)
        const minMom = moment(_min, format)
        const maxIsBeforeMin = maxMom.isBefore(minMom)
        if (maxIsBeforeMin) {
          errors.timeInputRangeError = 'First time must precede the second'
        }
      }
    }
  }

  return errors
}

function getLogicErrors(logic, errors = [], groupNumber = 0) {
  if (!logic) return errors
  const oppositeOps = {
    is_answered: 'is_not_answered',
    is_not_answered: 'is_answered',
    is_selected: 'is_not_selected',
    is_not_selected: 'is_selected',
    '>': '<',
    '<': '>',
    '>=': '<',
    '<=': '>',
  }
  const emptyMessage = 'All logic fields must be filled.'
  const tracker = {}
  for (let i = 0; i < logic.terms.length; i++) {
    const condition = logic.terms[i]
    tracker[condition.question_id] = tracker[condition.question_id] || {}
    tracker[condition.question_id][condition.comparator] = { value: condition.value, logicNumber: i + 1 }
    if (condition.comparator === 'boolean_combine') {
      groupNumber++
      getLogicErrors(condition, errors, groupNumber)
    }
    // add blank field logic error
    if (!errors.includes(emptyMessage) && _hasEmptyLogicField(condition)) {
      errors.push(emptyMessage)
    } else if (logic.operator === 'and') {
      // add conflicting logic errors if there are conflicting logic conditions
      const prevCondition = tracker[condition.question_id]
      const oppositeOp = oppositeOps[condition.comparator]
      if (prevCondition && prevCondition[oppositeOp] && prevCondition[oppositeOp].value === condition.value) {
        errors.push(
          `${groupNumber !== 0 ? `Group ${groupNumber}: ` : ''}Logic condition ${
            prevCondition[oppositeOp].logicNumber
          } conflicts with condition ${i + 1}`,
        )
      }
    }
  }
  return errors
}

// URL ERROR HANDLING

export const getArticleErrors = article => {
  const errors = {}
  const { url, title } = article

  if (title === '') errors.titleErr = 'Article title cannot be empty'
  if (!url) errors.blankURLError = 'Article URL cannot be empty'
  else if (url.includes(' ')) errors.URLError = 'Article URL should not have spaces.'
  else if (!url.match(urlRegex)) errors.invalidUrlError = 'Please enter a valid URL.'
  return errors
}

// SURVEY CONSENT HANDLING

export const getCreateNewConsentErrors = consent => {
  const errors = {}
  const { consent_title: consentName, metadata, consent_type: consentType } = consent
  if (consentType === 'addendum') return errors
  const { display_name: displayName } = metadata
  if (!consentName || consentName === '') errors.consentName = 'Consent Name cannot be empty'
  if (!displayName || displayName === '') errors.displayName = 'Display Name cannot be empty'
  return errors
}

export function getConsentErrors({
  consent,
  editorStates,
  skipQuestionValidation = false,
  ignoreScheduling = false,
  siteRolesStatus = {},
  isEditConsent = false,
}) {
  if (skipQuestionValidation) {
    return getCreateNewConsentErrors(consent)
  }
  const errors = { questions: {} }
  let hasUnskippableSignatureQuestion

  Object.keys(consent.questions).forEach(key => {
    const qErrors = getQuestionErrors({
      question: consent.questions[key],
      editorState: editorStates[key],
      siteRolesStatus,
      isEditConsent,
    })
    if (Object.keys(qErrors).length > 0) errors.questions[key] = qErrors

    if (consent.questions[key].type === 'signature' && Object.keys(consent.questions[key].logic).length === 0) {
      hasUnskippableSignatureQuestion = true
    }
  })

  if (!hasUnskippableSignatureQuestion) {
    errors.noUnskippableSignatureErr =
      'There must be a signature question type in this form. Remove skip logic on this question type or add a signature question without skip logic.'
  }

  if (ignoreScheduling) return errors
  const schedulingErrors = getSchedulingErrors(consent)
  return { ...errors, ...schedulingErrors }
}

// SURVEY ERROR HANDLING

export function getSurveyErrors(survey, editorStates) {
  const errors = { questions: {} }
  if (Object.keys(survey.questions).length < 1) {
    errors.noQuestionsErr = 'Survey must have at least one question.'
  }
  if (!survey.title) {
    errors.titleErr = 'Survey must have a title.'
  }
  Object.keys(survey.questions).forEach(key => {
    const qErrors = getQuestionErrors({
      question: survey.questions[key],
      editorState: editorStates[key],
      editorStates,
      isSurveyOrDiary: true,
    })
    if (Object.keys(qErrors).length > 0) errors.questions[key] = qErrors
  })
  return errors
}
export function getClinroErrors(clinro, editorStates) {
  const errors = { questions: {} }
  if (Object.keys(clinro.questions).length < 1) {
    errors.noQuestionsErr = 'Instrument must have at least one question.'
  }
  if (!clinro.title) {
    errors.titleErr = 'Instrument must have a title.'
  }
  Object.keys(clinro.questions).forEach(key => {
    const qErrors = getQuestionErrors({
      question: clinro.questions[key],
      editorState: editorStates[key],
      editorStates,
    })
    if (Object.keys(qErrors).length > 0) errors.questions[key] = qErrors
  })
  return errors
}
export function getDiaryErrors(diary, editorStates) {
  const errors = { questions: {} }
  if (Object.keys(diary.questions).length < 1) {
    errors.noQuestionsErr = 'Diary must have at least one question.'
  }
  if (!diary.title) {
    errors.titleErr = 'Diary must have a title.'
  }
  Object.keys(diary.questions).forEach(key => {
    const qErrors = getQuestionErrors({
      question: diary.questions[key],
      editorState: editorStates[key],
      editorStates,
      isSurveyOrDiary: true,
    })
    if (Object.keys(qErrors).length > 0) errors.questions[key] = qErrors
  })
  return errors
}

function _hasEmptyLogicField(condition) {
  return (
    (condition.value === '' && !condition.comparator.includes('answered')) ||
    condition.question_id === '' ||
    condition.comparator === '' ||
    condition.choice_id === ''
  )
}

/**
 * Checks that the metadata (minus scheduling) values are valid.
 *  - title cannot be blank
 *  - All metadata UI fields must be present
 * @param metadata
 * @returns {Object} errors
 */

// METADATA AND SCHEDULING

export const getAllErrors = instrument => {
  return { ...getSchedulingErrors(instrument), ...getMetadataErrors(instrument) }
}

export const getMetadataErrors = (instrument, isNewInstArch = false, isInstrumentUnificationEnabled = false) => {
  const { metadata, title } = instrument
  const errors = {}
  if (_isInvalid(title)) errors.title = 'Required field'
  const keys = Object.keys(PROPERTIES).filter(p =>
    isNewInstArch && isInstrumentUnificationEnabled ? p : p !== 'display_location',
  )

  keys.forEach(key => {
    if (key === 'display_icon' && instrument.type === 'TASK') return
    if (PROPERTIES[key].required && _isInvalid(metadata[key])) {
      errors[key] = 'Required field'
    }
    if (key === 'display_location' && !metadata[key]?.length) {
      errors[key] = 'Required field'
    }
  })

  return errors
}

export const _isInvalid = (value, nullCheck = true) => {
  const conditions = ['', undefined]
  if (nullCheck) conditions.push(null)
  return conditions.some(falsy => value === falsy) || value !== value
}
/**
 * Deployment Validations
 * 1. deploy.first_login.interval cannot be less than expire.first_login.interval
 * 2. If recurring.recurrences is set, expire must be set to { never: null }
 * 3. If deploy.first_login is set, expire cannot be set to absolute
 * 4. recurring.interval must be less than expire.first_login.interval - deploy.first_login.interval
 * 5. recurring.interval cannot exceed absolute expiration date (calculated from deploy time)
 * 6. visibility and visibilityRanges must always be defined and
 *    visibilityRanges must be set to default null state (see above) when visibility === 'all_day'
 */

export const getSchedulingErrors = (instrument, conditions) => {
  if (!instrument.metadata.hasOwnProperty('schedule')) return { schedule: 'Schedule property is missing' }
  const errors = {}
  const handleError = (key, text, index) => {
    if (index || index === 0) {
      errors[key] = text
    } else errors[key] = text
  }
  if (conditions?.length > 0) {
    _getConditionErrors(conditions, handleError)
  }
  _getSchedulingErrors(instrument.metadata.schedule, handleError)
  _getDeployErrors(instrument.metadata.schedule, handleError)
  _getExpireErrors(instrument.metadata.schedule, handleError)
  _getCohortErrors(instrument.metadata.schedule, handleError)
  _getRecurringErrors(instrument.metadata.schedule, handleError)
  return errors
}

export const _getSchedulingErrors = (schedule, handleError, route = 'schedule') => {
  if (!_isObject(schedule)) {
    if (_isInvalid(schedule, false)) {
      let errorMessage = 'This field cannot be empty or has invalid input'
      if (route.includes('cohort.filter.instrument_score')) {
        errorMessage = 'Score cannot be empty'
        handleError(INSTRUMENT_ERROR_LIST.instrumentScore, errorMessage)
        return
      }
      if (route === `schedule.chain_deployment_info.visit_id`) {
        errorMessage = 'Please select a visit'
        handleError(route, errorMessage)
      }
      handleError(route, errorMessage)
    }
  } else {
    const keys = Object.keys(schedule)
    keys.forEach(key => {
      _getSchedulingErrors(schedule[key], handleError, `${route}.${key}`)
    })
  }
}

const _getDeployErrors = ({ deploy }, handleError) => {
  if (!deploy) {
    handleError('schedule.deploy', 'Missing deploy field.')
  } else {
    if (deploy.hasOwnProperty('first_login') && _isInvalid(deploy.first_login.interval)) {
      handleError('schedule.deploy.first_login.interval', 'Must specify a relative login interval')
    }
    if (deploy.hasOwnProperty('absolute') && fromUTCMidnightSeconds(deploy.absolute).isBefore(moment(), 'day')) {
      handleError('schedule.deploy.absolute', `Deploy date cannot be before today's date`)
    }
  }
}

const _getExpireErrors = ({ expire, deploy }, handleError) => {
  if (!expire) {
    handleError('expire', 'Missing expire field.')
  } else if (expire.hasOwnProperty('absolute')) {
    if (moment(fromUTCMidnightSeconds(expire.absolute)).isBefore(moment(), 'day')) {
      handleError('schedule.expire.absolute', `Expiration date cannot be before today's date`)
    } else if (
      deploy.absolute &&
      fromUTCMidnightSeconds(expire.absolute).isBefore(fromUTCMidnightSeconds(deploy.absolute))
    ) {
      handleError('schedule.expire.absolute', 'Expiration date cannot be before deploy date.')
    }
  } else if (expire.hasOwnProperty('first_login')) {
    // @TODO: Check interval_type ?
    if (_isInvalid(expire.first_login.interval)) {
      handleError('schedule.expire.first_login.interval', 'Must specify a relative expiration interval.')
    }
    if (expire.first_login.interval < deploy.first_login.interval) {
      handleError(
        'schedule.expire.first_login.interval',
        'Relative expiration time cannot be less than relative start time.',
      )
    }
  }
}

const _instrumentCohortValidation = (cohort, handleError) => {
  if (cohort.type === GROUP_TYPE_MAP.age) {
    if (_isInvalid(cohort.filter.min_age)) handleError('schedule.cohort.filter.min_age', 'Minimum age must be set')
    if (_isInvalid(cohort.filter.max_age)) handleError('schedule.cohort.filter.max_age', 'Maximum age must be set')
  } else if (cohort.type === GROUP_TYPE_MAP.cohort) {
    if (cohort.filter.include.length <= 0) {
      handleError('schedule.cohort.filter.include', 'You must assign at least one track')
    }
  } else if (cohort.type === GROUP_TYPE_MAP.sites) {
    if (cohort.filter.site_ids.length <= 0) {
      handleError('schedule.cohort.filter.site_ids', 'You must assign at least one site ')
    }
  }
}

const _getCohortErrors = ({ cohort }, handleError) => {
  if (!cohort) {
    handleError('expire', 'Missing cohort field.')
  } else if (Array.isArray(cohort)) {
    if (cohort.length === 0) handleError('schedule.cohort.filter.include', 'Must assign at least one')
    else {
      cohort.forEach(item => {
        _instrumentCohortValidation(item, handleError)
      })
    }
  } else if (Object.keys(cohort)?.length === 0) {
    handleError('schedule.cohort.filter.error', 'Must assign at least one')
  } else {
    _instrumentCohortValidation(cohort, handleError)
  }
}

const _getRecurringErrors = (schedule, handleError) => {
  if (schedule.recurring) {
    const recurringScheme = schedule.recurring.scheme
    if (isRepeatTimeUnitOpt(recurringScheme)) {
      const intervalErrors = getIntervalErrors(schedule.expire, schedule.deploy, schedule.recurring.interval)
      Object.keys(intervalErrors).forEach(key => {
        handleError(key, intervalErrors[key])
      })
      validateRecurringFields(recurringScheme).forEach(key => {
        if (!schedule.recurring.hasOwnProperty(key)) {
          handleError(`schedule.recurring.${key}`, `${key} cannot be empty.`)
        }
      })
    } else if (
      schedule.recurring.scheme !== 'persistent' &&
      schedule.recurring.scheme !== 'n-hours' &&
      schedule.recurring.scheme !== 'n-minutes'
    ) {
      handleError('schedule.recurring.scheme', 'Invalid scheme type')
    }
  }
}

const _getConditionErrors = (conditions, handleError) => {
  const conditionErrors = {}
  conditions.forEach((condition, index) => {
    const { triggers } = condition
    const triggerType = triggers ? triggers[0].resource_function_name : null
    const resourceFunctionParams = triggers ? triggers[0].resource_function_params : {}
    switch (triggerType) {
      case TRIGGER_TYPE_MAP.singleScore:
      case TRIGGER_TYPE_MAP.score: {
        const scoreTriggerErrors = {}
        let hasErrors = false
        if (resourceFunctionParams.instrument_score === '') {
          hasErrors = true
          scoreTriggerErrors.score = 'Score cannot be empty'
        }
        if (hasErrors) conditionErrors[index] = scoreTriggerErrors
        break
      }
      case TRIGGER_TYPE_MAP.compliance: {
        const complianceTriggerErrors = {}
        let hasErrors = false
        if (resourceFunctionParams.window === '') {
          hasErrors = true
          complianceTriggerErrors.daysAfterDeployment = 'Days after deployment cannot be empty'
        }
        // if (resourceFunctionParams.site_ids.length === 0) {
        //   hasErrors = true
        //   complianceTriggerErrors.siteIds = 'Please select at least one site'
        // }
        if (resourceFunctionParams.completion_comparison === '') {
          hasErrors = true
          complianceTriggerErrors.completionComparison = 'Completion rate cannot be empty'
        }
        if (hasErrors) conditionErrors[index] = complianceTriggerErrors
        break
      }
      default:
        return null
    }
  })
  if (Object.keys(conditionErrors).length > 0) handleError('conditions', conditionErrors)
}

const getIntervalErrors = (expire, deploy, interval) => {
  let start
  let end
  const errors = {}
  // handle warning conditions
  if (!interval) {
    errors['schedule.recurring.interval'] = 'Recurring interval cannot be 0 or empty'
  }
  if (NEVER in expire) return errors
  if (RELATIVE_TO_FIRST_LOGIN in deploy) {
    // warn if recurring interval is greater than relative expiration interval
    if (expire.first_login && expire.first_login.interval - deploy.first_login.interval < interval) {
      errors['schedule.recurring.interval'] = 'Repeat interval cannot be greater than expiration interval.'
    }
  } else if (RELATIVE_TO_SEND_IDEAL in expire) {
    if (expire.relative_to_send_ideal.interval - 1 < interval) {
      errors['schedule.recurring.interval'] = 'Repeat interval cannot be greater than expiration interval.'
    }
  } else {
    if (NOW in deploy) {
      // The time when current day started in UTC
      start = currentDayStartInUTC()
    } else {
      start = fromUTCMidnightSeconds(deploy.absolute)
    }
    // warn if absolute expiration is before the first repeat
    if (expire.absolute) {
      end = fromUTCMidnightSeconds(expire.absolute)

      if (
        moment(start)
          .add(interval - 1, 'days')
          .isSameOrAfter(end, 'days')
      ) {
        errors['schedule.recurring.interval'] = 'Repeat interval exceeds the time of expiration.'
      }
    }
  }
  return errors
}

export const getFormulaErrors = ({ domains, scores }) => {
  const errors = {
    scores: {},
  }
  const validDomainIds = Object.keys(domains)
  if (!domains || !scores) return {}

  scores.forEach(score => {
    const { id, name, formula } = score
    const scoreErrors = {}

    if (name === '') {
      scoreErrors.emptyScoreNameErr = 'Please enter the score name'
    }
    const isInvalid = getDoesFormulaContainInvalidChars(formula, validDomainIds)
    const isIncomplete = getIsFormulaIncomplete(formula, validDomainIds)
    if (formula === '') {
      scoreErrors.emptyFormulaErr = 'Please enter a formula'
    }
    if (isInvalid) {
      scoreErrors.invalidCharFormulaErr = 'Please use existing Domains and supported operations'
    }
    if (isIncomplete) {
      scoreErrors.incompleteFormulaErr = 'Please complete this formula'
    }
    if (isIncomplete && isInvalid) {
      scoreErrors.invalidCharFormulaErr = `1. Please use existing Domains and supported operations`
      scoreErrors.incompleteFormulaErr = `2. Please complete this formula`
    }

    if (Object.keys(scoreErrors).length) {
      errors.scores[id] = scoreErrors
    }
  })
  if (!Object.keys(errors.scores).length) return {}
  return errors
}

export const getSigneeRequiredCount = signees => {
  return Object.values(signees).reduce((prev, curr) => prev + (curr.required & 1), 0)
}

export const checkForAdvancedDeploymentOptions = (_instrumentAdvancedOptions = {}) => {
  if (!_instrumentAdvancedOptions) return false
  return !!Object.keys(_instrumentAdvancedOptions).length
}
