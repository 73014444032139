import React, { Fragment, createRef } from 'react'
import { Button, Checkbox, Loader, PercentMeter, SortableTable, TextOverflow } from 'components/UIElements'
import PropTypes from 'prop-types'
import '../stylesheets/ParticipantTable.scss'
import { MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP, STUDY_CONFIG_FEATURE_KEY_MAP } from 'utils/constants'
import { filterObjectByKey } from 'utils/object'
import SexFilter from './Filters/SexFilter'
import TracksFilter from './Filters/TracksFilter'
import FirstLoginFilter from './Filters/FirstLoginFilter'
import getStringFilter from './Filters/StringFilter'
import getDateRangeFilter from './Filters/DateRangeFilter'
import getNumRangeFilter from './Filters/NumRangeFilter'
import ParticipantView from '../../CreateParticipant/containers/ParticipantContainer'

export const KEY_NAME_MAP = {
  checkbox: null,
  id: 'ID',
  subjectId: 'Subject ID',
  name: 'Name',
  lastName: 'Last Name',
  firstName: 'Preferred Name',
  email: 'Email',
  siteName: 'Site Name',
  siteId: 'Site ID',
  age: 'Age',
  sex: 'Sex',
  tracks: 'Tracks',
  firstLogin: 'First Login',
  lastInstrument: 'Last Instrument',
  lastPassiveData: 'Last Device Data',
  lastConnection: 'Last Connection',
  instrumentsCompleted: 'Instruments completed',
}

export const KEY_TOOLTIP_MAP = {
  lastPassiveData: 'Last time the participant device successfully sent passive data to the system',
  lastConnection: 'Last time the participant device successfully retrieved pending instruments',
}

export const NO_CONSENT_MAP = {
  checkbox: null,
  id: 'ID',
  subjectId: 'Subject ID',
  name: 'Name',
  lastName: 'Last name',
  firstName: 'Preferred name',
  email: 'Email',
  siteName: 'Site Name',
  siteId: 'Site ID',
  age: 'Age',
  sex: 'Sex',
  tracks: 'Tracks',
  firstLogin: 'First Login',
  lastInstrument: 'Last Instrument',
  lastPassiveData: 'Last Device Data',
  lastConnection: 'Last Connection',
  instrumentsCompleted: 'Instruments completed',
}

export const PARTICIPANT_STATUS_TYPE_MAP = {
  accountRecovery: 'account-recovery',
  completeAcccountSetup: 'complete-account-setup',
  pending: 'pending',
}

const RESEND_INVITE = 'Resend Invite'

class ParticipantTable extends React.Component {
  constructor(props) {
    super(props)
    const { consentRequired, hasPiiRole, participantDataFields, noTracksFeature } = this.props
    const hasParticipantDataDisabled = participantDataFields?.disabled
    this.ptpTableRef = createRef(null)
    this.disabledDataFields =
      hasParticipantDataDisabled &&
      hasParticipantDataDisabled.map(data => {
        switch (data.metadata.internal_key) {
          case 'date_of_birth':
            return 'age'
          case 'fname':
            return 'firstName'
          case 'lname':
            return hasPiiRole ? 'lastName' : 'name'
          case 'gender':
            return 'sex'
          default:
            return data.metadata.internal_key
        }
      })
    const filterKey = key =>
      hasPiiRole ? key !== 'name' : key !== 'lastName' && key !== 'firstName' && key !== 'email'
    const newNameMap = filterObjectByKey(KEY_NAME_MAP, filterKey)
    this.headerOptions = Object.keys(consentRequired ? newNameMap : filterObjectByKey(NO_CONSENT_MAP, filterKey)).map(
      key => {
        const className = KEY_NAME_MAP[key]
          ? KEY_NAME_MAP[key]
              .toLowerCase()
              .split(' ')
              .join('-')
          : undefined
        const iconClassName = KEY_TOOLTIP_MAP[key] ? 'fas fa-info-circle' : null
        const popupText = KEY_TOOLTIP_MAP[key] ? KEY_TOOLTIP_MAP[key] : null
        return { key, sortable: true, component: newNameMap[key], className, iconClassName, popupText }
      },
    )
    if (hasParticipantDataDisabled) {
      this.headerOptions = this.headerOptions.filter(option => !this.disabledDataFields.includes(option.key))
    }
    if (noTracksFeature) {
      this.headerOptions = this.headerOptions.filter(option => {
        return STUDY_CONFIG_FEATURE_KEY_MAP.tracks !== option.key
      })
    }

    this.headerOptions[0].sortable = false
    this.filterComponents = {
      id: getStringFilter({ header: 'ID', dataKey: 'id' }),
      subjectId: getStringFilter({ header: 'Subject ID', dataKey: 'subjectId' }),
      siteId: getStringFilter({ header: 'Site ID', dataKey: 'siteId' }),
      age: getNumRangeFilter({
        header: 'Age',
        dataKey: 'age',
        placeholders: { min: 'Min Age', max: 'Max Age' },
      }),
      sex: SexFilter,
      tracks: TracksFilter,
      firstLogin: FirstLoginFilter,
      lastInstrument: getDateRangeFilter({
        header: 'Last Instrument',
        dataKey: 'lastInstrument',
      }),
      lastPassiveData: getDateRangeFilter({
        header: 'Last Device Data Sent',
        dataKey: 'lastPassiveData',
      }),
      lastConnection: getDateRangeFilter({
        header: 'Last Connection',
        dataKey: 'lastConnection',
      }),
      compliancePercent: getNumRangeFilter({
        header: 'Compliance',
        dataKey: 'compliancePercent',
        valueKey: 'filterValue',
        placeholders: { min: 'Min %', max: 'Max %' },
        align: 'right',
      }),

      eConsent: getDateRangeFilter({
        header: 'Date Consented',
        dataKey: 'eConsent',
      }),
      name: getStringFilter({ header: 'Name', dataKey: 'name' }),
      lastName: getStringFilter({ header: 'Last name', dataKey: 'lastName' }),
      firstName: getStringFilter({ header: 'Preferred name', dataKey: 'firstName' }),
      email: getStringFilter({ header: 'Email', dataKey: 'email' }),
      instrumentsCompleted: getNumRangeFilter({
        header: 'Instruments completed',
        dataKey: 'instrumentsCompleted',
        placeholders: { min: 'Min instruments', max: 'Max instruments' },
      }),
    }
    this.renderFunctions = {
      checkbox: this.renderCheckboxCell,
      name: this.renderNameCell,
      firstName: this.renderNameCell,
      lastName: this.renderNameCell,
      compliancePercent: this.renderComplianceCell,
      eConsent: this.renderConsentCell,
      firstLogin: this.renderFirstLoginCell,
      id: this.renderIdCell,
    }
    this.searchKeys = hasPiiRole ? ['firstName', 'lastName', 'id', 'subjectId'] : ['name', 'id', 'subjectId']
    this.initialize()
  }

  componentDidMount() {
    // We need to remove searchable table keys if the participant field is disabled in the study
    if (this.disabledDataFields?.length > 0) {
      this.searchKeys = this.searchKeys.filter(searchKey => !this.disabledDataFields.includes(searchKey))
    }
  }

  componentDidUpdate = prevProps => {
    const { searchTerm, toggleUncheckAll } = this.props
    if (prevProps.searchTerm !== searchTerm) toggleUncheckAll()
  }

  onOpenPtpPreview(link, ptpSiteID, id, resetParticipant, closeDrawer) {
    const { openDrawer, resetDocs } = this.props
    openDrawer({
      closeOnBackgroundClick: true,
      onCancel: () => {
        resetParticipant()
        closeDrawer()
        resetDocs()
      },
      content: <ParticipantView {...this.props} ptpId={id} link={link} inDrawer ptpSiteID={ptpSiteID} />,
    })
  }

  onResendInvite = ({ username, ptpId }) => {
    const { openModal, closeModal, triggerResendInvite } = this.props
    openModal({
      content: MODAL_CONTENT_MAP.newInvitation,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        triggerResendInvite({ username, ptpId })
        closeModal()
      },
    })
  }

  initialize() {
    const { filterProps } = this.props
    if (filterProps) {
      this.headerOptions.forEach(option => {
        if (this.filterComponents.hasOwnProperty(option.key)) {
          const Component = this.filterComponents[option.key]
          option.component = (
            <span>
              {option.component}
              <Component />
            </span>
          )
        }
      })
    }
  }

  filterList(list) {
    const { consentRequired, noTracksFeature } = this.props
    const newList =
      this.disabledDataFields?.length > 0
        ? list
            .filter(item => !!item && Array.isArray(item))
            .map(participant => participant.filter(el => !this.disabledDataFields.includes(el.key)))
        : list
    if (!consentRequired) {
      for (let i = 0; i < newList.length; i += 1) {
        newList[i] = newList[i]?.filter(col => {
          return col.key !== STUDY_CONFIG_FEATURE_KEY_MAP.consent
        })
      }
    }
    if (noTracksFeature) {
      for (let i = 0; i < newList.length; i += 1) {
        newList[i] = newList[i]?.filter(col => {
          return col.key !== STUDY_CONFIG_FEATURE_KEY_MAP.tracks
        })
      }
    }
    return newList
  }

  renderNameCell = ({ value, idx, row }) => {
    const tableList = this.ptpTableRef.current ? this.ptpTableRef.current.getList() : null
    const lastTableItem = tableList ? tableList[tableList.length - 1] : null
    return (
      <td key={`row_${idx}`} className='compliance-row'>
        <TextOverflow
          width={160}
          text={value}
          position={lastTableItem && lastTableItem[1].value === row[1].value ? 'top' : 'bottom'}
        />
      </td>
    )
  }

  renderComplianceCell = ({ value, idx, inLeafSite }) => {
    const { hasOrphans, ptpsComplianceLoading } = this.props
    if (hasOrphans && !inLeafSite) {
      return (
        <td key={`row_${idx}`} className='compliance-row'>
          <div className='flexed row'>
            <i className='fas fa-exclamation-circle' />
            <p>
              <b>Click to reassign sites</b>
            </p>
          </div>
        </td>
      )
    }
    if (ptpsComplianceLoading) {
      return (
        <td key={`row_${idx}`} className='compliance-row'>
          <Loader size={24} inContainer />
        </td>
      )
    }
    return (
      <td key={`row_${idx}`} className='compliance-row'>
        <PercentMeter ratio={value} invariableColor />
      </td>
    )
  }

  renderCheckboxCell = ({ value, idx, checkedPtps, compliance, siteID }) => {
    const { toggleParticipant, dataSectionSelected } = this.props
    return (
      <td
        className={`checkbox-cell${
          dataSectionSelected ? ` ${dataSectionSelected}` : compliance ? ` ${compliance.split(' ').pop()}` : ''
        }`}
        onClick={e => {
          e.stopPropagation()
          toggleParticipant(value, siteID)
        }}
        key={`col_${idx}`}>
        <Checkbox className='bubble' checked={value in checkedPtps} />
      </td>
    )
  }

  renderFirstLoginCell = ({ value, idx, username, ptpId }) => {
    const { canResendInviteOrResetPw, isEmailless, studyLock } = this.props
    let content = <span>{value}</span>
    if (value.includes(RESEND_INVITE)) {
      const [firstSection, secondSection] = value.split(RESEND_INVITE)
      content = (
        <>
          <p>{firstSection}</p>
          {canResendInviteOrResetPw && !isEmailless && (
            <Button
              link
              disabled={studyLock}
              content={RESEND_INVITE}
              id={`resend-invite-${username}`}
              onClick={e => {
                e.stopPropagation()
                this.onResendInvite({ username, ptpId })
              }}
            />
          )}
          <p>{secondSection}</p>
        </>
      )
    }
    return (
      <td key={`col_${idx}`} className='first-login-row'>
        {content}
      </td>
    )
  }

  renderConsentCell = ({ value, idx }) => {
    return (
      <td key={`consent-${idx}`} className='consent-row'>
        {value ? (
          <div>
            <i className='fas fa-check' />
            <span>{value}</span>
          </div>
        ) : (
          <div>
            <i className='fas fa-times' />
          </div>
        )}
      </td>
    )
  }

  renderIdCell = ({ value, idx, isPtpDisabled }) => {
    const accountDisabledText =
      "This participant's account is disabled. Data collection is paused for the participant, and the participant will not be able to login until the account is enabled."
    return (
      <td key={`id-${idx}`} className='id-row'>
        {isPtpDisabled ? (
          <div className='flexed'>
            <Fragment key={idx}>
              <i className='fas fa-lock' />
              <div className='account-disabled-tooltip'>{accountDisabledText}</div>
            </Fragment>
            <span>{value}</span>
          </div>
        ) : (
          <div>{value}</div>
        )}
      </td>
    )
  }

  render() {
    const {
      checkedPtps,
      closeDrawer,
      disableRowClick,
      filterProps,
      hasOrphans,
      offsets,
      participantList,
      ptpMap,
      resetParticipant,
      searchTerm,
      studyID,
      toggleCheckAll,
      hasPiiRole,
      canMovePtp,
      studyLock,
      hasOverFlow,
    } = this.props
    const canNotMoveAndLocked = !canMovePtp && studyLock
    const rowList = this.filterList(
      canNotMoveAndLocked
        ? participantList.map(participant => participant.filter(columnName => columnName.key !== 'checkbox'))
        : participantList,
    )
    this.headerOptions[0].func = true
    this.headerOptions[0].component = self => (
      <Checkbox
        className='buble'
        checked={Object.keys(checkedPtps).length === participantList.length}
        onClick={() => {
          const filterdParticipants = self.currentList.reduce((acc, cur) => {
            acc[cur[0].value] = cur
            return acc
          }, {})
          toggleCheckAll(filterdParticipants)
        }}
      />
    )

    const rowHighlightOptions = [
      {
        hasHighlightClassName: row =>
          row.find(item => item.key === 'accountDeletionStatus')?.value === PARTICIPANT_STATUS_TYPE_MAP.pending,
        highlightClassName: 'highlighted account-deletion',
        highlightSecondaryClassName: 'border-top',
      },
      {
        hasHighlightClassName: row =>
          row.find(item => item.key === 'checkbox')?.compliance === PARTICIPANT_STATUS_TYPE_MAP.completeAcccountSetup,
        highlightClassName: 'highlighted complete-account-setup',
      },
      {
        hasHighlightClassName: row =>
          row.find(item => item.key === 'checkbox')?.compliance.includes(PARTICIPANT_STATUS_TYPE_MAP.accountRecovery),
        highlightClassName: 'highlighted account-recovery',
      },
    ]

    return (
      <SortableTable
        ref={this.ptpTableRef}
        id='participant-table'
        offset={offsets ? offsets[1] : 0}
        filters={filterProps ? filterProps.filters : undefined}
        optionalCellProps={{ checkedPtps }}
        searchTerm={searchTerm}
        searchKeys={this.searchKeys}
        onRowClick={
          disableRowClick
            ? null
            : rowData => {
                const ptpSiteID = rowData.filter(row => row.key === 'siteId').map(res => res.value)[0]
                const ptpID = rowData[0].value
                const editLink = `/studies/${studyID}/participants/${ptpID}/ptpSite/${ptpSiteID}`
                this.onOpenPtpPreview(editLink, ptpSiteID, ptpID, resetParticipant, closeDrawer)
              }
        }
        rowClassNameOptions={{
          hasClassName: row => !row.filter(row => row.key === 'siteId').map(res => res.inLeafSite)[0] && hasOrphans,
          className: 'is-non-leaf',
        }}
        rowHighlightOptions={rowHighlightOptions}
        className={`participant-list ${hasOverFlow ? 'overflow' : ''}`}
        sortingBy={hasPiiRole ? 'lastName' : 'name'}
        rowList={rowList}
        headerOptions={
          canNotMoveAndLocked ? this.headerOptions.filter(option => option.key !== 'checkbox') : this.headerOptions
        }
        emptyText='No Matching Participants'
        renderFunctions={this.renderFunctions}
        viewMoreWithTablePages={{ minRows: 20, maxRows: 20 }}
      />
    )
  }
}

ParticipantTable.propTypes = {
  canMovePtp: PropTypes.bool,
  canResendInviteOrResetPw: PropTypes.bool,
  checkedPtps: PropTypes.object,
  closeDrawer: PropTypes.func,
  closeModal: PropTypes.func,
  compliance: PropTypes.objectOf(PropTypes.number),
  consentRequired: PropTypes.bool,
  disableRowClick: PropTypes.bool,
  filterProps: PropTypes.object,
  hasOrphans: PropTypes.bool,
  hasOverFlow: PropTypes.bool,
  hasPiiRole: PropTypes.bool,
  isEmailless: PropTypes.bool,
  noTracksFeature: PropTypes.bool,
  offsets: PropTypes.arrayOf(PropTypes.number),
  openDrawer: PropTypes.func,
  openModal: PropTypes.func,
  participant: PropTypes.object,
  participantDataFields: PropTypes.objectOf(PropTypes.array),
  participantList: PropTypes.arrayOf(PropTypes.array),
  ptpMap: PropTypes.object,
  ptpsComplianceLoading: PropTypes.bool,
  resetDocs: PropTypes.func,
  resetParticipant: PropTypes.func,
  searchTerm: PropTypes.string,
  siteID: PropTypes.number,
  studyID: PropTypes.number,
  studyLock: PropTypes.bool,
  toggleCheckAll: PropTypes.func,
  toggleParticipant: PropTypes.func,
  toggleUncheckAll: PropTypes.func,
  triggerResendInvite: PropTypes.func,
}

export default ParticipantTable
