import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import Icons from './Icons'

class ParticipantRow extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.rowSVG = React.createRef()
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const dims = this.rowSVG.current.getBoundingClientRect()
    this.setState({ width: dims.width, height: dims.height })
  }

  render() {
    const { end, data, range, ptpId, onMouseEnterIcon, onMouseEnterPtpCell, grey, studyID, onMouseLeave } = this.props
    const { participantSiteId } = data
    const { width, height } = this.state
    const ptpLink = `/studies/${studyID}/participants/${ptpId}/${participantSiteId}`
    return (
      <tr>
        <td className='clickable' content={ptpId}>
          <span
            onMouseLeave={onMouseLeave}
            onMouseEnter={e => onMouseEnterPtpCell(e, data, ptpId)}
            onClick={() => {
              browserHistory.push(ptpLink)
            }}>
            {ptpId}
          </span>
        </td>
        <td className={grey ? 'grey' : ''}>
          <svg style={{ overflow: 'visible' }} ref={this.rowSVG} width='100%' className='ptp-row'>
            {data.events.map((event, idx) => {
              if (event.type === 'SENT' && data.completionIds.has(event.queue_id)) return null
              // Hide expired events beyond end of timeline
              if (event.type === 'EXPIRED' && event.timestamp.isAfter(end)) return null
              const percentOffset = event.offset / range
              const x = Math.round(percentOffset * width || 0)
              const y = height / 2
              return Icons[event.type]({
                key: `${event.id}-${idx}`,
                type: event.type,
                x,
                y,
                onMouseEnter: e => onMouseEnterIcon(e, event, data),
              })
            })}
          </svg>
        </td>
      </tr>
    )
  }
}

ParticipantRow.propTypes = {
  data: PropTypes.object,
  end: PropTypes.object,
  grey: PropTypes.bool,
  onMouseEnterIcon: PropTypes.func,
  onMouseEnterPtpCell: PropTypes.func,
  onMouseLeave: PropTypes.func,
  ptpId: PropTypes.string,
  range: PropTypes.number,
  studyID: PropTypes.string,
}

export default ParticipantRow
