import { connect } from 'react-redux'
import SingleScore from '../components/SingleScore'

const SingleScoreContainer = connect((state, ownProps) => ({
  deletedDomains: ownProps.isClinro
    ? state.clinroEditor.clinro.deletedDomains
    : ownProps.isDiary 
    ? state.diaryEditor.diary.deleteDomains 
    : state.surveyEditor.survey.deletedDomains,
}))(SingleScore)

export default SingleScoreContainer
