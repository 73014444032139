import React from 'react'
import PropTypes from 'prop-types'
import { URGENCY_MAP, CASE_STATUS_MAP } from '../../CasesPage/utils/statusMaps'
import { STATUS_MAP } from '../../CasesPage/modules/Cases'

const StatusTag = ({ status, reportedNo }) => {
  const isActionable = status !== 'closed'
  return (
    <div className='status-tag flexed'>
      {isActionable ? (
        <i className={`fas fa-exclamation-circle ${URGENCY_MAP[status]}`} />
      ) : (
        <i className={`fas fa-times ${URGENCY_MAP[status]}`} />
      )}
      <strong>{reportedNo && status === CASE_STATUS_MAP.reported ? 'Open' : STATUS_MAP[status]}</strong>
    </div>
  )
}

StatusTag.propTypes = {
  status: PropTypes.string,
  reportedNo: PropTypes.bool,
}

export default StatusTag
