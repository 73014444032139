import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import moment from 'moment'
import { updateSearchTerm } from 'store/searchTerm'
import { loadingActions } from 'store/loader'
import { actions as notyActions } from 'layouts/ErrorBox'
import { BLOB_TYPE_MAP, DEFAULT_NA_STRING_SORT_VAL, DATE_FORMAT_MAP } from 'utils/constants'
import {
  downloadBlob,
  pluralize,
  uniqueArr,
  getUserRoleByStudy,
  hasPiiRole,
  returnSortedObjArrByKey,
  sortFunction,
} from 'utils/misc'
import request, { generateNotyMessage } from 'utils/request'
import { dayDifference, today } from 'utils/time'
import { actions as filterActions } from 'store/tableFilters'
import movePtpsList, { actions as movePtpsActions, properties } from '../../MoveParticipant/modules/MovePtps'
import { _fetchBlockedConsents } from '../../../../Consents/routes/ConsentsDashboard/modules/Consents'

//
// Actions
//

const FETCHED_PARTICIPANTS = 'FETCHED_PARTICIPANTS'
export const FETCHED_VISIT_PARTICIPANTS = 'FETCHED_VISIT_PARTICIPANTS'
export const RESET_PARTICIPANTS = 'RESET_PARTICIPANTS'
const UPDATE_WIZARD_NAME = 'UPDATE_WIZARD_NAME'
const FETCHED_COHORTS = 'FETCHED_COHORTS'
const SET_STARTED_PTPS = 'SET_STARTED_PTPS'
const SET_NO_ACTIVE_DATA = 'SET_NO_ACTIVE_DATA'
const SET_NO_PASSIVE_DATA = 'SET_NO_PASSIVE_DATA'
const SET_ACCOUNT_DELETION_REQUEST_DATA = 'SET_ACCOUNT_DELETION_REQUEST_DATA'
const SET_ACCOUNT_SETUP_DATA = 'SET_ACCOUNT_SETUP_DATA'
const SET_ACCOUNT_RECOVERY_DATA = 'SET_ACCOUNT_RECOVERY_DATA'
const TOGGLE_PARTICIPANT = 'TOGGLE_PARTICIPANT'
const RESET_CHECKED_PTPS = 'RESET_CHECKED_PTPS'
const FETCHED_COHORT = 'FETCHED_COHORT'
const TOGGLE_CHECK_ALL_PARTICIPANTS = 'TOGGLE_CHECK_ALL_PARTICIPANTS'
const TOGGLE_UNCHECK_ALL_PARTICIPANTS = 'TOGGLE_UNCHECK_ALL_PARTICIPANTS'
const SET_PTPS_SELECTION_LIST = 'SET_PTPS_SELECTION_LIST'
const TOGGLE_FILTER_PARTICIPANT = 'TOGGLE_FILTER_PARTICIPANT'
const TOGGLE_FILTER_PARTICIPANT_ON = 'TOGGLE_FILTER_PARTICIPANT_ON'
const TOGGLE_FILTER_PARTICIPANT_OFF = 'TOGGLE_FILTER_PARTICIPANT_OFF'
const SET_PTP_MAP = 'SET_PTP_MAP'
const SET_CHECKED_PTPS = 'SET_CHECKED_PTPS'

//
// Action Creators
//

function updateWizardName(field, value) {
  return {
    type: UPDATE_WIZARD_NAME,
    field,
    value,
  }
}

function toggleParticipant(id, siteID) {
  return {
    type: TOGGLE_PARTICIPANT,
    id,
    siteID,
  }
}
function toggleFilterParticipant(id, obj) {
  return {
    type: TOGGLE_FILTER_PARTICIPANT,
    id,
    obj,
  }
}
function toggleFilterParticipantOn(id, obj) {
  return {
    type: TOGGLE_FILTER_PARTICIPANT_ON,
    id,
    obj,
  }
}
function toggleFilterParticipantOff(id) {
  return {
    type: TOGGLE_FILTER_PARTICIPANT_OFF,
    id,
  }
}

function toggleCheckAll(_ptpMap, list) {
  return {
    type: TOGGLE_CHECK_ALL_PARTICIPANTS,
    _ptpMap,
    list,
  }
}

function toggleUncheckAll() {
  return {
    type: TOGGLE_UNCHECK_ALL_PARTICIPANTS,
  }
}

function resetCheckedPtps() {
  return {
    type: RESET_CHECKED_PTPS,
  }
}

function resetParticipants() {
  return {
    type: RESET_PARTICIPANTS,
  }
}

function setPtpMap(ptpMap) {
  return {
    type: SET_PTP_MAP,
    ptpMap,
  }
}

function setCheckedPtps(checkedPtps) {
  return {
    type: SET_CHECKED_PTPS,
    checkedPtps,
  }
}

/**
 * Fetches the participant list from the control server
 * Each participant is returned as an object with the following fields:
 *  id, family_id, fname, lname, email, phone, is_child,
 *  is_head_of_household, registration_timestamp, last_updated,
 *  consent_progress, consent_timestamp, first_login_timestamp,
 *  questions_answered, instruments_completed, first_instrument_timestamp,
 *  last_instrument_timestamp, participant
 * */

export function _fetchParticipants(studyID) {
  return dispatch => {
    const success = jsonBody => {
      return Promise.resolve(jsonBody.participants)
    }
    return dispatch(
      request({
        url: `/control/studies/${studyID}/participants`,
        success,
      }),
    ).then(payload => {
      return Promise.resolve(payload)
    })
  }
}

export function fetchSiteParticipants(studyID, siteID, hasLoader = true, leafSiteOnly = false) {
  return dispatch => {
    const success = jsonBody => {
      const ptpList = Object.values(jsonBody.participants)
      if (leafSiteOnly) {
        return dispatch({
          type: FETCHED_VISIT_PARTICIPANTS,
          payload: ptpList,
        })
      }
      return Promise.resolve(ptpList.filter(ptp => !ptp.is_tester))
    }
    if (leafSiteOnly) dispatch(loadingActions.startLoader(true, 'visitorParticipant'))
    return dispatch(
      request({
        url: `/control/studies/${studyID}/sites/${siteID}/participants`,
        success,
        hasLoader,
      }),
    ).then(payload => {
      if (leafSiteOnly) return dispatch(loadingActions.stopLoader(true, 'visitorParticipant'))
      return Promise.resolve(payload)
    })
  }
}

const _addToPtpMap = (_ptpMap, { id, path, treeLevel }) => {
  _ptpMap[id] = { id, path, treeLevel }
}

const _generatePtpSelectionList = (ptpsPayload, studyId, isMove = false) => {
  const ptpIdArr = Object.keys(ptpsPayload)
  const selectionObj = {}
  const selectionPtpMap = {}

  const studyPath = `${studyId}`
  selectionObj[studyId] = {
    key: studyPath,
    text: `All Participants (${ptpIdArr.length} total)`,
    treeLevel: 1,
    path: studyPath,
  }
  _addToPtpMap(selectionPtpMap, { id: studyPath, path: studyPath, treeLevel: 1 })

  ptpIdArr.forEach(ptpId => {
    const ptp = ptpsPayload[ptpId]
    const { site_id: leafSiteId, fname, lname } = ptp
    const noPii = !fname && !lname
    let name = `Participant ${ptpId}`
    if (isMove) {
      name = noPii ? name : `${fname} ${lname}`
    }
    if (!selectionObj[leafSiteId]) {
      const path = `${studyId}.${leafSiteId}`
      selectionObj[leafSiteId] = {
        key: `${leafSiteId}`,
        text: `Site ${leafSiteId}`,
        treeLevel: 2,
        path,
      }
      _addToPtpMap(selectionPtpMap, { id: `${leafSiteId}`, path, treeLevel: 2 })
    }
    selectionObj[leafSiteId].ptpsCount = 1 + (selectionObj[leafSiteId].ptpsCount ?? 0)
    const path = `${studyId}.${leafSiteId}.${ptpId}`
    selectionObj[ptpId] = { key: `${ptpId}`, text: name, treeLevel: 3, path }
    _addToPtpMap(selectionPtpMap, { id: `${ptpId}`, path, treeLevel: 3 })
  })
  const resultList = Object.values(selectionObj).sort((row1, row2) => sortFunction(row1.path, row2.path))

  return Promise.resolve({ resultList, selectionPtpMap, ptpsPayload })
}

export function fetchSelectionPtps(studyId) {
  return async dispatch => {
    dispatch(loadingActions.startLoader(true, 'selectionPtps'))
    const payload = await dispatch(_fetchParticipants(studyId))
    _generatePtpSelectionList(payload, studyId).then(({ ptpsPayload, resultList, selectionPtpMap }) => {
      dispatch({
        type: SET_PTPS_SELECTION_LIST,
        list: resultList,
        selectionPtpMap,
        payload: ptpsPayload,
      })
      dispatch(loadingActions.stopLoader(true, 'selectionPtps'))
    })
  }
}

/**
 * @param {number} studyID
 * @param {boolean} hasLoader whether the request will initiate general loader
 * @param {boolean} sort whether the payload of request should be sorted alphabetically by cohort name
 *
 */
export function fetchCohorts(studyID, hasLoader = true, sorted = false) {
  return dispatch => {
    const success = jsonBody => {
      return dispatch({
        type: FETCHED_COHORTS,
        payload: jsonBody.study_groups,
        sorted,
      })
    }
    return dispatch(
      request({
        url: `/control/studies/${studyID}/study_groups`,
        success,
        hasLoader,
      }),
    )
  }
}

export function fetchPtpsAndCohorts(studyID) {
  return dispatch => {
    dispatch(loadingActions.startLoader(true))
    dispatch(resetParticipants())
    dispatch(fetchCohorts(studyID, false))
    // dispatch(_fetchParticipantsCompliance(studyID))
    dispatch(_fetchParticipants(studyID)).then(payload => {
      dispatch(parsePtpMap(payload))
      dispatch(loadingActions.stopLoader(true))
    })
  }
}

/**
 * Fetch participants by getting the blocked consents and filter those participants
 * that are not part of an not complete consent
 * @param {number} studyID
 * @returns async function for fetching and parse ptps
 */
export function fetchPtpsByNonCompleteBlockingConsent(studyID) {
  return async dispatch => {
    dispatch(loadingActions.startLoader(true))
    dispatch(resetParticipants())
    dispatch(fetchCohorts(studyID, false))

    const blockedConsents = await dispatch(_fetchBlockedConsents(studyID))
    const participants = await dispatch(_fetchParticipants(studyID))

    const blockedConsentsList = Object.keys(blockedConsents || {})
    if (blockedConsentsList.length > 0) {
      const ptpsIDArr = Object.keys(participants || {})

      const newParticipants = ptpsIDArr.reduce((prevPtpObj, currPtpId) => {
        const ptpBlockingConsents = participants[currPtpId]?.blocking_consents || []
        if (ptpBlockingConsents.length > 0) {
          if (
            blockedConsentsList.some(consentID =>
              ptpBlockingConsents.map(({ consent_id }) => consent_id).includes(consentID),
            )
          ) {
            return prevPtpObj
          }
        }
        return { ...prevPtpObj, [currPtpId]: participants[currPtpId] }
      }, {})

      dispatch(parsePtpMap(newParticipants))
      dispatch(loadingActions.stopLoader(true))
    } else {
      dispatch(parsePtpMap(participants))
    }
    dispatch(loadingActions.stopLoader(true))
  }
}

export const fetchPtps = studyID => dispatch => {
  dispatch(loadingActions.startLoader(true))
  dispatch(resetParticipants())
  dispatch(_fetchParticipants(studyID)).then(payload => {
    dispatch(parsePtpMap(payload))
    dispatch(loadingActions.stopLoader(true))
  })
}

export function downloadData(studyID, siteID, type) {
  let url
  let name
  if (type === 'survey') {
    url = `/control/data/survey?study_id=${studyID}&site_id=${siteID}`
    name = '_participant_responses'
  } else if (type === 'clinro') {
    url = `/control/data/clinro?study_id=${studyID}&site_id=${siteID}`
    name = '_clinician_instrument_responses'
  } else if (type === 'diary') {
    url = `/control/data/ediary?study_id=${studyID}&site_id=${siteID}`
    name = '_diary_responses'
  } else if (type === 'participants') {
    url = `/control/studies/${studyID}/participants/export`
    name = '_participant_list'
  } else if (type === 'phonecontacts') {
    url = `/control/data/contacts?study_id=${studyID}&site_id=${siteID}`
    name = `_participant_${type}_data`
  } else {
    url = `/control/data/${type}?study_id=${studyID}&site_id=${siteID}`
    name = `_participant_${type}_data`
  }
  return dispatch => {
    const success = (blob, fileName) => {
      if (blob.type === BLOB_TYPE_MAP.zip) {
        downloadBlob(blob, `study_${studyID}${name}_${today(true)}.zip`, fileName)
      } else {
        downloadBlob(blob, `study_${studyID}${name}_${today(true)}.csv`, fileName)
      }
    }
    dispatch(loadingActions.startLoader(true, type))
    return dispatch(
      request({
        url,
        resType: 'blob',
        success,
      }),
    ).then(() => {
      dispatch(loadingActions.stopLoader(true, type))
    })
  }
}

export function downloadBulkData({ studyID, type, ptpId }) {
  return dispatch => {
    const bodyObj = {
      study_id: studyID,
      download_type: type,
      participant_id: ptpId,
    }

    const success = () => {
      dispatch(
        notyActions.showSuccess({
          text: generateNotyMessage(
            `Data download request successfully processed. Compressed audio files will be sent to your email in a couple of minutes.`,
            true,
          ),
        }),
      )
    }
    const fail = () => {
      dispatch(
        notyActions.showError({
          text: generateNotyMessage(`Failed to download ${type} data`, true),
        }),
      )
    }

    dispatch(loadingActions.startLoader(true, type))
    return dispatch(
      request({
        method: 'POST',
        resType: 'blob',
        success,
        url: `/control/data_download`,
        body: JSON.stringify(bodyObj),
        fail,
      }),
    ).then(() => {
      dispatch(loadingActions.stopLoader(true, type))
    })
  }
}

function deleteParticipants(studyID, checkedParticipants, siteID, plural = true) {
  const ptpIdArr = Object.keys(checkedParticipants)
  return (dispatch, getState) => {
    const { sites } = getState()
    const { leafSites } = sites
    const failedDeletions = []
    dispatch(loadingActions.startLoader(true))
    return new Promise((resolve, reject) => {
      ptpIdArr.forEach((id, idx) => {
        const res = dispatch(deleteParticipant(studyID, id, checkedParticipants[id], failedDeletions))
        if (idx === ptpIdArr.length - 1) {
          res.then(() => {
            resolve()
          })
        }
      })
    }).then(() => {
      dispatch(loadingActions.stopLoader(true))
      if (failedDeletions.length > 0) {
        dispatch(
          notyActions.showError({
            text: generateNotyMessage(
              `There was a problem deleting participant${plural ? 's' : ''} with the following ${
                plural ? 'IDs' : 'ID'
              }: ${failedDeletions.join(',')}`,
              false,
            ),
          }),
        )
      } else {
        dispatch(
          notyActions.showSuccess({
            text: generateNotyMessage(`Participant${plural ? 's' : ''} successfully deleted`, true, 'fas fa-trash-alt'),
          }),
        )
      }
      dispatch(fetchPtpsAndCohorts(studyID))
    })
  }
}

function deleteParticipant(studyID, id, siteID, failedDeletions) {
  return dispatch => {
    const fail = () => {
      failedDeletions.push(id)
    }
    return dispatch(
      request({
        method: 'DELETE',
        url: `/control/studies/${studyID}/sites/${siteID}/participants/${id}`,
        fail,
      }),
    )
  }
}

const _rolloverParticipant = ({
  nextStudyID,
  nextSiteID,
  nextTrackIDs,
  ptpID,
  studyID,
  siteID,
  resultProps,
  responseCallbacks,
}) => {
  return (dispatch, getState) => {
    const { participants } = getState()
    const { ptpProperties } = participants
    const ptpPropertiesArray = Object.keys(ptpProperties)
      .filter(property => ptpProperties[property].toggle)
      .map(property => ptpProperties[property].key)
    // Construct the body obj and filter null values
    const bodyObj = {
      next_study_id: nextStudyID,
      next_site_id: nextSiteID,
      next_track_ids: nextTrackIDs,
      props: ptpPropertiesArray,
    }

    const success = () => {
      const { addToSuccessCount } = responseCallbacks
      addToSuccessCount()
      if (resultProps) {
        const { addToSuccessfulMoves } = resultProps
        addToSuccessfulMoves(ptpID)
      }
      return Promise.resolve
    }

    const fail = () => {
      const { addToFailCount } = responseCallbacks
      addToFailCount()
      if (resultProps) {
        const { addToFailedMoves } = resultProps
        addToFailedMoves(ptpID)
      }
      return Promise.resolve
    }

    return dispatch(
      request({
        method: 'POST',
        url: `/control/studies/${studyID}/sites/${siteID}/participants/${ptpID}/rollover`,
        success,
        hasLoader: true,
        loadingKey: 'ptpMove',
        id: ptpID,
        body: JSON.stringify(bodyObj),
        fail,
      }),
    )
  }
}

const rolloverParticipants = ({
  ptpArr,
  studyID,
  nextStudyID,
  nextSiteID,
  nextTrackIDs,
  redirect,
  onComplete = () => {},
  resultProps,
}) => (dispatch, getState) => {
  dispatch(loadingActions.startLoader(true, 'moveParticipants'))
  const { participants } = getState()
  const { checkedPtps } = participants
  let successCount = 0
  let failCount = 0
  const addToSuccessCount = () => {
    successCount += 1
  }
  const addToFailCount = () => {
    failCount += 1
  }

  const responseCallbacks = {
    addToFailCount,
    addToSuccessCount,
  }
  const rolloverRequests = ptpArr.map(ptpID => {
    // checkedPtps objects treeLevel = 3 has path = "studyId.siteId.ptpId"
    const siteID = checkedPtps[ptpID].path.split('.')[1]
    return dispatch(
      _rolloverParticipant({
        nextStudyID,
        nextSiteID,
        nextTrackIDs,
        ptpID,
        studyID,
        siteID,
        resultProps,
        responseCallbacks,
      }),
    )
  })
  Promise.allSettled(rolloverRequests).then(() => {
    dispatch(loadingActions.stopLoader(true, 'moveParticipants'))
    if (successCount) {
      dispatch(
        notyActions.showSuccess({
          text: generateNotyMessage(
            `Successfully moved ${pluralize(successCount, 'participant', 'participants')}`,
            true,
          ),
        }),
      )
    }
    if (failCount) {
      dispatch(
        notyActions.showError({
          text: generateNotyMessage(`Failed to move ${pluralize(failCount, 'participant', 'participants')}`, true),
        }),
      )
    }
    onComplete()
    if (redirect) browserHistory.push(redirect)
    else {
      dispatch(fetchPtpsAndCohorts(studyID))
    }
  })
}
//
// UTILS
//

export const genders = {
  m: 'M',
  f: 'F',
  o: 'Other',
  n: '---',
}

const parseCohortList = (cohorts, sortResults = false) => {
  let _cohortList = cohorts
  if (sortResults) _cohortList = returnSortedObjArrByKey(cohorts, 'group_name')
  return _cohortList.map(cohort => {
    return [
      { key: 'id', value: cohort.study_group_id, sortValue: cohort.study_group_id },
      { key: 'name', value: cohort.group_name, sortValue: cohort.group_name },
      { key: 'remarks', value: cohort.description, sortValue: cohort.description },
    ]
  })
}

const parseCohortMap = cohorts => {
  const _cohortMap = {}
  cohorts.forEach(cohort => {
    _cohortMap[cohort.study_group_id] = cohort.group_name
  })
  return _cohortMap
}

const setInitialCheckedPtps = ({ ptpsPayload, resultList, participants, selectionPtpMap, study }) => dispatch => {
  const { checkedPtps } = participants
  const ptpsIdArr = Object.keys(checkedPtps)
  const checkedSitesIdObj = Object.values(checkedPtps)
    .map(val => `${val}`)
    .reduce((prev, curr) => ({ ...prev, [curr]: 1 + (prev[curr] || 0) }), {})
  const resultSitesList = resultList.filter(({ treeLevel }) => treeLevel == 2)
  const resultSitesObj = resultSitesList.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.ptpsCount }), {})
  const uniqueSitesArr = Object.keys(checkedSitesIdObj).filter(site => resultSitesObj[site] == checkedSitesIdObj[site])
  const areAllPtpsSelected = Object.keys(ptpsPayload).length === ptpsIdArr.length
  const filterPtpsArr = [...ptpsIdArr, ...uniqueSitesArr, areAllPtpsSelected && `${study.currentStudy.id}`]
  if (filterPtpsArr.length > 1) {
    const _selectionPtpMap = Object.keys(selectionPtpMap)
      .filter(key => filterPtpsArr.includes(key))
      .reduce((cur, key) => Object.assign(cur, { [key]: selectionPtpMap[key] }), {})
    dispatch(setCheckedPtps(_selectionPtpMap))
  } else {
    dispatch(setCheckedPtps(selectionPtpMap))
  }
}

const parsePtpMap = rawPtpMap => {
  return (dispatch, getState) => {
    const { study, user, subroute, participants } = getState()
    const isPiiRole = hasPiiRole(getUserRoleByStudy(user, study.currentStudy.id))
    const resultPtpMap = {}

    const _startedParticipants = []
    const _noActiveData = []
    const _noPassiveData = []
    const _accountDeletionRequestData = []
    const _accountSetupData = []
    const _accountRecoveryData = []

    const ptpIdArr = Object.keys(rawPtpMap || {})

    const PARTICIPANT_STATUS_MAP = {
      accountDeletionRequest: 'account-deletion-request',
      accountRecovery: 'account-recovery',
      completeAccountSetup: 'complete-account-setup',
      compliant: 'compliant',
      noActiveData: 'no-active-data',
      noPassiveData: 'no-passive-data',
      started: 'started',
    }

    ptpIdArr.forEach(ptpId => {
      const ptp = rawPtpMap[ptpId]

      const noPii = !ptp.fname && !ptp.lname
      const presentDateTime = moment()
      const name = noPii ? `Participant ${ptp.id}` : `${ptp.fname} ${ptp.lname}`
      const lastName = noPii ? `Participant` : `${ptp.lname}`
      const firstName = noPii ? `${ptp.id}` : `${ptp.fname}`
      const age = ptp.date_of_birth ? moment().diff(ptp.date_of_birth, 'years') : '---'
      const sex = genders[ptp.gender]
      const firstLogin = moment(ptp.first_login_timestamp || ptp.registration_timestamp)
      const lastInstrument = moment(ptp.last_instrument_timestamp || 0)
      const lastPassiveData = moment(ptp.last_passive_data_timestamp || 0)
      const lastConnection = moment(ptp.last_connection || 0)

      const daysSinceFirstLogin = dayDifference(firstLogin, presentDateTime)
      const daysSinceLastInstrument = dayDifference(lastInstrument, presentDateTime)
      const daysSinceLastPassiveData = dayDifference(lastPassiveData, presentDateTime)
      const daysSinceLastConnection = dayDifference(lastConnection, presentDateTime)

      const registrationDate = moment(ptp.registration_timestamp)

      const cohorts = ptp.study_groups ? Object.keys(ptp.study_groups).sort() : []
      let cohortsText = ptp.study_groups
        ? Object.values(ptp.study_groups)
            .sort()
            .join(', ')
        : ''
      if (cohortsText && cohortsText.length > 15) cohortsText = `${cohortsText.slice(0, 15)}...`
      else if (!cohortsText) cohortsText = 'None'

      const hasNoPassive = isFinite(daysSinceLastPassiveData) && daysSinceLastPassiveData > 1
      const hasNoActive = isFinite(daysSinceLastInstrument) && daysSinceLastInstrument > 14
      const hasAccountDeletionRequest = ptp.account_deletion_status === 'pending'

      /**
       * This participant status is only valid for emailless studies where @completed_account_setup
       * is explicitly sent and not null or undefined. If the value is null or undefined, we don't
       * classify the participant as being @isInAccountSetup .
       */
      const isInAccountSetup = !ptp.completed_account_setup && !(ptp.completed_account_setup == null)

      const isInAccountRecovery = ptp.account_recovery_support && !(ptp.account_recovery_support == null)

      // let _complianceString = 'compliant'
      let _complianceString = PARTICIPANT_STATUS_MAP.compliant
      const registration = {
        value: registrationDate.format(DATE_FORMAT_MAP.mainWithDateTime),
        sortValue: Math.floor(registrationDate.valueOf() / 1000),
        moment: registrationDate,
      }

      const hasLoggedIn = !!ptp.first_login_timestamp

      if (hasAccountDeletionRequest) {
        _accountDeletionRequestData.push(ptp.id)
        _complianceString = PARTICIPANT_STATUS_MAP.accountDeletionRequest
      } else if (isInAccountSetup) {
        _accountSetupData.push(ptp.id)
        _complianceString = PARTICIPANT_STATUS_MAP.completeAccountSetup
      } else if (hasNoPassive && hasNoActive) {
        _noActiveData.push(ptp.id)
        _noPassiveData.push(ptp.id)
        _complianceString = `${PARTICIPANT_STATUS_MAP.noPassiveData} ${PARTICIPANT_STATUS_MAP.noActiveData}`
      } else if (hasNoPassive) {
        _noPassiveData.push(ptp.id)
        _complianceString += ` ${PARTICIPANT_STATUS_MAP.noPassiveData}`
      } else if (hasNoActive) {
        _noActiveData.push(ptp.id)
        _complianceString = PARTICIPANT_STATUS_MAP.noActiveData
      }
      if (hasLoggedIn) {
        _startedParticipants.push(ptp.id)
        _complianceString += ` ${PARTICIPANT_STATUS_MAP.started}`
        if (isInAccountRecovery) {
          _accountRecoveryData.push(ptp.id)
          _complianceString += ` ${PARTICIPANT_STATUS_MAP.accountRecovery}`
        }
      }

      const firstLoginText = !hasLoggedIn
        ? `Never Logged In Resend Invite (registered ${moment(ptp.registration_timestamp).format(
            DATE_FORMAT_MAP.main,
          )})`
        : `${firstLogin.format(DATE_FORMAT_MAP.main)} (${daysSinceFirstLogin} days)`
      const lastInstrumentText = !ptp.last_instrument_timestamp
        ? 'N/A'
        : `${lastInstrument.format(DATE_FORMAT_MAP.main)} (${daysSinceLastInstrument} days)`
      const lastPassiveText = !ptp.last_passive_data_timestamp
        ? 'N/A'
        : `${lastPassiveData.format(DATE_FORMAT_MAP.main)} (${daysSinceLastPassiveData} days)`
      const lastConnectionText = !ptp.last_connection
        ? 'N/A'
        : `${lastConnection.format(DATE_FORMAT_MAP.main)} (${daysSinceLastConnection} days)`

      const row = [
        { key: 'checkbox', value: ptp.id, sortValue: null, compliance: _complianceString, siteID: ptp.site_id },
        { key: 'id', value: ptp.id, sortValue: ptp.id, className: 'id-row', isPtpDisabled: ptp.opt_out || false },
        { key: 'subjectId', value: ptp.subject_id || '---', sortValue: ptp.subject_id || DEFAULT_NA_STRING_SORT_VAL },
        isPiiRole
          ? { key: 'lastName', value: lastName, sortValue: lastName, className: 'name-row' }
          : { key: 'name', value: name, sortValue: name, className: 'name-row' },
        isPiiRole ? { key: 'firstName', value: firstName, sortValue: firstName, className: 'name-row' } : null,
        isPiiRole ? { key: 'email', value: ptp.email, sortValue: ptp.email, className: 'email-row' } : null,
        { key: 'siteName', value: ptp.site_name, sortValue: ptp.site_name },
        { key: 'siteId', value: ptp.site_id, sortValue: ptp.site_id, inLeafSite: ptp.in_leaf_site },
        { key: 'age', value: age, sortValue: age, className: 'age-row' },
        { key: 'sex', value: sex, sortValue: sex, className: 'sex-row' },
        { key: 'tracks', value: cohortsText, sortValue: cohortsText, className: 'cohort-row', cohorts },
        {
          key: 'firstLogin',
          value: firstLoginText,
          sortValue: ptp.first_login_timestamp ? firstLogin.valueOf() : firstLogin.valueOf() / 1000,
          daysSinceFirstLogin,
          moment: firstLogin,
          username: ptp.username,
          ptpId: ptp.id,
        },
        {
          key: 'lastInstrument',
          value: lastInstrumentText,
          sortValue: lastInstrument.valueOf(),
          moment: lastInstrument,
          className: 'instrument-row',
        },
        {
          key: 'lastPassiveData',
          value: lastPassiveText,
          sortValue: lastPassiveData.valueOf(),
          moment: lastPassiveData,
        },
        {
          key: 'lastConnection',
          value: lastConnectionText,
          sortValue: lastConnection.valueOf(),
          moment: lastConnection,
        },
        {
          key: 'instrumentsCompleted',
          value: ptp.instruments_completed,
          sortValue: ptp.instruments_completed,
          className: 'id-row',
        },
        {
          key: 'accountDeletionStatus',
          value: ptp.account_deletion_status,
          sortValue: ptp.account_deletion_status,
          className: 'account-deletion-status-row',
        },
      ]
      if (subroute === 'move') {
        row.push({ key: 'name', value: name, sortValue: name, className: 'name-row' })
      }
      row.props = { compliance: _complianceString, registration }
      resultPtpMap[ptpId] = row.filter(item => !!item)
    })

    dispatch({
      type: SET_STARTED_PTPS,
      list: _startedParticipants,
    })
    dispatch({
      type: SET_NO_ACTIVE_DATA,
      list: _noActiveData,
    })
    dispatch({
      type: SET_NO_PASSIVE_DATA,
      list: _noPassiveData,
    })
    dispatch({
      type: SET_ACCOUNT_DELETION_REQUEST_DATA,
      list: _accountDeletionRequestData,
    })
    dispatch({
      type: SET_ACCOUNT_SETUP_DATA,
      list: _accountSetupData,
    })
    dispatch({
      type: SET_ACCOUNT_RECOVERY_DATA,
      list: _accountRecoveryData,
    })
    dispatch({
      type: FETCHED_PARTICIPANTS,
      payload: resultPtpMap,
    })
    if (subroute === 'move') {
      _generatePtpSelectionList(rawPtpMap, study.currentStudy.id, true).then(
        ({ ptpsPayload, resultList, selectionPtpMap }) => {
          dispatch(movePtpsActions.setMovePtpsList(resultList))
          dispatch(setPtpMap(ptpsPayload))
          dispatch(setInitialCheckedPtps({ ptpsPayload, resultList, participants, selectionPtpMap, study }))
          dispatch(movePtpsActions.setDefaultProperties())
        },
      )
    }
  }
}

export const ptpInBlockedConsentAlert = (checkedPtps, unCompleteBlockedConsents) => dispatch => {
  const blockingPtps = Object.values(unCompleteBlockedConsents).flat()
  const ptps = Object.keys(checkedPtps).filter(ptpID => blockingPtps.includes(ptpID))
  dispatch(
    notyActions.showError({
      text: generateNotyMessage(
        `Consent of selected ${ptps.toString()} participant${ptps.length > 1 ? 's' : ''} is incomplete!`,
      ),
    }),
  )
}

//
// Reducers
//

function participants(state = {}, action) {
  switch (action.type) {
    case FETCHED_PARTICIPANTS:
      return action.payload
    case RESET_PARTICIPANTS:
      return {}
    case SET_PTPS_SELECTION_LIST:
      return action.payload
    default:
      return state
  }
}

function ptpMap(state = {}, action) {
  switch (action.type) {
    case FETCHED_PARTICIPANTS:
      return action.payload
    case RESET_PARTICIPANTS:
      return {}
    case SET_PTP_MAP:
      return action.ptpMap
    case SET_PTPS_SELECTION_LIST:
      return action.selectionPtpMap
    default:
      return state
  }
}

function checkedPtps(state = {}, action) {
  let newState
  switch (action.type) {
    case FETCHED_COHORT:
      newState = {}
      action.payload.participants.forEach(id => {
        newState[id] = null
      })
      return newState
    case TOGGLE_PARTICIPANT:
      newState = { ...state }
      if (action.id in newState) delete newState[action.id]
      else newState[action.id] = action.siteID
      return newState
    case RESET_CHECKED_PTPS:
      return {}
    case TOGGLE_CHECK_ALL_PARTICIPANTS:
      newState = {}
      if (Object.keys(state).length > 0) return newState
      if (action.list) {
        action.list.forEach(id => {
          newState[id] = null
        })
      } else {
        const { _ptpMap } = action
        const ptpIdArr = Object.keys(_ptpMap)
        const siteIdIndex = _ptpMap[ptpIdArr[0]].findIndex(el => el.key === 'siteId')
        ptpIdArr.forEach(ptpId => {
          newState[ptpId] = _ptpMap[ptpId][siteIdIndex].value // this sets the leaf level siteID as the value, so that we know what leaf siteId to delete the participant
        })
      }
      return newState
    case TOGGLE_FILTER_PARTICIPANT: {
      newState = { ...state }
      if (action.id in newState) delete newState[action.id]
      else newState[action.id] = action.obj
      return newState
    }
    case TOGGLE_FILTER_PARTICIPANT_ON: {
      newState = { ...state }
      newState[action.id] = action.obj
      return newState
    }
    case TOGGLE_FILTER_PARTICIPANT_OFF: {
      newState = { ...state }
      if (action.id in newState) delete newState[action.id]
      return newState
    }
    case SET_CHECKED_PTPS:
      return action.checkedPtps
    case SET_PTPS_SELECTION_LIST: {
      return action.selectionPtpMap
    }
    case TOGGLE_UNCHECK_ALL_PARTICIPANTS:
      return {}
    default:
      return state
  }
}

function startedParticipants(state = [], action) {
  switch (action.type) {
    case SET_STARTED_PTPS:
      return uniqueArr([...state, ...action.list])
    case RESET_PARTICIPANTS:
      return []
    default:
      return state
  }
}
function noActiveData(state = [], action) {
  switch (action.type) {
    case SET_NO_ACTIVE_DATA:
      return uniqueArr([...state, ...action.list])
    case RESET_PARTICIPANTS:
      return []
    default:
      return state
  }
}
function noPassiveData(state = [], action) {
  switch (action.type) {
    case SET_NO_PASSIVE_DATA:
      return uniqueArr([...state, ...action.list])
    case RESET_PARTICIPANTS:
      return []
    default:
      return state
  }
}
function accountDeletionRequestData(state = [], action) {
  switch (action.type) {
    case SET_ACCOUNT_DELETION_REQUEST_DATA:
      return uniqueArr([...state, ...action.list])
    case RESET_PARTICIPANTS:
      return []
    default:
      return state
  }
}
function accountSetupData(state = [], action) {
  switch (action.type) {
    case SET_ACCOUNT_SETUP_DATA:
      return uniqueArr([...state, ...action.list])
    case RESET_PARTICIPANTS:
      return []
    default:
      return state
  }
}
function accountRecoveryData(state = [], action) {
  switch (action.type) {
    case SET_ACCOUNT_RECOVERY_DATA:
      return uniqueArr([...state, ...action.list])
    case RESET_PARTICIPANTS:
      return []
    default:
      return state
  }
}

function cohortList(state = [], action) {
  switch (action.type) {
    case FETCHED_COHORTS:
      return parseCohortList(action.payload, action.sorted)
    default:
      return state
  }
}

function cohortMap(state = {}, action) {
  switch (action.type) {
    case FETCHED_COHORTS:
      return parseCohortMap(action.payload)
    default:
      return state
  }
}

function wizardName(state = { fname: '', lname: '' }, action) {
  switch (action.type) {
    case UPDATE_WIZARD_NAME:
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

const participantSelectionList = (state = [], action) => {
  switch (action.type) {
    case SET_PTPS_SELECTION_LIST:
      return action.list
    case RESET_PARTICIPANTS:
      return []
    default:
      return state
  }
}

export default combineReducers({
  checkedPtps,
  cohortList,
  cohortMap,
  noActiveData,
  noPassiveData,
  accountDeletionRequestData,
  accountRecoveryData,
  accountSetupData,
  participants,
  participantSelectionList,
  ptpMap,
  startedParticipants,
  wizardName,
  movePtpsList,
  ptpProperties: properties,
})

export const actions = {
  ...filterActions,
  ...movePtpsActions,
  deleteParticipants,
  downloadData,
  downloadBulkData,
  fetchPtpsAndCohorts,
  fetchPtps,
  fetchPtpsByNonCompleteBlockingConsent,
  resetCheckedPtps,
  resetParticipants,
  rolloverParticipants,
  toggleCheckAll,
  toggleFilterParticipant,
  toggleFilterParticipantOn,
  toggleFilterParticipantOff,
  toggleParticipant,
  toggleUncheckAll,
  updateSearchTerm,
  updateWizardName,
}
