import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/videoplayer.scss'

const VideoPlayer = ({ className = '', src, showControls = true, onEnterPip = () => {}, onLeavePip = () => {} }) => {
  const [inPIP, setInPIP] = useState(false)
  const videoRef = useRef()
  useEffect(() => {
    videoRef.current.onenterpictureinpicture = () => {
      onEnterPip()
      if (videoRef.current) setInPIP(true)
    }
    videoRef.current.onleavepictureinpicture = () => {
      onLeavePip()
      if (videoRef.current) setInPIP(false)
    }
  })

  useEffect(() => {
    return () => {
      // If the video player is unmounted, we exit the picture-in-picture component
      if (document.pictureInPictureElement) document.exitPictureInPicture()
    }
  }, [])

  const _className = `${className}${inPIP ? ' pip' : ''}`

  return (
    <video ref={videoRef} className={_className} src={src} controls={showControls}>
      <track kind='captions' />
    </video>
  )
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  onEnterPip: PropTypes.func,
  onLeavePip: PropTypes.func,
  src: PropTypes.string,
  showControls: PropTypes.bool,
}

export default VideoPlayer
