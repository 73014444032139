const CONSENT_DEPLOYMENT_TYPES = {
  all: {
    key: 'all',
    text: 'Send to all participants',
  },
  existing: { key: 'existing_participants_only', text: 'Send to existing participants' },
  new: { key: 'new_participants_only', text: 'Send to new participants when enrolled' },
}

export default CONSENT_DEPLOYMENT_TYPES
