/**
 * Created by karan on 2/23/17.
 */

import React from 'react'

export default () => (
  <div
    style={{
      textAlign: 'center',
      margin: 100,
    }}
  >
    <h1>Permission Denied</h1>
  </div>
)
