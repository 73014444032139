import React, { useState } from 'react'
import PropTypes from 'prop-types'
import STRINGS from 'utils/strings'
import { Popup, Radio } from 'components/UIElements'
import DurationInput from './DurationInput'
import TimeoutWindow from './TimeoutWindow'
import useDidMountEffect from 'utils/hooks/didMountEffect'
import { DAY_MULTIPLIER_MAP } from '../constants/schedulerConstants'
import { MINUTES_IN_DAY } from 'utils/constants'
import { convertMinToDays } from 'utils/time'

const TimedCompletionContent = props => {
  const {
    schedule,
    deleteMetadataKey,
    disabled,
    updateScheduleKey,
    updateScheduleKeyField,
    hasInstrumentUnification,
    isDiary,
    isCalendarModeChecked,
  } = props
  const { submission_expiry } = schedule
  const _submission_expiry = submission_expiry || {}
  const { expires_in } = _submission_expiry

  const [days, setDays] = useState(expires_in || 0)
  const [timeUnit, setTimeUnit] = useState('days')

  useDidMountEffect(() => {
    if (submission_expiry || submission) {
      const dayMultiplier = DAY_MULTIPLIER_MAP[timeUnit]
      const newDayVal = days * dayMultiplier
      updateScheduleKeyField('submission_expiry', 'expires_in', newDayVal, 'schedule.submission_expiry.expires_in')
      updateScheduleKeyField(
        'timeout_window',
        'submission',
        { value: convertMinToDays(newDayVal) },
        'schedule.timeout_window.submission.value',
      )
    }
  }, [days, timeUnit])

  const onTimeUnitDropdownSelect = item => {
    setTimeUnit(item.key)
  }

  const onClickNoSubmissionExpiry = () => {
    deleteMetadataKey('schedule', 'submission_expiry')
    deleteMetadataKey('schedule', 'timeout_window.submission')
  }

  const onClickHasSubmissionExpiry = () => {
    const dayMultiplier = DAY_MULTIPLIER_MAP[timeUnit]
    const newDayVal = days * dayMultiplier
    updateScheduleKey('submission_expiry', { expires_in: newDayVal })
    // Set value in minutes
    updateScheduleKey('timeout_window', { submission: { value: newDayVal * MINUTES_IN_DAY } })
  }
  const onBlur = e => {
    if (e.target.value === '') {
      setDays(1)
    }
  }

  return (
    <div className='timed-entry-menu'>
      <div className='flexed start-justified start-aligned'>
        {hasInstrumentUnification ? <p>{STRINGS.instrumentTimedEntry}</p> : <p>{STRINGS.diaryTimedEntry}</p>}
        <Popup
          className='width-examples'
          hover
          overrideOverflow
          noPointer
          dark
          trigger={<i className='fas fa-info-circle' />}>
          <p>{isDiary ? STRINGS.timedEntryInfoTipDiary : STRINGS.timedEntryInfoTip}</p>
        </Popup>
      </div>

      <Radio
        disabled={disabled}
        selected={!submission_expiry}
        onClick={onClickNoSubmissionExpiry}
        id='always-allowed-diary-entry'
        content={isDiary ? STRINGS.timedEntryPtpsAlwaysAllowedDiary : STRINGS.timedEntryPtpsAlwaysAllowed}
      />
      <Radio
        id='allow-for-diary-entry'
        disabled={disabled}
        selected={!!submission_expiry}
        onClick={onClickHasSubmissionExpiry}
        content={
          <DurationInput
            disabled={disabled}
            hasMonthsOption
            isSelected={!!expires_in || expires_in === 0}
            onBlur={onBlur}
            onChange={val => {
              setDays(val)
            }}
            inputValue={String(days)}
            unitValue={timeUnit}
            onDropdownSelect={onTimeUnitDropdownSelect}
            preText={STRINGS.allowPtps}
            postText={isDiary ? STRINGS.timedEntryForPtpsToEditDiary : STRINGS.timedEntryForPtpsToEdit}
            min={0}
          />
        }
      />
      {/* !!submission_expiry && hasInstrumentUnification && isCalendarModeChecked && <TimeoutWindow {...props} /> */}
    </div>
  )
}

TimedCompletionContent.propTypes = {
  deleteMetadataKey: PropTypes.func,
  disabled: PropTypes.bool,
  schedule: PropTypes.shape({ submission_expiry: PropTypes.object, timeout_window: PropTypes.object }),
  updateScheduleKey: PropTypes.func,
  updateScheduleKeyField: PropTypes.func,
}

export default TimedCompletionContent
