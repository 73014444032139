import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import 'styles/d3/documents.scss'

const Documents = ({
  openFileModal,
  disabled,
  documents,
  deleteDocument,
  maxNumFiles,
  rowIdx,
  placeholder,
  callbackProps,
  footnote,
}) => {
  const currentDocs = documents[rowIdx.toString()] || []
  const reachedMax = maxNumFiles ? currentDocs.length === maxNumFiles : null

  const onOpenFileModal = e => {
    if (reachedMax) {
      return
    }
    openFileModal(e, rowIdx)
  }
  return (
    <div className='attachments'>
      <h6>ATTACHMENTS</h6>
      <div className={`upload-box${reachedMax ? ' max' : ''}`} onClick={onOpenFileModal}>
        {currentDocs.length > 0 ? (
          <div className='docs'>
            {currentDocs.map(({ file_name }, idx) => {
              return (
                <span onClick={e => e.stopPropagation()} key={idx}>
                  {file_name}
                  {!disabled && (
                    <i
                      onClick={e => {
                        e.stopPropagation()
                        deleteDocument(rowIdx, idx)
                      }}
                      className='fas fa-times'
                    />
                  )}
                </span>
              )
            })}
          </div>
        ) : (
          placeholder
        )}
      </div>
      {footnote && <p className='document-upload-footnote'>{footnote}</p>}
      {callbackProps && (
        <Button
          className='document-upload-button'
          content={callbackProps.text}
          disabled={disabled || currentDocs.length === 0}
          onClick={callbackProps.callback}
          loading={callbackProps.loading}
        />
      )}
    </div>
  )
}

Documents.propTypes = {
  callbackProps: PropTypes.shape({
    callback: PropTypes.func,
    loading: PropTypes.bool,
    text: PropTypes.string,
  }),
  deleteDocument: PropTypes.func,
  disabled: PropTypes.bool,
  documents: PropTypes.objectOf(PropTypes.array),
  footnote: PropTypes.string,
  maxNumFiles: PropTypes.number,
  openFileModal: PropTypes.func,
  placeholder: PropTypes.string,
  rowIdx: PropTypes.number,
}

export default Documents
