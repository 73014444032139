import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getUserRoleIdByStudy } from 'utils/misc'
import { USER_ROLE_ID_MAP } from 'utils/constants'

import { getHeaderName } from '../../../../../utils/visitNameUtils'
import { visitTemplateActions } from '../modules/VisitTemplate'
import VisitTemplatePage from '../components/VisitTemplatePage'

const mapDispatchToProps = { ...visitTemplateActions }

const mapStateToProps = (state, ownProps) => {
  const { loading, location, participants, study, subroute, user, visitTemplateReducer, studyLock } = state
  const { visitTemplateID } = ownProps.params
  const { currentStudy } = study
  const path = location.pathname.match(/([^/]+$)/)[0]
  const studyID = currentStudy.id
  const roleId = getUserRoleIdByStudy(user, studyID)

  return {
    subtitleName: getHeaderName(path, subroute),
    visitorParticipantLoader: loading.visitorParticipant,
    isLoading: loading.general,
    isSiteCoord: roleId === USER_ROLE_ID_MAP.siteCoordinator || roleId === USER_ROLE_ID_MAP.clinicalCoordinator,
    ...participants,
    path,
    isVisitTemplateEditor: path === 'edit',
    studyID,
    ...visitTemplateReducer,
    visitTemplateID,
    studyLock,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <VisitTemplatePage {...props} />
})
