import React from 'react'
import { FormattedContent, Radio, Popup, TimePicker } from 'components/UIElements'
import STRINGS from 'utils/strings'
import { DEPLOYMENT_KEYS, INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { DEPLOY_CONFIRM, ARTICLE_TYPE } from '../../../../../../utils/instrumentConstants'
import { DEFAULT_DEPLOY_TIME } from '../../../../modules/Instrument'
import moment from 'moment'

const DAYS_OFFSET_KEY = DEPLOYMENT_KEYS.daysOffset

const engagementArticleImg = require('assets/engagement-article.png')
const visitSummaryImg = require('assets/visit-tab.png')

function Subheader(props) {
  const { subheaderText, infoText, imgSrc } = props
  return (
    <span className='subheader flexed start-justified'>
      <p>{subheaderText}</p>
      {infoText && (
        <Popup hover noPointer position='bottom-right' dark trigger={<i className='fa fa-info-circle' />}>
          <div className='hover-body'>
            {infoText && <p className='hover-text'>{infoText}</p>}
            {imgSrc && subheaderText && (
              <img src={imgSrc} className='hover-img' alt={`${subheaderText.split(' ')[0].toLowerCase()}-hover-img`} />
            )}
          </div>
        </Popup>
      )}
    </span>
  )
}

function VisitItemType(props) {
  const { disabled, instrument, deploy, updateDeploy, updateDeployTime, updateExpire, updateMetadataField } = props
  const { metadata, type } = instrument
  const isArticle = type === INSTRUMENT_TYPE_MAP.article
  const { time_of_day } = deploy

  const infoText = txt => `Mobile screen when ${txt}  is  selected`
  const isArticleTriggeredByVisit = metadata.article_type === ARTICLE_TYPE.engagement_article.key

  return (
    <>
      <div className='article-type'>
        <p>{STRINGS.whatTypeArticle}</p>
        <Radio
          disabled={disabled}
          id='engagement-article-radio'
          content={
            <Subheader
              subheaderText={STRINGS.visitItemEngagement}
              infoText={infoText(ARTICLE_TYPE.engagement_article.txt)}
              imgSrc={engagementArticleImg}
            />
          }
          onClick={() => {
            updateExpire({ never: null })
            updateMetadataField(ARTICLE_TYPE.key, ARTICLE_TYPE.engagement_article.key)
            updateMetadataField(DEPLOY_CONFIRM.key, true)
          }}
          selected={isArticleTriggeredByVisit}
        />
        <p className='article-type-desc std-p'>{STRINGS.visitItemEngagementBody}</p>
        <div className='visit-instrument-deployment-time'>
          <p>{STRINGS.setDeploymentTime}</p>
          <TimePicker
            allowEmpty={false}
            disabled={disabled}
            preventPastTimes
            format='HH:mm'
            value={time_of_day ? moment(time_of_day, 'HH:mm') : moment(DEFAULT_DEPLOY_TIME, 'HH:mm')}
            onChange={updateDeployTime}
          />
        </div>

        {isArticle ? (
          <>
            <Radio
              disabled={disabled}
              id='visit-summary-radio'
              content={
                <Subheader
                  subheaderText={STRINGS.visitItemVisitSummary}
                  infoText={infoText(ARTICLE_TYPE.visit_summary.txt)}
                  imgSrc={visitSummaryImg}
                />
              }
              onClick={() => {
                updateExpire({ never: true })
                updateMetadataField(ARTICLE_TYPE.key, ARTICLE_TYPE.visit_summary.key)
                updateMetadataField(DEPLOY_CONFIRM.key, false)
              }}
              selected={metadata.article_type === ARTICLE_TYPE.visit_summary.key}
            />
            <p className='article-type-desc std-p'>{STRINGS.visitItemVisitSummaryBody}</p>
          </>
        ) : null}
        <Radio
          disabled={disabled}
          id='missed-visit-radio'
          content={<Subheader subheaderText={STRINGS.visitItemMissedVisit} />}
          onClick={() => {
            updateExpire({ never: null })
            updateDeploy({ [DAYS_OFFSET_KEY]: 0 })
            updateMetadataField(ARTICLE_TYPE.key, ARTICLE_TYPE.missed_visit_follow_up.key)
            updateMetadataField(DEPLOY_CONFIRM.key, false)
          }}
          selected={metadata.article_type === ARTICLE_TYPE.missed_visit_follow_up.key}
        />
        <p className='article-type-desc std-p'>{STRINGS.visitItemMissedVisitBody}</p>
      </div>
      {isArticleTriggeredByVisit && (
        <div className='article-type'>
          <Subheader
            subheaderText={<FormattedContent content={STRINGS.visitItemConfirmQuestion} />}
            infoText={STRINGS.visitItemConfirmQuestionTooltip}
          />
          <Radio
            disabled={disabled}
            id='confirm-visit-article-yes-radio'
            content={STRINGS.visitItemConfirmQuestionNo}
            onClick={() => {
              updateMetadataField(DEPLOY_CONFIRM.key, true)
            }}
            selected={metadata[DEPLOY_CONFIRM.key]}
          />
          <Radio
            disabled={disabled}
            id='confirm-visit-article-yes-radio'
            content={STRINGS.visitItemConfirmQuestionYes}
            onClick={() => {
              updateMetadataField(DEPLOY_CONFIRM.key, false)
            }}
            selected={!metadata[DEPLOY_CONFIRM.key]}
          />
        </div>
      )}
    </>
  )
}

VisitItemType.propTypes = {}

export default VisitItemType
