import { setSubroute } from 'store/navbar'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import VisitTemplateContainer from '../VisitTemplatePage/container/VisitTemplateContainer'
import visitTemplate, { visitTemplateActions } from '../VisitTemplatePage/modules/VisitTemplate'

export const injectVisitReducers = store => {
  injectReducers(store, {
    visitTemplateReducer: visitTemplate,
  })
}

export default store => ({
  path: 'visit-templates/:visitTemplateID/edit',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectVisitReducers(store)
        store.dispatch(setSubroute('visit-templates'))

        const { studyID, visitTemplateID } = nextState.params
        const { study } = store.getState()
        const { currentStudy } = study
        setPageTitle(`Edit Visit Schedule | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(visitTemplateActions.fetchVisitTemplate(studyID, visitTemplateID))

        cb(
          null,
          UserIsAuthenticated(UserHasValidPassword(UserHasScope(['wpv'], VisitTemplateContainer, true, studyID))),
        )
      },
      'visit-templates/details',
    )
  },
})
