import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/percentmeter.scss'

const getColor = ratio => {
  const red = ratio < 0.5 ? 250 : Math.floor(250 - (250 - 214) * ((ratio - 0.5) / 0.5))
  const green = ratio < 0.5 ? Math.floor(204 + (238 - 204) * (ratio / 0.5)) : 238
  const blue = ratio < 0.5 ? 176 : Math.floor(176 + (235 - 176) * ((ratio - 0.5) / 0.5))
  return `rgb(${red}, ${green}, ${blue})`
}

const PercentMeter = ({ ratio, invariableColor, meterInnerClassName, hideNum, hideBar }) => {
  const percent = `${Math.floor(ratio * 100)}%`
  return (
    <div className='percent-meter'>
      {!hideBar && (
        <div className='meter-outer'>
          <div
            className={`meter-inner ${meterInnerClassName || ''}${invariableColor ? ' grey' : ''}`}
            style={{ background: invariableColor ? '' : getColor(ratio), width: percent }}
          />
        </div>
      )}
      {!hideNum && <span>{percent}</span>}
    </div>
  )
}

PercentMeter.propTypes = {
  hideNum: PropTypes.bool,
  invariableColor: PropTypes.bool,
  meterInnerClassName: PropTypes.string,
  hideBar: PropTypes.bool,
  ratio: PropTypes.number,
}

export default PercentMeter
