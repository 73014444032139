import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SortableTable, Checkbox } from 'components/UIElements'

const headerOptionsSeed = [
  { key: 'checkbox', sortable: false },
  { key: 'participantName', sortable: true, component: 'Participant Name' },
  { key: 'ptpId', sortable: true, component: 'ID' },
  { key: 'clinicianName', sortable: true, component: 'Clinician Name' },
  { key: 'clinicianEmail', sortable: true, component: 'Clinician Email' },
  { key: 'visitTime', sortable: true, component: 'Time' },
  { key: 'visitDuration', sortable: true, component: 'Duration' },
]

const VisitsTable = props => {
  const { checkedVisitsArr, offset, visits, toggleVisit, toggleCheckAllVisits, filteredVisits, studyLock } = props
  const { checkedVisits } = visits
  const allVisitsChecked = filteredVisits.length === checkedVisitsArr.length
  const [headerOptions, setHeaderOptions] = useState([])
  const renderCheckboxCell = ({ value, idx, checkedVisits }) => {
    return (
      !studyLock && (
        <td
          className='checkbox-cell'
          onClick={e => {
            e.stopPropagation()
            toggleVisit(value)
          }}
          key={`col_${idx}`}>
          <Checkbox checked={value in checkedVisits} />
        </td>
      )
    )
  }

  const renderFunctions = {
    checkbox: renderCheckboxCell,
  }
  useEffect(() => {
    const newHeaderOptions = [...headerOptionsSeed]
    if (!studyLock) {
      newHeaderOptions[0].component = (
        <Checkbox checked={allVisitsChecked} onClick={() => toggleCheckAllVisits(filteredVisits)} />
      )
    } else if (newHeaderOptions[0].key === 'checkbox') {
      newHeaderOptions.splice(0, 1)
    }
    setHeaderOptions(newHeaderOptions)
  }, [checkedVisitsArr])
  return (
    <SortableTable
      id='visits-table'
      headerOptions={headerOptions}
      offset={offset}
      sortingBy='participantName'
      renderFunctions={renderFunctions}
      rowList={filteredVisits}
      optionalCellProps={{ checkedVisits }}
      emptyText='No Matching Upcoming Visits'
    />
  )
}

VisitsTable.propTypes = {
  checkedVisitsArr: PropTypes.arrayOf(PropTypes.string),
  filteredVisits: PropTypes.arrayOf(PropTypes.array),
  offset: PropTypes.number,
  toggleCheckAllVisits: PropTypes.func,
  toggleVisit: PropTypes.func,
  visits: PropTypes.shape({
    checkedVisits: PropTypes.objectOf(PropTypes.bool),
  }),
}

export default VisitsTable
