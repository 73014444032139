import React from 'react'
import PropTypes from 'prop-types'
import STRINGS from 'utils/strings'
import { Input } from 'components/UIElements'

const SubjectIdInput = ({
  inputKey,
  label,
  type,
  placeholder,
  setSubjectId,
  fieldDisabled,
  updateField,
  isSubjectIdAvailable,
  resetSubjectIdStatus,
  value,
}) => {
  return (
    <div className={`flexed end-aligned start-justified ${inputKey}-input-wrapper`}>
      <Input
        disabled={fieldDisabled}
        label={label}
        type={type}
        onChange={val => {
          setSubjectId(val)

          if (!val) {
            resetSubjectIdStatus()
          }

          return updateField(inputKey, val)
        }}
        value={value}
        id={`participant-form-${inputKey}`}
        placeholder={placeholder}
      />
      {value && isSubjectIdAvailable !== null ? (
        <div className={`${inputKey}-status ${!isSubjectIdAvailable ? 'no-margin-bottom' : ''}`}>
          {isSubjectIdAvailable ? (
            <div className='status-info-box'>
              <i className='fa fa-check' />
              <div className='status-info-text'>{STRINGS.thisIdIsAvailable}</div>
            </div>
          ) : (
            <div className='status-info-box'>
              <i className='fa fa-exclamation-circle' />
              <div className='status-info-text'>
                <div>{STRINGS.thisIdIsTaken}</div>
                <div>{STRINGS.pleaseChooseAnother}</div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

SubjectIdInput.propTypes = {
  updateField: PropTypes.func,
  key: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  setSubjectId: PropTypes.func,
  fieldDisabled: PropTypes.bool,
  isCreate: PropTypes.bool,
  isSubjectIdAvailable: PropTypes.bool,
  resetSubjectIdStatus: PropTypes.func,
}

export default SubjectIdInput
