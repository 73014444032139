import React from 'react'
import { Dropdown, Radio, NumberInputDropdown, WarningText } from 'components/UIElements'
import { fromUTCMidnightSeconds, currentDayStartInUTC } from 'utils/time'
import { pluralize } from 'utils/misc'
import _dayOptions, {
  REPEAT_TIME_UNIT_OPTION,
  UNIFICATION_REPEAT_TIME_UNIT_OPTION,
  RECURRING_AFTER_TIME_OPTION_DEFAULT,
  isRepeatTimeUnitOpt,
  isUnificationRepeatTimeUnitOpt,
  isHours,
} from '../constants/schedulerConstants'
import contentConnect from '../containers/ContentContainer'

class RecurrenceContent extends React.Component {
  getRecurrence = (duration, interval) => Math.floor(duration / interval) + (duration % interval !== 0 ? 1 : 0)

  calculateNumRecurrences = interval => {
    const { deploy, expire } = this.props
    if (interval === '') return
    let instrumentDuration = 1
    if (expire.absolute) {
      const endDate = fromUTCMidnightSeconds(expire.absolute)
      const startDate = 'now' in deploy ? currentDayStartInUTC() : fromUTCMidnightSeconds(deploy.absolute)
      instrumentDuration = endDate.diff(startDate, 'days')
    }
    if (expire.first_login) instrumentDuration = expire.first_login.interval - deploy.first_login.interval
    if (expire.chain_expire_offset) instrumentDuration = expire.chain_expire_offset - deploy.chain_deploy_offset
    if (expire.relative_to_send_ideal) instrumentDuration = expire.relative_to_send_ideal.interval - 1
    return this.getRecurrence(instrumentDuration, interval)
  }

  onUpdateRecurringField = ({ num, isNDays }) => {
    let _num = num
    if (_num === 0) _num = ''

    const { expire, updateRecurringField } = this.props
    updateRecurringField('interval', _num, 'schedule.recurring.interval')
    if (isNDays) {
      updateRecurringField('visible_days', _num, 'schedule.recurring.visible_days')
    }
    if (!expire.hasOwnProperty('never')) {
      updateRecurringField('recurrences', this.calculateNumRecurrences(_num), 'schedule.recurring.recurrences')
    }
  }

  getStateFromProps = () => {
    const { recurring, deploy, expire } = this.props
    const hasOccurrences = recurring && recurring.hasOwnProperty('recurrences') && expire.hasOwnProperty('never')
    let disableInterval
    if (hasOccurrences) {
      disableInterval = false
    } else if (expire.hasOwnProperty('never') || expire.hasOwnProperty('chain_expire_offset')) {
      disableInterval = false
    } else if (expire.hasOwnProperty('first_login')) {
      disableInterval = expire.first_login.interval - deploy.first_login.interval < 2
    } else if (expire.hasOwnProperty('relative_to_send_ideal')) {
      disableInterval = expire.relative_to_send_ideal.interval < 2
    } else {
      disableInterval = fromUTCMidnightSeconds(expire.absolute).isSame(fromUTCMidnightSeconds(deploy.absolute), 'day')
    }
    return { hasOccurrences, disableInterval }
  }

  onTimeDropdownSelect = item => {
    this._updateRecurring(item.key)
  }

  /**
   * Update the time unit for recurring
   * @param {String} timeUnitKey
   */
  _updateRecurring = timeUnitKey => {
    const { recurring, updateRecurring, expire } = this.props
    const _default = RECURRING_AFTER_TIME_OPTION_DEFAULT[timeUnitKey]

    if (!expire.hasOwnProperty('never')) {
      const recurrences = this.calculateNumRecurrences(1)
      if (recurrences !== undefined) _default.recurrences = recurrences
    }

    const newRecurring = { ...recurring, ..._default }

    if (this.isTime(timeUnitKey)) {
      delete newRecurring.visible_days
    }

    updateRecurring(newRecurring)
  }

  isTime = recurringScheme => {
    const { hasInstrumentUnification } = this.props
    const options = hasInstrumentUnification ? UNIFICATION_REPEAT_TIME_UNIT_OPTION : REPEAT_TIME_UNIT_OPTION

    return options
      .filter(opt => !opt.key.includes('n-days'))
      .map(opt => opt.key)
      .includes(recurringScheme)
  }

  render() {
    const {
      recurring,
      updateExpire,
      updateRecurring,
      errors,
      hideImmediateRepeat,
      hasInstrumentUnification,
    } = this.props

    const repeatTime = hasInstrumentUnification
      ? isUnificationRepeatTimeUnitOpt(recurring?.scheme)
      : isRepeatTimeUnitOpt(recurring?.scheme)

    const selected = {
      no_repeat: !recurring,
      repeat_after: recurring && repeatTime,
      immediately: recurring && recurring.scheme === 'persistent',
    }

    // TODO: Set this to false before BE has this support as well such as UI has been confirmed.
    const newNHoursSupportEnabled = true

    const options = hasInstrumentUnification ? UNIFICATION_REPEAT_TIME_UNIT_OPTION : REPEAT_TIME_UNIT_OPTION

    const { hasOccurrences, disableInterval } = this.getStateFromProps()
    return (
      <div className='repeat-menu'>
        <p>Should this instrument repeat?</p>
        <Radio
          disabled={hasOccurrences}
          selected={selected.no_repeat}
          onClick={() => {
            if (recurring && recurring.recurrences) {
              updateExpire({ never: null })
            }
            updateRecurring(null)
          }}
          id='does-not-repeat'
          content="Doesn't Repeat"
        />
        <Radio
          id='repeat-after-every'
          disabled={disableInterval}
          selected={selected.repeat_after}
          onClick={() => {
            this._updateRecurring(options[0].key)
          }}
          content={
            <div className='flexed'>
              Repeat after every
              {this.isTime(recurring?.scheme) ? (
                <input
                  className='occurence-input'
                  aria-label='repeat-hours-input'
                  min={recurring.scheme ? 1 : 10}
                  max={isHours(recurring.scheme) ? 24 : 60}
                  type='number'
                  id='repeat-hours-input'
                  value={recurring && selected.repeat_after ? recurring.interval : ''}
                  onChange={e => {
                    this.onUpdateRecurringField({ num: parseInt(e.target.value) || 1 })
                  }}
                />
              ) : (
                <NumberInputDropdown
                  id='repeat-day-input'
                  value={recurring && selected.repeat_after ? recurring.interval : ''}
                  options={_dayOptions}
                  onChange={num => this.onUpdateRecurringField({ num, isNDays: true })}
                />
              )}
              {newNHoursSupportEnabled ? (
                <Dropdown
                  id='time-unit-options-dropdown'
                  selected={recurring?.scheme || options[0].key}
                  onSelect={this.onTimeDropdownSelect}
                  options={options.map(opt => {
                    const optText = opt.text
                    const text =
                      recurring && selected.repeat_after
                        ? pluralize(recurring.interval, optText.substring(0, optText.length - 1), optText, false)
                        : optText
                    return {
                      ...opt,
                      text,
                    }
                  })}
                />
              ) : recurring && selected.repeat_after ? (
                pluralize(recurring.interval, 'day', 'days', false)
              ) : (
                'days'
              )}
              <WarningText text={errors['schedule.recurring.interval']} />
            </div>
          }
        />
        {!hideImmediateRepeat && (
          <Radio
            disabled={hasOccurrences}
            selected={selected.immediately}
            onClick={() => {
              updateRecurring({ scheme: 'persistent' })
            }}
            id='repeat-immediately'
            content='Repeat Immediately'
          />
        )}
      </div>
    )
  }
}

export default contentConnect(RecurrenceContent)
