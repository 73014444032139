import { connect } from 'react-redux'
import { modalActions } from 'store/modal'
import VisitTemplateView from '../components/VisitTemplateView'
import { getHeaderName, getSelectedTab } from '../../../utils/visitNameUtils'

const mapState = state => {
  const { loading, location, study, subroute } = state
  const path = location.pathname.match(/([^/]+$)/)[0]
  const studyID = study.currentStudy.id
  return {
    headerName: getHeaderName(path, subroute),
    loading: loading.general,
    path,
    preview: path === 'preview',
    selectedTab: getSelectedTab(path),
    studyID,
    subroute,
  }
}

const mapDispatch = modalActions

export default connect(mapState, mapDispatch)(VisitTemplateView)
