import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Radio, Dropdown, Input, Popup } from 'components/UIElements'
import { CONSENT_TYPE_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import { convert2DArrayToArray } from '../../../utils/consentUtils'

const ConsentFields = props => {
  const {
    disabled,
    errors,
    updateConsentType,
    updateConsentName,
    updateDisplayName,
    consent,
    consentList,
    updateAddendumConsent,
  } = props

  const updateSelected = key => ({
    1: key === CONSENT_TYPE_MAP.consent,
    2: key === CONSENT_TYPE_MAP.reconsent,
    3: key === CONSENT_TYPE_MAP.addendum,
  })

  const { consent_title: consentName, metadata, consent_id: consentId, consent_type: consentType } = consent
  const { consentName: nameErr, displayName: displayNameErr } = errors
  const { display_name: displayName, addendum_for: addendumFor } = metadata
  const scheduledList = consentList?.scheduled || []

  const _scheduledList = convert2DArrayToArray(scheduledList)
    .map(({ action }) => ({
      key: action.consentId,
      value: action.consentId,
      text: action.consent_title,
      type: action.consent_type,
    }))
    .filter(({ type }) => type !== 'addendum')

  const [selected, setSelected] = useState(updateSelected(consentType || CONSENT_TYPE_MAP.consent))

  useEffect(() => {
    const type = Object.keys(selected).filter(key => selected[key])[0]
    if (type == 3) {
      const firstScheduledConsentItem = _scheduledList[0]
      updateConsentType(CONSENT_TYPE_MAP.addendum)
      if (!addendumFor) {
        const addendumForConsentName = firstScheduledConsentItem?.text
        updateAddendumConsent({
          consentId: firstScheduledConsentItem?.value,
          consentTitle: addendumForConsentName,
        })
        /**
         * The following lines add a default consent_title and display_name for the
         * addendum based on the title of the consent the current addendum is for
         */
        const addendumName = `${STRINGS.addendumForPrefix}: ${addendumForConsentName}`
        updateConsentName(addendumName)
        updateDisplayName(addendumName)
      } else {
        const addendumName = `${STRINGS.addendumForPrefix}: ${addendumFor.consent_title}`
        updateConsentName(addendumName)
        updateDisplayName(addendumName)
      }
    } else {
      updateConsentType(type == 2 ? CONSENT_TYPE_MAP.reconsent : CONSENT_TYPE_MAP.consent)
    }
  }, [selected])

  const clearFields = () => {
    updateConsentName('')
    updateDisplayName('')
  }

  useEffect(() => {
    if (!consentId) clearFields()
  }, [consentId])

  const renderConsentNames = () => {
    return (
      <div className='names'>
        <Input
          label='Consent name'
          id='consent-name-input'
          className='consent-name-input'
          placeholder='Consent name displayed in the Consent List'
          value={consentName || ''}
          onChange={value => updateConsentName(value)}
          spellCheck
          charLimit={200}
          hasError={nameErr && !consentName}
          errorText={nameErr}
        />
        <Input
          id='ui-display-name-input'
          label='UI Display Name'
          className='consent-name-input'
          placeholder='Consent name displayed in App'
          value={displayName || ''}
          onChange={value => updateDisplayName(value)}
          spellCheck
          charLimit={200}
          hasError={displayNameErr && !displayName}
          errorText={displayNameErr}
        />
      </div>
    )
  }

  const updateSelection = key => {
    setSelected(updateSelected(key))
    clearFields()
  }

  const selectAddendumForConsent = item => {
    updateAddendumConsent({ consentId: item.value, consentTitle: item.text })
    const addendumName = `${STRINGS.addendumForPrefix}: ${item.text}`
    updateConsentName(addendumName)
    updateDisplayName(addendumName)
  }

  return (
    <Container>
      <div className='consent-fields'>
        <Radio
          id='consent-radio'
          disabled={disabled}
          selected={selected[1]}
          onClick={() => updateSelection(CONSENT_TYPE_MAP.consent)}
          content='Consent'
        />
        {selected[1] && renderConsentNames()}
        <Radio
          id='reconsent-radio'
          disabled={disabled}
          selected={selected[2]}
          onClick={() => updateSelection(CONSENT_TYPE_MAP.reconsent)}
          content='Reconsent'
        />
        {selected[2] && renderConsentNames()}
        <Radio
          id='addendum-radio'
          disabled={disabled || _scheduledList.length === 0}
          selected={selected[3]}
          onClick={() => updateSelection(CONSENT_TYPE_MAP.addendum)}
          content={
            <span>
              Addendum
              <Popup hover noPointer position='bottom-right' dark trigger={<i className='fa fa-info-circle' />}>
                <div className='hover-text'>{STRINGS.addendumDescription}</div>
              </Popup>
            </span>
          }
        />
      </div>
      {selected[3] && (
        <Dropdown
          placeholder='Search consent to add on'
          onSelect={selectAddendumForConsent}
          options={_scheduledList}
          selected={addendumFor?.consent_id}
        />
      )}
    </Container>
  )
}

ConsentFields.propTypes = {
  consent: PropTypes.object,
  consentList: PropTypes.shape({
    scheduled: PropTypes.array,
  }),
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  saveAttempted: PropTypes.bool,
  updateAddendumConsent: PropTypes.func,
  updateConsentName: PropTypes.func,
  updateConsentType: PropTypes.func,
  updateDisplayName: PropTypes.func,
}

export default ConsentFields
