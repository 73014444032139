import { setPageTitle } from 'utils/misc'
import { injectReducer } from 'store/reducers'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { setSubroute } from 'store/navbar'
import casesReducer, { actions } from '../CasesPage/modules/Cases'
import EditCaseContainer from './containers/EditCaseContainer'

export default store => ({
  path: ':caseId',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducer(store, {
          key: 'cases',
          casesReducer,
        })
        const { studyID, caseId } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`View Case | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(setSubroute('cases'))
        store.dispatch(actions.fetchCase(studyID, caseId))
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(UserHasScope(['rp', 'rp-no-pii'], EditCaseContainer, false, studyID)),
          ),
        )
      },
      '/cases/:caseId',
    )
  },
})
