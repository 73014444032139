import React from 'react'
import PropTypes from 'prop-types'
import { DataBox, AccountSetupTooltip } from 'components/UIElements'

class ActiveDataSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { selected: null }
  }

  componentDidUpdate(prevProps) {
    const {
      filterProps: { filters: prevFilters },
    } = prevProps
    const {
      filterProps: { filters },
    } = this.props
    if (prevFilters.checkbox && !filters.checkbox) {
      this.setState({ selected: null })
    }
  }

  getStartedParticipantsFilter = selected => {
    return col => {
      return col.compliance.includes(selected)
    }
  }

  onSelect = nextSelected => {
    const { addFilter, removeFilter, onDataSectionSelect } = this.props
    const { selected } = this.state
    if (nextSelected === selected) {
      this.setState({ selected: null })
      onDataSectionSelect(null)
      removeFilter('checkbox')
    } else {
      this.setState({ selected: nextSelected })
      onDataSectionSelect(nextSelected)
      addFilter('checkbox', this.getStartedParticipantsFilter(nextSelected))
    }
  }

  render() {
    const { participantList, study } = this.props
    const _props = { ...this.props, ...this.state, onSelect: this.onSelect, total: participantList.length }
    const { accountDeletionRequestData, accountRecoveryData, accountSetupData } = _props
    const { config } = study
    return (
      <div>
        <h5>Participant Activity Statuses</h5>
        <DataBox
          {..._props}
          type='no-active-data'
          header='No Active Data'
          subtitle='Participants inactive since 14 days ago'
          value={_props.noActiveData.length}
        />
        <DataBox
          {..._props}
          type='no-passive-data'
          header='No Passive Data'
          subtitle='Participants inactive since 24 hours ago'
          value={_props.noPassiveData.length}
        />
        <DataBox
          {..._props}
          type='started'
          header='Started'
          subtitle='Participants who have logged in to the app'
          value={_props.startedParticipants.length}
        />
        {!!accountDeletionRequestData.length && (
          <DataBox
            {..._props}
            type='account-deletion-request'
            header='Account Deletion Request'
            subtitle='Participants requested account deletion'
            value={accountDeletionRequestData.length}
          />
        )}
        {!!accountSetupData.length && (
          <DataBox
            {..._props}
            type='complete-account-setup'
            header='Complete Account Setup'
            value={accountSetupData.length}
            content={
              <AccountSetupTooltip
                url={config?.complete_account_setup_url}
                fileName={'complete_account_setup_url.pdf'}
              />
            }
            noPointer={false}
          />
        )}
        {!!accountRecoveryData.length && (
          <DataBox
            {..._props}
            className='wider'
            type='account-recovery'
            header='Account Recovery'
            subtitle='These participants are currently blocked from the study due to lost login credentials. Please connect with these participants outside of the Datacubed Health platform to assist with regaining access to their mobile app.'
            value={accountRecoveryData.length}
          />
        )}
      </div>
    )
  }
}

ActiveDataSection.propTypes = {
  addFilter: PropTypes.func,
  header: PropTypes.string,
  onDataSectionSelect: PropTypes.func,
  onSelect: PropTypes.func,
  participantList: PropTypes.arrayOf(PropTypes.array),
  removeFilter: PropTypes.func,
  selected: PropTypes.string,
  subtitle: PropTypes.string,
  total: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.number,
}

export default ActiveDataSection
