import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/UIElements'
import STRINGS from 'utils/strings'

const InReviewPanel = ({ inReviewChecked, onToggleInReviewChecked }) => {
  return (
    <div className='in-review attribute panel'>
      <h5 className='label-small med-font'>{STRINGS.inReviewScreen}</h5>
      <Checkbox
        checked={inReviewChecked}
        onClick={onToggleInReviewChecked}
        label={STRINGS.inReviewDescription}
        toggle
      />
    </div>
  )
}

InReviewPanel.propTypes = {
  inReviewChecked: PropTypes.bool,
  onToggleInReviewChecked: PropTypes.func,
}

export default InReviewPanel
