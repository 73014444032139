import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getUserRoleByStudy, getUserScopeByStudy, hasPiiRole, disableScope } from 'utils/misc'
import { actions as tableFilterActions } from 'store/tableFilters'
import { modalActions } from 'store/modal'
import { resetDocs } from 'store/documents'
import { drawerActions } from 'store/drawer'
import { USER_ROLES_MAP, USER_SCOPES } from 'utils/constants'
import { checkIsEmailless } from 'utils/studyConfigUtils'
import SadCloud from 'components/SadCloud'
import { actions as ptpsActions, ptpInBlockedConsentAlert } from '../modules/Participants'
import { actions as createPtpActions } from '../../CreateParticipant/modules/CreateParticipant'
import ParticipantWizard from '../components/ParticipantWizard'
import ParticipantsPage from '../components/ParticipantsPage'
import { onSetOrphanedParticipants } from '../../../../Sites/routes/SitesPage/modules/Sites'

const mapStateToProps = state => {
  const { loading, participants, searchTerm, sites, studies, study, user, studyLock, consents } = state
  const { leafSites } = sites
  const { currentStudy } = study

  const studyID = currentStudy.id
  const studyName = currentStudy.platform_name
  const currentUserRole = getUserRoleByStudy(user, studyID)
  const userScope = getUserScopeByStudy(user, currentStudy)
  const participantDataFields = currentStudy.config?.participant_data_fields
  const isEmailless = checkIsEmailless(currentStudy)
  const noTracksFeature = currentStudy.config?.tracks?.disabled === true

  return {
    ...participants,
    canAddPtp: userScope.includes('wxp'),
    canAddCohort: userScope.includes('wxc'),
    canDelete: userScope.includes('dp'),
    canMovePtp: userScope.includes('mp'),
    canResendInviteOrResetPw: userScope.includes('rpe'),
    canSeeOrphans:
      currentUserRole === USER_ROLES_MAP.root ||
      currentUserRole === USER_ROLES_MAP.studyCoordinator ||
      currentUserRole === USER_ROLES_MAP.investigator,
    canWrite: userScope.includes('wp'),
    consentRequired: currentStudy.consent_required,
    disableTrackCreation: disableScope({ study, user, scope: USER_SCOPES.wxc.code }),
    filterProps: state.tableFilters,
    isCaseManager: currentUserRole === USER_ROLES_MAP.caseManager,
    isEmailless,
    leafSites,
    loading: loading.general,
    noTracksFeature,
    participantList: Object.values(participants.participants),
    ptpsComplianceLoading: loading.ptpsCompliance,
    ptpMoveLoading: loading.moveParticipants,
    page: 'tracks' in state.location.query ? 'tracks' : 'participants',
    searchTerm,
    studyID,
    studyName,
    studies,
    wizardName: state.participants.wizardName,
    hasPiiRole: hasPiiRole(currentUserRole),
    studyLock,
    participantDataFields,
    study: currentStudy,
    unCompleteBlockedConsents: consents?.unCompletedBlockedConsents || {},
  }
}

const mapDispatchToProps = {
  resetDocs,
  ...ptpsActions,
  ...tableFilterActions,
  ...modalActions,
  ...drawerActions,
  onSetOrphanedParticipants,
  resetParticipant: createPtpActions.resetParticipant,
  triggerResendInvite: createPtpActions.triggerResendInvite,
  ptpInBlockedConsentAlert,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { canAddPtp, participantList } = props
  if (props.loading) {
    return <Loader />
  }
  if (!canAddPtp && participantList.length === 0) {
    return <SadCloud text='There are 0 participants. Please check back later.' />
  }
  return participantList.length === 0 ? <ParticipantWizard {...props} /> : <ParticipantsPage {...props} />
})
