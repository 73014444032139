import { setSubroute } from 'store/navbar'
import { injectReducer } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import { UserHasValidPassword, UserIsAuthenticated, UserHasScope } from 'containers/authContainers'
import DataCorrectionContainer from './containers/DataCorrectionContainer'
import reducer, { actions } from './modules/DataCorrection'

export default store => {
  return {
    path: 'data-correction',
    exact: true,
    getIndexRoute(location, cb) {
      store.dispatch(setSubroute('dataCorrection'))
      cb(null, {
        getComponent(nextState, _cb) {
          const { params } = nextState
          const { studyID } = params
          const state = store.getState()
          setPageTitle(`Data Correction | Study ${studyID} | ${state.study.currentStudy.platform_name}`)

          injectReducer(store, {
            key: 'dataCorrection',
            reducer,
          })
          store.dispatch(actions.initializeDataCorrectionForm())
          store.dispatch(actions.getDataCorrectionForm(studyID))

          require.ensure(
            [],
            () => {
              _cb(
                null,
                UserIsAuthenticated(
                  UserHasValidPassword(UserHasScope(['xf'], DataCorrectionContainer, false, studyID)),
                ),
              )
            },
            'data-correction',
          )
        },
      })
    },
  }
}
