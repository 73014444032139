import { connect } from 'react-redux'
import React from 'react'
import Loader from 'components/D3Loader'
import InstrumentPreviewPage from '../components/InstrumentPreviewPage'
import { onFetchInstrument, onFetchInstrumentEvents } from '../../../modules/Instrument'
import { fetchSurvey, fetchArticle } from '../../EditSurvey/modules/Survey'
import { fetchClinro } from '../../EditClinro/modules/Clinro'
import { fetchDiary } from '../../EditDiary/modules/Diary'

const mapStateToProps = (state, ownProps) => {
  const {
    loading,
    instrumentReducer,
    surveyEditor,
    clinroEditor,
    diaryEditor,
    study,
    user,
    participants,
    sites = {},
  } = state
  const studyID = study.currentStudy.id
  const { cohortMap } = participants
  const { siteSelectionList = [] } = sites
  return {
    loading: loading.general,
    ...instrumentReducer,
    ...surveyEditor,
    diary: diaryEditor,
    clinro: clinroEditor,
    studyID: ownProps.studyID || null,
    instrumentID: ownProps.instrumentID || null,
    inDrawer: ownProps.inDrawer || null,
    canWrite: user.permissions[studyID].scope.includes('wa'),
    cohortMap,
    siteSelectionList,
    caregiverEnabled: true, // !!study.currentStudy.caregiver_enabled, // TODO: uncomment once study configuration setting for `caregiver_enabled` will be presented
    userId: user?.id,
    study: study?.currentStudy,
  }
}

const mapDispatchToProps = {
  onFetchInstrument: (studyID, instrumentID, version, inDrawer) =>
    onFetchInstrument(studyID, instrumentID, version, inDrawer),
  onFetchInstrumentEvents: (studyID, instrumentID, inDrawer) =>
    onFetchInstrumentEvents(studyID, instrumentID, inDrawer),
  onFetchSurvey: (studyID, instrumentID, version, inDrawer) => fetchSurvey(studyID, instrumentID, version, inDrawer),
  onFetchClinro: (studyID, instrumentID, version, inDrawer) => fetchClinro(studyID, instrumentID, version, inDrawer),
  onFetchArticle: (studyID, instrumentID, version, inDrawer) => fetchArticle(studyID, instrumentID, version, inDrawer),
  onFetchDiary: (studyID, instrumentID, version, inDrawer) => fetchDiary(studyID, instrumentID, version, inDrawer),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.loading ? <Loader /> : <InstrumentPreviewPage {...props} />
})
