import React from 'react'
import { Filter, Selector } from 'components/UIElements'
import { _getMapState, mapActions } from 'components/Filter'
import { connect } from 'react-redux'

class SexFilter extends Filter {
  constructor(props) {
    super(props)
    this.defaults = { selected: '', filterType: 'radio' }
    this.state = { ...this.defaults }
    this.key = 'sex'
    this.header = 'Sex'
    this.allSexes = [
      { key: '---', value: '---', text: '---' },
      { key: 'F', value: 'F', text: 'Female' },
      { key: 'M', value: 'M', text: 'Male' },
      { key: 'Other', value: 'Other', text: 'Other' },
    ]
  }

  onSelectSex = ({ key }) => {
    this.setState({ selected: key, _edited: true })
  }

  getFilter(criteria) {
    return data => data.value === criteria.selected
  }

  render() {
    return super.render(
      <div style={{ width: 336 }} className='checkbox-list'>
        <Selector onSelect={this.onSelectSex} selected={this.state.selected} options={this.allSexes} />
      </div>,
    )
  }
}

export default connect(
  _getMapState('sex'),
  mapActions,
)(SexFilter)
