import React from 'react'
import { Filter } from 'components/UIElements'
import { _getMapState, mapActions } from 'components/Filter'
import { connect } from 'react-redux'
import { renderers, filterGetters } from './Utils'

class NumRangeFilter extends Filter {
  constructor(props) {
    super(props)
    this.defaults = { min: '', max: '', filterType: 'numRange' }
    this.state = { ...this.defaults }
    this.key = props.dataKey
    this.header = props.header
    this.valueKey = props.valueKey
    this.align = props.align
  }

  onChange = key => {
    return value => {
      this.setState({ [key]: value, _edited: true })
    }
  }

  getFilter(criteria) {
    return filterGetters.getNumRangeFilter(criteria.min, criteria.max, this.valueKey)
  }

  render() {
    return super.render(
      <div style={{ width: 350 }}>{renderers.renderNumRange(this.state, this.onChange, this.props.placeholders)}</div>,
    )
  }
}

export default config =>
  connect(
    _getMapState(config.dataKey),
    mapActions,
  )(props => {
    return <NumRangeFilter {...props} {...config} />
  })
