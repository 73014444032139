import React from 'react'
import PropTypes from 'prop-types'
import { Container, Divider } from 'components/UIElements'
import Subheader from '../../../../components/Subheader'
import VisitSchedulePreviewTable from './VisitSchedulePreviewTable'
import VisitScheduleAndDaySelector from './VisitScheduleAndDaySelector'

const VisitScheduleConfiguration = props => {
  const { selectedVisitScheduleId, setSelectedVisitScheduleId } = props

  return (
    <Container className='visit-schedule-configuration'>
      <Subheader subheaderText='Which visit schedule is this visit part of?' />
      <div className='flexed start-aligned'>
        <VisitScheduleAndDaySelector
          {...props}
          selectedVisitScheduleId={selectedVisitScheduleId}
          setSelectedVisitScheduleId={setSelectedVisitScheduleId}
        />
        <Divider color='#f5f5f5' margin='0 40px' width='2px' isVertical />
        <VisitSchedulePreviewTable {...props} selectedVisitScheduleId={selectedVisitScheduleId} />
      </div>
    </Container>
  )
}

VisitScheduleConfiguration.propTypes = {
  selectedVisitScheduleId: PropTypes.string,
  setSelectedVisitScheduleId: PropTypes.func,
}

export default VisitScheduleConfiguration
