import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import participants, { fetchCohorts } from '../../../Participants/routes/ParticipantsPage/modules/Participants'
import instrumentReducer from '../Instrument/modules/Instrument'
import instruments from './modules/Instruments'
import surveyReducer from '../Instrument/routes/EditSurvey/modules/Survey'
import clinroReducer from '../Instrument/routes/EditClinro/modules/Clinro'
import diaryReducer from '../Instrument/routes/EditDiary/modules/Diary'
import upload from '../Instrument/routes/UploadSurvey/modules/UploadSurvey'
import InstrumentsView from './containers/InstrumentsViewContainer'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'

export default store => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      require => {
        const { params } = nextState
        const { studyID } = params

        store.dispatch(setSubroute('instruments'))
        injectReducers(store, {
          instruments,
          instrumentReducer,
          upload,
          surveyEditor: surveyReducer,
          clinroEditor: clinroReducer,
          diaryEditor: diaryReducer,
          participants,
          sites: sitesReducer,
        })
        const { currentStudy } = store.getState().study
        setPageTitle(`Instruments | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(require('./modules/Instruments').actions.fetchInstruments({ studyID }))
        store.dispatch(fetchCohorts(studyID))
        store.dispatch(fetchSites(studyID, false))
        cb(null, UserIsAuthenticated(UserHasValidPassword(UserHasScope(['ra'], InstrumentsView, false, studyID))))
      },
      'instruments',
    )
  },
})
