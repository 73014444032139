import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import ConsentPropertiesContainer from './containers/ConsentPropertiesContainer'
import newConsentReducer, { fetchNewConsent } from '../NewConsentBuilder/modules/NewConsent'
import consents, { fetchConsents } from '../../../ConsentsDashboard/modules/Consents'
import instrumentReducer from '../../../../../Instruments/routes/Instrument/modules/Instrument'
import ptpsReducer from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'

export const injectConsentReducers = store => {
  injectReducers(store, {
    consents,
    newConsentReducer,
    instrumentReducer,
    participants: ptpsReducer,
  })
}

export default store => {
  return {
    path: ':consentId/:consentVersion/properties',
    getComponent(nextState, cb) {
      store.dispatch(setSubroute('consentProperties'))
      require.ensure(
        [],
        () => {
          injectConsentReducers(store)
          const { studyID, consentId, consentVersion } = nextState.params
          const { currentStudy } = store.getState().study
          setPageTitle(`Edit Consent | Study ${studyID} | ${currentStudy.platform_name}`)
          store.dispatch(fetchConsents(studyID))
          store.dispatch(fetchNewConsent({ studyId: studyID, consentId, version: consentVersion, getJson: true }))
          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(
                UserHasScope(['xc'], ConsentPropertiesContainer, false, studyID, null, currentStudy),
              ),
            ),
          )
        },
        '/consents/metadata',
      )
    },
  }
}
