import { useState, useEffect, useMemo } from 'react'

export const LIST_STATE = {
  restore: 'RESTORE',
  all: 'ALL',
  none: 'NONE',
}
const useCheckboxList = ({ list, selectAll, field = 'id' }) => {
  const [checkboxListData, setCheckboxListData] = useState({})
  const [savedCheckboxListData, setSavedCheckboxListData] = useState({})
  const [checkboxList, setcheckboxList] = useState(list)

  const keyList = useMemo(() => Object.keys(checkboxListData).filter(key => checkboxListData[key]), [checkboxListData])

  const checkboxListState = useMemo(() => {
    if (
      !Object.keys(checkboxListData).filter(key => checkboxListData[key]).length &&
      Object.keys(savedCheckboxListData).length
    )
      return LIST_STATE.restore
    if (keyList.length !== checkboxList.length) return LIST_STATE.all
    return LIST_STATE.none
  }, [checkboxListData, keyList, savedCheckboxListData])

  const checkboxListHandler = type => {
    const state = LIST_STATE[type] || checkboxListState
    switch (state) {
      case LIST_STATE.restore:
        setCheckboxListData({ ...savedCheckboxListData })
        break
      case LIST_STATE.all:
        setSavedCheckboxListData(
          Object.keys(checkboxListData).reduce((acc, key) => {
            if (checkboxListData[key]) acc[key] = true
            return acc
          }, {}),
        )
        setCheckboxListData(
          checkboxList.reduce((acc, item) => {
            acc[item[field]] = true
            return acc
          }, {}),
        )
        break
      default:
        setCheckboxListData(
          checkboxList.reduce((acc, item) => {
            acc[item[field]] = false
            return acc
          }, {}),
        )
        break
    }
  }

  const checkboxListItemHandler = id => {
    setCheckboxListData(prev => {
      const prevState = { ...prev }
      if (prevState[id]) prevState[id] = !prev[id]
      else prevState[id] = true
      return prevState
    })
  }

  useEffect(() => {
    if (selectAll) checkboxListHandler()
    else {
      const initValue = checkboxList.reduce((acc, { checked, id }) => {
        if (checked) acc[id] = checked
        return acc
      }, {})
      setCheckboxListData(initValue)
    }
  }, [])

  return {
    checkboxListData,
    savedCheckboxListData,
    checkboxListState,
    checkboxListHandler,
    checkboxListItemHandler,
    checkboxList,
    setCheckboxListData,
  }
}

export default useCheckboxList
