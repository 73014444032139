import React from 'react'
import PropTypes from 'prop-types'
import VisitTemplateNav from './VisitTemplateNav'
import '../stylesheets/visitsTemplate.scss'

const VisitTemplateView = props => {
  const { children } = props

  return (
    <div className='visits-template page'>
      <VisitTemplateNav {...props} />
      {children}
    </div>
  )
}

VisitTemplateView.propTypes = {
  children: PropTypes.node,
}

export default VisitTemplateView
