import { connect } from 'react-redux'
import { getUserScopeByStudy } from 'utils/misc'
import DataGapChart from '../components/DataGapChart'
import { actions } from '../modules/StudyDashboard'

const mapState = state => {
  const { study, studyDashboard, user } = state
  const { currentStudy } = study
  const userScope = getUserScopeByStudy(user, currentStudy)
  return {
    ...studyDashboard.participantEvents,
    allowCommunicationRedirect: userScope.includes('xp'),
    allowInstrumentRedirect: userScope.includes('wa'),
  }
}

export default connect(mapState, actions)(DataGapChart)
