import { combineReducers } from 'redux'
import moment from 'moment'
import request from 'utils/request'
import { GROUP_TYPE_MAP, GROUP_LABEL_MAP, DATE_FORMAT_MAP, INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { getScheduleText } from '../../../../Instruments/utils/PropertyFields'

const SET_ALERTS = 'SET_ALERTS'
const SET_SMS_MESSAGES = 'SET_SMS_MESSAGES'
const SET_COMMUNICATION_TYPE = 'SET_COMMUNICATION_TYPE'

// ACTION CREATORS
export const setAnnouncements = ({ announcementList, siteSelectionList, cohortList, isInstrument = false }) => {
  return {
    type: SET_ALERTS,
    payload: announcementList,
    siteSelectionList,
    cohortList,
    isInstrument,
  }
}
const setSMSMessages = (smsMessages, siteSelectionList, cohortList) => {
  return {
    type: SET_SMS_MESSAGES,
    payload: smsMessages,
    siteSelectionList,
    cohortList,
  }
}
export const setCommunicationType = communicationType => {
  return {
    type: SET_COMMUNICATION_TYPE,
    communicationType,
  }
}

// API FUNCTIONS

const _requestAnnouncements = (studyID, forAnnouncements = true) => {
  return dispatch => {
    const url = `/control/studies/${studyID}/${forAnnouncements ? 'announcements' : 'sms'}`
    return dispatch(request({ url, success: json => Promise.resolve(json.announcements), hasLoader: true }))
  }
}

const fetchAnnouncements = (studyID, forAnnouncements = true) => {
  return (dispatch, getState) => {
    return dispatch(_requestAnnouncements(studyID, forAnnouncements)).then(communicae => {
      const { sites: { siteSelectionList = [] } = {}, participants: { cohortList = [] } = {} } = getState()
      if (forAnnouncements) {
        dispatch(setAnnouncements({ announcementList: communicae, siteSelectionList, cohortList }))
      } else {
        dispatch(setSMSMessages(communicae, siteSelectionList, cohortList))
      }
    })
  }
}

const getRecipients = ({ type, filter }, siteSelectionList, cohortList) => {
  switch (type) {
    case GROUP_TYPE_MAP.sites:
      return [
        type,
        siteSelectionList
          .map(row => ({ key: row[0].value, name: row[1].value }))
          .filter(obj => filter.site_ids.includes(obj.key)),
      ]

    case GROUP_TYPE_MAP.cohorts:
      return [
        type,
        cohortList
          .map(row => ({ key: row[0].value, name: row[1].value }))
          .filter(obj => filter.include.includes(obj.key)),
      ]

    default:
      return []
  }
}

const recipientsText = sitesNames => {
  const data = []
  Object.keys(sitesNames).forEach(name => {
    const group = GROUP_LABEL_MAP[name] || null
    if (group) {
      const item = {
        label: group,
        list: [],
      }
      sitesNames[name].forEach(site => {
        item.list.push(site.name)
      })
      data.push(item)
    }
  })
  return data
}

const _parseRecipients = (schedule, siteSelectionList, cohortList) => {
  if (schedule.cohort && Array.isArray(schedule.cohort) && schedule.cohort.length) {
    const recipesNames = schedule.cohort.reduce((acc, item) => {
      const [key = '', listNames = []] = getRecipients(item, siteSelectionList, cohortList)
      if (key && listNames.length) {
        acc[key] = listNames
      }
      return acc
    }, {})
    return recipientsText(recipesNames)
  }

  if (schedule.cohort && Object.keys(schedule.cohort).length !== 0) {
    const [key = '', listNames = []] = getRecipients(schedule.cohort, siteSelectionList, cohortList)
    if (key && listNames.length) {
      const acc = {}
      acc[key] = listNames
      return recipientsText(acc)
    }
  }
  return ['All Participants']
}

const _formatCommunicationList = ({
  list,
  areAnnouncements = false,
  siteSelectionList = [],
  cohortList = [],
  isInstrument = false,
}) => {
  const _list = isInstrument ? list.filter(item => item.type === INSTRUMENT_TYPE_MAP.announcement) : list
  return Array.isArray(_list)
    ? _list.map(announcement => {
        const { metadata } = announcement
        const lastEdited = moment(announcement.updated)

        const schedule = isInstrument ? metadata.schedule : announcement.schedule
        const scheduleText = getScheduleText({ visibilityRanges: [{ start: null }], schedule })
        const recipients = _parseRecipients(schedule, siteSelectionList, cohortList)

        const readStatus = (announcement.num_read || announcement.num_received) / announcement.num_sent || 0
        const { translations } = announcement
        const instrumentTranslations = metadata?.announcement_content?.translations

        const row = [
          {
            key: 'title',
            value: announcement.title,
            sortValue: announcement.title,
            id: announcement.id,
            className: 'title-column',
            translations: translations || instrumentTranslations,
            isAnnouncement: areAnnouncements,
          },
          { key: 'recipients', value: recipients },
          { key: 'lastEdited', value: lastEdited.format(DATE_FORMAT_MAP.main), sortValue: lastEdited.valueOf() },
          {
            key: 'scheduled',
            value: scheduleText.text,
            sortValue: scheduleText.sortValue,
            deployed: announcement.deployed !== null,
          },
        ]
        if (areAnnouncements && announcement.deployed !== null)
          row.push({
            key: 'readStatus',
            value: readStatus === Infinity ? 0 : readStatus,
            sortValue: readStatus === Infinity ? 0 : readStatus,
          })
        return row
      })
    : []
}

// REDUCERS

const announcementsList = (state = [], action) => {
  switch (action.type) {
    case SET_ALERTS: {
      const { payload, siteSelectionList, cohortList, isInstrument } = action
      return _formatCommunicationList({
        list: payload,
        areAnnouncements: true,
        siteSelectionList,
        cohortList,
        isInstrument,
      })
    }
    default:
      return state
  }
}
const smsList = (state = [], action) => {
  switch (action.type) {
    case SET_SMS_MESSAGES: {
      const { payload, siteSelectionList, cohortList } = action
      return _formatCommunicationList({ list: payload, areAnnouncements: false, siteSelectionList, cohortList })
    }
    default:
      return state
  }
}

const currentCommunicationType = (state = 'announcements', action) => {
  switch (action.type) {
    case SET_COMMUNICATION_TYPE:
      return action.communicationType
    default:
      return state
  }
}

export const announcementsActions = {
  fetchAnnouncements,
  setCommunicationType,
}

export default combineReducers({ announcementsList, smsList, currentCommunicationType })
