import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Radio } from 'components/UIElements'
import STRINGS from 'utils/strings'
import useDidMountEffect from 'utils/hooks/didMountEffect'
import { MINUTES_IN_DAY } from 'utils/constants'
import { convertMinToDays } from 'utils/time'
import { REVIEW_TYPES, DEFAULT_SCREEN_REVIEW_VALUES } from '../constants/metaBuilderConstants'
import DurationInput from '../../Scheduler/components/DurationInput'
import {
  MINS_MULTIPLIER_MAP,
  DAY_MULTIPLIER_MAP,
  UNIFICATION_REPEAT_TIME_UNIT_OPTION,
  getInitialValues,
} from '../../Scheduler/constants/schedulerConstants'

function ReviewScreenProperties({ disabled, updateTimeoutWindow, instrument, updateTimeoutWindowField }) {
  const { timeout_window } = instrument.metadata.schedule
  const { review, edit } = timeout_window || {}
  const { value: reviewValue, type: reviewType } = review || {}
  const { value: editValue } = edit || {}

  const [days, setDays] = useState(convertMinToDays(reviewValue || MINUTES_IN_DAY, false))
  const [reviewTimeUnit, setReviewTimeUnit] = useState('days')

  const { key, val } = getInitialValues(editValue, 1)
  const [timeValue, setTimeValue] = useState(val)
  const [editTimeUnit, setEditTimeUnit] = useState(key)

  useDidMountEffect(() => {
    if (reviewValue >= 0) {
      onToggleReviewEntries()
    }
  }, [days, reviewTimeUnit])

  useDidMountEffect(() => {
    if (editValue >= 0) {
      const minsMultiplier = MINS_MULTIPLIER_MAP[editTimeUnit]
      const newTimeVal = minsMultiplier * timeValue
      onEditEntries(newTimeVal)
    }
  }, [timeValue, editTimeUnit])

  const onToggleReview = (allow = false) => {
    const newTimeoutWindow = timeout_window
    if (allow) {
      updateTimeoutWindow({ ...newTimeoutWindow, ...DEFAULT_SCREEN_REVIEW_VALUES })
    } else {
      delete newTimeoutWindow.review
      delete newTimeoutWindow.edit
      updateTimeoutWindow(newTimeoutWindow)
    }
  }

  const onToggleReviewEntries = isAll => {
    const dayMultiplier = DAY_MULTIPLIER_MAP[reviewTimeUnit]
    const newDayVal = isAll ? MINUTES_IN_DAY : days * dayMultiplier * MINUTES_IN_DAY
    updateTimeoutWindowField(
      'review',
      { type: isAll ? REVIEW_TYPES.all : REVIEW_TYPES.partial, value: newDayVal },
      'schedule.timeout_window.review',
    )
  }

  const onReviewTimeUnitDropdownSelect = item => {
    setReviewTimeUnit(item.key)
  }

  const onReviewEntryBlur = e => {
    if (e.target.value === '') setDays(1)
  }

  const onNoEditEntries = () => {
    setTimeValue(1)
    setEditTimeUnit('n-minutes')
    const prevTimeout = { ...timeout_window }
    delete prevTimeout.edit
    updateTimeoutWindow(prevTimeout)
  }

  const onEditEntries = value => {
    updateTimeoutWindowField('edit', { value }, 'schedule.timeout_window.edit')
  }

  const onEditTimeUnitDropdownSelect = item => {
    setEditTimeUnit(item.key)
  }

  const onEditBlur = e => {
    if (e.target.value === '') setTimeValue(1)
  }

  return (
    <div className='review-screen-properties'>
      <h4>Review Screen Settings</h4>
      <Container>
        <div>
          <p>
            <span>{STRINGS.allowReviewQuestion1}</span>
          </p>
          <Radio
            id='save-review'
            selected={!!review}
            onClick={() => onToggleReview(true)}
            disabled={disabled}
            content={STRINGS.allowReviewOption1}
          />
          <Radio
            id='no-save-review'
            selected={!review}
            onClick={() => onToggleReview(false)}
            disabled={disabled}
            content={STRINGS.allowReviewOption2}
          />
        </div>
        {!!review && (
          <div className='review-entries'>
            <p>
              <span>{STRINGS.allowReviewQuestion2}</span>
            </p>
            <Radio
              id='review-all'
              selected={reviewType === REVIEW_TYPES.all}
              onClick={() => {
                onToggleReviewEntries(true)
              }}
              disabled={disabled}
              content={STRINGS.allowReviewAll}
            />
            <Radio
              id='review-partial'
              selected={reviewType === REVIEW_TYPES.partial}
              onClick={() => {
                onToggleReviewEntries()
              }}
              disabled={disabled}
              content={
                <DurationInput
                  disabled={disabled}
                  hasMonthsOption
                  isSelected={reviewType === REVIEW_TYPES.partial}
                  onBlur={onReviewEntryBlur}
                  onChange={val => {
                    setDays(val)
                  }}
                  inputValue={String(days)}
                  unitValue={reviewTimeUnit}
                  onDropdownSelect={onReviewTimeUnitDropdownSelect}
                  preText={STRINGS.allowReviewPartial}
                  min={1}
                />
              }
            />
            <p>
              <span>{STRINGS.allowEditQuestion}</span>
            </p>
            <Radio
              id='edit-entries'
              selected={editValue >= 0}
              onClick={() => {
                onEditEntries(1)
              }}
              disabled={disabled}
              content={
                <DurationInput
                  disabled={disabled}
                  isSelected={!!editValue}
                  onBlur={onEditBlur}
                  onChange={val => {
                    setTimeValue(val)
                  }}
                  inputValue={String(timeValue)}
                  unitValue={editTimeUnit}
                  onDropdownSelect={onEditTimeUnitDropdownSelect}
                  preText={STRINGS.allowEditOption1}
                  min={1}
                  newTimeOpts={UNIFICATION_REPEAT_TIME_UNIT_OPTION}
                  useSimpleInput={true}
                />
              }
            />
            <Radio
              id='edit-entries-disabled'
              selected={isNaN(editValue)}
              onClick={() => {
                onNoEditEntries()
              }}
              disabled={disabled}
              content={STRINGS.allowEditOption2}
            />
          </div>
        )}
      </Container>
    </div>
  )
}

ReviewScreenProperties.propTypes = {
  disabled: PropTypes.bool,
  updateTimeoutWindow: PropTypes.func,
  instrument: PropTypes.shape({
    metadata: PropTypes.shape({ schedule: PropTypes.shape({ timeout_window: PropTypes.object }) }),
  }),
  updateTimeoutWindowField: PropTypes.func,
}

export default ReviewScreenProperties
