import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import SingleScoreContainer from '../containers/SingleScoreContainer'

import '../stylesheets/scoring.scss'

const OverallScores = ({ addScore, instrument, isClinro, isDiary, instrumentErrors, deleteScore, updateScore }) => {
  const { domains, scores = [] } = instrument
  const { scores: scoresErrors } = instrumentErrors

  return (
    <div className='overall-scores flexed half-page'>
      <h3>2. Overall score(s)</h3>
      <h4>Label, describe, and calculate the final scores</h4>
      {isClinro && (
        <p className='green-text'>
          <i className='fas fa-arrow-right' />
          This is what will be displayed on ClinRO
        </p>
      )}
      {isDiary && (
        <p className='green-text'>
          <i className='fas fa-arrow-right' />
          This is what will be displayed on Diary
        </p>
      )}
      {scores.map((score, idx) => {
        const scoreErrors = scoresErrors ? scoresErrors[score.id] : {}
        return (
          <SingleScoreContainer
            isClinro={isClinro}
            isDiary={isDiary}
            domains={domains}
            deleteScore={deleteScore}
            idx={idx}
            key={score.id}
            score={score}
            showDelete={scores.length > 1}
            updateScore={updateScore}
            errors={scoreErrors}
          />
        )
      })}
      <Button content='Add new score' icon='fas fa-plus' onClick={() => addScore()} />
    </div>
  )
}

export default OverallScores

OverallScores.propTypes = {
  addScore: PropTypes.func,
  clinro: PropTypes.shape({
    scores: PropTypes.arrayOf(PropTypes.object),
  }),
  deleteScore: PropTypes.func,
  instrument: PropTypes.shape({
    domains: PropTypes.objectOf(PropTypes.object),
    scores: PropTypes.arrayOf(PropTypes.object),
  }),
  instrumentErrors: PropTypes.shape({
    scores: PropTypes.object,
  }),
  isClinro: PropTypes.bool,
  updateScore: PropTypes.func,
}
