import React from 'react'
import { connect } from 'react-redux'
import AuditFilter from '../components/AuditFilter'
import { fetchAuditReport, resetAuditReport } from '../../modules/Audit'

const mapState = ({ loading, auditReport, study }) => {
  const { currentStudy } = study
  return {
    loading: loading.general,
    auditReport,
    noTracksFeature: currentStudy.config?.tracks?.disabled === true,
  }
}

const mapDispatch = dispatch => {
  return {
    fetchAuditReport: url => dispatch(fetchAuditReport(url)),
    resetAuditReport: () => dispatch(resetAuditReport()),
  }
}

export default connect(
  mapState,
  mapDispatch,
)(props => {
  return <AuditFilter {...props} />
})
