import React from 'react'
import PropTypes from 'prop-types'
import { PageSelector } from 'components/UIElements'

const StudiesPaginationPanel = ({ currentPage, setCurrentPage, totalPages }) => {
  return (
    <div className='studies-pagination-panel'>
      <PageSelector currentPage={currentPage} onChange={current => setCurrentPage(current)} total={totalPages} />
    </div>
  )
}

StudiesPaginationPanel.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default StudiesPaginationPanel
