import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Documents, Loader, MediaUpload, SearchInput, SortableTable } from 'components/UIElements'
import { MEDIA_TYPE_MAP, MODAL_CLASSES_MAP, PARTICIPANT_CONSENT_STATUS_MAP } from 'utils/constants'

const KEY_NAME_MAP = {
  checkbox: null,
  participant: 'Subject ID',
  trackId: 'Track Name',
  siteId: 'Site ID',
  completionStatus: 'Completion',
}

const StatusContainer = props => {
  const {
    addDocument,
    canEdit,
    closeModal,
    deleteDocument,
    documents,
    isDocumentOptional,
    loadingConsentAttachment,
    loadingPtps,
    loadingMarkConsentAsComplete,
    noTracksFeature,
    openModal,
    participantsList = [],
    studyLock,
    studyId,
    searchTerm,
    uploadConsentAttachments,
  } = props

  const disabled = studyLock || !canEdit

  const [participantChecked, toggleParticipant] = useState({})
  const ptpCheckedKeys = Object.keys(participantChecked)
  const [isSameStatus, setSameStatus] = useState(false)
  const ptpMap = participantsList.map(ptpArr => ptpArr.reduce((obj, item) => ((obj[item.key] = item.value), obj), {}))

  useEffect(() => {
    if (loadingMarkConsentAsComplete) {
      toggleParticipant({})
    }
  }, [loadingMarkConsentAsComplete])

  useEffect(() => {
    getCheckedPtpsStatus()
  }, [participantChecked, documents])

  const headerOptionsSeed = Object.keys(KEY_NAME_MAP).map(key => {
    return { key, sortable: key !== 'checkbox', component: KEY_NAME_MAP[key] }
  })

  const renderCheckboxCell = ({ value, idx, participantChecked }) => {
    return (
      !disabled && (
        <td
          className='checkbox-cell'
          onClick={e => {
            e.stopPropagation()
            if (!disabled) {
              const newParticipant = { ...participantChecked }
              if (value in newParticipant) delete newParticipant[value]
              else newParticipant[value] = true
              toggleParticipant(newParticipant)
            }
          }}
          key={`col_${idx}`}>
          <Checkbox disabled={disabled} checked={value in participantChecked} />
        </td>
      )
    )
  }

  const renderCompletionCell = (row, idx) => {
    const { value, received } = row
    const { longText, text } = PARTICIPANT_CONSENT_STATUS_MAP[value] || {}
    const isDeclined = value.toLowerCase() === 'declined' ? ' orange' : ''
    return (
      <td className={`completion-cell${isDeclined}`} key={`completion-date-${row}-${idx}`}>
        <>
          <i className={`fas fa-${received ? 'check' : `times${isDeclined}`}`} />{' '}
          <span>
            {longText || text} {received && received}
          </span>
        </>
      </td>
    )
  }

  const renderFunctions = {
    checkbox: renderCheckboxCell,
    completionStatus: renderCompletionCell,
  }

  const getHeaderOptions = () => {
    const ptpCheckedSize = ptpCheckedKeys.length
    let newHeaderOptions = [...headerOptionsSeed]

    /**
     * If a study does not have tracks, hide the tracks column of the table
     */
    if (noTracksFeature) {
      newHeaderOptions = newHeaderOptions.filter(headerOption => {
        return headerOption.key !== 'trackId'
      })
    }
    if (!disabled) {
      newHeaderOptions[0].component = (
        <Checkbox
          checked={ptpCheckedSize === ptpMap.length}
          disabled={disabled}
          onClick={() => {
            const newParticipant = {}
            if (ptpCheckedSize === 0) {
              ptpMap
                .map(ptp => ptp.checkbox)
                .forEach(id => {
                  newParticipant[id] = true
                })
            }
            toggleParticipant(newParticipant)
          }}
        />
      )
    } else if (newHeaderOptions[0].key === 'checkbox') {
      newHeaderOptions.splice(0, 1)
    }
    return newHeaderOptions
  }

  const getPtpList = () => {
    if (noTracksFeature) {
      const filteredList = Array.from(participantsList)
      if (noTracksFeature) {
        for (let i = 0; i < filteredList.length; i += 1) {
          filteredList[i] = filteredList[i]?.filter(col => {
            return col.key !== 'trackId'
          })
        }
        return filteredList
      }
    }
    return participantsList
  }

  const openFileModal = (e, rowIdx) => {
    e.stopPropagation()
    openModal({
      content: (
        <MediaUpload
          {...props}
          onClose={closeModal}
          initTab='document'
          callback={fileObj => {
            const doc = Object.fromEntries(fileObj)
            addDocument(rowIdx, doc)
          }}
          uploadDocument={() => {}}
          studyID={studyId}
          mediaTypes={[MEDIA_TYPE_MAP.document]}
          heading='Insert a document'
        />
      ),
      noButtons: true,
      className: MODAL_CLASSES_MAP.uploadMedia,
      closeOnBackgroundClick: false,
    })
  }

  const expandedRow = _ => {
    const ptpIdIndex = _.findIndex(cell => cell.key === 'checkbox')
    const { value: ptpId } = _[ptpIdIndex]
    return (
      <Documents
        documents={documents}
        rowIdx={ptpId}
        deleteDocument={deleteDocument}
        openFileModal={openFileModal}
        placeholder='Upload supportive documents here. (Optional)'
        maxNumFiles={1}
      />
    )
  }

  const getCheckedPtpsStatus = () => {
    const checkedKeys = ptpCheckedKeys.map(key => Number(key))
    const statusArr = ptpMap
      .filter(ptp => checkedKeys.includes(ptp.checkbox))
      .map(ptp => ptp.completionStatus)
      .filter((ptpStatus, idx, arr) => arr.indexOf(ptpStatus) === idx)
    setSameStatus(statusArr.length === 1 && !['declined', 'received', 'complete'].some(val => statusArr.includes(val)))
  }

  const markAsSignatureReceived = e => {
    e.stopPropagation()
    const { consent, consentId } = props
    const { consent_version } = consent
    uploadConsentAttachments({ studyId, checkedPtps: ptpCheckedKeys, consentId, version: consent_version })
  }

  const validateDocuments = () => isDocumentOptional || ptpCheckedKeys.every(ptpID => !!documents[ptpID])

  const validateMarkSignatureBtn = () =>
    participantsList.length > 0 && ptpCheckedKeys.length > 0 && isSameStatus && validateDocuments()

  return (
    <div className='page-break status-container'>
      <div className='status-container-header flexed'>
        <h4>Status</h4>
        {validateMarkSignatureBtn() && (
          <Button
            loading={loadingConsentAttachment}
            content='Mark as Signature Received'
            onClick={markAsSignatureReceived}
          />
        )}
        {participantsList.length > 0 && <SearchInput placeholder='Search name or site ID...' />}
      </div>
      <div className={`status-table${loadingPtps ? ' loading' : ''}`}>
        {loadingPtps ? (
          <Loader inContainer />
        ) : participantsList.length > 0 ? (
          <SortableTable
            disabled={disabled}
            sortingBy='completionStatus'
            initialOrder='descending'
            headerOptions={getHeaderOptions()}
            rowList={getPtpList()}
            renderFunctions={renderFunctions}
            optionalCellProps={{ participantChecked }}
            rowExpandOptions={{
              isRowExpanded: row => {
                const idData = row.find(el => el.key === 'checkbox')
                const consentId = idData.value
                return participantChecked[consentId]
              },
              expandedRowClassName: 'expanded',
            }}
            expandedRow={expandedRow}
            viewMoreWithTablePages={{ minRows: 7, maxRows: 20 }}
            searchTerm={searchTerm}
            searchKeys={['participant', 'siteId']}
            emptyText='No Matching Participants'
          />
        ) : (
          <span>This consent has not been deployed yet.</span>
        )}
      </div>
    </div>
  )
}

StatusContainer.propTypes = {
  addDocument: PropTypes.func,
  canEdit: PropTypes.bool,
  closeModal: PropTypes.func,
  consent: PropTypes.object,
  consentId: PropTypes.string,
  deleteDocument: PropTypes.func,
  documents: PropTypes.object,
  draft: PropTypes.object,
  loadingConsentAttachment: PropTypes.bool,
  loadingPtps: PropTypes.bool,
  loadingMarkConsentAsComplete: PropTypes.bool,
  noTracksFeature: PropTypes.bool,
  openModal: PropTypes.func,
  participantsList: PropTypes.array,
  studyLock: PropTypes.bool,
  studyId: PropTypes.number,
  searchTerm: PropTypes.string,
  uploadConsentAttachments: PropTypes.func,
}

export default StatusContainer
