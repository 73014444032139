import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  AVAILABLE_USER_OPTIONS,
  CLINICIAN_ROLES_ID_LIST,
  IMPARTIAL_WITNESS_ALLOWED_CREATORS_LIST,
  ROOT_DCH_ADMIN_ROLE_ID_LIST,
  USER_ROLE_ID_MAP,
  USER_ROLE_IDS_THAT_CAN_BE_CREATED_BY_SITE_ADMIN,
  USER_ROLE_IDS_WITH_LIMITED_WRITE_USER_PERMISSIONS,
} from 'utils/constants'
import { getUserRoleIdByStudy } from 'utils/misc'

const useRolesList = () => {
  const {
    user,
    study: { currentStudy },
  } = useSelector(state => state)
  const studyID = currentStudy.id

  const currentRoleId = studyID ? getUserRoleIdByStudy(user, studyID) : 7
  const isCurrentUserWithLimitedWriteAccess = USER_ROLE_IDS_WITH_LIMITED_WRITE_USER_PERMISSIONS.includes(currentRoleId)

  const config = currentStudy.config || {}
  const hasCaseManagement = !!config.case_management
  const hasClinro = !!config.clinro
  const hasConsent = !!config.consent_required
  const hasInsights = !!config.insights

  const currentUserIsRootOrDchAdmin = ROOT_DCH_ADMIN_ROLE_ID_LIST.includes(currentRoleId)
  const isAllowedToCreateImpartialWitness = IMPARTIAL_WITNESS_ALLOWED_CREATORS_LIST.includes(currentRoleId)

  const getRolesList = () => {
    let roleArr = [...AVAILABLE_USER_OPTIONS]

    /**
     * Filtering user role options based on study features and user type of current user
     */

    // If a study does not have case management, we need to remove the case manager option
    if (!hasCaseManagement) roleArr = roleArr.filter(role => role.role_id !== USER_ROLE_ID_MAP.caseManager)

    // If a study does not have Clinro, we need to filter out the clinician type roles
    if (!hasClinro) roleArr = roleArr.filter(role => !CLINICIAN_ROLES_ID_LIST.includes(role.role_id))

    // If a study does not have Insights, hide the reporter user option
    if (!hasInsights) {
      roleArr = roleArr.filter(role => role.role_id !== USER_ROLE_ID_MAP.reporter)
    }

    if (
      !(hasClinro && hasConsent) || // Impartial Witness role should be only available for studies with Clinro and eConsent
      !isAllowedToCreateImpartialWitness // Only DCH Admins and Study Coordinators can create Impartial witness
    ) {
      roleArr = roleArr.filter(role => {
        return USER_ROLE_ID_MAP.impartialWitness !== role.role_id
      })
    }

    if (isCurrentUserWithLimitedWriteAccess) {
      roleArr = roleArr.filter(role => USER_ROLE_IDS_THAT_CAN_BE_CREATED_BY_SITE_ADMIN.includes(role.role_id))
    } else if (!currentUserIsRootOrDchAdmin) {
      roleArr = roleArr.filter(role => USER_ROLE_ID_MAP.siteUserManagementAdmin !== role.role_id)
    }
    roleArr = roleArr.filter(role => role.key)

    return roleArr
  }

  const roles = useMemo(() => {
    const rolesList = getRolesList()
    return rolesList
  }, [currentStudy, currentRoleId])

  return roles
}

export default useRolesList
