import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button } from 'components/UIElements'
import D3Logo from 'assets/DCH-Logo.svg'
import { MODAL_CLASSES_MAP, MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import { NAV_HEADER_NAMES } from '../../../utils/visitNameUtils'

const ConfirmModalContent = () => (
  <div>
    <div className='confirmation-statement'>{MODAL_CONTENT_MAP.closeOut}</div>
    <p>{STRINGS.loseUnsavedContent}</p>
  </div>
)

const VisitsTemplateNav = props => {
  const { loading, openModal, preview, selectedTab, studyID, path, subroute } = props
  const header = path === 'edit' ? 'Edit' : 'Create new'
  const mainTabSelected = selectedTab === 'main'
  const navText = `${header} ${NAV_HEADER_NAMES[subroute]}`

  const onOpenConfirmModal = () => {
    openModal({
      onConfirm: () => {
        browserHistory.push(`/studies/${studyID}/visit-schedules`)
      },
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.spacious,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      content: <ConfirmModalContent />,
      heading: MODAL_CONTENT_MAP.pleaseConfirm,
    })
  }

  return (
    !preview && (
      <div className='visits-nav flexed-header'>
        <div className='flexed-header'>
          <div className='d3-logo-container'>
            <img className='d3-logo' src={D3Logo} alt='d3-logo' />
          </div>
          <Button
            selected={mainTabSelected}
            disabled={mainTabSelected || loading}
            link
            onClick={() => {}}
            content={
              <span>
                <i className='fas fa-arrow-right' />
                {navText}
              </span>
            }
          />
        </div>
        <Button grey id='close-button' content={STRINGS.close} onClick={onOpenConfirmModal} />
      </div>
    )
  )
}

VisitsTemplateNav.propTypes = {
  loading: PropTypes.bool,
  openModal: PropTypes.func,
  preview: PropTypes.bool,
  selectedTab: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default VisitsTemplateNav
