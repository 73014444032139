import { setSubroute } from 'store/navbar'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import DetailsContainer from './container/DetailsContainer'

export const injectVisitReducers = store => {
  injectReducers(store, {})
}

// Sync route definition
export default store => ({
  path: 'visit-templates/:visitId/details',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        injectVisitReducers(store)
        store.dispatch(setSubroute('visit-templates'))
        /*  Webpack - use require callback to define
         dependencies for bundling   */

        const { studyID } = nextState.params
        const { study } = store.getState()
        const { currentStudy } = study
        setPageTitle(`Visits Template Details | Study ${studyID} | ${currentStudy.platform_name}`)

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'visit-schedules')(UserHasScope(['rpv'], DetailsContainer, true, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'visit-templates/details',
    )
  },
})
