import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, SortableTable, MediaUpload, Documents, Popup } from 'components/UIElements'
import { MEDIA_TYPE_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'

const KEY_NAME_MAP = {
  status: 'Status',
  name: 'Consent Name',
  scheduled: 'Scheduled Date',
  completion: 'Completition Date',
}

const CONSENT_STATUS_TEXT_MAP = {
  'awaiting pi': STRINGS.awaitingSignatureFromPI,
  'awaiting iw': STRINGS.awaitingSignatureFromIw,
}

const NewConsentManagement = props => {
  const {
    addDocument,
    canUpdateConsent,
    closeModal,
    deleteDocument,
    disabled,
    documents,
    openModal,
    participantConsents,
    ptpConsentAttachmentLoading,
    ptpId,
    ptpName,
    ptpSiteID,
    resetDocs,
    studyID,
    uploadPtpConsentAttachments,
    canUploadConsentAttachment,
  } = props
  const [activeConsent, setActiveConsent] = useState()

  useEffect(() => {
    setActiveConsent()
    resetDocs()
  }, [participantConsents])

  const renderCompletionCell = (row, idx) => {
    const { value } = row
    const lowerCaseVal = value.toLowerCase()
    const isComplete = lowerCaseVal === 'complete'
    const isAwaitingClinro = lowerCaseVal === 'awaiting pi'
    const isAwaitingIW = lowerCaseVal === 'awaiting iw'
    const isHighlighted = isAwaitingClinro || isAwaitingIW
    return (
      <td className='consent-status-cell' key={`ptp-consent-status-${row}-${idx}`}>
        {isHighlighted ? (
          <Popup
            className='box-popup'
            align='left'
            position='bottom'
            hover
            noPointer
            dark
            trigger={
              <>
                <i className='fas fa-times' />
                <span className='orange'>{value}</span>
              </>
            }>
            <p className='consent-status-cell-tooltip'>{CONSENT_STATUS_TEXT_MAP[lowerCaseVal]}</p>
          </Popup>
        ) : (
          <>
            <i className={`fas fa-${isComplete ? 'check' : 'times'}`} /> <span>{isComplete ? '' : value}</span>
          </>
        )}
      </td>
    )
  }

  const renderNameCell = ({ consentId, value, completed }, idx) => {
    const { canDownloadConsent, downloadCompletedParticipantConsent, ptpId, studyID } = props
    return (
      <td key={`consent-status-${value}-${idx}`} className='consent-name-cell'>
        {completed ? (
          <>
            {canDownloadConsent ? (
              <Button
                className='flexed center-aligned'
                content={<div>{value}</div>}
                iconAfter='fas fa-download'
                link
                onClick={() => {
                  downloadCompletedParticipantConsent({ studyId: studyID, ptpId, consentId })
                }}
              />
            ) : (
              value
            )}
          </>
        ) : (
          value
        )}
      </td>
    )
  }

  const renderFunctions = {
    name: renderNameCell,
    status: renderCompletionCell,
  }

  const headerOptionsSeed = Object.keys(KEY_NAME_MAP).map(key => {
    return { key, sortable: false, component: KEY_NAME_MAP[key] }
  })

  const getHeaderOptions = () => {
    const newHeaderOptions = [...headerOptionsSeed]
    return newHeaderOptions
  }

  const openFileModal = (e, rowIdx) => {
    e.stopPropagation()
    openModal({
      content: (
        <MediaUpload
          {...props}
          onClose={closeModal}
          initTab={MEDIA_TYPE_MAP.document}
          callback={fileObj => {
            const doc = Object.fromEntries(fileObj)
            addDocument(rowIdx, doc)
            //setDocuments(doc)
          }}
          uploadDocument={() => {}}
          studyID={studyID}
          mediaTypes={[MEDIA_TYPE_MAP.document]}
          heading='Insert a document'
        />
      ),
      noButtons: true,
      className: MODAL_CLASSES_MAP.uploadMedia,
      closeOnBackgroundClick: false,
    })
  }

  const expandedRow = (_, rowIdx) => {
    const consentIdIndex = _.findIndex(cell => cell.key === 'name')
    const { consentId } = _[consentIdIndex]

    return canUploadConsentAttachment ? (
      <Documents
        callbackProps={{
          callback: () =>
            uploadPtpConsentAttachments({
              studyId: studyID,
              ptpId,
              consentId,
              isParticipant: true,
              rowIdx,
              ptpSiteId: ptpSiteID,
            }),
          text: 'Confirm and Upload',
          loading: ptpConsentAttachmentLoading,
        }}
        disabled={disabled}
        documents={documents}
        footnote='*Uploading this document will update the status as signature received.'
        maxNumFiles={1}
        rowIdx={rowIdx}
        deleteDocument={deleteDocument}
        openFileModal={openFileModal}
        placeholder='Upload supportive documents here. (Optional)'
      />
    ) : null
  }

  const onRowClick = rowData => {
    const idData = rowData.find(el => el.key === 'name')
    const { canUpload, consentId } = idData
    if (activeConsent === consentId) setActiveConsent()
    else if (canUpload && canUpdateConsent) setActiveConsent(consentId)
  }

  const rowCanClick = rowData => {
    if (disabled) return !disabled
    const idData = rowData.find(el => el.key === 'name')
    const { canUpload } = idData
    return canUpload && canUpdateConsent
  }

  return (
    <div className='new-consent-management d3-container'>
      <div className='title'>eConsent</div>
      {participantConsents.length > 0 ? (
        <>
          <SortableTable
            sortingBy='status'
            headerOptions={getHeaderOptions()}
            initialOrder='descending'
            rowList={participantConsents}
            renderFunctions={renderFunctions}
            rowCanClick={rowCanClick}
            onRowClick={disabled ? null : onRowClick}
            rowExpandOptions={{
              isRowExpanded: row => {
                const idData = row.find(el => el.key === 'name')
                const { consentId } = idData
                return activeConsent === consentId
              },
              expandedRowClassName: 'expanded',
            }}
            expandedRow={expandedRow}
          />
        </>
      ) : (
        `${ptpName} does not have any consents.`
      )}
    </div>
  )
}

NewConsentManagement.propTypes = {
  addDocument: PropTypes.func,
  canDownloadConsent: PropTypes.bool,
  canUpdateConsent: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteDocument: PropTypes.func,
  downloadCompletedParticipantConsent: PropTypes.func,
  documents: PropTypes.objectOf(PropTypes.array),
  disabled: PropTypes.bool,
  openModal: PropTypes.func,
  participantConsents: PropTypes.arrayOf(PropTypes.array),
  ptpId: PropTypes.number,
  ptpName: PropTypes.string,
  studyID: PropTypes.number,
  studyLock: PropTypes.bool,
  uploadPtpConsentAttachments: PropTypes.func,
}

export default NewConsentManagement
