import {
  UserDoesNotHaveRole,
  UserHasFeatures,
  UserHasScope,
  UserHasValidPassword,
  UserIsAuthenticated,
} from 'containers/authContainers'
import { injectReducer } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { STUDY_CONFIG_MAP, USER_ROLES_WITH_RESTRICTED_ACCESS } from 'utils/constants'
import { setPageTitle } from 'utils/misc'
import Payments from './containers/PaymentsContainer'
import paymentsReducer, { actions as paymentActions } from './modules/Payments'
// Sync route definition
export default store => ({
  path: 'payments',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params

        const { study } = store.getState()

        const hasTreasureChest = !!study.currentStudy.config?.super_gems
        const hasDonations = hasTreasureChest && !!study.currentStudy.config.super_gems.donations?.length > 0
        const hasTreasureChestGiftcards =
          hasTreasureChest && !!study.currentStudy.config[STUDY_CONFIG_MAP.treasureChestStoreGiftCard]

        setPageTitle(`Incentives | Study ${studyID} | ${study.currentStudy.platform_name}`)
        store.dispatch(setSubroute('payments'))
        injectReducer(store, {
          key: 'paymentsReducer',
          reducer: paymentsReducer,
        })

        if (hasTreasureChest) {
          if (hasTreasureChestGiftcards) {
            store.dispatch(paymentActions.fetchPendingGiftcards(nextState.params.studyID))
          } else if (hasDonations) {
            store.dispatch(paymentActions.fetchPendingDonations(nextState.params.studyID))
          }
        } else {
          store.dispatch(paymentActions.fetchPayments(nextState.params.studyID))
        }
        store.dispatch(paymentActions.unsetPendingPaymentsNotification())

        // Return Component
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserHasScope(
                ['rp', 'rp-no-pii'],
                UserHasFeatures(
                  UserDoesNotHaveRole(USER_ROLES_WITH_RESTRICTED_ACCESS, Payments),
                  [
                    {
                      name: 'incentives',
                      props: ['enabled'],
                    },
                  ],
                  'compliance',
                ),
                false,
                studyID,
              ),
            ),
          ),
        )
      },
      'payments',
    )
  },
})
