import React from 'react'
import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { RichTextEditor } from 'components/UIElements'
import { DEFAULT_LANG, QUESTION_TYPE_MAP, QUESTION_TYPES_WITH_AUTO_EXPANDING_ITEM_EDITOR } from 'utils/constants'
import STRINGS from 'utils/strings'
import { formatLinkEntities } from 'utils/draft'
import UploadPdfSection from './UploadPdfSection'
import NewConsentQuestionView from './NewConsentQuestionView'
import ConsentSignatureView from './ConsentSignature/ConsentSignatureView'
import HoverBar from '../../../../../../Instruments/routes/Instrument/routes/EditSurvey/components/HoverBar'
import 'draft-js/dist/Draft.css'

class NewConsentItem extends React.Component {
  constructor(props) {
    super(props)
    const { currentLang = DEFAULT_LANG } = this.props
    this.state = { isTranslation: currentLang !== DEFAULT_LANG }
  }

  componentDidMount() {
    const { isActive, consent, checkRolesForSites, resetSiteRolesStatus, studyId } = this.props
    if (isActive && this.draftEditor) {
      this.draftEditorRef.draftEditor.focus()
    }

    const { metadata = {} } = consent
    const { schedule = {} } = metadata
    const { cohort = {} } = schedule
    const siteIds = cohort?.filter?.site_ids
    resetSiteRolesStatus()
    if (Array.isArray(cohort)) {
      const sitesCohortIndex = cohort.findIndex(_cohort => _cohort.type === 'sites')
      if (sitesCohortIndex !== -1) {
        const sitesCohort = cohort[sitesCohortIndex]
        const { filter } = sitesCohort
        checkRolesForSites(studyId, filter.site_ids)
      }
    } else if (siteIds) checkRolesForSites(studyId, siteIds)
  }

  componentDidUpdate(prevProps) {
    const { currentLang } = this.props
    if (currentLang !== prevProps.currentLang) this.setState({ isTranslation: currentLang !== DEFAULT_LANG })
  }

  onLabelChange = newEditorState => {
    const { currentLang, itemId, editorState, updateItemLabel, updateItemLabelTranslation } = this.props
    const { isTranslation } = this.state
    if (isTranslation) {
      updateItemLabelTranslation({ itemId, oldEditorState: editorState, newEditorState, language: currentLang })
    } else {
      updateItemLabel(itemId, editorState, newEditorState)
    }
  }

  onClick = e => {
    const { isActive, item, itemId, onToggleEdit, onToggleItemEditor } = this.props
    e.stopPropagation()
    if (!isActive) {
      onToggleEdit(itemId, this.item)
      if (QUESTION_TYPES_WITH_AUTO_EXPANDING_ITEM_EDITOR.includes(item.type)) {
        onToggleItemEditor(true)
      }
    }
  }

  onPaste = e => {
    const { activeItemId, addChoiceOnPaste, isActive, item } = this.props
    if (
      isActive &&
      document.activeElement.tagName === 'BODY' &&
      ['select_one', 'select_multiple', 'likert'].includes(item.type)
    ) {
      addChoiceOnPaste(activeItemId, e.clipboardData.getData('text'))
    }
  }

  getClassName = () => {
    const { isActive, hasItemEditor } = this.props
    if (isActive) {
      if (hasItemEditor) {
        return 'survey-item-container active hasEditor'
      }
      return 'survey-item-container active'
    }
    return 'survey-item-container'
  }

  getErrorContent = () => {
    const { errors } = this.props
    return Object.values(errors).map((error, idx) => {
      return <p key={`error_${idx}`}>{`${idx + 1}. ${error}`}</p>
    })
  }

  getEditorState = () => {
    const { isTranslation } = this.state
    const { currentLang, editorState, editorStates, itemId } = this.props
    if (!isTranslation) {
      if (editorState) return formatLinkEntities(editorState)
      else return EditorState.createEmpty()
    }
    const editorStateId = `${itemId}_${currentLang}`
    const langEditorState = editorStates[editorStateId]
    if (langEditorState) return langEditorState
    return EditorState.createEmpty() // fallback for if there isn't an editor state for the translation
  }

  updateConsent = filename => {
    const { item, updateItem, currentLang, itemId } = this.props
    if (item.type === QUESTION_TYPE_MAP.pdf) {
      if (currentLang === DEFAULT_LANG) {
        updateItem(itemId, {
          ...item,
          url: filename,
        })
      } else {
        updateItem(itemId, {
          ...item,
          url_translations: {
            ...item.url_translations,
            [currentLang]: filename,
          },
        })
      }
    }
  }

  getPdfUrl = () => {
    const { item, currentLang } = this.props
    return currentLang === DEFAULT_LANG ? item.url : item.url_translations[currentLang]
  }

  render() {
    const {
      consent,
      itemId,
      item,
      itemIndex,
      isActive,
      onAddItem,
      questionNumber,
      errors,
      isConsent,
      uploadConsentPdf,
      currentLang,
      studyId,
    } = this.props
    const { isTranslation } = this.state
    const { order, consent_id: consentId, pdf_upload: pdfUpload } = consent
    const { type } = item
    const hoverBarEnabled = order[0][0] !== itemId

    const pdfUrl = currentLang === DEFAULT_LANG ? item.url : item.url_translations?.[currentLang]

    const questionTypeText = questionNumber
      ? `Question ${questionNumber}`
      : type === QUESTION_TYPE_MAP.prompt
      ? 'Text'
      : type

    return (
      <div onPaste={this.onPaste} id={`question-${itemId}`}>
        {hoverBarEnabled && <HoverBar onAddItem={onAddItem} itemIndex={itemIndex - 1} isConsent={isConsent} />}
        <div
          ref={el => {
            this.item = el
          }}
          onClick={this.onClick}
          onFocus={this.onClick}
          className={this.getClassName()}>
          <>
            <div className='label-header flexed start-justified start-aligned'>
              {itemIndex !== 0 ? (
                <>
                  <div className='grab-icon' />
                  <label className='label-small'>{questionTypeText}</label>
                </>
              ) : (
                <label className='label-small'>{STRINGS.consentContent}</label>
              )}
            </div>
          </>
          {type === QUESTION_TYPE_MAP.signature ? (
            <ConsentSignatureView
              {...this.props}
              signeeReqErr={errors.atLeastOneSigneeRequiredErr}
              invalidSigneesError={errors.invalidSigneesError}
            />
          ) : null}
          {type === QUESTION_TYPE_MAP.pdf && pdfUpload ? (
            <div className='label-header flexed start-justified start-aligned full-width'>
              <UploadPdfSection
                uploadConsentPdf={uploadConsentPdf}
                studyId={studyId}
                consentId={consentId}
                currentLang={currentLang}
                updateConsent={this.updateConsent}
                pdfUrl={pdfUrl}
                errorMsg={errors.noPdfUpload}
              />
            </div>
          ) : null}
          {type !== QUESTION_TYPE_MAP.signature && type !== QUESTION_TYPE_MAP.pdf ? (
            <div className='label-header flexed start-justified start-aligned'>
              <span className={questionNumber && 'has-question'}>{questionNumber}</span>
              <RichTextEditor
                ref={el => {
                  this.draftEditorRef = el
                }}
                autofocus
                className={item.type === 'prompt' ? 'prompt ' : ''}
                editorState={this.getEditorState()}
                hasError={!isTranslation && errors.blankLabelErr && item.label === '<p><br></p>'}
                hasLinks
                onChange={this.onLabelChange}
                placeholder={item.type === 'introduction' ? 'Type note' : 'Type text'}
                showToolbar={isActive}
                spellCheck
              />
            </div>
          ) : null}
          <NewConsentQuestionView {...this.props} />
        </div>
      </div>
    )
  }
}

export default NewConsentItem

NewConsentItem.propTypes = {
  activeItemId: PropTypes.string,
  addChoiceOnPaste: PropTypes.func,
  consent: PropTypes.shape({
    order: PropTypes.array,
  }),
  currentLang: PropTypes.string,
  editorState: PropTypes.object,
  editorStates: PropTypes.objectOf(PropTypes.object),
  errors: PropTypes.object,
  hasItemEditor: PropTypes.bool,
  isActive: PropTypes.bool,
  isConsent: PropTypes.bool,
  item: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
  }),
  itemId: PropTypes.string,
  itemIndex: PropTypes.number,
  onAddItem: PropTypes.func,
  onDeleteChoice: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onToggleEdit: PropTypes.func,
  questionNumber: PropTypes.number,
  onToggleItemEditor: PropTypes.func,
  updateItemLabel: PropTypes.func,
  updateItemLabelTranslation: PropTypes.func,
}
