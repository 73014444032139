import React from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/DeleteParticipant.scss'
import { Button } from 'components/UIElements'

const DeleteParticipant = props => {
  const { closeModal, checkedPtps, deleteParticipants, siteID, studyID, plural } = props

  const checkedPtpsArr = Object.keys(checkedPtps)
  const checkedPtpsNumber = checkedPtpsArr.length

  const deleteMsg = () => {
    let msg = 'Delete '
    if (checkedPtpsNumber <= 4) {
      msg += 'participant '
      checkedPtpsArr.forEach((ptpId, i) => {
        const index = i + 1
        msg += ptpId
        if (index === checkedPtpsNumber) {
          msg += '?'
        } else {
          msg += index === checkedPtpsNumber - 1 ? ' and ' : ', '
        }
      })
    } else {
      msg += `${checkedPtpsNumber} participants?`
    }
    return msg
  }
  return (
    <>
      <span className='fa-stack fa-2x'>
        <i className='fas fa-circle fa-stack-1x' />
        <i className='fas fa-exclamation-circle fa-stack-2x' />
      </span>
      <div className='flexed centered'>
        <h4>{deleteMsg()}</h4>
      </div>
      <div className='flexed centered'>
        <p>
          Removing the participant from the study will delete all data associated with them. This cannot be undone.
          Please download any data needed for this participant before deleting them.
        </p>
      </div>
      <Button
        light
        onClick={() => {
          closeModal()
        }}>
        Cancel
      </Button>
      <Button
        red
        onClick={() => {
          deleteParticipants(studyID, checkedPtps, siteID, plural)
          closeModal()
        }}>
        Delete
      </Button>
    </>
  )
}
export default DeleteParticipant

DeleteParticipant.propTypes = {
  plural: PropTypes.bool,
  checkedPtps: PropTypes.objectOf(PropTypes.number),
  siteID: PropTypes.number,
  studyID: PropTypes.number,
  deleteParticipants: PropTypes.func,
  closeModal: PropTypes.func,
}
