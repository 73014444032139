import React from 'react'
import 'styles/d3/container.scss'

export default props => {
  const style = {
    display: props.flex ? 'flex' : undefined,
    flexDirection: props.row ? 'row' : 'column',
    width: props.width,
    height: props.height,
    margin: props.centered ? '0px auto' : undefined
  }
  return (
    <div style={style} className={'d3-container ' + (props.className || '')}>
      {props.children}
    </div>
  )
}
