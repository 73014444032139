import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { setNavBarProps } from 'store/navbar'
import { modalActions } from 'store/modal'
import { USER_ROLES_MAP, USER_ROLE_IDS_CAN_SEE_FLAGSMITH_CONFIGURATION } from 'utils/constants'
import { getUserScope, getUserRole, getUserRoleByStudy, getUserRoleId } from 'utils/misc'
import CreateStudyConfigurationPage from '../components/CreateStudyConfigurationPage'
import { actions as studyActions } from '../modules/CreateStudy'

const mapState = (state, ownProps) => {
  const { user, study, location, loading } = state
  const path = location.pathname.match(/([^/]+$)/)[0]
  const { params } = ownProps
  const userRole = params && params.studyID ? getUserRoleByStudy(user, params.studyID) : ''
  const baseUserRole = getUserRole(user)
  const { id } = study?.currentStudy
  const isFlagsmithConfigurationSectionShown = USER_ROLE_IDS_CAN_SEE_FLAGSMITH_CONFIGURATION.includes(
    getUserRoleId(user, id),
  )

  return {
    disabled: !ownProps.isWizard && !location.pathname.includes('create'),
    hasDeleteStudyPermissions: getUserScope(user).includes('wu') && userRole === USER_ROLES_MAP.root,
    isRootOrDchAdmin: baseUserRole === USER_ROLES_MAP.root || baseUserRole === USER_ROLES_MAP.dchAdmin,
    saveAttempted: study.saveAttempted,
    study: study.currentStudy,
    loading,
    locales: study.locales,
    preview: path === 'preview',
    selectedTab: path,
    isFlagsmithConfigurationSectionShown,
    userId: user?.id,
  }
}

const mapDispatch = { ...modalActions, ...studyActions, setNavBarProps }

export default connect(
  mapState,
  mapDispatch,
)(props => {
  return props.loading.general ? <Loader active /> : <CreateStudyConfigurationPage {...props} />
})
