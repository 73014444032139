import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import WebViewer from '@pdftron/webviewer'
import '../stylesheets/datacorrection.scss'

const DISABLED_TOOLBARS = ['toolbarGroup-Shapes', 'toolbarGroup-Edit']
const DISABLED_ANNOTATE_TOOLS = [
  'underlineToolGroupButton',
  'strikeoutToolGroupButton',
  'squigglyToolGroupButton',
  'freeTextToolGroupButton',
  'shapeToolGroupButton',
  'freeHandToolGroupButton',
]
const DISABLED_INSERT_TOOLS = ['rubberStampToolButton']
// Disabled features for popup on right click on empty space
const DISABLED_CONTEXT_MENU_POPUP = ['freeHandToolButton', 'freeTextToolButton']
// Disabled features for popup on selecting text
const DISABLED_SELECT_TEXT_POPUP = [
  'textUnderlineToolButton',
  'textSquigglyToolButton',
  'textStrikeoutToolButton',
  'linkButton',
]

const PDFViewer = ({ path, studyId, setSubmit, uploadDataCorrectionForm }) => {
  const viewer = useRef(null)

  const webViewerOptionProps = {
    path: '/lib/',
    initialDoc: path,
    disabledElements: [
      ...DISABLED_TOOLBARS,
      ...DISABLED_CONTEXT_MENU_POPUP,
      ...DISABLED_SELECT_TEXT_POPUP,
      ...DISABLED_ANNOTATE_TOOLS,
      ...DISABLED_INSERT_TOOLS,
    ],
    // licenseKey: TODO: ENTER LICENSE KEY HERE OTHERWISE THERE WILL BE PDFTRON WATERMARK
  }
  useEffect(() => {
    if (path) {
      WebViewer(webViewerOptionProps, viewer.current).then(instance => {
        instance.iframeWindow.addEventListener('loaderror', err => {
          instance.showErrorMessage(
            'We ran into an error with the PDF. Please contact support@datacubed.com to resolve this.',
          )
        })

        const { docViewer, annotManager } = instance

        const onSubmit = async () => {
          const doc = docViewer.getDocument()
          if (!doc) return
          const xfdfString = await annotManager.exportAnnotations()
          const data = await doc.getFileData({
            // saves the document with annotations in it
            xfdfString,
          })
          const arr = new Uint8Array(data)
          const blob = new Blob([arr], { type: 'application/pdf' })

          const _data = new FormData()
          _data.set('pdf_file', blob)
          _data.set('study_id', studyId)
          uploadDataCorrectionForm(_data)
        }
        setSubmit(onSubmit)
      })
    }
  }, [path])

  return <div className='webviewer' ref={viewer} />
}

PDFViewer.propTypes = {
  path: PropTypes.string,
  setSubmit: PropTypes.func,
  studyId: PropTypes.string,
  uploadDataCorrectionForm: PropTypes.func,
}

export default PDFViewer
