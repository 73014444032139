import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { setNavBarProps } from 'store/navbar'
import CreateVisitsPage from '../components/CreateVisitsPage'
import { actions } from '../modules/CreateVisits'
import { fetchSiteParticipants } from '../../../../Participants/routes/ParticipantsPage/modules/Participants'
import { fetchClinicians } from '../../../../Users/routes/Dashboard/modules/Users'

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps
  const { createVisitsReducer, leafSites, loading, study } = state
  const { base_site_ids: baseSiteIdArr, config } = study.currentStudy
  const { studyID } = params
  const { currentVisits, visitDropdownOptions, visitErrors } = createVisitsReducer
  const siteID = baseSiteIdArr[0]

  const recordingDisabled = config?.virtual_visits?.disable_recording

  return {
    currentVisits,
    recordingDisabled,
    leafSites,
    loading, // We want the entire loading state for visitor specific loading slices here, not just the general slice
    studyID,
    siteID,
    visitDropdownOptions,
    visitErrors,
  }
}

const mapDispatchToProps = {
  ...actions,
  fetchClinicians,
  fetchSiteParticipants,
  setNavBarProps,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { loading } = props
  const { general } = loading
  if (general) {
    return <Loader />
  }
  return <CreateVisitsPage {...props} />
})
