import React from 'react'
import { SortableTable, Checkbox, PercentMeter } from 'components/UIElements'
import PropTypes from 'prop-types'
import CreateSite from './CreateSite'
import SitePreviewPage from './SitePreviewPage'
import { filterOutSiteKeys, findSiteWithId } from '../modules/Sites'

export const KEY_NAME_MAP = {
  checkbox: null,
  siteId: 'ID',
  siteName: 'Site Name',
  country: 'Country',
  regionState: 'Region/State',
  participantCount: 'No. Of Participants',
  dateAdded: 'Date Added',
  compliance: 'Compliance',
}

const KEYS_TO_HIDE = ['signUpCode']

const renderComplianceCell = ({ idx, value }) => {
  return (
    <td key={`row_${idx}`} className='compliance-row no-wrap'>
      <PercentMeter invariableColor ratio={value} />
    </td>
  )
}

const SitesTable = props => {
  const {
    canCreate,
    offsets,
    openDrawer,
    sendDisabled,
    showHeaderCreateSite,
    sites,
    studyID,
    toggleCheckAllSites,
    toggleSite,
    removeNewSite,
    studyLock,
  } = props
  const { checkedSites, sitesList, newSites } = sites
  const noSites = sitesList.length === 0
  const headerOptionsSeed = Object.keys(KEY_NAME_MAP).map(key => {
    return { key, sortable: key !== 'checkbox', component: KEY_NAME_MAP[key] }
  })

  const onOpenSitePreview = (link, siteInfo) => {
    const rowSiteId = siteInfo[0].value
    openDrawer({
      closeOnBackgroundClick: true,
      content: <SitePreviewPage {...props} link={link} inDrawer siteInfo={findSiteWithId(sitesList, rowSiteId)} />,
    })
  }
  const renderCheckboxCell = ({ value, idx, checkedSites, compliance, disabled, newSites }) => {
    return (
      !studyLock && (
        <td
          className={`checkbox-cell${compliance ? ` ${compliance}` : ''}`}
          onClick={e => {
            e.stopPropagation()
            toggleSite(value)
          }}
          key={`col_${idx}`}>
          <Checkbox disabled={disabled} checked={value in checkedSites} />
          {newSites.includes(value) && <div className='blue-dot' />}
        </td>
      )
    )
  }
  const renderSiteNameCell = ({ value, idx, isVirtual }) => {
    return (
      <td className='site-name' key={`col_site-name_${idx}`}>
        <span>{value}</span>
        {isVirtual && <div>v</div>}
      </td>
    )
  }

  /**
   * The noWrapMap defines for which keys we don't want the table data to wrap into
   * multiple lines. If column data key has a unique render-function, add inlude
   * `no-wrap` in the className.
   */
  const noWrapMap = { siteId: true }

  const renderFunctions = {
    checkbox: renderCheckboxCell,
    siteName: renderSiteNameCell,
    compliance: renderComplianceCell,
  }

  const getHeaderOptions = () => {
    const newHeaderOptions = [...headerOptionsSeed]

    if (!studyLock) {
      newHeaderOptions[0].component = (
        <Checkbox
          checked={Object.keys(checkedSites).length === sitesList.length}
          disabled={sendDisabled}
          onClick={() => toggleCheckAllSites(sitesList)}
        />
      )
    } else if (newHeaderOptions[0].key === 'checkbox') {
      newHeaderOptions.splice(0, 1)
    }
    return newHeaderOptions
  }

  const sendStateClassName = sendDisabled ? 'send-state' : ''

  return (
    <div>
      {canCreate && (showHeaderCreateSite || noSites) && <CreateSite header {...props} />}
      {!noSites && (
        <SortableTable
          className={`sites-list ${sendStateClassName}`}
          disabled={sendDisabled}
          headerOptions={getHeaderOptions()}
          offset={offsets[0]}
          onRowClick={
            !sendDisabled
              ? row => {
                  const rowSiteID = row[0].value
                  const editLink = `/studies/${studyID}/sites/edit/${row[0].value}`
                  removeNewSite(rowSiteID)
                  onOpenSitePreview(editLink, row)
                }
              : null
          }
          optionalCellProps={{ checkedSites, newSites }}
          renderFunctions={renderFunctions}
          rowList={filterOutSiteKeys(sitesList, KEYS_TO_HIDE)}
          noWrapMap={noWrapMap}
          sortingBy='siteName'
        />
      )}
    </div>
  )
}

SitesTable.propTypes = {
  canCreate: PropTypes.bool,
  offsets: PropTypes.arrayOf(PropTypes.number),
  onSiteClick: PropTypes.func,
  openDrawer: PropTypes.func,
  params: PropTypes.object,
  removeNewSite: PropTypes.func,
  sendDisabled: PropTypes.bool,
  showFooterCreateSite: PropTypes.bool,
  showHeaderCreateSite: PropTypes.bool,
  sites: PropTypes.shape({
    checkedSites: PropTypes.object,
    newSites: PropTypes.array,
    sitesList: PropTypes.array,
  }),
  studyID: PropTypes.number,
  toggleCheckAllSites: PropTypes.func,
  toggleSite: PropTypes.func,
  studyLock: PropTypes.bool,
}

export default SitesTable
