import React from 'react'
import PropTypes from 'prop-types'
import { QUESTION_TYPE_MAP } from 'utils/constants'
import AudioCaptureView from './AudioCaptureView'
import DateInputRange from './InputRange/DateInputRange'
import ImageCaptureView from './ImageCaptureView'
import LogicPreview from './LogicPreview'
import NumericInputRange from './InputRange/NumericInputRange'
import SelectView from './SelectView'
import FoodEntryView from './FoodEntryView'
import TimerView from './TimerView'
import TimeInputRange from './InputRange/TimeInputRange'
import VAScaleView from './VAScaleView'

class QuestionView extends React.Component {
  constructor(props) {
    super(props)
    this.DISPLAY_PROPS = {
      integer: '123',
      decimal: '123',
      text: 'abc',
      date: <i key='fa-calendar-preview' className='far fa-calendar-alt' />,
      time: <i key='fa-clock-preview' className='fa fa-clock-o' />,
      datetime: (
        <span className='flexed'>
          <i key='fa-calendar-preview' className='far fa-calendar-alt' />
          <i key='fa-clock-preview' className='fa fa-clock-o' />
        </span>
      ),
    }
  }

  getIsLastQuestion = () => {
    const { itemId, survey } = this.props
    if (!survey) return false
    const { order } = survey
    return order[0][order[0].length - 1] === itemId
  }

  renderDefaultQuestion = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <div>
        {item.type !== 'introduction' ? (
          <div className='non-select-preview'>
            <div style={{ fontFamily: 'AmericanTypeWriter' }}>{displayComponent}</div>
          </div>
        ) : null}
        <LogicPreview item={item} errors={errors} />
      </div>
    )
  }

  renderNumericInputRange = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <>
        <NumericInputRange
          {...this.props}
          displayComponent={displayComponent}
          isDecimal={item.type === QUESTION_TYPE_MAP.decimal}
        />
        <LogicPreview item={item} errors={errors} />
      </>
    )
  }

  renderDateQuestion = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <>
        <DateInputRange
          {...this.props}
          isLastQuestion={this.getIsLastQuestion()}
          displayComponent={displayComponent}
          timeDisplayComponent={this.DISPLAY_PROPS.time}
          dateDisplayComponent={this.DISPLAY_PROPS.date}
          isDateTime={item.type === QUESTION_TYPE_MAP.datetime}
        />
        <LogicPreview item={item} errors={errors} />
      </>
    )
  }

  renderTimeInputRange = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <>
        <TimeInputRange
          {...this.props}
          displayComponent={displayComponent}
          timeDisplayComponent={this.DISPLAY_PROPS.time}
        />
        <LogicPreview item={item} errors={errors} />
      </>
    )
  }

  renderTimerQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <TimerView {...this.props} />
        <LogicPreview errors={errors} item={item} />
      </div>
    )
  }

  renderSelectQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <SelectView {...this.props} />
        <LogicPreview errors={errors} item={item} />
      </div>
    )
  }

  renderFoodEntryQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <FoodEntryView {...this.props} />
        <LogicPreview errors={errors} item={item} />
      </div>
    )
  }

  renderVASQuestion = vasType => {
    const { item, errors } = this.props
    return (
      <div>
        <VAScaleView {...this.props} vasType={vasType} />
        <LogicPreview errors={errors} item={item} />
      </div>
    )
  }

  renderAudioRecordingQuestion = () => {
    return <AudioCaptureView {...this.props} />
  }

  renderScribbleQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <div className='non-select-preview flexed column center-justified scribble'>
          <i key='fa-pencil-alt' className='fas fa-pencil-alt' />
          <p>Participant drawing will be collected</p>
        </div>
        <LogicPreview errors={errors} item={item} />
      </div>
    )
  }

  renderImageCaptureQuestion = () => {
    const { item, errors } = this.props
    return (
      <div>
        <ImageCaptureView {...this.props} />
        <LogicPreview error={errors} item={item} />
      </div>
    )
  }

  render() {
    const { item } = this.props
    switch (item.type) {
      case QUESTION_TYPE_MAP.numericRatingScale:
      case QUESTION_TYPE_MAP.likert:
      case QUESTION_TYPE_MAP.longListSelectOne:
      case QUESTION_TYPE_MAP.selectOne:
      case QUESTION_TYPE_MAP.selectMultiple:
      case QUESTION_TYPE_MAP.multipleField:
        return this.renderSelectQuestion()
      case QUESTION_TYPE_MAP.vasHorizontal:
        return this.renderVASQuestion(QUESTION_TYPE_MAP.vasHorizontal)
      case QUESTION_TYPE_MAP.vaScale:
        return this.renderVASQuestion(QUESTION_TYPE_MAP.vaScale)
      case QUESTION_TYPE_MAP.vaScale2021:
        return this.renderVASQuestion(QUESTION_TYPE_MAP.vaScale2021)
      case QUESTION_TYPE_MAP.audioRecording:
        return this.renderAudioRecordingQuestion()
      case QUESTION_TYPE_MAP.imageCapture:
        return this.renderImageCaptureQuestion()
      case QUESTION_TYPE_MAP.scribble:
        return this.renderScribbleQuestion()
      case QUESTION_TYPE_MAP.decimal:
      case QUESTION_TYPE_MAP.integer:
        return this.renderNumericInputRange()
      case QUESTION_TYPE_MAP.date:
      case QUESTION_TYPE_MAP.datetime:
        return this.renderDateQuestion()
      case QUESTION_TYPE_MAP.time:
        return this.renderTimeInputRange()
      case QUESTION_TYPE_MAP.timer:
        return this.renderTimerQuestion()
      case QUESTION_TYPE_MAP.foodEntry:
        return this.renderFoodEntryQuestion()
      default:
        return this.renderDefaultQuestion()
    }
  }
}

export default QuestionView

QuestionView.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.string,
  onDeleteChoice: PropTypes.func,
  updateItem: PropTypes.func,
  isActive: PropTypes.bool,
  errors: PropTypes.object,
  preview: PropTypes.bool,
  survey: PropTypes.object,
}
