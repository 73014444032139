// We only need to import the modules necessary for initial render
import { setSubroute } from 'store/navbar'
import { fetchUser } from 'store/user'
import { setPageTitle } from 'utils/misc'
import StudiesRoute from './StudiesPage'
import CreateStudyRoute from './routes/CreateStudy'
import StudyRoute from './routes/Study'
import { fetchStudies } from './StudiesPage/modules/Studies'
import { clearPayments } from './routes/Study/routes/Payments/modules/Payments'

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export const createRoutes = store => {
  return {
    path: 'studies',
    exact: true,
    getIndexRoute(location, cb) {
      setPageTitle('All Studies | Admin Panel')
      localStorage.removeItem('lastUser')
      store.dispatch(fetchUser())
      store.dispatch(clearPayments())
      store.dispatch(fetchStudies())
      store.dispatch(setSubroute('studies'))
      cb(null, StudiesRoute(store))
    },
    getChildRoutes(location, cb) {
      cb(null, [CreateStudyRoute(store), StudyRoute(store)])
    },
  }
}

export default createRoutes
