import React from 'react'
import STRINGS from 'utils/strings'

const StatusTimestampTooltip = ({ site, mainSite }) => {
  return (
    <div className='status-timestamp-tooltip'>
      <p>{STRINGS.statusTooltipContent}</p>
      <p className='site-text'>{`${STRINGS.siteName} ${site || '---'}`}</p>
      <p className='main-site-text'>{mainSite}</p>
    </div>
  )
}

export default StatusTimestampTooltip
