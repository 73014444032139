import React from 'react'
import PropTypes from 'prop-types'
import { generateDateStringWithTimeZone } from 'utils/time'

const ParticipantReportEvents = ({ participantReports }) => {
  const hasEvents = participantReports.length > 0
  const icon = 'far fa-comment'

  const ptpEventList = participantReports.map((event, i) => {
    const { has_symptom, timestamp, geofence_name, is_uncertain } = event
    let string = has_symptom ? 'I have an infection' : "I don't have an infection"
    if (is_uncertain) string = "I'm not sure I have an infection"
    return (
      <div className='flexed column' key={`${has_symptom}-${timestamp}-${i}`}>
        <div className='flexed'>
          <span>{string}</span>
          <span>{generateDateStringWithTimeZone(timestamp)}</span>
        </div>
        {geofence_name && <span>{geofence_name}</span>}
      </div>
    )
  })
  return (
    <div className='participant-reports'>
      <div className='header'>
        <i className={icon} />
        PARTICIPANT REPORTED
      </div>
      {hasEvents ? ptpEventList : <div className='no-events'>Participant has not submitted responses to symptoms</div>}
      <div className='bottom-spacer' />
    </div>
  )
}

ParticipantReportEvents.propTypes = {
  participantReports: PropTypes.arrayOf(PropTypes.object),
}

export default ParticipantReportEvents
