import React from 'react'
import { SortableTable, Checkbox } from 'components/UIElements'
import PropTypes from 'prop-types'
import CreateGeofence from './CreateGeofence'
import GeofencePreviewPage from './GeofencePreviewPage'

export const KEY_NAME_MAP = {
  checkbox: null,
  name: 'Name',
  latitude: 'Latitude',
  longitude: 'Longitude',
  radius: 'Radius (meters)',
  dateUpdated: 'Date Updated',
}

const GeofencesTable = props => {
  const {
    canCreate,
    geofences,
    offsets,
    openDrawer,
    showHeaderCreateGeofence,
    studyID,
    toggleCheckAllGeofences,
    toggleGeofence,
    studyLock,
  } = props
  const { checkedGeofences, geofencesList } = geofences
  const noGeofences = geofencesList.length === 0
  const headerOptions = Object.keys(KEY_NAME_MAP).map(key => {
    return { key, sortable: key !== 'checkbox', component: KEY_NAME_MAP[key] }
  })
  const newGeofenceList = geofenceList => geofenceList.map(geofence => geofence.filter((_, i) => i !== 0))

  const onOpenGeofencePreview = (link, geofenceInfo) => {
    openDrawer({
      closeOnBackgroundClick: true,
      content: <GeofencePreviewPage {...props} link={link} inDrawer geofenceInfo={geofenceInfo} />,
    })
  }

  const renderCheckboxCell = ({ value, idx, checkedGeofences }) => {
    return (
      <td
        className='checkbox-cell'
        onClick={e => {
          e.stopPropagation()
          toggleGeofence(value)
        }}
        key={`col_${idx}`}>
        <Checkbox checked={value in checkedGeofences} />
      </td>
    )
  }

  const renderFunctions = {
    checkbox: renderCheckboxCell,
  }
  headerOptions[0].component = (
    <Checkbox
      checked={Object.keys(checkedGeofences).length === geofencesList.length}
      onClick={() => toggleCheckAllGeofences(geofencesList)}
    />
  )
  return (
    <div>
      {canCreate && (showHeaderCreateGeofence || noGeofences) && <CreateGeofence header {...props} />}
      {!noGeofences && (
        <SortableTable
          className='geofences-list'
          headerOptions={studyLock ? headerOptions.filter(option => option.key !== 'checkbox') : headerOptions}
          offset={offsets[0]}
          onRowClick={row => {
            const editLink = `/studies/${studyID}/geofences/edit/${row[0].value}`
            onOpenGeofencePreview(editLink, row)
          }}
          optionalCellProps={{ checkedGeofences }}
          renderFunctions={renderFunctions}
          rowList={studyLock ? newGeofenceList(geofencesList) : geofencesList}
          sortingBy='dateUpdated'
          initialOrder='descending'
        />
      )}
    </div>
  )
}

GeofencesTable.propTypes = {
  canCreate: PropTypes.bool,
  geofences: PropTypes.shape({
    checkedGeofences: PropTypes.object,
    geofencesList: PropTypes.array,
  }),
  offsets: PropTypes.arrayOf(PropTypes.number),
  onGeofenceClick: PropTypes.func,
  openDrawer: PropTypes.func,
  params: PropTypes.shape({ siteID: PropTypes.string }),
  showFooterCreateGeofence: PropTypes.bool,
  showHeaderCreateGeofence: PropTypes.bool,
  studyID: PropTypes.number,
  toggleCheckAllGeofences: PropTypes.func,
  toggleGeofence: PropTypes.func,
  studyLock: PropTypes.bool,
}

export default GeofencesTable
