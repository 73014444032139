import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserHasFeatures } from 'containers/authContainers'
import { setPageTitle } from 'utils/misc'
import LockRedirect from 'containers/lockContainers'
import { onFetchInstrument } from '../../modules/Instrument'
import { fetchDiary } from './modules/Diary'
import DiaryEditView from './containers/DiaryContainer'

// Sync route definition
export default store => ({
  path: ':instrumentID/editdiary',
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */
        const { studyID, instrumentID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Edit Participant Instrument | Study ${studyID} | ${currentStudy.platform_name}`)

        store.dispatch(onFetchInstrument(studyID, instrumentID)).then(() => {
          store.dispatch(fetchDiary(studyID, instrumentID))
        })
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'instruments',
              )(UserHasScope(['wa'], UserHasFeatures(DiaryEditView, ['ediary'], 'instruments'), false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      '/instruments/editdiary/:id',
    )
  },
})
