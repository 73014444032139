import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components/UIElements'
import { INPUT_VALIDATION_TYPE_MAP } from 'utils/constants'
import { SelectRange } from './DiaryNumericInputRange'
import RelativeDateRange from './DiaryRelativeDateRange'
import DatePickerRange from './DiaryDatePickerRange'
import TimeInputRange from './DiaryTimeInputRange'

const POPUP_TEXT =
  '"Relative to deployment" should be used for instruments that will be set to "repeat" or deployed "relative to start date" in the last step of this survey creation. Participant’s allowed input range will be based on the date/time that that specific instrument is received by the participant.'

const Tab = ({ disabled, isFocused, isRightTab, onClick, title, helpText }) => {
  return (
    <Button
      disabled={disabled}
      noStyling
      onClick={onClick}
      className={`flexed center-justified tab ${isFocused ? 'focused' : ''} ${isRightTab ? 'right' : ''}`}>
      {title}
      {helpText && (
        <div className='popup-container position-relative'>
          <i className='fas fa-info-circle' />
          <p>{helpText}</p>
        </div>
      )}
    </Button>
  )
}

const DateInputRange = props => {
  const {
    clearQuestionError,
    displayComponent,
    errors = {},
    isDateTime,
    isLastQuestion,
    item,
    itemId,
    preview,
    dateDisplayComponent,
    updateItem,
  } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const [isExactRangeTab, setisExactRangeTab] = useState(true)

  useEffect(() => {
    if (item.input_validation && Object.keys(item.input_validation).length) {
      setIsExpanded(true)
      if (item.input_validation.relative && !item.input_validation.isExactRangeTab) {
        setisExactRangeTab(false)
      }
    }
    if (!item.input_validation) {
      setIsExpanded(false)
    }
  }, [item.input_validation, item.input_validation?.isExactRangeTab])

  const example = <div className='example'>{displayComponent}</div>
  const gearIcon = (
    <div className='example'>
      <i key='fas fa-cog' className='fas fa-cog' />
    </div>
  )

  const hasError = errors.numericInputRangeError

  const itemWithDefaults = {
    ...item,
    input_validation: {
      absolute_date: {
        min: '',
        max: '',
      },
      isExactRangeTab,
      ...item.input_validation,
    },
  }

  const updateInput = (key, val) => {
    const prevInputValidation = itemWithDefaults.input_validation
    if (hasError) {
      clearQuestionError(itemId)
    }
    const newItem = {
      ...item,
      input_validation: {
        ...prevInputValidation,
        [key]: val,
      },
    }
    updateItem(itemId, newItem)
  }

  const resetInputValidation = () => {
    const newItem = {
      ...item,
      input_validation: {},
    }
    updateItem(itemId, newItem)
  }

  const relativeProps = { item, itemId, preview, updateItem }

  return (
    <div className={`non-select-preview date${isDateTime ? 'time' : ''}-input-range`}>
      <div className='flexed space-between-justified'>
        {!isExpanded ? example : <div />}
        {!preview && (
          <Button
            noStyling
            onClick={() => {
              if (isExpanded) {
                resetInputValidation()
                clearQuestionError(itemId)
              }
              setIsExpanded(!isExpanded)
            }}
            content={isExpanded ? 'Remove input range' : 'Set input range'}
          />
        )}
      </div>
      {isExpanded && !preview && (
        <div className='flexed start-justified'>
          <Tab
            disabled={preview}
            isFocused={isExactRangeTab}
            title='Exact date range'
            onClick={() => {
              setisExactRangeTab(true)
              updateInput('isExactRangeTab', true)
            }}
          />
          <Tab
            disabled={preview}
            isFocused={!isExactRangeTab}
            title='Relative to deployment'
            helpText={POPUP_TEXT}
            isRightTab
            onClick={() => {
              setisExactRangeTab(false)
              updateInput('isExactRangeTab', false)
            }}
          />
        </div>
      )}
      {isExpanded &&
        (isExactRangeTab ? (
          <>
            <SelectRange exampleComponent={dateDisplayComponent} description='Accepted Date Range'>
              <div className='flexed column end-aligned'>
                <div className='flexed start-justified input-area'>
                  <DatePickerRange
                    disabled={preview}
                    isLastQuestion={isLastQuestion}
                    item={itemWithDefaults}
                    onFromDateChange={val => {
                      updateInput(INPUT_VALIDATION_TYPE_MAP.absDate, {
                        ...itemWithDefaults.input_validation.absolute_date,
                        min: val,
                      })
                    }}
                    onToDateChange={val => {
                      updateInput(INPUT_VALIDATION_TYPE_MAP.absDate, {
                        ...itemWithDefaults.input_validation.absolute_date,
                        max: val,
                      })
                    }}
                    onAllPastDates={() => {
                      updateInput(INPUT_VALIDATION_TYPE_MAP.absDate, {
                        ...itemWithDefaults.input_validation.absolute_date,
                        min: '',
                      })
                    }}
                    onAllFutureDates={() => {
                      updateInput(INPUT_VALIDATION_TYPE_MAP.absDate, {
                        ...itemWithDefaults.input_validation.absolute_date,
                        max: '',
                      })
                    }}
                    onResetDates={() => {
                      updateInput(INPUT_VALIDATION_TYPE_MAP.absDate, {
                        ...itemWithDefaults.input_validation.absolute_date,
                        min: '',
                        max: '',
                      })
                    }}
                  />
                </div>
              </div>
            </SelectRange>
            {isDateTime && <TimeInputRange {...props} isDateTime isExactRangeTab={isExactRangeTab} />}
          </>
        ) : (
          <SelectRange
            exampleComponent={gearIcon}
            description='Limit input date to'
            outerDivClassName='start-aligned'
            innerDivClassName='limit-label'>
            <div className='flexed column end-aligned'>
              <div className='flexed start-justified input-area relative-date'>
                <RelativeDateRange {...relativeProps} />
              </div>
            </div>
          </SelectRange>
        ))}
    </div>
  )
}

DateInputRange.propTypes = {
  clearQuestionError: PropTypes.func,
  dateDisplayComponent: PropTypes.node,
  displayComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errors: PropTypes.object,
  isLastQuestion: PropTypes.bool,
  isDateTime: PropTypes.bool,
  isDecimal: PropTypes.bool,
  item: PropTypes.object,
  itemId: PropTypes.string,
  preview: PropTypes.bool,
  timeDisplayComponent: PropTypes.node,
  updateItem: PropTypes.func,
}

Tab.propTypes = {
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  isFocused: PropTypes.bool,
  isRightTab: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
}

export default DateInputRange
