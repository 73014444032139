import React from 'react'
import PropTypes from 'prop-types'
import { UploadInput, Button } from 'components/UIElements'

class UploadTranslationInput extends React.Component {
  constructor(props) {
    super(props)
    this._getDefaultState = () => ({ file: {}, loading: false })
    this.state = this._getDefaultState()
  }

  onUpdateFile = file => {
    this.setState({ file })
  }

  onUploadTranslation = () => {
    const {
      closeModal,
      forAnnouncement,
      forVisitSchedule,
      forConsent,
      forLongList,
      instrument,
      itemId,
      survey,
      studyID,
      uploadTranslation,
    } = this.props
    const { file } = this.state

    this.setState({ loading: true })
    if (forConsent) {
      uploadTranslation(studyID, file).then(() => {
        this.setState(this._getDefaultState())
        closeModal()
      })
    } else if (forAnnouncement) {
      uploadTranslation(file).then(() => {
        this.setState(this._getDefaultState())
      })
    } else if (forVisitSchedule) {
      uploadTranslation(file).then(() => {
        this.setState(this._getDefaultState())
      })
    } else if (forLongList) {
      uploadTranslation(file, itemId, survey).then(() => {
        this.setState(this._getDefaultState())
        closeModal()
      })
    } else {
      uploadTranslation(studyID, instrument, file).then(() => {
        this.setState(this._getDefaultState())
        closeModal()
      })
    }
  }

  onCancel = () => {
    const { closeModal } = this.props
    this.setState(this._getDefaultState())
    closeModal()
  }

  render() {
    const { instrument, forConsent, forAnnouncement, forVisitSchedule, forLongList, subsequentLogic } = this.props
    const { file, loading } = this.state
    const disabled = !(file instanceof File)

    let typeCopy = ' item'
    if (instrument) typeCopy = ` ${instrument.title}`
    else if (forConsent) typeCopy = ' Consent'
    else if (forAnnouncement) typeCopy = ' Announcement'
    else if (forVisitSchedule) typeCopy = ' Visit Schedule'
    else if (forLongList) typeCopy = ' Select One (10+ options)'

    return (
      <div className='upload-translation-box'>
        <h2>Upload CSV file with Translation</h2>
        <p>
          Upload translation for
          <b>{typeCopy}</b>
        </p>
        <UploadInput type='CSV' accept={['.csv']} file={file} onUpdateFile={this.onUpdateFile} loading={loading} />
        <div className='button-list'>
          <Button grey content='Cancel' onClick={this.onCancel} />
          <Button disabled={disabled} content='Upload' onClick={this.onUploadTranslation} />
        </div>
        {!!subsequentLogic && (
          <div className='warning-box'>
            <p>
              <i className='fas fa-exclamation-triangle' />
              Uploading a CSV file will replace the existing choices and delete all logic conditions.
            </p>
          </div>
        )}
      </div>
    )
  }
}

UploadTranslationInput.propTypes = {
  closeModal: PropTypes.func,
  forAnnouncement: PropTypes.bool,
  forConsent: PropTypes.bool,
  forLongList: PropTypes.bool,
  instrument: PropTypes.shape({
    title: PropTypes.string,
  }),
  studyID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uploadTranslation: PropTypes.func,
  itemId: PropTypes.string,
  subsequentLogic: PropTypes.object,
}

export default UploadTranslationInput
