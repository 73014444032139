import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Radio, DatePicker, NumberInputDropdown, WarningText } from 'components/UIElements'
import { fromUTCMidnightSeconds, toUTCMidnightSeconds } from 'utils/time'
import STRINGS from 'utils/strings'
import { pluralize } from 'utils/misc'
import { DEPLOYMENT_KEYS, DEPLOYMENT_VALUES } from 'utils/constants'
import VisitExpireContent from './VisitExpireContent'
import dayOptions, { RECURRING_AFTER_TIME_OPTION_DEFAULT } from '../../constants/schedulerConstants'
import contentConnect from '../../containers/ContentContainer'

const CHAIN_EXPIRE_OFFSET = DEPLOYMENT_KEYS.chainExpireOffset
const NEVER = DEPLOYMENT_KEYS.never
const NOW = DEPLOYMENT_KEYS.now
const RELATIVE_TO_FIRST_LOGIN = DEPLOYMENT_KEYS.firstLogin
const RELATIVE_TO_SEND_IDEAL = DEPLOYMENT_KEYS.relativeToSendIdeal
const RECEIPT_OF_ARTICLE = DEPLOYMENT_VALUES.receiptOfArticle
const COMPLETION_OF_VISIT = DEPLOYMENT_VALUES.completionOfVisit
const EXPIRE_TYPE = DEPLOYMENT_KEYS.expireType
const RECURRENCE_DEFAULT_VALUE = 2

export const _getDayString = num => `day${num !== 1 ? 's' : ''}`
const _getOccurenceString = num => (num !== 1 ? 'occurrences' : 'occurrence')

const _getExpireOnContent = ({
  expire,
  deploy,
  isInstrumentToInstrument,
  isChainExpire,
  isRelative,
  isRelativeToSendIdeal,
  updateExpireField,
  updateExpire,
  selected,
  updateRecurring,
  errors,
}) => {
  const isRelativeExpiration = isRelative || isChainExpire || isRelativeToSendIdeal
  const selectedDate = moment(expire.absolute ? fromUTCMidnightSeconds(expire.absolute) : moment().startOf('day'))
  const startDate = moment(deploy.absolute ? fromUTCMidnightSeconds(deploy.absolute) : undefined)

  const getRelativeExpirationVal = () => {
    if (isRelativeExpiration) {
      if (expire.first_login) {
        return expire.first_login.interval - deploy.first_login.interval
      }
      if (isChainExpire) {
        return expire.chain_expire_offset - deploy.chain_deploy_offset
      }
      if (isRelativeToSendIdeal) {
        return expire.relative_to_send_ideal?.interval - 1
      }
    }
    return null
  }

  const relExpVal = getRelativeExpirationVal()

  const input = isRelativeExpiration ? (
    <NumberInputDropdown
      value={relExpVal}
      options={dayOptions}
      onChange={num => {
        updateRecurring(null)
        if (isRelative) {
          updateExpireField(RELATIVE_TO_FIRST_LOGIN, {
            interval: num + deploy.first_login.interval,
            interval_type: 'days',
          })
        } else if (isInstrumentToInstrument) {
          updateExpireField(CHAIN_EXPIRE_OFFSET, num + deploy.chain_deploy_offset)
        } else if (isRelativeToSendIdeal) {
          updateExpireField(RELATIVE_TO_SEND_IDEAL, { interval: num + 1, interval_type: 'days' })
        }
      }}
      onBlur={e => {
        if (e.target.value === '') {
          if (isRelative) {
            updateExpireField(RELATIVE_TO_FIRST_LOGIN, {
              interval: 1 + deploy.first_login.interval,
              interval_type: 'days',
            })
          } else if (isInstrumentToInstrument) {
            updateExpireField(CHAIN_EXPIRE_OFFSET, 1 + deploy.chain_deploy_offset)
          } else if (isRelativeToSendIdeal) {
            updateExpireField(RELATIVE_TO_SEND_IDEAL, { interval: 1, interval_type: 'days' })
          }
        }
      }}
    />
  ) : (
    <DatePicker
      disabledDays={{ before: startDate.toDate() }}
      initialDate={selectedDate}
      onDayChange={date => {
        updateRecurring(null)
        updateExpire({ absolute: toUTCMidnightSeconds(date) })
      }}
    />
  )
  return (
    <div>
      <span>{isRelativeExpiration ? 'Expire' : 'Expire on Date'}</span>
      {(isRelativeExpiration || selected.expire_on) && input}
      {isRelativeExpiration ? `${pluralize(relExpVal, 'day', 'days', false)} after Instrument starts` : ''}
      <WarningText text={errors['expire-2']} />
    </div>
  )
}

const ExpireContent = props => {
  const {
    deploy,
    disabled,
    expire,
    isVisitSummary,
    isDeployedByVisit,
    isDiary,
    isInstrumentToInstrument,
    isRelative,
    isRelativeToInstrumentSentDate,
    recurring,
    updateExpire,
    updateRecurring,
    updateRecurringField,
    hasInstrumentUnification,
  } = props

  const expires =
    'absolute' in expire ||
    RELATIVE_TO_FIRST_LOGIN in expire ||
    RELATIVE_TO_SEND_IDEAL in expire ||
    EXPIRE_TYPE in expire
  const chainExpires = CHAIN_EXPIRE_OFFSET in expire
  const isImmediateStart = NOW in deploy
  const isSameRelativeDay = isRelative && expires && deploy.first_login.interval === expire.first_login.interval
  const isSameRelativeDayToSendIdeal =
    isRelativeToInstrumentSentDate && expires && expire.relative_to_send_ideal.interval === 1
  const isAfterRelativeDayToSendIdeal =
    isRelativeToInstrumentSentDate && expires && expire.relative_to_send_ideal.interval !== 1
  const isAfterVisit = isDeployedByVisit && expires && expire[EXPIRE_TYPE] === COMPLETION_OF_VISIT
  const isAfterReceiptOfArticle = isDeployedByVisit && expires && expire[EXPIRE_TYPE] === RECEIPT_OF_ARTICLE

  const selected = {
    all_completed: NEVER in expire && (!recurring || !recurring.recurrences) && !expire[NEVER],
    expire_after_send_ideal: expires && isAfterRelativeDayToSendIdeal,
    expire_after: chainExpires,
    expire_on: expires && !(isSameRelativeDay || isSameRelativeDayToSendIdeal || isAfterRelativeDayToSendIdeal),
    repeat_after: NEVER in expire && recurring && !!recurring.recurrences,
    same_day_send_ideal: expires && isSameRelativeDayToSendIdeal,
    same_day: expires && isSameRelativeDay,
    receipt_of_article: isAfterReceiptOfArticle,
    completion_of_visit: isAfterVisit,
    never: NEVER in expire && expire[NEVER],
  }

  const _updateRecurring = _recurring => {
    // if recurring is already selected just add recurrences default value
    const newRecurring = {}
    Object.assign(newRecurring, _recurring?.scheme ? _recurring : RECURRING_AFTER_TIME_OPTION_DEFAULT['n-days'])

    newRecurring.recurrences = RECURRENCE_DEFAULT_VALUE

    updateRecurring(newRecurring)
    updateExpire({ never: null })
  }

  return (
    <div className={`expire-menu${disabled ? ' disabled' : ''}`}>
      <p>
        <span>When should this instrument </span>
        <strong>expire</strong>
        <span>?</span>
      </p>
      <div>
        {!hasInstrumentUnification ? (
          <Radio
            id='when-all-complete'
            selected={selected.all_completed}
            onClick={() => {
              updateRecurring(null)
              updateExpire({ never: null })
            }}
            disabled={isVisitSummary}
            content={isDiary ? STRINGS.neverExpire : STRINGS.whenAllComplete}
          />
        ) : null}
        {!isRelative && isImmediateStart && (
          <>
            <Radio
              id='on-same-day-instrument-starts'
              selected={selected.same_day_send_ideal}
              onClick={() => {
                updateRecurring(null)
                updateExpire({ relative_to_send_ideal: { interval: 1, interval_type: 'days' } })
              }}
              content='On same day Instrument starts'
            />
            <Radio
              id='expire'
              selected={selected.expire_after_send_ideal}
              onClick={() => {
                updateRecurring(null)
                updateExpire({ relative_to_send_ideal: { interval: 2, interval_type: 'days' } })
              }}
              content={_getExpireOnContent({
                ...props,
                selected,
                isRelativeToSendIdeal: true,
              })}
            />
          </>
        )}
        {hasInstrumentUnification
          ? isRelative && (
              <Radio
                id='on-same-day-instrument-starts'
                selected={selected.same_day}
                onClick={() => {
                  updateRecurring(null)
                  updateExpire({ first_login: { interval: deploy.first_login.interval, interval_type: 'days' } })
                }}
                content='On same day Instrument starts'
              />
            )
          : isRelative &&
            !isDiary && (
              <Radio
                id='on-same-day-instrument-starts'
                selected={selected.same_day}
                onClick={() => {
                  updateRecurring(null)
                  updateExpire({ first_login: { interval: deploy.first_login.interval, interval_type: 'days' } })
                }}
                content='On same day Instrument starts'
              />
            )}
        {!isDeployedByVisit && (
          <Radio
            selected={selected.expire_on}
            onClick={() => {
              const defaultExpire = deploy.absolute || toUTCMidnightSeconds(moment())
              updateRecurring(null)
              updateExpire(
                isRelative
                  ? { first_login: { interval: deploy.first_login.interval + 1, interval_type: 'days' } }
                  : { absolute: defaultExpire },
              )
            }}
            content={_getExpireOnContent({ ...props, selected })}
          />
        )}
        {isInstrumentToInstrument && (
          <Radio
            id='inst-to-inst-expire-after'
            selected={selected.expire_after}
            onClick={() => {
              updateRecurring(null)
              updateExpire({ chain_expire_offset: deploy.chain_deploy_offset + 1 })
            }}
            content={_getExpireOnContent({ ...props, selected, isChainExpire: true })}
          />
        )}
        {!(isInstrumentToInstrument || isDeployedByVisit) && (
          <Radio
            id='expire-after'
            selected={selected.repeat_after}
            onClick={() => {
              _updateRecurring(recurring)
            }}
            content={
              <div>
                <span>Expire after</span>
                <input
                  className='occurence-input'
                  aria-label='occurence-input'
                  min={0}
                  type='number'
                  id='expire-occurance-input'
                  value={recurring && selected.repeat_after ? recurring.recurrences : ''}
                  onChange={e => {
                    if (recurring && recurring.recurrences) {
                      updateRecurringField('recurrences', parseInt(e.target.value) || 1)
                    } else {
                      const newRecurring = {
                        interval: 1,
                        interval_type: 'days',
                        ...recurring,
                        recurrences: 2,
                        scheme: 'n-days',
                        visible_days: 1,
                      }
                      updateRecurring(newRecurring)
                      updateExpire({ never: null })
                    }
                  }}
                />
                {_getOccurenceString(recurring ? recurring.recurrences : '')}
              </div>
            }
          />
        )}
        {hasInstrumentUnification ? (
          <Radio
            id='when-all-complete'
            selected={selected.all_completed}
            onClick={() => {
              updateRecurring(null)
              updateExpire({ never: null })
            }}
            disabled={isVisitSummary}
            content={STRINGS.neverExpire}
          />
        ) : null}
        {isDeployedByVisit && <VisitExpireContent selected={selected} isVisitSummary={isVisitSummary} {...props} />}
      </div>
    </div>
  )
}

const sharedPropTypes = {
  isInstrumentToInstrument: PropTypes.bool,
  isRelative: PropTypes.bool,
  isRelativeToInstrumentSentDate: PropTypes.bool,
  updateExpire: PropTypes.func,
}

_getExpireOnContent.propTypes = {
  ...sharedPropTypes,
  isChainExpire: PropTypes.bool,
  updateExpireField: PropTypes.func,
  updateRecurring: PropTypes.func,
}

ExpireContent.propTypes = {
  ...sharedPropTypes,
  isDiary: PropTypes.bool,
  disabled: PropTypes.bool,
  updateRecurringField: PropTypes.func,
}

export default contentConnect(ExpireContent)
