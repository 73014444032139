import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { hasScrollbar } from 'utils/misc'
import { Button, Popup, SortableTable } from 'components/UIElements'
import { ChevronCell } from './VirtualVisitDataCells'
import '../stylesheets/VisitTable.scss'

const headerOptions = [
  // { key: 'checkbox', sortable: false },
  { key: 'participantName', sortable: true, component: 'Participant Name' },
  { key: 'ptpId', sortable: true, component: 'Participant ID' },
  { key: 'subjectId', sortable: true, component: 'Subject ID' },
  { key: 'visitName', sortable: true, component: 'Visit Name' },
  { key: 'visitDay', sortable: true, component: 'Study Day' },
  { key: 'date', sortable: true, component: 'Scheduled Date', className: 'date' },
  { key: 'action', component: 'Action', className: 'visits-template-actions' },
]

const VisitSchedulesUpcomingTable = props => {
  const {
    disabled,
    offset,
    resetScheduleUpcomingExpandedVisits,
    searchTerm,
    setScheduleUpcomingCount,
    toggleScheduleUpcomingVisitExpand,
    fetchScheduleVisitUpcoming,
    studyID,
    siteID,
    visitSchedules,
    setDataBoxCount,
    selectedDataBox,
    hasPiiRole,
    studyLock,
    visitSchedulesUpcomingList = [],
    getVisitByPeriod,
    selectedVisitsPeriod,
  } = props
  const [summaryWrapperStyle, setSummaryWrapperStyle] = useState({})
  const [_hasScrollBar, setHasScrollBar] = useState(false)
  const vvHistoryTableRef = useRef(null)

  const { expandedScheduleUpcomingVisits = {}, visitSchedulesUpcomingInfo = {} } = visitSchedules

  useEffect(() => {
    resetScheduleUpcomingExpandedVisits()
  }, [])

  useEffect(() => {
    let list = []
    const currentList = vvHistoryTableRef?.current?.getList()
    if (currentList) {
      list = getVisitByPeriod(currentList, selectedVisitsPeriod)
    } else {
      list = visitSchedulesUpcomingList
    }
    const counts = list.reduce((acc, item) => {
      const status = item.filter(rowData => rowData.key === 'status').map(obj => obj.value)?.[0]
      if (status) {
        if (!acc[status]) acc[status] = 1
        else acc[status] += 1
      }
      return acc
    }, {})
    setScheduleUpcomingCount(list.length)
    setDataBoxCount(counts)
  }, [searchTerm, visitSchedulesUpcomingList])

  const handleResize = (isOnClick = false) => {
    // Need to check if argument is a boolean because on resize, the argument is an Event
    const _isOnClick = typeof isOnClick === 'boolean' && isOnClick
    setTimeout(
      () => {
        const tableRef = vvHistoryTableRef.current?.tableRef
        if (tableRef) {
          const summaryWrapperElements = tableRef?.getElementsByClassName('summary-wrapper')
          const _tableWidth = tableRef?.clientWidth
          const _chevronCellWidth = tableRef?.querySelector('thead tr th:first-child ').clientWidth
          for (let i = 0; i < summaryWrapperElements.length; i++) {
            summaryWrapperElements[i].style.width = `${_tableWidth - _chevronCellWidth - 3}px`
          }
          setSummaryWrapperStyle({
            width: `${_tableWidth - _chevronCellWidth - 3}px`,
          })
        }
      },
      _isOnClick ? 100 : 0,
    )
  }

  // This effect is to determine on initial mount and resize, the width of the expanded summary content dynamically
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    handleResize()
  }, [_hasScrollBar])

  const onRowClick = row => {
    const idData = row.find(el => el.key === 'checkbox')
    const { value: visitId } = idData
    const isExpanded = expandedScheduleUpcomingVisits[visitId]
    const visitInfo = visitSchedulesUpcomingInfo[visitId]

    toggleScheduleUpcomingVisitExpand(visitId)

    if (!isExpanded) {
      if (!visitInfo) fetchScheduleVisitUpcoming(studyID, siteID, visitId)
      setTimeout(() => {
        setHasScrollBar(hasScrollbar())
      }, 400) // we check if there a scrollbar on a delay to allow for the transition of the height to happen first
    }
  }

  const renderActionsCell = props => {
    if (studyLock) return null
    const { idx, row } = props
    const visitDayNotNumber = row.filter(rowData => rowData.key === 'visitDay' && typeof rowData.value !== 'number')
    const ptpID = row.filter(rowData => rowData.key === 'ptpId').map(ptpIDObj => ptpIDObj.value)
    const isAdhocVisit = visitDayNotNumber.length > 0
    return (
      <td key={`col_${idx}`}>
        <div className='flexed center-justified'>
          <Popup
            dark
            noPointer
            position='bottom'
            className='edit'
            disabled={disabled}
            trigger={<Button link icon='fas fa-pencil-alt' />}>
            <Button
              content={`Edit ${isAdhocVisit ? 'adhoc ' : 'participant '}visit`}
              onClick={() => {
                browserHistory.push(`/studies/${studyID}/visit-schedules/participant-visit/${ptpID}-${row.id}/edit`)
              }}
            />
          </Popup>
        </div>
      </td>
    )
  }

  const renderParticipantIdCell = ({ idx, value }) => {
    if (!hasPiiRole) return null
    return <td key={`col_${idx}`}>{value}</td>
  }

  //
  // Table Data Render Functions
  //
  const renderFunctions = {
    action: _props => renderActionsCell(_props),
    checkbox: _props => {
      const className = _props.row[7] && _props.row[7].key === 'status' ? `${_props.row[7].value}-request` : ''
      return <ChevronCell {..._props} className={className} />
    },
    ptpId: _props => renderParticipantIdCell(_props),
  }

  // headerOptions[0].component = <i className='fas fa-chevron-right' />

  return (
    <SortableTable
      ref={vvHistoryTableRef}
      className='visits-table'
      headerOptions={headerOptions.filter(item => {
        if (studyLock && item.key === 'action') return false
        if (item.key !== 'ptpId' || hasPiiRole) return true
        return false
      })}
      offset={offset}
      sortingBy='date'
      initialOrder='ascending'
      renderFunctions={renderFunctions}
      // rowExpandOptions={{
      //   isRowExpanded: row => {
      //     const idData = row.find(el => el.key === 'checkbox')
      //     const visitId = idData.value
      //     return expandedScheduleUpcomingVisits[visitId]
      //   },
      //   expandedRowClassName: 'expanded',
      // }}
      filters={{
        status: col => {
          if (!selectedDataBox) return true
          return col.value === selectedDataBox
        },
      }}
      rowList={visitSchedulesUpcomingList}
      searchKeys={['participantName', 'ptpId', 'subjectId', 'visitName', 'visitDay', 'date']}
      searchTerm={searchTerm}
      // onRowClick={onRowClick}
      emptyText='No Matching Schedule Upcoming Visits'
      viewMoreWithTablePages={{ minRows: 30, maxRows: 30 }}
    />
  )
}

ChevronCell.propType = {}

VisitSchedulesUpcomingTable.propTypes = {
  disabled: PropTypes.bool,
  fetchScheduleVisitUpcoming: PropTypes.func,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  offset: PropTypes.number,
  resetScheduleUpcomingExpandedVisits: PropTypes.func,
  searchTerm: PropTypes.string,
  setScheduleUpcomingCount: PropTypes.func,
  siteID: PropTypes.number,
  studyID: PropTypes.string,
  toggleScheduleUpcomingVisitExpand: PropTypes.func,
  visitSchedules: PropTypes.shape({
    expandedScheduleUpcomingVisits: PropTypes.object,
    visitSchedulesUpcomingInfo: PropTypes.objectOf(PropTypes.object),
    visitSchedulesUpcomingList: PropTypes.arrayOf(PropTypes.array),
  }),
  visitSchedulesUpcomingList: PropTypes.arrayOf(PropTypes.array),
  getVisitByPeriod: PropTypes.func,
  selectedVisitsPeriod: PropTypes.string,
}

export default VisitSchedulesUpcomingTable
