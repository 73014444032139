import { connect } from 'react-redux'

import DataCorrectionPage from '../components/DataCorrectionPage'
import { actions } from '../modules/DataCorrection'

const mapStateToProps = ({ dataCorrection, loading }) => {
  return {
    dataCorrection,
    loading: loading.general,
  }
}

const mapDispatchToProps = { ...actions }

export default connect(mapStateToProps, mapDispatchToProps)(DataCorrectionPage)
