import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { modalActions } from 'store/modal'
import STRINGS from 'utils/strings'
import { fetchParticipantVisits } from '../../../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import { getHeaderName } from '../../../../../utils/visitNameUtils'
import { participantVisitActions } from '../modules/ParticipantVisit'
import ParticipantVisitPage from '../components/ParticipantVisitPage'

const mapDispatchToProps = { ...participantVisitActions, fetchParticipantVisits, ...modalActions }

const getFilterPtpVisits = (ptpVisits, isUpdate, ssvpID) =>
  isUpdate ? ptpVisits.filter(visitArr => visitArr[0].visitId !== ssvpID) : ptpVisits

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps
  const { ptpID, ssvpID } = params
  const { loading, location, study, subroute, participantVisitReducer, participantReducer, studyLock } = state
  const { currentStudy } = study
  const path = location.pathname.match(/([^/]+$)/)[0]
  const isUpdate = path === 'edit'
  const studyID = currentStudy.id
  const { config = {} } = currentStudy
  const disableSubsequentVisitAdjustment = config.visit_support?.disable_subsequent_visit_adjustment
  return {
    disabled: studyLock,
    disableSubsequentVisitAdjustment,
    errors: participantVisitReducer.participantVisitErrors,
    subtitleName: getHeaderName(path, subroute),
    isLoading: loading.general,
    isUpdate,
    ptpID,
    ptpVisitsLoading: loading.participantVisits,
    participantVisitList: getFilterPtpVisits(participantReducer.participantVisits, isUpdate, ssvpID),
    participantVisitsArray: participantVisitReducer.participantVisits,
    ...participantVisitReducer,
    path,
    STRINGS,
    studyID,
    ssvpID,
    visitorParticipantLoader: loading.visitorParticipant,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <ParticipantVisitPage {...props} />
})
