import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Input } from 'components/UIElements'
import { MAX_INT, MIN_INT, DECIMAL_OPTIONS } from 'utils/constants'

export const SelectRange = ({ exampleComponent, description, children, outerDivClassName, innerDivClassName }) => {
  return (
    <div className={`select-range flexed space-between-justified ${outerDivClassName || ''}`}>
      <div className={`flexed ${innerDivClassName || ''}`}>
        {exampleComponent}
        <span>{description}</span>
      </div>
      {children}
    </div>
  )
}

const NumericInputRange = ({
  clearQuestionError,
  displayComponent,
  errors = {},
  isDecimal,
  item,
  itemId,
  preview,
  updateItem,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { input_validation } = item

  const example = <div className='example'>{displayComponent}</div>
  const decimalExample = <div className='example'>{`.${displayComponent}`}</div>
  const hasError = errors.numericInputRangeError
  const itemWithDefaults = {
    ...item,
    input_validation: {
      min: 0,
      max: 100,
      decimal_places: isDecimal ? 2 : '',
    },
  }

  const itemWithPrevInputValidation = { ...itemWithDefaults, input_validation: { ...input_validation } }

  if (input_validation && !input_validation.decimal_places) {
    itemWithPrevInputValidation.input_validation.decimal_places = ''
  }
  if (input_validation && !input_validation.min && input_validation.min !== 0) {
    itemWithPrevInputValidation.input_validation.min = ''
  }
  if (input_validation && !input_validation.max && input_validation.max !== 0) {
    itemWithPrevInputValidation.input_validation.max = ''
  }

  const resetToDefault = () => {
    updateItem(itemId, itemWithDefaults)
  }

  const updateInput = (key, val, isDropdown = false) => {
    let newValue = isDropdown ? val.key : val
    const prevInputValidation = itemWithPrevInputValidation.input_validation
    if (hasError) {
      clearQuestionError(itemId)
    }

    if (!isDecimal && !isDropdown) newValue = newValue === '' ? '' : Math.round(Number(newValue))

    const newItem = {
      ...item,
      input_validation: {
        ...prevInputValidation,
      },
    }
    if (key) {
      newItem.input_validation[key] = newValue === '' ? '' : Number(newValue)
    }
    updateItem(itemId, newItem)
  }

  useEffect(() => {
    if (item.input_validation && Object.keys(item.input_validation).length) {
      setIsExpanded(true)
    } else {
      setIsExpanded(false)
    }
  }, [item.input_validation])

  const resetInputValidation = () => {
    const newItem = {
      ...item,
      input_validation: {},
    }
    updateItem(itemId, newItem)
  }

  const inputProps = {
    disabled: preview,
    hasError,
    max: MAX_INT,
    min: MIN_INT,
    preventInput: val => val < MIN_INT || val > MAX_INT,
    type: 'number',
  }

  const { decimal_places, max, min } = itemWithPrevInputValidation.input_validation

  return (
    <div className='non-select-preview numeric-input-range'>
      <div className='flexed space-between-justified'>
        {!isExpanded ? example : <div />}
        {!preview && (
          <Button
            noStyling
            onClick={() => {
              if (isExpanded) {
                resetInputValidation()
                clearQuestionError(itemId)
              } else {
                resetToDefault()
              }
              setIsExpanded(!isExpanded)
            }}
            content={isExpanded ? 'Remove input range' : 'Set input range'}
          />
        )}
      </div>
      {isExpanded && (
        <>
          <SelectRange
            exampleComponent={example}
            description='Selectable Input Range'
            innerDivClassName={hasError ? 'with-error' : ''}>
            <div className='flexed column end-aligned'>
              <div className='flexed start-justified input-area'>
                <span>From</span>
                <Input
                  {...inputProps}
                  placeholder='0'
                  onChange={val => updateInput('min', val)}
                  value={min || min === 0 ? min : ''}
                />
                <span>to</span>
                <Input
                  {...inputProps}
                  placeholder='100'
                  onChange={val => updateInput('max', val)}
                  value={max || max === 0 ? max : ''}
                />
              </div>
              {hasError && <p className='error'>{hasError}</p>}
            </div>
          </SelectRange>
          {isDecimal && (
            <SelectRange outerDivClassName='decimal' exampleComponent={decimalExample} description='Decimal Places'>
              <div className='flexed start-justified input-area'>
                <span>0.</span>
                <Dropdown
                  disabled={preview}
                  selected={String(decimal_places)}
                  options={DECIMAL_OPTIONS}
                  onSelect={val => updateInput('decimal_places', val, true)}
                />
              </div>
            </SelectRange>
          )}
        </>
      )}
    </div>
  )
}

NumericInputRange.propTypes = {
  clearQuestionError: PropTypes.func,
  displayComponent: PropTypes.string,
  errors: PropTypes.object,
  isDecimal: PropTypes.bool,
  item: PropTypes.object,
  itemId: PropTypes.string,
  preview: PropTypes.bool,
  updateItem: PropTypes.func,
}

SelectRange.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  exampleComponent: PropTypes.node,
  innerDivClassName: PropTypes.string,
  outerDivClassName: PropTypes.string,
}

export default NumericInputRange
