import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Radio, DatePicker, Input, TimePicker, WarningText, Checkbox } from 'components/UIElements'
import { pluralize, handleNaN } from 'utils/misc'
import { currentDayStartInUTC, toUTCMidnightSeconds, fromUTCMidnightSeconds } from 'utils/time'
import moment from 'moment'
import { _getDefaultAnnouncement } from '../modules/Announcement'

const _defaultAbsSchedule = () => ({
  absolute: Math.round(currentDayStartInUTC().valueOf() / 1000),
  time_of_day: '09:00',
  new_participants: false,
})

const _defaultRelSchedule = () => ({
  first_login: { interval: 1, interval_type: 'days' },
  time_of_day: '09:00',
  new_participants: true,
})

const _defaultNDays = () => ({ scheme: 'n-days', interval: 1, interval_type: 'days', visible_days: 1 })

const getTimeOfDayField = (deploy, updateAnnouncementSchedule, disabled) => {
  return (
    <div className='time'>
      <span>At</span>
      <TimePicker
        id='sms-sent-time'
        disabled={disabled}
        format='HH:mm'
        value={moment(deploy.time_of_day, 'HH:mm')}
        onChange={mom => updateAnnouncementSchedule('deploy', { ...deploy, time_of_day: mom.format('HH:mm') })}
      />
    </div>
  )
}

const getDeployFields = (deploy, updateAnnouncementSchedule, errors, isSMS, disabled) => {
  const [showDateError, setShowDateError] = useState(true)

  useEffect(() => {
    setShowDateError(true)
  }, [errors])

  const selected = {
    1: Object.prototype.hasOwnProperty.call(deploy, 'now'),
    2: Object.prototype.hasOwnProperty.call(deploy, 'absolute'),
    3: Object.prototype.hasOwnProperty.call(deploy, 'first_login'),
  }
  const AbsoluteAnnouncement = () => {
    return (
      <div className='new-participant-checkbox'>
        {!deploy.new_participants && (
          <p>
            <i className='fas fa-exclamation-triangle' />
            Participants not enrolled yet will not receive this
          </p>
        )}
        <Checkbox
          id='deliver-upon-first-login'
          disabled={disabled}
          checked={deploy.new_participants}
          label={`Deliver upon first login to participants who
          ${selected[1] ? 'enroll after today' : 'enroll after this date'}`}
          onClick={() =>
            updateAnnouncementSchedule('deploy', { ...deploy, new_participants: !deploy.new_participants })
          }
        />
      </div>
    )
  }

  const hasDateError = !!errors['schedule.deploy.absolute']

  const onChangeSelected3 = num =>
    updateAnnouncementSchedule(
      'deploy',
      { ...deploy, first_login: { interval: Number(num), interval_type: 'days' } },
      'schedule.deploy.first_login.interval',
    )

  return (
    <div>
      <p>{`1. When would you like this ${isSMS ? 'SMS message' : 'Announcement'} to be sent?`}</p>
      <Radio
        id='send-now-radio'
        disabled={disabled}
        selected={selected[1]}
        onClick={() => updateAnnouncementSchedule('deploy', _getDefaultAnnouncement().schedule.deploy)}
        content='Send Now'
      />
      {selected[1] && (
        <div className='extra-radio-content'>
          <AbsoluteAnnouncement />
        </div>
      )}
      <Radio
        id='exact-date-radio'
        disabled={disabled}
        selected={selected[2]}
        onClick={() => updateAnnouncementSchedule('deploy', _defaultAbsSchedule())}
        content='Exact Date'
      />
      {selected[2] && (
        <div className='extra-radio-content'>
          <DatePicker
            id='exact-date-input'
            disabledDays={{ before: new Date() }}
            disabled={disabled}
            initialDate={fromUTCMidnightSeconds(deploy.absolute)}
            onDayChange={date => {
              if (hasDateError) setShowDateError(false)
              updateAnnouncementSchedule('deploy', {
                ...deploy,
                absolute: date ? toUTCMidnightSeconds(date) : 'INVALID',
              })
            }}
            hasError={showDateError && hasDateError}
          />
          {showDateError && hasDateError && <WarningText text={errors['schedule.deploy.absolute']} />}
          <AbsoluteAnnouncement />
        </div>
      )}
      <Radio
        id='relative-to-first-login-radio'
        disabled={disabled}
        selected={selected[3]}
        onClick={() => updateAnnouncementSchedule('deploy', _defaultRelSchedule())}
        content='Relative To First Login'
      />
      {selected[3] && (
        <div className='extra-radio-content flexed start-justified'>
          <Input
            id='day-after-first-login'
            inline
            margin='0px 10px 0px 0px'
            className='number-input'
            type='number'
            onlyInteger
            isPositive
            onBlur={e => handleNaN(e)(() => onChangeSelected3(1))}
            disabled={disabled}
            onChange={num => onChangeSelected3(num)}
            value={deploy.first_login.interval || ''}
          />
          {` ${pluralize(deploy.first_login.interval, 'day', 'days', false)} after participants first log in`}
          <WarningText text={errors['schedule.deploy.first_login.interval']} />
        </div>
      )}
      {(selected[2] || selected[3]) && (
        <div>
          <p>{`2. What time should this ${isSMS ? 'SMS message' : 'Announcement'} be sent?`}</p>
          {getTimeOfDayField(deploy, updateAnnouncementSchedule, disabled)}
        </div>
      )}
    </div>
  )
}

const getRecurringFields = (deploy, recurring, updateAnnouncementSchedule, errors, isSMS, disabled) => {
  const selected = {
    1: recurring === undefined,
    2: recurring && recurring.scheme === 'n-days',
    3: recurring && recurring.scheme === 'persistent',
  }
  const isSecondQuestion = Object.prototype.hasOwnProperty.call(deploy, 'now')
  const questionNumber = isSecondQuestion ? '2' : '3'
  const onChangeSelected2 = newNum =>
    updateAnnouncementSchedule(
      'recurring',
      { ...recurring, interval: newNum, visible_days: newNum },
      'schedule.recurring.interval',
    )
  return (
    <div>
      <p>{`${questionNumber}. Should this ${isSMS ? 'SMS message' : 'Announcement'} repeat?`}</p>
      <Radio
        id='no-repeat-radio'
        disabled={disabled}
        selected={selected[1]}
        onClick={() => updateAnnouncementSchedule('recurring', null)}
        content="Doesn't Repeat"
      />
      <Radio
        id='repeat-radio'
        disabled={disabled}
        selected={selected[2]}
        onClick={() => updateAnnouncementSchedule('recurring', _defaultNDays())}
        content={
          <div className='flexed'>
            <span>Repeat every</span>
            <Input
              id='repeat-day-input'
              inline
              margin='0px 10px'
              className='number-input flank-margin'
              type='number'
              onlyInteger
              isPositive
              disabled={disabled}
              onBlur={e => handleNaN(e)(() => onChangeSelected2(1))}
              onChange={num => onChangeSelected2(num)}
              value={recurring ? recurring.interval : 1}
            />
            <span>{pluralize(recurring ? recurring.interval : 1, 'day', 'days', false)}</span>
            <WarningText text={errors['schedule.recurring.interval']} />
          </div>
        }
      />
    </div>
  )
}

const DeliverySection = ({ schedule, updateAnnouncementSchedule, errors, isSMS, disabled }) => {
  return (
    <Container className='delivery-section'>
      {getDeployFields(schedule.deploy, updateAnnouncementSchedule, errors, isSMS, disabled)}
      {getRecurringFields(schedule.deploy, schedule.recurring, updateAnnouncementSchedule, errors, isSMS, disabled)}
    </Container>
  )
}

DeliverySection.propTypes = {
  schedule: PropTypes.object,
  updateAnnouncementSchedule: PropTypes.func,
  isSMS: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.object,
}

export default DeliverySection
