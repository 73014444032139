import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import ConsentProperties from '../components/ConsentProperties'
import { consentActions as consentActionsDashboard } from '../../../../ConsentsDashboard/modules/Consents'
import { consentActions, duplicateItem } from '../../NewConsentBuilder/modules/NewConsent'
import { instrumentActions } from '../../../../../../Instruments/routes/Instrument/modules/Instrument'

const mapDispatchToProps = { duplicateItem, ...consentActions, ...instrumentActions, ...consentActionsDashboard }

const mapStateToProps = state => {
  const { location, consents, newConsentReducer } = state
  const path = location.pathname.match(/([^/]+$)/)[0]
  const { study } = state
  const { currentStudy, locales, localesMap } = study
  return {
    consentList: consents?.consentList,
    consent: newConsentReducer.consent,
    editorStates: newConsentReducer.editorStates,
    isLoading: state.loading.general,
    enforcedLanguages: currentStudy?.config?.enforced_language?.languages,
    locales,
    localesMap,
    questionNumbers: newConsentReducer.consent.questionNumbers,
    wordCount: newConsentReducer.wordCount,
    path,
    studyID: study.currentStudy.id,
    errors: newConsentReducer.consentErrors,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <ConsentProperties {...props} />
})
