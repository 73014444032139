import React from 'react'
import { STUDY_SORTING_OPTIONS } from 'utils/constants'
import StudySortingOption from './StudySortingOption'
import STRINGS from 'utils/strings'

const StudySorting = ({ sortingOption, updateAscendingSortingOrder, ascendingSortingOrder, updateSortingOption }) => {
  return (
    <div className='sorting-box flexed center-aligned'>
      <div className='sorting-title'>{STRINGS.sortBy}</div>
      <StudySortingOption
        sortingOption={sortingOption}
        updateAscendingSortingOrder={updateAscendingSortingOrder}
        ascendingSortingOrder={ascendingSortingOrder}
        updateSortingOption={updateSortingOption}
        sortingText={STRINGS.studyCreateDate}
        selectedSortingOption={STUDY_SORTING_OPTIONS.studyCreateDate}
      />
      <StudySortingOption
        sortingOption={sortingOption}
        updateAscendingSortingOrder={updateAscendingSortingOrder}
        ascendingSortingOrder={ascendingSortingOrder}
        updateSortingOption={updateSortingOption}
        sortingText={STRINGS.studyName}
        selectedSortingOption={STUDY_SORTING_OPTIONS.studyName}
      />
    </div>
  )
}

export default StudySorting
