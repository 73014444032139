import React from 'react'
import { connect } from 'react-redux'
import { overlayActions } from 'store/overlay'
import { getUserScopeByStudy } from 'utils/misc'
import TrainingPage from '../components/TrainingPage'
import { actions } from '../modules/Training'

const mapStateToProps = state => {
  const { loading, searchTerm, study, trainingMaterial, user } = state
  const { currentStudy } = study

  return {
    canWrite: getUserScopeByStudy(user, currentStudy).includes('ft'),
    loading: loading.general,
    searchTerm,
    studyId: currentStudy.id,
    trainingMaterial,
  }
}

const mapDispatchToProps = { ...actions, ...overlayActions }

export default connect(mapStateToProps, mapDispatchToProps)(props => <TrainingPage {...props} />)
