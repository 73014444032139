import React from 'react'
import PropTypes from 'prop-types'
// import AtomicBlockUtils, EditorState if we need to insert images into Editor
import { EditorState, Modifier, KeyBindingUtil, RichUtils, getDefaultKeyBinding } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createImagePlugin from 'draft-js-image-plugin'
import { Button, Input, Popup } from 'components/UIElements'
import { blockTypeIcon, waveForm } from 'assets/assets'
import { MEDIA_TYPE_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import 'styles/d3/richtexteditor.scss'
import { decorator, getLinkInfo, getSelectedBlockElement, removeLink } from 'utils/draft'
import MediaUpload from '../containers/mediaUploadContainer'

const BLOCK_TYPES = [
  { label: 'Regular', style: 'unstyled' },
  { label: 'Heading 1', style: 'header-one' },
  { label: 'Heading 2', style: 'header-two' },
  { label: 'Heading 3', style: 'header-three' },
  // { label: 'H4', style: 'header-four' },
  // { label: 'H5', style: 'header-five' },
  // { label: 'H6', style: 'header-six' },
  // { label: 'Blockquote', style: 'blockquote' },
  // { label: 'UL', style: 'unordered-list-item' },
  // { label: 'OL', style: 'ordered-list-item' },
  // { label: 'Code Block', style: 'code-block' },
]

const ALIGNMENT_TYPES = ['align-left', 'align-center']

const STYLE_TYPES = {
  bold: 'BOLD',
  italic: 'ITALIC',
  underline: 'UNDERLINE',
}

const NO_URL_TEXT = 'No URL'
const URL_TEXT_PLACEHOLDER = 'Link text'
const URL_LINK_PLACEHOLDER = 'Which URL should the text refer to?'
const URL_LINK_HELPER_TEXT = 'Include http:// or https://'

const CHECK_YOUR_LINKS = 'Check your links'
const LINK_CHECK_REMINDER = 'They cannot be revised after deployment'

const imagePlugin = createImagePlugin()

const myNoStylingKeyBindingFunc = e => {
  const { hasCommandModifier } = KeyBindingUtil
  if (e.keyCode === 66 /* `B` key */ && hasCommandModifier(e)) {
    return STYLE_TYPES.bold
  }
  if (e.keyCode === 73 /* `I` key */ && hasCommandModifier(e)) {
    return STYLE_TYPES.italic
  }
  if (e.keyCode === 85 /* `U` key */ && hasCommandModifier(e)) {
    return STYLE_TYPES.underline
  }
  return getDefaultKeyBinding(e)
}

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editorHasFocus: false,
      image: this.props.item ? this.props.item.image : null,
      audio: this.props.item ? this.props.item.resources?.audio : null,
      video: this.props.item ? this.props.item.resources?.video : null,
      mediaSrc: this.props.item ? this.props.item.mediaSrc : null,
      src: this.props.item ? this.props.item.imageSrc : null,
      showURLInput: false,
      showURLOptions: false,
      urlValue: '',
      urlText: '',
      currentLinkRange: null,
      urlMenuPosition: { x: 0, y: 0 },
      prevLinkKey: null,
    }
    this.blockStyleControls = this.blockStyleControls.bind(this)
  }

  componentDidMount() {
    this.listener = e => {
      const clickedOutsideOfEditorComponent = !this.editorParentRef?.contains(e.target)
      if (clickedOutsideOfEditorComponent) {
        this.setState({ showURLInput: false, showURLOptions: false })
      }
    }
    document.addEventListener('mousedown', this.listener)
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (prevProps.item?.image && !item?.image) {
      this.setState({ image: null })
    }
    if (prevProps.item?.resources && !item?.resources) {
      this.setState({ audio: null, video: null })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.listener)
  }

  blockStyleControls = () => {
    const { editorState } = this.props
    const selection = editorState.getSelection()
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType()

    return (
      <Popup dark noPointer useOnMousedown position='bottom' trigger={<button type='button'>{blockTypeIcon}</button>}>
        {BLOCK_TYPES.map(type => {
          const isActive = blockType === type.style
          return (
            <button
              className={`${isActive ? 'active' : ''} ${type.style} flexed`}
              key={type.label}
              onMouseDown={this.onToggleBlockType(type.style)}
              type='button'>
              {type.label}
              {isActive && <i className='fas fa-check' />}
            </button>
          )
        })}
      </Popup>
    )
  }

  alignStyleControls = () => {
    const { editorState } = this.props
    const selection = editorState.getSelection()
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType()

    return ALIGNMENT_TYPES.map(type => {
      const unstyledAndLeftAligned = blockType === 'unstyled' && type === 'align-left'
      const isActive = blockType === type || unstyledAndLeftAligned
      return (
        <button
          className={`${isActive ? 'active' : ''} ${type} flexed fa-solid fa-${type}`}
          key={type.label}
          // onMouseDown={this.onToggleAlignmentType(type)}
          onMouseDown={this.onToggleBlockType(type)}
          type='button'
        />
      )
    })
  }

  richTextControls = (currentStyle, blockType) => {
    const { isChoice } = this.props
    return (
      <>
        {!isChoice && (
          <>
            <button
              tabIndex={-1}
              id='text-ol'
              type='button'
              arial-label='ol-block'
              className={blockType === 'ordered-list-item' ? 'fas fa-list-ol active' : 'fas fa-list-ol'}
              onMouseDown={this.onToggleBlockType('ordered-list-item')}
            />
            <button
              tabIndex={-1}
              id='text-ul'
              type='button'
              arial-label='ul-block'
              className={blockType === 'unordered-list-item' ? 'fas fa-list-ul active' : 'fas fa-list-ul'}
              onMouseDown={this.onToggleBlockType('unordered-list-item')}
            />
          </>
        )}
        <button
          tabIndex={-1}
          id='text-bold'
          type='button'
          arial-label='bold-block'
          className={currentStyle.has(STYLE_TYPES.bold) ? 'fas fa-bold active' : 'fas fa-bold'}
          onMouseDown={this.onToggleInlineStyle(STYLE_TYPES.bold)}
        />
        <button
          tabIndex={-1}
          id='text-italic'
          type='button'
          arial-label='italic-block'
          className={currentStyle.has(STYLE_TYPES.italic) ? 'fas fa-italic active' : 'fas fa-italic'}
          onMouseDown={this.onToggleInlineStyle(STYLE_TYPES.italic)}
        />
        <button
          tabIndex={-1}
          id='text-underline'
          type='button'
          arial-label='underline-block'
          className={currentStyle.has(STYLE_TYPES.underline) ? 'fas fa-underline active' : 'fas fa-underline'}
          onMouseDown={this.onToggleInlineStyle(STYLE_TYPES.underline)}
        />
      </>
    )
  }

  mediaUploadButtons = (isEditorMultiLine = false) => {
    const { canAddImage, canAddAudio, canAddVideo } = this.props
    const { editorHasFocus } = this.state
    return (
      <div
        className={`media-upload-button-list flexed end-justified${editorHasFocus ? '' : ' none'}${
          isEditorMultiLine ? ' multi-line' : ''
        }`}>
        {canAddImage && (
          <button onMouseDown={() => this.toggleMediaUploader()} className='clickable' type='button'>
            <i className='far fa-image' />
            <div className='helper-popup'>Upload Image</div>
          </button>
        )}
        {canAddAudio && (
          <button
            onMouseDown={() => this.toggleMediaUploader(MEDIA_TYPE_MAP.audio)}
            className='clickable'
            type='button'>
            {waveForm}
            <div className='helper-popup'>Upload Audio</div>
          </button>
        )}
        {canAddVideo && (
          <button
            onMouseDown={() => this.toggleMediaUploader(MEDIA_TYPE_MAP.video)}
            className='clickable'
            type='button'>
            <i className='fas fa-video' />
            <div className='helper-popup'>Upload Video</div>
          </button>
        )}
      </div>
    )
  }

  onToggleInlineStyle = style => {
    const { onChange, editorState } = this.props
    return e => {
      e.preventDefault()
      onChange(RichUtils.toggleInlineStyle(editorState, style))
    }
  }

  onToggleBlockType = style => {
    const { onChange, editorState } = this.props
    return e => {
      e.preventDefault()
      e.stopPropagation()
      onChange(RichUtils.toggleBlockType(editorState, style))
    }
  }

  myBlockStyleFn = contentBlock => {
    const type = contentBlock.getType()
    return type
  }

  onKeyCommand = (command, editorState) => {
    const { charLimit, noStyling, onChange } = this.props

    if (charLimit) {
      if (command === 'split-block') {
        const currentContentLength = this._getCurrentContentLength()
        const selectedTextLength = this._getLengthOfSelectedText()
        if (charLimit && currentContentLength - selectedTextLength > charLimit - 1) {
          return 'handled'
        }
      }
    }

    const newEditorState = RichUtils.handleKeyCommand(editorState, command)
    if (newEditorState) {
      if (
        noStyling &&
        (command === STYLE_TYPES.bold || command === STYLE_TYPES.italic || command === STYLE_TYPES.underline)
      ) {
        return 'handled'
      }
      onChange(newEditorState)
    }
  }

  toggleMediaUploader = (mediaType = MEDIA_TYPE_MAP.image) => {
    const { openModal, closeModal, canAddAudio, canAddImage, canAddVideo, currentResourcesSizeCount } = this.props
    const allowedMediaTypeArr = []
    if (canAddImage) allowedMediaTypeArr.push(MEDIA_TYPE_MAP.image)
    if (canAddAudio) allowedMediaTypeArr.push(MEDIA_TYPE_MAP.audio)
    if (canAddVideo) allowedMediaTypeArr.push(MEDIA_TYPE_MAP.video)

    const itemCallbacks = {
      audio: this.insertMedia,
      image: this.insertImage,
      video: mediaObj => this.insertMedia(mediaObj, true),
    }

    openModal({
      content: (
        <MediaUpload
          {...this.props}
          itemCallbacks={itemCallbacks}
          onClose={closeModal}
          mediaTypes={allowedMediaTypeArr}
          initTab={mediaType}
          currentResourcesSizeCount={currentResourcesSizeCount}
        />
      ),
      noButtons: true,
      className: MODAL_CLASSES_MAP.uploadMedia,
      closeOnBackgroundClick: false,
    })
  }

  insertImage = imageObj => {
    const { imageId, size, src } = imageObj
    const { closeModal, itemId, addItemImage } = this.props // add onChange to destructuring if we want native editor insertion
    this.setState({ image: imageId })
    this.setState({ src })
    addItemImage({ itemId, imageId, imageSrc: src, size })
    closeModal()

    // commented out for future if we want to allow for more than one photo natively inside the editor

    // const contentState = editorState.getCurrentContent()
    // const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', { src })
    // const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    // const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
    // const newState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
    // onChange(newState)
  }

  insertMedia = (mediaObj, isVideo = false) => {
    const { fileId, size, src } = mediaObj
    const { closeModal, itemId, addItemMedia } = this.props // add onChange to destructuring if we want native editor insertion
    if (isVideo) {
      this.setState({ video: fileId })
    } else {
      this.setState({ audio: fileId })
    }
    this.setState({ mediaSrc: src })
    addItemMedia({ itemId, fileId, fileSrc: src, size, isVideo })
    closeModal()
  }

  onRemoveImage = e => {
    const { deleteItemImage, itemId } = this.props
    const { image } = this.state
    e.stopPropagation()
    deleteItemImage(itemId, image)
    this.setState({ image: null })
  }

  onRemoveMedia = (e, isVideo) => {
    const { deleteItemMedia, itemId } = this.props
    const { audio, video } = this.state
    e.stopPropagation()
    deleteItemMedia(itemId, isVideo ? video : audio)
    this.setState({ audio: null, video: null })
  }

  onFocus = () => {
    const { onFocus } = this.props
    if (onFocus) onFocus()
  }

  // Functions related to links
  setLinkMenuPosition = e => {
    const { readOnly } = this.props
    // Set position of the link menu relative to editor wrapper element
    const { x: parentX, y: parentY } = this.editorWrapper.getBoundingClientRect()
    const currentSelection = window.getSelection()

    if (!readOnly && (currentSelection || currentSelection.rangeCount)) {
      const alternateSelection = getSelectedBlockElement()
      const alternateSelectionClientPostion = alternateSelection?.getBoundingClientRect()
      const noSelectionType = currentSelection.type === 'None'
      const selectionClientPosition = noSelectionType ? null : currentSelection?.getRangeAt(0)?.getBoundingClientRect()

      const _x = selectionClientPosition?.x || alternateSelectionClientPostion?.x
      const _y = selectionClientPosition?.y || alternateSelectionClientPostion?.y

      this.setState({
        urlMenuPosition: { x: _x - parentX, y: _y - parentY },
      })
    } else if (readOnly) {
      const linkPosition = e.target.getBoundingClientRect()

      this.setState({
        urlMenuPosition: { x: linkPosition.x - parentX, y: linkPosition.y - parentY },
      })
    }
  }

  promptForLink = (e, isToolbarClick = false) => {
    e.stopPropagation()
    const { editorState, readOnly } = this.props

    // If editor is readOnly, just open the link
    if (readOnly) return

    e.preventDefault()

    this.setState({ showURLInput: false, showURLOptions: false })
    const { prevLinkKey } = this.state

    const { linkKey, linkRange, linkText } = getLinkInfo(e, editorState)
    const contentState = editorState.getCurrentContent()

    let url = ''

    const hasDifferentLink = !!(linkKey && linkKey !== prevLinkKey)
    const noCurrentOrPrevLink = !linkKey && !prevLinkKey

    if (hasDifferentLink || noCurrentOrPrevLink) {
      this.setLinkMenuPosition(e)
    }

    this.setState({ prevLinkKey: linkKey })

    if (isToolbarClick) {
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
        this.setState({ showURLOptions: true })
      } else {
        this.setState({ showURLInput: true })
      }
    } else if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey)
      url = linkInstance.getData().url
      this.setState({ showURLOptions: true })
    }
    /**
     * If the current selection (read: highlighted text) in the text editor is not a link,
     * we will reset the currentLinkRange. This needs to be done so as to not replace the
     * previously set link with the new link that currently being created.
     */
    this.setState({ currentLinkRange: linkRange || null })

    this.setState(
      {
        urlValue: url,
        urlText: linkText,
      },
      () => {
        setTimeout(() => {
          if (!linkKey) this.focusIntoURLTextField()
        }, 0)
      },
    )
  }

  confirmLink = e => {
    e.preventDefault()
    const { onChange, editorState } = this.props
    const { urlValue, urlText, currentLinkRange } = this.state
    const contentState = editorState.getCurrentContent()

    let selection = editorState.getSelection()

    /**
     * If the current selection is a link already, we will use the current range
     * staged in the local state from clicking on a current link, otherwise
     * we will use the text selection (what is highlighted in the editor)
     */
    if (currentLinkRange) {
      const { start, end } = currentLinkRange
      selection = selection.merge({
        anchorOffset: start,
        focusOffset: end,
      })
    }

    // Replace link text
    const replacementText = urlText || urlValue
    const updatedEditorWithText = Modifier.replaceText(contentState, selection, replacementText)
    const editorStateWithLinkText = EditorState.push(editorState, updatedEditorWithText, 'update-link-text')

    /**
     * After replacing the link text, we need to re-set the selection state to match the
     * length of the next text when we toggle the link for the text in the text editor
     */
    const offset1 = selection.getStartOffset()
    const offset2 = selection.getEndOffset()
    const startOffset = offset1 >= offset2 ? offset2 : offset1
    const isBackward = selection.getIsBackward()

    const newStringLengthOffset = urlText ? urlText.length : urlValue.length

    /**
     * Depending on whether the anchor (re: beginning of text selection) and focus (end of text selection)
     * started from the right to left (isBackward) or left to right, we adjust the selection accordingly
     * to accomodate the new URL text that will be inserted.
     */
    selection = selection.merge({
      anchorOffset: isBackward ? startOffset + newStringLengthOffset : startOffset,
      focusOffset: isBackward ? startOffset : startOffset + newStringLengthOffset,
    })

    const newContentState = editorStateWithLinkText.getCurrentContent()

    // Adding the link entity
    const contentStateWithEntity = newContentState.createEntity('LINK', 'MUTABLE', {
      url: urlValue,
      target: '_blank',
      rel: 'noopener noreferrer',
    })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selection, entityKey)

    const editorStateWithEntity = EditorState.set(editorStateWithLinkText, {
      currentContent: contentStateWithLink,
      decorator,
    })

    // Connect the selection (highlight texted) with the link entity that was created
    const resultEditorState = RichUtils.toggleLink(editorStateWithEntity, selection, entityKey)

    onChange(resultEditorState)

    this.setState({
      currentLinkRange: null,
      showURLInput: false,
      urlValue: '',
      urlText: '',
    })
  }

  onLinkInputKeyDown = e => {
    this.confirmLink(e)
  }

  onURLChange = val => this.setState({ urlValue: val })

  onLinkTextChange = val => this.setState({ urlText: val })

  renderLinkMenu = () => {
    const { readOnly } = this.props
    const { showURLInput, showURLOptions, urlValue, urlText, urlMenuPosition } = this.state

    const urlInput = (
      <div className='link-editor'>
        <div className='flexed'>
          <label htmlFor='link-text-input'>Link text</label>
          <Input
            disabled={readOnly}
            id='link-text-input'
            onChange={this.onLinkTextChange}
            placeholder={URL_TEXT_PLACEHOLDER}
            ref={el => {
              this.urlTextField = el
            }}
            type='text'
            value={urlText}
            onEnterPress={this.onLinkInputKeyDown}
          />
        </div>
        <div className='flexed'>
          <label htmlFor='link-val-input'>URL address</label>
          <Input
            disabled={readOnly}
            id='link-val-input'
            onChange={this.onURLChange}
            placeholder={URL_LINK_PLACEHOLDER}
            ref={el => {
              this.url = el
            }}
            type='text'
            value={urlValue}
            onEnterPress={this.onLinkInputKeyDown}
          />
        </div>
        <div className='flexed'>
          <span>{URL_LINK_HELPER_TEXT}</span>
        </div>
      </div>
    )

    const urlOptions = (
      <div className={`link-options flexed column${showURLOptions ? '' : ' hidden'}`}>
        {urlValue ? (
          <a href={urlValue} rel='noopener noreferrer' target='_blank' className='word-clamp'>
            {urlValue}
          </a>
        ) : (
          <div className='word-clamp'>{NO_URL_TEXT}</div>
        )}
        {!readOnly && (
          <>
            <button
              disabled={readOnly}
              type='button'
              id='edit-link'
              onMouseDown={() => {
                this.setState({ showURLInput: true, showURLOptions: false }, () => {
                  setTimeout(() => {
                    this.focusIntoURLTextField()
                  }, 0)
                })
              }}>
              Edit link
            </button>
            {this.renderRemoveLinkButton()}
          </>
        )}
        <div className='link-check-message'>
          <i className='fas fa-info-circle' />
          <span className='check-your-links'>{CHECK_YOUR_LINKS}</span>
          <span className='warning-message'>{LINK_CHECK_REMINDER}</span>
        </div>
      </div>
    )

    return (
      <div
        ref={el => {
          this.URLMenuRef = el
        }}
        className='link-menu'
        style={{
          left: `${urlMenuPosition.x}px`,
          top: `${urlMenuPosition.y + 24}px`,
        }}>
        {urlOptions}
        {showURLInput && urlInput}
      </div>
    )
  }

  renderRemoveLinkButton = () => {
    const { editorState, onChange, readOnly } = this.props
    return (
      <button
        id='remove-link'
        disabled={readOnly}
        type='button'
        onMouseDown={e => {
          removeLink(e, editorState, onChange)
          this.setState({ showURLInput: false, showURLOptions: false, currentLinkRange: null })
        }}>
        Remove
      </button>
    )
  }

  renderLinkButton = () => {
    return (
      <button
        tabIndex={-1}
        id='text-link'
        type='button'
        className='fas fa-link'
        onMouseDown={e => this.promptForLink(e, true)}
      />
    )
  }

  focusIntoURLTextField = () => {
    this.urlTextField?.input.focus()
  }

  /**
   * The following class functions enforces character limits when the user types
   * of pastes text content if there is a @charLimit prop passed to this component.
   */

  _getLengthOfSelectedText = () => {
    const { editorState = EditorState.createEmpty() } = this.props
    const currentSelection = editorState.getSelection()
    const isCollapsed = currentSelection.isCollapsed()

    let length = 0

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent()
      const startKey = currentSelection.getStartKey()
      const endKey = currentSelection.getEndKey()
      const startBlock = currentContent.getBlockForKey(startKey)
      const isStartAndEndBlockAreTheSame = startKey === endKey
      const startBlockTextLength = startBlock.getLength()
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset()
      const endSelectedTextLength = currentSelection.getEndOffset()
      const keyAfterEnd = currentContent.getKeyAfter(endKey)

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset()
      } else {
        let currentKey = startKey

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1
          } else if (currentKey === endKey) {
            length += endSelectedTextLength
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1
          }

          currentKey = currentContent.getKeyAfter(currentKey)
        }
      }
    }

    return length
  }

  _getCurrentContentLength = () => {
    const { editorState = EditorState.createEmpty() } = this.props
    const currentContent = editorState.getCurrentContent()
    return currentContent.getPlainText('').length
  }

  _handleBeforeInput = () => {
    const { charLimit } = this.props
    const currentContentLength = this._getCurrentContentLength()
    const selectedTextLength = this._getLengthOfSelectedText()
    if (charLimit && currentContentLength - selectedTextLength > charLimit - 1) {
      return 'handled'
    }
  }

  _removeSelection = () => {
    const { onChange, editorState = EditorState.createEmpty() } = this.props
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const endKey = selection.getEndKey()
    const endOffset = selection.getEndOffset()
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward')
      const tempEditorState = EditorState.push(editorState, newContent, 'remove-range')
      onChange(tempEditorState)
      return tempEditorState
    }
    return editorState
  }

  _addPastedContent = (input, editorState) => {
    const { onChange, charLimit } = this.props
    const inputLength = this._getCurrentContentLength()
    const remainingLength = charLimit - inputLength

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      input.slice(0, remainingLength),
    )
    onChange(EditorState.push(editorState, newContent, 'insert-characters'))
  }

  _handlePastedText = pastedText => {
    const { charLimit, editorState = EditorState.createEmpty() } = this.props
    const currentContentLength = this._getCurrentContentLength()
    const selectedTextLength = this._getLengthOfSelectedText()

    if (charLimit && currentContentLength + pastedText.length - selectedTextLength > charLimit) {
      const selection = editorState.getSelection()
      const isCollapsed = selection.isCollapsed()
      const tempEditorState = !isCollapsed ? this._removeSelection() : editorState
      this._addPastedContent(pastedText, tempEditorState)
      return 'handled'
    }
    return 'not-handled'
  }

  render() {
    const {
      canAddAudio,
      canAddImage,
      canAddVideo,
      charLimit,
      className = '',
      editorState = EditorState.createEmpty(),
      hasError,
      hasAlignmentControls,
      hasBlockStyles,
      hasLinks, // this prop can be set as true to activate link formatting and adding links UX
      noStyling,
      onBlur,
      onChange,
      placeholder,
      readOnly,
      showToolbar = true,
      spellCheck,
      stripPastedStyles,
    } = this.props

    const { editorHasFocus, image, src, audio, mediaSrc, video } = this.state
    const editorProps = { editorState, placeholder, onChange, spellCheck, readOnly }
    const currentStyle = editorState.getCurrentInlineStyle()
    const selection = editorState.getSelection()
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType()

    /**
     * These variables below are used to determine when to render the media upload button list in
     * a "new line". We say "new line" in quotes because it actually setting the media
     * bar is position absolute and a lower bottom is set when we logically deduce that
     * there are multiple lines based on the content of the editor state, or whether the
     * first line has reached a maximum of certain length (the threshold would be before
     * the text reaches the media bar).
     */

    const widthOfLastBlock = () => {
      const editorSpans = this.draftEditor?.getEditorRef()?.editor?.getElementsByTagName('span')
      const numberOfSpans = editorSpans?.length
      const widthOfLastSpan = editorSpans && numberOfSpans ? editorSpans[numberOfSpans - 1].offsetWidth : 0
      // the last span in the drafteditor is the last line of text
      return widthOfLastSpan
    }

    const editorBlockArr = editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()

    const generateLengthBreakPt = () => {
      if (canAddAudio && canAddImage && canAddVideo) return 500
      if (canAddAudio && canAddImage) return 530
      return 550
    }

    /**
     * If there is more than one block, that means multiple lines.
     * If the last and only block is greater than a certain width (the break point is dependent
     * on the number of media types that can be uploaded for the question), it means the label will
     * go into multiple lines.
     */
    const isMultiLine = editorBlockArr.size > 1 || widthOfLastBlock() > generateLengthBreakPt()

    const canUploadMedia = canAddImage || canAddAudio

    const addExtraMargin = isMultiLine && canUploadMedia && editorHasFocus

    return (
      <div
        className='d3-editor-wrapper'
        ref={el => {
          this.editorParentRef = el
        }}>
        {hasLinks && this.renderLinkMenu()}
        <div
          ref={el => {
            this.editorWrapper = el
          }}
          className={`${className ? `${className} ` : ''}d3-editor${editorHasFocus ? ' focus' : ''}${
            readOnly ? ' read-only' : ''
          }${hasError ? ' error' : ''}${addExtraMargin ? ' extra-margin' : ''}`}
          onClick={e => {
            if (hasLinks) this.promptForLink(e)
            this.draftEditor.focus()
          }}>
          <Editor
            {...editorProps}
            stripPastedStyles={stripPastedStyles}
            onFocus={() => {
              this.onFocus()
              this.setState({ editorHasFocus: true })
            }}
            onBlur={() => {
              if (onBlur) onBlur()
              this.setState({ editorHasFocus: false })
            }}
            ref={el => {
              this.draftEditor = el
            }}
            handleKeyCommand={this.onKeyCommand}
            keyBindingFn={noStyling ? myNoStylingKeyBindingFunc : null}
            plugins={[imagePlugin]}
            handleBeforeInput={this._handleBeforeInput}
            handlePastedText={this._handlePastedText}
            blockStyleFn={this.myBlockStyleFn}
          />
          {!image && !audio && !video && canUploadMedia && this.mediaUploadButtons(isMultiLine)}
          {showToolbar && (
            <div className={`rich-text-toolbar${editorHasFocus ? '' : ' none'}`}>
              {this.richTextControls(currentStyle, blockType)}
              {hasBlockStyles && this.blockStyleControls()}
              {hasAlignmentControls && this.alignStyleControls()}
              {hasLinks && this.renderLinkButton()}
            </div>
          )}
          {image && (
            <div className='image-container question-image flexed column'>
              <div className='image flexed column'>
                <Button
                  className='fas fa-times remove-image'
                  onClick={e => {
                    this.onRemoveImage(e)
                  }}
                />
                <p>{image}</p>
                <img src={src} alt='uploaded-question' />
              </div>
            </div>
          )}
          {audio && (
            <div className='media-container question-media flexed column'>
              <div className='media flexed column'>
                <Button
                  className='fas fa-times remove-media'
                  onClick={e => {
                    this.onRemoveMedia(e)
                  }}
                />
                <p>{audio}</p>
                <audio src={mediaSrc} controls />
              </div>
            </div>
          )}
          {video && (
            <div className='media-container question-media flexed column'>
              <div className='media flexed column'>
                <Button
                  className='fas fa-times remove-media'
                  onClick={e => {
                    this.onRemoveMedia(e, true)
                  }}
                />
                <p>{video}</p>
                <video src={mediaSrc} controls>
                  <track kind='captions' />
                </video>
              </div>
            </div>
          )}
        </div>
        {charLimit && <span className='char-limit'>{`${this._getCurrentContentLength()}/${charLimit}`}</span>}
      </div>
    )
  }
}

RichTextEditor.propTypes = {
  editorState: PropTypes.shape({
    getCurrentInlineStyle: PropTypes.func,
    getSelection: PropTypes.func,
    getCurrentContent: PropTypes.func,
  }),
  addItemImage: PropTypes.func,
  addItemMedia: PropTypes.func,
  canAddAudio: PropTypes.bool,
  canAddImage: PropTypes.bool,
  canAddVideo: PropTypes.bool,
  charLimit: PropTypes.number,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  currentResourcesSizeCount: PropTypes.number,
  deleteItemImage: PropTypes.func,
  deleteItemMedia: PropTypes.func,
  hasAlignmentControls: PropTypes.bool,
  hasBlockStyles: PropTypes.bool,
  hasError: PropTypes.bool,
  hasLinks: PropTypes.bool,
  isChoice: PropTypes.bool,
  item: PropTypes.shape({
    image: PropTypes.string,
  }),
  itemId: PropTypes.string,
  mediaIsUploading: PropTypes.bool,
  noStyling: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  openModal: PropTypes.func,
  placeholder: PropTypes.string,
  placeHolder: PropTypes.string,
  readOnly: PropTypes.bool,
  showToolbar: PropTypes.bool,
  spellCheck: PropTypes.bool,
  stripPastedStyles: PropTypes.bool,
}

export default RichTextEditor
