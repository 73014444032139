import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Radio } from 'components/UIElements'
import { closeIcon } from 'assets/assets'
import {
  PAYOUT_TYPE_THUMBNAIL_MAP,
  REWARD_TYPE_MAP,
  PAYMENT_DELIVERY_METHODS_MAP,
  MODAL_CONTENT_MAP,
  MODAL_CLASSES_MAP,
} from 'utils/constants'
import { obfuscateString } from 'utils/misc'
import STRINGS from 'utils/strings'
import PayoutOptionSelection from './PayoutOptionSelection'

const CURRENCY_OPTIONS = [
  { key: 'USD', value: 'USD', text: 'US dollar (USD)' },
  { key: 'CAD', value: 'CAD', text: 'Canadian dollar (CAD)' },
  // { key: 'AUD', value: 'AUD', text: 'Australian dollar (AUD)' },
  // { key: 'EUR', value: 'EUR', text: 'Euro (EUR)' },
  // { key: 'GBP', value: 'GBP', text: 'Pound sterling (GBP)' },
  // { key: 'JPY', value: 'JPY', text: 'Japanese yen (JPY)' },
  // { key: 'MXP', value: 'MXP', text: 'Mexican peso (MXP)' },
]

const changeCurrencyType = (cycles, newCurrencyType) => {
  return cycles.map(cycle => {
    const newCycle = {
      ...cycle,
      reward: 100,
      currency: newCurrencyType,
    }
    return newCycle
  })
}

export const onOpenPayoutModal = ({ props, currency, isTreasureChest = false }) => {
  const { openModal } = props
  openModal({
    content: <PayoutOptionSelection {...props} currency={currency} isTreasureChest={isTreasureChest} />,
    noButtons: true,
    closeOnBackgroundClick: false,
    className: MODAL_CLASSES_MAP.payout,
  })
}

export const ConfirmedPayoutSelection = ({
  disabled,
  fontAwesomeName,
  onDiscard,
  _onOpenPayoutModal,
  payoutType,
  val1,
  val2,
}) => {
  const image = PAYOUT_TYPE_THUMBNAIL_MAP[payoutType] || <i className={fontAwesomeName} />
  return (
    <div className='selected-payout'>
      <div className='flexed start-justified'>
        {image}
        <div>
          <p>{val1}</p>
          <p>{val2}</p>
        </div>
      </div>
      {!disabled && !fontAwesomeName && <Button onClick={() => _onOpenPayoutModal()} link content='Edit Account' />}
      {!disabled && <Button className='close-x' onClick={onDiscard} content={closeIcon()} />}
    </div>
  )
}

const AdvancedPaymentOptions = props => {
  const {
    disabled,
    changeRewardType,
    cycles,
    initializeCashPayment,
    openModal,
    study,
    updateConfig,
    updateAdvancedPayouts,
  } = props
  const { config } = study
  const _cycles = config ? config.cycles : study.cycles
  const advancedPayouts = config?.advanced_payouts
  const selectedPayoutType = advancedPayouts?.payout_types ? advancedPayouts.payout_types[0] : null
  const selectedPaymentDeliveryMethod = advancedPayouts?.notification_type
    ? advancedPayouts.notification_type
    : PAYMENT_DELIVERY_METHODS_MAP.email
  const { currency } = _cycles[0]
  const willManagePaymentsManually = disabled
    ? !selectedPayoutType
    : advancedPayouts &&
      Object.keys(advancedPayouts).includes('hasAdvancedPayouts') &&
      !advancedPayouts.hasAdvancedPayouts

  const onOpenDiscardModal = () => {
    openModal({
      content: MODAL_CONTENT_MAP.discardPaymentAccount,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => initializeCashPayment(),
    })
  }

  let options = (
    <div>
      <Button
        id='add-payment-account'
        className='payment add-account'
        content={STRINGS.createStudyAddPaymentAcct}
        disabled={disabled}
        iconBefore='fas fa-plus'
        noStyling
        onClick={() => {
          updateConfig({
            advanced_payouts: { ...updateAdvancedPayouts(advancedPayouts), hasAdvancedPayouts: true },
          })
          onOpenPayoutModal({ props, currency })
        }}
      />
      <Button
        id='self-manage-payment'
        className='payment self-manage'
        content={STRINGS.createStudyManagePayments}
        disabled={disabled}
        noStyling
        onClick={() =>
          updateConfig({
            cycles: changeRewardType(cycles, REWARD_TYPE_MAP.cash),
            advanced_payouts: {
              ...advancedPayouts,
              hasAdvancedPayouts: false,
            },
          })
        }
      />
    </div>
  )

  if (advancedPayouts && selectedPayoutType) {
    options = (
      <ConfirmedPayoutSelection
        disabled={disabled}
        onDiscard={onOpenDiscardModal}
        _onOpenPayoutModal={() => onOpenPayoutModal({ props, currency })}
        payoutType={selectedPayoutType.type}
        val1={`Region: ${selectedPayoutType.aws_region}`}
        val2={`Access Key: ${obfuscateString(4, selectedPayoutType.access_key)}`}
      />
    )
  }

  if (willManagePaymentsManually) {
    options = (
      <ConfirmedPayoutSelection
        disabled={disabled}
        fontAwesomeName='fas fa-money-bill-alt'
        onDiscard={initializeCashPayment}
        val1={STRINGS.createStudyPaymentsManaged}
      />
    )
  }

  const hasSelectedPayoutType = advancedPayouts && selectedPayoutType

  return (
    <div className='advanced-payment-options'>
      <div className='currency-selection'>
        <p>{disabled ? STRINGS.createStudyPaymentCurrency : STRINGS.createStudySelectCurrency}</p>
        <Dropdown
          id='currency-dropdown'
          disabled={disabled || hasSelectedPayoutType}
          options={CURRENCY_OPTIONS}
          onSelect={item => updateConfig({ cycles: changeCurrencyType(_cycles, item.value) })}
          selected={currency}
        />
      </div>
      {options}
      {hasSelectedPayoutType && (
        <>
          <p>How will the participants receive their payment giftcodes?</p>
          <Radio
            disabled={disabled}
            selected={selectedPaymentDeliveryMethod === PAYMENT_DELIVERY_METHODS_MAP.email}
            onClick={() =>
              updateConfig({
                advanced_payouts: {
                  ...advancedPayouts,
                  notification_type: PAYMENT_DELIVERY_METHODS_MAP.email,
                },
              })
            }
            content='Email'
          />
          <Radio
            disabled={disabled}
            selected={selectedPaymentDeliveryMethod === PAYMENT_DELIVERY_METHODS_MAP.announcement}
            onClick={() =>
              updateConfig({
                advanced_payouts: {
                  ...advancedPayouts,
                  notification_type: PAYMENT_DELIVERY_METHODS_MAP.announcement,
                },
              })
            }
            content='In-App'
          />
        </>
      )}
    </div>
  )
}

ConfirmedPayoutSelection.propTypes = {
  disabled: PropTypes.bool,
  fontAwesomeName: PropTypes.string,
  onDiscard: PropTypes.func,
  _onOpenPayoutModal: PropTypes.func,
  payoutType: PropTypes.string,
  val1: PropTypes.string,
  val2: PropTypes.string,
}

AdvancedPaymentOptions.propTypes = {
  disabled: PropTypes.bool,
  changeRewardType: PropTypes.func,
  cycles: PropTypes.arrayOf(PropTypes.object),
  initializeCashPayment: PropTypes.func,
  openModal: PropTypes.func,
  study: PropTypes.shape({
    config: PropTypes.object,
    cycles: PropTypes.arrayOf(PropTypes.object),
  }),
  updateConfig: PropTypes.func,
  updateAdvancedPayouts: PropTypes.func,
}

export default AdvancedPaymentOptions
