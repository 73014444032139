import React from 'react'
import PropTypes from 'prop-types'
import { Popup, Button } from 'components/UIElements'
import 'styles/d3/filter.scss'

export const _getMapState = key => state => {
  return {
    key,
    criteria: state.tableFilters.criteria[key] || null,
    filterApplied: state.tableFilters.filters.hasOwnProperty(key),
  }
}

export const mapActions = require('store/tableFilters').actions

class Filter extends React.Component {
  onSubmit = e => {
    e.preventDefault()
    this.applyFilter()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.criteria && !this.props.criteria) {
      this.setState({ ...this.defaults })
    }
  }

  applyFilter = () => {
    const { setFilterCriteria, addFilter } = this.props
    this.setState({ _edited: false })
    setFilterCriteria(this.key, this.state)
    addFilter(this.key, this.getFilter(this.state))
  }

  removeFilter = () => {
    const { removeFilter } = this.props
    this.setState({ ...this.defaults })
    removeFilter(this.key)
  }

  cancelFilter = () => {
    const { criteria } = this.props
    this.setState({ ...(criteria || this.defaults), _edited: false })
  }

  render(content) {
    const { _edited } = this.state
    const { filterApplied } = this.props
    return (
      <Popup
        align={this.align}
        className='d3-filter-box'
        trigger={<i className={`fas fa-filter${filterApplied ? ' active' : ''}`} />}>
        <form onSubmit={this.onSubmit}>
          <div className='flexed space-between'>
            <span className='label-small'>Filter</span>
            {filterApplied && (
              <Button
                className='close-trigger'
                // disabled={!filterApplied}
                link
                onClick={this.removeFilter}
                content='Clear'
                icon='fas fa-times'
              />
            )}
          </div>
          <p>{this.header}</p>
          <div>{content}</div>
          <div className='flexed end-justified'>
            <Button className='close-trigger' disabled={!_edited} link onClick={this.cancelFilter} content='Cancel' />
            <Button grey type='submit' className='close-trigger' disabled={!_edited} content='Apply' />
          </div>
        </form>
      </Popup>
    )
  }
}

Filter.propTypes = {
  filterApplied: PropTypes.bool,
  setFilterCriteria: PropTypes.func,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func,
}

export default Filter
