import { injectReducers } from 'store/reducers'
import { viewActions } from 'store/view'
import consents from '../ConsentsDashboard/modules/Consents'
import newConsentReducer from './routes/NewConsentBuilder/modules/NewConsent'
import instrumentReducer from '../../../Instruments/routes/Instrument/modules/Instrument'
import ptpsReducer from '../../../Participants/routes/ParticipantsPage/modules/Participants'
import CreateConsent from './routes/CreateConsent'
import EditConsentRoute from './routes/EditConsent'
import ConsentPreviewRoute from './routes/NewConsentBuilder/routes/ConsentPreview'
import ConsentPropertiesRoute from './routes/ConsentProperties'
import ConsentView from './container/ConsentViewContainer'
import ConsentSchedulerRoute from './routes/ConsentScheduler'
import ConsentDeployRoute from './routes/ConsentDeploy'
import ConsentFormatting from './routes/ConsentFormatting'

export const injectConsentReducers = store => {
  injectReducers(store, {
    consents,
    newConsentReducer,
    participants: ptpsReducer,
    instrumentReducer,
  })
}

export default store => ({
  component: ConsentView,
  getChildRoutes(location, cb) {
    store.dispatch(viewActions.setFullWidth())
    cb(null, [
      ConsentPreviewRoute(store),
      ConsentDeployRoute(store),
      ConsentPropertiesRoute(store),
      ConsentSchedulerRoute(store),
      CreateConsent(store),
      EditConsentRoute(store),
      ConsentFormatting(store),
    ])
  },
})
