import { connect } from 'react-redux'
import { consentActions } from '../routes/NewConsentBuilder/modules/NewConsent'
import { instrumentActions } from '../../../../Instruments/routes/Instrument/modules/Instrument'
import { consentActions as consentActionsDashboard } from '../../ConsentsDashboard/modules/Consents'
import ConsentView from '../components/ConsentView'

const HEADER_NAMES = {
  createconsent: 'Consent properties',
  properties: 'Consent properites',
  schedule: 'Schedule deployment',
}

const _getSelectedTab = path => {
  if (HEADER_NAMES.hasOwnProperty(path)) {
    return 'main'
  }
  return path
}

const _getHeaderName = route => {
  return HEADER_NAMES[route]
}

const mapState = state => {
  const { newConsentReducer, loading, location, subroute, study } = state
  const { consent } = newConsentReducer
  const path = location.pathname.match(/([^/]+$)/)[0]

  return {
    consent,
    headerName: _getHeaderName(path),
    isScheduleFlow: ['consentScheduler', 'consentDeploy'].includes(subroute),
    loading: loading.general,
    path,
    preview: path === 'preview',
    selectedTab: _getSelectedTab(path),
    studyID: study.currentStudy.id,
  }
}

const mapDispatch = {
  ...consentActionsDashboard,
  ...consentActions,
  ...instrumentActions,
}

export default connect(mapState, mapDispatch)(ConsentView)
