import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import CasesPageContainer from './containers/CasesPageContainer'

// Sync route definition
export default store => ({
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        cb(null, UserIsAuthenticated(UserHasValidPassword(UserHasScope(['rp', 'ra'], CasesPageContainer, false, studyID))))
      },
      'cases',
    )
  },
})
