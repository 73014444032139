import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
import { browserHistory } from 'react-router'
import { setPageTitle } from 'utils/misc'
import { isSameUser } from 'utils/userUtils'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import ParticipantsPageRoute from './routes/ParticipantsPage'
import CreateParticipantRoute from './routes/CreateParticipant'
import AccountSettingsRoute from './routes/AccountSettings'
import ViewParticipantsRoute from './routes/ViewParticipant'
import CreateCohortRoute from './routes/CreateCohort'
import ViewCohortRoute from './routes/ViewCohort'
import MoveParticipant from './routes/MoveParticipant'
import participantsReducer from './routes/ParticipantsPage/modules/Participants'
import participantReducer from './routes/CreateParticipant/modules/CreateParticipant'
import consentReducer from '../Consent/routes/ConsentEditor/modules/Consent'
// Sync route definition
export default store => {
  return {
    path: 'participants',
    exact: true,
    getIndexRoute(location, cb) {
      const { params } = location
      const { studyID } = params
      const { currentStudy } = store.getState().study

      store.dispatch(setSubroute('participants'))
      injectReducers(store, {
        participants: participantsReducer,
        participantReducer,
        consentReducer,
      })
      setPageTitle(`Participants | Study ${studyID} | ${currentStudy.platform_name}`)
      cb(
        null,
        UserIsAuthenticated(
          UserHasValidPassword(UserHasScope(['rp, rp-no-pii'], ParticipantsPageRoute(store), false, studyID)),
        ),
      )
    },
    getChildRoutes(location, cb) {
      store.dispatch(setSubroute('participants'))
      injectReducers(store, {
        participants: participantsReducer,
        participantReducer,
        consentReducer,
      })
      const { user } = store.getState()
      if (isSameUser(user.id) ?? true) {
        cb(null, [
          CreateParticipantRoute(store),
          AccountSettingsRoute(store),
          ViewParticipantsRoute(store),
          CreateCohortRoute(store),
          ViewCohortRoute(store),
          MoveParticipant(store),
        ])
      } else browserHistory.push('/')
    },
  }
}
