import React from 'react'
import { Container } from 'components/UIElements'

import '../stylesheets/EmbeddedInsights.scss'

const EmbeddedInsights = ({ sisenseUrl, dashboard }) => {
  return (
    <div className='embedded-insights page'>
      <iframe className='embedded-iframe' src={sisenseUrl} id='embedded-iframe' allowFullScreen />
    </div>
  )
}

export default EmbeddedInsights
