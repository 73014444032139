import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import GeofencesPageRoute from './routes/GeofencesPage'
import EditGeofenceRoute from './routes/EditGeofence'
import { UserIsAuthenticated, UserHasValidPassword } from '../../../../../../containers/authContainers'
import geofencesReducer, { actions as geofencesActions } from './routes/GeofencesPage/modules/Geofences'

export default store => {
  return {
    path: 'geofences',
    exact: true,
    getIndexRoute(location, cb) {
      const { params } = location
      const { studyID } = params
      store.dispatch(setSubroute('geofences'))
      injectReducers(store, {
        geofences: geofencesReducer,
      })
      store.dispatch(geofencesActions.fetchGeofences(studyID))
      const { currentStudy } = store.getState().study
      setPageTitle(`Geofences | Study ${studyID} | ${currentStudy.platform_name}`)
      // TODO: add user scope checks
      cb(null, UserIsAuthenticated(UserHasValidPassword(GeofencesPageRoute(store))))
    },
    getChildRoutes(location, cb) {
      injectReducers(store, {
        geofences: geofencesReducer,
      })
      cb(null, [EditGeofenceRoute(store)])
    },
  }
}
