import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AutocompleteSearch } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import Tags from '../../../../../../Participants/routes/CreateParticipant/components/Tags'

const DeploymentSiteSelection = props => {
  const {
    hasError,
    checkedSites,
    disabled = false,
    errorText,
    initializeCheckedSites,
    leafSites,
    sitesList,
    toggleSite,
    updateCohortField,
  } = props

  useEffect(() => {
    return () => {
      initializeCheckedSites([])
    }
  }, [])

  const onToggleSite = (id, pathName) => {
    const newSitesData = { ...checkedSites }
    toggleSite(id)
    if (id in checkedSites) {
      delete newSitesData[id]
    } else {
      newSitesData[id] = pathName
    }
    updateCohortField(
      'filter',
      { site_ids: Object.keys(newSitesData).map(_id => parseInt(_id, 10)) },
      'schedule.cohort.filter.site_ids',
    )
  }

  const generateListAndTags = () => {
    const tags = []
    const list = sitesList
      ? sitesList.map(row => {
          const item = { key: row[0].value, text: row[1].value }
          if (item.key in checkedSites) tags.push(item)
          return {
            key: row[0].value,
            text: row[1].value,
            treeLevel: row[2].value,
            path: row[3].value,
          }
        })
      : []

    return { list, tags }
  }

  const { list, tags } = generateListAndTags()

  return (
    <>
      {tags.length > 0 ? (
        <>
          <p>
            <b>{tags.length}</b> {`${pluralize(tags.length, 'site', 'sites', false)}`} assigned
          </p>
          <Tags disabled={disabled} toggle={onToggleSite} tags={tags} />
        </>
      ) : null}
      <AutocompleteSearch
        checked={checkedSites}
        errorText={errorText}
        filter={leafSites}
        hasError={hasError}
        list={list}
        id='search-for-site-input'
        placeholder='Search for a site...'
        toggleItem={onToggleSite}
      />
    </>
  )
}

DeploymentSiteSelection.propTypes = {
  checkedSites: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  initializeCheckedSites: PropTypes.func,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  sitesList: PropTypes.arrayOf(PropTypes.array),
  toggleSite: PropTypes.func,
  updateCohortField: PropTypes.func,
}

export default DeploymentSiteSelection
