import React from 'react'
import PropTypes from 'prop-types'
import { SortableTable } from 'components/UIElements'
import { browserHistory } from 'react-router'
import '../stylesheets/CohortTable.scss'

const headerOptions = [
  { key: 'id', sortable: true, component: 'ID' },
  { key: 'name', sortable: true, component: 'Track Name' },
  { key: 'remarks', sortable: true, component: 'Description' },
]

const CohortList = ({ list, offsets, studyID, studyLock }) => {
  const renderId = rowProps => (
    <td key={rowProps.value} className='track-id'>
      {rowProps.value}
    </td>
  )

  const renderFunctions = {
    id: renderId,
  }

  return (
    <div>
      <SortableTable
        offset={offsets[0]}
        onRowClick={row => {
          !studyLock && browserHistory.push(`/studies/${studyID}/participants/tracks/${row[0].value}`)
        }}
        className='cohort-list'
        sortingBy='id'
        rowList={list}
        headerOptions={headerOptions}
        renderFunctions={renderFunctions}
        emptyText='This study does not have any tracks'
      />
    </div>
  )
}

CohortList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.array),
  offsets: PropTypes.arrayOf(PropTypes.number),
  studyID: PropTypes.number,
}

export default CohortList
