import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import request, { generateNotyMessage } from 'utils/request'
import { actions as notyActions } from 'layouts/ErrorBox'
import { modalActions } from 'store/modal'
import { loadingActions } from 'store/loader'
import { fetchUser } from 'store/user'
import {
  CHINA_DEPLOY,
  DEFAULT_EMAIL_CONFIG,
  DEFAULT_INSIGHTS_DASHBOARD_CONFIG,
  DEFAULT_STUDY,
  DEFAULT_STUDY_CONFIG,
  MODAL_CLASSES_MAP,
  MODAL_CONTENT_MAP,
  REWARD_TYPE_MAP,
  SECURITY_KEY_MAP,
} from 'utils/constants'
import { isFunction } from 'utils/misc'
import { generateCycles, getCyclesInfo, generateDynamicCycles, getDynamicCyclesInfo } from '../utils/studyUtils'
import {
  locales,
  localesMap,
  fetchLocales,
} from '../../Study/routes/Participants/routes/CreateParticipant/modules/CreateParticipant'

// Actions/reducers for Create Study Page

const UPDATE_STUDY = 'UPDATE_STUDY'
const UPDATE_CYCLE = 'UPDATE_CYCLE'
const UPDATE_CONFIG = 'UPDATE_CONFIG'
const ADD_CYCLE = 'ADD_CYCLE'
const DELETE_CYCLE = 'DELETE_CYCLE'
const INITIALIZE_BLANK_STUDY = 'INITIALIZE_BLANK_STUDY'
const TOGGLE_MODULE = 'TOGGLE_MODULE'
const SET_STUDY = 'SET_STUDY'
const SET_INSIGHTS_LOW_FUNCTIONALITY_MODE = 'SET_INSIGHTS_LOW_FUNCTIONALITY_MODE'
const RESET_INSIGHTS_LOW_FUNCTIONALITY_MODE = 'RESET_INSIGHTS_LOW_FUNCTIONALITY_MODE'
const UPDATE_STUDY_CUSTOM_LOGO = 'UPDATE_STUDY_CUSTOM_LOGO'
const SAVE_ATTEMPTED = 'SAVE_ATTEMPTED'
const UPDATE_AUTO_NOTIFICATION = 'UPDATE_AUTO_NOTIFICATION'
const SET_ORPHANED_PARTICIPANTS = 'SET_ORPHANED_PARTICIPANTS'
const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE'

export const NOTIFICATION_CONSTS = {
  UPDATE_AUTO_NOTIFICATION,
}

// Constants

export const requiredFields = ['regoal', 'reward_duration']

export const requiredCycleFields = ['target', 'reward', 'cycleDuration', 'studyDuration', 'rewardType']

export const requiredDynamicCycleFields = ['target', 'reward', 'cycleDuration', 'rewardType']

// These keys were moved into the config object in v3.17
export const legacyKeys = [
  'modules',
  'forced_instrument_priority',
  'reward_duration',
  'reward_goal',
  'has_dynamic_cycles',
  'cycles',
  'auto_notification',
  'session_timeout_seconds', // optional
  'is_test', // optional,
  'consent_required', // optional,
]

const advancedStudyConfigKeys = [
  'custom_onboarding',
  'case_management',
  'custom_geofence_behavior',
  'custom_map_button',
]

// Actions

export const resetStudy = () => {
  return {
    type: INITIALIZE_BLANK_STUDY,
    value: DEFAULT_STUDY,
  }
}

const updateStudy = (key, value) => {
  return {
    type: UPDATE_STUDY,
    key,
    value,
  }
}

const updateConfig = value => {
  return {
    type: UPDATE_CONFIG,
    value,
  }
}

export const resetStudyData = () => {
  return dispatch => {
    dispatch(resetStudy())
    dispatch(updateConfig(DEFAULT_STUDY_CONFIG))
  }
}

const updateCycle = (cycleIdx, key, value) => {
  return {
    type: UPDATE_CYCLE,
    idx: cycleIdx,
    key,
    value,
  }
}

const addCycle = () => {
  return {
    type: ADD_CYCLE,
  }
}

const deleteCycle = idx => {
  return {
    type: DELETE_CYCLE,
    idx,
  }
}

const updateRewards = (cycles, duration, goal) => {
  return updateStudy(['cycles', 'reward_duration', 'reward_goal'], [cycles, duration, goal])
}

const toggleModule = key => {
  return {
    type: TOGGLE_MODULE,
    key,
  }
}

const toggleLanguage = language => {
  return {
    type: TOGGLE_LANGUAGE,
    language,
  }
}

export const resetInsightsLowFunctionalityMode = () => {
  return { type: RESET_INSIGHTS_LOW_FUNCTIONALITY_MODE }
}

const applyCBs = (_study, beforeCreateCbs) => {
  return beforeCreateCbs.reduce((acc, { cb }) => isFunction(cb) && cb(acc), _study)
}

// Thunk actions

const _saveStudy = (study, isPatch = false, studyID = '', diffIsConfig = false) => {
  const user = JSON.parse(localStorage.getItem('user'))
  return dispatch => {
    return dispatch(
      request({
        method: isPatch ? 'PATCH' : 'POST',
        url: isPatch ? `/control/studies/${studyID}` : '/control/studies',
        body: JSON.stringify({ study }),
        failMessage: 'Error creating study',
        successMessage: `Study successfully ${isPatch ? ' saved' : ' created'}`,
        success: () => {
          dispatch(fetchUser(user, !isPatch))
          if (diffIsConfig) dispatch(loadingActions.stopLoader(true, 'studyConfig'))
          return dispatch(isPatch ? fetchStudy(studyID) : () => {})
        },
      }),
    )
  }
}

export const createStudy = (_study, beforeCreate) => {
  return dispatch => {
    dispatch({ type: SAVE_ATTEMPTED })
    const hasStudyError = _hasStudyError(_study)
    const beforeCreateCbs = Array.isArray(beforeCreate) ? beforeCreate : []
    const sectionError = beforeCreateCbs.map(({ hasErrors }) => isFunction(hasErrors) && hasErrors(_study)).some(e => e)
    if (hasStudyError || sectionError) {
      return dispatch(
        notyActions.showError({ text: generateNotyMessage('Please correct empty fields marked in red.', false) }),
      )
    }
    dispatch(loadingActions.startLoader(true))
    const study = _convertStudyForSave(applyCBs(_study, beforeCreateCbs))
    return dispatch(_saveStudy(study)).then(() => {
      dispatch(resetStudyData())
      browserHistory.push('/studies')
      return dispatch(loadingActions.stopLoader(true))
    })
  }
}

export const fetchStudy = id => {
  return dispatch => {
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${id}`,
        errorMessage: `error fetching study ${id}`,
        success: json => {
          _formatStudy(json, dispatch)
          dispatch({
            type: SET_STUDY,
            study: json,
          })
          return Promise.resolve(json)
        },
        hasLoader: false,
        loadingKey: 'studyConfig',
      }),
    )
  }
}

export const fetchStudyInsightsStatus = id => {
  return dispatch => {
    return dispatch(
      request({
        method: 'GET',
        url: `/control/insights/study_setup/${id}`,
        errorMessage: `error fetching study ${id} insights`,
        success: json => {
          return Promise.resolve(json)
        },
        fail: () => {
          dispatch({
            type: SET_INSIGHTS_LOW_FUNCTIONALITY_MODE,
          })
        },
        hasLoader: true,
        loadingKey: 'sisense',
      }),
    )
  }
}

export const patchStudy = ({ studyId, studyDiff, diffIsConfig = false }) => {
  return dispatch => {
    if (diffIsConfig) {
      return dispatch(
        _saveStudy(
          { ...studyDiff, config: studyDiff.config ? _convertConfigForSave(studyDiff.config) : undefined },
          true,
          studyId,
          diffIsConfig,
        ),
      )
    }
    return dispatch(_saveStudy(studyDiff, true, studyId))
  }
}

export const _deleteStudy = (id, success) => {
  return dispatch => {
    const onConfirm = () => {
      return dispatch(
        request({
          method: 'DELETE',
          url: `/control/studies/${id}`,
          successMessage: 'Study successfully deleted',
          successIcon: 'fas fa-trash-alt',
          hasLoader: true,
          success,
        }),
      )
    }
    dispatch(
      modalActions.openModal({
        onConfirm,
        content: MODAL_CONTENT_MAP.cannotBeUndone,
        className: MODAL_CLASSES_MAP.confirmation,
      }),
    )
  }
}

const deleteStudy = id => dispatch => {
  dispatch(
    _deleteStudy(id, () => {
      browserHistory.push('/studies')
    }),
  )
}

const showPaymentAddSuccessFailNoty = (isFail = false) => dispatch => {
  if (isFail) {
    dispatch(
      notyActions.showError({
        text: generateNotyMessage(`Please check errors before proceeding`, true),
      }),
    )
  } else {
    dispatch(
      notyActions.showSuccess({
        text: generateNotyMessage(`Payment account successfully added`, true),
      }),
    )
  }
}

const validatePayoutCredentials = (credentials, success, fail) => dispatch => {
  dispatch(loadingActions.startLoader(true, 'credentials'))
  return dispatch(
    request({
      method: 'POST',
      url: `/control/validate_credentials`,
      failMessage: 'There was an error validating credentials',
      hasLoader: false,
      success: res => {
        success(res).then(() => {
          dispatch(loadingActions.stopLoader(true, 'credentials'))
        })
      },
      fail: () => {
        fail().then(() => {
          dispatch(loadingActions.stopLoader(true, 'credentials'))
        })
      },
      body: JSON.stringify(credentials),
    }),
  )
}

// Util Functions

const _getDefaultMapProgress = () => {
  return {
    medication_adherence: {
      duration_type: 'DAY',
      duration: 1,
      target_per_duration: 2,
      required_streak: 2,
    },
  }
}

//TODO: remove advanced payouts if payout_types is empty or if rewardType is gems

const _convertStudyForSave = study => {
  const newStudy = JSON.parse(JSON.stringify(study))
  const convertedModules = Object.keys(newStudy.config.modules)
    .filter(key => study.config.modules[key])
    .map(key => key)
  newStudy.config.modules = convertedModules
  requiredFields.forEach(field => {
    if (newStudy[field] === null) {
      delete newStudy[field]
      delete newStudy.config[field]
    }
  })
  const { config } = newStudy
  const {
    admin_security,
    advanced_payouts,
    consent_required,
    cycles,
    has_dynamic_cycles,
    hasAdvancedFeatures,
    medication_adherence,
    session_timeout_seconds,
    super_gem_store_payouts,
  } = config
  /**
   * SETTING DEFAULT INSIGHTS IN CONFIG
   * If app is deployed in China, we want to hide Insights, so we skip adding
   * an Insights configuration to the config.
   *  */

  if (!CHINA_DEPLOY) newStudy.config.insights = DEFAULT_INSIGHTS_DASHBOARD_CONFIG
  if (consent_required) {
    newStudy.config.consent_2 = {}
  }
  if (session_timeout_seconds === 0) {
    delete newStudy.config.session_timeout_seconds
  }
  if (medication_adherence) {
    newStudy.config.map_progress = _getDefaultMapProgress()
  }
  if (cycles !== null) {
    const firstCycle = cycles[0]
    const { rewardType } = firstCycle
    if (rewardType === REWARD_TYPE_MAP.gems || !advanced_payouts || !advanced_payouts.payout_types.length) {
      delete newStudy.config.advanced_payouts
    }
    const payoutTypes = advanced_payouts?.payout_types
    const payoutType = payoutTypes?.length > 0 ? payoutTypes[0].type : null
    newStudy.config.cycles = has_dynamic_cycles
      ? generateDynamicCycles(cycles, payoutType)
      : generateCycles(cycles, payoutType)
  } else {
    delete newStudy.config.cycles
  }
  if (!newStudy.config.hasEnforcedLanguage) {
    delete newStudy.config.enforced_language
  }
  if (advanced_payouts && Object.keys(advanced_payouts).includes('hasAdvancedPayouts')) {
    if (!advanced_payouts?.hasAdvancedPayouts) {
      delete newStudy.config.advanced_payouts
    } else {
      delete newStudy.config.advanced_payouts.hasAdvancedPayouts
    }
  }
  if (super_gem_store_payouts && Object.keys(super_gem_store_payouts).includes('hasSuperGemStorePayouts')) {
    if (!super_gem_store_payouts?.hasSuperGemStorePayouts) {
      delete newStudy.config.super_gem_store_payouts
    } else {
      delete newStudy.config.super_gem_store_payouts.hasSuperGemStorePayouts
    }
  }

  if (admin_security) {
    const pwSecurityArr = Object.keys(admin_security)
    pwSecurityArr.forEach(securityKey => {
      if (!admin_security[securityKey]) {
        delete newStudy.config.admin_security[securityKey]
      }
      if ([SECURITY_KEY_MAP.lockout_time, SECURITY_KEY_MAP.attemp_num].includes(securityKey)) {
        if (!admin_security[SECURITY_KEY_MAP.attempt_num]) delete newStudy.config.admin_security.lockout_time
        if (!admin_security[SECURITY_KEY_MAP.lockout_time]) delete newStudy.config.admin_security.attempt_num
      }
    })
    // if there is no valid values in admin_security, we delete the key
    if (Object.keys(admin_security).length === 0) delete newStudy.config.admin_security
  }

  delete newStudy.config.hasEnforcedLanguage
  if (!hasAdvancedFeatures) {
    advancedStudyConfigKeys.forEach(key => {
      delete newStudy.config[key]
    })
  }

  delete newStudy.config.hasAdvancedFeatures

  // For compatibility with studies created in 3.16 and below
  legacyKeys.forEach(key => {
    if (key in newStudy.config) {
      newStudy[key] = newStudy.config[key]
    }
  })
  return newStudy
}

const _convertConfigForSave = config => {
  const newConfig = JSON.parse(JSON.stringify(config))
  const convertedModules = Object.keys(newConfig.modules)
    .filter(key => newConfig.modules[key])
    .map(key => key)
  newConfig.modules = convertedModules
  const {
    advanced_payouts,
    cycles,
    has_dynamic_cycles,
    hasAdvancedFeatures,
    medication_adherence,
    session_timeout_seconds,
  } = newConfig
  if (session_timeout_seconds === 0) {
    delete newConfig.session_timeout_seconds
  }
  if (medication_adherence) {
    newConfig.map_progress = _getDefaultMapProgress()
  }
  if (cycles !== null) {
    const firstCycle = cycles[0]
    const { rewardType } = firstCycle
    if (rewardType === REWARD_TYPE_MAP.gems || !newConfig.advanced_payouts || !advanced_payouts.payout_types.length) {
      delete newConfig.advanced_payouts
    }
    const payoutTypes = advanced_payouts?.payout_types
    const payoutType = payoutTypes?.length > 0 ? payoutTypes[0].type : null
    newConfig.cycles = has_dynamic_cycles
      ? generateDynamicCycles(cycles, payoutType)
      : generateCycles(cycles, payoutType)
  } else {
    delete newConfig.cycles
  }
  if (!newConfig.hasEnforcedLanguage) {
    delete newConfig.enforced_language
  }
  delete newConfig.hasEnforcedLanguage
  if (!hasAdvancedFeatures) {
    advancedStudyConfigKeys.forEach(key => {
      delete newConfig[key]
    })
  }
  delete newConfig.hasAdvancedFeatures

  return newConfig
}

export const _formatStudy = (study, dispatch) => {
  const cyclesPath = study.config ? study.config : study
  const cyclesData = cyclesPath.cycles
  if (cyclesData instanceof Array && cyclesData.length > 0 && cyclesData[0].duration) {
    const cyclesInfo = cyclesPath.has_dynamic_cycles ? getDynamicCyclesInfo(cyclesData) : getCyclesInfo(cyclesData)
    study.cycles = cyclesInfo
    if (study.config) {
      study.config.cycles = cyclesInfo
    }
  }
  let { modules } = study
  if (study.config) {
    modules = study.config.modules
  }
  const formattedModules = DEFAULT_STUDY.config.modules
  const moduleKeys = Object.keys(formattedModules)
  moduleKeys.forEach(key => {
    formattedModules[key] = false
  })
  modules.forEach(name => {
    formattedModules[name] = true
  })
  study.modules = formattedModules
  if (study.config) {
    study.config.modules = formattedModules
    study.config.hasAdvancedFeatures = advancedStudyConfigKeys.some(key => study.config[key])
  }
  dispatch(loadingActions.stopLoader(true, 'studyConfig'))
}

export const getIsMissingRequiredDynamicCycleFields = cycles => {
  return cycles.some(cycle => {
    return requiredDynamicCycleFields.some(field => !cycle[field] || cycle[field] === 0)
  })
}

const _hasStudyError = study => {
  const { config } = study
  const { super_gems, super_gem_store_payouts } = config
  if (study.name === '' || study.platform_name === '') return true
  const isFixedCycleStudy = !study.config?.has_dynamic_cycles
  if (isFixedCycleStudy) {
    if (requiredCycleFields.some(field => !study.config?.cycles[0][field])) return true
    const studyDurationInDays =
      study.config.cycles[0].durationType === 'weeks'
        ? study.config.cycles[0].studyDuration * 7
        : study.config.cycles[0].studyDuration
    if (studyDurationInDays < study.config.cycles[0].cycleDuration) return true
  } else {
    return getIsMissingRequiredDynamicCycleFields(study.config.cycles)
  }

  if (study.config.hasEnforcedLanguage) {
    if (!study.config.enforced_language.default_language) return true
  }

  const hasEligibility = requiredFields.some(field => !!study.config[field])
  if (hasEligibility) return requiredFields.some(field => !study.config[field])

  // If special rewards (super gems) is selected, ensure either donations or gift cards are toggled
  if (super_gems) {
    const supergemKeys = Object.keys(super_gems)
    if (supergemKeys.length === 0) return true
    if (supergemKeys.includes('donations') && !super_gems.donations && !super_gems.gift_cards) return true
    if (supergemKeys.includes('gift_cards') && !super_gems.donations && !super_gems.gift_cards) return true
  }

  // Check for required gift card credentials and/or required donation info
  if (super_gem_store_payouts) {
    // Check for required gift card credentials
    if (super_gem_store_payouts.payout_types.length === 0) return true
    if (!super_gems.gift_cards) return true
  }
  if (super_gems?.donations) {
    // Check for required donation information if there is donation checked
    const donationInfo = super_gems.donations[0]
    const { summary, detailed_description, value, asset_download_link } = donationInfo
    return !summary || !detailed_description || !value || !asset_download_link
  }

  return false
}

export const uploadCustomLogo = url => ({
  type: UPDATE_STUDY_CUSTOM_LOGO,
  url,
})

export const uploadImage = formData => {
  const success = payload => {
    const { full_image_name } = payload
    return {
      src: full_image_name,
    }
  }
  return dispatch => {
    return dispatch(
      request({
        method: 'POST',
        url: '/control/study_image',
        contentType: 'multipart/form-data',
        body: formData,
        hasLoader: true,
        loadingKey: 'mediaUpload',
        success,
        successMessage: 'Image successfully uploaded.',
        catchMessage: 'There was a problem uploading the image. Please try again later.',
      }),
    )
  }
}

export const currentStudy = (state = DEFAULT_STUDY, action) => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case INITIALIZE_BLANK_STUDY: {
      return action.value
    }
    case TOGGLE_MODULE:
      newState.config.modules = { ...newState.config.modules }
      newState.config.modules[action.key] = !newState.config.modules[action.key]
      return newState
    case UPDATE_STUDY:
      if (action.key instanceof Array) {
        action.key.forEach((key, idx) => {
          if (key === 'cycles') {
            if (action.value instanceof Array) {
              // for dynamic cycles
              newState.config.cycles = action.value[idx]
            } else {
              // for fixed cycles
              newState.config.cycles = [action.value[idx]]
            }
          } else {
            newState.config[key] = action.value[idx]
          }
        })
      } else if (action.key === 'cycles') {
        if (action.value instanceof Array) {
          newState.config.cycles = action.value
        } else {
          newState.config.cycles = [action.value]
        }
      } else if (action.key === 'emailless') {
        let ptpDataFields = newState.config?.participant_data_fields
        if (!ptpDataFields) {
          newState.config.participant_data_fields = {}
          ptpDataFields = newState.config.participant_data_fields
        }
        const ptpDataFieldsKeys = Object.keys(ptpDataFields)
        const disabledArr = ptpDataFields.disabled || []
        const mandatoryArr = ptpDataFields.mandatory || []

        if (action.value) {
          ptpDataFields.disabled = [DEFAULT_EMAIL_CONFIG, ...disabledArr]
          ptpDataFields.mandatory = mandatoryArr.filter(field => field.type !== 'email')
        } else {
          ptpDataFields.disabled = disabledArr.filter(field => field.type !== 'email')
          if (!ptpDataFields.disabled.length && ptpDataFieldsKeys.length === 1) {
            delete newState.participant_data_fields
          }
          ptpDataFields.mandatory = [DEFAULT_EMAIL_CONFIG, ...mandatoryArr]
        }
      } else {
        newState[action.key] = action.value
      }
      return newState
    case UPDATE_CONFIG:
      Object.entries(action.value).forEach(([key, value]) => {
        newState.config[key] = value
      })
      return newState
    case TOGGLE_LANGUAGE: {
      const { enforced_language, hasEnforcedLanguage } = newState.config
      let newLanguages = []
      if (hasEnforcedLanguage) {
        const languageKeys = enforced_language.languages.map(lang => lang.key)
        if (languageKeys.includes(action.language.key)) {
          newLanguages = enforced_language.languages.filter(lang => lang.key !== action.language.key)
          if (action.language.key === enforced_language.default_language) {
            newState.config.enforced_language.default_language = ''
          }
        } else {
          newLanguages = [...enforced_language.languages, action.language]
        }
        newLanguages = newLanguages.sort((a, b) => a.text.localeCompare(b.text))
        newState.config.enforced_language.languages = newLanguages
      }
      return newState
    }
    case UPDATE_CYCLE:
      newState.config.cycles = newState.config.cycles.map((cycle, idx) => {
        if (idx === action.idx) {
          return {
            ...cycle,
            [action.key]: action.value,
          }
        }
        return cycle
      })
      return newState
    case ADD_CYCLE:
      newState.config.cycles = [...newState.config.cycles, newState.config.cycles.slice(-1)[0]]
      return newState
    case DELETE_CYCLE:
      newState.config.cycles = newState.config.cycles.filter((cycle, idx) => idx !== action.idx)
      return newState
    case UPDATE_AUTO_NOTIFICATION: {
      newState.config.auto_notification = { ...newState.config.auto_notification }
      newState.config.auto_notification[action.key] = action.value
      return newState
    }
    case SET_STUDY: {
      return action.study
    }
    case UPDATE_STUDY_CUSTOM_LOGO: {
      newState['custom-logo'] = action.url
      return newState
    }
    default:
      return state
  }
}

const saveAttempted = (state = false, action) => {
  switch (action.type) {
    case SAVE_ATTEMPTED:
      return true
    case INITIALIZE_BLANK_STUDY:
      return false
    default:
      return state
  }
}

const orphanedParticipants = (state = false, action) => {
  switch (action.type) {
    case SET_ORPHANED_PARTICIPANTS:
      return action.bool
    case INITIALIZE_BLANK_STUDY:
    case SET_STUDY:
      return false
    default:
      return state
  }
}

const insightsLowFunctionalityMode = (state = false, action) => {
  switch (action.type) {
    case SET_INSIGHTS_LOW_FUNCTIONALITY_MODE:
      return true
    case RESET_INSIGHTS_LOW_FUNCTIONALITY_MODE:
      return false
    default:
      return state
  }
}

export const actions = {
  addCycle,
  createStudy,
  deleteCycle,
  deleteStudy,
  fetchLocales,
  fetchStudy,
  resetInsightsLowFunctionalityMode,
  resetStudy,
  resetStudyData,
  showPaymentAddSuccessFailNoty,
  toggleLanguage,
  toggleModule,
  updateConfig,
  updateCycle,
  updateRewards,
  updateStudy,
  uploadImage,
  validatePayoutCredentials,
}

export default combineReducers({
  currentStudy,
  saveAttempted,
  orphanedParticipants,
  locales,
  localesMap,
  insightsLowFunctionalityMode,
})
