import React, { useRef, useState, useMemo, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'components/UIElements'

import 'styles/d3/textoverflow.scss'

const TextOverflow = props => {
  const { text, tag: Tag, className, popupText, textWrapper, position, tagClassName, maxWidth } = props
  const [width, setWidth] = useState(props.width)
  const [open, setOpen] = useState(false)
  const textRef = useRef()
  const wrapRef = useRef()

  const onMouseOver = () => {
    if (width && textRef.current.offsetWidth > width) setOpen(true)
  }

  useLayoutEffect(() => {
    if (!props.width && maxWidth < wrapRef.current?.offsetWidth) setWidth(maxWidth)
  }, [])

  const tagText = useMemo(() => {
    return (
      <div className={`text-overflow ${className}`} style={{ width: width ? `${width}px` : 'auto' }}>
        <Tag
          ref={textRef}
          className={tagClassName}
          onMouseOver={onMouseOver}
          onMouseLeave={() => setOpen(false)}
          style={{ width: 'max-content' }}>
          {text}
        </Tag>
      </div>
    )
  }, [className, textRef, onMouseOver, setOpen, text, wrapRef, width])

  return (
    <div className='text-overflow-wrap' ref={wrapRef}>
      <Popup
        open={open}
        position={position}
        hover
        trigger={textWrapper ? <div className={textWrapper}>{tagText}</div> : tagText}>
        <p>{popupText || text}</p>
      </Popup>
    </div>
  )
}

TextOverflow.defaultProps = {
  className: '',
  text: '',
  popupText: '',
  tag: 'span',
  textWrapper: '',
  position: 'bottom',
  tagClassName: '',
  width: null,
  maxWidth: null,
}

TextOverflow.propTypes = {
  textWrapper: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  popupText: PropTypes.string,
  width: PropTypes.number,
  tag: PropTypes.string,
  position: PropTypes.string,
  tagClassName: PropTypes.string,
  maxWidth: PropTypes.number,
}

export default TextOverflow
