import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from '../constants'
import useFlagsmithFeature from './useFlagsmithFeature'

const useFoodDiaryFeature = ({ study = {}, userId }) => {
  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.foodDiary]: { enabled: true } },
    userId,
    studyId: studyID,
  })

  // We will show Food Diary UI only when ALL of these conditions are met
  // - Flagsmith `food_diary` flag is turned on
  // - `instrument_architecture_version` > 1
  // - Study config contains `food_diary_enabled` field with value equals to `true`
  const hasFoodDiary =
    flagsmithFeatures[FLAGSMITH_FEATURES.foodDiary]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  return {
    hasFoodDiary,
  }
}

export default useFoodDiaryFeature
