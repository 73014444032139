import React from 'react'
import { Button } from 'components/UIElements'
import 'styles/d3/input.scss'

class Input extends React.Component {
  constructor(props) {
    super(props)
    this.container = null
    this.input = null
  }

  componentDidMount() {
    const { mountFunc } = this.props // new optional mounting function for inputs if a mountFunc prop is passed to Input component
    if (mountFunc) mountFunc()
  }

  focus = () => {
    this.input.focus()
  }

  onChange = e => {
    const { type, preventInput, onChange, enforceMinMax, min, max, charLimit, onlyInteger, isPositive } = this.props
    // if (this.container.classList.contains('has-error')) {
    //   this.container.classList.remove('has-error')
    // }
    let { value } = e.target
    if (type === 'number') {
      if (value !== '') {
        if (onlyInteger) {
          value = parseInt(value, 10)
        }
        value = value
      }
    }
    if (value !== '' && isPositive && value <= 0) return
    if (value !== '' && preventInput && preventInput(value)) return
    if (enforceMinMax && (min || min === 0) && (max || max === 0)) {
      if (value < min) value = min
      else if (value > max) value = max
    }
    onChange(charLimit ? value.slice(0, charLimit) : value, e)
  }

  render() {
    const {
      autocomplete,
      className,
      disabled,
      errorText,
      hasError,
      hideStepper = false,
      id,
      inline,
      inputProps,
      label,
      margin,
      max,
      min,
      name,
      onBackspacePress,
      onBlur,
      onEnterPress,
      onFocus,
      padding,
      placeholder,
      posttext,
      pretext,
      preventEnter,
      step,
      type,
      value,
      autoFocus,
      pattern,
      maxLength,
      hasPlusMinus,
      plusMinusButtonHeight,
      onChange,
      subTitle,
      charLimit,
      tooltip,
    } = this.props
    const style = pretext || posttext ? { display: 'inline-block' } : {}
    const hasCharLimitClass = charLimit ? ' has-char-limit' : ''
    const hideStepperClass = hideStepper ? ' hide-stepper' : ''
    const hasErrorClass = hasError ? ' has-error' : ''
    const inlineClass = inline ? ' inline' : ''
    const _className = className ? ` ${className}` : ''
    const hasPlusMinusButtons = hasPlusMinus && type === 'number'
    const inputComponent = (
      <input
        ref={el => {
          this.input = el
        }}
        {...inputProps}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            if (preventEnter) e.preventDefault()
            return onEnterPress && onEnterPress(e)
          }
        }}
        onKeyDown={e => {
          if (e.key === 'Backspace') {
            return onBackspacePress && onBackspacePress(e)
          }
        }}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        value={value}
        style={style}
        onChange={this.onChange}
        id={id}
        step={step}
        max={max}
        min={min}
        autoComplete={autocomplete}
        autoFocus={autoFocus}
        pattern={pattern}
        maxLength={maxLength}
      />
    )

    return (
      <div
        style={{ margin, padding }}
        ref={el => {
          this.container = el
        }}
        className={`d3-input${inlineClass}${hasErrorClass}${_className}${hasCharLimitClass}${hideStepperClass}`}
        onClick={() => this.input.focus()}>
        {label && (
          <label className='label-small'>
            {label}
            {tooltip}
          </label>
        )}
        {subTitle && <p className='subtitle'>{subTitle}</p>}
        {pretext && <span className='pretext'>{pretext}</span>}
        {!hasPlusMinusButtons ? (
          inputComponent
        ) : (
          <div className='flexed'>
            {hasPlusMinus && type === 'number' && (
              <Button
                disabled={min && value <= min}
                diameter={plusMinusButtonHeight}
                circle
                icon='fas fa-minus'
                onClick={() => {
                  this.input.stepDown()
                  onChange(this.input.value)
                }}
              />
            )}
            {inputComponent}
            {hasPlusMinus && type === 'number' && (
              <Button
                disabled={max && value >= max}
                diameter={plusMinusButtonHeight}
                circle
                icon='fas fa-plus'
                onClick={() => {
                  this.input.stepUp()
                  onChange(this.input.value)
                }}
              />
            )}
          </div>
        )}
        {!!charLimit && <span className='char-limit'>{`${value.length}/${charLimit}`}</span>}
        {posttext && <span className='posttext'>{posttext}</span>}
        {hasError && errorText && <span className='error-text nowrap'>{errorText}</span>}
      </div>
    )
  }
}

export default Input
