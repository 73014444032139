import React from 'react'
import PropTypes from 'prop-types'
import { SearchInput } from 'components/UIElements'
import { useOffsets } from 'utils/misc'
import TaskList from './TaskList'
import StickyHeader from '../../../../../../../../../../../components/StickyHeader'
import '../stylesheets/Tasks.scss'

const SelectTaskPage = props => {
  const { isCreate } = props
  const offsets = useOffsets()
  return (
    <div className='select-task-page'>
      {isCreate && (
        <StickyHeader offset={offsets ? offsets[0] : 0}>
          <div className='flexed-header task-header'>
            <h3>Select a task</h3>
            <SearchInput />
          </div>
        </StickyHeader>
      )}
      <div className='select-task page'>
        <TaskList {...props} offsets={offsets} />
      </div>
    </div>
  )
}

SelectTaskPage.propTypes = {
  isCreate: PropTypes.bool,
  selectedTaskName: PropTypes.string,
  selectTask: PropTypes.func,
  selectTaskToView: PropTypes.func,
  studyID: PropTypes.number,
  taskID: PropTypes.string,
}
export default SelectTaskPage

SelectTaskPage.propTypes = {}
