import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import '../stylesheets/studyDashboard.scss'
import { Button, FixedTooltip } from 'components/UIElements'
import StudyDBHeader from './StudyDBHeader'
import ComplianceChart from './ComplianceChart'
import PerformanceChart from '../containers/PerformanceChartContainer'
import DataGapChart from '../containers/DataGapChartContainer'
import InsightsDashboard from './Insights/InsightsDashboard'
import { INSIGHTS_VENDORS } from '../utils/utils'

class StudyDashboardPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { tooltipProps: { visible: false } }
  }

  componentDidMount() {
    const {
      fetchComplianceData,
      fetchInstrumentData,
      fetchEventData,
      hasReadInstrumentsPermission,
      hasReadParticipantPermission,
      insights,
      isEnroller,
      params,
      participantEvents,
      resetStudyDashboard,
    } = this.props
    const { studyID, siteID } = params
    document.addEventListener('scroll', this.closeTooltip)
    resetStudyDashboard()
    if (!insights) {
      if (!isEnroller) {
        if (hasReadInstrumentsPermission) fetchInstrumentData(studyID)
        if (hasReadParticipantPermission) {
          fetchComplianceData(studyID)
          fetchEventData(studyID, siteID, participantEvents.start, participantEvents.end)
        }
      } else if (hasReadParticipantPermission) {
        fetchEventData(studyID, siteID, participantEvents.start, participantEvents.end)
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.closeTooltip)
  }

  closeTooltip = () => {
    const { tooltipProps } = this.state
    if (tooltipProps.visible) {
      this.updateTooltip({ visible: false })
    }
  }

  updateTooltip = props => {
    this.setState({ tooltipProps: props })
  }

  renderSisenseLink = data => {
    const { fetchSisenseUrl, params, openSisenseNewWindow } = this.props
    const { studyID } = params
    return (
      <Button
        link
        className='linkt-button'
        icon='fas fa-external-link-alt'
        content={data.button.content}
        onClick={() => {
          if (data.vendor_type === INSIGHTS_VENDORS.holistics) {
            openSisenseNewWindow({ dashboard: data, studyID })
          } else {
            fetchSisenseUrl({ dashboard: data, studyID })
          }
        }}
      />
    )
  }

  render() {
    const {
      changeChartType,
      compliance,
      hasReadInstrumentsPermission,
      hasReadParticipantPermission,
      header,
      insights,
      isEnroller,
      isStudyAdmin,
      params,
      hasEditInsightsPermission,
      insightsLowFunctionalityMode,
      studyRoleId,
      loadSisenseDashboards,
    } = this.props
    const { siteID, studyID } = params
    let customButtons
    const { tooltipProps } = this.state
    return (
      <div className='study-dashboard page'>
        {insights ? (
          <InsightsDashboard
            insights={insights}
            isStudyAdmin={isStudyAdmin}
            renderSisenseLink={this.renderSisenseLink}
            studyID={studyID}
            hasEditInsightsPermission={hasEditInsightsPermission}
            studyRoleId={studyRoleId}
            insightsLowFunctionalityMode={insightsLowFunctionalityMode}
            loadSisenseDashboards={loadSisenseDashboards}
          />
        ) : (
          <>
            <FixedTooltip {...tooltipProps} />
            {!customButtons && <StudyDBHeader {...header} />}
            <div className='flexed start-aligned'>
              {hasReadParticipantPermission && (
                <ComplianceChart
                  {...compliance}
                  studyID={studyID}
                  siteID={siteID}
                  onChangeType={changeChartType}
                  insights={insights}
                  isEnroller={isEnroller}
                />
              )}
              {hasReadInstrumentsPermission && (
                <PerformanceChart
                  updateTooltip={this.updateTooltip}
                  studyID={studyID}
                  siteID={siteID}
                  insights={insights}
                  isEnroller={isEnroller}
                  renderSisenseLink={this.renderSisenseLink}
                />
              )}
            </div>
            {hasReadParticipantPermission && (
              <DataGapChart updateTooltip={this.updateTooltip} studyID={params.studyID} siteID={params.siteID} />
            )}
          </>
        )}
      </div>
    )
  }
}

StudyDashboardPage.propTypes = {
  studyRoleId: PropTypes.number,
  hasEditInsightsPermission: PropTypes.bool,
  changeChartType: PropTypes.func,
  compliance: PropTypes.object,
  fetchComplianceData: PropTypes.func,
  fetchEventData: PropTypes.func,
  fetchInstrumentData: PropTypes.func,
  fetchSisenseUrl: PropTypes.func,
  hasReadInstrumentsPermission: PropTypes.bool,
  hasReadParticipantPermission: PropTypes.bool,
  isEnroller: PropTypes.bool,
  isStudyAdmin: PropTypes.bool,
  header: PropTypes.object,
  insights: PropTypes.shape({
    custom_buttons: PropTypes.object,
    sisense_url: PropTypes.string,
  }),
  params: PropTypes.object,
  participantEvents: PropTypes.shape({
    end: PropTypes.instanceOf(moment),
    start: PropTypes.instanceOf(moment),
  }),
  resetStudyDashboard: PropTypes.func,
}

export default StudyDashboardPage
