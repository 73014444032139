import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CollapseMenu } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { convertMinToDays } from 'utils/time'
import { convertAdvancedOptions } from 'utils/hoc/AdvancedOptionsHOC'
import {
  DEPLOYMENT_KEYS,
  TRIGGER_TYPE_MAP,
  DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION,
  FLAGSMITH_FEATURES,
  INSTRUMENT_DISPLAY_LOCATION_OPTIONS,
} from 'utils/constants'
import STRINGS from 'utils/strings'
import ExpireContent from './ExpireContent/ExpireContent'
import ParticipantManagement from './ParticipantManagement'
import RecurrenceContent from './RecurrenceContent'
import StartContent from './StartContent/StartContent'
import TimedCompletionContent from './TimedCompletionContent'
import TriggerManagement from './TriggerManagement'
import SaveReviewContent from './SaveReviewContent/SaveReviewContent'
import { REPEAT_TIME_UNIT_OPTION, UNIFICATION_REPEAT_TIME_UNIT_OPTION } from '../constants/schedulerConstants'
import VisibilityContainer from '../containers/VisibilityContainer'
import { ARTICLE_TYPE } from '../../../../../utils/instrumentConstants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import useFoodDiaryFeature from 'utils/hooks/useFoodDiaryFeature'

const ERROR_LISTS = {
  recurring: ['schedule.recurring.interval'],
  visibility: ['schedule.recurring.visible_days'],
  participant: [
    'schedule.cohort.filter.max_age',
    'schedule.cohort.filter.min_age',
    'schedule.cohort.filter.site_ids',
    'schedule.cohort.include',
  ],
  trigger: ['conditions'],
}

export const getInstrumentDropdownOptions = (instrumentsList, instrumentId) => {
  const instrumentOptions = instrumentsList.map(instrumentArr => {
    const indexOfName = instrumentArr.findIndex(arr => arr.key === 'name')

    return {
      key: instrumentArr.id,
      text: `${instrumentArr[indexOfName].value} (${instrumentArr.id})`,
      prev_instrument_id: instrumentArr[indexOfName].prev_instrument_id,
      instrumentTriggerAncestors: instrumentArr.instrumentTriggerAncestors,
      type: instrumentArr.payload.type,
    }
  })

  return instrumentOptions.filter(option => {
    const { instrumentTriggerAncestors } = option
    if (instrumentTriggerAncestors) {
      /**
       * If the instrument's trigger ancestors has the current instrument being scheduled, the instrument
       * cannot be selected. Trigger ancestors are which are all the previous generations
       * of instruments that trigger instruments prior to it in the chain.
       */
      return !option.instrumentTriggerAncestors.has(instrumentId) && option.key !== instrumentId
    }
    return option.key !== instrumentId && option.prev_instrument_id !== instrumentId
  })
}

const DeploymentOptions = props => {
  const {
    addDiaryNotificationTrigger,
    addInstrumentTrigger,
    advancedOptions,
    ageDisabled,
    changeTriggerType,
    checkedCohorts,
    checkedSites,
    clearPrevalidatedError,
    deleteInstrumentTrigger,
    disabled,
    disableTrackCreation,
    errors,
    hasClinro,
    hasForcedPriorityEnabled,
    initializeCheckedSites,
    instrument,
    instrumentForm,
    instruments,
    instrumentTriggers,
    isDiary,
    isNewInstArch,
    isSurvey,
    leafSites,
    noTracksFeature,
    schedule,
    scoreOptions,
    setAdvancedOptions,
    setShowAdvancedOption,
    showAdvancedOption,
    sitesList,
    study,
    toggleSite,
    triggersLoading,
    updateInstrumentAction,
    updateInstrumentTrigger,
    updatePrevalidatedErrors,
    visibility,
    userId,
  } = props
  const studyHasVirtualVisits = !!config?.virtual_visits
  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })

  const { hasFoodDiary } = useFoodDiaryFeature({ study, userId })

  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const { chain_deployment_info = {}, cohort, deploy, expire, recurring } = schedule

  const isRelative = DEPLOYMENT_KEYS.firstLogin in deploy
  const isRelativeToInstrumentSentDate = DEPLOYMENT_KEYS.relativeToSendIdeal in expire
  const isInstrumentToInstrument = DEPLOYMENT_KEYS.chainDeployOffset in deploy
  const isDeployedByVisit = DEPLOYMENT_KEYS.chainDeployByVisit in chain_deployment_info
  const isVisitSummary = isDeployedByVisit && instrument?.metadata.article_type === ARTICLE_TYPE.visit_summary.key

  const isPersistentInstrument = recurring?.scheme === 'persistent'

  const isCalendarModeChecked = instrument?.metadata?.display_location?.includes(
    INSTRUMENT_DISPLAY_LOCATION_OPTIONS.calendar,
  )

  const otherInstruments = getInstrumentDropdownOptions(instruments, instrument.id)
  const noOtherInstrumentForTrigger = isInstrumentToInstrument && otherInstruments.length === 0

  // Diary triggers (for push notifications) will be hidden to the admin
  const visibleTriggers = instrumentTriggers?.conditions?.filter(condition => {
    const { triggers } = condition
    const triggerType = triggers ? triggers[0].resource_function_name : ''
    return triggerType !== TRIGGER_TYPE_MAP.newActivityNotification
  })

  const cohortSubtitleContent = _getCohortSubtitles(cohort, checkedCohorts, showAdvancedOption ? advancedOptions : null)

  useEffect(() => {
    if (noOtherInstrumentForTrigger) {
      updatePrevalidatedErrors({ inInstrumentLoop: '' })
    } else {
      clearPrevalidatedError('inInstrumentLoop')
    }
  }, [noOtherInstrumentForTrigger])

  const options = hasInstrumentUnification ? UNIFICATION_REPEAT_TIME_UNIT_OPTION : REPEAT_TIME_UNIT_OPTION

  return (
    <div className={`${disabled ? 'disabled' : ''}`}>
      <>
        <StartContent
          disabled={noOtherInstrumentForTrigger}
          instrumentId={instrument.id}
          isInstrumentToInstrument={isInstrumentToInstrument}
          isDeployedByVisit={isDeployedByVisit}
          isRelative={isRelative}
          otherInstruments={otherInstruments}
          isVisitSummary={isVisitSummary}
          {...props}
        />
        <ExpireContent
          disabled={noOtherInstrumentForTrigger}
          isDiary={isDiary}
          isRelative={isRelative}
          isRelativeToInstrumentSentDate={isRelativeToInstrumentSentDate}
          isInstrumentToInstrument={isInstrumentToInstrument}
          isDeployedByVisit={isDeployedByVisit}
          isVisitSummary={isVisitSummary}
          instrument={instrument}
          hasInstrumentUnification={hasInstrumentUnification}
        />
        {hasFoodDiary ? <SaveReviewContent {...props} /> : null}
      </>
      <p>Advanced Options</p>
      {hasInstrumentUnification ? (
        <>
          {!isVisitSummary && (
            <CollapseMenu
              hasError={ERROR_LISTS.recurring.some(key => errors.hasOwnProperty(key))}
              useErrorIcon
              className='repeat-settings'
              titleContent={STRINGS.repeat}
              subtitleContent={
                recurring
                  ? options.some(opt => opt.key === recurring.scheme)
                    ? `Repeat after every ${pluralize(
                        recurring.interval,
                        recurring.interval_type.substring(0, recurring.interval_type.length - 1),
                        recurring.interval_type,
                      )}`
                    : 'Repeat Immediately'
                  : "Doesn't repeat"
              }
              menuContent={
                <RecurrenceContent
                  hideImmediateRepeat={hasForcedPriorityEnabled}
                  isRelative={isRelative}
                  hasInstrumentUnification={hasInstrumentUnification}
                />
              }
            />
          )}
          <CollapseMenu
            className='visibility-settings'
            hasError={ERROR_LISTS.visibility.some(key => props.errors.hasOwnProperty(key))}
            menuContent={<VisibilityContainer />}
            subtitleContent={_getVisibilitySubtitle({ visibility, recurring })}
            titleContent={STRINGS.visibility}
            useErrorIcon
          />
        </>
      ) : (
        <>
          {!isVisitSummary && (
            <CollapseMenu
              hasError={ERROR_LISTS.recurring.some(key => errors.hasOwnProperty(key))}
              useErrorIcon
              className='repeat-settings'
              titleContent={STRINGS.repeat}
              subtitleContent={
                recurring
                  ? recurring.scheme === 'n-days'
                    ? `Repeat after every ${pluralize(recurring.interval, 'day', 'days')}`
                    : 'Repeat Immediately'
                  : "Doesn't repeat"
              }
              menuContent={
                <RecurrenceContent
                  hideImmediateRepeat={hasForcedPriorityEnabled || isDiary}
                  isRelative={isRelative}
                  hasInstrumentUnification={hasInstrumentUnification}
                />
              }
            />
          )}
          {!isDiary ? (
            <CollapseMenu
              className='visibility-settings'
              hasError={ERROR_LISTS.visibility.some(key => props.errors.hasOwnProperty(key))}
              menuContent={<VisibilityContainer />}
              subtitleContent={_getVisibilitySubtitle({ visibility, recurring })}
              titleContent={STRINGS.visibility}
              useErrorIcon
            />
          ) : null}
        </>
      )}

      {(isDiary || hasInstrumentUnification) && !isPersistentInstrument ? (
        <CollapseMenu
          className='timed-entry-window'
          menuContent={
            <TimedCompletionContent
              hasInstrumentUnification={hasInstrumentUnification}
              isDiary={isDiary}
              isCalendarModeChecked={isCalendarModeChecked}
              {...props}
            />
          }
          titleContent={STRINGS.timedCompletion}
          subtitleContent={_getTimedCompletionSubtitle(schedule, isDiary)}
        />
      ) : null}
      <CollapseMenu
        hasError={
          ERROR_LISTS.participant.some(key => errors.hasOwnProperty(key)) || typeof cohortSubtitleContent === 'object'
        }
        useErrorIcon
        className={`cohort-settings ${typeof cohortSubtitleContent === 'object' ? 'with-error' : ''}`}
        titleContent={STRINGS.sendTo}
        subtitleContent={
          typeof cohortSubtitleContent === 'string' ? cohortSubtitleContent : cohortSubtitleContent.subtitleContent
        }
        menuContent={
          <ParticipantManagement
            ageDisabled={ageDisabled}
            checkedCohorts={checkedCohorts}
            checkedSites={checkedSites}
            disableTrackCreation={disableTrackCreation}
            errors={errors}
            initializeCheckedSites={initializeCheckedSites}
            leafSites={leafSites}
            noTracksFeature={noTracksFeature}
            sitesList={sitesList}
            toggleSite={toggleSite}
            advancedOptions={advancedOptions}
            setAdvancedOptions={setAdvancedOptions}
            showAdvancedOption={showAdvancedOption}
            setShowAdvancedOption={setShowAdvancedOption}
          />
        }
      />
      {!isDeployedByVisit && (
        <>
          <CollapseMenu
            hasError={ERROR_LISTS.trigger.some(key => errors.hasOwnProperty(key))}
            useErrorIcon
            loading={triggersLoading}
            className='trigger-settings'
            titleContent={STRINGS.triggerAnotherItem}
            subtitleContent={`Trigger ${
              triggersLoading ? '--- items' : pluralize(visibleTriggers?.length, 'item', 'items')
            }`}
            menuContent={
              <TriggerManagement
                addDiaryNotificationTrigger={addDiaryNotificationTrigger}
                addInstrumentTrigger={addInstrumentTrigger}
                changeTriggerType={changeTriggerType}
                errors={errors}
                deleteInstrumentTrigger={deleteInstrumentTrigger}
                studyHasVirtualVisits={studyHasVirtualVisits}
                scoreOptions={scoreOptions}
                instrument={instrument}
                instrumentForm={instrumentForm}
                instrumentTriggers={instrumentTriggers}
                isDiary={isDiary}
                isSurvey={isSurvey}
                hasClinro={hasClinro}
                study={study}
                updateInstrumentTrigger={updateInstrumentTrigger}
                updateInstrumentAction={updateInstrumentAction}
                isNewInstArch={isNewInstArch}
              />
            }
          />
        </>
      )}
    </div>
  )
}

const _getVisibilitySubtitle = ({ visibility, recurring }) => {
  const visibleDaysText =
    recurring && recurring.interval !== recurring.visible_days ? `${recurring.visible_days} day(s)` : 'Every day '
  const visibilityText = visibility === 'all_day' ? ' & all day' : ' at specific times'
  return visibleDaysText + visibilityText
}

const _getCohortSubtitle = (cohort, subtitleList) => {
  const { type, filter } = cohort
  switch (type) {
    case 'age':
      const { min_age, max_age } = filter
      const minAge = min_age || min_age === 0 ? min_age : '--'
      const maxAge = max_age || max_age === 0 ? max_age : '--'
      subtitleList.push(`Participants between ${minAge} and ${maxAge} years of age`)
      break
    case 'study_group':
      subtitleList.push(`${pluralize(filter.include.length, 'track', 'tracks')}`)
      break
    case 'sites':
      subtitleList.push(`${pluralize(filter.site_ids.length, 'site', 'sites')}`)
      break
    default:
      break
  }
}

const _getCohortSubtitles = (cohort, checkedCohorts, advancedOptions = null) => {
  const subtitleList = []
  const convertedAdvancedOptions = convertAdvancedOptions(advancedOptions)
  if (convertedAdvancedOptions && convertedAdvancedOptions.length) {
    convertedAdvancedOptions.forEach(item => {
      _getCohortSubtitle(item, subtitleList)
    })
  } else {
    _getCohortSubtitle(cohort, subtitleList)
  }
  if (subtitleList.length) return subtitleList.join(', ')
  if (Object.keys(cohort).length === 0) {
    return {
      subtitleContent: 'Must assign at least one',
    }
  }
  return 'All participants'
}

const _getTimedCompletionSubtitle = (schedule, isDiary) => {
  const { submission_expiry, timeout_window = {} } = schedule
  const { submission = {} } = timeout_window
  const { value } = submission
  if (!submission_expiry || !submission)
    return isDiary ? STRINGS.timedEntryPtpsAlwaysAllowedSubDiary : STRINGS.timedEntryPtpsAlwaysAllowedSub
  const { expires_in } = submission_expiry
  return `Allow ${pluralize(convertMinToDays(value, false) || expires_in, STRINGS.day, STRINGS.days)} ${
    isDiary ? STRINGS.timedEntryPtpsAlwaysAllowedSubSuffixDiary : STRINGS.timedEntryPtpsAlwaysAllowedSubSuffix
  }`
}

DeploymentOptions.propTypes = {
  addDiaryNotificationTrigger: PropTypes.func,
  addInstrumentTrigger: PropTypes.func,
  ageDisabled: PropTypes.bool,
  clearPrevalidatedError: PropTypes.func,
  changeTriggerType: PropTypes.func,
  checkedSites: PropTypes.objectOf(PropTypes.string),
  deleteInstrumentTrigger: PropTypes.func,
  initializeCheckedSites: PropTypes.func,
  instrument: PropTypes.shape({ id: PropTypes.string }),
  instrumentForm: PropTypes.shape({ id: PropTypes.string }),
  instruments: PropTypes.arrayOf(PropTypes.array),
  instrumentTriggers: PropTypes.objectOf(PropTypes.array),
  isNewInstArch: PropTypes.bool,
  isDiary: PropTypes.bool,
  isSurvey: PropTypes.bool,
  hasClinro: PropTypes.bool,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  noTracksFeature: PropTypes.bool,
  setIsInInstrumentLoopState: PropTypes.func,
  sitesList: PropTypes.arrayOf(PropTypes.array),
  study: PropTypes.shape({
    config: PropTypes.shape({
      clinro: PropTypes.object,
    }),
  }),
  toggleSite: PropTypes.func,
  triggersLoading: PropTypes.bool,
  updateInstrumentTrigger: PropTypes.func,
  updateInstrumentAction: PropTypes.func,
  updatePrevalidatedErrors: PropTypes.func,
  schedule: PropTypes.shape({
    chain_deployment_info: PropTypes.object,
    cohort: PropTypes.object,
    deploy: PropTypes.object,
    expire: PropTypes.object,
    recurring: PropTypes.object,
  }),
  scoreOptions: PropTypes.array,
  updateTriggerError: PropTypes.func,
  advancedOptions: PropTypes.object,
  setAdvancedOptions: PropTypes.func,
  showAdvancedOption: PropTypes.bool,
  setShowAdvancedOption: PropTypes.func,
}

export default DeploymentOptions
