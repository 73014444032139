import React from 'react'
import { Loader, Input, Popup, Button } from 'components/UIElements'
import { exclamationIcon } from 'assets/assets'
import { PW_REQUIREMENTS } from 'utils/misc'
import PropTypes from 'prop-types'

const ChangePasswordForm = ({
  changePassword,
  requestLoading,
  saveUser,
  togglePasswordForm,
  updateNewPassword,
  userErrors,
}) => {
  const { noOldPasswordErr, pwSecurityError, pwNoMatchError, pwMeetCriteriaError, wrongPasswordError } = userErrors
  return (
    <form className='change-password-form border-radius eight' onSubmit={saveUser}>
      {requestLoading && <Loader inContainer />}
      <div className='change-password-header'>
        <h5>Update Password</h5>
        <Popup hover position='top' trigger={<i className='clickable fas fa-info-circle' />}>
          <div>
            {PW_REQUIREMENTS.map((req, idx) => {
              return <p key={idx}>{req.text}</p>
            })}
          </div>
        </Popup>
      </div>
      {pwSecurityError && (
        <div className='user-error flexed start-justified'>
          {exclamationIcon()}
          <div className='error-text'>{pwSecurityError}</div>
        </div>
      )}
      <Input
        disabled={requestLoading}
        hasError={(noOldPasswordErr && !changePassword.oldPassword) || !!wrongPasswordError}
        errorText={(!changePassword.oldPassword && noOldPasswordErr) || wrongPasswordError}
        label='Old Password'
        onChange={value => updateNewPassword('oldPassword', value)}
        placeholder='Enter old password'
        type='password'
        value={changePassword.oldPassword}
      />
      <Input
        disabled={requestLoading}
        hasError={!!pwMeetCriteriaError || !!pwNoMatchError || !!pwSecurityError}
        errorText={pwMeetCriteriaError || pwNoMatchError}
        label='New Password'
        onChange={value => updateNewPassword('newPassword1', value)}
        placeholder='Enter new password'
        type='password'
        value={changePassword.newPassword1}
      />
      <Input
        disabled={requestLoading}
        hasError={!!pwNoMatchError || !!pwSecurityError}
        errorText={pwNoMatchError}
        label='Confirm New Password'
        onChange={value => updateNewPassword('newPassword2', value)}
        placeholder='Retype new password'
        type='password'
        value={changePassword.newPassword2}
      />
      <div className='user-pw-buttons flexed end-justified'>
        <Button disabled={requestLoading} type='button' onClick={togglePasswordForm} content='Cancel' grey />
        <Button disabled={requestLoading} type='submit' content='Update' />
      </div>
    </form>
  )
}

ChangePasswordForm.propTypes = {
  changePassword: PropTypes.object,
  requestLoading: PropTypes.bool,
  saveUser: PropTypes.func,
  togglePasswordForm: PropTypes.func,
  updateNewPassword: PropTypes.func,
  userErrors: PropTypes.objectOf(PropTypes.string),
}

export default ChangePasswordForm
