import React from 'react'
import PropTypes from 'prop-types'
import { getStrictRegexFromTerm } from 'utils/search'
import { onEnterPress } from 'utils/misc'
import { AutocompleteTags, Checkbox, Loader, Input } from 'components/UIElements'
import 'styles/d3/autocompleteSearch.scss'

const TEMP_PLACEHOLDER = 'TEMP_PLACEHOLDER'
class AutocompleteSearch extends React.Component {
  constructor(props) {
    super(props)
    const { initialValue, list, noInitialValue } = this.props
    this.state = { searchTerm: initialValue, isFocused: false, filteredList: list }
    this.noMatches = true
    this.originalInitialValue = noInitialValue ? TEMP_PLACEHOLDER : ''
    this.autoCompleteSearchEl = null
  }

  componentDidMount() {
    this.blurListener = e => {
      const { initialValue } = this.props
      if (!this.autoCompleteSearchEl.contains(e.target)) {
        this.setState({
          isFocused: false,
          searchTerm: initialValue,
        })
      }
    }
    document.addEventListener('mouseup', this.blurListener)
    this.onUpdateFilteredList()
  }

  componentDidUpdate(prevProps) {
    const { initialValue, isDropdown, list } = this.props
    if (prevProps.list !== list) {
      this.onUpdateFilteredList()
    }
    if (isDropdown) {
      /**
       * to behave like a dropdown, anytime the initialValue
       * changes, the value in the input is immmediately changed
       */
      if (prevProps.initialValue !== initialValue) {
        this.originalInitialValue = initialValue
        this.setState({
          searchTerm: initialValue,
        })
      }
    } else if (!this.originalInitialValue && initialValue) {
      this.originalInitialValue = initialValue
      this.setState({
        searchTerm: initialValue,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.blurListener)
  }

  onFocus = () => {
    const { isFocused } = this.state
    if (!isFocused)
      this.setState({
        isFocused: true,
        searchTerm: '',
      })
  }

  clearSearch = () => this.setState({ searchTerm: '' })

  onUpdateFilteredList = () => {
    const { list = [], tagsProps } = this.props
    const { searchTerm } = this.state

    const tagsArray = tagsProps?.tags.map(tags => tags.id)
    const regex = getStrictRegexFromTerm(searchTerm)

    const filteredList = list.filter(listItem => {
      const isMatch = listItem.text.match(regex)
      /**
       * If we are using autocomplete tags, we need to filter
       * out already tagged items in the dropdown list
       */
      const notTagged = !tagsArray?.includes(listItem.key)
      return isMatch && notTagged
    })
    filteredList.sort((a, b) => a.text.localeCompare(b.text))
    this.setState({ filteredList })
  }

  renderList = () => {
    const {
      allSitesOnly,
      checked = {},
      disabled,
      filter,
      focusOnToggle,
      isDropdown,
      list = [],
      shouldReturnItem,
      tagsProps,
      toggleItem,
    } = this.props
    const { filteredList, isFocused, searchTerm } = this.state
    const regex = getStrictRegexFromTerm(searchTerm)
    this.noMatches = true

    /**
     * If this component has tagsProps (meaning tags that can be toggled with enter
     * and backspace press actions), then we will use the filtered list in the state,
     * If it is a normal autocomplete search, we will just use the list passed in the
     * props and simply hide list items that don't match the search term, instead of
     * actually filtering them out of the list.
     */
    const _list = tagsProps ? filteredList : list

    return _list.map((item, idx) => {
      const { treeLevel, key, path } = item

      const checkboxIsDisabled = disabled || (filter ? !filter.includes(key) : false) || allSitesOnly

      const onClick = () => {
        if (focusOnToggle) this.inputRef.focus() // Focus back to input if prop is true
        if (shouldReturnItem) {
          toggleItem(item)
        } else {
          toggleItem(key, path, treeLevel)
        }
        if (isDropdown) this.setState({ isFocused: false, searchTerm: item.text })
        else this.setState({ searchTerm: '' })
      }

      const isMatch = item.text.match(regex)
      const showItem = isFocused && (searchTerm === '' || isMatch)
      if (isMatch && this.noMatches) this.noMatches = false
      const _checked = item.key in checked
      const className = `list-item${treeLevel ? ` tree-level-${treeLevel}` : ''}${_checked ? ' checked' : ''}${
        !showItem ? ' hidden' : ''
      }`

      return (
        <li
          onKeyPress={e => onEnterPress(e, onClick)}
          onMouseDown={this.onMouseDown}
          className={className}
          key={`${item.key}${idx}`}>
          <Checkbox
            tabbable={!checkboxIsDisabled}
            /*
             * allSitesOnly is true when this component is used for site selection in the user
             * page and a study coordinator is selected
             */
            disabled={checkboxIsDisabled}
            label={item.text}
            checked={_checked}
            onClick={onClick}
          />
        </li>
      )
    })
  }

  placeCursorInOrigPos = pos => {
    const inputRef = this.inputRef?.input
    setTimeout(() => inputRef.setSelectionRange(pos, pos), 0)
  }

  onEnterPress = () => {
    const { addItemOnEnter, createItem, shouldReturnItem, toggleItem } = this.props
    const { isFocused, filteredList, searchTerm } = this.state
    const item = filteredList[0]
    if (!addItemOnEnter) return
    if (isFocused) {
      if (!searchTerm) {
        if (filteredList.length === 0) return
        if (shouldReturnItem) toggleItem(item)
        else {
          const { key, path, treeLevel } = item
          toggleItem(key, path, treeLevel)
        }
      } else {
        if (filteredList.length !== 0) {
          if (shouldReturnItem) toggleItem(item)
          else {
            const { key, path, treeLevel } = item
            toggleItem(key, path, treeLevel)
          }
        } else {
          createItem(searchTerm)
        }
        this.clearSearch()
      }
    }
  }

  onBackspacePress = e => {
    const { deleteItemOnBackspace, shouldReturnItem, toggleItem, tagsProps, onBackspacePress } = this.props
    const { isFocused, searchTerm } = this.state
    if (onBackspacePress) onBackspacePress(e)
    if (!deleteItemOnBackspace || !tagsProps) return null
    const { tags } = tagsProps
    if (tags.length === 0) return null
    if (isFocused && searchTerm === '') {
      const item = tags[tags.length - 1]
      if (shouldReturnItem) toggleItem(item)
      else {
        const { id, key } = item
        if (key) toggleItem(key)
        toggleItem(id)
      }
    }
  }

  generateSearchTermForTags = val => {
    const newVal = val.replace(/[\[\]]/g, '') // prevents '[' and ']' in tag names
    return newVal.slice(0, 50)
  }

  renderInput = () => {
    const {
      autocomplete,
      disabled,
      errorText,
      hasError,
      isDropdown,
      onChange,
      placeholder,
      tagsProps,
      id,
      useCaretIcon,
    } = this.props
    const { isFocused, searchTerm } = this.state
    return (
      <Input
        ref={el => {
          this.inputRef = el
        }}
        /*
         * We will only disable the input here if the autocompletesearch is in
         * dropdown mode. When this component is not acting as a dropdown, we
         * would still like to be able to see the options as read only.
         */
        id={id}
        onEnterPress={this.onEnterPress}
        onBackspacePress={this.onBackspacePress}
        key='autocomplete-tag-input'
        autocomplete={autocomplete || '-'}
        disabled={disabled && (isDropdown || useCaretIcon)}
        errorText={!isFocused && errorText}
        hasError={!isFocused && hasError}
        onFocus={this.onFocus}
        onChange={val =>
          this.setState(
            {
              searchTerm: tagsProps ? this.generateSearchTermForTags(val) : val,
            },
            () => {
              onChange && onChange()
              this.onUpdateFilteredList()
            },
          )
        }
        placeholder={placeholder}
        value={searchTerm === TEMP_PLACEHOLDER ? '' : searchTerm}
      />
    )
  }

  render() {
    const {
      absolutePositionedList = false,
      className,
      disabled,
      hideSearchIcon,
      isDropdown,
      loading,
      renderBottomItem,
      renderTopItem,
      tagsProps,
      useCaretIcon,
    } = this.props
    const { isFocused, searchTerm } = this.state
    const inputEl = this.renderInput()
    return (
      <div
        onClick={e => {
          if (tagsProps) {
            e.stopPropagation()
            this.inputRef.focus()
          }
        }}
        ref={el => {
          this.autoCompleteSearchEl = el
        }}
        className={`autocomplete-search${className ? ` ${className}` : ''}${isDropdown ? ' is-dropdown' : ''}`}>
        {tagsProps && <AutocompleteTags inputRef={this.inputRef} {...tagsProps} inputEl={inputEl} />}
        {!tagsProps && inputEl}
        {loading && <Loader inContainer size={24} />}
        {isDropdown || useCaretIcon ? (
          <i
            style={{ zIndex: 10 }}
            className={`${isFocused ? 'fas fa-caret-up' : 'fas fa-caret-down'}`}
            onClick={e => {
              if (isFocused) e.stopPropagation()
              this.setState({ isFocused: !isFocused })
            }}
          />
        ) : (
          !hideSearchIcon && <i className='fas fa-search' />
        )}
        <ul
          className={`autocomplete-list${isFocused ? '' : ' hidden'}${isDropdown ? ' is-dropdown' : ''}${
            absolutePositionedList ? ' pos abs width full' : ''
          }`}>
          {renderTopItem && searchTerm !== '' && renderTopItem(searchTerm)}
          {this.renderList()}
          {this.noMatches && !renderTopItem && !renderBottomItem && !disabled && (
            <li className='list-item no-matching'>No Matching Results</li>
          )}
          {renderBottomItem && renderBottomItem(searchTerm, this.clearSearch, this.inputRef)}
        </ul>
      </div>
    )
  }
}

AutocompleteSearch.defaultProps = {
  initialValue: '',
}

AutocompleteSearch.propTypes = {
  autocomplete: PropTypes.string,
  absolutePositionedList: PropTypes.bool,
  addItemOnEnter: PropTypes.bool,
  allSitesOnly: PropTypes.bool,
  checked: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  createItem: PropTypes.func,
  deleteItemOnBackspace: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.number),
  focusOnToggle: PropTypes.bool, // if true, we focus immediately back to input after toggling an item
  hasError: PropTypes.bool,
  hideSearchIcon: PropTypes.bool,
  initialValue: PropTypes.string,
  isDropdown: PropTypes.bool,
  label: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  noInitialValue: PropTypes.bool,
  onBackspacePress: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  preventChars: PropTypes.arrayOf(PropTypes.string),
  renderBottomItem: PropTypes.func,
  renderTopItem: PropTypes.func,
  selectionText: PropTypes.string,
  shouldReturnItem: PropTypes.bool, // if true, returns entire item object instead of just the key in toggleItem
  tagsProps: PropTypes.shape({
    toggledFunc: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.object),
  }),
  toggleItem: PropTypes.func,
  useCaretIcon: PropTypes.bool,
  id: PropTypes.string,
}

export default AutocompleteSearch
