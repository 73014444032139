import request from 'utils/request'
import { modalActions } from 'store/modal'
import { _deleteStudy, uploadCustomLogo } from '../../routes/CreateStudy/modules/CreateStudy'

const FETCHED_STUDIES = 'FETCHED_STUDIES'
const DELETE_STUDY_PREVIEW_LOGO = 'DELETE_STUDY_PREVIEW_LOGO'
const ADD_STUDY_PREVIEW_LOGO = 'ADD_STUDY_PREVIEW_LOGO'

export const uploadLogo = (formData, { method }) => {
  const studyId = formData.get('study_id')
  const url = `/control/studies/${studyId}/white-label-logo`
  return dispatch => {
    return dispatch(
      request({
        method,
        url,
        contentType: 'multipart/form-data',
        body: formData,
        loadingKey: 'mediaUpload',
        hasLoader: true,
        success: () => {
          dispatch(modalActions.closeModal())
          return dispatch(
            request({
              method: 'GET',
              url,
              success: payload => {
                dispatch(uploadCustomLogo(payload.url))
                return payload
              },
              catchMessage: 'There was a problem getting the logo.',
              skipNotySuccess: true,
            }),
          )
        },
        successMessage: `Logo successfully ${method === 'POST' ? 'uploaded' : 'changed'}.`,
        catchMessage: `There was a problem ${
          method === 'POST' ? 'uploading' : 'changing'
        } the logo. Please try again later.`,
      }),
    )
  }
}

const deletePreviewLogo = studyId => {
  return {
    type: DELETE_STUDY_PREVIEW_LOGO,
    studyId,
  }
}

const deleteLogo = studyId => {
  const url = `/control/studies/${studyId}/white-label-logo`
  return dispatch => {
    return dispatch(
      request({
        method: 'DELETE',
        url,
        success: () => dispatch(deletePreviewLogo(studyId)),
        successMessage: 'Logo successfully deleted.',
        catchMessage: 'There was a problem deleting the logo. Please try again later.',
      }),
    )
  }
}

const addPreviewLogo = ({ studyId, url }) => {
  return {
    type: ADD_STUDY_PREVIEW_LOGO,
    studyId,
    url,
  }
}

export const fetchStudies = (hasLoader = true) => {
  return dispatch => {
    return dispatch(
      request({
        method: 'GET',
        url: '/control/admin/studies',
        errorMessage: 'error fetching studies',
        success: json => {
          dispatch({
            type: FETCHED_STUDIES,
            payload: json.studies,
          })
        },
        hasLoader,
        forceLoader: hasLoader,
      }),
    )
  }
}

const deleteStudy = id => dispatch => {
  return dispatch(
    _deleteStudy(id, () => {
      return dispatch(fetchStudies())
    }),
  )
}

export const studies = (state = [], action) => {
  switch (action.type) {
    case FETCHED_STUDIES:
      return action.payload

    case DELETE_STUDY_PREVIEW_LOGO: {
      const newState = [...state]
      return newState.map(study => {
        if (study.id === action.studyId) delete study['custom-logo']
        return study
      })
    }

    case ADD_STUDY_PREVIEW_LOGO: {
      const newState = [...state]
      return newState.map(study => {
        if (study.id === action.studyId && action.url) study['custom-logo'] = action.url
        return study
      })
    }

    default:
      return state
  }
}

export const studiesAction = {
  closeModal: modalActions.closeModal,
  openModal: modalActions.openModal,
  deleteLogo,
  addPreviewLogo,
  uploadLogo,
  fetchStudies,
  deleteStudy,
}
export default studies
