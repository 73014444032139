import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import reducer, { announcementsActions } from './modules/Announcements'
import AnnouncementsPage from './containers/AnnouncementsContainer'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'
import { actions as instrumentsActions } from '../../../Instruments/routes/InstrumentsPage/modules/Instruments'

import { fetchLocales } from '../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import ptpsReducer, { fetchCohorts } from '../../../Participants/routes/ParticipantsPage/modules/Participants'
// Sync route definition for the annoucements list page
export default store => {
  return {
    getComponent(nextState, cb) {
      require.ensure(
        [],
        () => {
          const { params } = nextState
          const { studyID } = params

          // inject the announcements reducer to the store, if it doesn't already exist
          injectReducers(store, {
            announcements: reducer,
            sites: sitesReducer,
            participants: ptpsReducer,
          })
          // set the subroute state (for the nav bar)
          store.dispatch(setSubroute('communication'))

          const { currentStudy } = store.getState().study
          const isNewInstArch = currentStudy?.config?.instrument_architecture_version >= 2

          setPageTitle(`Announcements | Study ${studyID} | ${currentStudy.platform_name}`)
          // store.dispatch(fetchLocales())
          Promise.all([store.dispatch(fetchLocales()), store.dispatch(fetchCohorts(studyID, false))]).then(() => {
            if (isNewInstArch) {
              store.dispatch(instrumentsActions.fetchInstruments({ studyID, isAnnouncements: true }))
            } else {
              // pre-fetch announcements before the component is retrieved and rendered
              store.dispatch(announcementsActions.fetchAnnouncements(nextState.params.studyID))
            }
          })
          // prevent the fetch sms call (GET ../sms) in a study with SMS disabled
          // studies without a config object still can fetch SMS
          const { config } = currentStudy
          let isSmsEnabled = !config
          if (config) {
            const { communication } = config
            isSmsEnabled = communication?.sms.admin.enabled
          }
          if (isSmsEnabled) {
            store.dispatch(announcementsActions.fetchAnnouncements(nextState.params.studyID, false))
          }

          Promise.all([store.dispatch(fetchSites(studyID, false))])
          // UserIsAuthenticated wrapper redirects user to the login page if credentials are invalid
          // UserHasScope wrapper redirects user to the permission denied page if user does not have the correct permisions
          cb(null, UserIsAuthenticated(UserHasValidPassword(UserHasScope(['xp'], AnnouncementsPage, false, studyID))))
        },
        'communication',
      )
    },
  }
}
