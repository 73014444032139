import { connect } from 'react-redux'
import React from 'react'
import { setNavBarProps } from 'store/navbar'
import { modalActions } from 'store/modal'
import { Loader } from 'components/UIElements'
import { USER_SCOPES } from 'utils/constants'
import { getUserRoleByStudy, getUserScopeByStudy, hasPiiRole, disableScope } from 'utils/misc'
import CreateCohortPage from '../components/CreateCohortPage'
import { actions } from '../modules/cohort'

const mapState = state => {
  const { user, study, studyLock } = state
  const studyID = study.currentStudy.id
  const userScope = getUserScopeByStudy(user, study.currentStudy)
  const currentUserRole = getUserRoleByStudy(user, studyID)

  return {
    ...state.cohort.cohort,
    canWriteCohort: userScope.includes(USER_SCOPES.wxc.code),
    canAddPtp: userScope.includes(USER_SCOPES.wp.code),
    checkedPtps: state.participants.checkedPtps,
    disableTrackCreation: disableScope({ study, user, scope: USER_SCOPES.wxc.code }),
    isCreate: state.location.pathname.includes('create'),
    loading: state.loading.general,
    studyID,
    studyLock,
    hasPiiRole: hasPiiRole(currentUserRole),
  }
}

const mapActions = Object.assign({}, actions, {
  setNavBarProps,
  resetCheckedPtps: require('../../ParticipantsPage/modules/Participants').actions.resetCheckedPtps,
  ...modalActions,
})

export default connect(
  mapState,
  mapActions,
)(props => {
  if (props.loading) return <Loader />
  return <CreateCohortPage {...props} />
})
