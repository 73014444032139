import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import announcementReducer, { announcementActions } from './modules/Announcement'
import ptpsReducer from '../../../Participants/routes/ParticipantsPage/modules/Participants'
import ptpReducer, { fetchLocales } from '../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import AnnouncementView from './containers/AnnouncementContainer'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'
// Sync route definition
export default store => ({
  path: 'announcement/:announcementID',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducers(store, {
          announcementReducer,
          participants: ptpsReducer,
          participantReducer: ptpReducer,
          sites: sitesReducer,
        })

        const { params } = nextState
        const { studyID, announcementID } = params

        const { currentStudy } = store.getState().study
        const isNewInstArch = currentStudy?.config?.instrument_architecture_version >= 2

        store.dispatch(
          announcementActions.initializeAnnouncementPage({
            studyID,
            communicationId: announcementID,
            isInstrument: isNewInstArch,
          }),
        )
        Promise.all([store.dispatch(fetchSites(studyID, false, true)), store.dispatch(fetchLocales())])
        // Return Component
        cb(null, UserIsAuthenticated(UserHasValidPassword(UserHasScope(['xp'], AnnouncementView, false, studyID))))
      },
      'communication/announcement/:announcementID',
    )
  },
})
