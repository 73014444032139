const START_SENDING = 'START_SENDING'
const STOP_SENDING = 'STOP_SENDING'
const RESET_SENDING = 'RESET_SENDING'

export const startSender = (isDeploy = false) => {
  return {
    type: START_SENDING,
    isDeploy,
  }
}

export const stopSender = (isDeploy = false) => {
  return {
    type: STOP_SENDING,
    isDeploy,
  }
}

export const resetSender = (isDeploy = false) => {
  return {
    type: RESET_SENDING,
    isDeploy,
  }
}

const sending = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case START_SENDING:
      return { ...newState, isDeploy: action.isDeploy, sendingState: true, sentState: false }
    case STOP_SENDING:
      return { ...newState, isDeploy: action.isDeploy, sendingState: false, sentState: true }
    case RESET_SENDING:
      return { ...newState, isDeploy: action.isDeploy, sendingState: false, sentState: false }

    default:
      return state
  }
}

export const sendingActions = {
  startSender,
  stopSender,
  resetSender,
}

export default sending

