import React from 'react'
import PropTypes from 'prop-types'
import { Button, Loader, SearchInput, StickyHeader } from 'components/UIElements'
import { CURRENCY_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import { pluralize } from 'utils/misc'
import { isObjEmpty } from 'utils/object'
import PendingDonationsTable from './PendingDonationsTable'
import CharityInfoPreviewContent from './CharityInfoPreviewContent'
import generatePendingPaymentsMap from '../../utils/paymentUtils'

const CharityInfo = ({ charityInfo, onClick, pendingPaymentsLoading }) => {
  const noCharityInfo = isObjEmpty(charityInfo)
  const {
    donations_desc: charityDesc = 'No description',
    donations_image: imgSrc,
    donations_title: charityTitle = 'No Charity Selected',
  } = charityInfo

  return (
    <Button
      onClick={() => onClick({ charityDesc, charityTitle, imgSrc })}
      disabled={noCharityInfo}
      noStyling
      className='charity-info flexed'>
      {pendingPaymentsLoading && noCharityInfo ? (
        <Loader inContainer />
      ) : (
        <>
          {imgSrc ? (
            <img src={imgSrc} alt='charity-logo' />
          ) : (
            <div className='preview-image-placeholder'>
              <strong>No Image</strong>
            </div>
          )}
          <div className='charity-desc'>
            <h5>{charityTitle}</h5>
            <p>{charityDesc}</p>
          </div>
        </>
      )}
    </Button>
  )
}

const ApprovalPendingTotal = props => {
  const { approveAllPayments, currency, noPendingPayments, num = 0, pendingPaymentsLoading, studyLock } = props
  const currencySymbol = CURRENCY_MAP[currency]

  return (
    <div className='approve-pending flexed'>
      <Button
        disabled={pendingPaymentsLoading || noPendingPayments || studyLock}
        onClick={() => approveAllPayments(undefined, undefined, undefined, true)}>
        Mark all as done
      </Button>
      <div>
        {pendingPaymentsLoading ? <h4>---</h4> : <h4>{currency ? `${currencySymbol}${num / 100.0}` : num}</h4>}
        <p>TOTAL PENDING</p>
      </div>
    </div>
  )
}

const DonationsTab = props => {
  const {
    approveAllPayments,
    canEditPayments,
    charityInfo,
    currency,
    offsets,
    openModal,
    pendingDonations,
    pendingDonationsTotal,
    pendingPaymentsLoading,
    study,
    studyLock,
  } = props

  const numPendingPayments = pendingDonations.length

  const noPendingPayments = numPendingPayments === 0

  const openCharityPreview = ({ charityDesc, charityTitle, imgSrc }) => {
    openModal({
      className: MODAL_CLASSES_MAP.charityPreview,
      closeOnBackgroundClick: true,
      content: (
        <CharityInfoPreviewContent
          {...props}
          charityName={charityTitle}
          charityDescription={charityDesc}
          imgUrl={imgSrc}
        />
      ),
      noButtons: true,
    })
  }

  return (
    <div className='pending tab'>
      <div className='flexed donation-bar'>
        <CharityInfo
          charityInfo={charityInfo}
          onClick={openCharityPreview}
          pendingPaymentsLoading={pendingPaymentsLoading}
          studyId={study.id}
        />
        {canEditPayments && (
          <ApprovalPendingTotal
            approveAllPayments={() =>
              approveAllPayments(
                study.id,
                generatePendingPaymentsMap(pendingDonations),
                false /* isGiftcards */,
                true /* isDonation */,
              )
            }
            currency={currency}
            num={pendingDonationsTotal}
            openModal={openModal}
            pendingPaymentsLoading={pendingPaymentsLoading}
            noPendingPayments={noPendingPayments}
            studyLock={studyLock}
          />
        )}
      </div>
      <StickyHeader offset={offsets ? offsets[0] : 0}>
        <div className='flexed bar'>
          <div className='flexed start-justified count-search'>
            {!pendingPaymentsLoading && (
              <>
                <SearchInput
                  placeholder={`Search ${pluralize(numPendingPayments, 'donation', 'donations', true)}...`}
                  leftJustified
                />
                <p>{`(${pluralize(numPendingPayments, 'total donation', 'total donations', true)})`}</p>
              </>
            )}
          </div>
        </div>
      </StickyHeader>
      {pendingPaymentsLoading ? (
        <Loader inContainer className='pending-payments-table-loader' />
      ) : (
        <>
          <PendingDonationsTable {...props} />
          {noPendingPayments && (
            <div className='flexed column no-pending-payments'>
              <p className='no-margin'>There are no pending donations</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

ApprovalPendingTotal.propTypes = {
  approveAllPayments: PropTypes.func,
  currency: PropTypes.string,
  noPendingPayments: PropTypes.bool,
  num: PropTypes.number,
  pendingPaymentsLoading: PropTypes.bool,
}
CharityInfo.propTypes = {
  charityInfo: PropTypes.shape({
    donations_desc: PropTypes.string,
    donations_image: PropTypes.string,
    donations_title: PropTypes.string,
  }),
  pendingPaymentsLoading: PropTypes.bool,
}

DonationsTab.propTypes = {
  approveAllPayments: PropTypes.func,
  canEditPayments: PropTypes.bool,
  charityInfo: PropTypes.objectOf(PropTypes.string),
  currency: PropTypes.string,
  hasTreasureChest: PropTypes.bool,
  language: PropTypes.string,
  offsets: PropTypes.arrayOf(PropTypes.number),
  openModal: PropTypes.func,
  pendingDonations: PropTypes.arrayOf(PropTypes.array),
  pendingDonationsTotal: PropTypes.number,
  pendingPaymentsLoading: PropTypes.bool,
  study: PropTypes.shape({
    id: PropTypes.number,
  }),
  studyLock: PropTypes.bool,
}

CharityInfo.propTypes = {
  onClick: PropTypes.func,
}

export default DonationsTab
