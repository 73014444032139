import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components/UIElements'
import { createMomentObject, dayPickerConfig } from 'utils/time'
import { DateUtils } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { formatDate, parseDate } from 'react-day-picker/moment'

const PastDates = ({ classNames, children, selectedDay, onResetDates, onAllPastDates, ...props }) => {
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={`${classNames.overlay} past-overlay`}>
        {children}
        <div className='flexed column'>
          {selectedDay[1].to && <Button noStyling className='past' content='All past dates' onClick={onAllPastDates} />}
          <Button noStyling className='all-dates' content='Allow all dates' onClick={onResetDates} />
        </div>
      </div>
    </div>
  )
}

const FutureDates = ({ classNames, children, selectedDay, onResetDates, onAllFutureDates, ...props }) => {
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {children}
        <div className='flexed column'>
          {selectedDay[0] && (
            <Button noStyling className='future' content='All future dates' onClick={onAllFutureDates} />
          )}
          <Button noStyling className='all-dates' content='Allow all dates' onClick={onResetDates} />
        </div>
      </div>
    </div>
  )
}

const DatePickerRange = ({
  disabled,
  isLastQuestion,
  item,
  onAllFutureDates,
  onAllPastDates,
  onFromDateChange,
  onToDateChange,
  onResetDates,
}) => {
  const [openInput, setOpenInput] = useState('')
  const toPickerRef = useRef()
  const { min, max } = item.input_validation.absolute_date
  const isButtonVisible = !!(max && min) || (openInput === 'to' && !!max) || (openInput === 'from' && !!min)

  // min and max need to be converted into Date objects again when a date or datetime question is duplicated
  const minDate = min ? createMomentObject(min).toDate() : ''
  const maxDate = max ? createMomentObject(max).toDate() : ''

  const isPastDate = day => {
    if (!max || (max && min)) return false
    return DateUtils.isDayBefore(day, maxDate)
  }
  const isFutureDate = day => {
    if (!min || (max && min)) return false
    return DateUtils.isDayAfter(day, minDate)
  }

  const modifiers = { start: minDate, end: maxDate, isPastDate, isFutureDate }

  const pickerProps = {
    disabled,
    format: dayPickerConfig.format,
    formatDate,
    numberOfMonths: 2,
    parseDate,
  }
  const dayPickerProps = {
    selectedDays: [minDate, { from: minDate, to: maxDate }],
    numberOfMonths: 2,
  }

  const addProps = (Component, isFuture) => {
    const WrappedComponent = props => {
      return isFuture ? (
        <Component {...props} onResetDates={onResetDates} onAllFutureDates={onAllFutureDates} />
      ) : (
        <Component {...props} onResetDates={onResetDates} onAllPastDates={onAllPastDates} />
      )
    }
    return WrappedComponent
  }

  return (
    <div
      className={`date-picker flexed ${isLastQuestion ? 'last-question' : ''} ${isButtonVisible ? 'with-button' : ''} ${
        disabled ? 'disabled' : ''
      }`}>
      <DayPickerInput
        {...pickerProps}
        dayPickerProps={{
          isFrom: true,
          ...dayPickerProps,
          hideOnDayClick: false,
          disabledDays: { after: maxDate },
          locale: dayPickerConfig.locale, 
          localeUtils: dayPickerConfig.localeUtils,
          onDayClick: () => toPickerRef.current.getInput().focus(),
          toMonth: new Date(maxDate),
          modifiers,
        }}
        onDayChange={day => onFromDateChange(day)}
        onDayPickerShow={() => setOpenInput('to')}
        overlayComponent={addProps(PastDates)}
        placeholder='All Past Dates'
        value={minDate}
      />
      {` — `}
      <span className='date-picker-to'>
        <DayPickerInput
          {...pickerProps}
          dayPickerProps={{
            ...dayPickerProps,
            disabledDays: { before: minDate },
            locale: dayPickerConfig.locale, 
            localeUtils: dayPickerConfig.localeUtils,
            fromMonth: new Date(minDate),
            month: minDate,
            modifiers,
          }}
          overlayComponent={addProps(FutureDates, true)}
          onDayChange={day => onToDateChange(day)}
          onDayPickerShow={() => setOpenInput('from')}
          placeholder='All Future Dates'
          ref={toPickerRef}
          value={maxDate}
        />
      </span>
    </div>
  )
}

DatePickerRange.propTypes = {
  disabled: PropTypes.bool,
  isLastQuestion: PropTypes.bool,
  item: PropTypes.object,
  onFromDateChange: PropTypes.func,
  onToDateChange: PropTypes.func,
  onAllFutureDates: PropTypes.func,
  onAllPastDates: PropTypes.func,
  onResetDates: PropTypes.func,
}
PastDates.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.objectOf(PropTypes.string),
  onAllFutureDates: PropTypes.func,
  onAllPastDates: PropTypes.func,
  onResetDates: PropTypes.func,
}
FutureDates.propTypes = PastDates.propTypes

export default DatePickerRange
