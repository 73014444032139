import React from 'react'
import { doNotEnterIcon, deniedIcon } from 'assets/assets'

export const statusMap = {
  cancelled: { text: 'Cancelled', icon: <i className='fas fa-times' /> },
  did_not_meet: { text: 'Did Not Meet', icon: deniedIcon },
  error: { text: 'Error', icon: doNotEnterIcon },
  missed_call: { text: 'Missed Call', icon: deniedIcon },
  no_show: { text: 'No Show', icon: deniedIcon },
  pending: { text: 'Pending...', icon: null },
  onSchedule: { text: 'On Schedule', icon: null },
  reschedule: { text: 'Reschedule', icon: null },
  success: { text: 'Success', icon: <i className='fas fa-check' /> },
}

export const VISIT_STATUS_TYPE_MAP = {
  cancelled: 'cancelled',
  didNotMeet: 'did_not_meet',
  error: 'error',
  missedCall: 'missed_call',
  noShow: 'no_show',
  success: 'success',
}
export const VISIT_STATUS_EVENT_TEXT_MAP = {
  didNotMeet: 'Participant and Clinician did not meet',
  error: 'There was an error connecting to a room',
  missedCall: 'No clinicians answered the virtual visit request',
  noShow: {
    noPtp: 'Participant did not show up to call',
    noClin: 'Clninician did not show up to call',
    noBoth: 'Nobody showed up to the call',
  },
}
