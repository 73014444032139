import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import TrainingPageRoute from './routes/TrainingPage'
import UploadTrainingRoute from './routes/UploadTraining'

export default store => {
  return {
    path: 'training',
    exact: true,
    getIndexRoute(location, cb) {
      const { study } = store.getState()
      setPageTitle(`Training | Study ${study.currentStudy.id} | ${study.currentStudy.platform_name}`)
      store.dispatch(setSubroute('training'))
      cb(null, TrainingPageRoute(store))
    },
    getChildRoutes(location, cb) {
      store.dispatch(setSubroute('training'))
      cb(null, [UploadTrainingRoute(store)])
    },
  }
}
