import request from 'utils/request'
import moment from 'moment'
import { combineReducers } from 'redux'
import { _secondsToTimeString } from 'utils/misc'
import { fromUTCMidnightSecondsTS } from 'utils/time'
import { DATE_FORMAT_MAP } from 'utils/constants'
import { INSIGHTS_VENDORS } from '../utils/utils'
import { browserHistory } from 'react-router'

// constants //

const SET_HEADER_DATA = 'SET_HEADER_DATA'
const SET_HEADER_DATUM = 'SET_HEADER_DATUM'
const SET_COMPLIANCE_DATA = 'SET_COMPLIANCE_DATA'
const RESET_STUDY_DASHBOARD = 'RESET_STUDY_DASHBOARD'
const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE'
const SET_EVENT_DATA = 'SET_EVENT_DATA'
const TOGGLE_LOADER_COMPLIANCE = 'TOGGLE_LOADER_COMPLIANCE'
const TOGGLE_LOADER_EVENTS = 'TOGGLE_LOADER_EVENTS'
const SET_PERFORMANCE_DATA = 'SET_PERFORMANCE_DATA'
const TOGGLE_LOADER_PERFORMANCE = 'TOGGLE_LOADER_PERFORMANCE'
const SET_SISENSE_BODY_DATA = 'SET_SISENSE_BODY_DATA'
const FETCH_LINKT_URL = 'FETCH_LINKT_URL'

const CHART_TYPES = {
  compliance: 'CHANGE_COMPLIANCE_CHART_TYPE',
  performance: 'CHANGE_PERFORMANCE_CHART_TYPE',
}

// / action creators //

const changeChartType = (dataKey, value) => {
  return {
    type: CHART_TYPES[dataKey],
    value,
  }
}

const resetStudyDashboard = () => {
  return {
    type: RESET_STUDY_DASHBOARD,
  }
}

const updateDateRange = (start, end) => {
  return {
    type: UPDATE_DATE_RANGE,
    updatedRange: { start, end, range: end.diff(start) },
  }
}

const updateEventData = (studyId, siteId, start, end) => {
  return dispatch => {
    dispatch(updateDateRange(start, end))
    dispatch(fetchEventData(studyId, siteId, start, end))
  }
}

const toggleLoading = (type, loading) => {
  switch (type) {
    case 'compliance':
      return { type: TOGGLE_LOADER_COMPLIANCE, loading }
    case 'performance':
      return { type: TOGGLE_LOADER_PERFORMANCE, loading }
    case 'events':
      return { type: TOGGLE_LOADER_EVENTS, loading }
  }
}

const fetchComplianceData = studyID => {
  return dispatch => {
    dispatch(toggleLoading('compliance', true))
    return dispatch(_fetchDashboard(studyID, 'participant_compliance')).then(({ compliance, not_started } = {}) => {
      const data = _extractComplianceData(compliance || {}, not_started)
      dispatch({
        type: SET_COMPLIANCE_DATA,
        payload: data.compliance,
      })
      dispatch({
        type: SET_HEADER_DATA,
        payload: data.header,
      })
      dispatch(toggleLoading('compliance', false))
      return Promise.resolve()
    })
  }
}

const fetchInstrumentData = studyId => {
  return dispatch => {
    dispatch(toggleLoading('performance', true))
    return dispatch(_fetchDashboard(studyId, 'instrument_compliance'))
      .then(results => {
        if (results && results.best_performer !== null) {
          const chartData = _extractInstrumentData(results)
          const worstInst = results.worst_performer
          dispatch({
            type: SET_HEADER_DATUM,
            key: 'lowestCompliance',
            datum: {
              value: _roundFloat(
                (100 * worstInst.completions.reduce((acc, next) => acc + next, 0)) / worstInst.received,
              ),
              subtitle: worstInst.title,
            },
          })
          dispatch({
            type: SET_PERFORMANCE_DATA,
            payload: chartData,
          })
        }
        dispatch(toggleLoading('performance', false))
        return Promise.resolve()
      })
      .catch(e => {
        if (__DEV__) console.error(e)
      })
  }
}

const fetchEventData = (studyId, siteId, start, end) => {
  return dispatch => {
    dispatch(toggleLoading('events', true))
    return dispatch(_fetchQueueEvents(studyId, start, end)).then(({ events } = {}) => {
      dispatch({
        type: SET_EVENT_DATA,
        payload: _extractEventData(events, start, end, studyId, siteId),
      })
      dispatch(toggleLoading('events', false))
    })
  }
}

const setSisenseBodyData = (dashboard, vendor, sisenseUrl) => dispatch => {
  dispatch({ type: SET_SISENSE_BODY_DATA, dashboard, vendor, sisenseUrl })
}

const _fetchLinkUrl = state => dispatch => {
  dispatch({
    type: FETCH_LINKT_URL,
    payload: state,
  })
}

const openTab = url => window.open(url)

export const openSisenseNewWindow = ({ dashboard, studyID }) => dispatch => {
  const { vendor_type, dashboard_id, dashboard_name } = dashboard
  const dashboardID = vendor_type === INSIGHTS_VENDORS.holistics ? dashboard_name : dashboard_id
  dispatch(setSisenseBodyData(dashboardID, vendor_type))
  return browserHistory.push(`/studies/${studyID}/compliance/iframe/${dashboardID}/${vendor_type}`)
}

export const fetchSisenseUrl = ({ dashboard, open = true, studyID }) => dispatch => {
  const { vendor_type, dashboard_id, dashboard_name } = dashboard
  const isHolistics = vendor_type === INSIGHTS_VENDORS.holistics

  const data = {
    dashboard_id: isHolistics ? dashboard_name : dashboard_id,
    vendor_type: vendor_type || INSIGHTS_VENDORS.sisense,
  }

  const requestObj = isHolistics
    ? {
        url: `/control/studies/${studyID}/insights/redirect/dashboards`,
        method: 'POST',
        body: JSON.stringify(data),
        success: response => {
          const { base_url } = response
          const { embed_code, jwt } = response[dashboard_name]
          if (open) {
            const embeddedUrl = `${base_url || 'https://datacubed-dev.holistics.io'}/embed/${embed_code}?_token=${jwt}`
            return dispatch(setSisenseBodyData(dashboard_name, vendor_type, embeddedUrl))
          }
          return response
        },
        hasLoader: true,
        loadingKey: 'sisense',
        resType: 'json',
      }
    : {
        url: `/control/studies/${studyID}/insights/redirect/dashboards`,
        method: 'POST',
        body: JSON.stringify(data),
        success: response => {
          const { redirectUrl } = response
          if (!redirectUrl) return response
          if (open) return openTab(redirectUrl)
          return response
        },
        hasLoader: true,
        loadingKey: 'sisense',
        resType: 'json',
      }

  dispatch(_fetchLinkUrl(true))
  return dispatch(request(requestObj))
    .then(() => {
      if (open) return dispatch(_fetchLinkUrl(false))
      return Promise.resolve({ sisenseLoggedIn: true })
    })
    .catch(e => {
      throw new Error(e)
    })
}

// API Functions //

const _fetchDashboard = (studyId, type) => {
  return dispatch => {
    return dispatch(
      request({
        url: `/control/studies/${studyId}/dashboard/${type}`,
        method: 'GET',
        success: json => json,
        failMessage: 'There was an error fetching dashboard data. Please try again later.',
      }),
    )
  }
}

const _fetchQueueEvents = (studyId, start, end) => {
  return dispatch => {
    const startStr = start.format(DATE_FORMAT_MAP.datePicker)
    const endStr = moment(end)
      .add(1, 'day')
      .format(DATE_FORMAT_MAP.datePicker)
    const url = `/control/studies/${studyId}/dashboard/events?from=${startStr}&to=${endStr}`
    return dispatch(
      request({
        method: 'GET',
        url,
        success: json => json,
        failMessage: 'There was an error fetching dashboard data. Please try again later.',
      }),
    )
  }
}

// Util Functions //

const _extractComplianceData = (compliancePtpMap, notStarted = []) => {
  const complianceValues = Object.values(compliancePtpMap)
  if (complianceValues.length === 0) return {}
  const list = complianceValues.sort((a, b) => a - b)
  let avgCompliance = 0
  const complianceChartData = Array.from({ length: 11 }, (v, i) => ({ name: i * 10, value: 0 }))
  list.forEach(complianceVal => {
    const compliance = complianceVal * 100
    const category = Math.floor(compliance / 10)
    complianceChartData[category].value++
    avgCompliance += compliance / list.length
  })
  avgCompliance = { value: _roundFloat(avgCompliance) }
  return {
    header: { avgCompliance, notStarted: { value: notStarted.length } },
    compliance: {
      totalPtps: list.length,
      selected: 'num',
      num: complianceChartData,
      percent: complianceChartData.map(datum => ({ ...datum, value: Math.round((100 * datum.value) / list.length) })),
      percentiles: {
        75: `${Math.round(_getKthPercentile(list, 0.75))}%`,
        95: `${Math.round(_getKthPercentile(list, 0.95))}%`,
      },
    },
  }
}

const _extractInstrumentData = data => {
  let worstAggregate = 0
  let bestAggregate = 0
  if (data) {
    const chartData = data.completions_by_day.map((datum, idx) => {
      worstAggregate += data.worst_performer.completions[idx]
      bestAggregate += data.best_performer.completions[idx]
      return {
        name: idx + 1,
        averageCompletion: _roundFloat(datum.total / datum.unique || 0),
        averageCompletionPct: _roundFloat((100 * datum.total) / datum.unique / data.total_num_received || 0),
        worstCompletion: data.worst_performer.completions[idx],
        worstCompletionPct: _roundFloat((100 * data.worst_performer.completions[idx]) / data.total_num_received || 0),
        worstAggregate,
        worstAggregatePct: _roundFloat((100 * worstAggregate) / data.total_num_received || 0),
        bestCompletion: data.best_performer.completions[idx],
        bestCompletionPct: _roundFloat((100 * data.best_performer.completions[idx]) / data.total_num_received || 0),
        bestAggregate,
        bestAggregatePct: _roundFloat((100 * bestAggregate) / data.total_num_received || 0),
      }
    })
    chartData.bestPerformer = data.best_performer
    chartData.worstPerformer = data.worst_performer
    return chartData
  }
}

const _roundFloat = (float, decimals = 2) => {
  const multiplier = Math.pow(10, decimals)
  return Math.round(float * multiplier) / 100
}

const _getKthPercentile = (sortedList, k) => {
  if (sortedList.length === 1) return sortedList[0] * 100
  const idx = sortedList.length * k
  return idx % 1 === 0
    ? 100 * sortedList[idx]
    : (100 * (sortedList[Math.floor(idx) - 1] + sortedList[Math.ceil(idx) - 1])) / 2
}

const _getDefaultPtpData = ({ participant_name, site_id }, start, blank = false) => {
  return {
    blank,
    events: [],
    completionIds: new Set(),
    num_completed: blank ? -1 : 0,
    num_notifications: blank ? -1 : 0,
    num_expired: blank ? -1 : 0,
    num_received: new Set(),
    num_open_apps: blank ? -1 : 0,
    duration_sums: blank ? -1 : 0,
    name: participant_name,
    participantSiteId: site_id,
    event_timestamps: {
      largest_completion_gap: [start],
      largest_open_app_gap: [start],
      largest_announcement_gap: [start],
    },
  }
}

const _extractEventData = (list, start, end, studyId, siteId) => {
  const ptps = {}
  if (list)
    list.forEach(event => {
      ptps[event.participant_id] = ptps[event.participant_id] || _getDefaultPtpData(event, start)
      const ptp = ptps[event.participant_id]
      event.id = event.type + event.timestamp + (event.instrument_id || '') + (event.announcement_id || '')
      ;['sent', 'timestamp', 'read'].forEach(key => {
        if (key in event && event[key] !== null) {
          event[key] = fromUTCMidnightSecondsTS(event[key])
        }
      })
      event.offset = event.timestamp.diff(start)
      ptp.events.push(event)
      event.redirect = `/studies/${studyId}/sites/${siteId}/`
      if (event.hasOwnProperty('instrument_id')) {
        event.redirect += `instruments/${event.instrument_id}/` + `edit${event.instrument_type.toLowerCase()}`
      } else if (event.hasOwnProperty('announcement_id')) {
        event.redirect += `communication/announcement/${event.announcement_id}`
      }
      switch (event.type) {
        case 'EXPIRED':
          ptp.completionIds.add(event.queue_id)
          ptp.num_received.delete(event.queue_id)
          ptp.num_expired++
          break
        case 'SENT':
          ptp.num_received.add(event.queue_id)
          break
        case 'COMPLETED':
          ptp.event_timestamps.largest_completion_gap.push(event.timestamp)
          ptp.completionIds.add(event.queue_id)
          ptp.num_received.delete(event.queue_id)
          ptp.num_completed++
          event.completion_duration = Math.max(event.timestamp.diff(event.sent), 0)
          ptp.duration_sums += event.completion_duration
          event.completion_duration = _secondsToTimeString(event.completion_duration / 1000)
          break
        case 'ANNOUNCEMENT_SENT':
          ptp.event_timestamps.largest_announcement_gap.push(event.timestamp)
          ptp.num_notifications++
          break
        case 'APP_OPENED':
          ptp.event_timestamps.largest_open_app_gap.push(event.timestamp)
          ptp.num_open_apps++
          break
      }
    })
  Object.keys(ptps).forEach(ptpId => {
    const ptp = ptps[ptpId]
    ptp.averageDuration = _secondsToTimeString(ptp.duration_sums / ptp.num_completed / 1000)
    ptp.num_received = ptp.num_received.size
    _handleTimestampGaps(ptp, end)
    delete ptp.last_completed
    delete ptp.duration_sums
  })
  return ptps
}

const _handleTimestampGaps = (ptp, end) => {
  for (const key in ptp.event_timestamps) {
    const timestamps = ptp.event_timestamps[key]
    timestamps.push(end)
    ptp[key] = ptp.blank ? -1 : _calcMaxTimestampGap(timestamps)
  }
  delete ptp.event_timestamps
}

const _calcMaxTimestampGap = timestamps => {
  let gap = timestamps[1].diff(timestamps[0])
  for (let i = 1; i < timestamps.length - 1; i++) {
    const ts1 = timestamps[i]
    const ts2 = timestamps[i + 1]
    const newGap = ts2.diff(ts1)
    if (newGap > gap) gap = newGap
  }
  return gap
}

// Reducers //

const compliance = (state = { selected: 'num', num: [], percent: [], loading: true }, action) => {
  switch (action.type) {
    case SET_COMPLIANCE_DATA:
      return { ...state, ...action.payload }
    case RESET_STUDY_DASHBOARD:
      return { selected: 'num', num: [], percent: [] }
    case CHART_TYPES.compliance:
      return { ...state, selected: action.value }
    case TOGGLE_LOADER_COMPLIANCE:
      return { ...state, loading: action.loading }
    default:
      return state
  }
}

const performance = (state = { selected: 'num', chartData: [], loading: true }, action) => {
  switch (action.type) {
    case SET_PERFORMANCE_DATA:
      return { ...state, chartData: action.payload }
    case CHART_TYPES.performance:
      return { ...state, selected: action.value }
    case TOGGLE_LOADER_PERFORMANCE:
      return { ...state, loading: action.loading }
    case RESET_STUDY_DASHBOARD:
      return { selected: 'num', chartData: [], loading: true }
    default:
      return state
  }
}

const _getDefaultParticipantEvents = () => {
  const end = moment().endOf('day')
  const start = moment()
    .subtract(13, 'days')
    .startOf('day')
  return {
    data: {},
    start,
    end,
    range: end.diff(start),
    loading: false,
  }
}

const participantEvents = (state = _getDefaultParticipantEvents(), action) => {
  switch (action.type) {
    case SET_EVENT_DATA:
      return { ...state, data: action.payload, ptpList: action.ptpList }
    case UPDATE_DATE_RANGE:
      return { ...state, ...action.updatedRange }
    case TOGGLE_LOADER_EVENTS:
      return { ...state, loading: action.loading }
    default:
      return state
  }
}

const _defaultHeader = () => ({
  avgCompliance: null,
  avgTime: null,
  lowestCompliance: null,
  notStarted: null,
})

const header = (state = _defaultHeader(), action) => {
  switch (action.type) {
    case SET_HEADER_DATA:
      return { ...state, ...action.payload }
    case SET_HEADER_DATUM:
      return { ...state, [action.key]: action.datum }
    case RESET_STUDY_DASHBOARD:
      return _defaultHeader()
    default:
      return state
  }
}

const insights = (state = {}, action) => {
  switch (action.type) {
    case SET_SISENSE_BODY_DATA:
      return { dashboard_id: action.dashboard, vendor_type: action.vendor, sisenseUrl: action.sisenseUrl }
    case FETCH_LINKT_URL:
      return { fetchingSisenseUrl: action.payload, ...state }
    default:
      return state
  }
}

export const actions = {
  fetchComplianceData,
  fetchInstrumentData,
  fetchEventData,
  fetchSisenseUrl,
  resetStudyDashboard,
  changeChartType,
  updateEventData,
  openSisenseNewWindow,
}

export default combineReducers({
  compliance,
  performance,
  header,
  participantEvents,
  insights,
})
