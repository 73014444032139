import React from 'react'
import PropTypes from 'prop-types'
import { STUDY_CONFIG_MAP } from 'utils/constants'
import { Input, Dropdown, Button } from 'components/UIElements'
import { ribbonIcon } from 'assets/assets'

const superGem = require('../../../../../assets/super-gem.png')

const GiftCard = props => {
  const { configObj, updateGiftCardList, giftCard, updateProp } = props

  const supergems = configObj[STUDY_CONFIG_MAP.superGems]
  const giftCardConfig = supergems[STUDY_CONFIG_MAP.treasureChestGiftCardConfig]
  const giftCardList =
    giftCardConfig?.[STUDY_CONFIG_MAP.giftCardTypeList] && giftCardConfig[STUDY_CONFIG_MAP.giftCardTypeList]

  const deleteGiftCard = () => {
    const updatedList = giftCardList.filter(gc => gc.key !== giftCard.key)
    updateGiftCardList(updatedList)
  }

  return (
    <div className='gift-card flexed start-aligned'>
      <div className='flexed start-aligned'>
        <div className='card'>
          <div className='amount'>
            <span>$</span>
            {giftCard.value}
          </div>
          <div className='band' />
          {ribbonIcon}
        </div>
        <Input
          className='amount-input'
          label='amount'
          errorText='error'
          disabled={false}
          type='number'
          preventInput={num => num <= 0}
          value={giftCard.value}
          onChange={e => updateProp(e ? parseInt(e, 10) : 1, 'value', giftCard.key)}
        />
        <div className='quantity-input'>
          <p className='label-small'>How many?</p>
          <Dropdown
            disabled={false}
            selected={giftCard.quantity}
            options={[
              { key: 1, value: 1, text: 1 },
              { key: 2, value: 2, text: 2 },
              { key: 3, value: 3, text: 3 },
              { key: 4, value: 4, text: 4 },
              { key: 5, value: 5, text: 5 },
              { key: 6, value: 6, text: 6 },
              { key: 7, value: 7, text: 7 },
              { key: 8, value: 8, text: 8 },
              { key: 9, value: 9, text: 9 },
              { key: 10, value: 10, text: 10 },
            ]}
            main={giftCard.quantity}
            onSelect={item => updateProp(item.value, 'quantity', giftCard.key)}
          />
        </div>
      </div>
      <div className='gem-buttons flexed row'>
        <div className={`gem-button-wrapper${giftCard.cost === 3 ? ' selected' : ''}`}>
          <Button
            className={giftCard.cost === 3 ? 'selected' : ''}
            light
            onClick={() => {
              updateProp(3, 'cost', giftCard.key)
            }}
            content={<img src={superGem} alt='super-gem' />}
          />
          <p className='small-label'>Low</p>
        </div>
        <div className={`gem-button-wrapper${giftCard.cost === 6 ? ' selected' : ''}`}>
          <Button
            className={giftCard.cost === 6 ? 'selected' : ''}
            light
            onClick={() => {
              updateProp(6, 'cost', giftCard.key)
            }}
            content={
              <>
                <img src={superGem} alt='super-gem' />
                <img src={superGem} alt='super-gem' />
              </>
            }
          />
          <p className='small-label'>Mid</p>
        </div>
        <div className={`gem-button-wrapper${giftCard.cost === 12 ? ' selected' : ''}`}>
          <Button
            className={giftCard.cost === 12 ? 'selected' : ''}
            light
            onClick={() => {
              updateProp(12, 'cost', giftCard.key)
            }}
            content={
              <>
                <img src={superGem} alt='super-gem' />
                <img src={superGem} alt='super-gem' />
                <img src={superGem} alt='super-gem' />
              </>
            }
          />
          <p className='small-label'>High</p>
        </div>
      </div>
      <div className='action flexed row'>
        <Button link onClick={() => deleteGiftCard()} icon='fas fa-trash' />
      </div>
    </div>
  )
}

export const studyPropTypes = {
  configObj: PropTypes.object,
  updateGiftCardList: PropTypes.func,
  giftCard: PropTypes.object,
  updateProp: PropTypes.func,
}

GiftCard.propTypes = studyPropTypes

export default GiftCard
