import React from 'react'
import PropTypes from 'prop-types'
import { SortableTable } from 'components/UIElements'
import { browserHistory } from 'react-router'
import { STATUS_MAP, ACTION_MAP } from '../modules/Cases'
import { URGENCY_MAP, CASE_STATUS_MAP } from '../utils/statusMaps'
import '../stylesheets/CasesTable.scss'

const KEY_NAME_MAP = {
  hasNewEdits: '',
  id: 'Case ID',
  participantId: 'Participant ID',
  status: 'Status',
  actionNeeded: 'Action Needed',
  created: 'Case Created',
}

const renderNewCell = ({ value, idx }) => {
  return <td key={`col_${idx}`}>{value === 'a' && <div className='blue-dot' />}</td>
}

const renderStatusCell = ({ value, idx }) => {
  return (
    <td key={`col_${idx}`}>
      {value === 'closed' && <i className='fas fa-times' />}
      <strong>{STATUS_MAP[value]}</strong>
    </td>
  )
}
const renderActionCell = ({ value, idx }) => {
  const isActionable = ![CASE_STATUS_MAP.closed, CASE_STATUS_MAP.open_no_symptoms, CASE_STATUS_MAP.completed].includes(
    value,
  )
  return (
    <td key={`col_${idx}`} className='action-cell'>
      <div className='flexed row'>
        {isActionable && <i className={`fas fa-exclamation-circle ${URGENCY_MAP[value]}`} />}
        <span>{ACTION_MAP[value]}</span>
      </div>
    </td>
  )
}

const renderFunctions = {
  hasNewEdits: renderNewCell,
  status: renderStatusCell,
  actionNeeded: renderActionCell,
}

const CasesTable = props => {
  const { cases, offsets, params } = props
  const { casesList } = cases
  const { studyID, siteID } = params
  const noCases = casesList.length === 0
  const headerOptions = Object.keys(KEY_NAME_MAP).map(key => {
    return { key, sortable: key !== 'hasNewEdits', component: KEY_NAME_MAP[key] }
  })

  return (
    <div>
      {!noCases && (
        <SortableTable
          className='cases-list'
          headerOptions={headerOptions}
          offset={offsets[0]}
          renderFunctions={renderFunctions}
          sortingBy='hasNewEdits'
          rowList={casesList}
          onRowClick={row => {
            const caseId = row[1].case_id
            browserHistory.push(`/studies/${studyID}/cases/${caseId}`)
          }}
        />
      )}
    </div>
  )
}

CasesTable.propTypes = {
  cases: PropTypes.shape({
    casesList: PropTypes.arrayOf(PropTypes.array),
  }),
  offsets: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  params: PropTypes.shape({
    studyID: PropTypes.string,
    siteID: PropTypes.string,
  }),
}

export default CasesTable
