import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { STUDY_CONFIG_MAP } from 'utils/constants'
import { getRandomId } from 'utils/misc'
import useDidMountEffect from 'utils/hooks/didMountEffect'
import {
  calculateCycles,
  generateDefaultGiftCards,
  expandGiftCards,
  getGiftCardDistribution,
  mergeArray,
} from '../utils/studyUtils'
import superGem from 'assets/super-gem.png'
import GiftCard from './GiftCard'
import TierExplanation from './TierExplanation'

const configBodyTextMap = {
  subHeading:
    'First, configure your gift card values. We recommend offering twice the number of gift cards as chapters to maintain choice for your participants. Then, the system will automatically distribute your gift cards across every chapter. You can make changes to this after ',
  infoTiers: 'What are tiers?',
}

const GiftCardConfig = props => {
  const { configObj, updateConfig, hasDynamicCycles } = props
  const supergems = configObj[STUDY_CONFIG_MAP.superGems]
  const giftCardConfig = supergems[STUDY_CONFIG_MAP.treasureChestGiftCardConfig]
  const { [STUDY_CONFIG_MAP.treasureChestStoreGiftCard]: superGemPaymentAccount } = configObj

  const hasPaymentAccount = superGemPaymentAccount.payout_types.length > 0

  const maxAmount = giftCardConfig?.max ? giftCardConfig.max : 0
  const [usedAmount, setusedAmount] = useState(0)
  const [tierInfoVisible, setTierInfoVisible] = useState(false)
  const giftCardTypeList = giftCardConfig?.[STUDY_CONFIG_MAP.giftCardTypeList]
    ? giftCardConfig[STUDY_CONFIG_MAP.giftCardTypeList]
    : []

  const recommendedRewards = (hasDynamicCycles ? configObj.cycles.length : calculateCycles(configObj.cycles[0])) * 2
  const recommendedRewardsSection = recommendedRewards !== 0 && `We recommend selecting ${recommendedRewards}+ rewards`

  const emptyList = (
    <div className='empty'>{`${
      maxAmount === '' || maxAmount === 0 || !hasPaymentAccount
        ? 'Enter a budget and payment account above'
        : 'Add card'
    } to start selecting gift cards`}</div>
  )

  const getCount = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue.value * currentValue.quantity
    if (giftCardTypeList.length) {
      setusedAmount(giftCardTypeList.reduce(reducer, 0))
    } else {
      setusedAmount(0)
    }
  }

  const updateGiftCardList = list => {
    const maxMinusUsedAmount = maxAmount - usedAmount
    updateConfig({
      [STUDY_CONFIG_MAP.superGems]: {
        ...supergems,
        [STUDY_CONFIG_MAP.treasureChestGiftCardConfig]: {
          ...giftCardConfig,
          [STUDY_CONFIG_MAP.giftCardTypeList]: list || [
            ...giftCardTypeList,
            {
              key: getRandomId(),
              value: maxMinusUsedAmount <= 0 ? 5 : maxMinusUsedAmount,
              quantity: 1,
              cost: maxMinusUsedAmount <= 0 ? 3 : 6,
            },
          ],
        },
      },
    })
  }

  useDidMountEffect(() => {
    if (maxAmount !== '') {
      if (hasPaymentAccount) {
        const defaultGiftCards = generateDefaultGiftCards(maxAmount, recommendedRewards / 2)
        defaultGiftCards.map(obj => (obj.key = getRandomId()))
        updateGiftCardList(defaultGiftCards)
      } else if (giftCardTypeList.length > 0) {
        const defaultGiftCards = generateDefaultGiftCards(maxAmount, recommendedRewards / 2)
        defaultGiftCards.map(obj => (obj.key = getRandomId()))
        updateGiftCardList(defaultGiftCards)
      }
    }
  }, [maxAmount, recommendedRewards, hasPaymentAccount])

  useEffect(() => {
    getCount()
  }, [giftCardTypeList])

  const addCard = () => {
    const giftCardAmounts = giftCardTypeList.filter(gc => gc.value === maxAmount - usedAmount)
    const giftCardAmountExist = giftCardAmounts.length

    if (giftCardAmountExist) {
      const updatedList = giftCardTypeList.map(gc => {
        const updatedCard = gc
        if (gc.key === giftCardAmounts[0].key) {
          updatedCard.value = giftCardAmounts[0].value
          updatedCard.quantity = giftCardAmounts[0].quantity + 1
          updatedCard.cost = giftCardAmounts[0].cost
        }
        return updatedCard
      })

      updateGiftCardList(updatedList)
    } else {
      updateGiftCardList()
    }
  }

  const updateProp = (value, prop, key) => {
    const updatedList = giftCardTypeList.map(gc => {
      const updatedCard = gc
      if (gc.key === key) {
        updatedCard[prop] = value
      }
      return updatedCard
    })
    updateGiftCardList(updatedList)
  }

  const giftCards = () => {
    return giftCardTypeList.length
      ? giftCardTypeList.map(gc => (
          <GiftCard
            key={gc.key}
            giftCard={gc}
            {...props}
            updateGiftCardList={updateGiftCardList}
            updateProp={updateProp}
          />
        ))
      : emptyList
  }

  const counter = () => {
    let className = usedAmount === maxAmount ? 'success' : 'error'
    className = usedAmount < maxAmount ? 'warning' : className
    const status = (
      <span className={className}>
        {usedAmount === maxAmount && <i className='fas fa-check-circle' />}${usedAmount}
      </span>
    )
    return maxAmount > 0 ? (
      <div className='counter-status'>
        {status}/{maxAmount}
      </div>
    ) : (
      <div />
    )
  }

  const distributeGiftCards = () => {
    if (recommendedRewards > 0) {
      const gcDistributed = getGiftCardDistribution(expandGiftCards(giftCardTypeList), recommendedRewards / 2)
      updateConfig({
        [STUDY_CONFIG_MAP.superGems]: {
          ...supergems,
          [STUDY_CONFIG_MAP.treasureChestGiftCardConfig]: {
            ...giftCardConfig,
            [STUDY_CONFIG_MAP.giftCardsForUIObj]: gcDistributed,
          },
          [STUDY_CONFIG_MAP.giftCards]: mergeArray(Object.values(gcDistributed).filter(val => val.length > 0)),
        },
      })
    }
  }

  const sumQuantity = (prevVal, currVal) => prevVal + currVal.quantity

  const showTierInfo = isOpen => {
    return setTierInfoVisible(!isOpen)
  }

  const giftcardSubtotal = giftCardTypeList.reduce(sumQuantity, 0)

  return (
    <div>
      <div className='gift-cards-config'>
        <div className='flexed start-aligned'>
          <div>
            <p className='body-text'>{configBodyTextMap.subHeading}</p>
          </div>
          <div className='flexed start-aligned'>
            {!tierInfoVisible && (
              <Button
                link
                onClick={() => showTierInfo(tierInfoVisible)}
                content={configBodyTextMap.infoTiers}
                iconBefore='far fa-lightbulb'
              />
            )}
          </div>
        </div>
      </div>
      {tierInfoVisible && <TierExplanation closeTierInfo={showTierInfo} />}
      <div className='gift-cards-config gc-body'>
        <div className='flexed start-aligned'>
          <div className='gift-cards'>
            <div className='headings flexed start-aligned'>
              <div>GIFTCARDS</div>
              <div>TIER</div>
              {counter()}
            </div>
            <div className='gift-card-container'>{giftCards()}</div>
            <Button
              disabled={maxAmount <= 0 || recommendedRewards < 1}
              light
              onClick={() => addCard()}
              content='Add card'
              iconBefore='fas fa-plus'
            />
          </div>
          <div className='side-options-container'>
            {usedAmount !== maxAmount ? (
              <div className='empty'>Select enough gift cards to match the payout value</div>
            ) : null}

            <div className='side-options'>
              <div className='flexed start-aligned side-title'>
                <div>Gift cards</div>
                <div className='flexed start-aligned gc-number'>{giftcardSubtotal}</div>
              </div>
              <div className='side-divider' />
              <div className='flexed start-aligned side-total-number'>
                <div>Total number</div>
                <div className='flexed start-aligned rewards-number'>{giftcardSubtotal}</div>
              </div>
              <div className='flexed center-justified'>
                <Button
                  disabled={maxAmount === 0 || usedAmount !== maxAmount || recommendedRewards < 1}
                  className='no-margin'
                  onClick={() => {
                    distributeGiftCards()
                  }}
                  content='Distribute Giftcards'
                />
              </div>
              <div className='side-recommend-text'>{recommendedRewardsSection}</div>
              <div className='side-divider foot' />
              <div className='tier-counter'>
                <div className='tier-box'>
                  <span>{giftCardTypeList.filter(val => val.cost === 3).reduce(sumQuantity, 0)}</span>
                  <img src={superGem} alt='super-gem' />
                </div>
                <div className='tier-box'>
                  <span>{giftCardTypeList.filter(val => val.cost === 6).reduce(sumQuantity, 0)}</span>
                  <img src={superGem} alt='super-gem' />
                  <img src={superGem} alt='super-gem' />
                </div>
                <div className='tier-box'>
                  <span>{giftCardTypeList.filter(val => val.cost === 12).reduce(sumQuantity, 0)}</span>
                  <img src={superGem} alt='super-gem' />
                  <img src={superGem} alt='super-gem' />
                  <img src={superGem} alt='super-gem' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const studyPropTypes = {
  configObj: PropTypes.object,
  updateConfig: PropTypes.func,
  hasDynamicCycles: PropTypes.bool,
}

GiftCardConfig.propTypes = studyPropTypes

export default GiftCardConfig
