import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { pluralize } from 'utils/misc'
import { Button, PercentMeter } from 'components/UIElements'
import { MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP, DATE_FORMAT_MAP } from 'utils/constants'
import { dayDifference } from 'utils/time'

const ParticipantStats = props => {
  const { participant, study, inDrawer, openModal, closeModal, triggerResendInvite } = props
  const { cycles } = study
  let firstLogin
  let timeElapsed
  let cycleNo
  const hasLoggedIn = participant.first_login_timestamp !== null
  if (hasLoggedIn) {
    firstLogin = moment(participant.first_login_timestamp)
    timeElapsed = dayDifference(firstLogin, moment())
    const divisor = cycles ? cycles.cycleDuration * (cycles.durationType === 'weeks' ? 7 : 1) : 0
    cycleNo = cycles ? Math.ceil(timeElapsed / divisor) : null
  }

  const onResendInvite = () => {
    openModal({
      content: MODAL_CONTENT_MAP.newInvitation,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        triggerResendInvite(participant)
        closeModal()
      },
    })
  }

  return (
    <div className='participant-stats flexed'>
      {!inDrawer && (
        <FirstLogin
          timeElapsed={timeElapsed}
          firstLogin={firstLogin}
          cycleNo={cycleNo}
          onResendInvite={onResendInvite}
        />
      )}
      {hasLoggedIn && <Compliance timeElapsed={timeElapsed} {...props} />}
      {!inDrawer && study.cycles && hasLoggedIn && <DaysRemaining timeElapsed={timeElapsed} {...props} />}
    </div>
  )
}

const FirstLogin = ({ timeElapsed, firstLogin, cycleNo, onResendInvite }) => {
  const today = moment().format(DATE_FORMAT_MAP.main)
  const isToday = firstLogin ? firstLogin.format(DATE_FORMAT_MAP.main) === today : false
  let content = <b>Today</b>
  if (!isToday) {
    content = timeElapsed ? (
      <p>
        <b>{timeElapsed}</b>
        {pluralize(timeElapsed, 'day', 'days', false)}
        <span>{cycleNo ? `(In Chapter ${cycleNo})` : ''}</span>
      </p>
    ) : (
      <div className='flexed baseline-aligned start-justified'>
        <p>Never Logged In</p>
        <div className='flexed invite-wrapper'>
          <span>(</span>
          <Button id='resend-invite' content='Resend Invite' link onClick={onResendInvite} />
          <span>)</span>
        </div>
      </div>
    )
  }
  return (
    <div className='first-login stat-box'>
      <h5>First Login</h5>
      {content}
      <h5>
        {isToday
          ? firstLogin && `at ${firstLogin.format('h:mma')}, ${firstLogin.format(DATE_FORMAT_MAP.main)}`
          : firstLogin && `Since ${firstLogin.format(DATE_FORMAT_MAP.main)}`}
      </h5>
    </div>
  )
}

const Compliance = ({ participant, inDrawer }) => {
  const total = participant.num_total_instruments
  const completed = participant.num_completed_instruments
  return (
    <div className='compliance stat-box'>
      <h5>Overall Compliance</h5>
      <p>
        <b>{completed}</b>
        <span>of</span>
        <b>{total}</b>
        Completed
      </p>
      {!inDrawer && <PercentMeter hideNum ratio={completed / total || 0} />}
    </div>
  )
}

const DaysRemaining = ({ study, timeElapsed }) => {
  const { cycles } = study
  const studyDuration = cycles ? cycles[0].studyDuration * (cycles[0].durationType === 'weeks' ? 7 : 1) : 0
  let remaining = studyDuration - timeElapsed
  remaining = remaining < 0 ? 0 : remaining
  const ratio = Math.min(timeElapsed / studyDuration, 1)
  return (
    <div className='days-remaining stat-box'>
      <h5>Days Remaining in the Study</h5>
      <p>
        <b>{remaining}</b>
        {pluralize(remaining, 'day', 'days', false)}
      </p>
      <PercentMeter hideNum ratio={ratio} />
    </div>
  )
}

ParticipantStats.propTypes = {
  closeModal: PropTypes.func,
  inDrawer: PropTypes.bool,
  onResendInvite: PropTypes.func,
  openModal: PropTypes.func,
  participant: PropTypes.shape({
    id: PropTypes.number,
    fname: PropTypes.string,
    lname: PropTypes.string,
    first_login_timestamp: PropTypes.string,
  }),
  study: PropTypes.shape({
    id: PropTypes.number,
    consent_required: PropTypes.bool,
    name: PropTypes.string,
    cycles: PropTypes.arrayOf(PropTypes.object),
  }),
  timeElapsed: PropTypes.number,
  triggerResendInvite: PropTypes.func,
}

DaysRemaining.propTypes = ParticipantStats.propTypes
Compliance.propTypes = ParticipantStats.propTypes
FirstLogin.propTypes = ParticipantStats.propTypes

export default ParticipantStats
