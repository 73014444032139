import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Divider, Dropdown } from 'components/UIElements'
import ConditionItem from './NotificationConditionItem'
import { WINDOW_OPTIONS } from '../utils/conditionalNotificationUtils'

const renderList = (list, props) => {
  const { addMedNotificationConditionItem } = props
  const canDelete = list.length > 1
  const conditionsList = list.map((conditionItem, i) => {
    return <ConditionItem key={`condition-${i}`} index={i} {...conditionItem} canDelete={canDelete} {...props} />
  })
  const conditionButton = (
    <Button
      disabled={list.length >= 3}
      className='add-condition-button'
      id='add-condition'
      content='Add condition'
      iconBefore='fas fa-plus'
      key='add-condition-button'
      link
      onClick={addMedNotificationConditionItem}
    />
  )
  conditionsList.push(conditionButton)
  return conditionsList
}

const ConditionsSection = props => {
  const { conditionalNotifications, updateMedNotificationTrigger } = props
  const { medicationAdherence } = conditionalNotifications
  const list = medicationAdherence?.conditions
  const selectedWindowOption = list[0].triggers[0].resource_function_params.window
  return (
    <Container className='conditions section'>
      <h5>Conditions</h5>
      <p>
        Varying notifications are sent based on different degrees of adherence during a window of time immediately
        before notification time.
      </p>
      <p className='label-small'>Length of window</p>
      <Dropdown
        id='length-of-window'
        options={WINDOW_OPTIONS}
        onSelect={item => {
          updateMedNotificationTrigger({ key: 'window', value: item.value })
        }}
        selected={selectedWindowOption.toString()}
      />
      <Divider color='#eceff0' margin='27px -30px' />
      {renderList(list, props)}
    </Container>
  )
}

ConditionsSection.propTypes = {
  conditionalNotifications: PropTypes.shape({
    medicationAdherence: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }),
  updateMedNotificationTrigger: PropTypes.func,
}

export default ConditionsSection
