import React from 'react'
import 'styles/d3/warningtext.scss'

class WarningText extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showWarning: true }
  }

  static getDerivedStateFromProps = () => ({ showWarning: true });

  hide(){
    this.setState({ showWarning: false })
  }

  render() {
    const { text } = this.props
    const { showWarning } = this.state;
    return showWarning && text ? (
      <span className='warning-text'>
        <i onClick={this.hide} className='fas fa-times-circle' />
        {text}
      </span>
    ) : null
  }
}

export default WarningText
