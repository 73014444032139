import React from 'react'
import PropTypes from 'prop-types'
import { generateDateStringWithTimeZone } from 'utils/time'
import { CASE_STATUS_MAP } from '../../CasesPage/utils/statusMaps'

const generateMessage = (status, date, caseId) => {
  if (status === CASE_STATUS_MAP.open)
    return 'This case was opened because participant was in medical facility for 6+ hours with no response.'
  if (status === CASE_STATUS_MAP.open_no_symptoms)
    return 'Participant reported experiencing no symptoms since the case was opened.'
  if (status === CASE_STATUS_MAP.closed) return `Case ${caseId} was closed on ${date}`
  return null
}

const CloseCasePromptBar = ({ status, caseId, caseProgress }) => {
  let closed = null
  if (status === CASE_STATUS_MAP.closed) {
    const indexOfClose = caseProgress.findIndex(progress => progress.status === CASE_STATUS_MAP.closed)
    closed = caseProgress[indexOfClose]
  }

  const dateString = closed ? generateDateStringWithTimeZone(closed.timestamp) : null
  const className = `prompt-bar ${status}`

  return <div className={className}>{generateMessage(status, dateString, caseId)}</div>
}

CloseCasePromptBar.propTypes = {
  status: PropTypes.string,
  caseId: PropTypes.number,
  caseProgress: PropTypes.arrayOf(PropTypes.object),
}

export default CloseCasePromptBar
