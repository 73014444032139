import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { pluralize } from 'utils/misc'
import { fromUTCMidnightSeconds } from 'utils/time'
import { Container } from 'components/UIElements'
import { DEPLOYMENT_KEYS, DATE_FORMAT_MAP } from 'utils/constants'
import { DEFAULT_DEPLOY_TIME } from '../../../modules/Instrument'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from 'utils/constants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'

const ABSOLUTE = DEPLOYMENT_KEYS.abs
const CHAIN_DEPLOY_OFFSET = DEPLOYMENT_KEYS.chainDeployOffset
const NEVER = DEPLOYMENT_KEYS.never
const RELATIVE_TO_FIRST_LOGIN = DEPLOYMENT_KEYS.firstLogin
const RELATIVE_TO_SEND_IDEAL = DEPLOYMENT_KEYS.relativeToSendIdeal

const _getLine = (repeatInterval = 1, visibleDays = 1, totalWidth) => {
  const style = {
    width: `${totalWidth}px`,
  }
  const maximumBoxNum = totalWidth === 500 ? 30 : 11
  let dashes = []
  if (repeatInterval !== undefined && repeatInterval < maximumBoxNum) {
    const boxStyle = {
      width: `${totalWidth / repeatInterval}px`,
    }
    for (let i = 0; i < repeatInterval; i++) {
      const className = `timeline-box${i < visibleDays ? ' filled' : ''}`
      dashes.push(<div key={`timeline_box_${i}`} className={className} style={boxStyle} />)
    }
  } else {
    const fillStyle = {
      width: `${totalWidth * (visibleDays / repeatInterval)}px`,
    }
    dashes = <div className='variable-fill' style={fillStyle} />
  }
  return (
    <div className='timeline-interval' style={style}>
      {dashes}
    </div>
  )
}

const OccurenceBubble = ({ className, content, supText, subText }) => {
  const _content = content === '...' ? <i className='fas fa-ellipsis-h' /> : content
  return (
    <div className={`occurence-bubble${className ? ` ${className}` : ''}`}>
      <div className='content'>{_content}</div>
      <div className='sub-text'>{subText}</div>
      <div className='sup-text'>{supText}</div>
    </div>
  )
}

const IntervalLine = ({ repeatInterval, subText, visibleDays, totalWidth = 140 }) => {
  return (
    <div className='interval-line'>
      {_getLine(repeatInterval, visibleDays, totalWidth)}
      <div className='sub-text'>{subText}</div>
    </div>
  )
}

const ErrorBox = () => {
  return (
    <div className='error-box'>
      <i className='far fa-calendar-alt' />
      <p>
        Invalid Scheduling. <br />
        Start date cannot exceed finish date.
      </p>
    </div>
  )
}

const getCollapsedContent = occurenceContent => {
  if (occurenceContent.length <= 4) return occurenceContent
  let collapsed = occurenceContent.slice(0, 2).concat([{ content: '...', supText: '' }])
  collapsed = collapsed.concat([occurenceContent[occurenceContent.length - 1]])
  return collapsed
}

const _getDayString = num => {
  if (typeof num !== 'number') return num
  return `${num} day${num > 1 ? 's' : ''}`
}

const _getDateString = mom => {
  return mom.format(DATE_FORMAT_MAP.main)
}

const _setRecurrenceData = (displayData, variable, endCond, incrementFcn, setContent) => {
  for (variable; endCond(variable); incrementFcn(variable)) {
    setContent()
  }
}

const _calcRelRecurrences = (displayData, recurring, expire, deploy, firstDay, isRelativeToSendIdeal = false) => {
  const instrumentDuration = isRelativeToSendIdeal
    ? expire.relative_to_send_ideal.interval - 1
    : expire.first_login.interval - deploy.first_login.interval + firstDay
  if (recurring.scheme === 'n-days') {
    let i = 2
    let occurenceNum = 2
    let dayNumber = isRelativeToSendIdeal ? recurring.interval : recurring.interval + firstDay
    _setRecurrenceData(
      displayData,
      i,
      () => dayNumber <= instrumentDuration,
      () => {
        i += recurring.interval
        dayNumber = occurenceNum * recurring.interval + firstDay
        occurenceNum++
      },
      () => {
        displayData.occurenceContent.push({
          content: occurenceNum,
          supText: `${isRelativeToSendIdeal ? `${_getDayString(dayNumber)} after inst. start` : `Day ${dayNumber}`}`,
        })
      },
    )
    const lastInterval = (instrumentDuration - firstDay) % recurring.interval
    displayData.finalInterval =
      lastInterval === 0
        ? null
        : {
            subText: _getDayString(lastInterval),
            visibleDays: Math.min(lastInterval, recurring.visible_days),
            duration: lastInterval,
          }
  }
  displayData.expire.content = 'Expire'
  if (!isRelativeToSendIdeal) {
    displayData.expire.supText = `Day ${instrumentDuration}`
  }
}

const _calcAbsRecurrences = (displayData, startDate, expireDate, { scheme, interval, visible_days }) => {
  if (scheme === 'n-days') {
    const current = startDate.clone().add(interval, 'day')
    let idx = 2
    _setRecurrenceData(
      displayData,
      current,
      variable => variable.isSameOrBefore(expireDate, 'day'),
      variable => {
        variable.add(interval, 'day')
        idx++
      },
      () => {
        displayData.occurenceContent.push({
          content: idx,
          supText: _getDateString(current),
        })
      },
    )
    const lastDay = current.clone().subtract(interval, 'days')
    const lastInterval = Math.ceil(expireDate.diff(lastDay, 'days', true))
    displayData.finalInterval =
      lastInterval === 0
        ? null
        : {
            subText: _getDayString(lastInterval),
            visibleDays: Math.min(visible_days, lastInterval),
            duration: lastInterval,
          }
  }
  displayData.expire.content = 'Expire'
  displayData.expire.supText = _getDateString(expireDate)
}

const _calcInstToInstRecurrences = (displayData, recurring, expire, deploy) => {
  const { chain_deploy_offset } = deploy
  const { chain_expire_offset } = expire
  const instrumentDuration = chain_expire_offset - chain_deploy_offset
  if (recurring.scheme === 'n-days') {
    let i = 2
    let occurenceNum = 2
    let dayNumber = recurring.interval
    _setRecurrenceData(
      displayData,
      i,
      () => dayNumber <= instrumentDuration,
      () => {
        i += recurring.interval
        dayNumber = occurenceNum * recurring.interval
        occurenceNum++
      },
      () => {
        displayData.occurenceContent.push({
          content: occurenceNum,
          supText: `${pluralize(dayNumber, 'day', 'days')} after`,
        })
      },
    )
    const lastInterval = instrumentDuration % recurring.interval
    displayData.finalInterval =
      lastInterval === 0
        ? null
        : {
            subText: _getDayString(lastInterval),
            visibleDays: Math.min(lastInterval, recurring.visible_days),
            duration: lastInterval,
          }
  }
  displayData.expire.content = 'Expire'
  displayData.expire.supText = `${pluralize(
    chain_expire_offset - chain_deploy_offset,
    'day',
    'days',
  )} after instrument triggered`
}

const _calcFixedRecurrences = (displayData, startDate, recurring, expire, deploy, firstDay) => {
  const isInstrumentToInstrument = CHAIN_DEPLOY_OFFSET in deploy
  const isRelativeToSendIdeal = RELATIVE_TO_SEND_IDEAL in expire
  const expireInterval = recurring.recurrences * recurring.interval
  let expireDate
  if (RELATIVE_TO_FIRST_LOGIN in deploy) {
    const expireObj = {
      first_login: { interval: (recurring.recurrences - 1) * recurring.interval + deploy.first_login.interval },
    }
    _calcRelRecurrences(displayData, recurring, expireObj, deploy, firstDay)
  } else if (isInstrumentToInstrument) {
    _calcInstToInstRecurrences(displayData, recurring, expire, deploy, firstDay)
  } else if (isRelativeToSendIdeal) {
  } else {
    expireDate = startDate.clone().add(expireInterval - recurring.interval, 'days')
    _calcAbsRecurrences(displayData, startDate, expireDate, recurring)
  }
  if (!isInstrumentToInstrument) {
    displayData.finalInterval = {
      subText: _getDayString(recurring.visible_days),
      visibleDays: recurring.visible_days,
      duration: recurring.visible_days,
    }
    displayData.expire.supText =
      RELATIVE_TO_FIRST_LOGIN in deploy
        ? `Day ${firstDay + (expireInterval - (recurring.interval - recurring.visible_days))}`
        : _getDateString(expireDate.clone().add(recurring.visible_days, 'days'))
  }
}

const _getRelativeData = (displayData, { recurring, expire, deploy }, isRecurring) => {
  const isRelativeToFirstLogin = RELATIVE_TO_FIRST_LOGIN in deploy
  if (isRelativeToFirstLogin) {
    const firstDay = deploy.first_login.interval + 1
    displayData.start = `Day ${firstDay}`

    if (isRecurring) {
      if (RELATIVE_TO_FIRST_LOGIN in expire) {
        _calcRelRecurrences(displayData, recurring, expire, deploy, firstDay)
      } else if (NEVER in expire) {
        if (recurring.recurrences) {
          _calcFixedRecurrences(displayData, null, recurring, expire, deploy, firstDay)
        } else {
          displayData.expire.content = 'No Expiration'
          displayData.occurenceContent = [
            { content: '2', supText: `Day ${firstDay + recurring.interval}` },
            { content: '3', supText: `Day ${firstDay + recurring.interval * 2}` },
            { content: '...', supText: '' },
          ]
        }
      }
    } else if (NEVER in expire) {
      displayData.expire.content = 'No Expiration'
    } else {
      const instrumentDuration = expire.first_login.interval - deploy.first_login.interval
      displayData.finalInterval = {
        subText: instrumentDuration === 0 ? '' : _getDayString(instrumentDuration),
        visibleDays: instrumentDuration === 0 ? 1 : instrumentDuration,
        intervalWidth: 500,
        duration: instrumentDuration === 0 ? 1 : instrumentDuration,
      }
      displayData.expire.content = 'Expire'
      displayData.expire.supText = `Day ${firstDay + instrumentDuration}`
    }
  } else {
    displayData.start = `Instrument start day`
    const { relative_to_send_ideal } = expire
    if (isRecurring) {
      _calcRelRecurrences(displayData, recurring, expire, deploy, null, true)
    } else {
      const instrumentDuration = relative_to_send_ideal.interval - 1
      displayData.finalInterval = {
        subText: instrumentDuration === 0 ? '' : _getDayString(instrumentDuration),
        visibleDays: instrumentDuration === 0 ? 1 : instrumentDuration,
        intervalWidth: 500,
        duration: instrumentDuration === 0 ? 1 : instrumentDuration,
      }
      displayData.expire.content = 'Expire'
      displayData.expire.supText = `${
        instrumentDuration === 0 ? 'Same day as' : `${_getDayString(instrumentDuration)} after`
      }  instrument start`
    }
  }
}

const _getAbsoluteData = (displayData, { recurring, expire, deploy }, isRecurring) => {
  const startDate = fromUTCMidnightSeconds(deploy.absolute)
  displayData.start = _getDateString(startDate)
  if (isRecurring) {
    if (NEVER in expire && !recurring.recurrences) {
      displayData.expire.content = 'No Expiration'
      displayData.occurenceContent = [
        { content: '2', supText: _getDateString(startDate.clone().add(recurring.interval, 'days')) },
        { content: '3', supText: _getDateString(startDate.clone().add(recurring.interval * 2, 'days')) },
        { content: '...', supText: '' },
      ]
      displayData.finalInterval = {
        subText: _getDayString(recurring.interval),
        duration: recurring.interval,
        visibleDays: recurring.visible_days,
      }
    } else if (ABSOLUTE in expire) {
      const expireDate = fromUTCMidnightSeconds(expire.absolute)
      _calcAbsRecurrences(displayData, startDate, expireDate, recurring)
    } else {
      _calcFixedRecurrences(displayData, startDate, recurring, expire, deploy)
    }
  } else if (NEVER in expire) {
    displayData.expire.content = 'No Expiration'
    displayData.finalInterval = {}
  } else {
    const expireDate = fromUTCMidnightSeconds(expire.absolute)
    const duration = expireDate.diff(startDate, 'days')
    displayData.finalInterval = {
      subText: duration === 0 ? '' : _getDayString(duration),
      visibleDays: duration === 0 ? 1 : duration,
      intervalWidth: 500,
      duration: duration === 0 ? 1 : duration,
    }
    displayData.expire.content = 'Expire'
    displayData.expire.supText = _getDateString(fromUTCMidnightSeconds(expire.absolute))
  }
}
const _getInstrumentToInstrumentData = (displayData, { deploy, expire, recurring }, isRecurring) => {
  const { chain_deploy_offset } = deploy
  const { absolute, chain_expire_offset } = expire
  const startDate = fromUTCMidnightSeconds(deploy.absolute)

  displayData.start = 'This instrument'
  displayData.startSubtext =
    chain_deploy_offset === 0
      ? 'Triggered immediately after first instrument is complete'
      : `Triggered ${pluralize(chain_deploy_offset, 'day', 'days')} after first instrument is complete`

  if (isRecurring) {
    if (NEVER in expire && !recurring.recurrences) {
      displayData.expire.content = 'No Expiration'
      displayData.occurenceContent = [
        { content: '2', supText: `${pluralize(recurring.interval, 'day', 'days')} after` },
        { content: '3', supText: `${recurring.interval * 2} days after` },
        { content: '...', supText: '' },
      ]
      displayData.finalInterval = {
        subText: _getDayString(recurring.interval),
        duration: recurring.interval,
        visibleDays: recurring.visible_days,
      }
    } else if (ABSOLUTE in expire) {
      const expireDate = fromUTCMidnightSeconds(expire.absolute)
      _calcAbsRecurrences(displayData, startDate, expireDate, recurring)
    } else {
      _calcFixedRecurrences(displayData, startDate, recurring, expire, deploy)
    }
  } else if (NEVER in expire) {
    displayData.expire.content = 'No Expiration'
    displayData.finalInterval = {}
  } else {
    const duration = chain_expire_offset ? chain_expire_offset - chain_deploy_offset : ''

    displayData.finalInterval = {
      subText: duration === 0 ? '' : _getDayString(duration),
      visibleDays: duration || 1,
      intervalWidth: 500,
      duration: duration || 1,
    }

    displayData.expire.content = 'Expire'

    const expirationDaysAfterSupText = chain_expire_offset
      ? `${pluralize(chain_expire_offset - chain_deploy_offset, 'day', 'days')} after instrument triggered`
      : ''

    displayData.expire.supText = absolute
      ? _getDateString(fromUTCMidnightSeconds(absolute))
      : expirationDaysAfterSupText
  }
}

export const _getTimelineData = schedule => {
  const { expire, recurring, deploy } = schedule
  const isRecurring = recurring && recurring.interval !== ''
  const displayData = {
    occurenceContent: [],
    expire,
    deploy,
    visibleDays: isRecurring ? recurring.visible_days : null,
    repeatInterval: isRecurring ? recurring.interval : null,
  }

  const isInstrumentToInstrument = CHAIN_DEPLOY_OFFSET in deploy

  displayData.intervalText = isRecurring ? _getDayString(recurring.interval) : ''

  if (RELATIVE_TO_FIRST_LOGIN in deploy || RELATIVE_TO_SEND_IDEAL in expire) {
    _getRelativeData(displayData, schedule, isRecurring)
  } else if (isInstrumentToInstrument) {
    _getInstrumentToInstrumentData(displayData, schedule, isRecurring)
  } else {
    _getAbsoluteData(displayData, schedule, isRecurring)
  }
  if (recurring && recurring.scheme === 'persistent') {
    displayData.occurenceContent = [{ content: '...', supText: 'Repeat Immediately' }]
    displayData.finalInterval = {}
  }
  return displayData
}

const TimelineDisplay = ({ disabled, schedule, study, userId, isDiary, isDeployedByVisit }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const toggleTimelineCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const renderOccurences = ({ repeatInterval, intervalText, visibleDays, occurrences }) => {
    const intervals = []
    for (let i = 0; i < occurrences.length; i++) {
      intervals.push(
        <IntervalLine
          key={`interval_${i}`}
          repeatInterval={repeatInterval}
          subText={intervalText}
          visibleDays={visibleDays}
        />,
      )
      intervals.push(
        <OccurenceBubble
          supText={occurrences[i].supText}
          key={`occurence-bubble-${i}`}
          content={occurrences[i].content}
        />,
      )
    }
    return intervals
  }

  const renderLastInterval = finalInterval => {
    if (!finalInterval) return <IntervalLine repeatInterval={1} visibleDays={1} totalWidth={15} />
    const { subText, visibleDays = 1, duration = 1, intervalWidth = 140 } = finalInterval
    return (
      <IntervalLine repeatInterval={duration} subText={subText} visibleDays={visibleDays} totalWidth={intervalWidth} />
    )
  }

  const renderErrorBox = () => {
    return [
      <IntervalLine key='eb1' totalWidth={100} />,
      <ErrorBox key='eb2' />,
      <IntervalLine key='eb3' totalWidth={100} />,
    ]
  }

  const renderExpandButton = () => {
    return (
      <span className='collapse-button' onClick={toggleTimelineCollapse}>
        <i className={`fas fa-${isCollapsed ? 'plus' : 'minus'}`} />
        {isCollapsed ? 'Expand' : 'Collapse'}
      </span>
    )
  }

  const {
    start,
    startSubtext,
    repeatInterval = 1,
    finalInterval,
    occurenceContent,
    expire,
    deploy,
    visibleDays,
    intervalText,
  } = _getTimelineData(schedule)

  const isInstrumentToInstrument = CHAIN_DEPLOY_OFFSET in deploy
  const collapsedContent = getCollapsedContent(occurenceContent)
  const occurrences = isCollapsed ? collapsedContent : occurenceContent
  const showErrorBox = finalInterval && finalInterval.duration < 0
  let expireSubText = '23:59'
  const hasRecurrences = schedule.recurring && schedule.recurring.recurrences
  if (!hasRecurrences && NEVER in schedule.expire) {
    expireSubText = ''
  }
  if (hasRecurrences) {
    expireSubText = '00:00'
  }

  const isShown = hasInstrumentUnification ? !isDeployedByVisit : !(isDiary || isDeployedByVisit)

  return isShown ? (
    <Container className={`${showErrorBox ? 'invalid-expire' : ''} ${disabled ? 'disabled' : ''}`}>
      <div className='timeline-header'>
        <p>Timeline</p>
        {collapsedContent.length !== occurenceContent.length && renderExpandButton()}
      </div>
      <div className='timeline-display'>
        <OccurenceBubble
          className={`${isInstrumentToInstrument ? 'start-bubble' : ''}`}
          content='1'
          supText={start}
          subText={startSubtext || deploy.time_of_day || DEFAULT_DEPLOY_TIME}
        />
        {!showErrorBox && renderOccurences({ repeatInterval, intervalText, visibleDays, occurrences })}
        {!showErrorBox ? renderLastInterval(finalInterval) : renderErrorBox()}
        {expire && (
          <OccurenceBubble
            className='expire'
            content={expire.content}
            supText={finalInterval ? expire.supText : ''}
            subText={expireSubText}
          />
        )}
      </div>
    </Container>
  ) : null
}

TimelineDisplay.propTypes = {
  disabled: PropTypes.bool,
  expire: PropTypes.shape({
    content: PropTypes.string,
  }),
  schedule: PropTypes.shape({
    recurring: PropTypes.object,
  }),
}

export default TimelineDisplay
