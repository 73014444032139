// We only need to import the modules necessary for initial render
import { fetchUserImage } from 'store/user'
import CoreLayout from '../layouts/CoreLayout/CoreLayout'
import ActivateUserRoute from './ActivateUser'
import LoginRoute from './Login'
import ProfilePage from './ProfilePage'
import ResetPasswordRoute from './ResetPassword'
import StudiesRoute from './Studies'
import Unauthorized from './Unauthorized'

export const createRoutes = store => {
  return {
    path: '/',
    component: CoreLayout,
    indexRoute: { onEnter: (nextState, replace) => replace('/studies') },
    getChildRoutes(location, cb) {
      const state = store.getState()
      const { user } = state
      if (Object.keys(user).length !== 0 && !user.userImage) {
        store.dispatch(fetchUserImage(user.id))
      }
      cb(null, [
        ActivateUserRoute(store),
        LoginRoute(store),
        ProfilePage(store),
        ResetPasswordRoute(store),
        StudiesRoute(store),
        Unauthorized(store),
      ])
    },
    store,
  }
}

export default createRoutes
