import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { formatDate, parseDate } from 'react-day-picker/moment'
import { dayPickerConfig } from 'utils/time'
import { Button, Checkbox } from 'components/UIElements'
import '../../../../../../../../../../styles/d3/dateFilter.scss'

const err1 = 'Start date should be on or before the end date'
const err2 = 'End date should be on or after the start date'
const err3 = 'End date should be on or before today’s date'
const err4 = 'Invalid date format'

const PastDatesOverlay = ({
  classNames,
  children,
  selectedDay,
  onSetDate,
  futureOverlay,
  pastOverlay,
  setAllDates,
  ...props
}) => {
  // const date = selectedDay[1]
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={`${classNames.overlay} ${pastOverlay ? 'past-overlay' : 'future-overlay'}`}>
        {children}
        <div className='buttons-container flexed column'>
          {/* {date?.to && pastOverlay && (
            <Button
              grey
              className='past-button'
              content='All past dates'
              onClick={() => onSetDate({ from: '', to: date.to })}
            />
          )} */}
          <Button link className='all-dates' content='Allow all dates' onClick={setAllDates} />
        </div>
      </div>
    </div>
  )
}

const DateFilter = props => {
  const {
    date,
    onSetDate,
    isLongFormat,
    setDateError,
    dateError,
    handleDateFilterError,
    hideAllDatesCheckbox = false,
  } = props
  const dateFormat = 'MM/DD/YYYY'
  const fromPickerRef = useRef()
  const toPickerRef = useRef()

  const dayPickerList = [
    {
      type: 'from',
      ref: fromPickerRef,
    },
    {
      type: 'to',
      ref: toPickerRef,
    },
  ]

  const setAllDates = () => {
    const emptyEventTargetValue = { target: { value: '' } }
    setDateError({
      from: {
        value: true,
        error: '',
      },
      to: {
        value: true,
        error: '',
      },
    })

    fromPickerRef.current.handleInputChange(emptyEventTargetValue)
    toPickerRef.current.handleInputChange(emptyEventTargetValue)
    onSetDate({ from: '', to: '', allDate: true })
  }

  // If is long format, we will clear the dates and will be set to "All dates"
  useEffect(() => {
    if (isLongFormat) setAllDates()
  }, [isLongFormat])

  useEffect(() => {
    const { from, to, allDate } = date
    if (allDate || from === '' || to === '') return
    const todayFormatted = moment(new Date(), dateFormat).startOf('day')
    const fromFormatted = moment(from, dateFormat).startOf('day')
    const toFormatted = moment(to, dateFormat).startOf('day')

    const dateFromBefore = moment(fromFormatted).isSameOrBefore(moment(toFormatted))
    const dateToAfter = moment(toFormatted).isSameOrAfter(moment(fromFormatted))
    const dateToBefore = moment(toFormatted).isSameOrBefore(moment(todayFormatted))

    // eslint-disable-next-line no-nested-ternary
    const errorFrom = dateError.to.error === err4 ? '' : !dateFromBefore ? err1 : ''
    // eslint-disable-next-line no-nested-ternary
    const errorTo = dateError.from.error === err4 ? '' : !dateToAfter ? err2 : !dateToBefore ? err3 : ''

    setDateError({
      from: {
        value: !dateFromBefore,
        error: !dateError.from.error ? errorFrom ?? '' : dateError.from.error,
      },
      to: {
        value: !dateToAfter || !dateToBefore,
        error: !dateError.to.error ? errorTo ?? '' : dateError.to.error,
      },
    })
  }, [date])

  const modifiers = { start: date.from, end: date.to }

  const commonDayPickerProps = type => {
    const dateNow = new Date()
    const disabledDays = type === 'from' ? { after: date.to || dateNow } : { before: date.from, after: dateNow }
    const onDayClick = (_, options) => {
      const { disabled } = options
      const otherType = type === 'from' ? 'to' : 'from'
      const refs = {
        to: toPickerRef,
        from: fromPickerRef,
      }
      if (disabled || date[otherType]) return
      refs[otherType].current.getInput().focus()
    }
    const month = type === 'from' ? dateNow : date.to

    return {
      selectedDays: [date.from, { from: date.from, to: date.to }],
      numberOfMonths: 2,
      modifiers,
      disabledDays,
      month,
      toMonth: dateNow,
      onDayClick,
    }
  }

  const commonDayPickerInputProps = {
    placeholder: dayPickerConfig.placeholder,
    format: dayPickerConfig.format,
    formatDate,
    parseDate,
  }

  const setDateErrorField = (value, type) => {
    const { from, to } = date
    if (from === '' || to === '') return
    const error = value ? err4 : ''
    const otherType = type === 'from' ? 'to' : 'from'
    setDateError(prev => {
      const next = {
        ...prev,
      }
      next[otherType] = {
        ...next[otherType],
        error: next[otherType].error !== err4 ? '' : err4,
      }
      next[type] = {
        value,
        error,
      }
      return next
    })
  }

  const onDayChange = (value, state, type) => {
    const data = state.input.value
    const dataValidate = moment(data, dateFormat, true).isValid()
    if (!value) setDateErrorField(!dataValidate, type)
    else setDateErrorField(false, type)
    const newDate = {
      ...date,
      allDate: false,
    }
    newDate[type] = value
    onSetDate(newDate)
  }

  const getClassNames = type => ({
    container: `DayPickerInput ${
      !date.allDate && dateError[type].error && dateError[type].value ? 'DayPickerInputError' : ''
    }`,
    overlayWrapper: 'DayPickerInput-OverlayWrapper',
    overlay: 'DayPickerInput-Overlay',
  })

  return (
    <div className='date-picker flexed'>
      {dayPickerList.map(item => {
        const { type, ref } = item
        return (
          <DayPickerInput
            key={type}
            ref={ref}
            {...commonDayPickerInputProps}
            dayPickerProps={{
              ...commonDayPickerProps(type),
            }}
            classNames={getClassNames(type)}
            inputProps={{
              disabled: isLongFormat,
              onBlur: event => {
                if (date.allDate) return
                if (!event.relatedTarget && dateError[type]?.error) handleDateFilterError(dateError[type].error)
              },
            }}
            onDayChange={(value, _, state) => {
              onDayChange(value, state, type)
            }}
            overlayComponent={_props => (
              <PastDatesOverlay
                {..._props}
                setAllDates={setAllDates}
                onSetDate={onSetDate}
                pastOverlay={type === 'from'}
              />
            )}
            value={date[type]}
          />
        )
      })}
      {!hideAllDatesCheckbox ? (
        <Checkbox checked={date.allDate} disabled={isLongFormat} onClick={() => setAllDates()} label='All dates' />
      ) : null}
    </div>
  )
}

DateFilter.propTypes = {
  date: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    allDate: PropTypes.bool,
  }),
  isLongFormat: PropTypes.bool,
  onSetDate: PropTypes.func,
  setDateError: PropTypes.func,
  dateError: PropTypes.shape({
    from: PropTypes.shape({
      value: PropTypes.bool,
      error: PropTypes.string,
    }),
    to: PropTypes.shape({
      value: PropTypes.bool,
      error: PropTypes.string,
    }),
  }),
  handleDateFilterError: PropTypes.func,
}

export default DateFilter
