import React, { useEffect, useState, useRef } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button, Container, Input } from 'components/UIElements'
import Map from './GeofenceMap'
import calculateLng from '../utils/geofenceUtils'
import '../stylesheets/CreateGeofence.scss'

const INITIAL_GEOFENCE_STATE = {
  name: '',
  latitude: '',
  longitude: '',
  radius: 100,
}

const GEOFENCE_FIELDS_MAP = {
  name: 'name',
  lat: 'latitude',
  lng: 'longitude',
  rad: 'radius',
}

const CreateGeofence = props => {
  const {
    editMode,
    geofences,
    createGeofence,
    updateCurrentGeofence,
    header,
    params,
    setNavBarProps,
    setHeaderCreateGeofence,
    studyLock,
  } = props

  const [geofence, setGeofence] = useState(INITIAL_GEOFENCE_STATE)
  const [fieldsEntered, setFieldsEntered] = useState(false)
  const [radiusError, setRadiusError] = useState('')
  const [dupCoordError, setDupCoordError] = useState('')
  const [nameEntered, setNameEntered] = useState(!!editMode)
  const [currentGeofencesCoords, setCurrentGeofencesCoords] = useState([])
  const { studyID, editGeofenceID } = params
  const { name, latitude, longitude, radius } = geofence
  const { geofencesList } = geofences
  const nameInputEl = useRef(null)

  const noGeofences = geofencesList.length === 0

  useEffect(() => {
    if (editMode) {
      setNavBarProps({
        back: `/studies/${studyID}/geofences`,
        close: `/studies/${studyID}/geofences`,
        backText: 'Geofences',
      })
    }
    return () => {
      if (editMode) setNavBarProps(null)
    }
  }, [])

  useEffect(() => {
    if (editMode && geofencesList.length) {
      const editedGeofence = geofencesList.filter(s => s[0].value === editGeofenceID)[0]
      if (!editedGeofence) return
      setGeofence({
        name: editedGeofence[1].value,
        latitude: editedGeofence[2].value,
        longitude: editedGeofence[3].value,
        radius: editedGeofence[4].value,
      })
    }
    //
    // This creates a list to check to validate the current geofence from being duplicate.
    //
    if (geofencesList.length) {
      const _currentGeofenceCoords = []
      geofencesList.forEach(_geofence => {
        _currentGeofenceCoords.push({ lat: _geofence[2].value, lng: _geofence[3].value })
      })
      setCurrentGeofencesCoords(_currentGeofenceCoords)
    }
  }, [geofences.geofencesList.length])

  useEffect(() => {
    if ((latitude || latitude === 0) && (longitude || longitude === 0)) setFieldsEntered(true)
  })

  const validateCoords = coords => {
    const hasCoords = !!coords
    const lat = hasCoords ? coords.lat : latitude
    const lng = hasCoords ? coords.lng : longitude
    if (lat && lng) {
      for (let i = 0; i < currentGeofencesCoords.length; i++) {
        const _geofence = currentGeofencesCoords[i]
        if (_geofence.lat.toFixed(8) === lat.toFixed(8) && _geofence.lng.toFixed(8) === lng.toFixed(8)) {
          return setDupCoordError('A geofence already exists for these coordinates')
        }
      }
    }
    return setDupCoordError('')
  }

  const validateRadius = val => {
    const setMaxError = () => setRadiusError('Cannot be greater than 2000 meters')
    const setMinError = () => setRadiusError('Cannot be less than 100 meters')

    if (val || val === 0 || val === '') {
      if (val > 2000) {
        return setMaxError()
      }
      if (val < 100 && val !== '') {
        return setMinError()
      }
      if (val === '') return setRadiusError('')
      return setRadiusError('')
    }

    if (radius > 2000) {
      return setMaxError()
    }
    if (radius < 100 && radius !== '') {
      return setMinError()
    }
    return setRadiusError('')
  }

  const onChange = (key, value) => {
    let val = value
    if (value || value === 0) {
      if (key === GEOFENCE_FIELDS_MAP.lat) {
        if (value >= 90) val = 90
        if (value <= -90) val = -90
        validateCoords({ lat: val, lng: longitude })
      }
      if (key === GEOFENCE_FIELDS_MAP.lng) {
        val = calculateLng(value)
        validateCoords({ lat: latitude, lng: val })
      }
    }
    if (key === GEOFENCE_FIELDS_MAP.rad && !!radiusError) {
      validateRadius(value)
    }
    setGeofence({
      ...geofence,
      [key]: val,
    })
  }

  const onButtonPress = (onSubmit = false) => {
    if (editMode) {
      const link = `/studies/${studyID}/geofences`
      if (onSubmit) {
        updateCurrentGeofence(studyID, editGeofenceID, geofence)
      }
      browserHistory.push(link)
    } else {
      if (onSubmit) createGeofence(studyID, geofence)
      if (header) {
        setHeaderCreateGeofence(false)
      }
    }
  }

  const latFilled = latitude || latitude === 0
  const lngFilled = longitude || longitude === 0

  //
  // This boolean validates all fields for the Add Geofence button to be enabled
  //
  const requiredFieldsFilled = name && latFilled && lngFilled && radius && !radiusError && !dupCoordError

  const geofenceFieldsAndButtonList = (
    <div className='fields-button'>
      <div className='flexed fields'>
        <div className='left flexed'>
          <Input
            ref={nameInputEl}
            className='geofence-name-input'
            label='Geofenced Location Name'
            id='geofenced-location-name'
            placeholder='Name this location'
            type='text'
            onChange={val => {
              setNameEntered(true)
              onChange('name', val)
            }}
            value={name}
            disabled={studyLock}
          />
        </div>
        <div className='right flexed'>
          <Input
            hasError={!!dupCoordError}
            errorText={dupCoordError}
            id='latitude-input'
            className='latitude-input'
            label='Latitude'
            type='number'
            onChange={val => onChange('latitude', val || val === 0 ? Number(val) : '')}
            onBlur={() => validateCoords()}
            min={-90}
            max={90}
            step={1}
            value={latitude}
            disabled={studyLock}
          />
          <Input
            hasError={!!dupCoordError}
            id='longitude-input'
            className='longitude-input'
            label='Longitude'
            type='number'
            onChange={val => onChange('longitude', val || val === 0 ? Number(val) : '')}
            onBlur={() => validateCoords()}
            min={-180}
            max={180}
            step={1}
            value={longitude}
            disabled={studyLock}
          />
          <Input
            hasPlusMinus={!studyLock}
            id='radius-input'
            plusMinusButtonHeight={40}
            hasError={!!radiusError}
            errorText={radiusError}
            className='radius'
            label='Radius'
            type='number'
            onChange={val => onChange('radius', val || val === 0 ? Number(val) : '')}
            onBlur={() => validateRadius()}
            min={100}
            max={2000}
            step={10}
            value={radius}
            posttext='m'
            disabled={studyLock}
          />
        </div>
      </div>
      <div className='button-list'>
        <Button grey disabled={noGeofences || studyLock} id='cancel' content='Cancel' onClick={() => onButtonPress(false)} />
        <Button  
          id='add-geofence-location'        
          content={editMode ? 'Submit Changes' : 'Add Geofence Location'}
          disabled={!requiredFieldsFilled || studyLock}
          onClick={() => onButtonPress(true)}
        />
      </div>
    </div>
  )

  return (
    <Container className={`create-geofence ${editMode ? 'edit-mode' : ''}`}>
      <h4>{editMode ? 'Geofence Details' : 'Add Geofence Location'}</h4>
      <Map
        {...props}
        geofence={geofence}
        setGeofence={setGeofence}
        editMode={editMode}
        fieldsEntered={fieldsEntered}
        nameEntered={nameEntered}
        validateCoords={validateCoords}
        nameRef={nameInputEl}
      />
      {geofenceFieldsAndButtonList}
    </Container>
  )
}

CreateGeofence.propTypes = {
  editMode: PropTypes.bool,
  geofences: PropTypes.shape({
    checkedGeofences: PropTypes.object,
    geofencesList: PropTypes.array,
  }),
  header: PropTypes.bool,
  params: PropTypes.shape({
    siteID: PropTypes.string,
    studyID: PropTypes.string,
    editGeofenceID: PropTypes.string,
  }),
  setNavBarProps: PropTypes.func,
  createGeofence: PropTypes.func,
  updateNewGeofence: PropTypes.func,
  updateCurrentGeofence: PropTypes.func,
  setFooterCreateGeofence: PropTypes.func,
  setHeaderCreateGeofence: PropTypes.func,
  studyLock: PropTypes.bool,
}

export default CreateGeofence
