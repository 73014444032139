import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Divider, Input } from 'components/UIElements'
import { convertMinToDays } from 'utils/time'
import { pluralize } from 'utils/misc'
import { GROUP_TYPE_MAP, TRIGGER_TYPE_MAP, INSTRUMENT_TYPE_MAP, OPERATOR_OPTIONS } from 'utils/constants'
import contentConnect from '../containers/ContentContainer'

const CONDITION_DROPDOWN_OPTIONS = [
  { key: 'get_instrument_scores', value: 'get_instrument_scores', text: 'Instrument score' },
  { key: 'get_instrument_compliance', value: 'get_instrument_compliance', text: 'Completion rate' },
]

const ACTION_OPTIONS = [
  { key: 'create_virtual_visit_emergency_call', value: 'Immediate virtual visit', text: 'Immediate virtual visit' },
  {
    key: 'send_email',
    value: 'Email notification',
    text: 'Email notification',
    email_template: 'instrument_score_participant_response',
  },
]

const ROLE_OPTIONS = [
  { key: 3, value: [3, 4], text: 'Study coordinator/Investigator' },
  { key: 7, value: [7], text: 'Enroller' },
  { key: 8, value: [8], text: 'Site coordinator' },
  { key: 16, value: [16], text: 'Clinical coordinator' },
]

const TriggerManagement = props => {
  const {
    addDiaryNotificationTrigger,
    addInstrumentTrigger,
    changeTriggerType,
    deleteInstrumentTrigger,
    errors,
    instrument,
    instrumentForm,
    instrumentTriggers,
    isNewInstArch,
    isSurvey,
    hasClinro,
    scoreOptions,
    studyHasVirtualVisits,
    updateInstrumentAction,
    updateInstrumentTrigger,
  } = props
  const { id: instrumentId, metadata } = instrument
  const { id, scores } = instrumentForm || {}

  const hasScoring = !!scores?.length > 0
  const hasScoreOptions = scoreOptions?.length
  const defaultScoreParams = hasScoreOptions
    ? { score_id: scoreOptions[0].key, score_name: scoreOptions[0].text }
    : null

  const instrumentTriggerConditions = instrumentTriggers?.conditions || []

  const clinicalCoordinatorFilter = arr =>
    arr.filter(option => {
      if (!hasClinro) return option.key !== 16
      return true
    })

  /**
   * If the instrument is a diary and the instrument does not have a push notification condition for it,
   * we will automatically add a pre-set notification trigger. This trigger will be hidden to the admin user.
   *
   * In the new instrument architecture, this notification will not be added.
   */
  useEffect(() => {
    if (instrument.type === INSTRUMENT_TYPE_MAP.diary) {
      const hasDiaryNotificationTrigger = metadata?.conditions_data?.conditions?.find(condition => {
        return condition.triggers[0].resource_function_name === TRIGGER_TYPE_MAP.newActivityNotification
      })
      if (
        !hasDiaryNotificationTrigger &&
        instrumentTriggerConditions[0]?.triggers[0].resource_function_name !== TRIGGER_TYPE_MAP.newActivityNotification
      ) {
        addDiaryNotificationTrigger({ instrumentId })
      }
    }
  }, [])

  /**
   * If deployment is not by site, we remove site coordinator role option for
   * the compliance to email trigger
   */
  const isDeployedBySite = metadata.schedule.cohort.type === GROUP_TYPE_MAP.sites

  /**
   * If the instrument deployment is changed from  "Send to site" to any other option, the trigger
   * needs to be defaulted to be sent to only study coordinators as 'Site Coordinator' would not
   * be an available trigger role option.
   */
  const [roleOptions, setRoleOptions] = useState([...ROLE_OPTIONS])

  useEffect(() => {
    if (!isDeployedBySite) {
      instrumentTriggerConditions.forEach((triggerCondition, idx) => {
        const { triggers } = triggerCondition
        const triggerType = triggers ? triggers[0].resource_function_name : ''
        if (triggerType === TRIGGER_TYPE_MAP.compliance) updateInstrumentTrigger({ key: 'role_ids', value: [3] }, idx)
      })
      setRoleOptions(ROLE_OPTIONS.filter(option => option.key !== 8 && option.key !== 7))
    } else {
      setRoleOptions(ROLE_OPTIONS.filter(option => option.key !== 7))
    }
  }, [isDeployedBySite])

  let conditionDropdownOptions = [...CONDITION_DROPDOWN_OPTIONS]
  let actionOptions = [...ACTION_OPTIONS]

  if (!hasScoring) {
    conditionDropdownOptions = CONDITION_DROPDOWN_OPTIONS.slice(1)
  }
  if (!studyHasVirtualVisits) {
    actionOptions = ACTION_OPTIONS.slice(1)
  }

  const renderScoreTriggerActionForm = (idx, conditionId) => {
    const { conditions } = errors
    const _errors = conditions ? conditions[idx] : {}
    const instrumentConditionTriggers = instrumentTriggerConditions[idx]?.triggers || []
    const intrumentConditionAction = instrumentTriggerConditions[idx]?.action
    const scoreVal = instrumentConditionTriggers[0]?.resource_function_params.instrument_score
    const roleIds = instrumentConditionTriggers[0]?.resource_function_params.role_ids

    return (
      <div key={idx} className='position-relative'>
        {idx === 0 && <p>Enter a condition and the item it will trigger if it is met</p>}
        <div className='flexed'>
          <p className='label-small'>If this condition is met</p>{' '}
          {instrumentTriggerConditions.length > 0 ? (
            <Button
              className='delete'
              content=''
              iconBefore='far fa-trash-alt'
              noStyling
              onClick={() => {
                deleteInstrumentTrigger(idx, conditionId)
              }}
            />
          ) : (
            <div />
          )}
        </div>
        <div className='flexed start-justified start-aligned score-menu-row'>
          <Dropdown
            className='condition-dropdown'
            options={CONDITION_DROPDOWN_OPTIONS}
            selected={
              instrumentConditionTriggers[0]?.resource_function_name === 'get_instrument_score_by_name'
                ? 'get_instrument_scores'
                : instrumentConditionTriggers[0]?.resource_function_name
            }
            onSelect={item => {
              changeTriggerType({
                instrumentId: id,
                index: idx,
                conditionId,
                newType: item.value,
                virtualVisitsEnabled: studyHasVirtualVisits,
                scoreParams: defaultScoreParams,
              })
            }}
          />
          {isSurvey ? (
            <>
              <span>for</span>
              {intrumentConditionAction?.type === 'send_email' ? (
                <Dropdown
                  className='instrument-score-dropdown'
                  options={scoreOptions}
                  selected={instrumentConditionTriggers[0]?.resource_function_params.score_id}
                  onSelect={item => {
                    updateInstrumentTrigger(
                      { key: 'score_id', value: item.key, key2: 'score_name', value2: item.text },
                      idx,
                    )
                  }}
                />
              ) : (
                <span className='score'>any score</span>
              )}
            </>
          ) : null}
          <span>is</span>
          <Dropdown
            className='comparator-dropdown'
            options={OPERATOR_OPTIONS}
            selected={instrumentConditionTriggers[0]?.resource_function_params.logical_operator}
            disabled={isSurvey && intrumentConditionAction?.type === 'send_email'}
            onSelect={item => {
              updateInstrumentTrigger({ key: 'logical_operator', value: item.value }, idx)
            }}
          />
          <Input
            className='number-trigger-input'
            errorText={_errors?.score}
            placeholder='score'
            hasError={!!_errors?.score && scoreVal === ''}
            type='number'
            onChange={val => {
              updateInstrumentTrigger({ key: 'instrument_score', value: val === '' ? val : Number(val) }, idx)
            }}
            value={scoreVal}
          />
        </div>
        <p className='label-small'>Trigger this item</p>
        <div className='flexed start-justified start-aligned compliance-menu-row'>
          <Dropdown
            className='action-dropdown'
            options={actionOptions}
            disabled={!hasScoring}
            selected={intrumentConditionAction?.type}
            onSelect={item => {
              if (item.email_template) {
                updateInstrumentAction(
                  {
                    key: 'type',
                    value: item.key,
                    key2: Object.keys(item)[3],
                    value2: item.email_template,
                    score: scoreOptions ? scoreOptions[0] : null,
                  },
                  idx,
                )
              } else {
                updateInstrumentAction({ key: 'type', value: item.key }, idx)
              }
              if (
                instrumentConditionTriggers[0]?.resource_function_params.logical_operator !== '==' &&
                item.key === 'send_email'
              ) {
                updateInstrumentTrigger({ key: 'logical_operator', value: '==' }, idx)
              }
            }}
          />
          {intrumentConditionAction?.type === 'send_email' && (
            <>
              <span>to the</span>
              <Dropdown
                className='role-dropdown'
                options={clinicalCoordinatorFilter(ROLE_OPTIONS)}
                selected={roleIds[0]}
                onSelect={item => {
                  updateInstrumentTrigger({ key: 'role_ids', value: item.value }, idx)
                }}
              />
            </>
          )}
        </div>

        <Divider color='#eceff0' margin='24px 0px 14px' />
      </div>
    )
  }

  const renderComplianceToEmailForm = (idx, conditionId) => {
    const instrumentConditionTriggers = instrumentTriggerConditions[idx]?.triggers || []
    const windowVal = convertMinToDays(instrumentConditionTriggers[0]?.resource_function_params.window, false)
    const complianceVal = instrumentConditionTriggers[0]?.resource_function_params.completion_comparison
    const { conditions } = errors
    const _errors = conditions ? conditions[idx] : {}
    const roleIds = instrumentConditionTriggers[0]?.resource_function_params.role_ids

    return (
      <div key={idx} className='position-relative'>
        {idx === 0 && <p>Enter a condition and the item it will trigger if it is met</p>}
        <div className='flexed'>
          <p className='label-small'>If this condition is met</p>{' '}
          {instrumentTriggerConditions.length > 0 ? (
            <Button
              className='delete'
              content=''
              iconBefore='far fa-trash-alt'
              noStyling
              onClick={() => {
                deleteInstrumentTrigger(idx, conditionId)
              }}
            />
          ) : (
            <div />
          )}
        </div>
        <div className='flexed start-justified start-aligned compliance-menu-row'>
          <Dropdown
            className='condition-dropdown'
            options={conditionDropdownOptions}
            selected={instrumentConditionTriggers[0]?.resource_function_name}
            onSelect={item => {
              changeTriggerType({
                instrumentId: id,
                index: idx,
                conditionId,
                newType: item.value,
                virtualVisitsEnabled: studyHasVirtualVisits,
                scoreParams: defaultScoreParams,
              })
            }}
          />
          <span>is</span>
          <Dropdown
            className='comparator-dropdown'
            options={OPERATOR_OPTIONS}
            selected={instrumentConditionTriggers[0]?.resource_function_params.logical_operator}
            onSelect={item => {
              updateInstrumentTrigger({ key: 'logical_operator', value: item.value }, idx)
            }}
          />
          <Input
            className='number-trigger-input'
            enforceMinMax
            errorText={_errors?.completionComparison}
            hasError={!!_errors?.completionComparison && complianceVal === ''}
            placeholder='--'
            max={100}
            min={0}
            type='number'
            onChange={val => {
              updateInstrumentTrigger({ key: 'completion_comparison', value: val === '' ? val : Number(val) }, idx)
            }}
            value={complianceVal}
          />
          <span>%</span>
          <Input
            className='number-trigger-input'
            hasError={!!_errors?.daysAfterDeployment && windowVal === ''}
            errorText={_errors?.daysAfterDeployment}
            placeholder='--'
            max={100}
            min={0}
            type='number'
            onChange={val => {
              updateInstrumentTrigger(
                { key: 'window', value: val === '' ? val : convertMinToDays(Number(val), true) },
                idx,
              )
            }}
            value={windowVal}
          />
          <span>{`${pluralize(windowVal, 'day', 'days', false)} after deployment date`}</span>
        </div>
        <p className='label-small'>Trigger this item</p>
        <div className='flexed start-justified start-aligned compliance-menu-row'>
          <Dropdown className='action-dropdown' disabled options={actionOptions} selected='send_email' />
          <span>to the coordinators with the following role</span>
          <Dropdown
            className='role-dropdown'
            options={clinicalCoordinatorFilter(roleOptions)}
            onSelect={item => updateInstrumentTrigger({ key: 'role_ids', value: item.value }, idx)}
            selected={roleIds[0]}
          />
        </div>
        <Divider color='#eceff0' margin='24px 0px 14px' />
      </div>
    )
  }

  return (
    <div className='trigger-menu'>
      {instrumentTriggerConditions.map((trigger, idx) => {
        const { condition_id, triggers } = trigger
        const triggerType = triggers ? triggers[0].resource_function_name : ''
        /**
         * We are hiding new activity notifications because they are only used for diaries, and the
         * trigger-action settings have a specific behavior that we do not want to be configurable
         * by the admin user.
         */
        if (triggerType === TRIGGER_TYPE_MAP.newActivityNotification) return null
        if (triggerType === TRIGGER_TYPE_MAP.compliance) return renderComplianceToEmailForm(idx, condition_id)
        return renderScoreTriggerActionForm(idx, condition_id)
      })}
      <Button
        content='Add new trigger'
        id='add-new-trigger'
        className='add-trigger'
        iconBefore='fas fa-plus'
        noStyling
        onClick={() =>
          addInstrumentTrigger({
            instrumentId: id,
            isScoringTrigger: hasScoring,
            virtualVisitsEnabled: studyHasVirtualVisits,
            scoreParams: defaultScoreParams,
          })
        }
      />
    </div>
  )
}

TriggerManagement.propTypes = {
  addDiaryNotificationTrigger: PropTypes.func,
  addInstrumentTrigger: PropTypes.func,
  changeTriggerType: PropTypes.func,
  deleteInstrumentTrigger: PropTypes.func,
  errors: PropTypes.shape({
    conditions: PropTypes.objectOf(PropTypes.object),
  }),
  instrument: PropTypes.shape({
    id: PropTypes.string,
    metadata: PropTypes.object,
    type: PropTypes.string,
  }),
  instrumentForm: PropTypes.shape({
    scores: PropTypes.array,
  }),
  instrumentTriggers: PropTypes.objectOf(PropTypes.array),
  isNewInstArch: PropTypes.bool,
  isSurvey: PropTypes.bool,
  isDiary: PropTypes.bool,
  hasClinro: PropTypes.bool,
  studyHasVirtualVisits: PropTypes.bool,
  updateInstrumentTrigger: PropTypes.func,
  updateInstrumentAction: PropTypes.func,
  scoreOptions: PropTypes.arrayOf(PropTypes.object),
}

export default contentConnect(TriggerManagement)
