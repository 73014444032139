import { connect } from 'react-redux'
import { getBaseSiteIdFromStudy } from 'utils/misc'
import Loader from 'components/D3Loader'
import { metadataActions, schedulerActions } from '../../../modules/Instrument'
import MetadataBuilderView from '../components/MetadataBuilderPage'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION } from 'utils/constants'

const mapStateToProps = ({ instrumentReducer, study, loading, user }) => {
  const instrumentArchitectureVersion =
    study?.currentStudy?.config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION
  return {
    instrument: instrumentReducer.instrument,
    instrumentArchitectureVersion,
    errors: instrumentReducer.errors,
    studyID: study.currentStudy.id,
    siteID: getBaseSiteIdFromStudy(study),
    loading: loading.general,
    userId: user?.id,
    study: study?.currentStudy,
  }
}

const mapDispatchToProps = {
  ...metadataActions,
  ...schedulerActions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.loading) {
    return Loader(props)
  }
  return MetadataBuilderView(props)
})
