import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Divider } from 'components/UIElements'
import {
  QUESTION_TYPE_MAP,
  QUESTION_TYPES_WITH_CHOICES,
  QUESTION_TYPES_WITH_SCORING,
  SELECT_QUESTION_TYPES,
} from 'utils/constants'
import { scrollTo } from 'utils/misc'
import DiaryScoringPanel from './Scoring/DiaryScoringPanel'
import DiaryQuestionPanel from './DiaryQuestionPanel'
import DiaryValidationPanel from './DiaryValidationPanel'
import DiaryLogicPanelContainer from '../containers/DiaryLogicPanelContainer'
import { itemEditorActions } from '../modules/Diary'

const introPic = require('../../EditSurvey/assets/intro_with_greeting.png')

class DiaryItemEditor extends React.Component {
  componentDidUpdate(prevProps) {
    const { activeItemId } = this.props
    if (activeItemId !== prevProps.activeItemId) {
      this.onScrollToItem({})
    }
  }

  onChangeItemType = item => {
    const { diary, activeItemId, changeItemType } = this.props
    changeItemType(diary, activeItemId, diary.questions[activeItemId].type, item.value)
  }

  onUpdateItemInputWidth = item => {
    const { updateItem, diary, activeItemId } = this.props
    const { questions } = diary
    const question = questions[activeItemId]
    const newItem = { ...question, attributes: { ...question.attributes, inputWidth: item.value } }
    updateItem(activeItemId, newItem)
  }

  onDeleteChoice = choiceId => {
    const { activeItemId, deleteChoice, diary } = this.props
    return () => {
      deleteChoice(diary, activeItemId, choiceId)
    }
  }

  onScrollToItem = ({ isIntro }) => {
    const { activeItemId } = this.props
    const element = document.getElementById(`question-${activeItemId}`)
    let offset = 120
    if (isIntro) offset += 16
    if (element) {
      scrollTo({ element, offset })
    }
  }

  onAddChoice = () => {
    const { activeItemId, diary, addChoice } = this.props
    const { questions } = diary
    const { type } = questions[activeItemId]
    addChoice(activeItemId, questions[activeItemId], null, type)
  }

  onUpdateUnitType = e => {
    const { activeItemId, updateUnitType } = this.props
    updateUnitType(activeItemId, e.target.value)
  }

  onAddOtherChoice = e => {
    const { activeItemId, addOtherChoice } = this.props
    addOtherChoice(activeItemId)
  }

  onMoveItem = direction => {
    const { activeItemId, moveItem, diary } = this.props
    return () => {
      const startIdx = diary.order[0].indexOf(activeItemId)
      let endIdx
      if (direction === 'up') {
        endIdx = startIdx - 1
      } else {
        endIdx = startIdx + 1
      }
      moveItem(diary, startIdx, endIdx)
    }
  }

  onDuplicateItem = () => {
    const { activeItemId, duplicateItem, editorStates, question, diaryItemLabelEditorState } = this.props
    if (QUESTION_TYPES_WITH_CHOICES.includes(question.type)) {
      const choicesEditorStates = {}
      const { choices_order } = question
      const choiceQProps = { choicesOrder: choices_order }
      if (SELECT_QUESTION_TYPES.includes(question.type)) {
        choices_order.forEach(choiceId => {
          choicesEditorStates[choiceId] = editorStates[choiceId]
        })
        choiceQProps.choicesEditorStates = choicesEditorStates
      }
      duplicateItem(activeItemId, diaryItemLabelEditorState, choiceQProps)
    } else {
      duplicateItem(activeItemId, diaryItemLabelEditorState)
    }
  }

  onToggleRequiredQuestion = () => {
    const { toggleRequiredQuestion, diary, activeItemId } = this.props
    toggleRequiredQuestion(diary, activeItemId)
  }

  onToggleInputValidation = () => {
    const { toggleInputValidation, activeItemId } = this.props
    toggleInputValidation(activeItemId)
  }

  onToggleScoring = () => {
    const { diary, toggleQuestionScoring, activeItemId } = this.props
    const item = diary.questions[activeItemId]
    const { domains = [] } = item
    toggleQuestionScoring(activeItemId, domains)
  }

  onUpdateItemFormula = formula => {
    const { activeItemId, updateItemFormula } = this.props
    updateItemFormula(activeItemId, formula)
  }

  onCreateNewDomain = label => {
    const { activeItemId, createNewDomainAndAddToQuestion } = this.props
    createNewDomainAndAddToQuestion(activeItemId, label)
  }

  onToggleDomain = domainId => {
    const { activeItemId, toggleQuestionDomain } = this.props
    toggleQuestionDomain(activeItemId, domainId)
  }

  render() {
    const { activeItemId, onDeleteItem, diary } = this.props
    const item = diary.questions[activeItemId]
    const { attributes, formula } = item
    const { hasScoring } = attributes
    const { domains } = diary
    const questionDomains = item.domains
    const qNumber = item.attributes.questionNumber
    const isIntro = item.type === QUESTION_TYPE_MAP.introduction
    const isMultiField = item.type === QUESTION_TYPE_MAP.multipleField
    const isSelectMultiple = item.type === QUESTION_TYPE_MAP.selectMultiple
    const isQuestion = ![QUESTION_TYPE_MAP.introduction, QUESTION_TYPE_MAP.prompt].includes(item.type)
    const canHaveScoring = QUESTION_TYPES_WITH_SCORING.includes(item.type)
    const itemText = qNumber ? `Q${qNumber}` : isIntro ? 'Introduction Text' : 'Text'
    const disabledRequired = [
      QUESTION_TYPE_MAP.introduction,
      QUESTION_TYPE_MAP.audioRecording,
      QUESTION_TYPE_MAP.prompt,
    ].includes(item.type)

    return (
      <div className='item-editor' onClick={() => this.onScrollToItem({ isIntro })}>
        <div className='editor-toolbar flexed'>
          <div className='flexed align-center'>
            <div className='question-number'>
              <h4>{itemText}</h4>
            </div>
            {!isIntro && (
              <>
                <Divider height='15px' width='1px' color='#cacaca' />
                <DiaryValidationPanel
                  hasCharLimit={item.attributes.hasCharLimit}
                  disabledRequired={disabledRequired}
                  isMultiField={isMultiField}
                  onToggleInputValidation={this.onToggleInputValidation}
                  onToggleRequiredQuestion={this.onToggleRequiredQuestion}
                  required={item.attributes.required}
                />
                <div className='toolbar-buttons button-list'>
                  <Button className='far fa-clone hoverable-1' onClick={this.onDuplicateItem} noStyling />
                  <Button className='fas fa-trash-alt hoverable-red' onClick={onDeleteItem(activeItemId)} noStyling />
                  <i
                    className={`clickable fas fa-arrow-up${
                      diary.order[0][1] === activeItemId || diary.order[0][0] === activeItemId ? ' disabled' : ''
                    }`}
                    onClick={this.onMoveItem('up')}
                  />
                  <i
                    className={`clickable fas fa-arrow-down${
                      diary.order[0][diary.order[0].length - 1] === activeItemId || item.type === 'introduction'
                        ? ' disabled'
                        : ''
                    }`}
                    onClick={this.onMoveItem('down')}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {item.type === 'introduction' && (
          <div>
            <div className='panel'>Introduction text will be given in a speech bubble by Humi.</div>
            <img src={introPic} alt='intro-sample' />
          </div>
        )}
        {isQuestion && (
          <>
            <DiaryQuestionPanel
              item={item}
              onAddOtherChoice={this.onAddOtherChoice}
              onChangeItemType={this.onChangeItemType}
              onDeleteChoice={this.onDeleteChoice}
              onAddChoice={this.onAddChoice}
              onUpdateUnitType={this.onUpdateUnitType}
              onUpdateItemInputWidth={this.onUpdateItemInputWidth}
              {...this.props}
            />
            {canHaveScoring && (
              <DiaryScoringPanel
                createNewDomain={this.onCreateNewDomain}
                domains={domains}
                hasCalcDropdown={isSelectMultiple}
                hasScoring={hasScoring}
                onToggleDomain={this.onToggleDomain}
                onToggleScoring={this.onToggleScoring}
                onUpdateItemFormula={this.onUpdateItemFormula}
                questionDomains={questionDomains}
                formula={formula}
              />
            )}
          </>
        )}
        {item.attributes.canHaveLogic && <DiaryLogicPanelContainer itemId={activeItemId} />}
      </div>
    )
  }
}

DiaryItemEditor.propTypes = {
  activeItemId: PropTypes.string,
  addChoice: PropTypes.func,
  addOtherChoice: PropTypes.func,
  changeItemType: PropTypes.func,
  createNewDomainAndAddToQuestion: PropTypes.func,
  deleteChoice: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorStates: PropTypes.objectOf(PropTypes.object),
  item: PropTypes.object,
  itemId: PropTypes.string,
  moveItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  question: PropTypes.shape({
    choices_order: PropTypes.array,
    type: PropTypes.string,
  }),
  diary: PropTypes.object,
  diaryItemLabelEditorState: PropTypes.object,
  toggleInputValidation: PropTypes.func,
  toggleQuestionDomain: PropTypes.func,
  toggleRequiredQuestion: PropTypes.func,
  toggleQuestionScoring: PropTypes.func,
  updateItem: PropTypes.func,
  updateItemFormula: PropTypes.func,
  updateUnitType: PropTypes.func,
}

export default connect(null, itemEditorActions)(DiaryItemEditor)
