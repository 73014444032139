import { combineReducers } from 'redux'
import { loadingActions } from 'store/loader'
import moment from 'moment'
import { actions as notyActions } from 'layouts/ErrorBox'
import { modalActions } from 'store/modal'
import {
  DEFAULT_NA_STRING_SORT_VAL,
  VISIT_INFO_TYPE_MAP,
  VISIT_TIMELINE_EVENT_MAP,
  VISIT_NOTY_TYPE_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  MODAL_CONTENT_MAP,
  DATE_FORMAT_MAP,
} from 'utils/constants'
import request, { generateNotyMessage } from 'utils/request'
import { groupByDate, getFormattedTimeString, generateMultiUnitDifference, getFormattedDateString } from 'utils/time'

//
// Actions
//

const SET_VISITS = 'SET_VISITS'
const SET_VISITS_HISTORY = 'SET_VISITS_HISTORY'
const SET_VISIT_INFO = 'SET_VISIT_INFO'
const TOGGLE_VISIT_EXPAND = 'TOGGLE_VISIT_EXPAND'
const RESET_EXPANDED_VISITS = 'RESET_EXPANDED_VISITS'
const TOGGLE_VISIT = 'TOGGLE_VISIT'
const RESET_CHECKED_VISITS = 'RESET_CHECKED_VISITS'
const TOGGLE_CHECK_ALL_VISITS = 'TOGGLE_CHECK_ALL_VISITS'

//
// Action Creators
//

const setVisits = ({ visits }) => {
  return {
    type: SET_VISITS,
    payload: visits,
  }
}
const setVisitInfo = (payload, visitId) => {
  return {
    type: SET_VISIT_INFO,
    payload,
    visitId,
  }
}

const setVisitsHistory = ({ visits }) => {
  return {
    type: SET_VISITS_HISTORY,
    payload: visits,
  }
}

const toggleVisit = id => {
  return {
    type: TOGGLE_VISIT,
    id,
  }
}

const toggleCheckAllVisits = (visits, list) => {
  return {
    type: TOGGLE_CHECK_ALL_VISITS,
    visits,
    list,
  }
}

const toggleVisitExpand = visitId => {
  return {
    type: TOGGLE_VISIT_EXPAND,
    visitId,
  }
}
const resetExpandedVisits = () => {
  return {
    type: RESET_EXPANDED_VISITS,
  }
}

const resetCheckedVisits = () => {
  return {
    type: RESET_CHECKED_VISITS,
  }
}

//
// Utils
//

const formatVisitsList = visitsList => {
  return visitsList.map(
    ({
      email,
      fname,
      id,
      leaf_site_id,
      leaf_site_name,
      lname,
      nickname,
      participant_id,
      visit_duration,
      visit_time,
    }) => {
      const ptpName = `${fname}${lname ? ` ${lname}` : ''}` || `Participant ${participant_id}`
      const visitTime = moment.utc(visit_time)
      const row = [
        { key: 'checkbox', value: id, sortValue: DEFAULT_NA_STRING_SORT_VAL },
        { key: 'participantName', value: ptpName, sortValue: ptpName, className: 'participant-name-data' },
        { key: 'ptpId', value: participant_id, sortValue: participant_id },
        { key: 'clinicianName', value: nickname, sortValue: nickname, className: 'clinician-name-data' },
        { key: 'clinicianEmail', value: email, sortValue: email },
        {
          key: 'visitTime',
          value: `${visitTime.format(DATE_FORMAT_MAP.mainWithDateTime)} UTC`,
          sortValue: visitTime.valueOf(),
        },
        // TODO: update to use TZ in future {
        //   key: 'visitTime',
        //   value: generateDateStringWithTimeZone(visitTime, false, true, true),
        //   sortValue: visitTime.valueOf(),
        // },
        { key: 'visitDuration', value: `${visit_duration} min`, sortValue: visit_duration },
      ]
      row.siteId = leaf_site_id
      row.leafSiteName = leaf_site_name
      return row
    },
  )
}
const formatVisitsHistoryList = visitsHistoryList => {
  return visitsHistoryList.map(
    ({
      fname,
      id,
      is_recorded,
      leaf_site_id,
      leaf_site_name,
      lname,
      nickname,
      participant_id,
      status,
      subject_id,
      user_id,
      visit_time,
    }) => {
      const visitTime = moment.utc(visit_time)
      const row = [
        { key: 'checkbox', value: id, sortValue: DEFAULT_NA_STRING_SORT_VAL },
        {
          key: 'participantName',
          value: `${fname} ${lname}`,
          sortValue: lname,
          additionalInfo: [`Participant ID ${participant_id}`, `Subject ID ${subject_id || '--'}`],
          visitId: id,
          status,
          isRecorded: is_recorded,
        },
        { key: 'ptpId', value: participant_id, sortValue: participant_id },
        { key: 'subjectId', value: subject_id || '--', sortValue: subject_id || DEFAULT_NA_STRING_SORT_VAL },
        { key: 'clinicianName', value: nickname, sortValue: nickname, additionalInfo: [`Clinician ID ${user_id}`] },
        { key: 'clinicianId', value: user_id, sortValue: user_id },
        {
          key: 'siteId',
          value: leaf_site_id,
          sortValue: leaf_site_id,
          className: 'site-data',
          leafSiteName: leaf_site_name,
        },
        { key: 'status', value: status, sortValue: status },
        {
          key: 'date',
          value: `${visitTime.format(DATE_FORMAT_MAP.mainWithDateTime)} UTC`,
          sortValue: visitTime.valueOf(),
        },
        // TODO: update to use TZ {
        //   key: 'date',
        //   value: generateDateStringWithTimeZone(visitTime, false, true, true), // (timestamp, showTimeOnly, uppercaseAmPm, showTimeZone)
        //   sortValue: visitTime.valueOf(),
        // },
      ]
      row.siteId = leaf_site_id
      row.leafSiteName = leaf_site_name
      return row
    },
  )
}

const getScheduledVisitString = (duration, visitTime) => {
  const dateMoment = moment.utc(visitTime)
  const dateMomentClone = dateMoment.clone()
  dateMomentClone.add(duration, 'minutes')

  /**
   * This creates a time string with duration that looks like:
   * "April 23th, 2020, 7:05 am - 7:35pm (30 min)"
   * */

  return `${dateMoment.format(DATE_FORMAT_MAP.mainWithDateTime)} - ${dateMomentClone.format(
    'h:mm a',
  )} (${duration} min)`
}

const VISIT_EVENT_TEXT_MAP = {
  cancelled: 'Cancelled',
  createdDate: 'Created',
  virtualVisitBegins: 'Virtual Visit Begins',
  virtualVisitEnds: 'Virtual Visit Ends',
}

const formatEvent = timelineEvent => {
  const { duration, event_type, is_adhoc, is_admin, visitor_name, visitors, start_time, timestamp } = timelineEvent
  const resultEventObj = {
    timestamp,
    time: getFormattedTimeString(timestamp),
    // TODO: time: generateDateStringWithTimeZone(timestamp, true),
  }
  let participantName
  let clinicianName

  if (visitors) {
    visitors.forEach(visitor => {
      if (visitor.is_admin) clinicianName = visitor.visitor_name
      else participantName = visitor.visitor_name
    })
  }

  switch (event_type) {
    case VISIT_TIMELINE_EVENT_MAP.createdDate:
      if (is_adhoc) resultEventObj.event = { text: `Created & Entered: ${visitor_name}` }
      else {
        resultEventObj.event = {
          text: VISIT_EVENT_TEXT_MAP.createdDate,
          additionalInfo: [getScheduledVisitString(duration, start_time)],
        }
        if (visitors)
          resultEventObj.event.additionalInfo.push(`Participant ${participantName} and Clinician ${clinicianName}`)
      }
      break
    case VISIT_TIMELINE_EVENT_MAP.cancelled:
      resultEventObj.event = { text: VISIT_EVENT_TEXT_MAP.cancelled }
      break
    case VISIT_TIMELINE_EVENT_MAP.virtualVisitBegins:
      resultEventObj.event = { text: VISIT_EVENT_TEXT_MAP.virtualVisitBegins, isStartOrEnd: true }
      break
    case VISIT_TIMELINE_EVENT_MAP.virtualVisitEnds:
      resultEventObj.event = { text: VISIT_EVENT_TEXT_MAP.virtualVisitEnds, isStartOrEnd: true }
      break
    case VISIT_TIMELINE_EVENT_MAP.ptpConnected:
      if (is_admin) resultEventObj.event = { text: `Entered: Clinician ${visitor_name}`, clinicianConnected: true }
      else resultEventObj.event = { text: `Entered: Participant ${visitor_name}`, participantConnected: true }
      break
    case VISIT_TIMELINE_EVENT_MAP.ptpDisconnected:
      if (is_admin) resultEventObj.event = { text: `Left: Clinician ${visitor_name}` }
      else resultEventObj.event = { text: `Left: Participant ${visitor_name}` }
      break
    default:
      break
  }
  return resultEventObj
}

const formatInfo = (info, infoType) => {
  const resInfo = {}
  switch (infoType) {
    case VISIT_INFO_TYPE_MAP.type:
      if (info.is_adhoc) {
        resInfo.heading = `Adhoc on ${getFormattedDateString(info.created, DATE_FORMAT_MAP.mainWithDateTime, true)}`
        const clinicians = []
        const { visitors } = info
        if (visitors) {
          visitors.forEach(visitor => {
            const { is_admin, nickname, id } = visitor
            if (is_admin) {
              clinicians.push(`${nickname}, Clinician ${id}`)
            }
          })
          resInfo.content = [{ text: `Clinicians (${clinicians.length})`, list: clinicians }]
        }
      } else {
        const { created, cancelled_time, start_time } = info
        resInfo.heading = `Scheduled for ${getFormattedDateString(start_time, DATE_FORMAT_MAP.mainWithDateTime, true)}`
        resInfo.content = []
        if (created) {
          resInfo.content.push({
            text: `Created on ${getFormattedDateString(created, DATE_FORMAT_MAP.mainWithDateTime, true)}`,
          })
        }
        if (cancelled_time) {
          resInfo.content.push({
            text: `Cancelled on ${getFormattedDateString(cancelled_time, DATE_FORMAT_MAP.mainWithDateTime, true)}`,
          })
        }
      }
      break
    case VISIT_INFO_TYPE_MAP.length: {
      const { start_time, end_time } = info
      const { string } = generateMultiUnitDifference(start_time, end_time)
      resInfo.heading = `Length (${string})`
      resInfo.content = [{ text: `From ${getFormattedTimeString(start_time)} to ${getFormattedTimeString(end_time)}` }]
      break
    }
    case VISIT_INFO_TYPE_MAP.instrumentsCompleted: {
      const numInstrumentsCompleted = info.length
      resInfo.heading = `Instruments Completed (${numInstrumentsCompleted})`
      resInfo.content = info.map(instrumentObj => {
        const { title } = instrumentObj
        return { text: title }
      })
      break
    }
    case VISIT_INFO_TYPE_MAP.notifications:
    default: {
      let notificationCount = 0
      const { admin, participant } = info
      const adminNotificationObj = {}
      const hasAdminNoty = admin.length > 0
      const hasPtpNoty = participant.length > 0
      if (hasAdminNoty) {
        adminNotificationObj.text = 'Clinician'
        adminNotificationObj.list = admin.map(noty => {
          const { type, timestamp } = noty
          notificationCount += 1
          return `${VISIT_NOTY_TYPE_MAP[type] || 'Notification'} sent at ${getFormattedDateString(
            timestamp,
            'h:mm A UTC DD-MMM-YYYY',
            true, // last boolean param is to specify to use UTC
          )}`
        })
      }
      const ptpNotificationObj = {}
      if (hasPtpNoty) {
        ptpNotificationObj.text = 'Participant'
        ptpNotificationObj.list = participant.map(noty => {
          const { type, timestamp } = noty
          notificationCount += 1
          return `${VISIT_NOTY_TYPE_MAP[type] || 'Notification'} sent at ${getFormattedDateString(
            timestamp,
            'h:mm A UTC DD-MMM-YYYY',
            true,
          )}`
        })
      }
      resInfo.heading = `Notifications Sent (${notificationCount})`
      resInfo.content = []
      if (hasAdminNoty) resInfo.content.push(adminNotificationObj)
      if (hasPtpNoty) resInfo.content.push(ptpNotificationObj)
    }
  }
  return resInfo
}

const generateFormatAdditionalInfo = additionalInfo => {
  const resultAdditionalInfo = {}
  const infoKeys = Object.keys(additionalInfo)
  infoKeys.forEach(infoKey => {
    resultAdditionalInfo[infoKey] = formatInfo(additionalInfo[infoKey], infoKey)
  })
  return resultAdditionalInfo
}

const formatVisitInfo = payload => {
  const result = { ...payload }
  const { timeline, additional_info, download_links } = payload
  const resTimeline = timeline.map(event => formatEvent(event))
  const resAddInfo = generateFormatAdditionalInfo(additional_info)
  const groupedTimeline = groupByDate(resTimeline, 'timestamp') // This creates an object of timeline event arrays grouped by date
  result.timeline = groupedTimeline
  result.additionalInfo = resAddInfo
  result.downloadLinks = download_links || []
  delete result.additional_info
  delete result.message
  delete result.download_links
  return result
}

// Fetch functions

const fetchVisits = (studyId, siteId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    dispatch(setVisits(payload))
    onSuccess()
  }
  return dispatch(
    request({
      url: `/control/studies/${studyId}/sites/${siteId}/visits`,
      success,
      hasLoader: true,
      loadingKey: 'virtualVisits',
    }),
  )
}

const fetchVisitsHistory = (studyId, siteId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    dispatch(setVisitsHistory(payload))
    onSuccess()
  }
  return dispatch(
    request({
      url: `/control/studies/${studyId}/sites/${siteId}/visits_history`,
      success,
      hasLoader: true,
      loadingKey: 'visitsHistory',
    }),
  )
}

const fetchVisit = (studyId, siteId, visitId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    dispatch(setVisitInfo(payload, visitId))
    onSuccess()
    dispatch(loadingActions.stopLoader(true, 'visit', visitId))
  }
  dispatch(loadingActions.startLoader(true, 'visit', visitId))
  return dispatch(
    request({
      url: `/control/studies/${studyId}/sites/${siteId}/visits/${visitId}`,
      success,
    }),
  )
}

const deleteVisit = (studyId, siteId, id, failedDeletions) => dispatch => {
  const fail = () => {
    failedDeletions.push(id)
  }
  return dispatch(
    request({
      method: 'DELETE',
      url: `/control/studies/${studyId}/sites/${siteId}/visits/${id}`,
      fail,
    }),
  )
}

const _deleteVisits = (studyId, siteId, visits, plural) => dispatch => {
  const visitArr = Object.keys(visits)
  const failedDeletions = []
  dispatch(loadingActions.startLoader(true))
  return new Promise((resolve, reject) => {
    visitArr.forEach((id, idx) => {
      const res = dispatch(deleteVisit(studyId, siteId, id, failedDeletions))
      if (idx === visitArr.length - 1) {
        res.then(() => {
          resolve()
        })
      }
    })
  }).then(() => {
    if (failedDeletions.length > 0) {
      dispatch(
        notyActions.showError({
          text: generateNotyMessage(
            `There was a problem deleting visit${plural ? 's' : ''} with the following ${
              plural ? 'IDs' : 'ID'
            }: ${failedDeletions.join(',')}`,
            false,
          ),
        }),
      )
    } else {
      dispatch(
        notyActions.showSuccess({
          text: generateNotyMessage(`Visit${plural ? 's' : ''} successfully cancelled.`, true, 'fas fa-trash-alt'),
        }),
      )
    }
    dispatch(resetCheckedVisits())
    dispatch(fetchVisits(studyId, siteId, () => dispatch(loadingActions.stopLoader(true))))
  })
}

const deleteVisits = (studyId, siteId, visits, plural = true) => {
  return dispatch => {
    const onConfirm = () => {
      dispatch(_deleteVisits(studyId, siteId, visits, plural))
    }
    dispatch(
      modalActions.openModal({
        content: `${MODAL_CONTENT_MAP.cancelQuestion} ${plural ? 'these visits' : 'this visit'}? ${
          MODAL_CONTENT_MAP.undone
        }`,
        onConfirm,
        className: MODAL_CLASSES_MAP.confirmation,
        confirmButton: MODAL_BUTTONS_MAP.cancelVisit,
        cancelButton: MODAL_BUTTONS_MAP.nevermind,
      }),
    )
  }
}

//
// Reducers
//

export const visitsList = (state = [], action) => {
  switch (action.type) {
    case SET_VISITS:
      return formatVisitsList(action.payload)
    default:
      return state
  }
}

export const visitsHistoryList = (state = [], action) => {
  switch (action.type) {
    case SET_VISITS_HISTORY:
      return formatVisitsHistoryList(action.payload)
    default:
      return state
  }
}

export const visitsInfo = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_VISIT_INFO:
      newState[action.visitId] = formatVisitInfo(action.payload)
      return newState
    default:
      return state
  }
}

const checkedVisits = (state = {}, action) => {
  let newState
  switch (action.type) {
    case TOGGLE_VISIT:
      newState = { ...state }
      if (action.id in newState) delete newState[action.id]
      else newState[action.id] = true
      return newState
    case RESET_CHECKED_VISITS:
      return {}
    case TOGGLE_CHECK_ALL_VISITS:
      newState = {}
      if (Object.keys(state).length > 0) return newState // clear checks
      if (action.list) {
        action.list.forEach(id => {
          newState[id] = true
        })
      } else {
        action.visits.forEach(visit => {
          newState[visit[0].value] = true
        })
      }
      return newState
    default:
      return state
  }
}

const expandedVisits = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case TOGGLE_VISIT_EXPAND:
      if (newState[action.visitId]) delete newState[action.visitId]
      else newState[action.visitId] = true
      return newState
    case RESET_EXPANDED_VISITS:
      return {}
    default:
      return state
  }
}

export default combineReducers({ checkedVisits, expandedVisits, visitsList, visitsHistoryList, visitsInfo })

export const actions = {
  fetchVisit,
  fetchVisits,
  fetchVisitsHistory,
  resetCheckedVisits,
  resetExpandedVisits,
  setVisits,
  toggleCheckAllVisits,
  toggleVisit,
  toggleVisitExpand,
  deleteVisits,
}
