import React from 'react'
import PropTypes from 'prop-types'
import { pluralize } from 'utils/misc'
import handleComponentStateLoading from 'utils/handleComponentStateLoading'
import { Container, AutocompleteSearch } from 'components/UIElements'
import Tags from './Tags'
import '../stylesheets/cohortmanagement.scss'

class CohortManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      creatingTag: false,
    }
  }

  componentDidMount() {
    const { fetchCohorts, cohortList, studyID } = this.props
    if (cohortList === null) {
      fetchCohorts(studyID, true, true)
    }
  }

  componentWillUnmount() {
    const { initializeCheckedCohorts } = this.props
    initializeCheckedCohorts([])
  }

  onToggleCohort = id => {
    const {
      onChange,
      toggleCohort,
      onToggleCohort,
      clearCohorts,
      checked,
      enforceSingleTrackAssignment = false,
    } = this.props

    if (enforceSingleTrackAssignment && !Object.keys(checked).includes(String(id))) {
      clearCohorts()
    }

    if (onChange) onChange()
    if (onToggleCohort) onToggleCohort(id)
    toggleCohort(id)
  }

  onAddNewCohort = searchTerm => {
    const { onChange, saveCohortInline, studyID, onToggleCohort } = this.props
    if (onChange) onChange()
    handleComponentStateLoading.call(
      this,
      saveCohortInline.bind(this, studyID, { group_name: searchTerm, description: searchTerm }, {}, onToggleCohort),
      'creatingTag',
    )
  }

  renderTopItem = list => {
    const { disableTrackCreation } = this.props
    return searchTerm => {
      if (list.find(item => item.text === searchTerm) === undefined) {
        return disableTrackCreation ? (
          <li className='no-match'>No Matching Tracks</li>
        ) : (
          <li className='top-item' onClick={() => this.onAddNewCohort(searchTerm)}>
            <i className='fas fa-plus' />
            Add As New Track
          </li>
        )
      }
    }
  }

  generateListAndTags = () => {
    const { checked, cohortList } = this.props
    const tags = []
    const list = cohortList
      ? cohortList.map(row => {
          const item = { key: row[0].value, text: row[1].value }
          if (item.key in checked) tags.push(item)
          return item
        })
      : []
    return { list, tags }
  }

  render() {
    const { creatingTag } = this.state
    const { checked, className, disabled, errorText, hasError, ptpName, title } = this.props
    const { list, tags } = this.generateListAndTags()
    const noCohorts = tags.length > 0 ? null : <p>{`${ptpName} does not belong to a track.`}</p>

    return (
      <Container className={className} centered flex column>
        {title && <div className='title'>{title}</div>}
        <div>
          {tags.length > 0 ? (
            <>
              <p>
                <b>{tags.length}</b> {`${pluralize(tags.length, 'track', 'tracks', false)}`} assigned
              </p>
              <Tags disabled={disabled} toggle={this.onToggleCohort} tags={tags} />
            </>
          ) : null}
          {!disabled ? (
            <AutocompleteSearch
              loading={creatingTag}
              disabled={creatingTag}
              hasError={hasError}
              errorText={errorText}
              renderTopItem={this.renderTopItem(list)}
              checked={checked}
              toggleItem={this.onToggleCohort}
              id='participant-search-track'
              placeholder='Search for a track...'
              list={list}
            />
          ) : (
            noCohorts
          )}
        </div>
      </Container>
    )
  }
}

CohortManagement.propTypes = {
  checked: PropTypes.object,
  className: PropTypes.string,
  cohortList: PropTypes.arrayOf(PropTypes.array),
  disabled: PropTypes.bool,
  disableTrackCreation: PropTypes.bool,
  errorText: PropTypes.string,
  fetchCohorts: PropTypes.func,
  hasError: PropTypes.bool,
  initializeCheckedCohorts: PropTypes.func,
  onChange: PropTypes.func,
  onToggleCohort: PropTypes.func,
  ptpName: PropTypes.string,
  saveCohortInline: PropTypes.func,
  studyID: PropTypes.number,
  toggleCohort: PropTypes.func,
  title: PropTypes.string,
}

export default CohortManagement
