import React from 'react'
import PropTypes from 'prop-types'
import { ExpandableList } from 'components/UIElements'
import { generateLanguagesString, generateRecipientsString } from '../../../ConsentView/utils/consentUtils'

const FIELDS = ['consent name', 'ui display name', 'recipient', 'file format', 'language']

const DetailsContainer = props => {
  const { consent, cohortMap, localesMap, sitesMap, loadingPtps } = props
  const { consent_title, metadata } = consent
  const { other_languages, schedule } = metadata
  const { cohort } = schedule

  const consentObj = {
    consentName: consent_title,
    uiDisplayName: metadata.display_name,
    recipiant: generateRecipientsString({ cohortMap, recipients: cohort, sitesMap }),
    fileFormat: 'Linkt Consent',
    language: generateLanguagesString({ otherLanguages: other_languages, localesMap }),
  }

  const fieldValues = [
    consentObj.consentName,
    consentObj.uiDisplayName,
    consentObj.recipiant,
    consentObj.fileFormat,
    consentObj.language,
  ]

  return (
    <div className='page-break details-container'>
      <h4>Consent Details</h4>
      <div className='details-table'>
        {!loadingPtps && (
          <table>
            <tbody>
              {FIELDS.map((field, idx) => {
                if (field === 'recipient') {
                  return (
                    <tr key={field}>
                      <td
                        style={{
                          verticalAlign: 'top',
                        }}
                        className='label-small first-column'>
                        {field}
                      </td>
                      <td className='fw-medium'>
                        {fieldValues[idx].map((item, index) => {
                          if (!item?.label) return <React.Fragment key={index}>{item}</React.Fragment>
                          return <ExpandableList className='recipients' key={index} {...item} cutLabel={false} />
                        })}
                      </td>
                    </tr>
                  )
                }
                return (
                  <tr key={field}>
                    <td className='label-small first-column'>{field}</td>
                    <td className='fw-medium'>{fieldValues[idx]}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

DetailsContainer.propTypes = {
  cohortMap: PropTypes.objectOf(PropTypes.string),
  consent: PropTypes.shape({
    consent_title: PropTypes.string,
    metadata: PropTypes.object,
  }),
  sitesMap: PropTypes.objectOf(PropTypes.string),
  localesMap: PropTypes.objectOf(PropTypes.object),
}

export default DetailsContainer
