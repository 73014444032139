import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'components/UIElements'
import { getHighestUser } from 'utils/misc'
import { USER_ROLE_ID_MAP } from 'utils/constants'
import StudiesList from '../components/StudiesList'
import { studiesAction } from '../modules/Studies'
import { actions as studyAction } from '../../routes/CreateStudy/modules/CreateStudy'
import { retrieveStudyPaginationSettings, clearStudyPaginationSettings } from 'utils/studyConfigUtils'

const mapState = state => {
  const { loading, study, studies, user, searchTerm } = state
  const currentUserRoleId = getHighestUser({ user, getRoleId: true })
  const studyPaginationSettings = retrieveStudyPaginationSettings(user?.id)
  if (!studyPaginationSettings) {
    clearStudyPaginationSettings()
  }
  const roleIdArrWithNoCreateStudyAccess = [
    USER_ROLE_ID_MAP.enroller,
    USER_ROLE_ID_MAP.siteCoordinator,
    USER_ROLE_ID_MAP.caseManager,
    USER_ROLE_ID_MAP.clinicalCoordinator,
    USER_ROLE_ID_MAP.siteUserManagementAdmin,
  ]
  return {
    loading: loading.general || loading.studyConfig,
    study: study.currentStudy,
    studies,
    allowCreateStudy:
      getHighestUser({ user }).includes('wu') && !roleIdArrWithNoCreateStudyAccess.includes(currentUserRoleId),
    user,
    searchTerm,
    studyPaginationSettings,
  }
}

export default connect(mapState, { ...studyAction, ...studiesAction })(props => {
  if (props.loading || props.studies.length === 0) return <Loader />
  return <StudiesList {...props} />
})
