import {
  UserIsAuthenticated,
  UserHasValidPassword,
  UserDoesNotHaveRole,
  UserHasFeatures,
  UserHasScope,
} from 'containers/authContainers'
import { STUDY_CONFIG_MAP, USER_ROLES_MAP } from 'utils/constants'
import VisitSchedulesPageContainer from './containers/VisitSchedulesPageContainer'

// Sync route definition
export default store => ({
  exact: true,
  getComponent(nextState, cb) {
    const { params } = nextState
    const { studyID } = params
    const { study } = store.getState()
    require.ensure(
      [],
      () => {
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserDoesNotHaveRole(
                [USER_ROLES_MAP.enroller],
                UserHasScope(
                  ['wpv', 'rpv'],
                  UserHasFeatures(
                    VisitSchedulesPageContainer,
                    [STUDY_CONFIG_MAP.visitSupport],
                    'compliance',
                    false,
                    false,
                  ),
                  false,
                  studyID,
                  null,
                  study.CurrentStudy,
                ),
              ),
            ),
          ),
        )
      },
      'visit-schedules',
    )
  },
})
