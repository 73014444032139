import React from 'react'
import { connect } from 'react-redux'
import { setNavBarProps } from 'store/navbar'
import actions from '../modules/UploadTraining'

import UploadTrainingPage from '../components/UploadTrainingPage'

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps
  const { loading, sending, studyLock } = state

  const { studyID, trainingMaterialType } = params
  return {
    sending,
    studyID,
    studyLock,
    trainingMaterialType,
    mediaLoading: loading.mediaUpload,
    thumbnailLoading: loading.thumbnail,
  }
}
const mapDispatchToProps = { setNavBarProps, ...actions }

export default connect(mapStateToProps, mapDispatchToProps)(props => <UploadTrainingPage {...props} />)
