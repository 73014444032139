import { connect } from 'react-redux'
import { authActions } from 'store/user'
import NavBar from './NavBar'

const navText = {
  studies: 'All Studies',
  audit: 'Audit Report',
}

const mapDispatchToProps = {
  onLogout: authActions.logout,
}

const mapStateToProps = ({
  user,
  userReducer,
  study,
  subroute,
  location,
  sending,
  studies,
  modal,
  navBar,
  view,
  studyLock,
  loading,
}) => {
  const selectedUser = userReducer ? userReducer.user.nickname : undefined
  const dropdownText =
    studies.length === 0 && navText[subroute] === 'studies'
      ? 'Create Study'
      : navText[subroute] || selectedUser || study.currentStudy.platform_name
  const disabled = sending.sendingState || sending.sentState
  return {
    studyLock,
    disabled,
    view,
    selectedUser,
    user,
    location,
    studies,
    modal,
    subroute,
    dropdownText,
    study: study.currentStudy,
    showNavLinks: subroute !== 'studies',
    navProps: navBar,
    hasPayments: ['cycles', 'reward', 'reward_goal'].some(
      key => key in study.currentStudy && study.currentStudy[key] !== null,
    ),
    loadingStudy: loading.studyConfig,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
