import React from 'react'
import PropTypes from 'prop-types'
import { CURRENCY_MAP } from 'utils/constants'

const CountBox = ({ currency, num, label }) => {
  const currencySymbol = CURRENCY_MAP[currency]
  const labelToRender = label.toUpperCase()

  return (
    <div className='count-box flexed'>
      <p>{labelToRender}</p>
      <h4>{currency ? `${currencySymbol}${num / 100.0}` : num}</h4>
    </div>
  )
}

CountBox.propTypes = {
  currency: PropTypes.string,
  label: PropTypes.string,
  num: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default CountBox
