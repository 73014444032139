import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AutocompleteSearch, Button, Checkbox, Dropdown } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { convertLanguagesToCheckedObject } from '../utils/studyUtils'

const LanguageTags = ({ disabled, tags, toggleLanguage }) => {
  return (
    <div className='language-tags'>
      {tags.map(tag => {
        const { id, text } = tag
        return (
          <span key={id}>
            {text}
            {!disabled && <i onClick={() => toggleLanguage(tag)} className='fas fa-times' />}
          </span>
        )
      })}
    </div>
  )
}

const PLACEHOLDER = { key: 'placeholder', text: 'Select a default language' }

const EnforcedLanguagesConfig = ({
  disabled,
  locales,
  resetEnforcedLanguage,
  study,
  updateConfig,
  toggleLanguage,
  hasError,
}) => {
  const [isDescriptionCollapsed, setisDescriptionCollapsed] = useState(true)
  const config = study.config || {}
  const { enforced_language } = config
  const tags = enforced_language ? enforced_language.languages : []
  const tagKeys = tags.map(tag => tag.key)
  const areAllLanguagesAllowed = disabled ? !config.enforced_language : !config.hasEnforcedLanguage

  const addEnforcedLanguageKey = () => {
    updateConfig({
      hasEnforcedLanguage: true,
      enforced_language: {
        languages: [],
        default_language: '',
        ...config.enforced_language,
      },
    })
  }

  const clearLanguages = () => {
    updateConfig({
      hasEnforcedLanguage: true,
      enforced_language: {
        languages: [],
        default_language: '',
      },
    })
  }
  const allowedLanguagesText = tags.length ? (
    <p>
      <strong>{`${tags.length}`}</strong>
      {` ${pluralize(tags.length, 'language', 'languages', false)} allowed`}
    </p>
  ) : (
    <p>No languages selected</p>
  )

  return (
    <div className='enforced-languages-config'>
      <p className='label-small'>Allowed Language(s)</p>
      {!disabled && (
        <div className='flexed baseline-aligned'>
          <AutocompleteSearch
            checked={areAllLanguagesAllowed ? [] : convertLanguagesToCheckedObject(tags || [])}
            disabled={disabled}
            toggleItem={item => {
              if (!config.hasEnforcedLanguage) {
                clearLanguages()
              }
              toggleLanguage(item)
            }}
            id='search-language-input'
            placeholder='Search languages to add'
            shouldReturnItem
            list={areAllLanguagesAllowed ? locales : locales.filter(locale => !tagKeys.includes(locale.key))}
          />
          <Checkbox
            id='allow-all-languages'
            label='Allow all languages'
            checked={areAllLanguagesAllowed}
            disabled={disabled}
            onClick={() => {
              const willBeEnforced = !config.hasEnforcedLanguage
              if (willBeEnforced) {
                addEnforcedLanguageKey()
              } else {
                resetEnforcedLanguage()
              }
            }}
          />
        </div>
      )}
      <div className={`default-language-section${hasError ? ' has-error' : ''}`}>
        {areAllLanguagesAllowed ? (
          <>
            <p className={isDescriptionCollapsed ? 'no-bottom-margin' : ''}>
              <Button
                className='show-hide-languages-btn'
                link
                onClick={() => setisDescriptionCollapsed(!isDescriptionCollapsed)}>
                {isDescriptionCollapsed ? 'View languages' : 'Hide languages'}
              </Button>
              <strong>{`All ${locales.length} `}</strong>
              languages allowed
            </p>
            {!isDescriptionCollapsed && (
              <>
                <p>{locales.map(locale => locale.text).join(', ')}</p>
                <em>Default language is not required if all languages are allowed</em>
              </>
            )}
          </>
        ) : (
          <>
            {allowedLanguagesText}
            {tags.length > 0 && <LanguageTags disabled={disabled} toggleLanguage={toggleLanguage} tags={tags} />}
            <div className='flexed baseline-aligned start-justified'>
              <p className='label-medium'>Default Language</p>
              <Dropdown
                id='default-language-dropdown'
                hasError={hasError}
                disabled={disabled}
                selected={enforced_language.default_language || PLACEHOLDER.key}
                options={[PLACEHOLDER, ...tags]}
                onSelect={newLang => {
                  updateConfig({
                    enforced_language: {
                      default_language: newLang.key === PLACEHOLDER.key ? '' : newLang.key,
                      languages: [...tags],
                    },
                  })
                }}
              />
            </div>
          </>
        )}
      </div>
      {hasError && <p className='error-text'>Add language(s) or "Allow all languages"</p>}
    </div>
  )
}

const localeType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
  }),
)

LanguageTags.propTypes = {
  disabled: PropTypes.bool,
  tags: localeType,
  toggleLanguage: PropTypes.func,
}

EnforcedLanguagesConfig.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  locales: localeType,
  resetEnforcedLanguage: PropTypes.func,
  study: PropTypes.object,
  toggleLanguage: PropTypes.func,
  updateConfig: PropTypes.func,
}

export default EnforcedLanguagesConfig
