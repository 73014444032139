import { connect } from 'react-redux'
import React from 'react'
import Loader from 'components/D3Loader'
import ConsentPreviewPage from '../components/ConsentPreviewPage'

const mapStateToProps = ({ loading, newConsentReducer }) => {
  return {
    loading: loading.general,
    ...newConsentReducer,
  }
}

export default connect(
  mapStateToProps,
  null,
)(props => {
  return props.loading ? <Loader /> : <ConsentPreviewPage {...props} />
})
