import { UserIsAuthenticated, UserHasValidPassword } from 'containers/authContainers'
import { setPageTitle } from 'utils/misc'
import PermissionDeniedPage from './components/PermissionDenied'

// Sync route definition
export default () => ({
  path: 'unauthorized',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        setPageTitle(`Unauthorized | Admin Panel`)
        cb(null, UserIsAuthenticated(UserHasValidPassword(PermissionDeniedPage)))
      },
      'unauthorized',
    )
  },
})
