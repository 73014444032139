import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Divider } from 'components/UIElements'
import {
  QUESTION_TYPE_MAP,
  QUESTION_TYPES_WITH_CHOICES,
  QUESTION_TYPES_WITH_SCORING,
  QUESTION_TYPES_WITH_OPT_OUT,
  SELECT_QUESTION_TYPES,
  QUESTION_ATTRIBUTE_MAP,
  MAX_PHOTO_LIMIT_FIELD_DEFAULT_VALUE,
} from 'utils/constants'
import STRINGS from 'utils/strings'
import { scrollTo } from 'utils/misc'
import AttributePanel from './AttributePanel'
import ScoringPanel from '../Scoring/ScoringPanel'
import QuestionPanel from '../QuestionPanel'
import ValidationPanel from '../ValidationPanel'
import LogicPanelContainer from '../../containers/LogicPanelContainer'
import { itemEditorActions } from '../../modules/Survey'
import InReviewPanel from './InReviewPanel'
import MaxPhotoLimitPanel from './MaxPhotoLimitPanel'

const introPic = require('../../assets/intro_with_greeting.png')
const introNoGreetingPic = require('../../assets/intro_no_greeting.png')
const timerPic = require('../../assets/survey-timer.png')

class ItemEditor extends React.Component {
  componentDidUpdate(prevProps) {
    const { activeItemId, toggleItemAttribute, survey } = this.props
    const item = survey.questions[activeItemId]
    const { attributes } = item

    if (activeItemId !== prevProps.activeItemId) {
      this.onScrollToItem({})

      if (
        attributes[QUESTION_ATTRIBUTE_MAP.inReview] === undefined ||
        attributes[QUESTION_ATTRIBUTE_MAP.inReview] === null
      ) {
        toggleItemAttribute({ attribute: QUESTION_ATTRIBUTE_MAP.inReview, itemId: activeItemId })
      }
    }
  }

  onChangeItemType = item => {
    const { survey, activeItemId, changeItemType } = this.props
    changeItemType(survey, activeItemId, survey.questions[activeItemId].type, item.value)
  }

  onUpdateItemInputWidth = item => {
    const { updateItem, survey, activeItemId } = this.props
    const { questions } = survey
    const question = questions[activeItemId]
    const newItem = { ...question, attributes: { ...question.attributes, inputWidth: item.value } }
    updateItem(activeItemId, newItem)
  }

  onDeleteChoice = choiceId => {
    const { activeItemId, deleteChoice, survey } = this.props
    return () => {
      deleteChoice(survey, activeItemId, choiceId)
    }
  }

  onScrollToItem = ({ isIntro }) => {
    const { activeItemId } = this.props
    const element = document.getElementById(`question-${activeItemId}`)
    let offset = 120
    if (isIntro) offset += 16
    if (element) {
      scrollTo({ element, offset })
    }
  }

  onAddChoice = () => {
    const { activeItemId, survey, addChoice } = this.props
    const { questions } = survey
    const { type } = questions[activeItemId]
    addChoice(activeItemId, questions[activeItemId], null, type)
  }

  onUpdateUnitType = e => {
    const { activeItemId, updateUnitType } = this.props
    updateUnitType(activeItemId, e.target.value)
  }

  onAddOtherChoice = e => {
    const { activeItemId, addOtherChoice } = this.props
    addOtherChoice(activeItemId)
  }

  onMoveItem = direction => {
    const { activeItemId, moveItem, survey } = this.props
    return () => {
      const startIdx = survey.order[0].indexOf(activeItemId)
      let endIdx
      if (direction === 'up') {
        endIdx = startIdx - 1
      } else {
        endIdx = startIdx + 1
      }
      moveItem(survey, startIdx, endIdx)
    }
  }

  onDuplicateItem = () => {
    const { activeItemId, duplicateItem, editorStates, question, surveyItemLabelEditorState } = this.props
    if (QUESTION_TYPES_WITH_CHOICES.includes(question.type)) {
      const choicesEditorStates = {}
      const { choices_order } = question
      const choiceQProps = { choicesOrder: choices_order }
      if (SELECT_QUESTION_TYPES.includes(question.type)) {
        choices_order.forEach(choiceId => {
          choicesEditorStates[choiceId] = editorStates[choiceId]
        })
        choiceQProps.choicesEditorStates = choicesEditorStates
      }
      duplicateItem(activeItemId, surveyItemLabelEditorState, choiceQProps)
    } else {
      duplicateItem(activeItemId, surveyItemLabelEditorState)
    }
  }

  onToggleRequiredQuestion = () => {
    const { toggleRequiredQuestion, survey, activeItemId } = this.props
    toggleRequiredQuestion(survey, activeItemId)
  }

  onToggleInputValidation = () => {
    const { toggleInputValidation, activeItemId } = this.props
    toggleInputValidation(activeItemId)
  }

  onToggleScoring = () => {
    const { survey, toggleQuestionScoring, activeItemId } = this.props
    const item = survey.questions[activeItemId]
    const { domains = [] } = item
    toggleQuestionScoring(activeItemId, domains)
  }

  onToggleOptOut = () => {
    const { survey, toggleQuestionOptOut, activeItemId } = this.props
    const item = survey.questions[activeItemId]
    const { domains = [] } = item
    toggleQuestionOptOut(activeItemId, domains)
  }

  onUpdateItemFormula = formula => {
    const { activeItemId, updateItemFormula } = this.props
    updateItemFormula(activeItemId, formula)
  }

  onCreateNewDomain = label => {
    const { activeItemId, createNewDomainAndAddToQuestion } = this.props
    createNewDomainAndAddToQuestion(activeItemId, label)
  }

  onToggleDomain = domainId => {
    const { activeItemId, toggleQuestionDomain } = this.props
    toggleQuestionDomain(activeItemId, domainId)
  }

  render() {
    const { activeItemId, onDeleteItem, survey, toggleItemAttribute, setItemAttribute, study, userId } = this.props
    const item = survey.questions[activeItemId]
    const { attributes, formula } = item
    const { hasScoring, hasOptOut, max_photo_limit: maxPhotoLimit } = attributes
    const { domains } = survey
    const questionDomains = item.domains
    const qNumber = item.attributes.questionNumber
    const isIntro = item.type === QUESTION_TYPE_MAP.introduction
    const isMultiField = item.type === QUESTION_TYPE_MAP.multipleField
    const isSelectType = SELECT_QUESTION_TYPES.includes(item.type)
    const isSelectMultiple = item.type === QUESTION_TYPE_MAP.selectMultiple
    const isFoodEntry = item.type === QUESTION_TYPE_MAP.foodEntry
    const isPhotoSubmission = item.type === QUESTION_TYPE_MAP.imageCapture
    const isQuestion = ![QUESTION_TYPE_MAP.introduction, QUESTION_TYPE_MAP.prompt].includes(item.type)
    const canHaveScoring = QUESTION_TYPES_WITH_SCORING.includes(item.type)
    const canHaveOptOut = QUESTION_TYPES_WITH_OPT_OUT.includes(item.type)
    const itemText = qNumber ? `Q${qNumber}` : isIntro ? 'Introduction Text' : 'Text'
    const disabledRequired = [
      QUESTION_TYPE_MAP.introduction,
      QUESTION_TYPE_MAP.audioRecording,
      QUESTION_TYPE_MAP.prompt,
    ].includes(item.type)
    const maxPhotoLimitPanelShown = isFoodEntry || isPhotoSubmission

    return (
      <div className='item-editor' onClick={() => this.onScrollToItem({ isIntro })}>
        <div className='editor-toolbar flexed'>
          <div className='flexed align-center'>
            <div className='question-number'>
              <h4>{itemText}</h4>
            </div>
            {!isIntro && (
              <>
                <Divider height='15px' width='1px' color='#cacaca' />
                <ValidationPanel
                  hasCharLimit={item.attributes.hasCharLimit}
                  disabledRequired={disabledRequired}
                  isMultiField={isMultiField}
                  onToggleInputValidation={this.onToggleInputValidation}
                  onToggleRequiredQuestion={this.onToggleRequiredQuestion}
                  required={item.attributes.required}
                />
                <div className='toolbar-buttons button-list'>
                  <Button
                    id='clone-question'
                    className='far fa-clone hoverable-1'
                    onClick={this.onDuplicateItem}
                    noStyling
                  />
                  <Button
                    id='trash-icon'
                    className='fas fa-trash-alt hoverable-red'
                    onClick={onDeleteItem(activeItemId)}
                    noStyling
                  />
                  <i
                    className={`clickable fas fa-arrow-up${
                      survey.order[0][1] === activeItemId || survey.order[0][0] === activeItemId ? ' disabled' : ''
                    }`}
                    onClick={this.onMoveItem('up')}
                  />
                  <i
                    className={`clickable fas fa-arrow-down${
                      survey.order[0][survey.order[0].length - 1] === activeItemId || item.type === 'introduction'
                        ? ' disabled'
                        : ''
                    }`}
                    onClick={this.onMoveItem('down')}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {isIntro && (
          <div>
            <div className='panel'>{STRINGS.surveyIntroDescription}</div>
            <img
              src={!attributes[QUESTION_ATTRIBUTE_MAP.disableGreeting] ? introPic : introNoGreetingPic}
              alt='intro-sample'
            />
            <AttributePanel
              label={STRINGS.surveyIntroDisplayGreeting}
              checked={!attributes[QUESTION_ATTRIBUTE_MAP.disableGreeting]}
              onToggle={() => {
                toggleItemAttribute({ attribute: QUESTION_ATTRIBUTE_MAP.disableGreeting, itemId: activeItemId })
              }}
            />
          </div>
        )}
        {isQuestion && (
          <>
            <QuestionPanel
              item={item}
              onAddOtherChoice={this.onAddOtherChoice}
              onChangeItemType={this.onChangeItemType}
              onDeleteChoice={this.onDeleteChoice}
              onAddChoice={this.onAddChoice}
              onUpdateUnitType={this.onUpdateUnitType}
              onUpdateItemInputWidth={this.onUpdateItemInputWidth}
              study={study}
              userId={userId}
              {...this.props}
            />
            {isSelectType && (
              <AttributePanel
                label={STRINGS.surveySelectDisplayCheckbox}
                checked={!attributes[QUESTION_ATTRIBUTE_MAP.disableButtons]}
                onToggle={() => {
                  toggleItemAttribute({ attribute: QUESTION_ATTRIBUTE_MAP.disableButtons, itemId: activeItemId })
                }}
              />
            )}
            {canHaveOptOut && (
              <AttributePanel
                hasOptOut={hasOptOut}
                heading={STRINGS.optOut}
                label={STRINGS.optOutDescription}
                checked={attributes[QUESTION_ATTRIBUTE_MAP.hasOptOut]}
                onToggle={() => {
                  toggleItemAttribute({ attribute: QUESTION_ATTRIBUTE_MAP.hasOptOut, itemId: activeItemId })
                }}
              />
            )}
            {canHaveScoring && (
              <ScoringPanel
                createNewDomain={this.onCreateNewDomain}
                domains={domains}
                hasCalcDropdown={isSelectMultiple}
                hasScoring={hasScoring}
                onToggleDomain={this.onToggleDomain}
                onToggleScoring={this.onToggleScoring}
                onUpdateItemFormula={this.onUpdateItemFormula}
                questionDomains={questionDomains}
                formula={formula}
              />
            )}
          </>
        )}
        {item.type === QUESTION_TYPE_MAP.timer && (
          <div>
            <img src={timerPic} alt='timer-sample' />
          </div>
        )}
        {item.attributes.canHaveLogic && <LogicPanelContainer itemId={activeItemId} />}
        {!isIntro ? (
          <InReviewPanel
            inReviewChecked={attributes[QUESTION_ATTRIBUTE_MAP.inReview]}
            onToggleInReviewChecked={() => {
              toggleItemAttribute({ attribute: QUESTION_ATTRIBUTE_MAP.inReview, itemId: activeItemId })
            }}
          />
        ) : null}
        {maxPhotoLimitPanelShown ? (
          <MaxPhotoLimitPanel
            study={study}
            userId={userId}
            maxPhotoLimit={maxPhotoLimit}
            onPlusClick={() =>
              setItemAttribute({
                attribute: QUESTION_ATTRIBUTE_MAP.maxPhotoLimit,
                itemId: activeItemId,
                value: maxPhotoLimit + 1,
              })
            }
            onMinusClick={() => {
              setItemAttribute({
                attribute: QUESTION_ATTRIBUTE_MAP.maxPhotoLimit,
                itemId: activeItemId,
                value: maxPhotoLimit - 1,
              })
            }}
            onSetDefaultValue={() => {
              setItemAttribute({
                attribute: QUESTION_ATTRIBUTE_MAP.maxPhotoLimit,
                itemId: activeItemId,
                value: MAX_PHOTO_LIMIT_FIELD_DEFAULT_VALUE,
              })
            }}
          />
        ) : null}
      </div>
    )
  }
}

ItemEditor.propTypes = {
  activeItemId: PropTypes.string,
  addChoice: PropTypes.func,
  addOtherChoice: PropTypes.func,
  changeItemType: PropTypes.func,
  createNewDomainAndAddToQuestion: PropTypes.func,
  deleteChoice: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorStates: PropTypes.objectOf(PropTypes.object),
  item: PropTypes.object,
  itemId: PropTypes.string,
  moveItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  question: PropTypes.shape({
    choices_order: PropTypes.array,
    type: PropTypes.string,
  }),
  survey: PropTypes.object,
  surveyItemLabelEditorState: PropTypes.object,
  toggleInputValidation: PropTypes.func,
  toggleItemAttribute: PropTypes.func,
  toggleQuestionDomain: PropTypes.func,
  toggleRequiredQuestion: PropTypes.func,
  toggleQuestionScoring: PropTypes.func,
  updateItem: PropTypes.func,
  updateItemFormula: PropTypes.func,
  updateUnitType: PropTypes.func,
}

export default connect(null, itemEditorActions)(ItemEditor)
