import React from 'react'
import { Filter } from 'components/UIElements'
import { _getMapState, mapActions } from 'components/Filter'
import { connect } from 'react-redux'
import { renderers, filterGetters } from './Utils'

class DateRangeFilter extends Filter {
  constructor(props) {
    super(props)
    this.defaults = { start: '', end: '', filterType: 'dateRange' }
    this.state = { ...this.defaults }
    this.key = props.dataKey
    this.header = props.header
    this.getFilter = ({ start, end }) => filterGetters.getDateRangeFilter(start, end)
    this.align = 'right'
  }

  onChange = key => {
    return date => {
      this.setState({ [key]: date, _edited: true })
    }
  }

  render() {
    return super.render(
      <div style={{ width: 400 }}>
        <p className='label-small'>Sent Between (Date Range)</p>
        {renderers.renderDateRange(this.state, this.onChange)}
      </div>,
    )
  }
}

export default config =>
  connect(
    _getMapState(config.dataKey),
    mapActions,
  )(props => {
    return <DateRangeFilter {...props} {...config} />
  })
