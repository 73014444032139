import React from 'react'
import PropTypes from 'prop-types'
import '../styles/d3/promptbar.scss'

const PromptBar = ({ status, children, className, strong = true }) => {
  const _className = `prompt-bar${className ? ` ${className}` : ''} ${status || ''} ${strong ? 'strong' : ''}`

  return <div className={_className}>{children}</div>
}

PromptBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  status: PropTypes.string,
  strong: PropTypes.bool,
}

export default PromptBar
