import React from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/consentFormatting.scss'
import { Button } from 'components/UIElements'
import HeaderFooterFields from './HeaderFooterFields'
import ConsentPdfPreview from './ConsentPdfPreview'

const headerMessage =
  'Customize the header and footer content for PDF view of your consent. This change will only affect the PDF view of this document (printed or emailed consents), and will not be visible in-app when consent is accessed through a mobile device.'
function ConsentFormattingPage(props) {
  const { consent, onSave } = props
  const { pdf_upload: pdfUpload } = consent

  return (
    <div className='consent-formatting page'>
      <h4>Consent Page Formatting (Optional)</h4>
      <p>{headerMessage}</p>
      <div className='body'>
        <HeaderFooterFields {...props} />
        <ConsentPdfPreview consentElements={consent?.consent_elements} />
      </div>
      <div className='button-list'>
        <Button
          grey
          id='save-as-draft'
          content='Save and close'
          onClick={() =>
            onSave(
              'consents',
              false,
              {
                setInProgress: true,
                isJsonSave: true,
                isUpdate: true,
                isPdfUpload: pdfUpload,
              },
              { query: '?tab=drafts' },
            )
          }
        />
        <Button
          id='save'
          content='Save and continue'
          onClick={() =>
            onSave(
              'deploy',
              false,
              {
                setReadyToBeScheduled: true,
                isJsonSave: true,
                isUpdate: true,
                isPdfUpload: pdfUpload,
              },
              { query: '?tab=drafts' },
            )
          }
        />
      </div>
    </div>
  )
}

ConsentFormattingPage.propTypes = {
  consent: PropTypes.object,
}

export default ConsentFormattingPage
