import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import '../styles/d3/cropImage.scss'

const ImageCropper = ({ img, setCroppedImage, onSubmit }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', error => reject(error))
      image.src = url
    })

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const { x, y, width, height } = pixelCrop
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) return null

    // set canvas size to match the bounding box
    canvas.width = image.width
    canvas.height = image.height

    // draw image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(x, y, width, height)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = width
    canvas.height = height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    const base64 = canvas.toDataURL('image/jpeg')
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        resolve({
          blob,
          base64,
          file: new File([blob], `${new Date().getTime()}.jpeg`, { type: 'image/jpeg' }),
        })
      }, 'image/jpeg')
    })
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(img, croppedAreaPixels)
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

  useEffect(() => {
    if (onSubmit) {
      showCroppedImage()
    }
  }, [onSubmit])

  return (
    <div className='crop-container'>
      <div className='img-cropper'>
        <Cropper
          image={img}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
        />
      </div>
      <div className='controls'>
        <input
          type='range'
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby='Zoom'
          onChange={e => {
            setZoom(e.target.value)
          }}
          className='zoom-range'
        />
        <span>x{Number.parseFloat(zoom).toFixed(1)}</span>
      </div>
    </div>
  )
}

export default ImageCropper
