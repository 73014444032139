import React from 'react'
import PropTypes from 'prop-types'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { Button } from 'components/UIElements'
import TitleInfo from './TitleInfo'
import UIProperties from './UIProperties'
import OtherProperties from './OtherProperties'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from 'utils/constants'
import '../stylesheets/properties.scss'
import ReviewScreenProperties from './ReviewScreenProperties'

const MetadataBuilderPage = props => {
  const { instrument, instrumentArchitectureVersion, studyID, userId } = props

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })

  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const { type } = instrument
  let displayType = type
  if (type === INSTRUMENT_TYPE_MAP.diary) displayType = 'diary'

  return (
    <div className='metadata page'>
      <form
        onSubmit={e => {
          e.preventDefault()
          props.onSave('schedule')
        }}>
        <TitleInfo {...props} displayType={displayType} hasInstrumentUnification={hasInstrumentUnification} />
        <UIProperties {...props} hasInstrumentUnification={hasInstrumentUnification} />
        {hasInstrumentUnification && <ReviewScreenProperties {...props} />}
        <OtherProperties {...props} displayType={displayType} hasInstrumentUnification={hasInstrumentUnification} />
        <div className='button-list'>
          <Button type='submit' content='Next' />
        </div>
      </form>
    </div>
  )
}

export default MetadataBuilderPage

MetadataBuilderPage.propTypes = {
  instrument: PropTypes.object,
  onSave: PropTypes.func,
  stepIndex: PropTypes.number,
}
