import React from 'react'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { Button } from 'components/UIElements'
import NewConsentItemContainer from '../containers/NewConsentItemContainer'

export const ButtonList = props => {
  const { onAddItem, consent } = props
  const itemIndex = consent.order[0].length - 1
  return (
    <div>
      <Button grey id='add-text' content='Text' icon='fas fa-plus' onClick={onAddItem('prompt', itemIndex)} />
      <Button
        grey
        id='add-comprehension-question '
        content='Comprehension Question'
        icon='fas fa-plus'
        onClick={onAddItem('select_one', itemIndex)}
      />
      <Button
        grey
        id='add-signature'
        content='Signature'
        icon='fas fa-plus'
        onClick={onAddItem('signature', itemIndex)}
      />
    </div>
  )
}

const ItemList = SortableContainer(props => {
  const { consent, onSave, errors, onAddItem } = props
  const order = consent.order[0]
  const { pdf_upload: pdfUpload } = consent

  return (
    <div className='item-list'>
      <p className='survey-error'>{order.length === 0 ? errors.noQuestionsErr : ''}</p>
      {order.map((itemId, index) => {
        if (index === 0) {
          return (
            <Item
              key={`consent-item-${itemId}-${index}`}
              index={index}
              itemIndex={index}
              itemId={itemId}
              hideHoverBar={index === order.length - 1}
              {...props}
            />
          )
        }
        return (
          <SortableItem
            key={`consent-item-${itemId}-${index}`}
            index={index}
            itemIndex={index}
            itemId={itemId}
            hideHoverBar={index === order.length - 1}
            {...props}
          />
        )
      })}
      <div className='survey-buttons flexed'>
        <ButtonList onAddItem={onAddItem} consent={consent} />
      </div>
      <div className='button-list'>
        <Button
          grey
          id='save-as-draft'
          content='Save and close'
          onClick={() =>
            onSave(
              'consents',
              false,
              { setInProgress: true, isJsonSave: true, isUpdate: true, isPdfUpload: pdfUpload, isEditConsent: true },
              { query: '?tab=drafts' },
            )
          }
        />
        <Button
          id='save'
          content='Save and continue'
          onClick={() =>
            onSave('formatting', false, {
              isJsonSave: true,
              isUpdate: true,
              isPdfUpload: pdfUpload,
              isEditConsent: true,
            })
          }
        />
      </div>
    </div>
  )
})

const SortableItem = SortableElement(props => {
  return <Item {...props} />
})

const Item = props => {
  return <NewConsentItemContainer {...props} />
}

export default ItemList
