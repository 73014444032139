import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button, Popup, SortableTable } from 'components/UIElements'
import { MODAL_CONTENT_MAP, MODAL_CLASSES_MAP, STATUS_OPTIONS } from 'utils/constants'
import STRINGS from 'utils/strings'
import { ChevronCell } from './VirtualVisitDataCells'
import '../stylesheets/VisitTable.scss'
import '../stylesheets/VisitSchedules.scss'
import UpdateStatusSection from './UpdateStatusSection'

const headerOptions = [
  { key: 'participantName', sortable: true, component: 'Participant Name' },
  { key: 'ptpId', sortable: true, component: 'Participant ID' },
  { key: 'subjectId', sortable: true, component: 'Subject ID' },
  { key: 'visitName', sortable: true, component: 'Visit Name' },
  { key: 'visitDay', sortable: true, component: 'Study Day' },
  { key: 'date', sortable: true, component: 'Scheduled Date', className: 'date' },
  { key: 'actualDate', sortable: true, component: 'Actual Date', className: 'date' },
  { key: 'status', sortable: true, component: 'Status', className: 'date' },
  { key: 'action', component: 'Action', className: 'visits-template-actions' },
]

const VisitSchedulesPreviousTable = props => {
  const {
    disabled,
    offset,
    resetSchedulePreviousExpandedVisits,
    searchTerm,
    setSchedulePreviousCount,
    studyID,
    setDataBoxCount,
    selectedDataBox,
    hasPiiRole,
    studyLock,
    visitSchedulesPreviousList = [],
    getVisitByPeriod,
    selectedVisitsPeriod,
    openModal,
    updatePreviousScheduleVisit,
    closeModal,
  } = props
  const [summaryWrapperStyle, setSummaryWrapperStyle] = useState({})
  const [_hasScrollBar, setHasScrollBar] = useState(false)
  const vvHistoryTableRef = useRef(null)

  useEffect(() => {
    resetSchedulePreviousExpandedVisits()
  }, [])

  useEffect(() => {
    let list = []
    const currentList = vvHistoryTableRef?.current?.getList()
    if (currentList) {
      list = getVisitByPeriod(currentList, selectedVisitsPeriod)
    } else {
      list = visitSchedulesPreviousList
    }
    const counts = list.reduce((acc, item) => {
      const status = item.filter(rowData => rowData.key === 'status').map(obj => obj.value)?.[0]
      if (status) {
        if (!acc[status]) acc[status] = 1
        else acc[status] += 1
      }
      return acc
    }, {})
    setSchedulePreviousCount(list.length)
    setDataBoxCount(counts)
  }, [searchTerm, visitSchedulesPreviousList])

  const handleResize = (isOnClick = false) => {
    // Need to check if argument is a boolean because on resize, the argument is an Event
    const _isOnClick = typeof isOnClick === 'boolean' && isOnClick
    setTimeout(
      () => {
        const tableRef = vvHistoryTableRef.current?.tableRef
        if (tableRef) {
          const summaryWrapperElements = tableRef?.getElementsByClassName('summary-wrapper')
          const _tableWidth = tableRef?.clientWidth
          const _chevronCellWidth = tableRef?.querySelector('thead tr th:first-child ').clientWidth
          for (let i = 0; i < summaryWrapperElements.length; i++) {
            summaryWrapperElements[i].style.width = `${_tableWidth - _chevronCellWidth - 3}px`
          }
          setSummaryWrapperStyle({
            width: `${_tableWidth - _chevronCellWidth - 3}px`,
          })
        }
      },
      _isOnClick ? 100 : 0,
    )
  }

  // This effect is to determine on initial mount and resize, the width of the expanded summary content dynamically
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    handleResize()
  }, [_hasScrollBar])

  const renderActionsCell = cellProps => {
    if (studyLock) return null
    const { idx, row, className, allowStatusUpdate } = cellProps
    const ptpID = row.filter(rowData => rowData.key === 'ptpId').map(ptpIDObj => ptpIDObj.value)
    const status = row.filter(rowData => rowData.key === 'status').map(ptpIDObj => ptpIDObj.value)?.[0]

    const isConfirmed = status === STATUS_OPTIONS.Confirmed
    const actionable = !isConfirmed || allowStatusUpdate

    return (
      <td key={`col_${idx}`} className={className}>
        <div className='flexed center-justified'>
          {actionable ? (
            <Popup
              dark
              noPointer={false}
              position='bottom'
              className='edit'
              disabled={disabled}
              trigger={<Button link icon='fas fa-pencil-alt' />}>
              <>
                {!isConfirmed ? (
                  <Button
                    content='Reschedule'
                    onClick={() => {
                      browserHistory.push(
                        `/studies/${studyID}/visit-schedules/participant-visit/${ptpID}-${row.id}/edit`,
                      )
                    }}
                  />
                ) : null}
                {allowStatusUpdate ? (
                  <Button
                    content={STRINGS.visitScheduleUpdateStatus}
                    onClick={() => {
                      openModal({
                        closeOnBackgroundClick: true,
                        heading: MODAL_CONTENT_MAP.didVisitHappen,
                        content: (
                          <UpdateStatusSection
                            status={status}
                            updatePreviousScheduleVisit={updatePreviousScheduleVisit}
                            onClose={closeModal}
                            studyID={studyID}
                            ssvId={row.id}
                            ptpID={ptpID}
                          />
                        ),
                        className: MODAL_CLASSES_MAP.confirmation,
                        noButtons: true,
                      })
                    }}
                  />
                ) : null}
              </>
            </Popup>
          ) : null}
        </div>
      </td>
    )
  }

  const renderParticipantIdCell = ({ idx, value, className }) => {
    if (!hasPiiRole) return null
    return (
      <td key={`col_${idx}`} className={className}>
        {value}
      </td>
    )
  }

  const renderStatusCell = ({ idx, value, className }) => {
    return (
      <td key={`col_${idx}`} className={className}>
        {value !== 'confirm-occurence' ? (
          <div className={`visit-status-cell ${value === STATUS_OPTIONS.Confirmed ? 'confirmed' : ''}`}>{value}</div>
        ) : null}
      </td>
    )
  }

  //
  // Table Data Render Functions
  //
  const renderFunctions = {
    action: _props => renderActionsCell(_props),
    ptpId: _props => renderParticipantIdCell(_props),
    status: _props => renderStatusCell(_props),
  }

  // headerOptions[0].component = <i className='fas fa-chevron-right' />

  return (
    <SortableTable
      ref={vvHistoryTableRef}
      className='visits-table'
      headerOptions={headerOptions.filter(item => {
        if (studyLock && item.key === 'action') return false
        if (item.key !== 'ptpId' || hasPiiRole) return true
        return false
      })}
      offset={offset}
      sortingBy='date'
      initialOrder='ascending'
      renderFunctions={renderFunctions}
      // rowExpandOptions={{
      //   isRowExpanded: row => {
      //     const idData = row.find(el => el.key === 'checkbox')
      //     const visitId = idData.value
      //     return expandedSchedulePreviousVisits[visitId]
      //   },
      //   expandedRowClassName: 'expanded',
      // }}
      filters={{
        status: col => {
          if (!selectedDataBox) return true
          return col.value === selectedDataBox
        },
      }}
      rowList={visitSchedulesPreviousList}
      searchKeys={['participantName', 'ptpId', 'subjectId', 'visitName', 'visitDay', 'date']}
      searchTerm={searchTerm}
      emptyText={STRINGS.visitScheduleNoResultsPrevVisits}
      viewMoreWithTablePages={{ minRows: 30, maxRows: 30 }}
    />
  )
}

ChevronCell.propType = {}

VisitSchedulesPreviousTable.propTypes = {
  disabled: PropTypes.bool,
  fetchScheduleVisitPrevious: PropTypes.func,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  offset: PropTypes.number,
  resetSchedulePreviousExpandedVisits: PropTypes.func,
  searchTerm: PropTypes.string,
  setSchedulePreviousCount: PropTypes.func,
  siteID: PropTypes.number,
  studyID: PropTypes.string,
  toggleSchedulePreviousVisitExpand: PropTypes.func,
  visitSchedules: PropTypes.shape({
    expandedSchedulePreviousVisits: PropTypes.object,
    visitSchedulesPreviousInfo: PropTypes.objectOf(PropTypes.object),
    visitSchedulesPreviousList: PropTypes.arrayOf(PropTypes.array),
  }),
  visitSchedulesPreviousList: PropTypes.arrayOf(PropTypes.array),
  getVisitByPeriod: PropTypes.func,
  selectedVisitsPeriod: PropTypes.object,
}

export default VisitSchedulesPreviousTable
