import React from 'react'
import PropTypes from 'prop-types'
import { Container, Button, Dropdown } from 'components/UIElements'
import { convertScheduledTimeToString, TIME_DROPDOWN_OPTIONS } from '../utils/conditionalNotificationUtils'

const medOptions = [
  { key: 'yes', text: 'Days that medication is taken', value: 'and' },
  { key: 'no', text: 'Days that medication isn’t taken', value: 'days that medication isn’t taken' },
  { key: 'custom', text: 'Custom...', value: 'Custom...' },
]

const ScheduleSection = ({ scheduleProps, updateScheduleTime }) => {
  return (
    <Container className='schedule section'>
      <div>
        <h5>Schedule</h5>
        <p>
          Notifications are scheduled to send based on this study’s participant input frequency configuration, which is
          2 times a day on the blue days shown below.
        </p>
        <div className='disabled-everyday'>
          <Button id='everyday-schedule' className='noti-schedule-button' disabled content='Everyday' onClick={() => {}} />
          <i className='fas fa-check' />
        </div>
        <div className='flexed schedule-options'>
          <div>
            <p className='label-small'>Initiate notification on these days</p>
            <Dropdown id='initiate-notification-days' hideCaret options={medOptions} disabled selected='yes' />
          </div>
          <div>
            <p className='label-small'>At this time</p>
            <Dropdown
              id='notification-time-dropdown'
              onSelect={item => updateScheduleTime(item.value)}
              options={TIME_DROPDOWN_OPTIONS}
              selected={convertScheduledTimeToString(scheduleProps)}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}
ScheduleSection.propTypes = {
  scheduleProps: PropTypes.shape({}),
  updateScheduleTime: PropTypes.func,
}

export default ScheduleSection
