import { combineReducers } from 'redux'
import request from 'utils/request'
import { AVAILABLE_INSIGHTS_ROLE_OPTIONS } from 'utils/constants'
import { fetchStudy } from '../../../../../../CreateStudy/modules/CreateStudy'

// ACTION CONSTANTS
const SET_INSIGHTS_PERMISSIONS = 'SET_INSIGHTS_PERMISSIONS'

// UTIL FUNCTIONS
export const _getDefaultInsightsPermissions = () => ({
  permissions: [],
  studyLevelRoleAccess: {},
})

// API CALLS
const saveInsightsPermissions = (studyID, dashboards, selectedPermissions = {}, selectedType, callback = null) => (
  dispatch,
  getState,
) => {
  const { study: { currentStudy } = {} } = getState()
  const insighthsButtons = currentStudy.config?.insights?.custom_buttons

  const selectedGlobalRoles = AVAILABLE_INSIGHTS_ROLE_OPTIONS.reduce((acc, { key }) => {
    if (!selectedPermissions[key]) acc[key] = true
    return acc
  }, {})

  const selectedRoles = Object.keys(dashboards).reduce((acc, dashboard) => {
    acc.push({
      ...dashboards[dashboard],
      hidden_role_ids:
        selectedType === 'all'
          ? { ...selectedGlobalRoles }
          : {
              ...selectedGlobalRoles,
              ...dashboards[dashboard].hidden_role_ids,
            },
    })
    return acc
  }, [])

  const deselectedRoles = Object.keys(insighthsButtons).reduce((acc, dashboardName) => {
    if (!dashboards[dashboardName])
      acc.push({
        dashboard_name: dashboardName,
        hidden_role_ids: {
          ...selectedGlobalRoles,
        },
        metadata: { group_id: insighthsButtons[dashboardName].group_id || '' },
      })
    return acc
  }, [])

  const studyLevelRoleAccess = Object.keys(selectedPermissions).reduce((acc, key) => {
    if (selectedPermissions[key]) acc[key] = selectedPermissions[key]
    return acc
  }, {})

  const body = {
    permissions: [...deselectedRoles, ...selectedRoles],
    study_level_role_access: studyLevelRoleAccess,
  }

  const url = `/control/insights/studies/${studyID}/permissions`
  return dispatch(
    request({
      method: 'PATCH',
      url,
      fail: () => {
        if (callback) callback()
      },
      success: () => {
        return dispatch(fetchStudy(studyID)).finally(() => {
          if (callback) callback()
        })
      },
      hasLoader: true,
      forceLoader: true,
      body: JSON.stringify(body),
    }),
  )
}

// REDUCERS
const insightsPermissions = (state = _getDefaultInsightsPermissions(), action) => {
  switch (action.type) {
    case SET_INSIGHTS_PERMISSIONS:
      return action.payload

    default:
      return state
  }
}

export const insightsPermissionsActions = {
  saveInsightsPermissions,
}

export default combineReducers({ insightsPermissions })
