import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Radio } from 'components/UIElements'
import '../styles/d3/richbutton.scss'
import { formatStringForID } from 'utils/misc'

const RichButton = props => {
  const {
    additionalLabel,
    className,
    disabled,
    graphicElement,
    hasError,
    isRadio,
    isCheckbox,
    label,
    labelOnTop = false,
    onClick,
    selected,
    stopPropagation, 
  } = props
  const _className = `rich-button flexed column start-justified clickable ${disabled ? ' disabled' : ''}${
    hasError ? ' has-error' : ''
  }`
  const _onClick = e => {
    if (stopPropagation) e.stopPropagation()
    onClick()
  }
  return (
    <div className='rich-button-container'>
      <button
        type='button'
        id = {formatStringForID(label)}
        onClick={disabled || !onClick ? null : _onClick}
        className={`${_className}${selected ? ' selected' : ''}${className ? ` ${className}` : ''}`}>
        {labelOnTop && <span className='label top'>{label}</span>}
        {graphicElement}
        {!labelOnTop && <span className='label'>{label}</span>}
        {selected && !isRadio && <i className='fas fa-check' />}
        {isRadio && <Radio justCircle selected={selected} />}
        {isCheckbox && <Checkbox selected={selected} />}
      </button>
      {additionalLabel && <div className='additional-label'>{additionalLabel}</div>}
    </div>
  )
}

RichButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  graphicElement: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  labelOnTop: PropTypes.bool,
  onClick: PropTypes.func,
  hasError: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  isRadio: PropTypes.bool,
  stopPropagation: PropTypes.func,
  selected: PropTypes.bool,
  additionalLabel: PropTypes.string,
}

export default RichButton
