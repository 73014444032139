import React from 'react'
import 'styles/d3/sortabletable.scss'
import { getRegexFromTerm } from 'utils/search'
import { deepCompareObj } from 'utils/object'
import { Button, Checkbox, Loader, Popup } from 'components/UIElements'
import PropTypes from 'prop-types'
import Pagination from 'rc-pagination'
import paginationLocale from 'rc-pagination/lib/locale/en_US'

const classNames = require('classnames')

/*
SORTABLE TABLE HOC

rowList is a list of arrays, each array in the list contains objects that represent a cell in the table.
Cells must have a key which refers to the column key, a  value which is the value of the cell, and a sortableValue
which is used by the sorting functions.

To custom render a cell, pass in a renderFunction object with keys representing the  column key, with values being
react functions that return a component (these functions will be passed the individual cell objects for rendering)

rowList example format:

The below rowList will be rendered into a 3 x 3 table (excluding headerand footer)

rowList = [
  // row 1
  [
    {key: 'col_1_key', value: 'col_1_value', sortValue: 'col_1_sortableValue'}
    {key: 'col_2_key', value: 'col_2_value', sortValue: 'col_2_sortableValue'}
    {key: 'col_3_key', value: 'col_3_value', sortValue: 'col_3_sortableValue'}
  ],
  // row 2
  [
    {key: 'col_1_key', value: 'col_1_value', sortValue: 'col_1_sortableValue'}
    {key: 'col_2_key', value: 'col_2_value', sortValue: 'col_2_sortableValue'}
    {key: 'col_3_key', value: 'col_3_value', sortValue: 'col_3_sortableValue'}
  ],
]

headerOptions = [
  { key: 'keyName', sortable: boolean, component: <ReactComponent /> }
]

*/

// TODO: Need to adding in un-checking of rows when rows are filtered by filter or search terms

class SortableTable extends React.Component {
  constructor(props) {
    super(props)
    this.renderFunctions = props.renderFunctions || {}
    const { viewMoreWithTablePages } = this.props
    this.state = {
      sortingBy: this.props.sortingBy,
      ascending: this.props.initialOrder ? this.props.initialOrder === 'ascending' : true,
      keyIndexMap: this._getKeyIndexMap(),
      viewMore: this.props.viewMoreButton ? this.props.viewMoreLine : undefined,
      expand: viewMoreWithTablePages && viewMoreWithTablePages?.minRows === viewMoreWithTablePages?.maxRows,
      currentPage: viewMoreWithTablePages
        ? { pageNum: 1, list: viewMoreWithTablePages.maxRows }
        : { pageNum: 1, list: 10 },
      minRows: viewMoreWithTablePages ? viewMoreWithTablePages.minRows : 5,
      maxRows: viewMoreWithTablePages ? viewMoreWithTablePages.maxRows : 10,
      collapsedList: {},
    }
  }

  componentDidUpdate = prevProps => {
    const { searchTerm, filters, rowList } = this.props
    const { maxRows } = this.state
    if (prevProps.searchTerm !== searchTerm) {
      this.onUncheckAll()
      this.setCurrentPage({ pageNum: 1, list: this.pageSize })
    }
    if (!deepCompareObj(prevProps.filters, filters)) {
      this.setCurrentPage({ pageNum: 1, list: maxRows })
    }
    if (prevProps.rowList?.length !== rowList?.length) this.setState({ keyIndexMap: this._getKeyIndexMap() })
  }

  onCheck = row => {
    return e => {
      const { checked, onUpdateChecked } = this.props
      e.stopPropagation()
      const newChecked = { ...checked }
      if (newChecked.hasOwnProperty(row.id)) {
        delete newChecked[row.id]
      } else {
        newChecked[row.id] = row.payload
      }
      if (typeof onUpdateChecked === 'function') onUpdateChecked(newChecked)
    }
  }

  onCheckAll = () => {
    const { checked, onUpdateChecked } = this.props
    const checkedRows = {}
    if (Object.keys(checked).length === 0) {
      this.currentList.forEach(row => {
        checkedRows[row.id] = row.payload
      })
    }
    if (typeof onUpdateChecked === 'function') onUpdateChecked(checkedRows)
  }

  onUncheckAll = () => {
    const { onUpdateChecked } = this.props
    if (typeof onUpdateChecked === 'function') onUpdateChecked({})
  }

  handleSort = key => {
    return () => {
      const { ascending, sortingBy } = this.state
      this.setState({
        sortingBy: key,
        ascending: key === sortingBy ? !ascending : true,
      })
    }
  }

  handleRowClick = (rowData, isDisabled) => {
    if (isDisabled) return null
    const { onRowClick } = this.props
    return () => {
      if (onRowClick) {
        onRowClick(rowData)
      }
    }
  }

  getList = () => {
    const { rowList } = this.props
    const searchedList = this.getSearchedList(this.getSortedList(this.getFilteredList(rowList)))
    return searchedList
  }

  getSortedList = list => {
    const { keyIndexMap, sortingBy } = this.state
    const idx = keyIndexMap[sortingBy] || 0
    return list.sort(this._alphanumericSort(idx))
  }

  getSearchedList = list => {
    const { searchTerm, searchKeys } = this.props
    const { keyIndexMap } = this.state

    if (!searchTerm || !searchKeys) return list
    const regex = getRegexFromTerm(searchTerm)
    return list.filter(row => {
      for (let i = 0; i < searchKeys.length; i++) {
        const key = searchKeys[i]
        const value = `${row[keyIndexMap[key]].value}`
        if (value.match(regex)) {
          return true
        }
      }
      return false
    })
  }

  getFilteredList = list => {
    const { filters } = this.props
    const { keyIndexMap } = this.state

    const filterKeys = Object.keys(filters || {})
    if (filterKeys.length > 0) {
      return list.filter(row => {
        for (const key in filters) {
          const colIdx = keyIndexMap[key]
          const filter = filters[key]
          if (!filter(row[colIdx])) return false
        }
        return true
      })
    }
    return list
  }

  setCurrentPage = ({ pageNum, list }) => {
    this.setState({ currentPage: { pageNum, list } })
  }

  setTablePages = () => {
    const {
      currentPage: { pageNum = 1 },
      maxRows,
    } = this.state
    const currentList = this.getList()
    return (
      <Pagination
        pageSize={maxRows}
        total={currentList.length}
        onChange={(current, size) => {
          this.setCurrentPage({ pageNum: current, list: size * current })
        }}
        hideOnSinglePage
        locale={paginationLocale}
        current={pageNum}
        showTitle={false}
        itemRender={(_, type, element) => {
          switch (type) {
            case 'jump-prev':
              return (
                <Button
                  noStyling
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setCurrentPage({ pageNum: 1, list: maxRows })
                  }}
                />
              )
            case 'jump-next':
              return (
                <Button
                  noStyling
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    const lastPage = Math.ceil(currentList.length / maxRows)
                    this.setCurrentPage({ pageNum: lastPage, list: maxRows * lastPage })
                  }}
                />
              )
            case 'prev':
              return <Button noStyling content='Previous' />
            case 'next':
              return <Button noStyling content='Next' />
            default:
              return element
          }
        }}
      />
    )
  }

  _alphanumericSort = idx => {
    const { ascending } = this.state

    return (prev, next) => {
      const _normalAlphaNumericSort = (a, b) => {
        if (typeof a === 'string') {
          return ascending
            ? a.toLowerCase().localeCompare(b.toLowerCase())
            : b.toLowerCase().localeCompare(a.toLowerCase())
        }
        return ascending ? a - b : b - a
      }

      /**
       * STRICT SORTING: when certain items need to be forced to be after other items
       * e.g., triggered instruments need to be always after triggering instruments in the instrument table.
       *
       * If a table is required to have strict sorting, in the list preparation function, parentSortValues
       * and forceOrderPrevRowId's need to be be injected into each row. See `formatInstrumentList` func in
       * `Instruments.js` for an example.
       */
      const { forceOrderPrevRowId: prevForceOrderPrevRowId, generation: prevItemGeneration } = prev
      const { forceOrderPrevRowId, generation: nextItemGeneration } = next

      const parentA = prev[idx].parentSortValue
      const parentB = next[idx].parentSortValue

      if (forceOrderPrevRowId && prevForceOrderPrevRowId && forceOrderPrevRowId !== prevForceOrderPrevRowId) {
        if (prevForceOrderPrevRowId === next.id) {
          return 1
        }
        if (prev.id === forceOrderPrevRowId) {
          return -1
        }
        /**
         * This logic is meant to sort rows with different forceOrderPrevRowIds's, i.e., rows that both have
         * specific rows they NEED to come after but the respective rows they need be after are different. We fallback
         * to alphanumeric sort of their respective parent rows' sort values.
         *  */
        return _normalAlphaNumericSort(parentA, parentB) || prevItemGeneration - nextItemGeneration
      }
      if (prevForceOrderPrevRowId && !forceOrderPrevRowId) {
        if (prevForceOrderPrevRowId === next.id) {
          // If the next row is the designated row the previous row needs to be after.
          return 1
        }
        /**
         * If previous row is required to have a specific element with a specific row id (prevForceOrderPrevRowId)
         * and the next row does not, fallback to alphanumeric sort based on each row's parent row. If a row does not
         * have a parent row, they are there own parent row.
         */

        return _normalAlphaNumericSort(parentA, parentB) || 1
      }
      if (!prevForceOrderPrevRowId && forceOrderPrevRowId) {
        if (forceOrderPrevRowId === prev.id) {
          // If the previous row is the designated row the next row needs to be after.
          return -1
        }
        return _normalAlphaNumericSort(parentA, parentB) || -1
      }

      /**
       * End of strict sorting logic
       */

      const a = prev[idx].sortValue
      const b = next[idx].sortValue

      return _normalAlphaNumericSort(a, b)
    }
  }

  onToggleGroup = type => {
    if (!type) return
    this.setState(prev => {
      const collapsedList = { ...prev.collapsedList }
      if (!collapsedList[type]) collapsedList[type] = true
      else collapsedList[type] = !collapsedList[type]
      return {
        ...prev,
        collapsedList,
      }
    })
  }

  _getKeyIndexMap() {
    const { rowList } = this.props
    const map = {}
    const firstRow = rowList?.[0] || []
    firstRow.forEach((option = {}, idx) => {
      map[option.key] = idx
    })
    return map
  }

  renderSortedRows = () => {
    const {
      checked,
      disabled,
      emptyText,
      onRowClick,
      optionalCellProps,
      rowCanClick,
      rowClassName,
      rowSelectOptions,
      rowClassNameOptions,
      rowExpandOptions,
      rowHighlightOptions,
      expandedRow,
      viewMoreWithTablePages,
      deleting,
      headerOptions,
      noWrapMap = {},
      accordion: { field = '', editable } = {},
    } = this.props
    const { viewMore, expand, currentPage, minRows, maxRows, collapsedList } = this.state
    const { isRowSelected, selectedRowClassName } = rowSelectOptions || {}
    const { isRowExpanded, expandedRowClassName } = rowExpandOptions || {}
    const { hasClassName, className } = rowClassNameOptions || {}
    let currentList = this.getList()
    let accordionField = null

    if (emptyText && currentList.length === 0)
      return (
        <tr>
          <td className={!!emptyText ? 'empty-cell' : ''} colSpan={headerOptions?.length + 1 || 0}>
            {emptyText}
          </td>
        </tr>
      )

    currentList = viewMore ? this.currentList.slice(0, viewMore) : this.currentList
    if (viewMoreWithTablePages) {
      currentList = !expand
        ? this.currentList.slice(0, minRows)
        : this.currentList.slice(currentPage.list - maxRows, currentPage.list)
    }

    if (field) currentList = currentList.sort((a, b) => a[field].localeCompare(b[field]))

    return currentList.map((row, idx) => {
      let setAccordionField = false
      let hasNoData = false
      const isSelected = isRowSelected && isRowSelected(row)
      const isExpanded = isRowExpanded && isRowExpanded(row)
      const isDeleting = deleting && deleting.hasOwnProperty(row.id)
      const isDisabled = disabled || isDeleting || false
      const hasClass = hasClassName && hasClassName(row)
      const classNameOptions = {
        clickable: rowCanClick ? rowCanClick(row) : onRowClick,
        [className]: hasClass,
        [rowClassName]: rowClassName,
        [selectedRowClassName]: isSelected,
        [expandedRowClassName]: isExpanded,
        blur: isDeleting,
        checked: optionalCellProps?.checkedPtps && row[0]?.value && optionalCellProps?.checkedPtps[row[0]?.value],
      }

      // This iterates through the available highlighting conditions pass in the props under rowHighlightOptions
      if (rowHighlightOptions?.length) {
        rowHighlightOptions.forEach(rowHighlightOption => {
          const { hasHighlightClassName, highlightClassName, highlightSecondaryClassName } = rowHighlightOption
          const highlighted = hasHighlightClassName && hasHighlightClassName(row)
          classNameOptions[highlightClassName] = highlighted
          if (highlightSecondaryClassName && highlighted) {
            classNameOptions[highlightSecondaryClassName] =
              hasHighlightClassName && idx > 0 && !hasHighlightClassName(currentList[idx - 1])
          }
        })
      }
      const trClassName = classNames(classNameOptions)

      if (field && (!accordionField || accordionField !== row[field])) {
        accordionField = row[field]
        setAccordionField = true
        hasNoData = row.length === 1 && row[0].noData
      }

      const onToggleGroup = this.onToggleGroup.bind(this, accordionField)

      return (
        <React.Fragment key={row?.id ?? idx}>
          {setAccordionField && (
            <tr className='row-group' onClick={onToggleGroup}>
              <td colSpan={headerOptions?.length}>
                <div className='row-group-name flexed'>
                  <div className='flexed'>
                    <i className={`fas fa-chevron-${collapsedList[row[field]] ? 'up' : 'down'}`} />
                    <h6>{row[field]}</h6>
                  </div>
                  {editable && editable(row.parentId, { ...optionalCellProps })}
                </div>
              </td>
            </tr>
          )}
          {!collapsedList[row[field]] && [
            <tr className={trClassName} onClick={this.handleRowClick(row, isDisabled)} key={row.id ?? idx}>
              {checked && (
                <td onClick={this.onCheck(row, idx)} className='checkbox-cell'>
                  <Checkbox checked={checked.hasOwnProperty(row.id)} />
                </td>
              )}
              {!hasNoData
                ? row.map((cellProps, rowIdx) => {
                    const cellClassName = `${cellProps.className || ''}${noWrapMap[cellProps.key] ? ' no-wrap' : ''}`
                    return this.renderFunctions[cellProps.key] ? (
                      this.renderFunctions[cellProps.key](
                        { ...cellProps, ...optionalCellProps, disabled: isDisabled, isSelected, idx: rowIdx, row },
                        rowIdx,
                      )
                    ) : (
                      <td key={`col_${rowIdx}`} className={cellClassName}>
                        {cellProps.value}
                      </td>
                    )
                  })
                : row.map(cellProps => (
                    <td key='key_no_data' colSpan={headerOptions?.length}>
                      {cellProps.noData}
                    </td>
                  ))}
              {isDeleting && (
                <td className='deleting'>
                  <Loader inContainer size={20} />
                </td>
              )}
            </tr>,
            isExpanded && expandedRow && (
              <tr className='expanded-row' key={`expanded_row_${idx}`}>
                <td colSpan={row.length + (isExpanded ? 1 : 0)}>{expandedRow(row, idx)}</td>
              </tr>
            ),
          ]}
        </React.Fragment>
      )
    })
  }

  renderHeaderRow = () => {
    const { checked, disabled, headerOptions, offset } = this.props
    const { ascending, sortingBy } = this.state
    const currentList = this.getList()
    let header = []
    if (checked) {
      header.push(
        <th key='checkbox-header' onClick={this.onCheckAll} className='checkbox-cell' style={{ top: `${offset}px` }}>
          <Checkbox checked={Object.keys(checked).length === currentList.length} disabled={disabled} />
        </th>,
      )
    }
    header = header.concat(
      headerOptions.map((option, idx) => {
        let className = option.sortable ? 'clickable' : ''
        className += option.key === sortingBy ? ' active' : ''
        className += ascending ? ' asc' : ' desc'
        className += option.className ? ` ${option.className}` : ''
        className += option.key === null ? ` null` : ''
        className += option.popupText || option.popupComponent ? ` flexed-cell` : ''
        return (
          <th
            className={className}
            onClick={!disabled && option.sortable ? this.handleSort(option.key) : null}
            key={`header_${idx + 1}`}
            style={{ top: `${offset}px` }}>
            <span>{option.func ? option.component(this) : option.component}</span>
            {option.popupText || option.popupComponent ? (
              <Popup
                className='box-popup'
                align='right'
                position='bottom'
                hover
                noPointer
                dark
                trigger={option.iconClassName ? <i className={`header-icon ${option.iconClassName}`} /> : null}>
                {option.popupText ? <p>{option.popupText}</p> : null}
                {option.popupComponent ? option.popupComponent : null}
              </Popup>
            ) : null}
            {option.sortable && (
              <span className='sort-arrows'>
                <i className='fas fa-caret-up' />
                <i className='fas fa-caret-down' />
              </span>
            )}
          </th>
        )
      }),
    )
    return header
  }

  renderFooter = () => {
    const { footerOptions, renderFooter } = this.props
    if (renderFooter) return renderFooter(this.currentList)
    if (!footerOptions) return null
    return (
      <tr>
        {footerOptions.map((option, idx) => {
          return <td key={`footer_${idx}`}>{option.component}</td>
        })}
      </tr>
    )
  }

  render() {
    const { className, headerOptions, loadingBody, renderHeader, viewMoreLine, viewMoreWithTablePages, id } = this.props
    const { viewMore, expand, minRows, maxRows } = this.state
    this.currentList = this.getList()
    return (
      <div className={`d3-sortable-table ${className || ''}`}>
        {renderHeader && renderHeader(this.currentList)}
        <div className='table-container'>
          <table
            id={id}
            ref={el => {
              this.tableRef = el
            }}>
            <thead>
              <tr className='sticky'>{this.renderHeaderRow()}</tr>
            </thead>
            <tbody>
              {loadingBody ? (
                <tr>
                  <td colSpan={headerOptions?.length}>
                    <Loader inContainer size={20} />
                  </td>
                </tr>
              ) : (
                this.renderSortedRows()
              )}
            </tbody>
            <tfoot>{this.renderFooter()}</tfoot>
          </table>
        </div>
        {viewMore && this.currentList.length > viewMoreLine && (
          <div className='view-more-container'>
            <Button
              className='view-more-button'
              noStyling
              content={viewMore < this.currentList.length ? 'View more' : 'View less'}
              onClick={() => {
                const numRows = viewMore < this.currentList.length ? viewMore + viewMoreLine : viewMoreLine
                this.setState({ viewMore: numRows })
              }}
            />
          </div>
        )}
        {viewMoreWithTablePages && (
          <div className='view-more-with-table-pages'>
            {expand && (
              <div
                className={`page-numbers flexed center-justified ${
                  viewMoreWithTablePages.minRows === viewMoreWithTablePages.maxRows ? 'page-numbers-pagination' : ''
                }`}>
                {this.setTablePages()}
              </div>
            )}
            {viewMoreWithTablePages.minRows !== viewMoreWithTablePages.maxRows && this.currentList.length > minRows && (
              <div className='flexed center-justified'>
                <Button
                  className='expand-button'
                  content={expand ? 'Hide entries' : 'View more entries'}
                  onClick={() => {
                    if (!expand) this.setCurrentPage({ pageNum: 1, list: maxRows })
                    this.setState({ expand: !expand })
                  }}
                  noStyling
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

SortableTable.propTypes = {
  checked: PropTypes.object, // adds a checklist column (required for checklist)
  className: PropTypes.string, // classname for component
  disabled: PropTypes.bool,
  emptyText: PropTypes.string, // text to display if table is empty
  editable: PropTypes.node, // component for edit expandables
  filters: PropTypes.object, // filter functions for the table
  footerOptions: PropTypes.array, // options for footer
  headerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      sortable: PropTypes.bool,
      component: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
    }),
  ).isRequired, // options/key values for each column
  initialOrder: PropTypes.oneOf(['ascending', 'descending']), // initial sort ascending or descending
  loadingBody: PropTypes.bool, //loader if hiding Body while fetching data
  noWrapMap: PropTypes.objectOf(PropTypes.bool),
  offset: PropTypes.number,
  onRowClick: PropTypes.func, // callback for row click - row props passed as arguments
  onUpdateChecked: PropTypes.func, // callback for when a row is "checked"
  optionalCellProps: PropTypes.object, // props to be passed to every cell - includes result of isRowSelected if provided
  renderFooter: PropTypes.func, // render function for table footer
  renderFunctions: PropTypes.objectOf(PropTypes.func), // render function specified column types
  renderHeader: PropTypes.func, // render function for component above table
  rowCanClick: PropTypes.func, // optional callback that can be used to see if a specific row can or cannot be closed
  rowClassName: PropTypes.string, // className for each row in table
  rowList: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string]),
        sortValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
      }),
    ),
  ).isRequired, // list of row options,
  rowSelectOptions: PropTypes.shape({
    isRowSelected: PropTypes.func, // func that takes row object and returns a bool
    selectedRowClassName: PropTypes.string, // className to be added to tr in sorted rows if isRowSelected returns true
  }),
  rowExpandOptions: PropTypes.shape({
    isRowExpanded: PropTypes.func, // func that takes row object and returns a bool
    expandedRowClassName: PropTypes.string, // className to be added to tr in sorted rows if isRowSelected returns true
  }),
  rowHighlightOptions: PropTypes.arrayOf(PropTypes.object),
  rowClassNameOptions: PropTypes.shape({
    hasClassName: PropTypes.func,
    className: PropTypes.string,
  }),
  searchKeys: PropTypes.arrayOf(PropTypes.string), // keys to the column values that will be searched
  searchTerm: PropTypes.string, // search term to filter by
  sortingBy: PropTypes.string, // initial sort key
  viewMoreButton: PropTypes.bool,
  viewMoreLine: PropTypes.number,
  id: PropTypes.string,
}

export default SortableTable
