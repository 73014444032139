import React from 'react'
import { Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import { DATE_FORMAT_MAP } from 'utils/constants'

const Tooltip = ({ participant_name, participant_id, children }) => {
  return (
    <div>
      <p>{`${participant_name} #${participant_id}`}</p>
      {children}
    </div>
  )
}

export const TooltipRow = ({ text, value, divider, header }) => {
  return (
    <tr className={divider ? 'divider' : ''}>
      <td>{header ? <b>{text}</b> : text}</td>
      <td>{value}</td>
    </tr>
  )
}

const CompletedTooltip = ({ eventData, ptpData, allowInstrumentRedirect }) => {
  const { instrument_title, sent, timestamp, redirect, completion_duration } = eventData
  return (
    <Tooltip {...eventData}>
      <RedirectButton allowRedirect={allowInstrumentRedirect} redirect={redirect} content={instrument_title} />
      <table>
        <tbody>
          <TooltipRow text='Received' value={sent.format(DATE_FORMAT_MAP.mainWithDateTime)} />
          <TooltipRow text='Completed' value={timestamp.format(DATE_FORMAT_MAP.mainWithDateTime)} />
          <TooltipRow divider />
          <TooltipRow text='Time Taken To Complete' value={completion_duration} />
          <TooltipRow text="Participant's Average" value={ptpData.averageDuration || 'N/A'} />
        </tbody>
      </table>
    </Tooltip>
  )
}

const ReceivedTooltip = ({ eventData, ptpData, allowInstrumentRedirect }) => {
  const { instrument_title, redirect, timestamp, opened } = eventData
  return (
    <Tooltip {...eventData}>
      <RedirectButton allowRedirect={allowInstrumentRedirect} redirect={redirect} content={instrument_title} />
      <table>
        <tbody>
          <TooltipRow text='Received' value={timestamp.format(DATE_FORMAT_MAP.mainWithDateTime)} />
          <TooltipRow
            text='Opened'
            value={opened === undefined ? 'Not Opened' : opened.format(DATE_FORMAT_MAP.mainWithDateTime)}
          />
          <TooltipRow text="Participant's Average" value={ptpData.averageDuration} />
        </tbody>
      </table>
    </Tooltip>
  )
}

const OpenedAppTooltip = ({ eventData }) => {
  return (
    <Tooltip {...eventData}>
      <p>Opened App</p>
      <table>
        <tbody>
          <TooltipRow text='Opened' value={eventData.timestamp.format(DATE_FORMAT_MAP.mainWithDateTime)} />
        </tbody>
      </table>
    </Tooltip>
  )
}

const AnnouncementTooltip = ({ eventData, allowCommunicationRedirect }) => {
  const { announcement_title, timestamp, redirect, read } = eventData
  return (
    <Tooltip {...eventData}>
      <RedirectButton allowRedirect={allowCommunicationRedirect} redirect={redirect} content={announcement_title} />
      <table>
        <tbody>
          <TooltipRow text='Received' value={timestamp.format(DATE_FORMAT_MAP.mainWithDateTime)} />
          <TooltipRow text='Read' value={read === null ? 'Not Read' : read.format(DATE_FORMAT_MAP.mainWithDateTime)} />
        </tbody>
      </table>
    </Tooltip>
  )
}

const ExpiredTooltip = ({ eventData, allowInstrumentRedirect }) => {
  const { instrument_title, timestamp, redirect, sent } = eventData
  return (
    <Tooltip {...eventData}>
      <RedirectButton allowRedirect={allowInstrumentRedirect} redirect={redirect} content={instrument_title} />
      <table>
        <tbody>
          <TooltipRow text='Received' value={sent.format(DATE_FORMAT_MAP.mainWithDateTime)} />
          <TooltipRow text='Expired' value={timestamp.format(DATE_FORMAT_MAP.mainWithDateTime)} />
        </tbody>
      </table>
    </Tooltip>
  )
}

const PtpTooltip = ({ eventData, ptpData }) => {
  const value = ptpData.blank
    ? '0/0'
    : `${ptpData.num_received}/${ptpData.num_completed + ptpData.num_received + ptpData.num_expired}`
  return (
    <Tooltip {...eventData}>
      <table>
        <tbody>
          <TooltipRow text='Completion Rate' value={value} />
        </tbody>
      </table>
    </Tooltip>
  )
}

const RedirectButton = ({ allowRedirect, redirect, content }) => {
  return allowRedirect ? (
    <Button link content={content} onClick={() => browserHistory.push(redirect)} />
  ) : (
    <h5>{content}</h5>
  )
}

export default {
  SENT: ReceivedTooltip,
  ANNOUNCEMENT_SENT: AnnouncementTooltip,
  COMPLETED: CompletedTooltip,
  APP_OPENED: OpenedAppTooltip,
  EXPIRED: ExpiredTooltip,
  PTP_HOVER: PtpTooltip,
}
