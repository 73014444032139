import React from 'react'
import PropTypes from 'prop-types'
import { geofenceEventIcon } from 'assets/assets'
import { generateDateStringWithTimeZone, generateMultiUnitDifference } from 'utils/time'

const GeofenceEvents = ({ geofenceEvents, urgency, participantReports }) => {
  const participantReportedGeofences = []

  participantReports.forEach(report => {
    const { geofence_id } = report
    if (geofence_id) participantReportedGeofences.push(geofence_id)
  })

  const hasEvents = geofenceEvents.length > 0

  const geofenceEventList = geofenceEvents.map(event => {
    const { name, id, enter, exit } = event
    const duration = generateMultiUnitDifference(enter, exit)
    const { string, durationInHrs } = duration
    const durationAboveSix = durationInHrs > 6

    const reported = participantReportedGeofences.includes(id)

    return (
      <div className='flexed column' key={`${id}-${enter}-${exit}`}>
        <div className='geofence-name'>
          {reported && <i className={`fas fa-angle-right ${urgency}`} />}
          {name}
        </div>
        <div className='flexed'>
          <span className='label-small'>DURATION</span>
          <div>
            <span className={`${durationAboveSix ? `no-response ${urgency}` : ''}`}>{string}</span>
          </div>
        </div>
        <div className='flexed'>
          <span className='label-small'>ENTERED</span>
          <span>{generateDateStringWithTimeZone(enter)}</span>
        </div>
        <div className='flexed'>
          <span className='label-small'>EXITED</span>
          <span>{exit ? generateDateStringWithTimeZone(exit) : 'No exit recorded'}</span>
        </div>
      </div>
    )
  })

  return (
    <div className='geofence-events'>
      <div className='header'>
        {geofenceEventIcon()}
        GEOFENCE EVENTS
      </div>
      {hasEvents ? geofenceEventList : <div className='no-events'>No events recorded</div>}
    </div>
  )
}

GeofenceEvents.propTypes = {
  geofenceEvents: PropTypes.arrayOf(PropTypes.object),
  participantReports: PropTypes.arrayOf(PropTypes.object),
  urgency: PropTypes.string,
}

export default GeofenceEvents
