import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { SortableTable, RatioMeter, Popup, Button } from 'components/UIElements'
import { STUDY_CONFIG_FEATURE_KEY_MAP } from 'utils/constants'
import Loader from 'components/D3Loader'
import UploadTranslationInput from '../../../../Instruments/routes/InstrumentsPage/components/UploadTranslationInput'

// TODO: uncomment download csv translation function when backend is implemented

const HEADER_OPTIONS_FOR_CONSENTS = [
  { key: 'name', sortable: true, component: 'Name' },
  { key: 'type', sortable: true, component: 'Type' },
  { key: 'version', sortable: true, component: 'Version' },
  { key: 'sites', sortable: true, component: 'Sites' },
  { key: 'tracks', sortable: true, component: 'Tracks' },
  { key: 'consentStatus', sortable: true, component: 'Consent Status' },
  { key: 'lastEdited', sortable: true, component: 'Last Edited' },
  { key: 'timeSent', sortable: true, component: 'Time Sent' },
  { key: 'action', component: 'Action' },
]

const HEADER_OPTIONS_FOR_DRAFTS = [
  { key: 'name', sortable: true, component: 'Name' },
  { key: 'type', sortable: true, component: 'Type' },
  { key: 'version', sortable: true, component: 'Version' },
  { key: 'sites', sortable: true, component: 'Sites' },
  { key: 'tracks', sortable: true, component: 'Tracks' },
  { key: 'lastEdited', sortable: true, component: 'Last Edited' },
  { key: 'draftStatus', sortable: true, component: 'Draft Status' },
  { key: 'action', component: 'Action' },
]

class ConsentsTable extends React.Component {
  constructor(props) {
    super(props)
    this.renderFunctions = {
      name: this.renderNameCell,
      consentStatus: this.renderConsentStatusCell,
      action: this.renderActionCell,
      lastSent: this.renderLastSentCell,
      type: this.renderTypeCell,
    }
    this.filters = {
      scheduled: this.scheduledFilter,
      drafts: this.draftFilter,
    }
  }

  scheduledFilter = props => {
    return props.deployed
  }

  draftFilter = props => {
    return !props.deployed
  }

  getFilteredHeaders = () => {
    const { selectedPage, isSiteAdmin, noTracksFeature } = this.props
    let headers = selectedPage === 'scheduled' ? HEADER_OPTIONS_FOR_CONSENTS : HEADER_OPTIONS_FOR_DRAFTS
    if (noTracksFeature) {
      headers = headers.filter(header => {
        return STUDY_CONFIG_FEATURE_KEY_MAP.tracks !== header.key
      })
    }
    if (isSiteAdmin) {
      return headers.filter(header => header.key !== 'sites')
    }

    return headers
  }

  renderNameCell = (row, idx) => {
    let languages = ['EN']
    if (row.otherLanguages) languages = [...languages, ...row.otherLanguages]
    const languageTags = languages.map(language => {
      return (
        <div className='tag' key={`consent-${language}`}>
          {language.toUpperCase()}
        </div>
      )
    })
    return (
      <td className='name-cell' key={`row_${idx}`}>
        <p className='name'>{row.value}</p>
        <div className='languages flexed start-justified'>{languageTags}</div>
      </td>
    )
  }

  renderConsentStatusCell = ({ value }, idx) => {
    const { notCompleted, completed, notRespondedLabel, respondedLabel } = value
    return (
      <td key={`row_${idx}`} className='consent-status-cell'>
        <RatioMeter
          labelOne={notRespondedLabel}
          labelTwo={respondedLabel}
          valueOne={notCompleted}
          valueTwo={completed}
        />
      </td>
    )
  }

  renderLastSentCell = (row, idx) => {
    // when addendums are added we will get this data from consent and addednum (intruments)
    // api, not hard coded in with ptp reducer data
    return (
      <td className='last-sent-cell' key={`row_${idx}`}>
        <p>
          {row.value === 'N/A' ? (
            <span className='draft'>Draft</span>
          ) : (
            <span className='deployed'>
              <i className='fas fa-check' />
              Sent
            </span>
          )}
          <span>{row.value}</span>
        </p>
      </td>
    )
  }

  renderTypeCell = (row, idx) => {
    return (
      <td className='type-cell' key={`row_${idx}`}>
        {row.value}
      </td>
    )
  }

  onOpenUploadModal = () => {
    const { closeModal, openModal, params, uploadTranslation } = this.props
    const { studyID } = params
    openModal({
      noButtons: true,
      closeOnBackgroundClick: true,
      children: (
        <UploadTranslationInput
          forConsent
          closeModal={closeModal}
          studyID={studyID}
          uploadTranslation={uploadTranslation}
        />
      ),
    })
  }

  renderActionCell = (row, idx) => {
    const { canEdit, downloadConsentPreview, location, params, selectedPage, studyLock } = this.props
    const { studyID } = params
    const { consentId, readyToSchedule, version, draft } = row

    // If a deployed consent has a draft, we will navigate to the edit properties of the draft version
    const editConsentRoute = `${location.pathname}/${consentId}/${draft ? draft.version : version}/properties`
    const consentsAction = (
      <div className='flexed scheduled'>
        {canEdit && !studyLock && (
          <Button
            link
            onClick={e => {
              e.stopPropagation()
              browserHistory.push(editConsentRoute)
            }}
            icon='fas fa-pencil-alt'
            content=''
          />
        )}
        <Popup
          position='left'
          dark
          trigger={<Button link id='download-cloud-icon' icon='fas fa-cloud-download-alt' />}
          className={studyLock ? 'studyLock' : ''}>
          <Button
            link
            id='download-consent-preview'
            icon='fas fa-cloud-download-alt'
            content='Download Consent Preview'
            onClick={() => downloadConsentPreview(row, studyID)}
          />
        </Popup>
      </div>
    )
    const draftsAction = (
      <div className='flexed drafts'>
        {readyToSchedule ? (
          <Button
            grey
            id='schedule-button'
            content='Schedule'
            onClick={() => browserHistory.push(`${location.pathname}/${consentId}/${version}/deploy`)}
          />
        ) : (
          <Button
            link
            onClick={() => browserHistory.push(editConsentRoute)}
            id='edit-button'
            icon='fas fa-pencil-alt'
            content='Edit'
          />
        )}
      </div>
    )
    return (
      <td className='action-cell' key={`row_${idx}`}>
        {selectedPage === 'scheduled' ? consentsAction : draftsAction}
      </td>
    )
  }

  filterList(list) {
    const { noTracksFeature } = this.props
    if (noTracksFeature) {
      const filteredList = Array.from(list)
      if (noTracksFeature) {
        for (let i = 0; i < filteredList.length; i += 1) {
          filteredList[i] = filteredList[i]?.filter(col => {
            return col.key !== STUDY_CONFIG_FEATURE_KEY_MAP.tracks
          })
        }
        return filteredList
      }
    }
    return list
  }

  render() {
    const { consentList, loading, offset, openConsentDrawer, selectedPage } = this.props

    const isScheduledPage = selectedPage === 'scheduled'

    return (
      <div className='consent-table'>
        {loading ? (
          <Loader inContainer className='sticky' />
        ) : (
          <SortableTable
            offset={offset}
            id='consent-table'
            className='consent-list'
            emptyText={`You do not have any ${isScheduledPage ? 'scheduled consents' : 'drafts'}.`}
            rowList={this.filterList(consentList[selectedPage] || [])}
            headerOptions={this.getFilteredHeaders()}
            sortingBy='lastEdited'
            initialOrder='descending'
            renderFunctions={this.renderFunctions}
            viewMoreButton
            viewMoreLine={7}
            onRowClick={isScheduledPage ? openConsentDrawer : null}
          />
        )}
      </div>
    )
  }
}

ConsentsTable.propTypes = {
  canEdit: PropTypes.bool,
  closeModal: PropTypes.func,
  consent: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    metadata: PropTypes.shape({
      other_languages: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  consentStartDate: PropTypes.string,
  downloadConsentPreview: PropTypes.func,
  downloadConsentTranslation: PropTypes.func,
  isSiteAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  noTracksFeature: PropTypes.bool,
  offset: PropTypes.number,
  openModal: PropTypes.func,
  openConsentDrawer: PropTypes.func,
  params: PropTypes.shape({
    studyID: PropTypes.string,
  }),
  participants: PropTypes.arrayOf(PropTypes.array),
  respondence: PropTypes.shape({
    accepted: PropTypes.number,
    completed: PropTypes.number,
    rejected: PropTypes.number,
    total: PropTypes.number,
  }),
  selectedPage: PropTypes.string,
  studyLock: PropTypes.bool,
  uploadTranslation: PropTypes.func,
}

export default ConsentsTable
