import React from 'react'
import { connect } from 'react-redux'
import { setNavBarProps } from 'store/navbar'
import { getUserScope, getUserRoleIdByStudy, getUserScopeByStudy } from 'utils/misc'
import { Loader } from 'components/UIElements'
import { actions as loginActions } from 'routes/Login/modules/Login'
import { actions } from '../modules/CreateUser'
import { actions as siteActions } from '../../../../Sites/routes/SitesPage/modules/Sites'
import CreateUserPage from '../components/CreateUserPage'

const mapStateToProps = (state, ownProps) => {
  let pageType = 'edit'
  if (state.location.pathname.includes('/users/create')) {
    pageType = 'create'
    state.userReducer.user.created_by = state.user.id
  } else if (state.location.pathname.includes('/users/me')) {
    pageType = 'profile'
  } else if (parseInt(state.location.pathname.split('/').slice(-1)[0], 10) === state.user.id) {
    pageType = 'profile'
  }

  const { loading, sending, sites, user, study, studyLock, studies, userReducer, subroute } = state
  const { currentStudy } = study
  const { params } = ownProps
  const { studyID } = params
  const userScope = studyID ? getUserScopeByStudy(user, currentStudy) : getUserScope(user)
  const userRoleIdByCurrentStudy = studyID ? getUserRoleIdByStudy(user, studyID) : 7
  const { general, request, userSites } = loading
  const { sendingState, sentState } = sending
  const sendDisabled = sendingState || sentState

  return {
    ...userReducer,
    pageType,
    studies,
    currentUser: user,
    loading: general,
    requestLoading: request,
    requestSending: sendingState,
    requestSent: sentState,
    sendDisabled,
    studyLock,
    userSitesLoading: userSites,
    currentStudy: study.currentStudy,
    siteSelectionList: sites ? sites.siteSelectionList : [],
    checkedSites: sites ? sites.checkedSites : {},
    noPII: userScope.includes('rp-no-pii'),
    canWrite: userScope.includes('wu'),
    currentRoleId: userRoleIdByCurrentStudy,
    subroute,
    insights: currentStudy.config?.insights,
  }
}

const mapDispatch = { ...actions, ...siteActions, ...loginActions, setNavBarProps }

export default connect(
  mapStateToProps,
  mapDispatch,
)(props => {
  if (props.pageType === 'profile') {
    if (props.loading) return <Loader />
  } else if (props.loading || props.siteSelectionList.length === 0) {
    return <Loader />
  }
  return <CreateUserPage {...props} />
})
