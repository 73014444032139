import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'components/UIElements'
import { closeIcon } from 'assets/assets'
import { CONSENT_SIGNEE_MAP } from 'utils/constants'
import { checkIsInvalidSignee } from 'utils/instrumentValidation'
import STRINGS from 'utils/strings'

const signeeOptions = [
  { key: CONSENT_SIGNEE_MAP.participant, text: 'Participant', value: CONSENT_SIGNEE_MAP.participant },
  { key: CONSENT_SIGNEE_MAP.caregiver, text: 'Caregiver', value: CONSENT_SIGNEE_MAP.caregiver },
  { key: CONSENT_SIGNEE_MAP.clinro, text: 'PI', value: CONSENT_SIGNEE_MAP.clinro },
  { key: CONSENT_SIGNEE_MAP.witness, text: 'Impartial Witness', value: CONSENT_SIGNEE_MAP.witness },
]

const SigneeDropdown = ({
  consent,
  isClinro,
  isInvalidSignee,
  itemId,
  signee,
  signeeId,
  siteRolesStatus,
  updateSignee,
}) => {
  const { type } = signee
  const { metadata } = consent
  const { schedule } = metadata
  const { cohort } = schedule
  const isDeployedByAdvancedOptionSites =
    Array.isArray(cohort) && cohort.findIndex(_cohort => _cohort.type === 'sites') !== -1
  const isNotSiteBasedRecipients = cohort?.type !== 'sites' && !isDeployedByAdvancedOptionSites
  const { witness_status: witnessStatus, clinician_status: clinicianStatus } = siteRolesStatus

  const onSelect = option => {
    const { value } = option
    const newSignee = { ...signee, type: value }
    updateSignee({ itemId, signeeId, signee: newSignee })
  }

  const getFilteredOptions = () => {
    return signeeOptions
      .filter(option => {
        if (!isClinro && option.key === 'witness') {
          return false
        }
        return isClinro || option.key !== 'clinro'
      })
      .filter(option => {
        /**
         * We don't want to filter out the option if the signee selection is invalid,
         * so that the user can see what the invalid selection is.
         */
        if (isInvalidSignee) {
          if (
            (option.key === CONSENT_SIGNEE_MAP.witness && type === CONSENT_SIGNEE_MAP.witness) ||
            (option.key === CONSENT_SIGNEE_MAP.clinro && type === CONSENT_SIGNEE_MAP.clinro)
          )
            return true
        }
        if ((option.key === 'witness' || option.key === 'clinro') && isNotSiteBasedRecipients) {
          return false
        }
        if ((!witnessStatus && option.key === 'witness') || (!clinicianStatus && option.key === 'clinro')) {
          return false
        }
        return true
      })
  }

  return (
    <Dropdown
      hasError={isInvalidSignee}
      errorText={STRINGS.invalidSigneeErr}
      errorTextClassName='signee-error-msg'
      id='signee-dropdown'
      onSelect={onSelect}
      options={getFilteredOptions()}
      selected={type}
    />
  )
}

const Signee = props => {
  const {
    canDelete,
    deleteSignee,
    isActive,
    itemId,
    signee,
    signeeId,
    updateSignee,
    isClinro,
    signeeReqErr,
    consent,
    siteRolesStatus,
  } = props
  const { required, type } = signee

  const onUpdateRequired = bool => {
    const newSignee = { ...signee, required: bool }
    updateSignee({ itemId, signeeId, signee: newSignee })
  }

  const isInvalidSignee = checkIsInvalidSignee(type, siteRolesStatus)

  return (
    <div
      className={`signee flexed start-justified border-radius three position-relative${signeeReqErr ? ' error' : ''}${
        isInvalidSignee ? ' has-signee-error' : ''
      }`}>
      {isActive && <div className='signee-grab-icon' />}
      <SigneeDropdown
        id={signee}
        itemId={itemId}
        signee={signee}
        signeeId={signeeId}
        updateSignee={updateSignee}
        isClinro={isClinro}
        consent={consent}
        siteRolesStatus={siteRolesStatus}
        isInvalidSignee={isInvalidSignee}
      />
      <div className='flexed'>
        <div className='require-options flexed'>
          <Button
            noStyling
            id='signature-required'
            className={required ? 'sel' : ''}
            onClick={() => {
              onUpdateRequired(true)
            }}
            content='Required'
          />
          <Button
            noStyling
            id='signature-optional'
            className={!required ? 'sel' : ''}
            onClick={() => {
              onUpdateRequired(false)
            }}
            content='Optional'
          />
        </div>
        {canDelete && (
          <Button
            id='remove-signee'
            className='rm-signee'
            onClick={() => {
              deleteSignee(itemId, signeeId)
            }}
            noStyling>
            {closeIcon()}
          </Button>
        )}
      </div>
    </div>
  )
}

SigneeDropdown.propTypes = {
  itemId: PropTypes.string,
  signee: PropTypes.shape({
    type: PropTypes.string,
  }),
  isClinro: PropTypes.bool,
  isInvalidSignee: PropTypes.bool,
  signeeId: PropTypes.string,
  siteRolesStatus: PropTypes.shape({ witness_status: PropTypes.bool, clinician_status: PropTypes.bool }),
  updateSignee: PropTypes.func,
}

Signee.propTypes = {
  canDelete: PropTypes.bool,
  deleteSignee: PropTypes.func,
  isActive: PropTypes.bool,
  isClinro: PropTypes.bool,
  itemId: PropTypes.string,
  signee: PropTypes.shape({
    required: PropTypes.bool,
    type: PropTypes.string,
  }),
  signeeId: PropTypes.string,
  updateSignee: PropTypes.func,
  siteRolesStatus: PropTypes.shape({ witness_status: PropTypes.bool, clinician_status: PropTypes.bool }),
  signeeReqErr: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default Signee
