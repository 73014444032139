import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { GROUP_TYPE_MAP } from 'utils/constants'
import { pluralize } from 'utils/misc'
import { checkForAdvancedDeploymentOptions } from 'utils/instrumentValidation'
import { AutocompleteSearch, Checkbox, Input, WarningText } from 'components/UIElements'
import { actions } from '../routes/Studies/routes/Study/routes/Participants/routes/CreateCohort/modules/cohort'
import 'styles/d3/advancedOptions.scss'

const defaultConfig = {
  sites: {
    type: GROUP_TYPE_MAP.sites,
    label: 'Site',
    countableText: ['site', 'sites'],
  },
  age: {
    type: GROUP_TYPE_MAP.age,
    label: 'Age Range',
  },
}

const generateList = list =>
  list?.length
    ? list.map(row => {
        const item = {
          key: row[0]?.value,
          text: row[1]?.value,
          treeLevel: row[2]?.value || 0,
          path: row[3]?.value || null,
        }
        return item
      })
    : []

const AdvancedOptions = props => {
  const dispatch = useDispatch()
  const getState = useSelector(state => {
    const {
      participants: { cohortList },
      study,
      sites,
    } = state
    const { currentStudy } = study
    const { leafSites, siteSelectionList } = sites
    const studyID = currentStudy.id
    return {
      cohortList,
      studyID,
      siteSelectionList,
      leafSites,
    }
  })
  const { studyID, cohortList, siteSelectionList, leafSites } = getState
  const {
    advancedOptions,
    cohort,
    disabled,
    errors = null,
    exclude,
    hideWithOne = false,
    resetAdvancedOptions = () => {},
    setAdvancedOptions,
    setConfig,
    setShowAdvancedOption,
    showAdvancedOption,
    setAdvancedOptionsToStore = () => {},
  } = props

  useEffect(() => {
    const hasAdvancedOptions = checkForAdvancedDeploymentOptions(advancedOptions)
    // Set advancedOptions to redux store to persist it between Properties and Schedule tabs on survey creation
    if (hasAdvancedOptions) {
      setAdvancedOptionsToStore(advancedOptions)
    }
  }, [advancedOptions])

  const data = {}
  const initChecked = {}
  const initAdvancedOptions = {}

  const config = {
    ...defaultConfig,
    ...setConfig,
  }

  Object.keys(config).forEach(key => {
    initChecked[config[key].type] = false
    if (config?.age && key === config.age.type) {
      initAdvancedOptions[config[key].type] = {
        min_age: 0,
        max_age: 100,
      }
    } else {
      initAdvancedOptions[config[key]?.type] = {}
      const type = config[key]?.type
      switch (type) {
        case GROUP_TYPE_MAP.sites:
          data[type] = {
            list: generateList(siteSelectionList),
            leafSites,
          }
          break
        case GROUP_TYPE_MAP.cohort:
        case GROUP_TYPE_MAP.cohorts:
          data[type] = {
            list: generateList(cohortList),
          }
          break
        default:
          break
      }
    }
  })

  const [initialized, setInitialized] = useState(false)
  const [trackLoading, setTrackLoading] = useState(false)
  const [checked, setChecked] = useState({ ...initChecked })

  const onAgeUpdate = field => value => {
    setAdvancedOptions(prev => {
      const prevState = { ...prev }
      prevState.age[field] = value < 0 ? 0 : value > 100 ? 100 : Number(value) || ''
      return { ...prevState }
    })
  }

  const onToggle = (id, ...args) => {
    if (disabled) return
    const { type } = args.pop()
    const newAdvancedOptions = { ...advancedOptions }
    if (id in advancedOptions[type]) delete newAdvancedOptions[type][id]
    else {
      const getItem = data[type].list.filter(item => item.key === id)[0]
      if (!getItem) return
      newAdvancedOptions[type][id] = getItem.text
    }
    setAdvancedOptions({ ...newAdvancedOptions })
  }

  const renderTags = options => {
    const idsTagsList = Object.keys(advancedOptions[options.type])
    return (
      <div className='tags'>
        {idsTagsList.map(id => {
          return (
            <span key={id}>
              {advancedOptions[options.type][id]}
              {!disabled && <i onClick={() => onToggle(id, options)} className='fas fa-times' />}
            </span>
          )
        })}
      </div>
    )
  }

  const onAddNewCohort = async searchTerm => {
    if (disabled) return
    setTrackLoading(true)
    try {
      await dispatch(
        actions.saveCohortInline(
          studyID,
          { group_name: searchTerm, description: searchTerm },
          {},
          id => onToggle(id, { type: config[GROUP_TYPE_MAP.cohort]?.type || config[GROUP_TYPE_MAP.cohorts]?.type }),
          false,
        ),
      )
    } finally {
      setTrackLoading(false)
    }
  }

  const renderTopItem = list => searchTerm => {
    const { disableTrackCreation } = props
    if (list.find(item => item.text === searchTerm) === undefined) {
      return disableTrackCreation ? (
        <li className='no-match'>No Matching Tracks</li>
      ) : (
        <li className='top-item' onClick={() => onAddNewCohort(searchTerm)}>
          <i className='fas fa-plus' />
          Add As New Track
        </li>
      )
    }
  }

  const checkboxToggle = type => {
    setChecked(prev => {
      const prevState = { ...prev }
      prevState[type] = !prevState[type]
      return prevState
    })
    setAdvancedOptions(prev => {
      const statePrev = { ...prev }
      if (!checked[type]) statePrev[type] = JSON.parse(JSON.stringify(initAdvancedOptions[type]))
      else delete statePrev[type]
      return statePrev
    })
  }

  const showAdvancedOptionToggle = () => {
    /**
     * Once the user toggles off the Advanced Options UI, we should clear the advanced options from the store,
     * so that there isn't a difference between the UI and redux.
     */
    if (showAdvancedOption) {
      resetAdvancedOptions()
    }
    setChecked({ ...initChecked })
    setAdvancedOptions({})
    setShowAdvancedOption(prev => !prev)
  }

  useEffect(() => {
    if (initialized || !cohort?.length || showAdvancedOption) return
    const copyCohort = [...cohort]
    const getInitialValues = copyCohort.reduce((acc, item) => {
      const { type, filter } = item
      if (type === config.age.type) {
        acc[type] = { ...filter }
        if (!checked[type]) {
          setChecked(prev => {
            const prevState = { ...prev }
            prevState[type] = true
            return prevState
          })
        }
      } else {
        const field = type === config.sites.type ? 'site_ids' : 'include'
        filter[field].map(tid => {
          const findedItem = data[type].list.find(listItem => listItem.key === tid)
          if (findedItem?.text) {
            if (!acc[type]) acc[type] = {}
            acc[type][tid] = findedItem.text
          }
          if (!checked[type]) {
            setChecked(prev => {
              const prevState = { ...prev }
              prevState[type] = true
              return prevState
            })
          }
        })
      }
      return acc
    }, {})
    if (getInitialValues && Object.keys(getInitialValues)?.length > 0) {
      setShowAdvancedOption(true)
      setAdvancedOptions(getInitialValues)
      setInitialized(true)
    }
  }, [cohort, data])

  const siteOption = {
    ...config.sites,
    cases: 'search',
    options: {
      id: 'advanced-sites',
      filter: data[config.sites.type].leafSites,
      placeholder: 'Search for a site...',
    },
  }

  const trackOption = {
    cases: 'search',
    ...(config[config[GROUP_TYPE_MAP.cohort]?.type] || config[config[GROUP_TYPE_MAP.cohorts]?.type]),
    options: {
      id: 'advanced-tracks',
      loading: trackLoading,
      renderTopItem: renderTopItem(
        data[config[GROUP_TYPE_MAP.cohort]?.type || config[GROUP_TYPE_MAP.cohorts]?.type].list,
      ),
      placeholder: 'Search for a track...',
    },
  }

  const ageOption = {
    cases: 'age',
    ...config.age,
    divider: <span className='age-divider'>to</span>,
    items: [
      {
        id: 'advanced-min-age',
        placeholder: 'Min Age',
        field: 'min_age',
      },
      {
        id: 'advanced-max-age',
        placeholder: 'Max Age',
        field: 'max_age',
      },
    ],
  }

  const optionsList = [siteOption, trackOption, ageOption].filter(item => !exclude[item.type])

  const renderElements = el => {
    const { type, cases, items } = el
    switch (cases) {
      case 'search':
        const optionsKeys = Object.keys(advancedOptions[type]) || []
        const [singular, plural] = config[type].countableText
        return (
          <div className='input-wrap'>
            {optionsKeys.length > 0 && (
              <p>
                <b>{optionsKeys.length}</b> {`${pluralize(optionsKeys.length, singular, plural, false)}`} assigned
              </p>
            )}
            {renderTags({ type })}
            {!disabled && (
              <AutocompleteSearch
                {...el.options}
                list={data[type].list}
                checked={advancedOptions[type]}
                toggleItem={id => onToggle(id, { type })}
              />
            )}
          </div>
        )
      case 'age':
        return (
          <div className='input-wrap'>
            {items?.length &&
              items.map((options, index) => {
                return (
                  <React.Fragment key={options.id}>
                    <Input
                      {...options}
                      key={options.id}
                      className='age-input'
                      value={advancedOptions[type][options.field]}
                      onChange={onAgeUpdate(options.field)}
                      onBlur={() => {
                        if (options.field === 'min_age' && advancedOptions.age.min_age > advancedOptions.age.max_age) {
                          onAgeUpdate('max_age')(advancedOptions.age.min_age)
                        }
                        if (options.field === 'max_age' && advancedOptions.age.max_age < advancedOptions.age.min_age) {
                          onAgeUpdate('min_age')(advancedOptions.age.max_age)
                        }
                      }}
                      type='number'
                      max='100'
                      disabled={disabled}
                    />
                    {index === 0 && el.divider ? el.divider : null}
                  </React.Fragment>
                )
              })}
          </div>
        )
      default:
        return null
    }
  }

  if (hideWithOne && optionsList?.length <= 1) return null

  return (
    <div className='advanced-options'>
      <div className='advanced-options-toggle'>
        <span>Show advanced option</span>
        <Checkbox disabled={disabled} toggle checked={showAdvancedOption} onClick={showAdvancedOptionToggle} />
      </div>
      {showAdvancedOption && (
        <div className='advanced-settings'>
          <div className='advanced-settings-label'>Send to participants within</div>
          {errors && (
            <div className='warning-wrap'>
              <WarningText
                text={
                  errors['schedule.cohort.filter.sites'] ||
                  errors['schedule.cohort.filter.include'] ||
                  errors['schedule.cohort.filter.min_age'] ||
                  errors['schedule.cohort.filter.max_age']
                }
                disabled
              />
            </div>
          )}
          {optionsList?.length &&
            optionsList.map(item => {
              const { type, label, countableText } = item
              const text =
                advancedOptions[type] && Array.isArray(countableText)
                  ? pluralize(
                      Object.keys(advancedOptions[type])?.length || 1,
                      countableText[0],
                      countableText[1],
                      false,
                    )
                  : label
              return (
                <React.Fragment key={label}>
                  {!exclude[type] && (
                    <div key={label} className='advanced-settings-checkbox'>
                      <Checkbox
                        disabled={disabled}
                        checked={checked[type]}
                        label={text}
                        tabbable
                        onClick={() => checkboxToggle(type)}
                      />
                      {checked[type] && advancedOptions[type] && renderElements(item)}
                    </div>
                  )}
                </React.Fragment>
              )
            })}
        </div>
      )}
    </div>
  )
}

AdvancedOptions.defaultProps = {
  exclude: {},
  disabled: false,
}

AdvancedOptions.propTypes = {
  advancedOptions: PropTypes.objectOf(PropTypes.object),
  cohort: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  exclude: PropTypes.object,
  hideWithOne: PropTypes.bool,
  resetAdvancedOptions: PropTypes.func,
  setAdvancedOptions: PropTypes.func,
  setAdvancedOptionsToStore: PropTypes.func,
  setConfig: PropTypes.objectOf(PropTypes.object),
  setShowAdvancedOption: PropTypes.func,
  showAdvancedOption: PropTypes.bool,
}

export default AdvancedOptions
