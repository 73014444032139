import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import ConsentDeployContainer from './containers/ConsentDeployContainer'
import newConsentReducer, { fetchNewConsent } from '../NewConsentBuilder/modules/NewConsent'
import consents from '../../../ConsentsDashboard/modules/Consents'
import instrumentReducer from '../../../../../Instruments/routes/Instrument/modules/Instrument'
import ptpsReducer, { fetchCohorts } from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'
import participantReducer from '../../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'

import sitesReducer, { fetchSites } from '../../../../../Sites/routes/SitesPage/modules/Sites'

export const injectConsentReducers = store => {
  injectReducers(store, {
    newConsentReducer,
    consents,
    instrumentReducer,
    participantReducer,
    participants: ptpsReducer,
    sites: sitesReducer,
  })
}

export default store => {
  return {
    path: ':consentId/:consentVersion/deploy',
    getComponent(nextState, cb) {
      store.dispatch(setSubroute('consentDeploy'))
      require.ensure(
        [],
        () => {
          injectConsentReducers(store)
          const { studyID, consentId, consentVersion } = nextState.params
          const { currentStudy } = store.getState().study
          setPageTitle(`Deploy Consent | Study ${studyID} | ${currentStudy.platform_name}`)
          Promise.all([
            store.dispatch(fetchCohorts(studyID, false)),
            store.dispatch(fetchSites(studyID, true, false, false)), // params: studyID, hasLoader, includeLang, clearCheckedSites
          ]).then(() => {
            store.dispatch(fetchNewConsent({ studyId: studyID, consentId, version: consentVersion, getJson: true }))
          })
          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(UserHasScope(['xc'], ConsentDeployContainer, false, studyID, null, currentStudy)),
            ),
          )
        },
        '/consents/deploy',
      )
    },
  }
}
