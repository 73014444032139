import React from 'react'
import { Button, Popup } from 'components/UIElements'
import { INSTRUMENT_TYPE_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP, MODAL_CONTENT_MAP } from 'utils/constants'
import { browserHistory } from 'react-router'
import InstrumentsViewModalContent from './InstrumentsViewModalContent'
import CreateDiaryButton from './CreateDiaryButton'

const InstrumentsViewDefaultButtons = ({
  canWrite,
  hasClinro,
  hasDiary,
  openModal,
  studyID,
  studyLock,
  wizardDisplayName,
  wizardTitle,
  updateWizardDisplayName,
  updateWizardTitle,
  hasInstrumentUnification,
}) => {
  return (
    <div className='header-button-list'>
      {canWrite && (
        <div>
          <Button
            grey
            id='upload-article'
            content='Upload Article'
            className='article'
            disabled={studyLock}
            onClick={() =>
              !studyLock &&
              openModal({
                content: (
                  <InstrumentsViewModalContent
                    type={INSTRUMENT_TYPE_MAP.article}
                    updateWizardTitle={updateWizardTitle}
                    updateWizardDisplayName={updateWizardDisplayName}
                    wizardTitle={wizardTitle}
                    wizardDisplayName={wizardDisplayName}
                    hasInstrumentUnification={hasInstrumentUnification}
                  />
                ),
                confirmButton: MODAL_BUTTONS_MAP.next,
                onConfirm: () => {
                  browserHistory.push(`/studies/${studyID}/instruments/createarticle`)
                },
                className: MODAL_CLASSES_MAP.createInstrument,
              })
            }
          />
          <Button
            grey
            id='create-task'
            disabled={studyLock}
            content='Create Task'
            onClick={() => !studyLock && browserHistory.push(`/studies/${studyID}/instruments/selecttask`)}
          />
          {hasDiary && !hasInstrumentUnification ? (
            <CreateDiaryButton
              studyLock={studyLock}
              hasClinro={hasClinro}
              onClick={() =>
                !studyLock &&
                openModal({
                  content: (
                    <InstrumentsViewModalContent
                      type={INSTRUMENT_TYPE_MAP.diary}
                      updateWizardTitle={updateWizardTitle}
                      updateWizardDisplayName={updateWizardDisplayName}
                      wizardTitle={wizardTitle}
                      wizardDisplayName={wizardDisplayName}
                      hasInstrumentUnification={hasInstrumentUnification}
                    />
                  ),
                  confirmButton: MODAL_BUTTONS_MAP.createDiary,
                  onConfirm: () => {
                    browserHistory.push(`/studies/${studyID}/instruments/creatediary`)
                  },
                  className: MODAL_CLASSES_MAP.createInstrument,
                })
              }
            />
          ) : null}
          <Popup
            dark
            noPointer
            disabled={studyLock}
            trigger={
              <Button
                id='create-survey'
                grey={hasClinro ? true : undefined}
                iconAfter='fas fa-caret-down after-text'
                content={hasInstrumentUnification ? 'Create Participant Instrument' : 'Create Survey'}
              />
            }>
            <Button
              id='create-survey-dropdown'
              content={hasInstrumentUnification ? 'Create Participant Instrument' : 'Create Survey'}
              grey={hasClinro ? true : undefined}
              onClick={() =>
                !studyLock &&
                openModal({
                  content: (
                    <InstrumentsViewModalContent
                      type={INSTRUMENT_TYPE_MAP.survey}
                      updateWizardTitle={updateWizardTitle}
                      updateWizardDisplayName={updateWizardDisplayName}
                      wizardTitle={wizardTitle}
                      wizardDisplayName={wizardDisplayName}
                      hasInstrumentUnification={hasInstrumentUnification}
                    />
                  ),
                  confirmButton: hasInstrumentUnification
                    ? MODAL_BUTTONS_MAP.createParticipantInstrument
                    : MODAL_BUTTONS_MAP.createSurvey,
                  onConfirm: () => {
                    browserHistory.push(`/studies/${studyID}/instruments/createinstrument`)
                  },
                  className: MODAL_CONTENT_MAP.createInstrument,
                })
              }
              icon='fas fa-file-alt'
            />
            <Button
              grey
              id='upload-survey'
              content={hasInstrumentUnification ? 'Upload Participant Instrument' : 'Upload Survey'}
              onClick={() => !studyLock && browserHistory.push(`/studies/${studyID}/instruments/uploadinstrument`)}
              icon='fas fa-file-upload'
            />
          </Popup>
          {hasClinro && (
            <Button
              id='create-clinician-instrument'
              content='Create Clinician Instrument'
              disabled={studyLock}
              onClick={() =>
                !studyLock &&
                openModal({
                  content: (
                    <InstrumentsViewModalContent
                      type={INSTRUMENT_TYPE_MAP.clinro}
                      updateWizardTitle={updateWizardTitle}
                      updateWizardDisplayName={updateWizardDisplayName}
                      wizardTitle={wizardTitle}
                      wizardDisplayName={wizardDisplayName}
                      hasInstrumentUnification={hasInstrumentUnification}
                    />
                  ),
                  confirmButton: MODAL_BUTTONS_MAP.createInstrument,
                  onConfirm: () => {
                    browserHistory.push(`/studies/${studyID}/instruments/createclinro`)
                  },
                  className: MODAL_CLASSES_MAP.createInstrument,
                  closeOnBackgroundClick: true,
                })
              }
            />
          )}
        </div>
      )}
    </div>
  )
}

export default InstrumentsViewDefaultButtons
