import { ROLE_NAME_ID_MAP, STUDY_ROLE_IDS } from './constants'

const getRoleIdFromRoleName = roleName => ROLE_NAME_ID_MAP[roleName.split(' ').join('_')]

export const isSameUser = userId => {
  if (!userId) return null
  const lastUser = Number(localStorage.getItem('lastUser'))
  if (lastUser) return userId === lastUser
  return null
}

export const setUserLinktFlag = userID => {
  const userMap = JSON.parse(localStorage.getItem('user-map')) || {}
  if (!userMap[userID]) {
    const newUsers = { ...userMap, [userID]: { linktFlag: true } }
    localStorage.setItem('user-map', JSON.stringify(newUsers))
  }
}

export const checkIsStudyAdmin = roleId => {
  return STUDY_ROLE_IDS.includes(roleId)
}
export default getRoleIdFromRoleName

export const checkUserHasScope = ({ user, studyId, scope }) =>
  user?.permissions?.[studyId]?.scope?.some(item => scope.includes(item))
