import VisibilityContent from '../components/VisibilityContent'
import { schedulerActions } from '../../../modules/Instrument'
import { connect } from 'react-redux'

const mapStateToProps = ({ instrumentReducer }) => {
  return {
    ...instrumentReducer.instrument.metadata.schedule,
    metadata: instrumentReducer.instrument.metadata
  }
}

export default connect(
  mapStateToProps,
  schedulerActions
)(VisibilityContent)
