import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GROUP_TYPE_MAP } from 'utils/constants'
import { pluralize } from 'utils/misc'
import { Container, Radio, WarningText, AutocompleteSearch, AdvancedOptions } from 'components/UIElements'
import CohortManagement from '../../../../Participants/routes/CreateParticipant/containers/CohortManagementContainer'

const RecipientSection = ({
  checkedSites,
  disabled,
  disableTrackCreation,
  errors,
  isCreate,
  isSMS,
  leafSites,
  noTracksFeature,
  readOnly,
  schedule,
  siteSelectionList = [],
  updateAnnouncementSchedule,
  advancedOptions,
  setAdvancedOptions,
  showAdvancedOption,
  setShowAdvancedOption,
}) => {
  const [checked, setChecked] = useState({})
  const getCohort = Array.isArray(schedule.cohort) || showAdvancedOption ? { type: null } : schedule.cohort
  const selected = {
    1: getCohort.type === GROUP_TYPE_MAP.all,
    2: getCohort.type === GROUP_TYPE_MAP.cohorts,
    3: getCohort.type === GROUP_TYPE_MAP.sites,
  }

  const list = siteSelectionList.map(row => {
    const item = { key: row[0].value, text: row[1].value, treeLevel: row[2].value, path: row[3].value }
    return item
  })
  const checkedSiteInfo = list.filter(site => checkedSites.includes(site.key))
  const onToggleSite = siteId => {
    const newChecked = { ...checked }
    if (siteId in checked) {
      delete newChecked[siteId]
    } else {
      const { text } = list.filter(item => item.key === siteId)[0]
      newChecked[siteId] = text
    }
    setChecked(newChecked)
    updateAnnouncementSchedule(
      'cohort',
      { type: 'sites', filter: { site_ids: [...Object.keys(newChecked)].map(objKey => parseInt(objKey, 10)) } },
      'schedule.cohort.filter.include',
    )
  }
  const checkedCurrentAnnouncementSites = () => {
    const announcementSites = {}
    for (let i = 0; i < checkedSiteInfo.length; i++) {
      announcementSites[checkedSiteInfo[i].key] = checkedSiteInfo[i].text
    }
    setChecked(announcementSites)
  }
  useEffect(() => {
    if (readOnly || !isCreate) {
      checkedCurrentAnnouncementSites()
    }
  }, [isCreate])

  const siteTags = () => {
    const siteIdsTagsList = Object.keys(checked)
    return (
      <div className='tags'>
        {siteIdsTagsList.map(siteId => {
          return (
            <span key={siteId}>
              {checked[siteId]}
              {!readOnly && <i onClick={() => onToggleSite(siteId)} className='fas fa-times' />}
            </span>
          )
        })}
      </div>
    )
  }
  return (
    <>
      <h4>Recipients</h4>
      <Container>
        <p>Send To</p>
        <div className='recipient-panel'>
          <Radio
            id='send-to-all-participants'
            disabled={disabled || showAdvancedOption}
            selected={selected[1]}
            onClick={() =>
              updateAnnouncementSchedule('cohort', { type: GROUP_TYPE_MAP.all }, 'schedule.cohort.filter.include')
            }
            content='All Participants'
          />
          {!noTracksFeature && (
            <>
              <Radio
                id='send-to-tracks'
                className='cohort-select'
                disabled={disabled || showAdvancedOption}
                selected={selected[2]}
                onClick={() =>
                  updateAnnouncementSchedule(
                    'cohort',
                    { type: GROUP_TYPE_MAP.cohorts, filter: { include: [] } },
                    'schedule.cohort.filter.include',
                  )
                }
                content={
                  <span>
                    Select Tracks
                    {selected[2] && !showAdvancedOption && (
                      <WarningText text={errors['schedule.cohort.filter.include']} disabled={disabled} />
                    )}
                  </span>
                }
              />
              {selected[2] && !showAdvancedOption && <CohortManagement disabled={disabled} />}
            </>
          )}
          {!isSMS && (
            <Radio
              id='send-to-sites'
              disabled={disabled || showAdvancedOption}
              selected={selected[3]}
              onClick={() => {
                setChecked({})
                updateAnnouncementSchedule(
                  'cohort',
                  { type: GROUP_TYPE_MAP.sites, filter: { site_ids: [] } },
                  'schedule.cohort.filter.sites',
                )
              }}
              content={
                <span>
                  Select Sites
                  {selected[3] && !showAdvancedOption && (
                    <WarningText text={errors['schedule.cohort.filter.sites']} disabled={disabled} />
                  )}
                </span>
              }
            />
          )}
          {!isSMS && selected[3] && !showAdvancedOption && Object.keys(checked).length > 0 && (
            <p>
              <b>{Object.keys(checked).length}</b> {`${pluralize(Object.keys(checked).length, 'site', 'sites', false)}`}{' '}
              assigned
            </p>
          )}
          {!isSMS && selected[3] && !showAdvancedOption && siteTags()}
          {!isSMS && selected[3] && !disabled && !showAdvancedOption && (
            <AutocompleteSearch
              checked={checked}
              list={list}
              filter={leafSites}
              toggleItem={onToggleSite}
              id='search-for-a-site'
              placeholder='Search for a site...'
              disabled={disabled}
            />
          )}
          <AdvancedOptions
            cohort={schedule.cohort}
            advancedOptions={advancedOptions}
            disableTrackCreation={disableTrackCreation}
            setAdvancedOptions={setAdvancedOptions}
            showAdvancedOption={showAdvancedOption}
            setShowAdvancedOption={setShowAdvancedOption}
            disabled={disabled}
            exclude={{
              [GROUP_TYPE_MAP.age]: true,
              [GROUP_TYPE_MAP.cohorts]: noTracksFeature,
              [GROUP_TYPE_MAP.cohort]: noTracksFeature,
            }}
            errors={errors}
            setConfig={{
              study_groups: {
                type: GROUP_TYPE_MAP.cohorts,
                label: 'Track',
                countableText: ['track', 'tracks'],
              },
            }}
          />
        </div>
      </Container>
    </>
  )
}

RecipientSection.propTypes = {
  checkedSites: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
  disableTrackCreation: PropTypes.bool,
  errors: PropTypes.object,
  isCreate: PropTypes.bool,
  isSMS: PropTypes.bool,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  noTracksFeature: PropTypes.bool,
  readOnly: PropTypes.bool,
  schedule: PropTypes.shape({
    cohort: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  }),
  siteSelectionList: PropTypes.arrayOf(PropTypes.array),
  updateAnnouncementSchedule: PropTypes.func,
  advancedOptions: PropTypes.object,
  setAdvancedOptions: PropTypes.func,
  showAdvancedOption: PropTypes.bool,
  setShowAdvancedOption: PropTypes.func,
}

export default RecipientSection
