import React from 'react'
import { Button, Radio, Input, Dropdown, Checkbox, Popup, TimePicker } from 'components/UIElements'
import STRINGS from '../../../../../../../../../../../../utils/strings'

const InstrumentDeploymentRange = ({
  disabled,
  id,
  onClick,
  selected,
  windowOptions,
  isDateTime,
  hasError,
  value,
  onChange,
  addon,
  onAddButtonClick,
  onRemoveButtonClick,
  inputValue,
  onInputChange,
  selectedDropdownItem,
  dropdownOptions,
  onDropdownSelect,
  subtitle,
  toggleDisabled,
  toggleChecked,
  onToggleClick,
  from,
  timePickerPlaceholder = '',
  disablePriorToTime,
}) => {
  return (
    <Radio
      className='has-future-toggle'
      disabled={disabled}
      id={id}
      onClick={onClick}
      selected={selected}
      content={
        <div className='option-wrapper column'>
          {!windowOptions ? (
            <>
              <div className='option-inner'>
                <span className={disabled ? 'disabled' : ''}>{STRINGS.instrumentDeployedDay}</span>
                {isDateTime ? (
                  <>
                    <span className={disabled ? 'disabled' : ''}>{STRINGS.at}</span>
                    <TimePicker
                      allowEmpty
                      use12Hours
                      disabled={disabled}
                      hasError={!!hasError && selected}
                      value={value}
                      onChange={onChange}
                      addon={addon}
                      popupClassName='timepicker-overlay'
                      placeholder={timePickerPlaceholder}
                      className={timePickerPlaceholder ? 'long' : ''}
                      disablePriorToTime={disablePriorToTime}
                    />
                  </>
                ) : null}
                <Button
                  id='add-window'
                  className='window-btn'
                  content={STRINGS.addWindow}
                  disabled={disabled}
                  iconBefore='fas fa-plus'
                  noStyling
                  onClick={onAddButtonClick}
                />
              </div>
              {!from && selected ? (
                <div className='future-dates-toggle'>
                  <Checkbox disabled={toggleDisabled} toggle checked={toggleChecked} onClick={onToggleClick} />
                  <span className={disabled ? 'disabled' : ''}>
                    {isDateTime ? STRINGS.acceptFutureDatesTime : STRINGS.acceptFutureDates}
                  </span>
                  <Popup
                    className='future-dates-popup'
                    align={'left'}
                    position='bottom'
                    hover
                    noPointer
                    dark
                    trigger={<i className='fas fa-info-circle' />}>
                    {<p>{isDateTime ? STRINGS.acceptFutureDatesTimeTooltip : STRINGS.acceptFutureDatesTooltip}</p>}
                  </Popup>
                </div>
              ) : null}
            </>
          ) : null}
          {windowOptions ? (
            <>
              <div className='option-inner'>
                <span className={disabled ? 'disabled' : ''}>{STRINGS.instrumentDeployedDay}</span>
                {isDateTime ? (
                  <>
                    <span className={disabled ? 'disabled' : ''}>{STRINGS.at}</span>
                    <TimePicker
                      allowEmpty
                      use12Hours
                      disabled={disabled}
                      hasError={!!hasError && selected}
                      value={value}
                      onChange={onChange}
                      addon={addon}
                      popupClassName='timepicker-overlay'
                      placeholder={timePickerPlaceholder}
                      className={timePickerPlaceholder ? 'long' : ''}
                    />
                  </>
                ) : null}
              </div>
              {!from && selected ? (
                <div className='future-dates-toggle'>
                  <Checkbox disabled={toggleDisabled} toggle checked={toggleChecked} onClick={onToggleClick} />
                  <span className={disabled ? 'disabled' : ''}>
                    {isDateTime ? STRINGS.acceptFutureDatesTime : STRINGS.acceptFutureDates}
                  </span>
                  <Popup
                    className='future-dates-popup'
                    align={'left'}
                    position='bottom'
                    hover
                    noPointer
                    dark
                    trigger={<i className='fas fa-info-circle' />}>
                    {<p>{isDateTime ? STRINGS.acceptFutureDatesTimeTooltip : STRINGS.acceptFutureDatesTooltip}</p>}
                  </Popup>
                </div>
              ) : null}
              <div className='option-inner'>
                <Input
                  className='period-duration'
                  inline
                  errorText={'Cannot be empty'}
                  isPositive
                  onlyInteger
                  onBlur={e => {}}
                  disabled={disabled}
                  id='period-duration-input'
                  type='number'
                  value={inputValue}
                  onChange={onInputChange}
                />
                <Dropdown
                  id='period-day-week-select'
                  disabled={disabled}
                  selected={selectedDropdownItem}
                  options={dropdownOptions}
                  onSelect={onDropdownSelect}
                />
                <span className={disabled ? 'disabled' : ''}>{subtitle}</span>
                <Button
                  id='remove-window'
                  className='window-btn'
                  content={STRINGS.removeWindow}
                  disabled={disabled}
                  iconBefore='fas fa-close'
                  noStyling
                  onClick={onRemoveButtonClick}
                />
              </div>
            </>
          ) : null}
        </div>
      }
    />
  )
}

export default InstrumentDeploymentRange
