import { injectReducers } from 'store/reducers'
import { loadingActions } from 'store/loader'
import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import AccountSettingsContainer from './containers/AccountSettingsContainer'
import consentReducer from '../../../Consent/routes/ConsentEditor/modules/Consent'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'
import { actions as createPtpActions } from '../CreateParticipant/modules/CreateParticipant'
import { fetchCohorts } from '../ParticipantsPage/modules/Participants'
// Sync route definition
export default store => ({
  path: 'account-settings',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducers(store, {
          consentReducer,
          sites: sitesReducer,
        })

        const { params } = nextState
        const { studyID } = params
        const { currentStudy } = store.getState().study
        setPageTitle(`Create Participant | Account Settings | Study ${studyID} | ${currentStudy.platform_name}`)

        store.dispatch(loadingActions.startLoader(true))
        Promise.all([
          store.dispatch(fetchSites(studyID, false, false, false)),
          store.dispatch(fetchCohorts(studyID, false, true)),
          store.dispatch(createPtpActions.fetchLocales()),
        ]).then(() => {
          store.dispatch(loadingActions.stopLoader(true))
        })

        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'participants',
              )(
                UserHasScope(
                  ['wp', 'wxp'],
                  AccountSettingsContainer,
                  false,
                  studyID,
                  `/studies/${studyID}/participants/create`,
                ),
              ),
            ),
          ),
        )
      },
      '/participants/account-settings',
    )
  },
})
