import { injectReducer } from 'store/reducers'
import { authActions } from 'store/user'
import { setPageTitle } from 'utils/misc'
import reducer, { actions } from '../Studies/routes/Study/routes/Users/routes/ActivateUser/modules/ActivateUser'
import ActivateUserContainer from '../Studies/routes/Study/routes/Users/routes/ActivateUser/containers/ActivateUserContainer'

// Sync route definition
export default store => ({
  path: '/users/activate',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        setPageTitle(`Activate User | Admin Panel`)
        injectReducer(store, {
          key: 'activateUser',
          reducer,
        })
        store.dispatch(authActions.logout())
        const token = store.getState().location.search.split('?token=')[1]
        store.dispatch(actions.activateUser(token)).then(() => {
          cb(null, ActivateUserContainer)
        })
      },
      'activate',
    )
  },
})
