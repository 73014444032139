import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'

const ExportImportChoices = ({ item, itemId, downloadChoicesCSV, isImport = false, onOpenUploadModal, className }) => {
  const question = {
    [itemId]: item,
  }
  return (
    <div className={`flexed column start-aligned ${className ? className : ''}`}>
      <Button
        content={`${isImport ? 'Import CSV...' : 'CSV Template'}`}
        iconBefore={`fas fa-cloud-${isImport ? 'upload' : 'download'}-alt`}
        light
        onClick={() => {
          downloadChoicesCSV({ question })
        }}
        onClick={() => (isImport ? onOpenUploadModal() : downloadChoicesCSV({ question }))}
      />
    </div>
  )
}

ExportImportChoices.propTypes = {
  downloadChoicesCSV: PropTypes.func,
  item: PropTypes.shape({}),
  itemId: PropTypes.string,
  isImport: PropTypes.bool,
  onOpenUploadModal: PropTypes.func,
  className: PropTypes.string,
}

export default ExportImportChoices
