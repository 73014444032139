import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Loader, PageSelector } from 'components/UIElements'
import 'styles/d3/pdfviewer.scss'

const DEFAULT_WIDTH = 600

const PdfViewer = props => {
  const { url } = props
  const [pdfModule, setPdfModule] = useState(null)
  const [pages, setPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  import('react-pdf/dist/esm/entry.webpack').then(module => {
    setPdfModule(module)
  })

  const renderPage = useMemo(() => {
    if (pages && pdfModule) {
      const { Page } = pdfModule
      return (
        <Page pageNumber={currentPage} renderAnnotationLayer={false} renderTextLayer={false} width={DEFAULT_WIDTH} />
      )
    }
    return null
  }, [pages, pdfModule, currentPage])

  const renderPdf = useCallback(() => {
    if (pdfModule) {
      const { Document } = pdfModule
      return (
        <>
          <Document
            file={{ url }}
            loading={<Loader inContainer />}
            onLoadSuccess={({ numPages }) => {
              setPages(numPages)
            }}>
            {renderPage}
          </Document>
          <PageSelector currentPage={currentPage} onChange={current => setCurrentPage(current)} total={pages} />
        </>
      )
    }
    return null
  }, [pages, pdfModule, currentPage, url])

  return <div className='d3-pdf-viewer'>{renderPdf()}</div>
}

PdfViewer.propTypes = {
  url: PropTypes.string,
}

export default PdfViewer
