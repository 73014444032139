import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import Loader from 'components/D3Loader'
import { INSIGHTS_DASHBOARD_KEY_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import { Button } from 'components/UIElements'
import useSisense from '../../utils/useSisense'
import InsightsFrame from './InsightsFrame'
import { INSIGHTS_VENDORS } from '../../utils/utils'

const defaultClassName = 'flexed start-aligned'

const overviewList = [
  {
    key: INSIGHTS_DASHBOARD_KEY_MAP.studySpecific,
    order: 0,
    type: 'text',
    className: 'insights-container study-specific-top',
    vendor_type: '',
  },
  {
    key: INSIGHTS_DASHBOARD_KEY_MAP.location,
    order: 1,
    type: 'frame',
    vendor_type: '',
  },
  {
    key: INSIGHTS_DASHBOARD_KEY_MAP.demographics,
    order: 2.1,
    type: 'frame',
    vendor_type: '',
  },
  {
    key: INSIGHTS_DASHBOARD_KEY_MAP.instrumentDetail,
    order: 2.2,
    type: 'frame',
    vendor_type: '',
  },
  {
    key: INSIGHTS_DASHBOARD_KEY_MAP.dataSurveillance,
    order: 3,
    type: 'frame',
    vendor_type: '',
  },
  {
    key: INSIGHTS_DASHBOARD_KEY_MAP.riskEngagement,
    order: 4.1,
    type: 'frame',
    vendor_type: '',
  },
  {
    key: INSIGHTS_DASHBOARD_KEY_MAP.consentDetail,
    order: 4.2,
    type: 'frame',
    vendor_type: '',
  },
]

const InsightsDashboard = props => {
  const {
    insights,
    isStudyAdmin,
    renderSisenseLink,
    studyID,
    hasEditInsightsPermission,
    studyRoleId,
    insightsLowFunctionalityMode,
    loadSisenseDashboards,
  } = props
  const {
    custom_buttons: customButtons,
    sisense_url: sisenseUrl,
    base_url: baseUrl,
    permissions: insightsPermissions,
  } = insights

  // Validate if any dashboard has Holistics
  const isHolistics = Object.values(customButtons).some(
    dashboard => dashboard?.vendor_type === INSIGHTS_VENDORS.holistics,
  )

  const overviewListFilteredByPermissions = useMemo(() => {
    const dashboardsKeys = Object.keys(customButtons)
    let newOverviewList = overviewList.filter(({ key }) => dashboardsKeys.includes(key))
    const nonDefaultDashboards = dashboardsKeys.filter(key => !newOverviewList.find(item => item.key === key))
    newOverviewList = newOverviewList.map(item => ({
      ...item,
      order: Number(item.order + nonDefaultDashboards.length),
    }))
    nonDefaultDashboards.map((key, idx) => {
      newOverviewList = [
        {
          key,
          order: Number(`${idx + 1}`),
          type: 'text',
          vendor_type: '',
          className: 'insights-container study-specific-top',
        },
        ...newOverviewList,
      ]
    })
    return newOverviewList.reduce((acc, item) => {
      const { key } = item
      const find = insightsPermissions?.length
        ? insightsPermissions.find(itm => itm.dashboard_name === key && studyID == itm.study_id)
        : null
      if (find && !find?.hidden_role_ids[studyRoleId]) acc.push(item)
      return acc
    }, [])
  }, [insightsPermissions, studyRoleId, overviewList])

  const [sisenseLoadingErr, setSisenseLoadingErr] = useState(null)

  const sisenseLoaded = useSisense({ sisenseUrl, setSisenseLoadingErr })

  const renderOverview = ({ key, order, type, studySpecificDashboard }) => {
    if (!key || !customButtons[key]) return null
    const dashboard = studySpecificDashboard || customButtons[key]
    switch (type) {
      case 'frame':
        return (
          <InsightsFrame
            key={key}
            baseUrl={baseUrl}
            frameKey={`${order}: ${key}`}
            isStudyAdmin={isStudyAdmin}
            insightsModule={dashboard}
            sisenseLink={renderSisenseLink(dashboard)}
            sisenseLoaded={sisenseLoaded}
            sisenseLoadingErr={sisenseLoadingErr}
            studyID={studyID}
          />
        )
      case 'text':
        return (
          <div key={key}>
            {renderSisenseLink(dashboard)}
            {dashboard.heading && <h5>{dashboard.heading}</h5>}
            {dashboard.body && <p>{dashboard.body}</p>}
          </div>
        )

      default:
        return null
    }
  }

  const renderOverviews = ({ overview, isArray }, index) => {
    /**
     * Some studies may have multiple study specific dashboards. In such cases, an array of
     * dashboard configurations would be given. If an array is given, this array should be
     * mapped to overview renders.
     */
    if (overview.key === INSIGHTS_DASHBOARD_KEY_MAP.studySpecific) {
      const studySpecificDashboardConfig = customButtons[INSIGHTS_DASHBOARD_KEY_MAP.studySpecific]
      if (Array.isArray(studySpecificDashboardConfig)) {
        return studySpecificDashboardConfig.map((studySpecificDashboard, i) => {
          return (
            <div key={`${studySpecificDashboard.dashboard_id}-${i}`} className={overview.className ?? defaultClassName}>
              {renderOverview({ ...overview, studySpecificDashboard })}
            </div>
          )
        })
      }
    }
    if (Array.isArray(overview))
      return (
        <div key={index} className={defaultClassName}>
          {overview.map(_overview => renderOverviews({ overview: _overview, isArray: true }))}
        </div>
      )
    if (!overview?.key || !customButtons[overview.key]) return null
    if (isArray) return renderOverview(overview)
    return (
      <div key={overview.key} className={overview.className ?? defaultClassName}>
        {renderOverview(overview)}
      </div>
    )
  }

  const renderGroups = useMemo(() => {
    const groups = []
    const keys = {}
    let overviewListWithVendor = overviewListFilteredByPermissions.map(overView => {
      return { ...overView, vendor_type: customButtons[overView.key]?.vendor_type ?? INSIGHTS_VENDORS.sisense }
    })
    if ((insightsLowFunctionalityMode && !isHolistics) || overviewListFilteredByPermissions.length === 0)
      return (
        <div className='insights-unavailable'>
          <span>
            <b>{STRINGS.insightsDashboardUnavailable}</b>
          </span>
        </div>
      )
    if (isHolistics && insightsLowFunctionalityMode) {
      overviewListWithVendor = overviewListWithVendor.filter(
        ({ vendor_type }) => vendor_type === INSIGHTS_VENDORS.holistics,
      )
    }
    const sortedArray = overviewListWithVendor.sort((a, b) => a.order - b.order)
    for (let i = 0; i < sortedArray.length; i++) {
      if (keys[sortedArray[i].key]) continue
      const neighbors = []
      for (let j = i + 1; j < sortedArray.length; j++) {
        if (keys[sortedArray[j].key]) continue
        if (parseInt(sortedArray[i].order) === parseInt(sortedArray[j].order)) {
          keys[sortedArray[j].key] = true
          neighbors.push(sortedArray[j])
        }
      }
      if (neighbors.length) groups.push([sortedArray[i], ...neighbors])
      else {
        keys[sortedArray[i].key] = true
        groups.push(sortedArray[i])
      }
    }
    return groups.map((overview, index) => renderOverviews({ overview }, index))
  }, [customButtons, sisenseLoaded, insightsLowFunctionalityMode])

  return loadSisenseDashboards ? (
    <Loader active />
  ) : (
    <div className='insights-dashboard'>
      {hasEditInsightsPermission && (
        <div className='flexed-header'>
          <div />
          <Button
            content='Edit Properties'
            onClick={() => browserHistory.push(`/studies/${studyID}/compliance/insights-permissions`)}
          />
        </div>
      )}
      {renderGroups}
    </div>
  )
}

InsightsDashboard.propTypes = {
  hasEditInsightsPermission: PropTypes.bool,
  insights: PropTypes.shape({
    custom_buttons: PropTypes.shape({}),
    sisense_url: PropTypes.string,
    base_url: PropTypes.string,
  }),
  isStudyAdmin: PropTypes.bool,
  renderSisenseLink: PropTypes.func,
  studyID: PropTypes.string,
}

export default InsightsDashboard
