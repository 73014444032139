import React from 'react'
import PropTypes from 'prop-types'
import { AutocompleteSearch } from 'components/UIElements'

function ParticipantSelection(props) {
  const { disabled, isUpdate, setVisitPtp, participantVisit, resetAdhocSchedule, visitorParticipantLoader } = props
  const { checkedPtp, participantDropdownOptions, selectedParticipant } = participantVisit
  return (
    <div>
      <AutocompleteSearch
        autocomplete='off'
        checked={checkedPtp}
        disabled={disabled || visitorParticipantLoader || isUpdate}
        initialValue={selectedParticipant}
        isDropdown
        list={participantDropdownOptions}
        loading={visitorParticipantLoader}
        placeholder='Search participant ID...'
        id='participant-input'
        shouldReturnItem
        toggleItem={item => {
          setVisitPtp({ participantVal: item.text, ptpId: item.key })
          resetAdhocSchedule()
        }}
      />
    </div>
  )
}

ParticipantSelection.propTypes = {
  disabled: PropTypes.bool,
  setVisitPtp: PropTypes.func,
  visitScheduler: PropTypes.object,
  visitorParticipantLoader: PropTypes.bool,
}

export default ParticipantSelection
