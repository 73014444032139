import React from 'react'
import PropTypes from 'prop-types'
import { Popup, Radio } from 'components/UIElements'
import SiteSignatureTypeConfig from './SiteSignatureTypeConfig'
import '../../stylesheets/siteconsentconfig.scss'
import { getDefaultDigitalSignatures } from '../../modules/Sites'

const SITE_CONFIG_SECTION_LABEL = 'Acceptable signature'
const ACCEPTABLE_SIGNATURE_POPUP_COPY = 'Select the type(s) of signature that this site will accept for consents.'
const SITE_CONSENT_QUESTION_1 = 'Will this site send out electronic consent?'
const SITE_CONSENT_QUESTION_2 = 'Select acceptable type(s) of eSignature.'
const PAPER_ONLY_PROMPT =
  'Please note: When "No" is selected, site admins and/or clinicians will need to manage consent outside of DCH platform. All consent features on DCH Admin Panel will be removed from the site admin and/or clinician\'s DCH account.'

const SiteConsentConfig = props => {
  const { config, disabled, updateSiteConfig } = props
  const { consent_2 } = config
  const hasPaperConsent = !consent_2 || consent_2?.paper_only

  const onSelectElectronicOption = (isPaper = false) => {
    const newConfig = { ...config }
    delete newConfig.consent_2.read_only
    if (isPaper) {
      newConfig.consent_2.paper_only = true
      delete newConfig.consent_2.digital_signatures
    } else {
      delete newConfig.consent_2.paper_only
      newConfig.consent_2.digital_signatures = getDefaultDigitalSignatures()
    }
    updateSiteConfig(newConfig)
  }

  return (
    <div className='site-consent-config'>
      <div className='flexed start-justified center-aligned'>
        <h6 className='label-small'>{SITE_CONFIG_SECTION_LABEL}</h6>
        <Popup hover noPointer position='bottom-right' dark trigger={<i className='fa fa-info-circle' />}>
          <div className='hover-text'>{ACCEPTABLE_SIGNATURE_POPUP_COPY}</div>
        </Popup>
      </div>
      <ol>
        <li>
          <p>{SITE_CONSENT_QUESTION_1}</p>
          <Radio content='Yes' disabled={disabled} selected={!hasPaperConsent} onClick={onSelectElectronicOption} />
          <Radio
            content='No'
            disabled={disabled}
            selected={hasPaperConsent}
            onClick={() => onSelectElectronicOption(true)}
          />
        </li>
        {!hasPaperConsent ? (
          <li>
            <p>{SITE_CONSENT_QUESTION_2}</p>
            <SiteSignatureTypeConfig {...props} />
          </li>
        ) : (
          <div className='alert-note'>{PAPER_ONLY_PROMPT}</div>
        )}
      </ol>
    </div>
  )
}

SiteConsentConfig.propTypes = {
  config: PropTypes.shape({ consent_2: PropTypes.object }),
  disabled: PropTypes.bool,
  updateSiteConfig: PropTypes.func,
}

export default SiteConsentConfig
