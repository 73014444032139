import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'

import CreateStudyConfigurationContainer from '../../CreateStudy/containers/CreateStudyConfigurationContainer'
import { actions } from '../../CreateStudy/modules/CreateStudy'
import { actions as paymentActions } from '../routes/Payments/modules/Payments'

export default store => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params, location } = nextState
        const { studyID } = params

        if (store.getState().study.currentStudy.id !== parseInt(studyID, 10)) {
          store.dispatch(actions.fetchStudy(studyID)).then(study => {
            if (Object.keys(study).length && !location.pathname.endsWith('/payments')) {
              if (study?.config?.super_gems) {
                store.dispatch(paymentActions.fetchPendingGiftcards(params.studyID, false))
              } else {
                store.dispatch(paymentActions.fetchPayments(params.studyID, false))
              }
            }
            cb(
              null,
              UserIsAuthenticated(
                UserHasValidPassword(
                  UserHasScope(['rsc'], CreateStudyConfigurationContainer, false, studyID, null, study),
                ),
              ),
            )
          })
        } else {
          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(UserHasScope(['rsc'], CreateStudyConfigurationContainer, false, studyID)),
            ),
          )
        }
      },
      'create',
    )
  },
})
