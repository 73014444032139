import React from 'react'
import { Button } from 'components/UIElements'
import { INSTRUMENT_TYPES_WITH_TRANSLATIONS } from 'utils/constants'
import InstrumentsViewExportButton from './InstrumentsViewExportButton'
import InstrumentsViewDefaultButtons from './InstrumentsViewDefaultButtons'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from 'utils/constants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'

const InstrumentsViewButtonList = ({
  canWrite,
  ids,
  onDelete,
  onOpenUploadModal,
  onDownloadTranslations,
  onDownloadJSONs,
  hasClinro,
  hasDiary,
  openModal,
  studyLock,
  updateWizardTitle,
  updateWizardDisplayName,
  wizardTitle,
  wizardDisplayName,
  checked,
  study,
  userId,
}) => {
  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  if (ids.length === 0)
    return (
      <InstrumentsViewDefaultButtons
        canWrite={canWrite}
        hasClinro={hasClinro}
        hasDiary={hasDiary}
        openModal={openModal}
        studyID={studyID}
        studyLock={studyLock}
        updateWizardTitle={updateWizardTitle}
        updateWizardDisplayName={updateWizardDisplayName}
        wizardTitle={wizardTitle}
        wizardDisplayName={wizardDisplayName}
        hasInstrumentUnification={hasInstrumentUnification}
      />
    )

  const onlyTypesWithTranslationsChecked = ids.every(id =>
    INSTRUMENT_TYPES_WITH_TRANSLATIONS.includes(checked[id].type),
  )

  const buttons = []

  if (canWrite) {
    buttons.push(<Button key='delete' grey id='delete-instrument' content='Delete' onClick={onDelete()} />)
    if (onlyTypesWithTranslationsChecked && ids.length === 1) {
      buttons.push(
        <Button
          key='upload'
          grey
          id='upload-csv-translation'
          content='Upload CSV with Translation'
          onClick={onOpenUploadModal}
        />,
      )
    }
  }

  if (onlyTypesWithTranslationsChecked)
    buttons.push(
      <InstrumentsViewExportButton
        showTranslationButton={ids?.length > 0}
        onDownloadTranslations={onDownloadTranslations}
        onDownloadJSONs={onDownloadJSONs}
      />,
    )

  return <div className='header-button-list'>{buttons}</div>
}

export default InstrumentsViewButtonList
