import { combineReducers } from 'redux'
import moment from 'moment'
import { actions as notyActions } from 'layouts/ErrorBox'
import { modalActions } from 'store/modal'
import request, { generateNotyMessage } from 'utils/request'
import { loadingActions } from 'store/loader'
import { downloadBlob, getUserRoleByStudy, isSiteAdmin } from 'utils/misc'
import { today } from 'utils/time'
import { CONSENT_STATUS, CONSENT_TYPES, DATE_FORMAT_MAP } from 'utils/constants'
import documents, { documentsActions } from 'store/documents'
import { consentActions as newConsentActions } from '../../ConsentView/routes/NewConsentBuilder/modules/NewConsent'
import { actions as ptpActions } from '../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import { _fetchParticipants } from '../../../../Participants/routes/ParticipantsPage/modules/Participants'

const FAILED_CONSENT_ATTACHMENT_MESSAGE = 'Failed to upload consent attachment'

// Consent
const SET_CONSENT_TAB = 'SET_CONSENT_TAB'
const SET_CONSENT_LIST = 'SET_CONSENT_LIST'
const SET_CONSENT_EVENTS = 'SET_CONSENT_EVENTS'
const RESET_CONSENT_DASHBOARD = 'RESET_CONSENT_DASHBOARD'
const FETCHED_BLOCKED_CONSENTS = 'FETCHED_BLOCKED_CONSENTS'

//
// Action Creators
//

// Consent Actions
export function resetConsentDashboard() {
  return {
    type: RESET_CONSENT_DASHBOARD,
  }
}

function setBlockedConsents(blockedConsents) {
  return {
    type: FETCHED_BLOCKED_CONSENTS,
    blockedConsents,
  }
}

//
// API Actions
//

export const fetchConsents = (studyID, hasLoader = true) => {
  return (dispatch, getState) => {
    const { user, study } = getState()
    const success = jsonBody => {
      const { consents, drafts } = jsonBody
      dispatch({
        type: SET_CONSENT_LIST,
        payload: { consents, drafts, user, study },
      })
    }
    return dispatch(
      request({
        url: `/control/v2/studies/${studyID}/consents`,
        success,
        hasLoader,
        forceLoader: true,
      }),
    )
  }
}

const block_uncompleted_consents = {
  '2e0d06553c9b20a1': ['23946', '24263', '24240', '23533', '23924'],
}

/**
 * Fetch all not completed and blocked consents
 * @param {string} studyID
 * @returns payload object
 */
export function _fetchBlockedConsents(studyID, hasLoader = true) {
  return dispatch => {
    const success = jsonBody => Promise.resolve(jsonBody?.blocking_consents || block_uncompleted_consents)
    return dispatch(
      request({
        url: `/control/v2/studies/${studyID}/consents`,
        success,
        hasLoader,
        forceLoader: true,
      }),
    ).then(payload => Promise.resolve(payload))
  }
}

export const downloadConsentPreview = (data, studyID) => {
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyID}_${data.consent_title}_${today(true)}.pdf`, fileName)
    }
    return dispatch(
      request({
        method: 'GET',
        url: `/control/v2/consent/pdf?consent_id=${data.consentId}&version=${data.version}&platform=admin_panel`,
        resType: 'blob',
        success,
      }),
    )
  }
}

export const downloadNewConsentPreview = ({ studyId, consentId, version }) => {
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyId}_consent_${consentId}_version_${version}_${today(true)}.pdf`, fileName)
    }
    return dispatch(
      request({
        method: 'GET',
        url: `/control/v2/consent/pdf?consent_id=${consentId}&version=${version}&platform=admin_panel`,
        resType: 'blob',
        success,
      }),
    )
  }
}

export function fetchConsentEvents(studyID) {
  return dispatch => {
    const success = json => {
      dispatch({
        type: SET_CONSENT_EVENTS,
        json,
      })
    }
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/consent_events_list`,
        success,
      }),
    )
  }
}

export const downloadCompletedParticipantConsent = ({ studyId, ptpId, consentId }) => {
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyId}_Particiapnt_${ptpId}_Consent_${consentId}.pdf`, fileName)
    }
    return dispatch(
      request({
        url: `/control/v2/studies/${studyId}/participants/${ptpId}/download?consent_id=${consentId}`,
        resType: 'blob',
        success,
      }),
    )
  }
}

const convertURLtoBlob = async url => {
  const blob = await fetch(url).then(res => res.blob())
  return blob
}

const _uploadConsentFile = params => async dispatch => {
  const { ptpId, fileName, fileUrl, version, studyId, consentId, onFail } = params

  const blob = await convertURLtoBlob(fileUrl)

  const file = new File([blob], fileName) // converts the blob to a file to be uploaded

  const fData = new FormData()
  fData.set('upload_file', file)
  fData.set('file_name', fileName)
  fData.set('tag_name', 'consent_attachment')
  fData.set('participant_id', ptpId)
  if (version) fData.set('version', version)

  const success = () => Promise.resolve()

  const fail = (res, content) => {
    onFail()
    dispatch(
      notyActions.showError({
        text: generateNotyMessage(content?.message || FAILED_CONSENT_ATTACHMENT_MESSAGE),
      }),
    )
  }

  return dispatch(
    request({
      method: 'POST',
      url: `/control/v2/studies/${studyId}/consents/${consentId}/attachments`,
      contentType: 'multipart/form-data',
      body: fData,
      success,
      fail,
    }),
  )
}

const completeConsent = ({
  consentId,
  ptpId,
  studyId,
  version,
  onSuccess = () => {},
  onFail = () => {},
}) => dispatch => {
  const versionSuffix = version ? `?version=${version}` : ''
  const success = () => {
    onSuccess()
    Promise.resolve()
  }
  const fail = (response, content) => {
    onFail()
    dispatch(
      notyActions.showError({
        text: generateNotyMessage(content?.message || 'Failed to update participant'),
      }),
    )
    Promise.reject()
  }

  return dispatch(
    request({
      method: 'PATCH',
      url: `/control/v2/admin/studies/${studyId}/participants/${ptpId}/consents/${consentId}${versionSuffix}`,
      success,
      fail,
    }),
  )
}

//
// Util Functions
//
const formatConsentList = payload => {
  const { consents, drafts, user, study } = payload
  const consentList = {}
  const userRole = getUserRoleByStudy(user, study.currentStudy.id)
  const isUserSiteAdmin = isSiteAdmin(userRole)
  consentList.scheduled = Object.keys(consents).map(consentId => {
    const item = consents[consentId]
    const {
      consent_title,
      consent_type,
      cohort,
      num_deployed,
      num_completed,
      version,
      updated,
      deployed,
      metadata,
    } = item
    const { sites, study_group } = cohort
    const lastEdited = moment(updated)
    const timeSent = moment(deployed)
    const notRespondedLabel = 'Unopened'
    const respondedLabel = 'Received'

    const draft = drafts[consentId]

    const row = [
      { key: 'name', value: consent_title, otherLanguages: metadata.other_languages, sortValue: consent_title },
      { key: 'type', value: CONSENT_TYPES[consent_type], sortValue: consent_type, deployed: !!deployed },
      { key: 'version', value: version, sortValue: version },
      { key: 'sites', value: sites || '-', sortValue: sites === undefined ? null : sites },
      { key: 'tracks', value: study_group || '-', sortValue: study_group === undefined ? null : study_group },
      {
        key: 'consentStatus',
        value: {
          notCompleted: num_deployed - num_completed,
          total: num_deployed,
          completed: num_completed,
          notRespondedLabel,
          respondedLabel,
        },
        sortValue: num_completed ? num_deployed / num_completed : 0,
      },
      {
        key: 'lastEdited',
        value: updated ? lastEdited.format(DATE_FORMAT_MAP.mainWithDateTime) : 'N/A',
        sortValue: updated ? lastEdited.valueOf() : 0,
      },
      {
        key: 'timeSent',
        value: deployed ? timeSent.format(DATE_FORMAT_MAP.mainWithDateTime) : 'N/A',
        sortValue: timeSent.valueOf(),
      },
      { key: 'action', consentId, consent_type, consent_title, deployed: !!deployed, version, draft },
    ]
    if (isUserSiteAdmin) {
      return row.filter(content => content.key !== 'sites')
    }
    return row
  })

  consentList.drafts = Object.keys(drafts).map(draftId => {
    const item = drafts[draftId]
    const { consent_title, consent_type, consent_status, cohort, version, updated, deployed, metadata } = item
    const { sites, study_group } = cohort
    const lastEdited = moment(updated)
    const readyToSchedule = consent_status === CONSENT_STATUS.readyToBeScheduled.key
    const draftStatus =
      consent_status === CONSENT_STATUS.readyToBeScheduled.key
        ? CONSENT_STATUS.readyToBeScheduled.component
        : consent_status === CONSENT_STATUS.inProgress.key
        ? CONSENT_STATUS.inProgress.component
        : null
    const row = [
      { key: 'name', value: consent_title, otherLanguages: metadata.other_languages, sortValue: consent_title },
      { key: 'type', value: CONSENT_TYPES[consent_type], sortValue: consent_type, deployed: !!deployed },
      { key: 'version', value: version, sortValue: version },
      { key: 'sites', value: sites || '-', sortValue: sites === undefined ? null : sites },
      { key: 'tracks', value: study_group || '-', sortValue: study_group === undefined ? null : study_group },
      {
        key: 'lastEdited',
        value: updated ? lastEdited.format(DATE_FORMAT_MAP.mainWithDateTime) : 'N/A',
        sortValue: updated ? lastEdited.valueOf() : 0,
      },
      { key: 'draftStatus', value: draftStatus, sortValue: null },
      { key: 'action', value: '', sortValue: null, readyToSchedule, deployed: !!deployed, version, consentId: draftId },
    ]
    if (isUserSiteAdmin) {
      return row.filter(content => content.key !== 'sites')
    }
    return row
  })
  return consentList
}

export const uploadPtpConsentAttachments = ({
  consentId,
  isParticipant = false,
  ptpId,
  rowIdx,
  studyId,
  version,
  ptpSiteId,
}) => (dispatch, getState) => {
  let documentsToUpload
  const { consents, participantReducer } = getState()

  if (isParticipant) {
    documentsToUpload = participantReducer.documents[rowIdx]
  } else {
    documentsToUpload = consents.documents[ptpId]
  }

  const failedUploads = {}
  const addToFailedUploads = (fileName, fileUrl) => {
    failedUploads[fileName] = fileUrl
  }
  const failedCompletions = {}
  const addToFailedCompletions = () => {
    if (isParticipant) failedCompletions[rowIdx] = true
    else failedCompletions[ptpId] = true
  }

  if (!documentsToUpload?.length && isParticipant) {
    return Promise.resolve()
  }

  const stopLoader = () => dispatch(loadingActions.stopLoader(true, 'consentAttachment'))

  return new Promise((resolve, reject) => {
    const completionOnFail = () => {
      stopLoader()
      addToFailedCompletions()
      return reject({ failedCompletions })
    }
    dispatch(loadingActions.startLoader(true, 'consentAttachment'))
    if (!documentsToUpload?.length) {
      const onSuccess = () => {
        resolve({ markedAsComplete: true })
        stopLoader()
      }
      return dispatch(
        completeConsent({
          consentId,
          ptpId,
          studyId,
          version,
          isParticipant,
          onSuccess,
          onFail: completionOnFail,
        }),
      )
    }
    const uploadRequests = documentsToUpload.map(document => {
      const { file_name: fileName, upload_file } = document
      const fileUrl = upload_file
      return dispatch(
        _uploadConsentFile({
          fileName,
          fileUrl,
          studyId,
          consentId,
          ptpId,
          onFail: () => {
            addToFailedUploads(fileName, fileUrl)
          },
        }),
      )
    })

    Promise.allSettled(uploadRequests).then(() => {
      if (Object.keys(failedUploads).length > 0) {
        stopLoader()
        return reject({ failedUploads })
      }
      const onSuccess = () => {
        stopLoader()
        if (isParticipant) {
          dispatch(
            notyActions.showSuccess({
              text: generateNotyMessage('Successfully marked selected participant as complete'),
            }),
          )
          dispatch(ptpActions.fetchParticipant(studyId, ptpSiteId, ptpId))
        } else {
          resolve({ markedAsComplete: true })
        }
      }
      return dispatch(
        completeConsent({
          consentId,
          ptpId,
          studyId,
          version,
          isParticipant,
          onSuccess,
          onFail: completionOnFail,
        }),
      )
    })
  })
}

export const uploadConsentAttachments = ({ consentId, studyId, checkedPtps, version }) => async dispatch => {
  const countPtps = checkedPtps.length
  const uploadRequests = checkedPtps.map(ptpId => {
    return dispatch(uploadPtpConsentAttachments({ consentId, studyId, ptpId, version }))
  })
  const uploadResults = await Promise.allSettled(uploadRequests)
  const sumRejected = uploadResults.reduce((count, result) => (result.status === 'rejected' ? count + 1 : count), 0)

  dispatch(loadingActions.startLoader(true, 'markConsentAsComplete'))
  dispatch(newConsentActions.resetConsentDashboard())
  dispatch(documentsActions.resetDocs())

  if (sumRejected < countPtps) {
    dispatch(
      notyActions.showSuccess({
        text: generateNotyMessage(
          `Successfully marked selected participant${
            countPtps > 1 && countPtps - sumRejected > 1 ? 's' : ''
          } as complete`,
        ),
      }),
    )
  } else {
    dispatch(
      notyActions.showError({
        text: generateNotyMessage(`Could not update any selected participant${countPtps > 1 ? 's' : ''}`),
      }),
    )
  }
  dispatch(loadingActions.stopLoader(true, 'markConsentAsComplete'))
  dispatch(
    newConsentActions.fetchNewConsent({
      consentId,
      getJson: false,
      studyId,
      version,
    }),
  )
  dispatch(fetchConsents(studyId, false))
}

export const fetchBlockedConsents = studyID => async dispatch => {
  dispatch(loadingActions.startLoader(true))
  const blockedConsents = await dispatch(_fetchBlockedConsents(studyID))

  const blockedConsentsList = Object.keys(blockedConsents || {})

  if (blockedConsentsList.length > 0) {
    const participants = await dispatch(_fetchParticipants(studyID))

    const ptpsWithBlockedConsents = Object.keys(participants || {}).filter(
      ptpID => participants[ptpID]?.blocking_consents.length > 0,
    )

    const blockedPtpWithConsents = {}

    blockedConsentsList.forEach(consentID => {
      ptpsWithBlockedConsents.forEach(ptpID => {
        if (participants[ptpID]?.blocking_consents.some(({ consent_id }) => consentID === consent_id)) {
          const _blockedPtpWithConsents = blockedPtpWithConsents[consentID] || []
          blockedPtpWithConsents[consentID] = [..._blockedPtpWithConsents, ptpID]
        }
      })
    })

    dispatch(setBlockedConsents(blockedPtpWithConsents))
  } else {
    dispatch(setBlockedConsents({}))
  }

  dispatch(loadingActions.stopLoader(true))
}

//
// Reducers
//

const consentList = (state = {}, action) => {
  switch (action.type) {
    case SET_CONSENT_LIST:
      return formatConsentList(action.payload)
    default:
      return state
  }
}

const unCompletedBlockedConsents = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_BLOCKED_CONSENTS:
      return action.blockedConsents
    default:
      return state
  }
}

export default combineReducers({
  consentList,
  documents,
  unCompletedBlockedConsents,
})

export const consentActions = {
  downloadConsentPreview,
  downloadNewConsentPreview,
  downloadCompletedParticipantConsent,
  fetchBlockedConsents,
  fetchConsentEvents,
  openModal: modalActions.openModal,
  resetConsentDashboard,
  uploadConsentAttachments,
  uploadPtpConsentAttachments,
  ...documentsActions,
}
