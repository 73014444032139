import React from 'react'
import PropTypes from 'prop-types'
import { giftCardIcon } from 'assets/assets'
import { Button, Popup } from 'components/UIElements'
import { STUDY_CONFIG_MAP } from 'utils/constants'

const RewardGiftCardItem = props => {
  const { readOnly, item, cycleNum, giftCardIndex, updateGiftCard, updateGiftCardForUIObj, cycleArr } = props
  const { value: cardText, isMinigame = false, show_preview: showPreview = true, id } = item
  const cardInfo = isMinigame ? 'Minigame' : `$${cardText} Gift card`

  const hasOneCycle = cycleArr.length === 1

  return (
    <div className={`gift-card-item item-container${readOnly ? ' read-only' : ''}`}>
      {readOnly || hasOneCycle ? (
        <div />
      ) : (
        <div className='six-dots'>
          <i className='fas fa-ellipsis-v' />
          <i className='fas fa-ellipsis-v' />
        </div>
      )}
      <div className='big-card-info'>
        <div className='big-card'>
          <h5>{`$${cardText}`}</h5>
          <div className='rectangle' />
        </div>
        <div className='card-info'>
          <h6>{cardInfo}</h6>
          <div className='little-card'>
            <div className='gift-card-pay-icon'>
              {isMinigame ? <i className='fas fa-gamepad' /> : giftCardIcon('#545454')}
            </div>
            <p>{isMinigame ? 'Minigame' : 'Payment'}</p>
          </div>
        </div>
      </div>
      <div className='show-info'>
        <div className='eyes-container'>
          <i className={`fas fa-eye${!showPreview ? '-slash' : ''}`} />
          <h6>{!showPreview ? 'Hidden until available' : 'Preview is shown'}</h6>
        </div>
        {!readOnly && (
          <Button
            className='show-info-btn'
            content={showPreview ? 'Hide' : 'Show'}
            link
            onClick={() => {
              updateGiftCard({
                cycle: cycleNum,
                giftCardIndex,
                id,
                key: STUDY_CONFIG_MAP.showPreview,
                value: !showPreview,
              })
            }}
          />
        )}
      </div>
      <div />
      {readOnly || hasOneCycle ? (
        <div />
      ) : (
        <Popup
          className='version-dropdown'
          position='bottom xd'
          noPointer
          trigger={<Button className='three-dots-btn noStyling' link icon='fas fa-ellipsis-h' />}>
          <p className='label-small'>MOVE TO...</p>
          {cycleArr
            .filter(cycle => cycle !== cycleNum)
            .map((targetCycle, key) => (
              <Button
                noStyling
                key={key}
                className='cycle-popup flexed start-justified'
                onClick={() => {
                  // update giftCard's cycle_availability field
                  item.cycle_availability = parseInt(targetCycle, 10)
                  updateGiftCardForUIObj({
                    giftCard: item,
                    sourceCycle: cycleNum,
                    targetCycle,
                    oldIndex: giftCardIndex,
                  })
                  updateGiftCard({
                    id,
                    key: 'cycle_availability',
                    value: parseInt(targetCycle, 10),
                  })
                }}>{`Chapter ${targetCycle}`}</Button>
            ))}
        </Popup>
      )}
    </div>
  )
}

export default RewardGiftCardItem

RewardGiftCardItem.propTypes = {
  giftCardIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  updateGiftCard: PropTypes.func,
  updateGiftCardForUIObj: PropTypes.func,
  cycleArr: PropTypes.arrayOf(PropTypes.string),
  item: PropTypes.shape({
    cycle_availability: PropTypes.number,
    id: PropTypes.string,
    isMinigame: PropTypes.bool,
    show_preview: PropTypes.bool,
    value: PropTypes.number,
  }),
  cycleNum: PropTypes.string,
}
