import { combineReducers } from 'redux'
import { loadingActions } from 'store/loader'
import { EditorState } from 'draft-js'
import moment from 'moment'
import {
  DEFAULT_NA_STRING_SORT_VAL,
  VISIT_INFO_TYPE_MAP,
  VISIT_TIMELINE_EVENT_MAP,
  VISIT_NOTY_TYPE_MAP,
  DATE_FORMAT_MAP,
  DEFAULT_LANG,
  STATUS_OPTIONS,
} from 'utils/constants'
import request, { generateNotyMessage } from 'utils/request'
import { actions as notyActions } from 'layouts/ErrorBox'
import { HTMLToEditorState } from 'utils/draft'
import { downloadBlob } from 'utils/misc'
import { groupByDate, getFormattedTimeString, generateMultiUnitDifference, getFormattedDateString } from 'utils/time'

//
// Actions
//

const VISIT_SCHEDULE_EVENT_TEXT_MAP = {
  cancelled: 'Cancelled',
  createdDate: 'Created',
  virtualVisitBegins: 'Virtual Visit Begins',
  virtualVisitEnds: 'Virtual Visit Ends',
}

const SET_SCHEDULE_VISITS_HISTORY = 'SET_SCHEDULE_VISITS_HISTORY'
const SET_SCHEDULE_VISITS_UPCOMING = 'SET_SCHEDULE_VISITS_UPCOMING'
const SET_SCHEDULE_VISITS_PREVIOUS = 'SET_SCHEDULE_VISITS_PREVIOUS'
export const SET_SCHEDULE_VISIT_TEMPLATES = 'SET_SCHEDULE_VISIT_TEMPLATES'
const SET_SCHEDULE_VISIT_INFO = 'SET_SCHEDULE_VISIT_INFO'
const SET_SCHEDULE_VISIT_UPCOMING_INFO = 'SET_SCHEDULE_VISIT_UPCOMING_INFO'
const SET_SCHEDULE_VISIT_PREVIOUS_INFO = 'SET_SCHEDULE_VISIT_PREVIOUS_INFO'
const TOGGLE_SCHEDULE_VISIT_EXPAND = 'TOGGLE_SCHEDULE_VISIT_EXPAND'
const TOGGLE_SCHEDULE_UPCOMING_VISIT_EXPAND = 'TOGGLE_SCHEDULE_UPCOMING_VISIT_EXPAND'
const TOGGLE_SCHEDULE_PREVIOUS_VISIT_EXPAND = 'TOGGLE_SCHEDULE_PREVIOUS_VISIT_EXPAND'
const TOGGLE_VISIT_TEMPLATE = 'TOGGLE_VISIT_TEMPLATE'
const RESET_CHECKED_VISIT_TEMPLATES = 'RESET_CHECKED_VISIT_TEMPLATES'
const TOGGLE_CHECK_ALL_VISIT_TEMPLATES = 'TOGGLE_CHECK_ALL_VISIT_TEMPLATES'
const RESET_SCHEDULE_EXPANDED_VISITS = 'RESET_SCHEDULE_EXPANDED_VISITS'
const RESET_SCHEDULE_UPCOMING_EXPANDED_VISITS = 'RESET_SCHEDULE_UPCOMING_EXPANDED_VISITS'
const RESET_SCHEDULE_PREVIOUS_EXPANDED_VISITS = 'RESET_SCHEDULE_PREVIOUS_EXPANDED_VISITS'
const TOGGLE_SCHEDULE_TRANSLATIONS = 'TOGGLE_SCHEDULE_TRANSLATIONS'
const ADD_SCHEDULE_TRANSLATION = 'ADD_SCHEDULE_TRANSLATION'
const DELETE_SCHEDULE_TRANSLATION = 'DELETE_SCHEDULE_TRANSLATION'
const UPDATE_SCHEDULE_TRANSLATION = 'UPDATE_SCHEDULE_TRANSLATION'
const SET_SCHEDULE_TRANSLATIONS = 'SET_SCHEDULE_TRANSLATIONS'

//
// Action Creators
//

const setScheduleVisitInfo = (payload, visitSchedulesId) => {
  return {
    type: SET_SCHEDULE_VISIT_INFO,
    payload,
    visitSchedulesId,
  }
}

const setScheduleVisitUpcomingInfo = (payload, visitSchedulesId) => {
  return {
    type: SET_SCHEDULE_VISIT_UPCOMING_INFO,
    payload,
    visitSchedulesId,
  }
}

const setScheduleVisitPreviousInfo = (payload, visitSchedulesId) => {
  return {
    type: SET_SCHEDULE_VISIT_PREVIOUS_INFO,
    payload,
    visitSchedulesId,
  }
}

const setScheduleVisitsUpcoming = ({ upcoming_visits }) => {
  return {
    type: SET_SCHEDULE_VISITS_UPCOMING,
    payload: upcoming_visits,
  }
}

const setScheduleVisitsPrevious = ({ previous_visits }) => {
  return {
    type: SET_SCHEDULE_VISITS_PREVIOUS,
    payload: previous_visits,
  }
}

export const setScheduleVisitTemplates = ({ study_visit_schedule }) => {
  return {
    type: SET_SCHEDULE_VISIT_TEMPLATES,
    payload: study_visit_schedule,
  }
}

const toggleScheduleHistoryVisitExpand = visitSchedulesId => {
  return {
    type: TOGGLE_SCHEDULE_VISIT_EXPAND,
    visitSchedulesId,
  }
}

const toggleScheduleUpcomingVisitExpand = visitSchedulesId => {
  return {
    type: TOGGLE_SCHEDULE_UPCOMING_VISIT_EXPAND,
    visitSchedulesId,
  }
}

const toggleSchedulePreviousVisitExpand = visitSchedulesId => {
  return {
    type: TOGGLE_SCHEDULE_PREVIOUS_VISIT_EXPAND,
    visitSchedulesId,
  }
}

const resetScheduleHistoryExpandedVisits = () => {
  return {
    type: RESET_SCHEDULE_EXPANDED_VISITS,
  }
}

const resetScheduleUpcomingExpandedVisits = () => {
  return {
    type: RESET_SCHEDULE_UPCOMING_EXPANDED_VISITS,
  }
}

const resetSchedulePreviousExpandedVisits = () => {
  return {
    type: RESET_SCHEDULE_PREVIOUS_EXPANDED_VISITS,
  }
}

const toggleVisitTemplate = id => {
  return {
    type: TOGGLE_VISIT_TEMPLATE,
    id,
  }
}

const toggleCheckAllVisitTemplates = (visits, list) => {
  return {
    type: TOGGLE_CHECK_ALL_VISIT_TEMPLATES,
    visits,
    list,
  }
}

const resetCheckedVisitTemplates = () => {
  return {
    type: RESET_CHECKED_VISIT_TEMPLATES,
  }
}

const toggleScheduleTranslations = localeId => {
  return {
    type: TOGGLE_SCHEDULE_TRANSLATIONS,
    localeId,
  }
}

const addScheduleTranslation = localeId => {
  return {
    type: ADD_SCHEDULE_TRANSLATION,
    localeId,
  }
}

const updateScheduleTranslation = ({ key, localeId, value }) => {
  return {
    type: UPDATE_SCHEDULE_TRANSLATION,
    key,
    localeId,
    value,
  }
}

const deleteScheduleTranslation = localeId => {
  return {
    type: DELETE_SCHEDULE_TRANSLATION,
    localeId,
  }
}

const setScheduleTranslations = translations => {
  return {
    type: SET_SCHEDULE_TRANSLATIONS,
    translations,
  }
}

const _getDefaultTranslations = localeId => {
  return {
    [localeId]: {
      title: '',
      content: EditorState.createEmpty(),
    },
  }
}
const _getDefaultTranslation = () => {
  return {
    title: '',
    content: EditorState.createEmpty(),
  }
}

//
// Utils
//

const formatScheduleVisitsHistoryList = visitSchedulesHistoryList => {
  return visitSchedulesHistoryList.map(
    ({
      fname,
      id,
      is_recorded,
      leaf_site_id,
      leaf_site_name,
      lname,
      nickname,
      participant_id,
      status,
      subject_id,
      user_id,
      visit_time,
      visit_name,
      visits,
    }) => {
      const visitTime = moment.utc(visit_time)
      const row = [
        { key: 'checkbox', value: id, sortValue: 'z' },
        {
          key: 'participantName',
          value: `${fname} ${lname}`,
          sortValue: lname,
          additionalInfo: [`Participant ID ${participant_id}`, `Subject ID ${subject_id || '--'}`],
          visitId: id,
          status,
          isRecorded: is_recorded,
        },
        { key: 'ptpId', value: participant_id, sortValue: participant_id },
        { key: 'visitName', value: visit_name, sortValue: visit_name },
        { key: 'clinicianName', value: nickname, sortValue: nickname, additionalInfo: [`Clinician ID ${user_id}`] },
        { key: 'clinicianId', value: user_id, sortValue: user_id },
        { key: 'visits', value: visits, sortValue: visits },
        { key: 'status', value: status, sortValue: status },
        {
          key: 'date',
          value: `${visitTime.format(DATE_FORMAT_MAP.mainWithDateTime)} UTC`,
          sortValue: visitTime.valueOf(),
        },
      ]
      row.siteId = leaf_site_id
      row.leafSiteName = leaf_site_name
      return row
    },
  )
}

const formatScheduleVisitsUpcomingList = visitSchedulesUpcomingList => {
  return visitSchedulesUpcomingList.map(rowProps => {
    const {
      id,
      participant_id,
      visit_datetime,
      visit_day,
      name,
      fname = '',
      lname = '',
      subject_id = '',
      is_adhoc,
      participant_visit_metadata,
    } = rowProps

    const { visit_name } = participant_visit_metadata
    const dateTime = moment(visit_datetime)
    const visitName = visit_name || name
    const ptpName = `${fname}${lname ? ` ${lname}` : ''}` || `Participant ${participant_id}`

    let visitDayVal = visit_day
    let visitDaySortVal = visit_day
    const hasNewVisitDay = 'new_visit_day' in participant_visit_metadata
    if (is_adhoc) {
      visitDayVal = 'N/A'
      visitDaySortVal = -1
    } else if (hasNewVisitDay) {
      const { new_visit_day } = participant_visit_metadata
      visitDayVal = new_visit_day
      visitDaySortVal = new_visit_day
    }

    const row = [
      { key: 'participantName', value: ptpName, sortValue: ptpName, className: 'participant-name-data' },
      { key: 'ptpId', value: participant_id, sortValue: participant_id },
      { key: 'subjectId', value: subject_id, sortValue: subject_id || DEFAULT_NA_STRING_SORT_VAL },
      { key: 'visitName', value: visitName, sortValue: visitName || DEFAULT_NA_STRING_SORT_VAL },
      { key: 'visitDay', value: visitDayVal, sortValue: visitDaySortVal },
      { key: 'date', value: dateTime.format(DATE_FORMAT_MAP.main), sortValue: dateTime.valueOf() },
      { key: 'action', value: id, sortValue: -1 },
    ]
    row.id = id
    return row
  })
}

const formatScheduleVisitsPreviousList = visitSchedulesPreviousList => {
  return visitSchedulesPreviousList.map(rowProps => {
    const {
      is_update_allowed = true,
      id,
      participant_id,
      visit_datetime,
      actual_visit_datetime,
      visit_day,
      name,
      fname = '',
      lname = '',
      subject_id = '',
      is_adhoc,
      participant_visit_metadata,
      status = '',
    } = rowProps
    const dateTime = moment(visit_datetime)
    const actualDateTime = moment(actual_visit_datetime)

    let visitDayVal = visit_day
    let visitDaySortVal = visit_day
    const hasNewVisitDay = 'new_visit_day' in participant_visit_metadata
    if (is_adhoc) {
      visitDayVal = 'N/A'
      visitDaySortVal = -1
    } else if (hasNewVisitDay) {
      const { new_visit_day } = participant_visit_metadata
      visitDayVal = new_visit_day
      visitDaySortVal = new_visit_day
    }

    const visitName = participant_visit_metadata?.visit_name || name
    const ptpName = `${fname}${lname ? ` ${lname}` : ''}` || `Participant ${participant_id}`
    const className = status !== STATUS_OPTIONS.Missed && status !== STATUS_OPTIONS.Confirmed ? 'confirm-occurence' : ''
    const row = [
      {
        key: 'participantName',
        value: ptpName,
        sortValue: ptpName,
        className: `participant-name-data ${className}`,
      },
      { key: 'ptpId', value: participant_id, sortValue: participant_id, className },
      { key: 'subjectId', value: subject_id, sortValue: subject_id || DEFAULT_NA_STRING_SORT_VAL, className },
      { key: 'visitName', value: visitName, sortValue: visitName || DEFAULT_NA_STRING_SORT_VAL, className },
      { key: 'visitDay', value: visitDayVal, sortValue: visitDaySortVal, className },
      { key: 'date', value: dateTime.format(DATE_FORMAT_MAP.main), sortValue: dateTime.valueOf(), className },
      {
        key: 'actualDate',
        value: actual_visit_datetime ? actualDateTime.format(DATE_FORMAT_MAP.main) : 'N/A',
        sortValue: actual_visit_datetime ? actualDateTime.valueOf() : -1,
        className,
      },
      { key: 'status', value: status || 'confirm-occurence', sortValue: status || '', className },
      { key: 'action', value: id, sortValue: -1, className, allowStatusUpdate: is_update_allowed },
    ]
    row.id = id
    row.className = className
    return row
  })
}

const formatScheduleVisitTemplatesList = visitSchedulesTemplatesList =>
  visitSchedulesTemplatesList
    .map(({ visits, template_name, id: visitScheduleId }) => {
      return visits.length > 0
        ? visits.map(({ id, name, visit_day }) => {
            const row = [
              { key: 'visitName', value: name, sortValue: name, className: 'visit-template-name-cell' },
              {
                key: 'visitDay',
                value: visit_day,
                sortValue: visit_day,
              },
              { key: 'action', value: id, sortValue: '' },
            ]
            row.id = id
            row.templateName = template_name
            row.parentId = visitScheduleId
            return row
          })
        : Array.from([visitScheduleId], val => {
            const row = [
              {
                noData: 'No visit schedule created.',
              },
            ]
            row.parentId = val
            row.templateName = template_name
            return row
          })
    })
    .flat()

const getScheduledVisitString = (duration, visitTime) => {
  const dateMoment = moment.utc(visitTime)
  const dateMomentClone = dateMoment.clone()
  dateMomentClone.add(duration, 'minutes')

  /**
   * This creates a time string with duration that looks like:
   * "23 04 2020 7:05 am - 7:35pm (30 min)"
   * */

  return `${dateMoment.format(DATE_FORMAT_MAP.mainWithDateTime)} - ${dateMomentClone.format(
    'h:mm a',
  )} (${duration} min)`
}

const formatScheduleEvent = timelineEvent => {
  const { duration, event_type, is_adhoc, is_admin, visitor_name, visitors, start_time, timestamp } = timelineEvent
  const resultEventObj = {
    timestamp,
    time: getFormattedTimeString(timestamp),
    // TODO: time: generateDateStringWithTimeZone(timestamp, true),
  }
  let participantName
  let clinicianName

  if (visitors) {
    visitors.forEach(visitor => {
      if (visitor.is_admin) clinicianName = visitor.visitor_name
      else participantName = visitor.visitor_name
    })
  }

  switch (event_type) {
    case VISIT_TIMELINE_EVENT_MAP.createdDate:
      if (is_adhoc) resultEventObj.event = { text: `Created & Entered: ${visitor_name}` }
      else {
        resultEventObj.event = {
          text: VISIT_SCHEDULE_EVENT_TEXT_MAP.createdDate,
          additionalInfo: [getScheduledVisitString(duration, start_time)],
        }
        if (visitors)
          resultEventObj.event.additionalInfo.push(`Participant ${participantName} and Clinician ${clinicianName}`)
      }
      break
    case VISIT_TIMELINE_EVENT_MAP.cancelled:
      resultEventObj.event = { text: VISIT_SCHEDULE_EVENT_TEXT_MAP.cancelled }
      break
    case VISIT_TIMELINE_EVENT_MAP.virtualVisitBegins:
      resultEventObj.event = { text: VISIT_SCHEDULE_EVENT_TEXT_MAP.virtualVisitBegins, isStartOrEnd: true }
      break
    case VISIT_TIMELINE_EVENT_MAP.virtualVisitEnds:
      resultEventObj.event = { text: VISIT_SCHEDULE_EVENT_TEXT_MAP.virtualVisitEnds, isStartOrEnd: true }
      break
    case VISIT_TIMELINE_EVENT_MAP.ptpConnected:
      if (is_admin) resultEventObj.event = { text: `Entered: Clinician ${visitor_name}`, clinicianConnected: true }
      else resultEventObj.event = { text: `Entered: Participant ${visitor_name}`, participantConnected: true }
      break
    case VISIT_TIMELINE_EVENT_MAP.ptpDisconnected:
      if (is_admin) resultEventObj.event = { text: `Left: Clinician ${visitor_name}` }
      else resultEventObj.event = { text: `Left: Participant ${visitor_name}` }
      break
    default:
      break
  }
  return resultEventObj
}

const formaScheduletInfo = (info, infoType) => {
  const resInfo = {}
  switch (infoType) {
    case VISIT_INFO_TYPE_MAP.type:
      if (info.is_adhoc) {
        resInfo.heading = `Adhoc on ${getFormattedDateString(info.created, DATE_FORMAT_MAP.mainWithDateTime, true)}`
        const clinicians = []
        const { visitors } = info
        if (visitors) {
          visitors.forEach(visitor => {
            const { is_admin, nickname, id } = visitor
            if (is_admin) {
              clinicians.push(`${nickname}, Clinician ${id}`)
            }
          })
          resInfo.content = [{ text: `Clinicians (${clinicians.length})`, list: clinicians }]
        }
      } else {
        const { created, cancelled_time, start_time } = info
        resInfo.heading = `Scheduled for ${getFormattedDateString(start_time, DATE_FORMAT_MAP.mainWithDateTime, true)}`
        resInfo.content = []
        if (created) {
          resInfo.content.push({
            text: `Created on ${getFormattedDateString(created, DATE_FORMAT_MAP.mainWithDateTime, true)}`,
          })
        }
        if (cancelled_time) {
          resInfo.content.push({
            text: `Cancelled on ${getFormattedDateString(cancelled_time, DATE_FORMAT_MAP.mainWithDateTime, true)}`,
          })
        }
      }
      break
    case VISIT_INFO_TYPE_MAP.length: {
      const { start_time, end_time } = info
      const { string } = generateMultiUnitDifference(start_time, end_time)
      resInfo.heading = `Length (${string})`
      resInfo.content = [{ text: `From ${getFormattedTimeString(start_time)} to ${getFormattedTimeString(end_time)}` }]
      break
    }
    case VISIT_INFO_TYPE_MAP.instrumentsCompleted: {
      const numInstrumentsCompleted = info.length
      resInfo.heading = `Instruments Completed (${numInstrumentsCompleted})`
      resInfo.content = info.map(instrumentObj => {
        const { title } = instrumentObj
        return { text: title }
      })
      break
    }
    case VISIT_INFO_TYPE_MAP.notifications:
    default: {
      let notificationCount = 0
      const { admin, participant } = info
      const adminNotificationObj = {}
      const hasAdminNoty = admin.length > 0
      const hasPtpNoty = participant.length > 0
      if (hasAdminNoty) {
        adminNotificationObj.text = 'Clinician'
        adminNotificationObj.list = admin.map(noty => {
          const { type, timestamp } = noty
          notificationCount += 1
          return `${VISIT_NOTY_TYPE_MAP[type] || 'Notification'} sent at ${getFormattedDateString(
            timestamp,
            DATE_FORMAT_MAP.mainWithDateTime,
            true, // last boolean param is to specify to use UTC
          )}`
        })
      }
      const ptpNotificationObj = {}
      if (hasPtpNoty) {
        ptpNotificationObj.text = 'Participant'
        ptpNotificationObj.list = participant.map(noty => {
          const { type, timestamp } = noty
          notificationCount += 1
          return `${VISIT_NOTY_TYPE_MAP[type] || 'Notification'} sent at ${getFormattedDateString(
            timestamp,
            DATE_FORMAT_MAP.mainWithDateTime,
            true,
          )}`
        })
      }
      resInfo.heading = `Notifications Sent (${notificationCount})`
      resInfo.content = []
      if (hasAdminNoty) resInfo.content.push(adminNotificationObj)
      if (hasPtpNoty) resInfo.content.push(ptpNotificationObj)
    }
  }
  return resInfo
}

const generateScheduleFormatAdditionalInfo = additionalInfo => {
  const resultAdditionalInfo = {}
  const infoKeys = Object.keys(additionalInfo)
  infoKeys.forEach(infoKey => {
    resultAdditionalInfo[infoKey] = formaScheduletInfo(additionalInfo[infoKey], infoKey)
  })
  return resultAdditionalInfo
}

const formatScheduleVisitInfo = payload => {
  const result = { ...payload }
  const { timeline, additional_info, download_links } = payload
  const resTimeline = timeline.map(event => formatScheduleEvent(event))
  const resAddInfo = generateScheduleFormatAdditionalInfo(additional_info)
  const groupedTimeline = groupByDate(resTimeline, 'timestamp') // This creates an object of timeline event arrays grouped by date
  result.timeline = groupedTimeline
  result.additionalInfo = resAddInfo
  result.downloadLinks = download_links || []
  delete result.additional_info
  delete result.message
  delete result.download_links
  return result
}

// Fetch functions

export const _fetchScheduleVisitsUpcoming = (studyId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    onSuccess(payload)
  }

  return dispatch(
    request({
      url: `/control/studies/${studyId}/upcoming_visits`,
      success,
      hasLoader: true,
      loadingKey: 'visitSchedules',
    }),
  )
}

export const _fetchScheduleVisitsPrevious = (studyId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    onSuccess(payload)
  }

  return dispatch(
    request({
      url: `/control/studies/${studyId}/previous_visits`,
      success,
      hasLoader: true,
      loadingKey: 'visitSchedules',
    }),
  )
}

export const _updatePreviousScheduleVisit = (studyId, ssvId, ptpId, body, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    onSuccess(payload)
  }

  return dispatch(
    request({
      method: 'PATCH',
      url: `/control/studies/${studyId}/study_schedules_visits/${ssvId}/participants/${ptpId}`,
      success,
      hasLoader: true,
      loadingKey: 'visitSchedules',
      body: JSON.stringify(body),
    }),
  )
}

export const _fetchVisitSchedules = (studyId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    onSuccess(payload)
  }
  return dispatch(
    request({
      url: `/control/studies/${studyId}/study_schedules`,
      success,
      hasLoader: true,
      loadingKey: 'visitSchedules',
    }),
  )
}

const fetchScheduleVisitsUpcoming = (studyId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    dispatch(setScheduleVisitsUpcoming(payload))
    onSuccess()
  }
  return dispatch(_fetchScheduleVisitsUpcoming(studyId, success))
}

const fetchScheduleVisitsPrevious = (studyId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    dispatch(setScheduleVisitsPrevious(payload))
    onSuccess()
  }
  return dispatch(_fetchScheduleVisitsPrevious(studyId, success))
}

const updatePreviousScheduleVisit = (studyId, ssvId, ptpId, body, onSuccess = () => {}) => dispatch => {
  const success = () => {
    onSuccess()
    dispatch(fetchScheduleVisitsPrevious(studyId))
  }
  return dispatch(_updatePreviousScheduleVisit(studyId, ssvId, ptpId, body, success))
}

const fetchVisitSchedules = (studyId, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    dispatch(setScheduleVisitTemplates(payload))
    onSuccess()
  }
  return dispatch(_fetchVisitSchedules(studyId, success))
}

const fetchScheduleHistoryVisit = (studyId, siteId, visitsScheduleId, onSuccess = () => {}) => dispatch => {
  const dummyData = {
    status: 'success',
    timeline: [
      {
        timestamp: '2022-08-23T14:17:11.816+00:00',
        start_time: '2022-09-02T09:00:00.000+00:00',
        duration: 11,
        event_type: 'created_date',
        is_adhoc: false,
        cancelled_time: null,
        visitors: [
          {
            is_admin: true,
            visitor_name: 'farinik331',
          },
          {
            is_admin: false,
            visitor_name: 'jehifid684 aregods',
          },
        ],
      },
      {
        timestamp: '2022-09-02T09:00:00.000+00:00',
        event_type: 'virtual_visit_begins',
      },
      {
        timestamp: '2022-09-02T09:30:00.000+00:00',
        event_type: 'virtual_visit_ends',
      },
    ],
    additional_info: {
      type: {
        is_adhoc: false,
        created: '2022-08-23T14:17:11.816+00:00',
        start_time: '2022-09-02T09:00:00.000+00:00',
        cancelled_time: null,
        visitors: [
          {
            is_admin: true,
            nickname: 'farinik331@rxcay.com',
            email: 'farinik331',
            id: 2775,
          },
          {
            is_admin: false,
            fname: 'jehifid684',
            lname: 'aregods',
            id: 18075,
          },
        ],
      },
      length: {
        start_time: '2022-09-02T09:00:00.000+00:00',
        end_time: '2022-09-02T09:30:00.000+00:00',
      },
      instruments_completed: [],
      notifications: {
        admin: [
          {
            type: 'email',
            timestamp: '2022-09-01T09:00:20.776+00:00',
          },
          {
            type: 'email',
            timestamp: '2022-09-02T08:50:08.314+00:00',
          },
        ],
        participant: [
          {
            type: 'email',
            timestamp: '2022-09-01T09:00:20.776+00:00',
          },
          {
            type: 'email',
            timestamp: '2022-09-02T08:30:16.261+00:00',
          },
        ],
      },
    },
    download_links: [],
    message: 'SUCCESS',
  }
  dispatch(loadingActions.startLoader(true, 'visit', visitSchedulesId))
  setTimeout(() => {
    dispatch(loadingActions.stopLoader(true, 'visit', visitSchedulesId))
    dispatch(setScheduleVisitInfo(dummyData, visitSchedulesId))
  }, 2000)
}

const fetchScheduleVisitUpcoming = (studyId, siteId, visitSchedulesId, onSuccess = () => {}) => dispatch => {
  const dummyData = {
    status: 'pending',
    timeline: [
      {
        timestamp: '2022-08-23T14:17:11.816+00:00',
        start_time: '2022-09-02T09:00:00.000+00:00',
        duration: 30,
        event_type: 'created_date',
        is_adhoc: false,
        cancelled_time: null,
        visitors: [
          {
            is_admin: true,
            visitor_name: 'farinik331',
          },
          {
            is_admin: false,
            visitor_name: 'jehifid684 aregods',
          },
        ],
      },
      {
        timestamp: '2022-09-02T09:00:00.000+00:00',
        event_type: 'virtual_visit_begins',
      },
      {
        timestamp: '2022-09-02T09:30:00.000+00:00',
        event_type: 'virtual_visit_ends',
      },
    ],
    additional_info: {
      type: {
        is_adhoc: false,
        created: '2022-08-23T14:17:11.816+00:00',
        start_time: '2022-09-02T09:00:00.000+00:00',
        cancelled_time: null,
        visitors: [
          {
            is_admin: true,
            nickname: 'farinik331@rxcay.com',
            email: 'farinik331',
            id: 2775,
          },
          {
            is_admin: false,
            fname: 'jehifid684',
            lname: 'aregods',
            id: 18075,
          },
        ],
      },
      length: {
        start_time: '2022-09-02T09:00:00.000+00:00',
        end_time: '2022-09-02T09:30:00.000+00:00',
      },
      instruments_completed: [],
      notifications: {
        admin: [
          {
            type: 'email',
            timestamp: '2022-09-01T09:00:20.776+00:00',
          },
          {
            type: 'email',
            timestamp: '2022-09-02T08:50:08.314+00:00',
          },
        ],
        participant: [
          {
            type: 'email',
            timestamp: '2022-09-01T09:00:20.776+00:00',
          },
          {
            type: 'email',
            timestamp: '2022-09-02T08:30:16.261+00:00',
          },
        ],
      },
    },
    download_links: [],
    message: 'SUCCESS',
  }
  dispatch(loadingActions.startLoader(true, 'visit', visitSchedulesId))
  setTimeout(() => {
    dispatch(loadingActions.stopLoader(true, 'visit', visitSchedulesId))
    dispatch(setScheduleVisitUpcomingInfo(dummyData, visitSchedulesId))
  }, 2000)
}

const fetchScheduleVisitPrevious = (studyId, siteId, visitSchedulesId, onSuccess = () => {}) => dispatch => {
  const dummyData = {
    status: 'pending',
    timeline: [
      {
        timestamp: '2022-08-23T14:17:11.816+00:00',
        start_time: '2022-09-02T09:00:00.000+00:00',
        duration: 30,
        event_type: 'created_date',
        is_adhoc: false,
        cancelled_time: null,
        visitors: [
          {
            is_admin: true,
            visitor_name: 'farinik331',
          },
          {
            is_admin: false,
            visitor_name: 'jehifid684 aregods',
          },
        ],
      },
      {
        timestamp: '2022-09-02T09:00:00.000+00:00',
        event_type: 'virtual_visit_begins',
      },
      {
        timestamp: '2022-09-02T09:30:00.000+00:00',
        event_type: 'virtual_visit_ends',
      },
    ],
    additional_info: {
      type: {
        is_adhoc: false,
        created: '2022-08-23T14:17:11.816+00:00',
        start_time: '2022-09-02T09:00:00.000+00:00',
        cancelled_time: null,
        visitors: [
          {
            is_admin: true,
            nickname: 'farinik331@rxcay.com',
            email: 'farinik331',
            id: 2775,
          },
          {
            is_admin: false,
            fname: 'jehifid684',
            lname: 'aregods',
            id: 18075,
          },
        ],
      },
      length: {
        start_time: '2022-09-02T09:00:00.000+00:00',
        end_time: '2022-09-02T09:30:00.000+00:00',
      },
      instruments_completed: [],
      notifications: {
        admin: [
          {
            type: 'email',
            timestamp: '2022-09-01T09:00:20.776+00:00',
          },
          {
            type: 'email',
            timestamp: '2022-09-02T08:50:08.314+00:00',
          },
        ],
        participant: [
          {
            type: 'email',
            timestamp: '2022-09-01T09:00:20.776+00:00',
          },
          {
            type: 'email',
            timestamp: '2022-09-02T08:30:16.261+00:00',
          },
        ],
      },
    },
    download_links: [],
    message: 'SUCCESS',
  }
  dispatch(loadingActions.startLoader(true, 'visit', visitSchedulesId))
  setTimeout(() => {
    dispatch(loadingActions.stopLoader(true, 'visit', visitSchedulesId))
    dispatch(setScheduleVisitPreviousInfo(dummyData, visitSchedulesId))
  }, 2000)
}

const parseNewTranslations = ({ payloadTranslations, enforcedLanguage }) => {
  const resultTranslations = { ...payloadTranslations }

  // If uploaded languages are not supported by a study, they will be discarded
  if (enforcedLanguage) {
    const payloadLangIdArr = Object.keys(payloadTranslations)
    const enforcedLangIdArr = enforcedLanguage.languages.map(enforcedLangObj => {
      return enforcedLangObj.id
    })
    enforcedLangIdArr.push(DEFAULT_LANG)

    const diffArr = payloadLangIdArr.filter(langId => !enforcedLangIdArr.includes(langId))
    diffArr.forEach(diffLangId => {
      delete resultTranslations[diffLangId]
    })
  }
  return resultTranslations
}

const downloadScheduleTranslationTemplate = (translations, studyId, visitScheduleId) => dispatch => {
  const requestBodyTranslations = { ...translations }
  const translationKeys = Object.keys(requestBodyTranslations)
  const convertBackTranslationEditorStates = () => {
    translationKeys.forEach(languageId => {
      requestBodyTranslations[languageId].content = HTMLToEditorState(requestBodyTranslations[languageId].content)
    })
  }
  const success = (blob, fileName) => {
    downloadBlob(blob, `schedule_translation.csv`, fileName)
    convertBackTranslationEditorStates()
  }
  const fail = () => {
    dispatch(
      notyActions.showError({
        text: generateNotyMessage('Please try again later', false),
      }),
    )
    convertBackTranslationEditorStates()
  }

  return dispatch(
    request({
      resType: 'blob',
      success,
      url: `/control/studies/${studyId}/study_schedules/${visitScheduleId}/export_csv`,
      fail,
    }),
  )
}

const uploadScheduleTranslations = (file, successCb, enforcedLanguage, studyId, visitScheduleId) => dispatch => {
  const success = payload => {
    if (successCb) successCb()
    const translationsToSet = parseNewTranslations({ payloadTranslations: payload, enforcedLanguage })
    dispatch(setScheduleTranslations(translationsToSet))
    dispatch(
      notyActions.showSuccess({
        text: generateNotyMessage('CSV upload successful!', true),
      }),
    )
  }

  return dispatch(
    request({
      body: file,
      method: 'PATCH',
      contentType: 'text/csv',
      success,
      url: `/control/studies/${studyId}/study_schedules/${visitScheduleId}/import_csv`,
      catchMessage: 'CSV upload unsuccessful. Please try again after a few minutes.',
    }),
  )
}

export const visitSchedulesHistoryList = (state = [], action) => {
  switch (action.type) {
    case SET_SCHEDULE_VISITS_HISTORY:
      return formatScheduleVisitsHistoryList(action.payload)
    default:
      return state
  }
}

export const visitSchedulesUpcomingList = (state = [], action) => {
  switch (action.type) {
    case SET_SCHEDULE_VISITS_UPCOMING:
      return formatScheduleVisitsUpcomingList(action.payload)
    default:
      return state
  }
}

export const visitSchedulesPreviousList = (state = [], action) => {
  switch (action.type) {
    case SET_SCHEDULE_VISITS_PREVIOUS:
      return formatScheduleVisitsPreviousList(action.payload)
    default:
      return state
  }
}

export const visitSchedulesTemplatesList = (state = [], action) => {
  switch (action.type) {
    case SET_SCHEDULE_VISIT_TEMPLATES:
      return formatScheduleVisitTemplatesList(action.payload)
    default:
      return state
  }
}

export const visitSchedulesHistoryInfo = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_SCHEDULE_VISIT_INFO:
      newState[action.visitSchedulesId] = formatScheduleVisitInfo(action.payload)
      return newState
    default:
      return state
  }
}

export const visitSchedulesUpcomingInfo = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_SCHEDULE_VISIT_UPCOMING_INFO:
      newState[action.visitSchedulesId] = formatScheduleVisitInfo(action.payload)
      return newState
    default:
      return state
  }
}

export const visitSchedulesPreviousInfo = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_SCHEDULE_VISIT_PREVIOUS_INFO:
      newState[action.visitSchedulesId] = formatScheduleVisitInfo(action.payload)
      return newState
    default:
      return state
  }
}

const checkedVisitTemplates = (state = {}, action) => {
  let newState
  switch (action.type) {
    case TOGGLE_VISIT_TEMPLATE:
      newState = { ...state }
      if (action.id in newState) delete newState[action.id]
      else newState[action.id] = true
      return newState
    case RESET_CHECKED_VISIT_TEMPLATES:
      return {}
    case TOGGLE_CHECK_ALL_VISIT_TEMPLATES:
      newState = {}
      if (Object.keys(state).length > 0) return newState // clear checks
      if (action.list) {
        action.list.forEach(id => {
          newState[id] = true
        })
      } else {
        action.visits.forEach(visit => {
          newState[visit[0].value] = true
        })
      }
      return newState
    default:
      return state
  }
}

const expandedScheduleHistoryVisits = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case TOGGLE_SCHEDULE_VISIT_EXPAND:
      if (newState[action.visitSchedulesId]) delete newState[action.visitSchedulesId]
      else newState[action.visitSchedulesId] = true
      return newState
    case RESET_SCHEDULE_EXPANDED_VISITS:
      return {}
    default:
      return state
  }
}

const expandedScheduleUpcomingVisits = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case TOGGLE_SCHEDULE_UPCOMING_VISIT_EXPAND:
      if (newState[action.visitSchedulesId]) delete newState[action.visitSchedulesId]
      else newState[action.visitSchedulesId] = true
      return newState
    case RESET_SCHEDULE_UPCOMING_EXPANDED_VISITS:
      return {}
    default:
      return state
  }
}

const expandedSchedulePreviousVisits = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case TOGGLE_SCHEDULE_PREVIOUS_VISIT_EXPAND:
      if (newState[action.visitSchedulesId]) delete newState[action.visitSchedulesId]
      else newState[action.visitSchedulesId] = true
      return newState
    case RESET_SCHEDULE_PREVIOUS_EXPANDED_VISITS:
      return {}
    default:
      return state
  }
}

const visitScheduleTranslations = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case TOGGLE_SCHEDULE_TRANSLATIONS: {
      if (newState.translations) {
        delete newState.translations
      } else if (action.localeId) {
        newState.translations = _getDefaultTranslations(action.localeId)
      }
      return newState
    }
    case ADD_SCHEDULE_TRANSLATION: {
      newState.translations[action.localeId] = _getDefaultTranslation()
      return newState
    }
    case UPDATE_SCHEDULE_TRANSLATION:
      newState.translations[action.localeId][action.key] = action.value
      return newState
    case DELETE_SCHEDULE_TRANSLATION:
      delete newState.translations[action.localeId]
      return newState
    case SET_SCHEDULE_TRANSLATIONS:
      newState.translations = action.translations
      return newState
    default:
      return state
  }
}

export default combineReducers({
  expandedScheduleHistoryVisits,
  visitSchedulesHistoryList,
  visitSchedulesHistoryInfo,
  expandedScheduleUpcomingVisits,
  expandedSchedulePreviousVisits,
  visitSchedulesUpcomingList,
  visitSchedulesPreviousList,
  visitSchedulesTemplatesList,
  visitSchedulesUpcomingInfo,
  visitSchedulesPreviousInfo,
  checkedVisitTemplates,
  visitScheduleTranslations,
})

export const actions = {
  fetchScheduleHistoryVisit,
  fetchScheduleVisitUpcoming,
  fetchScheduleVisitPrevious,
  fetchScheduleVisitsUpcoming,
  fetchScheduleVisitsPrevious,
  updatePreviousScheduleVisit,
  fetchVisitSchedules,
  resetScheduleHistoryExpandedVisits,
  resetScheduleUpcomingExpandedVisits,
  resetSchedulePreviousExpandedVisits,
  toggleScheduleHistoryVisitExpand,
  toggleScheduleUpcomingVisitExpand,
  toggleSchedulePreviousVisitExpand,
  toggleVisitTemplate,
  toggleCheckAllVisitTemplates,
  resetCheckedVisitTemplates,
  downloadScheduleTranslationTemplate,
  uploadScheduleTranslations,
  toggleScheduleTranslations,
  addScheduleTranslation,
  updateScheduleTranslation,
  deleteScheduleTranslation,
  setScheduleTranslations,
}
