import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { VISIT_STATUS_TYPE_MAP, VISIT_STATUS_EVENT_TEXT_MAP } from '../utils/visitUtils'

const Timeline = ({ timelineList, statusEventText, isLastTimelineGroup, setPtpJoined, setClinJoined }) => {
  return timelineList.map((timelineEvent, idx) => {
    const isLast = idx === timelineList.length - 1
    const { timestamp, event, time } = timelineEvent
    const { clinicianConnected, participantConnected, text, additionalInfo, isStartOrEnd } = event
    useEffect(() => {
      if (clinicianConnected) setClinJoined(true)
      if (participantConnected) setPtpJoined(true)
    })
    return (
      // using Fragment instead of shorthand to give it a key
      <Fragment key={`${text}-${timestamp}-${idx}`}>
        <div className='flexed start-justified start-aligned'>
          <div className='time'>{time}</div>
          <div>
            <div className={`event-info ${isStartOrEnd ? 'status-styled' : ''}`}>{text}</div>
            {additionalInfo && additionalInfo.map((info, i) => <p key={`${info}-${i}`}>{info}</p>)}
          </div>
        </div>
        {isLastTimelineGroup && isLast && statusEventText && (
          <div className='flexed start-justified start-aligned status-styled'>{statusEventText}</div>
        )}
      </Fragment>
    )
  })
}

const getStatusEventText = (status, ptpJoined, clinJoined) => {
  switch (status) {
    case VISIT_STATUS_TYPE_MAP.missedCall:
      return VISIT_STATUS_EVENT_TEXT_MAP.missedCall
    case VISIT_STATUS_TYPE_MAP.error:
      return VISIT_STATUS_EVENT_TEXT_MAP.error
    case VISIT_STATUS_TYPE_MAP.didNotMeet:
      return VISIT_STATUS_EVENT_TEXT_MAP.didNotMeet
    case VISIT_STATUS_TYPE_MAP.noShow:
      if (!ptpJoined && !clinJoined) return VISIT_STATUS_EVENT_TEXT_MAP.noShow.noBoth
      if (!ptpJoined && clinJoined) return VISIT_STATUS_EVENT_TEXT_MAP.noShow.noPtp
      return VISIT_STATUS_EVENT_TEXT_MAP.noShow.noClin
    case VISIT_STATUS_TYPE_MAP.success:
    default:
      return null
  }
}

const VisitTimeline = props => {
  const { timeline, status } = props
  const timelineDates = timeline ? Object.keys(timeline) : []
  const [ptpJoined, setPtpJoined] = useState(false)
  const [clinJoined, setClinJoined] = useState(false)
  const visitorProps = { setPtpJoined, setClinJoined }

  const visitTimeline = timelineDates.map((date, idx) => {
    const isLastTimelineDataGroup = idx === timelineDates.length - 1
    return (
      <div key={`${date}-${idx}`} className='visit-timeline'>
        <div className='label-small'>{date}</div>
        <Timeline
          timelineList={timeline[date]}
          statusEventText={getStatusEventText(status, ptpJoined, clinJoined)}
          isLastTimelineGroup={isLastTimelineDataGroup}
          {...visitorProps}
        />
      </div>
    )
  })

  return <div>{timelineDates.length > 0 ? visitTimeline : 'There is no timeline for this visit'}</div>
}

VisitTimeline.propTypes = {
  status: PropTypes.string,
  timeline: PropTypes.objectOf(PropTypes.array),
}

export default VisitTimeline
