import React, { useState, useMemo } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Loader, SearchInput, StickyHeader, Dropdown } from 'components/UIElements'
import { DATE_FORMAT_MAP } from 'utils/constants'
import { useOffsets } from 'utils/misc'
import STRINGS from 'utils/strings'
import VisitSchedulesUpcomingTable from './VisitSchedulesUpcomingTable'

const dataBoxList = [
  {
    header: 'Reschedule request',
    status: 'reschedule',
    subtitle: 'Reschedule request subtitle',
    color: 'red',
  },
  {
    header: 'On Schedule request',
    status: 'onSchedule',
    subtitle: 'On Schedule request subtitle',
    color: 'green',
  },
]

const VISITS_PERIOD_OPTIONS = [
  { key: 'all', text: 'All visits' },
  { key: 'days', text: 'A day' },
  { key: 'weeks', text: 'A week' },
  { key: 'months', text: 'A month' },
]

const VisitSchedulesUpcoming = props => {
  const {
    adhocVisitsDisabled,
    studyID,
    selectedTab,
    canEditVisits,
    studyLock,
    visitSchedules,
    isSiteCoord,
    visitSchedulesLoading,
  } = props
  const { visitSchedulesUpcomingList = [] } = visitSchedules
  const [scheduleUpcomingCount, setScheduleUpcomingCount] = useState(visitSchedulesUpcomingList.length)
  const [selectedDataBox, setSelectedDataBox] = useState(null)
  const [dataBoxCount, setDataBoxCount] = useState({})
  const [selectedVisitsPeriod, setSelectedVisitsPeriod] = useState(VISITS_PERIOD_OPTIONS[0].key)
  const offsets = useOffsets('sticky', selectedTab)

  const onSelectDataBox = nextSelected => {
    if (nextSelected === selectedDataBox) {
      setSelectedDataBox(null)
    } else {
      setSelectedDataBox(nextSelected)
    }
  }

  const getVisitByPeriod = (visitList, period) =>
    period === 'all'
      ? visitList
      : visitList.filter(visit => {
          const date = visit.find(({ key }) => key === 'date')
          if (!date) return true
          const today = moment()
          const visitDate = moment.utc(date.value, DATE_FORMAT_MAP.main)
          const filterDate = today.add(1, period)
          return moment(filterDate).isSameOrAfter(moment(visitDate))
        })

  const filteredVisitSchedulesUpcomingList = useMemo(
    () => getVisitByPeriod(visitSchedulesUpcomingList, selectedVisitsPeriod),
    [visitSchedulesUpcomingList, selectedVisitsPeriod],
  )

  return (
    <>
      <StickyHeader offset={offsets ? offsets[0] : 0} className='column'>
        <div className='full-width-flex flex-end align-center'>
          <div className='flex'>
            <div className='periods-filter'>
              <h6>Showing visits for</h6>
              <Dropdown
                className='periods-dropdown'
                options={VISITS_PERIOD_OPTIONS}
                onSelect={item => {
                  setSelectedVisitsPeriod(item.key)
                }}
                selected={selectedVisitsPeriod}
              />
            </div>
            {/* {dataBoxList.map(box => (
              <DataBox
                key={box.status}
                type={box.status}
                header={box.header}
                value={dataBoxCount[box.status] ?? 0}
                total={scheduleUpcomingCount ?? 0}
                subtitle={box.subtitle ?? ''}
                onSelect={onSelectDataBox}
                selected={selectedDataBox}
                className={box.status ? `${box.status}-request` : ''}
              />
            ))} */}
          </div>
          {isSiteCoord ? (
            !adhocVisitsDisabled && (
              <Button
                id='create-new-adhoc-visit'
                disabled={studyLock}
                content={STRINGS.visitScheduleCreateNewAdhoc}
                onClick={() => {
                  if (!studyLock) {
                    browserHistory.push(`/studies/${studyID}/visit-schedules/participant-visit/create`)
                  }
                }}
              />
            )
          ) : (
            <>
              {canEditVisits && (
                <Button
                  id='schedule-new-visit'
                  disabled={studyLock}
                  content={STRINGS.visitScheduleScheduleNewVisit}
                  onClick={() => !studyLock && browserHistory.push(`/studies/${studyID}/virtualvisits/create`)}
                />
              )}
              <Button
                id='create-new-visit'
                disabled={studyLock}
                content={STRINGS.visitScheduleCreateNewVisit}
                onClick={() => {
                  if (!studyLock) {
                    browserHistory.push(`/studies/${studyID}/visit-schedules/template-visits/create`)
                  }
                }}
              />
            </>
          )}
        </div>
        <div className='full-width-flex space-between'>
          <div />
          <SearchInput />
        </div>
      </StickyHeader>
      {visitSchedulesLoading ? (
        <Loader inContainer className='visits-history-loader' />
      ) : (
        <>
          <VisitSchedulesUpcomingTable
            {...props}
            setScheduleUpcomingCount={setScheduleUpcomingCount}
            setDataBoxCount={setDataBoxCount}
            selectedDataBox={selectedDataBox}
            visitSchedulesUpcomingList={filteredVisitSchedulesUpcomingList}
            getVisitByPeriod={getVisitByPeriod}
            selectedVisitsPeriod={selectedVisitsPeriod}
            offset={offsets ? offsets[1] : 0}
          />
          {visitSchedulesUpcomingList.length === 0 && (
            <div className='flexed column no-visits'>
              <h5>There are no visits</h5>
              <p>...yet!</p>
            </div>
          )}
        </>
      )}
    </>
  )
}

VisitSchedulesUpcoming.propTypes = {
  adhocVisitsDisabled: PropTypes.bool,
  canEditVisits: PropTypes.bool,
  loading: PropTypes.shape({
    virtualVisits: PropTypes.bool,
  }),
  studyID: PropTypes.string,
  selectedTab: PropTypes.string,
  visitSchedules: PropTypes.shape({
    visitsScheduleUpcomingList: PropTypes.arrayOf(PropTypes.array),
  }),
  studyLock: PropTypes.bool,
  isSiteCoord: PropTypes.bool,
}

export default VisitSchedulesUpcoming
