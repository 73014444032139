import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
import { UserIsAuthenticated, UserHasValidPassword } from 'containers/authContainers'
import SitePageRoute from './routes/SitesPage'
import EditSiteRoute from './routes/EditSite'
import CreateSiteRoute from './routes/CreateSite'
import sitesReducer, { fetchSites } from './routes/SitesPage/modules/Sites'

export default store => {
  return {
    path: 'sites',
    exact: true,
    getIndexRoute(location, cb) {
      const { params } = location
      const { studyID } = params
      store.dispatch(setSubroute('sites'))
      injectReducers(store, {
        sites: sitesReducer,
      })
      store.dispatch(fetchSites(studyID))
      cb(null, UserIsAuthenticated(UserHasValidPassword(SitePageRoute(store))))
    },
    getChildRoutes(location, cb) {
      injectReducers(store, {
        sites: sitesReducer,
      })
      cb(null, [CreateSiteRoute(store), EditSiteRoute(store)])
    },
  }
}
