import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducer } from 'store/reducers'
import AuditFilterContainer from './containers/AuditFilterContainer'
import reducer from '../modules/Audit'

export default store => {
  return {
    getComponent(nextState, cb) {
      const { params } = nextState
      const { studyID } = params

      require.ensure(
        [],
        () => {
          injectReducer(store, {
            key: 'auditReport',
            reducer,
          })
          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(UserHasScope(['xr', 'xr-no-pii'], AuditFilterContainer, false, studyID)),
            ),
          )
        },
        'audit-filter',
      )
    },
    getChildRoutes(location, cb) {
      cb(null, [])
    },
  }
}
