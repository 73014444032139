import React, { useEffect, useState, useRef } from 'react'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { Textarea, Button, Input, Dropdown, RichTextEditor } from 'components/UIElements'
import MediaUpload from 'containers/mediaUploadContainer'
import { triangleIcon } from 'assets/assets'
import {
  DEFAULT_LANG,
  MEDIA_TYPE_MAP,
  MODAL_CLASSES_MAP,
  NUMERIC_CHOICE_QUESTION_TYPES,
  QUESTION_ATTRIBUTE_MAP,
  QUESTION_TYPE_MAP,
} from 'utils/constants'

const TYPE_OPTIONS = [
  // dropdown options for input type for multiple field questions
  { key: 'text', value: 'text', text: 'Text' },
  { key: 'integer', value: 'integer', text: 'Number' },
  { key: 'decimal', value: 'decimal', text: 'Decimal' },
]

const renderScoringHeader = () => {
  return (
    <div className='scoring-header flexed'>
      <div className='label-small'>Choices</div>
      <div className='label-small'>Values</div>
    </div>
  )
}

const renderButtonValueLabel = () => <div className='label-small'>Label</div>
const renderScoreValueLabel = () => <div className='label-small score-value'>(Value)</div>

const ChoicesList = SortableContainer(props => {
  const [activeChoiceId, setActiveChoiceId] = useState(null)
  const { isActive, item, preview, editorStates, itemId, activeItemId } = props
  const { hasScoring, nonDefaultScoring } = item.attributes

  const icon = ['select_one', 'likert', 'numeric_rating_scale'].includes(item.type)
    ? 'fa fa-circle-thin'
    : 'fa fa-square-o'
  const numChoices = item.choices_order.length + (item.attributes.other ? 1 : 0)
  const isLikert = item.type === QUESTION_TYPE_MAP.likert
  const isSelect = item.type === QUESTION_TYPE_MAP.selectOne || item.type === QUESTION_TYPE_MAP.selectMultiple
  const isMultiField = item.type === QUESTION_TYPE_MAP.multipleField
  const isNRS = item.type === QUESTION_TYPE_MAP.numericRatingScale

  const renderMultiFieldHeader = () => {
    return (
      <div className='multi-field-header'>
        <div className='label-small'>INPUT FIELD</div>
        <div className='label-small'>LABEL/UNITS</div>
        <div className='label-small'>INPUT TYPE</div>
        {item.attributes.hasCharLimit && <div className='label-small'>VALIDATION</div>}
      </div>
    )
  }

  return (
    <div className={isLikert || isNRS ? 'flexed' : ''}>
      {hasScoring && isActive && isSelect && renderScoringHeader()}
      {props.errors && numChoices < 2 && props.errors.minChoicesErr && (
        <p className='survey-error'>{props.errors.minChoicesErr}</p>
      )}
      {isMultiField && renderMultiFieldHeader()}
      {item.choices_order.map((choiceId, index) => {
        let placeholder
        if (isLikert || isNRS) placeholder = `Item ${index + 1} Label`
        else if (isSelect) placeholder = 'Type choice'
        else placeholder = 'Label/Unit'
        return (
          <Choice
            activeChoiceId={activeChoiceId}
            choice={item.choices[choiceId]}
            choiceEditorState={editorStates ? editorStates[choiceId] : {}}
            choiceId={choiceId}
            cIndex={index}
            disabled={preview}
            draggable={isSelect} // only drag and droppable if it is a select type question
            icon={icon}
            index={index}
            isLikert={isLikert}
            isMultiField={isMultiField}
            isNRS={isNRS}
            isSelect={isSelect}
            key={choiceId}
            nonDefaultScoring={nonDefaultScoring}
            numChoices={item.choices_order.length}
            onFocus={() => setActiveChoiceId(choiceId)}
            placeholder={placeholder}
            {...props}
          />
        )
      })}
      {item.attributes.other !== undefined && (
        <div className='select-option other'>
          <i className={icon} />
          <Textarea
            autoFocus={Boolean(item.attributes.other === '' && itemId === activeItemId)}
            disabled={preview}
            className={`choice-label-input${props.errors.hasBlankChoicesErr && !item.attributes.other ? ' error' : ''}`}
            onChange={value => props.updateOtherChoice(props.item, props.itemId, value)}
            placeholder='type other choice'
            value={item.attributes.other}
          />
          <span>[ Other ]</span>
          {props.isActive && <i className='fas fa-trash' onClick={() => props.deleteOtherChoice(props.itemId)} />}
        </div>
      )}
    </div>
  )
})

const allowAddChoice = props => {
  if (NUMERIC_CHOICE_QUESTION_TYPES.includes(props.item.type)) return false
  return true
}

const allowDeleteChoice = props => {
  if (
    (props.item.type === QUESTION_TYPE_MAP.likert && props.item.choices_order.length <= 5) ||
    props.item.choices_order.length <= 2
  ) {
    return false
  }
  return true
}

const Choice = SortableElement(props => {
  const errors = props.errors ? props.errors : {}
  const {
    choice,
    choiceEditorState,
    choiceId,
    cIndex,
    currentLang = DEFAULT_LANG,
    currentResourcesSizeCount,
    draggable,
    isActive,
    isConsent,
    isLikert,
    isMultiField,
    isNRS,
    isSelect,
    item,
    itemId,
    activeItemId,
    nonDefaultScoring,
    numChoices,
    onAddChoice,
    onFocus,
    placeholder,
    preview,
    setQuestionScoresEdited,
    updateChoiceKey,
    updateChoiceLabel,
    updateChoiceLabelTranslation,
    updateChoiceRichLabel,
    updateChoiceValue,
    updateChoiceScoreValue,
  } = props
  const [isTranslation, setIsTranslation] = useState(currentLang !== DEFAULT_LANG)
  const [image, setImage] = useState(choice.image)
  const [imageSrc, setImageSrc] = useState(choice.imageSrc)

  const hasIcon = !item.attributes[QUESTION_ATTRIBUTE_MAP.disableButtons]

  useEffect(() => {
    setIsTranslation(currentLang !== DEFAULT_LANG)
  }, [currentLang])

  // Clear the image from the component if there is no image in the choice anymore
  useEffect(() => {
    if (!choice.image) {
      setImage(null)
      setImageSrc(null)
    }
  }, [choice.image])

  const labelRef = useRef(null)
  const [validationError, setValidationError] = useState('')
  const [negativeValError, setNegativeValError] = useState('')

  const addImageToChoice = imageObj => {
    const { imageId, size, src } = imageObj
    setImage(imageId)
    setImageSrc(src)
    props.addChoiceLabelImage({ item, itemId, choiceId, imageId, imageSrc: src, size })
    props.closeModal()
  }

  const removeImageFromChoice = imageId => {
    props.deleteChoiceLabelImage(props.item, props.itemId, props.choiceId, imageId)
    setImage(null)
  }

  const { hasScoring, hasCharLimit } = item.attributes

  const isLikertFive = isLikert && numChoices === 5
  const isLikertSeven = isLikert && numChoices === 7

  const inputDefaultNRSValues = () => {
    // add a default value for the value in numeric rating scale question choices
    if (!choice.value && isNRS) updateChoiceValue(item, itemId, choiceId, (cIndex + 1).toString())
  }

  const inputDefaultScoreValues = (_isNRS = false) => {
    /**
     * Adds a default value for the score value in select question choices. If the score values
     * have been edited at anytime, a default score value of the choice's index is not added.
     *  */
    const value = _isNRS ? cIndex + 1 : cIndex
    if (!choice.score_value && hasScoring && !nonDefaultScoring && (isSelect || isNRS)) {
      updateChoiceScoreValue(item, itemId, choiceId, value.toString())
      // If the question is NRS, the default values start at 1 not 0
    }
  }

  // logic for determining whether a choices has a label beneath it, depending on the type and the number of choices
  const _hasLabel = () => {
    if (
      item.type === QUESTION_TYPE_MAP.selectOne ||
      item.type === QUESTION_TYPE_MAP.selectMultiple ||
      item.type === QUESTION_TYPE_MAP.multipleField
    ) {
      return true
    }
    if (isLikert) {
      if (numChoices === 5) {
        return true
      }
      if ([0, 6].includes(cIndex)) {
        return true
      }
    }
    if (isNRS) {
      if (numChoices <= 5) {
        return true
      }
      if (
        /*
         * these booleans check the number of choices in a numeric rating scale question,
         * and gives labels according to the specific index of the choices
         */
        (numChoices === 6 && [0, 2, 3, 5].includes(cIndex)) ||
        (numChoices === 7 && [0, 3, 6].includes(cIndex)) ||
        (numChoices === 8 && [0, 2, 5, 7].includes(cIndex)) ||
        (numChoices === 9 && [0, 2, 4, 6, 8].includes(cIndex)) ||
        (numChoices === 10 && [0, 3, 6, 9].includes(cIndex)) ||
        (numChoices === 11 && [0, 3, 5, 7, 10].includes(cIndex))
      ) {
        return true
      }
    }
    return false
  }

  const numString = {
    '6': 'six',
    '7': 'seven',
    '8': 'eight',
    '9': 'nine',
    '10': 'ten',
    '11': 'eleven',
  }

  const onUpdateChoiceValue = val => {
    return updateChoiceValue(item, itemId, choiceId, val || val === 0 ? parseInt(val, 10).toString() : '') // parsing as integer first to remove prefixed '0's
  }

  const onUpdateChoiceRichLabel = newEditorState => {
    if (choiceEditorState) {
      updateChoiceRichLabel(item, itemId, choiceId, choiceEditorState, newEditorState)
    }
  }

  const onUpdateChoiceScoreValue = val => {
    setQuestionScoresEdited(itemId)
    return updateChoiceScoreValue(item, itemId, choiceId, val || val === 0 ? parseInt(val, 10).toString() : '') // parsing as integer first to remove prefixed '0's
  }

  const getChoiceLabel = () => {
    const { label, label_translations } = choice
    if (isTranslation) {
      if (label_translations?.[currentLang]) {
        return label_translations[currentLang]
      }
      return ''
    }
    return label
  }

  const _updateChoiceLabel = value => {
    if (isTranslation) {
      updateChoiceLabelTranslation({
        item,
        itemId,
        choiceId,
        newTranslation: value,
        language: currentLang,
      })
    } else if (updateChoiceLabel) {
      updateChoiceLabel(item, itemId, choiceId, value)
    }
  }

  const renderChoiceLabel = () => {
    return (
      <>
        {isSelect && !isConsent ? (
          <RichTextEditor
            isChoice
            readOnly={preview}
            onFocus={onFocus}
            className={`choice-label-input${isConsent || isLikert || isNRS ? ' no-image' : ''}${
              isLikert ? ` likert${isLikertSeven ? ' seven' : ''}${isLikertFive ? ' five' : ''}` : ''
            }${isNRS ? ' NRS' : ''}${isMultiField ? ' multi-field' : ''}${!hasIcon ? ' no-icon' : ''}`}
            hasAlignmentControls
            hasError={errors.blankChoicesErr && (choice.label === '<p><br></p>' || choice.label === '<div><br></div>')}
            showToolbar={isActive}
            spellCheck
            placeholder='Type choice'
            onChange={onUpdateChoiceRichLabel}
            editorState={choiceEditorState || {}}
          />
        ) : (
          <Textarea
            ref={labelRef}
            hasError={!isTranslation && choice.label === '' && errors.blankChoicesErr}
            autoFocus={Boolean(choice.label === '' && activeItemId === itemId)}
            disabled={preview}
            className={`choice-label-input${isConsent || isLikert || isNRS ? ' no-image' : ''}${
              isLikert ? ` likert${isLikertSeven ? ' seven' : ''}${isLikertFive ? ' five' : ''}` : ''
            }${isNRS ? ' NRS' : ''}${isMultiField ? ' multi-field' : ''}`}
            onKeyPress={e => {
              if (e.key === 'Enter' && allowAddChoice(props)) {
                e.preventDefault()
                onAddChoice(cIndex + 1)()
              }
            }}
            onChange={value => _updateChoiceLabel(value)}
            placeholder={placeholder}
            value={getChoiceLabel()}
          />
        )}
      </>
    )
  }

  const renderScoreValueInput = () => {
    return (
      <Input
        disabled={preview}
        hasError={choice.score_value === '' && errors.blankScoreErr}
        className='score-val-input'
        min={0}
        mountFunc={() => inputDefaultScoreValues(isNRS)}
        type='number'
        value={choice.score_value || ''}
        onChange={val => (updateChoiceScoreValue ? onUpdateChoiceScoreValue(val) : '')}
      />
    )
  }

  const renderTypeDropdown = () => {
    return (
      <Dropdown
        disabled={preview}
        onSelect={dropdownItem => updateChoiceKey(item, itemId, choiceId, 'type', dropdownItem.value)}
        options={TYPE_OPTIONS}
        selected={choice.type}
        value={choice.type}
      />
    )
  }

  // This renders the error state if the min is greater than the max, and visa versa.
  const validateMinMax = () => {
    if (choice.max && choice.min && choice.min >= choice.max)
      setValidationError('Minimum value must not exceed maximum value')
    else setValidationError('')
  }

  const validateNegative = () => {
    if (choice.max < 0 || choice.min < 0) setNegativeValError('Values cannot be negative.')
    else setNegativeValError('')
  }

  const validate = () => {
    validateMinMax()
    validateNegative()
  }

  const renderLimitInput = () => {
    const { type } = choice
    const isTextType = type === 'text'
    return (
      <div className='input-validation'>
        <div>
          {!isTextType && (
            <Input
              disabled={preview}
              hasError={!!validationError || choice.min < 0}
              type='number'
              placeholder='Min Value'
              min={0}
              max={choice.max ? choice.max - 1 : null}
              value={choice.min || choice.min === 0 ? choice.min : ''}
              onChange={value => {
                updateChoiceKey(item, itemId, choiceId, 'min', value || value === 0 ? parseInt(value, 10) : '')
                validate()
              }}
            />
          )}
          <Input
            disabled={preview}
            className={isTextType ? 'char-limit' : ''}
            hasError={!!validationError || choice.max < 0}
            type='number'
            placeholder={isTextType ? 'Character Limit' : 'Max Value'}
            min={choice.min ? choice.min + 1 : 1}
            value={choice.max || ''}
            onChange={value => {
              updateChoiceKey(item, itemId, choiceId, 'max', value || value === 0 ? parseInt(value, 10) : '')
              validate()
            }}
          />
        </div>
        {validationError && <p className='error'>{validationError}</p>}
        {negativeValError && <p className='error'>{negativeValError}</p>}
      </div>
    )
  }

  return (
    <div
      className={`select-option${isSelect ? ' is-select' : ''}${isLikert || isNRS ? ' flexed column' : ''}${
        isLikertSeven ? ' likert-seven' : ''
      }${isLikertFive ? ' likert-five' : ''}${isNRS ? ' NRS' : ''}`}>
      {draggable && <div className='choice-grab-icon' />}

      {isMultiField ? (
        <Input className='ptp-input-placeholder' disabled placeholder='Participant Entry' />
      ) : (
        hasIcon && <i className={`${props.icon}`} />
      )}
      {isNRS && (
        <div className='full-width'>
          {hasScoring && cIndex === 0 && isActive && (
            <div>
              {renderButtonValueLabel()}
              {renderScoreValueLabel()}
            </div>
          )}
          <Input
            disabled={preview}
            mountFunc={() => inputDefaultNRSValues()} // enters default value of '1, 2, 3, 4, 5...' into the numeric rating scale value input
            className={`choice-value-input${
              errors && errors.blankValuesErr && !choice.value && choice.value !== 0 ? ' error' : ''
            }${errors && errors.valuesErr && (choice.value > 99 || choice.value < 0) ? ' error' : ''}`}
            type='number'
            min={0}
            max={99}
            value={choice.value}
            onChange={value => (updateChoiceValue ? onUpdateChoiceValue(value) : '')}
          />
        </div>
      )}
      {isNRS && hasScoring && renderScoreValueInput(isNRS)}
      {isNRS && _hasLabel() && triangleIcon('triangle')}

      {isLikert && <p className='num-value'>{cIndex + 1}</p>}
      {isLikert && _hasLabel() && <div className='label-divider' />}

      {(isLikertSeven || isNRS) && numChoices > 5
        ? _hasLabel() && (
            <div
              className={`label-wrapper${cIndex === 0 ? ' left' : ''}${
                cIndex !== 0 && cIndex < numChoices / 2 && cIndex !== numChoices / 2 - 0.5 ? ' left-mid' : ''
              }${cIndex === numChoices / 2 - 0.5 ? ' mid' : ''}${
                cIndex !== numChoices - 1 && cIndex >= numChoices / 2 ? ' right-mid' : ''
              }${cIndex === numChoices - 1 ? ' right' : ''} ${numString[numChoices]}`}>
              {renderChoiceLabel()}
            </div>
          )
        : _hasLabel() && renderChoiceLabel()}
      {isMultiField && renderTypeDropdown()}
      {isMultiField && hasCharLimit && renderLimitInput()}
      {!isLikert &&
        !isNRS &&
        !isMultiField &&
        (image ? (
          <div className='image-container choice-image flexed column'>
            <div className='image flexed column'>
              {!preview && (
                <Button className='fas fa-times remove-image' onClick={() => removeImageFromChoice(image)} />
              )}
              <p>{image}</p>
              <img src={imageSrc} alt='uploaded-choice' />
            </div>
          </div>
        ) : (
          props.isActive &&
          !props.isConsent && (
            <Button
              icon='far fa-image'
              content='Upload Image'
              onClick={() => {
                props.openModal({
                  content: (
                    <MediaUpload
                      callback={addImageToChoice}
                      uploadImage={props.uploadImage}
                      studyID={props.studyID}
                      onClose={props.closeModal}
                      mediaTypes={[MEDIA_TYPE_MAP.image]}
                      currentResourcesSizeCount={currentResourcesSizeCount}
                    />
                  ),
                  noButtons: true,
                  className: MODAL_CLASSES_MAP.uploadMedia,
                  closeOnBackgroundClick: true,
                })
              }}
            />
          )
        ))}
      {props.isActive && !isLikert && !isNRS && !isMultiField && allowDeleteChoice(props) && (
        <i
          className='fas fa-trash hoverable-red'
          onClick={() => props.deleteChoice(isConsent ? props.consent : props.survey, props.itemId, props.choiceId)}
        />
      )}
      {isSelect && hasScoring && renderScoreValueInput(isNRS)}
    </div>
  )
})

export default ChoicesList
