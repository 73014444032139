import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Input, Loader } from 'components/UIElements'
import { exclamationIcon } from 'assets/assets'
import '../../stylesheets/payoutForm.scss'

const PAYOUTS_KEY_MAP = {
  advancedPayouts: 'advanced_payouts',
  superGemStorePayouts: 'super_gem_store_payouts',
}

const PAYOUT_TYPE_MAP = {
  amazon_giftcard: 'amazon_giftcard',
}

const AWS_REGION_DROPDOWN_OPTIONS = [
  { key: 'us-east-1', text: 'North America (US/CA/MX)', value: 'us-east-1' },
  { key: 'eu-west-1', text: 'Europe (IT/ES/DE/FR/UK)', value: 'eu-west-1' },
  { key: 'us-west-2', text: 'Japan or Australia', value: 'us-west-2' },
]

const getInitialAmzCredentials = currency => {
  return {
    payout_type: PAYOUT_TYPE_MAP.amazon_giftcard,
    access_key: '',
    access_secret: '',
    aws_region: 'us-east-1',
    partner_id: '',
    currency,
  }
}

const AMZ_KEY_MAP = {
  accessKey: 'access_key',
  accessSecret: 'access_secret',
  partnerId: 'partner_id',
  region: 'aws_region',
}

const AMZ_GIFTCARD_STATUS_MAP = {
  failed: 'Failed',
  fulfilled: 'Fulfilled',
}

const AmazonForm = props => {
  const {
    closeModal,
    configObj,
    credentialsLoading,
    currency,
    isTreasureChest = false,
    payoutTypeObj,
    setCurrentView,
    updateConfig,
    viewStateMap,
  } = props
  const [validationError, setValidationError] = useState('')

  const [amazonCredentials, setAmazonCredentials] = useState(getInitialAmzCredentials(currency))

  const updateAmzCredential = (amzKey, val) => {
    return setAmazonCredentials({ ...amazonCredentials, [amzKey]: val })
  }

  const validateAndUpdateAdvancedPayout = (credentials, e) => {
    e.preventDefault()
    const { showPaymentAddSuccessFailNoty, validatePayoutCredentials } = props
    const payoutsKey = isTreasureChest ? PAYOUTS_KEY_MAP.superGemStorePayouts : PAYOUTS_KEY_MAP.advancedPayouts
    const resultAdvPayouts = { ...configObj[payoutsKey] }
    resultAdvPayouts.auto_approve.payout_type = credentials.payout_type

    /**
     * Control server will send 400 errors when there is no currency key or aws_region, but missing
     * or erroneous values for access_key, partner_id, access_secret will return success (200) with a gift_card_status
     * representing whether the gift card credentials valid or not ('Failed' v. 'Fulfilled'). This is why in the
     * success callback, there are conditionals where the error is set.
     */
    const success = result => {
      if (result.gift_card_status === AMZ_GIFTCARD_STATUS_MAP.failed) {
        setValidationError(result.description)
        showPaymentAddSuccessFailNoty(true) // first param isFail
        return Promise.resolve()
      }
      amazonCredentials.type = amazonCredentials.payout_type
      delete amazonCredentials.payout_type
      resultAdvPayouts.payout_types = [amazonCredentials]
      updateConfig({
        [payoutsKey]: {
          ...resultAdvPayouts,
          [isTreasureChest ? 'hasSuperGemStorePayouts' : 'hasAdvancedPayouts']: true,
        },
      })
      showPaymentAddSuccessFailNoty()
      closeModal()
      return Promise.resolve()
    }
    const fail = () => {
      setValidationError(payoutTypeObj.errorText)
      return Promise.resolve()
    }
    validatePayoutCredentials(credentials, success, fail)
  }

  return (
    <div className='payout-form'>
      {credentialsLoading && <Loader inContainer />}
      <h4>Your Amazon Incentives credentials</h4>
      <form
        id='amz-cred-form'
        onSubmit={e => {
          validateAndUpdateAdvancedPayout(amazonCredentials, e)
        }}>
        {validationError && (
          <p className='error-text flexed start-justified'>
            {exclamationIcon()}
            {validationError}
          </p>
        )}
        <label className='label-small' htmlFor='amz-reg-sel'>
          Which region are you in?
        </label>
        <Dropdown
          disabled={credentialsLoading}
          id='amz-reg-sel'
          onSelect={item => {
            updateAmzCredential(AMZ_KEY_MAP.region, item.value)
          }}
          options={AWS_REGION_DROPDOWN_OPTIONS}
          selected={amazonCredentials.aws_region}
        />
        <label className='label-small' htmlFor='amz-p-id'>
          Partner ID
        </label>
        <Input
          disabled={credentialsLoading}
          id='amz-p-id'
          onChange={val => updateAmzCredential(AMZ_KEY_MAP.partnerId, val)}
          placeholder='Partner ID'
        />
        <label className='label-small' htmlFor='amz-a-k'>
          Access key
        </label>
        <Input
          disabled={credentialsLoading}
          id='amz-a-k'
          onChange={val => updateAmzCredential(AMZ_KEY_MAP.accessKey, val)}
          placeholder='Access key'
        />
        <label className='label-small' htmlFor='amz-s-k'>
          Secret key
        </label>
        <Input
          disabled={credentialsLoading}
          id='amz-s-k'
          onChange={val => updateAmzCredential(AMZ_KEY_MAP.accessSecret, val)}
          placeholder='Secret key'
        />
        <div className='bottom flexed row'>
          <Button
          	id='amazon-back-button'
            disabled={credentialsLoading}
            type='button'
            content='back'
            link
            noStyling
            onClick={() => setCurrentView(viewStateMap.selection)}
          />
          <div>
            <Button id='amazon-cancel-button' disabled={credentialsLoading} type='button' content='Cancel' grey onClick={() => closeModal()} />
            <Button id='amazon-submit-button' disabled={credentialsLoading} type='submit' content='Submit' form='amz-cred-form' />
          </div>
        </div>
      </form>
    </div>
  )
}

const PayoutForm = props => {
  const { payoutTypeObj } = props
  switch (payoutTypeObj.name) {
    case PAYOUT_TYPE_MAP.amazon_giftcard:
    default:
      return <AmazonForm {...props} />
  }
}

AmazonForm.propTypes = {
  closeModal: PropTypes.func,
  configObj: PropTypes.shape({
    advanced_payouts: PropTypes.object,
    super_gem_store_payouts: PropTypes.object,
  }),
  currency: PropTypes.string,
  credentialsLoading: PropTypes.bool,
  isTreasureChest: PropTypes.bool,
  setCurrentView: PropTypes.func,
  loading: PropTypes.shape({
    credentials: PropTypes.bool,
  }),
  payoutTypeObj: PropTypes.shape({
    errorText: PropTypes.string,
    name: PropTypes.string,
  }),
  updateConfig: PropTypes.func,
  showPaymentAddSuccessFailNoty: PropTypes.func,
  validatePayoutCredentials: PropTypes.func,
  viewStateMap: PropTypes.shape({
    selection: PropTypes.string,
  }),
}

PayoutForm.propTypes = {
  payoutTypeObj: PropTypes.shape({
    errorText: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default PayoutForm
