import React from 'react'
import PropTypes from 'prop-types'
import { RichTextEditor } from 'components/UIElements'

const MatrixQuestionsList = props => {
  const { editorStates, isActive, item, itemId, preview, questionsError, updateClinroMatrixQuestionLabel } = props

  const onMatrixQuestionLabelChange = (newEditorState, editorState, questionId) => {
    updateClinroMatrixQuestionLabel(itemId, questionId, editorState, newEditorState)
  }

  return (
    <div className='matrix-questions-wrapper'>
      <div className='matrix-questions flexed'>
        <div className='flexed column start-aligned matrix-questions-list'>
          {item.questions_order.map((matrixQuestionId, index) => {
            const matrixQuestionLabelEditorState = editorStates[matrixQuestionId]
            return (
              <RichTextEditor
                autoFocus
                readOnly={preview}
                editorState={matrixQuestionLabelEditorState}
                hasError={!!questionsError && item.questions[matrixQuestionId].label === '<p><br></p>'}
                key={`matrix-choice-label-${matrixQuestionId}-${index}`}
                onChange={newEditorState =>
                  onMatrixQuestionLabelChange(newEditorState, matrixQuestionLabelEditorState, matrixQuestionId)
                }
                placeholder={`Question ${index + 1}`}
                showToolbar={isActive}
              />
            )
          })}
        </div>
        <div className='questions-placeholder'>
          <i className='fas fa-ellipsis-h' />
        </div>
      </div>
      {questionsError && <p className='survey-error nrs-value matrix question-list'>{questionsError}</p>}
    </div>
  )
}

MatrixQuestionsList.propTypes = {
  editorStates: PropTypes.objectOf(PropTypes.object),
  isActive: PropTypes.bool,
  item: PropTypes.shape({
    questions_order: PropTypes.arrayOf(PropTypes.string),
    questions: PropTypes.shape({
      label: PropTypes.string,
    }),
  }),
  itemId: PropTypes.string,
  preview: PropTypes.bool,
  questionsError: PropTypes.string,
  updateClinroMatrixQuestionLabel: PropTypes.func,
}

export default MatrixQuestionsList
