import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { SortableTable } from 'components/UIElements'
import STRINGS from 'utils/strings'
import { generateUpdatedTable } from '../../utils/visitDayUtils'

const HEADER_OPTIONS = [
  { key: 'name', component: 'Visit Name', sortable: false },
  { key: 'visit_day', component: 'Scheduled Day', sortable: false, className: 'visit-day-col' },
]

const VisitSchedulePreviewTable = props => {
  const { filter, isEdit, selectedVisitScheduleId, visitScheduleInitialData, templateVisit } = props

  const rowList = useMemo(() => {
    if (!selectedVisitScheduleId) return []
    return generateUpdatedTable({
      templateVisit,
      initialVisits: visitScheduleInitialData[selectedVisitScheduleId]?.visitTableMap,
      isEdit,
      filter,
    })
  }, [selectedVisitScheduleId, templateVisit])

  return (
    <div className='visit-schedule-table-preview'>
      <label className='label-small' htmlFor='current-visit-schedule-table'>
        {STRINGS.visitScheduleVisitsInSchedule}
      </label>
      <SortableTable
        emptyText={selectedVisitScheduleId ? 'Please add a visit' : 'Please select a visit schedule'}
        headerOptions={HEADER_OPTIONS}
        id='current-visit-schedule-table'
        rowList={rowList}
        sortingBy='visit_day'
      />
    </div>
  )
}

VisitSchedulePreviewTable.propTypes = {
  filter: PropTypes.shape(),
  isEdit: PropTypes.bool,
  selectedVisitScheduleId: PropTypes.string,
  templateVisit: PropTypes.shape(),
  visitScheduleInitialData: PropTypes.objectOf(PropTypes.object),
}

export default VisitSchedulePreviewTable
