import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'components/UIElements'
import { STUDY_CONFIG_MAP, USER_ROLES_MAP } from 'utils/constants'
import { getUserScopeByStudy, getUserRoleByStudy } from 'utils/misc'
import { modalActions } from 'store/modal'
import PaymentsPage from '../components/PaymentsPage'
import { actions as paymentActions } from '../modules/Payments'

const mapState = ({ user, loading, paymentsReducer, searchTerm, study, studyLock }) => {
  const { currentStudy } = study
  const studyID = currentStudy.id
  const userScope = getUserScopeByStudy(user, currentStudy)
  const userRole = getUserRoleByStudy(user, studyID)
  const hasTreasureChest = !!currentStudy.config?.super_gems
  const hasDonations = hasTreasureChest && !!currentStudy.config.super_gems.donations?.length > 0
  const hasTreasureChestGiftcards =
    hasTreasureChest && !!currentStudy.config[STUDY_CONFIG_MAP.treasureChestStoreGiftCard]

  return {
    adminName: user.name,
    canWrite: userScope.includes('wp'),
    canEditPayments: [
      USER_ROLES_MAP.root,
      USER_ROLES_MAP.studyCoordinator,
      USER_ROLES_MAP.investigator,
      USER_ROLES_MAP.siteCoordinator,
    ].includes(userRole),
    loading: loading.general,
    pendingPaymentsLoading: loading.pendingPayments,
    hasTreasureChest,
    hasDonations,
    hasTreasureChestGiftcards,
    searchTerm,
    study: study.currentStudy,
    studyConfigLoading: loading.studyConfig,
    ...paymentsReducer,
    studyLock,
  }
}

export default connect(mapState, { ...paymentActions, ...modalActions })(props => {
  if (props.loading || props.studyConfigLoading) return <Loader />
  return <PaymentsPage {...props} />
})
