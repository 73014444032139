import React from 'react'
import PropTypes from 'prop-types'
import { CollapseMenu } from 'components/UIElements'

const renderAdditionalInfoContent = (content, visitId) => {
  return content.map((contentItem, i) => {
    const { text, list } = contentItem
    return (
      <div key={`${visitId}-info-content-${text}-${i}`} className='info-content'>
        <p className='info-content-text'>{text}</p>
        {list && (
          <ul>
            {list.map((li, idx) => {
              return (
                <li key={`${visitId}-${idx}-${i}`}>
                  <span>{li}</span>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    )
  })
}

const AdditionalInfo = ({ additionalInfo, handleResize, readjustHeight, visitId }) => {
  const addInfoKeys = additionalInfo ? Object.keys(additionalInfo) : []
  const additionalInfoMenus = addInfoKeys.map((key, idx) => {
    const { heading, content } = additionalInfo[key]
    if (!content)
      return (
        <ul key={`add-info-${visitId}-${key}-${heading}-${idx}`}>
          <li>
            <span>{heading}</span>
          </li>
        </ul>
      )
    if (content.length === 0) {
      return null
    }
    return (
      <CollapseMenu
        key={`add-info-${visitId}-${key}-${heading}-${idx}`}
        titleContent={heading}
        menuContent={renderAdditionalInfoContent(content, visitId)}
      />
    )
  })
  return (
    <div
      className='visit-additional-info'
      onClick={e => {
        e.stopPropagation()
        readjustHeight()

        // /**
        //  * this adjusts the width of the information-data cell (parent of summary content)
        //  * in case expanding a additional info collapse menu triggers the scrollbar
        //  *  */
        handleResize(true) // bool param represents that the resize handling is triggered on a click action
      }}>
      {additionalInfoMenus}
    </div>
  )
}

AdditionalInfo.propTypes = {
  additionalInfo: PropTypes.objectOf(PropTypes.object),
  handleResize: PropTypes.func,
  readjustHeight: PropTypes.func,
  visitId: PropTypes.string,
}

export default AdditionalInfo
