import React from 'react'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { Button } from 'components/UIElements'
import ClinroItemContainer from '../containers/ClinroItemContainer'

const ItemList = SortableContainer(props => {
  const { clinro, errors, onSave, onAddItem, sortingItemKey, sortingItemsArr } = props
  const order = clinro.order[0]
  const itemIndex = order.length - 1
  const { domains = {}, questionsWithScoring } = clinro
  const nextPage = Object.keys(questionsWithScoring).length && Object.keys(domains).length ? 'scoring' : 'properties'

  const filteredOrder = order.filter(questionId => {
    // don't hide the current item being sorted, the library will take care of the hiding
    if (sortingItemKey === questionId) return true

    // hide the item if the item is not the item being dragged/sorted, but is a sibling of the dragged item
    if (sortingItemKey !== questionId && sortingItemsArr.includes(questionId)) {
      return false
    }

    return true
  })

  return (
    <div className='item-list'>
      <p className='survey-error'>{order.length === 0 ? errors.noQuestionsErr : ''}</p>
      {filteredOrder.map((itemId, index) => {
        if (index === 0) {
          return (
            <Item
              key={`survey-item-${itemId}-${index}`}
              index={index}
              itemIndex={index}
              itemId={itemId}
              instrumentDomains={domains}
              hideHoverBar={index === order.length - 1}
              {...props}
            />
          )
        }
        return (
          <SortableItem
            key={`survey-item-${itemId}-${index}`}
            index={index}
            itemIndex={index}
            itemId={itemId}
            instrumentDomains={domains}
            hideHoverBar={index === order.length - 1}
            {...props}
          />
        )
      })}
      <div className='survey-buttons flexed'>
        <div>
          <Button grey content='Question' icon='fas fa-plus' onClick={onAddItem('select_one', itemIndex)} />
        </div>
      </div>
      <div className='button-list'>
        <Button content='Next' onClick={() => onSave(nextPage)} />
      </div>
    </div>
  )
})

const SortableItem = SortableElement(props => {
  return <ClinroItemContainer {...props} />
})

const Item = props => {
  return <ClinroItemContainer {...props} />
}

export default ItemList
