import { combineReducers } from 'redux'
import { modalActions } from 'store/modal'
import request from 'utils/request'

const UPDATE_USERNAME_FIELD = 'UPDATE_USERNAME_FIELD'
const UPDATE_PASSWORD_FIELD = 'UPDATE_PASSWORD_FIELD'
const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'

function updateUsername(username) {
  return {
    type: UPDATE_USERNAME_FIELD,
    payload: username,
  }
}

function updatePassword(password) {
  return {
    type: UPDATE_PASSWORD_FIELD,
    payload: password,
  }
}

function forgotPassword(email) {
  return dispatch => {
    return dispatch(
      request({
        method: 'POST',
        url: '/control/users/forgot_password',
        body: JSON.stringify({ username: email, platform: 'admin' }),
        successMessage: 'Reset password email sent.',
        failMessage: 'Unable to send reset password email.',
        authRequired: false,
      }),
    )
  }
}

// reducers

function username(state = '', { type, payload }) {
  if (type === UPDATE_USERNAME_FIELD) {
    return payload
  }
  return state
}

function password(state = '', { type, payload }) {
  if (type === UPDATE_PASSWORD_FIELD) {
    return payload
  }
  return state
}

function loginError(state = '', { type, error }) {
  if (type === SET_LOGIN_ERROR) {
    return error
  }
  return state
}

export const actions = {
  updateUsername,
  updatePassword,
  forgotPassword,
  openModal: modalActions.openModal,
  closeModal: modalActions.closeModal,
}

export default combineReducers({ username, password, loginError })
