import React from 'react'
import { returnPercentage } from 'utils/misc'

const INSTRUMENT_EVENTS_MAP = {
  scheduled: 'Scheduled',
  received: 'Received',
  // seen: 'Seen', // comment back in when seen events are being sent by all platforms
  opened: 'Opened',
  completed: 'Completed',
}

const InstrumentStatusFunnelChart = ({ instrumentEvents }) => {
  const total = instrumentEvents.scheduled ? instrumentEvents.scheduled.length : 0

  const headerRow = (
    <tr>
      <td />
      <td className='bar-header' />
      <td className='text-align right label'>
        <b>PERCENTAGE</b>
      </td>
      <td className='text-align right label'>
        <b>RETAINED</b>
      </td>
    </tr>
  )

  const footerRow = (
    <tr>
      <td />
      <td />
      <td className='text-align right label'>
        <b>TOTAL SCHEDULED</b>
      </td>
      <td className='text-align right label'>
        <b>NO. OF PARTICIPANTS</b>
      </td>
    </tr>
  )
  const eventsList = Object.keys(INSTRUMENT_EVENTS_MAP)

  const renderRow = (event, ptpList, netType = false, idx) => {
    const count = ptpList ? ptpList.length : 0
    const width = returnPercentage(count, total, 1)
    let priorEvent = null
    let priorCount = total
    let difference = 0
    let differenceStr = ''
    let retention = 100

    if (event !== INSTRUMENT_EVENTS_MAP.scheduled.toLowerCase()) {
      priorEvent = eventsList[eventsList.indexOf(event) - 1]
      priorCount = instrumentEvents[priorEvent] ? instrumentEvents[priorEvent].length : 0
      difference = priorCount > 0 ? priorCount - count : 0
      differenceStr = priorCount > 0 ? `-${difference}` : ''
      retention = priorCount > 0 ? `${returnPercentage(count, priorCount, 1)}%` : ''
    }

    return netType ? (
      <tr key={`event-${netType}-${idx}`}>
        <td className='event-type' />
        <td className='bar flexed column'>
          {event !== INSTRUMENT_EVENTS_MAP.scheduled.toLowerCase() && (
            <div className='flexed center-justified net-stats'>
              <span>{`${retention}`}</span>
              <i className='fas fa-arrow-down' />
              <span className={`${difference === 0 ? 'no-decrease' : ''}`}>{`${differenceStr}`}</span>
            </div>
          )}
        </td>
        <td className='percentage text-align right' />
        <td className='retained text-align right' />
      </tr>
    ) : (
      <tr key={`event-${netType}-${idx}`}>
        <td className='event-type'>{INSTRUMENT_EVENTS_MAP[event]}</td>
        <td className='bar flexed column'>
          {width > 0 ? (
            <div className='percentage-bar' style={{ width: `${width}%` }} />
          ) : (
            <div className='no-events'>{`None ${INSTRUMENT_EVENTS_MAP[event].toLowerCase()}`}</div>
          )}
        </td>
        <td className='percentage text-align right'>{`${width}%`}</td>
        <td className='retained text-align right'>{count}</td>
      </tr>
    )
  }

  const eventRows = eventsList.map((event, idx) => {
    if (event === INSTRUMENT_EVENTS_MAP.scheduled.toLowerCase()) {
      return renderRow(event, instrumentEvents[event], false, idx)
    }
    return [renderRow(event, instrumentEvents[event], true, idx), renderRow(event, instrumentEvents[event], false, idx)]
  })

  return (
    total > 0 && (
      <div className='page-break instrument-status-chart'>
        <div>
          <h5>Status</h5>
          <table>
            <tbody>
              {headerRow}
              {eventRows}
              {footerRow}
            </tbody>
          </table>
        </div>
      </div>
    )
  )
}

export default InstrumentStatusFunnelChart
