import React from 'react'
import PropTypes from 'prop-types'
import { TextOverflow } from 'components/UIElements'
import useComponentVisible from 'utils/hooks/useComponentVisible'
import 'styles/d3/expandableList.scss'

const ExpandableList = props => {
  const { label, list, className, showCount, cutLabel, width } = props
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  if (!list?.length) return null
  if (list.length === 1)
    return (
      <div className='expandable-list-wrap'>
        <TextOverflow
          width={160}
          textWrapper={`expandable-list ${className}`}
          text={`${cutLabel ? label.slice(0, -1) : label} ${showCount ? ` (${list.length})` : ''}: ${list[0]}`}
          popupText={list[0]}
          className='expandable-list-indent'
        />
      </div>
    )

  return (
    <div
      className={`expandable-list expandable-list-indent ${className} ${
        isComponentVisible ? 'expandable-list-visible' : ''
      }`}
      ref={ref}
      onClick={e => e.stopPropagation()}
      style={{ width: `${width}px` }}>
      <div
        className={`expandable-list-label ${list.length > 1 ? 'pointer' : ''}`}
        onClick={() => setIsComponentVisible(prev => !prev)}>
        <span className='strong'>
          {label} ({list.length})
        </span>
        {list.length > 1 && <i className={`fas fa-caret-${isComponentVisible ? 'down' : 'right'}`} />}
      </div>
      {isComponentVisible && list.length > 1 && <div className='expandable-list-item'>{list.join(', ')}</div>}
    </div>
  )
}

ExpandableList.defaultProps = {
  className: '',
  showCount: true,
  cutLabel: true,
  width: 160,
}

ExpandableList.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  showCount: PropTypes.bool,
  cutLabel: PropTypes.bool,
  width: PropTypes.number,
}

export default ExpandableList
