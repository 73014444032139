import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AutocompleteSearch, Notes } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import Tags from '../../CreateParticipant/components/Tags'

const RolloverTrackSelector = ({ checkedTracks, checkTrack, notes, trackList, tags, setTags }) => {
  useEffect(() => {
    if (Object.keys(checkedTracks).length < 1) {
      setTags([])
    }
  }, [checkedTracks])

  const onToggleTrack = id => {
    const newTracks = checkedTracks
    if (id in newTracks) delete newTracks[id]
    else newTracks[id] = null
    const trackListSelected = trackList.filter(({ key }) => Object.keys(newTracks).includes(key + ''))
    setTags(trackListSelected)
    checkTrack(newTracks)
  }

  return (
    <div className='tracks'>
      {tags.length > 0 ? (
        <>
          <p>
            <b>{tags.length}</b> {`${pluralize(tags.length, 'track', 'tracks', false)}`} assigned
          </p>
          <Tags toggle={onToggleTrack} tags={tags} />
        </>
      ) : null}
      <AutocompleteSearch
        checked={checkedTracks}
        disabled={trackList.length < 1}
        useCaretIcon
        toggleItem={onToggleTrack}
        hideSearchIcon
        id='move-participant-search-track'
        placeholder='Select a track'
        className='w-100'
        list={trackList.sort((a, b) => a.text.localeCompare(b.text))}
      />
      {notes && <Notes {...notes} />}
    </div>
  )
}

RolloverTrackSelector.propTypes = {
  checkedTracks: PropTypes.object,
  checkTrack: PropTypes.func,
  notes: PropTypes.object,
  trackList: PropTypes.array,
}

export default RolloverTrackSelector
