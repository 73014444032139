import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, TimePicker } from 'components/UIElements'
import { SelectRange } from './DiaryNumericInputRange'

const TimeInputRange = props => {
  const {
    clearQuestionError,
    displayComponent,
    errors = {},
    isDateTime,
    isExactRangeTab,
    item,
    itemId,
    preview,
    timeDisplayComponent,
    updateItem,
  } = props
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (item.input_validation && Object.keys(item.input_validation).length) {
      setIsExpanded(true)
    }
  }, [])

  const MOMENT_FORMAT = 'HH:mm'
  const example = <div className='example'>{displayComponent}</div>
  const hasError = errors.timeInputRangeError

  const itemWithDefaults = {
    ...item,
    input_validation: {
      absolute_time: {
        min: '',
        max: '',
      },
      isExactRangeTab,
      ...item.input_validation,
    },
  }

  const updateInput = (key, val, key2, val2) => {
    const prevInputValidation = itemWithDefaults.input_validation
    const prevAbsTime = prevInputValidation.absolute_time
    if (hasError) {
      clearQuestionError(itemId)
    }
    const newItem = {
      ...item,
      input_validation: {
        ...prevInputValidation,
        absolute_time: { ...prevAbsTime, [key]: val ? val.format(MOMENT_FORMAT) : '' },
      },
    }
    if (!!key2 && !!val2) {
      newItem.input_validation.absolute_time[key2] = val2 ? val2.format(MOMENT_FORMAT) : ''
    }
    updateItem(itemId, newItem)
  }

  const resetInputValidation = () => {
    const newItem = {
      ...item,
      input_validation: {},
    }
    updateItem(itemId, newItem)
  }

  const timeSelectRange = (
    <SelectRange
      exampleComponent={timeDisplayComponent}
      description='Accepted Time Range'
      innerDivClassName={hasError ? 'with-error' : ''}>
      <div className='flexed column end-aligned'>
        <div className='flexed start-justified input-area'>
          <span>From</span>
          <TimePicker
            allowEmpty
            disabled={preview}
            hasError={!!hasError}
            value={
              itemWithDefaults.input_validation.absolute_time.min
                ? moment(itemWithDefaults.input_validation.absolute_time.min, MOMENT_FORMAT)
                : ''
            }
            onChange={val => {
              if (itemWithDefaults.input_validation.absolute_time.max) {
                /**
                 * This boolean is meant to check if the "from" time is after the "to" time.
                 * If it is, we change the "to" time to the value of the "from" time.
                 *   */
                const maxIsBeforeMin = moment(
                  itemWithDefaults.input_validation.absolute_time.max,
                  MOMENT_FORMAT,
                ).isBefore(val)

                if (maxIsBeforeMin) {
                  updateInput('min', val, 'max', val)
                } else {
                  updateInput('min', val)
                }
              } else {
                updateInput('min', val)
              }
            }}
          />
          <span>to</span>
          <TimePicker
            allowEmpty
            disabled={preview}
            disablePriorToTime={
              itemWithDefaults.input_validation.absolute_time.min
                ? moment(itemWithDefaults.input_validation.absolute_time.min, MOMENT_FORMAT)
                : ''
            }
            hasError={!!hasError}
            value={
              itemWithDefaults.input_validation.absolute_time.max
                ? moment(itemWithDefaults.input_validation.absolute_time.max, MOMENT_FORMAT)
                : ''
            }
            onChange={val => {
              updateInput('max', val)
            }}
          />
        </div>
        {hasError && <p className='error'>{hasError}</p>}
      </div>
    </SelectRange>
  )

  return (
    <>
      {isDateTime ? (
        timeSelectRange
      ) : (
        <div className='non-select-preview time-input-range'>
          <div className='flexed space-between-justified'>
            {!isExpanded ? example : <div />}
            {!preview && (
              <Button
                noStyling
                onClick={() => {
                  if (isExpanded) {
                    resetInputValidation()
                  }
                  setIsExpanded(!isExpanded)
                }}
                content={isExpanded ? 'Remove input range' : 'Set input range'}
              />
            )}
          </div>
          {isExpanded && timeSelectRange}
        </div>
      )}
    </>
  )
}

TimeInputRange.propTypes = {
  clearQuestionError: PropTypes.func,
  displayComponent: PropTypes.node,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  isDateTime: PropTypes.bool,
  isExactRangeTab: PropTypes.bool,
  item: PropTypes.shape({
    input_validation: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.object])),
  }),
  itemId: PropTypes.string,
  preview: PropTypes.bool,
  timeDisplayComponent: PropTypes.node,
  updateItem: PropTypes.func,
}

export default TimeInputRange
