import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { modalActions } from 'store/modal'
import { setNavBarProps } from 'store/navbar'
import { getUserScope } from 'utils/misc'
import { actions } from '../../CasesPage/modules/Cases'
import EditCasePage from '../components/EditCasePage'

const mapStateToProps = state => {
  const { cases, loading, user, study, studyLock } = state
  const studyID = study.currentStudy.id
  const userScope = getUserScope(user, studyID)
  return {
    loading: loading.general,
    currentCase: cases.currentCase,
    canWrite: userScope.includes('xp'),
    isNoPiiUser: userScope.includes('rp-no-pii'),
    studyLock,
  }
}

const mapDispatchToProps = { ...actions, ...modalActions, setNavBarProps }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { loading, currentCase } = props
  const noCase = Object.entries(currentCase).length === 0 && currentCase.constructor === Object
  if (loading || noCase) return <Loader />
  return <EditCasePage {...props} />
})
