import React from 'react'
import { Input, Dropdown } from 'components/UIElements'
import { pluralize, handleNaN } from 'utils/misc'
import { CURRENCY_MAP, REWARD_TYPE_MAP, DURATION_TYPE_MAP } from 'utils/constants'
import { studyPropTypes } from './RewardConfig'

const CycleRewardConfig = props => {
  const { configObj, disabled, saveAttempted, updateConfig } = props
  const { studyDuration, currency, cycleDuration, target, reward, durationType, rewardType } = configObj.cycles[0]
  const getUpdateCycles = key => value =>
    updateConfig({ cycles: [{ ...configObj.cycles[0], [key]: parseInt(value, 10) || '' }] })

  const studyDurationInDays = durationType === DURATION_TYPE_MAP.weeks ? studyDuration * 7 : studyDuration
  const invalidDurations = cycleDuration !== '' && studyDuration !== '' && studyDurationInDays < cycleDuration

  const usesCashRewards = rewardType === REWARD_TYPE_MAP.cash
  const currencySymbol = CURRENCY_MAP[currency] || '$'
  const rewardValue = val => {
    let newVal
    if (val === '') {
      newVal = ''
    } else if (rewardType === REWARD_TYPE_MAP.gems || rewardType === REWARD_TYPE_MAP.superGems) {
      newVal = Math.floor(val)
    } else newVal = Math.round(val) / 100
    return newVal
  }
  const rewardOnChange = val => {
    let newVal
    if (val === '') {
      newVal = ''
    } else if (rewardType === REWARD_TYPE_MAP.gems || rewardType === REWARD_TYPE_MAP.superGems) {
      newVal = Math.floor(val)
    } else {
      newVal = parseInt(val * 100, 10)
    }
    return newVal
  }

  return (
    <div className='cycles-config'>
      <p className='label-small'>Study Duration</p>
      <p className='label'>What is the length of the study?</p>
      <div className='flexed start-justified start-aligned study-duration'>
        <Input
          className='study-duration'
          inline
          hasError={saveAttempted && (!studyDuration || invalidDurations)}
          errorText={
            !studyDuration ? 'Cannot be empty' : 'Study duration must be greater or equal to than chapter duration.'
          }
          isPositive
          onlyInteger
          onBlur={e => handleNaN(e)(() => getUpdateCycles('studyDuration')(1))}
          disabled={disabled}
          id='study-duration-input'
          type='number'
          value={studyDuration || ''}
          onChange={getUpdateCycles('studyDuration')}
        />
        <Dropdown
          id='study-day-week-select'
          disabled={disabled}
          selected={durationType}
          options={[
            { key: 'weeks', value: 'weeks', text: pluralize(studyDuration, 'Week', 'Weeks', false) },
            { key: 'days', value: 'days', text: pluralize(studyDuration, 'Day', 'Days', false) },
          ]}
          onSelect={item => {
            const newCycle = { ...configObj.cycles[0], durationType: item.value }
            if (item.value === 'days' && studyDuration < cycleDuration) {
              newCycle.cycleDuration = studyDuration
            }
            updateConfig({ cycles: [newCycle] })
          }}
        />
      </div>
      <p className='label-small'>Chapters</p>
      <p className='label'>
        How long would you like each Chapter to be? Chapters divide your study into smaller chunks to reward
        participants sooner and more often. We generally recommend 14 days per Chapter.
      </p>
      <Input
        errorText={
          !cycleDuration ? 'Cannot be empty' : 'Chapter duration must be less than or equal to study duration.'
        }
        isPositive
        hasError={saveAttempted && (!cycleDuration || invalidDurations)}
        posttext='day chapter'
        disabled={disabled}
        id='chapter-input'
        type='number'
        value={cycleDuration}
        onChange={getUpdateCycles('cycleDuration')}
      />
      <CyclesSummary {...configObj.cycles[0]} />
      <p className='label-small'>Each Chapter Reward Requirements</p>
      <div className='cycle-requirements'>
        <div>
          <p className='label'>
            How many instruments (between 2 and 14) in a chapter should a participant complete to unlock a reward?
          </p>
        </div>
        <div className='cycle-dropdown-p'>
          <span className='pretext'>Complete the first</span>
          <Dropdown
            selected={target}
            options={[
              { key: 2, value: 2, text: '2' },
              { key: 3, value: 3, text: '3' },
              { key: 4, value: 4, text: '4' },
              { key: 5, value: 5, text: '5' },
              { key: 6, value: 6, text: '6' },
              { key: 7, value: 7, text: '7' },
              { key: 8, value: 8, text: '8' },
              { key: 9, value: 9, text: '9' },
              { key: 10, value: 10, text: '10' },
              { key: 11, value: 11, text: '11' },
              { key: 12, value: 12, text: '12' },
              { key: 13, value: 13, text: '13' },
              { key: 14, value: 14, text: '14' },
            ]}
            disabled={disabled}
            id='reward-instrument-input'
            type='number'
            value={target}
            onSelect={item => {
              getUpdateCycles('target')(item.value)
            }}
          />
          <span className='posttext'>{`instruments in a ${cycleDuration} day chapter`}</span>
        </div>
      </div>
      <p>{`What is the reward amount (max ${currencySymbol}100 for payments)?`}</p>
      <Input
        className='reward-amount'
        step='1'
        preventInput={num => {
          if (usesCashRewards) {
            return num < 1 || num > 100
          }
          return num < 1 || num > 9999
        }}
        errorText='Cannot be empty'
        hasError={saveAttempted && !reward}
        pretext={usesCashRewards ? currencySymbol : ''}
        posttext={rewardType === REWARD_TYPE_MAP.gems ? 'gem(s)' : ''}
        disabled={disabled}
        id='reward-gems-input'
        type='number'
        value={rewardValue(reward)}
        onChange={val => getUpdateCycles('reward')(rewardOnChange(val))}
        onBlur={e => handleNaN(e)(() => getUpdateCycles('reward')(rewardOnChange(1)))}
      />
    </div>
  )
}

const CyclesSummary = ({ studyDuration, cycleDuration, durationType }) => {
  const timeInterval = durationType === DURATION_TYPE_MAP.weeks ? ['week', 'weeks'] : ['day', 'days']
  const studyDurationInDays = durationType === DURATION_TYPE_MAP.weeks ? studyDuration * 7 : studyDuration
  const validDurations = studyDuration !== '' && cycleDuration !== '' && studyDurationInDays / cycleDuration >= 1
  const numCycles = Math.ceil(studyDurationInDays / cycleDuration)
  const remainder = studyDurationInDays % cycleDuration
  const note =
    remainder === 0 ? '' : ` - note: the last chapter will be only be ${pluralize(remainder, 'day', 'days')} long.`

  return (
    <div className='cycles-summary'>
      <span>
        <i className='far fa-calendar' />
        Your study's total duration is
        <b>{`${validDurations ? pluralize(studyDuration, ...timeInterval) : '--- days'}`}</b>
        {`(${validDurations ? pluralize(numCycles, 'Chapter', 'Chapters') + note : '---'})`}
      </span>
    </div>
  )
}

CycleRewardConfig.propTypes = studyPropTypes
CyclesSummary.propTypes = studyPropTypes

export default CycleRewardConfig
