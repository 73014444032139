import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/sadCloud.scss'

const img = require('../assets/sadcloud.png')

const SadCloud = ({ text = '' }) => {
  return (
    <div className='sad-cloud'>
      <img src={img} alt='empty' />
      <h3>{text}</h3>
    </div>
  )
}

SadCloud.propTypes = {
  text: PropTypes.string,
}

export default SadCloud
