import { viewActions } from 'store/view'
import { setSubroute } from 'store/navbar'
import InstrumentsViewRoute from './routes/InstrumentsPage'
import InstrumentViewRoute from './routes/Instrument'

// Sync route definition
export default store => {
  store.dispatch(viewActions.setNormalWidth())
  return {
    path: 'instruments',
    exact: true,
    indexRoute: InstrumentsViewRoute(store),
    getChildRoutes(location, cb) {
      store.dispatch(setSubroute('instruments'))
      store.dispatch(viewActions.setFullWidth())
      cb(null, [InstrumentViewRoute(store)])
    },
  }
}
