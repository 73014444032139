import { connect } from 'react-redux'
import ActivateUserPage from '../../ActivateUser/components/ActivateUserPage'
import PermissionDenied from '../../../../../../../../Unauthorized/components/PermissionDenied'
import { actions } from '../../ActivateUser/modules/ActivateUser'

const mapStateToProps = state => {
  const { resetPassword, user } = state
  return { ...resetPassword, ...user }
}

const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.userToken || props.token) {
    return ActivateUserPage(props, true)
  }
  return PermissionDenied(props)
})
