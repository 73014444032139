import React from 'react'
import PropTypes from 'prop-types'
import { statusMap } from '../utils/visitUtils'

export const VisitStatusCell = ({ value, idx, isDiv }) => {
  if (isDiv) {
    return (
      <div className='visit-status-data'>
        <div className='tag flexed center-aligned'>
          {statusMap[value].icon}
          <span>{statusMap[value].text}</span>
        </div>
      </div>
    )
  }
  return (
    <td key={`visit-status-cell-${value}-${idx}`} className='visit-status-data'>
      <div className='tag flexed center-aligned'>
        {statusMap[value].icon}
        <span>{statusMap[value].text}</span>
      </div>
    </td>
  )
}
VisitStatusCell.propTypes = {
  idx: PropTypes.number,
  isDiv: PropTypes.bool,
  value: PropTypes.string,
}

export const ChevronCell = ({ idx }) => {
  return (
    <td className='chevron' key={`col_${idx}`}>
      <i className='fas fa-chevron-right' />
    </td>
  )
}
ChevronCell.propTypes = {
  idx: PropTypes.number,
}

export const SiteCell = ({ idx, value, leafSiteName }) => {
  return (
    <td className='site-data' key={`col_${idx}`}>
      <div className='site-name'>{leafSiteName}</div>
      <p className='site-info'>
        <span className='site-id-label'>Site ID</span>
        <span className='site-id'>{value}</span>
      </p>
    </td>
  )
}
SiteCell.propTypes = {
  idx: PropTypes.number,
  leafSiteName: PropTypes.string,
  value: PropTypes.number,
}
