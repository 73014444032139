import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { SortableTable } from 'components/UIElements'
import STRINGS from 'utils/strings'
import { PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP } from '../utils/participantVisitConstants'

function ParticipantVisitScheduleTable(props) {
  const {
    dayChanged,
    dayDiff,
    participantVisit,
    participantVisitsArray,
    participantVisitsMap,
    ptpVisitsLoading,
    pushSubsequent,
    showDeviationsOnly,
    updatePtpVisits,
  } = props

  const headerOptionsSeed = Object.keys(PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP).map(key => ({
    key,
    sortable: false,
    component: PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP[key].text,
  }))

  const getHeaderOptions = () => {
    const newHeaderOptions = [...headerOptionsSeed]
    return newHeaderOptions
  }

  const { checkedPtp } = participantVisit
  const isPtpChecked = Object.keys(checkedPtp).length > 0

  /**
   * This effect triggers the update of the participant visit list
   */
  useEffect(() => {
    updatePtpVisits({
      participantVisit,
      dayChanged,
      pushSubsequent,
      dayDiff,
      participantVisitsMap,
    })
  }, [participantVisit, pushSubsequent, dayChanged, dayDiff])

  if (showDeviationsOnly) {
    const filteredList = participantVisitsArray.filter(item => {
      const proposedDayIndex = PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.proposedDay.index
      return item[proposedDayIndex].hasDeviation
    })
    return (
      isPtpChecked && (
        <SortableTable
          emptyText='No Matching Participant Visits'
          headerOptions={getHeaderOptions()}
          id='participant-visit-table'
          initialOrder='descending'
          rowList={filteredList}
          sortingBy={PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.scheduleDay.key}
          viewMoreWithTablePages={{ minRows: 10, maxRows: 10 }}
          loadingBody={ptpVisitsLoading}
        />
      )
    )
  }

  return (
    isPtpChecked && (
      <div className='ptp-visit-table'>
        <p className='label-small'>{STRINGS.visitScheduleVisitsInSchedule}</p>
        <SortableTable
          emptyText='No Matching Participant Visits'
          headerOptions={getHeaderOptions()}
          id='participant-visit-table'
          initialOrder='descending'
          rowList={participantVisitsArray}
          sortingBy={PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.scheduleDay.key}
          viewMoreWithTablePages={{ minRows: 10, maxRows: 10 }}
          loadingBody={ptpVisitsLoading}
        />
      </div>
    )
  )
}

ParticipantVisitScheduleTable.propTypes = {
  dayChanged: PropTypes.bool,
  dayDiff: PropTypes.number,
  participantVisit: PropTypes.shape({
    adhocVisitSchedule: PropTypes.shape({
      specificDate: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
    checkedPtp: PropTypes.objectOf(PropTypes.bool),
    visit_metadata: PropTypes.shape({}),
  }),
  ptpVisitsLoading: PropTypes.bool,
  pushSubsequent: PropTypes.bool,
  showDeviationsOnly: PropTypes.bool,
  updatePtpVisits: PropTypes.func,
  updatePtpVisitMetadata: PropTypes.func,
}

export default ParticipantVisitScheduleTable
