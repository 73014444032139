import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, SortableTable } from 'components/UIElements'
import { createMomentObject } from 'utils/time'
import { DATE_FORMAT_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import StatusHistorySection from './StatusHistorySection'

const noDiariesString = 'There are no pending diaries for this participant.'

const CompletedDiaries = props => {
  const {
    baseSiteID,
    diaryStatus,
    diaryTimeline,
    diaryTimelineLoading,
    downloadParticipantData,
    fetchDiaryTimeline,
    hasAdvancedEventTimelines,
    ptpId,
    resetDiaryTimeline,
    studyID,
    site,
    mainSite,
  } = props
  const [expand, setExpand] = useState(false)
  const [isStatusRowExpanded, setIsStatusRowExpanded] = useState({})
  const [currentPage, setCurrentPage] = useState({ pageNum: 1, list: 20 })
  const hasDiaries = diaryStatus.length > 0
  const headerOptions = [
    { key: 'name', sortable: true, component: 'Diary Name' },
    { key: 'scheduleDate', sortable: true, component: 'Date Scheduled' },
    {
      key: 'completionDate',
      sortable: true,
      component: 'Completion',
      iconClassName: 'fas fa-info-circle',
      popupText: "Participant's local time",
    },
    { key: 'action', sortable: false, component: 'Action' },
  ]

  const setTablePages = () => {
    const diaryByFloor = Math.floor(diaryStatus.length / 20)
    const numberOfPages = diaryByFloor < diaryStatus.length / 20 ? diaryByFloor + 1 : diaryByFloor
    const pageArr = []
    for (let pageNum = 1; pageNum <= numberOfPages; pageNum++) {
      pageArr.push(
        <Button
          noStyling
          className={`page-button ${pageNum === currentPage.pageNum ? 'current-page-number' : ''}`}
          key={`tablePage-${pageNum}`}
          onClick={() => setCurrentPage({ pageNum, list: 20 * pageNum })}>
          {pageNum}
        </Button>,
      )
    }
    return pageArr
  }

  const renderFunctions = {
    completionDate: (row, idx) => {
      const { completed, value } = row
      return (
        <td className='completion-cell' key={`completion-date-${row}-${idx}`}>
          {completed ? (
            <>
              <i className='fas fa-check' /> <span>{value}</span>
            </>
          ) : (
            <i className='fas fa-times' />
          )}
        </td>
      )
    },
    action: ({ row }) => {
      return (
        <td className='download-cell'>
          <Button
            onClick={e => {
              e.stopPropagation()
              const scheduleDate = createMomentObject(new Date(row[1].value)).format(DATE_FORMAT_MAP.main)
              downloadParticipantData({
                type: 'diary',
                studyId: studyID,
                ptpIdArr: [ptpId],
                siteId: baseSiteID,
                scheduleDate,
                formatType: 'wide',
              })
            }}
            link
            icon='fas fa-cloud-download-alt'
          />
        </td>
      )
    },
  }

  const expandedRow = row => {
    const { idqId } = row[row.length - 1]
    return (
      <StatusHistorySection
        instrumentTimeline={diaryTimeline}
        instrumentId={idqId}
        fetchInstrumentTimeline={fetchDiaryTimeline}
        resetInstrumentTimeline={resetDiaryTimeline}
        instrumentTimelineLoading={diaryTimelineLoading}
        onClose={() => {
          setIsStatusRowExpanded({})
          resetInstrumentTimeline()
        }}
        noStatusMessage={STRINGS.noDiarytatus}
        site={site}
        mainSite={mainSite}
        withUtcOffset
      />
    )
  }

  return (
    <Container className='diaries-table'>
      <div className='title'>Diary</div>
      {hasDiaries ? (
        <>
          <div className='flexed end-justified'>
            <Button
              content='All Responses (Wide Format)'
              iconBefore='fas fa-download'
              onClick={() =>
                downloadParticipantData({
                  type: 'diary',
                  studyId: studyID,
                  ptpIdArr: [ptpId],
                  siteId: baseSiteID,
                  formatType: 'wide',
                })
              }
            />
          </div>
          <SortableTable
            headerOptions={headerOptions}
            sortingBy='name'
            rowList={!expand ? diaryStatus.slice(0, 7) : diaryStatus.slice(currentPage.list - 20, currentPage.list)}
            renderFunctions={renderFunctions}
            onRowClick={
              hasAdvancedEventTimelines
                ? row => {
                    const { id } = row[row.length - 1]
                    setIsStatusRowExpanded({
                      [id]: !isStatusRowExpanded[id],
                    })
                  }
                : null
            }
            rowExpandOptions={{
              isRowExpanded: row => {
                const { id } = row[row.length - 1]
                return isStatusRowExpanded[id]
              },
              expandedRowClassName: 'expanded',
            }}
            expandedRow={expandedRow}
          />
          {expand && <div className='page-numbers flexed center-justified'>{setTablePages()}</div>}
          {diaryStatus.length > 7 && (
            <div className='flexed center-justified'>
              <Button
                className='expand-button'
                content={expand ? 'Hide entries' : 'View more entries'}
                onClick={() => {
                  if (!expand) setCurrentPage({ pageNum: 1, list: 20 })
                  setExpand(!expand)
                }}
                noStyling
              />
            </div>
          )}
        </>
      ) : (
        <p>{noDiariesString}</p>
      )}
    </Container>
  )
}

CompletedDiaries.propTypes = {
  baseSiteID: PropTypes.number,
  diaryStatus: PropTypes.arrayOf(PropTypes.array),
  downloadParticipantData: PropTypes.func,
  hasAdvancedEventTimelines: PropTypes.bool,
  studyID: PropTypes.number,
  ptpId: PropTypes.number,
}

export default CompletedDiaries
