import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'components/UIElements'
import VisitDaySelector from './VisitDaySelector'

const VisitScheduleAndDaySelector = props => {
  const { disabled, isEdit, selectedVisitScheduleId, setSelectedVisitScheduleId, visitScheduleOptions } = props

  return (
    <div className='visit-schedule-and-day-selector'>
      <label className='label-small' htmlFor='visit-schedule-selection'>
        Visit Schedule Name
      </label>
      <div>
        <Dropdown
          id='visit-schedule-selection-dropdown'
          disabled={isEdit || disabled}
          onSelect={option => {
            setSelectedVisitScheduleId(option.key)
          }}
          options={visitScheduleOptions}
          placeholder='Please select a visit schedule'
          selected={selectedVisitScheduleId}
        />
      </div>
      <VisitDaySelector {...props} />
    </div>
  )
}

VisitScheduleAndDaySelector.propTypes = {
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
  selectedVisitScheduleId: PropTypes.string,
  setSelectedVisitScheduleId: PropTypes.func,
  visitScheduleOptions: PropTypes.arrayOf(PropTypes.object),
  visitScheduleInitialData: PropTypes.shape({
    visitOptions: PropTypes.array,
  }),
}

export default VisitScheduleAndDaySelector
