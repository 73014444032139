import { connect } from 'react-redux'
import React from 'react'
import Loader from 'components/D3Loader'
import { setNavBarProps } from 'store/navbar'
import { getBaseSiteIdFromStudy, getUserRoleByStudy, getUserScopeByStudy, getUserRoleIdByStudy } from 'utils/misc'
import { USER_ROLE_ID_MAP, USER_ROLES_MAP, USER_SCOPES } from 'utils/constants'
import { PERMISSION_FOR_VIEWING_PARTICIPANT_VISITS } from 'utils/userPermissionConstants'
import { checkIsEmailless } from 'utils/studyConfigUtils'
import CreateParticipantPage from '../components/CreateParticipantPage'
import { actions } from '../modules/CreateParticipant'
import { actions as ptpActions, downloadBulkData } from '../../ParticipantsPage/modules/Participants'
import { actions as siteActions } from '../../../../Sites/routes/SitesPage/modules/Sites'
import {
  downloadParticipantConsent,
  fetchConsentEvents,
} from '../../../../Consent/routes/ConsentEditor/modules/Consent'
import {
  downloadCompletedParticipantConsent,
  uploadPtpConsentAttachments,
} from '../../../../Consents/routes/ConsentsDashboard/modules/Consents'

const mapStateToProps = (state, ownProps) => {
  const {
    consents,
    user,
    participantReducer,
    loading,
    sending,
    tableFilters,
    sites,
    location,
    study,
    studyLock,
    userReducer,
  } = state
  const { currentStudy, orphanedParticipants } = study
  const { consent_required } = currentStudy
  const { participant, files } = participantReducer
  const isCreate = state.location.pathname.includes('create')
  const studyID = parseInt(ownProps.params.studyID, 10)
  const userScope = getUserScopeByStudy(user, currentStudy)
  const currentUserRole = getUserRoleByStudy(user, studyID)
  const hasMarkInstrumentComplete = !!currentStudy.config?.mark_instrument_complete
  const hasTreasureChest = !!currentStudy.config?.super_gems
  const { sendingState, sentState } = sending
  const sendDisabled = sendingState || sentState
  const isDiary = !!currentStudy.config?.ediary
  const canViewVisitSchedule = PERMISSION_FOR_VIEWING_PARTICIPANT_VISITS.some(scope => userScope.includes(scope))
  const hasVisitSupport = !!currentStudy.config?.visit_support
  const hasStartVisitId = !!currentStudy.config?.start_visit_id
  const hasVisitStartDatetime = !!currentStudy.config?.visit_start_datetime
  const roleId = getUserRoleIdByStudy(user, studyID)
  const hasIncentives = currentStudy.config?.incentives?.enabled
  const sitesInfo = () => {
    if (!userReducer?.userSites) return {}
    const newSitesInfo = { ...userReducer.userSites }
    Object.keys(newSitesInfo).forEach(key => {
      if (newSitesInfo[key].level === 1) delete newSitesInfo[key]
    })
    return newSitesInfo
  }
  const participantDataFields = currentStudy.config?.participant_data_fields
  const isEmailless = checkIsEmailless(currentStudy)
  const noTracksFeature = currentStudy.config?.tracks?.disabled === true
  const hasSubjectIdValidation = currentStudy.config?.study_validations?.participant_subject_id_uniqueness?.enabled

  return {
    ...participantReducer,
    canAddPtp: userScope.includes('wxp'),
    canMove: userScope.includes('mp'),
    canResendInviteOrResetPw: userScope.includes('rpe'),
    canSeeOrphans: [USER_ROLES_MAP.root, USER_ROLES_MAP.studyCoordinator, USER_ROLES_MAP.investigator].includes(
      currentUserRole,
    ),
    announcementTimelineLoading: loading.announcementTimeline,
    canDownloadConsent: userScope.includes('xdc'),
    canUpdateConsent: userScope.includes('xc'),
    canUploadConsentAttachment: userScope.includes(USER_SCOPES.xcu.code),
    canViewAnnouncements: userScope.includes('xp'),
    canViewFiles: userScope.includes('xp'),
    canViewVisitSchedule,
    canWrite: userScope.includes('wp'),
    checkedSites: sites.checkedSites,
    componentLoading: loading,
    consentRequired: consent_required,
    diaryTimelineLoading: loading.diaryTimeline,
    files,
    filterProps: tableFilters,
    hasIncentives,
    hasMarkInstrumentComplete,
    hasOrphanedParticipants: orphanedParticipants,
    hasRewards: ['cycles', 'reward_goal', 'reward_duration'].some(key => key in study && study[key] !== null),
    hasStartVisitId,
    hasSubjectIdValidation,
    hasVisitStartDatetime,
    hasVisitSupport,
    inLeafSite: participant.in_leaf_site,
    instrumentTimelineLoading: loading.instrumentTimeline,
    isCreate,
    isDiary,
    isEmailless,
    isInParticipantsTable: location.pathname.endsWith('/participants'),
    isSiteCoord: roleId === USER_ROLE_ID_MAP.siteCoordinator || roleId === USER_ROLE_ID_MAP.clinicalCoordinator,
    leafSites: sites.leafSites,
    loading: loading.general,
    noPII: userScope.includes('rp-no-pii'),
    noTracksFeature,
    ptpAnnouncementsLoading: loading.participantAnnouncements,
    ptpConsentAttachmentLoading: loading.consentAttachment,
    ptpId: ownProps.ptpId ? ownProps.ptpId : participant.id,
    ptpInstrumentsLoading: loading.participantInstruments,
    ptpMoveLoading: loading.moveParticipants,
    requestSending: sendingState,
    requestSent: sentState,
    sendDisabled,
    siteSelectionList: sites.siteSelectionList,
    sitesLanguages: sites.sitesLanguages,
    study: currentStudy,
    studyID,
    studyLock,
    tempPwdLoading: loading.tempPassword,
    /**
     * the logic used in the following lines is to get the site ID values depending on
     * whether the participant page is in the preview drawer or in its own route
     */
    baseSiteID: ownProps.siteID || getBaseSiteIdFromStudy(state.study),
    ptpSiteID: ownProps.ptpSiteID || (isCreate ? null : parseInt(ownProps.params.siteID, 10)),
    hasTreasureChest,
    participantDataFields,
    sitesInfo: sitesInfo(),
    userId: user.id,
    visitStartDatetime: participant?.visit_start_datetime,
    unCompleteBlockedConsents: consents?.unCompletedBlockedConsents || {},
  }
}

const mapDispatchToProps = {
  ...actions,
  ...siteActions,
  ...ptpActions,
  setNavBarProps,
  downloadBulkData,
  downloadParticipantConsent,
  downloadCompletedParticipantConsent,
  uploadPtpConsentAttachments,
  fetchConsentEvents,
  fetchPtp: (studyId, siteId, ptpId) => actions.fetchParticipant(studyId, siteId, ptpId),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.loading) {
    return Loader(props)
  }
  return <CreateParticipantPage {...props} />
})
