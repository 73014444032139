import { connect } from 'react-redux'
import ClinroItem from '../components/ClinroItem'
import { itemActions, imageActions } from '../modules/Clinro'
import { formatLinkEntities } from 'utils/draft'

const mapDispatchToProps = { ...itemActions, ...imageActions }

const mapStateToProps = (state, ownProps) => {
  const { itemId, instrumentDomains } = ownProps
  const { itemIndex, sortingItemsArr } = ownProps
  const { clinroEditor } = state
  const { clinro } = clinroEditor
  const { siblings } = clinro
  const item = clinroEditor.clinro.questions[itemId]
  const { sibling_id } = item
  const isFirstSibling = sibling_id ? siblings[sibling_id].indexOf(itemId) === 0 : false
  return {
    editorState: formatLinkEntities(clinroEditor.editorStates[itemId]),
    errors: clinroEditor.clinroErrors.questions[itemId] || {},
    instrumentDomains,
    isActive: ownProps.activeItemId === itemId,
    isFirstSibling,
    item,
    itemId,
    itemIndex,
    preview: state.location.pathname.includes('preview'),
    siblings,
    sortingItemsArr,
    questionNumber: clinroEditor.clinro.questions[itemId].attributes.questionNumber,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinroItem)
