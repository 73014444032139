import { useEffect } from 'react'
import { convertToMB } from 'utils/misc'

const MEDIA_VALIDATION_MAP = {
  image: {
    maxSize: 2500000,
    errorText: 'Your image exceeded 2.5 MB. Please reduce the file size.',
  },
  resources: {
    maxSize: 15000000,
    errorText: `A survey can only support 15 MB of files.  There is [remainingSpace] MB left for this survey `,
  },
  invalidType: {
    errorText: `Please upload a file with the correct extension ([extensions])`,
  },
  media: {
    maxSize: 2000000,
    errorText: 'Your file exceeded 2.0 MB. Please reduce the file size.',
  },
}

const useMediaError = ({ file, setError, setFile, currentResourcesSizeCount, accept, isImage }) => {
  useEffect(() => {
    const { size, name } = file
    const extension = name
      ? `.${name
          .split('.')
          .pop()
          .toLowerCase()}`
      : undefined

    /**
     * Only surveys current have resource_size_list to calcuate the current capacity, so the first
     * conditional is for surveys only. When clinician instruments can have other media type uploads, they
     * can be included in the first conditional.
     */
    if (currentResourcesSizeCount || currentResourcesSizeCount === 0) {
      // Error validation for incorrect file type
      if (extension && !accept.includes(extension)) {
        const validExtensionStr = accept.join(', ')
        setError(MEDIA_VALIDATION_MAP.invalidType.errorText.replace('[extensions]', validExtensionStr))
        setFile({})
      } else if (size) {
        const newSum = size + currentResourcesSizeCount
        const remainingSpace = convertToMB(MEDIA_VALIDATION_MAP.resources.maxSize - currentResourcesSizeCount)

        // If image exceeds 2.5 mb
        if (size > MEDIA_VALIDATION_MAP.image.maxSize && isImage) {
          setError(MEDIA_VALIDATION_MAP.image.errorText)
          setFile({})

          // If audio/video exceeds 2.0 mb
        } else if (size > MEDIA_VALIDATION_MAP.media.maxSize && !isImage) {
          setError(MEDIA_VALIDATION_MAP.media.errorText)
          setFile({})

          // If adding media will exceeed 15 mb
        } else if (newSum > MEDIA_VALIDATION_MAP.resources.maxSize && !isImage) {
          setError(MEDIA_VALIDATION_MAP.resources.errorText.replace('[remainingSpace]', remainingSpace))
          setFile({})
        } else {
          setError('')
        }
      }

      // Error validation for incorrect file type for clinro
    } else if (extension && !accept.includes(extension)) {
      const validExtensionStr = accept.join(', ')
      setError(MEDIA_VALIDATION_MAP.invalidType.errorText.replace('[extensions]', validExtensionStr))
      setFile({})

      // If image exceed 2.5 mb for clinro
    } else if (size) {
      if (size > MEDIA_VALIDATION_MAP.image.maxSize && isImage) {
        setError(MEDIA_VALIDATION_MAP.image.errorText)
        setFile({})

        // If audio/video exceeds 2.0 mb
      } else if (size > MEDIA_VALIDATION_MAP.media.maxSize && !isImage) {
        setError(MEDIA_VALIDATION_MAP.media.errorText)
        setFile({})
      } else {
        setError('')
      }
    }
  }, [file])
}

export default useMediaError
