import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Input, Checkbox } from 'components/UIElements'
import { OPERATOR_OPTIONS, INSTRUMENT_ERROR_LIST } from 'utils/constants'

const CONDITION_DROPDOWN_OPTIONS = [{ key: 'scores', value: 'get_instrument_scores', text: 'Instrument score' }]

const AddConditions = props => {
  const { chain_deployment_info, errors, updateChainDeployInfo, instrumentScore, instrumentId } = props
  const isTriggerInstrumentWithScore = instrumentScore.length > 0
  const terms = chain_deployment_info?.deployment_condition?.terms || []
  const currentTerm = terms.find(term => term?.instrument_id === instrumentId)
  const cohort = currentTerm?.cohort
  const filter = cohort?.filter
  const addConditionsChecked = isTriggerInstrumentWithScore && !!cohort
  const instrumentScoreError = errors[INSTRUMENT_ERROR_LIST.instrumentScore]
  const instrumentScoreIdError = errors[INSTRUMENT_ERROR_LIST.instrumentScoreId]

  const getScoreDropdownOptions = scoreArr => {
    return scoreArr.map(score => {
      return {
        key: score.id,
        text: score.name,
      }
    })
  }

  const instrumentScoreOptions = getScoreDropdownOptions(instrumentScore)

  const updateCohortField = newCohort => {
    const updatedTerms = terms.map(term => {
      if (term?.instrument_id === instrumentId) {
        return {
          ...term,
          cohort: newCohort,
        }
      }
      return term
    })

    const updatedConditions = {
      ...chain_deployment_info,
      deployment_condition: {
        ...chain_deployment_info?.deployment_condition,
        terms: updatedTerms,
      },
    }

    updateChainDeployInfo(updatedConditions)
  }

  const updateAddConditions = (type, item) => {
    let value = item?.key
    const score_name = type === 'score_id' ? item.text : filter?.score_name
    if (type === `instrument_score`) value = item ? parseInt(item, 10) : ''

    const newCohort = {
      ...cohort,
      filter: {
        ...filter,
        [type]: value,
        score_name,
      },
    }

    updateCohortField(newCohort)
  }

  useEffect(() => {
    if (isTriggerInstrumentWithScore) {
      const scoreOptions = instrumentScoreOptions
      const optionsKeys = scoreOptions.map(option => option.key)
      const scoreId = filter?.score_id

      /**
       * If there is triggering score and it's not an option in the trigger instrument,
       * we set the score id in the trigger to blank, so that error validation will prompt
       * the sure to select a different score
       *  */

      const triggerScoreIsNotAnOption = scoreId && !optionsKeys.includes(scoreId)

      if (triggerScoreIsNotAnOption) {
        updateAddConditions('score_id', { key: '', text: '' })
      } else {
        /**
         * This block handles the case when a score name has been changed in the triggering
         * instrument, so that the score name is also updated in the chain_deployment_info
         * of the current instrument
         *  */

        const option = scoreOptions.find(scoreOption => scoreOption.key === scoreId)
        if (option) updateAddConditions('score_id', option)
      }
    }
  }, [isTriggerInstrumentWithScore])

  const handleClickAddConditions = checked => {
    const newCohort = {
      type: 'scores',
      filter: {
        instrument_score: '',
        logical_operator: '==',
        score_id: instrumentScore[0]?.id,
        score_name: instrumentScore[0]?.name,
      },
    }

    if (!!currentTerm?.instrument_id) {
      const updatedTerms = terms.map(term => {
        if (term?.instrument_id === currentTerm?.instrument_id) {
          return {
            ...term,
            cohort: newCohort,
          }
        }
        return term
      })

      const conditions = {
        ...chain_deployment_info,
        deployment_condition: {
          ...chain_deployment_info?.deployment_condition,
          terms: updatedTerms,
        },
      }

      updateChainDeployInfo(conditions)
    } else {
      const defaultConditions = {
        ...chain_deployment_info,
        deployment_condition: {
          ...chain_deployment_info?.deployment_condition,
          terms: [
            ...terms,
            {
              instrument_id: instrumentId,
              cohort: newCohort,
            },
          ],
        },
      }

      updateChainDeployInfo(defaultConditions)
    }

    if (!checked) {
      const updatedTerms = chain_deployment_info?.deployment_condition?.terms.map(term => {
        if (term?.instrument_id === instrumentId) {
          return {
            instrument_id: term?.instrument_id,
          }
        }
        return term
      })

      const updatedConditions = {
        ...chain_deployment_info,
        deployment_condition: {
          ...chain_deployment_info.deployment_condition,
          terms: updatedTerms,
        },
      }

      updateChainDeployInfo(updatedConditions)
    }
  }

  const addConditionText = addConditionsChecked ? '- Trigger only if' : ''

  return (
    <div>
      {isTriggerInstrumentWithScore && (
        <Checkbox
          label={`Add conditions ${addConditionText}`}
          onClick={() => handleClickAddConditions(!addConditionsChecked)}
          checked={addConditionsChecked}
        />
      )}
      {addConditionsChecked && (
        <div className='add-conditions-box'>
          <div className='flexed start-justified start-aligned'>
            <Dropdown
              className='condition'
              options={CONDITION_DROPDOWN_OPTIONS}
              onSelect={item =>
                updateCohortField({
                  type: item.key,
                  filter,
                })
              }
              selected={CONDITION_DROPDOWN_OPTIONS[0].key}
            />
            <span>for</span>
            <Dropdown
              className='score-name-list'
              errorText={instrumentScoreIdError}
              hasError={instrumentScoreIdError && !filter.score_id}
              onSelect={item => updateAddConditions('score_id', item)}
              options={instrumentScoreOptions}
              selected={filter?.score_id}
            />
            <span>is</span>
            <Dropdown
              className='logical-options'
              options={OPERATOR_OPTIONS}
              onSelect={item => updateAddConditions('logical_operator', item)}
              selected={filter?.logical_operator}
            />
            <Input
              className='score'
              type='number'
              placeholder='score'
              onChange={item => updateAddConditions('instrument_score', item)}
              value={filter?.instrument_score}
              hasError={instrumentScoreError && !filter.instrument_score && filter.instrument_score !== 0}
              errorText={instrumentScoreError}
            />
          </div>
        </div>
      )}
    </div>
  )
}

AddConditions.propTypes = {
  chain_deployment_info: PropTypes.shape({
    has_chain_deployment: PropTypes.bool,
    cohort: PropTypes.shape({
      filter: PropTypes.shape({
        logical_operator: PropTypes.string,
        score_id: PropTypes.string,
        score_name: PropTypes.string,
      }),
    }),
  }),
  instrumentScore: PropTypes.arrayOf(PropTypes.object),
  updateChainDeployInfo: PropTypes.func,
  updateChainDeployInfoField: PropTypes.func,
}

export default AddConditions
