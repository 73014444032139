import { connect } from 'react-redux'
import { CONSENT_ITEM_EDITOR_ENABLED_QUESTION_TYPES_ARR } from 'utils/constants'
import NewConsentItem from '../components/NewConsentItem'
import { itemActions, selectViewActions, uploadActions } from '../modules/NewConsent'

const mapDispatchToProps = {
  ...itemActions,
  ...selectViewActions,
  ...uploadActions,
}

const mapStateToProps = (state, ownProps) => {
  const { currentLang, itemId } = ownProps
  const { newConsentReducer, study } = state
  const { consent, consentErrors, editorStates, siteRolesStatus } = newConsentReducer
  const { questions } = consent
  const item = newConsentReducer.consent.questions[itemId]
  return {
    currentLang,
    isConsent: true,
    itemId,
    item,
    isActive: ownProps.activeItemId === itemId,
    hasItemEditor:
      ownProps.activeItemId === itemId &&
      CONSENT_ITEM_EDITOR_ENABLED_QUESTION_TYPES_ARR.includes(questions[itemId].type),
    editorState: editorStates[itemId],
    editorStates,
    questionNumber: questions[itemId].attributes.questionNumber,
    errors: consentErrors.questions?.[itemId] || {},
    preview: state.location.pathname.includes('preview'),
    isClinro: !!study.currentStudy?.config?.clinro,
    studyId: study?.currentStudy?.id,
    siteRolesStatus,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewConsentItem)
