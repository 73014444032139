import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { modalActions } from 'store/modal'
import { getUserRoleByStudy, getUserRoleIdByStudy, hasPiiRole } from 'utils/misc'
import { USER_ROLES_WITH_SCHEDULE_VIRTUAL_VISIT_PERMISSION, USER_ROLE_ID_MAP, SITE_ROLE_IDS } from 'utils/constants'
import { checkUserHasScope } from 'utils/userUtils'
import VisitSchedulesPage from '../components/VisitSchedulesPage'
import { actions } from '../modules/VisitSchedules'

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps
  const { studyID } = params
  const { loading, location, searchTerm, study, user, visitSchedules, studyLock } = state
  const { currentStudy } = study
  const { base_site_ids: baseSitesArr } = currentStudy
  const { visitScheduleTranslations } = visitSchedules
  const { translations } = visitScheduleTranslations
  const enforcedLanguage = currentStudy?.config?.enforced_language
  const adhocVisitsDisabled = currentStudy?.config?.visit_support?.disable_adhoc_visit

  const siteID = baseSitesArr[0]

  const userRole = getUserRoleByStudy(user, studyID)
  const roleId = getUserRoleIdByStudy(user, studyID)
  return {
    adhocVisitsDisabled,
    canEditVisits: USER_ROLES_WITH_SCHEDULE_VIRTUAL_VISIT_PERMISSION.includes(userRole),
    isSiteCoord: roleId === USER_ROLE_ID_MAP.siteCoordinator || roleId === USER_ROLE_ID_MAP.clinicalCoordinator,
    isUpcomingTabVisible:
      SITE_ROLE_IDS.includes(user.permissions[studyID].role_id) &&
      checkUserHasScope({ user, studyId: studyID, scope: ['rppv'] }),
    isPreviousTabVisible:
      SITE_ROLE_IDS.includes(user.permissions[studyID].role_id) &&
      checkUserHasScope({ user, studyId: studyID, scope: ['rppv'] }),
    loading,
    location,
    searchTerm,
    siteID,
    studyID,
    visitSchedules: visitSchedules || {},
    studyLock,
    hasPiiRole: hasPiiRole(userRole),
    translations,
    enforcedLanguage,
  }
}

const mapDispatchToProps = { ...actions, ...modalActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { loading } = props
  if (loading.general) {
    return <Loader />
  }
  return <VisitSchedulesPage {...props} />
})
