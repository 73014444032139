import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import { USER_ROLES_WITH_BLOCKED_SITES_ACCESS } from 'utils/constants'
import { UserDoesNotHaveRole, UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import SitesPageContainer from './containers/SitesPageContainer'
import reducer, { actions as userActions } from '../../../Users/routes/CreateUser/modules/CreateUser'

const { fetchUserSites } = userActions
// Sync route definition
export default store => ({
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        const { userId } = store.getState().user
        const { study } = store.getState()
        setPageTitle(`Sites | Study ${studyID} | ${study.currentStudy.platform_name}`)
        injectReducers(store, {
          userReducer: reducer,
        })
        store.dispatch(fetchUserSites({ studyId: studyID, userId }))
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserHasScope(
                ['rp', 'rp-no-pii'],
                UserDoesNotHaveRole(USER_ROLES_WITH_BLOCKED_SITES_ACCESS, SitesPageContainer),
                false,
                studyID,
                null,
                study.currentStudy,
              ),
            ),
          ),
        )
      },
      'sites',
    )
  },
})
