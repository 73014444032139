import React from 'react'
import PropTypes, { bool } from 'prop-types'
import 'styles/d3/radio.scss'

const Radio = ({ disabled, justCircle, selected, content, className, onClick, id }) => {
  const _onClick = () => {
    if (!selected && !disabled) onClick()
  }
  let _className = 'd3-radio'
  _className = _className += className ? ` ${className}` : ''
  _className = _className += disabled ? ' disabled' : ''
  _className = _className += selected ? ' selected' : ''
  return (
    <div onClick={_onClick} className={_className}>
      <div className={`radio-circle${selected ? ' selected' : ''}`} />
      {!justCircle && <div id={id} className='radio-label'>{content}</div>}
    </div>
  )
}

Radio.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  justCircle: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}

export default Radio
