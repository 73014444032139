import { evaluate } from 'mathjs'

export const getQuestionsForDomain = (domainId, instrument) => {
  const { questions, order } = instrument
  let output = []
  Object.keys(questions).forEach(id => {
    const question = questions[id]
    const num = order[0].indexOf(id)
    const { domains, label } = question
    if (domains?.includes(domainId)) {
      output = [
        ...output,
        {
          id,
          num,
          label,
        },
      ]
    }
  })
  return output
}

export const getInitialHoverState = domainsObj => {
  const domainIds = Object.keys(domainsObj)
  const state = {}
  domainIds.forEach(id => {
    state[id] = { x: null, y: null }
  })
  return state
}

// Functions for formula validation

const VALID_OPERATORS = ['+', '-', '*', '/', '^', '(', ')']
const VALID_NUMBERS = ['.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
const ALL_VALID_CHARS = [...VALID_NUMBERS, ...VALID_OPERATORS]

export const replaceTargetsWithVal = (formula = '', targets = [], replaceVal = '1') => {
  let output = formula.slice()
  targets.forEach(val => {
    output = output.split(val).join(replaceVal)
  })
  return output
}

export const getDoesFormulaContainInvalidChars = (formula = '', validIds = []) => {
  const replacedFormula = replaceTargetsWithVal(formula, validIds)

  // Check for multiple domain IDs in a row
  const regexToFindConsecutiveDomain = /1\[\]\s*1/g
  if (replacedFormula.match(regexToFindConsecutiveDomain)) return true

  const formulaWithoutBrackets = replaceTargetsWithVal(replacedFormula, ['[]'], '')

  const chars = formulaWithoutBrackets.split('')
  for (let i = 0; i < chars.length; i++) {
    const charWithoutWhitespace = chars[i].trim()
    if (charWithoutWhitespace && !ALL_VALID_CHARS.includes(charWithoutWhitespace)) {
      return true
    }
  }
  return false
}

export const getIsFormulaIncomplete = (formula = '', validIds = []) => {
  if (!formula) return false
  const replacedFormula = replaceTargetsWithVal(formula, validIds)
  const formulaWithoutBrackets = replaceTargetsWithVal(replacedFormula, ['[]'], '')

  try {
    const formulaWithoutWhitespace = formulaWithoutBrackets.replace(/\s/g, '')
    const evaluatedFormula = evaluate(formulaWithoutWhitespace)
    if (!Number.isFinite(evaluatedFormula)) return true
    return false
  } catch (error) {
    return true
  }
}
