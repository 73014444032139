import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { DEFAULT_LANG } from 'utils/constants'
import LanguageListPopup from './LanguageListPopup'
import { formatStringForID } from 'utils/misc'

const DEFAULT_LIST = [
  {
    key: DEFAULT_LANG,
    value: DEFAULT_LANG,
    text: 'English (US)*',
  },
]

const parseLangKeysLangItemsArr = (arr, localesMap) => {
  return arr.map(langKey => {
    return { key: langKey, value: langKey, text: localesMap[langKey]?.text }
  })
}

const LanguageItem = props => {
  const {
    currentLang,
    deleteConsentTranslation,
    dismissConsentError,
    hasConsentError,
    isDefault,
    isLatest,
    language,
    setCurrentLang,
  } = props
  const { value, text } = language
  const isSelected = value === currentLang

  const latestButtonRef = useRef()

  /**
   * This useEffect scrolls into the view the latest button that is added, so that
   * the button is seen when the language list hits its height limit.
   * */

  useEffect(() => {
    if (isLatest) latestButtonRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [isLatest])

  return (
    <div className='translation-lang-wrapper position-relative'>
      <Button
        ref={isLatest ? latestButtonRef : null}
        className={`translation-lang${isSelected ? ' sel' : ''} flexed center-aligned`}
        noStyling
        onClick={() => {
          if (isDefault) dismissConsentError()
          setCurrentLang(value)
        }}>
        {isSelected && <i className={`fas fa-caret-right${isDefault ? ' is-default' : ''}`} />}
        <div id={formatStringForID(text)} className='lang-text'>
          {text}
        </div>
      </Button>
      {!isDefault && (
        <Button
          noStyling
          className='rm-translation'
          onClick={() => {
            setCurrentLang(DEFAULT_LANG)
            deleteConsentTranslation(value)
          }}>
          <i className='fas fa-trash' />
        </Button>
      )}
      {isDefault && hasConsentError && <i className='fas fa-exclamation-triangle' />}
    </div>
  )
}

const TranslationSelection = props => {
  const { clearAllConsentTranslations, consent, localesMap, setCurrentLang } = props
  const { metadata } = consent
  const { other_languages: otherLanguages } = metadata
  let translationList = DEFAULT_LIST

  if (otherLanguages) translationList = [...DEFAULT_LIST, ...parseLangKeysLangItemsArr(otherLanguages, localesMap)]

  return (
    <div className='translation-selection flexed column space-between-justified'>
      <div className='translation-list'>
        {translationList.map((language, i) => {
          const { key } = language
          return (
            <LanguageItem
              key={key}
              isDefault={key === DEFAULT_LANG}
              isLatest={i === translationList.length - 1}
              language={language}
              {...props}
            />
          )
        })}
      </div>
      <div>
        <LanguageListPopup otherLanguages={otherLanguages} {...props} />
        <Button
          id='clear-all-translations'
          className='clear-all-lang'
          content='Clear all translations'
          link
          onClick={() => {
            clearAllConsentTranslations()
            setCurrentLang(DEFAULT_LANG)
          }}
        />
      </div>
    </div>
  )
}

LanguageItem.propTypes = {
  currentLang: PropTypes.string,
  deleteConsentTranslation: PropTypes.func,
  dismissConsentError: PropTypes.func,
  hasConsentError: PropTypes.bool,
  isDefault: PropTypes.bool,
  isLatest: PropTypes.bool,
  language: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
  }),
  setCurrentLang: PropTypes.func,
}
TranslationSelection.propTypes = {
  clearAllConsentTranslations: PropTypes.func,
  consent: PropTypes.shape({
    metadata: PropTypes.object,
  }),
  localesMap: PropTypes.objectOf(PropTypes.object),
  setCurrentLang: PropTypes.func,
}

export default TranslationSelection
