import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { toUTCMidnightSeconds } from 'utils/time'
import { DEPLOYMENT_KEYS, GROUP_TYPE_MAP } from 'utils/constants'
import moment from 'moment'
import InstrumentPermissionSelector from './InstrumentPermissionSelector'
import { getInstrumentDropdownOptions } from './DeploymentOptions'
import { ARTICLE_TYPE } from '../../../../../utils/instrumentConstants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from 'utils/constants'

const DEPLOYMENT_TYPE = {
  exact: 'exact',
  relative: 'relative',
  chainDeploy: 'chain_deploy_offset',
  immediately: 'immediately',
  chainDeployByVisit: DEPLOYMENT_KEYS.chainDeployByVisit,
}

const ABSOLUTE = DEPLOYMENT_KEYS.abs
const NOW = DEPLOYMENT_KEYS.now
const RELATIVE_TO_FIRST_LOGIN = DEPLOYMENT_KEYS.firstLogin
const CHAIN_DEPLOY_OFFSET = DEPLOYMENT_KEYS.chainDeployOffset
const CHAIN_DEPLOY_BY_VISIT = DEPLOYMENT_KEYS.chainDeployByVisit

const getDeployFormat = type => {
  switch (type) {
    case DEPLOYMENT_TYPE.exact:
      return { absolute: toUTCMidnightSeconds(moment()) }
    case DEPLOYMENT_TYPE.relative:
      return { first_login: { interval: 1, interval_type: 'days' }, time_of_day: '09:00' }
    case DEPLOYMENT_TYPE.chainDeploy:
      return { chain_deploy_offset: 0 }
    case DEPLOYMENT_TYPE.chainDeployByVisit: {
      return { days_offset: 0 }
    }
    case DEPLOYMENT_TYPE.immediately:
    default:
      return { now: null }
  }
}

const getExpireFormat = type => {
  switch (type) {
    default:
      return { never: null }
  }
}

const getChainDeployInfoFormat = type => {
  switch (type) {
    case DEPLOYMENT_TYPE.chainDeployByVisit:
      return {
        [DEPLOYMENT_KEYS.chainDeployByVisit]: true,
        visit_id: '',
      }
    case DEPLOYMENT_TYPE.chainDeploy:
    default:
      return {
        has_chain_deployment: true,
        prev_instrument_id: null,
      }
  }
}

const FormatSelector = props => {
  const {
    // canSetDeploymentByVisit,
    clearInstrumentTriggers,
    deleteMetadataField,
    hasVisitSupport,
    instrumentId,
    instruments,
    instrumentForm,
    isDiary,
    removeChainDeploymentInfo,
    schedule,
    updateChainDeployInfo,
    updateCohort,
    updateDeploy,
    updateExpire,
    updateMetadataField,
    updateRecurring,
    config,
    hasInstrumentUnification,
  } = props

  const otherInstruments = getInstrumentDropdownOptions(instruments, instrumentId)
  const { chain_deployment_info = {}, deploy } = schedule
  const { ediary: { version = null } = {} } = config
  const { metadata: { schedule: { deploy: deployForm = {} } = {} } = {} } = instrumentForm || {}

  const onUpdateDeploy = type => {
    const isAlreadyExact = type === DEPLOYMENT_TYPE.exact && (ABSOLUTE in deploy || NOW in deploy)

    const isAlreadyRelative = type === DEPLOYMENT_TYPE.relative && RELATIVE_TO_FIRST_LOGIN in deploy

    const isAlreadyChainedDeployType = type === DEPLOYMENT_TYPE.chainDeploy && CHAIN_DEPLOY_OFFSET in deploy

    const isDeployedByVisit = type === DEPLOYMENT_TYPE.chainDeployByVisit

    const isAlreadyChainedDeployVisitType = isDeployedByVisit && CHAIN_DEPLOY_BY_VISIT in chain_deployment_info

    /**
     * We only change the deployment type and change to default deployment of the type if the type is actually
     * being change to a different type. This prevents current settings from being reset to default if admin user
     * presses the same deployment type.
     */
    if (!(isAlreadyExact || isAlreadyRelative || isAlreadyChainedDeployType || isAlreadyChainedDeployVisitType)) {
      return () => {
        updateExpire(getExpireFormat(type))
        updateDeploy(getDeployFormat(type))
        if (type === DEPLOYMENT_TYPE.chainDeploy || isDeployedByVisit) {
          updateChainDeployInfo(getChainDeployInfoFormat(type))
          updateRecurring(null)
          /**
           * If the instrument is deployed by visit, we will return to default of sending to all participants
           * and removing all instrument triggers.
           */
          if (isDeployedByVisit) {
            updateCohort({
              type: GROUP_TYPE_MAP.all,
              filter: {},
            })
            clearInstrumentTriggers()
            updateMetadataField(ARTICLE_TYPE.key, ARTICLE_TYPE.engagement_article.key)
          }
        } else {
          removeChainDeploymentInfo()
          deleteMetadataField(ARTICLE_TYPE.key)
        }
      }
    }
    return () => {}
  }

  return (
    <div className='format-list flexed start-justified'>
      {hasInstrumentUnification ? (
        <Button
          id='exact-date'
          content='By Exact Date'
          onClick={onUpdateDeploy(DEPLOYMENT_TYPE.exact)}
          className={ABSOLUTE in deploy || NOW in deploy ? 'selected' : ''}
        />
      ) : (
        !(isDiary && !(ABSOLUTE in deployForm || NOW in deployForm)) && (
          <Button
            id='exact-date'
            content='By Exact Date'
            onClick={onUpdateDeploy(DEPLOYMENT_TYPE.exact)}
            className={ABSOLUTE in deploy || NOW in deploy ? 'selected' : ''}
          />
        )
      )}
      <Button
        id='relative-to-participant-start'
        content='Relative to Participant Start'
        onClick={onUpdateDeploy(DEPLOYMENT_TYPE.relative)}
        className={RELATIVE_TO_FIRST_LOGIN in deploy ? 'selected' : ''}
      />
      {hasInstrumentUnification ? (
        <Button
          id='triggered-by-instrument'
          content='Triggered by Instrument'
          disabled={otherInstruments.length === 0} // disable this option if there are no other instruments
          onClick={onUpdateDeploy(DEPLOYMENT_TYPE.chainDeploy)}
          className={CHAIN_DEPLOY_OFFSET in deploy ? 'selected' : ''}
        />
      ) : (
        !isDiary && (
          <Button
            id='triggered-by-instrument'
            content='Triggered by Instrument'
            disabled={otherInstruments.length === 0} // disable this option if there are no other instruments
            onClick={onUpdateDeploy(DEPLOYMENT_TYPE.chainDeploy)}
            className={CHAIN_DEPLOY_OFFSET in deploy ? 'selected' : ''}
          />
        )
      )}
      {hasInstrumentUnification
        ? hasVisitSupport && (
            <Button
              id='triggered-by-instrument'
              content='Triggered by Visit'
              // disabled={!hasVisits}
              onClick={onUpdateDeploy(DEPLOYMENT_TYPE.chainDeployByVisit)}
              className={CHAIN_DEPLOY_BY_VISIT in chain_deployment_info ? 'selected' : ''}
            />
          )
        : hasVisitSupport &&
          !isDiary && (
            <Button
              id='triggered-by-instrument'
              content='Triggered by Visit'
              // disabled={!hasVisits}
              onClick={onUpdateDeploy(DEPLOYMENT_TYPE.chainDeployByVisit)}
              className={CHAIN_DEPLOY_BY_VISIT in chain_deployment_info ? 'selected' : ''}
            />
          )}
    </div>
  )
}

const DeploymentSchedule = props => {
  const { disabled, isClinro, study, userId } = props
  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  return (
    <div className={`${disabled ? 'disabled' : ''}`}>
      {isClinro && <InstrumentPermissionSelector {...props} />}
      <h4>Schedule</h4>
      <div className='flexed start-justified format-selection-wrapper'>
        <p>Set start and expiration</p>
        <FormatSelector hasInstrumentUnification={hasInstrumentUnification} {...props} />
      </div>
    </div>
  )
}

FormatSelector.propTypes = {
  canSetDeploymentByVisit: PropTypes.bool,
  clearInstrumentTriggers: PropTypes.func,
  config: PropTypes.shape({
    ediary: PropTypes.shape({
      version: PropTypes.number,
    }),
  }),
  hasVisitSupport: PropTypes.bool,
  instrumentId: PropTypes.string,
  instruments: PropTypes.arrayOf(PropTypes.array),
  instrumentForm: PropTypes.shape({
    metadata: PropTypes.shape({ schedule: PropTypes.object, deploy: PropTypes.object }),
  }),
  isDiary: PropTypes.bool,
  removeChainDeploymentInfo: PropTypes.func,
  schedule: PropTypes.shape({
    chain_deployment_info: PropTypes.object,
    deploy: PropTypes.object,
  }),
  updateChainDeployInfo: PropTypes.func,
  updateCohort: PropTypes.func,
  updateDeploy: PropTypes.func,
  updateExpire: PropTypes.func,
  updateRecurring: PropTypes.func,
}

DeploymentSchedule.propTypes = {
  disabled: PropTypes.bool,
  isClinro: PropTypes.bool,
}

export default DeploymentSchedule
