import React from 'react'
import PropTypes from 'prop-types'
import Pagination from 'rc-pagination'
import paginationLocale from 'rc-pagination/lib/locale/en_US'
import { Button } from 'components/UIElements'
import 'styles/d3/pageselector.scss'

const PageSelector = props => {
  const { currentPage, total, onChange } = props
  return (
    <Pagination
      pageSize={1}
      total={total}
      onChange={(current, _) => {
        onChange(current)
      }}
      hideOnSinglePage
      locale={paginationLocale}
      current={currentPage}
      showTitle={false}
      showLessItems
      itemRender={(_, type, element) => {
        switch (type) {
          case 'jump-prev':
            return (
              <Button
                noStyling
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onChange(1)
                }}
              />
            )
          case 'jump-next':
            return (
              <Button
                noStyling
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onChange(total)
                }}
              />
            )
          case 'prev':
            return <Button noStyling content='Previous' />
          case 'next':
            return <Button noStyling content='Next' />
          default:
            return element
        }
      }}
    />
  )
}

PageSelector.propTypes = {
  currentPage: PropTypes.number,
  onChange: PropTypes.func,
  total: PropTypes.number,
}

export default PageSelector
