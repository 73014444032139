import React from 'react'
import PropTypes from 'prop-types'
import { Container, Input } from 'components/UIElements'
import STRINGS from 'utils/strings'
import Subheader from '../../../components/Subheader'

function VisitFields(props) {
  const { children, templateVisit, templateVisitErrors, disabled, updateVisitMetadata, updateVisit } = props
  const { name: visitName, visit_metadata } = templateVisit
  const { display_name } = visit_metadata
  const { name: nameErr, display_name: displayNameErr } = templateVisitErrors

  return (
    <Container>
      <div className='visit-fields'>
        <Subheader subheaderText={STRINGS.visitScheduleVistNameQuestion} />
        <div className='names'>
          <Input
            label='Visit Name'
            disabled={disabled}
            id='visit-name-input'
            className='visit-name-input'
            placeholder={STRINGS.visitName}
            value={visitName || ''}
            onChange={value => updateVisit({ key: 'name', value })}
            spellCheck
            charLimit={100}
            hasError={nameErr && !visitName}
            errorText={nameErr}
          />
          <Input
            label={STRINGS.visitDisplayName}
            disabled={disabled}
            id='visit-display-name-input'
            className='visit-name-input'
            placeholder={STRINGS.appDisplayName}
            value={visit_metadata.display_name || ''}
            onChange={value => updateVisitMetadata({ key: 'display_name', value })}
            spellCheck
            charLimit={100}
            hasError={displayNameErr && !display_name}
            errorText={displayNameErr}
          />
        </div>
        {children}
      </div>
    </Container>
  )
}

VisitFields.propTypes = {
  disabled: PropTypes.bool,
  templateVisitErrors: PropTypes.objectOf(PropTypes.string),
  name: PropTypes.string,
  templateVisit: PropTypes.shape({
    name: PropTypes.string,
    visit_metadata: PropTypes.shape({
      display_name: PropTypes.string,
    }),
  }),
  updateVisit: PropTypes.func,
  updateVisitMetadata: PropTypes.func,
}

export default VisitFields
