import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { QUESTION_TYPE_MAP } from 'utils/constants'
import ChoicesList from './ClinroChoicesList'
import { selectViewActions } from '../modules/Clinro'

class SelectView extends React.Component {
  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (prevProps.item.type !== item.type) {
      this.el.querySelector('textarea').focus()
    }
  }

  shouldCancelStart = e => {
    const { isActive } = this.props
    return !isActive || !e.target.className.includes('choice-grab-icon')
  }

  onAddChoice = idx => {
    const { item, addChoice, itemId } = this.props
    return () => {
      addChoice(itemId, item.choices_order.length, idx, item.type)
    }
  }

  render() {
    const { itemId, moveChoice, item, errors } = this.props
    const useXAxis = item.type === QUESTION_TYPE_MAP.likert || item.type === QUESTION_TYPE_MAP.numericRatingScale
    return (
      <div
        ref={el => {
          this.el = el
        }}
        className='multi-choice-container clinro'>
        {errors && !!errors.valuesErr && <p className='survey-error nrs-value'>{errors.valuesErr}</p>}
        {errors && !!errors.blankValuesErr && <p className='survey-error nrs-value'>{errors.blankValuesErr}</p>}
        <ChoicesList
          onSortEnd={({ oldIndex, newIndex }) => moveChoice(itemId, oldIndex, newIndex)}
          lockAxis={useXAxis ? 'x' : 'y'}
          axis={useXAxis ? 'x' : 'y'}
          lockToContainerEdges
          helperClass='sortableHelper choices'
          shouldCancelStart={this.shouldCancelStart}
          onAddChoice={this.onAddChoice}
          {...this.props}
        />
      </div>
    )
  }
}

export default connect(null, selectViewActions)(SelectView)

SelectView.propTypes = {
  item: PropTypes.object,
  errors: PropTypes.object,
  itemId: PropTypes.string,
  moveChoice: PropTypes.func,
  addChoice: PropTypes.func,
  isActive: PropTypes.bool,
  preview: PropTypes.bool,
}
