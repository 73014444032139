import React from 'react'
import PropTypes from 'prop-types'
import { Button, PercentMeter, SortableTable } from 'components/UIElements'
import { browserHistory } from 'react-router'
import {
  MOBILE_APP_PERMISSION_NAMES_MAP,
  TASKS_WITH_AUDIO_PERMISSION,
  TASKS_WITH_STORAGE_PERMISSION,
} from 'utils/constants'

const DURATION_RATIOS = {
  long: 1,
  medium: 0.5,
  short: 0.25,
}

const headerOptions = [
  { key: 'task_name', sortable: true, component: 'TASK NAME' },
  { key: 'description', sortable: false, component: 'DESCRIPTION' },
  { key: 'type', sortable: true, component: 'TYPE' },
  { key: 'estimated_duration', sortable: true, component: 'LENGTH' },
  { key: 'actions', sortable: false, component: '' },
]

const TaskList = props => {
  const { isCreate, onSave, searchTerm, selectedTask, selectTask, studyID, taskList, offsets } = props

  const renderTaskNameCol = ({ image, isSelected, value }) => {
    return (
      <td className={`name-col ${isSelected ? 'selected' : ''}`} key={value}>
        <img
          alt='Small screenshot of task'
          className={`screenshot ${isSelected && 'selected'}`}
          height='auto'
          src={image}
          width={isSelected ? 240 : 50}
        />
        <span className='name'>{value}</span>
      </td>
    )
  }

  const renderDescriptionCol = ({ isSelected, taskName, value }) => {
    return (
      <td key={`${taskName}-${value}`}>
        <div className={`description-col ${isSelected ? 'selected' : ''}`}>
          <p>{value}</p>
        </div>
      </td>
    )
  }

  const renderDurationCol = ({ value }) => {
    return (
      <td className='duration-col' key={value}>
        <PercentMeter
          hideNum
          invariableColor
          meterInnerClassName='length-meter'
          ratio={DURATION_RATIOS[value.toLowerCase()]}
        />
        <span className='length'>{value}</span>
      </td>
    )
  }

  const renderTypeCol = ({ value }) => {
    return (
      <td className='type-col' key={value}>
        <span>{value}</span>
      </td>
    )
  }

  const renderActionCol = ({ isCreate, isSelected, onSave, studyID, value }) => {
    const onCancelClick = () => (isCreate ? {} : browserHistory.push(`/studies/${studyID}/instruments`))
    const onNextClick = () => onSave('properties')
    return isSelected ? (
      <td className='button-cell' key={value}>
        <div className='flexed row offset-button-row'>
          <Button content='Cancel' onClick={onCancelClick} grey />
          <Button content='Next' onClick={onNextClick} />
        </div>
      </td>
    ) : (
      <td className='button-cell' key={value}>
        <Button content='Select' onClick={() => {}} grey />
      </td>
    )
  }

  const renderFunctions = {
    task_name: renderTaskNameCol,
    description: renderDescriptionCol,
    type: renderTypeCol,
    estimated_duration: renderDurationCol,
    actions: renderActionCol,
  }

  const onRowClick = row => {
    const selectedTaskName = selectedTask ? selectedTask.task_name : ''

    const nameIndex = row.findIndex(el => el.key === 'task_name')
    const durationIndex = row.findIndex(el => el.key === 'estimated_duration')

    const isSelected = selectedTaskName === row[nameIndex].taskName

    const { taskName } = row[nameIndex]
    const stagedTask = {
      display_name: row[nameIndex].displayName,
      est_duration: row[durationIndex].value,
      task_name: taskName,
      title: row[nameIndex].value,
    }
    stagedTask.required_permissions = []
    if (TASKS_WITH_AUDIO_PERMISSION.includes(taskName)) {
      stagedTask.required_permissions.push(MOBILE_APP_PERMISSION_NAMES_MAP.audioRecording)
    }
    if (TASKS_WITH_STORAGE_PERMISSION.includes(taskName)) {
      stagedTask.required_permissions.push(MOBILE_APP_PERMISSION_NAMES_MAP.storageAccess)
    }

    if (isSelected) {
      selectTask({})
    } else {
      selectTask(stagedTask)
    }
  }
  const rowList = isCreate
    ? taskList
    : taskList.filter(row => row[0].taskName === (selectedTask.metadata && selectedTask.metadata.task_name))
  return (
    <div className='task-list'>
      <SortableTable
        className='sortable-table'
        headerOptions={headerOptions}
        initialOrder='ascending'
        offset={offsets ? offsets[isCreate ? 1 : 0] : 0}
        onRowClick={isCreate ? onRowClick : null}
        optionalCellProps={{ isCreate, onSave, studyID }}
        rowSelectOptions={{
          isRowSelected: row =>
            row[0].taskName === (selectedTask.metadata ? selectedTask.metadata.task_name : selectedTask.task_name),
          selectedRowClassName: 'selected',
        }}
        renderFunctions={renderFunctions}
        rowList={rowList}
        searchKeys={['task_name', 'description']}
        searchTerm={searchTerm}
        sortingBy='task_name'
        emptyText='No Matching Tasks'
      />
      {isCreate && selectedTask.title && (
        <div className='button-list'>
          <Button content='Cancel' onClick={() => browserHistory.push(`/studies/${studyID}/instruments`)} grey />
          <Button content='Next' onClick={() => onSave('properties')} />
        </div>
      )}
    </div>
  )
}

const taskShape = PropTypes.shape({
  description: PropTypes.string,
  estimated_duration: PropTypes.string,
  image: PropTypes.string,
  task_id: PropTypes.number,
  task_name: PropTypes.string,
  title: PropTypes.string,
})

const metadataShape = PropTypes.shape({
  est_duration: PropTypes.oneOf(['long', 'medium', 'short']),
  gamification_points: PropTypes.number,
  priority: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  schedule: PropTypes.object,
  visibility: PropTypes.string, // TODO: oneOf('all_day', ...)
  visibilityRanges: PropTypes.arrayOf(PropTypes.object),
})

TaskList.propTypes = {
  isCreate: PropTypes.bool,
  onSave: PropTypes.func,
  searchTerm: PropTypes.string,
  selectTask: PropTypes.func,
  selectedTask: PropTypes.shape({
    metadata: metadataShape,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  studyID: PropTypes.number,
  taskList: PropTypes.arrayOf(PropTypes.arrayOf(taskShape)),
  offsets: PropTypes.arrayOf(PropTypes.number),
}

export default TaskList
