import React, { useEffect, useState } from 'react'
import { Button, Checkbox } from 'components/UIElements'
import PropTypes from 'prop-types'
import 'styles/d3/modal.scss'
import { connect } from 'react-redux'
import { modalActions } from 'store/modal'
import { closeIcon } from 'assets/assets'

const Modal = props => {
  const {
    cancelButton,
    children,
    checkbox,
    className,
    closeModal,
    closeOnBackgroundClick = true,
    confirmButton,
    content,
    disabled,
    height,
    heading,
    hideClose = false,
    isUrgent = false,
    location,
    noButtons,
    onCancel,
    onConfirm,
    open,
    spaceBetweenButtons = false,
    width,
    hideCancelButton,
    confirmBtnClassName,
  } = props

  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    closeModal()
  }, [location?.pathname])

  const style = {
    height: height || '',
    width: width || '',
  }

  const _className = `d3-modal modal-${open ? 'open' : 'closed'}${className ? ` ${className}` : ''}${
    hideClose ? ' no-close' : ''
  }`

  return (
    <div onClick={closeOnBackgroundClick ? onCancel || closeModal : null} className={_className}>
      <div style={style} onClick={e => e.stopPropagation()} className='content'>
        {!hideClose && (
          <Button className='modal-close-x' disabled={disabled} onClick={closeModal} content={closeIcon()} />
        )}
        {heading && <h6 className='label-large light-grey'>{heading}</h6>}
        <div className='top'>{children || content}</div>
        {!noButtons && (
          <div className={`bottom flexed${spaceBetweenButtons ? ' space-between-justified' : ''}`}>
            {checkbox && (
              <Checkbox disabled={disabled} checked={toggle} label={checkbox} onClick={() => setToggle(!toggle)} />
            )}
            {!hideCancelButton && (
              <Button
                disabled={disabled}
                grey
                id='modal-cancel'
                content={cancelButton || 'Cancel'}
                onClick={() => {
                  if (onCancel) onCancel(toggle)
                  closeModal()
                }}
              />
            )}
            <Button
              red={isUrgent}
              disabled={disabled}
              id='modal-confirm'
              className={`confirm ${confirmBtnClassName || ''}`}
              content={confirmButton || 'Confirm'}
              onClick={() => {
                closeModal()
                if (onConfirm) onConfirm(toggle)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

Modal.propTypes = {
  cancelButton: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  closeOnBackgroundClick: PropTypes.bool,
  confirmButton: PropTypes.string,
  content: PropTypes.node,
  disabled: PropTypes.bool,
  heading: PropTypes.string,
  height: PropTypes.string,
  hideClose: PropTypes.bool,
  isUrgent: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  noButtons: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  spaceBetweenButtons: PropTypes.bool,
  width: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
  const { loading } = state
  const { mediaUpload } = loading
  const { disabled: _disabled } = ownProps
  return { disabled: _disabled || mediaUpload, ...ownProps }
}

export default connect(mapStateToProps, modalActions)(Modal)
