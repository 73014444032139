import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Textarea } from 'components/UIElements'
import { generateDateStringWithTimeZone } from 'utils/time'

const CaseNotes = ({ notes, params, postNote, canWrite, studyLock }) => {
  const [currentNote, setCurrentNote] = useState('')
  const notesList = notes.map(caseNote => {
    const { admin_id, admin_name, note, timestamp, is_auto_generated } = caseNote

    const adminName = admin_name || ''

    const auto = is_auto_generated ? ' auto' : ''

    return (
      <div className={`case-note${auto}`} key={`${admin_id}-${timestamp}`}>
        <p>{note}</p>
        <p className='timestamp'>
          {`${generateDateStringWithTimeZone(timestamp)} ${adminName ? `\u2022 ${adminName}` : ''}`}
        </p>
      </div>
    )
  })

  const onChange = e => setCurrentNote(e)

  const onSubmit = e => {
    e.preventDefault()
    const { studyID, caseId } = params
    postNote(currentNote, studyID, caseId)
  }

  return (
    <div className='case-notes'>
      <div className='header'>NOTES</div>
      {notesList}
      {canWrite && (
        <form onSubmit={onSubmit}>
          <p>Add note</p>
          <Textarea id='add-note' value={currentNote} onChange={onChange} disabled={studyLock} />
          <Button disabled={!currentNote || studyLock} id='save' content='Save' type='submit' />
        </form>
      )}
    </div>
  )
}

CaseNotes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object),
  postNote: PropTypes.func,
  canWrite: PropTypes.bool,
  params: PropTypes.shape({
    studyID: PropTypes.string,
    caseId: PropTypes.string,
  }),
  studyLock: PropTypes.bool,
}

export default CaseNotes
