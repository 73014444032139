import { connect } from 'react-redux'
import { QUESTION_TYPE_MAP } from 'utils/constants'
import ClinroLogicPanel from '../components/ClinroLogicPanel'
import { logicActions } from '../modules/Clinro'

const mapDispatchToProps = logicActions

const mapStateToProps = (state, ownProps) => {
  const { clinroEditor } = state
  const { itemId } = ownProps
  const { questions } = clinroEditor.clinro
  const order = clinroEditor.clinro.order[0]
  const currentIndex = order.indexOf(itemId)

  const questionOptions = []
  let questionNumber = 0
  const nonQuestionTypeArr = [QUESTION_TYPE_MAP.introduction, QUESTION_TYPE_MAP.prompt]
  const questionTypesWithNestedQuestionsArr = [QUESTION_TYPE_MAP.matrix]
  const { sibling_id } = questions[itemId]

  for (let i = 0; i < currentIndex; i++) {
    const questionType = questions[order[i]].type
    if (nonQuestionTypeArr.includes(questionType)) continue
    if (sibling_id && questions[order[i]].sibling_id === sibling_id) {
      questionNumber++
      continue
    }
    if (questionType.includes(QUESTION_TYPE_MAP.multipleField)) {
      questionNumber++
      continue
    }
    if (questionType.includes(QUESTION_TYPE_MAP.audioRecording)) {
      questionNumber++
      continue
    }
    if (questionType.includes(QUESTION_TYPE_MAP.scribble)) {
      questionNumber++
      continue
    }
    if (questionType.includes(QUESTION_TYPE_MAP.imageCapture)) {
      questionNumber++
      continue
    }
    if (questionTypesWithNestedQuestionsArr.includes(questionType)) {
      questionNumber++
      const question = questions[order[i]]
      const { questions_order } = question
      const currentQuestionNumber = questionNumber

      questions_order.forEach((questionId, index) => {
        const plainQuestionText = clinroEditor.editorStates[questionId].getCurrentContent().getPlainText()
        let text = `Q${currentQuestionNumber}.${index + 1}: ${plainQuestionText}`
        if (text.length > 40) text = `${text.slice(0, 40)}...`
        questionOptions.push({
          key: questionId,
          text,
          value: order[i],
          value2: questionId,
        })
      })
      continue
    }
    const plainText = clinroEditor.editorStates[order[i]].getCurrentContent().getPlainText()
    questionNumber++
    let text = `Q${questionNumber}: ${plainText}`
    if (text.length > 40) text = `${text.slice(0, 40)}...`
    questionOptions.push({
      key: order[i],
      text,
      value: order[i],
    })
  }

  return {
    logic: clinroEditor.clinro.questions[itemId].logic.show_if,
    questionOptions,
    questions,
    logicErr: clinroEditor.clinroErrors.questions[itemId],
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinroLogicPanel)
