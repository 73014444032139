import React from 'react'
import PropTypes from 'prop-types'
import { SortableTable, Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import { formatStringForID } from 'utils/misc'
import { CONDITIONAL_TYPE_MAP } from './NotificationTypes'

const CONDITIONAL_NOTIFICATIONS = {
  medication_adherence: { fixed: false },
}

const getRowData = (key, info, item, triggerValue, className) => {
  return [
    { key: 'notification_event', value: info.text, eventKey: key, className },
    { key: 'trigger', value: info.getTriggerText(triggerValue), className },
    { key: 'control', value: item.enabled ? 'On' : 'Off', className },
  ]
}
class ConditionalNotifications extends React.Component {
  constructor(props) {
    super(props)
    this.headerOptions = [
      { key: 'notification_event', sortable: false, component: 'Notification Event' },
      { key: 'trigger', sortable: false, component: 'Trigger' },
      { key: 'control', sortable: false, component: 'Control' },
    ]
    this.renderFunctions = {
      notification_event: this.renderEventLink.bind(this),
    }
  }

  getTableList = () => {
    const { conditionalNotifications } = this.props
    const { medicationAdherence } = conditionalNotifications
    if (medicationAdherence) {
      const list = Object.keys(CONDITIONAL_NOTIFICATIONS).map(key => {
        const item = CONDITIONAL_NOTIFICATIONS[key]
        item.enabled = medicationAdherence.enabled
        const info = CONDITIONAL_TYPE_MAP[key]
        const intervalKey = key === 'periodic' ? 'interval_days' : 'interval_hours'
        return getRowData(key, info, item, item[intervalKey], item.fixed ? 'fixed-notification' : '')
      })
      return list
    }
    return []
  }

  renderEventLink({ className, eventKey, value }) {
    const { studyID, siteID } = this.props
    return (
      <td className={className} key={eventKey}>
        <Button
          link
          id={formatStringForID(value)}
          content={value}
          onClick={() => {
            browserHistory.push(`/studies/${studyID}/notifications/conditional/${eventKey}`)
          }}
        />
      </td>
    )
  }

  render() {
    return (
      <div className='notifications-section'>
        <h6>Conditional Notifications</h6>
        <SortableTable
          className='conditional'
          headerOptions={this.headerOptions}
          rowList={this.getTableList()}
          renderFunctions={this.renderFunctions}
        />
      </div>
    )
  }
}

ConditionalNotifications.propTypes = {
  conditionalNotifications: PropTypes.objectOf(PropTypes.object),
  siteID: PropTypes.string,
  studyID: PropTypes.string,
}

export default ConditionalNotifications
