import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasRole, UserHasFeatures } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { STUDY_CONFIG_MAP, USER_ROLES_WITH_SCHEDULE_VIRTUAL_VISIT_PERMISSION } from 'utils/constants'
import CreateVisitsContainer from './containers/CreateVisitsContainer'
import createVisitsReducer, { actions } from './modules/CreateVisits'
import { actions as sitesActions } from '../../../Sites/routes/SitesPage/modules/Sites'
// Sync route definition
export default store => ({
  path: 'create',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        injectReducers(store, {
          createVisitsReducer,
        })

        const { currentStudy } = store.getState().study
        setPageTitle(`Schedule a Virtual Visit | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(setSubroute('Virtual Visits'))
        store.dispatch(actions.initalizePage())
        store.dispatch(sitesActions.fetchSites(studyID))

        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'virtualvisits',
              )(
                UserHasRole(
                  USER_ROLES_WITH_SCHEDULE_VIRTUAL_VISIT_PERMISSION,
                  studyID,
                  UserHasFeatures(
                    CreateVisitsContainer,
                    [STUDY_CONFIG_MAP.virtualVisits, STUDY_CONFIG_MAP.visitSupport],
                    'virtualvisits',
                    false,
                    false,
                  ),
                  `/studies/${studyID}/virtualvisits`,
                ),
              ),
            ),
          ),
        )
      },
      'create',
    )
  },
})
