import { h64 } from 'xxhashjs'

export function randomString(length) {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export function getRandomQuestionId() {
  const possible = 'abcdefghijklmnopqrstuvwxyz'
  return (
    possible.charAt(Math.floor(Math.random() * possible.length)) +
    h64(randomString(12), 0x586fc)
      .toString(16)
      .substring(0, 6)
  )
}
