import { injectReducers } from 'store/reducers'
import { viewActions } from 'store/view'
import VisitTemplate from './container/VisitTemplateContainer'
import CreateParticipantVisit from './routes/CreateParticipantVisit'
import CreateVisit from './routes/CreateVisit'
import CreateVisitTemplate from './routes/CreateVisitTemplate'
import EditParticipantVisit from './routes/EditParticipantVisit'
import EditVisit from './routes/EditVisit'
import EditVisitTemplate from './routes/EditVisitTemplate'
import VisitTemplateDetails from './routes/VisitTemplateDetails'

export const injectVisitsReducers = store => {
  injectReducers(store, {})
}

export default store => ({
  component: VisitTemplate,
  getChildRoutes(location, cb) {
    store.dispatch(viewActions.setFullWidth())
    cb(null, [
      CreateVisit(store),
      CreateVisitTemplate(store),
      CreateParticipantVisit(store),
      VisitTemplateDetails(store),
      EditParticipantVisit(store),
      EditVisit(store),
      EditVisitTemplate(store),
    ])
  },
})
