// We only need to import the modules necessary for initial render
import { setSubroute } from 'store/navbar'
import { studyLockActions } from 'store/studyLock'
import { browserHistory } from 'react-router'
import { setPageTitle } from 'utils/misc'
import { isSameUser, checkUserHasScope } from 'utils/userUtils'
import { SITE_ROLE_IDS } from 'utils/constants'
import ViewStudyRoute from './ViewStudy'
import { actions } from '../CreateStudy/modules/CreateStudy'
import AnnouncementsRoute from './routes/Announcements'
import AuditRoute from './routes/Audit'
import CasesRoute from './routes/Cases'
import ConsentsRoute from './routes/Consents'
import DataCorrectionRoute from './routes/DataCorrection'
import GeofencesRoute from './routes/Geofences'
import InstrumentsRoute from './routes/Instruments'
import NotificationsRoute from './routes/Notifications'
import ParticipantsRoute from './routes/Participants'
import PaymentsRoute from './routes/Payments'
import SitesRoute from './routes/Sites'
import StudyDashboardRoute from './routes/StudyDashboard'
import TrainingRoute from './routes/Training'
import DataInboxRoute from './routes/DataInbox'
import UsersRoute from './routes/Users'
import VirtualVisitsRoute from './routes/VirtualVisits'
import VisitSchedulesRoute from './routes/VisitSchedules'
import { actions as paymentActions } from './routes/Payments/modules/Payments'
import { actions as visitSchedulesActions } from './routes/VisitSchedules/routes/VisitSchedulesPage/modules/VisitSchedules'

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export default function createRoutes(store) {
  const { user } = store.getState()
  return {
    path: ':studyID',
    exact: true,
    getIndexRoute(location, cb) {
      if (isSameUser(user.id) ?? true) {
        store.dispatch(setSubroute('configuration'))
        const state = store.getState()
        setPageTitle(`Configuration | Study ${location.params.studyID} | ${state.study.currentStudy.platform_name}`)
        cb(null, ViewStudyRoute(store))
      } else browserHistory.push('/')
    },
    getChildRoutes(location, cb) {
      const getChildren = () => {
        cb(null, [
          AnnouncementsRoute(store),
          AuditRoute(store),
          CasesRoute(store),
          ConsentsRoute(store),
          DataCorrectionRoute(store),
          GeofencesRoute(store),
          InstrumentsRoute(store),
          NotificationsRoute(store),
          ParticipantsRoute(store),
          PaymentsRoute(store),
          SitesRoute(store),
          StudyDashboardRoute(store),
          TrainingRoute(store),
          DataInboxRoute(store),
          UsersRoute(store),
          VirtualVisitsRoute(store),
          VisitSchedulesRoute(store),
        ])
      }
      if (isSameUser(user.id) ?? true) {
        if (store.getState().study.currentStudy.id !== parseInt(location.params.studyID, 10)) {
          store.dispatch(actions.fetchStudy(location.params.studyID)).then(study => {
            if (
              !location.location.pathname.endsWith('/payments') &&
              checkUserHasScope({ user, studyId: location.params.studyID, scope: ['rp', 'rp-no-pii'] })
            ) {
              if (study?.config?.super_gems) {
                store.dispatch(paymentActions.fetchPendingGiftcardsAndDonations(location.params.studyID, false))
              } else {
                store.dispatch(paymentActions.fetchPayments(location.params.studyID, false))
              }
            }

            if (
              study?.config?.visit_support &&
              SITE_ROLE_IDS.includes(user?.permissions?.[location.params.studyID]?.role_id) &&
              checkUserHasScope({ user, studyId: location.params.studyID, scope: ['rppv'] })
            ) {
              store.dispatch(visitSchedulesActions.fetchScheduleVisitsPrevious(location.params.studyID))
            }

            const isLocked = study?.is_locked ? study?.is_locked : study?.config?.is_locked
            store.dispatch(studyLockActions.setStudyLock(isLocked))
            getChildren()
          })
        } else {
          getChildren()
        }
      } else browserHistory.push('/')
    },
  }
}
