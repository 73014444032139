import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, StickyHeader } from 'components/UIElements'
import { useOffsets } from 'utils/misc'
import GeofencesTable from './GeofencesTable'
import '../stylesheets/Geofences.scss'

const GeofencesPage = props => {
  const { canEdit, canCreate, deleteGeofences, geofences, params, resetNewGeofences, studyLock } = props
  const { studyID } = params
  const { checkedGeofences, geofencesList, newGeofences } = geofences
  const [showHeaderCreateGeofence, setHeaderCreateGeofence] = useState(newGeofences || false)

  const offsets = useOffsets()
  const subsequentOffsets = offsets ? offsets.slice(1) : []
  const noGeofences = geofencesList.length === 0
  const checkedGeofencesArr = Object.keys(checkedGeofences)
  const anyCheckedGeofences = checkedGeofencesArr.length > 0
  const plural = checkedGeofencesArr.length > 1

  useEffect(() => {
    return () => {
      resetNewGeofences()
    }
  }, [])

  return (
    <div className='geofences page'>
      <StickyHeader offset={offsets ? offsets[0] : 0}>
        <span />
        <div>
          {canEdit && anyCheckedGeofences && !showHeaderCreateGeofence && (
            <Button
              grey
              disabled={studyLock}
              content={`Delete Geofence${plural ? 's' : ''}`}
              onClick={() => {
                deleteGeofences(studyID, checkedGeofences, plural)
              }}
              id='delete-geofence-button'
            />
          )}
          {!noGeofences && canCreate && !showHeaderCreateGeofence && (
            <Button
              disabled={showHeaderCreateGeofence || studyLock}
              content='Add Geofence'
              onClick={() => {
                setHeaderCreateGeofence(true)
              }}
            />
          )}
        </div>
      </StickyHeader>
      <GeofencesTable
        {...props}
        offsets={subsequentOffsets}
        showHeaderCreateGeofence={showHeaderCreateGeofence}
        setHeaderCreateGeofence={setHeaderCreateGeofence}
      />
    </div>
  )
}

GeofencesPage.propTypes = {
  canCreate: PropTypes.bool,
  canEdit: PropTypes.bool,
  deleteGeofences: PropTypes.func,
  geofences: PropTypes.shape({
    checkedGeofences: PropTypes.object,
    geofencesList: PropTypes.arrayOf(PropTypes.array),
    newGeofences: PropTypes.bool,
  }),
  geofencesLoading: PropTypes.bool,
  params: PropTypes.shape({ studyID: PropTypes.string, siteID: PropTypes.string }),
  resetNewGeofences: PropTypes.func,
  resetCheckedGeofences: PropTypes.func,
}

export default GeofencesPage
