import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import {
  getFormulaErrors,
  getMetadataErrors,
  getSchedulingErrors,
  getAllErrors,
  _isInvalid,
  checkForAdvancedDeploymentOptions,
} from 'utils/instrumentValidation'
import { modalActions } from 'store/modal'
import { loadingActions } from 'store/loader'
import request, { generateNotyMessage } from 'utils/request'
import { getScoreErrors } from 'utils/instrument'
import { uniqueArr } from 'utils/misc'
import { convertAdvancedOptions } from 'utils/hoc/AdvancedOptionsHOC'
import {
  INSTRUMENT_TYPE_MAP,
  TRIGGER_TYPE_MAP,
  MODAL_CONTENT_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  TASKS_WITH_AUDIO_PERMISSION,
  TASKS_WITH_STORAGE_PERMISSION,
  MOBILE_APP_PERMISSION_NAMES_MAP,
} from 'utils/constants'
import moment from 'moment'
import { compareJSON } from 'utils/object'
import { fromEpochToUTC } from 'utils/time'
import { actions as notyActions } from 'layouts/ErrorBox'
import { saveTaskToDB } from '../routes/SelectTask/modules/SelectTask'
import {
  validateArticleAndSave,
  validateSurveyAndSave,
  saveUploadToDatabase,
} from '../routes/EditSurvey/modules/Survey'
import { validateClinroAndSave } from '../routes/EditClinro/modules/Clinro'
import { validateConsentAndSave } from '../../../../Consent/routes/ConsentEditor/modules/Consent'
import {
  validateConsentAndSave as newValidateConsentAndSave,
  onCreateNewConsent,
} from '../../../../Consents/routes/ConsentView/routes/NewConsentBuilder/modules/NewConsent'
import { validateDiaryAndSave } from '../routes/EditDiary/modules/Diary'
import { _getDefaultMetadata, _getDefaultSchedule } from '../../../utils/PropertyFields'
import {
  changeTrigger,
  changeAction,
  saveConditions,
  fetchInstrumentConditions,
} from '../../../../Notifications/routes/EditConditionalNotification/modules/ConditionalNotification'
import { SET_VISIT_TEMPLATES } from '../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import { fetchInstruments } from '../../InstrumentsPage/modules/Instruments'

export const RESET_SCHEDULE = 'RESET_SCHEDULE'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const UPDATE_SCHEDULE_FIELD = 'UPDATE_SCHEDULE_FIELD'
const DELETE_SCHEDULE_FIELD = 'DELETE_SCHEDULE_FIELD'
const DELETE_METADATA_FIELD = 'DELETE_METADATA_FIELD'
const ADD_DIARY_NOTIFICATION_TRIGGER = 'ADD_DIARY_NOTIFICATION_TRIGGER'
const UPDATE_DIARY_NOTIFICATION_TRIGGER = 'UPDATE_DIARY_NOTIFICATION_TRIGGER'
const ADD_INSTRUMENT_TRIGGER = 'ADD_INSTRUMENT_TRIGGER'
const ADD_VISIBILITY_RANGE = 'ADD_VISIBILITY_RANGE'
const CHANGE_INSTRUMENT_TRIGGER_TYPE = 'CHANGE_INSTRUMENT_TRIGGER_TYPE'
const CLEAR_ERRORS = 'CLEAR_ERRORS'
const CLEAR_INSTRUMENT_CONDITIONS_TO_DELETE = 'CLEAR_INSTRUMENT_CONDITIONS_TO_DELETE'
const CLEAR_INSTRUMENT_EVENTS = 'CLEAR_INSTRUMENT_EVENTS'
const CLEAR_INSTRUMENT_TRIGGERS = 'CLEAR_INSTRUMENT_TRIGGERS'
const CLEAR_PREVALIDATED_ERROR = 'CLEAR_PREVALIDATED_ERROR'
const DELETE_INSTRUMENT_TRIGGER = 'DELETE_INSTRUMENT_TRIGGER'
const REMOVE_CHAIN_DEPLOYMENT_INFO = 'REMOVE_CHAIN_DEPLOYMENT_INFO'
const RESET_INSTRUMENT = 'RESET_INSTRUMENT'
const SET_CLINRO_ROLES = 'SET_CLINRO_ROLES'
const SET_ERRORS = 'SET_ERRORS'
const SET_INSTRUMENT = 'SET_INSTRUMENT'
const SET_INSTRUMENT_EVENTS = 'SET_INSTRUMENT_EVENTS'
const SET_INSTRUMENT_TRIGGERS = 'SET_INSTRUMENT_TRIGGERS'
const TOGGLE_SAVE_ATTEMPT = 'TOGGLE_SAVE_ATTEMPT'
const UPDATE_ARTICLE_URL = 'UPDATE_ARTICLE_URL'
const UPDATE_GEOFENCE_ENABLED = 'UPDATE_GEOFENCE_ENABLED'
const UPDATE_INSTRUMENT_ACTION = 'UPOATE_INSTRUMENT_ACTION'
const UPDATE_INSTRUMENT_ROLES = 'UPDATE_INSTRUMENT_ROLES'
const UPDATE_INSTRUMENT_TITLE = 'UPDATE_INSTRUMENT_TITLE'
const UPDATE_INSTRUMENT_DISPLAY_LOCATION = 'UPDATE_INSTRUMENT_DISPLAY_LOCATION'
const UPDATE_INSTRUMENT_TRIGGER = 'UPDATE_INSTRUMENT_TRIGGER'
const UPDATE_METADATA_FIELD = 'UPDATE_METADATA_FIELD'
const UPDATE_PREVALIDATED_ERRORS = 'UPDATE_PREVALIDATED_ERRORS'
const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY'
const UPDATE_VISIBILITY_RANGE = 'UPDATE_VISIBILITY_RANGE'
const SET_ADVANCED_OPTIONS = 'SET_ADVANCED_OPTIONS'
const RESET_ADVANCED_OPTIONS = 'RESET_ADVANCED_OPTIONS'
const SET_INSTRUMENT_UNIFICATION_ENABLED = 'SET_INSTRUMENT_UNIFICATION_ENABLED'
const RESET_INSTRUMENT_UNIFICATION_ENABLED = 'RESET_INSTRUMENT_UNIFICATION_ENABLED'

const VISIBILITY_TIME_FORMAT = 'H:mm' // format for 2-digit hour and 24-hour clock format (e.g. "23:45")

export const DEFAULT_DEPLOY_TIME = '09:00'

const DEFAULT_PERSISTENT_SCHEME = { scheme: 'persistent' }

//
// API Actions
//

const _compareInstruments = (current, original) => {
  const _blank = { activeItemId: null, activeItem: null }
  const _current = { ...current, ..._blank }
  const _original = { ...original, ..._blank }
  return compareJSON(_current, _original)
}

// Global save handler for instruments, conditionally saves instruments based on type and handles POST vs PUT/PATCH
// requests
export const onSave = (redirect, showModal, consentProps, queries = {}) => {
  return (dispatch, getState) => {
    const {
      instrumentReducer,
      study,
      location,
      consentReducer,
      newConsentReducer,
      surveyEditor,
      clinroEditor,
      diaryEditor,
      upload,
    } = getState()

    const { query = '', confirmQuery = '', cancelQuery = '' } = queries
    let queryParams = query

    const studyID = study.currentStudy.id
    const {
      instrument: storeInstrument,
      _instrument,
      instrumentTriggers,
      conditionsToDelete,
      instrumentAdvancedOptions,
    } = instrumentReducer
    const { conditions } = instrumentTriggers
    const hasConditions = !!conditions?.length || !!conditionsToDelete?.length
    let instrument = Object.assign({}, storeInstrument)
    const { type } = instrument
    const path = location.pathname.match(/([^/]+$)/)[0]

    const hasAdvancedOptions = checkForAdvancedDeploymentOptions(instrumentAdvancedOptions)
    if (hasAdvancedOptions) {
      const convertedAdvancedOptions = convertAdvancedOptions(instrumentAdvancedOptions)
      if (convertedAdvancedOptions) {
        instrument = {
          ...instrument,
          metadata: {
            ...instrument.metadata,
            schedule: {
              ...instrument.metadata.schedule,
              cohort: [...convertedAdvancedOptions],
            },
          },
        }
      }
    }

    let shouldSave = false
    let action
    const isClinro = type === INSTRUMENT_TYPE_MAP.clinro
    const isSurvey = type === INSTRUMENT_TYPE_MAP.survey && !path.includes('consent')
    const isDiary = type === INSTRUMENT_TYPE_MAP.diary

    /**
     * We need to check if survey or clinician instrument changes create new scoring formula errors.
     * If so, we make the redirect path 'scoring' to allow the user to see the errors on the scoring
     * page and warn them to fix the errors.
     */
    const hasNewFormulaErrors =
      (isClinro || isSurvey || isDiary) && !consentProps
        ? !!getFormulaErrors(isClinro ? clinroEditor.clinro : isSurvey ? surveyEditor.survey : diaryEditor.diary).scores
        : false
    const redirectPath = hasNewFormulaErrors ? 'scoring' : redirect

    /**
     * We also need to check for pre-existing score errors, so that even if the user does not save
     * the current state of the instrument, they will be redirected to the scoring page to fix the
     * pre-existing errors.
     */
    const hasScoreErrors =
      (isClinro || isSurvey) && !consentProps ? !!getScoreErrors(isClinro ? clinroEditor : surveyEditor, type) : false
    const nonSavingRedirectPath = hasScoreErrors ? 'scoring' : redirect

    /**
     * Navigation callback upon instrument save
     * @param {Object} resJSON
     * @param {Boolean} noSave
     */
    const onRedirect = (resJSON, noSave = false) => {
      if (resJSON && !resJSON.ok && resJSON.status === 412) return
      // sometimes instrument.id and resJSON?.id are undefined so we are using the id from the pathname
      const instrumentID =
        instrument.id || resJSON?.id || location.pathname.replace('/' + path, '').match(/([^/]+$)/)[0]
      _redirect(
        noSave ? nonSavingRedirectPath : redirectPath,
        studyID,
        instrumentID,
        consentProps ? { ...newConsentReducer.consent, ...consentProps } : null,
        queryParams,
      )
    }

    if (redirect === 'e-consent') {
      shouldSave = !_compareInstruments(consentReducer.consent, consentReducer.originalConsent)
      action = () => dispatch(validateConsentAndSave({ studyID, consentReducer, onRedirect }))
    } else if (
      (['consents', 'createconsent', 'schedule', 'formatting'].includes(redirect) && !!consentProps) ||
      (redirect === 'properties' && !!consentProps) ||
      (redirect === 'editconsent' && !!consentProps) ||
      (redirect === 'deploy' && !!consentProps)
    ) {
      const isUpdate = consentProps?.isUpdate
      const isPdfUpload = consentProps?.isPdfUpload
      const setForScheduling = consentProps?.setReadyToBeScheduled
      const { advancedOptions: consentAdvancedOptions, shouldValidate = false } = consentProps
      const convertedAdvancedOptions = convertAdvancedOptions(consentAdvancedOptions)

      shouldSave =
        !_compareInstruments(newConsentReducer.consent, newConsentReducer.originalConsent) ||
        !isUpdate ||
        setForScheduling ||
        !compareJSON(newConsentReducer.consent.metadata.schedule.cohort, convertedAdvancedOptions) ||
        (shouldValidate && !consentAdvancedOptions && Array.isArray(newConsentReducer.consent.metadata.schedule.cohort))

      action = () =>
        isUpdate
          ? dispatch(
              newValidateConsentAndSave({
                studyID,
                newConsentReducer,
                onRedirect,
                isPdfUpload,
                ...consentProps,
              }),
            )
          : dispatch(
              onCreateNewConsent({
                studyID,
                newConsentReducer,
                onRedirect,
                isPdfUpload,
              }),
            )
    } else if (path === 'createarticle' || path === 'editarticle') {
      shouldSave = !compareJSON(instrument, _instrument)
      action = () => dispatch(validateArticleAndSave(studyID, surveyEditor.article, onRedirect))
    } else if (path === 'selecttask') {
      shouldSave = instrument.title !== ''
      action = () => dispatch(saveTaskToDB(studyID, instrument)).then(onRedirect)
    } else if (path === 'properties') {
      shouldSave = !compareJSON(instrument, _instrument)
      action = () => dispatch(validateThenSave({ studyID, instrument, validationType: path, onRedirect }))
    } else if (path === 'schedule') {
      if (!hasAdvancedOptions && Array.isArray(instrument.metadata.schedule.cohort)) {
        instrument.metadata.schedule.cohort = {}
      }
      shouldSave = hasConditions ? true : !compareJSON(instrument, _instrument)
      action = () =>
        hasConditions
          ? dispatch(validateThenDeploy({ onFail: () => {}, onRedirect }, instrumentAdvancedOptions))
          : dispatch(validateThenSave({ studyID, instrument, validationType: path, onRedirect }))
    } else if (path === 'scoring') {
      if (isClinro) {
        shouldSave = !_compareInstruments(clinroEditor.clinro, clinroEditor.originalClinro) || hasScoreErrors
        action = () => dispatch(validateClinroAndSave({ studyID, clinroEditor, onRedirect, path }))
      } else if (isSurvey) {
        shouldSave = !_compareInstruments(surveyEditor.survey, surveyEditor.originalSurvey) || hasScoreErrors
        action = () => dispatch(validateSurveyAndSave({ studyID, surveyEditor, onRedirect, path }))
      } else if (isDiary) {
        shouldSave = !_compareInstruments(diaryEditor.diary, diaryEditor.originalDiary) || hasScoreErrors
        action = () => dispatch(validateDiaryAndSave({ studyID, diaryEditor, onRedirect, path }))
      }
    } else if (path === 'uploadinstrument' || (path === 'instruments' && !!upload.uploadedSurvey)) {
      shouldSave = upload.uploadedSurvey !== null
      action = () => dispatch(saveUploadToDatabase(studyID, upload.uploadedSurvey)).then(onRedirect)
    } else if (isSurvey) {
      shouldSave =
        path === 'createinstrument' ||
        !_compareInstruments(surveyEditor.survey, surveyEditor.originalSurvey) ||
        hasScoreErrors
      action = () => dispatch(validateSurveyAndSave({ studyID, surveyEditor, onRedirect, redirect: redirectPath }))
    } else if (isClinro) {
      shouldSave =
        path === 'createclinro' ||
        !_compareInstruments(clinroEditor.clinro, clinroEditor.originalClinro) ||
        hasScoreErrors
      action = () => dispatch(validateClinroAndSave({ studyID, clinroEditor, onRedirect, redirect: redirectPath }))
    } else if (isDiary) {
      shouldSave =
        path === 'creatediary' || !_compareInstruments(diaryEditor.diary, diaryEditor.originalDiary) || hasScoreErrors
      action = () => dispatch(validateDiaryAndSave({ studyID, diaryEditor, onRedirect, redirect: redirectPath }))
    }

    if (!shouldSave) {
      onRedirect()
    } else if (showModal) {
      if (path === 'consent' && !!consentProps) {
        dispatch(
          modalActions.openModal({
            onConfirm: () => {
              if (typeof confirmQuery === 'string') queryParams = confirmQuery
              action()
            },
            content: MODAL_CONTENT_MAP.updateConsent,
            confirmButton: MODAL_BUTTONS_MAP.yesUpdateConsent,
            cancelButton: MODAL_BUTTONS_MAP.cancel,
            className: MODAL_CLASSES_MAP.confirmation,
          }),
        )
      } else {
        dispatch(
          modalActions.openModal({
            onConfirm: () => {
              if (typeof confirmQuery === 'string') queryParams = confirmQuery
              action()
            },
            onCancel: resJson => {
              if (typeof cancelQuery === 'string') queryParams = cancelQuery
              onRedirect(resJson, true)
            },
            content: MODAL_CONTENT_MAP.saveChanges,
            confirmButton: MODAL_BUTTONS_MAP.yes,
            cancelButton: MODAL_BUTTONS_MAP.no,
            className: MODAL_CLASSES_MAP.confirmation,
            closeOnBackgroundClick: false,
          }),
        )
      }
    } else {
      action()
    }
  }
}

const _handleInstrumentErrors = errors => dispatch => {
  dispatch(setErrors(errors))
  dispatch(
    notyActions.showError({ text: generateNotyMessage('Please correct the required fields marked in red.', false) }),
  )
}

const validateInstrument = ({
  conditions,
  instrument,
  onValid,
  onInvalid,
  validationType,
  isNewInstArch,
  isInstrumentUnificationEnabled,
}) => {
  const errors =
    validationType === 'properties'
      ? getMetadataErrors(instrument, isNewInstArch, isInstrumentUnificationEnabled)
      : validationType === 'schedule'
      ? getSchedulingErrors(instrument, conditions)
      : { ...getAllErrors(instrument) }

  if (Object.keys(errors).length === 0) {
    onValid()
  } else {
    onInvalid(errors)
  }
}

// Validates surveys before saving and redirecting
const validateThenSave = ({ studyID, instrument, onRedirect, validationType, conditions }) => {
  return (dispatch, getState) => {
    const { currentStudy } = getState().study
    const { instrumentUnificationEnabled } = getState().instrumentReducer
    const isNewInstArch = currentStudy?.config?.instrument_architecture_version >= 2
    const onValid = () => {
      dispatch(saveInstrument(studyID, instrument)).then(onRedirect)
    }
    validateInstrument({
      instrument,
      validationType,
      onValid,
      onInvalid: errors => dispatch(_handleInstrumentErrors(errors)),
      conditions,
      isNewInstArch,
      isInstrumentUnificationEnabled: instrumentUnificationEnabled,
    })
  }
}

const getNewSchedule = (schedule, isNewInstArch) => {
  const newSchedule = { ...schedule }
  const { recurring } = newSchedule

  if (recurring && recurring.scheme !== 'persistent') {
    newSchedule.recurring_cal = recurring
  } else if (!recurring) {
    delete newSchedule.recurring_cal
  }
  newSchedule.recurring = DEFAULT_PERSISTENT_SCHEME

  return newSchedule
}

const prepInstrumentForSave = (instrument, isNewInstArch = false) => {
  const instrumentToSave = { ...instrument, metadata: { ...instrument.metadata } }
  const { type, metadata } = instrumentToSave
  const { visibility, visibilityRanges, is_optional, schedule } = metadata
  if (visibility === 'specific_time') {
    visibilityRanges.forEach(range => {
      const { start, end } = range
      range.start = moment.utc(start).format(VISIBILITY_TIME_FORMAT)
      range.end = moment.utc(end).format(VISIBILITY_TIME_FORMAT)
    })
  }
  const mapProgressWeight = is_optional ? 0.0 : 1.0
  instrumentToSave.map_progress_weight = mapProgressWeight
  if (type === INSTRUMENT_TYPE_MAP.diary) {
    const newSchedule = getNewSchedule(schedule, isNewInstArch)
    instrumentToSave.metadata.schedule = newSchedule
  } else if (type === INSTRUMENT_TYPE_MAP.task) {
    const taskName = instrumentToSave.metadata.task_name
    const requiredPermissions = []
    if (TASKS_WITH_AUDIO_PERMISSION.includes(taskName))
      requiredPermissions.push(MOBILE_APP_PERMISSION_NAMES_MAP.audioRecording)
    if (TASKS_WITH_STORAGE_PERMISSION.includes(taskName))
      requiredPermissions.push(MOBILE_APP_PERMISSION_NAMES_MAP.storageAccess)
    instrumentToSave.metadata.required_permissions = requiredPermissions
  }
  return instrumentToSave
}

export const handleDateFilterError = text => dispatch => {
  dispatch(notyActions.showError({ text: generateNotyMessage(text, false) }))
}

const fetchInstrument = (studyID, id) => {
  return dispatch => {
    return dispatch(
      request({
        url: `/control/studies/${studyID}/instruments/${id}`,
        success: json => Promise.resolve(json),
      }),
    )
  }
}

export const onFetchInstrument = (studyID, id, version, inDrawer) => (dispatch, getStore) => {
  const { study } = getStore()
  const isNewInstArch = study.currentStudy?.config?.instrument_architecture_version >= 2
  if (!inDrawer) dispatch(loadingActions.startLoader(true))
  return dispatch(fetchInstrument(studyID, id))
    .then(json => {
      _formatOldMetadata(json, isNewInstArch)
      dispatch(setInstrument(json))
      return Promise.resolve(json)
    })
    .then(json => {
      if (!inDrawer) dispatch(loadingActions.stopLoader(true))
      return Promise.resolve(json)
    })
}

const saveInstrument = (studyID, instrument) => {
  return (dispatch, getStore) => {
    const { study } = getStore()
    const isNewInstArch = study.currentStudy?.config?.instrument_architecture_version >= 2
    const instrumentToSave = prepInstrumentForSave(instrument, isNewInstArch)
    const success = () => {
      return Promise.resolve()
    }
    return dispatch(
      request({
        method: 'PUT',
        url: `/control/studies/${studyID}/instruments/${instrument.id}`,
        hasLoader: true,
        body: JSON.stringify(instrumentToSave),
        failMessage: 'Saving instrument failed',
        successMessage: 'Instrument saved successfully',
        success,
      }),
    )
  }
}

const fetchInstrumentEvents = (studyID, id) => {
  return dispatch => {
    return dispatch(
      request({
        url: `/control/studies/${studyID}/instruments/${id}/events`,
        success: json => Promise.resolve(json),
      }),
    )
  }
}

export const onFetchInstrumentEvents = (studyID, id) => {
  return dispatch => {
    dispatch(fetchInstrumentEvents(studyID, id)).then(payload => {
      dispatch(setInstrumentEvents(payload.participant_instrument_progress_by_version))
    })
  }
}

export const deployInstrument = ({
  studyID,
  instrumentID,
  isAnnouncement = false,
  successCallback,
  isConditionalDeployment = false,
}) => {
  return dispatch => {
    const _defaultSuccess = () => {
      dispatch(toggleSaveAttempt(false))
      browserHistory.push(`/studies/${studyID}/instruments`)

      if (isConditionalDeployment) {
        dispatch(fetchInstruments({ studyID, hasLoader: true }))
      }
    }
    const success = successCallback || _defaultSuccess
    return dispatch(
      request({
        method: 'POST',
        url: `/control/studies/${studyID}/instruments/${instrumentID}/deploy`,
        successMessage: `${isAnnouncement ? 'Announcement' : 'Instrument'} has been successfully deployed`,
        success,
        hasLoader: true,
        hasSender: isAnnouncement,
      }),
    )
  }
}

const toggleDeployConfirm = (studyID, instrument) => {
  return dispatch => {
    const isConditionalDeployment = Array.isArray(
      instrument?.metadata?.schedule?.chain_deployment_info?.prev_instrument_id,
    )
    const onConfirm = () => {
      dispatch(deployInstrument({ studyID, instrumentID: instrument.id, isConditionalDeployment }))
    }
    dispatch(
      modalActions.openModal({
        content: MODAL_CONTENT_MAP.deployInstrument,
        confirmButton: MODAL_BUTTONS_MAP.proceed,
        cancelButton: MODAL_BUTTONS_MAP.cancel,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  }
}

/**
 *
 * This function saves, handles instruments conditions, and triggers the deploy flow of the instrument.
 */
const validateThenDeploy = ({ onFail, onRedirect }, _instrumentAdvancedOptions = null) => {
  return (dispatch, getState) => {
    const { instrumentReducer, study } = getState()
    const convertedAdvancedOptions = convertAdvancedOptions(_instrumentAdvancedOptions)
    const { instrument, instrumentTriggers, conditionsToDelete } = instrumentReducer
    const studyID = study.currentStudy.id
    const { conditions } = instrumentTriggers

    const hasAdvancedOptions = checkForAdvancedDeploymentOptions(_instrumentAdvancedOptions)
    if (hasAdvancedOptions && convertedAdvancedOptions) {
      instrument.metadata.schedule.cohort = [...convertedAdvancedOptions]
    }

    if (!hasAdvancedOptions && Array.isArray(instrument.metadata.schedule.cohort)) {
      instrument.metadata.schedule.cohort = {}
    }

    let onValid = () => {
      dispatch(clearErrors())
      return dispatch(saveInstrument(studyID, instrument)).then(() =>
        onRedirect ? onRedirect() : dispatch(toggleDeployConfirm(studyID, instrument)),
      )
    }

    if (conditions?.length > 0 || conditionsToDelete.length > 0) {
      const conditionParams = {
        studyId: studyID,
        conditions: instrumentTriggers,
        isInstrumentTrigger: true,
      }
      if (conditionsToDelete.length > 0) {
        conditionParams.toDeleteArr = conditionsToDelete
      }

      onValid = () => {
        dispatch(clearErrors())
        return dispatch(saveConditions(conditionParams)).then(payload => {
          const hasUpdate = payload.some(val => {
            return !!val?.oldConditionId
          })
          const newInstrument = { ...instrument }
          const { conditions: _conditions } = instrument.metadata
          if (hasUpdate) {
            payload.forEach(condition => {
              if (condition?.oldConditionId) {
                replaceConditionId(newInstrument, condition)
              } else if (condition) {
                newInstrument.metadata.conditions.push(condition)
              }
            })
          } else if (_conditions && payload) {
            const newConditions = [..._conditions]
            payload.forEach(condition => {
              if (condition) newConditions.push(condition)
            })
            newInstrument.metadata.conditions = newConditions
          } else if (payload) {
            newInstrument.metadata.conditions = payload
          }
          newInstrument.metadata.conditions_data = instrumentTriggers
          return dispatch(saveInstrument(studyID, newInstrument)).then(() => {
            dispatch(clearConditionsToDelete())
            dispatch(fetchInstrumentConditions(studyID, instrument.metadata.conditions)).then(() =>
              onRedirect ? onRedirect() : dispatch(toggleDeployConfirm(studyID, newInstrument)),
            )
          })
        })
      }
      return validateInstrument({
        instrument,
        validationType: 'schedule',
        onValid,
        onInvalid: errors => {
          onFail()
          return dispatch(_handleInstrumentErrors(errors))
        },
        conditions,
      })
    }
    return validateInstrument({
      instrument,
      validationType: 'schedule',
      onValid,
      onInvalid: errors => {
        onFail()
        return dispatch(_handleInstrumentErrors(errors))
      },
    })
  }
}

//
// UTILS
//

export const _getDefaultInstrumentScoringTriggerAction = ({
  instrumentId,
  conditionId,
  startDate = moment.utc().toISOString(),
  virtualVisitsEnabled = true,
  scoreParams = {},
}) => {
  const { score_id, score_name } = scoreParams
  const result = virtualVisitsEnabled
    ? {
        triggers: [
          {
            resource_type: 'instrument_scoring',
            resource_function_name: 'get_instrument_scores',
            resource_function_params: {
              instrument_id: instrumentId,
              instrument_score: '',
              window: 2,
              logical_operator: '>=',
            },
          },
        ],
        action: {
          type: 'create_virtual_visit_emergency_call',
          action_params: {},
        },
        schedule: {
          start_date: startDate,
          recurrence_rule: {
            frequency: 'minutely',
            interval: 2,
          },
        },
      }
    : {
        triggers: [
          {
            resource_type: 'instrument_scoring',
            resource_function_name: 'get_instrument_score_by_name',
            resource_function_params: {
              instrument_id: instrumentId,
              instrument_score: '',
              window: 2,
              logical_operator: '==',
              role_ids: [8],
              score_id,
              score_name,
            },
          },
        ],
        action: {
          type: 'send_email',
          action_params: {
            email_template: 'instrument_score_participant_response',
          },
        },
        schedule: {
          is_manual: true,
        },
      }
  if (conditionId) result.condition_id = conditionId
  return result
}

export const _getDefaultInstrumentComplianceTriggerAction = ({ instrumentId, conditionId }) => {
  const result = {
    triggers: [
      {
        resource_type: 'instrument',
        resource_function_name: 'get_instrument_compliance',
        resource_function_params: {
          completion_comparison: 5,
          instrument_id: instrumentId,
          window: 4320, // 3 days in minutes
          logical_operator: '<',
          role_ids: [3, 4],
        },
      },
    ],
    action: {
      type: 'send_email',
      action_params: {
        email_template: 'instrument_compliance',
      },
    },
    schedule: {
      is_manual: true,
    },
  }
  if (conditionId) result.condition_id = conditionId
  return result
}

export const _getDefaultDiaryTriggerAction = ({
  conditionId,
  instrumentId,
  daysAfterInstrumentDeployment,
  timeString = '09:00',
  date = new Date(),
  recurring,
}) => {
  const startDate = date

  let _daysAfterDeployment = 1
  let _frequencyInterval = 1

  if (daysAfterInstrumentDeployment) {
    _daysAfterDeployment = daysAfterInstrumentDeployment
  }
  if (recurring) {
    const { interval } = recurring
    _daysAfterDeployment = interval
    _frequencyInterval = interval
    if (daysAfterInstrumentDeployment) {
      _daysAfterDeployment += daysAfterInstrumentDeployment - 1
    }
  }

  startDate.setDate(startDate.getDate() + _daysAfterDeployment)

  const [hours, minutes] = timeString.split(':').map(numStr => Number(numStr))
  startDate.setUTCHours(hours, minutes, 0, 0)
  const resultTriggerAction = {
    triggers: [
      {
        resource_type: 'instrument',
        resource_function_name: 'send_new_activity_notification',
        resource_function_params: {
          instrument_id: instrumentId,
          time_of_day: timeString,
        },
      },
    ],
    action: {
      type: 'send_push_notification',
      action_params: {
        title_string_id: 'new_instrument_notification',
      },
    },
    schedule: {
      start_date: startDate, // the notifications should start a day after the initial deployment
      recurrence_rule: {
        frequency: 'daily',
        interval: _frequencyInterval,
        by_hour: [hours],
        by_minute: minutes,
      },
    },
  }
  if (conditionId) resultTriggerAction.condition_id = conditionId
  return resultTriggerAction
}

const generateUpdatedDiaryTriggerAction = ({ conditionId, deploy, instrumentId, recurring }) => {
  let resultTriggerAction
  const { first_login, absolute, time_of_day } = deploy
  if (first_login) {
    const { interval } = first_login
    resultTriggerAction = _getDefaultDiaryTriggerAction({
      conditionId,
      instrumentId,
      daysAfterInstrumentDeployment: interval + 1,
      timeString: time_of_day,
      recurring,
    })
  } else if (absolute) {
    const date = fromEpochToUTC(absolute).toDate()
    resultTriggerAction = _getDefaultDiaryTriggerAction({
      conditionId,
      instrumentId,
      date,
      timeString: time_of_day,
      recurring,
    })
  } else {
    resultTriggerAction = _getDefaultDiaryTriggerAction({ conditionId, instrumentId, recurring })
  }
  return resultTriggerAction
}

const replaceConditionId = (instrument, conditionIdObj) => {
  const { oldConditionId, newConditionId } = conditionIdObj
  const oldConditionIdIndex = instrument.metadata.conditions.indexOf(oldConditionId)
  instrument.metadata.conditions[oldConditionIdIndex] = newConditionId
}

const _changeInstrumentTriggerType = ({
  state,
  newType,
  conditionId,
  instrumentId,
  index,
  virtualVisitsEnabled,
  scoreParams,
}) => {
  const newState = { ...state }
  switch (newType) {
    case TRIGGER_TYPE_MAP.score:
      newState.conditions[index] = _getDefaultInstrumentScoringTriggerAction({
        instrumentId,
        conditionId,
        virtualVisitsEnabled,
        scoreParams,
      })
      return newState
    case TRIGGER_TYPE_MAP.compliance:
      newState.conditions[index] = _getDefaultInstrumentComplianceTriggerAction({ instrumentId, conditionId })
      return newState
    default:
      return newState
  }
}

//
// ACTION CREATORS
//

const updateMetadataField = (field, value, errorPath) => {
  return {
    type: UPDATE_METADATA_FIELD,
    field,
    value,
    errorPath,
  }
}

const deleteMetadataField = field => {
  return {
    type: DELETE_METADATA_FIELD,
    field,
  }
}

const updateInstrumentTitle = title => {
  return {
    type: UPDATE_INSTRUMENT_TITLE,
    title,
  }
}

const updateInstrumentDisplayLocation = displayLocation => {
  return {
    type: UPDATE_INSTRUMENT_DISPLAY_LOCATION,
    displayLocation,
  }
}

const setErrors = errors => {
  return {
    type: SET_ERRORS,
    errors,
  }
}

const clearErrors = () => ({ type: CLEAR_ERRORS })

const updateSchedule = (key, obj) => {
  return {
    type: UPDATE_SCHEDULE,
    key,
    obj,
  }
}

const updateScheduleField = (key, field, value, errorPath) => {
  return {
    type: UPDATE_SCHEDULE_FIELD,
    key,
    field,
    value,
    errorPath,
  }
}

const deleteMetadataKey = (key, field) => {
  if (key === 'schedule') {
    return {
      type: DELETE_SCHEDULE_FIELD,
      field,
    }
  }
}

const removeChainDeploymentInfo = () => {
  return {
    type: REMOVE_CHAIN_DEPLOYMENT_INFO,
  }
}

const updateVisibility = (key, value) => {
  return {
    type: UPDATE_VISIBILITY,
    key,
    value,
  }
}

const addVisibilityRange = () => {
  return {
    type: ADD_VISIBILITY_RANGE,
  }
}

const updateVisibilityRange = (idx, key, value) => {
  return {
    type: UPDATE_VISIBILITY_RANGE,
    idx,
    key,
    value,
  }
}

const updateGeofenceEnabled = value => {
  return {
    type: UPDATE_GEOFENCE_ENABLED,
    value,
  }
}

const updateInstrumentRoles = rolesArr => {
  return {
    type: UPDATE_INSTRUMENT_ROLES,
    instrumentRoles: rolesArr,
  }
}

export const setInstrument = instrument => {
  return {
    type: SET_INSTRUMENT,
    payload: instrument,
  }
}

export const resetInstrument = instrumentType => {
  return {
    type: RESET_INSTRUMENT,
    instrumentType,
  }
}

export const resetSchedule = () => {
  return {
    type: RESET_SCHEDULE,
  }
}

const toggleSaveAttempt = bool => {
  return {
    type: TOGGLE_SAVE_ATTEMPT,
    bool,
  }
}

const setInstrumentEvents = instrumentEvents => {
  return {
    type: SET_INSTRUMENT_EVENTS,
    instrumentEvents,
  }
}

const addInstrumentTrigger = ({
  instrumentId,
  isScoringTrigger = false,
  trigger,
  virtualVisitsEnabled,
  scoreParams,
}) => {
  return {
    type: ADD_INSTRUMENT_TRIGGER,
    trigger:
      trigger ||
      (isScoringTrigger
        ? _getDefaultInstrumentScoringTriggerAction({ instrumentId, virtualVisitsEnabled, scoreParams })
        : _getDefaultInstrumentComplianceTriggerAction({ instrumentId })),
  }
}

const addDiaryNotificationTrigger = ({ instrumentId }) => {
  return {
    type: ADD_DIARY_NOTIFICATION_TRIGGER,
    trigger: _getDefaultDiaryTriggerAction({ instrumentId }),
  }
}

const updateDiaryNotificationTrigger = ({ deploy, instrumentId, conditionId, recurring }) => {
  return {
    type: UPDATE_DIARY_NOTIFICATION_TRIGGER,
    conditionId,
    deploy,
    instrumentId,
    recurring,
  }
}

const changeTriggerType = ({ instrumentId, index, conditionId, newType, virtualVisitsEnabled, scoreParams }) => {
  return {
    type: CHANGE_INSTRUMENT_TRIGGER_TYPE,
    index,
    instrumentId,
    conditionId,
    newType,
    virtualVisitsEnabled,
    scoreParams,
  }
}

const deleteInstrumentTrigger = (index, conditionId) => {
  return {
    type: DELETE_INSTRUMENT_TRIGGER,
    index,
    conditionId,
  }
}

const updateInstrumentTrigger = ({ key, value, key2, value2 }, index, isRootTrigger) => {
  return {
    type: UPDATE_INSTRUMENT_TRIGGER,
    key,
    value,
    key2,
    value2,
    index,
    isRootTrigger,
  }
}
const updateInstrumentAction = ({ key, value, key2, value2, score }, index) => {
  return {
    type: UPDATE_INSTRUMENT_ACTION,
    key,
    value,
    key2,
    value2,
    index,
    score,
  }
}

export const clearInstrumentTriggers = () => {
  return {
    type: CLEAR_INSTRUMENT_TRIGGERS,
  }
}

const clearConditionsToDelete = () => {
  return {
    type: CLEAR_INSTRUMENT_CONDITIONS_TO_DELETE,
  }
}

const updatePrevalidatedErrors = obj => {
  return {
    type: UPDATE_PREVALIDATED_ERRORS,
    prevalErrors: obj,
  }
}

const clearPrevalidatedError = errorKey => {
  return {
    type: CLEAR_PREVALIDATED_ERROR,
    errorKey,
  }
}

const setAdvancedOptionsToStore = value => {
  return {
    type: SET_ADVANCED_OPTIONS,
    value,
  }
}

const resetAdvancedOptions = () => {
  return {
    type: RESET_ADVANCED_OPTIONS,
  }
}

const setInstrumentUnificationEnabled = value => {
  return {
    type: SET_INSTRUMENT_UNIFICATION_ENABLED,
    value,
  }
}

const resetInstrumentUnificationEnabled = () => {
  return {
    type: RESET_INSTRUMENT_UNIFICATION_ENABLED,
  }
}

//
// UTILS
//

const _redirect = (redirect, studyID, instrumentID, consentProps, queryParams) => {
  if (redirect) {
    if (redirect === 'e-consent') {
      browserHistory.push(`/studies/${studyID}/e-consent${queryParams || ''}`)
    } else if (redirect === 'consents') {
      browserHistory.push(`/studies/${studyID}/consents${queryParams || ''}`)
    } else if (['schedule', 'editconsent', 'formatting'].includes(redirect) && !!consentProps) {
      const { consent_id: consentId, consent_version: consentVersion, isUpdate } = consentProps
      const _instrumentId = consentId || instrumentID
      if (isUpdate) {
        browserHistory.push(
          `/studies/${studyID}/consents/${_instrumentId}/${consentVersion}/${redirect}${queryParams || ''}`,
        )
      } else {
        const _consentVersion = consentVersion ? consentVersion + 1 : 1
        browserHistory.push(
          `/studies/${studyID}/consents/${_instrumentId}/${_consentVersion}/${redirect}${queryParams || ''}`,
        )
      }
    } else if (['properties', 'schedule', 'deploy'].includes(redirect) && !!consentProps) {
      const { consent_id: consentId, consent_version: consentVersion } = consentProps
      browserHistory.push(`/studies/${studyID}/consents/${consentId}/${consentVersion}/${redirect}${queryParams || ''}`)
    } else if (redirect === 'createconsent') {
      browserHistory.push(`/studies/${studyID}/consents/${redirect}${queryParams || ''}`)
    } else if (redirect === 'consent-preview') {
      browserHistory.push(`/studies/${studyID}/e-consent/consent/preview${queryParams || ''}`)
    } else if (redirect === 'instruments' || instrumentID === undefined) {
      browserHistory.push(`/studies/${studyID}/instruments${queryParams || ''}`)
    } else {
      browserHistory.push(`/studies/${studyID}/instruments/${instrumentID}/${redirect}${queryParams || ''}`)
    }
  }
}

export const formatChainedDeployment = schedule => {
  const { chain_deployment_info } = schedule
  if (chain_deployment_info) {
    const { cohort, deployment_condition, prev_instrument_id } = chain_deployment_info

    const termObject = { instrument_id: prev_instrument_id }
    const _deploymentCondition = {}

    if (!Array.isArray(prev_instrument_id)) {
      schedule.chain_deployment_info.prev_instrument_id = [prev_instrument_id]
    }
    if (cohort) {
      termObject.cohort = { ...cohort }
      delete schedule.chain_deployment_info.cohort
    }
    if (!deployment_condition) {
      _deploymentCondition.operator = 'AND'
      _deploymentCondition.terms = [termObject]
    }
    schedule.chain_deployment_info.deployment_condition = deployment_condition || _deploymentCondition
  }
}

const _formatOldMetadata = (instrument, isNewInstArch) => {
  const { metadata = {} } = instrument || {}
  const isDiary = instrument?.type === INSTRUMENT_TYPE_MAP.diary
  const { schedule, visibility, visibilityRanges, is_optional } = metadata
  if (schedule) {
    // expiration cannot be absolute if deployment schedule is relative
    if ('first_login' in schedule.deploy && 'absolute' in schedule.expire) {
      schedule.expire = { never: null }
    }

    // remove old 'deploy_time' keys and convert to 'first_login'
    if (schedule.expire.deploy_time) {
      schedule.expire.first_login = schedule.expire.deploy_time
      schedule.expire.first_login.interval = schedule.expire.first_login.interval + schedule.deploy.first_login.interval
      delete schedule.expire.deploy_time
    }

    // convert all week interval types to days
    if (schedule.deploy.first_login && schedule.deploy.first_login.interval_type === 'weeks') {
      schedule.deploy.first_login.interval *= 7
      schedule.deploy.first_login.interval_type = 'days'
    }
    if (schedule.expire.first_login && schedule.expire.first_login.interval_type === 'weeks') {
      schedule.expire.first_login.interval *= 7
      schedule.expire.first_login.interval_type = 'days'
    }

    // handle legacy recurring formats
    if (schedule.recurring) {
      if (schedule.recurring.interval_type === 'weeks') {
        schedule.recurring.interval *= 7
        schedule.recurring.interval_type = 'days'
      }
      if ('persistent' in schedule.recurring) {
        schedule.recurring = { ...schedule.recurring, ...DEFAULT_PERSISTENT_SCHEME }
      } else if (
        schedule.recurring.scheme !== 'persistent' &&
        schedule.recurring.scheme !== 'n-hours' &&
        schedule.recurring.scheme !== 'n-minutes'
      ) {
        schedule.recurring.scheme = 'n-days'
        if (!('visible_days' in schedule.recurring)) {
          schedule.recurring.visible_days = schedule.recurring.interval
        }
      }
    }
    if (isDiary) {
      delete schedule.recurring
      if (schedule.recurring_cal) {
        schedule.recurring = schedule.recurring_cal
      }
    }
    if (schedule.deploy?.absolute && !schedule.deploy.time_of_day) {
      schedule.deploy.time_of_day = DEFAULT_DEPLOY_TIME
    }
    /**
     * This will handle converting old chained deployemnt schemas into the new schema shape
     */
    if (schedule.chain_deployment_info) {
      formatChainedDeployment(schedule)
    }
  } else {
    metadata.schedule = _getDefaultMetadata().schedule
  }
  if (visibility === 'specific_time') {
    visibilityRanges.forEach(range => {
      const { start, end } = range
      range.start = moment.utc(start, VISIBILITY_TIME_FORMAT)
      range.end = moment.utc(end, VISIBILITY_TIME_FORMAT)
    })
  }
  if (is_optional === undefined) {
    metadata.is_optional = false
  }
  return schedule
}

const _formatInstrumentEvents = eventsObj => {
  const events = { ...eventsObj }
  const eventTypes = Object.keys(events)
  eventTypes.forEach(eventType => {
    events[eventType] = uniqueArr(eventsObj[eventType])
  })
  return events
}

export const _getDefaultInstrument = (type = 'SURVEY') => {
  return {
    type,
    title: '',
    metadata: _getDefaultMetadata(type),
    is_geofencing_enabled: false,
    instrument_roles: type === INSTRUMENT_TYPE_MAP.clinro ? [11, 12, 13] : [],
  }
}

const defaultInstrumentEvents = {
  participants_scheduled: [],
  participants_received: [],
  participants_seen: [],
  particpants_opened: [],
  participants_completed: [],
}

const addInstrumentPermissions = instrument => {
  const { type, instrument_roles: instrumentRoles } = instrument
  if (instrumentRoles?.length === 0 && type === INSTRUMENT_TYPE_MAP.clinro) instrument.instrument_roles = [11, 12, 13]
  return instrument
}

//
// REDUCERS
//

export const errors = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_ERRORS:
      return action.errors
    case UPDATE_SCHEDULE_FIELD:
    case UPDATE_METADATA_FIELD:
      if (state.hasOwnProperty(action.errorPath) && !_isInvalid(action.value)) {
        delete newState[action.errorPath]
        return newState
      }
      return state
    case SET_INSTRUMENT:
    case CLEAR_ERRORS:
    case UPDATE_SCHEDULE:
      return {}
    default:
      return state
  }
}

export const deployedAttempted = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SAVE_ATTEMPT:
      return action.bool === undefined ? !state : action.bool
    default:
      return state
  }
}

const _instrument = (state = {}, action) => {
  switch (action.type) {
    case SET_INSTRUMENT:
      return JSON.parse(JSON.stringify(action.payload))
    default:
      return state
  }
}

const isEdited = (state = false, action) => {
  switch (action.type) {
    case SET_INSTRUMENT:
      return false
    case UPDATE_SCHEDULE:
    case UPDATE_SCHEDULE_FIELD:
    case DELETE_SCHEDULE_FIELD:
    case DELETE_METADATA_FIELD:
    case UPDATE_METADATA_FIELD:
    case UPDATE_VISIBILITY:
    case ADD_VISIBILITY_RANGE:
    case UPDATE_VISIBILITY_RANGE:
    case UPDATE_GEOFENCE_ENABLED:
    case UPDATE_INSTRUMENT_ROLES:
    case REMOVE_CHAIN_DEPLOYMENT_INFO:
      return true
    default:
      return state
  }
}

const instrumentEvents = (state = defaultInstrumentEvents, action) => {
  switch (action.type) {
    case SET_INSTRUMENT_EVENTS:
      return _formatInstrumentEvents(action.instrumentEvents)
    case CLEAR_INSTRUMENT_EVENTS:
      return {}
    default:
      return state
  }
}

export const instrumentTriggers = (state = { conditions: [] }, action) => {
  const newState = { ...state }
  switch (action.type) {
    case ADD_INSTRUMENT_TRIGGER: {
      newState.conditions.push(action.trigger)
      return newState
    }
    case DELETE_INSTRUMENT_TRIGGER: {
      const { conditions } = newState
      const newConditionsArr = [...conditions]
      newConditionsArr.splice(action.index, 1)
      newState.conditions = newConditionsArr
      return newState
    }
    case UPDATE_INSTRUMENT_TRIGGER:
      return changeTrigger(
        newState,
        { key: action.key, value: action.value, key2: action.key2, value2: action.value2 },
        action.index,
        action.isRootTrigger,
      )

    case UPDATE_INSTRUMENT_ACTION:
      return changeAction(
        newState,
        { key: action.key, value: action.value, key2: action.key2, value2: action.value2, score: action.score },
        action.index,
      )
    case CHANGE_INSTRUMENT_TRIGGER_TYPE: {
      const { index, instrumentId, conditionId, newType, virtualVisitsEnabled, scoreParams } = action
      const params = {
        state: newState,
        newType,
        conditionId,
        instrumentId,
        index,
        virtualVisitsEnabled,
        scoreParams,
      }
      return _changeInstrumentTriggerType(params)
    }
    case SET_INSTRUMENT_TRIGGERS:
      return { conditions: action.triggers }
    case CLEAR_INSTRUMENT_TRIGGERS:
      return { conditions: [] }
    case ADD_DIARY_NOTIFICATION_TRIGGER: {
      newState.conditions.unshift(action.trigger)
      return newState
    }
    case UPDATE_DIARY_NOTIFICATION_TRIGGER: {
      const { deploy, conditionId, instrumentId, recurring } = action
      const newDiaryTriggerAction = generateUpdatedDiaryTriggerAction({ conditionId, deploy, instrumentId, recurring })
      newState.conditions[0] = newDiaryTriggerAction
      return newState
    }
    default:
      return state
  }
}

const conditionsToDelete = (state = [], action) => {
  const newState = [...state]
  switch (action.type) {
    case DELETE_INSTRUMENT_TRIGGER:
      if (action.conditionId) newState.push(action.conditionId)
      return newState
    case CLEAR_INSTRUMENT_TRIGGERS:
    case CLEAR_INSTRUMENT_CONDITIONS_TO_DELETE:
      return []
    default:
      return state
  }
}

export const instrument = (state = _getDefaultInstrument(), action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_INSTRUMENT:
      return addInstrumentPermissions(action.payload) || _getDefaultInstrument()
    case RESET_INSTRUMENT:
      return _getDefaultInstrument(action.instrumentType)
    case RESET_SCHEDULE:
      newState.metadata.schedule = _getDefaultSchedule()
      return newState
    case UPDATE_INSTRUMENT_TITLE:
      newState.title = action.title
      return newState
    case UPDATE_INSTRUMENT_DISPLAY_LOCATION:
      newState.display_location = action.displayLocation
      newState.metadata.display_location = action.displayLocation
      newState.metadata.placement = action.displayLocation
      return newState
    case UPDATE_ARTICLE_URL:
      newState.url = action.url
      return newState
    case UPDATE_SCHEDULE:
      if (action.obj === null) {
        delete newState.metadata.schedule[action.key]
      } else {
        newState.metadata.schedule[action.key] = action.obj
      }
      return newState
    case UPDATE_SCHEDULE_FIELD:
      newState.metadata.schedule = { ...newState.metadata.schedule }
      newState.metadata.schedule[action.key] = { ...state.metadata.schedule[action.key] }
      newState.metadata.schedule[action.key][action.field] = action.value
      return newState
    case DELETE_SCHEDULE_FIELD:
      newState.metadata.schedule = { ...newState.metadata.schedule }
      delete newState.metadata.schedule[action.field]
      return newState
    case REMOVE_CHAIN_DEPLOYMENT_INFO:
      delete newState.metadata.schedule.chain_deployment_info
      return newState
    case UPDATE_VISIBILITY:
      newState.metadata = { ...newState.metadata }
      if (action.key === 'visibilityRanges' && action.value === null) {
        delete newState.metadata.visibilityRanges
      } else {
        newState.metadata[action.key] = action.value
      }
      return newState
    case ADD_VISIBILITY_RANGE:
      newState.metadata = { ...newState.metadata }
      newState.metadata.visibilityRanges = newState.metadata.visibilityRanges
        ? newState.metadata.visibilityRanges.slice()
        : []
      const defaultStart = moment()
        .hours(4)
        .startOf('hour')
        .toISOString()
      const defaultEnd = moment()
        .hours(16)
        .startOf('hour')
        .toISOString()
      newState.metadata.visibilityRanges.push({ start: defaultStart, end: defaultEnd })
      return newState
    case UPDATE_VISIBILITY_RANGE:
      newState.metadata = { ...newState.metadata }
      newState.metadata.visibilityRanges = newState.metadata.visibilityRanges.slice()
      newState.metadata.visibilityRanges[action.idx][action.key] = action.value.startOf('minute').toISOString()
      return newState
    case DELETE_METADATA_FIELD:
      newState.metadata = { ...newState.metadata }
      delete newState.metadata[action.field]
      return newState
    case UPDATE_METADATA_FIELD:
      newState.metadata[action.field] = action.value
      return newState
    case UPDATE_GEOFENCE_ENABLED:
      newState.is_geofencing_enabled = action.value
      return newState
    case UPDATE_INSTRUMENT_ROLES:
      newState.instrument_roles = action.instrumentRoles
      return newState
    case DELETE_INSTRUMENT_TRIGGER: {
      newState.metadata = { ...newState.metadata }
      const { conditions } = newState.metadata
      if (conditions) conditions.splice(newState.metadata.conditions.indexOf(action.conditionId), 1)
      return newState
    }
    case UPDATE_PREVALIDATED_ERRORS: {
      newState.metadata.schedule.prevalErrors = action.prevalErrors
      return newState
    }
    case CLEAR_PREVALIDATED_ERROR: {
      const { prevalErrors } = newState.metadata.schedule
      if (prevalErrors) {
        delete prevalErrors[action.errorKey]
        const hasPrevalErrors = Object.keys(prevalErrors).length > 0
        if (!hasPrevalErrors) delete newState.metadata.schedule.prevalErrors
      }
      return newState
    }
    default:
      return state
  }
}

const userRoles = (state = [], action) => {
  switch (action.type) {
    case SET_CLINRO_ROLES: {
      return action.roles
    }
    default:
      return state
  }
}

const visitTemplates = (state = [], action) => {
  switch (action.type) {
    case SET_VISIT_TEMPLATES:
      return action.visits
    default:
      return state
  }
}

const instrumentAdvancedOptions = (state = {}, action) => {
  switch (action.type) {
    case SET_ADVANCED_OPTIONS:
      return action.value
    case RESET_ADVANCED_OPTIONS:
      return {}
    default:
      return state
  }
}

const instrumentUnificationEnabled = (state = null, action) => {
  switch (action.type) {
    case SET_INSTRUMENT_UNIFICATION_ENABLED:
      return action.value
    case RESET_INSTRUMENT_UNIFICATION_ENABLED:
      return {}
    default:
      return state
  }
}

//
// EXPORTS
//

export const instrumentActions = {
  addInstrumentTrigger,
  addDiaryNotificationTrigger,
  changeTriggerType,
  clearInstrumentTriggers,
  deleteInstrumentTrigger,
  onFetchInstrument,
  onFetchInstrumentEvents,
  onSave,
  updateDiaryNotificationTrigger,
  updateGeofenceEnabled,
  updateInstrumentAction,
  updateInstrumentRoles,
  updateInstrumentTrigger,
  handleDateFilterError,
  setInstrumentUnificationEnabled,
  resetInstrumentUnificationEnabled,
}

export const metadataActions = {
  deleteMetadataField,
  updateMetadataField,
  updateInstrumentTitle,
  updateInstrumentDisplayLocation,
  onSave,
}

export const schedulerActions = {
  addVisibilityRange,
  clearInstrumentTriggers,
  deployInstrument: validateThenDeploy,
  removeChainDeploymentInfo,
  resetSchedule,
  updateCohort: obj => updateSchedule('cohort', obj),
  updateCohortField: (field, value, keyPath) => updateScheduleField('cohort', field, value, keyPath),
  updateChainDeployInfo: obj => updateSchedule('chain_deployment_info', obj),
  updateChainDeployInfoField: (field, value, keyPath) =>
    updateScheduleField('chain_deployment_info', field, value, keyPath),
  updateDeploy: obj => updateSchedule('deploy', obj),
  updateDeployField: (field, value, keyPath) => updateScheduleField('deploy', field, value, keyPath),
  updateExpire: obj => updateSchedule('expire', obj),
  updateExpireField: (field, value, keyPath) => updateScheduleField('expire', field, value, keyPath),
  updateRecurring: obj => updateSchedule('recurring', obj),
  updateRecurringField: (field, value, keyPath) => updateScheduleField('recurring', field, value, keyPath),
  updateTimeoutWindow: obj => updateSchedule('timeout_window', obj),
  updateTimeoutWindowField: (field, value, keyPath) => updateScheduleField('timeout_window', field, value, keyPath),
  updateScheduleKey: (key, obj) => updateSchedule(key, obj),
  updateScheduleKeyField: (key, field, value, keyPath) => updateScheduleField(key, field, value, keyPath),
  deleteMetadataKey: (key, field) => deleteMetadataKey(key, field),
  updateSchedule,
  updatePrevalidatedErrors,
  clearPrevalidatedError,
  updateVisibility,
  updateVisibilityRange,
  setAdvancedOptionsToStore,
  resetAdvancedOptions,
}

export default combineReducers({
  conditionsToDelete,
  instrument,
  _instrument,
  instrumentEvents,
  instrumentTriggers,
  errors,
  deployedAttempted,
  isEdited,
  userRoles,
  visitTemplates,
  instrumentAdvancedOptions,
  instrumentUnificationEnabled,
})
