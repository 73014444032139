import React, { useEffect } from 'react'
import { Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import '../stylesheets/Instruments.scss'
import UploadSurveyInput from '../../Instrument/routes/UploadSurvey/components/UploadSurveyInput'
import '../../../../../../../stylesheets/Wizard.scss'
import { FLAGSMITH_FEATURES } from 'utils/constants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION } from 'utils/constants'

const InstrumentWizard = ({
  fileName,
  hasClinro,
  onDiscardUpload,
  onSave,
  onSurveyUpload,
  params,
  studyLock,
  title,
  updateWizardTitle,
  uploadedSurvey,
  userId,
  hasDiary,
  study,
}) => {
  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const sections = hasInstrumentUnification
    ? ['Add questions', 'Add properties', 'Send participant instrument', 'Analyze results']
    : ['Add questions', 'Add properties', 'Send survey', 'Analyze results']

  useEffect(() => {
    updateWizardTitle('')
  }, [])
  return (
    <div className='instrument-wizard page'>
      <div className='wizard'>
        <div className='survey section'>
          <h1>{hasInstrumentUnification ? 'Create your first participant instrument' : 'Create your first survey'}</h1>
          <p>
            {hasInstrumentUnification
              ? 'A fast and easy way to build a participant instrument.'
              : 'A fast and easy way to build a survey.'}
          </p>
          <div className='visual'>
            {sections.map((text, idx) => {
              return (
                <div className='box-section' key={`section_${text}`}>
                  <div className='circle'>{idx + 1}</div>
                  <h4>{text}</h4>
                </div>
              )
            })}
          </div>
          <input
            value={title}
            onChange={e => updateWizardTitle(e.target.value)}
            className='custom-input'
            id='instrument-name-input'
            placeholder='Name this Instrument'
            disabled={studyLock}
          />
          <div className='instrument-button-list'>
            <Button
              grey={hasClinro ? true : undefined}
              onClick={() => browserHistory.push(`/studies/${studyID}/instruments/createinstrument`)}
              content='Create Participant Instrument'
              id='create-participant-instrument'
              disabled={studyLock}
            />
            {hasClinro && (
              <Button
                onClick={() => browserHistory.push(`/studies/${studyID}/instruments/createclinro`)}
                content='Create Clinician Instrument'
                id='create-clinician-instrument'
                disabled={studyLock}
              />
            )}
          </div>
          <h2>OR</h2>
          <UploadSurveyInput
            onSurveyUpload={onSurveyUpload}
            fileName={fileName}
            onDiscardUpload={onDiscardUpload}
            params={params}
            disabled={studyLock}
          />
          <Button
            disabled={uploadedSurvey === null || studyLock}
            onClick={() => onSave('properties')}
            id='upload-button'
            content='Upload'
          />
          {hasDiary && !hasInstrumentUnification && (
            <div className='diary section'>
              <h1>Create your first diary</h1>
              <Button
                onClick={() => browserHistory.push(`/studies/${studyID}/instruments/creatediary`)}
                content='Create Diary'
                disabled={studyLock}
              />
            </div>
          )}
        </div>
        <div className='article section'>
          <h1>Create your first article</h1>
          <input
            value={title}
            onChange={e => updateWizardTitle(e.target.value)}
            className='custom-input'
            id='article-name-input'
            placeholder='Name this Instrument'
            disabled={studyLock}
          />
          <Button
            onClick={() => browserHistory.push(`/studies/${studyID}/instruments/createarticle`)}
            content='Create Article'
            id='create-article'
            disabled={studyLock}
          />
        </div>
        <div className='task section'>
          <h1>Create your first task</h1>
          <Button
            id='proceed-to-task-library'
            onClick={() => browserHistory.push(`/studies/${studyID}/instruments/selecttask`)}
            content='Proceed to Task Library'
            disabled={studyLock}
          />
        </div>
      </div>
    </div>
  )
}

export default InstrumentWizard
