import { combineReducers } from 'redux'
import { loadingActions } from 'store/loader'
import { actions as notyActions } from 'layouts/ErrorBox'
import { modalActions } from 'store/modal'
import { DEFAULT_NA_STRING_SORT_VAL, MODAL_CONTENT_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import { DATE_FORMAT_MAP } from 'utils/constants'
import request, { generateNotyMessage } from 'utils/request'
import moment from 'moment'

//
// Actions
//

const SET_GEOFENCES = 'SET_GEOFENCES'
const UPDATE_CURRENT_GEOFENCE = 'UPDATE_CURRENT_GEOFENCE'
const INITIALIZE_GEOFENCE = 'INITIALIZE_GEOFENCE'
const TOGGLE_GEOFENCE = 'TOGGLE_GEOFENCE'
const RESET_CHECKED_GEOFENCES = 'RESET_CHECKED_GEOFENCES'
const TOGGLE_CHECK_ALL_GEOFENCES = 'TOGGLE_CHECK_ALL_GEOFENCES'
const UPDATE_NEW_GEOFENCE = 'UPDATE_NEW_GEOFENCE'
const REMOVE_NEW_GEOFENCE = 'REMOVE_NEW_GEOFENCE'
const RESET_NEW_GEOFENCES = 'RESET_NEW_GEOFENCES'

//
// Action Creators
//

const setGeofences = geofences => {
  return {
    type: SET_GEOFENCES,
    payload: geofences,
  }
}

const updateGeofence = (key, value) => {
  return {
    type: UPDATE_CURRENT_GEOFENCE,
    key,
    value,
  }
}

const toggleGeofence = id => {
  return {
    type: TOGGLE_GEOFENCE,
    id,
  }
}

const toggleCheckAllGeofences = (geofences, list) => {
  return {
    type: TOGGLE_CHECK_ALL_GEOFENCES,
    geofences,
    list,
  }
}

const resetCheckedGeofences = () => {
  return {
    type: RESET_CHECKED_GEOFENCES,
  }
}

const updateNewGeofence = () => {
  return {
    type: UPDATE_NEW_GEOFENCE,
  }
}

const removeNewGeofence = id => {
  return {
    type: REMOVE_NEW_GEOFENCE,
    id,
  }
}

const resetNewGeofences = () => {
  return {
    type: RESET_NEW_GEOFENCES,
  }
}

// Utils

const parseGeofencesList = geofencesList => {
  const list = geofencesList.map(geofence => {
    const { id, last_updated, latitude, longitude, name, radius } = geofence
    const dateUpdated = moment(last_updated)
    return [
      { key: 'checkbox', value: id, sortValue: DEFAULT_NA_STRING_SORT_VAL },
      { key: 'name', value: name, sortValue: name },
      { key: 'latitude', value: latitude, sortValue: latitude },
      { key: 'longitude', value: longitude, sortValue: longitude },
      { key: 'radius', value: radius, sortValue: radius },
      {
        key: 'dateUpdated',
        value: dateUpdated.format(DATE_FORMAT_MAP.mainWithDateTime),
        sortValue: dateUpdated.valueOf(),
      },
    ]
  })
  return list
}

// Fetch functions

const fetchGeofences = studyId => dispatch => {
  const success = payload => dispatch(setGeofences(payload.geofence_locations))
  return dispatch(
    request({
      method: 'GET',
      url: `/control/studies/${studyId}/geofences`,
      success,
      hasLoader: true,
      loadingKey: 'geofences',
      fail: () => {},
    }),
  )
}

const createGeofence = (studyId, geofence) => {
  return dispatch => {
    dispatch(loadingActions.startLoader(true, 'geofences'))

    const success = () => {
      dispatch(fetchGeofences(studyId))
      dispatch(updateNewGeofence())
    }
    const fail = () => {
      dispatch(loadingActions.stopLoader(true, 'geofences'))
    }

    return dispatch(
      request({
        method: 'POST',
        url: `/control/studies/${studyId}/geofences`,
        body: JSON.stringify(geofence),
        catchMessage: 'Error creating geofence',
        success,
        successMessage: 'Geofence successfully created',
        fail,
      }),
    )
  }
}

const updateCurrentGeofence = (studyId, geofenceId, geofence) => {
  return dispatch => {
    const success = () => {
      dispatch(fetchGeofences(studyId))
    }
    return dispatch(
      request({
        method: 'PATCH',
        url: `/control/studies/${studyId}/geofences/${geofenceId}`,
        body: JSON.stringify(geofence),
        catchMessage: 'Error updating geofence',
        success,
        successMessage: 'Geofence successfully updated',
      }),
    )
  }
}

const deleteGeofence = (studyId, id, failedDeletions) => dispatch => {
  const fail = () => {
    failedDeletions.push(id)
  }
  return dispatch(
    request({
      method: 'DELETE',
      url: `/control/studies/${studyId}/geofences/${id}`,
      fail,
    }),
  )
}

const _deleteGeofences = (studyId, geofences, plural) => dispatch => {
  const geofenceArr = Object.keys(geofences)
  const failedDeletions = []
  dispatch(loadingActions.startLoader(true))
  return new Promise((resolve, reject) => {
    geofenceArr.forEach((id, idx) => {
      const res = dispatch(deleteGeofence(studyId, id, failedDeletions))
      if (idx === geofenceArr.length - 1) {
        res.then(() => {
          resolve()
        })
      }
    })
  }).then(() => {
    dispatch(loadingActions.stopLoader(true))
    if (failedDeletions.length > 0) {
      dispatch(
        notyActions.showError({
          text: generateNotyMessage(
            `There was a problem deleting geofence${plural ? 's' : ''} with the following ${
              plural ? 'IDs' : 'ID'
            }: ${failedDeletions.join(',')}`,
            false,
          ),
        }),
      )
    } else {
      dispatch(
        notyActions.showSuccess({
          text: generateNotyMessage(`Geofence${plural ? 's' : ''} successfully deleted`, true, 'fas fa-trash-alt'),
        }),
      )
    }
    dispatch(resetCheckedGeofences())
    dispatch(fetchGeofences(studyId))
  })
}

const deleteGeofences = (studyId, geofences, plural = true) => {
  return dispatch => {
    const onConfirm = () => {
      dispatch(_deleteGeofences(studyId, geofences, plural))
    }
    dispatch(
      modalActions.openModal({
        content: `${MODAL_CONTENT_MAP.actionCannotUndone} ${plural ? 'these geofences' : 'this geofence'}?`,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  }
}

const defaultGeofence = {
  geofenceName: '',
  latitude: null,
  longitude: null,
  radius: 100,
}

//
// Reducers
//

const geofencesList = (state = [], action) => {
  switch (action.type) {
    case SET_GEOFENCES:
      return parseGeofencesList(action.payload)
    default:
      return state
  }
}

const currentGeofence = (state = defaultGeofence, action) => {
  const newState = { ...state }
  switch (action.type) {
    case INITIALIZE_GEOFENCE:
      return defaultGeofence
    case UPDATE_CURRENT_GEOFENCE:
      newState[action.key] = action.value
      return newState
    default:
      return state
  }
}

const checkedGeofences = (state = {}, action) => {
  let newState
  switch (action.type) {
    case TOGGLE_GEOFENCE:
      newState = Object.assign({}, state)
      if (action.id in newState) delete newState[action.id]
      else newState[action.id] = true
      return newState
    case RESET_CHECKED_GEOFENCES:
      return {}
    case TOGGLE_CHECK_ALL_GEOFENCES:
      newState = {}
      if (Object.keys(state).length > 0) return newState // clear checks
      if (action.list) {
        action.list.forEach(id => {
          newState[id] = true
        })
      } else {
        action.geofences.forEach(geofence => {
          newState[geofence[0].value] = true
        })
      }
      return newState
    default:
      return state
  }
}

const newGeofences = (state = false, action) => {
  switch (action.type) {
    case UPDATE_NEW_GEOFENCE:
      return true
    case RESET_NEW_GEOFENCES:
      return false
    default:
      return state
  }
}

export default combineReducers({
  currentGeofence,
  checkedGeofences,
  geofencesList,
  newGeofences,
})

export const actions = {
  createGeofence,
  updateCurrentGeofence,
  fetchGeofences,
  removeNewGeofence,
  resetCheckedGeofences,
  resetNewGeofences,
  toggleCheckAllGeofences,
  toggleGeofence,
  deleteGeofences,
  updateGeofence,
  updateNewGeofence,
}
