import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Container, DatePicker, PromptBar, Radio } from 'components/UIElements'
import { DATE_FORMAT_MAP, DEFAULT_VISIT_START_HOUR, DEFAULT_VISIT_START_MINUTE } from 'utils/constants'
import STRINGS from 'utils/strings'
import Subheader from '../../../components/Subheader'
import ParticipantVisitScheduleTable from './ParticipantVisitScheduleTable'
import '../stylesheets/participantVisit.scss'
import { getDayDifference, getVisitDeviationMap, getNewMetadata } from '../../VisitPage/utils/visitDayUtils'

const getDateErrors = (date, options) => {
  const inputDateValue = options?.props?.inputProps?.ref?.current?.value
  if (inputDateValue === '') return false
  return !date || typeof date === 'undefined' || moment().isAfter(date, 'day')
}

function ParticipantVisitDate(props) {
  const {
    closeModal,
    dayChanged,
    disabled,
    disableSubsequentVisitAdjustment,
    errors,
    fetchParticipantVisits,
    isDayOneVisit,
    isUpdate,
    openModal,
    participantVisit,
    participantVisitsArray,
    pushSubsequent,
    setDaysBeforeVisitPassed,
    setPushSubsequent,
    ssvpID,
    studyID,
    updateAdhocSchedule,
    updatePtpVisitMetadata,
  } = props
  const { specificDate: specificDateErr } = errors
  const { adhocVisitSchedule, checkedPtp, visit_metadata = {}, isAdhoc } = participantVisit
  const isPtpChecked = Object.keys(checkedPtp).length > 0
  const [originalDate, setOriginalDate] = useState(null)
  const [scheduledDate, setScheduledDate] = useState(null)

  const [startVisitDateError, setStartVisitDateError] = useState(false)
  const [visitDays, setVisitDays] = useState(1)
  const [visitDate, setVisitDate] = useState('')
  const [dayDiff, setDayDiff] = useState(0)

  const resetDaysBefore = () => {
    setDaysBeforeVisitPassed(false)
    setVisitDays(1)
    setVisitDate('')
  }

  useEffect(() => {
    return () => {
      updateAdhocSchedule({
        key: 'specificDate',
        value: '',
      })
    }
  }, [])

  useEffect(() => {
    const { original_visit_datetime } = visit_metadata
    if (!originalDate) {
      if (original_visit_datetime) {
        setOriginalDate(moment(original_visit_datetime))
      } else if (adhocVisitSchedule['specificDate'].value) {
        setOriginalDate(moment(adhocVisitSchedule['specificDate'].value))
      }
    }
    if (!scheduledDate && adhocVisitSchedule['specificDate'].value) {
      setScheduledDate(moment(adhocVisitSchedule['specificDate'].value))
    }
    /**
     * This block updates the current visit's metadata
     */
    const proposedMoment = moment(adhocVisitSchedule?.specificDate?.value, DATE_FORMAT_MAP.datePickerWithFullTime)
    const dayDifference = getDayDifference(originalDate, proposedMoment)
    setDayDiff(dayDifference)
    const newMetadata = getNewMetadata({ participantVisit, dayDifference })
    updatePtpVisitMetadata(newMetadata)
  }, [adhocVisitSchedule.specificDate?.value, originalDate])

  useEffect(() => {
    resetDaysBefore()
  }, [checkedPtp])

  useEffect(() => {
    const { isSelected } = adhocVisitSchedule.daysBeforeVisit
    if (isSelected) {
      if (visitDays && visitDate !== '') {
        const substractDays = moment(visitDate)
          .subtract(visitDays, 'days')
          .format(DATE_FORMAT_MAP.mainWithFullTime)
        updateAdhocSchedule({
          key: 'daysBeforeVisit',
          value: substractDays,
        })
        if (moment().isAfter(substractDays, 'day')) setDaysBeforeVisitPassed(true)
        else setDaysBeforeVisitPassed(false)
      }
    } else {
      resetDaysBefore()
    }
  }, [adhocVisitSchedule, visitDays, visitDate])

  const onDayChange = (date, options) => {
    if (!disableSubsequentVisitAdjustment) setPushSubsequent(null)
    setStartVisitDateError(getDateErrors(date, options))
    if (date) {
      date.set({ hour: DEFAULT_VISIT_START_HOUR, minute: DEFAULT_VISIT_START_MINUTE })
      updateAdhocSchedule({
        key: 'specificDate',
        value: date.format(DATE_FORMAT_MAP.datePickerWithFullTime),
      })
    } else updateAdhocSchedule({ key: 'specificDate', value: date })
    setPushSubsequent(false)
  }

  /**
   * The following blocks of code will handle the confirmation modal logic when
   * the proposed changed date causes a visit window deviation AKA the new proposed date
   * is outside the window of the original visit date.
   */
  const [currentMetadata, setCurrentMetadata] = useState(null)

  useEffect(() => {
    return () => {
      setCurrentMetadata(null)
    }
  }, [])

  useEffect(() => {
    if (!currentMetadata && !!visit_metadata?.display_name) setCurrentMetadata(visit_metadata)
  }, [participantVisit])

  /**
   * When user clicks cancel in the confirmation model, we reset the visit to the previously scheduled
   * visit date.
   */
  const cancelChanges = () => {
    updateAdhocSchedule({
      key: 'specificDate',
      value: scheduledDate.format(DATE_FORMAT_MAP.datePickerWithFullTime),
    })
    updatePtpVisitMetadata(currentMetadata)
    setCurrentMetadata(null)
    setPushSubsequent(null)
    closeModal()
  }

  const confirmChanges = () => {
    closeModal()
  }

  const renderTable = (deviationsOnly = false) => {
    return (
      <ParticipantVisitScheduleTable
        {...props}
        showDeviationsOnly={deviationsOnly}
        dayChanged={dayChanged}
        originalDate={originalDate}
        scheduledDate={scheduledDate}
        dayDiff={dayDiff}
      />
    )
  }

  const confirmationModalContent = () => {
    return (
      <>
        <div className='flexed-header start-justified'>
          <i className='fa-solid fa-triangle-exclamation' />
          <span className='label-small no-margin'>{STRINGS.visitScheduleSelectedDayTolerance}</span>
        </div>
        <p>{STRINGS.visitScheduleProtocolDeviation}</p>
        {renderTable(true)}
      </>
    )
  }

  /**
   * This function returns a object of key-value pairs of visitId's and proposed date
   * values that are deviated, aka have proposed dates that are outside of their tolerance window
   * relative to their orignal scheduled dates.
   */
  const visitDeviationMap = getVisitDeviationMap(participantVisitsArray)

  useEffect(() => {
    const numDeviations = Object.values(visitDeviationMap).length
    const hasDeviations = !!numDeviations
    if (!hasDeviations) {
      closeModal()
    } else if (hasDeviations && dayChanged) {
      openModal({
        content: confirmationModalContent(),
        onConfirm: confirmChanges,
        onCancel: cancelChanges,
        className: 'visit-deviation-warning',
        closeOnBackgroundClick: false,
        hideClose: true,
      })
    }
  }, [JSON.stringify(visitDeviationMap)])

  /**
   * End confirmation modal logic
   */

  const [lastPtpId, setLastPtpId] = useState(null)

  useEffect(() => {
    const checkedPtpKeyArr = Object.keys(checkedPtp)
    if (checkedPtpKeyArr.length > 0 && lastPtpId !== checkedPtpKeyArr[0]) {
      setLastPtpId(checkedPtpKeyArr[0])
      fetchParticipantVisits(studyID, checkedPtpKeyArr[0], isAdhoc, isUpdate, ssvpID)
    }
  }, [checkedPtp, lastPtpId])

  return (
    isPtpChecked && (
      <Container>
        <div className='adhoc-visit-date'>
          <Subheader subheaderText={STRINGS.visitScheduleWhenShould} />
          <div className='flexed start-justified visit-date-settings'>
            <div className='flex-child'>
              <div>
                <p className='label-small'>{STRINGS.visitScheduleCurrentlyScheduledDate}</p>
                <DatePicker disabled date={scheduledDate} />
              </div>
            </div>
            <div className='flex-child'>
              <div className='new-date'>
                <p className='label-small'>{STRINGS.visitScheduleChangeVisitDate}</p>
                <DatePicker
                  disabled={disabled || isDayOneVisit}
                  disabledDays={{ before: new Date() }}
                  hasError={startVisitDateError || (specificDateErr && !adhocVisitSchedule.specificDate.value)}
                  date={
                    adhocVisitSchedule.specificDate.value
                      ? moment(adhocVisitSchedule.specificDate.value, DATE_FORMAT_MAP.datePickerWithFullTime)
                      : adhocVisitSchedule.specificDate.value
                  }
                  onDayChange={onDayChange}
                />
              </div>
            </div>
            {!disableSubsequentVisitAdjustment && (
              <div className='flex-child visit-push-out'>
                <div>
                  <p className='label-small'>{STRINGS.visitSchedulePushOut}</p>
                  <div className='subsequent-choices flexed start-justified'>
                    <Radio
                      id='push-subsequent-yes'
                      disabled={disabled || isDayOneVisit || disableSubsequentVisitAdjustment}
                      content={STRINGS.yes}
                      selected={pushSubsequent}
                      onClick={() => {
                        setPushSubsequent(true)
                      }}
                    />
                    <Radio
                      id='push-subsequent-no'
                      disabled={disabled || isDayOneVisit || disableSubsequentVisitAdjustment}
                      content={STRINGS.no}
                      selected={pushSubsequent === false}
                      onClick={() => {
                        setPushSubsequent(false)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {pushSubsequent === true && (
            <PromptBar>
              <strong>{`${STRINGS.pleaseNote}: `}</strong>
              <span>{STRINGS.visitSchedulePushNotificationYes}</span>
            </PromptBar>
          )}
          {pushSubsequent === false && (
            <PromptBar>
              <strong>{`${STRINGS.pleaseNote}: `}</strong>
              <span>{STRINGS.visitSchedulePushNotificationNo}</span>
            </PromptBar>
          )}
          {renderTable()}
        </div>
      </Container>
    )
  )
}

ParticipantVisitDate.propTypes = {
  closeModal: PropTypes.func,
  dayChanged: PropTypes.bool,
  disabled: PropTypes.bool,
  disableSubsequentVisitAdjustment: PropTypes.bool,
  openModal: PropTypes.func,
  fetchParticipantVisits: PropTypes.func,
  isDayOneVisit: PropTypes.bool,
  isUpdate: PropTypes.bool,
  participantVisit: PropTypes.shape({
    visit_metadata: PropTypes.shape({ visit_name: PropTypes.string }),
    isAdhoc: PropTypes.bool,
  }),
  pushSubsequent: PropTypes.bool,
  setDaysBeforeVisitPassed: PropTypes.func,
  setPushSubsequent: PropTypes.func,
  ssvpID: PropTypes.string,
  studyID: PropTypes.number,
  updateAdhocSchedule: PropTypes.func,
  updateAdhocScheduleSelection: PropTypes.func,
  updatePtpVisitMetadata: PropTypes.func,
}

export default ParticipantVisitDate
