import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import EditAutoNotificationView from './containers/AutoNotificationContainer'
import { TYPE_MAP } from '../../NotificationsPage/components/NotificationTypes'
// Sync route definition
export default (store, route) => ({
  path: `automated/${route}`,
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        const { study } = store.getState()
        setPageTitle(`Edit ${TYPE_MAP[route].text} | Study ${studyID} | ${study.currentStudy.platform_name}`)
        // Return Component
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserHasScope(['wu'], EditAutoNotificationView(route), false, studyID, null, study.currentStudy),
            ),
          ),
        )
      },
      'automated',
    )
  },
})
