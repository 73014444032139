import React, { useState } from 'react'

export const setAdvancedOption = (key, value) => {
  const option = {
    type: key,
    filter: {},
  }
  if (key === 'age') {
    if (typeof value.min_age !== 'number' || typeof value.max_age !== 'number') return null
    option.filter = { ...value }
  } else {
    const keys = Object.keys(value)
    if (!keys?.length) return null
    const field = key === 'sites' ? 'site_ids' : 'include'
    option.filter[field] = keys.map(Number)
  }
  return option
}

export const convertAdvancedOptions = options => {
  if (!options) return null

  return Object.entries(options).reduce((acc, [key, value]) => {
    const item = setAdvancedOption(key, value)
    if (item) acc.push(item)
    return acc
  }, [])
}

const AdvancedOptionsHOC = (Component, initState = {}) => props => {
  const [advancedOptions, setAdvancedOptions] = useState(initState)
  const [showAdvancedOption, setShowAdvancedOption] = useState(false)
  const options = {
    advancedOptions,
    setAdvancedOptions,
    showAdvancedOption,
    setShowAdvancedOption,
  }
  return <Component {...props} {...options} />
}

export default AdvancedOptionsHOC
