import React from 'react'
import '../styles/d3/ratioMeter.scss'

const calculateCompletionPercentage = (value, total) => {
  if (total === 0) return '0%'
  return `${Math.round((value / total) * 100).toString()}%`
}

const RatioMeter = props => {
  const { labelOne, labelTwo, valueOne, valueTwo } = props
  const total = valueOne + valueTwo
  return (
    <div className='ratio-meter'>
      <div className='flexed'>
        <span>{labelOne}</span>
        <span>{labelTwo}</span>
      </div>
      <div className='flexed'>
        <div className='ratio-bar'>
          <div className='dividend-one' style={{ width: calculateCompletionPercentage(valueOne, total) }} />
          <div className='dividend-two' style={{ width: calculateCompletionPercentage(valueTwo, total) }} />
        </div>
      </div>
    </div>
  )
}

export default RatioMeter
