export const PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP = {
  visitName: { key: 'visitName', text: 'Visit Name', index: 0 },
  scheduleDay: { key: 'scheduleDay', text: 'Scheduled Day, Date', index: 1 },
  originalDay: { key: 'originalDay', text: 'Original Scheduled Day, Date', index: 2 },
  visitWindow: { key: 'visitWindow', text: 'Visit Tolerance Window', index: 3 },
  proposedDay: { key: 'proposedDay', text: 'Proposed Change Day, Date', index: 4 },
}

export const PARTICIPANT_VISIT_TABLE_COLUMNS_WITH_DEVIATION_WARNING = [
  PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.proposedDay.key,
  PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.visitWindow.key,
]
