import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { TIMEOUT_WINDOW } from '../constants/schedulerConstants'
import { convertMinToDays } from 'utils/time'
import STRINGS from 'utils/strings'

const initChecked = timeout_window =>
  Object.keys(TIMEOUT_WINDOW).reduce((prevChecked, key) => {
    prevChecked[key] = !!timeout_window[key]
    return { ...prevChecked }
  }, {})

const TimeoutWindow = props => {
  const { schedule, disabled, updateTimeoutWindow, updateTimeoutWindowField } = props
  const { timeout_window = {}, submission_expiry = {} } = schedule

  const [checked, setChecked] = useState({ ...initChecked(timeout_window) })

  const _updateTimeoutWindowField = ({ key, num }) => {
    updateTimeoutWindowField(key, { value: num }, `schedule.timeout_window.${key}`)
  }

  const checkboxToggle = type => {
    setChecked(prev => {
      const prevState = { ...prev }
      prevState[type] = !prevState[type]

      // Update timeout window key
      const prevTimeout = { ...timeout_window }
      if (!!prevState[type]) {
        prevTimeout[type] = { value: 1 }
        // edit and review times are calculated from submission time
        const submissionExpiryDaysToMins = convertMinToDays(submission_expiry?.expires_in || 0, true)

        if (type !== 'draft') {
          prevTimeout[type] = { value: submissionExpiryDaysToMins }
        }

        updateTimeoutWindow(prevTimeout)
      } else {
        delete prevTimeout[type]
        updateTimeoutWindow(prevTimeout)
      }

      return prevState
    })
  }

  const TimeoutRadio = ({ timeoutKey }) => {
    return (
      <div className='timeout'>
        <Checkbox
          id={`timeout-${timeoutKey}`}
          disabled={disabled}
          checked={checked[timeoutKey]}
          onClick={() => checkboxToggle(timeoutKey)}
          label={TIMEOUT_WINDOW[timeoutKey].text}
        />
        {checked[timeoutKey] && (
          <div className='flexed start-justified'>
            <input
              className='timeout-window-input'
              aria-label={`radio-input-${timeoutKey}`}
              id={`radio-input-${timeoutKey}`}
              type='number'
              value={timeout_window[timeoutKey]?.value}
              onChange={e => {
                _updateTimeoutWindowField({ key: timeoutKey, num: parseInt(e.target.value) })
              }}
            />
            {timeout_window[timeoutKey]?.value
              ? pluralize(timeout_window[timeoutKey]?.value, 'minute', 'minutes', false)
              : 'minutes'}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='timeout-window-menu'>
      <p>{STRINGS.timeoutWindowSub}</p>
      {Object.keys(TIMEOUT_WINDOW).map((option, idx) => (
        <TimeoutRadio key={`${option}-${idx}`} timeoutKey={option} />
      ))}
    </div>
  )
}

TimeoutWindow.propTypes = {
  disabled: PropTypes.bool,
  schedule: PropTypes.object,
  updateTimeoutWindow: PropTypes.func,
  updateTimeoutWindowField: PropTypes.func,
}

export default TimeoutWindow
