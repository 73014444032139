import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { browserHistory } from 'react-router'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import { setPageTitle } from 'utils/misc'
import { injectReducers } from 'store/reducers'
import SchedulerView from './containers/SchedulerContainer'
import { clearInstrumentTriggers, instrumentActions } from '../../modules/Instrument'
import instruments, { fetchInstruments } from '../../../InstrumentsPage/modules/Instruments'
import { fetchClinroRoles } from '../../../../../Users/routes/CreateUser/modules/CreateUser'
import { fetchCohorts } from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'
import { fetchSurvey } from '../EditSurvey/modules/Survey'
import { fetchDiary } from '../EditDiary/modules/Diary'
import { fetchInstrumentConditions } from '../../../../../Notifications/routes/EditConditionalNotification/modules/ConditionalNotification'
import sitesReducer, { fetchSites } from '../../../../../Sites/routes/SitesPage/modules/Sites'
import hasInstrumentFeatures from '../../../../utils/hasInstrumentFeatures'
// Sync route definition
export default store => ({
  path: ':instrumentID/schedule',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      async () => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */

        injectReducers(store, {
          sites: sitesReducer,
          instruments,
        })
        const { studyID, instrumentID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Schedule | Study ${studyID} | ${currentStudy.platform_name}`)

        store.dispatch(clearInstrumentTriggers())
        await store.dispatch(fetchSites(studyID, true, false, false)) // params: studyID, hasLoader, includeLang, clearCheckedSites
        await store.dispatch(fetchCohorts(studyID, false))
        store.dispatch(instrumentActions.onFetchInstrument(studyID, instrumentID)).then(({ type }) => {
          const {
            currentStudy: { config },
          } = store.getState().study

          if (!hasInstrumentFeatures(config, type)) browserHistory.push(`/studies/${studyID}/instruments`)
          const isClinroOrSurvey = [INSTRUMENT_TYPE_MAP.clinro, INSTRUMENT_TYPE_MAP.survey].includes(type)
          if (isClinroOrSurvey) {
            if (type === INSTRUMENT_TYPE_MAP.clinro) {
              store.dispatch(fetchClinroRoles())
            } else if (type === INSTRUMENT_TYPE_MAP.survey) {
              store.dispatch(fetchSurvey(studyID, instrumentID))
            }
          }
          if (type === INSTRUMENT_TYPE_MAP.diary) store.dispatch(fetchDiary(studyID, instrumentID))
          const { instrumentReducer } = store.getState()
          const { metadata } = instrumentReducer.instrument
          const { conditions, conditions_data } = metadata

          // If an instrument has conditions aka trigger-actions, we will fetch and set the instrument triggers
          if (conditions) {
            store.dispatch(fetchInstrumentConditions(studyID, conditions))
          }
          // If conditions array is empty but conditions_data exists (from an imported JSON), add trigger info to instrumentTriggers reducer with the current instrument ID
          if (conditions?.length === 0 && conditions_data) {
            conditions_data.conditions.forEach(condition => {
              const conditionWithId = {
                ...condition,
                triggers: condition.triggers.map(trigger => {
                  return {
                    ...trigger,
                    resource_function_params: {
                      ...trigger.resource_function_params,
                      instrument_id: instrumentID,
                    },
                  }
                }),
              }
              delete conditionWithId.condition_id
              store.dispatch(
                instrumentActions.addInstrumentTrigger({
                  instrumentId: '',
                  isScoringTrigger: false,
                  trigger: conditionWithId,
                }),
              )
            })
          }
        })
        /**
         * Second arguments in the following fetch functions are booleans that represent whether
         * these requests should affect loading state that can potentially stop the UI loader before the
         * instrument is loaded.
         */
        store.dispatch(fetchSites(studyID, true, false, false)) // params: studyID, hasLoader, includeLang, clearCheckedSites
        store.dispatch(fetchInstruments({ studyID, hasLoader: false }))
        store.dispatch(fetchCohorts(studyID, false))

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['wa'], SchedulerView, false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'instruments/schedule',
    )
  },
})
