import { connect } from 'react-redux'
import { modalActions } from 'store/modal'
import DiaryDataDownloadForm from '../components/DiaryDataDownload/DiaryDataDownloadForm'
import { fetchSites } from '../../../../Sites/routes/SitesPage/modules/Sites'
import {
  actions as ptpsActions,
  fetchSelectionPtps,
} from '../../../../Participants/routes/ParticipantsPage/modules/Participants'
import { downloadParticipantData } from '../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'

const mapStateToProps = state => {
  const { loading, participants, study } = state
  const { participants: _participants, participantSelectionList, checkedPtps } = participants
  return {
    checkedPtps,
    diaryDownloadLoading: loading.diary,
    numTotalPtps: Object.keys(_participants).length,
    participantSelectionList,
    ptpSelectionListLoading: loading.selectionPtps,
    siteId: study.currentStudy.base_site_ids[0],
    studyId: study.currentStudy.id,
  }
}

const mapDispatchToProps = {
  downloadParticipantData,
  resetCheckedPtps: ptpsActions.resetCheckedPtps,
  fetchSites,
  fetchSelectionPtps,
  closeModal: modalActions.closeModal,
  togglePtp: ptpsActions.toggleFilterParticipant,
  togglePtpOn: ptpsActions.toggleFilterParticipantOn,
  togglePtpOff: ptpsActions.toggleFilterParticipantOff,
}

export default connect(mapStateToProps, mapDispatchToProps)(DiaryDataDownloadForm)
