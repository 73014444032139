import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container, ExpandableList } from 'components/UIElements'
import { browserHistory } from 'react-router'
import { penToSquare } from 'assets/assets'
import CONSENT_DEPLOYMENT_TYPES from '../../../utils/consentConstants'
import '../stylesheets/consentDeploy.scss'
import { generateRecipientsString } from '../../../utils/consentUtils'
import { GROUP_TYPE_MAP } from 'utils/constants'

const RecipientContent = props => {
  const { cohortMap, recipients, sitesMap } = props
  const recipientsList = generateRecipientsString({ cohortMap, recipients, sitesMap })

  return (
    <div className='review-recipients'>
      <h5 className='label-small'>Recipients</h5>
      <div className='review-recipients-list flexed column start-aligned start-justified'>
        {recipientsList.map((item, index) => {
          if (!item?.label) return <React.Fragment key={index}>{item}</React.Fragment>
          return <ExpandableList className='recipients' key={index} {...item} cutLabel={false} />
        })}
      </div>
    </div>
  )
}

const ConsentReviewAndDeploy = props => {
  const { consent, deployConsent, onSave, studyId, sitesMap, cohortMap } = props

  const {
    consent_id: consentId,
    consent_status: consentStatus,
    consent_title: consentTitle,
    consent_version: consentVersion,
    pdf_upload: pdfUpload,
    metadata,
    is_blocking: isBlocking,
  } = consent
  const { display_name: displayName, schedule } = metadata
  const { cohort } = schedule

  const getRecipients = () => {
    if (Array.isArray(cohort)) {
      const siteIds = cohort?.find(item => item.type === GROUP_TYPE_MAP.sites)
      const trackIds = cohort?.find(item => item.type === GROUP_TYPE_MAP.cohort)

      const sitenames = siteIds?.filter?.site_ids?.map(id => sitesMap[id])
      const tracknames = trackIds?.filter?.include?.map(id => cohortMap[id])

      return (
        <>
          {!!sitenames?.length ? <p className='no-margin'>Sites: {sitenames?.join(', ')}</p> : null}
          {!!tracknames?.length ? <p className='no-margin'>Tracks: {tracknames?.join(', ')}</p> : null}
        </>
      )
    }
    if (cohort?.filter?.site_ids) {
      const sitenames = cohort?.filter?.site_ids?.map(id => sitesMap[id])
      return `Sites: ${sitenames?.join(', ')}`
    }
    if (cohort?.type === GROUP_TYPE_MAP.cohort) {
      const tracknames = cohort?.filter?.include?.map(id => cohortMap[id])
      return `Tracks: ${tracknames?.join(', ')}`
    }
    if (cohort?.type === GROUP_TYPE_MAP.all) {
      return 'All Participants'
    }
    return 'NA'
  }

  const recipients = getRecipients()
  const consentFormat = pdfUpload ? 'PDF Upload' : 'Consent Builder'

  const onSaveConsent = (asDraft = true) => {
    const notScheduled = consentStatus !== 'scheduled'
    const redirectPath = asDraft ? 'consents' : 'editconsent'
    onSave(
      redirectPath,
      false,
      {
        isProperties: true,
        isUpdate: notScheduled && !!consentId,
        isJsonSave: true,
        isPdfUpload: pdfUpload,
      },
      { query: '?tab=drafts' },
    )
  }

  const goToSchedulePage = () => {
    browserHistory.push(`/studies/${studyId}/consents/${consentId}/${consentVersion}/schedule`)
  }

  const getDeploymentTimingText = () => {
    if (cohort?.[CONSENT_DEPLOYMENT_TYPES.existing.key]) return CONSENT_DEPLOYMENT_TYPES.existing.text
    if (cohort?.[CONSENT_DEPLOYMENT_TYPES.new.key]) return CONSENT_DEPLOYMENT_TYPES.new.text
    return CONSENT_DEPLOYMENT_TYPES.all.text
  }

  const renderNameContainer = () => (
    <>
      <h4>New consent properties</h4>
      <Container>
        <h5 className='label-small'>Consent Name</h5>
        <p>{consentTitle}</p>
        <h5 className='label-small'>UI Display Name</h5>
        <p>{displayName}</p>
        <h5 className='label-small'>Recipients who will see and edit the draft (Optional)</h5>
        <p>{recipients}</p>
        <h5 className='label-small'>Consent Format</h5>
        <p>{consentFormat}</p>
      </Container>
    </>
  )

  return (
    <div className='consent-deploy page'>
      <h3>Review &#38; Deploy</h3>
      {renderNameContainer()}
      <h4>Schedule Deployment</h4>
      <Container>
        <Button link onClick={goToSchedulePage} id='edit-schedule-deployment' className='edit-schedule flexed'>
          {penToSquare()}
          Edit
        </Button>
        <RecipientContent {...props} recipients={cohort} />
        <h5 className='label-small'>Blocking or Nonblocking</h5>
        <p>{isBlocking ? 'Blocking' : 'Nonblocking'}</p>
        <h5 className='label-small'>Deployment Timing</h5>
        <p>{getDeploymentTimingText()}</p>
      </Container>
      <div className='button-list'>
        <Button grey id='cancel-button' content='Save and close' onClick={onSaveConsent} />
        <Button
          id='deploy-button'
          className='deploy-button'
          content='Deploy Consent'
          onClick={() => {
            deployConsent(studyId, consentId)
          }}
        />
      </div>
    </div>
  )
}

RecipientContent.propTypes = {
  cohortMap: PropTypes.objectOf(PropTypes.string),
  recipients: PropTypes.oneOfType([
    PropTypes.shape({
      filter: PropTypes.object,
      type: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        filter: PropTypes.object,
        type: PropTypes.string,
      }),
    ),
  ]),
  sitesMap: PropTypes.objectOf(PropTypes.string),
}

ConsentReviewAndDeploy.propTypes = {
  consent: PropTypes.shape({
    consent_id: PropTypes.string,
    consent_status: PropTypes.string,
    consent_title: PropTypes.string,
    consent_version: PropTypes.number,
    is_blocking: PropTypes.bool,
    metadata: PropTypes.object,
  }),
  deployConsent: PropTypes.func,
  onSave: PropTypes.func,
  studyId: PropTypes.number,
}

export default ConsentReviewAndDeploy
