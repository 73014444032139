import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'components/UIElements'
import { capitalize } from 'utils/misc'
import PropertyField from './PropertyField'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'

const titleInfo = {
  text: 'Instrument Name',
  description: 'The title of the instrument.',
  type: 'input',
}

const TitleInfo = ({ displayType, errors, instrument, updateInstrumentTitle, hasInstrumentUnification }) => {
  const getInstrumentTitle = () => {
    if (instrument.type === INSTRUMENT_TYPE_MAP.survey) {
      return STRINGS.participantInstrument
    }
    return capitalize(displayType.toLowerCase())
  }
  return (
    <div className='title-info'>
      <h3>
        Set the {hasInstrumentUnification ? getInstrumentTitle() : capitalize(displayType.toLowerCase())} Properties
      </h3>
      <Container>
        <PropertyField
          error={errors.hasOwnProperty('title')}
          fieldInfo={titleInfo}
          value={instrument.title}
          onUpdate={updateInstrumentTitle}
        />
      </Container>
    </div>
  )
}

TitleInfo.propTypes = {
  displayType: PropTypes.string,
  instrument: PropTypes.shape({
    title: PropTypes.string,
  }),
  updateInstrumentTitle: PropTypes.func,
  hasInstrumentUnification: PropTypes.bool,
}

export default TitleInfo
