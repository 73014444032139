import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import Map from './GeofenceMap'

const FIELDS = ['name', 'latitude', 'longitude', 'radius (meters)', 'date updated']

const GeofencePreviewPage = ({ canEdit, closeDrawer, link, geofenceInfo, studyLock }) => {
  const name = geofenceInfo[studyLock ? 0 : 1].value
  const latitude = geofenceInfo[studyLock ? 1 : 2].value
  const longitude = geofenceInfo[studyLock ? 2 : 3].value
  const radius = geofenceInfo[studyLock ? 3 : 4].value
  const dateUpdated = geofenceInfo[studyLock ? 4 : 5].value

  const fieldValues = [name, latitude, longitude, radius, dateUpdated]

  const geofence = {
    latitude,
    longitude,
    radius,
  }
  return (
    <div className='preview page'>
      <div className='preview-header'>
        <div className='flexed-header'>
          <div className='title'>
            <h2>{name}</h2>
          </div>
          {canEdit && link && (
            <Button
              disabled={studyLock}
              content='Edit This Geofence'
              onClick={() => {
                closeDrawer()
                browserHistory.push(link)
              }}
            />
          )}
        </div>
      </div>
      <div>
        <Map geofence={geofence} preview />
        <table className='avoid-break'>
          <tbody>
            {FIELDS.map((field, idx) => {
              return (
                <tr key={field}>
                  <td className='first-column'>{field}</td>
                  <td>{fieldValues[idx]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

GeofencePreviewPage.propTypes = {
  closeDrawer: PropTypes.func,
  link: PropTypes.string,
  geofenceInfo: PropTypes.array,
  canEdit: PropTypes.bool,
  studyLock: PropTypes.bool,
}

export default GeofencePreviewPage
