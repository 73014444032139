import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { viewActions } from 'store/view'
import { setPageTitle } from 'utils/misc'
import consents from './routes/ConsentsDashboard/modules/Consents'
import newConsentReducer from './routes/ConsentView/routes/NewConsentBuilder/modules/NewConsent'
import instrumentReducer from '../Instruments/routes/Instrument/modules/Instrument'
import ptpsReducer from '../Participants/routes/ParticipantsPage/modules/Participants'
import ConsentViewRoute from './routes/ConsentView'
import ConsentDashboardRoute from './routes/ConsentsDashboard'

export const injectConsentReducers = store => {
  injectReducers(store, {
    consents,
    newConsentReducer,
    instrumentReducer,
    participants: ptpsReducer,
  })
}

export default store => ({
  path: 'consents',
  exact: true,
  getIndexRoute(location, cb) {
    const { study } = store.getState()
    const { currentStudy } = study
    store.dispatch(viewActions.setNormalWidth())
    store.dispatch(setSubroute('consents'))
    setPageTitle(`Consents | Study ${currentStudy.id} | ${currentStudy.platform_name}`)

    injectConsentReducers(store)
    cb(
      null,
      UserIsAuthenticated(
        UserHasValidPassword(UserHasScope(['rp', 'rp-no-pii'], ConsentDashboardRoute(store), false, currentStudy.id)),
      ),
    )
  },
  getChildRoutes(location, cb) {
    store.dispatch(viewActions.setFullWidth())
    cb(null, [ConsentViewRoute(store)])
  },
})
