//
// Actions
//
const SET_MOVE_PTPS_LIST = 'SET_MOVE_PTPS_LIST'
const SET_PTP_PROPERTIES = 'SET_PTP_PROPERTIES'
const SET_DEFAULT_PROPERTIES = 'SET_DEFAULT_PROPERTIES'

//
// Action Creators
//
const setMovePtpsList = movePtpsList => {
  return {
    type: SET_MOVE_PTPS_LIST,
    movePtpsList,
  }
}

const setPtpProperty = propertyKey => {
  return {
    type: SET_PTP_PROPERTIES,
    propertyKey,
  }
}

const setDefaultProperties = () => {
  return {
    type: SET_DEFAULT_PROPERTIES,
  }
}

//
// Utils
//
const defaultProperties = {
  ptpGemCount: {
    toggle: true,
    key: 'participant_gem_count',
  },
  superGemCount: {
    toggle: true,
    key: 'super_gem_count',
  },
  avatarPurchases: {
    toggle: true,
    key: 'avatar_purchases',
  },
  avatarSettings: {
    toggle: true,
    key: 'avatar_settings',
  },
}

//
// Reducers
//
export default (state = [], action) => {
  switch (action.type) {
    case SET_MOVE_PTPS_LIST:
      return action.movePtpsList
    default:
      return state
  }
}

export const properties = (state = {}, action) => {
  let newState = state
  switch (action.type) {
    case SET_DEFAULT_PROPERTIES: {
      newState = defaultProperties
      Object.keys(newState).forEach(property => {
        newState[property].toggle = true
      })
      return newState
    }
    case SET_PTP_PROPERTIES: {
      newState[action.propertyKey].toggle = !newState[action.propertyKey].toggle
      return newState
    }
    default:
      return state
  }
}

export const actions = {
  setMovePtpsList,
  setDefaultProperties,
  setPtpProperty,
}
