import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import CohortForm from './CohortForm'
import ParticipantManagementContainer from '../containers/ParticipantManagementContainer'
import '../stylesheets/cohorts.scss'

class CreateCohortPage extends React.Component {
  componentDidMount() {
    const { checkedPtps, isCreate, resetCohort, setNavBarProps, studyID } = this.props
    if (isCreate) {
      resetCohort(Object.keys(checkedPtps))
    }
    setNavBarProps({
      back: `/studies/${studyID}/participants?tracks`,
      backText: 'Tracks',
    })
  }

  componentWillUnmount() {
    const { resetCohort, setNavBarProps } = this.props
    resetCohort()
    setNavBarProps(null)
  }

  prepareModalContent = () => {
    const { group_name } = this.props
    return (
      <p>
        {MODAL_CONTENT_MAP.deleteTrackDescriptionStart}
        <strong>{MODAL_CONTENT_MAP.wholeStudy}. </strong>
        {MODAL_CONTENT_MAP.deleteTrackDescriptionEnd(group_name)}
      </p>
    )
  }

  onDeleteCohort = () => {
    const { closeModal, openModal, studyID, siteID, id, group_name, deleteCohort } = this.props
    openModal({
      heading: MODAL_CONTENT_MAP.deleteTrack(group_name),
      content: this.prepareModalContent(),
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        deleteCohort(studyID, siteID, id)
        closeModal()
      },
    })
  }

  render() {
    const {
      canWriteCohort,
      canAddPtp,
      checkedPtps,
      description,
      disableTrackCreation,
      group_name,
      id,
      isCreate,
      participants,
      saveCohort,
      studyID,
      studyLock,
    } = this.props
    const canEdit = canAddPtp || canWriteCohort
    return (
      <div className='create-cohort page'>
        <h2 className='heading'>{isCreate ? 'Create Track' : `Edit Track: Track ${group_name}`}</h2>
        <div className='flexed-header'>
          <h4>Track Details</h4>
          {isCreate
            ? null
            : canWriteCohort &&
              !studyLock && <Button grey id='delete-track' content='Delete Track' onClick={this.onDeleteCohort} />}
        </div>
        <CohortForm {...this.props} />
        <h4>Participant Management</h4>
        <ParticipantManagementContainer {...this.props} />
        {canEdit && (
          <div className='button-list'>
            <Button
              grey
              id='cancel'
              content='Cancel'
              onClick={() => browserHistory.push(`/studies/${studyID}/participants?tracks`)}
            />
            <Button
              id='create-track'
              disabled={!group_name || studyLock || disableTrackCreation}
              content={isCreate ? 'Create Track' : 'Save Changes'}
              onClick={() => {
                const cohort = { description, group_name, participants }
                if (id) cohort.id = id
                saveCohort({ studyID, cohort, checkedPtps, canWriteCohort })
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

CreateCohortPage.propTypes = {
  canAddPtp: PropTypes.bool,
  canWriteCohort: PropTypes.bool,
  checkedPtps: PropTypes.object,
  disableTrackCreation: PropTypes.bool,
  deleteCohort: PropTypes.func,
  description: PropTypes.string,
  group_name: PropTypes.string,
  id: PropTypes.number,
  isCreate: PropTypes.bool,
  participants: PropTypes.array,
  ptpList: PropTypes.array,
  resetCohort: PropTypes.func,
  saveCohort: PropTypes.func,
  setNavBarProps: PropTypes.func,
  siteID: PropTypes.number,
  studyID: PropTypes.number,
  studyLock: PropTypes.bool,
}

export default CreateCohortPage
