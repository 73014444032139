import React from 'react'
import { browserHistory } from 'react-router'
import MediaUpload from 'containers/mediaUploadContainer'
import { MEDIA_TYPE_MAP, MODAL_CLASSES_MAP, DATE_FORMAT_MAP } from 'utils/constants'
import { pluralize } from 'utils/misc'
import { getDateFromNow, getFormatDate } from 'utils/time'
import STRINGS from 'utils/strings'

const StudyCard = ({
  study,
  mainStudyPage,
  hasWhiteLabelingEditPermissions,
  onUpload,
  uploadLogo,
  openModal,
  deleteLogo,
  dateToString,
  tooltips,
  renderIcons,
}) => {
  const { id, platform_name, name, participant_count, is_test, is_locked, created_on } = study
  return (
    <div
      onClick={() => {
        browserHistory.push(`/studies/${id}/${mainStudyPage}`)
      }}
      className='study-box clickable'
      key={`study_${platform_name}_${id}`}
      id={`study-${id}`}
      type='button'>
      <div className='sticker-container'>
        <span className='study-id'>{`ID ${id}`}</span>
        <div
          style={{
            display: 'flex',
          }}>
          {is_test && <div className='test'>{STRINGS.test}</div>}
          {is_locked && (
            <span className='lock sticker'>
              <i className='fa fa-lock' />
            </span>
          )}
        </div>
      </div>
      <div style={{ flexGrow: 1 }}>
        <div className='participant-box'>
          <div className={`logo ${study['custom-logo'] ? 'img' : 'symbol'}`}>
            {hasWhiteLabelingEditPermissions && (
              <div
                className='tools'
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}>
                <span
                  className='tool-button tool-pencil'
                  onClick={e => {
                    e.stopPropagation()
                    openModal({
                      content: (
                        <MediaUpload
                          className='photo-submission'
                          confirmButton={STRINGS.submit}
                          mediaTypes={[MEDIA_TYPE_MAP.image]}
                          noTabs
                          heading={STRINGS.provideExampleImages}
                          subheading={STRINGS.uploadImage}
                          noAltTextReq
                          callback={onUpload}
                          uploadImage={uploadLogo}
                          uploadOptions={{ method: study['custom-logo'] ? 'PUT' : 'POST' }}
                          studyID={id}
                          showButtons
                          isCrop
                          previmSrc={study['custom-logo'] || null}
                          changeImageMode={!!study['custom-logo']}
                          initTab='white_labeling'
                        />
                      ),
                      className: MODAL_CLASSES_MAP.photoSubmissionUploader,
                      closeOnBackgroundClick: false,
                      noButtons: true,
                    })
                  }}>
                  <i className='fas fa-pencil-alt' />
                </span>
                {study['custom-logo'] && (
                  <span className='tool-button trash-icon'>
                    <i className='fas fa-trash-alt' onClick={() => deleteLogo(id)} />
                  </span>
                )}
              </div>
            )}
            {study['custom-logo'] ? (
              <img alt='logo' src={study['custom-logo']} loading='lazy' />
            ) : (
              <span className='char'>{platform_name.charAt(0)}</span>
            )}
          </div>
          <div className='info'>
            <div className='ptp-text'>
              <i className='fas fa-user' />
              <span>{pluralize(participant_count, 'participant', 'participants')}</span>
            </div>
            <div>
              <i className='far fa-calendar-alt' />
              <span>{`${dateToString} ${STRINGS.study}`}</span>
            </div>
          </div>
        </div>
        <h5 className='word-clamp'>{platform_name}</h5>
        <h6>{name}</h6>
      </div>
      <div className='card-foot flexed'>
        <div className='date-created'>
          <p>{`${STRINGS.created} ${getDateFromNow(created_on)}`}</p>
          <div className='hover-text'>{getFormatDate(created_on, DATE_FORMAT_MAP.mainWithTime)}</div>
        </div>
        <div className='foot-icons'>{tooltips.map(tooltip => renderIcons(id, tooltip))}</div>
      </div>
    </div>
  )
}

export default StudyCard
