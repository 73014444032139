import React from 'react'
import '../stylesheets/payments.scss'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { REWARD_TYPE_MAP, MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import { calculateOffsets } from 'utils/misc'
import handleComponentStateLoading from 'utils/handleComponentStateLoading'
import { Button, Checkbox, Loader, SearchInput, StickyHeader } from 'components/UIElements'
import PaymentApprovalBar from './pendingPayments/PaymentApprovalBar'
import CountBox from './pendingPayments/CountBox'
import PendingPaymentsTable from './pendingPayments/PendingPaymentsTable'
import DonationsTab from './pendingPayments/DonationsTab'
import PaymentsTable from './PaymentsTable'

const TABS = {
  pending: 'Pending',
  history: 'History',
}

const tabsArr = Object.keys(TABS)

const TREASURE_CHEST_ENABLED_TABS = {
  giftcards: 'Gift cards',
  donations: 'Donations',
  tresureChestHistory: 'History',
}

const TABS_WITH_DATA_DOWNLOAD = [
  TABS.history,
  TREASURE_CHEST_ENABLED_TABS.donations,
  TREASURE_CHEST_ENABLED_TABS.giftcards,
]

class PaymentsPage extends React.Component {
  constructor(props) {
    super(props)
    const { hasTreasureChest } = props
    this.state = {
      language: 'en-US',
      offsets: [],
      selectedPage: hasTreasureChest ? TREASURE_CHEST_ENABLED_TABS.giftcards : TABS.pending,
      autoApproveLoading: false,
    }
  }

  componentDidMount() {
    const { hasTreasureChest, hasDonations, hasTreasureChestGiftcards } = this.props
    this.setState({ offsets: calculateOffsets('sticky') })
    const browserLang = navigator.language
    this.setState({ language: browserLang })
    if (hasTreasureChest) {
      if (hasTreasureChestGiftcards) this.onSelectPage(TREASURE_CHEST_ENABLED_TABS.giftcards)
      else if (hasDonations) this.onSelectPage(TREASURE_CHEST_ENABLED_TABS.donations)
    } else {
      this.onSelectPage(TABS.pending)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pendingPaymentsLoading } = this.props
    const { selectedPage } = this.state
    if (prevState.selectedPage !== selectedPage || prevProps.pendingPaymentsLoading !== pendingPaymentsLoading) {
      this.onUpdateOffsets()
    }
  }

  onUpdateOffsets = () => {
    this.setState({ offsets: calculateOffsets('sticky') })
  }

  _isSelectedPage = filterName => {
    const { selectedPage } = this.state
    return selectedPage === filterName
  }

  onSelectPage = selectedPage => {
    const { setIncentivesTab } = this.props
    setIncentivesTab(selectedPage)
    this.setState({ selectedPage })
  }

  renderTab = tabType => {
    const { fetchPendingDonations, fetchTreasureChestPayoutHistory, hasTreasureChest, study } = this.props
    const studyId = study.id
    const isHistoryTab = tabType === 'History'
    const isDonationsTab = tabType === 'Donations'
    return (
      <Button
        key={tabType}
        selected={this._isSelectedPage(tabType)}
        onClick={() => {
          this.onSelectPage(tabType)
          if (hasTreasureChest && isHistoryTab) {
            fetchTreasureChestPayoutHistory(studyId, false)
          } else if (isDonationsTab) {
            fetchPendingDonations(studyId, false)
          }
        }}
        content={tabType}
      />
    )
  }

  renderTabs = () => {
    const { hasTreasureChest, hasDonations, hasTreasureChestGiftcards } = this.props

    const superGemTabsObj = { ...TREASURE_CHEST_ENABLED_TABS }
    if (!hasTreasureChestGiftcards) delete superGemTabsObj.giftcards
    if (!hasDonations) delete superGemTabsObj.donations

    const superGemTabs = Object.keys(superGemTabsObj)
    return (
      <div className='selected-button-list '>
        {hasTreasureChest ? (
          <>
            {superGemTabs.map(key => {
              return this.renderTab(TREASURE_CHEST_ENABLED_TABS[key])
            })}
          </>
        ) : (
          <>
            {tabsArr.map(key => {
              return this.renderTab(TABS[key])
            })}
          </>
        )}
      </div>
    )
  }

  renderTable = () => {
    const { payments, study, superGemPayoutHistory } = this.props
    const { offsets, language } = this.state

    const hasPaymentHistory = payments.length > 0 || superGemPayoutHistory.length > 0

    return hasPaymentHistory ? (
      <PaymentsTable {...this.props} offsets={offsets ? offsets.slice(1) : []} language={language} />
    ) : (
      <div className='empty-payments-section'>
        <p>
          The study participants have not yet reached their reward eligibility.
          <br />
          Contact information, current activity, and data collection can be monitored on the Participants page.
        </p>
        <a onClick={() => browserHistory.push(`/studies/${study.id}/participants`)}>View Participants Page</a>
      </div>
    )
  }

  renderPendingPaymentsTable = () => {
    const { offsets, language } = this.state
    return <PendingPaymentsTable {...this.props} offsets={offsets ? offsets.slice(1) : []} language={language} />
  }

  generatePendingPaymentCount = () => {
    const { hasTreasureChest, pendingGiftcards, pendingPayments } = this.props
    if (hasTreasureChest) return pendingGiftcards.length
    return pendingPayments.length
  }

  _getAutoApproveSetting = hasAdvPayouts => {
    if (!hasAdvPayouts) return false
    const { study } = this.props
    const { config } = study
    return config?.advanced_payouts?.auto_approve.status
  }

  _toggleAutoApprove = hasAdvPayouts => {
    if (!hasAdvPayouts) return null
    const { study, toggleAutoApprove } = this.props
    const { config, id: studyId } = study
    const hasAutoApprove = config?.advanced_payouts.auto_approve.status
    const newConfig = JSON.parse(JSON.stringify(config))

    if (config) newConfig.advanced_payouts.auto_approve.status = !hasAutoApprove
    handleComponentStateLoading.call(this, toggleAutoApprove.bind(this, studyId, newConfig), 'autoApproveLoading')
  }

  checkStudyUsesCash = () => {
    const { study } = this.props
    const { cycles } = study
    const rewardType = cycles ? cycles[0].rewardType : REWARD_TYPE_MAP.gems
    return rewardType === REWARD_TYPE_MAP.cash
  }

  isStudyConfiguredForPayments = () => {
    const { hasTreasureChest, study } = this.props
    const usesCash = this.checkStudyUsesCash()

    return ((!study.reward_goal || !study.reward_duration) && !study.cycles) || (!usesCash && !hasTreasureChest)
  }

  getRewardCurrency = () => {
    const { study, hasTreasureChestGiftcards } = this.props
    if (hasTreasureChestGiftcards) {
      const { payout_types } = study.config.super_gem_store_payouts
      return payout_types[0].currency
    }
    const usesCash = this.checkStudyUsesCash()
    const { cycles } = study
    const currency = usesCash ? cycles[0].currency : null // fallback for currency assuming all old studies without cycles are in USD
    return currency
  }

  renderDataDownloadButton = () => {
    const { currentIncentivesTab, downloadPayments, study } = this.props
    const { id: studyId } = study

    let downloadFunction = downloadPayments
    if (currentIncentivesTab === 'Donations') {
      downloadFunction = _studyId => {
        downloadPayments(_studyId, 'donations')
      }
    } else if (currentIncentivesTab === 'Gift cards') {
      downloadFunction = _studyId => {
        downloadPayments(_studyId, 'giftcards')
      }
    }

    return (
      <Button
        className='data-download'
        light
        icon='fas fa-file-download'
        content='Data'
        onClick={() => {
          downloadFunction(studyId)
        }}
      />
    )
  }

  onToggleAutoApprove = (hasAdvPayouts, checked) => {
    if (checked) this._toggleAutoApprove(hasAdvPayouts)
    else {
      const { openModal } = this.props
      openModal({
        onConfirm: () => {
          this._toggleAutoApprove(hasAdvPayouts)
        },
        content: MODAL_CONTENT_MAP.autoApprove,
        confirmButton: MODAL_BUTTONS_MAP.yes,
        cancelButton: MODAL_BUTTONS_MAP.no,
        className: MODAL_CLASSES_MAP.confirmation,
      })
    }
  }

  render() {
    const {
      approveAllPayments,
      canEditPayments,
      currentIncentivesTab,
      fetchPayments,
      fetchPendingGiftcards,
      hasTreasureChest,
      numPendingParticipants,
      payments,
      pendingPayments,
      pendingGiftcards,
      pendingPaymentsLoading,
      pendingTotal,
      study,
      studyConfigLoading,
      superGemPayoutHistory,
      studyLock,
    } = this.props
    const { id: studyId, config } = study

    const hasAdvPayouts = !!config?.advanced_payouts || !!config?.super_gem_store_payouts
    const checked = this._getAutoApproveSetting(hasAdvPayouts)

    const noPendingPayments = hasTreasureChest ? pendingGiftcards.length === 0 : pendingPayments.length === 0
    const _pendingPayments = hasTreasureChest ? pendingGiftcards : pendingPayments

    const currency = this.getRewardCurrency()

    const { offsets, autoApproveLoading } = this.state

    if (this.isStudyConfiguredForPayments()) {
      return <div className='disabled-payments-page'>This study is not configured for payments.</div>
    }

    const pendingTabsArr = [TABS.pending, TREASURE_CHEST_ENABLED_TABS.giftcards]

    const _payments = hasTreasureChest ? superGemPayoutHistory : payments

    const numPendingPayments = this.generatePendingPaymentCount()

    return (
      <div className='study-payments page'>
        {this.renderTabs()}
        {TABS_WITH_DATA_DOWNLOAD.includes(currentIncentivesTab) && this.renderDataDownloadButton()}
        {pendingTabsArr.includes(currentIncentivesTab) && (
          <div className='pending tab'>
            <div className='flexed'>
              <div className='flexed start-justified'>
                <CountBox num={pendingTotal} currency={currency} label='Total' />
                <CountBox num={numPendingParticipants} label='Participants' />
              </div>
              {hasAdvPayouts &&
              canEditPayments &&
              !hasTreasureChest && ( // TODO: disable auto-approve for treasure chest studies for now
                  <div className='flexed auto-approve'>
                    <Checkbox
                      loading={studyConfigLoading || autoApproveLoading}
                      disabled={studyConfigLoading || studyLock || autoApproveLoading}
                      toggle
                      label='Auto-approve'
                      checked={checked}
                      onClick={() => {
                        this.onToggleAutoApprove(hasAdvPayouts, checked)
                      }}
                    />
                  </div>
                )}
            </div>
            <StickyHeader offset={offsets ? offsets[0] : 0}>
              <div className='flexed bar'>
                <div className='flexed start-justified count-search'>
                  <SearchInput placeholder={`Search ${numPendingPayments} payments...`} leftJustified />
                  <p>{`${numPendingPayments} pending payments`}</p>
                </div>
                {hasAdvPayouts && canEditPayments && (
                  <PaymentApprovalBar
                    approveAllPayments={pendingPaymentMap =>
                      approveAllPayments(studyId, pendingPaymentMap, hasTreasureChest)
                    }
                    canEditPayments={canEditPayments}
                    config={config}
                    hasTreasureChest={hasTreasureChest}
                    noPendingPayments={noPendingPayments}
                    pendingPayments={_pendingPayments}
                    pendingPaymentsLoading={pendingPaymentsLoading}
                    reload={() => {
                      if (hasTreasureChest) {
                        fetchPendingGiftcards(studyId, false)
                      } else {
                        fetchPayments(studyId, false)
                      }
                    }}
                    studyId={studyId}
                    studyConfigLoading={studyConfigLoading}
                    studyLock={studyLock}
                  />
                )}
              </div>
            </StickyHeader>
            {pendingPaymentsLoading ? (
              <Loader inContainer className='pending-payments-table-loader' />
            ) : (
              <>
                {this.renderPendingPaymentsTable()}
                {noPendingPayments && (
                  <div className='flexed column no-pending-payments'>
                    <p className='no-margin'>There are no pending payments</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {currentIncentivesTab === TREASURE_CHEST_ENABLED_TABS.donations && (
          <DonationsTab {...this.props} offsets={offsets} currency={currency} />
        )}
        {currentIncentivesTab === TABS.history && (
          <>
            <StickyHeader offset={offsets ? offsets[0] : 0}>
              <div className='payment-heading'>
                <p>{pendingPaymentsLoading ? '' : `${_payments.length} Payments`}</p>
              </div>
            </StickyHeader>
            {pendingPaymentsLoading ? (
              <Loader inContainer className='pending-payments-table-loader' />
            ) : (
              this.renderTable()
            )}
          </>
        )}
      </div>
    )
  }
}

PaymentsPage.propTypes = {
  approveAllPayments: PropTypes.func,
  canEditPayments: PropTypes.bool,
  currentIncentivesTab: PropTypes.string,
  downloadPayments: PropTypes.func,
  fetchPendingGiftcards: PropTypes.func,
  fetchPendingDonations: PropTypes.func,
  fetchPayments: PropTypes.func,
  fetchTreasureChestPayoutHistory: PropTypes.func,
  hasTreasureChest: PropTypes.bool,
  hasDonations: PropTypes.bool,
  hasTreasureChestGiftcards: PropTypes.bool,
  numPendingParticipants: PropTypes.number,
  openModal: PropTypes.func,
  params: PropTypes.shape({
    siteID: PropTypes.string,
  }),
  payments: PropTypes.arrayOf(PropTypes.array),
  pendingGiftcards: PropTypes.arrayOf(PropTypes.array),
  pendingPayments: PropTypes.arrayOf(PropTypes.array),
  pendingPaymentsLoading: PropTypes.bool,
  pendingTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setIncentivesTab: PropTypes.func,
  study: PropTypes.shape({
    config: PropTypes.shape({
      advanced_payouts: PropTypes.object,
      super_gem_store_payouts: PropTypes.object,
    }),
    cycles: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.number,
    reward_duration: PropTypes.number,
  }),
  studyConfigLoading: PropTypes.bool,
  superGemPayoutHistory: PropTypes.arrayOf(PropTypes.array),
  toggleAutoApprove: PropTypes.func,
  studyLock: PropTypes.bool,
  autoApproveLoading: PropTypes.bool,
}

export default PaymentsPage
