import React from 'react'
import PropTypes from 'prop-types'
import { Editor } from 'draft-js'
import NewConsentQuestionView from './NewConsentQuestionView'
import ConsentSignatureView from './ConsentSignature/ConsentSignatureView'

const ItemListView = props => {
  const { order } = props
  return (
    <div className='page-break'>
      {order.map(itemId => {
        const item = props.questions[itemId]
        return (
          <div className='survey-question avoid-break' key={itemId}>
            {item.attributes.questionNumber && item.type !== 'signature' && (
              <span>{`${item.attributes.questionNumber}.`}</span>
            )}
            <Editor className='preview-editor' readOnly spellCheck editorState={props.editorStates[itemId]} />
            <NewConsentQuestionView preview itemId={itemId} item={item} />
            {item.type === 'signature' && <ConsentSignatureView />}
          </div>
        )
      })}
    </div>
  )
}

ItemListView.propTypes = {
  order: PropTypes.array,
  questions: PropTypes.object,
}

export default ItemListView
