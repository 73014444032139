import React from 'react'
import PropTypes from 'prop-types'
import LogicPreview from './LogicPreview'
import SelectView from './SelectView'

class NewConsentQuestionView extends React.Component {
  constructor(props) {
    super(props)
    this.DISPLAY_PROPS = {
      integer: '123',
      decimal: '123',
      text: 'abc',
      date: <i key='fa-calendar-preview' className='far fa-calendar' />,
      time: <i key='fa-clock-preview' className='far fa-clock' />,
      datetime: (
        <span>
          <i key='fa-calendar-preview' className='far fa-calendar' />
          <i key='fa-clock-preview' className='far fa-clock' />
        </span>
      ),
    }
  }

  renderDefaultQuestion = () => {
    const { item, errors } = this.props
    const displayComponent = this.DISPLAY_PROPS[item.type]
    return (
      <div>
        {item.type !== 'introduction' ? (
          <div className='non-select-preview'>
            <div style={{ fontFamily: 'AmericanTypeWriter' }}>{displayComponent}</div>
          </div>
        ) : null}
        <LogicPreview item={item} errors={errors} />
      </div>
    )
  }

  renderSelectQuestion = () => {
    return (
      <div>
        <SelectView {...this.props} />
        <LogicPreview errors={this.props.errors} item={this.props.item} />
      </div>
    )
  }

  render() {
    const { item } = this.props

    switch (item.type) {
      case 'likert':
      case 'select_one':
      case 'signature':
      case 'select_multiple':
        return this.renderSelectQuestion()
      default:
        return this.renderDefaultQuestion()
    }
  }
}

export default NewConsentQuestionView

NewConsentQuestionView.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
  }),
  itemId: PropTypes.string,
  onDeleteChoice: PropTypes.func,
  isActive: PropTypes.bool,
}
