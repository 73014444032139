export const WINDOW_OPTIONS = [
  { key: '180', text: '3 hours', value: 180 },
  { key: '360', text: '6 hours', value: 360 },
  { key: '540', text: '9 hours', value: 540 },
  { key: '720', text: '12 hours', value: 720 },
  { key: '1440', text: '24 hours', value: 1440 },
]

export const convertScheduledTimeToString = scheduleProps => {
  const { by_hour, by_minute } = scheduleProps
  let timeString = ''
  if (by_hour[0] < 10) timeString = `0${by_hour[0]}:`
  else timeString = `${by_hour[0]}:`
  if (by_minute < 10) timeString = `${timeString}0${by_minute}`
  else timeString = `${timeString}${by_minute}`
  return timeString
}

export const TIME_DROPDOWN_OPTIONS = [
  { key: '00:00', value: '00:00', text: '12:00 am' },
  { key: '00:30', value: '00:30', text: '12:30 am' },
  { key: '01:00', value: '01:00', text: '1:00 am' },
  { key: '01:30', value: '01:00', text: '1:30 am' },
  { key: '02:00', value: '02:00', text: '2:00 am' },
  { key: '02:30', value: '02:30', text: '2:30 am' },
  { key: '03:00', value: '03:00', text: '3:00 am' },
  { key: '03:30', value: '03:30', text: '3:30 am' },
  { key: '04:00', value: '04:00', text: '4:00 am' },
  { key: '04:30', value: '04:30', text: '4:30 am' },
  { key: '05:00', value: '05:00', text: '5:00 am' },
  { key: '05:30', value: '05:30', text: '5:30 am' },
  { key: '06:00', value: '06:00', text: '6:00 am' },
  { key: '06:30', value: '06:30', text: '6:30 am' },
  { key: '07:00', value: '07:00', text: '7:00 am' },
  { key: '07:30', value: '07:30', text: '7:30 am' },
  { key: '08:00', value: '08:00', text: '8:00 am' },
  { key: '08:30', value: '08:30', text: '8:30 am' },
  { key: '09:00', value: '09:00', text: '9:00 am' },
  { key: '09:30', value: '09:30', text: '9:30 am' },
  { key: '10:00', value: '10:00', text: '10:00 am' },
  { key: '10:30', value: '10:30', text: '10:30 am' },
  { key: '11:00', value: '11:00', text: '11:00 am' },
  { key: '11:30', value: '11:30', text: '11:30 am' },
  { key: '12:00', value: '12:00', text: '12:00 pm' },
  { key: '12:30', value: '12:30', text: '12:30 pm' },
  { key: '13:00', value: '13:00', text: '1:00 pm' },
  { key: '13:30', value: '13:30', text: '1:30 pm' },
  { key: '14:00', value: '14:00', text: '2:00 pm' },
  { key: '14:30', value: '14:30', text: '2:30 pm' },
  { key: '15:00', value: '15:00', text: '3:00 pm' },
  { key: '15:30', value: '15:30', text: '3:30 pm' },
  { key: '16:00', value: '16:00', text: '4:00 pm' },
  { key: '16:30', value: '16:30', text: '4:30 pm' },
  { key: '17:00', value: '17:00', text: '5:00 pm' },
  { key: '17:30', value: '17:30', text: '5:30 pm' },
  { key: '18:00', value: '18:00', text: '6:00 pm' },
  { key: '18:30', value: '18:30', text: '6:30 pm' },
  { key: '19:00', value: '19:00', text: '7:00 pm' },
  { key: '19:30', value: '19:30', text: '7:30 pm' },
  { key: '20:00', value: '20:00', text: '8:00 pm' },
  { key: '20:30', value: '20:30', text: '8:30 pm' },
  { key: '21:00', value: '21:00', text: '9:00 pm' },
  { key: '21:30', value: '21:30', text: '9:30 pm' },
  { key: '22:00', value: '22:00', text: '10:00 pm' },
  { key: '22:30', value: '22:30', text: '10:30 pm' },
  { key: '23:00', value: '23:00', text: '11:00 pm' },
  { key: '23:30', value: '23:30', text: '11:30 pm' },
]

export const MEDICATION_ADHERENCE_CONDITION_OPTIONS = [
  { key: 'cond-0', value: { count: 0, operator: '==' }, text: 'No medications have been recorded' },
  { key: 'cond-1', value: { count: 1, operator: '==' }, text: 'If 1 of 2 medications have been recorded' },
  { key: 'cond-2', value: { count: 2, operator: '>=' }, text: 'If all medications have been recorded' },
]
