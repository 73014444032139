import { authActions } from 'store/user'
import { actions as notyActions } from 'layouts/ErrorBox'
import { loadingActions } from 'store/loader'
import { sendingActions } from 'store/sender'

const _defaultMessages = {
  '500': 'The server is experiencing technical difficulties. Please try again later.',
  '401': 'Authorization required, please login to continue.',
  '404': 'Page or resource not found.',
}

export const generateNotyMessage = (message, success = true, icon) => {
  const defaultIcon = success ? 'far fa-check-circle' : 'far fa-times-circle'
  const notyIcon = icon || defaultIcon
  return `<div class='flexed centered-aligned' ><i class="first ${notyIcon}"></i><span class="message-text">${message}</span></div><span>Clear</span>`
}

// default fail behavior is to display a failmessage via noty if it exists
// otherwise shows the server message
// customFail will replace all default behavior

const _defaultFail = (response, resContent, customFail, failMessage, hasSender) => {
  return dispatch => {
    const content = JSON.parse(resContent)
    if (hasSender) {
      dispatch(sendingActions.resetSender())
    }
    if (customFail) {
      return customFail(response, content)
    }
    let errMessage = failMessage || _defaultMessages[response.status]
    errMessage = errMessage || content.message || `${response.status} Error: ${response.statusText}.`
    dispatch(notyActions.showError({ text: generateNotyMessage(errMessage, false) }))
  }
}

// default success behavior will trigger a noty message with successMessage if it is passed

const _getDefaultSuccess = successMessage => {
  return () => {
    return dispatch => {
      if (successMessage) dispatch(notyActions.showSuccess({ text: successMessage }))
    }
  }
}

// validate the session and returns a promise
export const validateSession = (dispatch, user, callback, getState) => {
  const { location } = getState()
  const activatingUser = location?.pathname === '/users/activate'

  let errMessage
  const handleInvalidSession = err => {
    dispatch(authActions.logout(user))
    if (!activatingUser) {
      dispatch(notyActions.showError({ text: generateNotyMessage(err.message, false), killer: true }))
    }
  }

  return new Promise((resolve, reject) => {
    if (!user.token || !user.exp) {
      errMessage = _defaultMessages['401']
    } else if (user.exp * 1000 < Date.now()) {
      errMessage = 'Session has expired, please login again to continue.'
    }
    if (errMessage) throw new Error(errMessage)
    else resolve()
  })
    .then(callback)
    .catch(handleInvalidSession)
}

const parseOutFileName = contentDispositionString => {
  const fileName =
    contentDispositionString?.split('filename=')[1]?.split(';')[0] ||
    contentDispositionString?.split('filename = ')[1]?.split(';')[0]
  const resultFileName = fileName.replace(/"/g, '')
  return resultFileName
}

const request = ({
  method = 'GET',
  url,
  body,
  headerOptions,
  contentType = 'application/json',
  success,
  fail,
  failMessage,
  successMessage,
  skipNotySuccess,
  catchMessage,
  forceLoader = false,
  authRequired = true,
  resType = 'json', // currently supports only blob or json responses
  hasLoader = false,
  hasSender = false,
  loadingKey,
  id,
  userData,
  manualToken,
  successIcon,
}) => {
  return (dispatch, getState) => {
    if (hasLoader) {
      if (loadingKey) {
        dispatch(loadingActions.startLoader(true, loadingKey, id))
      } else {
        dispatch(loadingActions.startLoader(forceLoader))
      }
    }
    const resolveLoader = () => {
      if (hasLoader) {
        if (loadingKey) {
          dispatch(loadingActions.stopLoader(true, loadingKey, id))
        } else {
          dispatch(loadingActions.stopLoader(forceLoader))
        }
      }
    }

    const user = userData || getState().user
    const callback = () => {
      // track response
      let response

      // establish default success behavior (print success message if provided)
      success = success || _getDefaultSuccess(successMessage)

      // setup headers
      const headers = {
        Authorization: manualToken || user.token,
      }
      if (contentType) headers['Content-Type'] = contentType
      Object.assign(headers, headerOptions)

      if (contentType === 'multipart/form-data') {
        delete headers['Content-Type']
      }
      // make fetch request
      return fetch(url, { method, headers, body })
        .then(res => {
          response = res
          if (res.ok) {
            return resType === 'json' ? res.json() : resType === 'text' ? res.text() : res.blob()
          }
          return res.text()
        })
        .then(resContent => {
          // parses out a filename if there is one
          let fileName = ''
          const contentDisposition = response.headers.get('content-disposition')
          if (contentDisposition) fileName = parseOutFileName(contentDisposition)

          let nextStep
          if (response.ok) {
            if (!skipNotySuccess && successMessage)
              setTimeout(
                () => {
                  dispatch(
                    notyActions.showSuccess({
                      text: generateNotyMessage(successMessage, true, successIcon),
                    }),
                  )
                },
                hasSender ? 1000 : 0,
              )
            nextStep = success(resContent, fileName)
          } else {
            nextStep = dispatch(_defaultFail(response, resContent, fail, failMessage, hasSender))
          }
          resolveLoader()
          return nextStep || true // return default true to handle conditional promise chaining
        })
        .catch(err => {
          if (catchMessage) dispatch(notyActions.showError({ text: generateNotyMessage(catchMessage, false) }))
          resolveLoader()
          if (__DEV__) console.error(err)
        })
    }
    if (!authRequired) return callback()
    return validateSession(dispatch, user, callback, getState)
  }
}

export default request
