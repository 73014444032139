import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import EditInsightsContainer from './container/EditInsights'

// Sync route definition
export default store => ({
  path: 'insights-permissions',
  exact: true,
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        const { studyID } = nextState.params
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'compliance',
              )(
                LockRedirect(
                  studyID,
                  'compliance',
                )(
                  UserHasScope(['wpi', 'rpi'], EditInsightsContainer, false, studyID, `/studies/${studyID}/compliance`),
                ),
              ),
            ),
          ),
        )
        /* Webpack named bundle   */
      },
      'insights-permissions',
    )
  },
})
