import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { PAYOUT_TYPE_THUMBNAIL_MAP } from 'utils/constants'
import PayoutForm from '../../containers/PayoutFormContainer'

const VIEW_STATE_MAP = {
  selection: 'selection',
  amazon_giftcard: 'amazon_giftcard',
}

const PAYOUT_TYPE_MAP = {
  amazon_giftcard: {
    name: 'Amazon',
    image: PAYOUT_TYPE_THUMBNAIL_MAP.amazon_giftcard,
    nameString: 'AWS Incentives Account',
    errorText: 'Your Amazon Incentive credentials are incorrect.',
  },
  paypal: {
    name: 'Paypal',
  },
}

const PAYOUT_TYPES = ['amazon_giftcard']

const SelectionView = props => {
  const { setCurrentView } = props

  const onPayoutSelection = payoutType => {
    setCurrentView(payoutType)
  }

  const payoutOptionList = PAYOUT_TYPES.map(payoutType => {
    const { image, nameString } = PAYOUT_TYPE_MAP[payoutType]
    return (
      <Button
        id='aws-incentive-account'
        className='payout-choice flexed start-justified center-aligned'
        key={payoutType}
        noStyling
        onClick={() => onPayoutSelection(payoutType)}>
        {image}
        <strong>{nameString}</strong>
      </Button>
    )
  })

  return (
    <div>
      <h4>Set up a payment account for participant rewards</h4>
      {payoutOptionList}
    </div>
  )
}

const PayoutOptionSelection = props => {
  const { updateConfig } = props
  const [currentView, setCurrentView] = useState(VIEW_STATE_MAP.selection)

  switch (currentView) {
    case VIEW_STATE_MAP.amazon_giftcard:
      return (
        <PayoutForm
          {...props}
          payoutTypeObj={PAYOUT_TYPE_MAP.amazon_giftcard}
          setCurrentView={setCurrentView}
          viewStateMap={VIEW_STATE_MAP}
        />
      )
    case VIEW_STATE_MAP.selection:
    default:
      return <SelectionView setCurrentView={setCurrentView} updateConfig={updateConfig} />
  }
}

SelectionView.propTypes = {
  setCurrentView: PropTypes.func,
}

PayoutOptionSelection.propTypes = {
  updateConfig: PropTypes.func,
}

export default PayoutOptionSelection
