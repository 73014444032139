import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { MODAL_CLASSES_MAP } from 'utils/constants'

const EditInsightsNav = props => {
  const { openModal, params: { studyID = '' } = {}, onSave, disabled, loading, studyLock } = props

  const onOpenDiscardModal = () => {
    if (disabled) browserHistory.push(`/studies/${studyID}/compliance`)
    else {
      openModal({
        content: 'Would you like to save your changes?',
        className: MODAL_CLASSES_MAP.confirmation,
        confirmButton: 'Yes',
        cancelButton: 'No',
        onConfirm: () => onSave(() => browserHistory.push(`/studies/${studyID}/compliance`)),
        onCancel: () => browserHistory.push(`/studies/${studyID}/compliance`),
      })
    }
  }

  return (
    <div className='edit-insights-nav flexed-header'>
      <div />
      <Button disabled={loading || studyLock} grey content='Close' onClick={onOpenDiscardModal} />
    </div>
  )
}

EditInsightsNav.propTypes = {
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  studyLock: PropTypes.bool,
  openModal: PropTypes.func,
  params: PropTypes.shape({
    studyID: PropTypes.string,
  }),
}

export default EditInsightsNav
