import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { PercentMeter } from 'components/UIElements'
import { dayDifference } from 'utils/time'
import { DATE_FORMAT_MAP } from 'utils/constants'
import { calculateCurrentCycle } from '../utils/pariticipantUtils'
import DaysRemaining from './DaysRemaining'
import FirstLogin from './FirstLogin'

const StudyTimeline = (props) => {
  const { participant, study } = props
  const { platform_name, cycles, has_dynamic_cycles: hasDynamicCycles } = study
  const { first_login_timestamp, rolled_over_timestamp } = participant

  let firstLogin = null
  let daysElapsed = 0
  let cycleNo
  let studyOver
  let rolloverTimestamp
  if (rolled_over_timestamp) rolloverTimestamp = moment(rolled_over_timestamp).format(DATE_FORMAT_MAP.main)
  const hasLoggedIn = !!first_login_timestamp
  const studyDuration = cycles ? cycles[0].studyDuration * (cycles[0].durationType === 'weeks' ? 7 : 1) : 0
  if (hasLoggedIn) {
    firstLogin = moment(first_login_timestamp)
    daysElapsed = dayDifference(firstLogin, moment())
    studyOver = daysElapsed > studyDuration
    cycleNo = calculateCurrentCycle({ cycles, daysElapsed, hasDynamicCycles, studyOver })
  }

  return (
    <div className='study-timeline-section'>
      <p className='study-info'>
        <span className='study-name'>{platform_name}</span>
        {studyOver ? (
          <span className='current-cycle'>Not currently in a chapter</span>
        ) : (
          !!cycleNo && <span className='current-cycle'>{`Currently in Chapter ${cycleNo}`}</span>
        )}
      </p>
      <PercentMeter ratio={daysElapsed / studyDuration} hideNum invariableColor />
      <div className='duration'>
        <FirstLogin daysElapsed={daysElapsed} firstLogin={firstLogin} />
        <DaysRemaining
          study={study}
          daysElapsed={daysElapsed}
          firstLogin={firstLogin}
          rolloverTimestamp={rolloverTimestamp}
        />
      </div>
    </div>
  )
}

StudyTimeline.propTypes = {
  participant: PropTypes.shape({
    first_login_timestamp: PropTypes.string,
    rolled_over_timestamp: PropTypes.string,
  }),
  study: PropTypes.shape({
    cycles: PropTypes.array,
    has_dynamic_cycles: PropTypes.bool,
    platform_name: PropTypes.string,
  }),
}

export default StudyTimeline
