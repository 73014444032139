import { setPageTitle } from 'utils/misc'
import {
  UserIsAuthenticated,
  UserHasValidPassword,
  UserHasScopes,
  UserDoesNotHaveRole,
} from 'containers/authContainers'
import { browserHistory } from 'react-router'
import { setSubroute } from 'store/navbar'
import { setStudyLock } from 'store/studyLock'
import { USER_ROLES_MAP } from 'utils/constants'
import { isSameUser } from 'utils/userUtils'
import CreateStudySettingContainer from '../../containers/CreateStudySettingContainer'

// Sync route definition
export default store => ({
  path: 'create/setting',
  exact: true,
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    const { user } = store.getState()
    if (isSameUser(user.id) ?? true) {
      store.dispatch(setSubroute('createStudy'))
      store.dispatch(setStudyLock(false))
      setPageTitle(`Create Study | Admin Panel`)
      require.ensure(
        [],
        () => {
          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(
                UserHasScopes(
                  ['wu'],
                  UserDoesNotHaveRole(
                    [USER_ROLES_MAP.siteCoordinator, USER_ROLES_MAP.enroller, USER_ROLES_MAP.clinicalCoordinator],
                    CreateStudySettingContainer,
                  ),
                ),
              ),
            ),
          )
        },
        'create/setting',
      )
    } else browserHistory.push('/')
  },
})
