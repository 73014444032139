import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { obfuscateString } from 'utils/misc'
import { MODAL_CONTENT_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import { ConfirmedPayoutSelection, onOpenPayoutModal } from '../AdvancedPaymentOptions/AdvancedPaymentOptions'

const defaultSuperGemStorePayoutConfig = {
  auto_approve: {
    status: false,
    payout_type: 'amazon_giftcard',
  },
  payout_types: [],
  notification_type: 'email',
}

const updateSuperGemStorePayouts = payoutsObj => {
  if (payoutsObj) return payoutsObj
  return defaultSuperGemStorePayoutConfig
}

// TODO: to be slotted into treasure chest rewards section if gift cards is selected
const AddTreasureChestGiftCardAccountComponent = props => {
  const { disabled, openModal, saveAttempted, study, updateConfig } = props
  const { config } = study
  const superGemStorePayouts = config?.super_gem_store_payouts
  const hasValidCredentials = superGemStorePayouts?.payout_types.length > 0
  const selectedPayout = superGemStorePayouts?.payout_types ? superGemStorePayouts.payout_types[0] : null

  const initializeSuperGemStorePayout = () => {
    updateConfig({
      super_gem_store_payouts: {
        ...updateSuperGemStorePayouts(defaultSuperGemStorePayoutConfig),
        hasSuperGemStorePayouts: false,
      },
    })
  }

  const onOpenDiscardModal = () => {
    openModal({
      content: MODAL_CONTENT_MAP.discardPaymentAccount,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => initializeSuperGemStorePayout(),
    })
  }

  return (
    <>
      {hasValidCredentials ? (
        <ConfirmedPayoutSelection
          disabled={disabled}
          onDiscard={onOpenDiscardModal}
          _onOpenPayoutModal={() => onOpenPayoutModal({ props, currency: 'USD', isTreasureChest: true })}
          payoutType={selectedPayout.type}
          val1={`Region: ${selectedPayout.aws_region}`}
          val2={`Access Key: ${obfuscateString(4, selectedPayout.access_key)}`}
        />
      ) : (
        <Button
          className='payment-donation add-account'
          content='Add Payment Account'
          errorText='Add payment account'
          hasError={saveAttempted && !selectedPayout}
          disabled={disabled}
          iconBefore='fas fa-plus'
          noStyling
          onClick={() => {
            updateConfig({
              super_gem_store_payouts: updateSuperGemStorePayouts(superGemStorePayouts),
            })
            onOpenPayoutModal({ props, currency: 'USD', isTreasureChest: true })
          }}
        />
      )}
    </>
  )
}

AddTreasureChestGiftCardAccountComponent.propTypes = {
  disabled: PropTypes.bool,
  openModal: PropTypes.func,
  saveAttempted: PropTypes.bool,
  study: PropTypes.shape({
    config: PropTypes.object,
  }),
  updateConfig: PropTypes.func,
}

export default AddTreasureChestGiftCardAccountComponent
