const calculateLng = (deg) => {
  if (deg <= 180 && deg >= -180) {
   return deg
  }
  if (deg > 0) {
    return calculateLng(deg - 360)
  } 
  return calculateLng(deg + 360)
}

export default calculateLng
