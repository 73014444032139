import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import announcementReducer, { announcementActions } from '../EditAnnouncement/modules/Announcement'
import ptpsReducer from '../../../Participants/routes/ParticipantsPage/modules/Participants'
import ptpReducer from '../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import AnnouncementView from '../EditAnnouncement/containers/AnnouncementContainer'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'

// Sync route definition
export default store => ({
  path: 'sms/:smsID',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducers(store, {
          announcementReducer,
          participants: ptpsReducer,
          participantReducer: ptpReducer,
          sites: sitesReducer,
        })

        const { params } = nextState
        const { studyID, smsID } = params
        const { currentStudy } = store.getState().study
        setPageTitle(`Edit SMS | Study ${studyID} | ${currentStudy.platform_name}`)

        store.dispatch(
          announcementActions.initializeAnnouncementPage({ studyID, communicationId: smsID, isAnnouncement: false }),
        )

        Promise.all([store.dispatch(fetchSites(studyID, false, true))])

        // Return Component
        cb(null, UserIsAuthenticated(UserHasValidPassword(UserHasScope(['xp'], AnnouncementView, false, studyID))))
      },
      'communication/sms/:smsID',
    )
  },
})
