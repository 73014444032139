import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect'
import { browserHistory } from 'react-router'

const LockRedirect = (studyID, path) =>
  connectedReduxRedirect({
    authenticatedSelector: state => {
      return !state.studyLock
    },
    redirectAction: newLoc => dispatch => {
      browserHistory.replace(newLoc)
    },
    redirectPath: `/studies/${studyID}/${path}`,
    wrapperDisplayName: 'LockRedirect',
    allowRedirectBack: false,
  })

export default LockRedirect
