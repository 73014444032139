import React from 'react'

const VisibilityDisplay = ({ filled, total }) => {
  const boxes = []
  for (let i = 0; i < total; i++) {
    boxes.push(
      <div className={'vis-box' + (i < filled ? ' filled' : '')} key={`box_${i + 1}`}>
        {i === 0 && <TickBar content='Repeat Start' tickPosition='top left' textPosition='top right' />}
        {`D${i + 1}`}
        {i === total - 1 && <TickBar content='Repeat End' tickPosition='bottom right' textPosition='bottom right' />}
      </div>
    )
  }
  return <div className='visible-days-display'>{boxes}</div>
}

export const TickBar = ({ content, tickPosition, textPosition }) => {
  const tickClassName = 'tick-bar ' + tickPosition || 'top left'
  const textClassName = 'tick-text ' + textPosition || 'top left'
  return (
    <div className={tickClassName}>
      <div className={textClassName}>{content}</div>
    </div>
  )
}

export default VisibilityDisplay
