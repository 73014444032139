import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input, Popup } from 'components/UIElements'
import { QUESTION_TYPE_MAP } from 'utils/constants'

const VA_SCALE_2021_LABEL_MAX_LENGTH = 40
const VA_SCALE_2021_OPTION_MAX_LENGTH = 32
const VA_SCALE_OPTION_MAX_LENGTH = 36
const VA_SCALE_2021_LABEL = 'YOUR HEALTH TODAY'
const VA_SCALE_2021_CAREGIVER_LABEL = "YOUR CHILD'S HEALTH TODAY"

const VA_SCALE_2021_LABEL_MAP = {
  hint: VA_SCALE_2021_LABEL,
  caregiver_hint: VA_SCALE_2021_CAREGIVER_LABEL,
}

const VAScaleView = ({ item, itemId, updateItem, errors, preview, vasType, caregiverEnabled }) => {
  const isStandardVAS = vasType === QUESTION_TYPE_MAP.vasHorizontal
  const isVAS2021 = vasType === QUESTION_TYPE_MAP.vaScale2021

  const updateLabel = type => value => {
    let newItem
    if (type === 'hint') {
      newItem = { ...item, hint: value }
      updateItem(itemId, newItem)
    } else if (type === 'caregiver_hint') {
      newItem = { ...item, caregiver_hint: value }
      updateItem(itemId, newItem)
    } else if (type === 'unit') {
      newItem = { ...item, attributes: { ...item.attributes, unit: value } }
      updateItem(itemId, newItem)
    } else if (type === 'top_value' || type === 'mid_value' || type === 'bottom_value') {
      const truncString = value.slice(0, 4)
      newItem = { ...item, scale_labels: { ...item.scale_labels, [type]: truncString } }
      updateItem(itemId, newItem)
    } else if (type !== 'hint' || type !== 'caregiver_hint') {
      newItem = { ...item, scale_labels: { ...item.scale_labels, [type]: value } }
      updateItem(itemId, newItem)
    }
  }

  useEffect(() => {
    if (isVAS2021 && updateItem && !item.hint) {
      updateLabel('hint')(VA_SCALE_2021_LABEL)
      if (caregiverEnabled && !item.caregiver_hint) {
        updateLabel('caregiver_hint')(VA_SCALE_2021_CAREGIVER_LABEL)
      }
    }
  }, [])

  const vasLabelInput = (placeholder, className, value, key, tooltipText) => {
    if (isVAS2021) {
      return (
        <Input
          placeholder={placeholder}
          disabled={preview}
          className={className}
          value={value || ''}
          charLimit={!preview ? VA_SCALE_2021_LABEL_MAX_LENGTH : null}
          onChange={updateLabel(key)}
          onBlur={() => {
            if (!item[key]) {
              updateLabel(key)(VA_SCALE_2021_LABEL_MAP[key])
            }
          }}
          label={placeholder}
          tooltip={
            <Popup
              className='box-popup'
              align='left'
              position='bottom'
              hover
              dark
              trigger={<i className='fas fa-info-circle' />}>
              <div className='tooltip-text'>{tooltipText}</div>
            </Popup>
          }
        />
      )
    }
    return (
      <Input
        placeholder={placeholder}
        disabled={preview}
        className={className}
        value={value}
        maxLength={4}
        onChange={updateLabel(key)}
      />
    )
  }

  const getOptionCharLimit = () => {
    if (isStandardVAS) {
      return null
    }

    if (isVAS2021) {
      return VA_SCALE_2021_OPTION_MAX_LENGTH
    }

    return VA_SCALE_OPTION_MAX_LENGTH
  }

  return (
    <div className={`non-select-preview${isStandardVAS ? ' vas' : ''}`}>
      {isStandardVAS && vasLabelInput('Label', 'vas-input scale-label-input', item.hint, 'hint')}
      {isStandardVAS && <div className='vas-line' />}
      {isVAS2021 &&
        vasLabelInput(
          "Scale's mobile display name for participants",
          'vas-2021-input scale-label-input',
          item.hint,
          'hint',
          `“${VA_SCALE_2021_LABEL}” is the valid text associated with the EuroQol license. If this question uses EQ-5D, the text here must state “${VA_SCALE_2021_LABEL}”.`,
        )}
      {isVAS2021 &&
        caregiverEnabled &&
        vasLabelInput(
          "Scale's mobile display name for caregivers (if applicable)",
          'vas-2021-input scale-label-input',
          item.caregiver_hint,
          'caregiver_hint',
          "If this study has caregiver enabled, this label will display on the caregiver's device.",
        )}
      <div className={isStandardVAS ? 'flexed row-reverse' : ''}>
        <div className={isStandardVAS ? 'description-labels flexed column center-justified' : ''}>
          {isStandardVAS && (
            <div className='flexed start-justified'>
              {vasLabelInput(
                'Right',
                `vas-input choice-label-input${isStandardVAS ? ' value' : ''}`,
                item.scale_labels.top_value,
                'top_value',
              )}
            </div>
          )}
          <Input
            charLimit={!preview ? getOptionCharLimit() : null}
            placeholder={errors?.VASLabelError || `Label${isStandardVAS ? ' (Optional)' : ''}`}
            disabled={preview}
            className={`vas-input choice-label-input${isStandardVAS ? ' vas-label' : ''}`}
            hasError={(errors && errors.VASLabelError && item.scale_labels?.top === '') || !item.scale_labels}
            pretext={`${isStandardVAS ? '' : '100 (Top Most)'}`}
            value={item.scale_labels?.top}
            onChange={updateLabel('top')}
          />
        </div>
        {isStandardVAS && (
          <div className='description-labels mid flexed column center-justified'>
            {vasLabelInput(
              '50',
              'vas-input choice-label-input value mid flexed center-justified',
              item.scale_labels.mid_value,
              'mid_value',
            )}
          </div>
        )}
        <div className={isStandardVAS ? 'description-labels flexed column center-justified' : ''}>
          {isStandardVAS && (
            <div className='flexed start-justified'>
              {vasLabelInput(
                'Left',
                `vas-input choice-label-input${isStandardVAS ? ' value' : ''}`,
                item.scale_labels.bottom_value,
                'bottom_value',
              )}
            </div>
          )}
          <Input
            charLimit={!preview ? getOptionCharLimit() : null}
            placeholder={errors?.VASLabelError || `Label${isStandardVAS ? ' (Optional)' : ''}`}
            disabled={preview}
            className={`vas-input choice-label-input${isStandardVAS ? ' vas-label' : ''}`}
            hasError={(errors && errors.VASLabelError && item.scale_labels?.bottom === '') || !item.scale_labels}
            pretext={`${isStandardVAS ? '' : '0 (Bottom Most)'}`}
            value={item.scale_labels?.bottom}
            onChange={updateLabel('bottom')}
          />
        </div>
      </div>
    </div>
  )
}

VAScaleView.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.string,
  updateItem: PropTypes.func,
  errors: PropTypes.object,
  preview: PropTypes.bool,
  vasType: PropTypes.string,
}

export default VAScaleView
