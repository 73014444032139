import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popup } from 'components/UIElements'
import { DEFAULT_LANG } from 'utils/constants'

const LanguageListPopup = props => {
  const { addConsentTranslation, enforcedLanguages, locales, otherLanguages = [], setCurrentLang } = props
  const languageList = enforcedLanguages || locales

  const filteredList = languageList.filter(langObj => {
    return ![...otherLanguages, DEFAULT_LANG].includes(langObj.id)
  })

  const languageOptionList = filteredList.map(({ id, text }) => {
    return (
      <Button
        key={id}
        className='popup-list-btn'
        content={text}
        onClick={() => {
          addConsentTranslation(id)
          setCurrentLang(id)
        }}
      />
    )
  })

  return (
    <Popup
      disabled={languageOptionList.length === 0}
      isButtonList
      noPointer
      position='right'
      trigger={
        <Button className='add-lang' onClick={() => {}} noStyling icon='fas fa-plus' id='add-languages' content='Add language...' />
      }>
      {languageOptionList}
    </Popup>
  )
}

LanguageListPopup.propTypes = {
  addConsentTranslation: PropTypes.func,
  enforcedLanguages: PropTypes.arrayOf(PropTypes.object),
  locales: PropTypes.arrayOf(PropTypes.object),
  otherLanguages: PropTypes.arrayOf(PropTypes.string),
  setCurrentLang: PropTypes.func,
}

export default LanguageListPopup
