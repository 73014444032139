import React from 'react'
import PropTypes from 'prop-types'
import { RichTextEditor } from 'components/UIElements'
import { capitalize } from 'utils/misc'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import SurveyQuestionView from '../../EditSurvey/components/QuestionView'
import ClinroQuestionView from '../../EditClinro/components/QuestionView'
import DiaryQuestionView from '../../EditDiary/components/DiaryQuestionView'
import STRINGS from 'utils/strings'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from 'utils/constants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'

const ItemListView = props => {
  const {
    consent,
    editorStates,
    order,
    questions,
    instrumentType,
    isSurvey = true,
    isClinro,
    isDiary,
    caregiverEnabled,
    study,
    userId,
  } = props

  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const getTitle = () => {
    if (!hasInstrumentUnification) {
      return instrumentType !== INSTRUMENT_TYPE_MAP.clinro
        ? capitalize(instrumentType.toLowerCase())
        : 'Clinician Instrument'
    }

    if (instrumentType === INSTRUMENT_TYPE_MAP.clinro) {
      return STRINGS.clinicianInstrument
    }
    if (instrumentType === INSTRUMENT_TYPE_MAP.survey) {
      return STRINGS.participantInstrument
    }
    return capitalize(instrumentType.toLowerCase())
  }

  return (
    <div className='page-break'>
      {!consent && <h4>{`${getTitle()} Questions`}</h4>}
      {order.map(itemId => {
        const item = questions[itemId]
        const { imageSrc, mediaSrc, resources } = item
        const hasVideo = !!resources?.video
        const questionViewProps = {
          itemId,
          item,
          editorStates,
        }
        return (
          <div className='survey-question avoid-break' key={itemId}>
            <div className='survey-question-inner'>
              {item.attributes.questionNumber && (
                <span className='question-number'>{`${item.attributes.questionNumber}.`}</span>
              )}
              <RichTextEditor className='preview-editor' readOnly spellCheck editorState={editorStates[itemId]} />
            </div>
            {imageSrc && <img className='question-image-preview' src={imageSrc} alt='question-preview-img' />}
            {mediaSrc &&
              (hasVideo ? (
                <video className='question-media-preview' src={mediaSrc} controls />
              ) : (
                <audio className='question-media-preview' src={mediaSrc} controls />
              ))}
            {isSurvey && <SurveyQuestionView preview caregiverEnabled={caregiverEnabled} {...questionViewProps} />}
            {isClinro && <ClinroQuestionView preview {...questionViewProps} />}
            {isDiary && <DiaryQuestionView preview {...questionViewProps} />}
          </div>
        )
      })}
    </div>
  )
}

ItemListView.propTypes = {
  consent: PropTypes.object,
  editorStates: PropTypes.object,
  instrumentType: PropTypes.string,
  isSurvey: PropTypes.bool,
  order: PropTypes.array,
  questions: PropTypes.object,
  caregiverEnabled: PropTypes.bool,
}

export default ItemListView
