import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AutocompleteSearch } from 'components/UIElements'
import '../stylesheets/VisitorsSelection.scss'

const VisitorsSelection = props => {
  const {
    currentVisits,
    disabled,
    fetchSiteParticipants,
    fetchClinicians,
    loading,
    setVisitPtp,
    setVisitUser,
    studyID,
    updateField,
    visitDropdownOptions,
    visitErrors,
  } = props
  const {
    checkedPtp,
    checkedUser,
    participantDropdownOptions,
    selectedParticipant,
    selectedSite,
    selectedUser,
    userDropdownOptions,
  } = visitDropdownOptions
  const { participant_id, user_id } = currentVisits
  const { noPtp, noUser } = visitErrors
  const { visitorUser, visitorParticipant } = loading

  useEffect(() => {
    if (selectedSite) {
      fetchSiteParticipants(studyID, selectedSite, false, true)
      fetchClinicians(studyID, selectedSite)
    }
    updateField('participant_id', '')
    updateField('user_id', '')
    setVisitPtp('')
    setVisitUser('')
  }, [selectedSite])

  return (
    <div className='user-participant-selection'>
      <h5>Set up a meeting between</h5>
      <div className='flexed start-justified'>
        <div>
          <p className='label-small'>Participant</p>
          <AutocompleteSearch
            hasError={!!noPtp && !participant_id}
            errorText={noPtp}
            checked={checkedPtp}
            disabled={disabled || visitorParticipant}
            initialValue={selectedParticipant}
            isDropdown
            list={participantDropdownOptions}
            loading={visitorParticipant}
            placeholder='Enter an ID or name'
            id='participant-input'
            shouldReturnItem
            toggleItem={item => {
              updateField('participant_id', item.key)
              setVisitPtp({ participantVal: item.text, ptpId: item.key })
            }}
          />
        </div>
        <h5>and</h5>
        <div>
          <p className='label-small'>Clinician</p>
          <AutocompleteSearch
            hasError={!!noUser && !user_id}
            errorText={noUser}
            checked={checkedUser}
            disabled={disabled || visitorUser}
            initialValue={selectedUser}
            isDropdown
            list={userDropdownOptions}
            loading={visitorUser}
            placeholder='Enter a nickname or email'
            id='clinician-input'
            shouldReturnItem
            toggleItem={item => {
              updateField('user_id', item.key)
              setVisitUser({ userVal: item.text, userId: item.key })
            }}
          />
        </div>
      </div>
    </div>
  )
}

VisitorsSelection.propTypes = {
  currentVisits: PropTypes.shape({
    user_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    participant_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  disabled: PropTypes.bool,
  fetchSiteParticipants: PropTypes.func,
  fetchClinicians: PropTypes.func,
  loading: PropTypes.shape({
    visitorParticipant: PropTypes.bool,
    visitorUser: PropTypes.bool,
  }),
  selectedSite: PropTypes.string,
  setVisitPtp: PropTypes.func,
  setVisitUser: PropTypes.func,
  studyID: PropTypes.string,
  visitDropdownOptions: PropTypes.shape({
    checkedPtp: PropTypes.objectOf(PropTypes.bool),
    checkedUser: PropTypes.objectOf(PropTypes.bool),
    participantDropdownOptions: PropTypes.arrayOf(PropTypes.object),
    selectedParticipant: PropTypes.string,
    selectedSite: PropTypes.string,
    selectedUser: PropTypes.string,
    userDropdownOptions: PropTypes.arrayOf(PropTypes.object),
  }),
  updateField: PropTypes.func,
  visitErrors: PropTypes.shape({
    noPtp: PropTypes.string,
    noUser: PropTypes.string,
  }),
}

export default VisitorsSelection
