import React from 'react'
import { Popup, Button } from 'components/UIElements'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'

const InstrumentsViewExportButton = ({ showTranslationButton, onDownloadTranslations, onDownloadJSONs }) => {
  const isArticle = INSTRUMENT_TYPE_MAP.article
  return (
    <Popup
      dark
      noPointer
      className='export-button'
      key='export'
      trigger={
        <Button grey id='download-instrument' content='Download Instrument' iconAfter='fas fa-caret-down after-text' />
      }>
      <div>
        {showTranslationButton && (
          <Button
            link
            id='csv-translation-template'
            content='CSV Translation Template'
            icon='fas fa-cloud-download-alt'
            onClick={onDownloadTranslations}
          />
        )}
        {!isArticle && <Button link content='JSON' icon='fas fa-cloud-download-alt' onClick={onDownloadJSONs()} />}
      </div>
    </Popup>
  )
}

export default InstrumentsViewExportButton
