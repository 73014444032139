import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button } from 'components/UIElements'
import D3Logo from 'assets/DCH-Logo.svg'
import { HEADER_TABS, HEADER_TAB_NAMES, CREATE_STUDY_FULL_URLS } from '../utils/studyUtils'

const CreateStudyNav = props => {
  const { preview, selectedTab } = props

  return preview ? (
    <Button onClick={() => browserHistory.push(`/studies`)} className='preview-close-btn' content='Close' />
  ) : (
    <div className='create-study-nav flexed-header'>
      <div className='flexed-header'>
        <div className='d3-logo-container'>
          <img className='d3-logo' src={D3Logo} alt='d3-logo' />
        </div>
        <Button
          selected={selectedTab === HEADER_TABS.type}
          link
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.type)}
          content={
            <span>
              {HEADER_TAB_NAMES.type}
              <i className='fas fa-arrow-right' />
            </span>
          }
        />
        <Button
          selected={selectedTab === HEADER_TABS.setting}
          link
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.setting)}
          content={
            <span>
              {HEADER_TAB_NAMES.setting}
              <i className='fas fa-arrow-right' />
            </span>
          }
        />
        <Button
          selected={selectedTab === HEADER_TABS.schedule}
          link
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.schedule)}
          content={
            <span>
              {HEADER_TAB_NAMES.schedule}
              <i className='fas fa-arrow-right' />
            </span>
          }
        />
        <Button
          selected={selectedTab === HEADER_TABS.review}
          link
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.review)}
          content={<span>{HEADER_TAB_NAMES.review}</span>}
        />
      </div>
      <Button grey content='Close' onClick={() => browserHistory.push('/studies')} />
    </div>
  )
}

CreateStudyNav.propTypes = {
  loading: PropTypes.shape({}),
  preview: PropTypes.bool,
  selectedTab: PropTypes.string,
}

export default CreateStudyNav
