import React from 'react'
import PropTypes from 'prop-types'
import { Container, Loader, SortableTable } from 'components/UIElements'
import StatusHistorySection from './StatusHistorySection'
import StatusTimestampTooltip from './StatusTimestampTooltip'
import { NOT_APPLICABLE_PLACEHOLDER } from 'utils/constants'
import STRINGS from 'utils/strings'

const noAnnouncementsString = 'There are no pending announcements for this participant.'

class CompletedAnnouncements extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isStatusRowExpanded: {},
    }
    this.headerOptions = [
      { key: 'name', sortable: true, component: 'Announcement Name' },
      { key: 'status', sortable: true, component: 'Status' },
      {
        key: 'statusTimestamp',
        sortable: true,
        component: 'Status Timestamp',
        iconClassName: 'fas fa-info-circle',
        className: 'status-timestamp-cell',
        popupComponent: <StatusTimestampTooltip site={props.site} mainSite={props.mainSite} />,
      },
    ]
  }

  renderStatusCell = (row, idx) => {
    const { value } = row
    return (
      <td className='completion-cell' key={`status-cell-${row}-${idx}`}>
        <span>{value}</span>
      </td>
    )
  }

  expandedRow = row => {
    const {
      announcementTimeline,
      fetchAnnouncementTimeline,
      resetAnnouncementTimeline,
      announcementTimelineLoading,
      site,
      mainSite,
    } = this.props
    const { idqId } = row[row.length - 1]
    return (
      <StatusHistorySection
        instrumentTimeline={announcementTimeline}
        instrumentId={idqId}
        fetchInstrumentTimeline={fetchAnnouncementTimeline}
        instrumentTimelineLoading={announcementTimelineLoading}
        onClose={() => {
          this.setState({
            isStatusRowExpanded: {},
          })
          resetAnnouncementTimeline()
        }}
        noStatusMessage={STRINGS.noAnnouncementStatus}
        site={site}
        mainSite={mainSite}
        withUtcOffset
      />
    )
  }

  render() {
    const { ptpAnnouncementsLoading, announcementStatus } = this.props
    const { isStatusRowExpanded } = this.state
    const hasInstruments = announcementStatus.length > 0
    const renderFunctions = {
      status: this.renderStatusCell,
      name: ({ value }) => (
        <td key={`announcement-${value}`} className='instrument-name'>
          {value}
        </td>
      ),
      statusTimestamp: ({ value }) => <td key={`announcement-${value}`}>{value || NOT_APPLICABLE_PLACEHOLDER}</td>,
    }
    return (
      <div>
        <Container className={`ptp-instruments ${hasInstruments ? 'fixed-min-height' : ''}`}>
          <div className='title'>Announcements</div>
          {ptpAnnouncementsLoading ? (
            <Loader inContainer />
          ) : (
            <>
              {hasInstruments ? (
                <SortableTable
                  sortingBy='cycleNo'
                  headerOptions={this.headerOptions}
                  rowList={announcementStatus}
                  renderFunctions={renderFunctions}
                  onRowClick={row => {
                    const { idqId } = row[row.length - 1]
                    this.setState({
                      isStatusRowExpanded: {
                        [idqId]: !isStatusRowExpanded[idqId],
                      },
                    })
                  }}
                  rowExpandOptions={{
                    isRowExpanded: row => {
                      const { idqId } = row[row.length - 1]
                      return isStatusRowExpanded[idqId]
                    },
                    expandedRowClassName: 'expanded',
                  }}
                  expandedRow={this.expandedRow}
                />
              ) : (
                <p>{noAnnouncementsString}</p>
              )}
            </>
          )}
        </Container>
      </div>
    )
  }
}

CompletedAnnouncements.propTypes = {
  announcementStatus: PropTypes.arrayOf(PropTypes.array),
  ptpAnnouncementsLoading: PropTypes.bool,
}

export default CompletedAnnouncements
