import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import { loadingActions } from 'store/loader'
import { Parser } from 'json2csv'
import { actions as notyActions } from 'layouts/ErrorBox'
import { modalActions } from 'store/modal'
import { sendingActions } from 'store/sender'
import request, { generateNotyMessage } from 'utils/request'
import { DEFAULT_NA_STRING_SORT_VAL, MODAL_CONTENT_MAP, DATE_FORMAT_MAP, BLOB_TYPE_MAP } from 'utils/constants'
import moment from 'moment'
import { parseSpecialCharactersIn, sortFunction, downloadBlob } from 'utils/misc'
import { today } from 'utils/time'
import { fetchUser } from 'store/user'
import { actions as userActions } from '../../../../Users/routes/CreateUser/modules/CreateUser'

const { fetchUserSites } = userActions
//
// Actions
//

export const SET_SITES = 'SET_SITES'
const INITIALIZE_CHECKED_SITES = 'INITIALIZE_CHECKED_SITES'
const TOGGLE_SITE = 'TOGGLE_SITE'
const TOGGLE_ON_SITE = 'TOGGLE_ON_SITE'
const TOGGLE_OFF_SITE = 'TOGGLE_OFF_SITE'
const RESET_CHECKED_SITES = 'RESET_CHECKED_SITES'
const TOGGLE_CHECK_ALL_SITES = 'TOGGLE_CHECK_ALL_SITES'
const UPDATE_NEW_SITE = 'UPDATE_NEW_SITE'
const REMOVE_NEW_SITE = 'REMOVE_NEW_SITE'
const RESET_NEW_SITES = 'RESET_NEW_SITES'
const SET_ORPHANED_PARTICIPANTS = 'SET_ORPHANED_PARTICIPANTS'
const UPDATE_SITE_CONFIG = 'UPDATE_SITE_CONFIG'
const TOGGLE_SITE_LANGUAGE = 'TOGGLE_SITE_LANGUAGE'
const INITIALIZE_BLANK_SITE = 'INITIALIZE_BLANK_SITE'
const CLEAR_SITE_ERRORS = 'CLEAR_SITE_ERRORS'
const HAS_SITE_ERRORS = 'HAS_SITE_ERRORS'
const CHECK_USER_SITES = 'CHECK_USER_SITES'
const CLEAR_USER_SITES = 'CLEAR_USER_SITES'
const SET_SITES_BY_STUDIES = 'SET_SITES_BY_STUDIES'

//
// Action Creators
//

const setOrphanedParticipants = bool => {
  return {
    type: SET_ORPHANED_PARTICIPANTS,
    bool,
  }
}

// this function sets at a study level whether there are orphaned participants (those that are not in a leaf site)
export const onSetOrphanedParticipants = bool => {
  return dispatch => {
    dispatch(setOrphanedParticipants(bool))
  }
}

const setSites = (sites, includeLang = false) => {
  return {
    type: SET_SITES,
    payload: sites,
    includeLang,
  }
}

function toggleSite(id, pathName) {
  return {
    type: TOGGLE_SITE,
    id,
    pathName,
  }
}

function toggleOnSite(id, pathName) {
  return {
    type: TOGGLE_ON_SITE,
    id,
    pathName,
  }
}

function toggleOffSite(id) {
  return {
    type: TOGGLE_OFF_SITE,
    id,
  }
}

function toggleCheckAllSites(sites, list) {
  return {
    type: TOGGLE_CHECK_ALL_SITES,
    sites,
    list,
  }
}

function resetCheckedSites() {
  return {
    type: RESET_CHECKED_SITES,
  }
}

function updateNewSite(id) {
  return {
    type: UPDATE_NEW_SITE,
    id,
  }
}

function removeNewSite(id) {
  return {
    type: REMOVE_NEW_SITE,
    id,
  }
}

function resetNewSites() {
  return {
    type: RESET_NEW_SITES,
  }
}

function updateSiteConfig(config) {
  return {
    type: UPDATE_SITE_CONFIG,
    config,
  }
}

function toggleSiteLanguage(language) {
  return {
    type: TOGGLE_SITE_LANGUAGE,
    language,
  }
}

function initializeCheckedSites(sites) {
  return {
    type: INITIALIZE_CHECKED_SITES,
    sites,
  }
}

function clearUserSites() {
  return {
    type: CLEAR_USER_SITES,
  }
}

function setAllStudiesSites(sitesArr) {
  return {
    type: SET_SITES_BY_STUDIES,
    sitesArr,
  }
}

// Utils

const filterLeafLevel = (sitesList, treeLevels) => {
  // treeLevels is an array of the site tree levels you want to show
  let resultSitesList = [...sitesList]
  if (treeLevels.length > 0) {
    resultSitesList = resultSitesList.filter(site => {
      return treeLevels.includes(site.tree_level)
    })
  }
  return resultSitesList
}

const filterVirtualandLevel4 = sitesList => {
  let resultSitesList = [...sitesList]
  resultSitesList = resultSitesList.filter(site => {
    return site.tree_level === 4 || site.is_virtual
  })
  return resultSitesList
}

// Include virtual sites and with level 1 and 4.
const filterVirtualAndLevel = sitesList =>
  sitesList
    .filter(site => {
      return site.is_virtual || [1, 4].includes(site.level)
    })
    .filter((site, _, siteArr) => (siteArr.length > 1 ? site.level != 1 : true))

const parseSites = sites => {
  const sitesArr = Object.keys(sites)
  const resultsObj = sitesArr.reduce((obj, site) => {
    obj[site] = sites[site].site_path
    return obj
  }, {})
  return resultsObj
}

const createLeafSiteArr = sitesList => {
  const leafSiteArr = sitesList.map(site => {
    return site.id
  })
  return leafSiteArr
}

const parseSitesList = (sitesList, includeLang = false) => {
  const list = sitesList.map(site => {
    let row
    const { path, created_on, config = {} } = site
    const enforcedLanguage = config?.enforced_language
    const enforcedLanguageArr = enforcedLanguage?.languages.map(language => language.key)
    const dateAdded = moment(created_on)
    const compliancePercent = Math.round(site.compliance * 100) / 100
    if (site.is_virtual) {
      row = [
        { key: 'checkbox', value: site.id },
        { key: 'siteId', value: site.id, sortValue: site.id },
        {
          key: 'siteName',
          value: site.label,
          sortValue: site.label,
          isVirtual: true,
          description: site.description,
          config: site.config,
        },
        { key: 'country', value: '--', sortValue: DEFAULT_NA_STRING_SORT_VAL },
        { key: 'regionState', value: '--', sortValue: DEFAULT_NA_STRING_SORT_VAL },
        { key: 'participantCount', value: site.participant_count, sortValue: site.participant_count },
        { key: 'dateAdded', value: dateAdded.format(DATE_FORMAT_MAP.mainWithDateTime), sortValue: dateAdded.valueOf() },
        { key: 'compliance', value: compliancePercent, sortValue: compliancePercent },
        { key: 'signUpCode', value: site.self_sign_code, sortValue: site.self_sign_code },
        includeLang && enforcedLanguage ? { key: 'enforcedLanguage', value: enforcedLanguageArr } : null,
      ]
      return row.filter(el => el)
    }
    const pathArr = path.split('.')
    const country = parseSpecialCharactersIn(pathArr[1])
    const regionState = parseSpecialCharactersIn(pathArr[2])
    row = [
      { key: 'checkbox', value: site.id },
      { key: 'siteId', value: site.id, sortValue: site.id },
      { key: 'siteName', value: site.label, sortValue: site.label, description: site.description, config: site.config },
      { key: 'country', value: country, sortValue: country },
      { key: 'regionState', value: regionState, sortValue: regionState },
      { key: 'participantCount', value: site.participant_count, sortValue: site.participant_count },
      { key: 'dateAdded', value: dateAdded.format(DATE_FORMAT_MAP.mainWithDateTime), sortValue: dateAdded.valueOf() },
      { key: 'compliance', value: compliancePercent, sortValue: compliancePercent },
      { key: 'signUpCode', value: site.self_sign_code, sortValue: site.self_sign_code },
      includeLang && enforcedLanguage ? { key: 'enforcedLanguage', value: enforcedLanguageArr } : null,
    ]
    return row.filter(el => el)
  })
  return list
}

const generateSitesMap = sitesList => {
  const sitesMap = {}
  for (let i = 0; i < sitesList.length; i++) {
    const site = sitesList[i]
    sitesMap[site.id] = site.label
  }
  return sitesMap
}

const parseSelectionList = sitesList => {
  const list = sitesList.map(site => {
    const { path, label, tree_level } = site
    // const pathArr = path.split('.')
    // let name = parseSpecialCharactersIn(pathArr[pathArr.length-1])
    const isTreeLevelOne = tree_level === 1
    let name = label
    if (isTreeLevelOne) name = 'All Sites'

    const row = [
      { key: 'id', value: site.id, sortValue: site.id },
      { key: 'name', value: name, sortValue: name },
      { key: 'treeLevel', value: site.tree_level, sortValue: site.tree_level },
      { key: 'path', value: path, sortValue: path },
    ]
    return row
  })
  list.sort((row1, row2) => sortFunction(row1[3].sortValue, row2[3].sortValue))
  return list
}

export const getParentSiteIdFromPath = (path, sitesInfo) => {
  const pathArr = path.split('.')
  if (pathArr.length <= 1) return path
  const parentPath = pathArr.slice(0, -1).join('.')
  const parentSiteInfo = Object.values(sitesInfo).filter(site => site.site_path === parentPath)
  if (parentSiteInfo.length === 0) return path
  return parentSiteInfo[0].site_id
}

export const filterOutSiteKeys = (sitesList, keysToFilter) => {
  return sitesList.map(site => {
    return site.filter(siteRow => !keysToFilter.includes(siteRow.key))
  })
}

export const findSiteWithId = (sitesList, siteId) => {
  return sitesList.filter(site => {
    return site[0].value === siteId
  })[0]
}

// Fetch functions

export const fetchPermittedSites = (hasLoader = false) => dispatch => {
  const success = json => {
    return Promise.resolve(json)
  }
  return dispatch(
    request({
      method: 'GET',
      url: '/control/admin/permitted_studies',
      errorMessage: 'error fetching studies',
      success,
      hasLoader,
      forceLoader: hasLoader,
    }),
  )
}

export const fetchSiteDescendants = (studyID, siteID, successFunc, hasLoader = true) => dispatch => {
  const success = jsonBody => {
    return Promise.resolve(jsonBody.sites)
  }

  return dispatch(
    request({
      method: 'GET',
      url: `/control/studies/${studyID}/sites/${siteID}/descendants`,
      success: successFunc || success,
      hasLoader,
    }),
  )
}

const transformSites = data => {
  return data
    .map(innerArray => {
      return innerArray.reduce((acc, curr) => {
        acc[curr.key] = curr.value
        return acc
      }, {})
    })
    .map(innerMap => ({ ...innerMap, virtual: innerMap.country === '--' }))
}

export function downloadSiteData(studyID) {
  let fileName = `study_${studyID}_site_list_${today(true)}.csv`
  return (dispatch, getState) => {
    dispatch(loadingActions.startLoader(true, 'siteList'))

    const { sites } = getState()
    const { sitesList } = sites

    const fields = [
      {
        label: 'site id',
        value: 'siteId',
      },
      {
        label: 'site name',
        value: 'siteName',
      },
      {
        label: 'country',
        value: 'country',
      },
      {
        label: 'region state',
        value: 'regionState',
      },
      {
        label: 'no. of participants',
        value: 'participantCount',
      },
      {
        label: 'date added',
        value: 'dateAdded',
      },
      {
        label: 'compliance',
        value: 'compliance',
      },
      {
        label: 'virtual',
        value: 'virtual',
      },
    ]

    const json2csvParser = new Parser({ fields })
    let sitesCSV = null
    try {
      sitesCSV = json2csvParser.parse(transformSites(sitesList))
    } catch (error) {
      dispatch(notyActions.showError({ text: error }))
    }

    // Generate and Download the file
    const blob = new Blob([sitesCSV], { type: 'text/csv' })
    downloadBlob(blob, fileName)

    dispatch(loadingActions.stopLoader(true, 'siteList'))
  }
}

export const fetchSites = (studyID, hasLoader = true, includeLang = false, clearCheckedSites = true) => (
  dispatch,
  getState,
) => {
  const { study } = getState()
  const { base_site_ids = [] } = study.currentStudy

  const requestArr = [...base_site_ids]

  const requests = requestArr.map(site => {
    return dispatch(fetchSiteDescendants(studyID, site, null, hasLoader))
  })
  return Promise.all(requests).then(results => {
    let resultSites = []
    results.forEach(siteArr => {
      resultSites = [...resultSites, ...siteArr]
    })
    const siteList = parseSitesList(filterVirtualandLevel4(resultSites))
    if (siteList.length === 0) {
      dispatch(onSetOrphanedParticipants(false))
    }
    if (clearCheckedSites) dispatch(initializeCheckedSites([]))
    return dispatch(setSites(resultSites, includeLang))
  })
}

export const fetchSitesByStudies = currentStudyID => async dispatch => {
  const permittedSitesRes = await dispatch(fetchPermittedSites(true))
  const { message, studies } = permittedSitesRes || {}

  if (permittedSitesRes && message === 'SUCCESS') {
    const studiesList = studies
      .filter(({ study_id }) => currentStudyID != study_id)
      .map(({ study_id, sites, tracks }) => ({ [study_id]: { sites: filterVirtualAndLevel(sites), tracks } }))

    dispatch(setAllStudiesSites(Object.assign(...studiesList)))
  }
}

const updateSite = (studyID, editSiteID, sitesInfo, link) => {
  return dispatch => {
    const success = () => {
      dispatch(sendingActions.stopSender())
      setTimeout(() => {
        browserHistory.push(link)
        dispatch(sendingActions.resetSender())
      }, 500)
    }
    dispatch(sendingActions.startSender())
    return dispatch(
      request({
        method: 'PATCH',
        url: `/control/studies/${studyID}/sites/${editSiteID}`,
        body: JSON.stringify({ sites: sitesInfo }),
        success,
        successMessage: 'Site successfully updated!',
        failMessage: 'There was a problem saving this site. Please try again.',
        hasSender: true,
      }),
    )
  }
}

export const _deleteSiteAndParent = (studyID, siteID, failedDeletions, sitesInfo) => {
  return (dispatch, getState) => {
    const siteInfo = sitesInfo[siteID]
    const success = () => {
      if (siteInfo) {
        // If tree level 4 or 3, delete parent if childless
        if (siteInfo.level === 4 || siteInfo.level === 3) {
          dispatch(
            _deleteSiteAndParent(
              studyID,
              getParentSiteIdFromPath(siteInfo.site_path, sitesInfo),
              failedDeletions,
              sitesInfo,
            ),
          )
        }
        const { id: userId } = getState().user
        dispatch(fetchUserSites({ studyId: studyID, userId }))
      }
    }
    const fail = () => {
      failedDeletions.push(siteID)
    }
    return dispatch(
      request({
        method: 'DELETE',
        url: `/control/studies/${studyID}/sites/${siteID}`,
        success,
        fail,
      }),
    )
  }
}

const _deleteLeafSites = (studyID, siteIDs, baseSiteID, sitesInfo, plural = true) => {
  return dispatch => {
    const failedDeletions = []
    dispatch(loadingActions.startLoader(true))
    return new Promise((resolve, reject) => {
      siteIDs.forEach((id, idx) => {
        const res = dispatch(_deleteSiteAndParent(studyID, id, failedDeletions, sitesInfo))
        if (idx === siteIDs.length - 1) {
          res.then(() => {
            resolve()
          })
        }
      })
    }).then(() => {
      dispatch(loadingActions.stopLoader(true))
      if (failedDeletions.length > 0) {
        dispatch(
          notyActions.showError({
            text: generateNotyMessage(
              `There was a problem deleting site${plural ? 's' : ''} with the following ID${
                plural ? 's' : ''
              }: ${failedDeletions.join(',')}`,
              false,
            ),
          }),
        )
      } else {
        dispatch(
          notyActions.showSuccess({
            text: generateNotyMessage(`Site${plural ? 's' : ''} successfully deleted`, false, 'fas fa-trash-alt'),
          }),
        )
      }
      dispatch(resetCheckedSites())
      dispatch(fetchSites(studyID))
      dispatch(fetchUser())
    })
  }
}

const deleteLeafSites = (studyID, siteIDs, baseSiteID, sitesInfo, plural = true) => {
  return dispatch => {
    const onConfirm = () => {
      dispatch(_deleteLeafSites(studyID, siteIDs, baseSiteID, sitesInfo, plural))
    }
    dispatch(
      modalActions.openModal({
        content: `${MODAL_CONTENT_MAP.actionCannotUndone} ${plural ? 'these sites' : 'this site'}?`,
        onConfirm,
      }),
    )
  }
}

//
// Reducers
//

const sitesList = (state = [], action) => {
  switch (action.type) {
    case SET_SITES: // for the sites list in the sites page, we only want to show leaf sites
      return parseSitesList(filterVirtualandLevel4(action.payload), action.includeLang)
    default:
      return state
  }
}

const sitesMap = (state = {}, action) => {
  switch (action.type) {
    case SET_SITES:
      return generateSitesMap(action.payload)
    default:
      return state
  }
}

const siteSelectionList = (state = [], action) => {
  switch (action.type) {
    case SET_SITES: // all the tree_level array has all tree levels of sites
      return parseSelectionList(filterLeafLevel(action.payload, [1, 2, 3, 4]))
    default:
      return state
  }
}

export const leafSites = (state = [], action) => {
  switch (action.type) {
    case SET_SITES:
      return createLeafSiteArr(action.payload.filter(site => site.is_leaf))
    default:
      return state
  }
}

const sitesLanguages = (state = {}, action) => {
  switch (action.type) {
    case SET_SITES:
      return action.payload.reduce((acc, item) => ({ ...acc, [item.id]: item.config }), {})
    default:
      return state
  }
}

const checkedSites = (state = {}, action) => {
  let newState
  switch (action.type) {
    case INITIALIZE_CHECKED_SITES:
      newState = {}
      action.sites.forEach(siteId => {
        newState[siteId] = null
      })
      return newState
    case TOGGLE_SITE:
      newState = { ...state }
      if (action.id in newState) delete newState[action.id]
      else newState[action.id] = action.pathName
      return newState
    case TOGGLE_ON_SITE:
      newState = { ...state }
      newState[action.id] = action.pathName
      return newState
    case TOGGLE_OFF_SITE:
      newState = { ...state }
      if (action.id in newState) delete newState[action.id]
      return newState
    case RESET_CHECKED_SITES:
      return {}
    case TOGGLE_CHECK_ALL_SITES:
      newState = {}
      if (Object.keys(state).length > 0) return newState // clear checks
      if (action.list) {
        action.list.forEach(id => {
          newState[id] = true
        })
      } else {
        action.sites.forEach(site => {
          newState[site[0].value] = true
        })
      }
      return newState
    case CHECK_USER_SITES:
      return parseSites(action.sites)
    case CLEAR_USER_SITES:
      return {}
    default:
      return state
  }
}

const newSites = (state = [], action) => {
  let newState
  switch (action.type) {
    case UPDATE_NEW_SITE:
      newState = [...state, action.id]
      return newState
    case REMOVE_NEW_SITE:
      newState = state.filter(site => site !== action.id)
      return newState
    case RESET_NEW_SITES:
      return []
    default:
      return state
  }
}

const sitesByStudies = (state = {}, action) => {
  switch (action.type) {
    case SET_SITES_BY_STUDIES:
      return action.sitesArr
    default:
      return state
  }
}

const _getDefaultSite = () => {
  return {
    config: {},
  }
}

export const getDefaultDigitalSignatures = () => {
  return {
    draw: true,
    text: false,
    image: true,
  }
}

// TODO: move all current site actions from CreateSite component to here
const currentSite = (state = _getDefaultSite(), action) => {
  const newState = { ...state }
  switch (action.type) {
    case UPDATE_SITE_CONFIG: {
      newState.config = action.config
      return newState
    }
    case TOGGLE_SITE_LANGUAGE: {
      const { enforced_language } = newState.config
      let newLanguages = []
      const languageKeys = enforced_language.languages.map(lang => lang.key)
      if (languageKeys.includes(action.language.key)) {
        newLanguages = enforced_language.languages.filter(lang => lang.key !== action.language.key)
        if (action.language.key === enforced_language.default_language) {
          newState.config.enforced_language.default_language = ''
        }
      } else {
        newLanguages = [...enforced_language.languages, action.language]
      }
      newLanguages = newLanguages.sort((a, b) => a.text.localeCompare(b.text))
      newState.config.enforced_language.languages = newLanguages
      return newState
    }
    default:
      return state
  }
}

const currentSiteHasErrors = (state = false, action) => {
  switch (action.type) {
    case INITIALIZE_BLANK_SITE:
      return false
    case CLEAR_SITE_ERRORS:
      return false
    case HAS_SITE_ERRORS:
      return true
    default:
      return state
  }
}

export default combineReducers({
  sitesList,
  sitesMap,
  sitesByStudies,
  checkedSites,
  siteSelectionList,
  leafSites,
  newSites,
  currentSite,
  currentSiteHasErrors,
  sitesLanguages,
})

export const actions = {
  clearUserSites,
  deleteLeafSites,
  fetchSites,
  fetchSitesByStudies,
  initializeCheckedSites,
  onSetOrphanedParticipants,
  removeNewSite,
  resetCheckedSites,
  resetNewSites,
  toggleCheckAllSites,
  toggleOffSite,
  toggleOnSite,
  toggleSite,
  toggleSiteLanguage,
  updateNewSite,
  updateSite,
  updateSiteConfig,
  downloadSiteData,
}
