import { connect } from 'react-redux'
import React from 'react'
import Loader from 'components/D3Loader'
import { setNavBarProps } from 'store/navbar'
import { getBaseSiteIdFromStudy } from 'utils/misc'
import { checkIsEmailless } from 'utils/studyConfigUtils'
import AccountSettings from '../components/AccountSettings'
import { actions } from '../../CreateParticipant/modules/CreateParticipant'
import { actions as siteActions } from '../../../../Sites/routes/SitesPage/modules/Sites'
import { actions as ptpActions } from '../../ParticipantsPage/modules/Participants'
import {
  downloadParticipantConsent,
  fetchConsentEvents,
} from '../../../../Consent/routes/ConsentEditor/modules/Consent'
import {
  downloadCompletedParticipantConsent,
  uploadPtpConsentAttachments,
} from '../../../../Consents/routes/ConsentsDashboard/modules/Consents'

const mapStateToProps = (state, ownProps) => {
  const { participantReducer, loading, sending, sites, studyLock } = state
  const { currentStudy } = state.study
  const { consent_required } = currentStudy
  const study = state.study.currentStudy
  const studyID = parseInt(ownProps.params.studyID, 10)
  const { sendingState, sentState } = sending
  const sendDisabled = sendingState || sentState

  const checkedSites = sites.checkedSites
  const leafSites = sites.leafSites
  const ptpSiteID = () => leafSites.find(id => id === Number(Object.keys(checkedSites)[0]))

  const participantDataFields = study.config?.participant_data_fields
  const isEmailless = checkIsEmailless(currentStudy)

  return {
    ...participantReducer,
    checkedSites,
    consentRequired: consent_required,
    isEmailless,
    loading: loading.general,
    requestSending: sendingState,
    requestSent: sentState,
    sendDisabled,
    study,
    studyLock,
    studyID,
    baseSiteID: ownProps.siteID || getBaseSiteIdFromStudy(state.study),
    participantDataFields,
    ptpSiteID: ptpSiteID(),
    tempPwdLoading: loading.tempPassword,
  }
}

const mapDispatchToProps = {
  ...actions,
  ...siteActions,
  ...ptpActions,
  setNavBarProps,
  downloadParticipantConsent,
  downloadCompletedParticipantConsent,
  uploadPtpConsentAttachments,
  fetchConsentEvents,
  fetchPtp: (studyId, siteId, ptpId) => actions.fetchParticipant(studyId, siteId, ptpId),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.loading) {
    return Loader(props)
  }
  return <AccountSettings {...props} />
})
