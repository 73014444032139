const START_APPSINSTALLED_LOADING = 'START_APPSINSTALLED_LOADING'
const START_BATTERY_LOADING = 'START_BATTERY_LOADING'
const START_CLINRO_LOADING = 'START_CLINRO_LOADING'
const START_CREDENTIALS_LOADING = 'START_CREDENTIALS_LOADING'
const START_GEOFENCING_LOADING = 'START_GEOFENCING_LOADING'
const START_LOADING = 'START_LOADING'
const START_LOCATION_LOADING = 'START_LOCATION_LOADING'
const START_MEDADHERENCE_LOADING = 'START_MEDADHERENCE_LOADING'
const START_PENDINGPAYMENTS_LOADING = 'START_PENDINGPAYMENTS_LOADING'
const START_PHONECONTACTS_LOADING = 'START_PHONECONTACTS_LOADING'
const START_REQUEST_LOADING = 'START_REQUEST_LOADING'
const START_SCREENTIME_LOADING = 'START_SCREENTIME_LOADING'
const START_STEPCOUNTER_LOADING = 'START_STEPCOUNTER_LOADING'
const START_STUDYCONFIG_LOADING = 'START_STUDYCONFIG_LOADING'
const START_SURVEY_LOADING = 'START_SURVEY_LOADING'
const START_VISIT_LOADING = 'START_VISIT_LOADING'
const START_VISITORPARTICIPANT_LOADING = 'START_VISITORPARTICIPANT_LOADING'
const START_VISITORUSER_LOADING = 'START_VISITORUSER_LOADING'
const STOP_APPSINSTALLED_LOADING = 'STOP_APPSINSTALLED_LOADING'
const STOP_BATTERY_LOADING = 'STOP_BATTERY_LOADING'
const STOP_CLINRO_LOADING = 'STOP_CLINRO_LOADING'
const STOP_CREDENTIALS_LOADING = 'STOP_CREDENTIALS_LOADING'
const STOP_GEOFENCING_LOADING = 'STOP_GEOFENCING_LOADING'
const STOP_LOADING = 'STOP_LOADING'
const STOP_LOCATION_LOADING = 'STOP_LOCATION_LOADING'
const STOP_MEDADHERENCE_LOADING = 'STOP_MEDADHERENCE_LOADING'
const STOP_PENDINGPAYMENTS_LOADING = 'STOP_PENDINGPAYMENTS_LOADING'
const STOP_PHONECONTACTS_LOADING = 'STOP_PHONECONTACTS_LOADING'
const STOP_REQUEST_LOADING = 'STOP_REQUEST_LOADING'
const STOP_SCREENTIME_LOADING = 'STOP_SCREENTIME_LOADING'
const STOP_STEPCOUNTER_LOADING = 'STOP_STEPCOUNTER_LOADING'
const STOP_STUDYCONFIG_LOADING = 'STOP_STUDYCONFIG_LOADING'
const STOP_SURVEY_LOADING = 'STOP_SURVEY_LOADING'
const STOP_VISIT_LOADING = 'STOP_VISIT_LOADING'
const STOP_VISITORPARTICIPANT_LOADING = 'STOP_VISITORPARTICIPANT_LOADING'
const STOP_VISITORUSER_LOADING = 'STOP_VISITORUSER_LOADING'
const START_VISITSSCHEDULE_LOADING = 'START_VISITSSCHEDULE_LOADING'
const STOP_VISITSSCHEDULE_LOADING = 'STOP_VISITSSCHEDULE_LOADING'
const START_VISITSHISTORY_LOADING = 'START_VISITSHISTORY_LOADING'
const STOP_VISITSHISTORY_LOADING = 'STOP_VISITSHISTORY_LOADING'
const START_VIRTUALVISITS_LOADING = 'START_VIRTUALVISITS_LOADING'
const STOP_VIRTUALVISITS_LOADING = 'STOP_VIRTUALVISITS_LOADING'
const START_MEDIAUPLOAD_LOADING = 'START_MEDIAUPLOAD_LOADING'
const STOP_MEDIAUPLOAD_LOADING = 'STOP_MEDIAUPLOAD_LOADING'
const START_PARTICIPANTINSTRUMENTS_LOADING = 'START_PARTICIPANTINSTRUMENTS_LOADING'
const STOP_PARTICIPANTINSTRUMENTS_LOADING = 'STOP_PARTICIPANTINSTRUMENTS_LOADING'
const START_PARTICIPANTVISITS_LOADING = 'START_PARTICIPANTVISITS_LOADING'
const STOP_PARTICIPANTVISITS_LOADING = 'STOP_PARTICIPANTVISITS_LOADING'
const START_MOVEPARTICIPANTS_LOADING = 'START_MOVEPARTICIPANTS_LOADING'
const STOP_MOVEPARTICIPANTS_LOADING = 'STOP_MOVEPARTICIPANTS_LOADING'
const START_PTPMOVE_LOADING = 'START_PTPMOVE_LOADING'
const STOP_PTPMOVE_LOADING = 'STOP_PTPMOVE_LOADING'
const START_PTPSCOMPLIANCE_LOADING = 'START_PTPSCOMPLIANCE_LOADING'
const STOP_PTPSCOMPLIANCE_LOADING = 'STOP_PTPSCOMPLIANCE_LOADING'
const START_USERSITES_LOADING = 'START_USERSITES_LOADING'
const STOP_USERSITES_LOADING = 'STOP_USERSITES_LOADING'
const START_GEOFENCES_LOADING = 'START_GEOFENCES_LOADING'
const STOP_GEOFENCES_LOADING = 'STOP_GEOFENCES_LOADING'
const START_TRIGGER_LOADING = 'START_TRIGGER_LOADING'
const STOP_TRIGGER_LOADING = 'STOP_TRIGGER_LOADING'
const START_SELECTIONPTPS_LOADING = 'START_SELECTIONPTPS_LOADING'
const STOP_SELECTIONPTPS_LOADING = 'STOP_SELECTIONPTPS_LOADING'
const START_DIARY_LOADING = 'START_DIARY_LOADING'
const STOP_DIARY_LOADING = 'STOP_DIARY_LOADING'
const START_SISENSE_LOADING = 'START_SISENSE_LOADING'
const STOP_SISENSE_LOADING = 'STOP_SISENSE_LOADING'
const START_SISENSESETUP_LOADING = 'START_SISENSESETUP_LOADING'
const STOP_SISENSESETUP_LOADING = 'STOP_SISENSESETUP_LOADING'
const START_CONSENTPTPS_LOADING = 'START_CONSENTPTPS_LOADING'
const STOP_CONSENTPTPS_LOADING = 'STOP_CONSENTPTPS_LOADING'
const START_MARKCONSENTASCOMPLETE_LOADING = 'START_MARKCONSENTASCOMPLETE_LOADING'
const STOP_MARKCONSENTASCOMPLETE_LOADING = 'STOP_MARKCONSENTASCOMPLETE_LOADING'
const START_CONSENTATTACHMENT_LOADING = 'START_CONSENTATTACHMENT_LOADING'
const STOP_CONSENTATTACHMENT_LOADING = 'STOP_CONSENTATTACHMENT_LOADING'
const START_TEMPPASSWORD_LOADING = 'START_TEMPPASSWORD_LOADING'
const STOP_TEMPPASSWORD_LOADING = 'STOP_TEMPPASSWORD_LOADING'
const START_THUMBNAIL_LOADING = 'START_THUMBNAIL_LOADING'
const STOP_THUMBNAIL_LOADING = 'STOP_THUMBNAIL_LOADING'
const START_INSTRUMENTTIMELINE_LOADING = 'START_INSTRUMENTTIMELINE_LOADING'
const STOP_INSTRUMENTTIMELINE_LOADING = 'STOP_INSTRUMENTTIMELINE_LOADING'
const START_DIARYTIMELINE_LOADING = 'START_DIARYTIMELINE_LOADING'
const STOP_DIARYTIMELINE_LOADING = 'STOP_DIARYTIMELINE_LOADING'
const START_ANNOUNCEMENTTIMELINE_LOADING = 'START_ANNOUNCEMENTTIMELINE_LOADING'
const STOP_ANNOUNCEMENTTIMELINE_LOADING = 'STOP_ANNOUNCEMENTTIMELINE_LOADING'

let forcedLoader = false

export const startLoader = (forced = false, type = null, id = null) => {
  if (id && type) {
    return {
      type: `START_${type.toUpperCase()}_LOADING`,
      forced,
      id,
      value: type,
    }
  }
  if (type) {
    return {
      type: `START_${type.toUpperCase()}_LOADING`,
      forced,
      value: type,
    }
  }
  return {
    type: START_LOADING,
    forced,
  }
}

export const stopLoader = (forced = false, type = null, id = null) => {
  if (id && type) {
    return {
      type: `STOP_${type.toUpperCase()}_LOADING`,
      forced,
      id,
      value: type,
    }
  }
  if (type) {
    return {
      type: `STOP_${type.toUpperCase()}_LOADING`,
      forced,
      value: type,
    }
  }
  return {
    type: STOP_LOADING,
    forced,
  }
}

const loading = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case START_LOADING:
      if (action.forced) forcedLoader = true
      return { ...newState, general: true }
    case STOP_LOADING:
      if (!action.forced && forcedLoader) {
        return { ...newState, general: true }
      }
      forcedLoader = false
      return { ...newState, general: false }
    case START_STUDYCONFIG_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_STUDYCONFIG_LOADING:
      return { ...newState, [action.value]: false }
    case START_SURVEY_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_SURVEY_LOADING:
      return { ...newState, [action.value]: false }
    case START_CLINRO_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_CLINRO_LOADING:
      return { ...newState, [action.value]: false }
    case START_MEDADHERENCE_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_MEDADHERENCE_LOADING:
      return { ...newState, [action.value]: false }
    case START_PENDINGPAYMENTS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_PENDINGPAYMENTS_LOADING:
      return { ...newState, [action.value]: false }
    case START_CREDENTIALS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_CREDENTIALS_LOADING:
      return { ...newState, [action.value]: false }
    case START_GEOFENCING_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_GEOFENCING_LOADING:
      return { ...newState, [action.value]: false }
    case START_BATTERY_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_BATTERY_LOADING:
      return { ...newState, [action.value]: false }
    case START_PHONECONTACTS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_PHONECONTACTS_LOADING:
      return { ...newState, [action.value]: false }
    case START_LOCATION_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_LOCATION_LOADING:
      return { ...newState, [action.value]: false }
    case START_MEDIAUPLOAD_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_MEDIAUPLOAD_LOADING:
      return { ...newState, [action.value]: false }
    case START_STEPCOUNTER_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_STEPCOUNTER_LOADING:
      return { ...newState, [action.value]: false }
    case START_APPSINSTALLED_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_APPSINSTALLED_LOADING:
      return { ...newState, [action.value]: false }
    case START_REQUEST_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_REQUEST_LOADING:
      return { ...newState, [action.value]: false }
    case START_SCREENTIME_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_SCREENTIME_LOADING:
      return { ...newState, [action.value]: false }
    case START_VISITORUSER_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_VISITORUSER_LOADING:
      return { ...newState, [action.value]: false }
    case START_VISITORPARTICIPANT_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_VISITORPARTICIPANT_LOADING:
      return { ...newState, [action.value]: false }
    case START_VISIT_LOADING:
      return { ...newState, [action.value]: { ...newState[action.value], [action.id]: true } }
    case STOP_VISIT_LOADING:
      return { ...newState, [action.value]: { ...newState[action.value], [action.id]: false } }
    case START_VISITSSCHEDULE_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_VISITSSCHEDULE_LOADING:
      return { ...newState, [action.value]: false }
    case START_VISITSHISTORY_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_VISITSHISTORY_LOADING:
      return { ...newState, [action.value]: false }
    case START_VIRTUALVISITS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_VIRTUALVISITS_LOADING:
      return { ...newState, [action.value]: false }
    case START_PARTICIPANTINSTRUMENTS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_PARTICIPANTINSTRUMENTS_LOADING:
      return { ...newState, [action.value]: false }
    case START_PARTICIPANTVISITS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_PARTICIPANTVISITS_LOADING:
      return { ...newState, [action.value]: false }
    case START_MOVEPARTICIPANTS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_MOVEPARTICIPANTS_LOADING:
      return { ...newState, [action.value]: false }
    case START_PTPSCOMPLIANCE_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_PTPSCOMPLIANCE_LOADING:
      return { ...newState, [action.value]: false }
    case START_USERSITES_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_USERSITES_LOADING:
      return { ...newState, [action.value]: false }
    case START_GEOFENCES_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_GEOFENCES_LOADING:
      return { ...newState, [action.value]: false }
    case START_PTPMOVE_LOADING:
      return { ...newState, [`${action.value}${action.id}`]: true }
    case STOP_PTPMOVE_LOADING:
      return { ...newState, [`${action.value}${action.id}`]: false }
    case START_TRIGGER_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_TRIGGER_LOADING:
      return { ...newState, [action.value]: false }
    case START_THUMBNAIL_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_THUMBNAIL_LOADING:
      return { ...newState, [action.value]: false }
    case START_SELECTIONPTPS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_SELECTIONPTPS_LOADING:
      return { ...newState, [action.value]: false }
    case START_DIARY_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_DIARY_LOADING:
      return { ...newState, [action.value]: false }
    case START_SISENSE_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_SISENSE_LOADING:
      return { ...newState, [action.value]: false }
    case START_SISENSESETUP_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_SISENSESETUP_LOADING:
      return { ...newState, [action.value]: false }
    case START_CONSENTPTPS_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_CONSENTPTPS_LOADING:
      return { ...newState, [action.value]: false }
    case START_MARKCONSENTASCOMPLETE_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_MARKCONSENTASCOMPLETE_LOADING:
      return { ...newState, [action.value]: false }
    case START_CONSENTATTACHMENT_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_CONSENTATTACHMENT_LOADING:
      return { ...newState, [action.value]: false }
    case START_TEMPPASSWORD_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_TEMPPASSWORD_LOADING:
      return { ...newState, [action.value]: false }
    case START_INSTRUMENTTIMELINE_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_INSTRUMENTTIMELINE_LOADING:
      return { ...newState, [action.value]: false }
    case START_DIARYTIMELINE_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_DIARYTIMELINE_LOADING:
      return { ...newState, [action.value]: false }
    case START_ANNOUNCEMENTTIMELINE_LOADING:
      return { ...newState, [action.value]: true }
    case STOP_ANNOUNCEMENTTIMELINE_LOADING:
      return { ...newState, [action.value]: false }
    default:
      return state
  }
}

export const loadingActions = {
  startLoader,
  stopLoader,
}

export default loading
