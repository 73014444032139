import React from 'react'
import moment from 'moment'
import { DatePicker } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { DATE_FORMAT_MAP } from 'utils/constants'
import { Button, Popup, Input } from 'components/UIElements'

class DateRangeSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    let state = {
      start: this.props.start || moment().startOf('day'),
      end: this.props.end || moment().endOf('day'),
      type: 'exact',
    }
    state.range = state.end.diff(state.start, 'days') + 1
    return state
  }

  renderTypeSelector = () => {
    return (
      <div className='format-list'>
        <Button
          content='Relative Range'
          onClick={() => this.setState({ type: 'exact' })}
          className={this.state.type === 'exact' ? 'selected' : ''}
        />
        <Button
          content='Exact Range'
          onClick={() => this.setState({ type: 'range' })}
          className={this.state.type === 'range' ? 'selected' : ''}
        />
      </div>
    )
  }

  onDayChange(key) {
    return date => {
      let newDate = key === 'start' ? moment(date).startOf('day') : moment(date).endOf('day')
      let otherDate = key === 'start' ? this.state.end : this.state.start
      this.setState({ [key]: newDate, range: Math.abs(newDate.diff(otherDate, 'days')) + 1 })
    }
  }

  onRelativeDayChange = days => {
    let end = moment().endOf('day')
    let start = moment(end)
      .subtract(days, 'days')
      .startOf('day')
    this.setState({ start, end, range: days })
  }

  onCancel = () => {
    this.setState(this.getInitialState())
  }

  onApply = () => {
    this.props.applyDateRange(this.state.start, this.state.end)
  }

  render() {
    let dayText = pluralize(this.state.range, 'day', 'days', false)
    return (
      <Popup
        align='right'
        trigger={
          <div className='data-gap-popup-button'>
            Date Range
            <i className='fas fa-filter' />
          </div>
        }>
        <div className='date-range-selector'>
          {this.renderTypeSelector()}
          {this.state.type === 'exact' ? (
            <div className='relative-range'>
              <Input
                posttext={`${dayText} from today (${moment().format(DATE_FORMAT_MAP.main)})`}
                type='number'
                onChange={this.onRelativeDayChange}
                value={this.state.range}
              />
            </div>
          ) : (
            <div className='exact-range'>
              <span>From </span>
              <DatePicker onDayChange={this.onDayChange('start')} date={this.state.start} />
              <span> To </span>
              <DatePicker onDayChange={this.onDayChange('end')} date={this.state.end} />
              <span>{`(${this.state.range} ${dayText})`}</span>
            </div>
          )}
          <div>
            {this.state.type === 'exact'
              ? 'Show participants that showed the least activity data for the set custom date range'
              : 'Show participants that showed the least activity data for the set last number of days'}
          </div>
          <div className='button-list flex-end selector'>
            <Button className='close-trigger' grey content='Cancel' onClick={this.onCancel} />
            <Button className='close-trigger' content='Apply' onClick={this.onApply} />
          </div>
        </div>
      </Popup>
    )
  }
}

export default DateRangeSelector
