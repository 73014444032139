import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { getStrictRegexFromTerm } from 'utils/search'
import { onEnterPress } from 'utils/misc'
import { Checkbox, Loader, Input, Radio } from 'components/UIElements'
import 'styles/d3/autocompleteGroupSearch.scss'

const COMPONENT_TYPE = {
  radio: 'radio',
  checkbox: 'checkbox',
}

class AutocompleteGroupSearch extends React.Component {
  constructor(props) {
    super(props)
    const { initialValue, list } = this.props
    this.autoCompleteSearchEl = null
    const selectedGroup = {}
    const initailGroup = props.initial?.field ? props.initial.field : list[0] && list[0].field ? list[0].field : ''
    selectedGroup[initailGroup] = true

    this.state = {
      searchTerm: initialValue,
      isFocused: false,
      filteredList: [...list],
      selectedGroup,
    }

    this.noMatchesRef = createRef()

    for (let i = 0; i <= list.length; i++) {
      this[`radioRef-${i}`] = createRef()
    }
  }

  componentDidMount() {
    const { initialValue } = this.props
    this.blurListener = e => {
      if (!this.autoCompleteSearchEl.contains(e.target)) {
        this.setState({
          isFocused: false,
          searchTerm: initialValue,
        })
      }
    }
    document.addEventListener('mouseup', this.blurListener)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.blurListener)
  }

  onFocus = () => {
    const { isFocused } = this.state
    if (!isFocused)
      this.setState({
        isFocused: true,
        searchTerm: '',
      })
  }

  clearSearch = () => this.setState({ searchTerm: '' })

  renderList = () => {
    const copmonents = {
      radio: Radio,
      checkbox: Checkbox,
    }
    const {
      checked = {},
      disabled,
      isDropdown,
      list = [],
      toggleItem,
      initial,
      groupComponent,
      childComponent,
    } = this.props
    const GroupComponent = copmonents[groupComponent]
    const ChildComponent = copmonents[childComponent]
    const { searchTerm, selectedGroup, isFocused } = this.state
    const regex = getStrictRegexFromTerm(searchTerm)
    this.noMatches = {}

    const _list = [initial || null, ...list]
    if (_list.length === 0) return null

    if (this.noMatchesRef?.current)
      this.noMatchesRef.current.hidden = !(searchTerm && Object.keys(selectedGroup).length === 0)

    return _list.map((group, index) => {
      const onClick = itm => {
        toggleItem(itm, group)
        if (isDropdown) this.setState({ isFocused: false, searchTerm: itm.text })
        else this.setState({ searchTerm: '' })
      }

      if (!group) return null
      let count = 0
      return (
        <React.Fragment key={group.field}>
          <li
            ref={this[`radioRef-${index}`]}
            className={`group-${groupComponent} ${
              (!selectedGroup[group.field] && searchTerm !== '') || !isFocused ? 'hidden' : ''
            }`}>
            <GroupComponent
              id={group.field}
              disabled={disabled}
              onClick={() => {
                this.setState(prev => {
                  const prevState = { ...prev }
                  switch (groupComponent) {
                    case COMPONENT_TYPE.radio:
                      prevState.selectedGroup = {}
                      prevState.selectedGroup[group.field] = true
                      break
                    case COMPONENT_TYPE.checkbox:
                      if (prevState.selectedGroup[group.field]) delete prevState.selectedGroup[group.field]
                      else prevState.selectedGroup[group.field] = true
                      break
                    default:
                      break
                  }
                  return prevState
                })
                onClick(null)
              }}
              selected={selectedGroup[group.field]}
              content={group.label}
              checked={selectedGroup[group.field]}
              label={group.label}
            />
            <ul className='group-items-list'>
              {selectedGroup[group.field] &&
                group?.value?.length !== 0 &&
                group.value?.map((item, idx) => {
                  const isMatch = item.text.match(regex)
                  const showItem = searchTerm === '' || isMatch
                  if (isMatch && this[`radioRef-${index}`]?.current) {
                    if (this.noMatchesRef?.current) this.noMatchesRef.current.hidden = true
                    if (this.noMatches[group.field]) {
                      delete this.noMatches[group.field]
                    }
                    this[`radioRef-${index}`].current.hidden = false
                  }
                  const _checked = checked[group.field] && item.key in checked[group.field]
                  const className = `child-${childComponent} list-item ${_checked ? ' checked' : ''}${
                    !showItem ? ' hidden' : ''
                  }`
                  if (!showItem && this[`radioRef-${index}`]?.current) {
                    count += 1
                    if (group.value.length === count) {
                      this.noMatches[group.field] = true
                      this[`radioRef-${index}`].current.hidden = true
                      if (Object.keys(this.noMatches).length === Object.keys(selectedGroup).length && !disabled)
                        if (this.noMatchesRef?.current) this.noMatchesRef.current.hidden = false
                    }
                  }
                  return (
                    <li
                      onKeyPress={e => onEnterPress(e, onClick)}
                      onMouseDown={this.onMouseDown}
                      className={className}
                      key={`${item.key}${idx}`}>
                      <ChildComponent
                        content={item.text}
                        selected={_checked}
                        label={item.text}
                        checked={_checked}
                        onClick={() => onClick({ ...item, field: group.field || '' })}
                      />
                    </li>
                  )
                })}
            </ul>
          </li>
        </React.Fragment>
      )
    })
  }

  placeCursorInOrigPos = pos => {
    const inputRef = this.inputRef?.input
    setTimeout(() => inputRef.setSelectionRange(pos, pos), 0)
  }

  onEnterPress = () => {
    const { toggleItem } = this.props
    const { isFocused, filteredList, searchTerm } = this.state
    const item = filteredList[0]
    if (isFocused) {
      if (!searchTerm) {
        if (filteredList.length === 0) return
        else {
          const { key, path } = item
          toggleItem(key, path)
        }
      } else {
        if (filteredList.length !== 0) {
          const { key, path } = item
          toggleItem(key, path)
        }
        this.clearSearch()
      }
    }
  }

  onBackspacePress = e => {
    const { deleteItemOnBackspace, onBackspacePress } = this.props
    if (onBackspacePress) onBackspacePress(e)
    if (!deleteItemOnBackspace) return null
  }

  generateSearchTermForTags = val => {
    const newVal = val.replace(/[\[\]]/g, '') // prevents '[' and ']' in tag names
    return newVal.slice(0, 50)
  }

  renderInput = () => {
    const { disabled, errorText, hasError, onChange, placeholder, id, value } = this.props
    const { isFocused, searchTerm } = this.state
    return (
      <Input
        ref={el => {
          this.inputRef = el
        }}
        id={id}
        onEnterPress={this.onEnterPress}
        onBackspacePress={this.onBackspacePress}
        autocomplete='-'
        disabled={disabled}
        errorText={!isFocused && errorText}
        hasError={!isFocused && hasError}
        onFocus={this.onFocus}
        onChange={val =>
          this.setState(
            {
              searchTerm: val,
            },
            () => {
              onChange && onChange()
            },
          )
        }
        placeholder={placeholder}
        value={searchTerm || value || ''}
      />
    )
  }

  render() {
    const { absolutePositionedList = false, className, disabled, hideSearchIcon, loading, list } = this.props
    const { isFocused } = this.state
    const inputEl = this.renderInput()

    return (
      <div
        ref={el => {
          this.autoCompleteSearchEl = el
        }}
        className={`autocomplete-group-search${className ? ` ${className}` : ''}`}>
        {inputEl}
        {loading && <Loader inContainer size={24} />}
        {!hideSearchIcon && <i className='fas fa-search' />}
        <ul className={`${isFocused ? '' : ' hidden'}${absolutePositionedList ? ' pos abs width full' : ''}`}>
          {this.renderList()}
          {!disabled && (
            <li ref={this.noMatchesRef} className='list-item no-matching'>
              No Matching Results
            </li>
          )}
        </ul>
      </div>
    )
  }
}

AutocompleteGroupSearch.defaultProps = {
  groupComponent: COMPONENT_TYPE.radio,
  childComponent: COMPONENT_TYPE.checkbox,
  initialValue: '',
}

AutocompleteGroupSearch.propTypes = {
  absolutePositionedList: PropTypes.bool,
  checked: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  deleteItemOnBackspace: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  hideSearchIcon: PropTypes.bool,
  initialValue: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  onBackspacePress: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  toggleItem: PropTypes.func,
  id: PropTypes.string,
  initial: PropTypes.shape({
    label: PropTypes.string,
    fiedl: PropTypes.string,
  }),
  isDropdown: PropTypes.bool,
  groupComponent: PropTypes.string,
  childComponent: PropTypes.string,
}

export default AutocompleteGroupSearch
