import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import moment from 'moment'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { actions as notyActions } from 'layouts/ErrorBox'
import { modalActions } from 'store/modal'
import { HTMLToEditorState, EditorStateToHTML, formatLinkEntities, HTMLToEditorStatePure } from 'utils/draft'
import request, { generateNotyMessage } from 'utils/request'
import {
  getConsentErrors,
  getCreateNewConsentErrors,
  checkForAdvancedDeploymentOptions,
} from 'utils/instrumentValidation'
import { downloadBlob } from 'utils/misc'
import { today } from 'utils/time'
import { convertAdvancedOptions } from 'utils/hoc/AdvancedOptionsHOC'
import {
  QUESTION_TYPE_MAP,
  CONSENT_TYPE_MAP,
  CONSENT_STATUS,
  MODAL_CONTENT_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  DATE_FORMAT_MAP,
  QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS,
} from 'utils/constants'
import { _getDefaultMetadata, _getDefaultSchedule } from '../../../../../../Instruments/utils/PropertyFields'
import { validateAndFixQuestionAndChoiceIdMismatches } from '../../../../../../Instruments/routes/Instrument/routes/EditSurvey/modules/Survey'
import {
  RESET_SCHEDULE,
  UPDATE_SCHEDULE,
  UPDATE_SCHEDULE_FIELD,
} from '../../../../../../Instruments/routes/Instrument/modules/Instrument'
import { getRandomQuestionId } from '../../../../../../../utils/getRandomQuestionId'

// Consent
export const INITIALIZE_NEW_CONSENT_EDIT = 'INITIALIZE_NEW_CONSENT_EDIT'
const INITIALIZE_NEW_BLANK_CONSENT = 'INITIALIZE_NEW_BLANK_CONSENT'
const INIT_FORMATTING = 'INIT_FORMATTING'
const UPDATE_NEW_CONSENT_TITLE = 'UPDATE_NEW_CONSENT_TITLE'
const TOGGLE_NEW_CONSENT_EDIT = 'TOGGLE_NEW_CONSENT_EDIT'
const UPDATE_NEW_CONSENT_ITEM = 'UPDATE_NEW_CONSENT_ITEM'
const UPDATE_NEW_CONSENT_ITEM_LABEL = 'UPDATE_NEW_CONSENT_ITEM_LABEL'
const CHANGE_NEW_CONSENT_ITEM_TYPE = 'CHANGE_NEW_CONSENT_ITEM_TYPE'
const TOGGLE_REQUIRED_NEW_CONSENT_QUESTION = 'TOGGLE_REQUIRED_NEW_CONSENT_QUESTION'
const ADD_NEW_CONSENT_ITEM = 'ADD_NEW_CONSENT_ITEM'
const DELETE_NEW_CONSENT_ITEM = 'DELETE_NEW_CONSENT_ITEM'
const MOVE_NEW_CONSENT_ITEM = 'MOVE_NEW_CONSENT_ITEM'
const DUPLICATE_NEW_CONSENT_ITEM = 'DUPLICATE_NEW_CONSENT_ITEM'
const ADD_NEW_CONSENT_CHOICE = 'ADD_NEW_CONSENT_CHOICE'
const ADD_NEW_CONSENT_CHOICE_ON_PASTE = 'ADD_NEW_CONSENT_CHOICE_ON_PASTE'
const ADD_OTHER_NEW_CONSENT_CHOICE = 'ADD_OTHER_NEW_CONSENT_CHOICE'
const DELETE_OTHER_NEW_CONSENT_CHOICE = 'DELETE_OTHER_NEW_CONSENT_CHOICE'
const UPDATE_OTHER_NEW_CONSENT_CHOICE = 'UPDATE_OTHER_NEW_CONSENT_CHOICE'
const UPDATE_NEW_CONSENT_CHOICE_LABEL = 'UPDATE_NEW_CONSENT_CHOICE_LABEL'
const DELETE_NEW_CONSENT_CHOICE = 'DELETE_NEW_CONSENT_CHOICE'
const MOVE_NEW_CONSENT_CHOICE = 'MOVE_NEW_CONSENT_CHOICE'
const UPDATE_NEW_CONSENT_LOGIC_CONDITION = 'UPDATE_NEW_CONSENT_LOGIC_CONDITION'
const ADD_NEW_CONSENT_LOGIC_CONDITION = 'ADD_NEW_CONSENT_LOGIC_CONDITION'
const ADD_NEW_CONSENT_LOGIC_GROUP = 'ADD_NEW_CONSENT_LOGIC_GROUP'
const DELETE_NEW_CONSENT_LOGIC_CONDITION = 'DELETE_NEW_CONSENT_LOGIC_CONDITION'
const DELETE_ALL_NEW_CONSENT_LOGIC = 'DELETE_ALL_NEW_CONSENT_LOGIC'
const CHANGE_NEW_CONSENT_LOGIC_OPERATOR = 'CHANGE_NEW_CONSENT_LOGIC_OPERATOR'
const DELETE_INVALID_NEW_CONSENT_LOGIC = 'DELETE_INVALID_NEW_CONSENT_LOGIC'
const UPDATE_ORIGINAL_NEW_CONSENT = 'UPDATE_ORIGINAL_NEW_CONSENT'
const UPDATE_NEW_CONSENT_UNIT_TYPE = 'UPDATE_NEW_CONSENT_UNIT_TYPE'
const RESET_NEW_CONSENT_DASHBOARD = 'RESET_NEW_CONSENT_DASHBOARD'
const ADD_NEW_CONSENT_SIGNEE = 'ADD_NEW_CONSENT_SIGNEE'
const UPDATE_NEW_CONSENT_SIGNEE = 'UPDATE_NEW_CONSENT_SIGNEE'
const DELETE_NEW_CONSENT_SIGNEE = 'DELETE_NEW_CONSENT_SIGNEE'
const MOVE_NEW_CONSENT_SIGNEE = 'MOVE_NEW_CONSENT_SIGNEE'
const ADD_NEW_CONSENT_TRANSLATION = 'ADD_NEW_CONSENT_TRANSLATION'
const DELETE_NEW_CONSENT_TRANSLATION = 'DELETE_NEW_CONSENT_TRANSLATION'
const CLEAR_ALL_NEW_CONSENT_TRANSLATIONS = 'CLEAR_ALL_NEW_CONSENT_TRANSLATIONS'
const UPDATE_NEW_CONSENT_CHOICE_LABEL_TRANSLATION = 'UPDATE_NEW_CONSENT_CHOICE_LABEL_TRANSLATION'
const UPDATE_NEW_CONSENT_ITEM_LABEL_TRANSLATION = 'UPDATE_NEW_CONSENT_ITEM_LABEL_TRANSLATION'
const UPDATE_NEW_CONSENT_TYPE = 'UPDATE_NEW_CONSENT_TYPE'
const UPDATE_NEW_CONSENT_NAME = 'UPDATE_NEW_CONSENT_NAME'
const UPDATE_NEW_CONSENT_PDF_UPLOAD = 'UPDATE_NEW_CONSENT_PDF_UPLOAD'
const UPDATE_NEW_DISPLAY_NAME = 'UPDATE_NEW_DISPLAY_NAME'
const UPDATE_ADDENDUM_CONSENT = 'UPDATE_ADDENDUM_CONSENT'
const UPDATE_NEW_CONSENT_KEY = 'UPDATE_NEW_CONSENT_KEY'
const UPDATE_NEW_CONSENT_ELEMENT = 'UPDATE_NEW_CONSENT_ELEMENT'
const SET_CONSENT_PARTICIPANTS_STATUS = 'SET_CONSENT_PARTICIPANTS_STATUS'
const SET_SITE_ROLES_STATUS = 'SET_SITE_ROLES_STATUS'
const RESET_SITE_ROLES_STATUS = 'RESET_SITE_ROLES_STATUS'
const SET_ADVANCED_OPTIONS = 'SET_ADVANCED_OPTIONS'
const RESET_ADVANCED_OPTIONS = 'RESET_ADVANCED_OPTIONS'

// Errors
const ADD_CONSENT_ERRORS = 'ADD_CONSENT_ERRORS'
const CLEAR_CONSENT_ERROR = 'CLEAR_CONSENT_ERROR'
const CLEAR_CONSENT_QUESTION_ERROR = 'CLEAR_CONSENT_QUESTION_ERROR'

//
// Action Creators
//

// Consent Actions
function initializeEdit(json, _editorStates, isMetadataOnly) {
  return {
    type: INITIALIZE_NEW_CONSENT_EDIT,
    json,
    editorStates: _editorStates,
    isMetadataOnly,
  }
}

function initializeBlankConsent(title = '') {
  return {
    type: INITIALIZE_NEW_BLANK_CONSENT,
    title,
  }
}

function initFormatting() {
  return {
    type: INIT_FORMATTING,
  }
}

export function resetConsentDashboard() {
  return {
    type: RESET_NEW_CONSENT_DASHBOARD,
  }
}

export function updateTitle(title) {
  return {
    type: UPDATE_NEW_CONSENT_TITLE,
    title,
  }
}

export const updateConsentType = type => {
  return {
    type: UPDATE_NEW_CONSENT_TYPE,
    consent_type: type,
  }
}

export const updateConsentName = value => {
  return {
    type: UPDATE_NEW_CONSENT_NAME,
    value,
  }
}

export const updateConsentPdfUpload = value => {
  return {
    type: UPDATE_NEW_CONSENT_PDF_UPLOAD,
    value,
  }
}

export const updateDisplayName = value => {
  return {
    type: UPDATE_NEW_DISPLAY_NAME,
    value,
  }
}

export const updateAddendumConsent = ({ consentId, consentTitle }) => {
  return {
    type: UPDATE_ADDENDUM_CONSENT,
    consentId,
    consentTitle,
  }
}

export const updateConsentKey = ({ key, value }) => {
  return {
    type: UPDATE_NEW_CONSENT_KEY,
    key,
    value,
  }
}

export const setConsentParticipantsStatus = value => {
  return {
    type: SET_CONSENT_PARTICIPANTS_STATUS,
    value,
  }
}

const updateConsentElement = ({ elementKey, newEditorState }) => {
  return {
    type: UPDATE_NEW_CONSENT_ELEMENT,
    elementKey,
    newEditorState,
  }
}

// Item Actions

function toggleEdit(itemId) {
  return {
    type: TOGGLE_NEW_CONSENT_EDIT,
    itemId,
  }
}

function addItem({ itemType, index, newQId, isClinro }) {
  const choiceOneId = `${newQId}_${getRandomQuestionId()}`
  const choiceTwoId = `${newQId}_${getRandomQuestionId()}`
  return {
    type: ADD_NEW_CONSENT_ITEM,
    itemType,
    newQId,
    index,
    choiceOneId,
    choiceTwoId,
    isClinro,
  }
}

function deleteItem(itemId) {
  return {
    type: DELETE_NEW_CONSENT_ITEM,
    itemId,
  }
}

function addChoice(itemId, choicesLength, insertIdx) {
  return {
    type: ADD_NEW_CONSENT_CHOICE,
    itemId,
    insertIdx,
    choicesLength,
  }
}

function addChoiceOnPaste(itemId, pasteText) {
  return {
    type: ADD_NEW_CONSENT_CHOICE_ON_PASTE,
    itemId,
    pasteText,
  }
}

function addOtherChoice(itemId) {
  return {
    type: ADD_OTHER_NEW_CONSENT_CHOICE,
    itemId,
  }
}

function deleteOtherChoice(itemId) {
  return {
    type: DELETE_OTHER_NEW_CONSENT_CHOICE,
    itemId,
  }
}

function updateOtherChoice(item, itemId, otherValue) {
  return {
    type: UPDATE_OTHER_NEW_CONSENT_CHOICE,
    item,
    itemId,
    otherValue,
  }
}

function deleteChoice(itemId, choiceId) {
  return {
    type: DELETE_NEW_CONSENT_CHOICE,
    itemId,
    choiceId,
  }
}

function updateChoiceLabel(item, itemId, choiceId, newLabel) {
  return {
    type: UPDATE_NEW_CONSENT_CHOICE_LABEL,
    item,
    itemId,
    choiceId,
    newLabel,
  }
}

function moveChoice(itemId, startIdx, endIdx) {
  return {
    type: MOVE_NEW_CONSENT_CHOICE,
    itemId,
    startIdx,
    endIdx,
  }
}

function changeItemType(itemId, prevType, nextType, isClinro) {
  return {
    type: CHANGE_NEW_CONSENT_ITEM_TYPE,
    itemId,
    prevType,
    nextType,
    isClinro,
  }
}

function toggleRequiredQuestion(itemId) {
  return {
    type: TOGGLE_REQUIRED_NEW_CONSENT_QUESTION,
    itemId,
  }
}

function moveItem(startIdx, endIdx) {
  return {
    type: MOVE_NEW_CONSENT_ITEM,
    startIdx,
    endIdx,
  }
}

function setSiteRolesStatus(status) {
  return {
    type: SET_SITE_ROLES_STATUS,
    status,
  }
}

function _resetSiteRolesStatus() {
  return {
    type: RESET_SITE_ROLES_STATUS,
  }
}

function setAdvancedOptionsToStore(value) {
  return {
    type: SET_ADVANCED_OPTIONS,
    value,
  }
}

function resetAdvancedOptions() {
  return {
    type: RESET_ADVANCED_OPTIONS,
  }
}

// Signature question actions

const addSignee = itemId => {
  return {
    type: ADD_NEW_CONSENT_SIGNEE,
    itemId,
  }
}
const updateSignee = ({ itemId, signeeId, signee }) => {
  return {
    type: UPDATE_NEW_CONSENT_SIGNEE,
    itemId,
    signeeId,
    signee,
  }
}
const deleteSignee = (itemId, signeeId) => {
  return {
    type: DELETE_NEW_CONSENT_SIGNEE,
    itemId,
    signeeId,
  }
}

const moveSignee = ({ itemId, oldIndex, newIndex }) => {
  return {
    type: MOVE_NEW_CONSENT_SIGNEE,
    itemId,
    oldIndex,
    newIndex,
  }
}

// Translation actions

const addConsentTranslation = langKey => {
  return {
    type: ADD_NEW_CONSENT_TRANSLATION,
    langKey,
  }
}

const deleteConsentTranslation = langKey => {
  return {
    type: DELETE_NEW_CONSENT_TRANSLATION,
    langKey,
  }
}
const clearAllConsentTranslations = () => {
  return {
    type: CLEAR_ALL_NEW_CONSENT_TRANSLATIONS,
  }
}

const updateChoiceLabelTranslation = ({ item, itemId, choiceId, newTranslation, language }) => {
  return {
    type: UPDATE_NEW_CONSENT_CHOICE_LABEL_TRANSLATION,
    item,
    itemId,
    choiceId,
    newTranslation,
    language,
  }
}

const updateItemLabelTranslation = ({ itemId, oldEditorState, newEditorState, language }) => {
  return {
    type: UPDATE_NEW_CONSENT_ITEM_LABEL_TRANSLATION,
    itemId,
    oldEditorState,
    newEditorState,
    language,
  }
}

// Signature util functions

const getDefaultConsentSignatureAttr = (optionalObject = {}) => {
  return {
    required: true,
    signature_and_camera_toggle: false,
    print_to_sign: true,
    custom_name_enter: false,
    signature_draw: true,
    user_type: 'participant',
    ...optionalObject,
  }
}

const getDefaultSignee = (type = 'participant') => {
  return {
    type,
    required: true,
  }
}

const getDefaultSignees = (itemId, isClinro) => {
  const signeeQId1 = `${itemId}_${getRandomQuestionId()}`
  const signeeQId2 = `${itemId}_${getRandomQuestionId()}`
  const signeeQId3 = `${itemId}_${getRandomQuestionId()}`
  const signeeQId4 = `${itemId}_${getRandomQuestionId()}`

  const defaultSignees = {
    [signeeQId1]: getDefaultSignee(),
    [signeeQId2]: getDefaultSignee('caregiver'),
  }
  const defaultSigneeOrder = [signeeQId1, signeeQId2]

  if (isClinro) {
    return {
      signees: {
        ...defaultSignees,
        [signeeQId3]: getDefaultSignee('clinro'),
        [signeeQId4]: getDefaultSignee('witness'),
      },
      signeeOrder: [...defaultSigneeOrder, signeeQId3, signeeQId4],
    }
  }

  return {
    signees: defaultSignees,
    signeeOrder: defaultSigneeOrder,
  }
}

export function duplicateItem({ itemId, editorState, choiceQProps, qType }) {
  const newQId = getRandomQuestionId()
  if (choiceQProps) {
    /**
     * we will keep choices editor states in here for the future when there is
     * rich text implemented for the clinro choices
     *  */
    const { choicesOrder, choicesEditorStates } = choiceQProps
    const newChoicesIdMap = {}
    choicesOrder.forEach(choiceId => {
      const newChoiceId = `${newQId}_${getRandomQuestionId()}`
      newChoicesIdMap[choiceId] = newChoiceId
    })
    return {
      type: DUPLICATE_NEW_CONSENT_ITEM,
      choicesEditorStates,
      hasChoices: true,
      choicesOrder,
      editorState,
      itemId,
      newChoicesIdMap,
      newQId,
    }
  }
  return {
    type: DUPLICATE_NEW_CONSENT_ITEM,
    itemId,
    newQId,
    editorState,
    qType,
  }
}

function updateUnitType(itemId, unit) {
  return {
    type: UPDATE_NEW_CONSENT_UNIT_TYPE,
    itemId,
    unit,
  }
}

// Error actions

function addConsentErrors(errors) {
  return {
    type: ADD_CONSENT_ERRORS,
    errors,
  }
}

function checkLogicAndToggleRequired(survey, itemId) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnToggleRequired(survey.questions, survey.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(toggleRequiredQuestion(itemId))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.actionInvalidate,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(toggleRequiredQuestion(itemId))
    }
  }
}

function checkLogicAndDeleteItem(survey, itemId) {
  return dispatch => {
    let onConfirm = () => {
      dispatch(deleteItem(itemId))
    }
    let modalBody = {
      content: MODAL_CONTENT_MAP.deleteItem,
      confirmButton: MODAL_BUTTONS_MAP.yes,
      cancelButton: MODAL_BUTTONS_MAP.no,
    }
    const invalidConditions = findInvalidLogicOnDelete(survey.questions, survey.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) {
      onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(deleteItem(itemId))
      }
      modalBody = {
        content: MODAL_CONTENT_MAP.logicInvalidatedByMove,
        confirmButton: MODAL_BUTTONS_MAP.proceed,
        cancelButton: MODAL_BUTTONS_MAP.cancel,
      }
    }
    dispatch(
      modalActions.openModal({
        ...modalBody,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  }
}

function checkLogicAndMoveItem(survey, startIdx, endIdx) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnMove(survey.questions, survey.order[0], startIdx, endIdx)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(moveItem(startIdx, endIdx))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByMove,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(moveItem(startIdx, endIdx))
    }
  }
}

function checkLogicAndDeleteChoice(survey, itemId, choiceId) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnChoiceDelete(survey.questions, survey.order[0], itemId, choiceId)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(deleteChoice(itemId, choiceId))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByDeletion,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(deleteChoice(itemId, choiceId))
    }
  }
}

function checkLogicAndChangeItemType(survey, itemId, prevType, nextType, isClinro) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnChangeItemType(
      survey.questions,
      survey.order[0],
      itemId,
      prevType,
      nextType,
    )
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(changeItemType(itemId, prevType, nextType, isClinro))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByType,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(changeItemType(itemId, prevType, nextType, isClinro))
    }
  }
}

function logicForEach(terms, callback, path = []) {
  if (!terms) return
  terms.forEach((term, index) => {
    if (term.comparator === 'boolean_combine') {
      logicForEach(term.terms, callback, path.slice().concat(index))
    } else {
      callback(term, path.slice().concat(index))
    }
  })
}

export function findInvalidLogicOnMove(questions, order, startIdx, endIdx) {
  const invalidConditions = {}
  if (startIdx > endIdx) {
    const itemId = order[startIdx]
    if (!questions[itemId].logic.show_if) return invalidConditions
    const currentItemRefs = {}
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      currentItemRefs[term.question_id] = currentItemRefs[term.question_id] || []
      currentItemRefs[term.question_id].push(path)
    })
    for (let i = endIdx; i < startIdx; i++) {
      if (order[i] in currentItemRefs) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId] = invalidConditions[itemId].concat(currentItemRefs[order[i]])
      }
    }
  } else {
    for (let i = startIdx + 1; i <= endIdx; i++) {
      if (!questions[order[i]].logic.show_if) continue
      logicForEach(questions[order[i]].logic.show_if.terms, (term, path) => {
        if (term.question_id === order[startIdx]) {
          invalidConditions[order[i]] = invalidConditions[order[i]] || []
          invalidConditions[order[i]].push(path)
        }
      })
    }
  }
  return invalidConditions
}

export function findInvalidLogicOnDelete(questions, order, deleteId) {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (deleteId === term.question_id) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnToggleRequired(questions, order, toggleId) {
  const invalidConditions = {}
  if (questions[toggleId].attributes.required) return invalidConditions
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (toggleId === term.question_id && term.comparator.includes('answered')) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnChoiceDelete(questions, order, itemId, choiceId) {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (term.value === choiceId) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnChangeItemType(questions, order, itemId, prevType, nextType) {
  if (prevType === nextType) return {}
  const invalidConditions = {}
  if (prevType !== nextType) {
    order.forEach(id => {
      if (!questions[id].logic.show_if) return
      logicForEach(questions[id].logic.show_if.terms, (term, path) => {
        if (!term.comparator.includes('answered') && term.question_id === itemId) {
          invalidConditions[id] = invalidConditions[id] || []
          invalidConditions[id].push(path)
        }
      })
    })
  }
  return invalidConditions
}

function deleteInvalidLogic(invalidConditions) {
  return {
    type: DELETE_INVALID_NEW_CONSENT_LOGIC,
    invalidConditions,
  }
}

function updateItemLabel(itemId, oldEditorState, newEditorState) {
  return {
    type: UPDATE_NEW_CONSENT_ITEM_LABEL,
    itemId,
    oldEditorState,
    newEditorState,
  }
}

function updateItem(itemId, item) {
  return {
    type: UPDATE_NEW_CONSENT_ITEM,
    itemId,
    item,
  }
}

// Logic Actions
function updateLogicCondition(itemId, logic, conditionPath, field, value) {
  return {
    type: UPDATE_NEW_CONSENT_LOGIC_CONDITION,
    itemId,
    conditionPath,
    field,
    value,
    logic,
  }
}

function addLogicCondition(itemId, groupPath) {
  return {
    type: ADD_NEW_CONSENT_LOGIC_CONDITION,
    itemId,
    groupPath,
  }
}

function addLogicGroup(itemId) {
  return {
    type: ADD_NEW_CONSENT_LOGIC_GROUP,
    itemId,
  }
}

function deleteLogicCondition(itemId, conditionPath) {
  return {
    type: DELETE_NEW_CONSENT_LOGIC_CONDITION,
    itemId,
    conditionPath,
  }
}

function deleteAllLogic(itemId) {
  return {
    type: DELETE_ALL_NEW_CONSENT_LOGIC,
    itemId,
  }
}

function changeLogicOperator(itemId, groupPath, value) {
  return {
    type: CHANGE_NEW_CONSENT_LOGIC_OPERATOR,
    itemId,
    groupPath,
    value,
  }
}

//
// API Actions
//

const downloadTranslations = (studyID, checkedInstruments) => {
  return dispatch => {
    const promises = Object.keys(checkedInstruments).map(id => {
      return dispatch(_downloadTranslation(studyID, id, checkedInstruments[id]))
    })
    return Promise.all(promises)
  }
}

const downloadConsentTranslation = studyID => {
  const success = (blob, fileName) => {
    downloadBlob(blob, `study_${studyID}_consent_translation_${today(true)}.csv`, fileName)
  }

  return dispatch => {
    return dispatch(
      request({
        url: `/control/studies/${studyID}/consent_translation`,
        resType: 'blob',
        success,
        fail: () => {
          throw new Error('Error when downloading consent')
        },
      }),
    )
  }
}

export function fetchNewConsent({ studyId, consentId, version, getJson = true, loadingKey }) {
  return dispatch => {
    const versionSuffix = version ? `?version=${version}` : ''
    const jsonSuffix = getJson ? '/consent.json' : ''
    function success(json) {
      const { formattedJSON, editorStates: _editorStates } = prepareConsent(json)
      if (getJson) calcQuestionNumbers(formattedJSON.order, formattedJSON.questions)
      dispatch(initializeEdit(formattedJSON, _editorStates, !getJson))
      dispatch(initFormatting())
      dispatch(setConsentParticipantsStatus(formattedJSON?.participant_history))
    }

    function fail(res) {
      throw new Error(`${res.status} ${res.statusText} when fetching eConsent`)
    }

    return dispatch(
      request({
        method: 'GET',
        url: `/control/v2/admin/studies/${studyId}/consents/${consentId}${jsonSuffix}${versionSuffix}`,
        failMessage: 'Error fetching eConsent.',
        success,
        fail,
        hasLoader: loadingKey ? !!loadingKey : getJson,
        forceLoader: getJson,
        loadingKey,
      }),
    )
  }
}

// const uploadTranslation = (studyID, file) => {
//   return dispatch => {
//     return dispatch(
//       request({
//         method: 'PUT',
//         url: `/control/studies/${studyID}/consent_translation`,
//         body: file,
//         success: () => dispatch(fetchConsent(studyID)),
//         successMessage: 'Translation successfully uploaded.',
//         catchMessage: 'There was a problem uploading the file. Please try again later.',
//         hasLoader: true,
//         contentType: 'text/csv',
//       }),
//     )
//   }
// }

const _deployConsent = (studyId, consentId) => dispatch => {
  const success = () => {
    browserHistory.push(`/studies/${studyId}/consents`)
  }
  return dispatch(
    request({
      url: `/control/v2/studies/${studyId}/consents/${consentId}/deploy`,
      method: 'POST',
      hasLoader: true,
      successMessage: 'Consent deployed successfully',
      success,
      failMessage: 'Failed to deploy consent',
    }),
  )
}

const toggleDeployConfirm = (studyId, consentId) => {
  return dispatch => {
    const onConfirm = () => {
      dispatch(_deployConsent(studyId, consentId))
    }
    dispatch(
      modalActions.openModal({
        content: MODAL_CONTENT_MAP.deployConsent,
        confirmButton: MODAL_BUTTONS_MAP.proceed,
        cancelButton: MODAL_BUTTONS_MAP.cancel,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  }
}

export const _saveNewConsent = (studyID, body, updateProps = {}) => dispatch => {
  const { consentId, isJsonSave, isUpdate, consentVersion } = updateProps
  const idSuffix = consentId ? `/${consentId}` : ''

  let suffix = ''
  if (isJsonSave) suffix = `/${consentVersion}.json`

  const url = `/control/v2/studies/${studyID}/consents${idSuffix}${suffix}`
  return dispatch(
    request({
      url,
      body,
      method: isUpdate || isJsonSave ? 'PUT' : 'POST',
      successMessage: 'Consent saved successfully',
      hasLoader: true,
      failMessage: 'Failed to save consent to database',
      success: json => json,
    }),
  )
}

export const saveNewConsentToDatabase = ({ consent: _consent, studyID }, isPdfUpload = false) => dispatch => {
  const { consent_type, consent_title } = _consent

  const defaultConsentJSON = {
    consent_title,
    metadata: {},
    consent_type,
    is_blocking: true,
    pdf_upload: isPdfUpload,
  }

  const success = resJSON => {
    dispatch({
      type: UPDATE_ORIGINAL_NEW_CONSENT,
      json: _consent,
    })
    return Promise.resolve(resJSON)
  }

  const consentJSON = { ...defaultConsentJSON, ..._consent }
  consentJSON.consent_status = CONSENT_STATUS.inProgress.key

  const body = JSON.stringify(consentJSON)

  return dispatch(_saveNewConsent(studyID, body)).then(success)
}

export function saveConsentToDatabase({
  consent: _consent,
  editorStates: _editorStates,
  studyID,
  isProperties = false,
  setInProgress = false,
  setReadyToBeScheduled = false,
  isUpdate = false,
  isJsonSave,
  isPdfUpload,
}) {
  return dispatch => {
    const consentJSON = JSON.parse(JSON.stringify(_consent))

    consentJSON.pdf_upload = isPdfUpload

    if (!isProperties) {
      formatLabelsAndTranslations({
        questions: consentJSON.questions,
        editorStates: _editorStates,
        otherLanguages: _consent.metadata.other_languages,
        consentElements: consentJSON.consent_elements,
      })
    }

    cleanUpAndFormatConsent(consentJSON)

    /**
     * This function will resolve any mismatches between a question's ID and
     * its choices' IDs.
     *  */
    if (!isProperties) validateAndFixQuestionAndChoiceIdMismatches(consentJSON)

    if (setReadyToBeScheduled) consentJSON.consent_status = CONSENT_STATUS.readyToBeScheduled.key
    else if (setInProgress) consentJSON.consent_status = CONSENT_STATUS.inProgress.key

    function success(resJSON) {
      dispatch({
        type: UPDATE_ORIGINAL_NEW_CONSENT,
        json: _consent,
      })
      return Promise.resolve(resJSON)
    }

    const body = JSON.stringify(consentJSON)
    const updateProps = {
      consentId: _consent.consent_id,
      isUpdate,
      isJsonSave,
      consentVersion: _consent.consent_version,
      isProperties,
    }
    return dispatch(_saveNewConsent(studyID, body, updateProps)).then(success)
  }
}

export const onCreateNewConsent = ({ studyID, newConsentReducer, onRedirect, isPdfUpload }) => dispatch => {
  const { consent: _consent } = newConsentReducer
  const { consent_type: consentType } = _consent
  const errors = getCreateNewConsentErrors(_consent)
  const valid = consentType === 'addendum' || !(Object.values(errors).filter(val => val).length >= 1)
  if (valid) {
    dispatch(saveNewConsentToDatabase({ studyID, ...newConsentReducer }, isPdfUpload)).then(onRedirect)
  } else {
    dispatch(addConsentErrors(errors))
    dispatch(
      notyActions.showError({
        text: generateNotyMessage('Please check errors before proceeding.', false),
      }),
    )
  }
}

export function validateConsentAndSave({
  studyID,
  newConsentReducer,
  onRedirect,
  isProperties,
  setReadyToBeScheduled = false,
  setInProgress = false,
  isUpdate = false,
  isJsonSave = false,
  advancedOptions = null,
  shouldValidate = false,
  isPdfUpload,
  isEditConsent,
}) {
  return dispatch => {
    const {
      consent: _consent,
      editorStates: _editorStates,
      siteRolesStatus,
      instrumentAdvancedOptions: consentAdvancedOptions,
    } = newConsentReducer

    const hasAdvancedOptions = checkForAdvancedDeploymentOptions(advancedOptions || consentAdvancedOptions)

    const convertedAdvancedOptions = convertAdvancedOptions(advancedOptions || consentAdvancedOptions)

    if (hasAdvancedOptions && convertedAdvancedOptions) {
      _consent.metadata.schedule.cohort = [...convertedAdvancedOptions]
    }
    if (shouldValidate && !hasAdvancedOptions && Array.isArray(_consent.metadata.schedule.cohort)) {
      _consent.metadata.schedule.cohort = {}
    }

    const errors = getConsentErrors({
      consent: _consent,
      editorStates: _editorStates,
      skipQuestionValidation: isProperties,
      siteRolesStatus,
      isEditConsent,
    })
    const valid = isProperties
      ? Object.keys(errors).length === 0
      : !(Object.keys(errors).length > 1 || Object.keys(errors.questions).length > 0)
    if (valid) {
      dispatch(
        saveConsentToDatabase({
          studyID,
          ...newConsentReducer,
          isProperties,
          setReadyToBeScheduled,
          isUpdate,
          isJsonSave,
          setInProgress,
          isPdfUpload,
        }),
      ).then(onRedirect)
    } else {
      dispatch(addConsentErrors(errors))
      dispatch(
        notyActions.showError({
          text: generateNotyMessage('Please check errors before proceeding.', false),
        }),
      )

      if (errors.noUnskippableSignatureErr) {
        dispatch(
          modalActions.openModal({
            content: errors.noUnskippableSignatureErr,
            confirmButton: MODAL_BUTTONS_MAP.okay,
            className: MODAL_CLASSES_MAP.confirmation,
            width: '640px',
            hideCancelButton: true,
          }),
        )
      }
    }
  }
}

export const downloadConsentPreview = (data, studyID) => {
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyID}_${data.title}_${today(true)}.pdf`, fileName)
    }
    return dispatch(
      request({
        method: 'POST',
        url: `/control/consent_preview`,
        body: JSON.stringify(data),
        resType: 'blob',
        success,
      }),
    )
  }
}

// export function saveUploadToDatabase(studyID, uploadedConsent) {
//   return dispatch => {
//     const body = new FormData()
//     body.set('format', uploadedConsent.format)
//     body.set('data', uploadedConsent.data)
//     return dispatch(_saveConsent(studyID, body, uploadedConsent.id, null))
//   }
// }

export const downloadParticipantConsent = (studyID, ptpID, { fname, lname }) => {
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyID}_${fname}_${lname}_Consent.pdf`, fileName)
    }
    return dispatch(
      request({
        url: `/control/studies/${studyID}/participants/${ptpID}/consent`,
        resType: 'blob',
        success,
      }),
    )
  }
}

const uploadConsentPdf = ({ formData, studyID, consentId, loadingKey = 'mediaUpload' }) => {
  const success = payload => {
    const { consent_url } = payload
    return {
      filename: consent_url,
    }
  }
  return dispatch => {
    return dispatch(
      request({
        method: 'POST',
        url: `/control/v2/studies/${studyID}/consents/${consentId}/pdf_upload`,
        contentType: 'multipart/form-data',
        body: formData,
        hasLoader: true,
        loadingKey,
        success,
      }),
    )
  }
}

const resetSiteRolesStatus = () => dispatch => dispatch(_resetSiteRolesStatus())

export function checkRolesForSites(studyID, siteIds) {
  return dispatch => {
    const body = {
      site_ids: siteIds,
    }
    const success = json => {
      const { status } = json
      dispatch(setSiteRolesStatus(status))
      return Promise.resolve()
    }
    return dispatch(
      request({
        method: 'POST',
        url: `/control/v2/studies/${studyID}/check_roles`,
        success,
        body: JSON.stringify(body),
      }),
    )
  }
}

//
// State Generators
//

const generateQuestionsAndOrder = (isPdfUpload = false) => {
  const newQId1 = getRandomQuestionId()
  const newQId2 = getRandomQuestionId()
  const newSigneeId = `${newQId2}_${getRandomQuestionId()}`
  const order = [[newQId1, newQId2]]
  const questions = {
    [newQId1]: isPdfUpload
      ? {
          type: QUESTION_TYPE_MAP.pdf,
          label: 'PDF',
          url: '',
          choices: {},
          choices_order: [],
          attributes: {
            required: true,
          },
          logic: {},
          url_translations: {},
        }
      : {
          type: QUESTION_TYPE_MAP.prompt,
          label: 'Your legal text.',
          choices: {},
          choices_order: [],
          attributes: {
            required: true,
          },
          logic: {},
        },
    [newQId2]: {
      type: QUESTION_TYPE_MAP.signature,
      label: '',
      choices: {},
      choices_order: [],
      attributes: {
        required: true,
        signees: { [newSigneeId]: { type: 'participant', required: true } },
        signeeOrder: [newSigneeId],
      },

      logic: {},
    },
  }
  return { order, questions }
}

const generateConsentElements = () => ({
  left_header: '',
  center_header: '',
  right_header: '',
  left_footer: '',
  center_footer: '',
})

function generateBlankConsent(initialTitle) {
  const { order, questions } = generateQuestionsAndOrder()
  return {
    consent_title: initialTitle || '',
    type: 'CONSENT',
    metadata: _getDefaultMetadata('CONSENT'),
    order,
    questions,
    consent_status: 'in_progress',
    consent_elements: generateConsentElements(),
    pdf_upload: false,
  }
}

//
// Util Functions
//

const parseSignaturesIntoParentSignatureQuestions = ({ _consent, signatureItem, itemId }) => {
  const { questions, order } = _consent
  const { signature_parent_id, signee_order_idx, camera_tip, clinro_camera_tip } = signatureItem
  if (signature_parent_id) {
    const parentQuestion = questions[signature_parent_id]
    const { attributes } = signatureItem
    const { user_type, required } = attributes
    if (parentQuestion) {
      parentQuestion.attributes.signees[itemId] = { type: user_type, required }
      parentQuestion.attributes.signeeOrder[signee_order_idx] = itemId
    } else {
      questions[signature_parent_id] = {
        type: 'signature',
        label: '',
        choices: {},
        choices_order: [],
        attributes: {
          required,
          signees: {
            [itemId]: { type: user_type, required },
          },
          signeeOrder: [itemId],
        },
        logic: {},
      }
    }
    if (camera_tip) {
      questions[signature_parent_id].camera_tip = camera_tip
    } else if (clinro_camera_tip) {
      questions[signature_parent_id].clinro_camera_tip = clinro_camera_tip
    }
    delete questions[itemId]
    if (signee_order_idx === 0) {
      const currentIdx = order[0].findIndex(questionId => questionId === itemId)
      _consent.order[0][currentIdx] = signature_parent_id
    } else {
      _consent.order = [order[0].filter(qId => qId !== itemId)]
    }
  }
}

export function prepareConsent(json) {
  const _editorStates = {}

  /**
   * If a fetch consent does not have questions, we will give it default
   * questions and accompanying order of a prompt and a signature.
   */
  if (!json.questions && !json.pdf_upload) {
    const { questions, order } = generateQuestionsAndOrder()
    json.questions = questions
    json.order = order
  }

  const questionValues = Object.values(json.questions)
  const pdfQuestion = questionValues.find(question => question.type === QUESTION_TYPE_MAP.pdf)
  const pdfUrlSet = !!pdfQuestion?.url
  const pdfUrlTranslationsSet = !!Object.values(pdfQuestion?.url_translations || {}).length

  if (pdfQuestion || pdfUrlSet || pdfUrlTranslationsSet) {
    json.pdf_upload = true
  }

  if (json.pdf_upload) {
    // We need to generate empty PDF questions only when there is no previously uploaded PDF
    // Because currently we don't have an ability to update questions with 'pdf' question type
    if (!pdfUrlSet && !pdfUrlTranslationsSet) {
      const { questions, order } = generateQuestionsAndOrder(json.pdf_upload)
      json.questions = questions
      json.order = order
    }
  }

  for (const qId in json.questions) {
    const question = json.questions[qId]
    if (question.type === 'signature') {
      parseSignaturesIntoParentSignatureQuestions({ _consent: json, signatureItem: question, itemId: qId })
    }
    const { label_translations } = question
    if (label_translations) {
      for (const language in label_translations) {
        const editorStateLangId = `${qId}_${language}`
        _editorStates[editorStateLangId] = formatLinkEntities(HTMLToEditorState(label_translations[language]))
        label_translations[language] = stateToHTML(_editorStates[editorStateLangId].getCurrentContent())
      }
    }
    _editorStates[qId] = formatLinkEntities(HTMLToEditorState(question.label))
    question.label = stateToHTML(_editorStates[qId].getCurrentContent())
    delete question.attributes.appearance
    // encapsulate logic conditions if they aren't in a boolean combine
    if (Object.keys(question.logic).length > 0 && question.logic.show_if.comparator !== 'boolean_combine') {
      question.logic.show_if = {
        comparator: 'boolean_combine',
        operator: 'and',
        terms: [question.logic.show_if],
      }
    }
  }

  /**
   * If a fetch consent does not have consent_elements, we will give it default
   * consents
   */
  if (!json.consent_elements) {
    json.consent_elements = generateConsentElements()
  }

  Object.keys(json.consent_elements).forEach(elementKey => {
    if (typeof json.consent_elements[elementKey] === 'object') {
      json.consent_elements[elementKey] = ''
    }
    _editorStates[elementKey] = HTMLToEditorStatePure(json.consent_elements[elementKey])
  })

  return {
    formattedJSON: json,
    editorStates: _editorStates,
  }
}
/**
 *
 * @param {object} _consent
 * @param {string} questionId
 *
 * The following helper functions take a single signature question and parses it
 * into multiple signature questions based on the number signees are in its attributes
 */

const parseSigneesIntoIndividualQuestions = ({ _consent, questionId, includedSignatureTypes = {} }) => {
  const { order, questions } = _consent
  const question = questions[questionId]
  const { attributes } = question
  const { signeeOrder, signees } = attributes
  const parentSignatureIndex = _consent.order[0].findIndex(el => el === questionId)
  if (signeeOrder) {
    for (let i = 0; i < signeeOrder.length; i++) {
      const signeeId = signeeOrder[i]
      const signee = signees[signeeId]
      const { type, required } = signee
      const newQuestion = {
        ...question,
        attributes: getDefaultConsentSignatureAttr({ user_type: type, required }),
        signature_parent_id: questionId,
        parent_signature_index: parentSignatureIndex,
        signee_order_idx: i,
      }
      if (type === 'clinro') {
        checkSigneeType('clinro', includedSignatureTypes)
        newQuestion.attributes.ignore = { mobile: true }
        delete newQuestion.camera_tip
      } else if (type === 'participant') {
        checkSigneeType('participant', includedSignatureTypes)
        delete newQuestion.clinro_camera_tip
      } else if (type === 'caregiver') {
        checkSigneeType('caregiver', includedSignatureTypes)
        delete newQuestion.clinro_camera_tip
        newQuestion.attributes.custom_name_enter = true
      } else if (type === 'witness') {
        checkSigneeType('witness', includedSignatureTypes)
        newQuestion.attributes.ignore = { mobile: true }
      }
      _consent.questions[signeeId] = newQuestion
    }
    const newQuestionOrder = [
      ...order[0].slice(0, parentSignatureIndex),
      ...signeeOrder,
      ...order[0].slice(parentSignatureIndex + 1),
    ]
    delete _consent.questions[questionId]
    _consent.order = [newQuestionOrder]
  }
}

const signeeTypeSortDict = {
  participant: 0,
  caregiver: 1,
  clinro: 2,
  witness: 3,
}

/**
 * This function adds signee types to a checklist to indicate what signature types
 * are in the consent. This will be used to generate the metadata user_type_deployment_order,
 * which helps the system determine the order to deploy consent forms.
 */
const checkSigneeType = (signeeType, includedSignatureTypes = {}) => {
  includedSignatureTypes[signeeType] = {}
}

function cleanUpAndFormatConsent(_consent) {
  // Remove unnecessary fields
  delete _consent.activeItemId
  const { questions } = _consent
  const includedSignatureTypes = {}
  for (const qId in questions) {
    const question = questions[qId]
    delete question.attributes.canHaveLogic
    delete question.attributes.questionNumber
    if (question.type === 'signature') {
      if (question.attributes.signees) {
        parseSigneesIntoIndividualQuestions({ _consent, questionId: qId, includedSignatureTypes })
      } else {
        question.attributes = getDefaultConsentSignatureAttr()
        checkSigneeType('participant', includedSignatureTypes)
      }
    }
  }
  const userTypeDeploymentOrder = Object.keys(includedSignatureTypes).sort(
    (a, d) => signeeTypeSortDict[a] - signeeTypeSortDict[d],
  )
  _consent.metadata.user_type_deployment_order = userTypeDeploymentOrder
}

const deleteTranslationsFromChoices = ({ choices, choicesOrder, otherLanguages = [] }) => {
  for (let i = 0; i < choicesOrder.length; i++) {
    const choiceId = choicesOrder[i]
    const choice = choices[choiceId]
    const { label_translations } = choice
    if (label_translations) {
      for (const language in label_translations) {
        if (!otherLanguages.includes(language)) {
          delete choice.label_translations[language]
        }
      }
      if (Object.keys(label_translations).length === 0) {
        delete choice.label_translations
      }
    }
  }
}

function formatLabelsAndTranslations({
  questions,
  editorStates: _editorStates,
  otherLanguages = [],
  consentElements = {},
}) {
  for (const key in questions) {
    const question = questions[key]
    if (question.type !== QUESTION_TYPE_MAP.signature) {
      question.label = EditorStateToHTML(_editorStates[key])
      const { label_translations } = question
      if (label_translations) {
        for (const language in label_translations) {
          if (otherLanguages.includes(language)) {
            if (label_translations[language]) {
              question.label_translations[language] = EditorStateToHTML(_editorStates[`${key}_${language}`])
            }
          } else {
            delete question.label_translations[language]
          }
        }
        if (question.type === QUESTION_TYPE_MAP.selectOne) {
          const { choices, choices_order: choicesOrder } = question
          deleteTranslationsFromChoices({ choices, choicesOrder, otherLanguages })
        }
        if (Object.keys(label_translations).length === 0) {
          delete question.label_translations
        }
      }
    }
    if (question.type === QUESTION_TYPE_MAP.pdf) {
      const { url_translations } = question
      if (url_translations) {
        for (const language in url_translations) {
          if (!otherLanguages.includes(language)) {
            delete question.url_translations[language]
          }
        }
      }
    }
  }
  for (const sectionKey in consentElements) {
    consentElements[sectionKey] = EditorStateToHTML(_editorStates[sectionKey])
  }
}

function countWordsInConsent(_consent) {
  return _consent.order[0].reduce((sum, itemId) => {
    return sum + countWords(_consent.questions[itemId]?.label)
  }, 0)
}

function countWords(string) {
  if (string === '') return 0
  return string.trim().split(' ').length
}

export function calcQuestionNumbers(order, questions) {
  let count = 0
  order[0].forEach(id => {
    if (!QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS.includes(questions[id].type)) {
      count++
      questions[id].attributes.questionNumber = count
      questions[id].attributes.canHaveLogic = count > 1
    } else {
      questions[id].attributes.canHaveLogic = count >= 1
    }
  })
}

function dupStateAndItem(state, itemId) {
  const newState = { ...state }
  newState.questions = { ...newState.questions }
  newState.questions[itemId] = { ...newState.questions[itemId] }
  return newState
}

function dupStateAndElement(state, elementKey) {
  const newState = { ...state }
  newState.consent_elements = { ...newState.consent_elements }
  newState.consent_elements[elementKey] = { ...newState.consent_elements[elementKey] }
  return newState
}

function getCondition(terms, conditionPath) {
  if (conditionPath.length === 1) {
    return terms[conditionPath[0]]
  }
  return getCondition(terms[conditionPath[0]].terms, conditionPath.slice(1))
}

function setCondition(terms, conditionPath, newValue) {
  if (conditionPath.length === 1) {
    terms[conditionPath[0]] = newValue
  } else {
    setCondition(terms[conditionPath[0]].terms, conditionPath.slice(1), newValue)
  }
}

function addCondition(terms, groupPath) {
  if (groupPath.length === 1) {
    terms[groupPath[0]].terms.push({
      question_type: '',
      question_id: '',
      value: '',
      comparator: '',
    })
  } else {
    addCondition(terms[groupPath[0]].terms, groupPath.slice(1))
  }
}

function deleteCondition(showIf, conditionPath) {
  if (conditionPath.length === 1) {
    showIf.terms.splice(conditionPath[0], 1)
  } else {
    deleteCondition(showIf.terms[conditionPath[0]], conditionPath.slice(1))
    if (showIf.terms[conditionPath[0]].terms.length === 0) {
      showIf.terms.splice(conditionPath[0], 1)
    }
  }
}

function removeLoneGroup(logic) {
  if (logic.show_if.terms.length < 1) {
    delete logic.show_if
  } else if (logic.show_if.terms[0].comparator === 'boolean_combine' && logic.show_if.terms.length === 1) {
    logic.show_if.terms = logic.show_if.terms[0].terms
  }
}

function setOperator(logic, groupPath, value) {
  if (groupPath.length === 0) {
    logic.operator = value
  } else {
    setOperator(logic.terms[groupPath[0]], groupPath.slice(1), value)
  }
}

const formatVersionHistory = versionHistoryArr => {
  if (!versionHistoryArr) return []
  const _versionHistoryArr = versionHistoryArr.map(({ title, consent_type, consent_version, deployed }) => {
    return [
      { key: 'consentName', value: title, consentVersion: consent_version },
      { key: 'type', value: consent_type },
      { key: 'version', value: consent_version, sortValue: consent_version },
      { key: 'lastSent', value: moment(deployed).format(DATE_FORMAT_MAP.mainWithDateTime) },
    ]
  })
  return _versionHistoryArr
}

const formatParticipants = ptpsArray => {
  if (!ptpsArray) return []
  const _ptpsArray = ptpsArray.map(({ id, name, site_id, track_id, deployed, status, completed }) => {
    return [
      { key: 'checkbox', value: id },
      { key: 'participant', value: name, sortValue: name },
      { key: 'trackId', value: track_id, sortValue: track_id },
      { key: 'siteId', value: site_id, sortValue: site_id },
      {
        key: 'completionStatus',
        value: status,
        received: completed ? moment(completed).format(DATE_FORMAT_MAP.main) : completed,
        status,
        deployedDate: moment(deployed).format(DATE_FORMAT_MAP.main),
        sortValue: status,
      },
    ]
  })
  return _ptpsArray
}

//
// Reducers
//

export function consent(state = generateBlankConsent(), action) {
  switch (action.type) {
    case INITIALIZE_NEW_BLANK_CONSENT: {
      return generateBlankConsent(action.title)
    }
    case INITIALIZE_NEW_CONSENT_EDIT: {
      return action.json
    }
    case INIT_FORMATTING: {
      const newState = { ...state }
      if (!newState.consent_elements) {
        newState.consent_elements = generateConsentElements()
      }
      return newState
    }
    case UPDATE_NEW_CONSENT_TYPE: {
      const newState = { ...state }
      newState.consent_type = action.consent_type
      if (action.consent_type !== CONSENT_TYPE_MAP.addendum) delete newState.metadata.addendum_for
      return newState
    }
    case UPDATE_NEW_CONSENT_NAME: {
      return { ...state, consent_title: action.value }
    }
    case UPDATE_NEW_CONSENT_PDF_UPLOAD: {
      return { ...state, pdf_upload: action.value }
    }
    case UPDATE_NEW_DISPLAY_NAME: {
      return { ...state, metadata: { ...state.metadata, display_name: action.value } }
    }
    case UPDATE_ADDENDUM_CONSENT: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          addendum_for: {
            consent_id: action.consentId,
            consent_title: action.consentTitle,
          },
        },
      }
    }
    case UPDATE_NEW_CONSENT_TITLE: {
      return { ...state, consent_title: action.title }
    }
    case TOGGLE_NEW_CONSENT_EDIT: {
      const newState = { ...state }
      newState.activeItemId = action.itemId
      return newState
    }
    case UPDATE_NEW_CONSENT_ITEM: {
      const newState = dupStateAndItem(state, action.itemId)
      Object.assign(newState.questions[action.itemId], action.item)
      return newState
    }
    case UPDATE_NEW_CONSENT_ELEMENT: {
      const newState = dupStateAndElement(state, action.elementKey)
      newState.consent_elements[action.elementKey] = stateToHTML(action.newEditorState.getCurrentContent())
      return newState
    }
    case UPDATE_NEW_CONSENT_ITEM_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].label = stateToHTML(action.newEditorState.getCurrentContent())
      return newState
    }
    case CHANGE_NEW_CONSENT_ITEM_TYPE: {
      const newState = dupStateAndItem(state, action.itemId)
      const question = newState.questions[action.itemId]
      question.type = action.nextType
      if (
        [QUESTION_TYPE_MAP.selectOne, QUESTION_TYPE_MAP.selectMultiple, QUESTION_TYPE_MAP.likert].includes(
          action.nextType,
        ) &&
        question.choices_order.length === 0
      ) {
        const newChoiceId = action.itemId + getRandomQuestionId()
        question.choices[newChoiceId] = { label: '' }
        question.choices_order.push(newChoiceId)
      }

      if (action.nextType === QUESTION_TYPE_MAP.likert) {
        delete question.attributes.other
        while (question.choices_order.length < 5) {
          const newChoiceId = action.itemId + getRandomQuestionId()
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
      }

      if (action.nextType === QUESTION_TYPE_MAP.vaScale) {
        question.scale_labels = {
          top: '',
          bottom: '',
        }
      }
      if (action.nextType === QUESTION_TYPE_MAP.vasHorizontal) {
        question.scale_labels = {
          top_value: '100',
          top: '',
          mid_value: '50',
          mid: '',
          bottom_value: '0',
          bottom: '',
        }
        question.hint = ''
        question.attributes.unit = '%'
      }

      if (
        ![QUESTION_TYPE_MAP.selectOne, QUESTION_TYPE_MAP.selectMultiple, QUESTION_TYPE_MAP.likert].includes(
          question.type,
        )
      ) {
        question.choices = {}
        question.choices_order = []
      }

      if (
        [QUESTION_TYPE_MAP.integer, QUESTION_TYPE_MAP.decimal].includes(action.prevType) &&
        ![QUESTION_TYPE_MAP.integer, QUESTION_TYPE_MAP.decimal].includes(action.nextType)
      ) {
        delete question.attributes.unit
      }

      if (action.nextType === QUESTION_TYPE_MAP.signature) {
        question.attributes = { required: true, ...getDefaultSignees(action.itemId, action.isClinro) }
      } else {
        question.attributes = { required: true }
        delete question.camera_tip
        delete question.clinro_camera_tip
      }
      calcQuestionNumbers(newState.order, newState.questions)
      return newState
    }
    case UPDATE_NEW_CONSENT_UNIT_TYPE: {
      const newState = dupStateAndItem(state, action.itemId)
      if (!action.unit) {
        delete newState.questions[action.itemId].attributes.unit
      } else {
        newState.questions[action.itemId].attributes.unit = action.unit
      }
      return newState
    }
    case TOGGLE_REQUIRED_NEW_CONSENT_QUESTION: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.required = !newState.questions[action.itemId].attributes.required
      return newState
    }
    case ADD_NEW_CONSENT_ITEM: {
      const newState = { ...state }
      if (action.index === undefined) {
        newState.order[0].push(action.newQId)
      } else {
        newState.order[0].splice(action.index + 1, 0, action.newQId)
      }
      newState.questions[action.newQId] = {
        type: action.itemType,
        label: '',
        choices: {},
        choices_order: [],
        attributes: { required: true },
        logic: {},
      }
      if (action.itemType === QUESTION_TYPE_MAP.signature) {
        newState.questions[action.newQId].attributes = {
          required: true,
          ...getDefaultSignees(action.newQId, action.isClinro),
        }
      }
      if (action.itemType === QUESTION_TYPE_MAP.selectOne) {
        const question = newState.questions[action.newQId]
        question.choices[action.choiceOneId] = { label: '' }
        question.choices_order.push(action.choiceOneId)
        question.choices[action.choiceTwoId] = { label: '' }
        question.choices_order.push(action.choiceTwoId)
      }
      calcQuestionNumbers(newState.order, newState.questions)
      newState.activeItemId = action.newQId
      return newState
    }
    case DELETE_NEW_CONSENT_ITEM: {
      const newState = { ...state }
      delete newState.questions[action.itemId]
      newState.order[0].splice(newState.order[0].indexOf(action.itemId), 1)
      calcQuestionNumbers(newState.order, newState.questions)
      if (action.itemId === newState.activeItemId) {
        newState.activeItemId = null
      }
      return newState
    }
    case MOVE_NEW_CONSENT_ITEM: {
      const newState = { ...state }
      newState.order = newState.order.slice()
      newState.questions = { ...newState.questions }
      newState.order[0].splice(action.endIdx, 0, newState.order[0].splice(action.startIdx, 1)[0])
      calcQuestionNumbers(newState.order, newState.questions)
      return newState
    }
    case DUPLICATE_NEW_CONSENT_ITEM: {
      const newState = { ...state }
      newState.order = newState.order.slice()
      newState.questions = { ...newState.questions }
      newState.questions[action.newQId] = JSON.parse(JSON.stringify(newState.questions[action.itemId]))
      const newIndex = newState.order[0].indexOf(action.itemId) + 1
      newState.order[0].splice(newIndex, 0, action.newQId)
      calcQuestionNumbers(newState.order, newState.questions)
      if (action.hasChoices) {
        const duplicateQuestion = newState.questions[action.newQId]
        const newChoicesOrder = action.choicesOrder.map(choiceId => action.newChoicesIdMap[choiceId])
        duplicateQuestion.choices_order = newChoicesOrder
        action.choicesOrder.forEach(choiceId => {
          const newChoiceId = action.newChoicesIdMap[choiceId]
          duplicateQuestion.choices[newChoiceId] = JSON.parse(JSON.stringify(duplicateQuestion.choices[choiceId]))
          delete duplicateQuestion.choices[choiceId]
        })
      }
      if (action.qType === QUESTION_TYPE_MAP.signature) {
        /**
         * If we are duplicating a signature question, we need to create new signee IDs and
         * signee order for the duplicated question similar to what we do for choices.
         */
        const { attributes } = newState.questions[action.newQId]
        const { signeeOrder, signees } = attributes
        const newSigneeOrder = []
        const oldSigneeOrder = [...signeeOrder]
        oldSigneeOrder.forEach(signeeId => {
          const newSigneeId = `${action.newQId}_${getRandomQuestionId()}`
          newSigneeOrder.push(newSigneeId)
          signees[newSigneeId] = JSON.parse(JSON.stringify(signees[signeeId]))
          delete signees[signeeId]
        })
        attributes.signeeOrder = newSigneeOrder
      }
      return newState
    }
    case ADD_NEW_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
      newState.questions[action.itemId].choices[newChoiceId] = { label: '' }
      if (action.insertIdx) {
        newState.questions[action.itemId].choices_order.splice(action.insertIdx, 0, newChoiceId)
      } else {
        newState.questions[action.itemId].choices_order.push(newChoiceId)
      }
      return newState
    }
    case ADD_NEW_CONSENT_CHOICE_ON_PASTE: {
      if (action.pasteText === '') return state
      const newState = dupStateAndItem(state, action.itemId)
      action.pasteText.split('\n').forEach(line => {
        const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
        newState.questions[action.itemId].choices[newChoiceId] = {
          label: line,
        }
        newState.questions[action.itemId].choices_order.push(newChoiceId)
      })
      return newState
    }
    case ADD_OTHER_NEW_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.other = 'Other'
      return newState
    }
    case DELETE_OTHER_NEW_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].attributes.other
      return newState
    }
    case UPDATE_OTHER_NEW_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.other = action.otherValue
      return newState
    }
    case DELETE_NEW_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].choices[action.choiceId]
      newState.questions[action.itemId].choices_order.splice(
        newState.questions[action.itemId].choices_order.indexOf(action.choiceId),
        1,
      )
      return newState
    }
    case UPDATE_NEW_CONSENT_CHOICE_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId].label = action.newLabel
      return newState
    }
    case MOVE_NEW_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices_order.splice(
        action.endIdx,
        0,
        newState.questions[action.itemId].choices_order.splice(action.startIdx, 1)[0],
      )
      return newState
    }
    case UPDATE_NEW_CONSENT_LOGIC_CONDITION: {
      const newState = dupStateAndItem(state, action.itemId)
      const newCondition = Object.assign(
        getCondition(newState.questions[action.itemId].logic.show_if.terms, action.conditionPath),
        { [action.field]: action.value },
      )
      if (action.field === 'question_id') {
        newCondition.question_type = newState.questions[action.value].type
        newCondition.comparator = ''
        newCondition.value = ''
      } else if (action.field === 'comparator' && action.value.includes('answered')) {
        newCondition.value = ''
      }
      setCondition(newState.questions[action.itemId].logic.show_if.terms, action.conditionPath, newCondition)
      return newState
    }
    case ADD_NEW_CONSENT_LOGIC_CONDITION: {
      const newState = dupStateAndItem(state, action.itemId)
      const blankCondition = {
        question_type: '',
        question_id: '',
        value: '',
        comparator: '',
      }
      if (action.groupPath) {
        addCondition(newState.questions[action.itemId].logic.show_if.terms, action.groupPath)
        return newState
      }
      if (newState.questions[action.itemId].logic.show_if) {
        newState.questions[action.itemId].logic.show_if.terms.push(blankCondition)
      } else {
        newState.questions[action.itemId].logic.show_if = {
          comparator: 'boolean_combine',
          operator: 'and',
          terms: [blankCondition],
        }
      }
      return newState
    }
    case ADD_NEW_CONSENT_LOGIC_GROUP: {
      const newState = dupStateAndItem(state, action.itemId)
      const blankGroup = {
        comparator: 'boolean_combine',
        operator: 'and',
        terms: [
          {
            question_type: '',
            question_id: '',
            value: '',
            comparator: '',
          },
        ],
      }
      if (newState.questions[action.itemId].logic.show_if.terms[0].comparator !== 'boolean_combine') {
        newState.questions[action.itemId].logic.show_if.terms = [
          {
            comparator: 'boolean_combine',
            operator: 'and',
            terms: newState.questions[action.itemId].logic.show_if.terms,
          },
          blankGroup,
        ]
      } else {
        newState.questions[action.itemId].logic.show_if.terms.push(blankGroup)
      }
      return newState
    }
    case DELETE_NEW_CONSENT_LOGIC_CONDITION: {
      const nextState = dupStateAndItem(state, action.itemId)
      deleteCondition(nextState.questions[action.itemId].logic.show_if, action.conditionPath)
      removeLoneGroup(nextState.questions[action.itemId].logic)
      return nextState
    }
    case DELETE_ALL_NEW_CONSENT_LOGIC: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].logic.show_if
      return newState
    }
    case CHANGE_NEW_CONSENT_LOGIC_OPERATOR: {
      const newState = dupStateAndItem(state, action.itemId)
      setOperator(newState.questions[action.itemId].logic.show_if, action.groupPath, action.value)
      return newState
    }
    case DELETE_INVALID_NEW_CONSENT_LOGIC: {
      const nextState = { ...state }
      for (const itemId in action.invalidConditions) {
        nextState.questions[itemId] = { ...nextState.questions[itemId] }
        action.invalidConditions[itemId].sort((a, b) => {
          return b[0] - a[0] || b[1] - a[1]
        })
        action.invalidConditions[itemId].forEach(path => {
          deleteCondition(nextState.questions[itemId].logic.show_if, path)
        })
        removeLoneGroup(nextState.questions[itemId].logic)
      }
      return nextState
    }
    case UPDATE_NEW_CONSENT_SIGNEE: {
      const nextState = { ...state }
      nextState.questions[action.itemId].attributes.signees[action.signeeId] = action.signee
      return nextState
    }
    case DELETE_NEW_CONSENT_SIGNEE: {
      const nextState = { ...state }
      const questionAttributes = nextState.questions[action.itemId].attributes
      delete questionAttributes.signees[action.signeeId]
      questionAttributes.signeeOrder = questionAttributes.signeeOrder.filter(signeeId => signeeId !== action.signeeId)
      return nextState
    }
    case ADD_NEW_CONSENT_SIGNEE: {
      const nextState = { ...state }
      const newSigneeId = `${action.itemId}_${getRandomQuestionId()}`
      const questionAttributes = nextState.questions[action.itemId].attributes
      const { signees, signeeOrder } = questionAttributes

      if (signees) signees[newSigneeId] = getDefaultSignee()
      else questionAttributes.signees = { [newSigneeId]: getDefaultSignee() }

      if (signeeOrder) questionAttributes.signeeOrder.push(newSigneeId)
      else questionAttributes.signeeOrder = [newSigneeId]

      return nextState
    }
    case MOVE_NEW_CONSENT_SIGNEE: {
      const newState = { ...state }
      newState.questions[action.itemId].attributes.signeeOrder.splice(
        action.newIndex,
        0,
        newState.questions[action.itemId].attributes.signeeOrder.splice(action.oldIndex, 1)[0],
      )
      return newState
    }
    case ADD_NEW_CONSENT_TRANSLATION: {
      const newState = { ...state }
      const { other_languages } = newState.metadata
      if (other_languages) {
        other_languages.push(action.langKey)
      } else {
        newState.metadata.other_languages = [action.langKey]
      }
      return newState
    }
    case DELETE_NEW_CONSENT_TRANSLATION: {
      const newState = { ...state }
      const { other_languages } = newState.metadata
      if (other_languages) {
        newState.metadata.other_languages = other_languages.filter(lang => lang !== action.langKey)
      }
      if (newState.metadata.other_languages.length === 0) delete newState.metadata.other_languages
      return newState
    }
    case CLEAR_ALL_NEW_CONSENT_TRANSLATIONS: {
      const newState = { ...state }
      delete newState.metadata.other_languages
      return newState
    }
    case UPDATE_NEW_CONSENT_CHOICE_LABEL_TRANSLATION: {
      const newState = { ...state }
      const choice = newState.questions[action.itemId].choices[action.choiceId]
      const { label_translations } = choice

      if (label_translations) {
        label_translations[action.language] = action.newTranslation
      } else {
        choice.label_translations = {
          [action.language]: action.newTranslation,
        }
      }
      return newState
    }
    case UPDATE_NEW_CONSENT_ITEM_LABEL_TRANSLATION: {
      const newState = { ...state }
      const { itemId, newEditorState, language } = action
      const question = newState.questions[itemId]
      const { label_translations } = question
      const newLabel = stateToHTML(newEditorState.getCurrentContent())

      if (label_translations) {
        label_translations[language] = newLabel
      } else {
        question.label_translations = {
          [language]: newLabel,
        }
      }
      return newState
    }
    case RESET_SCHEDULE: {
      const newState = { ...state }
      newState.metadata.schedule = _getDefaultSchedule()
      return newState
    }
    case UPDATE_SCHEDULE: {
      const newState = { ...state }
      if (action.obj === null) {
        delete newState.metadata.schedule[action.key]
      } else {
        newState.metadata.schedule[action.key] = action.obj
      }
      return newState
    }
    case UPDATE_SCHEDULE_FIELD: {
      const newState = { ...state }
      newState.metadata.schedule = { ...newState.metadata.schedule }
      newState.metadata.schedule[action.key] = { ...state.metadata.schedule[action.key] }
      newState.metadata.schedule[action.key][action.field] = action.value
      return newState
    }
    case UPDATE_NEW_CONSENT_KEY: {
      const newState = { ...state }
      newState[action.key] = action.value
      return newState
    }
    default: {
      return state
    }
  }
}

export function consentErrors(state = { questions: {} }, action) {
  let newState
  switch (action.type) {
    case INITIALIZE_NEW_CONSENT_EDIT:
    case INITIALIZE_NEW_BLANK_CONSENT:
      return { questions: {} }
    case ADD_CONSENT_ERRORS:
      return action.errors
    case CLEAR_CONSENT_ERROR:
      newState = { ...state }
      delete newState[action.key]
      return newState
    case CLEAR_CONSENT_QUESTION_ERROR:
    case CHANGE_NEW_CONSENT_ITEM_TYPE:
      newState = { ...state }
      newState.questions = { ...newState.questions }
      delete newState.questions[action.itemId]
      return newState
    default:
      return state
  }
}

export function editorStates(state = {}, action) {
  switch (action.type) {
    case INITIALIZE_NEW_CONSENT_EDIT: {
      return action.editorStates
    }
    case ADD_NEW_CONSENT_ITEM: {
      const newState = { ...state }
      newState[action.newQId] = EditorState.createEmpty()
      return newState
    }
    case DUPLICATE_NEW_CONSENT_ITEM: {
      const newState = { ...state }
      if (action.qType === QUESTION_TYPE_MAP.signature) return newState
      newState[action.newQId] = EditorState.createWithContent(action.editorState.getCurrentContent())
      return newState
    }
    case UPDATE_NEW_CONSENT_ELEMENT: {
      const newState = { ...state }
      newState[action.elementKey] = action.newEditorState
      return newState
    }
    case UPDATE_NEW_CONSENT_ITEM_LABEL: {
      const newState = { ...state }
      newState[action.itemId] = action.newEditorState
      return newState
    }
    case UPDATE_NEW_CONSENT_ITEM_LABEL_TRANSLATION: {
      const newState = { ...state }
      const { itemId, newEditorState, language } = action
      const _newEditorStateId = `${itemId}_${language}`
      newState[_newEditorStateId] = newEditorState

      return newState
    }
    default: {
      return state
    }
  }
}

function originalConsent(state = {}, action) {
  if (action.type === INITIALIZE_NEW_CONSENT_EDIT || action.type === UPDATE_ORIGINAL_NEW_CONSENT) {
    return JSON.parse(JSON.stringify(action.json))
  }
  return state
}

function wordCount(state = 0, action) {
  switch (action.type) {
    case INITIALIZE_NEW_BLANK_CONSENT: {
      return 0
    }
    case INITIALIZE_NEW_CONSENT_EDIT: {
      if (!action.isMetadataOnly) return countWordsInConsent(action.json)
      return 0
    }
    case UPDATE_NEW_CONSENT_ITEM_LABEL: {
      const oldEditorState = action.oldEditorState || EditorState.createEmpty()
      return (
        state +
        (countWords(action.newEditorState.getCurrentContent().getPlainText()) -
          countWords(oldEditorState.getCurrentContent().getPlainText()))
      )
    }
    default: {
      return state
    }
  }
}

function versionHistoryList(state = [], action) {
  switch (action.type) {
    case INITIALIZE_NEW_CONSENT_EDIT:
      return formatVersionHistory(action.json?.version_history)
    case INITIALIZE_NEW_BLANK_CONSENT:
      return []
    default:
      return state
  }
}

export function participantsList(state = [], action) {
  switch (action.type) {
    case SET_CONSENT_PARTICIPANTS_STATUS:
      return formatParticipants(action.value)
    default:
      return state
  }
}

const siteRolesStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_SITE_ROLES_STATUS: {
      return action.status
    }
    case RESET_SITE_ROLES_STATUS: {
      return {}
    }
    default:
      return state
  }
}

const instrumentAdvancedOptions = (state = {}, action) => {
  switch (action.type) {
    case SET_ADVANCED_OPTIONS:
      return action.value
    case RESET_ADVANCED_OPTIONS:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  consent,
  editorStates,
  consentErrors,
  wordCount,
  originalConsent,
  participantsList,
  versionHistoryList,
  siteRolesStatus,
  instrumentAdvancedOptions,
})

export const consentActions = {
  // uploadTranslation,
  addConsentTranslation,
  addItem,
  clearAllConsentTranslations,
  closeModal: modalActions.closeModal,
  deleteChoice: checkLogicAndDeleteChoice,
  deleteConsentTranslation,
  deleteItem: checkLogicAndDeleteItem,
  deployConsent: toggleDeployConfirm,
  downloadConsentPreview,
  downloadConsentTranslation,
  downloadParticipantConsent,
  fetchNewConsent,
  initializeBlankConsent,
  moveItem: checkLogicAndMoveItem,
  openModal: modalActions.openModal,
  resetConsentDashboard,
  toggleEdit,
  updateAddendumConsent,
  updateConsentKey,
  updateConsentName,
  updateConsentPdfUpload,
  updateConsentType,
  updateConsentElement,
  updateDisplayName,
  updateTitle,
  setAdvancedOptionsToStore,
  resetAdvancedOptions,
}

export const itemActions = {
  addChoiceOnPaste,
  addItem,
  duplicateItem,
  updateItem,
  updateItemLabel,
  updateItemLabelTranslation,
  addSignee,
  updateSignee,
  deleteSignee,
  moveSignee,
  checkRolesForSites,
  setSiteRolesStatus,
  resetSiteRolesStatus,
}

export const itemEditorActions = {
  updateUnitType,
  addChoice,
  addOtherChoice,
  toggleRequiredQuestion: checkLogicAndToggleRequired,
  changeItemType: checkLogicAndChangeItemType,
  deleteChoice: checkLogicAndDeleteChoice,
  moveItem: checkLogicAndMoveItem,
}

export const selectViewActions = {
  addChoice,
  moveChoice,
  updateChoiceLabel,
  updateChoiceLabelTranslation,
  deleteOtherChoice,
  updateOtherChoice,
  deleteChoice: checkLogicAndDeleteChoice,
}

export const logicActions = {
  updateLogicCondition,
  addLogicCondition,
  deleteLogicCondition,
  deleteAllLogic,
  changeLogicOperator,
  addLogicGroup,
}

export const uploadActions = {
  uploadConsentPdf,
}
