import React from 'react'
import PropTypes from 'prop-types'
import RCTimePicker from 'rc-time-picker'
import 'styles/d3/timepicker.scss'
import 'rc-time-picker/assets/index.css'
import moment from 'moment'
import { isCurrentHour, timeOptionsArr } from 'utils/time'

const TimePicker = props => {
  const {
    disabled,
    disablePriorToTime,
    disablePriorFromTime,
    hasError,
    onChange,
    placeholder,
    preventPastTimes,
    value,
    className,
  } = props

  const disablePastHours = () => {
    const hourOptions = timeOptionsArr(false) // returns an array with range [0,23]
    const currentHour = moment().hour()
    return hourOptions.filter(hour => hour < currentHour)
  }

  const disablePastMinutes = () => {
    const minuteOptions = timeOptionsArr()
    const currentMinute = moment().minute()
    if (isCurrentHour(value)) {
      const blockedOptions = minuteOptions.filter(min => min <= currentMinute)
      return blockedOptions
    }
    return []
  }

  const returnDisabledHours = () => {
    if (preventPastTimes) {
      return disablePastHours()
    }
    if (disablePriorToTime) {
      const hour = Number(disablePriorToTime.format('HH'))
      const hourOptions = timeOptionsArr(false)
      return hourOptions.filter(hourOption => hourOption < hour)
    }
    if (disablePriorFromTime) {
      const hour = Number(disablePriorFromTime.format('HH'))
      const hourOptions = timeOptionsArr(false)
      return hourOptions.filter(hourOption => hourOption > hour)
    }
    return []
  }

  const returnDisabledMinutes = () => {
    if (preventPastTimes) {
      return disablePastMinutes()
    }
    if (disablePriorToTime) {
      const hour = Number(disablePriorToTime.format('HH'))
      if (value) {
        const selectedHour = Number(value.format('HH'))
        if (hour === selectedHour) {
          const minute = Number(disablePriorToTime.format('mm'))
          const minuteOptions = timeOptionsArr()
          return minuteOptions.filter(minuteOption => minuteOption < minute)
        }
      }
    }
    if (disablePriorFromTime) {
      const hour = Number(disablePriorFromTime.format('HH'))
      if (value) {
        const selectedHour = Number(value.format('HH'))
        if (hour === selectedHour) {
          const minute = Number(disablePriorFromTime.format('mm'))
          const minuteOptions = timeOptionsArr()
          return minuteOptions.filter(minuteOption => minuteOption > minute)
        }
      }
    }
    return []
  }

  return (
    <RCTimePicker
      {...props}
      disabledHours={returnDisabledHours}
      disabledMinutes={returnDisabledMinutes}
      position='bottom'
      className={`d3-time-picker${hasError ? ' has-error' : ''}${disabled ? ' disabled' : ''} ${className}`}
      format='H:mm'
      placeholder={placeholder || 'hh:mm'}
      showSecond={false}
      value={value || null}
      onChange={mom => onChange(mom)}
    />
  )
}

TimePicker.propTypes = {
  disabled: PropTypes.bool,
  disablePriorToTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  preventPastTimes: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default TimePicker
