import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import useDidMountEffect from 'utils/hooks/didMountEffect'
import { Button } from 'components/UIElements'
import { STUDY_CONFIG_MAP, REWARD_TYPE_MAP } from 'utils/constants'
import { calculateCycles, generateCycles, generateDynamicCycles } from '../utils/studyUtils'
import SortableGiftCardList from './TreasureChestRewards/SortableGiftCardList'

const changeExpandState = (arr, value) => arr.map(_ => value)

const GIFT_CARD_BODY_TEXT =
  'Shown below are the new rewards that are unlocked in each chapter. Rewards from previous chapters will still be available for purchase in the following chapters. '

const arrayMove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

const RewardSelectionConfig = props => {
  const { configObj, disabled, updateConfig } = props
  const { super_gems = {}, cycles, has_dynamic_cycles } = configObj
  const { treasure_chest_gift_card_config = {} } = super_gems
  const giftCardConfig = treasure_chest_gift_card_config
  const maxAmount = giftCardConfig?.max ? giftCardConfig.max : 0
  const cyclesNum = has_dynamic_cycles ? cycles.length : calculateCycles(cycles[0])
  const rewardsByCycleObj = treasure_chest_gift_card_config[STUDY_CONFIG_MAP.giftCardsForUIObj]

  const [cardObj, setCardObj] = useState(rewardsByCycleObj)
  const cyclesArr = Object.keys(cardObj)

  const [draggingItem, setDraggingItem] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const [sourceCycle, setSourceCycle] = useState(null)
  const [targetCycle, setTargetCycle] = useState(null)
  const refArray = useRef([])

  const [isExpand, setIsExpand] = useState(changeExpandState(cyclesArr, false))

  const deleteGiftCards = () => {
    const newSuperGems = super_gems
    delete newSuperGems[STUDY_CONFIG_MAP.giftCards]
    delete newSuperGems[STUDY_CONFIG_MAP.treasureChestGiftCardConfig][STUDY_CONFIG_MAP.giftCardsForUIObj]
    updateConfig({ [STUDY_CONFIG_MAP.superGems]: newSuperGems })
  }

  useDidMountEffect(() => {
    deleteGiftCards()
  }, [maxAmount, cyclesNum])

  const handleCycleMouseEnter = cycle => {
    if (isDragging === true) {
      setTargetCycle(cycle)
    } else {
      setSourceCycle(cycle)
      setTargetCycle(cycle)
    }
  }

  const handleDragStart = e => {
    setIsDragging(true)
    setDraggingItem(cardObj[sourceCycle][e.index])
  }

  const handleDragEnd = e => {
    const { oldIndex, newIndex } = e
    if (sourceCycle === targetCycle) {
      cardObj[sourceCycle] = arrayMove(cardObj[sourceCycle], oldIndex, newIndex)
    } else {
      // update giftCard's cycle_availability field
      const cycleNum = parseInt(targetCycle, 10)
      draggingItem.cycle_availability = cycleNum
      // update gift card obj for UI
      updateGiftCardForUIObj({ giftCard: draggingItem, sourceCycle, targetCycle, oldIndex })
      // update cycle_availability on redux
      updateCycleAvailability(draggingItem.id, cycleNum)
    }

    setIsDragging(false)
    setDraggingItem(null)
    setSourceCycle(targetCycle)
  }

  const shouldCancelStart = e => {
    return e.target.className !== 'fas fa-ellipsis-v'
  }

  const updateGiftCardForUIObj = ({ giftCard, sourceCycle, targetCycle, oldIndex }) => {
    cardObj[targetCycle].push(giftCard)
    cardObj[sourceCycle].splice(oldIndex, 1)
  }

  const updateCycleAvailability = (id, cycleDestination) => {
    const newSuperGems = super_gems
    newSuperGems[STUDY_CONFIG_MAP.giftCards].map(giftCard => {
      if (giftCard.id === id) giftCard.cycle_availability = cycleDestination
    })
    updateConfig({ [STUDY_CONFIG_MAP.superGems]: newSuperGems })
  }

  const updateGiftCard = ({ index, key, value, id }) => {
    const newSuperGems = { ...super_gems }
    if (!index) {
      newSuperGems[STUDY_CONFIG_MAP.giftCards].map(giftCard => {
        if (giftCard.id === id) giftCard[key] = value
      })
      if (key === 'cycle_availability') {
        newSuperGems[STUDY_CONFIG_MAP.giftCards].sort((gc1, gc2) => {
          return gc1[key] - gc2[key]
        })
      }
    }
    updateConfig({ [STUDY_CONFIG_MAP.superGems]: newSuperGems })
  }

  /**
   * This information is used for the sub-title content regarding cycle duration
   * and instruments requireed in each cycle's collapse menu header
   */
  const cycleInfoArr = has_dynamic_cycles
    ? generateDynamicCycles(cycles, REWARD_TYPE_MAP.superGems)
    : generateCycles(cycles, REWARD_TYPE_MAP.superGems)

  return (
    <div className='reward-eligibility'>
      <p>
        {GIFT_CARD_BODY_TEXT}
        {!disabled && (
          <Button className='back-gift-card' content='Back to gift card values' link onClick={deleteGiftCards} />
        )}
      </p>
      <div className='reward-selection list-container'>
        <div className='flexed start-justified expand-buttons'>
          <Button
            className='expand-collapse styled collapse noStyling'
            content='Collapse all'
            onClick={() => setIsExpand(changeExpandState(cyclesArr, false))}
            disabled={isExpand.every(s => s === false)}
          />
          <Button
            className='expand-collapse styled noStyling'
            content='Expand all'
            onClick={() => setIsExpand(changeExpandState(cyclesArr, true))}
            disabled={isExpand.every(s => s === true)}
          />
        </div>
        {cyclesArr.map((key, index, cycleArr) => {
          const cycleInfo = cycleInfoArr[index]
          return (
            <div key={key} onMouseEnter={() => handleCycleMouseEnter(key)}>
              <SortableGiftCardList
                cycle={key}
                days={cycleInfo?.duration}
                instruments={cycleInfo?.target}
                disabled={disabled}
                draggingItem={draggingItem}
                isDragging={isDragging}
                isExpand={isExpand[index]}
                items={cardObj[key]}
                lockAxis='y'
                onSortEnd={handleDragEnd}
                onSortStart={handleDragStart}
                ref={ref => {
                  refArray.current[index] = ref
                }}
                shouldCancelStart={shouldCancelStart}
                sourceCycle={sourceCycle}
                targetCycle={targetCycle}
                updateGiftCard={updateGiftCard}
                updateGiftCardForUIObj={updateGiftCardForUIObj}
                cycleArr={cycleArr}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

RewardSelectionConfig.propTypes = {
  configObj: PropTypes.shape({
    super_gems: PropTypes.object,
    cycles: PropTypes.array,
    has_dynamic_cycles: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  updateConfig: PropTypes.func,
}

export default RewardSelectionConfig

RewardSelectionConfig.propTypes = {
  configObj: PropTypes.shape({
    super_gems: PropTypes.object,
    cycles: PropTypes.array,
    has_dynamic_cycles: PropTypes.bool,
  }),
  updateConfig: PropTypes.func,
}
