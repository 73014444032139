import React from 'react'
import { connect } from 'react-redux'
import { modalActions } from 'store/modal'

import DonationInfoForm from '../components/TreasureChestRewards/DonationInfoForm'
import { actions as studyActions } from '../modules/CreateStudy'

const mapState = state => {
  const { currentStudy } = state.study

  return {
    study: currentStudy,
  }
}

const mapDispatch = { ...modalActions, ...studyActions }

export default connect(mapState, mapDispatch)(props => <DonationInfoForm {...props} />)
