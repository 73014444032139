import React from 'react'
import { Dropdown } from 'components/UIElements'
import 'styles/d3/numberinputdropdown.scss'

const NumberInputDropdown = props => {
  const className = 'number-input-dropdown' + (props.className ? ` ${props.className}` : '')
  return (
    <Dropdown
      {...props}
      className={className}
      selected={props.value}
      main={
        <input
          aria-label='number-input-dropdown'
          disabled={props.disabled}
          value={props.value || ''}
          type='number'
          onChange={e => {
            let value = e.target.value !== '' ? parseInt(e.target.value) : e.target.value
            if (!props.allowNegative) {
              value = value < 0 ? 0 : value
            }
            props.onChange(value)
          }}
          onBlur={props.onBlur}
          min={props.min}
        />
      }
      onSelect={item => (props.onSelect ? props.onSelect(item.value) : props.onChange(item.value))}
      options={props.options}
    />
  )
}

export default NumberInputDropdown
