import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import { actions as notyActions } from 'layouts/ErrorBox'
import request, { generateNotyMessage } from 'utils/request'
import { pluralize } from 'utils/misc'
import { nearestNextHour, ensureFutureDate } from 'utils/time'
import getVisitErrors from './VisitValidation'
import { SET_CLINICIANS } from '../../../../Users/routes/Dashboard/modules/Users'
import {
  RESET_PARTICIPANTS,
  FETCHED_VISIT_PARTICIPANTS,
} from '../../../../Participants/routes/ParticipantsPage/modules/Participants'
import { SET_SITES } from '../../../../Sites/routes/SitesPage/modules/Sites'

//
// Actions
//

const INITIALIZE_CREATE_VISITS = 'INITIALIZE_CREATE_VISITS'
const UPDATE_VISIT_FIELD = 'UPDATE_VISIT_FIELD'
const UPDATE_MEETING_FIELD = 'UPDATE_MEETING_FIELD'
const ADD_ADDITIONAL_VISIT = 'ADD_ADDITIONAL_VISIT'
const DELETE_MEETING = 'DELETE_MEETING'
const SET_VISIT_SITE = 'SET_VISIT_SITE'
const SET_VISIT_PTP = 'SET_VISIT_PTP'
const SET_VISIT_USER = 'SET_VISIT_USER'
const SET_VISIT_ERRORS = 'SET_VISIT_ERRORS'
const CLEAR_VISIT_ERRORS = 'CLEAR_VISIT_ERRORS'
const CLEAR_VISIT_TIME_ERRORS = 'CLEAR_VISIT_TIME_ERRORS'

//
// Action Creators
//

const initalizePage = () => dispatch => {
  return dispatch({
    type: INITIALIZE_CREATE_VISITS,
  })
}

const updateField = (field, value) => dispatch => {
  return dispatch({
    type: UPDATE_VISIT_FIELD,
    field,
    value,
  })
}
const updateMeetingField = (meetingIdx, field, value) => dispatch => {
  return dispatch({
    type: UPDATE_MEETING_FIELD,
    idx: meetingIdx,
    field,
    value,
  })
}

const setVisitSite = site => {
  return {
    type: SET_VISIT_SITE,
    site,
  }
}
const setVisitPtp = ({ participantVal, ptpId }) => {
  return {
    type: SET_VISIT_PTP,
    participantVal,
    ptpId,
  }
}
const setVisitUser = ({ userVal, userId }) => {
  return {
    type: SET_VISIT_USER,
    userId,
    userVal,
  }
}

const addAdditionalVisit = () => {
  return {
    type: ADD_ADDITIONAL_VISIT,
  }
}

const deleteMeeting = meetingIdx => {
  return {
    type: DELETE_MEETING,
    idx: meetingIdx,
  }
}

const setVisitErrors = errors => {
  return {
    type: SET_VISIT_ERRORS,
    errors,
  }
}
const clearVisitErrors = () => {
  return {
    type: CLEAR_VISIT_ERRORS,
  }
}
const clearVisitTimeErrors = () => {
  return {
    type: CLEAR_VISIT_TIME_ERRORS,
  }
}

//
// Utils
//

export const getInitialVisit = () => {
  const initialDateTime = nearestNextHour()
  /**
   * this returns a moment representing the nearest next hour from current time
   */
  return {
    user_id: '',
    participant_id: '',
    visits: [
      {
        is_recorded: false,
        visit_time: initialDateTime.format(),
        visit_duration: 30,
        visit_type: 'virtual',
      },
    ],
  }
}

const getAdditionalVisit = () => {
  const initialDateTime = nearestNextHour()
  return {
    is_recorded: false,
    visit_time: initialDateTime.format(),
    visit_duration: 30,
    visit_type: 'virtual',
    isNew: true, // This key will be used to determine whether to render errors for newly added visits
  }
}

const formatParticipantsList = participantsList => {
  return participantsList.map(participant => {
    const { id, fname, lname } = participant
    return {
      key: id,
      text: `${fname} ${lname}, ${id}`,
    }
  })
}

const formatCliniciansIntoList = clinicians => {
  const clinicianList = clinicians.map(({ nickname, user_id, username }) => {
    return {
      key: user_id,
      text: `${nickname}, ${username}`,
    }
  })
  return clinicianList
}

const formatSitesForDropdown = sites => {
  const leafSites = sites.filter(site => site.is_leaf)
  return leafSites.map(site => {
    const { id, label } = site
    return {
      key: id.toString(),
      text: label,
      value: label,
    }
  })
}

export const _defaultVisitDropdownsState = {
  siteDropdownOptions: [],
  participantDropdownOptions: [],
  selectedParticipant: '',
  userDropdownOptions: [],
  selectedUser: '',
  selectedSite: '',
  checkedUser: {},
  checkedPtp: {},
}

//
// Fetch functions
//

const saveVisitToDatabase = (studyId, siteId, currentVisits) => dispatch => {
  const { visits } = currentVisits
  const fail = (response, content) => {
    const { overlaps } = content
    if (response.status === 409) {
      dispatch(setVisitErrors({ overlappingExistingVisits: overlaps }))
    }
    dispatch(notyActions.showError({ text: generateNotyMessage('Please check errors before proceeding', false) }))
  }
  return dispatch(
    request({
      method: 'POST',
      successMessage: `Successfully created virtual ${pluralize(visits.length, 'visit', 'visits', false)}`,
      url: `/control/studies/${studyId}/sites/${siteId}/visits`,
      body: JSON.stringify(currentVisits),
      success: () => {
        browserHistory.push(`/studies/${studyId}/virtualvisits`)
      },
      fail,
    }),
  )
}
const createVisits = (studyId, siteId, currentVisits) => dispatch => {
  const visitErrors = getVisitErrors(currentVisits)
  const valid = Object.keys(visitErrors).length === 0

  if (valid) {
    dispatch(clearVisitErrors())
    dispatch(saveVisitToDatabase(studyId, siteId, currentVisits))
  } else {
    dispatch(setVisitErrors(visitErrors))
    dispatch(
      notyActions.showError({
        text: generateNotyMessage('Please check errors before proceeding.', false),
      }),
    )
  }
}

//
// Reducers
//

export const currentVisits = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case INITIALIZE_CREATE_VISITS:
      return getInitialVisit()
    case UPDATE_VISIT_FIELD:
      newState[action.field] = action.value
      return newState
    case UPDATE_MEETING_FIELD:
      if (action.field === 'visit_time') {
        newState.visits[action.idx][action.field] = ensureFutureDate(action.value)
      } else {
        newState.visits[action.idx][action.field] = action.value
      }
      return newState
    case ADD_ADDITIONAL_VISIT:
      newState.visits.push(getAdditionalVisit())
      return newState
    case DELETE_MEETING:
      delete newState.visits[action.idx]
      newState.visits = newState.visits.filter((visit, idx) => idx !== action.idx)
      return newState
    default:
      return state
  }
}

export const visitDropdownOptions = (state = _defaultVisitDropdownsState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case INITIALIZE_CREATE_VISITS:
      return _defaultVisitDropdownsState
    case SET_SITES:
      if (action.payload.length === 1) {
        // only base site exists
        return {
          ...newState,
          siteDropdownOptions: [],
          selectedSite: action.payload[0]?.id?.toString(),
        }
      }
      return {
        ...newState,
        siteDropdownOptions: formatSitesForDropdown(action.payload),
      }
    case SET_VISIT_SITE:
      return {
        ...newState,
        selectedSite: action.site,
      }
    case SET_VISIT_PTP:
      return {
        ...newState,
        selectedParticipant: action.participantVal,
        checkedPtp: !action.ptpId ? {} : { [action.ptpId]: true },
      }
    case SET_VISIT_USER:
      return {
        ...newState,
        selectedUser: action.userVal,
        checkedUser: { [action.userId]: true },
      }
    case FETCHED_VISIT_PARTICIPANTS:
      return {
        ...newState,
        participantDropdownOptions: formatParticipantsList(action.payload),
      }
    case RESET_PARTICIPANTS:
      return {
        ...newState,
        participantDropdownOptions: [],
      }
    case SET_CLINICIANS:
      return {
        ...newState,
        userDropdownOptions: formatCliniciansIntoList(action.users),
      }
    default:
      return state
  }
}

const visitErrors = (state = {}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_VISIT_ERRORS:
      return { ...action.errors }
    case CLEAR_VISIT_TIME_ERRORS:
      delete newState.overlappingVisits
      delete newState.overlappingExistingVisits
      return newState
    case DELETE_MEETING:
    case CLEAR_VISIT_ERRORS:
      return {}
    default:
      return state
  }
}

export default combineReducers({ currentVisits, visitDropdownOptions, visitErrors })

export const actions = {
  createVisits,
  clearVisitErrors,
  clearVisitTimeErrors,
  deleteMeeting,
  initalizePage,
  updateField,
  updateMeetingField,
  setVisitSite,
  setVisitPtp,
  setVisitUser,
  addAdditionalVisit,
}
