import moment from 'moment-timezone'
import { combineReducers } from 'redux'
import { EditorState } from 'draft-js'
import { EditorStateToHTML, HTMLToEditorState, formatLinkEntities } from 'utils/draft'
import request, { generateNotyMessage } from 'utils/request'
import { downloadBlob, getBaseSiteIdFromStudy, pluralize } from 'utils/misc'
import { browserHistory } from 'react-router'
import { actions as notyActions } from 'layouts/ErrorBox'
import { loadingActions } from 'store/loader'
import { modalActions } from 'store/modal'
import { sendingActions } from 'store/sender'
import { toUTCMidnightSeconds } from 'utils/time'
import { DEFAULT_LANG, INSTRUMENT_TYPE_MAP, MODAL_CLASSES_MAP, GROUP_TYPE_MAP } from 'utils/constants'
import { convertAdvancedOptions } from 'utils/hoc/AdvancedOptionsHOC'
import STRINGS from 'utils/strings'
import { fetchCohorts } from '../../../../Participants/routes/ParticipantsPage/modules/Participants'
import { initializeCheckedCohorts } from '../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import { onFetchInstrument, deployInstrument } from '../../../../Instruments/routes/Instrument/modules/Instrument'

// ACTION CONSTANTS

const UPDATE_ALERT_FIELD = 'UPDATE_ALERT_FIELD'
const UPDATE_ALERT_SCHEDULE = 'UPDATE_ALERT_SCHEDULE'
const RESET_ALERT = 'RESET_ALERT'
const SET_ALERT = 'SET_ALERT'
const SET_ALERT_ERRORS = 'SET_ALERT_ERRORS'
const CLEAR_ANNOUNCEMENT_ERRORS = 'CLEAR_ANNOUNCEMENT_ERRORS'
const TOGGLE_COHORT = 'TOGGLE_COHORT'
const TOGGLE_ANNOUNCEMENT_TRANSLATIONS = 'TOGGLE_ANNOUNCEMENT_TRANSLATIONS'
const ADD_ANNOUNCEMENT_TRANSLATION = 'ADD_ANNOUNCEMENT_TRANSLATION'
const DELETE_ANNOUNCEMENT_TRANSLATION = 'DELETE_ANNOUNCEMENT_TRANSLATION'
const UPDATE_ANNOUNCEMENT_TRANSLATION = 'UPDATE_ANNOUNCEMENT_TRANSLATION'
const SET_ANNOUNCEMENT_TRANSLATIONS = 'SET_ANNOUNCEMENT_TRANSLATIONS'

// ACTION CREATORS

const resetAnnouncement = () => {
  return {
    type: RESET_ALERT,
  }
}

const setAnnouncement = payload => {
  return {
    type: SET_ALERT,
    payload,
  }
}

const updateAnnouncementField = (field, value, errorKey) => {
  return {
    type: UPDATE_ALERT_FIELD,
    field,
    value,
    errorKey,
  }
}

const updateAnnouncementSchedule = (field, value, errorKey) => {
  return {
    type: UPDATE_ALERT_SCHEDULE,
    field,
    value,
    errorKey,
  }
}

const toggleAnnouncementTranslations = localeId => {
  return {
    type: TOGGLE_ANNOUNCEMENT_TRANSLATIONS,
    localeId,
  }
}

const addAnnouncementTranslation = localeId => {
  return {
    type: ADD_ANNOUNCEMENT_TRANSLATION,
    localeId,
  }
}

const updateAnnouncementTranslation = ({ key, localeId, value }) => {
  return {
    type: UPDATE_ANNOUNCEMENT_TRANSLATION,
    key,
    localeId,
    value,
  }
}

const deleteAnnouncementTranslation = localeId => {
  return {
    type: DELETE_ANNOUNCEMENT_TRANSLATION,
    localeId,
  }
}

const setAnnouncementTranslations = translations => {
  return {
    type: SET_ANNOUNCEMENT_TRANSLATIONS,
    translations,
  }
}

const clearAnnouncementErrors = () => ({ type: CLEAR_ANNOUNCEMENT_ERRORS })

//
// ASYNC ACTIONS
//

const COMMUNICATION_REQUEST_MAP = {
  announcement: (studyID, communicationId) => _getAnnouncement(studyID, communicationId),
  announcementInstrument: (studyID, communicationId) => onFetchInstrument(studyID, communicationId),
  sms: (studyID, communicationId) => _getSMS(studyID, communicationId),
}

const initializeAnnouncementPage = ({ studyID, communicationId, isAnnouncement = true, isInstrument }) => {
  return dispatch => {
    dispatch(fetchCohorts(studyID))
    if (communicationId) {
      /**
       * if the announcement is an instrument (in `instrument_architecture_version` 2 and above),
       * we want to fetch an instrument
       */
      if (isInstrument) {
        dispatch(onFetchInstrument(studyID, communicationId)).then(instrument => {
          dispatch(fetchAnnouncementJson({ studyID, instrumentId: communicationId, instrument })).then(payload => {
            dispatch(
              setAnnouncement({ ...payload, num_received: instrument?.num_received, num_sent: instrument?.num_sent }),
            )
            if (payload.metadata.schedule.cohort.type === 'study_groups') {
              dispatch(initializeCheckedCohorts(payload.metadata.schedule.cohort.filter.include))
            }
            dispatch(loadingActions.stopLoader(true))
          })
        })
      } else {
        const requestFunc = isAnnouncement ? COMMUNICATION_REQUEST_MAP.announcement : COMMUNICATION_REQUEST_MAP.sms
        dispatch(requestFunc(studyID, communicationId)).then(payload => {
          dispatch(setAnnouncement(payload))
          if (payload.schedule.cohort.type === 'study_groups') {
            dispatch(initializeCheckedCohorts(payload.schedule.cohort.filter.include))
          }
        })
      }
    } else {
      dispatch(resetAnnouncement())
      dispatch(initializeCheckedCohorts([]))
    }
  }
}

const saveAnnouncement = (redirect, isAnnouncement = true, advancedOptions) => {
  return async dispatch => {
    try {
      const savedCommunicationResponse = await dispatch(
        validateAndSaveCommunication(false, isAnnouncement, advancedOptions),
      )
      const { studyID, deploy } = savedCommunicationResponse
      if (!deploy && studyID) {
        dispatch(sendingActions.stopSender())
        setTimeout(() => {
          browserHistory.push(`/studies/${studyID}/communication`)
          dispatch(sendingActions.resetSender())
        }, 500)
      }
    } catch (err) {
      if (__DEV__) console.error(err.message)
    }
  }
}

const saveAndDeploy = (isAnnouncement = true, advancedOptions) => {
  return dispatch => {
    return dispatch(
      modalActions.openModal({
        content: `Are you sure you want to deploy this ${
          isAnnouncement ? 'announcement' : 'SMS message'
        }? This cannot be undone and ${isAnnouncement ? 'announcements' : 'SMS messages'} cannot be deleted.`,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm: () => dispatch(_saveAndDeploy(isAnnouncement, advancedOptions)),
      }),
    )
  }
}

const _saveAndDeploy = (isAnnouncement = true, advancedOptions) => {
  return dispatch => {
    return dispatch(validateAndSaveCommunication(true, isAnnouncement, advancedOptions))
      .then(({ studyID, id, isInstrument = false }) => {
        if (isInstrument) {
          const successCallback = () => {
            dispatch(sendingActions.stopSender(true))
            browserHistory.push(`/studies/${studyID}/communication`)
            setTimeout(() => {
              dispatch(sendingActions.resetSender(true))
            }, 500)
          }
          return dispatch(deployInstrument({ studyID, instrumentID: id, isAnnouncement: true, successCallback }))
        }
        return dispatch(_deployCommunication(studyID, id, isAnnouncement))
      })
      .catch(err => {
        if (__DEV__) console.error(err.message)
      })
  }
}

const validateAndSaveCommunication = (deploy = false, isAnnouncement = true, advancedOptions) => {
  return (dispatch, getState) => {
    const { study, announcementReducer, participantReducer } = getState()
    const convertedAdvancedOptions = convertAdvancedOptions(advancedOptions)
    const { announcement } = announcementReducer
    const { currentStudy } = study
    const studyID = currentStudy.id
    const siteID = getBaseSiteIdFromStudy(study)

    const announcementContent = EditorStateToHTML(announcement.content)
    const announcementCopy = JSON.parse(JSON.stringify(announcement))
    announcementCopy.content = announcementContent
    const announcementCopyScheduleCohort = announcementCopy.schedule.cohort

    /**
     * If an announcement has translations we need to parse their contents from the EditorState object
     * into HTML like we normally do for announcement content.
     */
    if (announcement.translations) {
      const translationKeys = Object.keys(announcement.translations)
      translationKeys.forEach(lang => {
        announcementCopy.translations[lang].content = EditorStateToHTML(announcement.translations[lang].content)
      })
      /**
       * We will also set the first translation's content and title as the announcement's
       * title and content at the root level.
       */
      const firstTranslationId = translationKeys[0]
      announcementCopy.content = announcementCopy.translations[firstTranslationId].content
      announcementCopy.title = announcementCopy.translations[firstTranslationId].title
    }

    delete announcementCopy.disabledLangs

    if (announcementCopyScheduleCohort.type === GROUP_TYPE_MAP.cohorts) {
      announcementCopy.schedule.cohort.filter.include = Object.keys(participantReducer.checkedCohorts).map(numStr =>
        parseInt(numStr, 10),
      )
    }

    if (convertedAdvancedOptions) announcementCopy.schedule.cohort = [...convertedAdvancedOptions]
    const errors = _getAnnouncementErrors(announcementCopy)
    dispatch({ type: SET_ALERT_ERRORS, errors })
    if (Object.keys(errors).length === 0) {
      return dispatch(_saveCommunicationToDB(studyID, siteID, announcementCopy, deploy, isAnnouncement))
    }
    if (errors.translations) {
      const numTranslationsError = Object.keys(errors.translations).length
      dispatch(
        notyActions.showError({
          text: generateNotyMessage(
            pluralize(
              numTranslationsError,
              'There is 1 incomplete translation',
              `There are ${numTranslationsError} incomplete translations`,
              false,
            ),
            false,
          ),
        }),
      )
    } else {
      dispatch(
        notyActions.showError({
          text: generateNotyMessage('Please correct the errors marked in red before proceeding.', false),
        }),
      )
    }
    return Promise.reject(new Error(isAnnouncement ? 'Invalid announcement.' : 'Invalid SMS message'))
  }
}

const _formatAnnouncement = _announcement => {
  const resultAnnouncement = { ..._announcement }
  resultAnnouncement.content = formatLinkEntities(HTMLToEditorState(_announcement.content))
  const { translations, deployed } = _announcement
  if (translations) {
    resultAnnouncement.disabledLangs = []
    Object.keys(translations).forEach(localeId => {
      resultAnnouncement.translations[localeId].content = formatLinkEntities(
        HTMLToEditorState(_announcement.translations[localeId].content),
      )
      /**
       * If the announcement has been deployed and has translations, we need to know
       * which languages to not allow edits in for message and content. Translations
       * that have already been deployed and exist in the announcement should not be editable.
       */
      if (deployed) resultAnnouncement.disabledLangs.push(localeId)
    })
  }
  return resultAnnouncement
}

const parseNewTranslations = ({ payloadTranslations, enforcedLanguage }) => {
  const resultTranslations = { ...payloadTranslations }

  // If uploaded languages are not supported by a study, they will be discarded
  if (enforcedLanguage) {
    const payloadLangIdArr = Object.keys(payloadTranslations)
    const enforcedLangIdArr = enforcedLanguage.languages.map(enforcedLangObj => {
      return enforcedLangObj.id
    })
    enforcedLangIdArr.push(DEFAULT_LANG)

    const diffArr = payloadLangIdArr.filter(langId => !enforcedLangIdArr.includes(langId))
    diffArr.forEach(diffLangId => {
      delete resultTranslations[diffLangId]
    })
  }

  Object.keys(resultTranslations).forEach(langId => {
    resultTranslations[langId].content = HTMLToEditorState(payloadTranslations[langId].content)
  })
  return resultTranslations
}

//
// API CALLS
//

const _getAnnouncement = (studyID, announcementID) => {
  return dispatch => {
    const url = `/control/studies/${studyID}/announcements/${announcementID}`
    return dispatch(
      request({
        url,
        success: json => Promise.resolve(json),
        hasLoader: true,
        forceLoader: true,
      }),
    )
  }
}

const _getSMS = (studyID, smsID) => {
  return dispatch => {
    const url = `/control/studies/${studyID}/sms/${smsID}`
    return dispatch(
      request({
        url,
        success: json => Promise.resolve(json),
        hasLoader: true,
        forceLoader: true,
      }),
    )
  }
}

function fetchAnnouncementJson({ studyID, instrumentId, version, useLoader, instrument }) {
  const { deployed } = instrument
  return dispatch => {
    const versionText = version ? `?version=${version}` : ''
    function success(json) {
      return Promise.resolve({ ...json, deployed })
    }

    function fail(res) {
      dispatch(loadingActions.stopLoader(true))
      throw new Error(`${res.status} ${res.statusText} when fetching announcement`)
    }

    if (!useLoader) dispatch(loadingActions.startLoader(true))

    return dispatch(
      request({
        method: 'GET',
        url: `/control/admin/studies/${studyID}/instruments/${instrumentId}/announcement.json${versionText}`,
        success,
        fail,
      }),
    )
  }
}

const _saveCommunicationToDB = (studyID, siteID, communication, deploy, isAnnouncement = true) => {
  return (dispatch, getState) => {
    const { study } = getState()
    const { currentStudy } = study
    const isNewInstrumentArchitecture = currentStudy?.config?.instrument_architecture_version >= 2

    const isPUT = !!communication.id
    if (!isPUT) {
      communication.study_id = studyID
    }
    dispatch(sendingActions.startSender(deploy))
    if (isNewInstrumentArchitecture && isAnnouncement) {
      return dispatch(
        _saveAnnouncementToDbAsInstrument({ studyID, announcement: communication, deploy, isUpdate: isPUT }),
      )
    }
    const subUrl = isAnnouncement ? 'announcements' : 'sms'
    return dispatch(
      request({
        method: isPUT ? 'PUT' : 'POST',
        url: `/control/${isPUT ? `studies/${studyID}/${subUrl}/${communication.id}` : subUrl}`,
        body: JSON.stringify(communication),
        successMessage: deploy ? undefined : `${isAnnouncement ? 'Announcement' : 'SMS message'} successfully saved`,
        success: res => {
          return Promise.resolve(Object.assign(res, { studyID, siteID, deploy }))
        },
        hasSender: true,
      }),
    )
  }
}

const _deployCommunication = (studyID, communicationID, isAnnouncement = true) => {
  return dispatch => {
    return dispatch(
      request({
        method: 'PATCH',
        url: `/control/studies/${studyID}/${isAnnouncement ? 'announcements' : 'sms'}/${communicationID}/deploy`,
        successMessage: `${isAnnouncement ? 'Announcement' : 'SMS message'} successfully saved and deployed`,
        success: res => {
          dispatch(sendingActions.stopSender(true))
          setTimeout(() => {
            browserHistory.push(`/studies/${studyID}/communication`)
            dispatch(sendingActions.resetSender(true))
          }, 500)
        },
        hasSender: true,
      }),
    )
  }
}

const _formatAnnouncementToInstrument = (announcement, isUpdate = false) => {
  const _announcement = { ...announcement }
  _announcement.type = INSTRUMENT_TYPE_MAP.announcement

  /**
   * For Announcement instruments we want to package announcement content into metadata object
   * to allow the participant client apps to have the relevant content information without making
   * an additional request.
   */
  const { title, content, translations = {} } = announcement
  const announcement_content = { title, content }
  const metadata = { schedule: announcement.schedule }

  const translationKeys = Object.keys(translations)
  if (translationKeys.length) {
    announcement_content.translations = translations
    metadata.other_languages = translationKeys
  }
  metadata.announcement_content = announcement_content
  _announcement.metadata = metadata
  if (isUpdate) {
    return _announcement
  }
  return { data: _announcement, format: 'json' }
}

export const _saveAnnouncementToDbAsInstrument = ({ studyID, announcement, deploy, isUpdate }) => {
  const { id } = announcement
  const body = _formatAnnouncementToInstrument(announcement, isUpdate)
  return dispatch => {
    return dispatch(
      request({
        method: isUpdate ? 'PUT' : 'POST',
        url: `/control/studies/${studyID}/instruments${isUpdate ? `/${id}` : ''}`,
        body: JSON.stringify(body),
        errorMessage: STRINGS.announcementSaveErr,
        successMessage: deploy ? undefined : 'Announcement successfully saved',
        hasSender: true,
        success: res => {
          return Promise.resolve(Object.assign(res, { studyID, deploy, isInstrument: true }, isUpdate ? { id } : null))
        },
      }),
    )
  }
}

const downloadAnnouncementTranslationTemplate = translations => dispatch => {
  const requestBodyTranslations = { ...translations }
  const translationKeys = Object.keys(requestBodyTranslations)
  translationKeys.forEach(languageId => {
    requestBodyTranslations[languageId].content = EditorStateToHTML(requestBodyTranslations[languageId].content)
  })
  const convertBackTranslationEditorStates = () => {
    translationKeys.forEach(languageId => {
      requestBodyTranslations[languageId].content = HTMLToEditorState(requestBodyTranslations[languageId].content)
    })
  }
  const success = (blob, fileName) => {
    downloadBlob(blob, `announcement_translation.csv`, fileName)
    convertBackTranslationEditorStates()
  }
  const fail = () => {
    dispatch(
      notyActions.showError({
        text: generateNotyMessage('Please try again later', false),
      }),
    )
    convertBackTranslationEditorStates()
  }

  return dispatch(
    request({
      body: JSON.stringify(requestBodyTranslations),
      method: 'POST',
      resType: 'blob',
      success,
      url: '/control/announcements/convert_json',
      fail,
    }),
  )
}

const uploadAnnouncementTranslations = (file, successCb, enforcedLanguage) => dispatch => {
  const success = payload => {
    if (successCb) successCb()
    const translationsToSet = parseNewTranslations({ payloadTranslations: payload, enforcedLanguage })
    dispatch(setAnnouncementTranslations(translationsToSet))
  }

  return dispatch(
    request({
      body: file,
      method: 'POST',
      contentType: 'text/csv',
      success,
      url: '/control/announcements/convert_csv',
      catchMessage: 'There was a problem uploading the file. Please try again later.',
    }),
  )
}

// UTIL FUNCTIONS

const _announcementCohortValidation = (cohort, errors) => {
  if (cohort.type === GROUP_TYPE_MAP.cohorts && cohort.filter.include.length === 0) {
    errors['schedule.cohort.filter.include'] = 'Must assign at least one cohort.'
  }
  if (cohort.type === GROUP_TYPE_MAP.sites && cohort.filter.site_ids.length === 0) {
    errors['schedule.cohort.filter.sites'] = 'Must assign at least one site.'
  }
}

const _getAnnouncementErrors = announcement => {
  const errors = {}
  const { translations } = announcement
  // Check that all translations have subjects
  if (translations) {
    Object.keys(translations).forEach(languageId => {
      if (announcement.translations[languageId].title === '') {
        if (errors.translations) {
          errors.translations[languageId] = 'Subject line is empty'
        } else {
          errors.translations = { [languageId]: 'Subject line is empty' }
        }
      }
    })
  }
  if (announcement.title === '') errors.title = 'Subject line is empty'
  if (
    announcement.schedule.deploy.hasOwnProperty('first_login') &&
    announcement.schedule.deploy.first_login.interval === ''
  ) {
    errors['schedule.deploy.first_login.interval'] = 'Interval cannot be blank.'
  }

  if (announcement.schedule.deploy.absolute) {
    const today = toUTCMidnightSeconds(moment())
    if (announcement.schedule.deploy.absolute < today) {
      errors['schedule.deploy.absolute'] = 'Cannot travel to the past'
    } else if (announcement.schedule.deploy.absolute === 'INVALID') {
      errors['schedule.deploy.absolute'] = 'Please enter a valid date'
    } else {
      delete errors['schedule.deploy.absolute']
    }
  }

  if (announcement.schedule.recurring && announcement.schedule.recurring.interval === '') {
    errors['schedule.recurring.interval'] = 'Interval cannot be blank.'
  }

  if (Array.isArray(announcement.schedule.cohort)) {
    if (announcement.schedule.cohort.length === 0) {
      errors['schedule.cohort.filter.include'] = 'Must assign at least one'
    } else {
      announcement.schedule.cohort.forEach(cohort => {
        _announcementCohortValidation(cohort, errors)
      })
    }
  } else {
    _announcementCohortValidation(announcement.schedule.cohort, errors)
  }
  return errors
}

export const _getDefaultAnnouncement = () => {
  return {
    title: '',
    content: EditorState.createEmpty(),
    schedule: {
      deploy: {
        now: null,
        time_of_day: '09:00',
        new_participants: false,
      },
      cohort: {
        type: GROUP_TYPE_MAP.all,
      },
      expire: {
        never: null,
      },
    },
    deployed: null,
  }
}

const _getDefaultTranslations = localeId => {
  return {
    [localeId]: {
      title: '',
      content: EditorState.createEmpty(),
    },
  }
}
const _getDefaultTranslation = () => {
  return {
    title: '',
    content: EditorState.createEmpty(),
  }
}

//
// REDUCERS
//

const announcement = (state = _getDefaultAnnouncement(), action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_ALERT:
      return _formatAnnouncement(action.payload)
    case UPDATE_ALERT_FIELD:
      if (action.value === null) {
        delete newState[action.field]
      } else {
        newState[action.field] = action.value
      }
      return newState
    case UPDATE_ALERT_SCHEDULE:
      newState.schedule = { ...state.schedule }
      if (action.value === null) {
        delete newState.schedule[action.field]
      } else {
        newState.schedule[action.field] = action.value
      }
      return newState
    case RESET_ALERT:
      return _getDefaultAnnouncement()
    case TOGGLE_ANNOUNCEMENT_TRANSLATIONS: {
      if (newState.translations) {
        delete newState.translations
      } else if (action.localeId) {
        newState.translations = _getDefaultTranslations(action.localeId)
      }
      return newState
    }
    case ADD_ANNOUNCEMENT_TRANSLATION: {
      newState.translations[action.localeId] = _getDefaultTranslation()
      return newState
    }
    case UPDATE_ANNOUNCEMENT_TRANSLATION:
      newState.translations[action.localeId][action.key] = action.value
      return newState
    case DELETE_ANNOUNCEMENT_TRANSLATION:
      delete newState.translations[action.localeId]
      return newState
    case SET_ANNOUNCEMENT_TRANSLATIONS:
      newState.translations = action.translations
      return newState
    default:
      return state
  }
}

const errors = (state = {}, action) => {
  switch (action.type) {
    case SET_ALERT_ERRORS:
      return action.errors
    case UPDATE_ALERT_FIELD:
    case UPDATE_ALERT_SCHEDULE:
      if (state.hasOwnProperty(action.errorKey)) {
        const newState = { ...state }
        delete newState[action.errorKey]
        return newState
      }
      if (state.hasOwnProperty('schedule.cohort.filter.sites')) {
        const newState = { ...state }
        delete newState['schedule.cohort.filter.sites']
        return newState
      }
      return state
    case TOGGLE_COHORT: {
      const newState = { ...state }
      if (state.hasOwnProperty('schedule.cohort.filter.include')) {
        delete newState['schedule.cohort.filter.include']
      }
      return newState
    }
    case CLEAR_ANNOUNCEMENT_ERRORS:
      return {}
    default:
      return state
  }
}

export const announcementActions = {
  addAnnouncementTranslation,
  announcement,
  clearAnnouncementErrors,
  downloadAnnouncementTranslationTemplate,
  deleteAnnouncementTranslation,
  initializeAnnouncementPage,
  resetAnnouncement,
  saveAndDeploy,
  saveAnnouncement,
  toggleAnnouncementTranslations,
  updateAnnouncementField,
  updateAnnouncementSchedule,
  updateAnnouncementTranslation,
  uploadAnnouncementTranslations,
}

export default combineReducers({ announcement, errors })
