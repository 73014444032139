import React from 'react'
import { Container } from 'components/UIElements'
import '../stylesheets/createStudy.scss'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { STUDY_CONFIG_MAP, REWARD_TYPE_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import { confettiIcon, supriseBox, gem } from 'assets/assets'
import CycleRewardConfig from './CyclesRewardConfig'
import BinaryConfig from './BinaryConfig'
import DynamicCycleRewardConfig from './DynamicCycleRewardConfig'
import RewardConfig from './RewardConfig'
import NewRewardsConfig from './NewRewardsConfig'
import GiftCardConfig from './GiftCardConfig'
import RewardSelectionConfig from './RewardSelectionConfig'
import CreateStudyFooter from './CreateStudyFooter'
import { CREATE_STUDY_FULL_URLS } from '../utils/studyUtils'

const richButtonIconMap = {
  ban: <i className='fas fa-ban' />,
  squareCheck: <i className='fas fa-check-square' />,
  dataSync: (
    <div className='data-sync-icon flexed column'>
      <div />
    </div>
  ),
  confetti: confettiIcon,
  iconContainer: icon => {
    return (
      <div className='mid-term-reward-icon flexed column end-justified'>
        <div>{icon()}</div>
      </div>
    )
  },
}

class CreateStudySchedule extends React.Component {
  updateStudyConfig = newConfig => {
    const { study, updateConfig } = this.props
    const config = study.config || {}
    const _config = config || {}
    updateConfig({
      ..._config,
      ...{ ...newConfig },
    })
  }

  deleteConfigKey = key => {
    const { study, updateConfig } = this.props
    const config = study.config || {}
    if (!config) return
    const newConfig = config
    delete newConfig[key]
    updateConfig(newConfig)
  }

  render() {
    const { disabled, study } = this.props
    const configObj = study.config || study
    const { has_dynamic_cycles } = configObj
    const configProps = {
      ...this.props,
      configObj,
      deleteConfigKey: this.deleteConfigKey,
      updateConfig: this.updateStudyConfig,
    }
    const giftCardSelected = configObj[STUDY_CONFIG_MAP.treasureChestStoreGiftCard]

    const hasGemsRewards = [REWARD_TYPE_MAP.gems, REWARD_TYPE_MAP.superGems].includes(configObj?.cycles?.[0].rewardType)

    const ScheduleAndRewardsSection = (
      <Container>
        <h3>Schedule & Rewards</h3>
        <RewardConfig {...configProps} />
        <div className='reward-eligibility'>
          {has_dynamic_cycles ? <DynamicCycleRewardConfig {...configProps} /> : <CycleRewardConfig {...configProps} />}
          {hasGemsRewards ? (
            <BinaryConfig
              {...this.props}
              bodyText={STRINGS.midtermRewardsBody}
              buttonIcons={{
                leftIcon: richButtonIconMap.iconContainer(supriseBox),
                rightIcon: richButtonIconMap.iconContainer(gem),
              }}
              buttonLabels={{ left: STRINGS.specialRewards, right: STRINGS.avatarItems }}
              configKey={STUDY_CONFIG_MAP.superGems}
              defaultIsFalse
              heading={STRINGS.midtermRewards}
              subheading={STRINGS.midtermRewardsSubheading}
              updateStudyConfig={this.updateStudyConfig}
              deleteConfigKey={this.deleteConfigKey}
              isRewardType
            />
          ) : null}
        </div>
      </Container>
    )
    const GiftCardSection = (
      <Container>
        <h3>{STRINGS.giftcardConfig}</h3>
        <GiftCardConfig {...configProps} hasDynamicCycles={has_dynamic_cycles} />
      </Container>
    )

    const RewardsSection = (
      <Container>
        <h3>{STRINGS.rewards}</h3>
        <NewRewardsConfig {...configProps} updateStudyConfig={this.updateStudyConfig} configObj={configObj} />
      </Container>
    )

    const RewardSelectionSection = (
      <Container>
        <h3>{STRINGS.rewardSelection}</h3>
        <RewardSelectionConfig {...configProps} />
      </Container>
    )

    return (
      <>
        {configObj.cycles && ScheduleAndRewardsSection}
        {hasGemsRewards && configObj.super_gems && RewardsSection}
        {hasGemsRewards &&
          !disabled &&
          configObj.super_gems &&
          giftCardSelected &&
          !configObj.super_gems.gift_cards &&
          GiftCardSection}
        {configObj.super_gems && giftCardSelected && configObj.super_gems.gift_cards && RewardSelectionSection}
        <CreateStudyFooter
          {...this.props}
          title={STRINGS.review}
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.review)}
        />
      </>
    )
  }
}

CreateStudySchedule.propTypes = {
  createStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  disabled: PropTypes.bool,
  fetchLocales: PropTypes.func,
  hasDeleteStudyPermissions: PropTypes.bool,
  isRootOrDchAdmin: PropTypes.bool,
  isWizard: PropTypes.bool,
  saveAttempted: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  study: PropTypes.shape({
    self_sign_code: PropTypes.string,
    name: PropTypes.string,
    platform_name: PropTypes.string,
    id: PropTypes.number,
  }),
  updateConfig: PropTypes.func,
  updateStudy: PropTypes.func,
}

export default CreateStudySchedule
