import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'components/UIElements'
import { capitalize } from 'utils/misc'
import { INSTRUMENT_TYPE_MAP, INSTRUMENT_DISPLAY_LOCATION_OPTIONS } from 'utils/constants'
import { PROPERTIES } from '../../../../../utils/PropertyFields'
import PropertyField from './PropertyField'
import STRINGS from 'utils/strings'

const SURVEY_CATEGORIES = [
  {
    title: null,
    properties: ['est_duration', 'is_optional', 'gamification_points'],
  },
  {
    title: null,
    properties: ['priority'],
  },
  {
    title: null,
    properties: ['is_urgent'],
  },
  {
    title: 'Attribution Information',
    properties: ['attribution_text'],
  },
]

const TASK_CATEGORIES = [
  {
    title: null,
    properties: ['is_optional', 'gamification_points'],
  },
  {
    title: 'Task Card Behavior',
    properties: ['priority'],
  },
  {
    title: 'Attribution Information',
    properties: ['attribution_text'],
  },
]

const CLINRO_CATEGORIES = [
  {
    title: null,
    properties: ['priority'],
  },
  {
    title: 'Attribution Information',
    properties: ['attribution_text'],
  },
]

const DIARY_CATEGORIES = [
  {
    title: null,
    properties: ['gamification_points'],
  },
  {
    title: 'Attribution Information',
    properties: ['attribution_text'],
  },
]

const DEFAULT_CATEGORIES = [
  {
    title: null,
    properties: ['est_duration', 'is_optional', 'gamification_points'],
  },
  {
    title: null,
    properties: ['priority'],
  },
  {
    title: 'Attribution Information',
    properties: ['attribution_text'],
  },
]

const returnCategoriesToUse = type => {
  switch (type) {
    case INSTRUMENT_TYPE_MAP.task:
      return TASK_CATEGORIES
    case INSTRUMENT_TYPE_MAP.survey:
      return SURVEY_CATEGORIES
    case INSTRUMENT_TYPE_MAP.clinro:
      return CLINRO_CATEGORIES
    case INSTRUMENT_TYPE_MAP.diary:
      return DIARY_CATEGORIES
    default:
      return DEFAULT_CATEGORIES
  }
}

const returnV2CategoriesToUse = (type, isCarouselModeChecked = false, hasInstrumentUnification) => {
  switch (type) {
    case INSTRUMENT_TYPE_MAP.task:
      return TASK_CATEGORIES
    case INSTRUMENT_TYPE_MAP.survey:
      return isCarouselModeChecked && hasInstrumentUnification ? SURVEY_CATEGORIES : DIARY_CATEGORIES
    case INSTRUMENT_TYPE_MAP.clinro:
      return CLINRO_CATEGORIES
    case INSTRUMENT_TYPE_MAP.diary:
      return isCarouselModeChecked && hasInstrumentUnification ? SURVEY_CATEGORIES : DIARY_CATEGORIES
    default:
      return DEFAULT_CATEGORIES
  }
}

const OtherProperties = ({ displayType, errors, instrument, updateMetadataField, hasInstrumentUnification }) => {
  const { type, metadata } = instrument

  const { display_location: displayLocation } = metadata
  const isClinro = type === INSTRUMENT_TYPE_MAP.clinro
  const isSurvey = type === INSTRUMENT_TYPE_MAP.survey
  const isCarouselModeChecked = displayLocation?.includes(INSTRUMENT_DISPLAY_LOCATION_OPTIONS.carousel)
  const categoriesToUse = hasInstrumentUnification
    ? returnV2CategoriesToUse(type, isCarouselModeChecked, hasInstrumentUnification)
    : returnCategoriesToUse(type)
  return (
    <div className='other-properties'>
      <h4>
        {hasInstrumentUnification
          ? `${isSurvey ? STRINGS.participantInstrument : capitalize(displayType.toLowerCase())} Settings`
          : `${capitalize(displayType.toLowerCase())} Settings`}
      </h4>
      <Container>
        {categoriesToUse.map((cat, idx) => {
          return (
            <div key={`category_${idx}`}>
              {cat.title && <h5>{cat.title}</h5>}
              {cat.properties.map(key => {
                const onUpdate = val => {
                  const propertyValueIsNum = PROPERTIES[key].type === 'number'
                  updateMetadataField(key, propertyValueIsNum && (val || val === '0') ? Number(val) : val)
                }
                const metadataValue = instrument.metadata[key]
                return (
                  <PropertyField
                    error={errors.hasOwnProperty(key) && metadataValue === ''}
                    fieldInfo={PROPERTIES[key]}
                    isClinro={isClinro}
                    key={key}
                    metadataKey={key}
                    onUpdate={onUpdate}
                    value={metadataValue}
                  />
                )
              })}
            </div>
          )
        })}
      </Container>
    </div>
  )
}

OtherProperties.propTypes = {
  displayType: PropTypes.string,
  errors: PropTypes.object,
  instrument: PropTypes.object,
  updateMetadataField: PropTypes.func,
  hasInstrumentUnification: PropTypes.bool,
}

export default OtherProperties
