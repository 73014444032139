import React, { useEffect } from 'react'
import '../stylesheets/createStudy.scss'
import PropTypes from 'prop-types'
import STRINGS from 'utils/strings'
import { FLAGSMITH_FEATURES } from 'utils/constants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'

import { beforeCreate as securityConfigBeforeCreate } from './SecurityConfig'
import CreateStudyNav from './CreateStudyNav'

const beforeCreateStudyCbs = [securityConfigBeforeCreate]

const CreateStudyPage = props => {
  const {
    children,
    study,
    createStudy,
    disabled,
    isWizard,
    resetStudyData,
    setNavBarProps,
    fetchLocales,
    userId,
    updateConfig,
  } = props

  useEffect(() => {
    if (!disabled || isWizard) {
      resetStudyData()
    }
    if (!disabled && !isWizard) {
      setNavBarProps({
        back: '/studies',
        backText: STRINGS.studies,
        close: '/studies',
      })
    }
    if (fetchLocales) fetchLocales()
    return () => {
      setNavBarProps(null)
    }
  }, [])

  const updateStudyConfig = newConfig => {
    const config = study.config || {}
    const _config = config || {}
    updateConfig({
      ..._config,
      ...{ ...newConfig },
    })
  }

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.architectureVersion]: { enabled: true, value: 1 } },
    userId,
  })

  const architectureVersion = flagsmithFeatures[FLAGSMITH_FEATURES.architectureVersion].value

  useEffect(() => {
    updateStudyConfig({ instrument_architecture_version: architectureVersion })
  }, [architectureVersion])

  return (
    <div className='create-study page'>
      <CreateStudyNav onSave={() => createStudy(study, beforeCreateStudyCbs)} {...props} />
      {children}
    </div>
  )
}

CreateStudyPage.propTypes = {
  createStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  disabled: PropTypes.bool,
  fetchLocales: PropTypes.func,
  hasDeleteStudyPermissions: PropTypes.bool,
  isRootOrDchAdmin: PropTypes.bool,
  isWizard: PropTypes.bool,
  resetStudy: PropTypes.func,
  resetStudyData: PropTypes.func,
  saveAttempted: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  study: PropTypes.shape({
    self_sign_code: PropTypes.string,
    name: PropTypes.string,
    platform_name: PropTypes.string,
    id: PropTypes.number,
  }),
  updateConfig: PropTypes.func,
  updateStudy: PropTypes.func,
}

export default CreateStudyPage
