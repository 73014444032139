import React from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Dropdown } from 'components/UIElements'
import { runNTimes } from 'utils/misc'
import { QUESTION_TYPE_MAP, CLINRO_QUESTION_TYPE_OPTIONS } from 'utils/constants'

const QuestionPanel = ({
  consent,
  item,
  itemId,
  onAddChoice,
  onAddQuestion,
  onChangeItemType,
  onDeleteChoice,
  onDeleteQuestion,
  onUpdateUnitType,
  toggleClinroMatrixAllowMulti,
}) => {
  const hasChoices = [
    QUESTION_TYPE_MAP.matrix,
    QUESTION_TYPE_MAP.numericRatingScale,
    QUESTION_TYPE_MAP.selectMultiple,
    QUESTION_TYPE_MAP.selectOne,
  ].includes(item.type)

  const isSibling = !!item.sibling_id
  const hasQuestions = [QUESTION_TYPE_MAP.matrix].includes(item.type)
  const isConsent = consent ? consent.id === 'consent' : false
  const isNRS = item.type === QUESTION_TYPE_MAP.numericRatingScale
  const isMatrix = item.type === QUESTION_TYPE_MAP.matrix

  const questionTypeOptions = isSibling
    ? CLINRO_QUESTION_TYPE_OPTIONS.filter(el => el.key !== QUESTION_TYPE_MAP.imageCapture)
    : CLINRO_QUESTION_TYPE_OPTIONS

  const choicesSection = (
    <div className='choices section'>
      {isMatrix ? <p>Number of choices (columns)</p> : <h5 className='label-small med-font'>Options</h5>}
      {hasChoices ? (
        <div>
          <Button
            className='inline flexed'
            onClick={onDeleteChoice(item.choices_order[item.choices_order.length - 1])}
            circle
            disabled={item.choices_order.length <= 2}
            icon='fas fa-minus'
          />
          <p className='inline flexed'>{item.choices_order.length}</p>
          <Button
            className='inline flexed'
            disabled={(item.choices_order.length >= 11 && isNRS) || (item.choices_order.length >= 7 && isMatrix)}
            onClick={onAddChoice}
            circle
            icon='fas fa-plus'
          />
          {/* {
            !item.attributes.other &&
            <Button
              no-margin
              link
              icon='fas fa-plus'
              content='Add Other Choice'
              onClick={onAddOtherChoice} />
          } */}
        </div>
      ) : (
        <div>
          <Button
            className='inline flexed'
            onClick={
              item.choices_order.length > 5
                ? () => runNTimes(2, onDeleteChoice(item.choices_order[item.choices_order.length - 1]))
                : null
            }
            circle
            disabled={item.choices_order.length <= 5}
            icon='fas fa-minus'
          />
          <p className='inline flexed'>{item.choices_order.length}</p>
          <Button
            className='inline flexed'
            onClick={item.choices_order.length === 5 ? () => runNTimes(2, onAddChoice) : null}
            circle
            disabled={item.choices_order.length >= 7}
            icon='fas fa-plus'
          />
        </div>
      )}
    </div>
  )

  const renderQuestionsSection = () => (
    <div className='choices section'>
      <p>Number of questions (rows)</p>
      <div>
        <Button
          className='inline flexed'
          onClick={() => onDeleteQuestion(item.questions_order[item.questions_order.length - 1])}
          circle
          disabled={item.questions_order.length <= 1}
          icon='fas fa-minus'
        />
        <p className='inline flexed'>{item.questions_order.length}</p>
        <Button
          className='inline flexed'
          disabled={isMatrix && item.questions_order?.length >= 12}
          onClick={onAddQuestion}
          circle
          icon='fas fa-plus'
        />
      </div>
    </div>
  )

  const renderMatrixAllowMultiAnswerCheckbox = () => {
    return (
      <div className='section'>
        <Checkbox
          className='matrix-allow-multi-checkbox'
          checked={item.attributes.allowMultipleAnswers}
          label='Allow multiple answers per question'
          onClick={() => {
            toggleClinroMatrixAllowMulti(itemId)
          }}
        />
      </div>
    )
  }

  const unitsSection = ['decimal', 'integer'].includes(item.type) ? (
    <div className='number section'>
      Unit:
      <input aria-label='unit-field' onChange={onUpdateUnitType} maxLength={20} value={item.attributes.unit} />
    </div>
  ) : null

  return (
    <div>
      <div className='question panel'>
        <h5 className='label-small med-font'>Question Type</h5>
        <div className='selector section'>
          {!isConsent && (
            <Dropdown
              sortingOrder='asc'
              sortingBy='text'
              className='select-dropdown'
              selected={item.type}
              options={questionTypeOptions}
              onSelect={onChangeItemType}
            />
          )}
        </div>
        {isMatrix && renderMatrixAllowMultiAnswerCheckbox()}
        {hasChoices && choicesSection}
        {hasQuestions && renderQuestionsSection()}
        {unitsSection}
      </div>
    </div>
  )
}

QuestionPanel.propTypes = {
  consent: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  item: PropTypes.shape({
    sibling_id: PropTypes.string,
    type: PropTypes.string,
    attributes: PropTypes.shape({
      allowMultipleAnswers: PropTypes.bool,
    }),
  }),
  itemId: PropTypes.string,
  onAddChoice: PropTypes.func,
  onAddQuestion: PropTypes.func,
  onChangeItemType: PropTypes.func,
  onDeleteChoice: PropTypes.func,
  onDeleteQuestion: PropTypes.func,
  onUpdateUnitType: PropTypes.func,
  toggleClinroMatrixAllowMulti: PropTypes.func,
}

export default QuestionPanel
