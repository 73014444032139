import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { modalActions } from 'store/modal'
import { setNavBarProps } from 'store/navbar'
import { USER_ROLES_MAP } from 'utils/constants'
import { getUserRoleByStudy } from 'utils/misc'
import { checkIsEmailless } from 'utils/studyConfigUtils'
import { actions as sitesActions } from '../modules/Sites'
import { actions as siteActions } from '../../SiteManagement/modules/SiteManagement'
import CreateSite from '../components/CreateSite'

const mapStateToProps = state => {
  const { participants, sending, sites, study, loading, user } = state
  const { currentStudy } = study
  const studyID = currentStudy.id
  const userRole = getUserRoleByStudy(user, studyID)
  const { sendingState, sentState } = sending
  const sendDisabled = sendingState || sentState
  const hasConsent = currentStudy.consent_required
  const isEmailless = checkIsEmailless(currentStudy)

  return {
    ...participants,
    requestSending: sendingState,
    requestSent: sentState,
    sendDisabled,
    sites,
    study: study.currentStudy,
    studyID,
    loading: loading.general,
    hasConsent,
    isRootOrDchAdmin: [USER_ROLES_MAP.root, USER_ROLES_MAP.dchAdmin].includes(userRole),
    isEmailless,
  }
}

const mapDispatchToProps = { ...modalActions, ...siteActions, ...sitesActions, setNavBarProps }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.loading) {
    return <Loader />
  }
  return <CreateSite {...props} editMode />
})
