import React from 'react'
import PropTypes from 'prop-types'
import { Marker, Circle } from 'react-google-maps'

const GeofenceMarker = props => {
  const { onMarkerDragEnd, markerPosition, geofenceName, radius, draggable } = props
  const rad = radius || 0
  const options = {
    fillColor: 'yellow',
    fillOpacity: 0.5,
    strokeWeight: 2,
  }
  return (
    <div>
      <Marker draggable={draggable} onDragEnd={onMarkerDragEnd} position={markerPosition} name={geofenceName} />
      <Circle center={markerPosition} radius={rad} options={options} />
    </div>
  )
}

GeofenceMarker.propTypes = {
  onMarkerDragEnd: PropTypes.func,
  markerPosition: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  geofenceName: PropTypes.string,
  radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  draggable: PropTypes.bool,
}

export default GeofenceMarker
