import React, { useEffect } from 'react'
import STRINGS from 'utils/strings'
import { Input } from 'components/UIElements'
import { STUDY_CONFIG_FEATURE_KEY_MAP, MAX_OFFLINE_DURATION_DAYS } from 'utils/constants'

const ParticipantOfflineDuration = ({ study, disabled, updateStudyConfig = () => {} }) => {
  const config = study.config || {}
  const { instrument_architecture_version, participant_offline_duration_days } = config

  useEffect(() => {
    if (instrument_architecture_version > 1 && !participant_offline_duration_days) {
      updateStudyConfig({ [STUDY_CONFIG_FEATURE_KEY_MAP.offlineDuration]: MAX_OFFLINE_DURATION_DAYS })
    }
  }, [instrument_architecture_version])

  if (instrument_architecture_version > 1) {
    return (
      <section>
        <h5 className='heading'>{STRINGS.networkConnectionReminder}</h5>
        <p>{STRINGS.ptpOfflineDurationDesc}</p>
        <Input
          className='ptp-offline-duration'
          disabled={disabled}
          inline
          onChange={num => {
            updateStudyConfig({ [STUDY_CONFIG_FEATURE_KEY_MAP.offlineDuration]: Number(num) })
          }}
          onBlur={event => {
            const numVal = event.target.valueAsNumber
            if (!numVal || numVal > MAX_OFFLINE_DURATION_DAYS) {
              updateStudyConfig({ [STUDY_CONFIG_FEATURE_KEY_MAP.offlineDuration]: MAX_OFFLINE_DURATION_DAYS })
            }
          }}
          min={0}
          pretext={STRINGS.ptpOfflineDurationInputPretext}
          posttext={STRINGS.ptpOfflineDurationInputPosttext}
          preventInput={num => num < 0}
          id='ptp-offline-duration'
          type='number'
          value={participant_offline_duration_days || ''}
        />
      </section>
    )
  }
  return null
}

export default ParticipantOfflineDuration
