import { combineReducers } from 'redux'
import request from 'utils/request'
import { capitalize } from 'utils/misc'
import STRINGS from 'utils/strings'
import { _getDefaultMetadata } from '../../../../../utils/PropertyFields'

//
// Actions
//

const SET_TASKS = 'SET_TASKS'
const VIEW_TASK = 'VIEW_TASK'
const INITIALIZE_PAGE = 'INITIALIZE_PAGE'
const SET_INSTRUMENT = 'SET_INSTRUMENT'

//
// Action Creators
//

const selectTask = task => {
  return {
    type: SET_INSTRUMENT,
    payload: _getTaskInfo(task),
  }
}

const selectTaskToView = task => {
  return {
    type: VIEW_TASK,
    task,
  }
}

const setTasks = tasks => {
  return {
    type: SET_TASKS,
    tasks,
  }
}

const initializePage = () => {
  return {
    type: INITIALIZE_PAGE,
  }
}

const _getTaskInfo = task => {
  const { display_name, est_duration, task_name, title, required_permissions } = task
  const selectedTask = {
    ..._getDefaultMetadata('TASK'),
    display_name,
    est_duration,
    task_name,
    title,
    type: 'TASK',
  }
  if (required_permissions) selectedTask.required_permissions = required_permissions
  return selectedTask
}

export const saveTaskToDB = (studyID, task) => {
  return dispatch => {
    return dispatch(
      request({
        method: 'POST',
        url: `/control/studies/${studyID}/instruments`,
        body: JSON.stringify(task),
        errorMessage: STRINGS.taskSaveErr,
        hasLoader: true,
        success: json => json,
      }),
    )
  }
}

export const fetchTasks = selectedTask => {
  return dispatch => {
    const success = json => {
      dispatch(setTasks(parseTaskList(json.tasks)))
      if (selectedTask) {
        dispatch(selectTaskToView(selectedTask))
      }
      return Promise.resolve(json.tasks)
    }
    return dispatch(
      request({
        method: 'GET',
        url: '/control/task_list',
        errorMessage: 'could not fetch task list',
        success,
        hasLoader: true,
      }),
    )
  }
}

const taskList = (state = [], action) => {
  switch (action.type) {
    case SET_TASKS:
      return action.tasks
    default:
      return state
  }
}

export const reducers = {
  taskList,
}

//
// UTILS
//

const DURATION_SORT_VALUES = {
  short: 0,
  medium: 1,
  long: 2,
}

const placeholderImage = require('../assets/placeholder.png')

export const parseTaskList = rawTasks => {
  return rawTasks.map(task => {
    const { description, display_name, estimated_duration, image, is_hidden, task_id, task_name, title, type } = task
    const taskType = type || 'task'
    const taskTypeVal = capitalize(taskType)
    return [
      {
        key: 'task_name',
        value: title,
        sortValue: title,
        image: image || placeholderImage,
        taskId: task_id,
        taskName: task_name,
        isHidden: !!is_hidden,
        displayName: display_name || title,
      },
      {
        key: 'description',
        value: description,
        sortValue: '',
      },
      { key: 'type', value: taskTypeVal, sortValue: taskTypeVal },
      {
        key: 'estimated_duration',
        value: task.estimated_duration,
        sortValue: DURATION_SORT_VALUES[estimated_duration.toLowerCase()] || -1,
      },
      {
        key: 'actions',
        value: '',
        sortValue: '',
        taskName: task_name,
      },
    ]
  })
}

export default combineReducers(reducers)

export const actions = {
  fetchTasks,
  selectTask,
  selectTaskToView,
  initializePage,
  setTasks,
}
