import React from 'react'
import { ComposedChart, XAxis, YAxis, Bar, ResponsiveContainer, Line } from 'recharts'
import { Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import EmptyChart, { RedirectButton } from './EmptyChart'
import { TooltipRow } from './IconTooltips'

const PerformanceChartContainer = props => {
  const {
    insights,
    isEnroller,
    sisenseLoading,
    renderSisenseButton,
    loading,
    chartData,
    selected,
    changeChartType,
    studyID,
  } = props
  let buttonText = 'View All Instruments'
  if (chartData.length === 0 || isEnroller) {
    buttonText = 'Go To Instruments Page'
    return (
      <EmptyChart
        className='instrument-performance'
        header='Instrument Performance'
        message={
          isEnroller ? 'You do not have access to this chart' : 'This study does not have any deployed instruments'
        }
        studyID={studyID}
        buttonText={buttonText}
        loading={isEnroller ? false : loading}
        hasInsights={!!insights}
        insights={insights?.custom_buttons?.instrument_detail}
        renderSisenseButton={renderSisenseButton}
        sisenseLoading={sisenseLoading}
      />
    )
  }
  return (
    <div className='instrument-performance d3-chart-container'>
      <div className='flexed column chart-section'>
        <div className='chart-header flexed'>
          <h4>Instrument Performance</h4>
          <div>
            <Button
              onClick={() => changeChartType('performance', 'num')}
              grey
              className={selected === 'num' ? 'selected' : ''}
              content='#'
            />
            <Button
              onClick={() => changeChartType('performance', 'percent')}
              grey
              className={selected === 'percent' ? 'selected' : ''}
              content='%'
            />
          </div>
        </div>
        <p>{selected === 'num' ? '# Completed' : '% Compliance'}</p>
        <Chart {...props} />
        <h5>Days from Available to Completed</h5>
        <Legend />
        {insights?.custom_buttons?.instrument_detail &&
          renderSisenseButton(insights?.custom_buttons?.instrument_detail, sisenseLoading)}
      </div>
      {!insights && <RedirectButton studyID={studyID} buttonText={buttonText} redirect='instruments' />}
    </div>
  )
}

const Chart = props => {
  const dataKey = props.selected === 'num' ? 'averageCompletion' : 'averageCompletionPct'
  const worstDataKey = props.selected === 'num' ? 'worstCompletion' : 'worstCompletionPct'
  const bestDataKey = props.selected === 'num' ? 'bestCompletion' : 'bestCompletionPct'
  const longestLabel =
    props.chartData.length > 0
      ? `${props.chartData.reduce((a, b) => ({ value: Math.max(a[dataKey] || 0, b[dataKey] || 0) })).value}`
      : '1'
  const yAxisWidth = (longestLabel.length + 1) * 12 + 15

  return (
    <ResponsiveContainer width='100%' height={400}>
      <ComposedChart barCategoryGap={0} data={props.chartData}>
        <Bar dataKey={dataKey} fill='#e2d8ea' stroke='#e2d8ea' />
        <YAxis
          width={yAxisWidth}
          domain={[0, 'auto']}
          tick={{ textAlign: 'left' }}
          tickLine={false}
          axisLine={false}
          allowDecimals={false}
        />
        <XAxis tickLine={false} axisLine={{ strokeWidth: 6, stroke: '#efeded' }} dataKey='name' />
        <Line
          animationDuration={0}
          stroke='transparent'
          dataKey={worstDataKey}
          dot={getMarker(worstDataKey, props.chartData, props.updateTooltip, props.studyID, props.siteID)}
        />
        <Line
          animationDuration={0}
          stroke='transparent'
          dataKey={bestDataKey}
          dot={getMarker(bestDataKey, props.chartData, props.updateTooltip, props.studyID, props.siteID)}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

const getMarker = (dataKey, chartData, updateTooltip, studyID, siteID) => props => {
  const type = dataKey.includes('best') ? 'best' : 'worst'
  const range = chartData.length
  const color = type === 'worst' ? '#f27b21' : '#3db988'
  const instrument = type === 'worst' ? chartData.worstPerformer : chartData.bestPerformer
  const width = props.width / range
  const onMouseEnter = e => {
    const dims = e.currentTarget.getBoundingClientRect()
    const x = dims.x + dims.width / 2
    const y = dims.y + dims.height / 2
    updateTooltip({
      visible: true,
      x,
      y,
      triggerHeight: 20,
      triggerWidth: width,
      closeTrigger: () => updateTooltip({ visible: false }),
      direction: 'up',
      content: (
        <MarkerTooltip
          studyID={studyID}
          siteID={siteID}
          chartData={chartData}
          type={type}
          dataKey={dataKey}
          instrument={instrument}
          payload={props.payload}
        />
      ),
    })
  }
  return (
    <rect
      onMouseEnter={onMouseEnter}
      key={props.key}
      x={props.cx - width / 2}
      y={props.cy - 2}
      width={width}
      height={6}
      fill={color}
    />
  )
}

const MarkerTooltip = ({ payload, type, instrument, chartData, studyID, siteID }) => {
  const completed = type === 'best' ? payload.bestCompletion : payload.worstCompletion
  const completedPct = type === 'best' ? payload.bestCompletionPct : payload.worstCompletionPct
  const aggregate = type === 'best' ? payload.bestAggregate : payload.worstAggregate
  const aggregatePct = type === 'best' ? payload.bestAggregatePct : payload.worstAggregatePct
  const { averageCompletion } = chartData[payload.name - 1]
  const { averageCompletionPct } = chartData[payload.name - 1]
  const redirectURL = `/studies/${studyID}/instruments/${instrument.id}/${
    instrument.type === 'TASK' ? 'edittask' : 'editinstrument'
  }`
  return (
    <div>
      <h5>{`${type === 'best' ? 'Highest' : 'Lowest'} Completion Rate by Day ${payload.name}`}</h5>
      <Button link content={instrument.title} onClick={() => browserHistory.push(redirectURL)} />
      <table>
        <tbody>
          <TooltipRow text={`Instruments Completed (${completedPct}%)`} value={completed} />
          <TooltipRow />
          <TooltipRow header text={`By Day ${payload.name}`} />
          <TooltipRow text={`Instruments Completed (${aggregatePct}%)`} value={aggregate} />
          <TooltipRow text='Total Received' value={instrument.received} />
          <TooltipRow divider />
          <TooltipRow header text={`Average on Day ${payload.name}`} />
          <TooltipRow text={`Instruments Completed (${averageCompletionPct}%)`} value={averageCompletion} />
        </tbody>
      </table>
    </div>
  )
}

const Legend = () => {
  const legendsList = [
    {
      id: 'grey-square',
      text: 'Average Completion',
    },
    {
      id: 'worst-performed',
      text: 'Worst Performed',
    },
    {
      id: 'best-performed',
      text: 'Best Performed',
    },
  ]
  return (
    <div className='legend'>
      {legendsList &&
        legendsList.map(legend => (
          <div className='item' key={`${legend.id}-${legend.text}`}>
            <span className='icon' id={legend.id} />
            <span>{legend.text}</span>
          </div>
        ))}
    </div>
  )
}

export default PerformanceChartContainer
