import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/UIElements'
import '../../stylesheets/AttributePanel.scss'

const AttributePanel = ({ onToggle, label, heading, checked }) => {
  return (
    <div className='attribute panel'>
      {heading ? <h5 className='label-small med-font'>{heading}</h5> : null}
      <Checkbox checked={checked} onClick={onToggle} label={label} toggle />
    </div>
  )
}

AttributePanel.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onToggle: PropTypes.func,
  heading: PropTypes.string,
}

export default AttributePanel
