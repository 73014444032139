import React, { useEffect } from 'react'
import { INSTRUMENT_DISPLAY_LOCATION_OPTIONS } from 'utils/constants'
import { Checkbox } from 'components/UIElements'
import '../stylesheets/properties.scss'
import STRINGS from 'utils/strings'
import { carouselModeIcon, calendarModeIcon } from 'assets/assets'

const DisplayModeSection = ({ instrument, updateInstrumentDisplayLocation, errors }) => {
  const { metadata = {} } = instrument
  const { display_location: displayLocation = [] } = metadata

  const isError = errors.hasOwnProperty('display_location') && !displayLocation?.length

  const isCarouselModeChecked = displayLocation?.includes(INSTRUMENT_DISPLAY_LOCATION_OPTIONS.carousel)
  const isCalendarModeChecked = displayLocation?.includes(INSTRUMENT_DISPLAY_LOCATION_OPTIONS.calendar)

  useEffect(() => {
    if (!isCarouselModeChecked && !isCalendarModeChecked) {
      updateInstrumentDisplayLocation([INSTRUMENT_DISPLAY_LOCATION_OPTIONS.carousel])
    }
  }, [])

  const toggleCarouselMode = () => {
    if (isCarouselModeChecked) {
      updateInstrumentDisplayLocation(
        displayLocation.filter(item => item !== INSTRUMENT_DISPLAY_LOCATION_OPTIONS.carousel),
      )
    } else {
      updateInstrumentDisplayLocation([...displayLocation, INSTRUMENT_DISPLAY_LOCATION_OPTIONS.carousel])
    }
  }

  const toggleCalendarMode = () => {
    if (isCalendarModeChecked) {
      updateInstrumentDisplayLocation(
        displayLocation.filter(item => item !== INSTRUMENT_DISPLAY_LOCATION_OPTIONS.calendar),
      )
    } else {
      updateInstrumentDisplayLocation([...displayLocation, INSTRUMENT_DISPLAY_LOCATION_OPTIONS.calendar])
    }
  }

  return (
    <div className='display-mode-section'>
      <div className='section-title'>{STRINGS.displayMode}</div>
      <div className='section-content'>
        <div className='content-description'>
          <div className='description-item'>{STRINGS.carouselModeDescription}</div>
          <div className='description-item'>{STRINGS.calendarModeDescription}</div>
        </div>
        <div className='content-options'>
          <div
            className={`mode-option ${isCarouselModeChecked ? 'active' : ''} ${isError ? 'error' : ''}`}
            onClick={toggleCarouselMode}>
            <div className='mode-option-icon'>{carouselModeIcon()}</div>
            <div className='mode-option-title'>{STRINGS.carouselMode}</div>
            <div className='mode-option-checkbox'>
              <Checkbox checked={isCarouselModeChecked} onClick={toggleCarouselMode} />
            </div>
          </div>
          <div
            className={`mode-option ${isCalendarModeChecked ? 'active' : ''} ${isError ? 'error' : ''}`}
            onClick={toggleCalendarMode}>
            <div className='mode-option-icon'>{calendarModeIcon()}</div>
            <div className='mode-option-title'>{STRINGS.calendarMode}</div>
            <div className='mode-option-checkbox'>
              <Checkbox checked={isCalendarModeChecked} onClick={toggleCalendarMode} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisplayModeSection
