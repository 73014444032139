import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Radio, FormattedContent } from 'components/UIElements'
import { DEPLOYMENT_KEYS } from 'utils/constants'
import STRINGS from 'utils/strings'
import InstrumentVisitSelection from './InstrumentVisitSelection'
import DurationInput from '../DurationInput'
import { DAY_MULTIPLIER_MAP } from '../../constants/schedulerConstants'
import VisitItemType from './VisitItemType'
import { ARTICLE_TYPE } from '../../../../../../utils/instrumentConstants'

const DAYS_OFFSET = DEPLOYMENT_KEYS.daysOffset

const VisitStartContent = props => {
  const {
    deploy,
    disabled,
    instrument,
    updateDeploy,
    updateExpire,
    isVisitSummary,
    chain_deployment_info,
    updateChainDeployInfo,
  } = props
  const { metadata } = instrument
  const isMissedVisitItem = metadata.article_type === ARTICLE_TYPE.missed_visit_follow_up.key

  const [offsetDayState, setOffsetDayState] = useState({
    offsetDays: Math.abs(deploy[DAYS_OFFSET]),
    isBefore: deploy[DAYS_OFFSET] < 0,
  })
  const [offsetTimeUnit, setOffsetTimeUnit] = useState('days')

  useEffect(() => {
    const {
      chain_deployment_by_ss_visit,
      deployment_condition,
      prev_instrument_id,
      visit_id,
      visit_schedule_id,
    } = chain_deployment_info
    if (deployment_condition || prev_instrument_id) {
      updateChainDeployInfo({
        chain_deployment_by_ss_visit,
        visit_id,
        visit_schedule_id,
      })
    }
  }, [])

  useEffect(() => {
    const { offsetDays, isBefore } = offsetDayState
    const dayMultiplier = DAY_MULTIPLIER_MAP[offsetTimeUnit]
    const newOffsetVal = offsetDays * dayMultiplier * (isBefore ? -1 : 1)
    updateDeploy({ ...deploy, [DAYS_OFFSET]: newOffsetVal })
  }, [offsetDayState, offsetTimeUnit])

  const renderDaysInput = (inputProps = {}) => {
    const { offsetDays } = offsetDayState
    const { isBeforeInput = false, isSelected = false } = inputProps

    const onChange = val => {
      // prevents user from entering negative number
      if (Number(val) < 0) {
        setOffsetDayState({ offsetDays: 1, isBefore: isBeforeInput })
      } else {
        const isNum = val || val === '0'
        setOffsetDayState({ offsetDays: isNum ? Number(val) : val, isBefore: isBeforeInput })
      }
    }
    const onBlur = e => {
      if (e.target.value === '') {
        setOffsetDayState({ offsetDays: 1, isBefore: isBeforeInput })
      }
    }
    const onDropdownSelect = item => {
      setOffsetTimeUnit(item.key)
    }
    const postText = isMissedVisitItem ? (
      <span>{STRINGS.visitAfterMissedPosttext}</span>
    ) : (
      <>
        <strong>{isBeforeInput ? STRINGS.before : STRINGS.after}</strong>
        <span>{STRINGS.visit}</span>
      </>
    )

    return (
      <DurationInput
        isSelected={isSelected}
        onBlur={onBlur}
        onChange={onChange}
        inputValue={offsetDays}
        unitValue={offsetTimeUnit}
        onDropdownSelect={onDropdownSelect}
        postText={postText}
      />
    )
  }

  return (
    <div className={`triggered-by-menu${disabled ? ' disabled' : ''}`}>
      <div className='trigger-inst-box border-radius eight'>
        <p>
          <span className={`${disabled ? 'disabled' : ''}`}>{STRINGS.whichVisitTrigger}</span>
        </p>
        <InstrumentVisitSelection {...props} />
      </div>
      <VisitItemType {...props} />
      <p>
        <FormattedContent content={STRINGS.whenShouldDeployToPtp} />
      </p>
      <Radio
        disabled={disabled}
        id='on-day-of-radio'
        content={isMissedVisitItem ? STRINGS.visitOnDayOfMissed : STRINGS.visitOnDayOf}
        onClick={() => {
          setOffsetDayState({ offsetDays: 0, isBefore: false })
          if (!isVisitSummary) updateExpire({ never: null })
        }}
        selected={deploy[DAYS_OFFSET] === 0}
      />
      {!isMissedVisitItem && (
        <Radio
          disabled={disabled}
          id='before-visit-radio'
          onClick={() => {
            const { offsetDays } = offsetDayState
            setOffsetDayState({ offsetDays: offsetDays || 1, isBefore: true })
            if (!isVisitSummary) updateExpire({ never: null })
          }}
          selected={deploy[DAYS_OFFSET] < 0}
          content={renderDaysInput({ isBeforeInput: true, isSelected: deploy[DAYS_OFFSET] < 0 })}
        />
      )}
      <Radio
        disabled={disabled}
        id='after-visit-radio'
        onClick={() => {
          const { offsetDays } = offsetDayState
          setOffsetDayState({ offsetDays: offsetDays || 1, isBefore: false })
          if (!isVisitSummary) updateExpire({ never: null })
        }}
        selected={deploy[DAYS_OFFSET] > 0}
        content={renderDaysInput({ isSelected: deploy[DAYS_OFFSET] > 0 })}
      />
    </div>
  )
}

VisitStartContent.propTypes = {
  deploy: PropTypes.shape({
    days_offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  disabled: PropTypes.bool,
  otherInstruments: PropTypes.arrayOf(PropTypes.object),
  study: PropTypes.shape({
    id: PropTypes.number,
  }),
  updateDeploy: PropTypes.func,
  updateExpire: PropTypes.func,
}

export default VisitStartContent
