import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Loader } from 'components/UIElements'
import { DATE_FORMAT_MAP } from 'utils/constants'
import { Legend } from './Icons'
import Tooltips from './IconTooltips'
import DateRangeSelector from './DateRangeSelector'
import SortTypeSelector from './SortTypeSelector'
import ParticipantRow from './ParticipantRow'

const SORT_PRIORITY = [
  'num_received',
  'num_expired',
  'largest_completion_gap',
  'largest_open_app_gap',
  'largest_announcement_app',
]

class DataGapChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = { sortBy: 'num_received' }
    this.hoveredIcon = null
  }

  getSortedList = () => {
    const { data } = this.props
    const { sortBy } = this.state
    return Object.keys(data).sort(this.getSortingFunction(sortBy))
  }

  getSortingFunction = sortBy => {
    const { data } = this.props
    return (id1, id2) => {
      let diff = data[id2][sortBy] - data[id1][sortBy]
      let priorityIdx = 0
      while (priorityIdx < 5 && diff === 0) {
        const key = SORT_PRIORITY[priorityIdx]
        diff = data[id2][key] - data[id1][key]
        priorityIdx += 1
      }
      return diff
    }
  }

  changeSort = sortBy => {
    this.setState({ sortBy })
  }

  _handleUnhover = () => {
    if (this.hoveredIcon !== null) {
      this.hoveredIcon.classList.remove('hovered')
      this.hoveredIcon = null
    }
  }

  onMouseLeave = () => {
    const { updateTooltip } = this.props
    updateTooltip({ visible: false })
    this._handleUnhover()
  }

  onMouseEnterIcon = (e, eventData, ptpData, noGuard = false) => {
    const { allowCommunicationRedirect, allowInstrumentRedirect, updateTooltip } = this.props
    this._handleUnhover()
    this.hoveredIcon = e.currentTarget
    this.hoveredIcon.classList.add('hovered')
    const dims = this.hoveredIcon.getBoundingClientRect()
    this.hoveredElement = e.currentTarget
    updateTooltip({
      noGuard,
      x: dims.x + dims.width / 2,
      y: dims.y + dims.height / 2,
      triggerWidth: dims.width,
      triggerHeight: dims.height,
      direction: dims.y + dims.height / 2 > window.innerHeight / 2 ? 'up' : 'down',
      visible: true,
      closeTrigger: this.onMouseLeave,
      content: Tooltips[eventData.type]({
        eventData,
        ptpData,
        allowCommunicationRedirect,
        allowInstrumentRedirect,
      }),
    })
  }

  onMouseEnterPtpCell = (e, ptpData, ptpId) => {
    this.onMouseEnterIcon(
      e,
      { type: 'PTP_HOVER', participant_id: ptpId, participant_name: ptpData.name },
      ptpData,
      true,
    )
  }

  onApplyDateRange = (start, end) => {
    const { updateEventData, studyID, siteID } = this.props
    this.onMouseLeave()
    updateEventData(studyID, siteID, start, end)
  }

  render() {
    const { start, end, loading, data, studyID, range } = this.props
    const { sortBy } = this.state
    const sortedList = this.getSortedList()
    const timeAxis = <TimeAxis start={start} end={end} />
    return (
      <div onClick={this.onMouseLeave} className='flexed column data-gap-chart'>
        <div className='flexed-header'>
          <h4>Participant Activity Timeline</h4>
          <div className='flexed-header'>
            <SortTypeSelector applySortBy={this.changeSort} sortBy={sortBy} />
            <DateRangeSelector applyDateRange={this.onApplyDateRange} start={start} end={end} />
          </div>
        </div>
        <p>Participant ID</p>
        {loading ? (
          <div style={{ height: 713 }}>
            <Loader inContainer />
          </div>
        ) : (
          <table>
            <tbody>
              {sortedList.length > 5 && timeAxis}
              {sortedList.map((ptpId, idx) => {
                const eventData = data[ptpId]
                return (
                  <ParticipantRow
                    studyID={studyID}
                    grey={idx % 2 !== 0}
                    ptpId={ptpId}
                    onMouseLeave={this.onMouseLeave}
                    onMouseEnterIcon={this.onMouseEnterIcon}
                    onMouseEnterPtpCell={this.onMouseEnterPtpCell}
                    key={ptpId}
                    data={eventData}
                    range={range}
                    end={end}
                  />
                )
              })}
              {timeAxis}
            </tbody>
          </table>
        )}
        <Legend />
      </div>
    )
  }
}

const TimeAxis = ({ start, end }) => {
  const days = []
  const span = end.diff(start, 'days') + 1
  const increment = span > 14 ? Math.round(span / 14) : 1
  for (let day = moment(start); day.isSameOrBefore(end); day.add(increment, 'days')) {
    days.push(day.format(DATE_FORMAT_MAP.main))
  }
  return (
    <tr key='time-axis' className='time-axis'>
      <td />
      <td>
        <div className='flexed'>
          {days.map(day => {
            return <div key={day}>{day}</div>
          })}
        </div>
      </td>
    </tr>
  )
}

TimeAxis.propTypes = {
  end: PropTypes.instanceOf(moment),
  start: PropTypes.instanceOf(moment),
}

DataGapChart.propTypes = {
  allowCommunicationRedirect: PropTypes.bool,
  allowInstrumentRedirect: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.object),
  end: PropTypes.instanceOf(moment),
  loading: PropTypes.bool,
  range: PropTypes.number,
  start: PropTypes.instanceOf(moment),
  siteID: PropTypes.string,
  studyID: PropTypes.string,
  updateEventData: PropTypes.func,
  updateTooltip: PropTypes.func,
}

export default DataGapChart
