import React from 'react'
import { connect } from 'react-redux'
import DetailsPage from '../components/DetailsPage'

const mapStateToProps = state => {
  const { study } = state
  return {
    studyID: study.currentStudy.id,
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return <DetailsPage {...props} />
})
