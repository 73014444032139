import { UserIsAuthenticated, UserHasValidPassword } from 'containers/authContainers'
import StudiesContainer from './containers/StudiesContainer'

export default () => {
  return {
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, UserIsAuthenticated(UserHasValidPassword(StudiesContainer)), 'studies')
      })
    },
  }
}
