import React from 'react'
import PropTypes from 'prop-types'
import { MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import ItemList from './ItemList'
import ClinroToolbar from './ClinroToolbar'
import ClinroItemEditor from './ClinroItemEditor'
import '../../EditSurvey/stylesheets/Survey.scss'
import { getRandomQuestionId } from '../../../../../../../utils/getRandomQuestionId'

export default class ClinroView extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeItemId: null,
      sortingItemsArr: [],
      sortingItemKey: null,
      firstSiblingQIdArr: [],
    }
  }

  componentDidMount() {
    const { clinro, addItem } = this.props
    if (clinro.order[0].length === 0) {
      const newQId = getRandomQuestionId()
      addItem({
        itemType: 'introduction',
        index: 0,
        newQId,
      })
      this.setState({ activeItemId: newQId })
    } else {
      this.setState({ activeItemId: clinro.order[0][0] })
    }
  }

  componentDidUpdate(prevProps) {
    const { clinro, id } = this.props
    if (id !== prevProps.id) {
      this.setState({ activeItemId: clinro.order[0][0] })
    }
  }

  onDeleteItem = itemId => {
    const { clinro, deleteItem } = this.props
    return () => {
      deleteItem(clinro, itemId)
    }
  }

  onChangeItemType = (itemId, prevType, nextType) => {
    const { changeItemType, clinro } = this.props
    changeItemType(clinro, itemId, prevType, nextType)
  }

  onDeleteChoice = (itemId, choiceId) => {
    const { clinro, deleteChoice } = this.props
    return () => {
      deleteChoice(clinro, itemId, choiceId)
    }
  }

  setActiveItemId = id => {
    this.setState({ activeItemId: id })
  }

  onAddItem = (itemType, index) => {
    const { addItem } = this.props
    return () => {
      const newQId = getRandomQuestionId()
      addItem({
        itemType,
        index,
        newQId,
      })
      this.setActiveItemId(newQId)
    }
  }

  onToggleEdit = itemId => {
    const { activeItemId } = this.props
    if (activeItemId !== itemId) {
      this.setActiveItemId(itemId)
    }
  }

  onRandomize = () => {
    const { openModal, randomizeQuestions } = this.props
    openModal({
      content: MODAL_CONTENT_MAP.shuffleQuestionOrder,
      confirmButton: MODAL_BUTTONS_MAP.proceed,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: randomizeQuestions,
    })
  }

  onUpdateURL = value => {
    const { updateArticleURL } = this.props
    updateArticleURL(value.trim())
  }

  onMoveItem = ({ oldIndex, newIndex }) => {
    const { clinro, moveItem } = this.props
    const currentQuestionId = clinro.order[0][oldIndex]
    const { sibling_id } = clinro.questions[currentQuestionId]
    const selectedItemsArr = sibling_id ? clinro.siblings[sibling_id] : null

    if (newIndex !== 0) {
      moveItem(clinro, oldIndex, newIndex, selectedItemsArr)
    }
    this.setState({
      sortingItemsArr: [],
      sortingItemKey: null,
      firstSiblingQIdArr: [],
    })
  }

  beforeSortStart = ({ node, index }) => {
    const { clinro } = this.props
    const { siblings } = clinro
    const currentQuestionId = clinro.order[0][index]
    const { sibling_id } = clinro.questions[currentQuestionId]
    const selectedItemsArr = sibling_id ? siblings[sibling_id] : []
    const hasSiblings = siblings ? Object.keys(siblings).length > 0 : false

    /**
     * This array will help with the logic for the dragging state UI in regards
     * to showing siblings in their stacked state.
     * */
    const firstSiblingQIdArr = []

    if (hasSiblings) {
      Object.keys(siblings).forEach(siblingId => {
        siblings[siblingId].forEach((qId, idx) => {
          if (idx === 0) {
            firstSiblingQIdArr.push(qId)
          }
        })
      })
    }
    return new Promise(resolve => {
      this.setState(
        {
          sortingItemsArr: selectedItemsArr,
          sortingItemKey: currentQuestionId,
          firstSiblingQIdArr,
        },
        resolve,
      )
    })
  }

  shouldCancelStart(e) {
    return e.target.className !== 'grab-icon'
  }

  render() {
    const {
      clinro,
      downloadInstrument,
      duplicateItem,
      editorStates,
      errors,
      id,
      isLoading,
      onSave,
      studyID,
      type,
      updateTitle,
      version,
      wordCount,
    } = this.props
    const { activeItemId, firstSiblingQIdArr, sortingItemKey, sortingItemsArr } = this.state
    const { title, questions } = clinro

    return (
      <div className='survey-builder'>
        <ClinroToolbar
          studyID={studyID}
          id={id}
          version={version}
          onRandomize={this.onRandomize}
          wordCount={wordCount}
          type={type}
          isLoading={isLoading}
          updateTitle={updateTitle}
          title={title}
          errors={errors}
          download={downloadInstrument}
          onSave={onSave}
          questions={questions}
        />
        <div className='builder-content'>
          <div>
            <div className='main-editor-section'>
              <ItemList
                {...this.props}
                activeItemId={activeItemId}
                firstSiblingQIdArr={firstSiblingQIdArr}
                helperClass='sortableHelper'
                lockAxis='y'
                lockToContainerEdges
                onAddItem={this.onAddItem}
                onChangeItemType={this.onChangeItemType}
                onDeleteChoice={this.onDeleteChoice}
                onDeleteItem={this.onDeleteItem}
                onSortEnd={this.onMoveItem}
                updateBeforeSortStart={this.beforeSortStart}
                onToggleEdit={this.onToggleEdit}
                sortingItemsArr={sortingItemsArr}
                sortingItemKey={sortingItemKey}
                shouldCancelStart={this.shouldCancelStart}
              />
            </div>
            <div className={`right-section${activeItemId ? ' active' : ''}`}>
              {clinro.questions[activeItemId] && (
                <ClinroItemEditor
                  activeItemId={activeItemId}
                  clinro={clinro}
                  editorStates={editorStates}
                  deleteItem={this.onDeleteItem}
                  duplicateItem={duplicateItem}
                  question={clinro.questions[activeItemId]}
                  setActiveItemId={this.setActiveItemId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ClinroView.propTypes = {
  activeItemId: PropTypes.string,
  addChoiceOnPaste: PropTypes.func,
  addItem: PropTypes.func,
  changeItemType: PropTypes.func,
  clinro: PropTypes.shape({
    order: PropTypes.arrayOf(PropTypes.array),
    questions: PropTypes.objectOf(PropTypes.object),
    siblings: PropTypes.objectOf(PropTypes.array),
    title: PropTypes.string,
  }),
  deleteChoice: PropTypes.func,
  deleteItem: PropTypes.func,
  downloadInstrument: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorStates: PropTypes.object,
  errors: PropTypes.object,
  id: PropTypes.string,
  initializeBlankClinro: PropTypes.func,
  instrumentTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  moveItem: PropTypes.func,
  noQuestionsErr: PropTypes.string,
  onSave: PropTypes.func,
  openModal: PropTypes.func,
  randomizeQuestions: PropTypes.func,
  studyID: PropTypes.number,
  titleErr: PropTypes.string,
  type: PropTypes.string,
  toggleEdit: PropTypes.func,
  updateArticleTitle: PropTypes.func,
  updateArticleURL: PropTypes.func,
  updateInstrumentTitle: PropTypes.func,
  updateIntrumentTitle: PropTypes.func,
  updateTitle: PropTypes.func,
  url: PropTypes.string,
  version: PropTypes.number,
  versionOptions: PropTypes.array,
  wordCount: PropTypes.number,
}
