import moment from 'moment'
import { DATE_FORMAT_MAP } from 'utils/constants'

const getPtpAdhocVisitErrors = ptpVisit => {
  const errors = {}
  const { adhocVisitSchedule, checkedPtp, displayName, visitName } = ptpVisit
  const selectedScheduleKey = Object.keys(adhocVisitSchedule).filter(key => adhocVisitSchedule[key].isSelected)[0]
  const { value, visit } = adhocVisitSchedule[selectedScheduleKey]
  if (!visitName || visitName === '') errors.visitName = 'Visit Name cannot be empty'
  if (!displayName || displayName === '') errors.displayName = 'Visit Display Name cannot be empty'
  if (Object.keys(checkedPtp).length === 0) errors.checkedPtp = 'Participant must be selected'
  switch (selectedScheduleKey) {
    case 'specificDate': {
      if (!value || value === '') errors.specificDate = 'Please enter a valid Visit Date'
      else if (moment(value).format(DATE_FORMAT_MAP.datePickerWithFullTime) !== value) {
        errors.specificDate = 'Please check the format of the Visit Date'
      }
      break
    }
    case 'daysBeforeVisit':
      if (!visit.key || visit.key === '') errors.visitSelection = 'Please select a visit'
      break
    default:
      break
  }
  return errors
}

export default getPtpAdhocVisitErrors
