import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button, Container, Divider, Dropdown } from 'components/UIElements'
import VisitorsSelection from './VisitorsSelection'
import VisitTimeSelection from './VisitTimeSelection'
import '../stylesheets/CreateVisits.scss'

const SitesDropdown = ({ onSelect, visitDropdownOptions }) => {
  const { selectedSite, siteDropdownOptions } = visitDropdownOptions
  return (
    <>
      <p className='label-small'>Site</p>
      <Dropdown
        id='site-dropdown'
        placeholder='Select a site'
        onSelect={onSelect}
        options={siteDropdownOptions}
        selected={selectedSite}
      />
    </>
  )
}

const CreateVisitsPage = props => {
  const {
    clearVisitErrors,
    currentVisits,
    createVisits,
    setVisitSite,
    siteID,
    studyID,
    setNavBarProps,
    visitDropdownOptions,
  } = props
  const { selectedSite, siteDropdownOptions } = visitDropdownOptions

  const { participant_id, user_id } = currentVisits

  useEffect(() => {
    setNavBarProps({
      back: `/studies/${studyID}/virtualvisits`,
      backText: 'Virtual Visits',
    })
    clearVisitErrors()
    return () => setNavBarProps(null)
  }, [])

  return (
    <div className='create-visits page'>
      <Container>
        <h3>Schedule a virtual visit</h3>
        <p className='visit-desc'>Virtual visits can be set up between a ClinRO user and a participant.</p>
        {siteDropdownOptions.length > 0 && (
          <SitesDropdown onSelect={item => setVisitSite(item.key)} visitDropdownOptions={visitDropdownOptions} />
        )}
        <Divider color='#dededf' />
        <VisitorsSelection {...props} disabled={!selectedSite} />
        <VisitTimeSelection {...props} disabled={!selectedSite} />
      </Container>
      <div className='button-list'>
        <Button
          grey
          id='close'
          content='Close'
          onClick={() => browserHistory.push(`/studies/${studyID}/virtualvisits`)}
        />
        <Button
          id='schedule'
          disabled={!user_id || !participant_id}
          content='Schedule'
          onClick={() => createVisits(studyID, siteID, currentVisits)}
        />
      </div>
    </div>
  )
}

CreateVisitsPage.propTypes = {
  createVisits: PropTypes.func,
  currentVisits: PropTypes.shape({
    user_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    participant_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visits: PropTypes.arrayOf(
      PropTypes.shape({
        visit_timestamp: PropTypes.string,
        visit_duration: PropTypes.number,
        visit_type: PropTypes.string,
      }),
    ),
  }),
  setNavBarProps: PropTypes.func,
  setVisitSite: PropTypes.func,
  siteID: PropTypes.number,
  studyID: PropTypes.string,
  visitDropdowns: PropTypes.shape({
    siteDropdownOptions: PropTypes.arrayOf(PropTypes.object),
    selectedSite: PropTypes.string,
  }),
}
SitesDropdown.propTypes = CreateVisitsPage.propTypes

export default CreateVisitsPage
