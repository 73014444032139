import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popup } from 'components/UIElements'
import { browserHistory } from 'react-router'
import { USER_ROLES_MAP } from 'utils/constants'
import defaultUserImage from 'assets/Icons/profile-pic.svg'
import alertIcon from 'assets/Icons/alert.svg'
import { icons } from 'assets/assets'

const PopupButton = props => {
  const { disabled, hasAlert, user } = props
  return (
    <Button id='my-profile-settings' className='sidebar-user-trigger box-button' disabled={disabled}>
      <div className='flexed'>
        <div className='icon-wrapper'>
          <div className='prof-pic-wrapper'>
            <img key='img' src={user.userImage || defaultUserImage} alt='prof-pic' />
          </div>
          {hasAlert && <img className='alert' src={alertIcon} alt='has-alert' />}
        </div>
        <span>My Profile & Settings</span>
      </div>
    </Button>
  )
}

const _hasAlert = (role, phone) => {
  if (role === USER_ROLES_MAP.caseManager && !phone) return true
  return false
}

const UserPopup = props => {
  const { disabled, onLogout, user, currentRole } = props
  const { phone } = user
  const onGoToProfile = () => browserHistory.push('/users/me')

  const hasAlert = _hasAlert(currentRole, phone)

  return (
    <Popup disabled={disabled} trigger={<PopupButton {...props} hasAlert={hasAlert} />} position='right' dark noPointer>
      <Button id='my-profile' className='box-button close-trigger' no-margin onClick={() => onGoToProfile()}>
        {icons.myProfile}
        My Profile
        {hasAlert && <img className='alert' src={alertIcon} alt='has-alert' />}
      </Button>
      <Button id='logout' className='close-trigger' no-margin onClick={() => onLogout()}>
        {icons.logout}
        Log out
      </Button>
    </Popup>
  )
}

UserPopup.propTypes = {
  currentRole: PropTypes.string,
  disabled: PropTypes.bool,
  onLogout: PropTypes.func,
  user: PropTypes.shape({
    phone: PropTypes.string,
    userImage: PropTypes.string,
  }),
}
PopupButton.propTypes = {
  disabled: PropTypes.bool,
  hasAlert: PropTypes.bool,
  user: PropTypes.shape({
    userImage: PropTypes.string,
  }),
}

export default UserPopup
