import React from 'react'
import PropTypes from 'prop-types'
import { compareJSON } from 'utils/object'
import { CONSENT_ITEM_EDITOR_ENABLED_QUESTION_TYPES_ARR } from 'utils/constants'
import NewConsentItemList from './NewConsentItemList'
import SurveyToolbar from '../../../../../../Instruments/routes/Instrument/routes/EditSurvey/components/SurveyToolbar'
import NewConsentItemEditor from './NewConsentItemEditor'
import TranslationSelection from './TranslationSelection/TranslationSelection'
import '../stylesheets/NewConsentEditor.scss'
import '../../../../../../Instruments/routes/Instrument/routes/EditSurvey/stylesheets/Survey.scss'
import { getRandomQuestionId } from '../../../../../../../utils/getRandomQuestionId'

export default class NewConsentBuilderPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { activeItemId: null, currentLang: 'en_US', hasConsentError: false, itemEditorExpanded: true }
  }

  componentDidMount() {
    const { consent } = this.props
    this.setState({ activeItemId: consent.order[0][0] })
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props
    if (!compareJSON(errors.questions, prevProps.errors.questions))
      this.setState({ currentLang: 'en_US', hasConsentError: true })
  }

  onMoveItem = ({ oldIndex, newIndex }) => {
    this.props.moveItem(this.props.consent, oldIndex, newIndex)
  }

  onDeleteItem = itemId => {
    return () => {
      this.props.deleteItem(this.props.consent, itemId)
    }
  }

  onChangeItemType = (itemId, prevType, nextType) => {
    this.props.changeItemType(this.props.consent, itemId, prevType, nextType, this.props.isClinro)
  }

  onDeleteChoice = (itemId, choiceId) => {
    return () => {
      this.props.deleteChoice(this.props.consent, itemId, choiceId)
    }
  }

  onAddItem = (itemType, index) => {
    return e => {
      const newQId = getRandomQuestionId()
      this.props.addItem({
        itemType,
        index,
        newQId,
        isClinro: this.props.isClinro,
      })
      this.onToggleItemEditor(true)
      this.setState({ activeItemId: newQId })
    }
  }

  onToggleEdit = itemId => {
    if (this.props.activeItemId !== itemId) {
      this.setState({ activeItemId: itemId })
    }
  }

  itemEditorEnabled = id => {
    const question = this.props.consent.questions[id]
    if (question && CONSENT_ITEM_EDITOR_ENABLED_QUESTION_TYPES_ARR.includes(question.type)) {
      return true
    }
    return false
  }

  onToggleItemEditor = (toggleOn = false) => {
    const { itemEditorExpanded } = this.state
    if (toggleOn) {
      this.setState({ itemEditorExpanded: toggleOn })
    } else {
      this.setState({ itemEditorExpanded: !itemEditorExpanded })
    }
  }

  setCurrentLang = langKey => {
    this.setState({ currentLang: langKey })
  }

  shouldCancelStart(e) {
    return e.target.className !== 'grab-icon'
  }

  dismissConsentError = () => {
    this.setState({ hasConsentError: false })
  }

  render() {
    const { consent, duplicateItem, editorStates, isConsentTwo, studyID, version, study, userId } = this.props
    const { activeItemId, currentLang, hasConsentError, itemEditorExpanded } = this.state
    const { pdf_upload: pdfUpload } = consent
    return (
      <div className='e-consent'>
        <div className='survey-builder'>
          <SurveyToolbar
            studyID={studyID}
            id={consent.id}
            version={version}
            {...this.props}
            isConsent
            isConsentTwo={isConsentTwo}
          />
          <div
            className={`builder-content ${
              !itemEditorExpanded ? ' item-editor-collapsed' : ''
            } flexed start-aligned start-justified`}>
            <div className='left-section'>
              <h4>{`${pdfUpload ? 'PDF' : 'Draft'} Consent Form`}</h4>
              <TranslationSelection
                {...this.props}
                currentLang={currentLang}
                dismissConsentError={this.dismissConsentError}
                hasConsentError={hasConsentError}
                setCurrentLang={this.setCurrentLang}
              />
            </div>
            <div className='main-editor-section'>
              <NewConsentItemList
                {...this.props}
                activeItemId={activeItemId}
                currentLang={currentLang}
                onSortEnd={this.onMoveItem}
                shouldCancelStart={this.shouldCancelStart}
                lockToContainerEdges
                helperClass='sortableHelper sortable-items'
                lockAxis='y'
                onToggleEdit={this.onToggleEdit}
                onDeleteItem={this.onDeleteItem}
                onChangeItemType={this.onChangeItemType}
                onDeleteChoice={this.onDeleteChoice}
                onAddItem={this.onAddItem}
                onToggleItemEditor={this.onToggleItemEditor}
              />
              <div className={`right-section${this.itemEditorEnabled(activeItemId) ? ' active' : ''}`}>
                {consent.questions[activeItemId] &&
                  CONSENT_ITEM_EDITOR_ENABLED_QUESTION_TYPES_ARR.includes(consent.questions[activeItemId]?.type) && (
                    <NewConsentItemEditor
                      activeItemId={activeItemId}
                      consent={consent}
                      duplicateItem={duplicateItem}
                      editorStates={editorStates}
                      onDeleteItem={this.onDeleteItem}
                      question={consent.questions[activeItemId]}
                      onToggleItemEditor={this.onToggleItemEditor}
                      itemEditorExpanded={itemEditorExpanded}
                      study={study}
                      userId={userId}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

NewConsentBuilderPage.propTypes = {
  activeItem: PropTypes.object,
  activeItemId: PropTypes.string,
  addChoiceOnPaste: PropTypes.func,
  addItem: PropTypes.func,
  changeItemType: PropTypes.func,
  consent: PropTypes.object,
  deleteChoice: PropTypes.func,
  deleteItem: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorStates: PropTypes.object,
  errors: PropTypes.shape({
    questions: PropTypes.object,
  }),
  initializeBlankConsent: PropTypes.func,
  isConsentTwo: PropTypes.bool,
  moveItem: PropTypes.func,
  noQuestionsErr: PropTypes.string,
  openModal: PropTypes.func,
  originalSurvey: PropTypes.object,
  params: PropTypes.shape({ studyID: PropTypes.string }),
  randomizeQuestions: PropTypes.func,
  saveConsent: PropTypes.func,
  studyID: PropTypes.number,
  titleErr: PropTypes.string,
  toggleEdit: PropTypes.func,
  updateTitle: PropTypes.func,
  version: PropTypes.number,
  versionOptions: PropTypes.array,
  wordCount: PropTypes.number,
}
