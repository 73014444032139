import React from 'react'
import PropTypes from 'prop-types'
import { Container, Input } from 'components/UIElements'
import STRINGS from 'utils/strings'
import Subheader from '../../../components/Subheader'

function VisitFields(props) {
  const { children, visitTemplate, errors, disabled, updateVisitName, studyLock } = props
  const { template_name: visitName } = visitTemplate
  const { template_name: nameErr } = errors || {}

  return (
    <Container>
      <div className='visit-fields'>
        <Subheader subheaderText={`1. ${STRINGS.visitScheduleVistNameQuestion}`} />
        <div className='names'>
          <Input
            label={STRINGS.visitTemplateName}
            disabled={disabled || studyLock}
            id='visit-name-input'
            className='visit-name-input'
            placeholder={STRINGS.visitName}
            value={visitName || ''}
            onChange={value => updateVisitName(value)}
            spellCheck
            charLimit={100}
            hasError={nameErr && !visitName}
            errorText={nameErr}
          />
        </div>
        {children}
      </div>
    </Container>
  )
}

VisitFields.propTypes = {
  disabled: PropTypes.bool,
  studyLock: PropTypes.bool,
  visitTemplate: PropTypes.shape({ template_name: PropTypes.string }),
  errors: PropTypes.object,
  updateVisitName: PropTypes.func,
  template_name: PropTypes.string,
}

export default VisitFields
