import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { browserHistory } from 'react-router'

import '../../../../../../../stylesheets/Wizard.scss'

const inputs = [
  { text: 'Preferred Name', key: 'fname' },
  { text: 'Last Name', key: 'lname' },
]

class ParticipantWizard extends React.Component {
  componentDidMount() {
    const { updateWizardName } = this.props
    updateWizardName('fname', '')
    updateWizardName('lname', '')
  }

  render() {
    const { studyID, updateWizardName, wizardName, studyLock, participantDataFields = {} } = this.props
    const nameDisabledList = participantDataFields?.disabled
    const inputsDisabled = nameDisabledList
      ? inputs.filter(input => !nameDisabledList.map(data => data.metadata.internal_key).includes(input.key))
      : inputs

    return (
      <div className='participant-wizard page'>
        <div className='wizard'>
          <h1>Add your first participant</h1>
          <p>Efficiently manage your study participants. First enter your participant details.</p>
          <div className='participant-box'>
            {inputsDisabled.map(input => {
              const id = `label-${input.key}`
              return (
                <Fragment key={`ptp-input-${input.key}`}>
                  <label className='label-small' id={id}>
                    {input.text}
                  </label>
                  <input
                    aria-label='ptp'
                    aria-labelledby={id}
                    value={wizardName[input.key]}
                    onChange={e => updateWizardName(input.key, e.target.value)}
                    className='custom-input'
                    id={`wizard-input-${input.key}`}
                    placeholder='Enter name'
                    disabled={studyLock}
                  />
                </Fragment>
              )
            })}
          </div>
          <Button
            disabled={studyLock}
            onClick={() => browserHistory.push(`/studies/${studyID}/participants/create`)}
            content='Add This Participant'
            id='add-first-participant'
          />
        </div>
      </div>
    )
  }
}

ParticipantWizard.propTypes = {
  updateWizardName: PropTypes.func,
  studyID: PropTypes.number,
  siteID: PropTypes.number,
  wizardName: PropTypes.object,
  studyLock: PropTypes.bool,
  participantDataFields: PropTypes.object,
}

export default ParticipantWizard
