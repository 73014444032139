import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import AdvancedOptionsHOC from 'utils/hoc/AdvancedOptionsHOC'
import ConsentRecipients from './ConsentRecipients'
import ConsentDeploymentTiming from './ConsentDeploymentTiming'
import '../stylesheets/consentScheduler.scss'

const ConsentDeploymentScheduler = props => {
  const { onSave, advancedOptions, showAdvancedOption } = props
  const onSaveConsent = (asDraft = true) => {
    const redirectPath = asDraft ? 'consents' : 'editconsent'
    onSave(
      redirectPath,
      false,
      {
        isUpdate: true,
        isJsonSave: true,
        advancedOptions: showAdvancedOption ? advancedOptions : null,
        shouldValidate: true,
      },
      { query: asDraft ? '?tab=drafts' : '' },
    )
  }

  return (
    <div className='consent-scheduler page'>
      <h3>Schedule deployment</h3>
      <h4>Recipient</h4>
      <ConsentRecipients {...props} />
      <h4>Deployment time</h4>
      <ConsentDeploymentTiming {...props} />
      <div className='button-list'>
        <Button grey id='save-as-draft' content='Save and close' onClick={onSaveConsent} />
        <Button id='next' className='deploy-button' content='Save and continue' onClick={() => onSaveConsent(false)} />
      </div>
    </div>
  )
}

ConsentDeploymentScheduler.propTypes = {
  consent: PropTypes.shape({
    consent_id: PropTypes.string,
    consent_status: PropTypes.string,
    metadata: PropTypes.object,
  }),
  onSave: PropTypes.func,
  advancedOptions: PropTypes.object,
  showAdvancedOption: PropTypes.bool,
}

export default AdvancedOptionsHOC(ConsentDeploymentScheduler)
