import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword } from 'containers/authContainers'
import VisitsPageRoute from './routes/VisitsPage'
import CreateVisitsRoute from './routes/CreateVisits'
import visitsReducer from './routes/VisitsPage/modules/Visits'

export default store => {
  return {
    path: 'virtualvisits',
    exact: true,
    getIndexRoute(location, cb) {
      store.dispatch(setSubroute('virtualvisits'))
      const { currentStudy } = store.getState().study
      setPageTitle(`Virtual Visit | Study ${currentStudy.id} | ${currentStudy.platform_name}`)
      injectReducers(store, {
        visits: visitsReducer,
      })
      // TODO: add user scope checks
      cb(null, UserIsAuthenticated(UserHasValidPassword(VisitsPageRoute(store))))
    },
    getChildRoutes(location, cb) {
      cb(null, [CreateVisitsRoute(store)])
    },
  }
}
