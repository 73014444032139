import React, { useState, useEffect } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button, Dropdown, Loader, SearchInput, StickyHeader } from 'components/UIElements'
import { useOffsets, pluralize } from 'utils/misc'
import VisitsTable from './VisitsTable'
import VisitsHistoryTable from './VisitsHistoryTable'
import '../stylesheets/Visits.scss'

const VISITS_TABS = {
  upcoming: 'Upcoming Visit',
  templates: 'Visit Templates',
  history: 'Visit History',
}

const createDropdownOptions = (visits, baseSiteId) => {
  const uniqueSites = {
    [baseSiteId]: 'All sites',
  }
  visits.forEach(visit => {
    uniqueSites[visit.siteId] = visit.leafSiteName
  })
  const uniqueSiteOptions = Object.keys(uniqueSites)
    .map(id => {
      const leafSiteName = uniqueSites[id]
      return { key: id, text: leafSiteName, value: leafSiteName }
    })
    .sort((a, b) => a.text.localeCompare(b.text))
  return uniqueSiteOptions
}

const VisitsPage = props => {
  const {
    canEditVisits,
    deleteVisits,
    loading,
    resetCheckedVisits,
    siteID,
    studyID,
    visits,
    fetchVisitsHistory,
    studyLock,
  } = props
  const [selectedTab, setSelectedTab] = useState(VISITS_TABS.upcoming)
  const [visitFilter, setVisitFilter] = useState(siteID)
  const [selectedDataBox, setSelectedDataBox] = useState(null)
  const offsets = useOffsets('sticky', selectedTab)

  const { checkedVisits, expandedVisits, visitsHistoryList, visitsInfo, visitsList } = visits
  const [listCount, setListCount] = useState(visitsHistoryList.length)

  const filteredVisits =
    Number(visitFilter) === siteID ? visitsList : visitsList.filter(visit => visit.siteId === Number(visitFilter))
  const checkedVisitsArr = Object.keys(checkedVisits)
  const anyCheckedVisits = checkedVisitsArr.length > 0
  const moreThanOneVisitChecked = checkedVisitsArr.length > 1

  const { visitsHistory: visitsHistoryLoading, virtualVisits: virtualVisitsLoading } = loading

  useEffect(() => {
    if (selectedTab === VISITS_TABS.history) {
      fetchVisitsHistory(studyID, siteID)
    }
  }, [selectedTab])

  const onSelectDataBox = nextSelected => {
    if (nextSelected === selectedDataBox) {
      setSelectedDataBox(null)
    } else {
      setSelectedDataBox(nextSelected)
    }
  }

  return (
    <div className='visits page'>
      <div className='selected-button-list'>
        <Button
          id='visits-tab'
          selected={selectedTab === VISITS_TABS.upcoming}
          content={VISITS_TABS.upcoming}
          onClick={() => {
            setSelectedTab(VISITS_TABS.upcoming)
          }}
        />
        {canEditVisits && (
          <Button
            id='history-tab'
            selected={selectedTab === VISITS_TABS.history}
            content={VISITS_TABS.history}
            onClick={() => {
              setSelectedTab(VISITS_TABS.history)
            }}
          />
        )}
      </div>
      {selectedTab === VISITS_TABS.upcoming ? (
        <>
          <StickyHeader offset={offsets ? offsets[0] : 0}>
            <div className='sites-selection flexed'>
              <h3>Upcoming visits</h3>
              <Dropdown
                id='upcoming-visits-dropdown'
                options={createDropdownOptions(visitsList, siteID)}
                selected={visitFilter}
                onSelect={visit => {
                  resetCheckedVisits()
                  setVisitFilter(visit.key)
                }}
              />
            </div>
            <div>
              {anyCheckedVisits && canEditVisits && !studyLock && (
                <Button
                  grey
                  content={`Cancel ${pluralize(checkedVisitsArr.length, 'Visit', 'Visits', false)}`}
                  onClick={() => {
                    deleteVisits(studyID, siteID, checkedVisits, moreThanOneVisitChecked)
                  }}
                  id='cancel-visit-button'
                />
              )}
              {canEditVisits && (
                <Button
                  id='schedule-new-visit'
                  disabled={studyLock}
                  content='Schedule New Visit'
                  onClick={() => !studyLock && browserHistory.push(`/studies/${studyID}/virtualvisits/create`)}
                />
              )}
            </div>
          </StickyHeader>
          {virtualVisitsLoading ? (
            <Loader inContainer className='visits-history-loader' />
          ) : (
            <>
              <VisitsTable
                offset={offsets ? offsets[1] : 0}
                checkedVisitsArr={checkedVisitsArr}
                {...props}
                filteredVisits={filteredVisits}
              />
              {visitsList.length === 0 && (
                <div className='flexed column no-visits'>
                  <h5>There are no upcoming visits</h5>
                  {canEditVisits && <p>Schedule a new visit using the button above</p>}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className='history'>
            <div className=' flexed'>
              <h3>Audit Virtual Visits</h3>
            </div>
            <div className='flexed count-search bar'>
              <p className='label-small'>{`(${listCount}) Past Virtual Visits`}</p>
              <SearchInput />
            </div>
          </div>
          {visitsHistoryLoading ? (
            <Loader inContainer className='visits-history-loader' />
          ) : (
            <>
              <VisitsHistoryTable
                {...props}
                checkedVisitsArr={checkedVisitsArr}
                expandedVisits={expandedVisits}
                offset={offsets ? offsets[0] : 0}
                setListCount={setListCount}
                visitsInfo={visitsInfo}
                visitsHistoryList={visitsHistoryList}
              />
              {visitsHistoryList.length === 0 && (
                <div className='flexed column no-visits'>
                  <h5>There are no past virtual visits</h5>
                  <p>...yet!</p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

VisitsPage.propTypes = {
  canEditVisits: PropTypes.bool,
  deleteVisits: PropTypes.func,
  loading: PropTypes.shape({
    visitsHistory: PropTypes.bool,
  }),
  siteID: PropTypes.number,
  studyID: PropTypes.string,
  resetCheckedVisits: PropTypes.func,
  visits: PropTypes.shape({
    expandedVisits: PropTypes.objectOf(PropTypes.bool),
    visitsHistoryList: PropTypes.arrayOf(PropTypes.array),
    visitsInfo: PropTypes.objectOf(PropTypes.object),
    visitsList: PropTypes.arrayOf(PropTypes.array),
    checkedVisits: PropTypes.objectOf(PropTypes.bool),
  }),
  fetchVisitsHistory: PropTypes.func,
  studyLock: PropTypes.bool,
}

export default VisitsPage
