import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import STRINGS from 'utils/strings'

import { beforeCreate as securityConfigBeforeCreate } from './SecurityConfig'

const beforeCreateStudyCbs = [securityConfigBeforeCreate]

const CreateStudyFooter = ({
  disabled,
  createStudy,
  deleteStudy,
  hasDeleteStudyPermissions,
  study,
  title = STRINGS.createStudy,
  onClick,
}) => {
  const configObj = study.config || study
  const hasSuperGemGiftCards = !!configObj.super_gem_store_payouts
  const hasGiftcardListConfigured = configObj.super_gems?.gift_cards

  if (hasSuperGemGiftCards && !disabled) {
    if (hasGiftcardListConfigured) {
      return (
        <div className='button-list'>
          <Button
            id='create-study-button'
            onClick={onClick ? onClick : () => createStudy(study, beforeCreateStudyCbs)}
            content={title}
          />
        </div>
      )
    }
    return null
  }
  if (!disabled) {
    return (
      <div className='button-list'>
        <Button
          id='create-study-button'
          onClick={onClick ? onClick : () => createStudy(study, beforeCreateStudyCbs)}
          content={title}
        />
      </div>
    )
  }
  if (hasDeleteStudyPermissions) {
    return (
      <div className='button-list delete'>
        <Button icon='fas fa-trash' id='delete-study' content='Delete' onClick={() => deleteStudy(study.id)} />
      </div>
    )
  }
  return null
}

CreateStudyFooter.propTypes = {
  createStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  disabled: PropTypes.bool,
  hasDeleteStudyPermissions: PropTypes.bool,
  study: PropTypes.shape({
    self_sign_code: PropTypes.string,
    name: PropTypes.string,
    platform_name: PropTypes.string,
    id: PropTypes.number,
  }),
  title: PropTypes.string,
  onClick: PropTypes.func,
}

export default CreateStudyFooter
