import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserHasFeatures } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import ConsentsDashboardContainer from './containers/ConsentsDashboardContainer'
import { fetchConsents } from './modules/Consents'
import { fetchLocales, localesMap } from '../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'
import { fetchCohorts } from '../../../Participants/routes/ParticipantsPage/modules/Participants'

export default store => {
  return {
    getComponent(nextState, cb) {
      require.ensure(
        [],
        () => {
          const { params } = nextState
          const { studyID } = params
          injectReducers(store, { localesMap, sites: sitesReducer })
          Promise.allSettled([
            store.dispatch(fetchCohorts(studyID, false)),
            store.dispatch(fetchSites(studyID, true, false, false)), // params: studyID, hasLoader, includeLang, clearCheckedSites])
            store.dispatch(fetchLocales()),
          ])
          store.dispatch(fetchConsents(studyID))
          cb(
            null,
            UserIsAuthenticated(
              UserHasValidPassword(
                UserHasScope(
                  ['rc', 'xc'],
                  UserHasFeatures(ConsentsDashboardContainer, ['consent_required'], 'compliance', true),
                  false,
                  studyID,
                ),
              ),
            ),
          )
        },
        'consents',
      )
    },
  }
}
