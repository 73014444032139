import request, { generateNotyMessage } from 'utils/request'
import { actions as notyActions } from 'layouts/ErrorBox'
import { browserHistory } from 'react-router'
import { sendingActions } from 'store/sender'
import STRINGS from 'utils/strings'
import { REDIRECT_DELAY } from 'utils/constants'

const uploadStudyAsset = ({ formData, studyID, loadingKey = 'mediaUpload' }) => {
  const success = payload => {
    const { filename } = payload
    return {
      filename,
    }
  }
  return dispatch => {
    return dispatch(
      request({
        method: 'POST',
        url: `/control/studies/${studyID}/assets/upload`,
        contentType: 'multipart/form-data',
        body: formData,
        hasLoader: true,
        loadingKey,
        success,
      }),
    )
  }
}

const uploadTrainingMaterial = ({ trainingMaterial, studyID }) => {
  return dispatch => {
    const success = () => {
      dispatch(sendingActions.stopSender())
      setTimeout(() => {
        dispatch(sendingActions.resetSender())
        browserHistory.push(`/studies/${studyID}/training`)
      }, REDIRECT_DELAY)
    }
    const fail = (res, content) => {
      dispatch(notyActions.showError({ text: generateNotyMessage(content.message, false) }))
      dispatch(sendingActions.resetSender())
    }
    dispatch(sendingActions.startSender())
    return dispatch(
      request({
        method: 'POST',
        url: `/control/studies/${studyID}/training`,
        body: JSON.stringify(trainingMaterial),
        hasLoader: true,
        success,
        successMessage: STRINGS.trainingUploadSuccess,
        fail,
      }),
    )
  }
}

const actions = {
  uploadStudyAsset,
  uploadTrainingMaterial,
}

export default actions
