import React from 'react'
import PropTypes from 'prop-types'
import { RichButton, Container } from 'components/UIElements'
import { CONSENT_OPTIONS_IDS } from 'utils/constants'

const ConsentFormat = props => {
  const { disabled, options, selectedOption, setSelectedOption, updateConsentPdfUpload } = props

  return (
    <Container>
      <div className='consent-builder'>
        <div className='flexed start-aligned'>
          <div>
            <h5 className='heading'>{selectedOption.heading}</h5>
            <p className='body-text'>{selectedOption.bodyText}</p>
          </div>
          <div className='flexed start-aligned'>
            {options.map(option => (
              <RichButton
                key={option.id}
                disabled={disabled}
                graphicElement={option.icon}
                isRadio
                label={option.heading}
                className='square-shape'
                selected={option.id === selectedOption.id}
                onClick={() => {
                  setSelectedOption(option)
                  updateConsentPdfUpload(option.id === CONSENT_OPTIONS_IDS.pdfUpload)
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
}

ConsentFormat.propTypes = {
  disabled: PropTypes.bool,
}

export default ConsentFormat
