import React from 'react'
import { Container, Input, Checkbox, Divider } from 'components/UIElements'
import '../stylesheets/createStudy.scss'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { STUDY_CONFIG_MAP, MIN_SESSION_TIMEOUT_MINUTES } from 'utils/constants'
import STRINGS from 'utils/strings'
import { pluralize } from 'utils/misc'
import { confettiIcon } from 'assets/assets'
import { generatePClassName, CREATE_STUDY_FULL_URLS } from '../utils/studyUtils'
import BinaryConfig from './BinaryConfig'
import EnforcedLanguagesConfig from './EnforcedLanguagesConfig'
import SecurityConfig from './SecurityConfig'
import CreateStudyFooter from './CreateStudyFooter'
import ParticipantOfflineDuration from './ParticipantOfflineDuration'

const richButtonIconMap = {
  ban: <i className='fas fa-ban' />,
  squareCheck: <i className='fas fa-check-square' />,
  dataSync: (
    <div className='data-sync-icon flexed column'>
      <div />
    </div>
  ),
  confetti: confettiIcon,
  iconContainer: icon => {
    return (
      <div className='mid-term-reward-icon flexed column end-justified'>
        <div>{icon()}</div>
      </div>
    )
  },
}

class CreateStudySetting extends React.Component {
  updateStudyConfig = newConfig => {
    const { study, updateConfig } = this.props
    const config = study.config || {}
    const _config = config || {}
    updateConfig({
      ..._config,
      ...{ ...newConfig },
    })
  }

  deleteConfigKey = key => {
    const { study, updateConfig } = this.props
    const config = study.config || {}
    if (!config) return
    const newConfig = config
    delete newConfig[key]
    updateConfig(newConfig)
  }

  getHoursAndMins(mins) {
    const hours = Math.floor(mins / 60)
    const leftMins = mins % 60
    return [hours, leftMins]
  }

  renderTimeoutInput = sessionTimeoutConfig => {
    const { disabled, study } = this.props
    const configObj = study.config || study
    const { admin_security, session_timeout_seconds } = configObj
    const { updateValue, updateStudySecurityConfig, key, currentSettings } = sessionTimeoutConfig || {}
    const onChange = sessionTimeoutConfig
      ? updateValue(updateStudySecurityConfig, key, currentSettings)
      : val => this.updateStudyConfig({ session_timeout_seconds: val * 60 })
    const sessionValue = sessionTimeoutConfig
      ? disabled && !admin_security?.[key]
        ? '720'
        : admin_security?.[key] || ''
      : session_timeout_seconds
      ? session_timeout_seconds / 60
      : ''
    const [hours, mins] = this.getHoursAndMins(sessionValue)
    const exampleTime =
      hours > 0 ? ` ( ${pluralize(hours, 'hour', 'hours')} ${mins > 0 ? pluralize(mins, 'min ', 'mins ') : ''}) ` : ' '
    return (
      <Input
        className='user-session-timeout'
        disabled={disabled}
        inline
        onChange={onChange}
        onBlur={event => {
          if (Number(event.target.value) < MIN_SESSION_TIMEOUT_MINUTES) {
            if (sessionTimeoutConfig) {
              updateValue(updateStudySecurityConfig, key, currentSettings)
              this.updateStudyConfig({
                admin_security: {
                  ...admin_security,
                  [key]: MIN_SESSION_TIMEOUT_MINUTES,
                },
              })
            } else {
              this.updateStudyConfig({ session_timeout_seconds: MIN_SESSION_TIMEOUT_MINUTES * 60 })
            }
          }
        }}
        pretext='Timeout after'
        posttext={`${pluralize(sessionValue, 'minute', 'minutes', false)}${exampleTime}`}
        preventInput={num => num < 0}
        id='session-timeout'
        type='number'
        value={sessionValue}
      />
    )
  }

  render() {
    const { disabled, isRootOrDchAdmin, saveAttempted, study } = this.props
    const configObj = study.config || study
    const { hasEnforcedLanguage, enforced_language } = configObj
    const configProps = {
      ...this.props,
      configObj,
      deleteConfigKey: this.deleteConfigKey,
      updateConfig: this.updateStudyConfig,
    }
    const hasLanguageError =
      saveAttempted && hasEnforcedLanguage && !!enforced_language && enforced_language.default_language === ''

    const configCheckbox = ({ id, label, coupledKeyString, keyString, toggle = false, val = {} }) => {
      return (
        <Checkbox
          id={id}
          toggle={toggle}
          label={label}
          checked={!!configObj[keyString]}
          disabled={disabled}
          onClick={() => {
            const enabled = configObj[keyString]
            if (!enabled) {
              this.updateStudyConfig({ [keyString]: val })
              if (coupledKeyString) this.updateStudyConfig({ [coupledKeyString]: val })
            } else {
              this.deleteConfigKey(keyString)
              if (coupledKeyString) this.deleteConfigKey(coupledKeyString)
            }
          }}
        />
      )
    }

    const subjectIdConfig = (
      <div>
        <h5>{STRINGS.ptpSubjectId}</h5>
        <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.showParticipantUniqueIdentifier, disabled)}>
          {STRINGS.subjectId}
        </p>
        {configCheckbox({
          id: 'subject-id',
          label: STRINGS.showPtpSubjectId,
          keyString: STUDY_CONFIG_MAP.showParticipantUniqueIdentifier,
          toggle: false,
          val: {
            type: 'subject_id?subject_id:participant_id',
          },
        })}
      </div>
    )

    const endOfStudyConfig = (
      <div>
        <h5>{STRINGS.endOfStudySummary}</h5>
        <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.studyEnd, disabled)}>
          {STRINGS.endOfStudyConfigBody}
        </p>
        {configCheckbox({
          id: 'end-of-summary',
          label: STRINGS.displayEndOfStudy,
          keyString: STUDY_CONFIG_MAP.studyEnd,
        })}
      </div>
    )

    const ParticipantSection = (
      <Container>
        <h3>{STRINGS.ptpAppSettings}</h3>
        {isRootOrDchAdmin && (
          <>
            <h5>{STRINGS.languages}</h5>
            <EnforcedLanguagesConfig
              {...this.props}
              resetEnforcedLanguage={() => this.updateStudyConfig({ hasEnforcedLanguage: false })}
              updateConfig={this.updateStudyConfig}
              hasError={hasLanguageError}
            />
            <Divider color='#dfe1f1' />
            {subjectIdConfig}
            <Divider color='#dfe1f1' />
            {endOfStudyConfig}
            <Divider color='#dfe1f1' />
          </>
        )}
        <h5>Session Time Out</h5>
        {this.renderTimeoutInput()}
      </Container>
    )

    const SecuritySection = (
      <Container>
        <h3>{STRINGS.security}</h3>
        <SecurityConfig
          {...configProps}
          updateStudySecurityConfig={newConfig => this.updateStudyConfig({ admin_security: { ...newConfig } })}
          renderTimeoutInput={this.renderTimeoutInput}
        />
      </Container>
    )

    const ToneSection = (
      <Container>
        <BinaryConfig
          {...this.props}
          bodyText={STRINGS.toneOfVoiceBody}
          buttonIcons={{ leftIcon: richButtonIconMap.squareCheck, rightIcon: richButtonIconMap.confetti }}
          buttonLabels={{ left: STRINGS.neutral, right: STRINGS.celebratory }}
          configKey={STUDY_CONFIG_MAP.toneOfVoice}
          defaultIsFalse
          heading={STRINGS.toneOfVoice}
          updateStudyConfig={this.updateStudyConfig}
        />
      </Container>
    )

    const DataSyncSection = (
      <Container>
        <BinaryConfig
          {...this.props}
          bodyText={STRINGS.dataSyncConfigBody}
          buttonIcons={{ leftIcon: richButtonIconMap.ban, rightIcon: richButtonIconMap.dataSync }}
          buttonLabels={{ left: STRINGS.hide, right: STRINGS.show }}
          configKey={STUDY_CONFIG_MAP.dataSyncUI}
          heading={STRINGS.dataSync}
          updateStudyConfig={this.updateStudyConfig}
        />
        <ParticipantOfflineDuration study={study} disabled={disabled} updateStudyConfig={this.updateStudyConfig} />
      </Container>
    )

    return (
      <>
        {ParticipantSection}
        {ToneSection}
        {DataSyncSection}
        {SecuritySection}
        <CreateStudyFooter
          {...this.props}
          title={STRINGS.next}
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.schedule)}
        />
      </>
    )
  }
}

CreateStudySetting.propTypes = {
  createStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  disabled: PropTypes.bool,
  fetchLocales: PropTypes.func,
  hasDeleteStudyPermissions: PropTypes.bool,
  isRootOrDchAdmin: PropTypes.bool,
  isWizard: PropTypes.bool,
  saveAttempted: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  study: PropTypes.shape({
    self_sign_code: PropTypes.string,
    name: PropTypes.string,
    platform_name: PropTypes.string,
    id: PropTypes.number,
  }),
  updateConfig: PropTypes.func,
  updateStudy: PropTypes.func,
}

export default CreateStudySetting
