import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getUserRoleIdByStudy } from 'utils/misc'
import { modalActions } from 'store/modal'
import { getHeaderName } from '../../../../../utils/visitNameUtils'
import { visitActions } from '../modules/TemplateVisit'
import VisitPage from '../components/VisitPage'

const mapDispatchToProps = { ...visitActions, ...modalActions }

const mapStateToProps = (state, ownProps) => {
  const { loading, location, sending, study, subroute, user, templateVisitReducer, studyLock } = state
  const { isDeploy, sendingState, sentState } = sending
  const { templateVisitId, parentScheduleId } = ownProps.params
  const { currentStudy } = study
  const path = location.pathname.match(/([^/]+$)/)[0]
  const studyID = currentStudy.id

  return {
    ...templateVisitReducer,
    isEdit: path === 'edit',
    isLoading: loading.general,
    isSiteCoord: getUserRoleIdByStudy(user, studyID) === 8,
    parentScheduleId,
    path,
    saveSending: !isDeploy && sendingState,
    saveSent: !isDeploy && sentState,
    sendDisabled: sendingState || sentState,
    studyID,
    studyLock,
    subtitleName: getHeaderName(path, subroute),
    templateVisitId,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <VisitPage {...props} />
})
