import { MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import { browserHistory } from 'react-router'

export const calculateCurrentCycle = ({ cycles, daysElapsed, hasDynamicCycles, studyOver }) => {
  // No current cycle if study is over or if study has no cycles
  if (!cycles || studyOver) return null

  // If it is day 1, the participant is in Cycle 1
  if (daysElapsed <= 0) return 1

  // Current cycle in static cycles calculation
  if (!hasDynamicCycles) {
    const cycle = cycles[0]
    const { cycleDuration } = cycle
    // Need to plus `cycleDuration` because if not - we are getting a number of completed chapters
    // `cycleDuration` is always in days, not weeks, so we don't need to make any convertions
    return Math.ceil((daysElapsed + cycleDuration) / cycleDuration)
  }

  let currentDifference = Number(daysElapsed)
  let currentCycle = 0
  while (currentDifference > 0) {
    /**
     * We take the days that have elapsed and loop through the dynamic cycles
     * of varying length of days to calculated and return what the current cycle
     * the participant is in.
     * */
    cycles.some(cycle => {
      const { cycleDuration } = cycle
      currentCycle += 1
      currentDifference -= cycleDuration
      if (currentDifference <= 0) return true // We break out the loop once all the daysElapsed have been account for
    })
  }
  return currentCycle
}

export const generateDefaultPtpValMap = study => {
  const { config, id } = study
  const resultMap = {}
  if (!config || !id) return resultMap
  const { participant_data_fields: ptpDataFields } = config
  const { disabled, mandatory, optional } = ptpDataFields
  const fieldsArr = [...disabled, ...mandatory, ...optional]
  fieldsArr.forEach(ptpField => {
    const { metadata } = ptpField
    const { internal_key, default_value } = metadata
    resultMap[internal_key] = default_value
  })
  return resultMap
}

export const onOpenCancelModal = props => {
  const { closeModal, openModal, studyID } = props
  openModal({
    onConfirm: () => {
      closeModal()
      browserHistory.push(`/studies/${studyID}/participants`)
    },
    onCancel: () => {
      closeModal()
    },
    heading: MODAL_CONTENT_MAP.leavingThisPage,
    content: MODAL_CONTENT_MAP.closingThisPage,
    confirmButton: MODAL_BUTTONS_MAP.confirmAndClose,
    cancelButton: MODAL_BUTTONS_MAP.cancel,
    className: MODAL_CLASSES_MAP.confirmation,
    closeOnBackgroundClick: true,
  })
}
