export const formatDomainsToList = (domains = {}) => {
  const domainIdList = Object.keys(domains)
  if (domainIdList.length === 0) return []
  const domainsList = domainIdList.map(domainId => {
    return {
      key: domainId,
      text: domains[domainId].label,
    }
  })
  return domainsList
}

export const formatDomainsToTagsList = (domains = {}, isDeleted = false) => {
  const domainIdList = Object.keys(domains)
  if (domainIdList.length === 0) return []
  const domainsList = domainIdList.map(domainId => {
    const result = {
      id: domainId,
      value: domains[domainId].label,
    }
    if (isDeleted) result.class = 'redTag'
    return result
  })
  return domainsList
}

export const formatQuestionDomainsToCheckedObject = (domainsList = []) => {
  const checked = {}
  if (domainsList.length === 0) return checked
  domainsList.forEach(domainId => {
    checked[domainId] = true
  })
  return checked
}

export const formatQuestionDomainsToTagList = (itemDomainsList = [], instrumentDomains) => {
  if (itemDomainsList.length === 0) return itemDomainsList
  return itemDomainsList.map(domainId => {
    return {
      id: domainId,
      text: instrumentDomains[domainId].label,
    }
  })
}

// This helper func checks if a domain is in use in the instrument
export const getDomainIsUtilized = ({ questionsWithScoring, domainId }) => {
  const questionArr = Object.keys(questionsWithScoring)
  return questionArr.some(questionId => questionsWithScoring[questionId].includes(domainId))
}

export const formatMixedTextStringToFormula = mixedString => {
  let newString = mixedString.slice()
  const regexToFindTags = /\[\[(.*?)\]\]/g // looks for '[[ stuff ]]' in a string
  const matches = mixedString.match(regexToFindTags)
  if (matches) {
    matches.forEach(match => {
      const tagObj = JSON.parse(match.slice(2, match.length - 2))
      const domainId = tagObj.id
      newString = newString.replace(match, `${domainId}[]`)
    })
  }
  return newString
}

export const SELECT_MULTIPLE_CALC_TYPE_ARR = [
  { key: 'MEAN', value: 'MEAN', text: 'Average' },
  { key: 'SUM', value: 'SUM', text: 'Sum' },
]
