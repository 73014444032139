import { injectReducers } from 'store/reducers'
import instrumentReducer from './modules/Instrument'
import surveyEditor from './routes/EditSurvey/modules/Survey'
import clinroEditor from './routes/EditClinro/modules/Clinro'
import diaryEditor from './routes/EditDiary/modules/Diary'
import selectTask from './routes/SelectTask/modules/SelectTask'
import uploadReducer from './routes/UploadSurvey/modules/UploadSurvey'
import InstrumentView from './containers/InstrumentViewContainer'
import participantReducer from '../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import participantsReducer from '../../../Participants/routes/ParticipantsPage/modules/Participants'
import CreateSurveyRoute from './routes/CreateSurvey'
import EditSurveyRoute from './routes/EditSurvey'
import CreateClinroRoute from './routes/CreateClinro'
import EditClinroRoute from './routes/EditClinro'
import CreateDiaryRoute from './routes/CreateDiary'
import EditDiaryRoute from './routes/EditDiary'
import CreateArticleRoute from './routes/CreateArticle'
import EditArticleRoute from './routes/EditArticle'
import EditTaskRoute from './routes/EditTask'
import SelectTaskRoute from './routes/SelectTask'
import SchedulerRoute from './routes/Scheduler'
import MetadataBuilderRoute from './routes/MetadataBuilder'
import UploadSurveyRoute from './routes/UploadSurvey'
import ScoringRoute from './routes/Scoring'
import InstrumentPreview from './routes/InstrumentPreview'

// Sync route definition
export const injectInstrumentReducers = store => {
  injectReducers(store, {
    instrumentReducer,
    surveyEditor,
    clinroEditor,
    diaryEditor,
    selectTask,
    participantReducer,
    participants: participantsReducer,
    upload: uploadReducer,
  })
}

export default store => ({
  component: InstrumentView,
  /*  Async getComponent is only invoked when route matches   */
  getChildRoutes(location, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      require => {
        /*  Webpack - use require callback to define
       dependencies for bundling   */
        injectInstrumentReducers(store)

        /*  Return getComponent   */
        cb(null, [
          CreateClinroRoute(store),
          EditClinroRoute(store),
          CreateSurveyRoute(store),
          EditSurveyRoute(store),
          CreateDiaryRoute(store),
          EditDiaryRoute(store),
          CreateArticleRoute(store),
          EditArticleRoute(store),
          EditTaskRoute(store),
          InstrumentPreview(store),
          SelectTaskRoute(store),
          MetadataBuilderRoute(store),
          UploadSurveyRoute(store),
          SchedulerRoute(store),
          ScoringRoute(store),
        ])

        /* Webpack named bundle   */
      },
      'instrument',
    )
  },
})
