import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ChoicesList from '../../../../../../Instruments/routes/Instrument/routes/EditSurvey/components/ChoicesList'
import { selectViewActions } from '../modules/NewConsent'

class SelectView extends React.Component {
  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (prevProps.item.type !== item.type) {
      this.el.querySelector('textarea').focus()
    }
  }

  shouldCancelStart = e => {
    const { isActive } = this.props
    return !isActive || !e.target.className.includes('choice-grab-icon')
  }

  onAddChoice = idx => {
    const { addChoice, item, itemId } = this.props
    return () => {
      addChoice(itemId, item.choices_order.length, idx)
    }
  }

  render() {
    const { itemId, moveChoice, preview } = this.props
    return (
      <div
        ref={el => {
          this.el = el
        }}
        className='multi-choice-container'>
        <ChoicesList
          isConsent
          preview={preview}
          onSortEnd={({ oldIndex, newIndex }) => moveChoice(itemId, oldIndex, newIndex)}
          lockAxis='y'
          lockToContainerEdges
          helperClass='sortableHelper choices'
          shouldCancelStart={this.shouldCancelStart}
          onAddChoice={this.onAddChoice}
          {...this.props}
        />
      </div>
    )
  }
}

export default connect(null, selectViewActions)(SelectView)

SelectView.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.string,
  moveChoice: PropTypes.func,
  addChoice: PropTypes.func,
  isActive: PropTypes.bool,
  preview: PropTypes.bool,
}
