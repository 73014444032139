import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import TimePicker from 'rc-time-picker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { Dropdown } from 'components/UIElements'
import { datePickerConfig } from 'utils/time'
import 'rc-time-picker/assets/index.css'
import { isNumeric } from 'utils/misc'
import { QUESTION_TYPE_MAP, DATE_FORMAT_MAP } from 'utils/constants'

const comparatorsForSelectOne = [
  { key: 'is_selected', text: 'is the following answer', value: 'is_selected' },
  { key: 'is_not_selected', text: 'is not the following answer', value: 'is_not_selected' },
  { key: 'is_answered', text: 'is answered', value: 'is_answered' },
  { key: 'is_not_answered', text: 'is not answered', value: 'is_not_answered' },
]

const comparatorsForSelectMulti = [
  { key: 'is_selected', text: 'includes one of the following answers', value: 'is_selected' },
  { key: 'is_not_selected', text: 'does not include one of the following answers', value: 'is_not_selected' },
  { key: 'is_answered', text: 'is answered', value: 'is_answered' },
  { key: 'not_answered', text: 'is not answered', value: 'is_not_answered' },
]

const comparatorsForNumber = [
  { key: '=', text: 'is exactly equal to', value: '=' },
  { key: '!=', text: 'is not exactly equal to', value: '!=' },
  { key: '>', text: '> (greater than)', value: '>' },
  { key: '>=', text: '>= (greater than or equal to)', value: '>=' },
  { key: '<', text: '< (less than)', value: '<' },
  { key: '<=', text: '<= (less than or equal to)', value: '<=' },
  { key: 'is_answered', text: 'is answered', value: 'is_answered' },
  { key: 'is_not_answered', text: 'is not answered', value: 'is_not_answered' },
]

const comparatorsForOther = [
  { key: '=', text: 'is exactly equal to', value: '=' },
  { key: '!=', text: 'is not exactly equal to', value: '!=' },
  { key: 'is_answered', text: 'is answered', value: 'is_answered' },
  { key: 'is_not_answered', text: 'is not answered', value: 'is_not_answered' },
]

const SelectDropdown = props => {
  const { conditionPath, disabled, editorStates, itemId, logic, questions, term, updateLogicCondition } = props
  const questionType = questions[term.question_id].type
  const isSelect = [QUESTION_TYPE_MAP.selectOne, QUESTION_TYPE_MAP.selectMultiple].includes(questionType)
  const choiceOptions = questions[term.question_id].choices_order.map(choiceId => {
    let text = questions[term.question_id].choices[choiceId].label
    if (isSelect && !!editorStates[choiceId]) text = editorStates[choiceId].getCurrentContent().getPlainText()
    if (text.length > 30) text = `${text.slice(0, 30)}...`
    return {
      key: choiceId,
      text,
      value: choiceId,
    }
  })
  return (
    <Dropdown
      className='value-dropdown'
      onSelect={item => updateLogicCondition(itemId, logic, conditionPath, 'value', item.value)}
      disabled={disabled}
      options={choiceOptions}
      selected={term.value}
    />
  )
}

const InputField = props => {
  const isDecimal = props.term.question_type === QUESTION_TYPE_MAP.decimal
  const isInteger = props.term.question_type === QUESTION_TYPE_MAP.integer
  const isVAS = [QUESTION_TYPE_MAP.vasHorizontal, QUESTION_TYPE_MAP.vaScale, QUESTION_TYPE_MAP.vaScale2021].includes(
    props.term.question_type,
  )
  const isNumber = isDecimal || isInteger || isVAS
  const updateCondition = value => {
    props.updateLogicCondition(props.itemId, props.logic, props.conditionPath, 'value', value)
  }
  const onBlur = e => {
    const val = e.target.value
    if (val === '' || val === '-') {
      updateCondition('')
    } else {
      if (isDecimal) {
        updateCondition(parseFloat(val))
      } else if (isInteger || isVAS) {
        updateCondition(parseInt(val))
      }
    }
  }
  const onChange = e => {
    let value = e.target.value
    if (!isNumber || value === '' || value === '-' || (isDecimal && isNumeric(value))) {
      updateCondition(value)
    } else if ((isInteger && isNumeric(value)) || (isVAS && isNumeric(value))) {
      updateCondition(parseInt(value))
    }
  }
  return (
    <input
      onBlur={onBlur}
      disabled={props.disabled}
      className='logic-input'
      placeholder={`${props.term.question_type}`}
      onChange={onChange}
      value={props.term.value}
      type={isVAS ? 'number' : 'text'}
    />
  )
}

const DateField = props => (
  <div className='date-picker'>
    <DatePicker
      locale={datePickerConfig.locale}
      disabled={props.disabled}
      selected={props.term.value ? moment(props.term.value.slice(0, props.term.value.length - 1)) : null}
      onChange={mom =>
        props.updateLogicCondition(
          props.itemId,
          props.logic,
          props.conditionPath,
          'value',
          mom.format('YYYY-MM-DDT00:00:00.000') + 'Z',
        )
      }
      dropdownMode='select'
      dateFormat={datePickerConfig.format}
      placeholderText={datePickerConfig.placeholder}
    />
  </div>
)

const TimeField = props => {
  const { term } = props
  const formattedTime = '0000-01-01' + term.value.slice(10, term.value.length - 1)
  return (
    <TimePicker
      allowEmpty={false}
      className='time-picker'
      format='H:mm'
      placeholder='hh:mm'
      showSecond={false}
      value={term.value ? moment(formattedTime) : null}
      onChange={mom => {
        props.updateLogicCondition(
          props.itemId,
          props.logic,
          props.conditionPath,
          'value',
          mom.format('0000-00-00THH:mm:00.000') + 'Z',
        )
      }}
    />
  )
}

const DateTimeField = props => {
  const { disabled, term } = props
  const date = term.value ? term.value.split('T')[0] : ''
  const time = term.value ? term.value.split('T')[1] : ''
  return (
    <div className='logic-datetime-picker'>
      <div className='date-picker stacked'>
        <DatePicker
          locale={datePickerConfig.locale}
          disabled={disabled}
          selected={date ? moment(term.value.slice(0, term.value.length - 1)) : null}
          onChange={mom =>
            props.updateLogicCondition(
              props.itemId,
              props.logic,
              props.conditionPath,
              'value',
              mom.format(DATE_FORMAT_MAP.datePicker) + 'T' + (time || '00:00:00.000Z'),
            )
          }
          dropdownMode='select'
          dateFormat={datePickerConfig.format}
          placeholderText={datePickerConfig.placeholder}
        />
      </div>
      <TimePicker
        allowEmpty={false}
        disabled={disabled}
        className='time-picker stacked'
        format='H:mm'
        placeholder='hh:mm'
        showSecond={false}
        value={time ? moment(time, 'H:mm') : null}
        onChange={mom =>
          props.updateLogicCondition(
            props.itemId,
            props.logic,
            props.conditionPath,
            'value',
            (date || moment().format(DATE_FORMAT_MAP.datePicker)) + 'T' + mom.format('HH:mm:00.000') + 'Z',
          )
        }
      />
    </div>
  )
}

const LogicCondition = props => {
  let comparatorOptions
  const disabled = props.term.comparator.includes('answered')
  let valueField = <InputField disabled={disabled} {...props} />
  switch (props.term.question_type) {
    case QUESTION_TYPE_MAP.likert:
    case QUESTION_TYPE_MAP.longListSelectOne:
    case QUESTION_TYPE_MAP.selectOne:
    case QUESTION_TYPE_MAP.numericRatingScale:
      comparatorOptions = comparatorsForSelectOne
      valueField = <SelectDropdown disabled={disabled} {...props} />
      break
    case QUESTION_TYPE_MAP.selectMultiple:
      comparatorOptions = comparatorsForSelectMulti
      valueField = <SelectDropdown disabled={disabled} {...props} />
      break
    case QUESTION_TYPE_MAP.integer:
    case QUESTION_TYPE_MAP.decimal:
    case QUESTION_TYPE_MAP.vasHorizontal:
    case QUESTION_TYPE_MAP.vaScale:
    case QUESTION_TYPE_MAP.vaScale2021:
      comparatorOptions = comparatorsForNumber
      break
    case QUESTION_TYPE_MAP.date:
      comparatorOptions = comparatorsForNumber
      valueField = <DateField disabled={disabled} {...props} />
      break
    case QUESTION_TYPE_MAP.time: {
      comparatorOptions = comparatorsForNumber
      valueField = <TimeField disabled={disabled} {...props} />
      break
    }
    case QUESTION_TYPE_MAP.datetime: {
      comparatorOptions = comparatorsForNumber
      valueField = <DateTimeField disabled={disabled} {...props} />
      break
    }
    default:
      comparatorOptions = comparatorsForOther
  }
  if (props.questionRequired) {
    comparatorOptions = comparatorOptions.slice(0, comparatorOptions.length - 2)
  }

  return (
    <div className='logic-condition'>
      <Dropdown
        className='question-dropdown'
        onSelect={item =>
          props.updateLogicCondition(props.itemId, props.logic, props.conditionPath, 'question_id', item.value)
        }
        options={props.questionOptions}
        required
        selected={props.term.question_id}
      />
      <Dropdown
        className='comparator-dropdown'
        onSelect={item =>
          props.updateLogicCondition(props.itemId, props.logic, props.conditionPath, 'comparator', item.value)
        }
        options={comparatorOptions}
        selected={props.term.comparator}
      />
      {valueField}
      <i
        onClick={() => props.deleteLogicCondition(props.itemId, props.conditionPath)}
        className='fas fa-trash clickable'
      />
    </div>
  )
}

LogicCondition.propTypes = {
  itemId: PropTypes.string,
  term: PropTypes.object,
  questionRequired: PropTypes.bool,
  questionOptions: PropTypes.array,
  logic: PropTypes.object,
  conditionPath: PropTypes.array,
  updateLogicCondition: PropTypes.func,
  deleteLogicCondition: PropTypes.func,
}

SelectDropdown.propTypes = LogicCondition.propTypes
InputField.propTypes = LogicCondition.propTypes
DateField.propTypes = LogicCondition.propTypes
TimeField.propTypes = LogicCondition.propTypes
DateTimeField.propTypes = LogicCondition.propTypes

export default LogicCondition
