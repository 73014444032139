import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import { Button } from 'components/UIElements'
import PropTypes from 'prop-types'
import { getUserScope, getUserScopeByStudy } from 'utils/misc'
import { setUserLinktFlag } from 'utils/userUtils'
import { icons } from 'assets/assets'
import STRINGS from 'utils/strings'
import UserPopup from './UserPopup'
import {
  INSIGHTS_DASHBOARD_NAMES,
  USER_ROLE_IDS_WITH_BLOCKED_SITES_ACCESS,
  USER_ROLE_IDS_WITH_BLOCKED_VIRTUAL_VISIT_ACCESS,
  USER_ROLE_IDS_WITH_RESTRICTED_ACCESS,
  USER_ROLES_MAP,
  MAINTENANCE_TOOL_URL,
  FLAGSMITH_FEATURES,
} from 'utils/constants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'

const FONT_AWESOME_MAP = {
  dataCorrection: <i key='data-correction-icon' className='far fa-edit' />,
  lockIcon: <i key='lock-icon' className='fa fa-lock' />,
  training: <i key='training-icon' className='far fa-lightbulb' />,
  visitSchedules: <i key='visit-schedules-icon' className='fa-solid fa-calendar-days' />,
}

const STATUS_LINKS = [
  {
    to: '/compliance',
    text: STRINGS.compliance,
    key: 'compliance',
    scopes: ['ra'],
    conditional: props => {
      return !props.study.config?.insights?.enabled
    },
  },
  {
    to: '/compliance',
    text: STRINGS.datacubedInsights,
    key: 'insights',
    scopes: ['ra', 'rp', 'rd'],
    conditional: props => {
      return !!props.study.config?.insights?.enabled
    },
  },
  {
    to: '/communication',
    text: STRINGS.communication,
    key: 'communication',
    scopes: ['xp'],
    conditional: props => props.study.cycles !== null,
    lockable: true,
  },
  {
    to: '/payments',
    text: STRINGS.incentives,
    key: 'payments',
    scopes: ['rp', 'rp-no-pii'],
    conditional: props => {
      const hasIncentives = props.study.config?.incentives
      return (
        props.hasPayments &&
        (hasIncentives ? props.study.config?.incentives.enabled : true) &&
        props.role !== USER_ROLES_MAP.siteUserManagementAdmin
      )
    },
    lockable: true,
  },
]
const PARTICIPANT_LINKS = [
  {
    to: '/participants',
    text: STRINGS.participants,
    key: 'participants',
    scopes: ['rp', 'rp-no-pii'],
    conditional: props => props.role !== USER_ROLES_MAP.siteUserManagementAdmin,
    lockable: true,
  },
  {
    to: '/visit-schedules',
    text: STRINGS.visitSchedule,
    key: 'visitSchedules',
    scopes: ['wpv', 'rpv'],
    conditional: props => {
      const { study, role } = props
      return study.config?.visit_support && role !== 'Enroller'
    },
    lockable: true,
  },
  {
    to: '/virtualvisits',
    text: STRINGS.virtualVisits,
    key: 'virtualvisits',
    scopes: ['rp', 'rp-no-pii'],
    conditional: props => {
      const { roleId, study } = props
      return study.config?.virtual_visits && !USER_ROLE_IDS_WITH_BLOCKED_VIRTUAL_VISIT_ACCESS.includes(roleId)
    },
    lockable: true,
  },

  {
    to: '/cases',
    text: STRINGS.cases,
    key: 'cases',
    scopes: ['rp'],
    conditional: props => props.study.config?.case_management && props.role !== USER_ROLES_MAP.siteUserManagementAdmin,
    lockable: true,
  },
]

const INSTRUMENT_LINKS = [
  {
    to: '/instruments',
    text: STRINGS.instruments,
    key: 'instruments',
    scopes: ['ra'],
    lockable: true,
    conditional: props => props.role !== USER_ROLES_MAP.reporter,
  },
]

const ADMIN_LINKS = [
  {
    to: '/consents',
    text: STRINGS.consents,
    key: 'consents',
    scopes: ['rp', 'rp-no-pii'],
    conditional: props => props.study.consent_required && props.role !== USER_ROLES_MAP.siteUserManagementAdmin,
    lockable: true,
  },
  {
    to: '/users',
    text: STRINGS.users,
    key: 'users',
    scopes: ['ru'],
    lockable: true,
  },
  {
    to: '/sites',
    text: STRINGS.sites,
    key: 'sites',
    scopes: ['rp', 'rp-no-pii'],
    lockable: true,
    conditional: ({ roleId }) => {
      return !USER_ROLE_IDS_WITH_BLOCKED_SITES_ACCESS.includes(roleId)
    },
  },
  {
    to: '/geofences',
    text: STRINGS.geofences,
    key: 'geofences',
    scopes: ['ra'],
    conditional: props => props.study.modules.geofencing,
    lockable: true,
  },
  {
    to: '/notifications',
    text: STRINGS.notifications,
    key: 'notifications',
    scopes: ['rsn'],
    lockable: true,
  },
  {
    to: '/data-correction',
    text: STRINGS.dataCorrection,
    key: 'dataCorrection',
    scopes: ['xf'],
  },
  {
    to: '',
    text: STRINGS.configuration,
    key: 'configuration',
    scopes: ['rsc'],
  },
  {
    to: '/training',
    text: STRINGS.training,
    key: 'training',
    scopes: ['rs'],
    conditional: props =>
      props.study.config?.training?.enabled && !USER_ROLE_IDS_WITH_RESTRICTED_ACCESS.includes(props.roleId),
  },
  {
    to: '/data-inbox',
    text: STRINGS.dataInbox,
    key: 'dataInbox',
    scopes: ['rd'],
    conditional: props => !USER_ROLE_IDS_WITH_RESTRICTED_ACCESS.includes(props.roleId),
  },
]

const studiesLink = {
  to: '/',
  text: STRINGS.allStudies,
  key: 'studies',
  scopes: ['ru'],
}

const CONFIGURATION_LINKS = [
  {
    text: 'Maintenance',
    key: 'maintenanceTool',
    externalLink: MAINTENANCE_TOOL_URL,
    scopes: ['xmt'],
  },
]

const AUDIT_LINKS = [
  {
    to: '/audit-reporting',
    text: 'Audit Report',
    key: 'auditInsights',
    scopes: ['xr', 'xr-no-pii'],
    conditional: ({ study, roleId }) => {
      const auditReportDashboard = study.config?.insights?.permissions?.find(
        permission => permission.dashboard_name === INSIGHTS_DASHBOARD_NAMES.auditReport,
      )
      return !auditReportDashboard?.hidden_role_ids[roleId]
    },
  },
]

const MainSideBar = props => {
  const {
    study,
    user,
    onFetchSisenseUrl,
    showNavLinks,
    role,
    subroute,
    orphanedParticipants,
    loading,
    showPendingPaymentsNotification,
    disabled,
    studyLock,
    sisenseLoading,
    passedVisitsCount = 0,
    isPassedVisitsCountVisible,
    sitesExist = false,
    onLogout,
  } = props

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.maintenanceTool]: { enabled: true } },
    userId: user?.id,
    studyId: study?.id,
  })

  const getUserLinktFlag = userID => {
    const userMap = JSON.parse(localStorage.getItem('user-map')) || {}
    let isLinktFlagHide = false
    const userStoraged = userMap[userID]
    if (userStoraged) {
      isLinktFlagHide = userStoraged['linktFlag']
    }
    return isLinktFlagHide
  }

  const [home, setHome] = useState(false)
  const [userScope, setUserScope] = useState(new Set(study.id ? getUserScopeByStudy(user, study) : getUserScope(user)))
  const [isLinktFlagHide, setIsLinktFlagHide] = useState(getUserLinktFlag(user.id))

  useEffect(() => {
    const newScope = new Set(study.id ? getUserScopeByStudy(user, study) : getUserScope(user))
    setUserScope(newScope)
  }, [study, user])

  const filterLinks = ({ text, scopes, conditional }) => {
    return (!scopes || scopes.some(scope => userScope.has(scope))) && (conditional ? conditional(props, text) : true)
  }

  const renderSisenseButton = (data, loading, key) => {
    return (
      <Button
        key={key}
        className='sidebar-link'
        loading={loading}
        disabled={loading}
        content={[icons[key], <div key='sisense-button-content'>{data.button.content}</div>, icons.externalLink]}
        onClick={() => onFetchSisenseUrl({ dashboard: data, studyID: study.id })}
      />
    )
  }

  const renderNavItem = ({ link, customButtons = {} }) => {
    const { key, lockable, externalLink } = link
    const selected = subroute === key
    const isStudies = key === 'studies'
    const isPtps = key === 'participants'
    const isPayments = key === 'payments'
    const isVisitSchedules = key === 'visitSchedules'
    const calloutTitle = 'New Feature Alert: Datacubed Insights'
    const calloutBody =
      'You can now oversee more study data such as participant compliance by study/site, instrument performance, and more. Check it out!'
    const isVisitsCountVisible = isVisitSchedules && passedVisitsCount && isPassedVisitsCountVisible

    return (
      <div key={`${key}-wrapper`} className={`${key}-wrapper`}>
        {link.text === 'Datacubed Insights' && !isLinktFlagHide && (
          <div className='d3-insights callout' key={`${key}-callout`}>
            <h6>
              {String.fromCodePoint(0x1f389)} {calloutTitle}
            </h6>
            <p>{calloutBody}</p>
            <Button link onClick={() => hideLinktFlag(user.id)}>
              Got it-dismiss
            </Button>
          </div>
        )}
        {customButtons.audit_report ? (
          renderSisenseButton(customButtons.audit_report, sisenseLoading, key)
        ) : (
          <Button
            key={key}
            className={`sidebar-link clickable${selected ? ' selected' : ''}${
              loading?.general && isPtps ? ' disabled-invisible' : ''
            }`}
            disabled={disabled}
            onClick={() => {
              if (key === 'maintenanceTool') {
                window.open(externalLink, '_blank')
              } else {
                browserHistory.push(isStudies ? '/studies' : `/studies/${study.id}${link.to}`)
              }
            }}
            id={`navbar-link-${key}`}
            content={[
              icons[key] || FONT_AWESOME_MAP[key], // either use custom svg if there is one or use fontAwesome icon
              <span className={`sidebar-link-text ${key === 'maintenanceTool' ? 'full-width' : ''}`}>{link.text}</span>,
              isPayments && showPendingPaymentsNotification
                ? icons.sidebarNotification('pendingPaymentsNotification')
                : null,
              orphanedParticipants && isPtps && sitesExist
                ? icons.sidebarNotification('orphanedParticipantsNotification')
                : null,
              !!lockable && studyLock ? FONT_AWESOME_MAP.lockIcon : null,
              isVisitsCountVisible ? (
                <span className='previous-visits-tab-count' key='previous-visits-tab-count'>
                  {passedVisitsCount}
                </span>
              ) : null,
              key === 'maintenanceTool' ? icons.externalLink : null,
            ]}
          />
        )}
      </div>
    )
  }

  const hideLinktFlag = userID => {
    setUserLinktFlag(userID)
    setIsLinktFlagHide(true)
  }

  const renderLinks = () => {
    let customButtons
    if (study.config?.insights?.enabled) customButtons = study.config.insights.custom_buttons
    const customAuditReport = customButtons?.audit_report

    if (showNavLinks) {
      const statusLinks = STATUS_LINKS.filter(filterLinks).map(link => {
        return renderNavItem({ link })
      })

      const particpipantLinks = PARTICIPANT_LINKS.filter(filterLinks).map(link => {
        return renderNavItem({ link })
      })

      const instrumentLinks = INSTRUMENT_LINKS.filter(filterLinks).map(link => {
        return renderNavItem({ link })
      })

      const adminLinks = ADMIN_LINKS.filter(filterLinks).map(link => {
        return renderNavItem({ link })
      })

      const auditLinks = AUDIT_LINKS.filter(filterLinks).map(link => {
        return renderNavItem({ link, customButtons: customAuditReport ? customButtons : {} })
      })

      const configurationLinks = flagsmithFeatures[FLAGSMITH_FEATURES.maintenanceTool]?.enabled
        ? CONFIGURATION_LINKS.filter(filterLinks).map(link => {
            return renderNavItem({ link })
          })
        : []

      const ptpHeading =
        role !== USER_ROLES_MAP.reporter && role !== USER_ROLES_MAP.siteUserManagementAdmin ? (
          <h5 key='ptp-heading'>PARTICIPANTS</h5>
        ) : null
      const statusHeading =
        role !== USER_ROLES_MAP.siteUserManagementAdmin ? <h5 key='status-heading'>STUDY STATUS</h5> : null
      const instrumentHeading = instrumentLinks.length ? <h5 key='instrument-heading'>INSTRUMENTS</h5> : null
      const adminHeading = role !== USER_ROLES_MAP.reporter ? <h5 key='admin-heading'>ADMINISTRATION</h5> : null
      const auditHeading = auditLinks.length ? <h5 key='audit-heading'>AUDIT</h5> : null
      const configurationHeading = configurationLinks.length ? <h5 key='configuration-heading'>CONFIGURATION</h5> : null

      return [
        statusHeading,
        ...statusLinks,
        ptpHeading,
        ...particpipantLinks,
        instrumentHeading,
        ...instrumentLinks,
        adminHeading,
        ...adminLinks,
        auditHeading,
        ...auditLinks,
        configurationHeading,
        ...configurationLinks,
      ]
    }
    const homeHeading = <h6 key='home-heading'>HOME</h6>
    return [homeHeading, renderNavItem({ link: studiesLink })]
  }

  const renderHeader = () => {
    const { platform_name } = study
    let headerText
    let subtitleText
    if (showNavLinks) {
      if (home) {
        headerText = 'Back to all studies'
      } else {
        headerText = `${platform_name}`
      }
    } else {
      headerText = 'Admin Panel'
      subtitleText = 'Powered by Datacubed'
    }
    const goBackStyles = home && showNavLinks ? ' dark' : ''
    const studyStyles = showNavLinks ? ' header-study' : ''
    const showAdditionalElems = subroute !== 'studies' && subroute !== 'me' && subroute !== 'createStudy'

    const backToStudies = () => {
      setHome(false)
      browserHistory.push('/')
    }

    return (
      <>
        <div className={`sidebar-header flexed start-justified${goBackStyles.length ? goBackStyles : studyStyles}`}>
          {showAdditionalElems && (
            <i
              onMouseEnter={() => {
                if (showNavLinks) setHome(true)
              }}
              onMouseLeave={() => {
                setHome(false)
              }}
              onClick={backToStudies}
              className='fas fa-chevron-left'
            />
          )}
          <div className={`flexed ${study['custom-logo'] ? 'logo' : ''}`}>
            {home && showNavLinks ? null : showAdditionalElems ? (
              study['custom-logo'] ? (
                <img src={study['custom-logo']} alt='Logo' />
              ) : (
                <span className='char'>{platform_name.charAt(0)}</span>
              )
            ) : (
              icons.logo
            )}
          </div>
          <span className='word-clamp sidebar-title'>
            {headerText}
            {subtitleText && <span className='sidebar-subtitle'>{subtitleText}</span>}
          </span>
        </div>
        {showAdditionalElems && <span className='sidebar-datacubed'>Powered by Datacubed Health</span>}
      </>
    )
  }

  const { token } = user
  const notAllowedClassName = disabled ? 'not-allowed' : ''
  if (!token) return null
  return (
    <div className={`sidebar ${notAllowedClassName}`}>
      {renderHeader()}
      <div className='main'>{renderLinks()}</div>
      <UserPopup user={user} onLogout={onLogout} currentRole={role} disabled={disabled} />
    </div>
  )
}

MainSideBar.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onLogout: PropTypes.func,
  orphanedParticipants: PropTypes.bool,
  onFetchSisenseUrl: PropTypes.func,
  role: PropTypes.string,
  showNavLinks: PropTypes.bool,
  sisenseLoading: PropTypes.bool,
  study: PropTypes.shape({
    config: PropTypes.object,
    platform_name: PropTypes.string,
    id: PropTypes.number,
  }),
  subroute: PropTypes.string,
  studyLock: PropTypes.bool,
  user: PropTypes.shape({
    phone: PropTypes.string,
    token: PropTypes.string,
  }),
  showPendingPaymentsNotification: PropTypes.bool,
}

export default MainSideBar
