import { connect } from 'react-redux'
import { QUESTION_TYPE_MAP } from 'utils/constants'
import DiaryLogicPanel from '../components/DiaryLogicPanel'
import { logicActions } from '../modules/Diary'

const mapDispatchToProps = logicActions

const mapStateToProps = (state, ownProps) => {
  const { editorStates } = state.diaryEditor
  const { itemId } = ownProps
  const { questions } = state.diaryEditor.diary
  const order = state.diaryEditor.diary.order[0]
  const currentIndex = order.indexOf(itemId)

  const questionOptions = []
  let questionNumber = 0
  for (let i = 0; i < currentIndex; i++) {
    const questionType = questions[order[i]].type
    if (questionType.includes(QUESTION_TYPE_MAP.introduction)) continue
    if (questionType.includes(QUESTION_TYPE_MAP.prompt)) continue
    if (questionType.includes(QUESTION_TYPE_MAP.imageCapture)) {
      questionNumber++
      continue
    }
    if (questionType.includes(QUESTION_TYPE_MAP.multipleField)) {
      questionNumber++
      continue
    }
    if (questionType.includes(QUESTION_TYPE_MAP.audioRecording)) {
      questionNumber++
      continue
    }
    if (questionType.includes(QUESTION_TYPE_MAP.scribble)) {
      questionNumber++
      continue
    }
    const plainText = state.diaryEditor.editorStates[order[i]].getCurrentContent().getPlainText()
    questionNumber++
    let text = `Q${questionNumber}: ${plainText}`
    if (text.length > 40) text = `${text.slice(0, 40)}...`
    questionOptions.push({
      key: order[i],
      text,
      value: order[i],
    })
  }

  return {
    editorStates,
    logic: state.diaryEditor.diary.questions[itemId].logic.show_if,
    questionOptions,
    questions,
    logicErr: state.diaryEditor.diaryErrors.questions[itemId],
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiaryLogicPanel)
