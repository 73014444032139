import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DATE_FORMAT_MAP } from 'utils/constants'

const LogicPreview = ({ itemType, groupedLogic, questions, editorStates, errors = {} }) => {
  errors.logicErr = errors.logicErr || []
  if (groupedLogic.length === 0) return null
  const header = itemType === 'introduction' ? 'This introduction has answer logic' : 'This question has answer logic'
  const generateOperatorText = operator => {
    return operator === 'and' ? 'ALL of these conditions are met:' : 'ANY of these conditions are met:'
  }
  let groupHeader = null
  if (groupedLogic.length > 1) {
    groupHeader = (
      <h4 className='group-header'>
        <b>{`${`${groupedLogic.map((el, idx) => `Group ${idx + 1}`).join(', ')}: `}`}</b>
        Show if {generateOperatorText(groupedLogic.operator)}
      </h4>
    )
  }
  return (
    <div className='logic-preview'>
      {errors.logicErr.map((err, idx) => {
        return (
          <p key={`logic-err-${idx}`} className='survey-error'>
            {err}
          </p>
        )
      })}
      <h4>
        <i className='fas fa-code-branch' />
        {header}
      </h4>
      {groupHeader}
      {groupedLogic.map((group, index) => {
        const groupText = groupedLogic.length > 1 ? <b>{`Group ${index + 1}: `}</b> : null
        const subHeader = group.terms.length > 1 ? generateOperatorText(group.operator) : null
        return (
          <ul key={`group_${index}`}>
            <li>
              {groupText}
              {subHeader}
            </li>
            {generateGroupPreview(group, index, questions, groupedLogic.length > 1, editorStates)}
          </ul>
        )
      })}
    </div>
  )
}

// Generates preview for each group of logic
const generateGroupPreview = (group, groupIndex, questions, hasGroups, editorStates) => {
  const showIfText = !hasGroups && group.terms.length === 1 ? <span>Show if: </span> : null
  return group.terms.map((term, index) => {
    if (!term.question_id || !term.comparator || (term.value === '' && !term.comparator.includes('answered'))) {
      return null
    }
    const refId = term.question_id
    const refQuestion = questions[refId]
    let refTitle = editorStates[refId].getCurrentContent().getPlainText()
    refTitle = refTitle.length <= 30 ? `"${refTitle}"` : `"${refTitle.substring(0, 30)}..."`
    const refNumber = refQuestion.attributes.questionNumber
    let refValue = ''
    let comparator = ''
    switch (term.comparator) {
      case 'is_answered':
        comparator = 'is'
        refValue = 'answered'
        break
      case 'is_not_answered':
        comparator = 'is'
        refValue = 'not answered'
        break
      case '<':
        comparator = 'is less than'
        break
      case '<=':
        comparator = 'is less than or equal'
        break
      case '>':
        comparator = 'is greater than'
        break
      case '>=':
        comparator = 'is greater than or equal to'
        break
      case '!=':
        comparator = 'is not exactly equal to'
        break
      case '=':
        comparator = 'is exactly equal to'
        break
      case 'is_selected':
        comparator = 'has selected choice'
        break
      case 'is_not_selected':
        comparator = 'has not selected choice'
        break
    }
    if (!refValue) {
      if (['select_one', 'select_multiple', 'likert'].includes(refQuestion.type)) {
        refValue = `"${refQuestion.choices[term.value].label}"`
      } else if (refQuestion.type.includes('integer') || refQuestion.type.includes('decimal')) {
        refValue = term.value
      } else if (refQuestion.type === 'datetime') {
        refValue = moment(term.value.slice(0, term.value.length - 1)).format(DATE_FORMAT_MAP.mainWithTime)
      } else if (refQuestion.type === 'date') {
        refValue = moment(term.value.slice(0, term.value.length - 1)).format(DATE_FORMAT_MAP.main)
      } else if (refQuestion.type === 'time') {
        refValue = moment(`0000-01-01${term.value.slice(10, term.value.length - 1)}`).format('HH:mm')
      } else {
        refValue = `"${term.value}"`
      }
    }

    return (
      <li key={`logicPreview_${groupIndex}_${index}`}>
        {showIfText}
        <b>{`Question ${refTitle} #${refNumber}`}</b> {comparator} <b>{`${refValue}`}</b>
      </li>
    )
  })
}

LogicPreview.propTypes = {
  itemType: PropTypes.string,
  groupedLogic: PropTypes.array,
  questions: PropTypes.object,
  editorStates: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  return {
    questions: state.newConsentReducer.consent.questions,
    itemType: ownProps.item.type,
    groupedLogic: groupLogic(ownProps.item.logic.show_if),
    editorStates: state.newConsentReducer.editorStates,
  }
}

// Groups logic object into array of groups
const groupLogic = logic => {
  if (!logic) return []
  if (!logic.terms) return []
  let groups
  if (logic.terms[0].comparator === 'boolean_combine') {
    groups = logic.terms.slice()
    groups.operator = logic.operator
  } else {
    groups = [logic]
  }
  return groups
}
export default connect(mapStateToProps, null)(LogicPreview)
