export const URGENCY_MAP = {
  open: 'high',
  reported: 'high',
  closed: '',
  suspected: 'medium',
  open_no_symptoms: 'low',
  completed: 'low',
  confirmed: 'low',
}

export const CASE_STATUS_MAP = {
  open: 'open',
  open_no_symptoms: 'open_no_symptoms',
  reported: 'reported',
  closed: 'closed',
  suspected: 'suspected',
  completed: 'completed',
  confirmed: 'confirmed',
}

export const CASE_STATUS_STRINGS = {
  open: 'opened',
  open_no_symptoms: 'opened',
  reported: 'reported',
  closed: 'closed',
  suspected: 'suspected',
  completed: 'confirmed',
  confirmed: 'confirmed',
}

export const CASE_HIERARCHY_LIST = ['open', 'reported', 'suspected', 'confirmed', 'completed', 'closed']
