import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/textarea.scss'

// This is an autosizing textarea component
class Textarea extends React.Component {
  componentDidMount() {
    this.resize()
  }

  componentDidUpdate() {
    this.resize()
  }

  resize = () => {
    const el = this.textarea
    el.style.height = 'auto'
    el.style.height = `${el.scrollHeight + 5}px`
  }

  onChange = e => {
    const { charlimit, onChange } = this.props
    this.resize()
    const { value } = e.target
    if (onChange) onChange(charlimit ? value.slice(0, charlimit) : value)
  }

  render() {
    const _props = { ...this.props }
    const { className, charlimit, disabled, hasError, label, value } = this.props
    delete _props.hasError
    let _className = `d3-textarea ${className || ''}`
    _className += hasError ? ' has-error' : ''
    return (
      <>
        {label && <label className='label-small'>{label}</label>}
        <textarea
          {..._props}
          disabled={disabled}
          className={_className}
          spellCheck
          rows='1'
          ref={el => {
            this.textarea = el
          }}
          onChange={this.onChange}
        />
        {!!charlimit && <span className='char-limit'>{`${value.length}/${charlimit}`}</span>}
      </>
    )
  }
}

Textarea.propTypes = {
  charlimit: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default Textarea
