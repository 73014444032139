/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { combineReducers } from 'redux'
import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { actions as notyActions } from 'layouts/ErrorBox'
import { HTMLToEditorState, EditorStateToHTML, formatLinkEntities } from 'utils/draft'
import { addUniqToArr, downloadBlob, stripHtmlTags } from 'utils/misc'
import request, { generateNotyMessage } from 'utils/request'
import { getSurveyErrors, getArticleErrors, getFormulaErrors } from 'utils/instrumentValidation'
import {
  addMediaToFileMap,
  deleteMediaFromInstrumentJson,
  removeValuesAndLabels,
  removeChoices,
  getRandomizedArray,
} from 'utils/instrument'
import { createMomentObject, getFormattedDateString } from 'utils/time'
import {
  DATE_FORMAT_MAP,
  INPUT_VALIDATION_TYPE_MAP,
  INSTRUMENT_PATH_MAP,
  MEDIA_TYPE_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  MODAL_CONTENT_MAP,
  NUMERIC_CHOICE_QUESTION_TYPES,
  QUESTION_ATTRIBUTE_MAP,
  QUESTION_TYPE_MAP,
  QUESTION_TYPES_WITH_AUDIO,
  QUESTION_TYPES_WITH_CAREGIVER_HINTS,
  QUESTION_TYPES_WITH_CHOICE_IMAGES,
  QUESTION_TYPES_WITH_CHOICES,
  QUESTION_TYPES_WITH_HINTS,
  QUESTION_TYPES_WITH_IMAGE,
  QUESTION_TYPES_WITH_SCORING,
  QUESTION_TYPES_WITH_VIDEO,
  QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS,
  RELATIVE_DATE_INTERVAL_MAP,
  SCALE_QUESTION_TYPES,
  SELECT_QUESTION_TYPES,
} from 'utils/constants'
import { modalActions, TURN_OFF_MODAL_ON_ITEM } from 'store/modal'
import { loadingActions } from 'store/loader'
import { getDomainIsUtilized } from '../../Scoring/utils/domainUtils'
import { _getDefaultMetadata, cleanUploadedJSON } from '../../../../../utils/PropertyFields'
import { replaceTargetsWithVal } from '../../Scoring/utils/scoring'
import { getRandomQuestionId } from '../../../../../../../utils/getRandomQuestionId'

//
// Constants
//

// Survey
export const INITIALIZE_EDIT = 'INITIALIZE_EDIT'
const ADD_CHOICE = 'ADD_CHOICE'
const ADD_CHOICE_LABEL_IMAGE = 'ADD_CHOICE_LABEL_IMAGE'
const ADD_CHOICE_ON_PASTE = 'ADD_CHOICE_ON_PASTE'
const ADD_ITEM = 'ADD_ITEM'
const ADD_ITEM_IMAGE = 'ADD_ITEM_IMAGE'
const ADD_ITEM_MEDIA = 'ADD_ITEM_MEDIA'
const ADD_PREVIEW_IMAGE = 'ADD_PREVIEW_IMAGE'
const ADD_LOGIC_CONDITION = 'ADD_LOGIC_CONDITION'
const ADD_LOGIC_GROUP = 'ADD_LOGIC_GROUP'
const ADD_NEW_SURVEY_DOMAIN = 'ADD_NEW_SURVEY_DOMAIN'
const ADD_OTHER_CHOICE = 'ADD_OTHER_CHOICE'
const CHANGE_ITEM_TYPE = 'CHANGE_ITEM_TYPE'
const CHANGE_LOGIC_OPERATOR = 'CHANGE_LOGIC_OPERATOR'
const DELETE_ALL_LOGIC = 'DELETE_ALL_LOGIC'
const DELETE_CHOICE = 'DELETE_CHOICE'
const DELETE_CHOICE_LABEL_IMAGE = 'DELETE_CHOICE_LABEL_IMAGE'
const DELETE_INVALID_LOGIC = 'DELETE_INVALID_LOGIC'
const DELETE_ITEM = 'DELETE_ITEM'
const DELETE_ITEM_IMAGE = 'DELETE_ITEM_IMAGE'
const DELETE_ITEM_MEDIA = 'DELETE_ITEM_MEDIA'
const DELETE_PREVIEW_IMAGE = 'DELETE_PREVIEW_IMAGE'
const DELETE_LOGIC_CONDITION = 'DELETE_LOGIC_CONDITION'
const DELETE_OTHER_CHOICE = 'DELETE_OTHER_CHOICE'
const DELETE_LONGLIST_ITEM = 'DELETE_LONGLIST_ITEM'
const DUPLICATE_ITEM = 'DUPLICATE_ITEM'
const INITIALIZE_BLANK_SURVEY = 'INITIALIZE_BLANK_SURVEY'
const MOVE_CHOICE = 'MOVE_CHOICE'
const MOVE_ITEM = 'MOVE_ITEM'
const RANDOMIZE_QUESTIONS = 'RANDOMIZE_QUESTIONS'
const SET_ITEM_SCORES_EDITED = 'SET_ITEM_SCORES_EDITED'
const TOGGLE_EDIT = 'TOGGLE_EDIT'
const TOGGLE_INPUT_VALIDATION = 'TOGGLE_INPUT_VALIDATION'
const TOGGLE_ITEM_DOMAIN = 'TOGGLE_ITEM_DOMAIN'
const TOGGLE_ITEM_HAS_SCORING = 'TOGGLE_ITEM_HAS_SCORING'
const TOGGLE_REQUIRED_QUESTION = 'TOGGLE_REQUIRED_QUESTION'
const TOGGLE_UNDO_ITEM = 'TOGGLE_UNDO_ITEM'
const TOGGLE_WARNING_BOX_ON_ITEM = 'TOGGLE_WARNING_BOX_ON_ITEM'
const UPDATE_CHOICE_KEY = 'UPDATE_CHOICE_KEY'
const UPDATE_CHOICE_LABEL = 'UPDATE_CHOICE_LABEL'
const UPDATE_CHOICE_RICH_LABEL = 'UPDATE_CHOICE_RICH_LABEL'
const UPDATE_CHOICE_SCORE_VALUE = 'UPDATE_CHOICE_SCORE_VALUE'
const UPDATE_CHOICE_VALUE = 'UPDATE_CHOICE_VALUE'
const UPDATE_ITEM = 'UPDATE_ITEM'
const UPDATE_ITEM_LABEL = 'UPDATE_ITEM_LABEL'
const UPDATE_LOGIC_CONDITION = 'UPDATE_LOGIC_CONDITION'
const UPDATE_LONG_LIST_ITEM = 'UPDATE_LONG_LIST_ITEM'
const UPDATE_ORIGINAL_SURVEY = 'UPDATE_ORIGINAL_SURVEY'
const UPDATE_OTHER_CHOICE = 'UPDATE_OTHER_CHOICE'
const UPDATE_TITLE = 'UPDATE_TITLE'
const UPDATE_UNIT_TYPE = 'UPDATE_UNIT_TYPE'
const UPDATE_SURVEY_DOMAIN_FORMULA = 'UPDATE_SURVEY_DOMAIN_FORMULA'
const UPDATE_ITEM_FORMULA = 'UPDATE_ITEM_FORMULA'
const UPDATE_SURVEY_SCORE = 'UPDATE_SURVEY_SCORE'
const UPDATE_LONGLIST_ITEM_BY_CSV = 'UPDATE_LONGLIST_ITEM_BY_CSV'
const ADD_SURVEY_SCORE = 'ADD_SURVEY_SCORE'
const DELETE_SURVEY_SCORE = 'DELETE_SURVEY_SCORE'
const TOGGLE_ITEM_ATTRIBUTE = 'TOGGLE_ITEM_ATTRIBUTE'
const SET_ITEM_ATTRIBUTE = 'SET_ITEM_ATTRIBUTE'
const SET_SURVEYS_SCORE = 'SET_SURVEYS_SCORE'

// Article

export const INITIALIZE_BLANK_ARTICLE = 'INITIALIZE_BLANK_ARTICLE'
export const INITIALIZE_ARTICLE_EDIT = 'INITIALIZE_ARTICLE_EDIT'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const UPDATE_ARTICLE_TITLE = 'UPDATE_ARTICLE_TITLE'
export const UPDATE_ARTICLE_URL = 'UPDATE_ARTICLE_URL'

// Errors
const ADD_SURVEY_ERRORS = 'ADD_SURVEY_ERRORS'
const CLEAR_SURVEY_ERROR = 'CLEAR_SURVEY_ERROR'
const CLEAR_SURVEY_ERRORS = 'CLEAR_SURVEY_ERRORS'
const CLEAR_QUESTION_ERROR = 'CLEAR_QUESTION_ERROR'

//
// Action Creators
//

// Article Actions

const initializeArticleEdit = json => {
  return {
    type: INITIALIZE_ARTICLE_EDIT,
    json,
  }
}

const initializeBlankArticle = (title = '', displayName = '') => {
  return {
    type: INITIALIZE_BLANK_ARTICLE,
    title,
    displayName,
  }
}

const updateArticleTitle = value => {
  return {
    type: UPDATE_ARTICLE_TITLE,
    value,
  }
}
const updateArticleURL = url => {
  return {
    type: UPDATE_ARTICLE_URL,
    url,
  }
}

// Survey Actions
function initializeEdit(json, _editorStates) {
  return {
    type: INITIALIZE_EDIT,
    json,
    editorStates: _editorStates,
  }
}

function initializeBlankSurvey(title = '', displayName = '') {
  return {
    type: INITIALIZE_BLANK_SURVEY,
    title,
    displayName,
  }
}

export function updateTitle(title) {
  return {
    type: UPDATE_TITLE,
    title,
  }
}

// Item Actions

function toggleEdit(itemId) {
  return {
    type: TOGGLE_EDIT,
    itemId,
  }
}

function addItem({ itemType, index, newQId }) {
  const choiceOneId = `${newQId}_${getRandomQuestionId()}`
  const choiceTwoId = `${newQId}_${getRandomQuestionId()}`
  return {
    type: ADD_ITEM,
    itemType,
    newQId,
    index,
    choiceOneId,
    choiceTwoId,
  }
}

function deleteItem(itemId) {
  return {
    type: DELETE_ITEM,
    itemId,
  }
}

function toggleItemAttribute({ itemId, attribute }) {
  return {
    type: TOGGLE_ITEM_ATTRIBUTE,
    attribute,
    itemId,
  }
}

function setItemAttribute({ itemId, attribute, value }) {
  return {
    type: SET_ITEM_ATTRIBUTE,
    attribute,
    itemId,
    value,
  }
}

function addItemImage({ itemId, imageId, imageSrc, size }) {
  return {
    type: ADD_ITEM_IMAGE,
    itemId,
    imageId,
    imageSrc,
    size,
  }
}

function deleteItemImage(itemId, imageId) {
  return {
    type: DELETE_ITEM_IMAGE,
    itemId,
    imageId,
  }
}

function addPreviewImage({ itemId, imageId, imageOrder, src: imageSrc, size }) {
  return {
    type: ADD_PREVIEW_IMAGE,
    itemId,
    imageId,
    imageOrder,
    imageSrc,
    size,
  }
}

function deletePreviewImage(itemId, imageId, imageOrder) {
  return {
    type: DELETE_PREVIEW_IMAGE,
    itemId,
    imageId,
    imageOrder,
  }
}
function addItemMedia({ isVideo, itemId, fileId, fileSrc, size }) {
  return {
    type: ADD_ITEM_MEDIA,
    itemId,
    isVideo,
    fileId,
    fileSrc,
    size,
  }
}

function deleteItemMedia(itemId, fileId) {
  return {
    type: DELETE_ITEM_MEDIA,
    itemId,
    fileId,
  }
}

function addChoice(itemId, choicesLength, insertIdx, itemType) {
  const newChoiceId = `${itemId}_${getRandomQuestionId()}`
  return {
    type: ADD_CHOICE,
    itemId,
    insertIdx,
    choicesLength,
    itemType,
    newChoiceId,
  }
}

function addChoiceOnPaste(itemId, pasteText) {
  return {
    type: ADD_CHOICE_ON_PASTE,
    itemId,
    pasteText,
  }
}

function addOtherChoice(itemId) {
  return {
    type: ADD_OTHER_CHOICE,
    itemId,
  }
}

function deleteOtherChoice(itemId) {
  return {
    type: DELETE_OTHER_CHOICE,
    itemId,
  }
}

function updateOtherChoice(item, itemId, otherValue) {
  return {
    type: UPDATE_OTHER_CHOICE,
    item,
    itemId,
    otherValue,
  }
}

function deleteChoice(itemId, choiceId) {
  return {
    type: DELETE_CHOICE,
    itemId,
    choiceId,
  }
}

function updateChoiceLabel(item, itemId, choiceId, newLabel) {
  return {
    type: UPDATE_CHOICE_LABEL,
    item,
    itemId,
    choiceId,
    newLabel,
  }
}
function updateChoiceRichLabel(item, itemId, choiceId, oldEditorState, newEditorState) {
  return {
    type: UPDATE_CHOICE_RICH_LABEL,
    item,
    itemId,
    choiceId,
    oldEditorState,
    newEditorState,
  }
}

function updateChoiceValue(item, itemId, choiceId, newValue) {
  return {
    type: UPDATE_CHOICE_VALUE,
    item,
    itemId,
    choiceId,
    newValue,
  }
}

function updateChoiceScoreValue(item, itemId, choiceId, newValue) {
  return {
    type: UPDATE_CHOICE_SCORE_VALUE,
    item,
    itemId,
    choiceId,
    newValue,
  }
}

function updateChoiceKey(item, itemId, choiceId, key, newValue) {
  return {
    type: UPDATE_CHOICE_KEY,
    item,
    itemId,
    choiceId,
    key,
    newValue,
  }
}

function addChoiceLabelImage({ item, itemId, choiceId, imageId, imageSrc, size }) {
  return {
    type: ADD_CHOICE_LABEL_IMAGE,
    item,
    itemId,
    choiceId,
    imageId,
    imageSrc,
    size,
  }
}

function deleteChoiceLabelImage(item, itemId, choiceId, imageId) {
  return {
    type: DELETE_CHOICE_LABEL_IMAGE,
    item,
    itemId,
    choiceId,
    imageId,
  }
}

function moveChoice(itemId, startIdx, endIdx) {
  return {
    type: MOVE_CHOICE,
    itemId,
    startIdx,
    endIdx,
  }
}

function changeItemType(itemId, prevType, nextType, _survey, newChoices) {
  const item = _survey.questions[itemId]
  return {
    type: CHANGE_ITEM_TYPE,
    itemId,
    prevType,
    nextType,
    item,
    newChoices,
  }
}

function toggleRequiredQuestion(itemId) {
  return {
    type: TOGGLE_REQUIRED_QUESTION,
    itemId,
  }
}

function toggleQuestionScoring(itemId, domains) {
  return {
    type: TOGGLE_ITEM_HAS_SCORING,
    itemId,
    domains,
  }
}

function setQuestionScoresEdited(itemId) {
  return {
    type: SET_ITEM_SCORES_EDITED,
    itemId,
  }
}

function toggleInputValidation(itemId) {
  return {
    type: TOGGLE_INPUT_VALIDATION,
    itemId,
  }
}
function toggleQuestionDomain(itemId, domainId) {
  return {
    type: TOGGLE_ITEM_DOMAIN,
    itemId,
    domainId,
  }
}

function createNewDomainAndAddToQuestion(itemId, label) {
  const newDomainId = getRandomQuestionId()
  return {
    type: ADD_NEW_SURVEY_DOMAIN,
    itemId,
    label,
    newDomainId,
  }
}

function updateSurveyDomainFormula(domainId, formula) {
  return {
    type: UPDATE_SURVEY_DOMAIN_FORMULA,
    domainId,
    formula,
  }
}

function updateSurveyScore(scoreId, key, value) {
  return {
    type: UPDATE_SURVEY_SCORE,
    scoreId,
    key,
    value,
  }
}

function addSurveyScore(scoreId) {
  return {
    type: ADD_SURVEY_SCORE,
    scoreId,
  }
}

function deleteSurveyScore(scoreId) {
  return {
    type: DELETE_SURVEY_SCORE,
    scoreId,
  }
}

function moveItem(startIdx, endIdx) {
  return {
    type: MOVE_ITEM,
    startIdx,
    endIdx,
  }
}

function updateItemFormula(itemId, formula) {
  return {
    type: UPDATE_ITEM_FORMULA,
    itemId,
    formula,
  }
}

export function duplicateItem(itemId, editorState, choiceQProps) {
  const newQId = getRandomQuestionId()
  if (choiceQProps) {
    const { choicesOrder, choicesEditorStates } = choiceQProps
    const newChoicesIdMap = {}
    choicesOrder.forEach(choiceId => {
      const newChoiceId = `${newQId}_${getRandomQuestionId()}`
      newChoicesIdMap[choiceId] = newChoiceId
    })
    return {
      type: DUPLICATE_ITEM,
      choicesEditorStates,
      hasChoices: true,
      isSelectType: !!choicesEditorStates,
      choicesOrder,
      editorState,
      itemId,
      newChoicesIdMap,
      newQId,
    }
  }
  return {
    type: DUPLICATE_ITEM,
    itemId,
    newQId,
    editorState,
  }
}

function updateUnitType(itemId, unit) {
  return {
    type: UPDATE_UNIT_TYPE,
    itemId,
    unit,
  }
}

function randomizeQuestions() {
  return {
    type: RANDOMIZE_QUESTIONS,
  }
}

function setSurveysScore(instrumentId, scores) {
  return {
    type: SET_SURVEYS_SCORE,
    instrumentId,
    scores,
  }
}

// Error actions

function addSurveyErrors(errors) {
  return {
    type: ADD_SURVEY_ERRORS,
    errors,
  }
}

function clearSurveyErrors() {
  return {
    type: CLEAR_SURVEY_ERRORS,
  }
}

function clearQuestionError(itemId) {
  return {
    type: CLEAR_QUESTION_ERROR,
    itemId,
  }
}

function checkLogicAndToggleRequired(survey, itemId) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnToggleRequired(survey.questions, survey.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(toggleRequiredQuestion(itemId))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.actionInvalidate,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(toggleRequiredQuestion(itemId))
    }
  }
}

function checkLogicAndDeleteItem(survey, itemId) {
  return dispatch => {
    let onConfirm = () => {
      dispatch(deleteItem(itemId))
    }
    let modalBody = {
      content: MODAL_CONTENT_MAP.deleteItem,
      confirmButton: MODAL_BUTTONS_MAP.yes,
      cancelButton: MODAL_BUTTONS_MAP.no,
    }
    const invalidConditions = findInvalidLogicOnDelete(survey.questions, survey.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) {
      onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(deleteItem(itemId))
      }
      modalBody = {
        content: MODAL_CONTENT_MAP.logicInvalidatedByMove,
        confirmButton: MODAL_BUTTONS_MAP.proceed,
        cancelButton: MODAL_BUTTONS_MAP.cancel,
      }
    }
    dispatch(
      modalActions.openModal({
        ...modalBody,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  }
}

function checkLogicAndMoveItem(survey, startIdx, endIdx) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnMove(survey.questions, survey.order[0], startIdx, endIdx)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(moveItem(startIdx, endIdx))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByMove,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(moveItem(startIdx, endIdx))
    }
  }
}

function checkLogicAndDeleteChoice(survey, itemId, choiceId) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnChoiceDelete(survey.questions, survey.order[0], itemId, choiceId)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(deleteChoice(itemId, choiceId))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByDeletion,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(deleteChoice(itemId, choiceId))
    }
  }
}

function checkLogicAndChangeItemType(_survey, itemId, prevType, nextType) {
  const newChoices = {
    choiceIdOne: `${itemId}_${getRandomQuestionId()}`,
    choiceIdTwo: `${itemId}_${getRandomQuestionId()}`,
    choiceIdThree: `${itemId}_${getRandomQuestionId()}`,
  }

  return dispatch => {
    const invalidConditions = findInvalidLogicOnChangeItemType(
      _survey.questions,
      _survey.order[0],
      itemId,
      prevType,
      nextType,
    )
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(changeItemType(itemId, prevType, nextType, _survey, newChoices))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByType,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(changeItemType(itemId, prevType, nextType, _survey, newChoices))
    }
  }
}

export function findInvalidLogicOnMove(questions, order, startIdx, endIdx) {
  const invalidConditions = {}
  if (startIdx > endIdx) {
    const itemId = order[startIdx]
    if (!questions[itemId].logic.show_if) return invalidConditions
    const currentItemRefs = {}
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      currentItemRefs[term.question_id] = currentItemRefs[term.question_id] || []
      currentItemRefs[term.question_id].push(path)
    })
    for (let i = endIdx; i < startIdx; i++) {
      if (order[i] in currentItemRefs) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId] = invalidConditions[itemId].concat(currentItemRefs[order[i]])
      }
    }
  } else {
    for (let i = startIdx + 1; i <= endIdx; i++) {
      if (!questions[order[i]].logic.show_if) continue
      logicForEach(questions[order[i]].logic.show_if.terms, (term, path) => {
        if (term.question_id === order[startIdx]) {
          invalidConditions[order[i]] = invalidConditions[order[i]] || []
          invalidConditions[order[i]].push(path)
        }
      })
    }
  }
  return invalidConditions
}

export function findInvalidLogicOnDelete(questions, order, deleteId) {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (itemId === deleteId) return
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (deleteId === term.question_id) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnToggleRequired(questions, order, toggleId) {
  const invalidConditions = {}
  if (questions[toggleId].attributes.required) return invalidConditions
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (toggleId === term.question_id && term.comparator.includes('answered')) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnChoiceDelete(questions, order, itemId, choiceId) {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (term.value === choiceId) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnChangeItemType(questions, order, itemId, prevType, nextType) {
  if (prevType === nextType) return {}
  const invalidConditions = {}
  if (prevType !== nextType) {
    order.forEach(id => {
      if (!questions[id].logic.show_if) return
      logicForEach(questions[id].logic.show_if.terms, (term, path) => {
        if (!term.comparator.includes('answered') && term.question_id === itemId) {
          invalidConditions[id] = invalidConditions[id] || []
          invalidConditions[id].push(path)
        }
      })
    })
  }
  return invalidConditions
}

function logicForEach(terms, callback, path = []) {
  if (!terms) return
  terms.forEach((term, index) => {
    if (term.comparator === 'boolean_combine') {
      logicForEach(term.terms, callback, path.slice().concat(index))
    } else {
      callback(term, path.slice().concat(index))
    }
  })
}

function deleteInvalidLogic(invalidConditions) {
  return {
    type: DELETE_INVALID_LOGIC,
    invalidConditions,
  }
}

function updateItemLabel(itemId, oldEditorState, newEditorState) {
  return {
    type: UPDATE_ITEM_LABEL,
    itemId,
    oldEditorState,
    newEditorState,
  }
}

function updateItem(itemId, item) {
  return {
    type: UPDATE_ITEM,
    itemId,
    item,
  }
}

function toggleUndoItem(itemId, item, undo) {
  return {
    type: TOGGLE_UNDO_ITEM,
    itemId,
    item,
    undo,
  }
}

function toggleWarningBoxOnItem(itemId, isOn) {
  return {
    type: TOGGLE_WARNING_BOX_ON_ITEM,
    itemId,
    isOn,
  }
}

function updateLongListItem(itemId, inputText, noSubsequentLogic) {
  return {
    type: UPDATE_LONG_LIST_ITEM,
    itemId,
    inputText,
    noSubsequentLogic,
  }
}

function deleteLongListItem(itemId) {
  return {
    type: DELETE_LONGLIST_ITEM,
    itemId,
  }
}

function updateLongListItemByCSV(itemId, longList) {
  return {
    type: UPDATE_LONGLIST_ITEM_BY_CSV,
    itemId,
    longList,
  }
}

function checkLogicAndUpdateLongList(survey, itemId, item, choices) {
  const newNumOfChoices = choices.split('\n').length
  const oldNumOfChoices = item.choices_order.length
  const isLogicBasedOnThisLongList = isLogicBasedOnThisItem(survey.questions, survey.order[0], itemId) //Boolean

  return dispatch => {
    if (isLogicBasedOnThisLongList) {
      if (newNumOfChoices === oldNumOfChoices) {
        dispatch(updateLongListItem(itemId, choices))
      } else {
        const onConfirm = () => {
          // delete logic on deleted Choices
          if (oldNumOfChoices > newNumOfChoices) {
            const deletedChoiceIds = item.choices_order.slice(newNumOfChoices - oldNumOfChoices)
            deletedChoiceIds.forEach(deletedId => {
              const invalidConditions = findInvalidLogicOnChoiceDelete(
                survey.questions,
                survey.order[0],
                itemId,
                deletedId,
              )
              if (Object.keys(invalidConditions).length > 0) {
                dispatch(deleteInvalidLogic(invalidConditions))
              }
            })
          }
          // update Changes
          dispatch(updateLongListItem(itemId, choices))
          dispatch(toggleWarningBoxOnItem(itemId, true))
        }
        const onCancel = () => {
          //undo Changes
          dispatch(toggleUndoItem(itemId, item, true))
        }
        dispatch(
          modalActions.openModal({
            content: MODAL_CONTENT_MAP.makeChange,
            confirmButton: MODAL_BUTTONS_MAP.proceed,
            cancelButton: MODAL_BUTTONS_MAP.cancel,
            className: MODAL_CLASSES_MAP.confirmation,
            onConfirm,
            onCancel,
          }),
        )
      }
    } else {
      dispatch(updateLongListItem(itemId, choices, true))
    }
  }
}

function isLogicBasedOnThisItem(questions, order, itemId) {
  return order.some(questionId => {
    if (questionId === itemId) return false
    if (questions[questionId].type === 'introduction') return false
    const { show_if } = questions[questionId].logic
    if (!show_if) return false
    let bool = false
    logicForEach(show_if.terms, (term, path) => {
      if (bool) return
      if (itemId === term.question_id) bool = true
    })
    return bool
  })
}

// Logic Actions
function updateLogicCondition(itemId, logic, conditionPath, field, value) {
  return {
    type: UPDATE_LOGIC_CONDITION,
    itemId,
    conditionPath,
    field,
    value,
    logic,
  }
}

function addLogicCondition(itemId, groupPath) {
  return {
    type: ADD_LOGIC_CONDITION,
    itemId,
    groupPath,
  }
}

function addLogicGroup(itemId) {
  return {
    type: ADD_LOGIC_GROUP,
    itemId,
  }
}

function deleteLogicCondition(itemId, conditionPath) {
  return {
    type: DELETE_LOGIC_CONDITION,
    itemId,
    conditionPath,
  }
}

function deleteAllLogic(itemId) {
  return {
    type: DELETE_ALL_LOGIC,
    itemId,
  }
}

function changeLogicOperator(itemId, groupPath, value) {
  return {
    type: CHANGE_LOGIC_OPERATOR,
    itemId,
    groupPath,
    value,
  }
}

// Survey API Functions

export const uploadImage = formData => {
  const success = payload => {
    const { full_path: src, image_id: imageId } = payload
    return {
      src,
      imageId,
    }
  }
  return dispatch => {
    return dispatch(
      request({
        method: 'POST',
        url: '/control/survey_image',
        contentType: 'multipart/form-data',
        body: formData,
        hasLoader: true,
        loadingKey: 'mediaUpload',
        success,
        successMessage: 'Image successfully uploaded.',
        catchMessage: 'There was a problem uploading the image. Please try again later.',
      }),
    )
  }
}

export const uploadMedia = formData => dispatch => {
  const success = payload => {
    const { full_path: src, file_id: fileId } = payload
    return {
      src,
      fileId,
    }
  }

  const fail = (response, content) => {
    dispatch(notyActions.showError({ text: generateNotyMessage(content.message, false) }))
    return Promise.resolve(false)
  }

  return dispatch(
    request({
      method: 'POST',
      url: '/control/survey_resource',
      contentType: 'multipart/form-data',
      body: formData,
      hasLoader: true,
      loadingKey: 'mediaUpload',
      success,
      fail,
      successMessage: 'Media successfully uploaded.',
      catchMessage: 'There was a problem uploading the media. Please try again later.',
    }),
  )
}

export const uploadChoicesCSV = (file, itemId, survey) => dispatch => {
  const success = longList => {
    const invalidConditions = findInvalidLogicOnDelete(survey.questions, survey.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) dispatch(deleteInvalidLogic(invalidConditions))
    dispatch(updateLongListItem(itemId, longList.join('\n'), true))
  }

  const fail = (response, content) => {
    dispatch(notyActions.showError({ text: generateNotyMessage(content.message, false) }))
    return Promise.resolve(false)
  }
  return dispatch(
    request({
      method: 'POST',
      url: '/control/questions/upload_csv',
      body: file,
      success,
      fail,
      successMessage: 'CSV successfully uploaded.',
      catchMessage: 'There was a problem uploading the file. Please try again later.',
      hasLoader: true,
      contentType: 'text/csv',
    }),
  )
}

export function downloadChoicesCSV({ question = {} }) {
  return dispatch => {
    const url = '/control/questions/download_csv'
    const success = (blob, fileName) => {
      downloadBlob(blob, `question_choices.csv`, fileName)
    }
    return dispatch(
      request({
        url,
        body: JSON.stringify(question),
        method: 'POST',
        resType: 'blob',
        success,
      }),
    )
  }
}

export function fetchSurvey(studyID, instrumentId, version, useLoader) {
  return (dispatch, getState) => {
    const { location } = getState()
    const path = location?.pathname.match(/([^/]+$)/)[0]
    const versionText = version ? `?version=${version}` : ''
    function success(json) {
      prepareSurvey(json)
        .then(result => {
          const { formattedJSON, editorStates: _editorStates } = result
          calcQuestionNumbers(formattedJSON.order, formattedJSON.questions)
          return Promise.resolve({ formattedJSON, editorStates: _editorStates })
        })
        .then(result => {
          const { formattedJSON: _formattedJSON, editorStates: _editorStates } = result
          dispatch(initializeEdit(_formattedJSON, _editorStates))
          let errors = getSurveyErrors(_formattedJSON, _editorStates)
          if (_formattedJSON?.scores?.length) {
            errors = {
              ...errors,
              ...getFormulaErrors(_formattedJSON),
            }
          }
          dispatch(setSurveysScore(instrumentId, _formattedJSON?.scores))
          dispatch(addSurveyErrors(errors))
          const isScoringPage = path === INSTRUMENT_PATH_MAP.scoring

          // Render a noty to alert user there are score errors if there are
          if (isScoringPage && !!errors.scores) {
            dispatch(
              notyActions.showError({
                text: generateNotyMessage('Please check errors before proceeding.', false),
              }),
            )
          }

          return Promise.resolve()
        })
        .then(() => {
          dispatch(loadingActions.stopLoader(true))
        })
    }

    function fail(res) {
      dispatch(loadingActions.stopLoader(true))
      throw new Error(`${res.status} ${res.statusText} when fetching instrument`)
    }

    if (!useLoader) dispatch(loadingActions.startLoader(true))

    return dispatch(
      request({
        method: 'GET',
        url: `/control/admin/studies/${studyID}/instruments/${instrumentId}/survey.json${versionText}`,
        success,
        fail,
      }),
    )
  }
}
export function fetchArticle(studyID, instrumentId, version, useLoader) {
  return dispatch => {
    const versionText = version ? `?version=${version}` : ''

    function fail(res) {
      throw new Error(`${res.status} ${res.statusText} when fetching instrument`)
    }

    return dispatch(
      request({
        method: 'GET',
        url: `/control/admin/studies/${studyID}/instruments/${instrumentId}/article.json${versionText}`,
        success: json => dispatch(initializeArticleEdit(json)),
        fail,
        hasLoader: !useLoader,
        forceLoader: !useLoader,
      }),
    )
  }
}

export function validateSurveyAndSave({ studyID, surveyEditor, onRedirect, redirect, path }) {
  return dispatch => {
    let errors = getSurveyErrors(surveyEditor.survey, surveyEditor.editorStates)

    /**
     * This boolean is created to allow users to save the instrument even if there are formula
     * errors if they are not navigating to or from the scoring page.
     */
    const isScoringPath = [redirect, path].includes(INSTRUMENT_PATH_MAP.scoring)
    const formulaErrors = getFormulaErrors(surveyEditor.survey)

    if (surveyEditor.survey?.scores?.length && isScoringPath) {
      errors = {
        ...errors,
        ...formulaErrors,
      }
    }

    const redirectIsScoring = redirect === 'scoring'
    const hasScoringErrors = !!errors.scores && Object.keys(errors.scores).length > 0
    const hasQuestionErrors = Object.keys(errors.questions).length > 0
    const hasAnyError = Object.keys(errors).length > 1

    const valid = !(hasScoringErrors || hasQuestionErrors || hasAnyError)
    if (valid) {
      // remove [] used as marker for end of domain ID
      surveyEditor.survey.scores = surveyEditor.survey.scores.map(score => {
        return {
          ...score,
          formula: replaceTargetsWithVal(score.formula, ['[]'], ''),
        }
      })
    }
    if (hasScoringErrors && redirectIsScoring && !hasQuestionErrors) {
      dispatch(saveSurveyToDatabase({ studyID, ...surveyEditor }))
        .then(onRedirect)
        .then(() => {
          dispatch(addSurveyErrors(errors))
        })
    } else if (valid) {
      dispatch(clearSurveyErrors())
      dispatch(saveSurveyToDatabase({ studyID, ...surveyEditor })).then(onRedirect)
    } else {
      dispatch(addSurveyErrors(errors))
      dispatch(
        notyActions.showError({
          text: generateNotyMessage('Please check errors before proceeding.', false),
        }),
      )
    }
  }
}

export function _saveSurvey(studyID, body, surveyID, isSurvey = true) {
  let reqBody = body
  if (surveyID) {
    const { data } = body
    reqBody = data
  }
  return dispatch => {
    const url = `/control/studies/${studyID}/instruments${
      surveyID === undefined ? '' : `/${surveyID}/${isSurvey ? 'survey' : 'article'}.json`
    }`
    return dispatch(
      request({
        url,
        body: isSurvey ? body : JSON.stringify(reqBody),
        method: surveyID ? 'PUT' : 'POST',
        successMessage: 'Instrument saved successfully',
        hasLoader: true,
        failMessage: 'Failed to save instrument to database.',
        success: json => json,
      }),
    )
  }
}

const httpRegex = /^http(s)?:\/\//gm

const validateURL = _article => {
  let { url } = _article
  if (!url.match(httpRegex)) {
    url = `http://${url}`
  }
  _article.url = url
  return _article
}

export const saveArticleToDatabase = (studyID, _article) => {
  validateURL(_article)
  const articleJSON = JSON.parse(JSON.stringify(_article))
  const jsonBody = {
    format: 'json',
    data: articleJSON,
  }
  return dispatch => {
    return dispatch(_saveSurvey(studyID, jsonBody, _article.id, null, false))
  }
}

export const validateArticleAndSave = (studyID, _article, onRedirect) => {
  return dispatch => {
    const articleJSON = JSON.parse(JSON.stringify(_article))
    const errors = getArticleErrors(articleJSON)
    const valid = !(Object.keys(errors).length > 0)
    if (valid) {
      if (!articleJSON?.metadata?.display_name) articleJSON.metadata.display_name = articleJSON.title
      dispatch(clearSurveyErrors())
      dispatch(saveArticleToDatabase(studyID, articleJSON)).then(onRedirect)
    } else {
      dispatch(addSurveyErrors(errors))
      dispatch(
        notyActions.showError({
          text: generateNotyMessage('Please check errors before proceeding.', false),
        }),
      )
    }
  }
}

const _fixQuestionAndChoiceIdMismatch = (question, qId) => {
  const { choices, choices_order } = question
  const newChoiceOrder = []

  for (let i = 0; i < choices_order.length; i++) {
    const choiceId = choices_order[i]
    const [prefix, suffix] = choiceId.split('_')
    if (prefix !== qId) {
      const newChoiceId = `${qId}_${suffix}`
      question.choices[newChoiceId] = choices[choiceId]
      delete choices[choiceId]
      newChoiceOrder.push(newChoiceId)
    } else {
      newChoiceOrder.push(choiceId)
    }
    question.choices_order = newChoiceOrder
  }
}

export const validateAndFixQuestionAndChoiceIdMismatches = instrument => {
  const { questions, order } = instrument
  const qOrder = order[0]
  for (let i = 0; i < qOrder.length; i++) {
    const qId = qOrder[i]
    const question = questions[qId]
    const { type } = question
    if (QUESTION_TYPES_WITH_CHOICES.includes(type)) {
      _fixQuestionAndChoiceIdMismatch(question, qId)
    }
  }
}

export function saveSurveyToDatabase({ survey: _survey, studyID, editorStates: _editorStates, isTest = false }) {
  return dispatch => {
    const surveyJSON = JSON.parse(JSON.stringify(_survey))
    cleanUpSurvey(surveyJSON)

    /**
     * This function will resolve any mismatches between a question's ID and
     * its choices' IDs.
     *  */
    validateAndFixQuestionAndChoiceIdMismatches(surveyJSON)

    surveyJSON.metadata.display_name = surveyJSON.metadata.display_name || surveyJSON.title

    let imageList = []
    const requiredPermissions = []

    for (const qId in surveyJSON.questions) {
      const question = surveyJSON.questions[qId]
      const { hasScoring } = question.attributes
      if (question.type === QUESTION_TYPE_MAP.audioRecording) {
        if (!requiredPermissions.includes(QUESTION_TYPE_MAP.audioRecording))
          requiredPermissions.push(QUESTION_TYPE_MAP.audioRecording)
      }
      if (question.type === QUESTION_TYPE_MAP.imageCapture && question.preview_images) {
        if (question.preview_images) {
          question.preview_images = question.preview_images.filter(imageId => imageId !== null)
          imageList = [...imageList, ...question.preview_images]
        }
      }
      const { questionsWithScoring } = surveyJSON
      const { domains } = question

      // logic to handle domains and scoring
      if (!QUESTION_TYPES_WITH_SCORING.includes(question.type)) {
        if (hasScoring) {
          delete question.attributes.hasScoring
          delete question.formula
          delete questionsWithScoring[qId]
          if (domains) {
            domains.forEach(domainId => {
              const domainIsNotUsedByInstrument = !getDomainIsUtilized({ questionsWithScoring, domainId })
              if (domainIsNotUsedByInstrument) {
                delete surveyJSON.domains[domainId]
              }
            })
          }
          delete question.domains
          delete question.formula
        }
      } else if (!hasScoring) {
        delete question.attributes.hasScoring
        delete question.formula
        delete questionsWithScoring[qId]
        if (domains) {
          domains.forEach(domainId => {
            const domainIsNotUsedByInstrument = !getDomainIsUtilized({ questionsWithScoring, domainId })
            if (domainIsNotUsedByInstrument) {
              delete surveyJSON.domains[domainId]
            }
          })
        }
        delete question.domains
        delete question.formula
      } else if (question.type !== QUESTION_TYPE_MAP.selectMultiple) {
        delete question.formula
      }

      // remove unused attributes exclusive to certain question types
      if (!QUESTION_TYPES_WITH_HINTS.includes(question.type)) {
        delete question.hint
      }
      if (!QUESTION_TYPES_WITH_CAREGIVER_HINTS.includes(question.type)) {
        delete question.caregiver_hint
      }
      if (!SCALE_QUESTION_TYPES.includes(question.type)) {
        delete question.scale_labels
      }
      if (question.type !== QUESTION_TYPE_MAP.audioRecording) {
        delete question.attributes.min
      }
      if (question.type !== QUESTION_TYPE_MAP.imageCapture) {
        delete question.preview_images
        delete question.previewImagesSrc
        delete question.camera_tip
      }
      if (question.type === QUESTION_TYPE_MAP.numericRatingScale) {
        if (!question.attributes[QUESTION_ATTRIBUTE_MAP.hasOptOut]) {
          delete question.attributes[QUESTION_ATTRIBUTE_MAP.hasOptOut]
          delete question.attributes[QUESTION_ATTRIBUTE_MAP.optOut]
        }
      } else {
        delete question.attributes[QUESTION_ATTRIBUTE_MAP.hasOptOut]
        delete question.attributes[QUESTION_ATTRIBUTE_MAP.optOut]
      }

      if (question.image) imageList = [...imageList, question.image]

      for (const choiceId in question.choices) {
        const choice = question.choices[choiceId]
        if (!hasScoring) {
          delete choice.score_value
        } else {
          const { score_value } = choice
          if (score_value || score_value === 0) choice.score_value = Number(choice.score_value)
          else choice.score_value = ''
        }
        if (question.type === QUESTION_TYPE_MAP.multipleField) {
          if (!question.attributes.hasCharLimit) {
            // deletes a choice's min and max key if the question does not have input validation
            delete choice.max
            delete choice.min
          } else if (
            (choice.max === '' || choice.max === undefined) &&
            (choice.min === '' || choice.min === undefined)
          ) {
            delete choice.max
            delete choice.min
          } else if ((choice.max === '' || choice.max === undefined) && choice.min !== '') {
            choice.max = 999999999
          } else if ((choice.min === '' || choice.min === undefined) && choice.max !== '') {
            choice.min = 0
          }
          if (choice.type === 'text') delete choice.min
          const choiceLabel = choice.label
          choice.choice_label = choiceLabel
          delete choice.label
        }
        if (choice.image) {
          imageList = [...imageList, choice.image]
        }
      }
      if (question.input_validation) {
        if (question.input_validation.absolute_date) {
          const { min, max } = question.input_validation.absolute_date
          if (min) {
            question.input_validation.absolute_date.min = getFormattedDateString(min, DATE_FORMAT_MAP.datePicker, true)
          }
          if (max) {
            question.input_validation.absolute_date.max = getFormattedDateString(max, DATE_FORMAT_MAP.datePicker, true)
          }
        }
      }
    }
    if (imageList.length) {
      const uniqImageList = [...new Set(imageList)]
      surveyJSON.image_list = uniqImageList
    }
    surveyJSON.required_permissions = requiredPermissions

    convertLabelsToHTML(surveyJSON.questions, _editorStates, isTest)

    function success(resJSON) {
      dispatch({
        type: UPDATE_ORIGINAL_SURVEY,
        json: _survey,
      })
      return Promise.resolve(resJSON)
    }

    const body = JSON.stringify(_survey.id ? surveyJSON : { data: surveyJSON, format: 'json' })
    return dispatch(_saveSurvey(studyID, body, _survey.id)).then(success)
  }
}

export function saveUploadToDatabase(studyID, uploadedSurvey) {
  return (dispatch, getState) => {
    let data = JSON.parse(uploadedSurvey.data)

    /**
     * This function will resolve any mismatches between a question's ID and
     * its choices' IDs in the uploaded JSON.
     *  */
    validateAndFixQuestionAndChoiceIdMismatches(data)

    const { participants, user } = getState()
    data = cleanUploadedJSON(data, participants, user, studyID)
    const body = {
      format: uploadedSurvey.format,
      data,
    }

    return dispatch(_saveSurvey(studyID, body, uploadedSurvey.id, null))
  }
}

//
// State Generators
//

function generateBlankSurvey(initialTitle, displayName) {
  return {
    title: initialTitle || '',
    type: 'SURVEY',
    metadata: _getDefaultMetadata('SURVEY', displayName),
    order: [[]],
    questions: {},
    questionsWithScoring: {},
    image_list: [],
    resource_list: [],
    resource_size_list: [],
    scores: [],
  }
}

function generateBlankArticle(initialTitle, displayName) {
  return {
    title: initialTitle || '',
    type: 'ARTICLE',
    metadata: _getDefaultMetadata('ARTICLE', displayName),
    url: '',
  }
}

//
// Util Functions
//

// this creates image elements that will render in the survey editor

const imageRegex = /<img src(.*)\/>/g
const pOpen = /<p>/g
const pClose = /<\/p>/g

const removeImageElementFromLabel = label => {
  let newLabel = label.slice()
  newLabel = newLabel.replace(imageRegex, '')
  newLabel = newLabel.replace(pOpen, '')
  newLabel = newLabel.replace(pClose, '')
  return newLabel
}

export function prepareSurvey(json) {
  const _editorStates = {}
  for (const qId in json.questions) {
    // convert note to introduction
    const question = json.questions[qId]

    let _editorState = null

    /**
     * Some question labels will have json representation of the label content. As such,
     * these labels will use the `convertFromRaw` method from draftjs to parse these labels
     * into the editorState, as opposed to the usual parser, `HTMLToEditorState`
     */
    if (question.labelJson) {
      const labelContentState = convertFromRaw(question.labelJson)
      _editorState = EditorState.createWithContent(labelContentState)
    } else {
      _editorState = HTMLToEditorState(question.label)
    }

    _editorStates[qId] = formatLinkEntities(_editorState)
    const label = stateToHTML(_editorStates[qId].getCurrentContent())
    question.label = removeImageElementFromLabel(label)
    if (question.type === 'note') {
      question.type = QUESTION_TYPE_MAP.introduction
    } else if (
      question.type === QUESTION_TYPE_MAP.selectOne &&
      question.attributes.appearance === QUESTION_TYPE_MAP.likert
    ) {
      question.type = QUESTION_TYPE_MAP.likert
    } else if (question.type === QUESTION_TYPE_MAP.audioRecording && question.attributes.show_timer === undefined) {
      question.attributes.show_timer = true
    }
    if (question.attributes.hasScoring && !question.formula) {
      question.formula = 'MEAN'
    }
    // If the question is not a multiple field type, delete the type key in the choices
    if (QUESTION_TYPES_WITH_CHOICES.includes(question.type) && question.type !== QUESTION_TYPE_MAP.multipleField) {
      question.choices_order.forEach(choiceId => {
        delete question.choices[choiceId].type
      })
    }
    delete question.attributes.appearance
    // encapsulate logic conditions if they aren't in a boolean combine
    if (Object.keys(question.logic).length > 0 && question.logic.show_if.comparator !== 'boolean_combine') {
      question.logic.show_if = {
        comparator: 'boolean_combine',
        operator: 'and',
        terms: [question.logic.show_if],
      }
    }
    const { choices_order, image, resources } = question
    if (image) {
      addMediaToFileMap({ _instrument: json, fileId: image, itemId: qId })
    } else if (resources) {
      const { audio, video } = resources
      addMediaToFileMap({ _instrument: json, fileId: audio || video, itemId: qId })
    }
    if (choices_order) {
      choices_order.forEach(choiceId => {
        const choice = question.choices[choiceId]
        const { score_value } = choice
        if (choice.image) {
          choice.label = removeImageElementFromLabel(choice.label)
          addMediaToFileMap({ _instrument: json, fileId: choice.image, itemId: qId })
        }
        if (score_value || score_value === 0) choice.score_value = score_value.toString()

        if (SELECT_QUESTION_TYPES.includes(question.type)) {
          let _choiceEditorState = null

          /**
           * Some labels will have json representation of the label content. As such,
           * these labels will use the `convertFromRaw` method from draftjs to parse these labels
           * into the editorState, as opposed to the usual parser, `HTMLToEditorState`
           */
          if (choice.labelJson) {
            const labelContentState = convertFromRaw(choice.labelJson)
            _choiceEditorState = EditorState.createWithContent(labelContentState)
          } else {
            _choiceEditorState = HTMLToEditorState(choice.label)
          }

          _editorStates[choiceId] = formatLinkEntities(_choiceEditorState)
        }

        if (question.type === QUESTION_TYPE_MAP.multipleField) {
          const choiceLabel = choice.choice_label
          choice.label = choiceLabel
          delete choice.choice_label
        }
      })
    }
    delete json.required_permissions

    if (question.input_validation && question.input_validation.absolute_date) {
      json.questions[qId].input_validation.isExactRangeTab = true
      const { min, max } = question.input_validation.absolute_date
      if (min) {
        json.questions[qId].input_validation.absolute_date.min = createMomentObject(min).toDate()
      }
      if (max) {
        json.questions[qId].input_validation.absolute_date.max = createMomentObject(max).toDate()
      }
    }
  }

  if (!json.questionsWithScoring) {
    json.questionsWithScoring = {}
  }
  if (!json.scores) {
    json.scores = []
  }

  return Promise.resolve({
    formattedJSON: json,
    editorStates: _editorStates,
  })
}

// TODO: delete `type` key from choice if question is not a mulitple field (do this in diary as well)

function cleanUpSurvey(_survey) {
  // Remove unnecessary fields
  delete _survey.activeItemId
  for (const qId in _survey.questions) {
    removeValuesAndLabels(_survey.questions[qId])
    removeChoices(_survey.questions[qId])
    delete _survey.questions[qId].images
    delete _survey.questions[qId].label_images
    delete _survey.questions[qId].attributes.canHaveLogic
    delete _survey.questions[qId].attributes.questionNumber

    if (_survey.questions[qId].input_validation) {
      if (_survey.questions[qId].input_validation.isExactRangeTab) {
        delete _survey.questions[qId].input_validation.relative
      } else if ([QUESTION_TYPE_MAP.date, QUESTION_TYPE_MAP.datetime].includes(_survey.questions[qId].type)) {
        // delete _survey.questions[qId].input_validation.absolute_date
        // delete _survey.questions[qId].input_validation.absolute_time
      }

      delete _survey.questions[qId].input_validation.isExactRangeTab

      for (const key of Object.keys(_survey.questions[qId].input_validation)) {
        for (const validationKey of Object.keys(_survey.questions[qId].input_validation[key])) {
          if (_survey.questions[qId].input_validation[key][validationKey] === '') {
            delete _survey.questions[qId].input_validation[key][validationKey]
            if (validationKey === RELATIVE_DATE_INTERVAL_MAP.preceding) {
              delete _survey.questions[qId].input_validation[key].preceding_interval_type
            }
            if (validationKey === RELATIVE_DATE_INTERVAL_MAP.succeeding) {
              delete _survey.questions[qId].input_validation[key].succeeding_interval_type
            }
          }
        }
        if (
          ![
            INPUT_VALIDATION_TYPE_MAP.decimalPlaces,
            INPUT_VALIDATION_TYPE_MAP.min,
            INPUT_VALIDATION_TYPE_MAP.max,
            INPUT_VALIDATION_TYPE_MAP.acceptFutureValues,
          ].includes(key) &&
          !Object.keys(_survey.questions[qId].input_validation[key]).length
        ) {
          delete _survey.questions[qId].input_validation[key]
        }
        if (
          key === INPUT_VALIDATION_TYPE_MAP.relative &&
          Object.keys(_survey.questions[qId].input_validation[key]).length === 1
        ) {
          delete _survey.questions[qId].input_validation[key]
        }
        if (_survey.questions[qId].input_validation[key] === '') {
          delete _survey.questions[qId].input_validation[key]
        }
      }
      if (!Object.keys(_survey.questions[qId].input_validation).length) {
        delete _survey.questions[qId].input_validation
      }
    }
  }
  if (Object.keys(_survey.questionsWithScoring).length === 0) {
    _survey.scores = []
  }
}

function convertLabelsToHTML(questions, _editorStates, isTest = false) {
  for (const key in questions) {
    const question = questions[key]
    question.label = EditorStateToHTML(_editorStates[key])

    if (!isTest) {
      const labelJson = convertToRaw(_editorStates[key].getCurrentContent())
      question.labelJson = labelJson
    }

    if (SELECT_QUESTION_TYPES.includes(question.type)) {
      question.choices_order.forEach(choiceId => {
        const choice = question.choices[choiceId]

        if (!isTest) {
          const choiceLabelJson = convertToRaw(_editorStates[choiceId].getCurrentContent())
          choice.labelJson = choiceLabelJson
        }
        let _label = EditorStateToHTML(_editorStates[choiceId])

        //
        // add image element to label if there is a choice label image
        //
        if (choice.image) _label = `${_label}<img src="${choice.image}"/>`
        choice.label = _label
      })
    }
    question.label = removeImageElementFromLabel(question.label)
    if (question.image) {
      //
      // add image element to label if there is a question label image
      //
      question.label = `${question.label}<img src="${question.image}"/>`
    }
  }
}

function countWords(string) {
  if (string === '') return 0
  return string.trim().split(' ').length
}

function countWordsInSurvey(_survey) {
  return _survey.order[0].reduce((sum, itemId) => {
    return sum + countWords(_survey.questions[itemId].label)
  }, 0)
}

export function calcQuestionNumbers(order, questions) {
  let count = 0
  order[0].forEach(id => {
    if (!QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS.includes(questions[id].type)) {
      count++
      questions[id].attributes.questionNumber = count
      questions[id].attributes.canHaveLogic = count > 1
    } else {
      questions[id].attributes.canHaveLogic = count >= 1
    }
  })
}

function dupStateAndItem(state, itemId) {
  const newState = { ...state }
  newState.questions = { ...newState.questions }
  newState.questions[itemId] = { ...newState.questions[itemId] }
  return newState
}
function dupStateAndItemAndChoice(state, itemId, choiceId) {
  // spread operator is only shallow copy hence the need for this func
  const newState = { ...state }
  newState.questions = { ...newState.questions }
  newState.questions[itemId] = { ...newState.questions[itemId] }
  newState.questions[itemId].choices[choiceId] = { ...newState.questions[itemId].choices[choiceId] }
  return newState
}

function getCondition(terms, conditionPath) {
  if (conditionPath.length === 1) {
    return terms[conditionPath[0]]
  }
  return getCondition(terms[conditionPath[0]].terms, conditionPath.slice(1))
}

function setCondition(terms, conditionPath, newValue) {
  if (conditionPath.length === 1) {
    terms[conditionPath[0]] = newValue
  } else {
    setCondition(terms[conditionPath[0]].terms, conditionPath.slice(1), newValue)
  }
}

function addCondition(terms, groupPath) {
  if (groupPath.length === 1) {
    terms[groupPath[0]].terms.push({
      question_type: '',
      question_id: '',
      value: '',
      comparator: '',
    })
  } else {
    addCondition(terms[groupPath[0]].terms, groupPath.slice(1))
  }
}

function deleteCondition(showIf, conditionPath) {
  if (conditionPath.length === 1) {
    showIf.terms.splice(conditionPath[0], 1)
  } else {
    deleteCondition(showIf.terms[conditionPath[0]], conditionPath.slice(1))
    if (showIf.terms[conditionPath[0]].terms.length === 0) {
      showIf.terms.splice(conditionPath[0], 1)
    }
  }
}

function removeLoneGroup(logic) {
  if (logic.show_if.terms.length < 1) {
    delete logic.show_if
  } else if (logic.show_if.terms[0].comparator === 'boolean_combine' && logic.show_if.terms.length === 1) {
    logic.show_if.terms = logic.show_if.terms[0].terms
  }
}

function deleteSubsequentLogic(questions, itemId, conditionPath) {
  let logicConditions
  if (conditionPath.length === 1) {
    logicConditions = { ...questions[itemId].logic.show_if.terms[conditionPath[0]] }
  } else {
    logicConditions = { ...questions[itemId].logic.show_if.terms[conditionPath[0]].terms[conditionPath[1]] }
  }

  const subsequentQuestion = questions[logicConditions.question_id]
  if (subsequentQuestion?.type !== QUESTION_TYPE_MAP.longListSelectOne) return // this function is only for long list question type

  if (logicConditions.value) {
    const choiceWithLogic = subsequentQuestion.subsequentLogic[logicConditions.value]
    if (choiceWithLogic[itemId].numConditions > 1) {
      choiceWithLogic[itemId].numConditions -= 1
    } else {
      delete choiceWithLogic[itemId]
    }
    if (Object.keys(choiceWithLogic).length === 0) delete subsequentQuestion.subsequentLogic[logicConditions.value]
  }
  if (Object.keys(subsequentQuestion.subsequentLogic).length === 0) delete subsequentQuestion.subsequentLogic
}

function updateSubsequentQuestionLogic(questions, action, oldCondition, newCondition) {
  // this function creates a redux obj "subsequentLogic" if subsequentQuestion is long list type question
  // subsequentQuestion is the question this logic is based on.
  const subsequentQuestion = questions[newCondition.question_id]
  const oldSubsequentQuestion = questions[oldCondition.question_id]
  if (
    subsequentQuestion.type !== QUESTION_TYPE_MAP.longListSelectOne &&
    oldCondition.question_type !== QUESTION_TYPE_MAP.longListSelectOne
  )
    return

  switch (action.field) {
    case 'question_id':
      if (subsequentQuestion.type === QUESTION_TYPE_MAP.longListSelectOne && !subsequentQuestion.subsequentLogic)
        subsequentQuestion.subsequentLogic = {}
      break
    case 'value': {
      const oldChoiceId = oldCondition.value
      // value is choice id
      const newChoiceId = action.value
      const { subsequentLogic } = subsequentQuestion
      if (oldChoiceId !== newChoiceId) {
        if (!subsequentLogic[newChoiceId]) {
          subsequentLogic[newChoiceId] = {
            [action.itemId]: { numConditions: 1 },
          }
          // 'numConditions' counts the number of logic conditions on the same choice within the same question (in case of duplicate logic condition)
          // 1 is a default number and means there is no duplicate logic
        } else if (subsequentLogic[newChoiceId][action.itemId]) {
          subsequentLogic[newChoiceId][action.itemId].numConditions += 1
        } else {
          subsequentLogic[newChoiceId][action.itemId] = { numConditions: 1 }
        }
      }
      break
    }
    default:
      break
  }
  if (
    oldCondition.question_id &&
    oldCondition.comparator === newCondition.comparator &&
    oldCondition.question_type === QUESTION_TYPE_MAP.longListSelectOne &&
    (oldCondition.value !== newCondition.value || oldCondition.question_id !== newCondition.question_id)
  ) {
    if (oldCondition.value) {
      const oldChoiceWithLogic = oldSubsequentQuestion.subsequentLogic[oldCondition.value]
      if (oldChoiceWithLogic[action.itemId].numConditions > 1) {
        oldChoiceWithLogic[action.itemId].numConditions -= 1
      } else {
        delete oldChoiceWithLogic[action.itemId]
      }
      if (Object.keys(oldChoiceWithLogic).length === 0) delete oldSubsequentQuestion.subsequentLogic[oldCondition.value]
    }
    if (Object.keys(oldSubsequentQuestion.subsequentLogic).length === 0) delete oldSubsequentQuestion.subsequentLogic
  }
}

function deleteAllSubsequentLogic(questions, itemId, terms) {
  terms.forEach((term, path1) => {
    const conditionPath = [path1]
    if (term.terms) {
      //group logic
      term.terms.forEach((_, path2) => {
        conditionPath[1] = path2
        deleteSubsequentLogic(questions, itemId, conditionPath)
      })
    } else {
      deleteSubsequentLogic(questions, itemId, conditionPath)
    }
  })
}

function setOperator(logic, groupPath, value) {
  if (groupPath.length === 0) {
    logic.operator = value
  } else {
    setOperator(logic.terms[groupPath[0]], groupPath.slice(1), value)
  }
}

function generateNewScoreObject(id = getRandomQuestionId()) {
  return {
    id,
    name: '',
    description: '',
    formula: '',
    tagFormula: '',
  }
}

//
// Reducers
//

export function article(state = { url: '' }, action) {
  switch (action.type) {
    case INITIALIZE_ARTICLE_EDIT: {
      return action.json
    }
    case INITIALIZE_BLANK_ARTICLE: {
      return generateBlankArticle(action.title, action.displayName)
    }
    case UPDATE_ARTICLE_TITLE: {
      return { ...state, title: action.value }
    }
    case UPDATE_ARTICLE_URL: {
      return { ...state, url: action.url }
    }
    default:
      return state
  }
}

export function survey(state = generateBlankSurvey(), action) {
  switch (action.type) {
    case INITIALIZE_BLANK_SURVEY: {
      return generateBlankSurvey(action.title, action.displayName)
    }
    case INITIALIZE_EDIT: {
      return action.json
    }
    case UPDATE_TITLE: {
      return { ...state, title: action.title }
    }
    case TOGGLE_EDIT: {
      const newState = { ...state }
      newState.activeItemId = action.itemId
      return newState
    }
    case UPDATE_ITEM: {
      const newState = dupStateAndItem(state, action.itemId)
      Object.assign(newState.questions[action.itemId], action.item)
      return newState
    }
    case UPDATE_LONG_LIST_ITEM: {
      const newState = dupStateAndItem(state, action.itemId)
      const question = newState.questions[action.itemId]

      const prevChoicesOrder = question.choices_order
      const prevNumChoices = prevChoicesOrder.length

      const nextInputTextArr = action.inputText.split('\n')
      const nextNumChoices = nextInputTextArr.length
      const choicesDiff = nextNumChoices - prevNumChoices

      const newChoices = {}
      const newChoicesOrder = []

      for (let idx = 0; idx < nextNumChoices; idx++) {
        const newContent = nextInputTextArr[idx]
        const choiceId = prevChoicesOrder[idx]
        const currentNum = idx + 1
        if (choicesDiff > 0) {
          // when next number of choices is larger than previous number of choices
          if (currentNum > prevNumChoices) {
            const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
            newChoices[newChoiceId] = { label: newContent }
            newChoicesOrder.push(newChoiceId)
          } else {
            newChoices[choiceId] = { label: newContent }
            newChoicesOrder.push(choiceId)
          }
        } else {
          // when next number of choices is less than/equal to prev number of choices
          newChoices[choiceId] = { label: newContent }
          newChoicesOrder.push(choiceId)
        }
      }
      question.choices = newChoices
      question.choices_order = newChoicesOrder
      question.choices_text = action.inputText
      if (action.noSubsequentLogic && question.subsequentLogic) delete question.subsequentLogic
      return newState
    }
    case DELETE_LONGLIST_ITEM: {
      const newState = dupStateAndItem(state, action.itemId)
      const question = newState.questions[action.itemId]
      question.choices = {}
      question.choices_order = []
      question.choices_text = ''
      return newState
    }
    case UPDATE_LONGLIST_ITEM_BY_CSV: {
      const { itemId, longList } = action
      const newState = dupStateAndItem(state, itemId)
      const question = newState.questions[itemId]

      const choicesText = longList.join('\n')

      const longListObj = longList.reduce((prevObj, currentValue, currentIndex) => {
        const newChoiceId = `${itemId}_${getRandomQuestionId()}`
        return {
          ...prevObj,
          [currentIndex]: { [newChoiceId]: { label: currentValue } },
        }
      }, {})
      const longListValues = Object.values(longListObj)
      const choices = longListValues.reduce((prevObj, value) => {
        return {
          ...prevObj,
          [Object.keys(value).flat()]: Object.values(value)[0],
        }
      }, {})
      const choiceOrder = longListValues.map(objKey => Object.keys(objKey)).flat()

      question.choices = choices
      question.choices_order = choiceOrder
      question.choices_text = choicesText

      return newState
    }
    case UPDATE_ITEM_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].label = stateToHTML(action.newEditorState.getCurrentContent())
      return newState
    }
    case CHANGE_ITEM_TYPE: {
      const newState = dupStateAndItem(state, action.itemId)
      const question = newState.questions[action.itemId]
      question.type = action.nextType

      if (action.prevType === action.newType) return newState

      if (!QUESTION_TYPES_WITH_SCORING.includes(action.nextType)) {
        const { domains } = question
        const { hasScoring } = question.attributes
        if (hasScoring) {
          delete question.attributes.hasScoring
          delete question.formula
          delete newState.questionsWithScoring[action.itemId]
          if (domains) {
            domains.forEach(domainId => {
              const domainIsNotUsedByInstrument = !getDomainIsUtilized({
                questionsWithScoring: newState.questionsWithScoring,
                domainId,
              })
              if (domainIsNotUsedByInstrument) {
                delete newState.domains[domainId]
              }
            })
          }
          delete question.domains
          delete question.formula
        }
      }

      if (SELECT_QUESTION_TYPES.includes(action.prevType)) {
        if (!SELECT_QUESTION_TYPES.includes(action.nextType)) {
          action.item.choices_order.forEach(choiceId => {
            const choice = action.item.choices[choiceId]
            const newLabel = stripHtmlTags(choice.label)
            question.choices[choiceId].label = newLabel
            if (action.nextType === QUESTION_TYPE_MAP.longListSelectOne) {
              question.choices[choiceId].label = newLabel.replace(/\n/g, ' ')
            }
          })
          delete question.attributes[QUESTION_ATTRIBUTE_MAP.disableButtons]
        }
      }

      if (action.prevType === QUESTION_TYPE_MAP.longListSelectOne) {
        if (question.subsequentLogic) delete question.subsequentLogic
        delete question.choices_text
      }

      if (!QUESTION_TYPES_WITH_CHOICES.includes(action.prevType)) {
        question.choices = {}
        question.choices_order = []

        const { choiceIdOne, choiceIdTwo, choiceIdThree } = action.newChoices
        question.choices[choiceIdOne] = { label: '' }
        question.choices_order.push(choiceIdOne)
        question.choices[choiceIdTwo] = { label: '' }
        question.choices_order.push(choiceIdTwo)
        question.choices[choiceIdThree] = { label: '' }
        question.choices_order.push(choiceIdThree)
      }

      if (SELECT_QUESTION_TYPES.includes(action.nextType)) {
        delete question.attributes.other
        while (question.choices_order.length < 2) {
          // when a question type is changed to select type, the question is given a minimum of two choices
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
        question.choices_order.forEach(choiceId => {
          delete question.choices[choiceId].value
        })
      }
      if (action.nextType === QUESTION_TYPE_MAP.longListSelectOne) {
        const choicesTextArr = []
        while (question.choices_order.length < 10) {
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
          question.choices_text += '\n'
        }
        question.choices_order.forEach(choiceId => {
          const choice = question.choices[choiceId]
          choicesTextArr.push(choice.label)
          delete question.choices[choiceId].value
        })
        question.choices_text = choicesTextArr.join('\n')
      }
      if (action.nextType === QUESTION_TYPE_MAP.likert) {
        delete question.attributes.other
        while (question.choices_order.length < 5) {
          // when a question type is changed to likert, the question is given a minimum of five choices
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
        if (question.choices_order.length > 7) {
          question.choices_order.length = 7
        } else if (question.choices_order.length === 6) {
          const newChoiceId = action.itemId + getRandomQuestionId()
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
      }

      if (action.nextType !== QUESTION_TYPE_MAP.timer) {
        delete question.attributes.timer_details
      }

      if (action.nextType === QUESTION_TYPE_MAP.numericRatingScale) {
        delete question.attributes.other

        /*
         * this prevents the uncontolled input error that occurs when multipleField questions
         * don't have value (the value inside the button)
         */
        if (
          [
            QUESTION_TYPE_MAP.selectOne,
            QUESTION_TYPE_MAP.selectMultiple,
            QUESTION_TYPE_MAP.likert,
            QUESTION_TYPE_MAP.numericRatingScale,
          ].includes(action.prevType)
        ) {
          question.choices_order.forEach(choiceId => {
            question.choices[choiceId].value = ''
          })
        }

        while (question.choices_order.length < 2) {
          // when a question type is changed to numeric rating scale type, the question is given a minimum of two choices
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
        if (question.choices_order.length > 11) {
          question.choices_order.length = 11
        }
      } else {
        delete question.attributes[QUESTION_ATTRIBUTE_MAP.hasOptOut]
        delete question.attributes[QUESTION_ATTRIBUTE_MAP.optOut]
      }

      if (action.nextType === QUESTION_TYPE_MAP.multipleField) {
        question.choices = {} // deletes all previous choices, and creates a new choice with a blank label and default type of 'text'
        question.choices_order = []
        delete question.attributes.other
        while (question.choices_order.length < 1) {
          const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
          question.choices[newChoiceId] = { label: '', type: 'text' }
          question.choices_order.push(newChoiceId)
        }
        question.attributes.inputWidth = 'medium'
      } else {
        delete question.attributes.inputWidth
      }

      if (action.nextType === QUESTION_TYPE_MAP.vaScale || action.nextType === QUESTION_TYPE_MAP.vaScale2021) {
        question.scale_labels = {
          top: '',
          bottom: '',
        }
      }

      if (!QUESTION_TYPES_WITH_HINTS.includes(action.nextType)) {
        delete question.hint
      }

      if (!QUESTION_TYPES_WITH_CAREGIVER_HINTS.includes(action.nextType)) {
        delete question.caregiver_hint
      }

      if (action.nextType === QUESTION_TYPE_MAP.vasHorizontal) {
        question.scale_labels = {
          top_value: '100',
          top: '',
          mid_value: '50',
          mid: '',
          bottom_value: '0',
          bottom: '',
        }
        question.hint = ''
      }

      if (!SCALE_QUESTION_TYPES.includes(action.nextType)) {
        delete question.scale_labels
      }

      if (action.nextType === QUESTION_TYPE_MAP.audioRecording) {
        question.attributes.min = ''
        question.attributes.show_timer = true
        question.attributes.required = true
      } else {
        delete question.attributes.min
        delete question.attributes.max
        delete question.attributes.show_timer
      }

      if (action.nextType === QUESTION_TYPE_MAP.imageCapture) {
        question.choices = {}
        question.choices_order = []
      } else {
        if (action.prevType === QUESTION_TYPE_MAP.imageCapture && !!question.preview_images) {
          question.preview_images.forEach(imageId => {
            deleteMediaFromInstrumentJson({
              _instrument: newState,
              fileId: imageId,
              itemId: action.itemId,
            })
          })
        }
        delete question.preview_images
        delete question.previewImagesSrc
        delete question.camera_tip
      }

      if (!QUESTION_TYPES_WITH_CHOICES.includes(question.type)) {
        question.choices = {}
        question.choices_order = []
      }

      if (
        [QUESTION_TYPE_MAP.integer, QUESTION_TYPE_MAP.decimal].includes(action.prevType) &&
        ![QUESTION_TYPE_MAP.integer, QUESTION_TYPE_MAP.decimal].includes(action.nextType)
      ) {
        delete question.attributes.unit
      }

      delete question.input_validation

      /**
       * Removing media if the new item type does not suppport media
       *  */

      // Removing images
      if (
        QUESTION_TYPES_WITH_IMAGE.includes(action.prevType) &&
        action?.item?.image &&
        !QUESTION_TYPES_WITH_IMAGE.includes(action.nextType)
      ) {
        const fileName = newState.questions[action.itemId].image
        delete newState.questions[action.itemId].image
        delete newState.questions[action.itemId].imageSrc

        deleteMediaFromInstrumentJson({ _instrument: newState, fileId: fileName, itemId: action.itemId })
      }
      // Removing video or audio
      if (
        (QUESTION_TYPES_WITH_VIDEO.includes(action.prevType) &&
          action?.item?.resources?.video &&
          !QUESTION_TYPES_WITH_VIDEO.includes(action.nextType)) ||
        (QUESTION_TYPES_WITH_AUDIO.includes(action.prevType) &&
          action?.item?.resources?.audio &&
          !QUESTION_TYPES_WITH_AUDIO.includes(action.nextType))
      ) {
        const { audio } = action.item.resources
        const fileName = newState.questions[action.itemId].resources[audio ? 'audio' : 'video']

        deleteMediaFromInstrumentJson({
          _instrument: newState,
          fileId: fileName,
          itemId: action.itemId,
          isImage: false,
        })

        delete newState.questions[action.itemId].resources
        delete newState.questions[action.itemId].mediaSrc
      }
      // Removing images from choices
      if (!QUESTION_TYPES_WITH_CHOICE_IMAGES.includes(action.nextType) && action?.item?.choices_order) {
        action.item.choices_order.forEach(choiceId => {
          const fileName = action.item.choices[choiceId].image
          if (fileName) {
            deleteMediaFromInstrumentJson({ _instrument: newState, fileId: fileName, itemId: action.itemId })

            if (newState.questions[action.itemId].choices[choiceId]?.image) {
              delete newState.questions[action.itemId].choices[choiceId].image
              delete newState.questions[action.itemId].choices[choiceId].imageSrc
            }
          }
        })
      }

      // Remove 'type' key from non-multiple_field questions
      if (
        action.prevType === QUESTION_TYPE_MAP.multipleField &&
        QUESTION_TYPES_WITH_CHOICES.includes(action.nextType)
      ) {
        question.choices_order.forEach(choiceId => {
          delete question.choices[choiceId].type
        })
      }

      return newState
    }
    case TOGGLE_ITEM_ATTRIBUTE: {
      const newState = dupStateAndItem(state, action.itemId)
      const attribute = newState.questions[action.itemId].attributes[action.attribute]
      newState.questions[action.itemId].attributes[action.attribute] = !attribute
      return newState
    }
    case SET_ITEM_ATTRIBUTE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes[action.attribute] = action.value
      return newState
    }
    case UPDATE_UNIT_TYPE: {
      const newState = dupStateAndItem(state, action.itemId)
      if (!action.unit) {
        delete newState.questions[action.itemId].attributes.unit
      } else {
        newState.questions[action.itemId].attributes.unit = action.unit
      }
      return newState
    }
    case TOGGLE_REQUIRED_QUESTION: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.required = !newState.questions[action.itemId].attributes.required
      return newState
    }
    case TOGGLE_ITEM_HAS_SCORING: {
      const newState = dupStateAndItem(state, action.itemId)
      const nextHasScoringState = !newState.questions[action.itemId].attributes.hasScoring
      newState.questions[action.itemId].attributes.hasScoring = nextHasScoringState
      newState.questions[action.itemId].formula = 'MEAN'

      if (nextHasScoringState) {
        newState.questionsWithScoring = {
          ...newState.questionsWithScoring,
          [action.itemId]: action.domains,
        }
      } else {
        delete newState.questionsWithScoring[action.itemId]
      }
      return newState
    }
    case SET_ITEM_SCORES_EDITED: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.nonDefaultScoring = true
      return newState
    }
    case TOGGLE_INPUT_VALIDATION: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.hasCharLimit = !newState.questions[action.itemId].attributes
        .hasCharLimit
      return newState
    }
    case ADD_ITEM: {
      const newState = { ...state }
      if (action.index === undefined) {
        newState.order[0].push(action.newQId)
      } else {
        newState.order[0].splice(action.index + 1, 0, action.newQId)
      }
      newState.questions[action.newQId] = {
        type: action.itemType,
        label: '',
        choices: {},
        choices_order: [],
        attributes: { required: true, hasCharLimit: false },
        logic: {},
      }
      if (action.itemType === QUESTION_TYPE_MAP.selectOne) {
        const question = newState.questions[action.newQId]
        delete question.attributes.other
        question.choices[action.choiceOneId] = { label: '<p><br></p>' }
        question.choices_order.push(action.choiceOneId)
        question.choices[action.choiceTwoId] = { label: '<p><br></p>' }
        question.choices_order.push(action.choiceTwoId)
      }
      calcQuestionNumbers(newState.order, newState.questions)
      newState.activeItemId = action.newQId
      return newState
    }
    case TOGGLE_UNDO_ITEM: {
      const newState = { ...state }
      const question = newState.questions[action.itemId]
      if (action.undo) {
        question.undo = true
      } else {
        delete question.undo
      }
      return newState
    }
    case TOGGLE_WARNING_BOX_ON_ITEM: {
      const newState = { ...state }
      const question = newState.questions[action.itemId]
      if (action.isOn) {
        question.showWarning = true
      } else {
        delete question.showWarning
      }
      return newState
    }
    case TURN_OFF_MODAL_ON_ITEM: {
      const newState = { ...state }
      const question = newState.questions[action.itemId]
      question.modalOff = action.off
      return newState
    }
    case DELETE_ITEM: {
      const newState = { ...state }
      const { questionsWithScoring } = newState
      const question = newState.questions[action.itemId]
      const { domains } = question
      delete newState.questionsWithScoring[action.itemId]
      if (domains?.length) {
        domains.forEach(domainId => {
          const domainIsNotUsedByInstrument = !getDomainIsUtilized({ questionsWithScoring, domainId })
          if (domainIsNotUsedByInstrument) delete newState.domains[domainId]
        })
        const { domains: surveyDomains } = newState
        // If there are no domains, delete all the scores
        if (Object.keys(surveyDomains).length === 0) newState.scores = []
      }

      // Delete question's media data from survey if not being used in another question
      const { image, resources, preview_images } = question
      if (image) {
        deleteMediaFromInstrumentJson({
          _instrument: newState,
          fileId: image,
          itemId: action.itemId,
        })
      } else if (resources) {
        const { audio } = resources
        const fileName = newState.questions[action.itemId].resources[audio ? 'audio' : 'video']
        deleteMediaFromInstrumentJson({
          _instrument: newState,
          fileId: fileName,
          itemId: action.itemId,
          isImage: false,
        })
      }
      if (preview_images) {
        preview_images.forEach(imageId => {
          deleteMediaFromInstrumentJson({
            _instrument: newState,
            fileId: imageId,
            itemId: action.itemId,
          })
        })
      }
      if (QUESTION_TYPES_WITH_CHOICE_IMAGES.includes(question.type)) {
        question.choices_order.forEach(choiceId => {
          const choice = question.choices[choiceId]
          const fileName = choice?.image
          if (fileName) {
            deleteMediaFromInstrumentJson({ _instrument: newState, fileId: fileName, itemId: action.itemId })
          }
        })
      }
      delete newState.questions[action.itemId]
      newState.order[0].splice(newState.order[0].indexOf(action.itemId), 1)
      calcQuestionNumbers(newState.order, newState.questions)
      if (action.itemId === newState.activeItemId) {
        newState.activeItemId = null
      }
      return newState
    }
    case ADD_ITEM_IMAGE: {
      const newState = { ...state }
      if (!action.imageId) return newState
      const { resource_size_list } = newState
      newState.image_list = newState.image_list ? addUniqToArr(newState.image_list, action.imageId) : [action.imageId]
      newState.questions[action.itemId].image = action.imageId
      newState.questions[action.itemId].imageSrc = action.imageSrc
      const fileResourceObject = {
        file_name: action.imageId,
        size: action.size,
        type: MEDIA_TYPE_MAP.image,
      }
      if (resource_size_list) {
        resource_size_list.push(fileResourceObject)
      } else newState.resource_size_list = [fileResourceObject]

      addMediaToFileMap({ _instrument: newState, fileId: action.imageId, itemId: action.itemId })
      return newState
    }
    case DELETE_ITEM_IMAGE: {
      const newState = { ...state }
      delete newState.questions[action.itemId].image
      delete newState.questions[action.itemId].imageSrc

      deleteMediaFromInstrumentJson({
        _instrument: newState,
        fileId: action.imageId,
        itemId: action.itemId,
      })

      return newState
    }
    case ADD_PREVIEW_IMAGE: {
      const newState = { ...state }
      if (!action.imageId) return newState
      const { resource_size_list } = newState
      const question = newState.questions[action.itemId]
      newState.image_list = newState.image_list ? addUniqToArr(newState.image_list, action.imageId) : [action.imageId]
      if (!question.preview_images) question.preview_images = []
      if (!question.previewImagesSrc) question.previewImagesSrc = {}
      question.preview_images[action.imageOrder] = action.imageId
      question.previewImagesSrc[action.imageId] = action.imageSrc

      const fileResourceObject = {
        file_name: action.imageId,
        size: action.size,
        type: MEDIA_TYPE_MAP.image,
      }
      if (resource_size_list) {
        resource_size_list.push(fileResourceObject)
      } else newState.resource_size_list = [fileResourceObject]

      addMediaToFileMap({ _instrument: newState, fileId: action.imageId, itemId: action.itemId })
      return newState
    }
    case DELETE_PREVIEW_IMAGE: {
      const newState = { ...state }
      const question = newState.questions[action.itemId]
      question.preview_images[action.imageOrder] = null
      delete question.previewImagesSrc[action.imageId]
      if (question.preview_images.length === 0 || Object.keys(question.previewImagesSrc).length === 0) {
        delete question.preview_images
        delete question.previewImagesSrc
      }
      deleteMediaFromInstrumentJson({
        _instrument: newState,
        fileId: action.imageId,
        itemId: action.itemId,
      })

      return newState
    }
    case ADD_ITEM_MEDIA: {
      const newState = { ...state }
      const { resource_size_list } = newState
      newState.resource_list = newState.resource_list
        ? addUniqToArr(newState.resource_list, action.fileId)
        : [action.fileId]
      if (action.isVideo) {
        newState.questions[action.itemId].resources = { video: action.fileId }
      } else {
        newState.questions[action.itemId].resources = { audio: action.fileId }
      }
      newState.questions[action.itemId].mediaSrc = action.fileSrc
      const fileResourceObject = {
        file_name: action.fileId,
        size: action.size,
        type: action.isVideo ? MEDIA_TYPE_MAP.video : MEDIA_TYPE_MAP.audio,
      }
      if (resource_size_list) resource_size_list.push(fileResourceObject)
      else newState.resource_size_list = [fileResourceObject]
      addMediaToFileMap({ _instrument: newState, fileId: action.fileId, itemId: action.itemId })
      return newState
    }
    case DELETE_ITEM_MEDIA: {
      const newState = { ...state }
      const { fileId, itemId } = action
      delete newState.questions[action.itemId].resources
      delete newState.questions[action.itemId].mediaSrc
      deleteMediaFromInstrumentJson({ _instrument: newState, fileId, itemId, isImage: false })
      return newState
    }
    case MOVE_ITEM: {
      const newState = { ...state }
      newState.order = newState.order.slice()
      newState.questions = { ...newState.questions }
      newState.order[0].splice(action.endIdx, 0, newState.order[0].splice(action.startIdx, 1)[0])
      calcQuestionNumbers(newState.order, newState.questions)
      return newState
    }
    case DUPLICATE_ITEM: {
      const newState = { ...state }
      newState.order = newState.order.slice()
      newState.questions = { ...newState.questions }
      newState.questions[action.newQId] = JSON.parse(JSON.stringify(newState.questions[action.itemId]))
      const newIndex = newState.order[0].indexOf(action.itemId) + 1
      newState.order[0].splice(newIndex, 0, action.newQId)
      calcQuestionNumbers(newState.order, newState.questions)
      const question = newState.questions[action.itemId]
      const { hasScoring } = question.attributes
      if (hasScoring) newState.questionsWithScoring[action.newQId] = question.domains
      if (action.hasChoices) {
        const duplicateQuestion = newState.questions[action.newQId]
        const newChoicesOrder = action.choicesOrder.map(choiceId => action.newChoicesIdMap[choiceId])
        duplicateQuestion.choices_order = newChoicesOrder
        action.choicesOrder.forEach(choiceId => {
          const newChoiceId = action.newChoicesIdMap[choiceId]
          duplicateQuestion.choices[newChoiceId] = JSON.parse(JSON.stringify(duplicateQuestion.choices[choiceId]))
          delete duplicateQuestion.choices[choiceId]
        })
      }
      /**
       * If the duplicated question has any media, we need to add the new question ID of
       * the duplicated question item to the file_map
       */
      const { type } = question
      if (QUESTION_TYPES_WITH_IMAGE.includes(type)) {
        if (newState.file_map) {
          const { image, preview_images } = question
          if (image && newState.file_map[image]) {
            addMediaToFileMap({ _instrument: newState, fileId: image, itemId: action.newQId })
          }
          if (QUESTION_TYPE_MAP.imageCapture === type && preview_images) {
            preview_images.forEach(imageId => {
              if (image !== null && newState.file_map[imageId]) {
                addMediaToFileMap({ _instrument: newState, fileId: imageId, itemId: action.newQId })
              }
            })
          }
          if (QUESTION_TYPES_WITH_CHOICE_IMAGES.includes(newState.questions[action.itemId].type)) {
            newState.questions[action.itemId].choices_order.forEach(choiceId => {
              const { image: choiceImage } = newState.questions[action.itemId].choices[choiceId]
              if (choiceImage && newState.file_map[choiceImage]) {
                addMediaToFileMap({ _instrument: newState, fileId: choiceImage, itemId: action.newQId })
              }
            })
          }
        }
      }
      if (QUESTION_TYPES_WITH_AUDIO.includes(type) || QUESTION_TYPES_WITH_VIDEO.includes(type)) {
        const { resources } = question
        if (resources) {
          const { audio, video } = resources
          addMediaToFileMap({ _instrument: newState, fileId: audio || video, itemId: action.newQId })
        }
      }
      return newState
    }
    case ADD_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.newChoiceId] = { label: '' }
      if (action.itemType === QUESTION_TYPE_MAP.longListSelectOne) {
        newState.questions[action.itemId].choices_text += '\n' //add a blank space to the text
      }
      if (action.itemType === QUESTION_TYPE_MAP.numericRatingScale) {
        newState.questions[action.itemId].choices[action.newChoiceId].value = ''
      }
      if (action.itemType === QUESTION_TYPE_MAP.multipleField) {
        newState.questions[action.itemId].choices[action.newChoiceId].type = 'text'
      }
      if (action.insertIdx) {
        newState.questions[action.itemId].choices_order.splice(action.insertIdx, 0, action.newChoiceId)
      } else {
        newState.questions[action.itemId].choices_order.push(action.newChoiceId)
      }
      return newState
    }
    case ADD_CHOICE_ON_PASTE: {
      if (action.pasteText === '') return state
      const newState = dupStateAndItem(state, action.itemId)
      action.pasteText.split('\n').forEach(line => {
        const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
        newState.questions[action.itemId].choices[newChoiceId] = { label: line }
        newState.questions[action.itemId].choices_order.push(newChoiceId)
      })
      return newState
    }
    case ADD_OTHER_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.other = 'Other'
      return newState
    }
    case DELETE_OTHER_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].attributes.other
      return newState
    }
    case UPDATE_OTHER_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.other = action.otherValue
      return newState
    }
    case DELETE_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      const question = newState.questions[action.itemId]
      delete question.choices[action.choiceId]
      question.choices_order.splice(question.choices_order.indexOf(action.choiceId), 1)
      if (question.type === QUESTION_TYPE_MAP.longListSelectOne) {
        const textArr = question.choices_text.split('\n')
        textArr.pop() // remove the last choice text
        question.choices_text = textArr.join('\n')
      }
      return newState
    }
    case UPDATE_CHOICE_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId].label = action.newLabel
      return newState
    }
    case UPDATE_CHOICE_RICH_LABEL: {
      const newState = dupStateAndItemAndChoice(state, action.itemId, action.choiceId)
      newState.questions[action.itemId].choices[action.choiceId].label = stateToHTML(
        action.newEditorState.getCurrentContent(),
      )
      return newState
    }
    case UPDATE_CHOICE_VALUE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId].value = action.newValue
      return newState
    }
    case UPDATE_CHOICE_SCORE_VALUE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId].score_value = action.newValue
      return newState
    }
    case UPDATE_CHOICE_KEY: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId][action.key] = action.newValue
      if (action.key === 'type' && action.newValue === 'text') {
        // deletes a choice's min key if, the type is changed to text, this is important
        // for the text's max value because the input is made so that the max value cannot
        // be less than the min value
        delete newState.questions[action.itemId].choices[action.choiceId].min
      }
      return newState
    }
    case ADD_CHOICE_LABEL_IMAGE: {
      const newState = dupStateAndItem(state, action.itemId)
      const { image_list, resource_size_list } = newState
      if (!action.imageId) return newState
      newState.image_list = image_list ? addUniqToArr(newState.image_list, action.imageId) : [action.imageId]
      newState.questions[action.itemId].choices[action.choiceId].image = action.imageId
      newState.questions[action.itemId].choices[action.choiceId].imageSrc = action.imageSrc
      const fileResourceObject = {
        file_name: action.imageId,
        size: action.size,
        type: MEDIA_TYPE_MAP.image,
      }
      if (resource_size_list) resource_size_list.push(fileResourceObject)
      else {
        newState.resource_size_list = [fileResourceObject]
      }
      addMediaToFileMap({ _instrument: newState, fileId: action.imageId, itemId: action.itemId })

      return newState
    }
    case DELETE_CHOICE_LABEL_IMAGE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].choices[action.choiceId].image
      delete newState.questions[action.itemId].choices[action.choiceId].imageSrc

      deleteMediaFromInstrumentJson({
        _instrument: newState,
        fileId: action.imageId,
        itemId: action.itemId,
      })
      return newState
    }
    case MOVE_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices_order.splice(
        action.endIdx,
        0,
        newState.questions[action.itemId].choices_order.splice(action.startIdx, 1)[0],
      )
      return newState
    }
    case UPDATE_LOGIC_CONDITION: {
      const oldCondition = { ...getCondition(state.questions[action.itemId].logic.show_if.terms, action.conditionPath) }
      const newState = dupStateAndItem(state, action.itemId)
      const newCondition = Object.assign(
        getCondition(newState.questions[action.itemId].logic.show_if.terms, action.conditionPath),
        { [action.field]: action.value },
      )
      updateSubsequentQuestionLogic(state.questions, action, oldCondition, newCondition)
      if (action.field === 'question_id') {
        newCondition.question_type = newState.questions[action.value].type
        newCondition.comparator = ''
        newCondition.value = ''
      } else if (action.field === 'comparator' && action.value.includes('answered')) {
        newCondition.value = ''
      }
      setCondition(newState.questions[action.itemId].logic.show_if.terms, action.conditionPath, newCondition)
      return newState
    }
    case ADD_LOGIC_CONDITION: {
      const newState = dupStateAndItem(state, action.itemId)
      const blankCondition = {
        question_type: '',
        question_id: '',
        value: '',
        comparator: '',
      }
      if (action.groupPath) {
        addCondition(newState.questions[action.itemId].logic.show_if.terms, action.groupPath)
        return newState
      }
      if (newState.questions[action.itemId].logic.show_if) {
        newState.questions[action.itemId].logic.show_if.terms.push(blankCondition)
      } else {
        newState.questions[action.itemId].logic.show_if = {
          comparator: 'boolean_combine',
          operator: 'and',
          terms: [blankCondition],
        }
      }
      return newState
    }
    case ADD_LOGIC_GROUP: {
      const newState = dupStateAndItem(state, action.itemId)
      const blankGroup = {
        comparator: 'boolean_combine',
        operator: 'and',
        terms: [
          {
            question_type: '',
            question_id: '',
            value: '',
            comparator: '',
          },
        ],
      }
      if (newState.questions[action.itemId].logic.show_if.terms[0].comparator !== 'boolean_combine') {
        newState.questions[action.itemId].logic.show_if.terms = [
          {
            comparator: 'boolean_combine',
            operator: 'and',
            terms: newState.questions[action.itemId].logic.show_if.terms,
          },
          blankGroup,
        ]
      } else {
        newState.questions[action.itemId].logic.show_if.terms.push(blankGroup)
      }
      return newState
    }
    case DELETE_LOGIC_CONDITION: {
      const nextState = dupStateAndItem(state, action.itemId)
      deleteSubsequentLogic(nextState.questions, action.itemId, action.conditionPath)
      deleteCondition(nextState.questions[action.itemId].logic.show_if, action.conditionPath)
      removeLoneGroup(nextState.questions[action.itemId].logic)
      return nextState
    }
    case DELETE_ALL_LOGIC: {
      const newState = dupStateAndItem(state, action.itemId)
      deleteAllSubsequentLogic(newState.questions, action.itemId, newState.questions[action.itemId].logic.show_if.terms)
      delete newState.questions[action.itemId].logic.show_if
      return newState
    }
    case CHANGE_LOGIC_OPERATOR: {
      const newState = dupStateAndItem(state, action.itemId)
      setOperator(newState.questions[action.itemId].logic.show_if, action.groupPath, action.value)
      return newState
    }
    case DELETE_INVALID_LOGIC: {
      const nextState = { ...state }
      for (const itemId in action.invalidConditions) {
        nextState.questions[itemId] = { ...nextState.questions[itemId] }
        action.invalidConditions[itemId].sort((a, b) => {
          return b[0] - a[0] || b[1] - a[1]
        })
        action.invalidConditions[itemId].forEach(path => {
          deleteSubsequentLogic(nextState.questions, itemId, path)
          deleteCondition(nextState.questions[itemId].logic.show_if, path)
        })
        removeLoneGroup(nextState.questions[itemId].logic)
      }
      return nextState
    }
    case RANDOMIZE_QUESTIONS: {
      const newState = { ...state }
      newState.order[0] = getRandomizedArray(newState.order[0])
      Object.keys(newState.questions).forEach(qId => {
        newState.questions[qId].logic = {}
      })
      calcQuestionNumbers(newState.order, newState.questions)
      return newState
    }
    case ADD_NEW_SURVEY_DOMAIN: {
      const nextState = { ...state }
      const { domains } = nextState
      const { newDomainId } = action

      if (!domains) nextState.domains = {}
      nextState.domains[newDomainId] = {
        label: action.label,
        formula: 'SUM',
      }

      const question = nextState.questions[action.itemId]
      const { domains: questionDomains } = question

      if (questionDomains) {
        question.domains.push(newDomainId)
      } else {
        question.domains = [newDomainId]
      }

      nextState.questionsWithScoring[action.itemId] = question.domains
      return nextState
    }
    case TOGGLE_ITEM_DOMAIN: {
      const nextState = { ...state }
      const { questionsWithScoring } = nextState
      const question = nextState.questions[action.itemId]
      const { domains = [] } = question
      let newDomains = []

      if (domains.includes(action.domainId)) {
        newDomains = domains.filter(_domainId => _domainId !== action.domainId)
      } else {
        newDomains = [...domains, action.domainId]
      }
      question.domains = newDomains
      nextState.questionsWithScoring[action.itemId] = newDomains // update questionsWithScoring with new domain list

      /**
       * If the admin toggles off a domain from a question, we do a check to see if the domain
       * is assigned to any other question. If it is not, we delete the domain.
       */
      const domainIsNotUsedByInstrument = !getDomainIsUtilized({ questionsWithScoring, domainId: action.domainId })
      if (domainIsNotUsedByInstrument) {
        delete nextState.domains[action.domainId]
      }
      const { domains: surveyDomains } = nextState
      // If there are no domains, delete all the scores
      if (Object.keys(surveyDomains).length === 0) nextState.scores = []
      return nextState
    }
    case UPDATE_ITEM_FORMULA: {
      const nextState = { ...state }
      nextState.questions[action.itemId].formula = action.formula
      return nextState
    }
    case UPDATE_SURVEY_DOMAIN_FORMULA: {
      const nextState = { ...state }
      nextState.domains[action.domainId] = {
        ...nextState.domains[action.domainId],
        formula: action.formula,
      }
      return nextState
    }
    case UPDATE_SURVEY_SCORE: {
      const nextState = { ...state }
      const { key, scoreId, value } = action
      const scoreIdx = state.scores.findIndex(score => score.id === scoreId)
      nextState.scores[scoreIdx] = {
        ...nextState.scores[scoreIdx],
        [key]: value,
      }
      return nextState
    }
    case ADD_SURVEY_SCORE: {
      const nextState = { ...state }
      if (!nextState.scores) {
        nextState.scores = []
      }
      nextState.scores = [...nextState.scores, generateNewScoreObject(action.scoreId)]
      return nextState
    }
    case DELETE_SURVEY_SCORE: {
      const nextState = { ...state }
      nextState.scores = nextState.scores.filter(score => score.id !== action.scoreId)
      return nextState
    }
    default: {
      return state
    }
  }
}

export function surveyErrors(state = { questions: {} }, action) {
  let newState
  switch (action.type) {
    case INITIALIZE_EDIT:
    case INITIALIZE_BLANK_SURVEY:
    case CLEAR_SURVEY_ERRORS:
      return { questions: {} }
    case ADD_SURVEY_ERRORS:
      return action.errors
    case CLEAR_SURVEY_ERROR:
      newState = { ...state }
      delete newState[action.key]
      return newState
    case CLEAR_QUESTION_ERROR:
    case CHANGE_ITEM_TYPE:
      newState = { ...state }
      newState.questions = { ...newState.questions }
      delete newState.questions[action.itemId]
      return newState
    default:
      return state
  }
}

export function editorStates(state = {}, action) {
  switch (action.type) {
    case INITIALIZE_EDIT: {
      return action.editorStates
    }
    case ADD_ITEM: {
      const newState = { ...state }
      newState[action.newQId] = EditorState.createEmpty()
      newState[action.choiceOneId] = EditorState.createEmpty()
      newState[action.choiceTwoId] = EditorState.createEmpty()
      return newState
    }
    case ADD_CHOICE: {
      const newState = { ...state }
      newState[action.newChoiceId] = EditorState.createEmpty()
      return newState
    }
    case DELETE_CHOICE: {
      const newState = { ...state }
      delete newState[action.choiceId]
      return newState
    }
    case DUPLICATE_ITEM: {
      const newState = { ...state }
      newState[action.newQId] = EditorState.createWithContent(action.editorState.getCurrentContent())
      if (action.isSelectType) {
        action.choicesOrder.forEach(choiceId => {
          const newChoiceId = action.newChoicesIdMap[choiceId]
          newState[newChoiceId] = EditorState.createWithContent(
            action.choicesEditorStates[choiceId].getCurrentContent(),
          )
        })
      }
      return newState
    }
    case UPDATE_ITEM_LABEL: {
      const newState = { ...state }
      newState[action.itemId] = action.newEditorState
      return newState
    }
    case UPDATE_CHOICE_LABEL: {
      const newState = { ...state }
      newState[action.choiceId] = EditorState.createWithContent(ContentState.createFromText(action.newLabel))
      return newState
    }
    case UPDATE_CHOICE_RICH_LABEL: {
      const newState = { ...state }
      newState[action.choiceId] = action.newEditorState
      return newState
    }
    case CHANGE_ITEM_TYPE: {
      const newState = { ...state }
      if (
        NUMERIC_CHOICE_QUESTION_TYPES.includes(action.prevType) ||
        action.prevType === QUESTION_TYPE_MAP.longListSelectOne ||
        action.prevType === QUESTION_TYPE_MAP.multipleField
      ) {
        if (SELECT_QUESTION_TYPES.includes(action.nextType)) {
          action.item.choices_order.forEach(choiceId => {
            const choice = action.item.choices[choiceId]
            if (!newState[choiceId]) {
              newState[choiceId] = EditorState.createWithContent(ContentState.createFromText(choice.label))
            }
          })
        }
      } else {
        const { choiceIdOne, choiceIdTwo, choiceIdThree } = action.newChoices
        if (SELECT_QUESTION_TYPES.includes(action.nextType)) {
          newState[choiceIdOne] = EditorState.createEmpty()
          newState[choiceIdTwo] = EditorState.createEmpty()
          newState[choiceIdThree] = EditorState.createEmpty()
        }
      }
      return newState
    }
    default: {
      return state
    }
  }
}

function originalSurvey(state = {}, action) {
  if (action.type === INITIALIZE_EDIT || action.type === UPDATE_ORIGINAL_SURVEY) {
    return JSON.parse(JSON.stringify(action.json))
  }
  return state
}

function wordCount(state = 0, action) {
  switch (action.type) {
    case INITIALIZE_BLANK_SURVEY: {
      return 0
    }
    case INITIALIZE_EDIT: {
      return countWordsInSurvey(action.json)
    }
    case UPDATE_ITEM_LABEL: {
      return (
        state +
        (countWords(action.newEditorState.getCurrentContent().getPlainText()) -
          countWords(action.oldEditorState.getCurrentContent().getPlainText()))
      )
    }
    default: {
      return state
    }
  }
}

function surveysScore(state = {}, action) {
  switch (action.type) {
    case SET_SURVEYS_SCORE: {
      return {
        ...state,
        [action.instrumentId]: action.scores,
      }
    }
    default: {
      return state
    }
  }
}

const domainCachereducer = (state, action) => {
  state.domains = state.domains || {}
  if (state.domains[action.domainId] && action.type === TOGGLE_ITEM_DOMAIN) {
    const formulas = state.scores.map(s => s.tagFormula)
    state.deletedDomains = { ...state.deletedDomains, [action.domainId]: state.domains[action.domainId] }

    state.deletedDomains = Object.keys(state.deletedDomains)
      .filter(tagId => formulas.map(f => f.includes(tagId)).some(b => b))
      .reduce((acc, k) => ({ ...acc, [k]: state.deletedDomains[k] }), {})
  }
  return state
}

export default combineReducers({
  survey(state = generateBlankSurvey(), action) {
    return survey(domainCachereducer(state, action), action)
  },
  editorStates,
  surveyErrors,
  wordCount,
  originalSurvey,
  article,
  surveysScore,
})

export const surveyActions = {
  addItem,
  addItemImage,
  addPreviewImage,
  addItemMedia,
  clearQuestionError,
  closeModal: modalActions.closeModal,
  deleteChoice: checkLogicAndDeleteChoice,
  deleteItem: checkLogicAndDeleteItem,
  deleteItemImage,
  deleteItemMedia,
  deletePreviewImage,
  downloadChoicesCSV,
  uploadChoicesCSV,
  fetchSurvey,
  initializeArticleEdit,
  initializeBlankArticle,
  initializeBlankSurvey,
  moveItem: checkLogicAndMoveItem,
  openModal: modalActions.openModal,
  randomizeQuestions,
  toggleEdit,
  updateArticleTitle,
  updateArticleURL,
  updateTitle,
}

export const itemActions = {
  updateItemLabel,
  duplicateItem,
  addItem,
  addChoiceOnPaste,
  updateItem,
  updateLongListItem: checkLogicAndUpdateLongList,
  deleteLongListItem,
  updateLongListItemByCSV,
  toggleUndoItem,
  toggleWarningBoxOnItem,
}

export const itemEditorActions = {
  addChoice,
  addOtherChoice,
  changeItemType: checkLogicAndChangeItemType,
  createNewDomainAndAddToQuestion,
  deleteChoice: checkLogicAndDeleteChoice,
  moveItem: checkLogicAndMoveItem,
  toggleItemAttribute,
  setItemAttribute,
  toggleInputValidation,
  toggleQuestionDomain,
  toggleQuestionScoring,
  toggleRequiredQuestion: checkLogicAndToggleRequired,
  updateItem,
  updateItemFormula,
  updateUnitType,
}

export const selectViewActions = {
  addChoice,
  addChoiceLabelImage,
  deleteChoice: checkLogicAndDeleteChoice,
  deleteChoiceLabelImage,
  deleteOtherChoice,
  moveChoice,
  setQuestionScoresEdited,
  updateChoiceKey,
  updateChoiceLabel,
  updateChoiceRichLabel,
  updateChoiceScoreValue,
  updateChoiceValue,
  updateOtherChoice,
}

export const logicActions = {
  updateLogicCondition,
  addLogicCondition,
  deleteLogicCondition,
  deleteAllLogic,
  changeLogicOperator,
  addLogicGroup,
}

export const mediaActions = {
  uploadImage,
  uploadMedia,
}

export const scoringActions = {
  updateSurveyDomainFormula,
  updateSurveyScore,
  addSurveyScore,
  deleteSurveyScore,
}
