import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import DataInboxPageRoute from './routes/DataInboxPage'

export default store => {
  return {
    path: 'data-inbox',
    exact: true,
    getIndexRoute(location, cb) {
      const { study } = store.getState()
      setPageTitle(`Data Inbox | Study ${study.currentStudy.id} | ${study.currentStudy.platform_name}`)
      store.dispatch(setSubroute('dataInbox'))
      cb(null, DataInboxPageRoute(store))
    },
  }
}
