import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'components/UIElements'
import VisitTimeline from './VisitTimeline'
import AdditionalInfo from './AdditionalInfo'
import { VisitStatusCell } from './VirtualVisitDataCells'

const VisitorCell = ({
  additionalInfo,
  handleResize,
  idx,
  isExpanded,
  isRecorded,
  isPtp = false,
  status,
  summaryWrapperstyle,
  value,
  visitId,
  visitsInfo,
  visitsLoading,
}) => {
  const visitInfo = visitsInfo[visitId]
  const { downloadLinks } = visitInfo || {}
  const cellRef = useRef(null)
  const wrapperRef = useRef(null)
  const [infoDataStyle, setInfoDataStyle] = useState({})
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const visitLoading = visitsLoading && visitsLoading[visitId]
  useEffect(() => {
    const _wrapperHeight = wrapperRef.current?.clientHeight
    setWrapperHeight(_wrapperHeight)
  })

  const setStyle = () => {
    if (isPtp) {
      if (isExpanded) {
        // need to add 3 px to include borders
        setInfoDataStyle({ height: `${wrapperHeight + 3}px` })
      }
    }
  }

  // Sets information data cell height on wrapper height change
  useEffect(() => {
    setStyle()
  }, [wrapperHeight, visitLoading, isExpanded])

  // Sets information data cell height on window resize
  useEffect(() => {
    window.addEventListener('resize', setStyle)
    return () => window.removeEventListener('resize', setStyle)
  })

  return (
    <td
      className={`information-data${isPtp ? ' ptp-data' : ''}`}
      key={`col_${idx}`}
      style={infoDataStyle}
      ref={cellRef}>
      {isPtp && (
        <div className='summary-wrapper' style={summaryWrapperstyle} ref={wrapperRef}>
          <div className={`summary ${status}`}>
            <div className='flexed start-justified summary-header'>
              <p className='label-small'>Virtual Visit Summary</p>
              <VisitStatusCell value={status} isDiv />
              {isRecorded && !!downloadLinks?.length && (
                <a href={downloadLinks[0]} className='flexed center-aligned' onClick={evt => evt.stopPropagation()}>
                  <i className='fas fa-download' />
                  <span>Download recorded visit</span>
                </a>
              )}
            </div>
            {visitLoading ? (
              <Loader inContainer className='summary loader' size={40} />
            ) : (
              <>
                {visitsInfo ? (
                  <div className='visit-info flexed flexed start-aligned'>
                    <VisitTimeline {...visitInfo} status={status} />
                    <AdditionalInfo
                      {...visitInfo}
                      visitId={visitId}
                      readjustHeight={setStyle}
                      handleResize={handleResize}
                    />
                  </div>
                ) : (
                  <div>No visit info</div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className='additional-info'>
        {isPtp && isRecorded ? (
          <div className='ptp-name-row visible flexed start-justified'>
            <div className='recording-icon visible'>
              <div className='center visible' />
            </div>
            <strong>{value}</strong>
          </div>
        ) : (
          <strong>{value}</strong>
        )}
        {additionalInfo.map((info, idx) => {
          return <p key={`${isPtp ? 'ptp' : 'clin'}-add-info-${value}-${idx}`}>{info}</p>
        })}
      </div>
    </td>
  )
}
VisitorCell.propTypes = {
  additionalInfo: PropTypes.arrayOf(PropTypes.string),
  downloadLinks: PropTypes.arrayOf(PropTypes.string),
  handleResize: PropTypes.func,
  isExpanded: PropTypes.bool,
  idx: PropTypes.number,
  isPtp: PropTypes.bool,
  isRecorded: PropTypes.bool,
  status: PropTypes.string,
  summaryWrapperstyle: PropTypes.objectOf(PropTypes.string),
  value: PropTypes.string,
  visitId: PropTypes.string,
  visitsInfo: PropTypes.objectOf(PropTypes.object),
  visitsLoading: PropTypes.objectOf(PropTypes.bool),
}

export default VisitorCell
