import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Input, Tags, Textarea } from 'components/UIElements'
import { formatMixedTextStringToFormula, formatDomainsToTagsList } from '../utils/domainUtils'
import { replaceTargetsWithVal } from '../utils/scoring'

const colorTagRed = k => {
  const tags = document.querySelectorAll(`#${k} > div:nth-child(2)`) || {}
  tags.forEach(el => {
    el.className = 'redTag'
  })
}

const colorMissingTagsRed = (deletedDomains, formula, domains) => () => {
  const formulaWithDeletedTags = replaceTargetsWithVal(formula, Object.keys(domains))
  return Object.keys(deletedDomains || {})
    .filter(domainId => formulaWithDeletedTags.includes(domainId))
    .forEach(colorTagRed)
}

const Examples = () => {
  return (
    <div className='examples'>
      <p>
        Example 1:
        <span className='domain-title'> Domain 1 </span>/ (3 +<span className='domain-title'> Domain 2 </span>)
      </p>
      <p>
        Example 2:
        <span className='domain-title'> Domain 2 </span>
      </p>
    </div>
  )
}

const FormulaErrors = ({ errors }) => {
  const { emptyFormulaErr, incompleteFormulaErr, invalidCharFormulaErr } = errors
  return (
    <div className='formula-errors'>
      {emptyFormulaErr && <p>{emptyFormulaErr}</p>}
      {invalidCharFormulaErr && <p>{invalidCharFormulaErr}</p>}
      {incompleteFormulaErr && <p>{incompleteFormulaErr}</p>}
    </div>
  )
}

const SingleScore = ({ idx, domains, deleteScore, errors = {}, score, showDelete, updateScore, deletedDomains }) => {
  const [domainsList, setDomainsList] = useState([
    ...formatDomainsToTagsList(domains),
    ...formatDomainsToTagsList(deletedDomains, true),
  ])

  const { id, name, description, tagFormula, formula } = score
  const { emptyFormulaErr, emptyScoreNameErr, incompleteFormulaErr, invalidCharFormulaErr } = errors
  const hasFormulaError = !!(emptyFormulaErr || incompleteFormulaErr || invalidCharFormulaErr)

  const updateFormula = updatedFormula => {
    const formattedString = formatMixedTextStringToFormula(updatedFormula)
    updateScore(id, 'formula', formattedString)
    updateScore(id, 'tagFormula', updatedFormula) // We will save the tag Formula for use in the Tags component
  }

  useEffect(colorMissingTagsRed(deletedDomains, formula, domains))

  useEffect(() => {
    const _domainsList = hasFormulaError
      ? [...formatDomainsToTagsList(domains), ...formatDomainsToTagsList(deletedDomains, true)]
      : formatDomainsToTagsList(domains)
    setDomainsList(_domainsList)
  }, [hasFormulaError])

  return (
    <>
      <span className='label-small'>Score name</span>
      <div className='flexed'>
        <Input
          errorText={emptyScoreNameErr}
          hasError={emptyScoreNameErr && !name}
          onChange={val => updateScore(id, 'name', val)}
          placeholder='Score name (Required)'
          value={name}
        />
        {showDelete && <Button link icon='far fa-trash-alt' onClick={() => deleteScore(id)} />}
      </div>
      <span className='label-small'>Score Description</span>
      <Textarea
        onChange={val => updateScore(id, 'description', val)}
        placeholder='Description of the score'
        value={description}
      />
      <span className='label-small'>Formula</span>
      <p>
        Use any combination of Domains and operations (<strong>+ , - , * , / , ^ , ( , ) </strong>).{' '}
      </p>
      <p>
        To use a domain, first enter the <strong>#</strong> symbol
      </p>
      <div className={`flexed formula-container${hasFormulaError ? ' has-error' : ''}`}>
        <span className='with-min-width'>Score =</span>
        <Tags
          hasError={hasFormulaError}
          list={domainsList}
          onChange={updateFormula}
          placeholder='(Required)'
          value={tagFormula}
        />
      </div>
      {hasFormulaError && <FormulaErrors errors={errors} />}
      {idx === 0 && <Examples />}
      <Divider color='#dededf' />
    </>
  )
}

SingleScore.propTypes = {
  deleteScore: PropTypes.func,
  updateScore: PropTypes.func,
  domains: PropTypes.objectOf(PropTypes.object),
  errors: PropTypes.objectOf(PropTypes.string),
  idx: PropTypes.number,
  score: PropTypes.objectOf(PropTypes.string),
  showDelete: PropTypes.bool,
  deletedDomains: PropTypes.objectOf(PropTypes.object),
}

FormulaErrors.propTypes = {
  errors: PropTypes.objectOf(PropTypes.string),
}

export default SingleScore
