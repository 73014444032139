import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Divider } from 'components/UIElements'
import { QUESTION_TYPE_MAP, QUESTION_TYPES_WITH_CHOICES, CONSENT_QUESTION_TYPES_WITHOUT_LOGIC } from 'utils/constants'
import { scrollTo } from 'utils/misc'
import QuestionPanel from '../../../../../../Instruments/routes/Instrument/routes/EditSurvey/components/QuestionPanel'
import ValidationPanel from '../../../../../../Instruments/routes/Instrument/routes/EditSurvey/components/ValidationPanel'
import LogicPanelContainer from '../containers/LogicPanelContainer'
import SignatureCameraTip from './ConsentSignature/ConsentSignatureCameraTip'

import { editorStates, itemEditorActions } from '../modules/NewConsent'

class ItemEditor extends React.Component {
  componentDidUpdate(prevProps) {
    const { activeItemId, consent } = this.props
    const isFirstPromptItem = consent.order[0][0] === activeItemId

    if (activeItemId !== prevProps.activeItemId) {
      this.onScrollToItem({ isFirstPromptItem })
    }
  }

  onChangeItemType = item => {
    this.props.changeItemType(this.props.consent, this.props.activeItemId, item.type, item.value)
  }

  onDeleteChoice = choiceId => {
    return () => {
      this.props.deleteChoice(this.props.consent, this.props.activeItemId, choiceId)
    }
  }

  onScrollToItem = ({ isFirstPromptItem }) => {
    const { activeItemId } = this.props
    const element = document.getElementById(`question-${activeItemId}`)
    let offset = 170
    if (!isFirstPromptItem) offset -= 16
    scrollTo({ element, offset })
  }

  onDuplicateItem = () => {
    const { activeItemId, editorStates: _editorStates, duplicateItem, question } = this.props
    const questionLabelEditorState = _editorStates[activeItemId]
    if (QUESTION_TYPES_WITH_CHOICES.includes(question.type)) {
      const { choices_order } = question
      const choiceQProps = { choicesOrder: choices_order }
      duplicateItem({ itemId: activeItemId, editorState: questionLabelEditorState, choiceQProps })
    } else {
      duplicateItem({ itemId: activeItemId, editorState: questionLabelEditorState, qType: question.type })
    }
  }

  onAddChoice = () => {
    this.props.addChoice(this.props.activeItemId, this.props.consent.questions[this.props.activeItemId])
  }

  onUpdateUnitType = e => {
    this.props.updateUnitType(this.props.activeItemId, e.target.value)
  }

  onAddOtherChoice = e => {
    this.props.addOtherChoice(this.props.activeItemId)
  }

  onMoveItem = direction => {
    return () => {
      const startIdx = this.props.consent.order[0].indexOf(this.props.activeItemId)
      let endIdx
      if (direction === 'up') {
        endIdx = startIdx - 1
      } else {
        endIdx = startIdx + 1
      }
      this.props.moveItem(this.props.consent, startIdx, endIdx)
    }
  }

  onToggleRequiredQuestion = () => {
    this.props.toggleRequiredQuestion(this.props.consent, this.props.activeItemId)
  }

  render() {
    const { consent, activeItemId, onDeleteItem, itemEditorExpanded, onToggleItemEditor } = this.props
    const item = consent.questions[activeItemId]
    const qNumber = item.attributes.questionNumber
    const isQuestion = ![
      QUESTION_TYPE_MAP.introduction,
      QUESTION_TYPE_MAP.prompt,
      QUESTION_TYPE_MAP.signature,
      QUESTION_TYPE_MAP.pdf,
    ].includes(item.type)
    const isSignature = item.type === QUESTION_TYPE_MAP.signature
    const itemText = qNumber ? `Q${qNumber}` : isSignature ? 'Signature' : 'Text'

    /**
     * We should prevent deletion of the first item in the consent,
     * where the majority of the consent content will be entered.
     */
    const isFirstPromptItem = consent.order[0][0] === activeItemId
    const cantBeMovedUp = consent.order[0][1] === activeItemId

    return (
      <div className='item-editor'>
        <div className='editor-toolbar flexed'>
          <div className='question-num-req flexed align-center'>
            <div className='question-number' onClick={() => this.onScrollToItem({ isFirstPromptItem })}>
              <h4>{itemText}</h4>
            </div>
            <Button id='collapse-button' className='collapse-button' onClick={() => onToggleItemEditor()}>
              <i className={`fas fa-angle-${itemEditorExpanded ? 'right' : 'left'}`} />
            </Button>
            {isQuestion && (
              <>
                <Divider height='15px' width='1px' color='#cacaca' />
                <ValidationPanel
                  onToggleRequiredQuestion={this.onToggleRequiredQuestion}
                  required={item.attributes.required}
                />
              </>
            )}
          </div>
          <div className='toolbar-buttons button-list'>
            <Button id='duplicate-item' className='far fa-clone hoverable-1' onClick={this.onDuplicateItem} noStyling />
            {!isFirstPromptItem && (
              <Button
                id='trash-button '
                className='fas fa-trash-alt hoverable-red'
                onClick={onDeleteItem(activeItemId)}
                noStyling
              />
            )}
            <i
              id='up-arrow'
              className={`clickable fas fa-arrow-up${isFirstPromptItem || cantBeMovedUp ? ' disabled' : ''}`}
              onClick={this.onMoveItem('up')}
            />
            <i
              id='down-arrow'
              className={`clickable fas fa-arrow-down${
                isFirstPromptItem || consent.order[0][consent.order[0].length - 1] === activeItemId ? ' disabled' : ''
              }`}
              onClick={this.onMoveItem('down')}
            />
          </div>
        </div>
        {isQuestion && (
          <QuestionPanel
            isConsent
            item={item}
            onAddOtherChoice={this.onAddOtherChoice}
            onChangeItemType={this.onChangeItemType}
            onDeleteChoice={this.onDeleteChoice}
            onAddChoice={this.onAddChoice}
            onUpdateUnitType={this.onUpdateUnitType}
            {...this.props}
          />
        )}
        {item.attributes.canHaveLogic && !CONSENT_QUESTION_TYPES_WITHOUT_LOGIC.includes(item.type) && (
          <LogicPanelContainer editorStates={editorStates} itemId={activeItemId} isConsent />
        )}
        {isSignature && <SignatureCameraTip item={item} />}
      </div>
    )
  }
}

ItemEditor.propTypes = {
  activeItemId: PropTypes.string,
  addChoice: PropTypes.func,
  addOtherChoice: PropTypes.func,
  changeItemType: PropTypes.func,
  consent: PropTypes.object,
  duplicateItem: PropTypes.func,
  editorStates: PropTypes.object,
  item: PropTypes.object,
  itemEditorExpanded: PropTypes.bool,
  itemId: PropTypes.string,
  onDeleteItem: PropTypes.func,
  question: PropTypes.shape({
    choices_order: PropTypes.array,
    type: PropTypes.string,
  }),
  toggleRequiredQuestion: PropTypes.func,
  onToggleItemEditor: PropTypes.func,
  updateUnitType: PropTypes.func,
}

export default connect(null, itemEditorActions)(ItemEditor)
