import React, { useEffect } from 'react'
import { Button, Loader, Popup } from 'components/UIElements'
import STRINGS from 'utils/strings'
import { DATE_FORMAT_MAP } from 'utils/constants'
import { getFormattedUTCTimestamp } from 'utils/time'
import moment from 'moment'
import StatusTimestampTooltip from './StatusTimestampTooltip'
import { COMBINED_INSTRUMENT_TIMELINE_MAP } from 'utils/constants'

const StatusHistorySection = ({
  instrumentTimeline = [],
  instrumentId,
  onClose,
  fetchInstrumentTimeline,
  resetInstrumentTimeline,
  instrumentTimelineLoading = false,
  noStatusMessage,
  site,
  mainSite,
  withUtcOffset = false,
}) => {
  useEffect(() => {
    if (instrumentId) {
      fetchInstrumentTimeline(instrumentId)
    } else {
      resetInstrumentTimeline()
    }
  }, [fetchInstrumentTimeline, instrumentId])

  const getTimestampValue = ({ event_timestamp, event_info = {} }) => {
    if (!withUtcOffset) {
      return moment(event_timestamp).format(DATE_FORMAT_MAP.mainWithDateTime)
    }

    const { utc_offset: utcOffset = 0 } = event_info

    return getFormattedUTCTimestamp(event_timestamp, utcOffset)
  }

  return instrumentTimelineLoading ? (
    <div className='status-history-section'>
      <Loader inContainer className='visits-history-loader' />
    </div>
  ) : (
    <div className='status-history-section'>
      {!!instrumentTimeline.length ? (
        <>
          <div className='heading-row'>
            <div className='heading-text'>{STRINGS.status}</div>
            <div className='heading-text status-timestamp-head-cell'>
              {STRINGS.statusTimestamp}
              {site || mainSite ? (
                <Popup
                  className='box-popup'
                  align='right'
                  position='bottom'
                  hover
                  noPointer
                  dark
                  trigger={<i className={`header-icon fas fa-info-circle`} />}>
                  <StatusTimestampTooltip site={site} mainSite={mainSite} />
                </Popup>
              ) : null}
            </div>
          </div>
          <div className='table-content'>
            {instrumentTimeline.map(item => (
              <div className='table-row' key={item.id}>
                <div className='table-row-text'>{COMBINED_INSTRUMENT_TIMELINE_MAP[item.event_type]}</div>
                <div className='table-row-text'>{getTimestampValue(item)}</div>
              </div>
            ))}
          </div>
          <div className='close-button-wrapper'>
            <Button content={STRINGS.close} onClick={onClose} />
          </div>
        </>
      ) : (
        <div className='no-status-message'>{noStatusMessage}</div>
      )}
    </div>
  )
}

export default StatusHistorySection
