import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getBaseSiteIdFromStudy, getUserRoleByStudy, disableScope, getUserScopeByStudy } from 'utils/misc'
import { getScoreErrors } from 'utils/instrument'
import {
  INSTRUMENT_TYPE_MAP,
  INSTRUMENT_TYPES_WITH_SCORING,
  INSTRUMENT_DISPLAY_LOCATION_OPTIONS,
  PARTICIPANT_DATA_FIELDS_MAP,
  USER_ROLES_MAP,
  USER_SCOPES,
} from 'utils/constants'
import SchedulerView from '../components/SchedulerPage'
import { metadataActions, schedulerActions, instrumentActions } from '../../../modules/Instrument'
import { actions as ptpActions } from '../../../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import { actions as siteActions } from '../../../../../../Sites/routes/SitesPage/modules/Sites'
import { fetchSurvey } from '../../EditSurvey/modules/Survey'
import { fetchClinro } from '../../EditClinro/modules/Clinro'
import { fetchDiary } from '../../EditDiary/modules/Diary'

const mapDispatchToProps = {
  fetchVisitTemplates: ptpActions.fetchVisitTemplates,
  initializeCheckedCohorts: ptpActions.initializeCheckedCohorts,
  ...metadataActions,
  ...instrumentActions,
  ...schedulerActions,
  ...siteActions,
  fetchClinro,
  fetchDiary,
  fetchSurvey,
}

const mapState = state => {
  const {
    modal,
    instrumentReducer,
    instruments,
    loading,
    participantReducer,
    sites,
    study,
    surveyEditor,
    clinroEditor,
    diaryEditor,
    user,
  } = state
  const { instrument } = instrumentReducer
  const { metadata } = instrument
  const { currentStudy } = study
  const { config } = currentStudy
  const studyID = currentStudy.id
  const userRole = getUserRoleByStudy(user, studyID)

  // const userRoleId = getUserRoleIdByStudy(user, studyID)
  // const isStudyAdmin = STUDY_ROLE_IDS.includes(userRoleId)
  const isClinro = instrument?.type === INSTRUMENT_TYPE_MAP.clinro
  const isDiary =
    instrument?.type === INSTRUMENT_TYPE_MAP.diary ||
    metadata?.display_location?.includes(INSTRUMENT_DISPLAY_LOCATION_OPTIONS.calendar)
  const isSurvey = instrument?.type === INSTRUMENT_TYPE_MAP.survey
  const isSurveyOrClinro = isClinro || isSurvey
  const scoreErrors = isSurveyOrClinro ? getScoreErrors(isClinro ? clinroEditor : surveyEditor, instrument?.type) : null
  const { checkedSites, leafSites, siteSelectionList } = sites
  const isTypeWithScoring = INSTRUMENT_TYPES_WITH_SCORING.includes(instrument?.type)
  const returnInstrumentForm = () => {
    if (isTypeWithScoring) {
      if (isClinro) return clinroEditor.clinro
      if (isDiary) return diaryEditor.diary
      return surveyEditor.survey
    }
    return null
  }
  const instrumentForm = returnInstrumentForm()
  const returnScoreOptions = () => {
    if (instrumentForm && isTypeWithScoring) {
      return instrumentForm.scores.map(score => ({ key: score.id, text: score.name }))
    }
    return null
  }
  const surveyScore = surveyEditor.survey.scores
  const surveysScore = surveyEditor.surveysScore
  const clinroScore = clinroEditor.clinro.scores
  const clinrosScore = clinroEditor.clinrosScore
  const diaryScore = diaryEditor.diary.scores
  const diariesScore = diaryEditor.diariesScore

  // Need to check if participant date of birth is disabled to know to hide send by age option
  const participantDataFields = config?.participant_data_fields
  const ageDisabled = !!participantDataFields?.disabled.find(field => {
    return field.metadata?.internal_key === PARTICIPANT_DATA_FIELDS_MAP.dob
  })
  const hasVisitSupport = !!config?.visit_support
  const hasClinro = !!currentStudy.config?.clinro
  const userScope = getUserScopeByStudy(user, currentStudy)
  const canWriteCohort = userScope.includes(USER_SCOPES.wxc.code)

  const noTracksFeature = currentStudy.config?.tracks?.disabled === true

  const isNewInstArch = currentStudy?.config?.instrument_architecture_version >= 2

  return {
    ...instrumentReducer,
    ageDisabled,
    canSetGeofencedInstruments: [USER_ROLES_MAP.root, USER_ROLES_MAP.dchAdmin, USER_ROLES_MAP.dchPii].includes(
      userRole,
    ),
    // canSetDeploymentByVisit: isStudyAdmin,
    checkedCohorts: participantReducer.checkedCohorts,
    checkedSites,
    clinroScore,
    clinrosScore,
    diariesScore,
    diaryScore,
    disableTrackCreation: disableScope({ study, user, scope: USER_SCOPES.wxc.code }) || !canWriteCohort,
    hasClinro,
    hasVisitSupport,
    instrumentForm,
    instruments: instruments.instrumentList,
    isClinro,
    isDiary,
    isGeofenceStudy: !!study.currentStudy.modules.geofencing,
    isNewInstArch,
    isSurvey,
    isSurveyOrClinro,
    leafSites,
    loading: loading.general,
    noTracksFeature,
    modal,
    scoreErrors,
    surveyScore,
    surveysScore,
    triggersLoading: loading.trigger,
    siteID: getBaseSiteIdFromStudy(study),
    sitesList: siteSelectionList,
    study: study.currentStudy,
    studyID: study.currentStudy.id,
    scoreOptions: returnScoreOptions(),
    userId: user?.id,
  }
}

export default connect(
  mapState,
  mapDispatchToProps,
)(props => {
  if (props.loading || !props.instrument) {
    return Loader(props)
  }
  return <SchedulerView {...props} />
})
