import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'

class HoverBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { mouseX: 0 }
  }

  getRelativeMousePosition = position => {
    const hoverBar = document.getElementsByClassName('hover-add-bar')[0]
    const boundingClient = hoverBar.getBoundingClientRect()
    return position - boundingClient.left + 10
  }

  render() {
    const { isConsent } = this.props
    const { mouseX } = this.state
    return (
      <div
        onMouseEnter={e => {
          this.setState({ mouseX: this.getRelativeMousePosition(e.clientX) })
        }}
        className='hover-add-bar'>
        <div className='hover-dashed-line' />
        <div
          style={{
            left: mouseX,
          }}
          className='hover-add-popup'>
          <Button
            tabIndex={-1}
            secondary
            link
            no-padding
            icon='fas fa-plus'
            content='Add Text'
            onClick={this.props.onAddItem('prompt', this.props.itemIndex)}
          />
          <Button
            tabIndex={-1}
            secondary
            link
            no-padding
            icon='fas fa-plus'
            content='Add Question'
            onClick={this.props.onAddItem('select_one', this.props.itemIndex)}
          />
          {isConsent && (
            <Button
              tabIndex={-1}
              secondary
              link
              no-padding
              icon='fas fa-plus'
              content='Add Signature'
              onClick={this.props.onAddItem('signature', this.props.itemIndex)}
            />
          )}
        </div>
      </div>
    )
  }
}

HoverBar.propTypes = {
  onAddItem: PropTypes.func,
  isConsent: PropTypes.bool,
  itemIndex: PropTypes.number,
}

export default HoverBar
