import React from 'react'
import { NumberInputDropdown, Dropdown, Input } from 'components/UIElements'
import _dayOptions, { TIME_UNIT_OPTIONS } from '../constants/schedulerConstants'

const DurationInput = (inputProps = {}) => {
  const {
    disabled,
    hasMonthsOption = false,
    inputValue,
    isSelected = false,
    onBlur,
    onChange,
    onSelect,
    onDropdownSelect,
    preText,
    postText,
    unitValue,
    min = 1,
    newTimeOpts,
    useSimpleInput = false,
  } = inputProps

  const timeOptions = newTimeOpts
    ? newTimeOpts
    : hasMonthsOption
    ? [
        ...TIME_UNIT_OPTIONS,
        {
          key: 'months',
          text: 'months',
        },
      ]
    : TIME_UNIT_OPTIONS

  return (
    <div className='flexed'>
      {preText}
      {useSimpleInput ? (
        <Input
          className='duration-input'
          id='duration-input-id'
          type='number'
          value={isSelected ? inputValue || '' : ''}
          onChange={onChange}
          onBlur={onBlur}
          min={min}
          disabled={disabled}
          preventInput={input => input <= 0}
        />
      ) : (
        <NumberInputDropdown
          id='offset-days-input'
          disabled={disabled}
          value={isSelected ? inputValue || '' : ''}
          options={_dayOptions}
          onChange={onChange}
          onSelect={onSelect}
          onBlur={onBlur}
          min={min}
        />
      )}
      <Dropdown
        disabled={disabled}
        id='time-unit-options-dropdown'
        selected={unitValue}
        onSelect={onDropdownSelect}
        options={timeOptions}
      />
      {postText}
    </div>
  )
}

DurationInput.propTypes = {}

export default DurationInput
