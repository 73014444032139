import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button } from 'components/UIElements'
import { urlRegex, INSTRUMENT_TYPE_MAP, MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import ItemList from './ItemList'
import SurveyToolbar from './SurveyToolbar'
import ItemEditor from './ItemEditor/ItemEditor'
import '../stylesheets/Survey.scss'
import { getRandomQuestionId } from '../../../../../../../utils/getRandomQuestionId'

export default class SurveyView extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { activeItemId: null }
  }

  componentDidMount() {
    const { survey, addItem } = this.props
    if (survey.order[0].length === 0) {
      const newQId = getRandomQuestionId()
      addItem({
        itemType: 'introduction',
        index: 0,
        newQId,
      })
      this.setState({ activeItemId: newQId })
    } else {
      this.setState({ activeItemId: survey.order[0][0] })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.setState({ activeItemId: this.props.survey.order[0][0] })
    }
  }

  onMoveItem = ({ oldIndex, newIndex }) => {
    if (newIndex !== 0) {
      this.props.moveItem(this.props.survey, oldIndex, newIndex)
    }
  }

  onDeleteItem = itemId => {
    return () => {
      this.props.deleteItem(this.props.survey, itemId)
    }
  }

  onChangeItemType = (itemId, prevType, nextType) => {
    this.props.changeItemType(this.props.survey, itemId, prevType, nextType)
  }

  onDeleteChoice = (itemId, choiceId) => {
    return () => {
      this.props.deleteChoice(this.props.survey, itemId, choiceId)
    }
  }

  onAddItem = (itemType, index) => {
    const { addItem } = this.props
    return e => {
      const newQId = getRandomQuestionId()
      addItem({
        itemType,
        index,
        newQId,
      })
      this.setState({ activeItemId: newQId })
    }
  }

  onToggleEdit = itemId => {
    if (this.props.activeItemId !== itemId) {
      this.setState({ activeItemId: itemId })
    }
  }

  onRandomize = () => {
    const { openModal, randomizeQuestions } = this.props
    openModal({
      content: MODAL_CONTENT_MAP.shuffleQuestionOrder,
      confirmButton: MODAL_BUTTONS_MAP.proceed,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: randomizeQuestions,
    })
  }

  shouldCancelStart(e) {
    return e.target.className !== 'grab-icon'
  }

  onUpdateURL = value => {
    const { updateArticleURL } = this.props
    updateArticleURL(value.trim())
  }

  onUpdateArticleInstrumentTitle = e => {
    const { updateInstrumentTitle, updateArticleTitle } = this.props
    updateArticleTitle(e)
    updateInstrumentTitle(e)
  }

  render() {
    const {
      article,
      downloadInstrument,
      duplicateItem,
      editorStates,
      errors,
      instrumentTitle,
      isLoading,
      onSave,
      studyID,
      survey,
      type,
      updateTitle,
      version,
      wordCount,
      study,
      userId,
    } = this.props
    const { activeItemId } = this.state
    const { title, id, questions } = survey
    const isArticle = type === INSTRUMENT_TYPE_MAP.article
    const articleHasSpaces = article.url.includes(' ') && errors.URLError
    const articleIsBlank = !article.url && errors.blankURLError
    const articleIsInvalid = !article.url.match(urlRegex) && errors.invalidUrlError
    const surveyItemLabelEditorState = editorStates[activeItemId]

    return (
      <div className='survey-builder'>
        <SurveyToolbar
          studyID={studyID}
          id={id}
          version={version}
          onRandomize={this.onRandomize}
          wordCount={wordCount}
          type={type}
          isLoading={isLoading}
          updateTitle={isArticle ? this.onUpdateArticleInstrumentTitle : updateTitle}
          title={isArticle ? instrumentTitle : title}
          errors={errors}
          download={downloadInstrument}
          onSave={onSave}
          questions={questions}
        />
        <div className='builder-content'>
          {isArticle ? (
            <div className='article-builder'>
              <div className='survey-item-container'>
                <p className='label-small'>ARTICLE LINK</p>
                <Input
                  id='article-link'
                  hasError={articleHasSpaces || articleIsBlank || articleIsInvalid}
                  placeholder={errors.blankURLError || 'Enter URL of article here'}
                  onChange={value => this.onUpdateURL(value)}
                  value={article.url}
                  errorText={errors.URLError || errors.invalidUrlError}
                />
              </div>
              <div className='button-list'>
                <Button id='next' content='Next' onClick={() => onSave('properties')} />
              </div>
            </div>
          ) : (
            <div>
              <div className='main-editor-section'>
                <ItemList
                  {...this.props}
                  activeItemId={activeItemId}
                  onSortEnd={this.onMoveItem}
                  shouldCancelStart={this.shouldCancelStart}
                  lockToContainerEdges
                  helperClass='sortableHelper'
                  lockAxis='y'
                  onToggleEdit={this.onToggleEdit}
                  onDeleteItem={this.onDeleteItem}
                  onChangeItemType={this.onChangeItemType}
                  onDeleteChoice={this.onDeleteChoice}
                  onAddItem={this.onAddItem}
                />
              </div>
              <div className={`right-section${activeItemId ? ' active' : ''}`}>
                {survey.questions[activeItemId] && (
                  <ItemEditor
                    survey={survey}
                    question={survey.questions[activeItemId]}
                    activeItemId={activeItemId}
                    changeItemType={this.onChangeItemType}
                    duplicateItem={duplicateItem}
                    onDeleteItem={this.onDeleteItem}
                    surveyItemLabelEditorState={surveyItemLabelEditorState}
                    editorStates={editorStates}
                    study={study}
                    userId={userId}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

SurveyView.propTypes = {
  activeItemId: PropTypes.string,
  addChoiceOnPaste: PropTypes.func,
  addItem: PropTypes.func,
  changeItemType: PropTypes.func,
  deleteChoice: PropTypes.func,
  deleteItem: PropTypes.func,
  duplicateItem: PropTypes.func,
  editorStates: PropTypes.object,
  initializeBlankSurvey: PropTypes.func,
  instrumentTitle: PropTypes.string,
  moveItem: PropTypes.func,
  noQuestionsErr: PropTypes.string,
  openModal: PropTypes.func,
  randomizeQuestions: PropTypes.func,
  studyID: PropTypes.number,
  survey: PropTypes.object,
  titleErr: PropTypes.string,
  toggleEdit: PropTypes.func,
  updateArticleTitle: PropTypes.func,
  updateArticleURL: PropTypes.func,
  updateInstrumentTitle: PropTypes.func,
  updateIntrumentTitle: PropTypes.func,
  updateTitle: PropTypes.func,
  url: PropTypes.string,
  version: PropTypes.number,
  versionOptions: PropTypes.array,
  wordCount: PropTypes.number,
}
