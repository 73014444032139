import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { browserHistory } from 'react-router'
import { Button, Container, SortableTable, Loader, TextOverflow } from 'components/UIElements'
import { convertToLocalizedNumber, parseSpecialCharactersIn } from 'utils/misc'
import { buttonContentOnSendState } from 'utils/helpers'
import { getFormattedDateString, getDateFromNow, yearDifference } from 'utils/time'
import { FLAGSMITH_FEATURES } from 'utils/constants'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import STRINGS from 'utils/strings'
import 'react-datepicker/dist/react-datepicker.css'
import ParticipantVisits from './ParticipantVisits'
import NewConsentManagement from './NewConsentManagement'
import FilesManagement from './FilesManagement'
import ParticipantForm from './ParticipantForm'
import CohortManagement from '../containers/CohortManagementContainer'
import {
  getTotals,
  headerOptions,
  renderFunctions,
  treasureChestHeaderOptions,
} from '../../../../Payments/components/PaymentsTable'
import CreateParticipantNav from './CreateParticipantNav'
import CompletedInstruments from './CompletedInstruments'
import CompletedAnnouncements from './CompletedAnnouncements'
import CompletedDiaries from './CompletedDiaries'
import ParticipantStats from './ParticipantStats'
import ParticipantProfile from './ParticipantProfile'
import ParticipantStudySettings from './ParticipantStudySettings'
import VisitScheduleManagement from './VisitScheduleManagement'
import '../stylesheets/participant.scss'
import AccountSettings from './AccountSettings'
import { onOpenCancelModal } from '../utils/pariticipantUtils'
import DrawerTabs from './DrawerTabs'
import StudyTimeline from './StudyTimeline'
import { validateParticipant } from '../modules/CreateParticipant'

const CreateParticipantPage = props => {
  const {
    isCreate,
    ptpId,
    canSeeOrphans,
    participant,
    inLeafSite,
    noTracksFeature,
    onSetOrphanedParticipants,
    userId,
    studyID,
  } = props
  const pageRef = useRef()

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.advancedEvents]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasAdvancedEventTimelines = flagsmithFeatures[FLAGSMITH_FEATURES.advancedEvents]?.enabled

  const [editable, setEditable] = useState(isCreate || !!ptpId)
  const [language, setLanguage] = useState('en-US')

  const [checkedVisitTemplate, setCheckedVisitTemplate] = useState({})

  useEffect(() => {
    const {
      fetchPtp,
      fetchPtpPayments,
      hasTreasureChest,
      inDrawer,
      inLeafSite,
      isInParticipantsTable,
      onSetOrphanedParticipants,
      params,
      ptpSiteID,
      setNavBarProps,
      study,
      toggleOnSite,
    } = props

    if (canSeeOrphans && !isCreate && !inLeafSite && !inDrawer) {
      onSetOrphanedParticipants(true)
    }

    setLanguage(navigator.language)

    const { studyID } = params

    if (((ptpId && inDrawer) || !isInParticipantsTable) && !isCreate) {
      fetchPtp(studyID, ptpSiteID, ptpId)
      fetchPtpPayments(studyID, ptpId, hasTreasureChest)
    }

    setNavBarProps({
      back: `/studies/${study.id}/participants`,
      close: `/studies/${study.id}/participants`,
      backText: 'Participants',
    })

    if (ptpId) {
      toggleOnSite(ptpSiteID)
    }
    pageRef.current.scrollIntoView(true)
    return () => {
      const {
        resetCheckedSites,
        params: { participantId },
      } = props
      setNavBarProps(null)
      if (participantId) resetCheckedSites()
    }
  }, [])

  useEffect(() => {
    const ptpLoaded = Object.keys(participant).length !== 0
    if (canSeeOrphans && ptpLoaded && !!ptpId && !inLeafSite) {
      onSetOrphanedParticipants(true)
    }
  }, [canSeeOrphans, participant, inLeafSite, onSetOrphanedParticipants, ptpId])

  const triggerEditable = () => {
    setEditable(true)
  }

  const getCompletion = () => {
    const { participant } = props
    const { num_completed_instruments, num_total_instruments } = participant
    const completed = num_completed_instruments || 0
    const total = num_total_instruments || 100
    return `${Math.floor((completed / total) * 100)}% Compliance`
  }

  const onSaveParticipant = () => {
    const {
      isEmailless,
      baseSiteID,
      saveToDatabase,
      study,
      participant,
      checkedCohorts,
      consentRequired,
      checkedSites,
      participantDataFields,
      params: { participantId },
      studyID,
    } = props

    saveToDatabase({
      studyID: study.id,
      siteID: baseSiteID,
      participant,
      checkedCohorts,
      participantID: participant.id,
      consentRequired,
      sites: checkedSites,
      pDataFields: participantDataFields,
      redirect: !participantId && isEmailless ? `/studies/${studyID}/participants/account-settings` : null,
    })
  }

  const onValidateParticipant = () => {
    const { participant, checkedCohorts, participantDataFields } = props

    const selectedTracks = Object.keys(checkedCohorts)

    return validateParticipant({
      participant,
      pDataFields: participantDataFields,
      selectedTracks: selectedTracks.length ? selectedTracks : null,
      isPatch: !isCreate,
    })
  }

  const renderPaymentsSection = name => {
    const { componentLoading, hasTreasureChest, payments } = props
    const { pendingPayments: paymentsLoading } = componentLoading
    const hasPayments = payments && payments.completedList.length > 0

    const _headerOptions = hasTreasureChest ? treasureChestHeaderOptions.slice(3) : headerOptions.slice(3)
    return (
      <Container>
        <div className='title'>Payments</div>
        {paymentsLoading && <Loader inContainer />}
        {!paymentsLoading && hasPayments && (
          <div className='payments-table'>
            <SortableTable
              sortingBy='name'
              rowList={payments.completedList}
              headerOptions={_headerOptions}
              renderFunctions={renderFunctions}
            />
            {getTotals(payments.completedList, payments.totals, language, hasTreasureChest)}
          </div>
        )}
        {!paymentsLoading && !hasPayments && <p>{`${name} does not have any payment activity.`}</p>}
      </Container>
    )
  }

  const renderAccountDeletion = (deactivationRequestTimestamp, canSeeAccountSettings) => {
    const deletionMessage = `Account deletion request received ${getFormattedDateString(
      deactivationRequestTimestamp,
      'DD/MM/YYYY',
    )} ( ${getDateFromNow(deactivationRequestTimestamp)} ). Please review the participant's account settings.`
    return (
      <>
        {deactivationRequestTimestamp && (
          <div className='account-deletion'>
            <h6>Account Deletion Request</h6>
            <p>{deletionMessage}</p>
          </div>
        )}
        {canSeeAccountSettings && <AccountSettings {...props} />}
      </>
    )
  }

  const getSiteLocation = (ptpSiteID, sitesInfo) => {
    let text = ''
    let color = '#DDDFF0'
    const siteLocation = ptpSiteID ? sitesInfo[ptpSiteID] : null
    if (!siteLocation) {
      text = 'Site Unassigned'
      color = '#FFC191'
    } else if (siteLocation.is_virtual) {
      text = 'Site Location: Virtual'
      color = '#DDDFF0'
    } else {
      color = '#DDDFF0'
      const siteLocationNameArr = siteLocation.site_path.split('.')
      const regionName = parseSpecialCharactersIn(siteLocationNameArr[2])
      text = `Site Location: ${regionName ? `${regionName}, ` : ''} ${parseSpecialCharactersIn(
        siteLocationNameArr[1],
      ) || ''}`
    }
    return {
      text,
      color,
      label: siteLocation?.site_label || null,
    }
  }

  const {
    canAddPtp,
    canMove,
    canResendInviteOrResetPw,
    canUpdateConsent,
    canViewAnnouncements,
    canViewFiles,
    hasVisitSupport,
    canViewVisitSchedule,
    canWrite,
    closeDrawer,
    consentRequired,
    clearPtpVisits,
    fetchParticipantVisits,
    fetchVisitTemplates,
    hasIncentives,
    inDrawer,
    isDiary,
    link,
    locales,
    noPII,
    participantDataFields,
    participantVisits,
    requestSending,
    requestSent,
    sendDisabled,
    study,
    studyLock,
    updateField,
    visitTemplates,
    ptpSiteID,
    sitesInfo = {},
    ptpInstrumentsLoading,
    instrumentStatus,
    isSubjectIdAvailable,
    visitStartDatetime,
  } = props

  const ptpSiteLocation = getSiteLocation(ptpSiteID, sitesInfo)
  const { fname, lname, id, deactivation_request_timestamp, email, phone, date_of_birth } = participant
  const name = noPII ? `Particpant ${id}` : `${fname} ${lname}`
  const needToReassign = !isCreate && canSeeOrphans && !participant?.in_leaf_site
  const canSave = canWrite || (isCreate && canAddPtp)
  const age = participantDataFields?.disabled?.some(data => data.metadata.internal_key === 'date_of_birth')
    ? undefined
    : yearDifference(date_of_birth, moment().format())
  renderFunctions.amount = ({ value }) => {
    const localizedPayment = convertToLocalizedNumber(value, language, true)
    return (
      <td key={`amount-${value}`} className='amount'>
        {localizedPayment}
      </td>
    )
  }

  const addParticipant = {
    content: 'Add This Participant',
    sendingContent: 'Adding',
    sentContent: '...Added!',
  }
  const saveChanges = {
    content: 'Save Changes',
    sendingContent: 'Saving',
    sentContent: '...Saved!',
  }
  const sendStateClassName = sendDisabled ? 'send-state' : '' // for cursor wait during sendState
  const site = ptpSiteLocation?.label
  const mainSite = ptpSiteLocation?.text

  const renderParticipantInfo = () => {
    return (
      <>
        <div className='participant-info'>
          <TextOverflow text={participant.fname} tagClassName='no-margin title-overflow' maxWidth={300} />
          <span className='text text-blue'>Participant ID: {id}</span>
          <div className='flexed end-justified'>
            <div className='ptp-site-location' style={{ background: ptpSiteLocation.color }}>
              <span>
                <b>{ptpSiteLocation.text}</b>
              </span>
            </div>
          </div>
          {!isCreate && !noPII && (
            <>
              {(age || age === 0) && <span>{`${age} y.o.`}</span>}
              <span className='text text-blue'>Subject ID: {participant.subject_id || '---'}</span>
              <div className='flexed end-justified'>
                <span className='text text-gray'>Site Name: {ptpSiteLocation?.label || '---'}</span>
              </div>
            </>
          )}
        </div>
      </>
    )
  }

  const TAB_IDS = {
    instruments: 'instruments',
    consent: 'consent',
    diary: 'diary',
    announcements: 'announcements',
    visits: 'visits',
    files: 'files',
    payments: 'payments',
    trackManagement: 'track-management',
    accountSettings: 'account-settings',
  }

  const canViewAccountSettings = canResendInviteOrResetPw || canWrite || canResendInviteOrResetPw

  const TABS = [
    {
      id: TAB_IDS.instruments,
      title: STRINGS.instruments,
      visible: !isCreate,
    },
    {
      id: TAB_IDS.consent,
      title: STRINGS.consent,
      visible: !isCreate && consentRequired && !noPII,
    },
    {
      id: TAB_IDS.diary,
      title: STRINGS.diary,
      visible: !isCreate && isDiary,
    },
    {
      id: TAB_IDS.announcements,
      title: STRINGS.announcements,
      visible: !isCreate && hasAdvancedEventTimelines && canViewAnnouncements,
    },
    {
      id: TAB_IDS.visits,
      title: STRINGS.visits,
      visible: !isCreate && hasVisitSupport && canViewVisitSchedule,
    },
    {
      id: TAB_IDS.trackManagement,
      title: STRINGS.track,
      visible: !noTracksFeature,
    },
    {
      id: TAB_IDS.files,
      title: STRINGS.files,
      visible: !isCreate && !noPII && canViewFiles,
    },
    {
      id: TAB_IDS.payments,
      title: STRINGS.payments,
      visible: !isCreate && hasIncentives,
    },
    {
      id: TAB_IDS.accountSettings,
      title: STRINGS.accountSettings,
      visible: inDrawer && canViewAccountSettings,
    },
  ]

  const visibleTabs = useMemo(() => {
    return TABS.filter(tab => tab.visible)
  }, [TABS])

  const [tab, setTab] = useState()

  useEffect(() => {
    if (!tab && ptpInstrumentsLoading === false) {
      setTab(visibleTabs[0])
    }
  }, [visibleTabs, tab, ptpInstrumentsLoading, instrumentStatus?.length])

  useEffect(() => {
    return () => {
      setTab(null)
    }
  }, [])

  const isValidPtp = onValidateParticipant()

  const visitTemplatesList = useMemo(
    () =>
      visitTemplates.map(({ id: tid, template_name, visits }) => ({
        field: tid,
        label: template_name,
        value: visits.map(({ id, name }) => ({
          key: id,
          text: name,
          value: id,
        })),
      })),
    [visitTemplates],
  )

  const hasVisitsInTemplates = useMemo(
    () =>
      visitTemplatesList.some(visitTemplate => {
        return !!visitTemplate.value.length
      }),
    [visitTemplatesList],
  )

  const visitNameFieldEmpty = hasVisitsInTemplates && visitStartDatetime && !Object.keys(checkedVisitTemplate)?.length

  const isSubmitBtnDisabled =
    sendDisabled || studyLock || isSubjectIdAvailable === false || !isValidPtp || visitNameFieldEmpty

  return (
    <div className={`participant page ${sendStateClassName} ${inDrawer ? 'drawer-container' : ''}`} ref={pageRef}>
      {isCreate && <CreateParticipantNav {...props} onSaveParticipant={onSaveParticipant} />}
      {isCreate || id ? (
        <>
          <div className={inDrawer ? 'ptp-drawer-buttons' : ''}>
            {inDrawer && (
              <div className='flexed-header compliance'>
                <span className='compliance-rate'>
                  <b>{getCompletion()}</b>
                </span>
              </div>
            )}
            {link && canWrite && (
              <div className='flexed-header end-justified'>
                <Button
                  disabled={studyLock}
                  grey
                  id='participant-edit-profile'
                  content='Edit Profile'
                  onClick={() => {
                    closeDrawer()
                    browserHistory.push(link)
                  }}
                />
              </div>
            )}
          </div>
          <div className={inDrawer ? 'ptp-sticky-info' : ''}>
            {isCreate ? (
              <div className='flexed-header'>
                <h2 className='ptp-name'>New Participant</h2>
              </div>
            ) : (
              <Container className={`container-sticky ${inDrawer ? 'drawer-container-sticky' : ''}`}>
                {renderParticipantInfo()}
              </Container>
            )}
          </div>
          <div className={`ptp-container-wrapper ${inDrawer ? 'drawer-container-wrapper' : ''}`}>
            {inDrawer ? (
              <>
                <div className='drawer-info-section'>
                  <StudyTimeline study={study} participant={participant} />
                  {!isCreate && <ParticipantStats {...props} />}
                </div>
                <DrawerTabs
                  tabs={visibleTabs}
                  selectedTab={tab}
                  selectTab={newTab => {
                    setTab(newTab)
                  }}
                />
                <div className='drawer-tab-content'>
                  {tab?.id == TAB_IDS.instruments ? (
                    <CompletedInstruments
                      {...props}
                      ptpName={name}
                      hasAdvancedEventTimelines={hasAdvancedEventTimelines}
                      ptpInstrumentsLoading={ptpInstrumentsLoading}
                      instrumentStatus={instrumentStatus}
                      site={site}
                      mainSite={mainSite}
                    />
                  ) : null}
                  {tab?.id == TAB_IDS.consent ? (
                    <div>
                      <NewConsentManagement disabled={!canUpdateConsent || studyLock} {...props} ptpName={name} />
                    </div>
                  ) : null}
                  {tab?.id == TAB_IDS.diary ? (
                    <div>
                      <CompletedDiaries {...props} hasAdvancedEventTimelines={hasAdvancedEventTimelines} />
                    </div>
                  ) : null}
                  {tab?.id == TAB_IDS.announcements ? (
                    <CompletedAnnouncements {...props} ptpName={name} site={site} mainSite={mainSite} />
                  ) : null}
                  {tab?.id == TAB_IDS.visits ? (
                    <div>
                      <ParticipantVisits {...props} />
                    </div>
                  ) : null}
                  {tab?.id == TAB_IDS.trackManagement && !noTracksFeature ? (
                    <div>
                      <CohortManagement
                        onChange={triggerEditable}
                        disabled={inDrawer || !(canWrite || (isCreate && canAddPtp)) || sendDisabled}
                        ptpName={name}
                        title={STRINGS.trackManagement}
                      />
                    </div>
                  ) : null}
                  {tab?.id == TAB_IDS.files ? (
                    <div>
                      <FilesManagement {...props} ptpName={name} />
                    </div>
                  ) : null}
                  {tab?.id == TAB_IDS.payments ? renderPaymentsSection(name) : null}
                  {tab?.id == TAB_IDS.accountSettings ? (
                    <ParticipantProfile
                      {...props}
                      accountSettings={renderAccountDeletion(
                        deactivation_request_timestamp,
                        canWrite || canResendInviteOrResetPw,
                      )}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <>
                {!isCreate && <ParticipantStats {...props} />}
                <ParticipantForm
                  disabled={!(canWrite || canAddPtp) || sendDisabled}
                  needToReassign={needToReassign}
                  editable={editable}
                  triggerEditable={triggerEditable}
                  {...props}
                  locales={
                    study.config && study.config.enforced_language ? study.config.enforced_language.languages : locales
                  }
                  participantDataFields={participantDataFields}
                  studyId={study?.id}
                  isSubjectIdAvailable={isSubjectIdAvailable}
                  participantId={ptpId}
                />
                {!isCreate && canMove && (
                  <div>
                    <ParticipantStudySettings {...props} />
                  </div>
                )}
                {!isCreate && consentRequired && !noPII && (
                  <div>
                    <NewConsentManagement disabled={!canUpdateConsent || studyLock} {...props} ptpName={name} />
                  </div>
                )}
                {!isCreate && hasVisitSupport && canViewVisitSchedule && (
                  <div>
                    <ParticipantVisits {...props} />
                  </div>
                )}
                {!isCreate && !noPII && canViewFiles && (
                  <div>
                    <FilesManagement {...props} ptpName={name} />
                  </div>
                )}
                {!noTracksFeature && (
                  <div>
                    <CohortManagement
                      onChange={triggerEditable}
                      disabled={inDrawer || !(canWrite || (isCreate && canAddPtp)) || sendDisabled}
                      ptpName={name}
                      title='Track Management'
                    />
                  </div>
                )}
                {!isCreate && hasIncentives && renderPaymentsSection(name)}
                {!isCreate && hasAdvancedEventTimelines && <CompletedAnnouncements {...props} ptpName={name} />}
                {!isCreate && (
                  <CompletedInstruments
                    {...props}
                    ptpName={name}
                    hasAdvancedEventTimelines={hasAdvancedEventTimelines}
                    ptpInstrumentsLoading={ptpInstrumentsLoading}
                    instrumentStatus={instrumentStatus}
                  />
                )}
                {!isCreate && isDiary && (
                  <div>
                    <CompletedDiaries {...props} hasAdvancedEventTimelines={hasAdvancedEventTimelines} />
                  </div>
                )}
                {canViewVisitSchedule && hasVisitSupport && (
                  <VisitScheduleManagement
                    studyID={studyID}
                    visitTemplates={visitTemplates}
                    fetchVisitTemplates={fetchVisitTemplates}
                    updateField={updateField}
                    participant={participant}
                    disabled={sendDisabled}
                    isCreate={isCreate}
                    clearPtpVisits={clearPtpVisits}
                    fetchParticipantVisits={fetchParticipantVisits}
                    participantVisits={participantVisits}
                    visitTemplatesList={visitTemplatesList}
                    hasVisitsInTemplates={hasVisitsInTemplates}
                    checkedVisitTemplate={checkedVisitTemplate}
                    setCheckedVisitTemplate={setCheckedVisitTemplate}
                  />
                )}
                {editable && canSave && (
                  <div className='button-list'>
                    <Button
                      onClick={() => onOpenCancelModal(props)}
                      className='grey'
                      id='create-participant-cancel'
                      content='Cancel'
                      disabled={sendDisabled}
                    />
                    <Button
                      onClick={onSaveParticipant}
                      id='participant-save'
                      content={buttonContentOnSendState(
                        isCreate ? addParticipant : saveChanges,
                        requestSending,
                        requestSent,
                      )}
                      disabled={isSubmitBtnDisabled}
                      loading={requestSending}
                    />
                  </div>
                )}
                {!isCreate && <AccountSettings {...props} />}
              </>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

CreateParticipantPage.propTypes = {
  baseSiteID: PropTypes.number,
  canAddPtp: PropTypes.bool,
  canMove: PropTypes.bool,
  canResendInviteOrResetPw: PropTypes.bool,
  canSeeOrphans: PropTypes.bool,
  canUpdateConsent: PropTypes.bool,
  canViewAnnouncements: PropTypes.bool,
  canViewFiles: PropTypes.bool,
  canViewVisitSchedule: PropTypes.bool,
  canWrite: PropTypes.bool,
  checkedCohorts: PropTypes.object,
  checkedSites: PropTypes.object,
  clearPtpVisits: PropTypes.func,
  closeDrawer: PropTypes.func,
  closeModal: PropTypes.func,
  componentLoading: PropTypes.shape({ pendingPayments: PropTypes.bool }),
  consentRequired: PropTypes.bool,
  fetchConsentEvents: PropTypes.func,
  fetchLocales: PropTypes.func,
  fetchParticipantVisits: PropTypes.func,
  fetchPtp: PropTypes.func,
  fetchPtpPayments: PropTypes.func,
  fetchVisitTemplates: PropTypes.func,
  hasIncentives: PropTypes.bool,
  hasOrphanedParticipants: PropTypes.bool,
  hasTreasureChest: PropTypes.bool,
  hasVisitSupport: PropTypes.bool,
  inDrawer: PropTypes.bool,
  initializeEditPage: PropTypes.func,
  inLeafSite: PropTypes.bool,
  isCreate: PropTypes.bool,
  isDiary: PropTypes.bool,
  isEmailless: PropTypes.bool,
  isInParticipantsTable: PropTypes.bool,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  link: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.object),
  noTracksFeature: PropTypes.bool,
  noPII: PropTypes.bool,
  onSetOrphanedParticipants: PropTypes.func,
  openModal: PropTypes.func,
  params: PropTypes.object,
  params: PropTypes.object,
  participant: PropTypes.object,
  participantDataFields: PropTypes.object,
  participantVisits: PropTypes.arrayOf(PropTypes.array),
  payments: PropTypes.object,
  ptpId: PropTypes.number,
  ptpSiteID: PropTypes.number,
  requestSending: PropTypes.bool,
  requestSent: PropTypes.bool,
  resetCheckedSites: PropTypes.func,
  saveToDatabase: PropTypes.func,
  sendDisabled: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  sitesInfo: PropTypes.shape(),
  study: PropTypes.object,
  studyID: PropTypes.number,
  studyLock: PropTypes.bool,
  toggleOnSite: PropTypes.func,
  toggleSite: PropTypes.func,
  updateField: PropTypes.func,
  userId: PropTypes.number,
  visitTemplates: PropTypes.arrayOf(PropTypes.object),
}

export default CreateParticipantPage
