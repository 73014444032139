import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import UploadTraining from './containers/UploadTrainingPageContainer'

export default store => ({
  path: 'upload/:trainingMaterialType',
  exact: false,
  getComponent(nextState, cb) {
    require.ensure([], () => {
      const { study } = store.getState()
      setPageTitle(`Upload Training | Study ${study.currentStudy.id} | ${study.currentStudy.platform_name}`)

      const { params } = nextState
      const { studyID } = params
      const redirectRoute = `/studies/${studyID}/training`
      cb(
        null,
        UserIsAuthenticated(
          UserHasValidPassword(
            LockRedirect(studyID, 'participants')(UserHasScope(['ft'], UploadTraining, false, studyID, redirectRoute)),
          ),
        ),
      )
    })
  },
})
