import React from 'react'

const addExistingUserModalContent = (
  <div>
    <h3>You&apos;re adding an existing user to this study</h3>
    <p>
      <span>This user&apos;s </span>
      <strong>name</strong>
      <span> (and for Case Managers, </span>
      <strong>phone number</strong>
      <span>) will be updated across all studies to which they have access.</span>
    </p>
  </div>
)

export default addExistingUserModalContent
