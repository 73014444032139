import React from 'react'
import { Loader } from 'components/UIElements'
import { browserHistory } from 'react-router'

export default props => {
  const { hasInsights, insights, sisenseLoading,renderSisenseButton, loading, header, message, className } = props
  const redirect = className === 'ptp-compliance' ? 'participants' : 'instruments'
  return (
    <div className={`d3-chart-container ${className}`}>
      <div className='flexed column chart-section empty'>
        <div className='chart-header flexed'>
          <h4>{header}</h4>
        </div>
        {loading ? (
          <div style={{ height: 400 }}>
            <Loader inContainer />
          </div>
        ) : (
          <h5>{message}</h5>
        )}
        {insights && renderSisenseButton && renderSisenseButton(insights, sisenseLoading)}
      </div>
      {!hasInsights && <RedirectButton {...props} redirect={redirect} />}
    </div>
  )
}
export const RedirectButton = ({ studyID, buttonText, redirect }) => {
  return (
    <div onClick={() => browserHistory.push(`/studies/${studyID}/${redirect}`)} className='bottom-section clickable'>
      {buttonText}
    </div>
  )
}
