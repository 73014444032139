import { REWARD_TYPE_MAP, DURATION_TYPE_MAP } from 'utils/constants'
import { compareValues, getRandomId } from 'utils/misc'

export const calcMapSteps = target => {
  const stepsPerReward = Math.floor(14 / target)
  const steps = new Array(target).fill(stepsPerReward)
  let current = 0
  for (let remainder = 14 % target; remainder > 0; remainder--) {
    steps[current]++
    current++
  }
  return steps
}

export const generateCycles = (cyclesArg, payoutType) => {
  const cycle = cyclesArg[0]
  const { target, cycleDuration, studyDuration, reward, durationType, rewardType, currency } = cycle
  const newCycleDuration = cycleDuration === '' ? 1 : cycleDuration
  const newStudyDuration = studyDuration === '' ? 0 : studyDuration
  const studyDurationInDays = durationType === 'days' ? newStudyDuration : newStudyDuration * 7
  const numCycles = Math.ceil(studyDurationInDays / newCycleDuration)
  const remainingDays = studyDurationInDays % newCycleDuration
  const cycles = new Array(numCycles)
  const mapSteps = calcMapSteps(target)
  for (let i = 0; i < numCycles; i++) {
    cycles[i] = {
      target,
      reward,
      duration: newCycleDuration,
      map_steps: mapSteps.slice(),
      reward_type: rewardType,
    }
    if (currency) cycles[i].currency = currency
    if (payoutType && rewardType !== REWARD_TYPE_MAP.gems) cycles[i].payout_type = payoutType
  }
  if (remainingDays) cycles[cycles.length - 1].duration = remainingDays
  return cycles
}

export const generateDynamicCycles = (cycles, payoutType) => {
  // TODO: add support for duration in weeks
  return cycles.map(cycle => {
    const { target, cycleDuration, reward, rewardType, currency } = cycle
    const resultCycle = {
      target,
      reward,
      duration: cycleDuration,
      map_steps: calcMapSteps(target),
      reward_type: rewardType,
    }
    if (currency) resultCycle.currency = currency
    if (payoutType && rewardType !== REWARD_TYPE_MAP.gems) resultCycle.payout_type = payoutType
    return resultCycle
  })
}

export const getCyclesInfo = cycles => {
  const { target, reward, currency, payout_type } = cycles[0]
  const rewardType = cycles[0].reward_type
  const cycleDuration = cycles[0].duration
  let studyDuration = cycles.reduce((acc, item) => {
    return acc + item.duration
  }, 0)
  const durationType = studyDuration % 7 === 0 ? 'weeks' : 'days'
  if (durationType === 'weeks') studyDuration /= 7
  const resultCycle = { target, reward, cycleDuration, studyDuration, durationType, rewardType }
  if (rewardType === REWARD_TYPE_MAP.cash) {
    if (currency) resultCycle.currency = currency
    else resultCycle.currency = 'USD'
    if (payout_type) resultCycle.payout_type = payout_type
  }
  return [resultCycle]
}

export const getDynamicCyclesInfo = cycles => {
  return cycles.map(cycle => {
    const { target, reward, currency, payout_type } = cycle
    const rewardType = cycle.reward_type
    const cycleDuration = cycle.duration
    let studyDuration = cycles.reduce((acc, item) => {
      return acc + item.duration
    }, 0)
    const durationType = studyDuration % 7 === 0 ? 'weeks' : 'days'
    if (durationType === 'weeks') studyDuration /= 7
    const resultCycle = {
      target,
      reward,
      cycleDuration,
      studyDuration,
      durationType,
      rewardType,
    }
    if (rewardType === REWARD_TYPE_MAP.cash) {
      if (currency) resultCycle.currency = currency
      else resultCycle.currency = 'USD'
      if (payout_type) resultCycle.payout_type = payout_type
    }
    return resultCycle
  })
}

export const convertLanguagesToCheckedObject = languages => {
  const output = {}
  languages.forEach(lang => {
    output[lang.key] = null
  })
  return output
}

export const generatePClassName = (studyConfig, key, disabled) => {
  if (!disabled) return ''
  /**
   * If the key type is an array, we will further key into the studyConfig
   * object to see if the result value is falsy
   */
  if (typeof key === 'object') {
    const keyArray = Object.values(key)
    let currentVal = studyConfig
    while (keyArray.length) {
      const currentKey = keyArray.shift()
      currentVal = currentVal[currentKey]
      if (!currentVal && disabled) return 'disabled'
    }
    if (currentVal && disabled) return ''
  }
  const selected = !!studyConfig[key]
  if (selected && disabled) return ''
  return 'disabled'
}

// Return current cycle's number for a not dynamic cycle study
export const calculateCycles = ({ studyDuration, cycleDuration, durationType }) => {
  const studyDurationInDays = durationType === DURATION_TYPE_MAP.weeks ? studyDuration * 7 : studyDuration
  const validDurations = studyDuration !== '' && cycleDuration !== '' && studyDurationInDays / cycleDuration >= 1
  return validDurations ? Math.ceil(studyDurationInDays / cycleDuration) : 0
}

const getRewardsObj = ({ value, quantity = 1, cost = 3 }) => ({
  value,
  quantity,
  cost,
})

const getDefaultRewardsArr = rewardArray =>
  rewardArray.map(el => getRewardsObj({ value: el[0], quantity: el[1], cost: el[2] }))

const getRewardsByChapters = (
  chapterByTwo,
  arr = [
    [10, 3],
    [15, 6],
    [20, 12],
  ],
) => {
  arr.map((val, i) => val.splice(1, 0, Math.floor(chapterByTwo * (i === 0 ? 1 / 2 : i === 1 ? 3 / 8 : 1 / 8))))
  return getDefaultRewardsArr(arr.filter(v => v[1] !== 0))
}

const getRewards = (maxAmount, chapters) => {
  let isSumOverAmount = true
  let rewards = []
  let sumRewards = 0
  while (isSumOverAmount) {
    rewards = getRewardsByChapters(chapters * 2)
    sumRewards = rewards.reduce((prevValue, currValue) => prevValue + currValue.value * currValue.quantity, 0)
    if (sumRewards > maxAmount) {
      chapters -= 1
    } else {
      isSumOverAmount = false
    }
  }
  const nextCycleDifferenceference = maxAmount - sumRewards
  if (nextCycleDifferenceference !== 0) {
    rewards.push(
      getRewardsObj({
        value: nextCycleDifferenceference,
        quantity: 1,
        cost: nextCycleDifferenceference <= 10 ? 3 : nextCycleDifferenceference <= 15 ? 6 : 12,
      }),
    )
    // if there are any extra value same as keys merge it into the key by increasing quantity
    rewards = Object.values(
      rewards.reduce((obj, item) => {
        obj[item.value] ? (obj[item.value].quantity += 1) : (obj[item.value] = { ...item })
        return obj
      }, {}),
    )
  }
  return rewards
}

/**
 * Return a default gift Card list
 * @param {Number} maxAmount payment max amount
 * @param {Number} cyclesNumber number of cycles
 */
export const generateDefaultGiftCards = (maxAmount, cyclesNumber) => {
  let giftCardList = []
  if (maxAmount !== 0 && cyclesNumber !== 0) {
    switch (true) {
      case maxAmount < 25:
        giftCardList = [getRewardsObj({ value: maxAmount })]
        break
      case maxAmount < 45:
        giftCardList = [getRewardsObj({ value: 10 }), getRewardsObj({ value: maxAmount - 10, cost: 12 })]
        break
      case maxAmount >= 45 && maxAmount <= 55:
        giftCardList = [
          getRewardsObj({ value: 10 }),
          getRewardsObj({ value: 15, cost: 6 }),
          getRewardsObj({ value: maxAmount - 25, cost: 12 }),
        ]
        break
      default:
        giftCardList = getRewards(maxAmount, cyclesNumber).sort(compareValues('value'))
        break
    }
  }
  return giftCardList
}

export const changeRewardType = (cycles, newType) => {
  return cycles.map(cycle => {
    const newCycle = {
      ...cycle,
      reward: 100,
      rewardType: newType,
    }
    if (newType !== REWARD_TYPE_MAP.cash) delete newCycle.currency
    else {
      newCycle.currency = newCycle.currency || 'USD'
    }
    return newCycle
  })
}

// Handle the requirement: End = highest rewards, Beg = medium reward
const sortGiftCardByAnotherArray = (arr, sortList = [6, 3, 12]) => {
  arr.sort((a, b) => sortList.indexOf(a.cost) - sortList.indexOf(b.cost))
  return arr
}

/**
 * Return all the gift cards so they will be the sum of all quantity
 * @param {Array} arr The giftCard array of giftCards configuration
 */
export const expandGiftCards = arr => {
  const newArr = []
  arr.forEach(item => {
    const newItem = item
    const { value, cost } = newItem
    for (let i = 0; i < item.quantity; i++) {
      newArr.push({ value, cost, id: getRandomId(), currency: 'USD', provider: 'Amazon', show_preview: true })
    }
  })
  return newArr
}

export const removeEmptyArrayOfOject = obj => {
  Object.keys(obj).map(key => {
    if (obj[key].length === 0) delete obj[key]
  })
  return obj
}

const addKeyValueToAnObj = (obj, key, value) => ({ ...obj, [key]: value })

const getObjByNumber = number => {
  const obj = {}
  for (let i = 0; i < number; i++) obj[i + 1] = []
  return obj
}

/**
 * Add 'cycle_availability' field to each object of the expanded GiftCard Array
 * @param {Array} arr Expanded Gift Card Array
 * @param {Number} cycles Number of Cycles or Chapters
 */
export const getGiftCardDistribution = (arr, cycles) => {
  const cycleKey = 'cycle_availability'
  const rewards = arr.length
  let cycleObj = getObjByNumber(cycles)
  // Allocate the highest value gc (gift card) into the last cycle.
  arr.sort(compareValues('value'))
  cycleObj[cycles].push(addKeyValueToAnObj(arr.pop(), cycleKey, cycles))

  /** Afterwards, put a medium-tier gc into the first cycle.
   * If there is no medium-tier gc remaining, put a high-tier gc in the first cycle.
   * If there is no high-tier gc remaining, put a low-tier gc into the first cycle.
   */
  if (arr.length > 0) {
    arr = sortGiftCardByAnotherArray(arr, [3, 12, 6])
    cycleObj[1].push(addKeyValueToAnObj(arr.pop(), cycleKey, 1))
  }

  // Distribute the remaining gift cards in each specific case.
  let cycleObjIndex = cycles
  let cycleLooper = 1
  arr.sort(compareValues('cost'))
  switch (true) {
    case rewards <= cycles / 2:
      cycleObjIndex -= 2
      while (cycleObjIndex > 1 && arr.length > 0) {
        cycleObj[cycleObjIndex].push(addKeyValueToAnObj(arr.pop(), cycleKey, cycleObjIndex))
        cycleObjIndex -= 2
      }
      break
    case rewards < cycles:
      cycleObjIndex -= 1
      while (cycleObjIndex > 1 && arr.length > 0) {
        cycleObj[cycleObjIndex].push(addKeyValueToAnObj(arr.pop(), cycleKey, cycleObjIndex))
        cycleObjIndex -= 1
      }
      break
    case rewards <= (cycles * 3) / 2:
    case rewards < cycles * 2:
      cycleObjIndex -= 1
      while (cycleObjIndex > 1 && arr.length > 0) {
        cycleObj[cycleObjIndex].push(addKeyValueToAnObj(arr.pop(), cycleKey, cycleObjIndex))
        if (cycleObjIndex - 1 === 1) {
          cycleObjIndex = cycles
          cycleLooper = 2
        } else {
          cycleObjIndex -= cycleLooper
        }
      }
      break
    default:
      cycleObjIndex -= 1
      let zeroOrOne = 1
      while (cycleObjIndex > 0 && arr.length > 0) {
        cycleObj[cycleObjIndex].push(addKeyValueToAnObj(arr.pop(), cycleKey, cycleObjIndex))
        if (cycleObjIndex - 1 === zeroOrOne) {
          cycleObjIndex = cycles
          zeroOrOne = 0
        } else {
          cycleObjIndex -= cycleLooper
        }
      }
      break
  }
  // if we still have gift cards to distribute in one cycle or when there might be any giftcard left.
  while (arr.length > 0) {
    cycleObj[cycles].push(addKeyValueToAnObj(arr.pop(), cycleKey, cycles))
  }
  return cycleObj
}

export const mergeArray = arr => arr.reduce((acc, val) => acc.concat(val), [])

export const HEADER_TAB_NAMES = {
  type: '1. Study Type',
  setting: '2. Participant App Setting',
  schedule: '3. Schedule & Rewards',
  review: '4. Review and Create Study',
}

export const STUDIES_ROUTE = '/studies/'
export const CREATE_STUDY_ROUTE = 'create/'

export const HEADER_TABS = {
  type: 'type',
  setting: 'setting',
  schedule: 'schedule',
  review: 'review',
}

export const CREATE_STUDY_ROUTES = {
  type: `${CREATE_STUDY_ROUTE}${HEADER_TABS.type}`,
  setting: `${CREATE_STUDY_ROUTE}${HEADER_TABS.setting}`,
  schedule: `${CREATE_STUDY_ROUTE}${HEADER_TABS.schedule}`,
  review: `${CREATE_STUDY_ROUTE}${HEADER_TABS.review}`,
}

export const CREATE_STUDY_FULL_URLS = {
  type: `${STUDIES_ROUTE}${CREATE_STUDY_ROUTES.type}`,
  setting: `${STUDIES_ROUTE}${CREATE_STUDY_ROUTES.setting}`,
  schedule: `${STUDIES_ROUTE}${CREATE_STUDY_ROUTES.schedule}`,
  review: `${STUDIES_ROUTE}${CREATE_STUDY_ROUTES.review}`,
}
