import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { buttonContentOnSendState } from 'utils/helpers'
import STRINGS from 'utils/strings'
import { MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP, MODAL_CONTENT_MAP, HOURS_IN_DAY } from 'utils/constants'
import '../../VisitTemplatePage/stylesheets/visitTemplatePage.scss'
import VisitFields from './TemplateVisitFields'
import VisiScheduleConfiguration from './VisitScheduleConfiguration/VisitScheduleConfiguration'
import VisitSchedulePreviewTable from './VisitScheduleConfiguration/VisitSchedulePreviewTable'
import { checkIfThereIsOverLap } from '../utils/visitDayUtils'
import VisitReminders from '../../VisitTemplatePage/components/VisitReminders'
import { REMINDER_PERIOD_OPTIONS } from '../../VisitTemplatePage/components/VisitReminder'

const SAVE_BUTTON_CONTENT = {
  content: STRINGS.saveVisit,
  sendingContent: STRINGS.saving,
  sentContent: STRINGS.saved,
}

export const DEFAULT_REMINDER = {
  id: 1,
  value: 1,
  period: REMINDER_PERIOD_OPTIONS[0].key,
}

function VisitTemplatePage(props) {
  const {
    isEdit,
    onDeleteVisit,
    onSaveTemplateVisit,
    openModal,
    parentScheduleId,
    saveSending,
    saveSent,
    sendDisabled,
    studyID,
    studyLock,
    subtitleName,
    templateVisitId,
    templateVisit,
    updateVisitMetadata,
  } = props
  const { visit_day, visit_metadata = { reminders: {} }, id } = templateVisit
  const [selectedVisitScheduleId, setSelectedVisitScheduleId] = useState(parentScheduleId)
  const [reminders, setReminders] = useState([DEFAULT_REMINDER])
  const [remindersEnabled, setRemindersEnabled] = useState(false)
  const [reminderNotificationsEnabled, setReminderNotificationsEnabled] = useState(true)

  useEffect(() => {
    const { reminders: _reminders } = visit_metadata
    if (_reminders && isEdit) {
      const formattedReminders = Object.keys(_reminders).map(key => {
        return {
          id: key,
          value: visit_metadata.reminders[key] / HOURS_IN_DAY,
          period: REMINDER_PERIOD_OPTIONS[0].key,
        }
      })
      if (formattedReminders.length) {
        setRemindersEnabled(true)
        setReminders(formattedReminders)
      }
    }
  }, [visit_metadata, isEdit])

  const onOpenDeleteModal = () => {
    openModal({
      onConfirm: () => {
        onDeleteVisit({ studyID, templateVisitId, visitScheduleId: selectedVisitScheduleId })
      },
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.spacious,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      content: STRINGS.deleteVisitConfirm,
      heading: MODAL_CONTENT_MAP.pleaseConfirm,
      isUrgent: true,
    })
  }

  const _onSaveVisit = () => {
    onSaveTemplateVisit({
      isUpdate: isEdit,
      studyID,
      templateVisitId,
      visitTemplateId: selectedVisitScheduleId,
    })
  }

  const onOpenSameVisitDayConfirmModal = () => {
    const ConfirmSaveVisitModalContent = () => (
      <div>
        <div className='confirmation-statement'>{STRINGS.visitSameDayPrompt}</div>
        <VisitSchedulePreviewTable
          {...props}
          selectedVisitScheduleId={selectedVisitScheduleId}
          filter={{ visitDay: visit_day }}
        />
        <p>{STRINGS.visitSameDayConfirmation}</p>
      </div>
    )

    openModal({
      onConfirm: _onSaveVisit,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.spacious,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      content: <ConfirmSaveVisitModalContent />,
      heading: MODAL_CONTENT_MAP.pleaseConfirm,
    })
  }

  const _checkIfThereIsOverLap = () => {
    const { visitScheduleInitialData } = props
    const selectedVisitDayMap = visitScheduleInitialData[selectedVisitScheduleId]?.visitDayMap
    const isOverLap = selectedVisitDayMap
      ? checkIfThereIsOverLap({ currentVisitDay: visit_day, selectedVisitDayMap, templateVisitId })
      : false
    if (isOverLap) onOpenSameVisitDayConfirmModal()
    else _onSaveVisit()
  }

  return (
    <div className='visits-template-page page'>
      <div className='flexed'>
        <h4>{subtitleName}</h4>
        {/* <Button id='top-save-as-draft' grey content='Save as Draft' onClick={() => {}} /> */}
      </div>
      <VisitFields {...props} disabled={studyLock} />
      <VisiScheduleConfiguration
        {...props}
        selectedVisitScheduleId={selectedVisitScheduleId}
        setSelectedVisitScheduleId={setSelectedVisitScheduleId}
        disabled={studyLock}
      />
      <VisitReminders
        {...props}
        reminders={reminders}
        setReminders={setReminders}
        remindersEnabled={remindersEnabled}
        setRemindersEnabled={setRemindersEnabled}
        reminderNotificationsEnabled={reminderNotificationsEnabled}
        setReminderNotificationsEnabled={setReminderNotificationsEnabled}
        updateVisitMetadata={updateVisitMetadata}
        disabled={!!id || studyLock}
      />
      <div className='button-list'>
        {/* <Button grey id='bottom-save-as-draft' content='Save as Draft' onClick={() => {}} /> */}
        <Button
          disabled={studyLock || sendDisabled || !selectedVisitScheduleId}
          id='save-visit'
          className='deploy-button'
          content={buttonContentOnSendState(SAVE_BUTTON_CONTENT, saveSending, saveSent)}
          loading={saveSending}
          onClick={_checkIfThereIsOverLap}
        />
        {isEdit && (
          <Button
            red
            disabled={!selectedVisitScheduleId || studyLock || sendDisabled}
            id='delete-visit'
            isThrottled
            content={STRINGS.deleteVisit}
            onClick={onOpenDeleteModal}
          />
        )}
      </div>
    </div>
  )
}

VisitTemplatePage.propTypes = {
  visitScheduleInitialData: PropTypes.shape({ visitDayMap: PropTypes.objectOf(PropTypes.number) }),
  isEdit: PropTypes.bool,
  onDeleteVisit: PropTypes.func,
  onSaveTemplateVisit: PropTypes.func,
  openModal: PropTypes.func,
  parentScheduleId: PropTypes.string,
  saveSending: PropTypes.bool,
  saveSent: PropTypes.bool,
  sendDisabled: PropTypes.bool,
  studyID: PropTypes.number,
  studyLock: PropTypes.bool,
  subtitleName: PropTypes.string,
  templateVisit: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    visit_day: PropTypes.number,
    visit_metadata: PropTypes.object,
  }),
  templateVisitId: PropTypes.string,
  updateVisitMetadata: PropTypes.func,
}

export default VisitTemplatePage
