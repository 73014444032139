import { connect } from 'react-redux'
import MediaUpload from '../components/MediaUpload'

const mapStateToProps = (state, ownProps) => {
  const { loading } = state
  const { mediaUpload: mediaUploadLoading } = loading
  return {
    mediaUploadLoading,
    ...ownProps,
  }
}

export default connect(mapStateToProps, null)(MediaUpload)
