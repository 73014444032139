export const SET_LOCKED = 'SET_LOCKED'

export const setStudyLock = isFlag => {
  return {
    type: SET_LOCKED,
    isLocked: !!isFlag,
  }
}

const studyLocked = (state = false, action) => {
  switch (action.type) {
    case SET_LOCKED:
      return action.isLocked
    default:
      return state
  }
}

export const studyLockActions = {
  setStudyLock,
}

export default studyLocked
