import moment from 'moment'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { uniqueArr } from 'utils/misc'
import { DATE_FORMAT_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'

const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const passCustomValidation = ({ val = '', customValidation }) => {
  const lastSlash = customValidation.lastIndexOf('/')
  const regex = new RegExp(customValidation.slice(1, lastSlash), customValidation.slice(lastSlash + 1))
  return val.match(regex)
}

function getNameError({ val, customValidation }) {
  if (customValidation && !passCustomValidation({ val, customValidation })) {
    return STRINGS.participantValidationValidName
  }
  if (!val || val.length === 0) {
    return STRINGS.participantValidationName
  }
  return null
}

export function getEmailError({ val, customValidation }) {
  if (customValidation && !passCustomValidation({ val, customValidation })) {
    return STRINGS.participantValidationEmailInvalid
  }
  if (!val || val.length === 0) {
    return STRINGS.participantValidationEmail
  }
  if (REGEX_EMAIL.test(val)) {
    return null
  }
  return STRINGS.participantValidationEmailInvalid
}

export const getPhoneError = ({ val, required, customValidation }) => {
  if (customValidation && !passCustomValidation({ val, customValidation })) {
    return STRINGS.participantValidationPhoneValid
  }
  if (!required) {
    return null
  }
  if (!val && required) {
    return STRINGS.participantValidationPhoneEmpty
  }
  if (!!val && isValidPhoneNumber(val)) return null
  return STRINGS.participantValidationPhoneValid
}

export const getSiteError = ({ val, type = 'participant' }) => {
  return val?.length < 1 ? `Please assign the ${type} to a site` : null
}

/**
 * Checks that dob object is not empty, and is not a future date
 * @param {Moment} dob
 */
function getDobError({ val, customValidation }) {
  if (customValidation && !passCustomValidation({ val, customValidation })) {
    return STRINGS.participantValidationDateOfBirthValid
  }
  if (!val || typeof val === 'undefined' || moment(val).format(DATE_FORMAT_MAP.datePicker) !== val) {
    return STRINGS.participantValidationDateOfBirthValid
  }
  if (moment().isBefore(val)) {
    return STRINGS.participantValidationDateOfBirthFuture
  }
  return null
}

function getVsdError({ val, ptpData: { start_visit_id } }) {
  if (!val || typeof val === 'undefined') return STRINGS.participantValidationVisitDateValid
  if (moment().isAfter(val, 'day')) return STRINGS.participantValidationVisitDatePast
  if (start_visit_id) {
    if (!start_visit_id || typeof start_visit_id === 'undefined') return STRINGS.participantValidationVisitNameValid
  }
  return null
}

function getVsIdError({ val, ptpData: { visit_start_datetime } }) {
  if (!visit_start_datetime || typeof visit_start_datetime === 'undefined')
    return STRINGS.participantValidationVisitDateValid
  if (!val || typeof val === 'undefined') return STRINGS.participantValidationVisitNameValid
  return null
}

function getSubjectIdError({ val, customValidation }) {
  if (customValidation && !passCustomValidation({ val, customValidation })) {
    return STRINGS.participantValidationValidSubjectId
  }
  if (!val) return STRINGS.participantValidationSubjectId
  return null
}

function getLocaleError({ val }) {
  if (!val || val.length === 0) {
    return STRINGS.participantValidationLocale
  }
  return null
}
function getTrackError({ val }) {
  if (!val || val.length === 0) {
    return STRINGS.participantValidationTrack
  }
  return null
}

const VALIDATION_FUNCTION_MAP = {
  fname: getNameError,
  lname: getNameError,
  email: getEmailError,
  phone: getPhoneError,
  date_of_birth: getDobError,
  site_id: getSiteError,
  visit_start_datetime: getVsdError,
  start_visit_id: getVsIdError,
  subject_id: getSubjectIdError,
  default_locale: getLocaleError,
  study_groups: getTrackError,
}

export function getParticipantErrors({ participantData, mandatoryValidations = [], customValidationsMap }) {
  const ptpFieldsWithValsArr = Object.keys(participantData).filter(field => participantData[field])
  const fieldsToValidate = uniqueArr([...mandatoryValidations, ...ptpFieldsWithValsArr])
  return fieldsToValidate
    .map(fieldToValidate => {
      const args = {}
      args.val = participantData[fieldToValidate]

      if (fieldToValidate === 'visit_start_datetime' || fieldToValidate === 'start_visit_id') {
        args.ptpData = participantData
      }

      if (customValidationsMap[fieldToValidate]) args.customValidation = customValidationsMap[fieldToValidate]
      return VALIDATION_FUNCTION_MAP[fieldToValidate].apply(this, [args])
    })
    .filter(e => e !== null)
}

export const isPtpInUncompletedConsentBlockList = (ptpList, consentBlockList) =>
  Object.values(consentBlockList)
    .flat()
    .some(ptp => ptpList.includes(ptp))
