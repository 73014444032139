import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button, Container, Loader, Dropdown, SortableTable } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { GROUP_TYPE_MAP } from 'utils/constants'
import '../stylesheets/MoveParticipant.scss'
import ParticipantFilter from '../../../../Instruments/routes/InstrumentsPage/components/DiaryDataDownload/ParticipantFilter'
import ParticipantProperties from './ParticipantProperties'
import RolloverTrackSelector from './RolloverTrackSelector'

export const KEY_NAME_MAP = {
  name: 'Name',
  subjectId: 'Subject ID',
  firstLogin: 'First Login',
  checkbox: 'Remove',
}

const RESEND_INVITE = 'Resend Invite'

const headerOptions = Object.keys(KEY_NAME_MAP).map(key => {
  return { key, sortable: !(key === 'checkbox'), component: KEY_NAME_MAP[key] }
})

const parseParticipantList = (ptpList, loadingState, resultProps) => {
  const { successfulMoves, failedMoves } = resultProps
  if (ptpList.length === 0) return []
  const sampleRow = ptpList[0]
  const neededValues = Object.keys(KEY_NAME_MAP)
  const neededValIndexArr = neededValues.map(neededVal => {
    return sampleRow.findIndex(cell => cell.key === neededVal)
  })

  /**
   * adds a flag to determine whether to allow removal of selected participants,
   * If there is only one selected participant, we don't render the remove "X" button
   * in the Remove column. We will add this flag to the 'checkbox' cell info.
   */
  const canRemove = ptpList.length > 1

  return ptpList.map(ptpRow => {
    return neededValIndexArr.map(neededIndex => {
      const resultRow = ptpRow[neededIndex]
      if (resultRow.key === 'checkbox') {
        // boolean for allow deselection of participant
        resultRow.canRemove = canRemove

        const ptpId = resultRow.value
        if (loadingState[`ptpMove${ptpId}`]) {
          resultRow.loading = true
        } else {
          resultRow.loading = false
        }
        if (successfulMoves[ptpId]) {
          resultRow.success = true
        }
        if (failedMoves[ptpId]) {
          resultRow.failed = true
        }
      }
      return resultRow
    })
  })
}

const MoveParticipant = props => {
  const {
    checkedPtps,
    loading,
    participantList,
    participantSelectionList,
    rolloverParticipants,
    setNavBarProps,
    studies,
    sitesAndTracksByStudies,
    studyID,
    studyName,
    togglePtp,
    togglePtpOff,
  } = props
  const { moveParticipants: moveParticipantsLoading } = loading

  const [nextStudyID, setNextStudyID] = useState(null)
  const [nextSiteID, setNextSiteID] = useState(null)
  const [successfulMoves, setSuccessfulMoves] = useState({})
  const [failedMoves, setFailedMoves] = useState({})
  const [sitesDisabled, disableSites] = useState(false)
  const [checkedTracks, checkTrack] = useState({})
  const [tags, setTags] = useState([])
  const backUrl = `/studies/${studyID}/participants`

  useEffect(() => {
    setNavBarProps({
      close: backUrl,
      closeText: 'Close',
      backText: 'Participants',
      back: backUrl,
    })
    return () => setNavBarProps(null)
  }, [])

  useEffect(() => {
    if (nextStudyID) {
      const sitesArr = sitesAndTracksByStudies[nextStudyID].sites
      if (sitesArr.length == 1) {
        const { site_id } = sitesArr[0]
        setNextSiteID(site_id)
        disableSites(true)
      }
    }
    return () => {
      disableSites(false)
      setNextSiteID(null)
      checkTrack({})
    }
  }, [nextStudyID])

  const _successfulMoves = {}
  const _failedMoves = {}
  const addToSuccessfulMoves = ptpId => {
    _successfulMoves[ptpId] = true
    setSuccessfulMoves(_successfulMoves)
  }
  const addToFailedMoves = ptpId => {
    _failedMoves[ptpId] = true
    setFailedMoves(_failedMoves)
  }

  const resultProps = { successfulMoves, failedMoves, addToSuccessfulMoves, addToFailedMoves }

  const selectedParticipants = Object.keys(checkedPtps)

  const dropdownOptions = studies.map(({ id, platform_name }) => ({ key: id, value: id, text: platform_name }))
  const sitesDropDownOptions =
    nextStudyID && sitesAndTracksByStudies[nextStudyID]
      ? sitesAndTracksByStudies[nextStudyID].sites.map(({ site_id, site_label }) => ({
          key: site_id,
          value: site_id,
          text: site_label,
        }))
      : []

  const trackList = nextStudyID
    ? sitesAndTracksByStudies[nextStudyID].tracks.map(({ id, group_name }) => ({ key: id, text: group_name }))
    : []

  const selectedParticipantList = participantList.filter(participantRow => {
    const ptpIdIndex = participantRow.findIndex(categoryCell => categoryCell.key === 'id')
    return selectedParticipants.includes(`${participantRow[ptpIdIndex].value}`)
  })

  const list = parseParticipantList(selectedParticipantList, loading, resultProps)

  const renderFirstLoginCell = ({ value, idx }) => {
    let content = <span>{value}</span>
    if (value.includes(RESEND_INVITE)) {
      const firstSection = value.split(RESEND_INVITE)[0]
      content = <div>{firstSection}</div>
    }
    return (
      <td key={`col_${idx}`} className='first-login-row'>
        {content}
      </td>
    )
  }

  const _togglePtp = (id, ptpSelectionList) => {
    const selectedPtp = ptpSelectionList.filter(({ key }) => key == id)[0]
    const { path, treeLevel } = selectedPtp
    if (selectedPtp) {
      ptpSelectionList.forEach(ptpItem => {
        if (ptpItem.path.includes(`${path}.`) || (path.includes(ptpItem.path) && treeLevel !== ptpItem.treeLevel)) {
          togglePtpOff(ptpItem.key)
        }
      })
    }
    togglePtp(id, { id, path, treeLevel })
  }

  const renderRemoveCell = ({ value, idx, siteID, canRemove, loading, success, failed, ptpSelectionList }) => {
    return (
      <td className='remove-cell' key={`col_${idx}`}>
        <div className='flexed end-justified'>
          {loading ? (
            <Loader inContainer size={20} />
          ) : (
            <div>
              {success && <i className='fas fa-check' />}
              {failed && <i className='failed fas fa-times' />}
              {!success && !failed && canRemove && (
                <Button
                  noStyling
                  className='fas fa-times'
                  onClick={() => {
                    _togglePtp(value, ptpSelectionList)
                  }}
                />
              )}
            </div>
          )}
        </div>
      </td>
    )
  }

  const ptpTableRenderFunctions = {
    firstLogin: renderFirstLoginCell,
    checkbox: renderRemoveCell,
  }

  const studyHasSuperGems = studies.filter(({ id }) => nextStudyID === id).some(study => !!study?.config?.super_gems)

  const nextStudy = studies.find(({ id }) => id === nextStudyID)

  const hasSiteNotes = nextStudyID && sitesDropDownOptions.length == 1
  const hasTrackNotes = nextStudyID && trackList.length < 1
  const hasMandatoryTracks =
    /**
     * TODO: When backend introduces a new configuration that indicates whether tracks are enabled in a study,
     * we can key into this flag to determine to check deeper configuration of whether track is set to mandatory.
     */

    // nextStudy?.config?.study_groups?.enabled ||
    nextStudy?.config?.participant_data_fields?.mandatory?.some(field => {
      return field?.metadata?.internal_key === GROUP_TYPE_MAP.cohorts
    })

  const mandatoryTracksSelected = !!(hasMandatoryTracks && tags.length) || !hasMandatoryTracks

  const getTracksNotes = () => {
    if (hasTrackNotes) {
      return { strongNote: 'Please note', noteText: ": The selected study doesn't have tracks." }
    }

    if (hasMandatoryTracks) {
      return { strongNote: 'Please note', noteText: ': The selected study has mandatory tracks.' }
    }

    return null
  }

  return (
    <div className='move-participants page'>
      <h4>Move to another study</h4>
      <Container>
        <div className='study-selection'>
          <p className='label-small'>From</p>
          <p className='current-study-name'>{studyName}</p>
          <p className='label-small to'>To</p>
          <Dropdown
            className={`studies${!studyHasSuperGems && nextStudyID ? ' no-super-gems' : ''}`}
            placeholder='Please select'
            options={dropdownOptions}
            onSelect={item => {
              setNextStudyID(item.key)
            }}
            selected={nextStudyID}
            notes={
              !studyHasSuperGems &&
              nextStudyID && {
                strongNote: 'Please note',
                noteText: ': The selected study does not support Super Gem.',
              }
            }
          />
          <p className='label-small site'>Site</p>
          <Dropdown
            className={`${hasSiteNotes ? 'site-notes' : ''}`}
            placeholder='No Site'
            options={sitesDropDownOptions}
            onSelect={item => {
              setNextSiteID(item.key)
            }}
            selected={nextSiteID}
            disabled={sitesDisabled}
            notes={
              hasSiteNotes
                ? {
                    strongNote: 'Please note',
                    noteText: ': The selected study has only one site.',
                  }
                : null
            }
          />
          <p className='label-small track-notes'>Tracks</p>
          <RolloverTrackSelector
            checkedTracks={checkedTracks}
            checkTrack={checkTrack}
            trackList={trackList}
            notes={getTracksNotes()}
            tags={tags}
            setTags={setTags}
          />
        </div>
      </Container>
      <h5>Participant Management</h5>
      <Container>
        <ParticipantFilter hasPtpCounter={false} placeholder={'Search participant...'} {...props} />
        <SortableTable
          headerOptions={headerOptions}
          rowList={list}
          renderFunctions={ptpTableRenderFunctions}
          sortingBy='name'
          emptyText='You do not have any participant selected'
          optionalCellProps={{ ptpSelectionList: participantSelectionList }}
          viewMoreWithTablePages={{ minRows: 10, maxRows: 10 }}
        />
      </Container>
      <h5>Participant Properties</h5>
      <Container>
        <ParticipantProperties nextStudyID={nextStudyID} studyHasSuperGems={studyHasSuperGems} {...props} />
      </Container>
      <div className='button-list'>
        <Button
          grey
          disabled={moveParticipantsLoading}
          content='Cancel'
          onClick={() => {
            browserHistory.push(backUrl)
          }}
        />
        <Button
          disabled={
            moveParticipantsLoading ||
            !nextStudyID ||
            !nextSiteID ||
            selectedParticipants.length < 1 ||
            !mandatoryTracksSelected
          }
          content={
            moveParticipantsLoading
              ? `Moving participant${pluralize(selectedParticipants.length, '', 's', false)}...`
              : 'Confirm and Move'
          }
          onClick={() => {
            rolloverParticipants({
              studyID,
              nextStudyID,
              nextSiteID,
              nextTrackIDs: Object.keys(checkedTracks).map(v => Number(v)),
              ptpArr: selectedParticipants.filter(key => checkedPtps[key].treeLevel == 3),
              redirect: backUrl,
              resultProps,
            })
          }}
          loading={moveParticipantsLoading}
        />
      </div>
    </div>
  )
}

export default MoveParticipant

MoveParticipant.propTypes = {
  checkedPtps: PropTypes.object,
  closeModal: PropTypes.func,
  loading: PropTypes.objectOf(PropTypes.bool),
  participantList: PropTypes.arrayOf(PropTypes.array),
  rolloverParticipants: PropTypes.func,
  single: PropTypes.bool,
  singlePtpId: PropTypes.number,
  siteID: PropTypes.number,
  sitesAndTracksByStudies: PropTypes.object,
  studies: PropTypes.arrayOf(PropTypes.object),
  studyID: PropTypes.number,
  studyName: PropTypes.string,
  togglePtp: PropTypes.func,
  togglePtpOff: PropTypes.func,
}
