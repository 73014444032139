import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserDoesNotHaveRole } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { loadingActions } from 'store/loader'
import { isSameUser } from 'utils/userUtils'
import { USER_ROLES_WITH_RESTRICTED_ACCESS } from 'utils/constants'
import ParticipantsPage from './containers/ParticipantsContainer'
import sitesReducer, { fetchSites } from '../../../Sites/routes/SitesPage/modules/Sites'
import userReducer, { actions as userActions } from '../../../Users/routes/CreateUser/modules/CreateUser'
import consentsReducer, { consentActions } from '../../../Consents/routes/ConsentsDashboard/modules/Consents'
import { actions } from './modules/Participants'
import { fetchStudies } from '../../../../../../StudiesPage/modules/Studies'

const { fetchUserSites } = userActions

export default store => ({
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducers(store, {
          sites: sitesReducer,
          userReducer,
          consents: consentsReducer,
        })
        const { params } = nextState
        const { studyID } = params
        const { studies, user } = store.getState()

        store.dispatch(fetchUserSites({ studyId: studyID, userId: user.id }))
        store.dispatch(fetchSites(studyID, true, false, false))
        if (isSameUser(user.id) ?? true) {
          store.dispatch(loadingActions.startLoader(true))
          if (studies.length === 0) {
            store.dispatch(fetchStudies(false))
          }
          Promise.all([
            store.dispatch(actions.fetchPtpsAndCohorts(studyID)),
            store.dispatch(consentActions.fetchBlockedConsents(studyID)),
          ]).then(() => {
            store.dispatch(loadingActions.stopLoader(true))
          })
        }
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserHasScope(
                ['rp', 'rp-no-pii'],
                UserDoesNotHaveRole(USER_ROLES_WITH_RESTRICTED_ACCESS, ParticipantsPage),
                false,
                studyID,
              ),
            ),
          ),
        )
      },
      'participants',
    )
  },
})
