import React, { useState } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button, Container, Loader } from 'components/UIElements'
import PDFViewer from './PDFViewer'
import '../stylesheets/datacorrection.scss'

const DCF_STRINGS = {
  intro:
    'When we receive the form, the Datacubed Health team will analyze submissions for data correction, and request more details for the ticket, if needed.',
  buttonNotExpanded: 'More about the data correction process',
  buttonExpanded: 'Less about the data correction process',
  descriptionList: [
    'Datacubed Health will send the request and an impact assessment to the Client submitter.',
    'The Client will verify that the appropriate authorizations are received internally, and Client approver(s) will provide signoff for Datacubed Health to make the requested change(s).',
    'Datacubed Health team will make the approved change(s).',
    'Datacubed Health team will confirm with key stakeholders (team and original requestor of the data change) that the data change process is completed. The requests will be stored in Box under the Project Management and Client-specific folder.',
  ],
  successHeader: 'Success! Form has been sent to support@datacubed.com',
  successBody:
    'You’ll receive an email confirmation shortly, and a member of our Support team will contact you within 48 business hours with the next steps. If you have any questions, please feel free to email support@datacubed.com.',
  backButton: 'Fill out another Data Correction Form',
}

const ListRow = ({ content, number }) => {
  return (
    <div className='bullet flexed start-justified start-aligned'>
      <div className='counter flexed center-justified center-aligned'>{number}</div>
      <p>{content}</p>
    </div>
  )
}

const Description = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Container>
      <p>{DCF_STRINGS.intro}</p>
      <Button
        className='expand'
        content={isExpanded ? DCF_STRINGS.buttonExpanded : DCF_STRINGS.buttonNotExpanded}
        link
        onClick={() => setIsExpanded(!isExpanded)}
      />
      {isExpanded &&
        DCF_STRINGS.descriptionList.map((string, idx) => {
          return <ListRow number={idx + 1} content={string} key={idx} />
        })}
    </Container>
  )
}

const SuccessScreen = ({ redirectUrl }) => {
  return (
    <Container>
      <div className='flexed start-justified'>
        <i className='fas fa-check-circle' />
        <h5>{DCF_STRINGS.successHeader}</h5>
      </div>
      <p>{DCF_STRINGS.successBody}</p>
      <Button
        className='redirect'
        content={DCF_STRINGS.backButton}
        link
        onClick={() => browserHistory.push(redirectUrl)}
        iconBefore='fas fa-arrow-left'
      />
    </Container>
  )
}

const DataCorrectionPage = ({ dataCorrection, loading, params, uploadDataCorrectionForm }) => {
  const [onSubmit, setOnSubmit] = useState(() => () => {})
  const { isFormSent, path } = dataCorrection
  const { studyID, siteID } = params

  return (
    <div className='data-correction page'>
      {isFormSent ? (
        <SuccessScreen redirectUrl={`/studies/${studyID}/data-correction`} />
      ) : (
        <>
          <Description />
          <PDFViewer
            path={path}
            studyId={studyID}
            setSubmit={func => {
              setOnSubmit(() => func)
            }}
            uploadDataCorrectionForm={uploadDataCorrectionForm}
          />
          <div className='button-list'>
            {loading && (
              <div className='dcf-loader flexed column'>
                <Loader inContainer size={25} />
              </div>
            )}
            <Button id='submit-pdf' content='Submit PDF' onClick={onSubmit} disabled={loading} />
          </div>
        </>
      )}
    </div>
  )
}

ListRow.propTypes = {
  content: PropTypes.string,
  number: PropTypes.number,
}

SuccessScreen.propTypes = {
  redirectUrl: PropTypes.string,
}

DataCorrectionPage.propTypes = {
  dataCorrection: PropTypes.shape({
    path: PropTypes.string,
    isFormSent: PropTypes.bool,
  }),
  loading: PropTypes.bool,
  params: PropTypes.shape({
    studyID: PropTypes.string,
    siteID: PropTypes.string,
  }),
  uploadDataCorrectionForm: PropTypes.func,
}

export default DataCorrectionPage
