import React from 'react'
import { SortableTable, Button } from 'components/UIElements'
import { browserHistory } from 'react-router'
import { TYPE_MAP } from './NotificationTypes'
import { formatStringForID } from 'utils/misc'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION } from 'utils/constants'

class AutoNotifications extends React.Component {
  constructor(props) {
    super(props)
    this.headerOptions = [
      { key: 'notification_event', sortable: false, component: 'Notification Event' },
      { key: 'trigger', sortable: false, component: 'Trigger' },
      { key: 'control', sortable: false, component: 'Control' },
    ]
    this.renderFunctions = {
      notification_event: this.renderEventLink.bind(this),
    }
  }

  renderEventLink({ className, eventKey, value }) {
    const { studyID, canEditNotifications } = this.props
    return (
      <td className={className} key={eventKey}>
        <Button
          disabled={!canEditNotifications}
          link
          id={formatStringForID(value)}
          content={value}
          onClick={() => {
            browserHistory.push(`/studies/${studyID}/notifications/automated/${eventKey}`)
          }}
        />
      </td>
    )
  }

  getRowData(key, info, item, triggerValue, className) {
    return [
      { key: 'notification_event', value: info.text, eventKey: key, className },
      { key: 'trigger', value: info.getTriggerText(triggerValue), className },
      { key: 'control', value: item.enabled ? 'On' : 'Off', className },
    ]
  }

  getFilteredTypeMap = map => {
    const { instrumentArchitectureVersion } = this.props
    const filteredMap = { ...map }

    if (instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION) {
      delete filteredMap.new_cycle
      delete filteredMap.data_sync
    }

    return filteredMap
  }

  getTableList = () => {
    const { autoNotifications } = this.props
    const filteredAutoNotifications = this.getFilteredTypeMap(autoNotifications)
    const list = Object.keys(filteredAutoNotifications).map(key => {
      const item = filteredAutoNotifications[key]
      const info = this.getFilteredTypeMap(TYPE_MAP)[key]
      const intervalKey = key === 'periodic' ? 'interval_days' : 'interval_hours'
      return this.getRowData(key, info, item, item[intervalKey], item.fixed ? 'fixed-notification' : '')
    })
    return list
  }

  render() {
    return (
      <div id='automated-app-notifications-section' className='notifications-section'>
        <h6>Automated App Notifications</h6>
        <SortableTable
          className='automated'
          headerOptions={this.headerOptions}
          rowList={this.getTableList()}
          renderFunctions={this.renderFunctions}
        />
      </div>
    )
  }
}

export default AutoNotifications
