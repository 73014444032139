import React from 'react'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { Button } from 'components/UIElements'
import DiaryItemContainer from '../containers/DiaryItemContainer'

const ItemList = SortableContainer(props => {
  const { diary, onSave, errors, onAddItem } = props
  const order = diary.order[0]
  const itemIndex = order.length - 1
  const { domains = {}, questionsWithScoring } = diary
  const nextPage = Object.keys(questionsWithScoring).length && Object.keys(domains).length ? 'scoring' : 'properties'

  return (
    <div className='item-list'>
      <p className='survey-error'>{order.length === 0 ? errors.noQuestionsErr : ''}</p>
      {order.map((itemId, index) => {
        if (index === 0) {
          return (
            <Item
              key={`survey-item-${itemId}-${index}`}
              index={index}
              instrumentDomains={domains}
              itemIndex={index}
              itemId={itemId}
              hideHoverBar={index === order.length - 1}
              {...props}
            />
          )
        }
        return (
          <SortableItem
            key={`survey-item-${itemId}-${index}`}
            index={index}
            instrumentDomains={domains}
            itemIndex={index}
            itemId={itemId}
            hideHoverBar={index === order.length - 1}
            {...props}
          />
        )
      })}
      <div className='survey-buttons flexed'>
        <div>
          <Button grey content='Text' icon='fas fa-plus' onClick={onAddItem('prompt', itemIndex)} />
          <Button grey content='Question' icon='fas fa-plus' onClick={onAddItem('select_one', itemIndex)} />
          {/* <Button link content='Introduction' icon='fas fa-plus' onClick={onAddItem('introduction', -1)} /> */}
        </div>
      </div>
      <div className='button-list'>
        <Button content='Next' onClick={() => onSave(nextPage)} />
      </div>
    </div>
  )
})

const SortableItem = SortableElement(props => {
  return <DiaryItemContainer {...props} />
})

const Item = props => {
  return <DiaryItemContainer {...props} />
}

export default ItemList
