import React from 'react'
import PropTypes from 'prop-types'
import { Textarea } from 'components/UIElements'
import '../../stylesheets/CommentsPanel.scss'

const CommentsPanel = ({ comments, onChange }) => {
  return (
    <div className='comments panel'>
      <h5 className=' label-small med-font'>COMMENTS</h5>
      <p>Comments will be shown per question in the form</p>
      <Textarea placeholder='No comment' value={comments} onChange={onChange} />
    </div>
  )
}

CommentsPanel.propTypes = {
  comments: PropTypes.string,
  onChange: PropTypes.func,
}

export default CommentsPanel
