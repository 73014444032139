import React from 'react'
import Dropdown from './Dropdown'
import Popup from './Popup'
import 'styles/d3/selector.scss'

export default props => {
  const _className = 'd3-selector' + (props.className ? ` ${props.className}` : '')
  return (
    <Dropdown
      className={_className}
      renderFunction={(item, props) => {
        return (
          <div className='d3-selector'>
            <span className='select-circle' />
            <span className='select-text'>{item.text}</span>
            {props.infoMap && (
              <Popup hover position='right' trigger={<i className='fas fa-info-circle' />}>
                <div>
                  <h4>{props.infoMap[item.key].header}</h4>
                  <p>{props.infoMap[item.key].content}</p>
                </div>
              </Popup>
            )}
          </div>
        )
      }}
      {...props}
    />
  )
}
