import { useEffect, useState } from 'react'
import { useFlags, useFlagsmith } from 'flagsmith/react'

const { FLAGSMITH_ENV = 'dev' } = process.env

const generateFlagsmithIdentity = ({ userId }) => {
  return `${FLAGSMITH_ENV}_admin_${userId}`
}

const generateStudyIdTrait = studyId => {
  return `${FLAGSMITH_ENV}_${studyId || 'no_study'}`
}

const useFlagsmithFeature = ({ featureFlags = {}, studyId = '', userId }) => {
  const flagsmith = useFlagsmith()
  const [flagsmithErr, setFlagsmithErr] = useState(null)

  useEffect(() => {
    const studyIdTrait = generateStudyIdTrait(studyId)
    flagsmith.setTrait('study_id', studyIdTrait)
  }, [studyId])

  useEffect(() => {
    const identity = generateFlagsmithIdentity({ userId })
    const onFlagsmithError = e => {
      setFlagsmithErr(e)
    }
    flagsmith.onError = onFlagsmithError
    flagsmith.identify(identity)
  }, [userId])
  const flagsArr = Object.keys(featureFlags).map(flag => {
    return [flag]
  })
  /**
   * If there is an error with Flagsmith, return what was passed as the default
   * flags as a parameter.
   */
  const features = useFlags(...flagsArr)
  if (flagsmithErr) return featureFlags
  return features
}

export default useFlagsmithFeature
