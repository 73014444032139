import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { AutocompleteGroupSearch, Loader } from 'components/UIElements'
import { DEPLOYMENT_KEYS } from 'utils/constants'
import { setObjectProperty } from 'utils/object'
import STRINGS from 'utils/strings'

const getScheduleAndVisitName = ({ visit_id, visit_schedule_id, visitTemplates }) => {
  const currentVisitSchedule = visitTemplates.reduce((_, currentTemplate) => {
    if (currentTemplate.id === visit_schedule_id) return currentTemplate
    return _
  }, {})
  const _visitScheduleName = currentVisitSchedule.template_name
  const currentVisit = currentVisitSchedule?.visits?.reduce((_, nextVisit) => {
    if (nextVisit.id === visit_id) return nextVisit
    return _
  }, {})

  return { _visitScheduleName, _visitName: currentVisit?.name }
}

const InstrumentVisitSelection = props => {
  const {
    chain_deployment_info,
    clearPrevalidatedError,
    disabled,
    errors,
    fetchVisitTemplates,
    studyID,
    updateChainDeployInfo,
    updatePrevalidatedErrors,
    visitTemplates,
  } = props

  const [visitTemplatesLoading, setVisitTemplatesLoading] = useState(false)
  const [checkedVisitTemplate, setCheckedVisitTemplate] = useState({})
  const [visitName, setVisitName] = useState('')

  const { visit_id, visit_schedule_id } = chain_deployment_info

  const visitTemplatesList = useMemo(
    () =>
      visitTemplates.map(({ id: tid, template_name, visits }) => ({
        field: tid,
        label: template_name,
        value: visits.map(({ id, name }) => ({
          key: id,
          text: name,
          value: id,
        })),
      })),
    [visitTemplates],
  )

  const hasVisitsInTemplates = useMemo(
    () =>
      visitTemplatesList.some(visitTemplate => {
        return !!visitTemplate.value.length
      }),
    [visitTemplatesList],
  )

  useEffect(() => {
    if (!hasVisitsInTemplates) {
      updatePrevalidatedErrors({ noVisits: '' })
    } else {
      clearPrevalidatedError('noVisits')
    }
  }, [hasVisitsInTemplates])

  useEffect(() => {
    if (!visitName) {
      updatePrevalidatedErrors({ noVisit: '' })
    } else {
      clearPrevalidatedError('noVisit')
    }
  }, [visitName])

  useEffect(() => {
    let visitsNotFetched = true
    setVisitTemplatesLoading(true)
    fetchVisitTemplates(studyID).finally(() => {
      if (visitsNotFetched) setVisitTemplatesLoading(false)
    })
    return () => {
      visitsNotFetched = false
      clearPrevalidatedError('noVisits')
      clearPrevalidatedError('noVisit')
    }
  }, [])

  useEffect(() => {
    const { _visitScheduleName, _visitName } = getScheduleAndVisitName({ visit_id, visit_schedule_id, visitTemplates })
    if (visit_id && _visitName) setVisitName(`${_visitScheduleName}: ${_visitName}`)
  }, [visitTemplates])

  useEffect(() => {
    setCheckedVisitTemplate(setObjectProperty({}, `${visit_schedule_id}.${visit_id}`, visit_id))
  }, [visit_id, visit_schedule_id])

  const onToggleVisit = (item, group) => {
    updateChainDeployInfo({
      ...chain_deployment_info,
      [DEPLOYMENT_KEYS.visitId]: item.key,
      [DEPLOYMENT_KEYS.visitScheduleId]: item.field,
    })
    setVisitName(`${group.label}: ${item.text}`)
  }

  const errorText = !hasVisitsInTemplates
    ? STRINGS.errPleaseASelectDiffDeployNoVisits
    : !visitName
    ? STRINGS.pleaseSelDifferentVisit
    : errors['schedule.chain_deployment_info.visit_id']
  const hasError = (!visit_id && !!errorText) || !hasVisitsInTemplates || !visitName

  if (visitTemplatesLoading) return <Loader inContainer size={25} />
  return (
    <AutocompleteGroupSearch
      hasError={hasError}
      errorText={errorText}
      absolutePositionedList
      list={visitTemplatesList || []}
      toggleItem={onToggleVisit}
      checked={checkedVisitTemplate}
      placeholder={STRINGS.selectPlaceholder}
      value={!hasVisitsInTemplates ? STRINGS.selectPlaceholder : visitName}
      disabled={disabled || !hasVisitsInTemplates}
    />
  )
}

InstrumentVisitSelection.propTypes = {
  chain_deployment_info: PropTypes.shape({
    visit_id: PropTypes.string,
    visit_schedule_id: PropTypes.string,
  }),
  clearPrevalidatedError: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.string),
  fetchVisitTemplates: PropTypes.func,
  studyID: PropTypes.number,
  updateChainDeployInfo: PropTypes.func,
  updatePrevalidatedErrors: PropTypes.func,
  visitTemplates: PropTypes.arrayOf(PropTypes.object),
}

export default InstrumentVisitSelection
