// NOTE TO DCH: At one point I thought this was needed for the react-day-picker
// library. Turns out it wasn't, but I left it here in case you wanted to use it.
// It's an easy way for moment to display Chinese-formatted dates throughout the
// app.
//import { CHINA_DEPLOY } from './constants'
//
//(async () => {
//  if (CHINA_DEPLOY) {
//    // Importing this sets all moment instances to the Chinese locale. This
//    // means that everywhere moment is used in the app it will be configured
//    // to display datetimes in Chinese.
//    //await import('moment/locale/zh-cn')
//  }
//})();

const generateLocalesMap = localesArr => {
  const result = {}
  localesArr.forEach(localeObj => {
    const { id, text } = localeObj
    result[id] = text
  })
  return result
}

export default generateLocalesMap
