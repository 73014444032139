import { injectReducers } from 'store/reducers'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope, UserHasFeatures } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import cohortReducer from './modules/cohort'
import { actions as ptpActions } from '../ParticipantsPage/modules/Participants'
import sitesReducer from '../../../Sites/routes/SitesPage/modules/Sites'
import CohortContainer from './containers/CohortContainer'

const disabledFeatures = [{ name: 'tracks', props: ['disabled'] }]

// Sync route definition
export default store => ({
  path: 'tracks/create',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        injectReducers(store, {
          sites: sitesReducer,
          cohort: cohortReducer,
        })

        const { params } = nextState
        const { studyID } = params

        store.dispatch(ptpActions.fetchPtpsAndCohorts(studyID))

        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(
                studyID,
                'participants',
              )(
                UserHasScope(
                  ['wxc'],
                  UserHasFeatures(
                    CohortContainer,
                    disabledFeatures,
                    'participants', // redirectSubroute,
                    false, // isNull
                    true, // every
                    true, // redirectIfEnabled
                    true, // nullMeansEnabled
                  ),
                  false,
                  studyID,
                ),
              ),
            ),
          ),
        )
      },
      '/tracks/create',
    )
  },
})
