import { STUDY_PAGINATION_SETTINGS_KEY } from './constants'

export const checkIsEmailless = (currentStudy = {}) => {
  const participantDataFields = currentStudy.config?.participant_data_fields || {}
  return participantDataFields?.disabled?.some(field => field.type === 'email')
}

export const persistStudyPaginationSettings = (userId, settings) => {
  if (!userId) {
    return
  }
  localStorage.setItem(`${STUDY_PAGINATION_SETTINGS_KEY}_${userId}`, JSON.stringify(settings))
}

export const retrieveStudyPaginationSettings = userId => {
  if (!userId) {
    return
  }
  return JSON.parse(localStorage.getItem(`${STUDY_PAGINATION_SETTINGS_KEY}_${userId}`) || null)
}

export const removeStudyPaginationSettings = key => {
  if (!key) {
    return
  }
  localStorage.removeItem(key)
}

export const clearStudyPaginationSettings = () => {
  const allItems = { ...localStorage }
  const studyPaginationKeys = Object.keys(allItems).filter(key => key.includes(STUDY_PAGINATION_SETTINGS_KEY))
  studyPaginationKeys.forEach(key => {
    removeStudyPaginationSettings(key)
  })
}
