import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { pluralize } from 'utils/misc'
import { DATE_FORMAT_MAP, HOURS_IN_DAY, MINUTES_IN_HOUR } from 'utils/constants'

const getRemainTime = (remainTime) => {
  if (remainTime / HOURS_IN_DAY < 1) {
    if (remainTime < 1) {
      const minutes = Math.round(remainTime * MINUTES_IN_HOUR)
      return [minutes, pluralize(minutes, ' minute', ' minutes', false)]
    }
    const hours = Math.round(remainTime)
    return [hours, pluralize(hours, ' hour', ' hours', false)]
  }
  const days = Math.round(remainTime / HOURS_IN_DAY)
  return [days, pluralize(days, ' day', ' days', false)]
}

const DaysRemaining = ({ study, firstLogin, rolloverTimestamp }) => {
  if (!firstLogin) return null

  const { cycles } = study
  const studyDuration = cycles ? cycles[0].studyDuration * (cycles[0].durationType === 'weeks' ? 7 : 1) : 0
  const studyEndDate = cycles ? firstLogin.add(studyDuration, 'days') : ''
  const remainTime = studyEndDate ? studyEndDate.diff(moment(), 'hours', true) : ''
  const hasPassedStudyDuration = remainTime < 0
  const label = hasPassedStudyDuration ? 'PASSED STUDY DURATION BY' : 'REMAINING IN THE STUDY'
  const studyEnd = studyEndDate ? studyEndDate.format(DATE_FORMAT_MAP.main) : ''
  const [displayTime = '', displayTimeFormat = ''] = getRemainTime(Math.abs(remainTime))

  return (
    <div>
      {firstLogin && !rolloverTimestamp && (
        <div className='duration-labels study-end'>
          <p>
            <span>{label}</span>
            <b className={hasPassedStudyDuration ? 'negative' : ''}>{displayTime}</b>
            <span className='days'>{displayTimeFormat}</span>
          </p>
          <p>{studyEnd}</p>
        </div>
      )}
      {rolloverTimestamp && (
        <div className='duration-labels study-end'>
          <p>
            <span className='ptp-moved-label'>Participant moved to another study</span>
          </p>
          <p>{rolloverTimestamp}</p>
        </div>
      )}
    </div>
  )
}

DaysRemaining.propTypes = {
  study: PropTypes.shape({
    cycles: PropTypes.array,
  }),
  daysElapsed: PropTypes.number,
  rolloverTimestamp: PropTypes.string,
  firstLogin: PropTypes.shape({
    add: PropTypes.func,
  }),
}
export default DaysRemaining
