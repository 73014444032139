import { combineReducers } from 'redux'
import { updateSearchTerm } from 'store/searchTerm'
import request from 'utils/request'
import { USER_ROLES_MAP, DATE_FORMAT_MAP, BLOB_TYPE_MAP } from 'utils/constants'
import { downloadBlob } from 'utils/misc'
import { today } from 'utils/time'
import moment from 'moment'
import { loadingActions } from 'store/loader'

//
// Actions
//

export const FETCHED_USERS = 'FETCHED_USERS'
export const SET_CLINICIANS = 'SET_CLINICIANS'

//
// Action Creators
//

// Fetches the user list and maps the users that are sub-users of the currently logged in user

export const fetchUsers = (studyID, hasLoader = true) => {
  return dispatch => {
    const success = json => {
      dispatch({
        type: FETCHED_USERS,
        users: json.users,
        studyID,
      })
    }
    return dispatch(
      request({
        url: `/control/studies/${studyID}/users`,
        success,
        hasLoader,
      }),
    )
  }
}

export const fetchClinicians = (studyID, leafSiteId) => {
  return dispatch => {
    const success = json => {
      dispatch({
        type: SET_CLINICIANS,
        users: json.users,
      })
    }
    dispatch(loadingActions.startLoader(true, 'visitorUser'))
    return dispatch(
      request({
        url: `/control/studies/${studyID}/sites/${leafSiteId}/clinicians`,
        success,
      }),
    ).then(() => dispatch(loadingActions.stopLoader(true, 'visitorUser')))
  }
}

export function downloadUserData(studyID) {
  let url = `/control/studies/${studyID}/users/export`
  let name = '_user_list'

  return dispatch => {
    const success = (blob, fileName) => {
      if (blob.type === BLOB_TYPE_MAP.zip) {
        downloadBlob(blob, `study_${studyID}${name}_${today(true)}.zip`, fileName)
      } else {
        downloadBlob(blob, `study_${studyID}${name}_${today(true)}.csv`, fileName)
      }
    }
    dispatch(loadingActions.startLoader(true, 'userList'))
    return dispatch(
      request({
        url,
        resType: 'blob',
        success,
      }),
    ).then(() => {
      dispatch(loadingActions.stopLoader(true, 'userList'))
    })
  }
}

const formatUserList = (list, studyID) => {
  const filteredList = Object.values(list).filter(user => {
    return user.permissions.some(permission => permission.study_id === studyID)
  })

  return filteredList.map(({ username, last_login, nickname, user_id, permissions }) => {
    const lastLogin = last_login ? moment(last_login) : last_login
    const roleIndex = permissions.map(permission => permission.study_id).indexOf(studyID)
    let roleName = permissions[roleIndex].role_name
    const siteNames = permissions[roleIndex].site_names
    if (roleName === USER_ROLES_MAP.investigator) roleName = 'Study Coordinator / Investigator'
    return [
      { key: 'nickname', value: nickname, sortValue: nickname, id: user_id },
      { key: 'email', value: username, sortValue: username },
      { key: 'role', value: roleName, sortValue: roleName },
      { key: 'sites', value: siteNames },
      {
        key: 'lastLogin',
        value: lastLogin ? lastLogin.format(DATE_FORMAT_MAP.main) : 'Never Logged In',
        sortValue: lastLogin ? lastLogin.valueOf() : 0,
      },
    ]
  })
}

//
// Reducers
//

const list = (state = [], action) => {
  switch (action.type) {
    case FETCHED_USERS:
      return formatUserList(action.users, action.studyID)
    default:
      return state
  }
}

export default combineReducers({
  list,
})

export const actions = {
  fetchUsers,
  updateSearchTerm,
  downloadUserData,
}
