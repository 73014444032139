import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { TimePicker, Popup, Checkbox, Radio } from 'components/UIElements'
import { INPUT_VALIDATION_TYPE_MAP } from 'utils/constants'
import STRINGS from '../../../../../../../../../../../../utils/strings'
import TimePickerOverlay from './TimePickerOverlay'

const PERIOD_IDS = {
  exactRange: 'exactRange',
  allHours: 'allHours',
}

const TimeInputRange = props => {
  const { clearQuestionError, errors = {}, item, itemId, updateItem, preview } = props
  const isDefaultExactRange =
    item?.input_validation?.absolute_time?.max ||
    item?.input_validation?.absolute_time?.min ||
    item?.input_validation?.absolute_time?.has_from_participant_current_time ||
    item?.input_validation?.absolute_time?.has_to_participant_current_time
  const [periodOptionId, setPeriodOptionId] = useState(
    isDefaultExactRange ? PERIOD_IDS.exactRange : PERIOD_IDS.allHours,
  )

  const MOMENT_FORMAT = 'HH:mm'
  const hasError = errors.timeInputRangeError

  const itemWithDefaults = {
    ...item,
    input_validation: {
      accept_future_values: true,
      absolute_time: {
        has_to_participant_current_time: false,
        has_from_participant_current_time: false,
      },
      ...item.input_validation,
    },
  }

  useEffect(() => {
    // Set default input_validation object
    if (updateItem) {
      updateItem(itemId, itemWithDefaults)
    }
  }, [])

  const updateInput = (key, val) => {
    const prevInputValidation = itemWithDefaults.input_validation
    if (hasError) {
      clearQuestionError(itemId)
    }
    const newItem = {
      ...item,
      input_validation: {
        ...prevInputValidation,
        [key]: val,
      },
    }
    updateItem(itemId, newItem)
  }

  const updateTimePickerInput = (key, val, acceptFutureValues) => {
    const prevInputValidation = itemWithDefaults.input_validation
    if (hasError) {
      clearQuestionError(itemId)
    }
    const newItem = {
      ...item,
      input_validation: {
        ...prevInputValidation,
        [key]: val,
        accept_future_values: acceptFutureValues,
      },
    }
    updateItem(itemId, newItem)
  }

  const resetInputValidation = () => {
    const newItem = {
      ...item,
      input_validation: {},
    }
    updateItem(itemId, newItem)
  }

  const hasFromPtpCurrentTime = !!itemWithDefaults.input_validation.absolute_time.has_from_participant_current_time
  const hasToPtpCurrentTime = !!itemWithDefaults.input_validation.absolute_time.has_to_participant_current_time

  const periodOptions = [
    {
      id: PERIOD_IDS.exactRange,
      component: (
        <Radio
          id={PERIOD_IDS.exactRange}
          disabled={preview}
          onClick={() => {
            setPeriodOptionId(PERIOD_IDS.exactRange)
            updateInput(INPUT_VALIDATION_TYPE_MAP.absTime, {
              ...itemWithDefaults.input_validation.absolute_time,
              min: '',
              max: '',
            })
          }}
          selected={periodOptionId === PERIOD_IDS.exactRange}
          content={
            <div className='option-wrapper column'>
              <div className='option-inner'>
                <span className={preview ? 'disabled' : ''}>{STRINGS.acceptFrom}</span>
                <TimePicker
                  allowEmpty
                  use12Hours
                  disabled={preview}
                  hasError={!!hasError}
                  value={
                    itemWithDefaults.input_validation.absolute_time.min
                      ? moment(itemWithDefaults.input_validation.absolute_time.min, MOMENT_FORMAT)
                      : ''
                  }
                  onChange={val => {
                    updateTimePickerInput(
                      INPUT_VALIDATION_TYPE_MAP.absTime,
                      {
                        ...itemWithDefaults.input_validation.absolute_time,
                        min: val ? moment(val).format(MOMENT_FORMAT) : '',
                        has_to_participant_current_time: false,
                        has_from_participant_current_time: false,
                      },
                      true,
                    )
                  }}
                  addon={() => (
                    <TimePickerOverlay
                      checked={hasFromPtpCurrentTime}
                      disabled={preview}
                      onClick={() => {
                        updateTimePickerInput(
                          INPUT_VALIDATION_TYPE_MAP.absTime,
                          {
                            ...itemWithDefaults.input_validation.absolute_time,
                            has_from_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                              .has_from_participant_current_time,
                            has_to_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                              .has_from_participant_current_time,
                            min: '',
                            max: '',
                          },
                          itemWithDefaults.input_validation.absolute_time.has_from_participant_current_time,
                        )
                      }}
                      itemWithDefaults={itemWithDefaults}
                    />
                  )}
                  popupClassName='timepicker-overlay'
                  className={hasFromPtpCurrentTime ? 'time-input-range' : ''}
                  placeholder={hasFromPtpCurrentTime ? STRINGS.participantCurrentTime : ''}
                />
                <span className={preview ? 'disabled' : ''}>{STRINGS.to}</span>
                <TimePicker
                  allowEmpty
                  use12Hours
                  disabled={preview}
                  hasError={!!hasError}
                  value={
                    itemWithDefaults.input_validation.absolute_time.max
                      ? moment(itemWithDefaults.input_validation.absolute_time.max, MOMENT_FORMAT)
                      : ''
                  }
                  onChange={val => {
                    updateTimePickerInput(
                      INPUT_VALIDATION_TYPE_MAP.absTime,
                      {
                        ...itemWithDefaults.input_validation.absolute_time,
                        max: val ? moment(val).format(MOMENT_FORMAT) : '',
                        has_to_participant_current_time: false,
                        has_from_participant_current_time: false,
                      },
                      true,
                    )
                  }}
                  addon={() => (
                    <TimePickerOverlay
                      checked={hasToPtpCurrentTime}
                      disabled={preview}
                      onClick={() => {
                        updateTimePickerInput(
                          INPUT_VALIDATION_TYPE_MAP.absTime,
                          {
                            ...itemWithDefaults.input_validation.absolute_time,
                            has_to_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                              .has_to_participant_current_time,
                            has_from_participant_current_time: !itemWithDefaults.input_validation.absolute_time
                              .has_to_participant_current_time,
                            min: '',
                            max: '',
                          },
                          itemWithDefaults.input_validation.absolute_time.has_to_participant_current_time,
                        )
                      }}
                      itemWithDefaults={itemWithDefaults}
                    />
                  )}
                  popupClassName='timepicker-overlay'
                  className={hasToPtpCurrentTime ? 'time-input-range' : ''}
                  placeholder={hasToPtpCurrentTime ? STRINGS.participantCurrentTime : ''}
                />
              </div>
            </div>
          }
        />
      ),
    },
    {
      id: PERIOD_IDS.allHours,
      component: (
        <Radio
          id={PERIOD_IDS.allHours}
          disabled={preview}
          onClick={() => {
            setPeriodOptionId(PERIOD_IDS.allHours)
            updateInput(INPUT_VALIDATION_TYPE_MAP.absTime, {
              ...itemWithDefaults.input_validation.absolute_time,
              min: '',
              max: '',
              has_to_participant_current_time: false,
              has_from_participant_current_time: false,
            })
          }}
          selected={periodOptionId === PERIOD_IDS.allHours}
          content={
            <div className='option-wrapper column'>
              <div className='option-inner'>
                <span className={preview ? 'disabled' : ''}>{STRINGS.acceptAllHours}</span>
              </div>
              {periodOptionId === PERIOD_IDS.allHours ? (
                <div className='option-inner'>
                  <div className='future-dates-toggle'>
                    <Checkbox
                      disabled={!!itemWithDefaults.input_validation.absolute_time.min || preview}
                      toggle
                      checked={!!itemWithDefaults.input_validation.accept_future_values}
                      onClick={() => {
                        updateInput(
                          INPUT_VALIDATION_TYPE_MAP.acceptFutureValues,
                          !itemWithDefaults.input_validation.accept_future_values,
                        )
                      }}
                    />
                    <span className={preview ? 'disabled' : ''}>{STRINGS.acceptFutureTime}</span>
                    <Popup
                      className='future-dates-popup'
                      align={'left'}
                      position='bottom'
                      hover
                      noPointer
                      dark
                      trigger={<i className='fas fa-info-circle' />}>
                      {<p>{STRINGS.acceptFutureTimeTooltip}</p>}
                    </Popup>
                  </div>
                </div>
              ) : null}
            </div>
          }
        />
      ),
    },
  ]

  return (
    <div className={`non-select-preview date-input-range date-period-range`}>
      <p className='heading'>{STRINGS.selectTimeRange}</p>

      <div className='radio-group expanded no-margin'>
        {periodOptions.map(option => (
          <div className='radio-group-item' key={option.id}>
            {option.component}
          </div>
        ))}
      </div>
    </div>
  )
}

TimeInputRange.propTypes = {
  clearQuestionError: PropTypes.func,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  item: PropTypes.shape({
    input_validation: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.object])),
  }),
  itemId: PropTypes.string,
  updateItem: PropTypes.func,
}

export default TimeInputRange
