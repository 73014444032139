import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import { onSave } from '../../../modules/Instrument'

//
// Actions
//

const UPLOAD_SURVEY = 'UPLOAD_SURVEY'
const DISCARD_UPLOAD_SURVEY = 'DISCARD_UPLOAD_SURVEY'
const UPLOAD_SURVEY_FILENAME = 'UPLOAD_SURVEY_FILENAME'
const INITIALIZE_UPLOAD_SURVEY_PAGE = 'INITIALIZE_UPLOAD_SURVEY_PAGE'

//
// Action Creators
//

function initialize() {
  return {
    type: INITIALIZE_UPLOAD_SURVEY_PAGE,
  }
}

//
// Utils
//

function readFile(file, callback) {
  const reader = new FileReader()
  reader.onload = e => {
    callback(e.target.result)
  }
  reader.readAsText(file)
}

/**
 * Converts user-uploaded survey to json and adds it to the state
 * @param fileNode - survey in csv or xls format
 * @returns {function(*)} - dispatch action to save survey json to the state
 */
function uploadSurveyDraft(fileNode) {
  return dispatch => {
    try {
      const format = fileNode.name
        .split('.')
        .pop()
        .toLowerCase()
      if (!['json', 'csv', 'xls', 'xlsx'].includes(format)) {
        throw new Error('File format not supported')
      }
      // return readFile(fileNode, format, function (data) {
      if (format !== 'json') {
        dispatch({
          type: UPLOAD_SURVEY,
          payload: {
            data: fileNode,
            format,
          },
        })
      } else {
        readFile(fileNode, data => {
          dispatch({
            type: UPLOAD_SURVEY,
            payload: {
              data,
              format,
            },
          })
        })
      }
      dispatch({
        type: UPLOAD_SURVEY_FILENAME,
        payload: fileNode.name,
      })
      // })
    } catch (err) {}
  }
}

/**
 * Discards the uploaded file and returns to the dashboard
 * @returns {function(*)}
 */
function discardUpload(studyID, siteID, reroute = true) {
  return dispatch => {
    dispatch({
      type: DISCARD_UPLOAD_SURVEY,
    })
    if (reroute) browserHistory.push(`/studies/${studyID}/instruments`)
  }
}

//
// Reducers
//

export const uploadedSurvey = (state = null, action) => {
  switch (action.type) {
    case UPLOAD_SURVEY:
      return Object.assign({}, state, action.payload)
    case DISCARD_UPLOAD_SURVEY:
    case INITIALIZE_UPLOAD_SURVEY_PAGE:
      return null
    default:
      return state
  }
}

export const fileName = (state = '', action) => {
  switch (action.type) {
    case INITIALIZE_UPLOAD_SURVEY_PAGE:
      return ''
    case UPLOAD_SURVEY_FILENAME:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({ uploadedSurvey, fileName })

export const actions = {
  onSurveyUpload: uploadSurveyDraft,
  onDiscardUpload: (studyID, siteID, reroute) => discardUpload(studyID, siteID, reroute),
  onSave,
  initialize,
}
