import './stylesheets/ErrorBox.scss'

export default props => {
  if (props.noty) {
    props.noty
      .on('onClose', () => {
        props.closeNoty()
      })
      .show()
  }
  return null
}
