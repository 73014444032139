import React from 'react'
import PropTypes from 'prop-types'
import { RichButton } from 'components/UIElements'
import { drawSignature, photoSignature, typeSignature } from 'assets/assets'
import { SIGNATURE_TYPE_MAP } from 'utils/constants'

const DRAW_SIGNATURE_LABEL = 'Draw'
const TYPE_SIGNATURE_LABEL = 'Type'
const PHOTO_SIGNATURE_LABEL = 'Photo'

const SIGNATURE_UI_MAP = {
  [SIGNATURE_TYPE_MAP.draw]: { icon: drawSignature(), text: DRAW_SIGNATURE_LABEL },
  [SIGNATURE_TYPE_MAP.text]: { icon: typeSignature(), text: TYPE_SIGNATURE_LABEL },
  [SIGNATURE_TYPE_MAP.image]: { icon: photoSignature(), text: PHOTO_SIGNATURE_LABEL },
}

const SiteSignatureTypeConfig = ({ config, disabled, updateSiteConfig }) => {
  const { consent_2 = {} } = config
  const { digital_signatures, read_only } = consent_2

  const toggleSignatureType = type => {
    const newConfig = { ...config }
    const currentToggleState = consent_2.digital_signatures?.[type]
    if (digital_signatures) {
      newConfig.consent_2.digital_signatures[type] = !currentToggleState
    } else {
      const digitalSignatureMap = Object.keys(SIGNATURE_TYPE_MAP).reduce(
        (prevObj, currKey) => ({ ...prevObj, [currKey]: false }),
        {},
      )
      digitalSignatureMap[type] = true
      newConfig.consent_2.digital_signatures = digitalSignatureMap
    }

    delete newConfig.consent_2.read_only
    updateSiteConfig(newConfig)
  }

  const toggleReadOnly = () => {
    const newConfig = { ...config }
    newConfig.consent_2.read_only = true
    delete newConfig.consent_2.digital_signatures
    updateSiteConfig(newConfig)
  }

  const renderSignatureOption = type => {
    const signatureTypeUI = SIGNATURE_UI_MAP[type]
    return (
      <RichButton
        disabled={disabled}
        graphicElement={signatureTypeUI.icon}
        isCheckbox
        label={signatureTypeUI.text}
        labelOnTop
        selected={digital_signatures?.[type]}
        onClick={() => {
          toggleSignatureType(type)
        }}
      />
    )
  }

  const hasTypeSignatureConfigured = digital_signatures?.text

  return (
    <div className='flexed start-justified'>
      <div className='signature-options flexed start-justified '>
        {renderSignatureOption(SIGNATURE_TYPE_MAP.draw)}
        {hasTypeSignatureConfigured && renderSignatureOption(SIGNATURE_TYPE_MAP.text)}
        {renderSignatureOption(SIGNATURE_TYPE_MAP.image)}
      </div>
      <RichButton
        disabled={disabled}
        labelOnTop
        label='Read-only'
        isRadio
        selected={read_only}
        onClick={toggleReadOnly}
      />
    </div>
  )
}

SiteSignatureTypeConfig.propTypes = {
  config: PropTypes.shape({
    consent_2: PropTypes.shape({
      digital_signatures: PropTypes.object,
      read_only: PropTypes.bool,
    }),
  }),
  disabled: PropTypes.bool,
  updateSiteConfig: PropTypes.func,
}

export default SiteSignatureTypeConfig
