import React from 'react'
import { Container, Input, Checkbox, Popup, PromptBar, SubMenu, Divider, Radio } from 'components/UIElements'
import '../stylesheets/createStudy.scss'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { CHINA_DEPLOY, STUDY_CONFIG_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'
import { checkIsEmailless } from 'utils/studyConfigUtils'
import { virtualVisitsIcon, visitSupportIcon } from 'assets/assets'
import { generatePClassName, CREATE_STUDY_FULL_URLS } from '../utils/studyUtils'
import AppPushConfig from './AppPushConfig'
import StudyWizardHeader from './StudyWizardHeader'
import CreateStudyFooter from './CreateStudyFooter'
import { formatAndSanitizeHTML } from '../../../../../utils/draft'

export const EmaillessConfig = ({ disabled, isEmailless, updateStudy = () => {} }) => {
  return (
    <div className='emailless'>
      <h5>{STRINGS.emaillessPCI}</h5>
      <p
        className='emailless-text'
        dangerouslySetInnerHTML={{ __html: formatAndSanitizeHTML(STRINGS.emaillessPCIBody) }}
      />
      <Radio
        id='no-email-collection'
        disabled={disabled}
        selected={isEmailless}
        onClick={() => updateStudy('emailless', true)}
        content={STRINGS.emaillessPCIOpt1}
      />
      <Radio
        id='email-collection'
        content={STRINGS.emaillessPCIOpt2}
        selected={!isEmailless}
        disabled={disabled}
        onClick={() => updateStudy('emailless', false)}
      />
      {!isEmailless && <PromptBar className='email-note'>{STRINGS.emaillessPCINote}</PromptBar>}
    </div>
  )
}

class CreateStudyType extends React.Component {
  updateStudyConfig = newConfig => {
    const { study, updateConfig } = this.props
    const config = study.config || {}
    const _config = config || {}
    updateConfig({
      ..._config,
      ...{ ...newConfig },
    })
  }

  deleteConfigKey = key => {
    const { study, updateConfig } = this.props
    const config = study.config || {}
    if (!config) return
    const newConfig = config
    delete newConfig[key]
    updateConfig(newConfig)
  }

  render() {
    const { disabled, isRootOrDchAdmin, isWizard, saveAttempted, study, updateStudy } = this.props
    const isEmailless = checkIsEmailless(study)
    const configObj = study.config || study
    const { self_sign_code } = study
    const configProps = {
      ...this.props,
      configObj,
      deleteConfigKey: this.deleteConfigKey,
      updateConfig: this.updateStudyConfig,
    }

    const renderStudyNameInput = () => {
      return (
        <Input
          className='name'
          hasError={saveAttempted && study.platform_name === ''}
          errorText={STRINGS.cannotBeEmpty}
          label={STRINGS.studyName}
          disabled={disabled}
          value={study.platform_name}
          onChange={value => updateStudy('platform_name', value)}
          id='study-name'
          placeholder={STRINGS.studyName}
        />
      )
    }

    const renderAppNameInput = () => {
      return (
        <Input
          charLimit={60}
          className='name pos rel'
          hasError={saveAttempted && study.name === ''}
          errorText={STRINGS.cannotBeEmpty}
          label='Name displayed to participants in the app'
          disabled={disabled}
          value={study.name}
          onChange={value => updateStudy('name', value)}
          id='app-display-name'
          placeholder='App Display Name'
        />
      )
    }

    const configCheckbox = ({ id, label, coupledKeyString, keyString, toggle = false, val = {} }) => {
      return (
        <Checkbox
          id={id}
          toggle={toggle}
          label={label}
          checked={!!configObj[keyString]}
          disabled={disabled}
          onClick={() => {
            const enabled = configObj[keyString]
            if (!enabled) {
              this.updateStudyConfig({ [keyString]: val })
              if (coupledKeyString) this.updateStudyConfig({ [coupledKeyString]: val })
            } else {
              this.deleteConfigKey(keyString)
              if (coupledKeyString) this.deleteConfigKey(coupledKeyString)
            }
          }}
        />
      )
    }

    const NameSection = (
      <Container>
        {isWizard ? <StudyWizardHeader /> : <h3>{disabled ? STRINGS.studyInfoViewOnly : STRINGS.createStudy}</h3>}
        {self_sign_code && (
          <div className='flexed start-justified start-aligned'>
            <p>{`Study Sign-up Code: ${self_sign_code}`}</p>
            <Popup hover noPointer dark position='right' trigger={<i className='fas fa-info-circle' />}>
              <p>{STRINGS.selfSignUpCode}</p>
            </Popup>
          </div>
        )}
        {renderStudyNameInput()}
        <Checkbox
          id='mark-as-test-study'
          label='Mark as Test Study'
          checked={configObj.is_test}
          disabled={disabled}
          onClick={() => this.updateStudyConfig({ is_test: !configObj.is_test })}
        />
        {renderAppNameInput()}
      </Container>
    )

    const orderedInstrumentContent = (isOrdered = true) => {
      return isOrdered ? (
        <div id='ordered-instruments'>
          <p>{STRINGS.orderedInstrumentsHeading}</p>
          <p>{STRINGS.orderedInstrumentsBody}</p>
        </div>
      ) : (
        <div id='unordered-instruments'>
          <p>{STRINGS.unorderedInstrumentsHeading}</p>
          <p>{STRINGS.unorderedInstrumentsBody}</p>
        </div>
      )
    }

    const MainFeaturesSection = (
      <Container>
        <h3>{STRINGS.studyConfigurations}</h3>
        <EmaillessConfig disabled={disabled} isEmailless={isEmailless} updateStudy={updateStudy} />
        <h5>Features</h5>
        <div className='config-checkboxes'>
          <div className='checkboxes'>
            {!CHINA_DEPLOY && (
              <>
                <Checkbox
                  id='econsent'
                  label={STRINGS.eConsent}
                  checked={configObj.consent_required}
                  disabled={disabled}
                  onClick={() => this.updateStudyConfig({ consent_required: !configObj.consent_required })}
                />
                <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.consentRequired, disabled)}>
                  {STRINGS.eConsentConfigBody}
                </p>
              </>
            )}
            <Checkbox
              id='training'
              label={STRINGS.training}
              checked={configObj.training?.enabled}
              disabled={disabled}
              onClick={() => this.updateStudyConfig({ training: { enabled: !configObj.training?.enabled } })}
            />
            <p className={generatePClassName(configObj, [STUDY_CONFIG_MAP.training, 'enabled'], disabled)}>
              {STRINGS.trainingConfigBody}
            </p>

            {isRootOrDchAdmin && (
              <>
                {configCheckbox({
                  id: 'clinician-reported-outcome',
                  label: STRINGS.clinicianReportedOutcomes,
                  keyString: STUDY_CONFIG_MAP.clinro,
                  coupledKeyString: STUDY_CONFIG_MAP.virtualVisits,
                })}
                <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.clinro, disabled)}>
                  {STRINGS.clinroConfigBody}
                </p>
                {configCheckbox({
                  id: 'medication-adherance',
                  label: STRINGS.medicationAdherence,
                  keyString: STUDY_CONFIG_MAP.medicationAdherence,
                })}
                <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.medicationAdherence, disabled)}>
                  {STRINGS.medicationAdherenceConfigBody}
                </p>
                <div className='config-toggles flexed column start-aligned'>
                  {configCheckbox({
                    id: 'advanced-features',
                    label: STRINGS.advancedFeatures,
                    keyString: STUDY_CONFIG_MAP.hasAdvancedFeatures,
                    toggle: true,
                    val: true,
                  })}
                  {configObj.hasAdvancedFeatures && (
                    <SubMenu>
                      {configCheckbox({
                        id: 'custom-onboarding',
                        label: STRINGS.customOnboarding,
                        keyString: STUDY_CONFIG_MAP.customOnboarding,
                      })}
                      {configCheckbox({
                        id: 'case-management',
                        label: STRINGS.caseManagement,
                        keyString: STUDY_CONFIG_MAP.caseManagement,
                      })}
                      {!CHINA_DEPLOY &&
                        configCheckbox({
                          id: 'geofence-triggered',
                          label: STRINGS.geofenceInstruments,
                          keyString: STUDY_CONFIG_MAP.customGeofenceBehavior,
                        })}
                      {configCheckbox({
                        id: 'custom-report-outcome',
                        label: STRINGS.customReportButton,
                        keyString: STUDY_CONFIG_MAP.customMapButton,
                      })}
                    </SubMenu>
                  )}
                </div>
              </>
            )}
            <h5>{STRINGS.visitConfiguration}</h5>
            <div className='flexed checkbox-with-graphic'>
              <div>
                {configCheckbox({
                  id: 'visit-support-checkbox',
                  label: STRINGS.visitSupport,
                  keyString: STUDY_CONFIG_MAP.visitSupport,
                })}
                <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.clinro, disabled)}>
                  {STRINGS.visitSupportConfigBody}
                </p>
              </div>
              {visitSupportIcon({ className: 'checkbox-graphic' })}
            </div>
            {configObj[STUDY_CONFIG_MAP.clinro] && isRootOrDchAdmin && (
              <div className='flexed checkbox-with-graphic'>
                <div>
                  {configCheckbox({
                    id: 'virtual-visit-checkbox',
                    label: STRINGS.virtualVisitClinro,
                    keyString: STUDY_CONFIG_MAP.virtualVisits,
                  })}
                  <p className={generatePClassName(configObj, STUDY_CONFIG_MAP.clinro, disabled)}>
                    {STRINGS.virtualVisitConfigBody}
                  </p>
                </div>
                {virtualVisitsIcon({ className: 'checkbox-graphic' })}
              </div>
            )}
            <Divider color='#dfe1f1' />
          </div>
          <h5>{STRINGS.instrumentCompletionOrder}</h5>
          <div className='flexed instrument-priority'>
            <Radio
              disabled={disabled}
              selected={configObj.forced_instrument_priority}
              onClick={() => this.updateStudyConfig({ [STUDY_CONFIG_MAP.forcedInstrumentPriority]: true })}
              content={orderedInstrumentContent(true)}
            />
            <Radio
              disabled={disabled}
              selected={!configObj.forced_instrument_priority}
              onClick={() => this.updateStudyConfig({ [STUDY_CONFIG_MAP.forcedInstrumentPriority]: false })}
              content={orderedInstrumentContent(false)}
            />
          </div>
          <Divider color='#dfe1f1' />
          <AppPushConfig {...configProps} />
        </div>
      </Container>
    )

    return (
      <>
        {NameSection}
        {MainFeaturesSection}
        <CreateStudyFooter
          title={STRINGS.next}
          onClick={() => browserHistory.push(CREATE_STUDY_FULL_URLS.setting)}
          {...this.props}
        />
      </>
    )
  }
}

CreateStudyType.propTypes = {
  createStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  disabled: PropTypes.bool,
  fetchLocales: PropTypes.func,
  hasDeleteStudyPermissions: PropTypes.bool,
  isRootOrDchAdmin: PropTypes.bool,
  isWizard: PropTypes.bool,
  saveAttempted: PropTypes.bool,
  setNavBarProps: PropTypes.func,
  study: PropTypes.shape({
    self_sign_code: PropTypes.string,
    name: PropTypes.string,
    platform_name: PropTypes.string,
    id: PropTypes.number,
    config: PropTypes.shape({ participant_data_fields: PropTypes.object }),
  }),
  updateConfig: PropTypes.func,
  updateStudy: PropTypes.func,
}

export default CreateStudyType
