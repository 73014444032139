import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'components/UIElements'

function Subheader(props) {
  const { subheaderText, infoText } = props
  return (
    <span>
      <h5>{subheaderText}</h5>
      {infoText && (
        <Popup hover noPointer position='bottom-right' dark trigger={<i className='fa fa-info-circle' />}>
          <div className='hover-text'>{infoText}</div>
        </Popup>
      )}
    </span>
  )
}

Subheader.propTypes = {
  subheaderText: PropTypes.string,
  infoText: PropTypes.string,
}

export default Subheader
