import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'components/UIElements'
import { getUserScopeByStudy } from 'utils/misc'
import NotificationsPage from '../components/NotificationsPage'

const FIXED_AUTO_NOTIS = {
  new_instrument: { enabled: true, fixed: true },
  new_cycle: { enabled: true, fixed: true },
  new_announcement: { enabled: true, fixed: true },
  data_sync: { enabled: true, fixed: true },
}

const mapState = state => {
  const { conditionalNotifications, studyLock, user, study } = state
  const conditionalNotisArr = Object.keys(conditionalNotifications)
  const hasConditionalNotifications = conditionalNotisArr.some(conditionalNotificationKey => {
    return Object.keys(conditionalNotifications[conditionalNotificationKey]).length > 0
  })
  const currentUserScope = getUserScopeByStudy(user, study.currentStudy)

  return {
    canEditNotifications: currentUserScope.includes('wu'),
    canViewConditionalNotifications: currentUserScope.includes('wa'),
    loading: state.loading.general,
    autoNotifications: { ...state.study.currentStudy.auto_notifications, ...FIXED_AUTO_NOTIS },
    conditionalNotifications,
    hasConditionalNotifications,
    studyLock,
    instrumentArchitectureVersion: study?.currentStudy?.config?.instrument_architecture_version,
  }
}

export default connect(
  mapState,
  null,
)(props => {
  return props.loading ? <Loader /> : <NotificationsPage {...props} />
})
