import React from 'react'
import { EditorState, CompositeDecorator, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { getEntityRange, getSelectionEntity } from 'draftjs-utils'
import DOMPurify from 'dompurify'

const he = require('he')

const REMOVE_BEGINNING_AND_END_BR_REGEX = /(^<br\/>+)|(<br\/>$)/g

export const HTMLToEditorState = htmlString => {
  let html = `<p>${htmlString}</p>`
  html = html.replace(/<br\/>/g, '</p><p>')
  return EditorState.createWithContent(stateFromHTML(html))
}

export const HTMLToEditorStatePure = htmlString => EditorState.createWithContent(stateFromHTML(htmlString))

const htmlRenderOptions = {
  blockStyleFn: block => {
    const type = block.getType()
    if (type === 'align-center') {
      return { style: { textAlign: 'center' } }
    }
    return null
  },
}

export const EditorStateToHTML = editorState => {
  let html = stateToHTML(editorState.getCurrentContent(), { defaultBlockTag: 'div', ...htmlRenderOptions })
  html = he.decode(html) // decode symbols such as &amp;

  html = html.replace(/<\/-~->\n<-~->/g, '<br/>')
  html = html.replace(/<-~->/g, '<br/>')
  html = html.replace(/<\/-~->/g, '<br/>')
  html = html.replace(/<br\/><br><br\/>/g, '<br/><br>') // remove extra br
  html = html.replace(REMOVE_BEGINNING_AND_END_BR_REGEX, '') // this removes the extra br at beginning and end
  return html
}

export const getEntities = (editorState, entityType = null) => {
  const content = editorState.getCurrentContent()
  const entities = []
  content.getBlocksAsArray().forEach(block => {
    let selectedEntity = null
    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity())
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            }
            return true
          }
        }
        return false
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end })
      },
    )
  })
  return entities
}

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
  }, callback)
}

const Link = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()
  return (
    <a href={url} target='_blank' rel='noopener noreferrer' className='text-editor-link'>
      {children}
    </a>
  )
}

export const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
])

export const getLinkInfo = (e, editorState) => {
  e.preventDefault()
  const selection = editorState.getSelection()
  const contentState = editorState.getCurrentContent()
  const startKey = selection.getStartKey()
  const startOffset = selection.getStartOffset()
  const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
  const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)
  const endOffset = selection.getEndOffset()

  const selectedText = blockWithLinkAtBeginning.getText().slice(startOffset, endOffset)
  const entityRange = getEntityRange(editorState, getSelectionEntity(editorState))

  let _linkText
  let linkRange

  if (entityRange) {
    const { start, end } = entityRange
    _linkText = entityRange.text
    linkRange = { start, end }
  }

  const linkText = _linkText || selectedText

  return { linkKey, linkText, linkRange }
}

export const removeLink = (e, editorState, onChange) => {
  e.preventDefault()
  const currentEntity = getSelectionEntity(editorState)
  let selection = editorState.getSelection()

  if (currentEntity) {
    const entityRange = getEntityRange(editorState, currentEntity)
    const isBackward = selection.getIsBackward()
    if (isBackward) {
      selection = selection.merge({
        anchorOffset: entityRange.end,
        focusOffset: entityRange.start,
      })
    } else {
      selection = selection.merge({
        anchorOffset: entityRange.start,
        focusOffset: entityRange.end,
      })
    }
    onChange(RichUtils.toggleLink(editorState, selection, null))
  }
}

export const getSelectedBlockElement = () => {
  const selection = window.getSelection()
  if (selection.rangeCount === 0) return null
  let node = selection.getRangeAt(0).startContainer
  do {
    if (node.getAttribute && node.getAttribute('data-block') === 'true') return node
    node = node.parentNode
  } while (node != null)
  return null
}

export const formatLinkEntities = editorState => {
  return EditorState.set(editorState, { decorator })
}

export const formatAndSanitizeHTML = html => DOMPurify.sanitize(html)
