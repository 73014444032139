import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Divider } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import { getQuestionsForDomain, getInitialHoverState } from '../utils/scoring'

import '../stylesheets/scoring.scss'

const FORMULA_OPTIONS = [
  { key: 'SUM', value: 'SUM', text: 'SUM' },
  { key: 'MEAN', value: 'MEAN', text: 'MEAN' },
  { key: 'PRODUCT', value: 'PRODUCT', text: 'PRODUCT' },
]

const Questions = ({ coord, domainId, instrument }) => {
  const qList = getQuestionsForDomain(domainId, instrument)
  if (!coord) return null
  return (
    <div className='questions-container'>
      <div
        className='questions flexed column start-aligned'
        style={{ top: `${coord.y - 60}px`, left: `${coord.x + 5}px` }}>
        <p className='question-header'>{pluralize(qList.length, 'Value', 'Values', true)}</p>
        {qList.map((q, idx) => {
          return (
            <div key={q.id}>
              <p className='question-row'>
                <span>{`Q${q.num} `}</span>
                {q.label}
              </p>
              {idx + 1 < qList.length && <Divider color='#dededf' />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const DomainList = ({ instrument, isClinro, updateDomainFormula }) => {
  const { domains = {} } = instrument
  const [selectedDomain, setSelectedDomain] = useState('')
  const [hoverCoord, setHoverCoord] = useState(getInitialHoverState(domains))

  return (
    <div className='flexed half-page'>
      <h3>1. Calculate domains</h3>
      <h4>How should the participant-selected values of each domain be calculated?</h4>
      <p className='green-text'>
        <i className='fas fa-arrow-right' />
        {isClinro ? 'Domains will not appear on ClinRO' : 'Domains and scores will not be displayed to participants'}
      </p>
      <span className='label-small'>Domain score label</span>
      {Object.keys(domains).map(domainId => {
        const { label, formula } = domains[domainId]
        return (
          <div className='domain-row flexed' key={domainId}>
            <div
              className='domain-title'
              onMouseEnter={() => setSelectedDomain(domainId)}
              onMouseMove={e => {
                setHoverCoord({
                  ...hoverCoord,
                  [domainId]: { x: e.clientX, y: e.clientY },
                })
              }}>
              {label}
            </div>
            <span>=</span>
            <Dropdown
              onSelect={item => {
                updateDomainFormula(domainId, item.value)
              }}
              options={FORMULA_OPTIONS}
              selected={formula}
              value={formula}
            />
            <span className='with-min-width'>of all values</span>
            {domainId === selectedDomain && (
              <Questions coord={hoverCoord[domainId]} domainId={domainId} instrument={instrument} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default DomainList

DomainList.propTypes = {
  isClinro: PropTypes.bool,
  instrument: PropTypes.object,
  updateDomainFormula: PropTypes.func,
}
Questions.propTypes = {
  coord: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
  instrument: PropTypes.object,
  domainId: PropTypes.string,
}
