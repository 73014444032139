import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { CONSENT_STATUSES } from 'utils/constants'
import D3Logo from 'assets/DCH-Logo.svg'
import { checkHasPdfIfRequired } from '../utils/consentUtils'

const ConsentNav = props => {
  const { consent, loading, onSave, path, preview, location } = props
  const { consent_status: consentStatus, consent_id: consentId, pdf_upload: pdfUpload } = consent
  const isProperties = path === 'properties'
  const isCreateConsent = path === 'createconsent'
  const isEditConsent = path === 'editconsent'
  const isFormatting = path === 'formatting'
  const isSchedule = path === 'schedule'
  const isDeploy = path === 'deploy'

  const isScheduled = consentStatus === CONSENT_STATUSES.scheduled
  const notScheduled = !isScheduled
  const inProgress = consentStatus === CONSENT_STATUSES.inProgress
  const isUpdate = isEditConsent || (notScheduled && !!consentId)

  const blockStepsAfterConsentDrafting = !checkHasPdfIfRequired(consent)

  return (
    !preview && (
      <div className='consent-nav flexed-header'>
        <div className='flexed-header'>
          <div className='d3-logo-container'>
            <img className='d3-logo' src={D3Logo} alt='d3-logo' />
          </div>
          <Button
            selected={isProperties || isCreateConsent}
            disabled={loading || isScheduled}
            link
            onClick={() =>
              onSave('properties', false, {
                isUpdate,
                shouldValidate: isSchedule,
                isJsonSave: true,
                isProperties,
                isPdfUpload: pdfUpload,
                isEditConsent,
              })
            }
            content={
              <span>
                1. Consent properties
                <i className='fas fa-arrow-right' />
              </span>
            }
          />
          <Button
            selected={isSchedule}
            disabled={loading || !consentId || isScheduled}
            link
            onClick={() =>
              onSave('schedule', false, {
                isUpdate,
                shouldValidate: isSchedule,
                isJsonSave: true,
                isProperties,
                isPdfUpload: pdfUpload,
                isEditConsent,
              })
            }
            content={
              <span>
                2. Deployment Schedule
                <i className='fas fa-arrow-right' />
              </span>
            }
          />
          <Button
            selected={isEditConsent}
            disabled={loading || !consentId || isScheduled}
            link
            onClick={() => {
              onSave('editconsent', false, {
                isUpdate,
                shouldValidate: isSchedule,
                isJsonSave: true,
                isProperties,
                isPdfUpload: pdfUpload,
                isEditConsent,
              })
            }}
            content={
              <span>
                3. Draft consent
                <i className='fas fa-arrow-right' />
              </span>
            }
          />
          <Button
            selected={isFormatting}
            disabled={loading || !consentId || isScheduled || blockStepsAfterConsentDrafting}
            link
            onClick={() => {
              onSave('formatting', false, {
                isUpdate,
                shouldValidate: isSchedule,
                isJsonSave: true,
                isProperties,
                isPdfUpload: pdfUpload,
                isEditConsent,
              })
            }}
            content={
              <span>
                4. Consent Page Formatting
                <i className='fas fa-arrow-right' />
              </span>
            }
          />
          <Button
            selected={isDeploy}
            disabled={loading || !consentId || inProgress || isScheduled || blockStepsAfterConsentDrafting}
            link
            onClick={() => {
              onSave('deploy', false, {
                isUpdate,
                shouldValidate: isSchedule,
                isJsonSave: true,
                isProperties,
                isPdfUpload: pdfUpload,
                isEditConsent,
              })
            }}
            content={<span>5. Review and Deploy</span>}
          />
        </div>
        <Button
          grey
          id='close-button'
          content='Close'
          onClick={() => {
            const { pathname } = location
            const getLastPath = pathname.split('/').pop()
            onSave(
              'consents',
              true,
              {
                isUpdate,
                shouldValidate: isSchedule,
                isJsonSave: true,
                isProperties,
                isPdfUpload: pdfUpload,
                isEditConsent,
              },
              {
                query: notScheduled ? '?tab=drafts' : '',
                confirmQuery: '?tab=drafts',
                cancelQuery: getLastPath && getLastPath !== 'createconsent' && notScheduled ? '?tab=drafts' : '',
              },
            )
          }}
        />
      </div>
    )
  )
}

ConsentNav.propTypes = {
  consent: PropTypes.shape({
    consent_id: PropTypes.string,
    consent_status: PropTypes.string,
  }),
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  path: PropTypes.string,
  preview: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default ConsentNav
