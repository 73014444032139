import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { instrumentActions } from '../../../../../../Instruments/routes/Instrument/modules/Instrument'
import { consentActions } from '../../NewConsentBuilder/modules/NewConsent'
import ConsentFormattingPage from '../components/ConsentFormattingPage'

const mapDispatchToProps = { ...instrumentActions, ...consentActions }

const mapStateToProps = state => {
  const { loading, location, newConsentReducer, study } = state
  const studyID = study.currentStudy?.id
  const path = location.pathname.match(/([^/]+$)/)[0]
  return {
    consent: newConsentReducer.consent,
    editorState: newConsentReducer.editorStates,
    errors: newConsentReducer.consentErrors,
    isLoading: loading.general,
    path,
    studyID,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader /> : <ConsentFormattingPage {...props} />
})
