import React from 'react'
import PropTypes from 'prop-types'

const Tags = props => {
  const { disabled, tags, toggle } = props
  return (
    <div className='tags'>
      {tags.map(({ key, text }) => {
        return (
          <span key={key}>
            {text}
            {!disabled && <i onClick={() => toggle(key)} className='fas fa-times' />}
          </span>
        )
      })}
    </div>
  )
}

Tags.propTypes = {
  disabled: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.object),
  toggle: PropTypes.func,
}

export default Tags
