import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import NewConsentBuilderPage from '../components/NewConsentBuilderPage'
import { consentActions, duplicateItem } from '../modules/NewConsent'
import { instrumentActions } from '../../../../../../Instruments/routes/Instrument/modules/Instrument'

const mapDispatchToProps = { duplicateItem, ...consentActions, ...instrumentActions }

const mapStateToProps = state => {
  const { location, consents } = state
  const path = location.pathname.match(/([^/]+$)/)[0]
  const { newConsentReducer, loading, study, user } = state
  const { currentStudy, locales, localesMap } = study
  return {
    consentList: consents?.consentList,
    consent: newConsentReducer.consent,
    editorStates: newConsentReducer.editorStates,
    isConsentTwo: currentStudy.consent_required,
    isLoading: loading.general,
    enforcedLanguages: currentStudy?.config?.enforced_language?.languages,
    locales,
    localesMap,
    questionNumbers: newConsentReducer.consent.questionNumbers,
    wordCount: newConsentReducer.wordCount,
    path,
    studyID: study.currentStudy.id,
    errors: newConsentReducer.consentErrors,
    isClinro: !!currentStudy?.config?.clinro,
    study: currentStudy,
    userId: user?.id,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <NewConsentBuilderPage {...props} />
})
