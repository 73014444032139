import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { UploadInput } from 'components/UIElements'

require('./UploadSurveyInput.scss')

const UploadSurveyInput = ({ disabled, onSurveyUpload, params, onDiscardUpload }) => {
  const { studyID, siteID } = params
  const [file, setFile] = useState({})

  const onFileSelection = _file => {
    setFile(_file)
    onSurveyUpload(_file)
  }

  return (
    <div className='upload-container'>
      <UploadInput
        type='json'
        accept={['.json']}
        onUpdateFile={onFileSelection}
        onRemoveFile={() => onDiscardUpload(studyID, siteID, false)}
        name='survey-json'
        file={file}
        disabled={disabled}
      />
    </div>
  )
}

UploadSurveyInput.propTypes = {
  disabled: PropTypes.bool,
  onSurveyUpload: PropTypes.func,
  params: PropTypes.shape({
    studyID: PropTypes.string,
    siteID: PropTypes.string,
  }),
  onDiscardUpload: PropTypes.func,
}

export default UploadSurveyInput
