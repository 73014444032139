import React from 'react'
import PropTypes from 'prop-types'
import { Button, SearchInput } from 'components/UIElements'
import { browserHistory } from 'react-router'
import ActiveDataSection from './ActiveData'
import DeleteParticipant from './DeleteParticipant'
import CohortList from './CohortList'
import FilterTagsPopup from './Filters/FilterTagsPopup'
import ParticipantList from './ParticipantList'
import '../stylesheets/Participants.scss'
import { isPtpInUncompletedConsentBlockList } from '../../../utils/participantValidation'
import StickyHeader from '../../../../../../../../../components/StickyHeader'
import { calculateOffsets } from '../../../../../../../../../utils/misc'
import { MODAL_CLASSES_MAP } from '../../../../../../../../../utils/constants'

class ParticipantsPage extends React.Component {
  constructor(props) {
    super(props)
    const { page } = this.props
    this.state = {
      page: page || 'participants',
      offsets: null,
      hasOrphans: false,
      dataSectionSelected: null,
    }
    this.onDataSectionSelect = this.onDataSectionSelect.bind(this)
  }

  componentDidMount() {
    const { canSeeOrphans, participantList, clearFilters, resetCheckedPtps } = this.props
    resetCheckedPtps()
    clearFilters()
    this.calculateAndSetOffsets()
    const containsOrphans = participantList.some(row => {
      return !row.filter(_row => _row.key === 'siteId').map(res => res.inLeafSite)[0]
    })

    /* We know there are orphans in the participant tables, if the current user is a Study Coordinator
     * or Investigator and if the leafSites array does not include the baseSiteID and some participants
     * are still in the baseSiteID (containsBaseSite boolean)
     */
    if (canSeeOrphans && containsOrphans) {
      this.setState({ hasOrphans: true }, () => this.onSetOrphanedParticipants())
    } else {
      this.onSetOrphanedParticipants()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filterProps: {
        filters: { checkbox: prevCheckbox },
      },
    } = prevProps
    const {
      filterProps: {
        filters: { checkbox },
      },
    } = this.props
    const { page } = this.state
    if (prevState.page !== page) this.calculateAndSetOffsets()
    if (prevCheckbox && !checkbox) this.onDataSectionSelect(null)
  }

  calculateAndSetOffsets = () => {
    this.setState({ offsets: calculateOffsets('sticky') })
  }

  onSetOrphanedParticipants = () => {
    const { onSetOrphanedParticipants } = this.props
    const { hasOrphans } = this.state
    if (hasOrphans) return onSetOrphanedParticipants(true)
    return onSetOrphanedParticipants(false)
  }

  togglePage = _page => {
    const { page } = this.state
    if (_page !== page) this.setState({ page: _page })
  }

  _validateCompletedBlockedConsents = () => {
    const { checkedPtps, studyID, unCompleteBlockedConsents, ptpInBlockedConsentAlert } = this.props

    if (isPtpInUncompletedConsentBlockList(Object.keys(checkedPtps), Object.values(unCompleteBlockedConsents))) {
      ptpInBlockedConsentAlert(checkedPtps, unCompleteBlockedConsents)
    } else {
      browserHistory.push(`/studies/${studyID}/participants/move`)
    }
  }

  renderMovePtpButton = () => {
    const { checkedPtps } = this.props
    const checkedPtpCount = Object.keys(checkedPtps).length
    const anyChecked = checkedPtpCount > 0
    return (
      <div className='move-ptp-btn'>
        {anyChecked && (
          <Button
            id='move-participant-button'
            content='Move to...'
            grey
            onClick={this._validateCompletedBlockedConsents}
          />
        )}
      </div>
    )
  }

  clearStateFilters() {
    this.setState({
      selected: null,
    })
  }

  onDataSectionSelect(value) {
    this.setState({
      dataSectionSelected: value,
    })
  }

  renderPtpButtons = () => {
    const {
      canAddCohort,
      canAddPtp,
      canDelete,
      checkedPtps,
      noTracksFeature,
      isCaseManager,
      studyID,
      openModal,
      studyLock,
    } = this.props
    const checkedPtpCount = Object.keys(checkedPtps).length
    const anyChecked = checkedPtpCount > 0
    const plural = checkedPtpCount > 1
    return (
      <>
        <div className='action-buttons-list'>
          {anyChecked && !isCaseManager && canDelete && (
            <Button
              grey
              content={`Delete Participant${plural ? 's' : ''}`}
              onClick={() => {
                openModal({
                  content: <DeleteParticipant {...this.props} plural={plural} />,
                  noButtons: true,
                  className: MODAL_CLASSES_MAP.deleteParticipant,
                })
              }}
              id='delete-participant-button'
              disabled={studyLock}
            />
          )}
          {anyChecked && canAddCohort && !noTracksFeature && (
            <Button
              grey
              content='Add Track'
              onClick={() => browserHistory.push(`/studies/${studyID}/participants/tracks/create`)}
              id='create-cohort-button'
              disabled={studyLock}
            />
          )}
          {canAddPtp && (
            <Button
              content='Add Participant'
              disabled={studyLock}
              onClick={() => browserHistory.push(`/studies/${studyID}/participants/create`)}
              id='create-participant-button'
            />
          )}
        </div>
        <div className='filters-container'>
          <SearchInput />
        </div>
      </>
    )
  }

  render() {
    const {
      canAddCohort,
      canAddPtp,
      canMovePtp,
      canWrite,
      cohortList,
      disableTrackCreation,
      downloadData,
      noTracksFeature,
      siteID,
      studyID,
      studyLock,
    } = this.props
    const { offsets, page, hasOrphans, dataSectionSelected } = this.state
    const subsequentOffsets = offsets ? offsets.slice(1) : []
    const isPtpPage = page === 'participants'
    const canAdd = canWrite || canAddCohort || canAddPtp
    return (
      <div className='participants page'>
        <div className='selected-button-list'>
          <Button
            id='participants'
            selected={page === 'participants'}
            onClick={() => this.togglePage('participants')}
            content='Participants'
          />
          {!noTracksFeature && (
            <Button selected={page === 'tracks'} onClick={() => this.togglePage('tracks')} content='Tracks' />
          )}
        </div>

        <StickyHeader
          className={`ptp-action-buttons-container ${isPtpPage ? '' : 'ptp-action-buttons-tracks-container'}`}
          offset={offsets?.[0] || null}>
          {isPtpPage && <ActiveDataSection {...this.props} onDataSectionSelect={this.onDataSectionSelect} />}
          <div className='ptp-action-buttons'>
            {canMovePtp && this.renderMovePtpButton()}
            {canAdd && (
              <div>
                {isPtpPage ? (
                  this.renderPtpButtons()
                ) : (
                  <>
                    {canAddCohort && !disableTrackCreation && (
                      <Button
                        id='create-track'
                        content='Create Track'
                        disabled={studyLock}
                        onClick={() => browserHistory.push(`/studies/${studyID}/participants/tracks/create`)}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </StickyHeader>
        <div className='ptp-filters'>
          {isPtpPage && (
            <>
              <Button
                id='download-participant-list'
                className='data-download'
                content='Participants List'
                icon='fas fa-cloud-download-alt'
                isThrottled
                link
                onClick={() => downloadData(studyID, siteID, 'participants')}
              />
              <FilterTagsPopup
                clearStateFilters={this.clearStateFilters}
                customTags={{
                  checkbox: {
                    title: 'Participant status',
                  },
                }}
              />
            </>
          )}
        </div>
        {isPtpPage ? (
          <ParticipantList {...this.props} hasOrphans={hasOrphans} dataSectionSelected={dataSectionSelected} />
        ) : (
          <CohortList offsets={subsequentOffsets} list={cohortList || []} studyID={studyID} studyLock={studyLock} />
        )}
      </div>
    )
  }
}

export default ParticipantsPage

ParticipantsPage.propTypes = {
  canAddPtp: PropTypes.bool,
  canAddCohort: PropTypes.bool,
  canDelete: PropTypes.bool,
  canMovePtp: PropTypes.bool,
  canSeeOrphans: PropTypes.bool,
  canWrite: PropTypes.bool,
  checkedPtps: PropTypes.object,
  clearFilters: PropTypes.func,
  cohortList: PropTypes.arrayOf(PropTypes.array),
  deleteParticipants: PropTypes.func,
  isCaseManager: PropTypes.bool,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  noTracksFeature: PropTypes.bool,
  onSetOrphanedParticipants: PropTypes.func,
  openModal: PropTypes.func,
  page: PropTypes.string,
  participantList: PropTypes.arrayOf(PropTypes.array),
  participantType: PropTypes.string,
  ptpMoveLoading: PropTypes.bool,
  resetCheckedPtps: PropTypes.func,
  siteID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  studyID: PropTypes.number,
  toggleParticipantType: PropTypes.func,
  updateSearchTerm: PropTypes.func,
  hasPiiRole: PropTypes.bool,
  studyLock: PropTypes.bool,
  filterProps: PropTypes.object,
}
