const HIDDEN_TASKS = ['janssen_chatbot_test', 'janssen_chatbot']

export const ARTICLE_TYPE = {
  key: 'article_type',
  engagement_article: {
    key: 'engagement_article',
    txt: 'Engagement Item',
  },
  visit_summary: {
    key: 'visit_summary',
    txt: 'Visit Summary',
  },
  missed_visit_follow_up: {
    key: 'missed_visit_follow_up',
    txt: 'missed_visit_follow_up',
  },
}

export const DEPLOY_CONFIRM = {
  key: 'deploy_on_confirm',
}

export default HIDDEN_TASKS
