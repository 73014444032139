import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import UploadSurveyView from './containers/UploadSurveyContainer'
import { resetInstrument } from '../../modules/Instrument'
import { actions } from './modules/UploadSurvey'
import { fetchCohorts } from '../../../../../Participants/routes/ParticipantsPage/modules/Participants'

// Sync route definition
export default store => ({
  path: 'uploadinstrument',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      require => {
        const { params } = nextState
        const { studyID } = params

        /*  Webpack - use require callback to define
       dependencies for bundling   */

        store.dispatch(resetInstrument('SURVEY'))
        store.dispatch(actions.initialize())
        store.dispatch(fetchCohorts(studyID))

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['wa'], UploadSurveyView, false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'instruments/uploadinstrument',
    )
  },
})
