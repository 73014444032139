import React, { memo, useState } from 'react'
import STRINGS from 'utils/strings'
import { Link } from 'react-router'
import { UploadInput, PdfViewer } from 'components/UIElements'
import { icons } from 'assets/assets'

const UploadPdfSection = ({ consentId, currentLang, pdfUrl, studyId, errorMsg, updateConsent, uploadConsentPdf }) => {
  const [files, setFiles] = useState({})

  const uploadFile = async _file => {
    if (!_file.name) {
      return
    }

    const formData = new FormData()
    formData.set('file_name', _file.name)
    formData.set('upload_file', _file)
    formData.set('language', currentLang)

    const { filename } = await uploadConsentPdf({
      formData,
      studyID: studyId,
      consentId,
      loadingKey: 'mediaUpload',
    })
    if (filename) {
      updateConsent(filename)
    }
  }

  const onFileSelection = _file => {
    setFiles({ ...files, [currentLang]: _file })
    uploadFile(_file)
  }

  return (
    <div className='upload-input-wrapper'>
      {!!pdfUrl ? (
        <PdfViewer url={pdfUrl} />
      ) : (
        <UploadInput
          type='pdf'
          accept={['.pdf']}
          onUpdateFile={onFileSelection}
          onRemoveFile={() => setFiles({ ...files, [currentLang]: undefined })}
          name='consent-pdf'
          file={files[currentLang] || {}}
          errorMsg={errorMsg}
        />
      )}
      {!!files[currentLang]?.name ? (
        <div className='upload-input-file-name'>
          <span>{`${STRINGS.fileName}: ${files[currentLang].name}`}</span>
          <div>
            <Link className='upload-input-external-link' target='_blank' href={pdfUrl}>
              {icons.externalLink}
              <span>{STRINGS.previewPdfFile}</span>
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default memo(UploadPdfSection)
