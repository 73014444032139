import React from 'react'
import PropTypes from 'prop-types'
import { DIARY_QUESTION_TYPE_OPTIONS, QUESTION_TYPE_MAP } from 'utils/constants'
import { Dropdown, Popup, Button } from 'components/UIElements'
import { runNTimes } from 'utils/misc'

const shortExample = require('./../assets/short.png')
const medExample = require('./../assets/medium.png')
const longExample = require('./../assets/long.png')

const options = DIARY_QUESTION_TYPE_OPTIONS

const SIZE_OPTIONS = [
  { key: 'short', value: 'short', text: 'Short' },
  { key: 'medium', value: 'medium', text: 'Medium' },
  { key: 'long', value: 'long', text: 'Long' },
]

const QuestionPanel = ({
  item,
  onChangeItemType,
  onUpdateItemInputWidth,
  // onAddOtherChoice,
  onAddChoice,
  onUpdateUnitType,
  onDeleteChoice,
  consent,
}) => {
  const hasChoices = [
    'select_one',
    'select_multiple',
    'likert',
    'long_list_select_one',
    'numeric_rating_scale',
    'multiple_field',
  ].includes(item.type)
  const isConsent = consent ? consent.id === 'consent' : false
  const isNRS = item.type === QUESTION_TYPE_MAP.numericRatingScale
  const isMultiField = item.type === QUESTION_TYPE_MAP.multipleField
  const isLongList = item.type === QUESTION_TYPE_MAP.longListSelectOne

  const choicesSection = (
    <div className='choices section'>
      <h5 className='label-small med-font'>{isMultiField ? 'Input fields with labels' : 'Options'}</h5>
      {hasChoices && item.type !== 'likert' ? (
        <div>
          <Button
            className='inline flexed'
            onClick={onDeleteChoice(item.choices_order[item.choices_order.length - 1])}
            circle
            disabled={item.choices_order.length <= (isMultiField ? 1 : isLongList ? 10 : 2)}
            icon='fas fa-minus'
          />
          <p className='inline flexed'>{item.choices_order.length}</p>
          <Button
            className='inline flexed'
            disabled={item.choices_order.length >= 11 && isNRS}
            onClick={onAddChoice}
            circle
            icon='fas fa-plus'
          />
          {/* {
            !item.attributes.other &&
            <Button
              no-margin
              link
              icon='fas fa-plus'
              content='Add Other Choice'
              onClick={onAddOtherChoice} />
          } */}
        </div>
      ) : (
        <div>
          <Button
            className='inline flexed'
            onClick={
              item.choices_order.length > 5
                ? () => runNTimes(2, onDeleteChoice(item.choices_order[item.choices_order.length - 1]))
                : null
            }
            circle
            disabled={item.choices_order.length <= 5}
            icon='fas fa-minus'
          />
          <p className='inline flexed'>{item.choices_order.length}</p>
          <Button
            className='inline flexed'
            onClick={item.choices_order.length === 5 ? () => runNTimes(2, onAddChoice) : null}
            circle
            disabled={item.choices_order.length >= 7}
            icon='fas fa-plus'
          />
        </div>
      )}
    </div>
  )

  const unitsSection = ['decimal', 'integer'].includes(item.type) ? (
    <div className='number section'>
      Unit:
      <input onChange={onUpdateUnitType} maxLength={20} value={item.attributes.unit} />
    </div>
  ) : null

  const renderSamplePicture = (srcImage, text, alt) => {
    return (
      <div className='flexed column'>
        <img src={srcImage} alt={alt} />
        <b>{text}</b>
      </div>
    )
  }

  const inputWidthSection = (
    <div className='input-width section'>
      <div className='flexed start-justified'>
        <div className='label-small'>Size of input fields</div>
        <Popup
          className='width-examples'
          hover
          overrideOverflow
          noPointer
          dark
          position='top'
          trigger={<i className='fas fa-info-circle' />}>
          <p>Examples of different sizes of input fields displayed to participants.</p>
          <div className='flexed'>
            {renderSamplePicture(shortExample, 'Short', 'short-field-example')}
            {renderSamplePicture(medExample, 'Medium', 'medium-field-example')}
            {renderSamplePicture(longExample, 'Long', 'long-field-example')}
          </div>
        </Popup>
      </div>
      <Dropdown
        className='input-width'
        selected={item.attributes.inputWidth}
        options={SIZE_OPTIONS}
        onSelect={onUpdateItemInputWidth}
      />
    </div>
  )

  return (
    <div>
      <div className='question panel'>
        <h5 className='label-small med-font'>Question Type</h5>
        {!isConsent && (
          <div className='selector section'>
            <Dropdown
              className='select-dropdown'
              onSelect={onChangeItemType}
              options={options}
              selected={item.type}
              sortingOrder='asc'
              sortingBy='text'
            />
          </div>
        )}
        {hasChoices && choicesSection}
        {isMultiField && inputWidthSection}
        {unitsSection}
      </div>
    </div>
  )
}

QuestionPanel.propTypes = {
  item: PropTypes.object,
  onChangeItemType: PropTypes.func,
  onUpdateItemInputWidth: PropTypes.func,
  onDeleteChoice: PropTypes.func,
  onUpdateUnitType: PropTypes.func,
  onAddChoice: PropTypes.func,
  consent: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default QuestionPanel
