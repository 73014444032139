import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import ConsentDeploy from '../components/ConsentDeploy'
import { consentActions } from '../../NewConsentBuilder/modules/NewConsent'
import { onSave } from '../../../../../../Instruments/routes/Instrument/modules/Instrument'

const mapDispatchToProps = { ...consentActions, onSave }

const mapStateToProps = state => {
  const { newConsentReducer, participants, sites, study } = state
  const { consent } = newConsentReducer
  const { sitesMap } = sites
  const { currentStudy } = study
  const { cohortMap } = participants
  return {
    cohortMap,
    consent,
    isLoading: state.loading.general,
    sitesMap,
    studyId: currentStudy.id,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <ConsentDeploy {...props} />
})
