import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Container, Popup } from 'components/UIElements'

const GeofenceCheckbox = ({ isChecked, onClick }) => {
  return (
    <Container>
      <div className='flexed start-justified'>
        <Checkbox
          checked={isChecked}
          onClick={() => onClick(!isChecked)}
          label='Make this the geofence-triggered instrument and allow self-reporting'
        />
        <Popup hover noPointer dark position='bottom' trigger={<i className='fas fa-info-circle' />}>
          <p>
            This instrument will be 1. sent to participants when they enter geofenced locations and 2. be available for
            participants to access whenever they want. Only one task in the schedule can be the geofence-triggered
            instrument, and gamification points will be set to 0.
          </p>
        </Popup>
      </div>
    </Container>
  )
}

GeofenceCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
}

export default GeofenceCheckbox
