import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import EditConditionalNotificationView from './containers/ConditionalNotificationContainer'
import { notificationActions } from './modules/ConditionalNotification'
import { CONDITIONAL_TYPE_MAP } from '../../NotificationsPage/components/NotificationTypes'
// Sync route definition
export default (store, route) => ({
  path: `conditional/${route}`,
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        store.dispatch(notificationActions.fetchConditions(studyID, route))
        const { currentStudy } = store.getState().study
        setPageTitle(`Edit ${CONDITIONAL_TYPE_MAP[route].text} | Study ${studyID} | ${currentStudy.platform_name}`)
        // Return Component
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserHasScope(['wa'], EditConditionalNotificationView(route), false, studyID, null, currentStudy),
            ),
          ),
        )
      },
      'conditional',
    )
  },
})
