import React from 'react'
import PropTypes from 'prop-types'
import { PercentMeter } from 'components/UIElements'

const ReadStatus = ({ num_read, num_received, num_sent }) => {
  const ratio = (num_read || num_received) / num_sent || 0
  const percent = (ratio * 100).toFixed(2)
  return (
    <div id='read-status-box'>
      <p>Read</p>
      <h3>
        <span>{percent}</span>%
      </h3>
      <PercentMeter hideNum ratio={ratio} />
    </div>
  )
}

ReadStatus.propTypes = {
  num_read: PropTypes.number,
  num_received: PropTypes.number,
  num_sent: PropTypes.number,
}

export default ReadStatus
