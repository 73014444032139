import React from 'react'
import { Input, DatePicker, Checkbox } from 'components/UIElements'

const renderNumRange = (state, getOnChange, placeholders) => {
  return (
    <div className='num-range-input'>
      <Input
        inline
        preventInput={val => val < 0}
        placeholder={placeholders.min}
        type='number'
        value={state.min}
        onChange={getOnChange('min')}
      />
      <span> - </span>
      <Input
        inline
        preventInput={val => val < 0}
        placeholder={placeholders.max}
        type='number'
        value={state.max}
        onChange={getOnChange('max')}
      />
    </div>
  )
}

const renderDateRange = (state, getOnChange) => {
  return (
    <div className='date-range-input'>
      <DatePicker date={state.start} onDayChange={getOnChange('start')} />
      <span> - </span>
      <DatePicker date={state.end} onDayChange={getOnChange('end')} />
    </div>
  )
}

const renderCheckboxList = (state, list, getOnChecked) => {
  return list.map(item => {
    return (
      <Checkbox label={item.label} key={item.id} checked={state.set.has(item.id)} onClick={getOnChecked(item.id)} />
    )
  })
}

export const renderers = {
  renderNumRange,
  renderDateRange,
  renderCheckboxList
}

const getNumRangeFilter = (min, max, key = 'value') => {
  return data => {
    if (min === '' && max === '') return true
    if (min === '') {
      return data[key] <= max
    } else if (max === '') {
      return data[key] >= min
    } else {
      return data[key] <= max && data[key] >= min
    }
  }
}

const getDateRangeFilter = (start, end) => {
  return data => {
    const isAfterStart = start === null || data.moment.isSameOrAfter(start, 'day')
    const isBeforeStart = end === null || data.moment.isSameOrBefore(end, 'day')
    return isAfterStart && isBeforeStart
  }
}

const getStringMatcher = (type, value) => {
  if (value === '') {
    return () => true
  } else {
    switch (type) {
      case 'contains':
        return data => (data.value + '').toUpperCase().includes(value.toUpperCase())
      case 'doesNotContain':
        return data => !(data.value + '').toUpperCase().includes(value.toUpperCase())
      case 'startsWith':
        return data => (data.value + '').substring(0, value.length) === value
      case 'endsWith':
        return data => {
          let str = data.value + ''
          return str.substring(str.length - value.length) === value
        }
    }
  }
}

export const filterGetters = {
  getNumRangeFilter,
  getDateRangeFilter,
  getStringMatcher,
}
