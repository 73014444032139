import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Container } from 'components/UIElements'
import { DATE_FORMAT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP, MODAL_CONTENT_MAP } from 'utils/constants'

const ParticipantProfile = (props) => {
  const {
    accountSettings,
    canResendInviteOrResetPw,
    closeModal,
    isEmailless,
    openModal,
    participant,
    triggerPasswordReset,
  } = props
  const { last_updated, opt_out } = participant
  const passwordResetDate = moment(last_updated).format(DATE_FORMAT_MAP.main)

  return (
    <Container className='participant-profile'>
      <span>
        {opt_out && (
          <span className='account-disabled'>
            <i className='fas fa-lock' />
            Account disabled
          </span>
        )}
      </span>
      {accountSettings}
      {canResendInviteOrResetPw && !isEmailless && (
        <PasswordReset
          openModal={openModal}
          closeModal={closeModal}
          participant={participant}
          passwordResetDate={passwordResetDate}
          triggerPasswordReset={triggerPasswordReset}
        />
      )}
    </Container>
  )
}

const PasswordReset = ({ closeModal, openModal, participant, passwordResetDate, triggerPasswordReset }) => {
  const onPasswordReset = () => {
    const { username, id } = participant
    openModal({
      content: MODAL_CONTENT_MAP.participantNewPassword,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        triggerPasswordReset({ username, id })
        closeModal()
      },
    })
  }

  return (
    <Container>
      <div className='ptp-pw-reset-section'>
        <div>
          <div className='title'>Password Reset</div>
          <span>{`Last password reset on ${passwordResetDate}`}</span>
        </div>
        <Button id='ptp-pw-reset-button' onClick={onPasswordReset} grey content='Reset Password' />
      </div>
    </Container>
  )
}

ParticipantProfile.propTypes = {
  canResendInviteOrResetPw: PropTypes.bool,
  closeModal: PropTypes.func,
  isEmailless: PropTypes.bool,
  openModal: PropTypes.func,
  participant: PropTypes.shape({
    date_of_birth: PropTypes.string,
    email: PropTypes.string,
    first_login_timestamp: PropTypes.string,
    last_updated: PropTypes.string,
    opt_out: PropTypes.bool,
    phone: PropTypes.string,
    rolled_over_timestamp: PropTypes.string,
  }),
  study: PropTypes.shape({
    cycles: PropTypes.array,
    has_dynamic_cycles: PropTypes.bool,
    platform_name: PropTypes.string,
  }),
  noPII: PropTypes.bool,
  participantDataFields: PropTypes.shape({ disabled: PropTypes.array }),
  triggerPasswordReset: PropTypes.func,
}

PasswordReset.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  participant: PropTypes.shape({
    username: PropTypes.string,
    id: PropTypes.number,
  }),
  passwordResetDate: PropTypes.string,
  triggerPasswordReset: PropTypes.func,
}

export default ParticipantProfile
