import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'components/UIElements'
import { announcementsActions } from '../modules/Announcements'
import AnnouncementsPage from '../components/AnnouncementsPage'

const mapState = state => {
  const { announcements, loading, study, studyLock } = state
  const { locales, currentStudy } = study

  return {
    loading: loading.general,
    locales,
    ...announcements,
    studyLock,
    communication: currentStudy.config?.communication,
    isClinro: currentStudy.config?.clinro,
  }
}

const mapDispatch = announcementsActions

export default connect(
  mapState,
  mapDispatch,
)(props => {
  if (props.loading) return <Loader />
  return <AnnouncementsPage {...props} />
})
