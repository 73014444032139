import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'components/UIElements'
import { FA_ICON_MAP } from 'utils/constants'
import 'styles/d3/overlay.scss'
import { overlayActions } from 'store/overlay'

const Overlay = props => {
  const {
    className,
    children,
    content,
    open = false,
    onDownload,
    maximizeOverlay,
    minimizeOverlay,
    closeOverlay,
    maximized = true,
    nameProps,
    onMaximize = () => {},
  } = props
  const _className = `d3-overlay${className ? ` ${className}` : ''}${maximized ? '' : ' minimized'}`

  const _onMaximize = () => {
    onMaximize()
    maximizeOverlay()
  }
  return open ? (
    <>
      {maximized ? <div className='overlay-bg' /> : null}
      <div className={_className}>
        <div className='overlay-header flexed'>
          {nameProps ? (
            <div className='icon-name flexed start-justified'>
              <i className={FA_ICON_MAP[nameProps.type]} />
              <div className='document-name'>{nameProps.name}</div>
            </div>
          ) : (
            <div />
          )}

          <div className='overlay-buttons'>
            {onDownload ? (
              <Button noStyling onClick={onDownload}>
                <i className={FA_ICON_MAP.download} />
              </Button>
            ) : null}
            {maximized ? (
              <Button noStyling onClick={minimizeOverlay}>
                <i className={FA_ICON_MAP.windowMin} />
              </Button>
            ) : (
              <Button noStyling onClick={_onMaximize}>
                <i className={FA_ICON_MAP.windowMax} />
              </Button>
            )}
            <Button noStyling onClick={closeOverlay}>
              <i className={FA_ICON_MAP.x} />
            </Button>
          </div>
        </div>
        {content || children}
      </div>
    </>
  ) : null
}

Overlay.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  closeOverlay: PropTypes.func,
  content: PropTypes.element,
  maximized: PropTypes.bool,
  maximizeOverlay: PropTypes.func,
  minimizeOverlay: PropTypes.func,
  nameProps: PropTypes.shape({ name: PropTypes.string, type: PropTypes.string }),
  onDownload: PropTypes.func,
  onMaximize: PropTypes.func,
  open: PropTypes.bool,
}

const mapStateToProps = (_, ownProps) => {
  const { disabled: _disabled } = ownProps
  return { disabled: _disabled, ...ownProps }
}

export default connect(mapStateToProps, overlayActions)(Overlay)
