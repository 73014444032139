import { combineReducers } from 'redux'
import request, { generateNotyMessage } from 'utils/request'
import { downloadBlob, pluralize } from 'utils/misc'
import moment from 'moment'
import { today } from 'utils/time'
import { actions as notyActions } from 'layouts/ErrorBox'
import { INSTRUMENT_TYPE_MAP, MODAL_CLASSES_MAP, DATE_FORMAT_MAP } from 'utils/constants'
import { SET_VISIT_TEMPLATES } from '../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import { setAnnouncements } from '../../../../Announcements/routes/AnnouncementsPage/modules/Announcements'
import { getScheduleText } from '../../../utils/PropertyFields'

const { openModal, closeModal } = require('store/modal').modalActions

//
// Actions
//

const SET_INSTRUMENT_LIST = 'SET_INSTRUMENT_LIST'
const UPDATE_WIZARD_TITLE = 'UPDATE_WIZARD_TITLE'
const UPDATE_WIZARD_DISPLAY_NAME = 'UPDATE_WIZARD_DISPLAY_NAME'

//
// Action Creators
//

function updateWizardTitle(title) {
  return {
    type: UPDATE_WIZARD_TITLE,
    title,
  }
}

function updateWizardDisplayName(displayName) {
  return {
    type: UPDATE_WIZARD_DISPLAY_NAME,
    displayName,
  }
}

//
// API Actions
//

const downloadTranslations = (studyID, checkedInstruments) => {
  return dispatch => {
    const promises = Object.keys(checkedInstruments).map(id => {
      return dispatch(_downloadTranslation(studyID, id, checkedInstruments[id]))
    })
    return Promise.all(promises)
  }
}

const _downloadTranslation = (studyID, id, { title }) => {
  return dispatch => {
    return dispatch(
      request({
        url: `/control/studies/${studyID}/instruments/${id}/translations`,
        resType: 'blob',
        success: (blob, fileName) =>
          downloadBlob(blob, `study_${studyID}_${title}_translation_${today(true)}.csv`, fileName),
        fail: () => {
          throw new Error(`Error when downloading instrument ${title}(${id})`)
        },
      }),
    )
  }
}

const downloadInstrumentData = (studyID, siteID, instrumentID, title) => {
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyID}_${title}_${today(true)}.zip`, fileName)
    }
    const fail = res => {
      throw new Error(`${res.status} ${res.statusText} when downloading instrument data`)
    }
    return dispatch(
      request({
        url: `/control/data/task?study_id=${studyID}&site_id=${siteID}&instrument_id=${instrumentID}`,
        resType: 'blob',
        success,
        fail,
      }),
    )
  }
}

const _downloadInstrument = (studyID, instrumentID, instrument) => {
  const { title, type } = instrument
  const downloadType = type.toLowerCase()
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyID}_${title}_${today(true)}.json`, fileName)
    }
    const fail = res => {
      throw new Error(`${res.status} ${res.statusText} when downloading instrument`)
    }
    return dispatch(
      request({
        url: `/control/admin/studies/${studyID}/instruments/${instrumentID}/${downloadType}.json`,
        resType: 'blob',
        success,
        fail,
      }),
    )
  }
}

export const downloadInstrument = (studyID, checkedInstruments) => {
  return dispatch => {
    const promises = Object.keys(checkedInstruments).map(id => {
      return dispatch(_downloadInstrument(studyID, id, checkedInstruments[id]))
    })
    return Promise.all(promises)
  }
}

const openDeleteModal = (studyID, id, callback, success) => {
  const content = `Are you sure you want to delete ${pluralize(
    id.length,
    'this instrument',
    'these instruments',
    false,
  )}? This cannot be undone.`
  return dispatch => {
    dispatch(
      openModal({
        onConfirm: () => {
          if (callback) callback()
          dispatch(deleteInstrument(studyID, id, success))
          dispatch(closeModal())
        },
        onCancel: () => dispatch(closeModal()),
        content,
        className: MODAL_CLASSES_MAP.confirmation,
      }),
    )
  }
}

const deleteInstrument = (studyID, ids, success) => {
  const isMultiple = ids instanceof Array
  const allIds = isMultiple ? ids : [ids]
  return dispatch => {
    const promises = allIds.map(id => dispatch(_deleteInstrument(studyID, id)))
    return Promise.all(promises)
      .then(() => {
        dispatch(
          notyActions.showSuccess({
            text: generateNotyMessage(
              `Instrument${isMultiple ? 's' : ''} successfully deleted`,
              true,
              'fas fa-trash-alt',
            ),
          }),
        )
        return dispatch(fetchInstruments({ studyID, hasLoader: false }))
      })
      .finally(() => {
        if (success) success()
      })
  }
}

const _deleteInstrument = (studyID, id) => {
  return dispatch => {
    return dispatch(
      request({
        method: 'DELETE',
        url: `/control/studies/${studyID}/instruments/${id}`,
      }),
    )
  }
}

export const fetchInstruments = ({ studyID, hasLoader = true, isAnnouncements = false }) => {
  return (dispatch, getState) => {
    const success = jsonBody => {
      if (isAnnouncements) {
        const { sites: { siteSelectionList = [] } = {}, participants: { cohortList = [] } = {} } = getState()
        dispatch(
          setAnnouncements({ announcementList: jsonBody.results, siteSelectionList, cohortList, isInstrument: true }),
        )
      } else {
        dispatch({
          type: SET_INSTRUMENT_LIST,
          payload: jsonBody.results,
        })
      }
    }
    return dispatch(
      request({
        url: `/control/studies/${studyID}/instruments`,
        success,
        hasLoader,
        forceLoader: true,
      }),
    )
  }
}

const uploadTranslation = (studyID, instrument, file) => {
  return dispatch => {
    const success = () => {
      dispatch(fetchInstruments({ studyID }))
    }
    return dispatch(
      request({
        method: 'PUT',
        url: `/control/studies/${studyID}/instruments/${instrument.id}/translations`,
        body: file,
        successMessage: 'Translation successfully uploaded.',
        catchMessage: 'There was a problem uploading the file. Please try again later.',
        hasLoader: true,
        contentType: 'text/csv',
        success,
      }),
    )
  }
}

const getAncestors = ({ item, parentId, list, prevInstrumentIds = new Set(), ancestors = new Set() }) => {
  if (Array.isArray(parentId)) {
    parentId.forEach(parent => {
      const parentItem = list[list.findIndex(el => el.id === parent)]
      if (parentItem) {
        ancestors.add(parentItem.id)
        if (prevInstrumentIds.has(item.id)) {
          return { ancestors }
        }
      } else {
        const isTriggered = !!parentItem?.metadata?.schedule.chain_deployment_info
        if (isTriggered) {
          const grandParentId = parentItem.metadata.schedule.chain_deployment_info.prev_instrument_id
          prevInstrumentIds.add(item.id)
          return getAncestors({ item: parentItem, parentId: grandParentId, list, prevInstrumentIds, ancestors })
        }
      }
    })
  } else {
    const parentItem = list[list.findIndex(el => el.id === parentId)]
    // return item itself if there is no parent item
    if (!parentItem) return { ancestors }

    ancestors.add(parentItem.id)
    // prevent maximum call stack size exceeded errors
    if (prevInstrumentIds.has(item.id)) {
      return { ancestors }
    }

    const isTriggered = !!parentItem.metadata.schedule.chain_deployment_info
    if (isTriggered) {
      const grandParentId = parentItem.metadata.schedule.chain_deployment_info.prev_instrument_id
      prevInstrumentIds.add(item.id)
      return getAncestors({ item: parentItem, parentId: grandParentId, list, prevInstrumentIds, ancestors })
    }
  }
  return { ancestors }
}

const checkTriggersForInstrument = (instrument, currentMetadata) => {
  const { prev_instrument_id } = currentMetadata.schedule.chain_deployment_info
  if (prev_instrument_id) {
    if (Array.isArray(prev_instrument_id)) {
      return prev_instrument_id.includes(instrument.id)
    }
    return prev_instrument_id === instrument.id
  }
  return false
}

const formatInstrumentList = list => {
  const filteredList = list.filter(listItem => listItem.type !== INSTRUMENT_TYPE_MAP.announcement)
  return filteredList.map(item => {
    const { title, updated, deployed, metadata, id, num_sent, num_received, type, is_geofencing_enabled } = item
    const otherLanguages = metadata.other_languages
    const displayName = metadata.display_name
    const lastEdited = moment(updated)
    const scheduleStatus = getScheduleText(metadata, deployed)
    const isConditionalDeployment = !!metadata?.schedule?.chain_deployment_info?.prev_instrument_id
    let taskName = ''
    const { task_name } = metadata

    if (type === INSTRUMENT_TYPE_MAP.task) taskName = task_name
    let prev_instrument_id

    const hasChainDeploymentInfo = !!metadata.schedule.chain_deployment_info
    const triggerExists = hasChainDeploymentInfo
      ? list.findIndex(el => checkTriggersForInstrument(el, metadata)) !== -1
      : false
    const isTriggered = hasChainDeploymentInfo && triggerExists

    // Add parentSortValues to give information to restrict and force "children" rows to be after "parent" rows
    let ancestors = null

    if (isTriggered) {
      prev_instrument_id = metadata.schedule.chain_deployment_info.prev_instrument_id

      /**
       * The ancestors are any triggering instruments that trigger either an instrument or an instrument's triggers.
       * Ancestor information is needed on each instrument in order to prevent infinite loops/cycles in an
       * instrument trigger chain when selecting instrument triggers in the instrument scheduler page.
       */
      const { ancestors: _ancestors } = getAncestors({ item, parentId: prev_instrument_id, list })
      ancestors = _ancestors
    }

    const receiptSortValue = num_sent === 0 ? 0 : num_received / num_sent

    const row = [
      {
        key: 'name',
        value: title,
        sortValue: title,
        otherLanguages,
        displayName,
        isGeofenceEnabled: !!is_geofencing_enabled,
        taskName,
        prev_instrument_id,
      },
      {
        key: 'type',
        value: type,
        sortValue: type,
        isDraft: !deployed,
      },
      {
        key: 'receipt',
        value: { num_received, num_sent },
        sortValue: receiptSortValue,
      },
      {
        key: 'lastEdited',
        value: lastEdited.format(DATE_FORMAT_MAP.mainWithDateTime),
        sortValue: lastEdited.valueOf(),
      },
      {
        key: 'scheduleStatus',
        value: scheduleStatus.text,
        sortValue: scheduleStatus.sortValue,
        isDraft: !deployed,
        isConditionalDeployment,
        visitId: scheduleStatus.visit_id,
      },
      { key: 'action', id, type, title },
    ]

    row.id = id
    row.payload = { title, type }
    row.instrumentTriggerAncestors = ancestors
    return row
  })
}

const generateVisitNameMap = visitSchedulesList => {
  const nameMap = {}
  visitSchedulesList.forEach(visitSchedule => {
    const { visits } = visitSchedule
    visits.forEach(visit => {
      const { id: visitId, name } = visit
      nameMap[visitId] = name
    })
  })
  return nameMap
}

//
// Reducers
//

const instrumentList = (state = [], action) => {
  switch (action.type) {
    case SET_INSTRUMENT_LIST:
      return formatInstrumentList(action.payload)
    default:
      return state
  }
}

const wizardTitle = (state = '', action) => {
  switch (action.type) {
    case UPDATE_WIZARD_TITLE:
      return action.title
    default:
      return state
  }
}
const wizardDisplayName = (state = '', action) => {
  switch (action.type) {
    case UPDATE_WIZARD_DISPLAY_NAME:
      return action.displayName
    default:
      return state
  }
}

const visitNames = (state = {}, action) => {
  switch (action.type) {
    case SET_VISIT_TEMPLATES:
      return generateVisitNameMap(action.visits)
    default:
      return state
  }
}

export default combineReducers({
  instrumentList,
  wizardTitle,
  wizardDisplayName,
  visitNames,
})

export const actions = {
  downloadInstrument,
  downloadInstrumentData,
  deleteInstrument: openDeleteModal,
  fetchInstruments,
  updateWizardTitle,
  updateWizardDisplayName,
  downloadTranslations,
  openModal,
  closeModal,
  uploadTranslation,
}
