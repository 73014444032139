import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Container, Checkbox, Button, CollapseMenu, Radio, SideBar } from 'components/UIElements'
import { deepCompareObj } from 'utils/object'
import { AVAILABLE_INSIGHTS_ROLE_OPTIONS, DEFAULT_INSIGHTS_STUDY_ROLE_ACCESS } from 'utils/constants'
import useCheckboxList, { LIST_STATE } from 'utils/hooks/useCheckboxList'
import InsightsRolePermissions from './InsightsRolePermissions'
import EditInsightsNav from './EditInsightsNav'
import DashboardItem from './DashboardItem'

import '../stylesheets/EditInsights.scss'

const checkboxGroupText = {
  [LIST_STATE.all]: 'Select All',
  [LIST_STATE.none]: 'Deselect All',
  [LIST_STATE.restore]: 'Back to default setting',
}

const showOptions = [
  {
    key: 'all',
    content: 'Show all dashboards to selected user role(s)',
  },
  {
    key: 'custom',
    content: 'Show select dashboards to selected user role(s)',
  },
]

export const getInitialValues = insightsPermissionsList => {
  return insightsPermissionsList.reduce(
    (acc, dashboard, index) => {
      const { study_id, ...args } = dashboard
      acc.data[dashboard.dashboard_name] = { ...args }
      if (
        acc.same &&
        index > 0 &&
        !deepCompareObj(args.hidden_role_ids, insightsPermissionsList[index - 1].hidden_role_ids)
      )
        acc.same = false
      return acc
    },
    {
      data: {},
      same: true,
    },
  )
}

const EditInsights = props => {
  const {
    currentStudy: {
      config: {
        insights: { permissions: insightsPermissionsList, study_level_role_access: studyLevelRoleAccess },
      },
    },
    saveInsightsPermissions,
    params,
    insights,
    loading,
    studyLock,
    readOnly,
  } = props
  const rolesList = AVAILABLE_INSIGHTS_ROLE_OPTIONS
  const {
    checkboxListData,
    checkboxListState,
    checkboxListHandler,
    checkboxListItemHandler,
    checkboxList,
    setCheckboxListData,
  } = useCheckboxList({
    list: rolesList,
    field: 'key',
  })
  const [isSending, setIsSending] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [dashboardRoles, setDashboardRoles] = useState({})
  const [copyDashboardRoles, setCopyDashboardRoles] = useState({})
  const [selectedType, setSelectedType] = useState('all')
  const [rollDown, setRollDown] = useState(false)

  const initialValue = useMemo(() => {
    return getInitialValues(JSON.parse(JSON.stringify(insightsPermissionsList)))
  }, [JSON.stringify(insightsPermissionsList)])

  useEffect(() => {
    if (selectedType === 'custom') {
      const selectedGlobalRoles = AVAILABLE_INSIGHTS_ROLE_OPTIONS.reduce((acc, { key }) => {
        if (!checkboxListData[key]) acc[key] = true
        return acc
      }, {})

      setDashboardRoles(() => {
        const prev = JSON.parse(JSON.stringify(copyDashboardRoles))
        return Object.keys(prev).reduce((acc, dashboardName) => {
          const { hidden_role_ids, ...args } = prev[dashboardName]
          acc[dashboardName] = { ...args, hidden_role_ids: { ...selectedGlobalRoles } }
          acc[dashboardName].hidden_role_ids = Object.keys(prev[dashboardName].hidden_role_ids).reduce(
            (accum, key) => {
              if (checkboxListData[key]) accum[key] = prev[dashboardName].hidden_role_ids[key]
              return accum
            },
            { ...selectedGlobalRoles },
          )
          return acc
        }, {})
      })
    } else {
      setCopyDashboardRoles(dashboardRoles)
      const selectedGlobalRoles = AVAILABLE_INSIGHTS_ROLE_OPTIONS.reduce((acc, { key }) => {
        if (!checkboxListData[key]) acc[key] = true
        return acc
      }, {})
      setDashboardRoles(prev => {
        const prevState = JSON.parse(JSON.stringify(prev))
        const prevKeys = Object.keys(prevState)
        if (!prevKeys.length) return prevState
        return prevKeys.reduce((acc, key) => {
          acc[key] = {
            ...prevState[key],
            hidden_role_ids: {
              ...selectedGlobalRoles,
            },
          }
          return acc
        }, {})
      })
    }
  }, [checkboxListData, selectedType])

  useEffect(() => {
    setDashboardRoles(initialValue.data)
    setCopyDashboardRoles(initialValue.data)
    setSelectedType(initialValue.same ? 'all' : 'custom')
    if (studyLevelRoleAccess) {
      if (Object.keys(studyLevelRoleAccess).length) setCheckboxListData(studyLevelRoleAccess)
    } else {
      setCheckboxListData(DEFAULT_INSIGHTS_STUDY_ROLE_ACCESS)
    }
  }, [JSON.stringify(insightsPermissionsList), checkboxList])

  useEffect(() => {
    const isSameRoleAccess = deepCompareObj(
      Object.keys(checkboxListData).reduce((acc, key) => {
        if (checkboxListData[key]) acc[key] = true
        return acc
      }, {}),
      studyLevelRoleAccess,
    )
    const isSameDashboardRoles = deepCompareObj(dashboardRoles, initialValue.data)
    setDisabled(isSameRoleAccess && isSameDashboardRoles)
  }, [
    JSON.stringify(insightsPermissionsList),
    dashboardRoles,
    checkboxListData,
    selectedType,
    loading,
    JSON.stringify(studyLevelRoleAccess),
  ])

  const onSave = () => {
    const { studyID } = params
    setIsSending(true)
    saveInsightsPermissions(studyID, dashboardRoles, checkboxListData, selectedType, () => setIsSending(false))
  }

  if (!Object.keys(insights?.custom_buttons).length) return null

  return (
    <div className='edit-insights page'>
      <EditInsightsNav {...props} onSave={onSave} disabled={disabled || readOnly} />
      <h3>Insights Properties</h3>
      <p>Configure who can view dashboards and reports related to my study.</p>

      <SideBar
        content={<InsightsRolePermissions studyLock={studyLock} checkboxList={checkboxList} />}
        open
        hideCloseButton
        rollDown={rollDown}
        setRollDown={setRollDown}
        width='auto'
        zIndex={100}
        borderRadius={0}
        boxShadow='0px 2px 4px rgba(0, 0, 0, 0.12)'
        showArrowButton
      />

      <Container className='edit-insights-container'>
        <div className='title'>Access</div>
        <div className='flexed start-justified start-aligned description'>
          <span>Select which user roles should have access to Insights Dashboards.</span>
        </div>
        <Button
          content={checkboxGroupText[checkboxListState]}
          iconBefore={`fas fa-${checkboxListState === LIST_STATE.none ? 'minus' : 'plus'}`}
          className='detect-button'
          noStyling
          onClick={checkboxListHandler}
          disabled={loading || studyLock || readOnly || isSending}
        />
        <div
          className='role-checkboxes'
          style={{ gridTemplateRows: `repeat(${Math.ceil(checkboxList.length / 2)}, 20px)` }}>
          {checkboxList.map(({ key, text }) => (
            <Checkbox
              key={key}
              className='role'
              label={text}
              checked={!!checkboxListData[key]}
              onClick={() => checkboxListItemHandler(key)}
              disabled={loading || studyLock || readOnly || isSending}
            />
          ))}
        </div>
      </Container>

      <Container className='edit-insights-container'>
        <div className='title'>Dashboard Visibility</div>
        <div className='flexed start-justified start-aligned'>
          <span>You can limit access to Insights dashboards for the selected user roles.</span>
        </div>
        <div className='dashboard-show-options'>
          {showOptions.map(({ key, content }) => (
            <Radio
              key={key}
              disabled={loading || studyLock || readOnly || isSending}
              selected={selectedType === key}
              onClick={() => setSelectedType(key)}
              content={content}
            />
          ))}
        </div>

        {selectedType === 'custom' && insights?.custom_buttons && (
          <div className='dashboard-list'>
            {Object.keys(insights?.custom_buttons).map(dashboardName => {
              const selected = dashboardRoles[dashboardName]
                ? Object.keys(dashboardRoles[dashboardName].hidden_role_ids)
                    .filter(key => checkboxListData[key])
                    .map(key => {
                      return rolesList.find(role => role.key == key)?.text
                    })
                : []
              return (
                <React.Fragment key={dashboardName}>
                  <CollapseMenu
                    menuContent={
                      <DashboardItem
                        dashboardName={dashboardName}
                        insight={insights.custom_buttons[dashboardName]}
                        dashboardRoles={dashboardRoles}
                        setDashboardRoles={setDashboardRoles}
                        checkboxListData={checkboxListData}
                        rolesList={rolesList}
                        loading={loading || isSending}
                        studyLock={studyLock}
                      />
                    }
                    subtitleContent={`Hidden from ${selected.length} user role(s)${
                      selected.length ? ': ' + selected.join(', ') : ''
                    }`}
                    titleContent={insights?.custom_buttons[dashboardName]?.button?.content}
                    useErrorIcon
                  />
                </React.Fragment>
              )
            })}
          </div>
        )}
      </Container>

      <div className='button-list'>
        <Button
          id='next'
          className='deploy-button'
          content={isSending ? 'Saving...' : 'Save'}
          disabled={disabled || studyLock || readOnly || isSending || loading}
          onClick={() => onSave()}
        />
      </div>
    </div>
  )
}

EditInsights.propTypes = {
  insightsPermissions: PropTypes.shape({
    permissions: PropTypes.array,
    studyLevelRoleAccess: PropTypes.object,
  }),
  saveInsightsPermissions: PropTypes.func,
  params: PropTypes.shape({
    studyID: PropTypes.string,
  }),
  insights: PropTypes.shape({
    custom_buttons: PropTypes.object,
  }),
  loading: PropTypes.bool,
  studyLock: PropTypes.bool,
  readOnly: PropTypes.bool,
}

export default EditInsights
