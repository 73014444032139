import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import { isSameUser } from 'utils/userUtils'
import MoveParticipantsContainer from './containers/MoveParticipantsContainer'
import { fetchStudies } from '../../../../../../StudiesPage/modules/Studies'
import sitesReducer, { fetchSitesByStudies } from '../../../Sites/routes/SitesPage/modules/Sites'
import { actions } from '../ParticipantsPage/modules/Participants'

// Sync route definition
export default store => ({
  path: 'move',
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        const { study, studies, user } = store.getState()

        setPageTitle(`Move participant | Study ${studyID} | ${study.currentStudy.platform_name}`)
        store.dispatch(setSubroute('move'))
        injectReducers(store, {
          sites: sitesReducer,
        })

        if (isSameUser(user.id) ?? true) {
          if (studies.length === 0) {
            store.dispatch(fetchStudies(false))
          }
          store.dispatch(fetchSitesByStudies(studyID))
          store.dispatch(actions.fetchPtpsByNonCompleteBlockingConsent(studyID))
        }
        // Return Component
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserHasScope(
                ['mp'],
                MoveParticipantsContainer,
                false,
                studyID,
                `/studies/${studyID}/participants`,
                study.currentStudy,
              ),
            ),
          ),
        )
      },
      'participants',
    )
  },
})
