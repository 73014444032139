import React from 'react'
import '../../../stylesheets/Wizard.scss'

const sections = [
  {
    title: 'Dashboard',
    text: 'Dashboard gives you an overview of how the study is going'
  },
  {
    title: 'Instruments',
    text: 'Create a survey or choose a task and deploy to the participants'
  },
  {
    title: 'Participants',
    text: 'Add, edit or remove participants and manage their progress and information'
  }
]

const StudyWizardHeader = () => {
  return (
    <div className='study-wizard-header wizard'>
      <h2>Create your first study</h2>
      <p>Set up your study. Your study will have three major features</p>
      <div className='visual'>
        {sections.map(section => {
          return (
            <div className='box-section' key={`section_${section.title}`}>
              <div className='box' />
              <h4>{section.title}</h4>
              <p>{section.text}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StudyWizardHeader
