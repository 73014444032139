import { connect } from 'react-redux'
import { formatLinkEntities } from 'utils/draft'
import DiaryItem from '../components/DiaryItem'
import { itemActions, mediaActions } from '../modules/Diary'

const mapDispatchToProps = { ...itemActions, ...mediaActions }

const mapStateToProps = (state, ownProps) => {
  const { activeItemId, itemId, instrumentDomains } = ownProps
  const { itemIndex } = ownProps
  const item = state.diaryEditor.diary.questions[itemId]
  const { editorStates, diary } = state.diaryEditor
  const { resource_size_list: resourceSizeList } = diary
  return {
    editorState: formatLinkEntities(editorStates[itemId]),
    editorStates,
    errors: state.diaryEditor.diaryErrors.questions[itemId] || {},
    instrumentDomains,
    isActive: activeItemId === itemId,
    item,
    itemId,
    itemIndex,
    preview: state.location.pathname.includes('preview'),
    questionNumber: state.diaryEditor.diary.questions[itemId].attributes.questionNumber,
    resourceSizeList,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiaryItem)
