import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'
import { GOOGLE_MAPS_URL } from 'utils/constants'
import GeofenceMarker from './GeofenceMarker'
import PlacesAutocompleteSearch from './PlacesAutocompleteSearch'

const MapComponent = withScriptjs(
  withGoogleMap(props => {
    const {
      geofence,
      onPlaceSelected,
      renderMarker,
      // renderGeofences,
      zoom,
      editMode,
      preview = false,
      validateCoords,
      onSetMarker,
      recenter,
      onSetRecenter,
      nameEntered,
    } = props
    const { latitude, longitude } = geofence
    const [currentCenter, setCurrentCenter] = useState({ lat: 40.694055, lng: -73.9839499 })
    const mapEl = useRef(null) // reference to Google Map element

    const placeSearchInput = document.getElementsByClassName('places-search')[0] // ref to Google Autocomplete Search input box

    const onRecenterMap = () => {
      const currentMapCenter = mapEl.current.getCenter()
      const lat = currentMapCenter.lat()
      const lng = currentMapCenter.lng()
      setCurrentCenter({ lat, lng })
    }

    const onSetMarkerAndPopulateNameFields = (coords, isNotPOI) => {
      let placeName = ''
      const { lat, lng } = coords

      /*
      using vanilla DOM querying to prevent the need to make another Google API call to get the selected place's name
      */
      const poiEl = document.getElementsByClassName('poi-info-window')[0]
      const placeTitleEl = poiEl ? poiEl.getElementsByClassName('title full-width')[0] : null

      const isPlace = placeTitleEl ? placeTitleEl.parentNode.style.display !== 'none' : false
      const placeTitle = placeTitleEl ? placeTitleEl.innerText : ''

      const transitEl = poiEl ? poiEl.getElementsByClassName('transit-container')[0] : null
      const isTransit = transitEl ? transitEl.style.display !== 'none' : false

      /*
      Transit name always in the 2nd element with the 'transit-title' class
      */
      const transitTitle = transitEl ? transitEl.getElementsByTagName('span')[1].innerText : ''

      if (isPlace) placeName = placeTitle
      else if (isTransit) placeName = transitTitle
      if (isNotPOI) placeName = ''

      /*
      places the new place's name into the Google search input to give
      user feedback that a new place has been selected
      */
      if (placeSearchInput) placeSearchInput.value = placeName

      onSetMarker({ lat, lng }, placeName, false, nameEntered) // the false bool telling the map to not recenter around the marker and to keep the current center of the map
      validateCoords({ lat, lng })
    }

    const onMapClick = e => {
      onRecenterMap()
      const lat = e.latLng.lat()
      const lng = e.latLng.lng()

      /*
      points of interest (POI) return a placeId in the event, if there is no placeId, it is just coordinates
      */
      const isNotPOI = !e.placeId

      const infoWindowCloseButton = document.getElementsByClassName('gm-ui-hover-effect')[0] // close button of Google Info window
      if (isNotPOI && infoWindowCloseButton) infoWindowCloseButton.click() // closes any Google info window if the placed marker is not a POI

      /*
      setting a place name is delayed to give time for the place marker
      to render and then retrieve the place name from the info box
      */
      setTimeout(() => onSetMarkerAndPopulateNameFields({ lat, lng }, isNotPOI), 50)
    }

    const hasLat = !!latitude || latitude === 0
    const hasLng = !!longitude || longitude === 0

    return (
      <GoogleMap
        ref={mapEl}
        onDragEnd={onRecenterMap}
        onDragStart={() => onSetRecenter(false)} // this prevents the map from sticking to the geofence's coordinates to be the map center on drag of the map
        onClick={e => (preview ? null : onMapClick(e))}
        zoom={editMode || preview ? 15 : zoom}
        defaultZoom={8}
        center={{
          lat: recenter && hasLat ? latitude : currentCenter.lat,
          lng: recenter && hasLng ? longitude : currentCenter.lng,
        }}
        defaultCenter={{ lat: latitude || 40.6940558, lng: longitude || -73.9839499 }}
      >
        {!preview && <PlacesAutocompleteSearch className='places-search' onPlaceSelected={onPlaceSelected} />}
        {/* {renderGeofences()} */}
        {renderMarker(onRecenterMap)}
      </GoogleMap>
    )
  }),
)

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNewMarkerDown: false,
      zoom: 8,
      recenter: true,
    }
    this.mapWrapperEl = null
  }

  onSetRecenter = bool => {
    this.setState({ recenter: bool })
  }

  onSetMarker = (place, placeName, recenterMap = true, nameEntered) => {
    const { setGeofence, geofence, nameRef } = this.props
    const { lat, lng } = place
    this.setState({ recenter: recenterMap }, () => {
      this.setState({
        isNewMarkerDown: true,
      })
    })

    setGeofence({
      ...geofence,
      latitude: lat,
      longitude: lng,
      name: nameEntered ? geofence.name : placeName,
    })
    nameRef.current.focus()
  }

  renderMarker = onRecenterMapCB => {
    const { geofence, editMode, preview, fieldsEntered } = this.props
    const { isNewMarkerDown } = this.state
    const { latitude, longitude, radius } = geofence
    const markerPosition = {
      lat: Number(latitude),
      lng: Number(longitude),
    }

    return isNewMarkerDown || editMode || preview || fieldsEntered ? (
      <GeofenceMarker
        draggable={!preview}
        preview={preview}
        onMarkerDragEnd={e => {
          onRecenterMapCB()
          this.onMarkerDragEnd(e)
        }}
        markerPosition={markerPosition}
        radius={radius}
      />
    ) : null
  }

  //
  // Sets new coordinaates on dragging of marker
  //

  onMarkerDragEnd = event => {
    const { setGeofence, geofence, validateCoords, nameEntered } = this.props
    const placeSearchInput = document.getElementsByClassName('places-search')[0]
    const newLat = event.latLng.lat()
    const newLng = event.latLng.lng()
    if (placeSearchInput) placeSearchInput.value = ''

    setGeofence({
      ...geofence,
      name: nameEntered ? geofence.name : '',
      latitude: newLat,
      longitude: newLng,
    })
    validateCoords({ lat: newLat, lng: newLng })
  }

  //
  // TBD for future if we want to display all geofences
  //

  // renderGeofences = () => {
  //   return dummyGeofenceList.map(geofence => {
  //     const { radius, latitude, name, longitude, id } = geofence
  //     const markerPosition = {
  //       lat: latitude,
  //       lng: longitude,
  //     }
  //     return <GeofenceMarker key={`${name}-${id}`} draggable={false} radius={radius} markerPosition={markerPosition} />
  //   })
  // }

  //
  // Function that runs after a place is searched and selected
  //
  onPlaceSelected = place => {
    const { validateCoords, nameEntered } = this.props
    const { geometry } = place
    const location = geometry ? geometry.location : {}
    const hasLocation = Object.keys(location).length !== 0
    let lat
    let lng
    const valueInSearch = document.getElementsByClassName('places-search')[0].value
    const placeName = valueInSearch.split(',')[0]
    if (hasLocation) {
      lat = location.lat()
      lng = location.lng()
      this.onSetMarker({ lat, lng }, placeName, true, nameEntered)
      this.setState({ zoom: 17 })
      validateCoords({ lat, lng })
    }
  }

  render() {
    const { zoom, recenter } = this.state
    return (
      <div className='map-wrapper'>
        <MapComponent
          {...this.props}
          googleMapURL={GOOGLE_MAPS_URL}
          loadingElement={<div style={{ height: '400px' }} />}
          containerElement={<div style={{ height: '400px', width: 'inherit' }} />}
          mapElement={<div style={{ height: '400px' }} />}
          renderMarker={this.renderMarker}
          renderGeofences={this.renderGeofences}
          onPlaceSelected={this.onPlaceSelected}
          onSetMarker={this.onSetMarker}
          recenter={recenter}
          onSetRecenter={this.onSetRecenter}
          zoom={zoom}
          onRegisterClick={this.onRegisterClick}
        />
      </div>
    )
  }
}

Map.propTypes = {
  geofence: PropTypes.shape({
    name: PropTypes.string,
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setGeofence: PropTypes.func,
  validateCoords: PropTypes.func,
  editMode: PropTypes.bool,
  preview: PropTypes.bool,
  fieldsEntered: PropTypes.bool,
  nameEntered: PropTypes.bool,
  nameRef: PropTypes.shape({
    current: PropTypes.object,
  }),
}

export default Map
