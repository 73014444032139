import { setPageTitle } from 'utils/misc'
import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import LockRedirect from 'containers/lockContainers'
import { actions } from './modules/SelectTask'
import { resetInstrument } from '../../modules/Instrument'
import SelectTaskView from './containers/SelectTaskContainer'

// Sync route definition
export default store => ({
  path: 'selecttask',
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params
        /*  Webpack - use require callback to define
       dependencies for bundling   */
        const { currentStudy } = store.getState().study
        setPageTitle(`Select a Task | Study ${params.studyID} | ${currentStudy.platform_name}`)

        store.dispatch(resetInstrument('TASK'))
        store.dispatch(actions.initializePage())
        store.dispatch(actions.fetchTasks())

        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              LockRedirect(studyID, 'instruments')(UserHasScope(['wa'], SelectTaskView, false, studyID)),
            ),
          ),
        )

        /* Webpack named bundle   */
      },
      'instruments/selecttask',
    )
  },
})
