import { connect } from 'react-redux'
import ActivateUserPage from '../components/ActivateUserPage'
import PermissionDenied from '../../../../../../../../Unauthorized/components/PermissionDenied'
import { actions } from '../modules/ActivateUser'

const mapStateToProps = state => state.activateUser

const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.userToken) {
    return ActivateUserPage(props)
  }
  return PermissionDenied(props)
})
