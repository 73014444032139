import React from 'react'
import { connect } from 'react-redux'

import EmbeddedInsights from '../components/EmbeddedInsights'

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps
  const { studyID: study_id } = params
  const studyID = Number(study_id)
  const { studyDashboard } = state
  const { insights } = studyDashboard || {}
  const { dashboard_id, sisenseUrl } = insights || {}

  return {
    studyID,
    dashboard: dashboard_id,
    sisenseUrl,
  }
}

export default connect(mapStateToProps)(props => {
  return <EmbeddedInsights {...props} />
})
