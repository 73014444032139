const generatePendingPaymentsMap = pendingPaymentsList => {
  const resMap = {}
  pendingPaymentsList.forEach(paymentRow => {
    const actionRow = paymentRow.find(cell => cell.key === 'action')
    const { cycleNo, paymentId } = actionRow
    resMap[paymentId] = cycleNo
  })
  return resMap
}

export default generatePendingPaymentsMap
