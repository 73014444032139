export const OPEN_OVERLAY = 'OPEN_OVERLAY'
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY'
export const MAXIMIZE_OVERLAY = 'MAXIMIZE_OVERLAY'
export const MINIMIZE_OVERLAY = 'MINIMIZE_OVERLAY'

function openOverlay(overlayProps) {
  return {
    type: OPEN_OVERLAY,
    overlayProps,
  }
}

function closeOverlay() {
  return {
    type: CLOSE_OVERLAY,
  }
}

function maximizeOverlay() {
  return {
    type: MAXIMIZE_OVERLAY,
  }
}

function minimizeOverlay() {
  return {
    type: MINIMIZE_OVERLAY,
  }
}

export default function overlayReducer(state = { open: false, maximized: true }, action) {
  switch (action.type) {
    case OPEN_OVERLAY: {
      return { open: true, className: 'universal-overlay', ...action.overlayProps }
    }
    case CLOSE_OVERLAY:
      return { open: false }
    case MAXIMIZE_OVERLAY: {
      return { ...state, maximized: true }
    }
    case MINIMIZE_OVERLAY: {
      return { ...state, maximized: false }
    }
    default:
      return state
  }
}

export const overlayActions = {
  openOverlay,
  closeOverlay,
  maximizeOverlay,
  minimizeOverlay,
}
