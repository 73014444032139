import { browserHistory } from 'react-router'
import request, { generateNotyMessage } from 'utils/request'
import { parseSpecialCharactersOut } from 'utils/misc'
import { fetchUser } from 'store/user'
import { actions as notyActions } from 'layouts/ErrorBox'
import { sendingActions } from 'store/sender'
import { actions as sitesActions, _deleteSiteAndParent, onSetOrphanedParticipants } from '../../SitesPage/modules/Sites'
import { actions as userActions } from '../../../../Users/routes/CreateUser/modules/CreateUser'

const { fetchSites, updateNewSite } = sitesActions
const { fetchUserSites } = userActions

//
// Actions
//

const UPDATE_CURRENT_SITE = 'UPDATE_CURRENT_SITE'
const INITIALIZE_BLANK_SITE = 'INITIALIZE_BLANK_SITE'
const CLEAR_SITE_ERRORS = 'CLEAR_SITE_ERRORS'
const HAS_SITE_ERRORS = 'HAS_SITE_ERRORS'

const updateSite = (key, value) => {
  return {
    type: UPDATE_CURRENT_SITE,
    key,
    value,
  }
}

const createSubPathnameString = string => {
  const resultStr = parseSpecialCharactersOut(string)
  const strArr = resultStr.split(' ')
  return strArr.join('_')
}

const createSiteObject = ({ label, pathName, description, studyID, isVirtual, config }) => {
  const siteObj = _getDefaultSite()
  siteObj.sites.label = label
  siteObj.sites.path = `${studyID ? `${studyID}.` : ``}${pathName}`
  if (description) siteObj.sites.description = description
  if (isVirtual) siteObj.sites.is_virtual = true
  if (config) siteObj.sites.config = config
  return siteObj
}

const _hasSiteErrors = (site, skipContactValidation) => {
  const { config, isVirtual, siteName, country, region } = site

  if (isVirtual) {
    if (!siteName) return true // if the site is virtual, only checks for siteName
  } else if (!siteName && !country && !region) return true

  const { enforced_language, help_and_support = {} } = config
  const { site_phone, support_phone } = help_and_support

  // We will not validate the phone fields if skipContactValidation is true
  if (!skipContactValidation && !site_phone && !support_phone) {
    return true
  }

  // check for that a default language is set
  if (!enforced_language) return false
  const { default_language } = enforced_language
  if (!default_language) return true

  return false
}

const _getDefaultSite = () => {
  return {
    sites: {
      label: '',
      description: '',
      path: '',
      is_virtual: false,
      config: {},
    },
  }
}

export const getSiteIdFromPath = (path, sitesInfo) => {
  const siteInfo = Object.values(sitesInfo).filter(site => site.site_path === path)
  if (siteInfo.length === 0) return path
  return siteInfo[0].site_id
}

export const createSite = (studyID, site, isLeafSite = false, sitesInfo = {}, sitesToDelete = []) => {
  return (dispatch, getState) => {
    const success = payload => {
      dispatch(onSetOrphanedParticipants(payload.has_non_leaf_participants))
      sitesToDelete.unshift(payload.site_id)
      const { userId } = getState().user
      dispatch(fetchUserSites({ studyId: studyID, userId }))
      return Promise.resolve(payload)
    }
    const fail = payload => {
      if (isLeafSite && payload.status === 401) {
        return Promise.reject(site.sites)
      }
      if (payload.status !== 401) {
        sitesToDelete.forEach(siteID => {
          dispatch(_deleteSiteAndParent(studyID, siteID, [], sitesInfo))
        })
        return Promise.reject(site.sites)
      }
      const siteID = getSiteIdFromPath(site.sites.path, sitesInfo)
      if (siteID !== site.sites.path) sitesToDelete.unshift(siteID)
      return Promise.resolve(site.sites)
    }
    dispatch(sendingActions.startSender())
    return dispatch(
      request({
        method: 'POST',
        url: `/control/studies/${studyID}/sites`,
        body: JSON.stringify({ sites: site.sites }),
        failMessage: 'Error creating site',
        successMessage: 'Site successfully created',
        success,
        fail,
        catchMessage: 'Error creating site',
        skipNotySuccess: !isLeafSite,
        hasSender: true,
      }),
    )
  }
}

const createSites = ({ studyID, site, sitesInfo, successCB, link, skipContactValidation }) => {
  const hasSiteErrors = _hasSiteErrors(site, skipContactValidation)

  const { siteName, country, region, description, isVirtual, config } = site

  const countryPathname = createSubPathnameString(country)
  const countrySite = createSiteObject({ label: country, pathName: countryPathname, studyID })
  const virtualPathname = createSubPathnameString(siteName)
  return dispatch => {
    if (!hasSiteErrors) {
      dispatch({ type: CLEAR_SITE_ERRORS })
      const sitesToDeleteOnFail = []
      if (isVirtual) {
        const virtualSite = createSiteObject({
          label: siteName,
          pathName: virtualPathname,
          description,
          studyID,
          isVirtual,
          config,
        })
        dispatch(createSite(studyID, virtualSite, true)).then(payload => {
          if (payload) {
            dispatch(sendingActions.stopSender())
            setTimeout(() => {
              dispatch(sendingActions.resetSender())
              dispatch(fetchSites(studyID))
              dispatch(updateNewSite(payload.site_id))
              dispatch(fetchUser())
              browserHistory.push(link)
            }, 500)
          } else {
            dispatch(sendingActions.resetSender())
          }
          if (successCB) successCB()
        })
      } else {
        dispatch(createSite(studyID, countrySite, false, sitesInfo, sitesToDeleteOnFail))
          .then(payload => {
            const regionPathname = `${payload.path}.${createSubPathnameString(region)}`
            const regionSite = createSiteObject({ label: region, pathName: regionPathname })
            return dispatch(createSite(studyID, regionSite, false, sitesInfo, sitesToDeleteOnFail))
          })
          .then(regionPayload => {
            const pathName = `${regionPayload.path}.${createSubPathnameString(siteName)}`

            const leafSite = createSiteObject({
              label: siteName,
              pathName,
              description,
              studyID: '',
              isVirtual: false,
              config,
            })
            return dispatch(createSite(studyID, leafSite, true, sitesInfo, sitesToDeleteOnFail))
          })
          .then(finalPayload => {
            if (finalPayload) {
              dispatch(sendingActions.stopSender())
              setTimeout(() => {
                dispatch(fetchSites(studyID))
                dispatch(updateNewSite(finalPayload.site_id))
                dispatch(fetchUser())
                browserHistory.push(link)
                dispatch(sendingActions.resetSender())
              }, 500)
            } else {
              dispatch(sendingActions.resetSender())
            }
            if (successCB) successCB()
          })
          .catch(() => {})
      }
    } else {
      dispatch({ type: HAS_SITE_ERRORS })
      dispatch(
        notyActions.showError({ text: generateNotyMessage('Please correct empty fields marked in red.', false) }),
      )
    }
  }
}

const currentSite = (state = _getDefaultSite(), action) => {
  const newState = { ...state }
  switch (action.type) {
    case INITIALIZE_BLANK_SITE:
      return _getDefaultSite()
    case UPDATE_CURRENT_SITE:
      newState[action.key] = action.value
      return newState
    default:
      return state
  }
}

export const actions = {
  createSite,
  updateSite,
  createSites,
}

export default currentSite
