import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, SortableTable } from 'components/UIElements'

const VERSION_KEY_NAME_MAP = {
  consentName: 'Name',
  type: 'Type',
  version: 'Version',
  lastSent: 'Last Sent',
}

const VersionContainer = props => {
  const { consentId, downloadNewConsentPreview, localesMap, studyId, versionHistoryList } = props
  const [tooltipX, setTooltipX] = useState(null)
  const [tooltipY, setTooltipY] = useState(null)

  const headerOptionsSeed = Object.keys(VERSION_KEY_NAME_MAP).map(key => {
    return { key, sortable: false, component: VERSION_KEY_NAME_MAP[key] }
  })

  const renderConsentName = (rowProps, idx) => {
    const { consentVersion, translations, localesMap: langObj, tooltipX: x, tooltipY: y } = rowProps
    let langArr = ['en_US']
    if (translations) langArr = Object.keys(translations)

    const languageTags = langArr.map(languageId => {
      return (
        <Fragment key={`${languageId}-announcement-tag`}>
          <div
            className='tag'
            onMouseMove={e => {
              setTooltipX(e.clientX)
              setTooltipY(e.clientY)
            }}>
            {languageId.toUpperCase()}
          </div>
          <div className='language-name-tooltip' style={{ top: `${y + 10}px`, left: `${x + 10}px` }}>
            {langObj[languageId].text}
          </div>
        </Fragment>
      )
    })

    return (
      <td className='consent-column' key={`row_${idx}`}>
        <div className='flexed start-justified start-aligned'>
          <div>
            <Button
              link
              className='name'
              onClick={() => downloadNewConsentPreview({ studyId, consentId, version: consentVersion })}>
              {rowProps.value}
            </Button>
            <div className='languages flexed start-justified'>{languageTags}</div>
          </div>
        </div>
      </td>
    )
  }

  const renderFunctions = {
    consentName: renderConsentName,
  }

  return (
    <div className='page-break version-container'>
      <h4>Version History</h4>
      <div className='version-table'>
        <SortableTable
          headerOptions={headerOptionsSeed}
          initialOrder='ascending'
          optionalCellProps={{ localesMap, tooltipX, tooltipY }}
          renderFunctions={renderFunctions}
          rowList={versionHistoryList}
          sortingBy='version'
        />
      </div>
    </div>
  )
}

VersionContainer.propTypes = {
  consentId: PropTypes.string,
  downloadNewConsentPreview: PropTypes.func,
  localesMap: PropTypes.objectOf(PropTypes.object),
  studyId: PropTypes.number,
  versionHistoryList: PropTypes.arrayOf(PropTypes.array),
}

export default VersionContainer
