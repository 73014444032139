import React from 'react'
import { modalActions } from 'store/modal'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getBaseSiteIdFromStudy } from 'utils/misc'
import ClinroView from '../components/ClinroView'
import { clinroActions, duplicateItem, imageActions } from '../modules/Clinro'
import { instrumentActions, metadataActions } from '../../../modules/Instrument'
import { downloadInstrument } from '../../../../InstrumentsPage/modules/Instruments'

const mapDispatchToProps = {
  duplicateItem,
  ...clinroActions,
  ...imageActions,
  ...instrumentActions,
  ...metadataActions,
  ...modalActions,
  downloadInstrument,
}

const mapStateToProps = state => {
  const path = state.location.pathname === '/instruments/createclinro' ? 'create' : 'edit'
  const { instrumentReducer, clinroEditor, loading, study } = state
  const { instrument } = instrumentReducer
  const { type, title, url } = instrument

  return {
    id: clinroEditor.clinro.id,
    type,
    url,
    instrumentTitle: title,
    clinro: clinroEditor.clinro,
    article: clinroEditor.article,
    editorStates: clinroEditor.editorStates,
    isLoading: loading.general,
    questionNumbers: clinroEditor.clinro.questionNumbers,
    wordCount: clinroEditor.wordCount,
    version: instrumentReducer.instrument.metadata.version,
    path,
    studyID: study.currentStudy.id,
    siteID: getBaseSiteIdFromStudy(study),
    errors: clinroEditor.clinroErrors,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <ClinroView {...props} />
})
