import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
import NotificationRoute from './NotificationsPage'
import getEditAutoNotificationRoutes from './routes/EditAutoNotification'
import getEditConditionalNotificationRoutes from './routes/EditConditionalNotification'
import conditionalNotificationReducer from './routes/EditConditionalNotification/modules/ConditionalNotification'

// Sync route definition
export default store => {
  injectReducers(store, {
    conditionalNotifications: conditionalNotificationReducer,
  })
  return {
    path: 'notifications',
    indexRoute: NotificationRoute(store),
    getChildRoutes(location, cb) {
      store.dispatch(setSubroute('notifications'))
      cb(null, [
        getEditAutoNotificationRoutes(store, 'incomplete_instrument'),
        getEditAutoNotificationRoutes(store, 'expiring_instrument'),
        getEditAutoNotificationRoutes(store, 'cycle_end'),
        getEditAutoNotificationRoutes(store, 'periodic'),
        getEditConditionalNotificationRoutes(store, 'medication_adherence'),
      ])
    },
  }
}
