import React from 'react'
import PropTypes from 'prop-types'
import '../styles/d3/common.scss'

const StickyHeader = props => {
  let className = 'flexed-header sticky'

  const { className: _className, offset, children } = props

  if (_className) {
    className += ` ${_className}`
  }
  return (
    <div
      className={className}
      style={{
        top: `${offset}px`,
      }}>
      {children}
    </div>
  )
}

StickyHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  offset: PropTypes.number,
}

export default StickyHeader
