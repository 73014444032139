import React from 'react'
import 'styles/d3/menu.scss'

export const Menu = props => {
  const _className = props.className ? ` ${props.className}` : ''
  return (
    <table className={'d3-menu' + _className}>
      <tbody>{props.children}</tbody>
    </table>
  )
}

export const MenuItem = props => {
  let _className = props.className ? ` ${props.className}` : ''
  _className = props.selected ? `${_className} selected` : _className
  return (
    <tr className='clickable'>
      <td data-closetrigger={!props.preventTrigger} className={'menu-item ' + _className} onClick={props.onClick}>
        {props.isTest && <span className='test'>Test</span>}
        {props.content}
      </td>
    </tr>
  )
}
