import { PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP } from '../../ParticipantVisitPage/utils/participantVisitConstants'

const DAY_RELATIVITY_TYPE_MAP = {
  joins: 'joins',
  lastVisit: 'lastVisit',
  completesVisit: 'completesVisit',
}

const calculateVisitDay = ({ dayVal, selectedVisitDayRelativity, visitDayMap, selectedVisit, currentVisitId }) => {
  if (dayVal == null || dayVal === '') return null
  const dayValNum = Number(dayVal)
  switch (selectedVisitDayRelativity) {
    case DAY_RELATIVITY_TYPE_MAP.completesVisit: {
      return dayValNum + visitDayMap[selectedVisit]
    }
    case DAY_RELATIVITY_TYPE_MAP.lastVisit: {
      const visitDayMapCopy = { ...visitDayMap }
      delete visitDayMapCopy[currentVisitId]
      const largestDay = Object.values(visitDayMapCopy).sort((a, d) => d - a)[0]
      return largestDay + dayValNum
    }
    case DAY_RELATIVITY_TYPE_MAP.joins:
    default:
      return dayValNum
  }
}

export const checkIfThereIsOverLap = ({ currentVisitDay, selectedVisitDayMap, templateVisitId }) => {
  const visitDayMap = { ...selectedVisitDayMap, [templateVisitId]: currentVisitDay }
  const daysArr = Object.values(visitDayMap)
  let count = 0
  for (let i = 0; i < daysArr.length; i++) {
    if (daysArr[i] === currentVisitDay) count += 1
    if (count > 1) return true
  }
  return false
}

const _formatIntoTableRow = templateVisit => {
  const { name, visit_day } = templateVisit

  const visitDay = visit_day ?? 'Enter a visit day'
  return [
    {
      key: 'visitName',
      value: name || 'Enter a visit name',
      sortValue: name,
      visitId: null,
      className: 'current-visit',
    },
    {
      key: 'visitDay',
      value: visitDay,
      sortValue: visit_day,
      className: 'visit-day-col current-visit',
    },
  ]
}

export const generateUpdatedTable = ({ templateVisit, initialVisits, isEdit, filter }) => {
  const row = _formatIntoTableRow(templateVisit)
  let tableData = null
  if (!isEdit) {
    tableData = [...Object.values(initialVisits), row]
  } else {
    const { id } = templateVisit
    tableData = Object.values({ ...initialVisits, [id]: row })
  }
  if (!filter) return tableData

  const [filterKey, filterVal] = Object.entries(filter)[0]
  let colIndex = null
  return tableData.filter(dataRow => {
    if (colIndex === null) colIndex = dataRow.findIndex(col => col.key === filterKey)
    return dataRow[colIndex].value === filterVal
  })
}

export const getIsDifferentDay = (moment1, moment2) => {
  if (!moment1 || !moment2) return false
  return !moment1.isSame(moment2, 'day')
}

export const getDayDifference = (moment1, moment2) => {
  if (!moment1 || !moment2) return 0
  return moment2.startOf('day').diff(moment1.startOf('day'), 'days')
}

export const getVisitWindowDeviation = (dayDifference, visitWindow) => {
  return Math.abs(dayDifference) > visitWindow
}

export const getVisitDeviationMap = ptpVisits => {
  const deviatedVisitMap = {}
  ptpVisits.forEach(visit => {
    const proposedVisitCell = visit[PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.proposedDay.index]
    if (proposedVisitCell?.hasDeviation) {
      deviatedVisitMap[visit[PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.visitName.index].visitId] = proposedVisitCell.value
    }
  })
  return deviatedVisitMap
}

export const getNewMetadata = ({ participantVisit = {}, dayDifference, subsequentVisit }) => {
  const newMetadata = {}
  if (subsequentVisit) {
    const { participant_visit_metadata: prevMetadata = {}, visit_datetime, visit_day, visit_window } = subsequentVisit
    const { original_visit_datetime, original_visit_day } = prevMetadata
    const originalVisitDay = original_visit_day || visit_day
    const originalVisitDatetime = original_visit_datetime || visit_datetime

    newMetadata.original_visit_datetime = originalVisitDatetime
    newMetadata.original_visit_day = originalVisitDay
    newMetadata.visit_window_deviation = getVisitWindowDeviation(dayDifference, visit_window)
    newMetadata.new_visit_day = originalVisitDay + dayDifference
  } else {
    const { visit_metadata: prevMetadata = {}, visitDatetime, visitDay, visitWindow } = participantVisit
    const { original_visit_datetime, original_visit_day } = prevMetadata
    const originalVisitDay = original_visit_day ?? visitDay
    newMetadata.original_visit_datetime = original_visit_datetime ?? visitDatetime
    newMetadata.original_visit_day = originalVisitDay
    newMetadata.visit_window_deviation = getVisitWindowDeviation(dayDifference, visitWindow)
    newMetadata.new_visit_day = originalVisitDay + dayDifference
  }
  return newMetadata
}

export default calculateVisitDay
