import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/UIElements'
import { MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, MODAL_CLASSES_MAP } from 'utils/constants'
import { CASE_STATUS_MAP, URGENCY_MAP } from '../../CasesPage/utils/statusMaps'
import StatusTag from './StatusTag'
import ParticipantInfo from './ParticipantInfo'
import CasePromptBar from './CasePromptBar'
import ParticipantReportEvents from './ParticipantReportEvents'
import GeofenceEvents from './GeofenceEvents'
import CaseProgress from './CaseProgress'
import CaseNotes from './CaseNotes'
import '../stylesheets/CasePage.scss'

const EditCasePage = props => {
  const {
    currentCase,
    setNavBarProps,
    params,
    postNote,
    updateCaseProgress,
    openModal,
    isNoPiiUser,
    canWrite,
    studyLock,
  } = props
  const { studyID, siteID, caseId } = params
  const {
    status,
    participant,
    case_progress,
    participant_reports,
    geofence_events,
    notes,
    info,
    case_sequence_num,
  } = currentCase

  const { reportedNo } = info

  const caseClosed = status === CASE_STATUS_MAP.closed

  useEffect(() => {
    if (caseId) {
      setNavBarProps({
        back: `/studies/${studyID}/cases`,
        close: `/studies/${studyID}/cases`,
        backText: 'Cases',
      })
    }
    return () => setNavBarProps(null)
  }, [])

  const closeCase = () => {
    openModal({
      content: MODAL_CONTENT_MAP.closeCase,
      className: MODAL_CLASSES_MAP.closeCase,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      confirmButton: MODAL_BUTTONS_MAP.closeCase,
      closeOnBackgroundClick: true,
      onConfirm: () => updateCaseProgress('closed', true, studyID, caseId),
    })
  }

  const header = (
    <div className='flexed header'>
      <div className='flexed center-aligned'>
        <h3>{`Case ${case_sequence_num}`}</h3>
        <StatusTag status={status} reportedNo={reportedNo} />
      </div>
      {!caseClosed && canWrite && <Button disabled={studyLock} grey id='close-case' content='Close Case' onClick={() => closeCase()} />}
    </div>
  )

  const hasCasePromptBar =
    status === CASE_STATUS_MAP.open_no_symptoms || status === CASE_STATUS_MAP.closed || status === CASE_STATUS_MAP.open

  return (
    <div className='case page'>
      {header}
      {hasCasePromptBar && <CasePromptBar status={status} caseId={case_sequence_num} caseProgress={case_progress} />}
      <div className='flexed main start-aligned'>
        <div className='flexed column left'>
          <ParticipantInfo
            participant={participant}
            params={params}
            isNoPiiUser={isNoPiiUser}
            canWrite={canWrite}
            studyLock={studyLock}
          />
          <ParticipantReportEvents urgency={URGENCY_MAP[status]} participantReports={participant_reports} />
          <GeofenceEvents
            urgency={URGENCY_MAP[status]}
            geofenceEvents={geofence_events}
            participantReports={participant_reports}
          />
        </div>
        <div className='flexed column right'>
          <CaseProgress
            params={params}
            info={info}
            status={status}
            caseClosed={caseClosed}
            caseProgress={case_progress}
            geofenceEvents={geofence_events}
            participantReports={participant_reports}
            updateCaseProgress={(_status, confirmation) => updateCaseProgress(_status, confirmation, studyID, caseId)}
            canWrite={canWrite}
            studyLock={studyLock}
          />
          <CaseNotes notes={notes} params={params} postNote={postNote} canWrite={canWrite} studyLock={studyLock} />
        </div>
      </div>
    </div>
  )
}

EditCasePage.propTypes = {
  setNavBarProps: PropTypes.func,
  updateCaseProgress: PropTypes.func,
  openModal: PropTypes.func,
  params: PropTypes.shape({
    studyID: PropTypes.string,
    siteID: PropTypes.string,
    caseId: PropTypes.string,
  }),
  currentCase: PropTypes.shape({
    id: PropTypes.string,
    case_sequence_num: PropTypes.number,
    status: PropTypes.string,
    participant: PropTypes.object,
    case_progress: PropTypes.array,
    participant_reports: PropTypes.array,
    geofence_events: PropTypes.array,
    notes: PropTypes.array,
    info: PropTypes.object,
  }),
  postNote: PropTypes.func,
  isNoPiiUser: PropTypes.bool,
  canWrite: PropTypes.bool,
  studyLock: PropTypes.bool,
}

export default EditCasePage
