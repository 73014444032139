import React from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/notifications.scss'
import AutoNotifications from './AutoNotifications'
import ConditionalNotifications from './ConditionalNotifications'

const NotificationsPage = ({
  autoNotifications,
  canEditNotifications,
  canViewConditionalNotifications,
  conditionalNotifications,
  hasConditionalNotifications,
  params,
  studyLock,
  instrumentArchitectureVersion,
}) => {
  const { studyID, siteID } = params
  return (
    <div className='notifications page'>
      {hasConditionalNotifications && canViewConditionalNotifications && (
        <ConditionalNotifications
          conditionalNotifications={conditionalNotifications}
          siteID={siteID}
          studyID={studyID}
          studyLock={studyLock}
        />
      )}
      <AutoNotifications
        studyID={studyID}
        siteID={siteID}
        autoNotifications={autoNotifications}
        canEditNotifications={canEditNotifications}
        instrumentArchitectureVersion={instrumentArchitectureVersion}
      />
    </div>
  )
}

NotificationsPage.propTypes = {
  autoNotifications: PropTypes.objectOf(PropTypes.object),
  canEditNotifications: PropTypes.bool,
  conditionalNotifications: PropTypes.objectOf(PropTypes.object),
  params: PropTypes.shape({
    siteID: PropTypes.string,
    studyID: PropTypes.string,
  }),
  canViewConditionNotifications: PropTypes.bool,
  hasConditionalNotifications: PropTypes.bool,
  studyLock: PropTypes.bool,
}

export default NotificationsPage
