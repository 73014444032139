import { combineReducers } from 'redux'
import { actions as notyActions } from 'layouts/ErrorBox'
import { EditorState } from 'draft-js'
import { HTMLToEditorState, EditorStateToHTML, formatLinkEntities } from 'utils/draft'
import { stateToHTML } from 'draft-js-export-html'
import { modalActions } from 'store/modal'
import request, { generateNotyMessage } from 'utils/request'
import { getConsentErrors } from 'utils/instrumentValidation'
import { downloadBlob } from 'utils/misc'
import { today } from 'utils/time'
import {
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  MODAL_CONTENT_MAP,
  QUESTION_TYPE_MAP,
  QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS,
} from 'utils/constants'
import { _getDefaultMetadata } from '../../../../Instruments/utils/PropertyFields'
import { validateAndFixQuestionAndChoiceIdMismatches } from '../../../../Instruments/routes/Instrument/routes/EditSurvey/modules/Survey'
import { getRandomQuestionId } from '../../../../../utils/getRandomQuestionId'

// Consent
export const INITIALIZE_CONSENT_EDIT = 'INITIALIZE_CONSENT_EDIT'
const INITIALIZE_BLANK_CONSENT = 'INITIALIZE_BLANK_CONSENT'
const UPDATE_CONSENT_TITLE = 'UPDATE_CONSENT_TITLE'
const TOGGLE_CONSENT_EDIT = 'TOGGLE_CONSENT_EDIT'
const UPDATE_CONSENT_ITEM = 'UPDATE_CONSENT_ITEM'
const UPDATE_CONSENT_ITEM_LABEL = 'UPDATE_CONSENT_ITEM_LABEL'
const CHANGE_CONSENT_ITEM_TYPE = 'CHANGE_CONSENT_ITEM_TYPE'
const TOGGLE_REQUIRED_CONSENT_QUESTION = 'TOGGLE_REQUIRED_CONSENT_QUESTION'
const ADD_CONSENT_ITEM = 'ADD_CONSENT_ITEM'
const DELETE_CONSENT_ITEM = 'DELETE_CONSENT_ITEM'
const MOVE_CONSENT_ITEM = 'MOVE_CONSENT_ITEM'
const DUPLICATE_CONSENT_ITEM = 'DUPLICATE_CONSENT_ITEM'
const ADD_CONSENT_CHOICE = 'ADD_CONSENT_CHOICE'
const ADD_CONSENT_CHOICE_ON_PASTE = 'ADD_CONSENT_CHOICE_ON_PASTE'
const ADD_OTHER_CONSENT_CHOICE = 'ADD_OTHER_CONSENT_CHOICE'
const DELETE_OTHER_CONSENT_CHOICE = 'DELETE_OTHER_CONSENT_CHOICE'
const UPDATE_OTHER_CONSENT_CHOICE = 'UPDATE_OTHER_CONSENT_CHOICE'
const UPDATE_CONSENT_CHOICE_LABEL = 'UPDATE_CONSENT_CHOICE_LABEL'
const DELETE_CONSENT_CHOICE = 'DELETE_CONSENT_CHOICE'
const MOVE_CONSENT_CHOICE = 'MOVE_CONSENT_CHOICE'
const UPDATE_CONSENT_LOGIC_CONDITION = 'UPDATE_CONSENT_LOGIC_CONDITION'
const ADD_CONSENT_LOGIC_CONDITION = 'ADD_CONSENT_LOGIC_CONDITION'
const ADD_CONSENT_LOGIC_GROUP = 'ADD_CONSENT_LOGIC_GROUP'
const DELETE_CONSENT_LOGIC_CONDITION = 'DELETE_CONSENT_LOGIC_CONDITION'
const DELETE_ALL_CONSENT_LOGIC = 'DELETE_ALL_CONSENT_LOGIC'
const CHANGE_CONSENT_LOGIC_OPERATOR = 'CHANGE_CONSENT_LOGIC_OPERATOR'
const DELETE_INVALID_CONSENT_LOGIC = 'DELETE_INVALID_CONSENT_LOGIC'
const UPDATE_ORIGINAL_CONSENT = 'UPDATE_ORIGINAL_CONSENT'
const UPDATE_CONSENT_UNIT_TYPE = 'UPDATE_CONSENT_UNIT_TYPE'
const SET_CONSENT_EVENTS = 'SET_CONSENT_EVENTS'
const RESET_CONSENT_DASHBOARD = 'RESET_CONSENT_DASHBOARD'

// Errors
const ADD_CONSENT_ERRORS = 'ADD_CONSENT_ERRORS'
const CLEAR_CONSENT_ERROR = 'CLEAR_CONSENT_ERROR'
const CLEAR_CONSENT_QUESTION_ERROR = 'CLEAR_CONSENT_QUESTION_ERROR'

//
// Action Creators
//

// Consent Actions
function initializeEdit(json, _editorStates) {
  return {
    type: INITIALIZE_CONSENT_EDIT,
    json,
    editorStates: _editorStates,
  }
}

function initializeBlankConsent(title = '') {
  return {
    type: INITIALIZE_BLANK_CONSENT,
    title,
  }
}

export function resetConsentDashboard() {
  return {
    type: RESET_CONSENT_DASHBOARD,
  }
}

export function updateTitle(title) {
  return {
    type: UPDATE_CONSENT_TITLE,
    title,
  }
}

// Item Actions

function toggleEdit(itemId) {
  return {
    type: TOGGLE_CONSENT_EDIT,
    itemId,
  }
}

function addItem({ itemType, index, newQId }) {
  const choiceOneId = `${newQId}_${getRandomQuestionId()}`
  const choiceTwoId = `${newQId}_${getRandomQuestionId()}`
  return {
    type: ADD_CONSENT_ITEM,
    itemType,
    newQId,
    index,
    choiceOneId,
    choiceTwoId,
  }
}

function deleteItem(itemId) {
  return {
    type: DELETE_CONSENT_ITEM,
    itemId,
  }
}

function addChoice(itemId, choicesLength, insertIdx) {
  return {
    type: ADD_CONSENT_CHOICE,
    itemId,
    insertIdx,
    choicesLength,
  }
}

function addChoiceOnPaste(itemId, pasteText) {
  return {
    type: ADD_CONSENT_CHOICE_ON_PASTE,
    itemId,
    pasteText,
  }
}

function addOtherChoice(itemId) {
  return {
    type: ADD_OTHER_CONSENT_CHOICE,
    itemId,
  }
}

function deleteOtherChoice(itemId) {
  return {
    type: DELETE_OTHER_CONSENT_CHOICE,
    itemId,
  }
}

function updateOtherChoice(item, itemId, otherValue) {
  return {
    type: UPDATE_OTHER_CONSENT_CHOICE,
    item,
    itemId,
    otherValue,
  }
}

function deleteChoice(itemId, choiceId) {
  return {
    type: DELETE_CONSENT_CHOICE,
    itemId,
    choiceId,
  }
}

function updateChoiceLabel(item, itemId, choiceId, newLabel) {
  return {
    type: UPDATE_CONSENT_CHOICE_LABEL,
    item,
    itemId,
    choiceId,
    newLabel,
  }
}

function moveChoice(itemId, startIdx, endIdx) {
  return {
    type: MOVE_CONSENT_CHOICE,
    itemId,
    startIdx,
    endIdx,
  }
}

function changeItemType(itemId, prevType, nextType) {
  return {
    type: CHANGE_CONSENT_ITEM_TYPE,
    itemId,
    prevType,
    nextType,
  }
}

function toggleRequiredQuestion(itemId) {
  return {
    type: TOGGLE_REQUIRED_CONSENT_QUESTION,
    itemId,
  }
}

function moveItem(startIdx, endIdx) {
  return {
    type: MOVE_CONSENT_ITEM,
    startIdx,
    endIdx,
  }
}

export function duplicateItem(itemId, editorState, choiceQProps) {
  const newQId = getRandomQuestionId()
  if (choiceQProps) {
    /**
     * we will keep choices editor states in here for the future when there is
     * rich text implemented for the clinro choices
     *  */
    const { choicesOrder, choicesEditorStates } = choiceQProps
    const newChoicesIdMap = {}
    choicesOrder.forEach(choiceId => {
      const newChoiceId = `${newQId}_${getRandomQuestionId()}`
      newChoicesIdMap[choiceId] = newChoiceId
    })
    return {
      type: DUPLICATE_CONSENT_ITEM,
      choicesEditorStates,
      hasChoices: true,
      choicesOrder,
      editorState,
      itemId,
      newChoicesIdMap,
      newQId,
    }
  }
  // We'll keep this return below for when we add question types without choices.
  return {
    type: DUPLICATE_CONSENT_ITEM,
    itemId,
    newQId,
    editorState,
  }
}

function updateUnitType(itemId, unit) {
  return {
    type: UPDATE_CONSENT_UNIT_TYPE,
    itemId,
    unit,
  }
}

// Error actions

function addConsentErrors(errors) {
  return {
    type: ADD_CONSENT_ERRORS,
    errors,
  }
}

function checkLogicAndToggleRequired(survey, itemId) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnToggleRequired(survey.questions, survey.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(toggleRequiredQuestion(itemId))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.actionInvalidate,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(toggleRequiredQuestion(itemId))
    }
  }
}

function checkLogicAndDeleteItem(survey, itemId) {
  return dispatch => {
    let onConfirm = () => {
      dispatch(deleteItem(itemId))
    }
    let content = 'Are you sure you want to delete this item?'
    let confirmButton = 'Yes'
    let cancelButton = 'No'
    const invalidConditions = findInvalidLogicOnDelete(survey.questions, survey.order[0], itemId)
    if (Object.keys(invalidConditions).length > 0) {
      onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(deleteItem(itemId))
      }
    }
    dispatch(
      modalActions.openModal({
        content: MODAL_CONTENT_MAP.logicInvalidatedByMove,
        confirmButton: MODAL_BUTTONS_MAP.proceed,
        cancelButton: MODAL_BUTTONS_MAP.cancel,
        className: MODAL_CLASSES_MAP.confirmation,
        onConfirm,
      }),
    )
  }
}

function checkLogicAndMoveItem(survey, startIdx, endIdx) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnMove(survey.questions, survey.order[0], startIdx, endIdx)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(moveItem(startIdx, endIdx))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByMove,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(moveItem(startIdx, endIdx))
    }
  }
}

function checkLogicAndDeleteChoice(survey, itemId, choiceId) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnChoiceDelete(survey.questions, survey.order[0], itemId, choiceId)
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(deleteChoice(itemId, choiceId))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByDeletion,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(deleteChoice(itemId, choiceId))
    }
  }
}

function checkLogicAndChangeItemType(survey, itemId, prevType, nextType) {
  return dispatch => {
    const invalidConditions = findInvalidLogicOnChangeItemType(
      survey.questions,
      survey.order[0],
      itemId,
      prevType,
      nextType,
    )
    if (Object.keys(invalidConditions).length > 0) {
      const onConfirm = () => {
        dispatch(deleteInvalidLogic(invalidConditions))
        dispatch(changeItemType(itemId, prevType, nextType))
      }
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.logicInvalidatedByType,
          confirmButton: MODAL_BUTTONS_MAP.proceed,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.confirmation,
          onConfirm,
        }),
      )
    } else {
      dispatch(changeItemType(itemId, prevType, nextType))
    }
  }
}

function logicForEach(terms, callback, path = []) {
  if (!terms) return
  terms.forEach((term, index) => {
    if (term.comparator === 'boolean_combine') {
      logicForEach(term.terms, callback, path.slice().concat(index))
    } else {
      callback(term, path.slice().concat(index))
    }
  })
}

export function findInvalidLogicOnMove(questions, order, startIdx, endIdx) {
  const invalidConditions = {}
  if (startIdx > endIdx) {
    const itemId = order[startIdx]
    if (!questions[itemId].logic.show_if) return invalidConditions
    const currentItemRefs = {}
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      currentItemRefs[term.question_id] = currentItemRefs[term.question_id] || []
      currentItemRefs[term.question_id].push(path)
    })
    for (let i = endIdx; i < startIdx; i++) {
      if (order[i] in currentItemRefs) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId] = invalidConditions[itemId].concat(currentItemRefs[order[i]])
      }
    }
  } else {
    for (let i = startIdx + 1; i <= endIdx; i++) {
      if (!questions[order[i]].logic.show_if) continue
      logicForEach(questions[order[i]].logic.show_if.terms, (term, path) => {
        if (term.question_id === order[startIdx]) {
          invalidConditions[order[i]] = invalidConditions[order[i]] || []
          invalidConditions[order[i]].push(path)
        }
      })
    }
  }
  return invalidConditions
}

export function findInvalidLogicOnDelete(questions, order, deleteId) {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (deleteId === term.question_id) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnToggleRequired(questions, order, toggleId) {
  const invalidConditions = {}
  if (questions[toggleId].attributes.required) return invalidConditions
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (toggleId === term.question_id && term.comparator.includes('answered')) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnChoiceDelete(questions, order, itemId, choiceId) {
  const invalidConditions = {}
  order.forEach(itemId => {
    if (!questions[itemId].logic.show_if) return
    logicForEach(questions[itemId].logic.show_if.terms, (term, path) => {
      if (term.value === choiceId) {
        invalidConditions[itemId] = invalidConditions[itemId] || []
        invalidConditions[itemId].push(path)
      }
    })
  })
  return invalidConditions
}

export function findInvalidLogicOnChangeItemType(questions, order, itemId, prevType, nextType) {
  if (prevType === nextType) return {}
  const invalidConditions = {}
  if (prevType !== nextType) {
    order.forEach(id => {
      if (!questions[id].logic.show_if) return
      logicForEach(questions[id].logic.show_if.terms, (term, path) => {
        if (!term.comparator.includes('answered') && term.question_id === itemId) {
          invalidConditions[id] = invalidConditions[id] || []
          invalidConditions[id].push(path)
        }
      })
    })
  }
  return invalidConditions
}

function deleteInvalidLogic(invalidConditions) {
  return {
    type: DELETE_INVALID_CONSENT_LOGIC,
    invalidConditions,
  }
}

function updateItemLabel(itemId, oldEditorState, newEditorState) {
  return {
    type: UPDATE_CONSENT_ITEM_LABEL,
    itemId,
    oldEditorState,
    newEditorState,
  }
}

function updateItem(itemId, item) {
  return {
    type: UPDATE_CONSENT_ITEM,
    itemId,
    item,
  }
}

// Logic Actions
function updateLogicCondition(itemId, logic, conditionPath, field, value) {
  return {
    type: UPDATE_CONSENT_LOGIC_CONDITION,
    itemId,
    conditionPath,
    field,
    value,
    logic,
  }
}

function addLogicCondition(itemId, groupPath) {
  return {
    type: ADD_CONSENT_LOGIC_CONDITION,
    itemId,
    groupPath,
  }
}

function addLogicGroup(itemId) {
  return {
    type: ADD_CONSENT_LOGIC_GROUP,
    itemId,
  }
}

function deleteLogicCondition(itemId, conditionPath) {
  return {
    type: DELETE_CONSENT_LOGIC_CONDITION,
    itemId,
    conditionPath,
  }
}

function deleteAllLogic(itemId) {
  return {
    type: DELETE_ALL_CONSENT_LOGIC,
    itemId,
  }
}

function changeLogicOperator(itemId, groupPath, value) {
  return {
    type: CHANGE_CONSENT_LOGIC_OPERATOR,
    itemId,
    groupPath,
    value,
  }
}

//
// API Actions
//

const downloadTranslations = (studyID, checkedInstruments) => {
  return dispatch => {
    const promises = Object.keys(checkedInstruments).map(id => {
      return dispatch(_downloadTranslation(studyID, id, checkedInstruments[id]))
    })
    return Promise.all(promises)
  }
}

const downloadConsentTranslation = studyID => {
  const success = (blob, fileName) => {
    downloadBlob(blob, `study_${studyID}_consent_translation_${today(true)}.csv`, fileName)
  }

  return dispatch => {
    return dispatch(
      request({
        url: `/control/studies/${studyID}/consent_translation`,
        resType: 'blob',
        success,
        fail: () => {
          throw new Error(`Error when downloading consent(${id})`)
        },
      }),
    )
  }
}

export function fetchConsent(studyID) {
  return dispatch => {
    // const versionText = version ? `?version=${version}` : ''

    function success(json) {
      const { formattedJSON, editorStates: _editorStates } = prepareConsent(json)
      calcQuestionNumbers(formattedJSON.order, formattedJSON.questions)
      dispatch(initializeEdit(formattedJSON, _editorStates))
    }

    function fail(res) {
      throw new Error(`${res.status} ${res.statusText} when fetching eConsent`)
    }

    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/consent_form`,
        failMessage: 'Error fetching eConsent.',
        success,
        fail,
        hasLoader: true,
        forceLoader: true,
      }),
    )
  }
}

const uploadTranslation = (studyID, file) => {
  return dispatch => {
    return dispatch(
      request({
        method: 'PUT',
        url: `/control/studies/${studyID}/consent_translation`,
        body: file,
        success: () => dispatch(fetchConsent(studyID)),
        successMessage: 'Translation successfully uploaded.',
        catchMessage: 'There was a problem uploading the file. Please try again later.',
        hasLoader: true,
        contentType: 'text/csv',
      }),
    )
  }
}

export function validateConsentAndSave({ studyID, consentReducer, onRedirect }) {
  return dispatch => {
    const errors = getConsentErrors({
      consent: consentReducer.consent,
      editorStates: consentReducer.editorStates,
      ignoreScheduling: true,
    })
    const valid = !(Object.keys(errors).length > 1 || Object.keys(errors.questions).length > 0)
    if (valid) {
      dispatch(saveConsentToDatabase({ studyID, ...consentReducer })).then(onRedirect)
    } else {
      dispatch(addConsentErrors(errors))
      dispatch(
        notyActions.showError({
          text: generateNotyMessage('Please check errors before proceeding.', false),
        }),
      )

      if (errors.noUnskippableSignatureErr) {
        dispatch(
          modalActions.openModal({
            content: errors.noUnskippableSignatureErr,
            confirmButton: MODAL_BUTTONS_MAP.okay,
            className: MODAL_CLASSES_MAP.confirmation,
            width: '640px',
            hideCancelButton: true,
          }),
        )
      }
    }
  }
}

export function saveConsentToDatabase({ consent, editorStates, studyID }) {
  return dispatch => {
    const consentJSON = JSON.parse(JSON.stringify(consent))
    cleanUpConsent(consentJSON)
    // consentJSON.metadata.display_name = consentJSON.metadata.display_name || consentJSON.title

    /**
     * This function will resolve any mismatches between a question's ID and
     * its choices' IDs.
     *  */
    validateAndFixQuestionAndChoiceIdMismatches(consentJSON)

    convertLabelsToHTML(consentJSON.questions, editorStates)
    // if (!consentJSON.id) consentJSON.metadata.display_name = consentJSON.title

    function success(resJSON) {
      dispatch({
        type: UPDATE_ORIGINAL_CONSENT,
        json: consent,
      })
      return Promise.resolve(resJSON)
    }

    const body = JSON.stringify(consentJSON)

    return dispatch(_saveConsent(studyID, body, null)).then(success)
  }
}

export const downloadConsentPreview = (data, studyID) => {
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyID}_${data.title}_${today(true)}.pdf`, fileName)
    }
    return dispatch(
      request({
        method: 'POST',
        url: `/control/consent_preview`,
        body: JSON.stringify(data),
        resType: 'blob',
        success,
      }),
    )
  }
}

export function fetchConsentEvents(studyID) {
  return dispatch => {
    const success = json => {
      dispatch({
        type: SET_CONSENT_EVENTS,
        json,
      })
    }
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/consent_events_list`,
        success,
      }),
    )
  }
}

export function saveUploadToDatabase(studyID, uploadedConsent) {
  return dispatch => {
    const body = new FormData()
    body.set('format', uploadedConsent.format)
    body.set('data', uploadedConsent.data)
    return dispatch(_saveConsent(studyID, body, uploadedConsent.id, null))
  }
}

export function _saveConsent(studyID, body, consentID, contentType) {
  return dispatch => {
    const url = `/control/studies/${studyID}/consent_form`
    return dispatch(
      request({
        url,
        body,
        method: 'POST',
        successMessage: 'eConsent saved successfully.',
        hasLoader: true,
        failMessage: 'Failed to save eConsent to database.',
        success: json => json,
        contentType,
      }),
    )
  }
}

export const downloadParticipantConsent = (studyID, ptpID, { fname, lname }) => {
  return dispatch => {
    const success = (blob, fileName) => {
      downloadBlob(blob, `study_${studyID}_${fname}_${lname}_Consent.pdf`, fileName)
    }
    return dispatch(
      request({
        url: `/control/studies/${studyID}/participants/${ptpID}/consent`,
        resType: 'blob',
        success,
      }),
    )
  }
}

//
// State Generators
//

function generateBlankConsent(initialTitle) {
  return {
    title: initialTitle || '',
    type: 'CONSENT',
    metadata: _getDefaultMetadata('CONSENT'),
    order: [[]],
    questions: {},
  }
}

//
// Util Functions
//

export function prepareConsent(json) {
  const editorStates = {}
  for (const qId in json.questions) {
    const question = json.questions[qId]
    editorStates[qId] = formatLinkEntities(HTMLToEditorState(question.label))
    question.label = stateToHTML(editorStates[qId].getCurrentContent())

    // convert note to introdußction
    // if (question.type === 'note') {
    //   question.type = 'introduction'
    // } else if (question.type === 'select_one' && question.attributes.appearance === 'likert') {
    //   question.type = 'likert'
    // }
    delete question.attributes.appearance
    // encapsulate logic conditions if they aren't in a boolean combine
    if (Object.keys(question.logic).length > 0 && question.logic.show_if.comparator !== 'boolean_combine') {
      question.logic.show_if = {
        comparator: 'boolean_combine',
        operator: 'and',
        terms: [question.logic.show_if],
      }
    }
  }
  return {
    formattedJSON: json,
    editorStates,
  }
}

function cleanUpConsent(_consent) {
  // Remove unnecessary fields
  delete _consent.activeItemId
  for (const qId in _consent.questions) {
    delete _consent.questions[qId].attributes.canHaveLogic
    delete _consent.questions[qId].attributes.questionNumber
  }
}

const prepareRespondenceData = consentEventsData => {
  const {
    participants_accepted: accepted,
    participants_logged_in_but_not_completed: loggedInNotCompleted,
    participants_logged_in_rejected: rejected,
    participants_not_logged_in: noLogin,
    total_participants: total,
  } = consentEventsData.consent_events_data

  const lastSent = consentEventsData.latest_first_login

  const _respondence = {
    accepted,
    completed: accepted + rejected,
    noLogin,
    loggedInNotCompleted,
    rejected,
    total,
    lastSent,
  }

  return _respondence
}

function convertLabelsToHTML(questions, editorStates) {
  for (const key in questions) {
    questions[key].label = EditorStateToHTML(editorStates[key])
  }
}

function countWordsInConsent(consent) {
  return consent.order[0].reduce((sum, itemId) => {
    return sum + countWords(consent.questions[itemId].label)
  }, 0)
}

function countWords(string) {
  if (string === '') return 0
  return string.trim().split(' ').length
}

export function calcQuestionNumbers(order, questions) {
  let count = 0
  order[0].forEach(id => {
    if (!QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS.includes(questions[id].type)) {
      count++
      questions[id].attributes.questionNumber = count
      questions[id].attributes.canHaveLogic = count > 1
    } else {
      questions[id].attributes.canHaveLogic = count >= 1
    }
  })
}

function dupStateAndItem(state, itemId) {
  const newState = { ...state }
  newState.questions = { ...newState.questions }
  newState.questions[itemId] = { ...newState.questions[itemId] }
  return newState
}

function getCondition(terms, conditionPath) {
  if (conditionPath.length === 1) {
    return terms[conditionPath[0]]
  }
  return getCondition(terms[conditionPath[0]].terms, conditionPath.slice(1))
}

function setCondition(terms, conditionPath, newValue) {
  if (conditionPath.length === 1) {
    terms[conditionPath[0]] = newValue
  } else {
    setCondition(terms[conditionPath[0]].terms, conditionPath.slice(1), newValue)
  }
}

function addCondition(terms, groupPath) {
  if (groupPath.length === 1) {
    terms[groupPath[0]].terms.push({
      question_type: '',
      question_id: '',
      value: '',
      comparator: '',
    })
  } else {
    addCondition(terms[groupPath[0]].terms, groupPath.slice(1))
  }
}

function deleteCondition(showIf, conditionPath) {
  if (conditionPath.length === 1) {
    showIf.terms.splice(conditionPath[0], 1)
  } else {
    deleteCondition(showIf.terms[conditionPath[0]], conditionPath.slice(1))
    if (showIf.terms[conditionPath[0]].terms.length === 0) {
      showIf.terms.splice(conditionPath[0], 1)
    }
  }
}

function removeLoneGroup(logic) {
  if (logic.show_if.terms.length < 1) {
    delete logic.show_if
  } else if (logic.show_if.terms[0].comparator === 'boolean_combine' && logic.show_if.terms.length === 1) {
    logic.show_if.terms = logic.show_if.terms[0].terms
  }
}

function setOperator(logic, groupPath, value) {
  if (groupPath.length === 0) {
    logic.operator = value
  } else {
    setOperator(logic.terms[groupPath[0]], groupPath.slice(1), value)
  }
}

//
// Reducers
//

export function consent(state = generateBlankConsent(), action) {
  switch (action.type) {
    case INITIALIZE_BLANK_CONSENT: {
      return generateBlankConsent(action.title)
    }
    case INITIALIZE_CONSENT_EDIT: {
      return action.json
    }
    case UPDATE_CONSENT_TITLE: {
      return { ...state, title: action.title }
    }
    case TOGGLE_CONSENT_EDIT: {
      const newState = { ...state }
      newState.activeItemId = action.itemId
      return newState
    }
    case UPDATE_CONSENT_ITEM: {
      const newState = dupStateAndItem(state, action.itemId)
      Object.assign(newState.questions[action.itemId], action.item)
      return newState
    }
    case UPDATE_CONSENT_ITEM_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].label = stateToHTML(action.newEditorState.getCurrentContent())
      return newState
    }
    case CHANGE_CONSENT_ITEM_TYPE: {
      const newState = dupStateAndItem(state, action.itemId)
      const question = newState.questions[action.itemId]
      question.type = action.nextType
      if (
        ['select_one', 'select_multiple', 'likert'].includes(action.nextType) &&
        question.choices_order.length === 0
      ) {
        const newChoiceId = action.itemId + getRandomQuestionId()
        question.choices[newChoiceId] = { label: '' }
        question.choices_order.push(newChoiceId)
      }

      if (action.nextType === 'likert') {
        delete question.attributes.other
        while (question.choices_order.length < 5) {
          const newChoiceId = action.itemId + getRandomQuestionId()
          question.choices[newChoiceId] = { label: '' }
          question.choices_order.push(newChoiceId)
        }
      }

      if (action.nextType === 'va_scale') {
        question.scale_labels = {
          top: '',
          bottom: '',
        }
      }
      if (action.nextType === 'vas_horizontal') {
        question.scale_labels = {
          top_value: '100',
          top: '',
          mid_value: '50',
          mid: '',
          bottom_value: '0',
          bottom: '',
        }
        question.hint = ''
        question.attributes.unit = '%'
      }

      if (!['select_one', 'select_multiple', 'likert'].includes(question.type)) {
        question.choices = {}
        question.choices_order = []
      }

      if (['integer', 'decimal'].includes(action.prevType) && !['integer', 'decimal'].includes(action.nextType)) {
        delete question.attributes.unit
      }

      return newState
    }
    case UPDATE_CONSENT_UNIT_TYPE: {
      const newState = dupStateAndItem(state, action.itemId)
      if (!action.unit) {
        delete newState.questions[action.itemId].attributes.unit
      } else {
        newState.questions[action.itemId].attributes.unit = action.unit
      }
      return newState
    }
    case TOGGLE_REQUIRED_CONSENT_QUESTION: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.required = !newState.questions[action.itemId].attributes.required
      return newState
    }
    case ADD_CONSENT_ITEM: {
      const newState = { ...state }
      if (action.index === undefined) {
        newState.order[0].push(action.newQId)
      } else {
        newState.order[0].splice(action.index + 1, 0, action.newQId)
      }
      newState.questions[action.newQId] = {
        type: action.itemType,
        label: '',
        choices: {},
        choices_order: [],
        attributes: { required: true },
        logic: {},
      }
      if (action.itemType === QUESTION_TYPE_MAP.selectOne) {
        const question = newState.questions[action.newQId]
        question.choices[action.choiceOneId] = { label: '' }
        question.choices_order.push(action.choiceOneId)
        question.choices[action.choiceTwoId] = { label: '' }
        question.choices_order.push(action.choiceTwoId)
      }
      calcQuestionNumbers(newState.order, newState.questions)
      newState.activeItemId = action.newQId
      return newState
    }
    case DELETE_CONSENT_ITEM: {
      const newState = { ...state }
      delete newState.questions[action.itemId]
      newState.order[0].splice(newState.order[0].indexOf(action.itemId), 1)
      calcQuestionNumbers(newState.order, newState.questions)
      if (action.itemId === newState.activeItemId) {
        newState.activeItemId = null
      }
      return newState
    }
    case MOVE_CONSENT_ITEM: {
      const newState = { ...state }
      newState.order = newState.order.slice()
      newState.questions = { ...newState.questions }
      newState.order[0].splice(action.endIdx, 0, newState.order[0].splice(action.startIdx, 1)[0])
      calcQuestionNumbers(newState.order, newState.questions)
      return newState
    }
    case DUPLICATE_CONSENT_ITEM: {
      const newState = { ...state }
      newState.order = newState.order.slice()
      newState.questions = { ...newState.questions }
      newState.questions[action.newQId] = JSON.parse(JSON.stringify(newState.questions[action.itemId]))
      const newIndex = newState.order[0].indexOf(action.itemId) + 1
      newState.order[0].splice(newIndex, 0, action.newQId)
      calcQuestionNumbers(newState.order, newState.questions)
      if (action.hasChoices) {
        const duplicateQuestion = newState.questions[action.newQId]
        const newChoicesOrder = action.choicesOrder.map(choiceId => action.newChoicesIdMap[choiceId])
        duplicateQuestion.choices_order = newChoicesOrder
        action.choicesOrder.forEach(choiceId => {
          const newChoiceId = action.newChoicesIdMap[choiceId]
          duplicateQuestion.choices[newChoiceId] = JSON.parse(JSON.stringify(duplicateQuestion.choices[choiceId]))
          delete duplicateQuestion.choices[choiceId]
        })
      }
      return newState
    }
    case ADD_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
      newState.questions[action.itemId].choices[newChoiceId] = { label: '' }
      if (action.insertIdx) {
        newState.questions[action.itemId].choices_order.splice(action.insertIdx, 0, newChoiceId)
      } else {
        newState.questions[action.itemId].choices_order.push(newChoiceId)
      }
      return newState
    }
    case ADD_CONSENT_CHOICE_ON_PASTE: {
      if (action.pasteText === '') return state
      const newState = dupStateAndItem(state, action.itemId)
      action.pasteText.split('\n').forEach(line => {
        const newChoiceId = `${action.itemId}_${getRandomQuestionId()}`
        newState.questions[action.itemId].choices[newChoiceId] = {
          label: line,
        }
        newState.questions[action.itemId].choices_order.push(newChoiceId)
      })
      return newState
    }
    case ADD_OTHER_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.other = 'Other'
      return newState
    }
    case DELETE_OTHER_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].attributes.other
      return newState
    }
    case UPDATE_OTHER_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].attributes.other = action.otherValue
      return newState
    }
    case DELETE_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].choices[action.choiceId]
      newState.questions[action.itemId].choices_order.splice(
        newState.questions[action.itemId].choices_order.indexOf(action.choiceId),
        1,
      )
      return newState
    }
    case UPDATE_CONSENT_CHOICE_LABEL: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices[action.choiceId].label = action.newLabel
      return newState
    }
    case MOVE_CONSENT_CHOICE: {
      const newState = dupStateAndItem(state, action.itemId)
      newState.questions[action.itemId].choices_order.splice(
        action.endIdx,
        0,
        newState.questions[action.itemId].choices_order.splice(action.startIdx, 1)[0],
      )
      return newState
    }
    case UPDATE_CONSENT_LOGIC_CONDITION: {
      const newState = dupStateAndItem(state, action.itemId)
      const newCondition = Object.assign(
        getCondition(newState.questions[action.itemId].logic.show_if.terms, action.conditionPath),
        { [action.field]: action.value },
      )
      if (action.field === 'question_id') {
        newCondition.question_type = newState.questions[action.value].type
        newCondition.comparator = ''
        newCondition.value = ''
      } else if (action.field === 'comparator' && action.value.includes('answered')) {
        newCondition.value = ''
      }
      setCondition(newState.questions[action.itemId].logic.show_if.terms, action.conditionPath, newCondition)
      return newState
    }
    case ADD_CONSENT_LOGIC_CONDITION: {
      const newState = dupStateAndItem(state, action.itemId)
      const blankCondition = {
        question_type: '',
        question_id: '',
        value: '',
        comparator: '',
      }
      if (action.groupPath) {
        addCondition(newState.questions[action.itemId].logic.show_if.terms, action.groupPath)
        return newState
      }
      if (newState.questions[action.itemId].logic.show_if) {
        newState.questions[action.itemId].logic.show_if.terms.push(blankCondition)
      } else {
        newState.questions[action.itemId].logic.show_if = {
          comparator: 'boolean_combine',
          operator: 'and',
          terms: [blankCondition],
        }
      }
      return newState
    }
    case ADD_CONSENT_LOGIC_GROUP: {
      const newState = dupStateAndItem(state, action.itemId)
      const blankGroup = {
        comparator: 'boolean_combine',
        operator: 'and',
        terms: [
          {
            question_type: '',
            question_id: '',
            value: '',
            comparator: '',
          },
        ],
      }
      if (newState.questions[action.itemId].logic.show_if.terms[0].comparator !== 'boolean_combine') {
        newState.questions[action.itemId].logic.show_if.terms = [
          {
            comparator: 'boolean_combine',
            operator: 'and',
            terms: newState.questions[action.itemId].logic.show_if.terms,
          },
          blankGroup,
        ]
      } else {
        newState.questions[action.itemId].logic.show_if.terms.push(blankGroup)
      }
      return newState
    }
    case DELETE_CONSENT_LOGIC_CONDITION: {
      const nextState = dupStateAndItem(state, action.itemId)
      deleteCondition(nextState.questions[action.itemId].logic.show_if, action.conditionPath)
      removeLoneGroup(nextState.questions[action.itemId].logic)
      return nextState
    }
    case DELETE_ALL_CONSENT_LOGIC: {
      const newState = dupStateAndItem(state, action.itemId)
      delete newState.questions[action.itemId].logic.show_if
      return newState
    }
    case CHANGE_CONSENT_LOGIC_OPERATOR: {
      const newState = dupStateAndItem(state, action.itemId)
      setOperator(newState.questions[action.itemId].logic.show_if, action.groupPath, action.value)
      return newState
    }
    case DELETE_INVALID_CONSENT_LOGIC: {
      const nextState = { ...state }
      for (const itemId in action.invalidConditions) {
        nextState.questions[itemId] = { ...nextState.questions[itemId] }
        action.invalidConditions[itemId].sort((a, b) => {
          return b[0] - a[0] || b[1] - a[1]
        })
        action.invalidConditions[itemId].forEach(path => {
          deleteCondition(nextState.questions[itemId].logic.show_if, path)
        })
        removeLoneGroup(nextState.questions[itemId].logic)
      }
      return nextState
    }
    default: {
      return state
    }
  }
}

export function consentErrors(state = { questions: {} }, action) {
  let newState
  switch (action.type) {
    case INITIALIZE_CONSENT_EDIT:
    case INITIALIZE_BLANK_CONSENT:
      return { questions: {} }
    case ADD_CONSENT_ERRORS:
      return action.errors
    case CLEAR_CONSENT_ERROR:
      newState = { ...state }
      delete newState[action.key]
      return newState
    case CLEAR_CONSENT_QUESTION_ERROR:
    case CHANGE_CONSENT_ITEM_TYPE:
      newState = { ...state }
      newState.questions = { ...newState.questions }
      delete newState.questions[action.itemId]
      return newState
    default:
      return state
  }
}

export function editorStates(state = {}, action) {
  switch (action.type) {
    case INITIALIZE_CONSENT_EDIT: {
      return action.editorStates
    }
    case ADD_CONSENT_ITEM: {
      const newState = { ...state }
      newState[action.newQId] = EditorState.createEmpty()
      return newState
    }
    case DUPLICATE_CONSENT_ITEM: {
      const newState = { ...state }
      newState[action.newQId] = EditorState.createWithContent(action.editorState.getCurrentContent())
      return newState
    }
    case UPDATE_CONSENT_ITEM_LABEL: {
      const newState = { ...state }
      newState[action.itemId] = action.newEditorState
      return newState
    }
    default: {
      return state
    }
  }
}

function originalConsent(state = {}, action) {
  if (action.type === INITIALIZE_CONSENT_EDIT || action.type === UPDATE_ORIGINAL_CONSENT) {
    return JSON.parse(JSON.stringify(action.json))
  }
  return state
}

function wordCount(state = 0, action) {
  switch (action.type) {
    case INITIALIZE_BLANK_CONSENT: {
      return 0
    }
    case INITIALIZE_CONSENT_EDIT: {
      return countWordsInConsent(action.json)
    }
    case UPDATE_CONSENT_ITEM_LABEL: {
      return (
        state +
        (countWords(action.newEditorState.getCurrentContent().getPlainText()) -
          countWords(action.oldEditorState.getCurrentContent().getPlainText()))
      )
    }
    default: {
      return state
    }
  }
}

function respondence(state = {}, action) {
  switch (action.type) {
    case SET_CONSENT_EVENTS: {
      return prepareRespondenceData(action.json)
    }
    case RESET_CONSENT_DASHBOARD: {
      return {
        noLogin: 0,
        loggedInNotCompleted: 0,
        accepted: 0,
        rejected: 0,
        completed: 0,
        total: 0,
        lastSent: '',
      }
    }
    default:
      return state
  }
}
export default combineReducers({
  consent,
  editorStates,
  consentErrors,
  wordCount,
  originalConsent,
  respondence,
})

export const consentActions = {
  addItem,
  closeModal: modalActions.closeModal,
  deleteChoice: checkLogicAndDeleteChoice,
  deleteItem: checkLogicAndDeleteItem,
  downloadConsentPreview,
  downloadConsentTranslation,
  downloadParticipantConsent,
  fetchConsent,
  fetchConsentEvents,
  initializeBlankConsent,
  moveItem: checkLogicAndMoveItem,
  openModal: modalActions.openModal,
  resetConsentDashboard,
  toggleEdit,
  updateTitle,
  uploadTranslation,
}

export const itemActions = {
  updateItemLabel,
  duplicateItem,
  addItem,
  addChoiceOnPaste,
  updateItem,
}

export const itemEditorActions = {
  updateUnitType,
  addChoice,
  addOtherChoice,
  toggleRequiredQuestion: checkLogicAndToggleRequired,
  changeItemType: checkLogicAndChangeItemType,
  deleteChoice: checkLogicAndDeleteChoice,
  moveItem: checkLogicAndMoveItem,
}

export const selectViewActions = {
  addChoice,
  deleteChoice: checkLogicAndDeleteChoice,
  deleteOtherChoice,
  moveChoice,
  updateChoiceLabel,
  updateOtherChoice,
}

export const logicActions = {
  updateLogicCondition,
  addLogicCondition,
  deleteLogicCondition,
  deleteAllLogic,
  changeLogicOperator,
  addLogicGroup,
}
