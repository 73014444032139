import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { injectReducers } from 'store/reducers'
import { setPageTitle } from 'utils/misc'
import newConsentReducer, { consentActions } from '../NewConsentBuilder/modules/NewConsent'
import consents, { fetchConsents } from '../../../ConsentsDashboard/modules/Consents'
import instrumentReducer from '../../../../../Instruments/routes/Instrument/modules/Instrument'
import CreateConsentView from '../ConsentProperties/containers/ConsentPropertiesContainer'

export const injectConsentReducers = store => {
  injectReducers(store, {
    consents,
    newConsentReducer,
    instrumentReducer,
  })
}

// Sync route definition
export default store => ({
  path: 'createconsent',
  exact: true,
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
     and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      () => {
        injectConsentReducers(store)
        /*  Webpack - use require callback to define
         dependencies for bundling   */

        const { studyID } = nextState.params
        const { currentStudy } = store.getState().study
        setPageTitle(`Create Consent | Study ${studyID} | ${currentStudy.platform_name}`)
        store.dispatch(consentActions.initializeBlankConsent())
        store.dispatch(fetchConsents(studyID))
        /*  Return getComponent   */
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(UserHasScope(['xc'], CreateConsentView, false, studyID, null, currentStudy)),
          ),
        )

        /* Webpack named bundle   */
      },
      'createconsent',
    )
  },
})
