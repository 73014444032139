import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import DashboardRoute from './routes/Dashboard'
import ViewUserRoute from './routes/ViewUser'
import CreateUserRoute from './routes/CreateUser'
import ActivateUserRoute from './routes/ActivateUser'
import ResetPasswordRoute from './routes/ResetPassword'

export default store => {
  return {
    path: 'users',
    exact: true,
    getIndexRoute(location, cb) {
      const { study } = store.getState()
      setPageTitle(`Users | Study ${study.currentStudy.id} | ${study.currentStudy.platform_name}`)
      store.dispatch(setSubroute('users'))
      cb(null, DashboardRoute(store))
    },
    getChildRoutes(location, cb) {
      store.dispatch(setSubroute('user'))
      cb(null, [ActivateUserRoute(store), ResetPasswordRoute(store), CreateUserRoute(store), ViewUserRoute(store)])
    },
  }
}
