import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getUserRoleId, getUserScopeByStudy, disableScope } from 'utils/misc'
import { USER_ROLE_IDS_CAN_EDIT_CONSENT_SITES, USER_SCOPES } from 'utils/constants'
import ConsentScheduler from '../components/ConsentScheduler'
import { consentActions, duplicateItem } from '../../NewConsentBuilder/modules/NewConsent'
import { instrumentActions, schedulerActions } from '../../../../../../Instruments/routes/Instrument/modules/Instrument'
import { actions as ptpActions } from '../../../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
import { actions as siteActions } from '../../../../../../Sites/routes/SitesPage/modules/Sites'

const mapDispatchToProps = {
  duplicateItem,
  initializeCheckedCohorts: ptpActions.initializeCheckedCohorts,
  ...consentActions,
  ...instrumentActions,
  ...schedulerActions,
  ...siteActions,
}

const mapStateToProps = state => {
  const { newConsentReducer, loading, participantReducer, sites, study, user } = state
  const { consent, instrumentAdvancedOptions } = newConsentReducer
  const { checkedSites, leafSites, siteSelectionList } = sites
  const { checkedCohorts } = participantReducer
  const { currentStudy } = study
  const { id } = currentStudy
  const canSeeSites = USER_ROLE_IDS_CAN_EDIT_CONSENT_SITES.includes(getUserRoleId(user, id))
  const userScope = getUserScopeByStudy(user, currentStudy)
  const canWriteCohort = userScope.includes(USER_SCOPES.wxc.code)
  const noTracksFeature = currentStudy.config?.tracks?.disabled === true

  return {
    canSeeSites,
    checkedCohorts,
    checkedSites,
    consent,
    disableTrackCreation: disableScope({ study, user, scope: USER_SCOPES.wxc.code }) || !canWriteCohort,
    errors: newConsentReducer.consentErrors,
    isLoading: loading.general,
    leafSites,
    noTracksFeature,
    siteSelectionList,
    instrumentAdvancedOptions,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <ConsentScheduler {...props} />
})
