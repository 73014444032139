import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import AuditRoute from './AuditPage'

export default store => {
  return {
    path: 'audit-reporting',
    exact: true,
    getIndexRoute(location, cb) {
      store.dispatch(setSubroute('audit'))
      const state = store.getState()
      setPageTitle(`Audit Report | Study ${location.params.studyID} | ${state.study.currentStudy.platform_name}`)
      cb(null, AuditRoute(store))
    },
    getChildRoutes(location, cb) {
      cb(null, [])
    },
  }
}
