import React from 'react'
import PropTypes from 'prop-types'
import { Container, Checkbox, Button } from 'components/UIElements'
import { HOURS_IN_DAY, HOURS_IN_WEEK } from 'utils/constants'
import Subheader from '../../../components/Subheader'
import VisitReminder, { REMINDER_PERIOD_OPTIONS } from './VisitReminder'
import '../stylesheets/visitReminders.scss'

function VisitReminders(props) {
  const {
    reminders,
    setReminders,
    remindersEnabled,
    setRemindersEnabled,
    reminderNotificationsEnabled,
    setReminderNotificationsEnabled,
    updateVisitMetadata,
    isUpdate,
    disabled,
  } = props

  const _disabled = disabled || isUpdate

  const getPreparedVisitMetadata = remindersArr => {
    return remindersArr.reduce(
      (acc, item) => (
        // convert weeks or days amount to hours
        (acc[item.id] = item.period === 'weeks' ? item.value * HOURS_IN_WEEK : item.value * HOURS_IN_DAY), acc
      ),
      {},
    )
  }

  const addReminder = () => {
    const updatedReminders = [
      ...reminders,
      { id: reminders.length + 1, value: 1, period: REMINDER_PERIOD_OPTIONS[0].key },
    ]
    setReminders(updatedReminders)
    updateVisitMetadata({ key: 'reminders', value: getPreparedVisitMetadata(updatedReminders) })
  }

  const updateReminder = (id, value, period) => {
    const updatedReminders = reminders.map(item => {
      if (item.id === id) {
        return {
          ...item,
          value,
          period,
        }
      }
      return item
    })
    setReminders(updatedReminders)
    updateVisitMetadata({ key: 'reminders', value: getPreparedVisitMetadata(updatedReminders) })
  }

  return (
    <Container>
      <div className='visit-reminders'>
        <Subheader subheaderText='Will the participants be reminded about the visit?' />
        <div className='inner'>
          <Checkbox
            toggle
            label='Turn on reminders'
            checked={remindersEnabled}
            onClick={() => {
              setRemindersEnabled(!remindersEnabled)
              updateVisitMetadata({ key: 'reminders', value: getPreparedVisitMetadata(reminders) })
            }}
            disabled={_disabled}
          />
          {remindersEnabled && (
            <>
              <strong>1. How will the recipients get their reminders?</strong>
              <Checkbox
                disabled
                label='Push notification on recipient mobile phone (recommended)'
                checked={reminderNotificationsEnabled}
                onClick={() => setReminderNotificationsEnabled(!reminderNotificationsEnabled)}
              />
              <strong>2. When will the participant receive their reminders?</strong>
              <div className='reminder-rows'>
                {reminders.map((reminder, idx) => (
                  <VisitReminder key={idx} reminder={reminder} updateReminder={updateReminder} disabled={_disabled} />
                ))}
                <div className='add-reminder'>
                  <Button
                    content='add reminder'
                    id='add-reminder'
                    iconBefore='fas fa-plus'
                    noStyling
                    onClick={addReminder}
                    disabled={_disabled}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  )
}

VisitReminders.propTypes = {
  disabled: PropTypes.bool,
  visitTemplate: PropTypes.shape({ template_name: PropTypes.string }),
  errors: PropTypes.object,
}

export default VisitReminders
