import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button, StickyHeader } from 'components/UIElements'
import { calculateOffsets } from 'utils/misc'
import useQueryString from 'utils/hooks/useQueryString'
import '../stylesheets/consentsDashboard.scss'
import ConsentsTable from './ConsentsTable'
import ConsentDrawerPage from '../containers/ConsentDrawerContainer'

const ConsentsDashboardPage = props => {
  const { canEdit, resetConsentDashboard, params, location } = props
  const defaultTab = 'scheduled'
  const {
    query: { tab = defaultTab },
    querySet,
  } = useQueryString({
    location,
    tabKeys: {
      tab: ['scheduled', 'drafts'],
    },
    initValues: {
      tab: defaultTab,
    },
  })
  const { studyID, siteID } = params
  const [offsets, setOffsets] = useState()

  useEffect(() => {
    setOffsets(calculateOffsets('sticky'))
  }, [])

  useEffect(() => {
    resetConsentDashboard()
  }, [siteID, studyID])

  const _openDrawer = rowData => {
    const indexWithData = rowData.findIndex(obj => obj.key === 'action')
    const { consentId, draft, version } = rowData[indexWithData]
    const { openDrawer, closeDrawer, resetDocs, setConsentParticipantsStatus } = props
    openDrawer({
      closeOnBackgroundClick: true,
      onCancel: () => {
        setConsentParticipantsStatus()
        resetConsentDashboard()
        resetDocs()
        closeDrawer()
      },
      content: <ConsentDrawerPage consentId={consentId} draft={draft} consentVersion={version} />,
    })
  }

  return (
    <div className='consent-dashboard page'>
      <StickyHeader offset={offsets ? offsets[0] : 0}>
        <div className='flexed top-buttons'>
          <div className='selected-button-list sub'>
            <Button
              id='consent-scheduled-tab'
              selected={tab === 'scheduled'}
              onClick={() => querySet({ tab: 'scheduled' })}
              noStyling
              content='Scheduled'
            />
            {canEdit && (
              <Button
                id='consent-draft-tab'
                selected={tab === 'drafts'}
                onClick={() => querySet({ tab: 'drafts' })}
                noStyling
                content='Drafts'
              />
            )}
          </div>
          {canEdit && (
            <Button
              id='create-button'
              className='create-button'
              content='Create'
              onClick={() => {
                browserHistory.push(`/studies/${studyID}/consents/createconsent`)
              }}
            />
          )}
        </div>
      </StickyHeader>
      <ConsentsTable
        selectedPage={typeof tab === 'string' ? tab : defaultTab}
        {...props}
        openConsentDrawer={_openDrawer}
        offset={offsets ? offsets[1] : 0}
      />
    </div>
  )
}

ConsentsDashboardPage.propTypes = {
  canEdit: PropTypes.bool,
  closeDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  params: PropTypes.shape({
    siteID: PropTypes.string,
    studyID: PropTypes.string,
  }),
  resetConsentDashboard: PropTypes.func,
  resetDocs: PropTypes.func,
  setConsentParticipantsStatus: PropTypes.func,
  location: PropTypes.object,
}

export default ConsentsDashboardPage
