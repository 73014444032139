import moment from 'moment-timezone'
import MomentLocaleUtils from 'react-day-picker/moment'
import { CHINA_DEPLOY, DATE_FORMAT_MAP } from 'utils/constants'
import { pluralize } from './misc'
import { MINUTES_IN_HOUR } from './constants'

/**
 * Moves a timestamp in utc to time in current locale.
 * e.g. Makes 12:00 UTC => 12:00 EST
 * @param dateAsEpoch
 * @returns {*|moment.Moment}
 */

export const timeOptionsArr = (forMinutes = true) => {
  const arr = []
  for (let num = 0; num < (forMinutes ? 60 : 24); num++) {
    arr.push(num)
  }
  return arr
}

export const addUTCOffset = moment => {
  return moment.add(moment.utcOffset(), 'minutes')
}
/**
 * This function returns a dateString representing a minute
 * from the present if the dateString in the argument is in the past.
 */
export const ensureFutureDate = (dateString, format) => {
  const momentObj = moment(dateString, format)
  const presentMoment = moment().startOf('minute') // Get rid of seconds because we don't render them to the user
  if (momentObj.isBefore(presentMoment)) {
    presentMoment.add(1, 'minutes')
    return presentMoment.format(format)
  }

  return dateString
}

export const fromUTCMidnightSeconds = (dateAsEpoch = moment().valueOf() / 1000) => {
  return moment((dateAsEpoch - moment().utcOffset() * 60) * 1000)
}

export const fromUTCMidnightSecondsTS = UTCTimestamp => {
  return fromUTCMidnightSeconds(moment(UTCTimestamp).valueOf() / 1000)
}

export const isToday = dateString => moment().isSame(dateString, 'day')

export const isTodayOrBeforeUTC = dateString => {
  const nowUTC = moment().utc()
  const isTodayUTC = nowUTC.isSame(dateString, 'day')
  const isBeforeToday = nowUTC.isSameOrAfter(dateString, 'day')
  return isTodayUTC || isBeforeToday
}

export const isCurrentHour = dateString => moment().hour() === moment(dateString).hour()

/**
 * This returns a moment object representing nearest hour to current time plus one hour
 * e.g., if current time is 11:01am, the function will return a moment representing 12:00pm
 * Adding 30 minutes and using startOf func allows us to see what is nearest hour.
 * Adding an hour to return the next hour.
 * */
export const nearestNextHour = () => {
  const initialDateTime = moment(new Date()).add(1, 'hours')
  initialDateTime.add(30, 'minutes').startOf('hour')
  return initialDateTime
}

/**
 * Moves a timestamp object back to midnight of current day and then applies utc-offset to represent it as midnight at
 * utc timezone.
 * e.q. Makes 10:00 PM EST -> 12:00 AM UTC
 * @param dateAsMoment
 * @returns {number}
 * */
export const toUTCMidnightSeconds = dateAsMoment =>
  dateAsMoment.startOf('day').valueOf() / 1000 + moment().utcOffset() * 60

export const toSecondsFromTimeString = dateAsMoment => dateAsMoment.diff(moment().startOf('day'), 'seconds')
/**
 * Returns when the current day started in UTC
 * @returns {moment.Moment}
 * */

export const currentDayStartInUTC = () =>
  moment()
    .utc()
    .startOf('day')
    .valueOf()

export const dayDifference = (date1, date2) => {
  const d1 = moment(date1).startOf('day')
  const d2 = moment(date2).startOf('day')
  return d2.diff(d1, 'days')
}

export const yearDifference = (date1, date2) => {
  const d1 = moment(date1).startOf('day')
  const d2 = moment(date2).startOf('day')
  return d2.diff(d1, 'years')
}

export const generateMultiUnitDifference = (date1, date2) => {
  const d1 = moment(date1)
  const d2 = moment(date2)
  const duration = moment.duration(d2.diff(d1))
  const days = duration.asDays()
  const strArr = []
  const durationInHrs = duration.asHours()

  if (days > 1) {
    const dayFloor = Math.floor(days)
    duration.subtract(moment.duration(dayFloor, 'days'))
    strArr.push(pluralize(dayFloor, 'day', 'days'))
  }

  const hours = duration.hours()
  if (hours > 0) {
    duration.subtract(moment.duration(hours, 'hours'))
    strArr.push(pluralize(hours, 'hour', 'hours'))
  }

  const minutes = duration.minutes()
  if (minutes > 0) {
    duration.subtract(moment.duration(minutes, 'minutes'))
    strArr.push(`${minutes} min`)
  }

  return {
    string: date1 && date2 ? strArr.join(' ') : 'N/A',
    durationInHrs,
  }
}

export const dateDifferenceByMins = (date1, date2) => {
  return moment(date1).diff(date2, 'minutes')
}

export const today = (string = false) => {
  const todayObject = new moment()
  if (string) {
    return todayObject.format('YYYYMMD_hhmm')
  }
  return todayObject
}

export const generateDateStringWithTimeZone = (string, timeOnly = false, uppercaseAmPm = true, showTimeZone = true) => {
  //
  // the commented code below deduces the timezone name from the offset in the timestamp string
  //

  // const offset = string.substr(string.length - 6)
  // const timeZones = moment.tz.names()
  // const timeZoneStr = timeZones.find(tz => {
  //   return offset === moment.tz(tz).format('Z')
  // })
  // const timeZoneAbrvStr = moment.tz(string, timeZoneStr).format('z')

  let newString = string

  const timeStringHasOffset = string ? string[string.length - 6] === '+' : false
  if (!timeStringHasOffset) newString = `${string}+00:00`

  const currentUserTz = moment.tz.guess() // best estimation of current user's timezone
  const dateCreatedInUTC = moment(newString).tz('Europe/London')
  const dateInCurrentUserTz = dateCreatedInUTC.clone().tz(currentUserTz)

  const dateString = `${dateInCurrentUserTz.format(
    `${timeOnly ? '' : `${DATE_FORMAT_MAP.main} `}h:mm ${uppercaseAmPm ? 'A' : 'a'} ${showTimeZone ? 'z' : ''}`,
  )}`

  return dateString
}

export const fromEpochToUTC = epoch => moment.unix(epoch).utc()

export const sortListByTime = (list, keyName, ascending = true) => {
  return list.sort((visit1, visit2) => {
    const comparisonValue1 = moment(visit1[keyName]).valueOf()
    const comparisonValue2 = moment(visit2[keyName]).valueOf()

    if (ascending) return comparisonValue1 - comparisonValue2
    return comparisonValue2 - comparisonValue1
  })
}

export const getFormattedDateString = (dateString, format = DATE_FORMAT_MAP.main, useUTC = false) => {
  if (useUTC) return `${moment.utc(dateString).format(format)}`
  return moment(dateString).format(format)
}

export const getFormattedTimeString = (
  dateString,
  useUTC = true,
  useTwentyFourHrFormat = false,
  uppercaseAmPm = true,
) => {
  if (useUTC)
    return moment.utc(dateString).format(`${useTwentyFourHrFormat ? 'hh' : 'h'}:mm ${uppercaseAmPm ? 'A' : 'a'} UTC`)
  return moment(dateString).format(`${useTwentyFourHrFormat ? 'hh' : 'h'}:mm ${uppercaseAmPm ? 'A' : 'a'}`)
}

export const getFormattedDateStringForVisitsGrouping = dateString => {
  const date = moment(dateString)

  const endOfWeekMoment = moment().startOf('isoweek')
  endOfWeekMoment.add(7, 'days') // next Monday 12:00 am

  if (isToday(dateString)) return 'today'
  if (date.isBefore(endOfWeekMoment)) return 'laterThisWeek'
  return 'futureVirtualVisits'
}

export const groupByDate = (timeline, keyName) => {
  const sortedTimeline = sortListByTime(timeline, keyName)

  /**
   * Takes first scheduled (non-adhoc) visit and takes it out of array and sets it to this variable.
   * The return product is an array with just that visit object.
   * */

  const groupedVisits = sortedTimeline.reduce((groupedList, visit) => {
    const keyToUse = getFormattedDateString(visit[keyName], DATE_FORMAT_MAP.main)

    if (groupedList[keyToUse]) groupedList[keyToUse].push(visit)
    else groupedList[keyToUse] = [visit]

    return groupedList
  }, {})
  return groupedVisits
}

export const getScheduledVisitString = ({ visit_duration, visit_time }) => {
  const dateMoment = moment(visit_time)
  const dateMomentClone = dateMoment.clone()
  dateMomentClone.add(visit_duration, 'minutes')

  /**
   * This creates a time string with duration that looks like:
   * "23-Apr-2020, 19:05 - 19:35"
   * */

  return `${dateMoment.format(DATE_FORMAT_MAP.mainWithFullTime)} - ${dateMomentClone.format('HH:mm')}`
}

export const createMomentObject = date => moment(date)

const MINUTES_TO_DAYS_MULTIPLE = 24 * 60

export const convertMinToDays = (num, minToDays = true) => {
  if (num === '') return ''
  if (minToDays) {
    return num * MINUTES_TO_DAYS_MULTIPLE
  }
  return num / MINUTES_TO_DAYS_MULTIPLE
}

/**
 * Return a formated date
 * @param {Date} date
 * @param {String} formatString
 */
export const getFormatDate = (date, formatString = 'M/D/YYYY') => moment(date).format(formatString)

/**
 * Get a date from Now
 * @param {Date} date
 */
export const getDateFromNow = date => moment(date).fromNow()

const DAYS_IN_WEEK = 7
const DAYS_IN_MONTH = 30
export const DAYS_IN_YEAR = 365
/**
 * Get the study length for years, months, weeks and days
 * @param {Number} studyDuration
 * @param {String} durationType
 */
export const getStudyLength = (studyDuration, durationType = 'days') => {
  let daysNumber = durationType === 'days' ? studyDuration : studyDuration * DAYS_IN_WEEK
  let years = parseInt(daysNumber / DAYS_IN_YEAR)
  daysNumber = daysNumber % DAYS_IN_YEAR
  let months = parseInt(daysNumber / DAYS_IN_MONTH)
  daysNumber = daysNumber % DAYS_IN_MONTH
  const weeks = parseInt(daysNumber / DAYS_IN_WEEK)
  let days = daysNumber % DAYS_IN_WEEK
  if (months === 12) {
    years++
    months = 0
    days = 0
  }
  const studyLengthObj = {
    year: years,
    month: months,
    week: weeks,
    day: days,
  }
  return Object.keys(studyLengthObj)
    .filter(key => studyLengthObj[key] > 0)
    .reduce((res, key) => ((res[key] = studyLengthObj[key]), res), {})
}

/* Sets the react-datepicker configuration according to the build variant */
export const datePickerConfig = {
  locale: CHINA_DEPLOY ? 'zh-cn' : undefined,
  placeholder: CHINA_DEPLOY ? '年 月 日' : DATE_FORMAT_MAP.main,
  format: CHINA_DEPLOY ? 'LL' : DATE_FORMAT_MAP.main,
}

/* Sets the react-day-picker configuration according to the build variant */
export const dayPickerConfig = {
  locale: CHINA_DEPLOY ? 'zh-cn' : undefined,
  localeUtils: CHINA_DEPLOY ? MomentLocaleUtils : undefined,
  placeholder: CHINA_DEPLOY ? '年 月 日' : DATE_FORMAT_MAP.main,
  format: CHINA_DEPLOY ? 'LL' : DATE_FORMAT_MAP.main,
}

export const getUtcOffsetString = utcOffset => {
  const utcOffsetNumber = Number(utcOffset)
  const utcHours = Math.trunc(utcOffsetNumber / MINUTES_IN_HOUR)
  const utcMinutes = Math.abs(Math.round(utcOffsetNumber % MINUTES_IN_HOUR)) || 0

  const utcMinutesStr = utcMinutes < 10 ? `:0${utcMinutes}` : `:${utcMinutes}`

  const offsetStr = ` ${utcHours < 0 ? '' : '+'}${utcHours}${utcMinutesStr}`
  return `(UTC${utcOffsetNumber ? offsetStr : ''})`
}

export const getFormattedUTCTimestamp = (timestamp, utcOffset) => {
  const utcDate = moment(timestamp).utc()
  const utcString = getUtcOffsetString(utcOffset)

  return `${utcDate.add(utcOffset || 0, 'minutes').format(DATE_FORMAT_MAP.mainWithDateTime)} ${utcString}`
}
