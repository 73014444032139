import React from 'react'
import PropTypes from 'prop-types'
import ConsentNav from './ConsentNav'
import '../stylesheets/consentView.scss'

const ConsentView = props => {
  const { children } = props

  return (
    <div className='consent-view page'>
      <ConsentNav {...props} />
      {children}
    </div>
  )
}

ConsentView.propTypes = {
  children: PropTypes.node,
}

export default ConsentView
