import React from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Popup } from 'components/UIElements'
import { MODAL_CLASSES_MAP, MODAL_CONTENT_MAP, MODAL_BUTTONS_MAP, BULK_DATA_TYPES } from 'utils/constants'
import { geofenceEventIcon } from 'assets/assets'
import STRINGS from 'utils/strings'
import DiaryDataDownload from '../containers/DiaryDataDownloadFormContainer'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from '../../../../../../../../../utils/constants'

const APP_PUSH_DATA_TYPES = [
  { type: 'geofencing', content: 'Geofencing Data', customIcon: geofenceEventIcon() },
  { type: 'battery', content: 'Battery Data', icon: 'fas fa-battery-three-quarters' },
  { type: 'phonecontacts', content: 'Phone Contact Data', icon: 'far fa-address-book' },
  { type: 'location', content: 'Location Data', icon: 'fas fa-map-marker-alt' },
  { type: 'stepcounter', content: 'Step Count Data', icon: 'fas fa-shoe-prints' },
  { type: 'appsinstalled', content: 'Apps Installed Data', icon: 'fab fa-app-store' },
  { type: 'screentime', content: 'Screen Time Data', icon: 'fas fa-mobile-alt' },
]

const STREAMING_DOWNLOAD_DATA_TYPES = ['location', 'stepcounter', 'appsinstalled', 'screentime']

const DownloadLink = ({ pushData, studyID, siteID, userToken }) => {
  return (
    <>
      <form
        name='dataDownload'
        target='_blank'
        action={`/control/data_fetch/${pushData.type}?study_id=${studyID}&site_id=${siteID}`}
        method='POST'>
        <input type='hidden' name='token' value={userToken} aria-label='download' />
        <Button
          content={`Download ${pushData.content}`}
          customIcon={pushData.customIcon}
          icon={pushData.icon}
          isThrottled
          link
          type='submit'
        />
      </form>
    </>
  )
}

const BatchDownloadList = props => {
  const {
    componentLoading,
    downloadData,
    downloadBulkData,
    hasClinro,
    hasDiary,
    hasMedAdherence,
    hasAudio,
    modules,
    openModal,
    siteID,
    study,
    userId,
    userToken,
    handleDateFilterError,
  } = props

  const { config, id: studyID } = study
  const instrumentArchitectureVersion =
    config?.instrument_architecture_version || DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const flagsmithFeatures = useFlagsmithFeature({
    /**
     * For the featureFlags key, we put in a object for what the default value of
     * the feature flag(s) should be in case there is an error with the Flagsmith API.
     */
    featureFlags: { [FLAGSMITH_FEATURES.instrumentUnification]: { enabled: true } },
    userId,
    studyId: studyID,
  })
  const hasInstrumentUnification =
    flagsmithFeatures[FLAGSMITH_FEATURES.instrumentUnification]?.enabled &&
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION

  const hasAppPushData = Object.values(modules).includes(true)
  const onOpenDiaryModal = () => {
    openModal({
      children: <DiaryDataDownload handleDateFilterError={handleDateFilterError} />,
      className: MODAL_CLASSES_MAP.diaryDownload,
      closeOnBackgroundClick: true,
      // confirmButton: 'Download Responses',
      // cancelButton: 'Close',
      noButtons: true,
    })
  }

  const onOpenAudioModal = () => {
    openModal({
      heading: MODAL_CONTENT_MAP.downloadAllAudioFiles,
      content: (
        <div className='audio-modal-content'>
          <div>{MODAL_CONTENT_MAP.downloadAllAudioFilesSubtitle}</div>
          <div className='caption'>{MODAL_CONTENT_MAP.downloadAllAudioFilesCaption}</div>
        </div>
      ),
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        downloadBulkData({ studyID, type: BULK_DATA_TYPES.audioFiles })
      },
    })
  }

  let downloadList = []
  if (hasMedAdherence) {
    const medAdherenceSection = (
      <div className='popup-section' key='med-adh-dl'>
        <div className='label-small'>Medication Adherence Data</div>
        <Button
          onClick={() => downloadData(studyID, siteID, 'medAdherence')}
          id='injection-sites-times'
          content='Injection Sites and Times'
          icon='fas fa-file-prescription'
          isThrottled
          link
          loading={componentLoading.medAdherence}
        />
        <Divider margin='8px 10px 0' />
      </div>
    )
    downloadList.push(medAdherenceSection)
  }
  const downloadSurveyDataSection = (
    <div className='popup-section' key='survey-res-dl'>
      <div className='label-small'>{hasInstrumentUnification ? 'Participant Instruments' : 'Surveys'}</div>
      <Button
        onClick={() => downloadData(studyID, siteID, 'survey')}
        content={hasInstrumentUnification ? 'All Participant Instrument Responses' : 'All Survey Responses'}
        id='all-survey-responses'
        icon='fas fa-file-alt'
        isThrottled
        link
        loading={componentLoading.survey}
      />
      <Divider margin='8px 10px 0' />
    </div>
  )
  downloadList.push(downloadSurveyDataSection)
  if (hasClinro) {
    const downloadClinroDataSection = (
      <div className='popup-section' key='clinro-res-dl'>
        <div className='label-small'>Clinician Instruments</div>
        <Button
          onClick={() => downloadData(studyID, siteID, 'clinro')}
          content='All Clinician Instrument Responses'
          id='all-clinician-instrument-responses'
          icon='fas fa-notes-medical'
          isThrottled
          link
          loading={componentLoading.clinro}
        />
        <Divider margin='8px 10px 0' />
      </div>
    )
    downloadList.push(downloadClinroDataSection)
  }
  if (hasDiary && !hasInstrumentUnification) {
    const downloadDiaryDataSection = (
      <div className='popup-section' key='diary-res-dl'>
        <div className='label-small'>Diary</div>
        <Button
          onClick={() => onOpenDiaryModal()}
          id='dairy-responses'
          content='Diary Responses'
          icon='fas fa-file-alt'
          isThrottled
          link
          loading={componentLoading.diary}
        />
        <Divider margin='8px 10px 0' />
      </div>
    )
    downloadList.push(downloadDiaryDataSection)
  }
  if (hasAudio) {
    const downloadAudioDataSection = (
      <div className='popup-section' key='audio-res-dl'>
        <div className='label-small'>{STRINGS.audioFiles}</div>
        <Button
          onClick={onOpenAudioModal}
          id='audio-responses'
          content={STRINGS.allAudioFiles}
          icon='fas fa-file-archive-o'
          isThrottled
          link
          loading={componentLoading.audio}
        />
        <Divider margin='8px 10px 0' />
      </div>
    )
    downloadList.push(downloadAudioDataSection)
  }
  if (hasAppPushData) {
    const appPushDataDownloadList = APP_PUSH_DATA_TYPES.map((pushData, idx) => {
      if (modules[pushData.type]) {
        const pushDataKey = `${pushData.type}-${idx}`
        if (STREAMING_DOWNLOAD_DATA_TYPES.includes(pushData.type)) {
          const linkProps = {
            pushData,
            siteID,
            studyID,
            userToken,
          }
          return <DownloadLink key={pushDataKey} {...linkProps} />
        }
        return (
          <Button
            content={pushData.content}
            customIcon={pushData.customIcon}
            icon={pushData.icon}
            isThrottled
            key={pushDataKey}
            link
            loading={componentLoading[pushData.type]}
            onClick={() => downloadData(studyID, siteID, pushData.type)}
          />
        )
      }
    })
    const appPushHeading = (
      <div key='app-push-heading' className='label-small'>
        App Data
      </div>
    )

    downloadList = [...downloadList, appPushHeading, ...appPushDataDownloadList]
  }
  return downloadList
}

const DataDownloadPopup = props => {
  return (
    <Popup
      dark
      noPointer
      className='export-button'
      key='export'
      trigger={
        <Button
          id='download-batch-data'
          className='popup-trigger'
          content='Download Batch Data'
          iconAfter='fas fa-caret-down after-text'
          iconBefore='fas fa-file-download'
        />
      }>
      <BatchDownloadList {...props} />
    </Popup>
  )
}

DownloadLink.propTypes = {
  pushData: PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.string,
    customIcon: PropTypes.string,
  }),
  siteID: PropTypes.number,
  studyID: PropTypes.number,
  userToken: PropTypes.string,
}

export default DataDownloadPopup
