import React from 'react'
import PropTypes from 'prop-types'
import { Container, Radio } from 'components/UIElements'
import { INSTRUMENT_TYPE_MAP, DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES } from 'utils/constants'
import { ICONS, PROPERTIES } from '../../../../../utils/PropertyFields'
import PropertyField, { TooltipLabel } from './PropertyField'
import { formatStringForID } from 'utils/misc'
import DisplayModeSection from './DisplayModeSection'

const INSTRUMENT_TYPES_WITH_DISPLAY_ICON = [INSTRUMENT_TYPE_MAP.survey]

const UIProperties = ({
  instrument,
  updateMetadataField,
  updateInstrumentDisplayLocation,
  errors,
  hasInstrumentUnification,
}) => {
  const displayName = instrument.metadata.display_name

  return (
    <div className='ui-properties'>
      <h4>UI Properties</h4>
      {hasInstrumentUnification ? (
        <Container>
          <DisplayModeSection
            instrument={instrument}
            updateInstrumentDisplayLocation={updateInstrumentDisplayLocation}
            errors={errors}
          />
        </Container>
      ) : null}
      <Container>
        <PropertyField
          error={errors.hasOwnProperty('display_name') && displayName === ''}
          onUpdate={value => updateMetadataField('display_name', value)}
          fieldInfo={PROPERTIES.display_name}
          value={displayName}
        />
        {INSTRUMENT_TYPES_WITH_DISPLAY_ICON.includes(instrument.type) && (
          <DisplayIcons
            error={errors.hasOwnProperty('display_icon')}
            updateMetadataField={updateMetadataField}
            selected={instrument.metadata.display_icon}
          />
        )}
      </Container>
    </div>
  )
}

const DisplayIcons = ({ error, selected, updateMetadataField }) => {
  return (
    <div className='property-field display-icons'>
      <TooltipLabel
        error={error}
        text={PROPERTIES.display_icon.text}
        description={PROPERTIES.display_icon.description}
      />
      <div className='icon-select flexed wrap'>
        {ICONS.map(info => {
          return (
            <Radio
              id={formatStringForID(info.key)}
              key={info.key}
              onClick={() => updateMetadataField('display_icon', info.key)}
              selected={selected === info.key}
              content={<img src={info.icon} alt={`${info.key} display icon`} />}
            />
          )
        })}
      </div>
    </div>
  )
}

UIProperties.propTypes = {
  errors: PropTypes.objectOf(PropTypes.string),
  instrument: PropTypes.shape({
    metadata: PropTypes.shape({
      display_icon: PropTypes.string,
      display_name: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
  updateMetadataField: PropTypes.func,
  hasInstrumentUnification: PropTypes.bool,
}

export default UIProperties
