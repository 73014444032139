import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/subMenu.scss'

const SubMenu = props => {
  const { children, className } = props
  const _className = className ? ` ${className}` : ''
  return <div className={`sub-menu${_className}`}>{children}</div>
}

SubMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default SubMenu
