import React from 'react'

const StudySortingOption = ({
  sortingOption,
  updateAscendingSortingOrder,
  updateSortingOption,
  ascendingSortingOrder,
  sortingText,
  selectedSortingOption,
}) => {
  return (
    <div
      className='sorting-option flexed center-aligned'
      onClick={() => {
        if (sortingOption === selectedSortingOption) {
          updateAscendingSortingOrder(!ascendingSortingOrder)
        } else {
          updateSortingOption(selectedSortingOption)
          updateAscendingSortingOrder(true)
        }
      }}>
      <div className={`sorting-text ${sortingOption === selectedSortingOption ? 'active' : ''}`}>{sortingText}</div>
      <span className='sort-arrows flexed column'>
        <i
          className={`fas fa-caret-up ${
            sortingOption === selectedSortingOption && ascendingSortingOrder ? 'active' : ''
          }`}
        />
        <i
          className={`fas fa-caret-down ${
            sortingOption === selectedSortingOption && !ascendingSortingOrder ? 'active' : ''
          }`}
        />
      </span>
    </div>
  )
}

export default StudySortingOption
