import React from 'react'
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer, Tooltip } from 'recharts'
import { Button } from 'components/UIElements'
import EmptyChart, { RedirectButton } from './EmptyChart'

const tooltipGetter = ({ selected, num, percent, totalPtps }) => {
  return props => {
    if (props.payload.length === 0) return null
    let data = props.payload[0].payload
    let value = selected === 'num' ? data.value : data.value + '%'
    let otherValue = selected === 'num' ? percent[data.name / 10].value + '%' : num[data.name / 10].value
    return (
      <div className='compliance-tooltip'>
        <p>{data.name}% of Compliance</p>
        <table>
          <tbody>
            <tr>
              <td>{value}</td>
              <td>Participants ({otherValue})</td>
            </tr>
            <tr>
              <td>{totalPtps}</td>
              <td>Total Participants</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

const ComplianceChartContainer = props => {
  const {
    insights,
    isEnroller,
    loading,
    num,
    onChangeType,
    percent,
    percentiles,
    selected,
    siteID,
    studyID,
    totalPtps,
  } = props
  const data = selected === 'num' ? num : percent
  const buttonText = 'View All Participants'
  if (num.length === 0 || isEnroller) {
    return (
      <EmptyChart
        className='ptp-compliance'
        header='Compliance (Per Participant)'
        message={isEnroller ? 'You do not have access to this chart' : 'This study does not have any compliance data.'}
        studyID={studyID}
        buttonText={buttonText}
        loading={loading}
        hasInsights={!!insights}
      />
    )
  }
  return (
    <div className='ptp-compliance d3-chart-container'>
      <div className='chart-section'>
        <div className='chart-header flexed'>
          <h4>Compliance (Per Participant)</h4>
          <div>
            <Button
              onClick={() => onChangeType('compliance', 'num')}
              grey
              className={selected === 'num' ? 'selected' : ''}
              content='#'
            />
            <Button
              onClick={() => onChangeType('compliance', 'percent')}
              grey
              className={selected === 'percent' ? 'selected' : ''}
              content='%'
            />
          </div>
        </div>
        <p>{selected === 'num' ? '# of Participants' : '% of Total Participants'}</p>
        <Chart data={data} {...props} />
        <h5>% of Instrument Completion</h5>
        <Percentiles percentiles={percentiles} />
      </div>
      {!insights && (
        <RedirectButton studyID={studyID} siteID={siteID} buttonText={buttonText} redirect='participants' />
      )}
    </div>
  )
}

const Chart = props => {
  const { data, selected } = props
  const longestLabel =
    data.length > 0 ? data.reduce((a, b) => ({ value: Math.max(a.value || 0, b.value || 0) })).value + '' : '1'
  const yAxisWidth = (longestLabel.length + 2) * 12
  return (
    <ResponsiveContainer width='100%' height={400}>
      <BarChart data={data}>
        <Tooltip cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} content={tooltipGetter(props)} />
        <Bar dataKey='value' fill='#909d41' />
        <YAxis
          domain={selected === 'percent' ? [0, 100] : [0, 'auto']}
          width={yAxisWidth}
          tick={{ textAlign: 'left' }}
          tickLine={false}
          axisLine={false}
          allowDecimals={false}
        />
        <XAxis tickLine={false} axisLine={{ strokeWidth: 6, stroke: '#efeded' }} dataKey='name' />
      </BarChart>
    </ResponsiveContainer>
  )
}

const Percentiles = ({ percentiles }) => {
  return (
    <div className='percentile-boxes'>
      {Object.keys(percentiles).map(percentile => {
        const value = percentiles[percentile]
        return (
          <PercentileBox
            key={percentile}
            percentile={percentile}
            headerText='indicates'
            valueText='completion'
            value={value}
          />
        )
      })}
    </div>
  )
}

const PercentileBox = ({ percentile, value, valueText, headerText }) => {
  return (
    <div className='box'>
      <p>
        {percentile}th percentile {headerText}
      </p>
      <div>
        <b>{value}</b>
        <span>{valueText}</span>
      </div>
    </div>
  )
}

export default ComplianceChartContainer
