import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, UploadInput } from 'components/UIElements'
import { MEDIA_TYPE_MAP } from 'utils/constants'
import { convertToMB } from 'utils/misc'
import useMediaError from 'utils/hooks/mediaUploadHooks'

import '../styles/d3/mediaupload.scss'

const mediaUploader = props => {
  const {
    autoUploadAfterFileSelect = false,
    callback,
    changeImageMode = false,
    className,
    currentResourcesSizeCount,
    confirmButton,
    editorState,
    heading,
    isGeneralImageUpload,
    itemCallbacks,
    initTab = MEDIA_TYPE_MAP.image,
    mediaTypes,
    mediaUploadLoading,
    noAltTextReq = false,
    noHeading = false,
    noTabs = false,
    onClose,
    restrictionText,
    showButtons,
    subheading,
    studyID,
    textEditor,
    uploadImage,
    uploadMedia,
    uploadDocument,
    isCrop,
    previmSrc,
    uploadOptions,
  } = props

  const mediaInfoMap = {
    audio: {
      acceptedTypes: ['.mp3', '.wav'],
      nameKey: 'file_name',
      fileKey: 'survey_file',
      uploadFunc: uploadMedia,
    },
    white_labeling: {
      acceptedTypes: ['.png', '.jpg', '.jpeg'],
      fileKey: 'upload_file',
      nameKey: 'file_name',
      restriction: 'Max size 2.5 MB, 1000 x 1000 pixels',
      uploadFunc: uploadImage,
    },
    image: {
      acceptedTypes: ['.png', '.jpg', '.jpeg'],
      fileKey: 'survey_image',
      nameKey: 'image_name',
      restriction: 'Max size 2.5 MB, 1000 x 1000 pixels',
      uploadFunc: uploadImage,
    },
    general_image: {
      acceptedTypes: ['.png', '.jpg', '.jpeg'],
      fileKey: 'file',
      nameKey: 'image_name',
      restriction: 'Max 400 KB',
      uploadFunc: uploadImage,
    },
    video: {
      acceptedTypes: ['.mp4', '.m4v', '.mov', '.webm'],
      nameKey: 'file_name',
      fileKey: 'survey_file',
      uploadFunc: uploadMedia,
    },
    document: {
      acceptedTypes: ['.pdf'],
      nameKey: 'file_name',
      fileKey: 'upload_file',
      uploadFunc: uploadDocument,
    },
  }

  const [error, setError] = useState('')
  const [altText, setAltText] = useState('')
  const [file, setFile] = useState({})
  const [isCropping, setIsCropping] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [croppedImage, setCroppedImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(initTab)
  const form = document.forms.namedItem('image-upload')
  const uploadRef = useRef()
  const isImage = activeTab === MEDIA_TYPE_MAP.image || activeTab === MEDIA_TYPE_MAP.white_labeling
  const accept = mediaInfoMap[activeTab].acceptedTypes

  // This is a custom hook that sets the error if the uploaded file is not valid.
  useMediaError({ file, setError, setFile, currentResourcesSizeCount, accept, isImage })
  useEffect(() => setError(''), [activeTab])

  useEffect(() => {
    setLoading(mediaUploadLoading)
  }, [mediaUploadLoading])

  const handleBackgroundClose = e => {
    e.stopPropagation()
    if (uploadRef.current.contains(e.target)) return
    const close = onClose || (() => {})
    if (!mediaUploadLoading) close()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleBackgroundClose, false)
    return () => document.removeEventListener('mousedown', handleBackgroundClose, false)
  })

  const onFileSelection = _file => {
    setFile(_file)
    if (isCrop) setIsCropping(true)
  }

  const onChange = e => {
    setAltText(e)
  }

  const mediaInfo = isGeneralImageUpload ? mediaInfoMap.general_image : mediaInfoMap[activeTab]

  const onFileInsert = () => {
    const fData = new FormData(form)
    fData.set('study_id', `${studyID}`)
    const close = onClose || (() => {})
    let cb = callback || {}
    if (itemCallbacks) cb = itemCallbacks[activeTab]

    const { nameKey, fileKey, uploadFunc } = mediaInfo
    const mediaFile = isCrop ? croppedImage.file : file

    const newUrl = URL.createObjectURL(file)

    fData.set(nameKey, mediaFile.name)
    fData.set(fileKey, mediaFile)
    if (noAltTextReq && (activeTab === MEDIA_TYPE_MAP.image || activeTab === MEDIA_TYPE_MAP.white_labeling))
      fData.set('alt_text', '')
    // TODO: when a post resquest is ready for documents get rid for this and add an uploadDocument function
    if (fileKey === 'upload_file' && activeTab !== 'white_labeling') {
      fData.set(fileKey, newUrl)
      cb(fData)
      close()
      return
    }
    uploadFunc(fData, uploadOptions).then(payload => {
      if (payload) {
        const resPayload = { ...payload }
        resPayload.size = mediaFile.size
        cb(resPayload, textEditor ? editorState : null)
        close()
      }
    })
  }

  const mediaTabs = mediaTypes.map(mediaType => {
    return (
      <Button
        disabled={mediaUploadLoading}
        key={mediaType}
        content={mediaType}
        onClick={() => {
          if (activeTab !== mediaType) onFileSelection({})
          setActiveTab(mediaType)
        }}
        link
        selected={activeTab === mediaType}
      />
    )
  })
  const hasFile = !!file.name
  const disableInsert =
    !hasFile ||
    ((activeTab === MEDIA_TYPE_MAP.image || activeTab === MEDIA_TYPE_MAP.white_labeling) && !altText && !noAltTextReq)
  const hasFileLimit = currentResourcesSizeCount !== undefined

  /**
   * If the image is cropped, insert the file
   */
  useEffect(() => {
    if (croppedImage) {
      onFileInsert()
    }
    return () => {
      setSubmit(false)
    }
  }, [croppedImage])

  /**
   * If autoUploadAfterFileSelect is true, we will immediately make the upload file
   * request after the user selects a file with this useEffect hook.
   */
  useEffect(() => {
    if (autoUploadAfterFileSelect && !disableInsert) {
      onFileInsert()
      setFile({})
    }
  }, [disableInsert, autoUploadAfterFileSelect])

  return (
    <div
      className={`media-uploader${className ? ` ${className}` : ''}${changeImageMode ? ' change-image' : ''}`}
      onClick={e => handleBackgroundClose(e)}
      ref={uploadRef}>
      {!noHeading && !heading && <h4 className='media-upload-heading'>Insert a Media File</h4>}
      {!!heading && <h4 className='media-upload-heading'>{heading}</h4>}
      {!!subheading && <span>{subheading}</span>}
      {error && (
        <p className='has-error'>
          <i className='fas fa-exclamation-circle' />
          {error}
        </p>
      )}
      {!noTabs && <div className='media-tabs'>{mediaTabs}</div>}
      <form encType='multipart/form-data' name='image-upload'>
        <UploadInput
          accept={accept}
          changeImageMode={changeImageMode}
          file={file}
          loading={loading}
          name={`instrument_${activeTab}`}
          onUpdateFile={onFileSelection}
          restrictionText={restrictionText || mediaInfo.restriction}
          type={activeTab}
          isMedia
          isCrop={isCrop}
          onSubmit={submit}
          previmSrc={previmSrc}
          setCroppedImage={setCroppedImage}>
          {hasFile &&
            (activeTab === MEDIA_TYPE_MAP.image || activeTab === MEDIA_TYPE_MAP.white_labeling) &&
            !noAltTextReq && (
              <Input
                autoFocus
                name='alt_text'
                onChange={e => onChange(e)}
                onEnterPress={() => !disableInsert && onFileInsert()}
                placeholder='Alternative text for this image'
                preventEnter
                value={altText}
              />
            )}
        </UploadInput>
      </form>
      {hasFileLimit && !isImage && (
        <p className={`capacity ${error ? 'has-error' : ''}`}>
          {`${convertToMB(currentResourcesSizeCount)} of 15 MB used`}
          {!isImage && hasFile && <span>{` (+ ${convertToMB(file.size)} MB)`}</span>}
        </p>
      )}
      {!error && (
        <div className={`buttons flexed end-justified${isCropping ? ' cropping' : ''}`}>
          {(showButtons || hasFile) && onClose && (
            <Button disabled={mediaUploadLoading} content='Cancel' grey onClick={() => (onClose ? onClose() : {})} />
          )}
          {(showButtons || hasFile) && !autoUploadAfterFileSelect && (
            <Button
              disabled={!hasFile || disableInsert || mediaUploadLoading}
              content={confirmButton || 'Insert'}
              onClick={() => (isCrop ? setSubmit(true) : onFileInsert())}
            />
          )}
        </div>
      )}
    </div>
  )
}

mediaUploader.propTypes = {
  autoUploadAfterFileSelect: PropTypes.bool,
  callback: PropTypes.func,
  changeImageMode: PropTypes.bool,
  className: PropTypes.string,
  currentResourcesSizeCount: PropTypes.number,
  initTab: PropTypes.string,
  isGeneralImageUpload: PropTypes.bool,
  itemCallbacks: PropTypes.objectOf(PropTypes.func),
  mediaTypes: PropTypes.arrayOf(PropTypes.string),
  mediaUploadLoading: PropTypes.bool,
  noAltTextReq: PropTypes.bool,
  noHeading: PropTypes.bool,
  noTabs: PropTypes.bool,
  onClose: PropTypes.func,
  restrictionText: PropTypes.string,
  ptpId: PropTypes.number,
  studyID: PropTypes.number,
  textEditor: PropTypes.bool,
  uploadDocument: PropTypes.func,
  uploadImage: PropTypes.func,
  uploadMedia: PropTypes.func,
}

export default mediaUploader
