import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import SadCloud from 'components/SadCloud'
import CasesPage from '../components/CasesPage'

const mapStateToProps = state => {
  const { cases, loading } = state

  return {
    loading: loading.general,
    cases,
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { loading, cases } = props
  if (loading) {
    return <Loader />
  }
  if (cases.casesList.length === 0) {
    return <SadCloud text='There are 0 cases. Please check back later.' />
  }
  return <CasesPage {...props} />
})
