import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { modalActions } from 'store/modal'
import { drawerActions } from 'store/drawer'
import { getUserRoleByStudy, getUserScopeByStudy, getUserRoleIdByStudy } from 'utils/misc'
import SadCloud from 'components/SadCloud'
import { USER_ROLES_MAP, USER_ROLE_IDS_CAN_DOWNLOAD_USER_SITE_LIST } from 'utils/constants'
import { checkIsEmailless } from 'utils/studyConfigUtils'
import { actions as sitesActions } from '../modules/Sites'
import { actions as siteActions } from '../../SiteManagement/modules/SiteManagement'
import SitesPage from '../components/SitesPage'

const mapStateToProps = state => {
  const { participants, sending, sites, study, loading, user, studyLock } = state
  const { currentStudy } = study
  const studyID = currentStudy.id
  const userRole = getUserRoleByStudy(user, studyID)
  const userScope = getUserScopeByStudy(user, currentStudy)
  const currentRoleId = getUserRoleIdByStudy(user, studyID)
  const canWriteSites = userScope?.includes('wu')
  const { sendingState, sentState } = sending
  const sendDisabled = sendingState || sentState
  const isEmailless = checkIsEmailless(currentStudy)

  const sitesInfo = () => {
    const newSitesInfo = { ...state.userReducer.userSites }
    Object.keys(newSitesInfo).forEach(key => {
      if (newSitesInfo[key].level === 1) delete newSitesInfo[key]
    })
    return newSitesInfo
  }

  const hasConsent = currentStudy.consent_required

  return {
    ...participants,
    canCreate: canWriteSites,
    canEdit: canWriteSites,
    canDelete: userRole !== USER_ROLES_MAP.siteUserManagementAdmin,
    hasConsent,
    isRootOrDchAdmin: [USER_ROLES_MAP.root, USER_ROLES_MAP.dchAdmin].includes(userRole),
    isEmailless,
    loading: loading.general,
    requestSending: sendingState,
    requestSent: sentState,
    sendDisabled,
    sites,
    sitesInfo: sitesInfo(),
    study: study.currentStudy,
    studyID,
    studyLock,
    canDownloadSiteList: USER_ROLE_IDS_CAN_DOWNLOAD_USER_SITE_LIST.includes(currentRoleId),
  }
}

const mapDispatchToProps = { ...modalActions, ...drawerActions, ...siteActions, ...sitesActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { canCreate, loading, sites } = props
  if (loading) {
    return <Loader />
  }
  if (!canCreate && sites.sitesList.length === 0) {
    return <SadCloud text='There are 0 sites. Please check back later.' />
  }
  return <SitesPage {...props} />
})
