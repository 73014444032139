import { connect } from 'react-redux'
import { USER_SCOPES, ROLE_NAME_ID_MAP } from 'utils/constants'
import { disableScope, getUserScopeByStudy, getUserRoleIdByStudy } from 'utils/misc'
import CohortManagement from '../components/CohortManagement'
import { actions } from '../modules/CreateParticipant'
import { actions as cohortActions } from '../../CreateCohort/modules/cohort'

const mapStateToProps = (props, ownProps) => {
  const { participantReducer, participants, study, user } = props
  const { errorText, hasError } = ownProps
  const { currentStudy } = study
  const userScope = getUserScopeByStudy(user, currentStudy)
  const userRoleId = getUserRoleIdByStudy(user, currentStudy?.id)
  const canWriteCohort = userScope.includes(USER_SCOPES.wxc.code)
  const isSiteCoordinator = userRoleId === ROLE_NAME_ID_MAP.Site_Coordinator
  return {
    checked: participantReducer.checkedCohorts,
    cohortList: participants.cohortList,
    checkedCohorts: participantReducer.checkedCohorts,
    errorText,
    hasError,
    studyID: study.currentStudy.id,
    participantId: participantReducer.participant.id,
    disableTrackCreation: disableScope({ study, user, scope: USER_SCOPES.wxc.code }) || !canWriteCohort,
    enforceSingleTrackAssignment: isSiteCoordinator && currentStudy?.config?.single_track_assignment,
  }
}

const mapActions = {
  ...actions,
  saveCohortInline: cohortActions.saveCohortInline,
  fetchCohorts: require('../../ParticipantsPage/modules/Participants').fetchCohorts,
}

export default connect(mapStateToProps, mapActions)(CohortManagement)
