import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { SortableTable } from 'components/UIElements'
import { hasScrollbar } from 'utils/misc'
import { VisitStatusCell, ChevronCell, SiteCell } from './VirtualVisitDataCells'
import VisitorCell from '../containers/VisitorCellContainer'
import '../stylesheets/VisitsHistoryTable.scss'

const headerOptions = [
  { key: 'checkbox', sortable: false },
  { key: 'participantName', sortable: true, component: 'Participant Name' },
  { key: 'ptpId', sortable: true, component: 'ID' },
  { key: 'subjectId', sortable: true, component: 'Subj. ID' },
  { key: 'clinicianName', sortable: true, component: 'Clinician Name' },
  { key: 'clinicianId', sortable: true, component: 'Clinician ID' },
  { key: 'siteId', sortable: true, component: 'Site ID' },
  { key: 'status', sortable: true, component: 'Success' },
  { key: 'date', sortable: true, component: 'Date' },
]

const VisitsHistoryTable = props => {
  const {
    expandedVisits,
    fetchVisit,
    offset,
    resetExpandedVisits,
    searchTerm,
    setListCount,
    siteID,
    studyID,
    toggleVisitExpand,
    visitsHistoryList,
    visitsInfo,
  } = props
  const [summaryWrapperStyle, setSummaryWrapperStyle] = useState({})
  const [_hasScrollBar, setHasScrollBar] = useState(false)

  const vvHistoryTableRef = useRef(null)

  useEffect(() => {
    resetExpandedVisits()
  }, [])

  useEffect(() => {
    const currentList = vvHistoryTableRef?.current?.getList()
    if (currentList) setListCount(currentList.length)
    else setListCount(visitsHistoryList.length)
  }, [searchTerm])

  const handleResize = (isOnClick = false) => {
    // Need to check if argument is a boolean because on resize, the argument is an Event
    const _isOnClick = typeof isOnClick === 'boolean' && isOnClick
    setTimeout(
      () => {
        const tableRef = vvHistoryTableRef.current?.tableRef
        if (tableRef) {
          const summaryWrapperElements = tableRef?.getElementsByClassName('summary-wrapper')
          const _tableWidth = tableRef?.clientWidth
          const _chevronCellWidth = tableRef?.querySelector('thead tr th:first-child ').clientWidth
          for (let i = 0; i < summaryWrapperElements.length; i++) {
            summaryWrapperElements[i].style.width = `${_tableWidth - _chevronCellWidth - 3}px`
          }
          setSummaryWrapperStyle({
            width: `${_tableWidth - _chevronCellWidth - 3}px`,
          })
        }
      },
      _isOnClick ? 100 : 0,
    )
  }

  // This effect is to determine on initial mount and resize, the width of the expanded summary content dynamically
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    handleResize()
  }, [_hasScrollBar])

  const onRowClick = row => {
    const idData = row.find(el => el.key === 'checkbox')
    const { value: visitId } = idData
    const isExpanded = expandedVisits[visitId]
    const visitInfo = visitsInfo[visitId]

    toggleVisitExpand(visitId)

    if (!isExpanded) {
      if (!visitInfo) fetchVisit(studyID, siteID, visitId)
      setTimeout(() => {
        setHasScrollBar(hasScrollbar())
      }, 400) // we check if there a scrollbar on a delay to allow for the transition of the height to happen first
    }
  }

  //
  // Table Data Render Functions
  //
  const renderFunctions = {
    participantName: _props => (
      <VisitorCell {..._props} isPtp summaryWrapperStyle={summaryWrapperStyle} handleResize={handleResize} />
    ),
    clinicianName: _props => <VisitorCell {..._props} />,
    checkbox: _props => <ChevronCell {..._props} />,
    siteId: _props => <SiteCell {..._props} />,
    status: _props => <VisitStatusCell {..._props} />,
  }

  headerOptions[0].component = <i className='fas fa-chevron-right' />

  return (
    <SortableTable
      ref={vvHistoryTableRef}
      headerOptions={headerOptions}
      offset={offset}
      sortingBy='date'
      initialOrder='descending'
      renderFunctions={renderFunctions}
      rowExpandOptions={{
        isRowExpanded: row => {
          const idData = row.find(el => el.key === 'checkbox')
          const visitId = idData.value
          return expandedVisits[visitId]
        },
        expandedRowClassName: 'expanded',
      }}
      rowList={visitsHistoryList}
      searchKeys={['participantName', 'ptpId', 'clinicianName', 'clinicianId', 'siteId']}
      searchTerm={searchTerm}
      onRowClick={onRowClick}
      emptyText='No Matching Past Virtual Visits'
    />
  )
}

ChevronCell.propType = {}

VisitsHistoryTable.propTypes = {
  expandedVisits: PropTypes.objectOf(PropTypes.bool),
  fetchVisit: PropTypes.func,
  offset: PropTypes.number,
  resetExpandedVisits: PropTypes.func,
  searchTerm: PropTypes.string,
  setListCount: PropTypes.func,
  studyID: PropTypes.string,
  siteID: PropTypes.number,
  toggleVisitExpand: PropTypes.func,
  visitsHistoryList: PropTypes.arrayOf(PropTypes.array),
  visitsInfo: PropTypes.objectOf(PropTypes.object),
}

export default VisitsHistoryTable
