import { connect } from 'react-redux'
import { getUserScopeByStudy, getUserRoleByStudy, getUserRoleId, isSiteAdmin } from 'utils/misc'
import { modalActions } from 'store/modal'
import { drawerActions } from 'store/drawer'
import { ROLE_NAME_ID_MAP } from 'utils/constants'
import { consentActions } from '../modules/Consents'
import ConsentDrawer from '../components/ConsentDrawer'
import { fetchNewConsent } from '../../ConsentView/routes/NewConsentBuilder/modules/NewConsent'

const mapStateToProps = (state, ownProps) => {
  const {
    consents,
    newConsentReducer,
    loading,
    participants,
    user,
    sites,
    study,
    localesMap,
    studyLock,
    searchTerm,
  } = state
  const { consentList, documents } = consents
  const { currentStudy } = study
  const { consentId, draft, consentVersion } = ownProps
  const { cohortMap } = participants
  const { sitesMap } = sites

  const userScope = getUserScopeByStudy(user, currentStudy)
  const userRole = getUserRoleByStudy(user, currentStudy.id)
  const noTracksFeature = currentStudy.config?.tracks?.disabled === true

  const consentForceUploadRoles = [ROLE_NAME_ID_MAP.Clinical_Coordinator, ROLE_NAME_ID_MAP.Site_Coordinator]
  const isDocumentOptional = !consentForceUploadRoles.includes(getUserRoleId(user, currentStudy.id))

  return {
    ...newConsentReducer,
    canEdit: userScope.includes('xc'),
    cohortMap,
    consentId,
    consentList,
    consentStartDate: state.study.currentStudy.created_on,
    consentVersion,
    documents,
    draft,
    isSiteAdmin: isSiteAdmin(userRole),
    isDocumentOptional,
    loading: loading.general,
    loadingPtps: loading.consentPtps,
    loadingMarkConsentAsComplete: loading.markConsentAsComplete,
    loadingConsentAttachment: loading.consentAttachment,
    localesMap,
    noTracksFeature,
    sitesMap,
    studyId: currentStudy.id,
    studyLock,
    searchTerm,
  }
}

const mapDispatchToProps = {
  ...consentActions,
  ...modalActions,
  ...drawerActions,
  fetchNewConsent,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentDrawer)
