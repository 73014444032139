import React from 'react'
import { Button } from 'components/UIElements'
import PropTypes from 'prop-types'

const DiaryLogicGroup = props => {
  return (
    <div className='logic-group'>
      <p>{`Group ${props.groupPath[0] + 1}`}</p>
      <div className='logic-group-inner'>{props.group}</div>
      <Button
        link
        icon='fas fa-plus'
        className='inner-add-logic'
        onClick={() => props.addLogicCondition(props.itemId, props.groupPath)}
        content='Add Logic Condition'
      />
    </div>
  )
}

DiaryLogicGroup.propTypes = {
  groupPath: PropTypes.array,
  itemId: PropTypes.string,
  addLogicCondition: PropTypes.func,
  group: PropTypes.array,
}

export default DiaryLogicGroup
