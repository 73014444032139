import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getBaseSiteIdFromStudy, getUserRoleByStudy } from 'utils/misc'
import { USER_ROLES_MAP } from 'utils/constants'
import SelectTaskView from '../components/SelectTaskPage'
import { actions } from '../modules/SelectTask'
import { instrumentActions } from '../../../modules/Instrument'

const mapDispatchToProps = { ...actions, ...instrumentActions }

const mapStateToProps = ({ instrumentReducer, loading, searchTerm, selectTask, study, user }) => {
  const { taskList } = selectTask
  const studyID = study.currentStudy.id
  const userRole = getUserRoleByStudy(user, studyID)
  const canSeeHiddenInstruments = [USER_ROLES_MAP.root, USER_ROLES_MAP.dchAdmin].includes(userRole)
  let filteredList = taskList
  if (!canSeeHiddenInstruments && taskList.length > 0) {
    filteredList = taskList.filter(item => !item[0].isHidden)
  }

  return {
    taskList: filteredList,
    isCreate: !instrumentReducer.instrument.hasOwnProperty('id'),
    loading: selectTask.taskList.length === 0 || loading.general,
    searchTerm,
    selectedTask: instrumentReducer.instrument,
    studyID: study.currentStudy.id,
    siteID: getBaseSiteIdFromStudy(study),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  if (props.loading) {
    return Loader(props)
  }
  return SelectTaskView(props)
})
