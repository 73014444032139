import { browserHistory } from 'react-router'
import { setSubroute } from 'store/navbar'
import { injectReducer } from 'store/reducers'
import { UserIsAuthenticated, UserHasValidPassword } from 'containers/authContainers'
import { setPageTitle } from 'utils/misc'
import { isSameUser } from 'utils/userUtils'
import reducer, {
  actions as createUserActions,
} from '../Studies/routes/Study/routes/Users/routes/CreateUser/modules/CreateUser'
import UserView from '../Studies/routes/Study/routes/Users/routes/CreateUser/containers/UserContainer'
import { fetchStudies } from '../Studies/StudiesPage/modules/Studies'

// Sync route definition
export default store => ({
  path: '/users/me',
  getComponent(nextState, cb) {
    const { user } = store.getState()
    if (isSameUser(user.id) ?? true) {
      require.ensure(
        [],
        () => {
          injectReducer(store, {
            key: 'userReducer',
            reducer,
          })
          setPageTitle('My Profile | Admin Panel')
          const { studies } = store.getState()
          if (studies.length === 0) store.dispatch(fetchStudies())
          store.dispatch(setSubroute('me'))
          store.dispatch(createUserActions.resetUserPage())
          store.dispatch(createUserActions.fetchUser('me'))
          /*  Return getComponent   */
          cb(null, UserIsAuthenticated(UserHasValidPassword(UserView)))
        },
        '/users/me',
      )
    } else browserHistory.push('/')
  },
})
